// vendors
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { ID } from "../../utlis/commonInterfaces";
import inputCalculationService from "../Services/inputCalculation.service";
import { InputCalculation } from "../Reducers/inputCalculation.reducer";
import { transformCalculatorInputFromApi } from "../../Configuration/Costing/CreateCostCalculator";

// services
// import inputCalculationService from "app/services/inputCalculation.service";

// // redux
// import { InputCalculation } from "../Reducers/inputCalculation.reducer";

// // others
// import { transformCalculatorInputFromApi } from "app/Components/Costing/CalculatorConfiguration/InputConfiguration/InputConfiguration";

// // interfaces
// import { ID } from "app/commonInterfaces";

// -------------------------------------------------------------------------------------
// api actions
// -------------------------------------------------------------------------------------

export interface GetInputCalculationsArg {
    userInputId: ID;
}
const getInputCalculations = createAsyncThunk(
    "inputCalculation/get",
    async ( arg: GetInputCalculationsArg, { rejectWithValue } ) => {
        try {
            const { data } = await inputCalculationService.get( arg );

            return {
                calculations: data.map( transformCalculatorInputFromApi ) as InputCalculation[]
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    },
    {
        condition ( arg, api ) {
            return Boolean( arg.userInputId );
        }
    }
);

const updateInputCalculations = createAsyncThunk(
    "inputCalculation/update",
    async ( arg: any, { rejectWithValue } ) => {
        try {
            const { data } = await inputCalculationService.update( arg );

            return {
                calculations: data.map( transformCalculatorInputFromApi ) as InputCalculation[]

            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------
// reducer actions
// -------------------------------------------------------------------------------------

const resetInputCalculations = createAction( "inputCalculation/reset" );

// -------------------------------------------------------------------------------------
// exports
// -------------------------------------------------------------------------------------

export {
    getInputCalculations,
    resetInputCalculations,
    updateInputCalculations
};