import React, { useState } from "react";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { useHistory } from "react-router-dom";
import styles from "./GradientProgressBar.module.scss";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { IconButton, Menu, MenuItem } from "@mui/material";
import ReplyIcon from '@mui/icons-material/Reply';
import DownloadIcon from '@mui/icons-material/Download';
import { useBaseModal } from "../../SearchPage/useBaseModal";
import { API } from "../../../api-services";
import { DialogComponent } from "../../../utlis/DialogComponent";

interface GradientProgressBar {
  completed: number;
  rejected: number;
  notStarted: number;
  item:{abbrevation: string,accepted_ideas: number,base: number,rejected_ideas: number,submitted_ideas: number,subsystem_name: string,total_ideas: number};
}

interface Params {
  projectId: number;
  topVaultId: number;
}

const GradientProgressBar = (props: GradientProgressBar) => {
  const { completed, rejected, notStarted,item} = props;
  const { projectId, topVaultId } = useRouteParams<Params>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [vaultGet,setVaultGet] = useState<number | undefined>();
  const [contentText,setContentText]=React.useState<string>("Report download is running in background");
  const [iconText,setIconText]=React.useState<string>("success")
  const open = Boolean(anchorEl);
  const openDialogModal=useBaseModal();
  const history = useHistory();

  const RedirectToIdeaTable:any=(abbr:string,baseVault:number)=>{
        history.push(`/idea/${projectId}/${topVaultId}/${abbr}/${baseVault}`)
  }

  const handleClickDownload = (event: React.MouseEvent<HTMLButtonElement>,vaultId:number) => {
    setAnchorEl(event.currentTarget);
    setVaultGet(vaultId);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

 const handleWithoutImage = async() => {
  handleClose();
    API.get("/cost/check_consolidate_downoad/",{ vault:vaultGet,with_image:false,consolidate_type:"Idea"},0).then((res:any)=>{
        if(res.data.status==="Failed"){
          openDialogModal.open();
         setIconText("failed")
         setContentText(res.data.message)
        }else if(res.data.status==="First Download"){
          openDialogModal.open();
         setIconText("firstdownload")
         setContentText(res.data.message)
         API.get(
            "/cost/consolidated_report/",
            {
              vault:vaultGet,
              consolidate_type:"Idea",
              with_image:false
             },
             0).then((res: any) => {
                       window.open(res?.data, "_blank");
                       }).catch((err: any) => {
                        openDialogModal.open();
                        setIconText("failed")
                        setContentText(err.response.data)
                       });
                      }else if(res.data.status==="In Progress"){
                        openDialogModal.open();
                       setIconText("reportProgress")
                       setContentText(res.data.message)
                      }else if(res.data.status==="Completed"){
                       window.open(res?.data?.url, "_blank");
                      }
              })}

 const handleWithImage = async() => {
  handleClose();
  API.get("/cost/check_consolidate_downoad/",{ vault:vaultGet,with_image:true,consolidate_type:"Idea"},0).then((res:any)=>{
        if(res.data.status==="Failed"){
          openDialogModal.open();
         setIconText("failed")
         setContentText(res.data.message)
        }else if(res.data.status==="First Download"){
          openDialogModal.open();
         setIconText("firstdownload")
         setContentText(res.data.message)
      API.get(
            "/cost/consolidated_report/",
            {
              vault:vaultGet,
              consolidate_type:"Idea",
              with_image:true
            },
             0).then((res: any) => {
                       window.open(res?.data, "_blank");
                       }).catch((err: any) => {
                        openDialogModal.open();
                        setIconText("failed")
                        setContentText(err.response.data)
                       });
                      }else if(res.data.status==="In Progress"){
                        openDialogModal.open();
                       setIconText("reportProgress")
                       setContentText(res.data.message)
                      }else if(res.data.status==="Completed"){
                       window.open(res?.data?.url, "_blank");
                      }
              })}


  return (
    <div className={styles.gradientGraph}>
    <DialogComponent isOpen={openDialogModal.isOpen} onClose={openDialogModal.close} content={contentText} type={iconText}/> 
    <span title={item?.abbrevation} className={styles.title} onClick={()=>RedirectToIdeaTable(item?.abbrevation,item?.base)}>{item?.subsystem_name}</span>
    <span className={styles.graphBar} title={`Submitted but no action : ₹${item?.submitted_ideas}, ${Math.round(notStarted)}%, Accepted : ₹${item?.accepted_ideas}, ${Math.round(completed)}%, Rejected : ₹${item?.rejected_ideas}, ${Math.round(rejected)}%`}>
    {!isNaN(notStarted) && (
        <div
          style={{
            height: "100%",
            width: `${notStarted}%`,
            display: "inline-block",
            textAlign:'center',
            backgroundColor:'#007fff',
            fontSize:'1rem',
            color:'white'
          }}
        >
          {notStarted==0?'':`${Math.round(notStarted)}%`}
        </div>
      )}
            {!isNaN(completed) && (
        <div
          style={{
            height: "100%",
            width: `${completed}%` ,
            display: "inline-block",
            textAlign:'center',
            backgroundColor:'#a3c182',
            color:'black',
            fontSize:'1rem',
            
          }}
        >       
            {completed==0?'':`${Math.round(completed)}%`}
        </div>
      )}
      {!isNaN(rejected) && (
        <div
          style={{
            height: "100%",
            width: `${rejected}%`,
            display: "inline-block",
            textAlign:'center',
            backgroundColor:'red',
            color:'white',
            fontSize:'1rem'
          }}
        >
          {rejected==0?'':`${Math.round(rejected)}%`}
        </div>
      )}
      
      </span>
      <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      sx={{
        ".MuiList-root.MuiMenu-list": {
          padding: 0,
        }
      }}
      >
      <MenuItem sx={{fontSize:'1rem'}} onClick={handleWithImage}>With Image Report</MenuItem>
      <MenuItem sx={{fontSize:'1rem'}} onClick={handleWithoutImage}>
        Without Image Report
      </MenuItem>
    </Menu>
      <IconButton onClick={(event:React.MouseEvent<HTMLButtonElement>)=>handleClickDownload(event,item?.base)}><DownloadIcon sx={{color:'primary.main'}} /></IconButton>
      <IconButton onClick={()=>RedirectToIdeaTable(item?.abbrevation,item?.base)}><ReplyIcon sx={{transform:'scaleX(-1)',color:'primary.main'}} /></IconButton>
    </div>
  );
};
export default GradientProgressBar;
