import Modal from "@mui/material/Modal";
import styles from "./CostingTableSection.module.scss";
import { makeStyles } from "@mui/styles";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Divider,
  Typography,
  Skeleton,
} from "@mui/material";
import Box from "@mui/material/Box";
import { API } from "../../api-services";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { useEffect, useLayoutEffect, useState } from "react";
import BouncingLoader from "../../ui-reusable-component/BouncingLoader";
import { MachineRevision } from "./MachineRevision";
import { MaterialRivision } from "./MaterialRevision";
import { display } from "@mui/system";
import { MLabourRivision } from "./LabourRevision";
import CancelIcon from "@mui/icons-material/Cancel";
import { AUTH } from "../../Redux/Services/auth.service";
import ReplyIcon from "@mui/icons-material/Reply";

export interface IAssumptionModalProps {
  isOpen: boolean;
  onClose: () => void;
  //   responseComment: any;
}

const styled = {
  boxSize: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",

    minWidth: { lg: "55%", xl: "40%" },
    maxWidth: "80%",
    height: "fit-content",
    minHeight: "30rem",
    borderRadius: "0.5rem",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 0,
    outline: 0,
  },
  NoAccessBoxSize: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center !important",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "40%",
    height: "fit-content",
    minHeight: "20rem",
    borderRadius: "0.5rem",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 0,
    outline: 0,
  },
  HeaderLine: {
    textAlign: "center",
    position: "relative",
  },
  SelectedHeaderLine: {
    color: "#1976d2",
  },
  nextButton: {
    position: "absolute",
    zIndex: "9999",
    left: "35rem",
    top: "1.8rem",
  },

  summery: {
    backgroundColor: "rgb(240, 247, 255)",
    borderColor: " rgb(0, 127, 255)",
    borderRadius: "10px",
    minHeight: "45px",
  },
  searchButton: {
    position: "absolute",

    // right: "37rem",
    top: "0.4rem",

    left: "-2rem",
    cursor: "default",
    color: "#1976d2",
    padding: " 0rem 0rem 0rem 2rem",
  },
  closedIcon: {
    position: "absolute",
    zIndex: "999",
    color: "#1976d2",
    right: "0.2rem",
    top: "0.5rem",
    cursor: "pointer",
  },
  editIcon: {
    marginTop: "0.3rem",
    color: "#1976d2",
    cursor: "pointer",
  },
};
// @ts-ignore

const useStyles = makeStyles((theme) => ({
  /** Changed modalStyle */
  ModalRoot: {
    backgroundColor: "rgba(111, 126, 140, 0.2) !important",
    backdropFilter: "blur(1px) !important",
  },
}));

export function AssumptionModal(props: IAssumptionModalProps) {
  const { isOpen, onClose } = props;
  const classes = useStyles();
  const { projectId, vault, topVault, scenarioId } = useRouteParams<any>();
  const [loding, setLoading] = useState<any>(false);
  const [assumptionDetails, setAssumptionDetails] = useState<any>([]);
  const [useraction, setuseraction] = useState<any>(null);

  const handleDownload = () => {
    try {
      // API.apiBase + `cost/generate_cost_guide_line/?project=${projectId}&user=${AUTH.user}`

      fetch(
        API.apiBase +
          `cost/generate_cost_guide_line/?project=${projectId}&user=${AUTH.user}`,
        {
          method: "GET",
        }
      )
        .then((resp) => resp.blob())

        .then((blob) => {
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement("a");

          a.style.display = "none";

          a.href = url;

          a.download = "costing-guideline"; // the filename you want

          document.body.appendChild(a);

          a.click();

          window.URL.revokeObjectURL(url);
        });
    } catch (e) {}
  };

  const getAssumptionData = () => {
    setLoading(true);
    API.get(
      "/cost/costing_module/",
      {
        vault_id: vault,
        get_assumption_details: true,
        scenario: scenarioId ? scenarioId : undefined,
      },
      0
    )
      .then((res: any) => {
        setLoading(false);

        setAssumptionDetails(res.data);

      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  
  const checkUserPermession = () => {
    
    API.get(
      '/auth/user_permission_module/',
      {
        action: true,
        database: true,
        module: 'DB-Costing',
      },
      0
    )
      .then((res: any) => {
        
        setuseraction(res?.data?.action);
        
      })
      .catch((err: any) => { });

  }

  useEffect(() => {
    getAssumptionData();
    checkUserPermession();
  }, [vault]);

  
  const handleUserRedirection = () => {
    window.open(`/#/data-base/revision-database/${assumptionDetails[0].revision_type}/${assumptionDetails[0].tools_revision["Revised on"].split("-")[2]}/${assumptionDetails[0].tools_revision["Revised on"].split("-")[1]}/${assumptionDetails[0].material_revision.id}/0/0/0/false/0`) 
  }
  return (
    <div>
      <Modal // autoFocusdal
        open={isOpen}
        className={classes.ModalRoot}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!loding ? (
          <Box sx={styled.boxSize}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "2.5rem",
                padding: "0 0.5rem",
              }}
            >
              <Typography
                style={{
                  textAlign: "center",
                  fontSize: "1rem",
                  padding: "0.2rem 0.5rem",
                  fontWeight: "600",
                  width: "95%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                Assumption Card (
                {assumptionDetails && assumptionDetails[0]?.name})
                {((useraction && useraction?.includes('C')) ||
									(useraction && useraction?.includes('U')) ||
									(useraction && useraction?.includes('R')) ||
									(useraction && useraction?.includes('D'))) && 
                (<ReplyIcon
                            onClick={()=> handleUserRedirection()}
                              
                              titleAccess="Go to Revision Page"
                              sx={{
                                fontSize: "1.5rem",
                                transform: "scaleX(-1)",
                                justifySelf: "center",
                                alignSelf: "center",
                                cursor: "pointer",
                              }}
                            /> ) 
}
              </Typography>
              <CancelIcon
                titleAccess="Close"
                sx={{ cursor: "pointer" }}
                onClick={onClose}
              />
            </Box>
            <Divider sx={{ margin: "0 1rem", borderColor: "primary.light" }} />
            <Box sx={{ padding: "0.2rem 1rem" }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0 0.5rem",
                        borderBottom: "none",
                        width: "43%",
                      }}
                    >
                      <Typography
                        style={{ fontSize: "1rem", color: "#646464" }}
                      >
                        Manufacturing Annual Volume(Qty):
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0 0.5rem",
                        borderBottom: "none",
                        width: "60%",
                      }}
                    >
                      {assumptionDetails && assumptionDetails[0]?.volume}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0 0.5rem",
                        borderBottom: "none",
                        width: "43%",
                      }}
                    >
                      <Typography
                        style={{ fontSize: "1rem", color: "#646464" }}
                      >
                        Manufacturing Location:
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0 0.5rem",
                        borderBottom: "none",
                        width: "60%",
                      }}
                    >
                      {assumptionDetails.length > 0 &&
                        `${assumptionDetails[0]?.location.city}, ${assumptionDetails[0]?.location.country}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0 0.5rem",
                        borderBottom: "none",
                        width: "43%",
                      }}
                    >
                      <Typography
                        style={{ fontSize: "1rem", color: "#646464" }}
                      >
                        Costing Guideline:
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0 0.5rem",
                        borderBottom: "none",
                        width: "60%",
                      }}
                    >
                      <u onClick={handleDownload} style={{ cursor: "pointer" }}>
                        costing-guideline.pdf
                      </u>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
            <Box
              sx={{
                width: "100%",
                padding: "1rem",
                display: "grid",
                gridTemplateColumns: "0.75fr 1fr",
                columnGap: "10px",
              }}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3"
            >
              <Box>
                <MaterialRivision
                  machineRevisionData={
                    assumptionDetails && assumptionDetails[0]?.material_revision
                  }
                />
                <MLabourRivision
                  machineRevisionData={
                    assumptionDetails && assumptionDetails[0]?.labour_revision
                  }
                />
              </Box>
              <Box>
                <MachineRevision
                  machineRevisionData={
                    assumptionDetails && assumptionDetails[0]?.machine_revision
                  }
                />
              </Box>
            </Box>
          </Box>
        ) : (
          <Box></Box>
        )}
      </Modal>
    </div>
  );
}
