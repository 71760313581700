import * as React from 'react';
import { Box, IconButton, Skeleton, Typography } from '@mui/material';
import { API } from '../../api-services';
import styles from './UserPermissionLanding.module.scss';
import { useHistory, useRouteMatch } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { GeneralPermission } from '../PermissionModule/GeneralPermission';
import InfoIcon from '@mui/icons-material/Info';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';



interface Params {
    categoryId: any;    
  }

export interface IUserPermissionLandingProps {}

export default function UserPermissionLanding(
	props: IUserPermissionLandingProps
) {
	const [vehicleCategory, setVehicleCategory] = React.useState([]);
	const [categoryLoader, setCategoryLoader] = React.useState(false);
	const history = useHistory();
    const { url } = useRouteMatch();
    const {categoryId } = useRouteParams<Params>();
    
	React.useEffect(() => {
		setCategoryLoader(true);
		API.get('/supplier/vehicle_category/', {}, 0)
			.then((res: any) => {
				setVehicleCategory(res.data);
				setCategoryLoader(false);
			})
			.catch((err: any) => {
				setCategoryLoader(false);
				err.response && console.log(err.response.data);
			});
	}, []);

	console.log(vehicleCategory,url, 'vehicleCategory');

	const handleClickBack = () => {
		history.push('/dashboard');
	};

	return (
		<div>
			<Box sx={{ padding: '0 0.5rem', display: 'flex', alignItems: 'center' }}>
				<Box className={styles.titleSection}>
					<Box className={styles.titleSectionLeft}>
						<IconButton sx={{ cursor: 'pointer' }} onClick={handleClickBack}>
							<ArrowBackIcon color='primary' titleAccess='Go Back' />
						</IconButton>
						<Typography
							sx={{
								fontSize: '1rem',
								whiteSpace: 'nowrap',
								display: 'inline-block',
							}}>
							User Permission
						</Typography>
					</Box>
					<Box className={styles.titleSectionRight}></Box>
				</Box>
			</Box>
			<Box
				sx={{
					display: 'grid',
					gridTemplateColumns: '20rem 1fr',
					width: '100%',
					height: '100%',
					flexGrow: 1,
					padding: '0 1rem',
				}}>
				<Box sx={{ width: '100%', height: '100%' }}>
                <Box			
                    sx={{
                        padding: '0.2rem 0.5rem',
                        borderBottom: '1px solid',
                        borderColor: 'primary.light',
                        backgroundColor: categoryId == 0  ? 'primary.main' : 'primary.light',
                        borderRadius: '5px',
                        border: categoryId == 0 ? '2px solid #000' : 'none',
                        color: 'white',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        marginBottom: '0.5rem',
                    }}>
                    General Permission <InfoIcon />
                </Box>
					{categoryLoader ? (
						<Box>
                            <Skeleton animation='wave' height={40} />
                            <Skeleton animation='wave' height={40} />
                            <Skeleton animation='wave' height={40} />
                            <Skeleton animation='wave' height={40} />
                            <Skeleton animation='wave' height={40} />
                            <Skeleton animation='wave' height={40} />
                            <Skeleton animation='wave' height={40} />
                            <Skeleton animation='wave' height={40} />
                            <Skeleton animation='wave' height={40} />
                        </Box>
					) : (
						vehicleCategory &&
						vehicleCategory.map((item: any) => (
							<Box
								key={item.id}
								sx={{
									padding: '0.2rem 0.5rem',
									borderRadius: '5px',
                                    borderColor: 'primary.light',
                                    backgroundColor: item.id == categoryId ? 'primary.main' : 'primary.light',
                                    color: item.id == categoryId ? 'white' : 'text.primary',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginBottom: '0.5rem',
                                    width: '100%',
								}}>
								{item.name} <InfoIcon />
							</Box>
						))
					)}
				</Box>
				<Box sx={{ width: '100%', height: '100%' }}>
                    {categoryId == 0 && <GeneralPermission />}
                </Box>
			</Box>
		</div>
	);
}
