import { Box, IconButton, Modal, Tooltip, Typography } from '@mui/material';
import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import LinkIcon from '@mui/icons-material/Link';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40vw',
  height: '60vh', // Adjust height if needed
  bgcolor: 'background.paper',
  borderRadius: '10px',
  p: 2, // Increased padding for better spacing
};

function PartListModal({ openModal, handleCloseModal }: any) {
  // Dummy data
  const dummyData: any[] = [
    { id: 1, name: 'Part A', bomUrl: 'http://example.com/bom/1', costingUrl: 'http://example.com/costing/1', msp: 100 },
    { id: 2, name: 'Part B', bomUrl: 'http://example.com/bom/2', costingUrl: 'http://example.com/costing/2', msp: 120 },
    { id: 3, name: 'Part C', bomUrl: 'http://example.com/bom/3', costingUrl: 'http://example.com/costing/3', msp: 150 },
  ];

  

  // Define columns with icons for URLs
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'msp', headerName: 'MSP', width: 150, renderCell: (params) => `₹${params.value}` },
    {
      field: 'bomUrl',
      headerName: 'BOM URL',
      width: 150,
      renderCell: (params) => (
        <Tooltip title="BOM URL">
          <IconButton
            component="a"
            href={params.value}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="BOM URL"
          >
            <LinkIcon />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      field: 'costingUrl',
      headerName: 'Costing URL',
      width: 150,
      renderCell: (params) => (
        <Tooltip title="Costing URL">
          <IconButton
            component="a"
            href={params.value}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Costing URL"
          >
            <AttachFileIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
          <Typography
            style={{
              fontSize: '1rem',
              fontWeight: '600',
              width: '100%',
              textAlign: 'center',
              marginRight: '-2rem',
              marginTop: '0.5rem',
            }}
          >
            Parts
          </Typography>
          <CancelIcon
            titleAccess="Close"
            sx={{ cursor: 'pointer', margin: '0.5rem' }}
            onClick={handleCloseModal}
          />
        </Box>
        <Box sx={{ height: 'calc(100% - 3rem)', width: '100%' }}> {/* Adjusted height for DataGrid */}
          <DataGrid
            rows={dummyData}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
          />
        </Box>
      </Box>
    </Modal>
  );
}

export default PartListModal;