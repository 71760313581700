import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import styles from "../AddRevisionModal.module.scss";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { useRouteMatch } from "react-router-dom";
import Swal from "sweetalert2";
import successIcon from "../../../Assets/images/success.png";

export interface IBearingFormProps {
  actions: any;
  onCloseModal: any;
  getCategoryData: any;
  category: any;
  onCloseParent: any;
}
const validationSchema = yup.object({
  code: yup.string().required("Please type a Item Code"),
  name: yup.string().required("Please type Item name"),
  type: yup.string().required("Please select fastener type"),
  grade: yup.mixed().required("Please type grade"),
  material: yup.mixed().required("Please type material"),
  m_size: yup.mixed().required("Please enter M-Size"),
  length: yup.mixed().required("Please type Length"),
  thread_length: yup.mixed().required("Please type Thread Length"),
  weight: yup.mixed().required("Please type Weight"),
  reference: yup.string().required("Please type Item reference"),
  unit_cost: yup.mixed().required("Please enter per unit cost"),
});
const useStyles = makeStyles((theme) => ({
  select: {
    "&.MuiInputBase-root-MuiInput-root-MuiSelect-root": {
      borderBottom: "1px solid red",
    },
  },
}));
export function FastenersFormMove(props: IBearingFormProps) {
  const [isLoadingsave, setIsLoadingSave] = React.useState<any>(false);
  const { url } = useRouteMatch();
  const { actions, onCloseModal, getCategoryData, category, onCloseParent } = props;
  const { categoryName } = useRouteParams<any>();
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      code: "",
      name: "",
      type: "",
      grade: "",
      material: "",
      m_size: "",
      length: "",
      thread_length: "",
      weight: "",
      reference: "",
      unit_cost: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsLoadingSave(true);
      API.post(
        "db/move_cost_data/",
        {
          ...values,
          ...{ target_commodity: category, id: url.includes("DB") ? actions.id : actions.purchase_item, boughtout: true },
        },
        {},
        0
      )
        .then((res: any) => {
          getCategoryData(categoryName);
          Swal.fire({
            html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />

      <br />

      <p style="color:#007fff;">Moved Successfully!</p>   

       </div>`,
          });
          onCloseModal();
          onCloseParent();
        })
        .catch((err: any) => { });
    },
  });
  React.useEffect(() => {
    if (actions !== "add") {
      formik.setFieldValue("code", actions?.code || "");
      formik.setFieldValue("name", actions?.name || "");
      formik.setFieldValue("type", actions?.type || "");
      formik.setFieldValue("grade", actions?.grade || "");
      formik.setFieldValue("material", actions?.material || "");
      formik.setFieldValue("m_size", actions?.m_size || "");
      formik.setFieldValue("length", actions?.length || "");
      formik.setFieldValue("weight", actions?.weight || "");
      formik.setFieldValue("thread_length", actions?.thread_length || "");
      formik.setFieldValue("reference", actions?.reference || "");
      formik.setFieldValue("unit_cost", parseFloat(actions.unit_cost?.slice(1)) || "");
    }
  }, [actions]);
  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: "flex", alignItems: "flex-start", columnGap: "1rem", marginBottom: "0.5rem" }}>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="Item Code"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="code"
              name="code"
              onChange={formik.handleChange}
              value={formik.values.code}
              multiline
              error={formik.touched.code && Boolean(formik.errors.code)}
              helperText={formik.touched.code && formik.errors.code}
            />
          </FormControl>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="Item Name"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="name"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              multiline
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </FormControl>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', columnGap: '1rem', marginBottom: '0.5rem' }}>

          <FormControl
            fullWidth
            variant="standard">
            <InputLabel
              sx={{ color: "primary.main" }}
              shrink
              id="demo-simple-select-label">
              Select type of fasteners{" "}
            </InputLabel>
            <Select
              id="type"
              name="type"
              className={classes.select}
              labelId="demo-select-small"
              // id="demo-select-small"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                  fontSize: "1rem",
                },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
              error={formik.touched.type && Boolean(formik.errors.type)}
              //@ts-ignore
              helperText={formik.touched.type && formik.errors.type}
              MenuProps={{
                style: {
                  maxHeight: 400,
                  color: "007fff",
                },
              }}
              onChange={formik.handleChange}
              value={formik.values.type !== null ? formik.values.type : ""}>
              {/* <option value={""} style={{ display: "none" }}></option> */}
              {["Bolt", "Nut", "Clamp", "Screw", "Rivet", "Washer", "Eye Bolt", "Universal Joint", "Push Clip", "Stud", "Pin", "CIRCLIP"].map((item: any, index: any) => {
                return (
                  <MenuItem
                    sx={{ color: "primary.main", fontSize: "1rem" }}
                    key={index}
                    value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
            <div className={styles.errormsg}>{formik.touched.type && Boolean(formik.errors.type) && <p>{formik.errors.type}</p>}</div>
          </FormControl>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="Grade"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="grade"
              name="grade"
              onChange={formik.handleChange}
              value={formik.values.grade}
              multiline
              error={formik.touched.grade && Boolean(formik.errors.grade)}
              helperText={formik.touched.grade && formik.errors.grade}
            />
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-start", columnGap: "1rem", marginBottom: "0.5rem" }}>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="Material"
              multiline
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="material"
              name="material"
              onChange={formik.handleChange}
              value={formik.values.material}
              error={formik.touched.material && Boolean(formik.errors.material)}
              helperText={formik.touched.material && formik.errors.material}
            />
          </FormControl>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="M-Size"
              type="number"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="m_size"
              name="m_size"
              onChange={formik.handleChange}
              value={formik.values.m_size}
              error={formik.touched.m_size && Boolean(formik.errors.m_size)}
              helperText={formik.touched.m_size && formik.errors.m_size}
            />
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-start", columnGap: "1rem", marginBottom: "0.5rem" }}>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="Length"
              type="number"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="length"
              name="length"
              onChange={formik.handleChange}
              value={formik.values.length}
              error={formik.touched.length && Boolean(formik.errors.length)}
              helperText={formik.touched.length && formik.errors.length}
            />
          </FormControl>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="Thread Length"
              type="number"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="thread_length"
              name="thread_length"
              onChange={formik.handleChange}
              value={formik.values.thread_length}
              error={formik.touched.thread_length && Boolean(formik.errors.thread_length)}
              helperText={formik.touched.thread_length && formik.errors.thread_length}
            />
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-start", columnGap: "1rem", marginBottom: "0.5rem" }}>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="Weight(g)"
              type="number"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="weight"
              name="weight"
              onChange={formik.handleChange}
              value={formik.values.weight}
              error={formik.touched.weight && Boolean(formik.errors.weight)}
              helperText={formik.touched.weight && formik.errors.weight}
            />
          </FormControl>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="Per unit cost (INR)"
              type="number"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="unit_cost"
              name="unit_cost"
              onChange={formik.handleChange}
              value={formik.values.unit_cost}
              error={formik.touched.unit_cost && Boolean(formik.errors.unit_cost)}
              helperText={formik.touched.unit_cost && formik.errors.unit_cost}
            />
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-start", columnGap: "1rem", marginBottom: "0.5rem" }}>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="Source"
              multiline
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="reference"
              name="reference"
              onChange={formik.handleChange}
              value={formik.values.reference}
              error={formik.touched.reference && Boolean(formik.errors.reference)}
              helperText={formik.touched.reference && formik.errors.reference}
            />
          </FormControl>
        </Box>
        <Box sx={{ width: "100%", textAlign: "right" }}>
          <LoadingButton
            size="small"
            variant="contained"
            sx={{
              padding: "0.5rem",
              lineHeight: 1,
              marginTop: "1rem",
              "&:hover": {
                transform: "Scale(1.05)",
                transition: "transform 0.5s ease",
              },
              ".MuiCircularProgress-root": {
                color: "primary.main",
              },
            }}
            loading={isLoadingsave}
            type="submit"
          >
            Move
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
}
