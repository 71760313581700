import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  MouseEvent,
} from "react";
import styles from "./SuppliersLandingPage.module.scss";
import {
  
  Autocomplete,
  Badge,
  Box,
  Button,
  Collapse,
  Divider,
  Drawer,
  FormControlLabel,
  IconButton,
  InputBase,
  Link,
  Pagination,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
  ListItemText,
  Popover,
  ListItemIcon,
  CircularProgress,
  List,
  ListItem,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Switch, { SwitchProps } from "@mui/material/Switch";
import AddIcon from "@mui/icons-material/Add";
import SuppliersDrawer from "./SuppliersDrawer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { API } from "../../api-services";
import RViewerJS from "viewerjs-react";
import InfiniteScroll from "react-infinite-scroll-component";
import Avatar from "@mui/material/Avatar";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Rating from "@mui/material/Rating";
import EditIcon from "@mui/icons-material/Edit";
import PushPinIcon from "@mui/icons-material/PushPin";
import DeleteIcon from "@mui/icons-material/Delete";
import useDebounce from "./useDebounce";
import useFetchSuppliers from "./useFetchSuppliers";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import AddSupplierDocumentModal from "./AddSupplierDocumentModal";
import CreatePartForm from "./CreatePartForm";
import AddSupplierModal from "./AddSupplierModal";
import CDN_URL from "../../utlis/CDN_URL";
import Swal from "sweetalert2";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import SupplierContactEditModal from "./SupplierContactEditModal";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import UploadIcon from "@mui/icons-material/Upload";
import useImageUpload from "./useImageUpload";
import ImageUpload from "./useImageUpload";
import SupplierContactAddModal from "./SupplierContactAddModal";
import GeneralSupplierInfoEdit from "./GeneralSupplierInfoEdit";
import ClaimBusinessFormNew from "./CreateSupplier/ClaimBusinessFormNew";
import OwnPartList from "./OwnPartList";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";

import SupplierUI from "./SupplierUI";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { useHistory } from "react-router-dom";

function NewSupplierUI() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorEll, setAnchorEll] = useState<null | HTMLElement>(null);
  const [filterOption, setFilterOption] = useState<any>({
    part: null,
    msp: null,
    verified: null,
    unverified: null,
  });
  const [recycleCount, setRecycleCount] = useState<any>(0);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<
    "verified_at" | "known_parts" | "business_name" | "potential_parts"
  >("verified_at");
  const [supplierDetails, setSupplierDetails] = useState<any>();
  const [supplierId, setSupplierId] = useState<any>();
  const [hasMore, setHasMore] = useState<boolean>(true);
  const OuterRef = React.useRef<any>();
  const descRef = React.useRef<any>();
  const history = useHistory();
  const [ids, setIds] = useState<any>({
    vcategoryId: null,
    bomCategoryId: null,
  });
  const [supplierList, setSupplierList] = useState<any>([]);
  const [search, setSearch] = React.useState<any>("");
  const [page, setPage] = useState<number>(1);
  const [state, setState] = useState({
    top: false,
    left: true,
    bottom: false,
    right: false,
  });
  const devouncedVal = useDebounce(search);
  const { loading, suppliers, suppliersCount } = useFetchSuppliers(
    page,
    devouncedVal,
    ids?.vcategoryId,
    ids?.bomCategoryId,
    filterOption.part,
    filterOption.msp,
    filterOption.verified ? true : filterOption.unverified ? false : null
  );

  const getSupplierDetails = async (id: any) => {
    const result = await API.get(`supplier/supplier_db/`, {
      id: id,
    });
console.log(result?.data?.[0]);

     setSupplierDetails(result?.data?.[0]);
  };

  const getRedirectedData = async () => {
    try {
      // Fetch HTML content from the API endpoint
      const response = await API.get(
        `/db/show_add_business_form/`,
        { internal_page: true },
        0
      );
      const data = await response.data;

      if (data) {
        // history.push("/data-base/add-business/0");
        history.push("/data-base/add-business/0");
      } else {
        history.push("/data-base/add-business/0?manual=true");
      }
    } catch (error: any) {
      if (error?.response) {
        // The error object contains a response property
        const { data } = error?.response;
        Swal.fire({
          icon: "error",
          title: "Error!",
          html: `<div>
              <p style="color:"red">${data[0]}</p>
              </div>`,
        });
        console.error("Server Error:", error?.response);
      }
    }
  };

  const fetchSuppliers = () => {
    // setPage(1);
    // setSearch(""); 
 
    // setSupplierList([]);

    let hash = window.location.hash;
            let parts = hash.split("/");
            
            parts[4] = "0";
            parts[5] = "0"
          
            
           
            let newHash = parts.join("/");
            window.location.hash = newHash;

            setIds({
              vcategoryId: null,
              bomCategoryId: null,
            })
  };

  const handleOnChange = async (event: any) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    if (suppliers.length > 0) {
      setSupplierList((prev: any) => [...prev, ...suppliers]); // Append new suppliers
      setHasMore(suppliers.length > 0); // Update hasMore based on the response
    } else {
      setHasMore(false); // No more data to load
    }
  }, [suppliers]);

  useEffect(() => {
    const hash = window.location.hash;
    const parts = hash.split("/");
    if (parts[6] !== "0") {
      setSupplierId(parseInt(parts[6]));
    }
  }, [window.location.hash]);



  useEffect(() => {
   
    
    setPage(1);
    setSupplierList([]);
  }, [devouncedVal, ids, filterOption]);

  const loadMoreSuppliers = () => {
    if (!loading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const editSupplier = (id: any) => {
  

    window.open(`/#/data-base/add-business/${id}?su`);
    //  history.push(`/`);
  };

  const verifySupplier = () => {
    API.put(`supplier/supplier_db/${supplierId}/`, {
      verified: true,
    })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Supplier verified successfully",
        }).then(() => {
          // This code will execute after the user clicks the "OK" button
          getSupplierDetails(supplierId);
        });
      })

      .catch((err: any) => {
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
        });
      });
  };

  const getTrashCount = () => {
    API.get(`auth/mui_trash_view/`, { bin_type: "supplierdatabase", count:true }, 0)
    .then((res: any) => {
    if (res.data && res.data?.count) {
      
      
      setRecycleCount(res.data?.count);
    }
    })
    .catch((err: any) => {
     
      console.log(err, "error");
    });
  }

  useEffect(() => {
    if (supplierId) {
      getSupplierDetails(supplierId);
    }
   
    
  }, [supplierId]);




  useEffect(() => {
    getTrashCount()
   
  }, []);



    // -------------Filter------------------

    const handleClick = (event: MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);

    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
  
  
    const menuItems = [
      { text: "Sellers without parts" },
      { text: "Sellers with parts not having MSP" },
      { text: "Verified Sellers" },
      { text: "Unverified Sellers" },
    ];
  
    // -------------Sort------------------
  
    const handleClickSort = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEll(event.currentTarget);
    };
  
    const handleCloseSort = () => {
      setAnchorEll(null);
    };
  
    const handleSortChange = (sortOption: any) => {
      handleCloseSort();
    };
  
    const handleRequestSort = (
      property:
        | "verified_at"
        | "known_parts"
        | "business_name"
        | "potential_parts",
      order?: any
    ) => {
      setOrder(order ? order : "asc");
      setOrderBy(property);
      setAnchorEll(null);
    };
  
    // Sorting logic
  
    const sortedSuppliers = React.useMemo(() => {
      return [...supplierList]?.sort((a, b) => {
        let aValue: any, bValue: any;
  
        if (orderBy === "verified_at") {
          aValue = a?.verified_at ? new Date(a.verified_at).getTime() : Infinity;
          bValue = b?.verified_at ? new Date(b.validated_at).getTime() : Infinity;
        } else if (orderBy === "known_parts") {
          aValue = a?.known_parts != null ? a.known_parts : Infinity;
          bValue = b?.known_parts != null ? b.known_parts : Infinity;
        } else if (orderBy === "potential_parts") {
          aValue = a?.potential_parts != null ? a.potential_parts : Infinity;
          bValue = b?.potential_parts != null ? b.potential_parts : Infinity;
        } else if (orderBy === "business_name") {
          aValue = a?.business_name?.toLowerCase() || ""; // Fallback to empty string if undefined
          bValue = b?.business_name?.toLowerCase() || ""; // Fallback to empty string if undefined
        }
  
        if (aValue < bValue) {
          return order === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return order === "asc" ? 1 : -1;
        }
        return 0;
      });
    }, [supplierList, order, orderBy]);
  
    const open = Boolean(anchorEll);
    const id = open ? "simple-popover" : undefined;
  

  
    


  return (
    <div style={{ height: "100%", overflowY: "scroll" }}>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0.2rem 1rem",
            marginBottom: "1rem",
            paddingTop:"0.5rem"
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "1.5rem" }}>

<Box 
      sx={{ cursor: 'pointer', position: 'relative' }} 
      onClick={()=> {
        //history.push("/recyclebin/supplier");
   
       window.open("/#/supplier/recyclebin/supplier", "_blank");
      
   }} 
    >
      <Badge 
        badgeContent={recycleCount || 0} 
        color="error" 
        sx={{ 
          "& .MuiBadge-dot": {
            backgroundColor: "#00887a",
          },
          // Adjust badge position
          "& .MuiBadge-root": {
            top: '20%', // Move it down
            right: '-10%', // Adjust right positioning as needed
          },
        }}
      >
        <DeleteIcon sx={{ color: "#00887a", fontSize: "2rem" }} /> {/* Adjust icon size if needed */}
      </Badge>
    </Box>

            <Button
              variant="contained"
              color="primary"
              sx={{ fontSize: "0.8rem", borderRadius: "0.5rem", textTransform: "none" }}
              onClick={getRedirectedData}
            >
              Add Manufacturer
            </Button>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <Box
          sx={{
            width: "100%",
            paddingRight: "1rem",
            display: "flex",
            gap: "0.5%",
          }}
        >
          <Box sx={{ height: { width: "24%" } }}>
            <SuppliersDrawer
              fetchSuppliers={fetchSuppliers}
              setSupplierList={setSupplierList}
              onClose={() => setState({ ...state, left: false })}
              getIds={(vcategoryId: number, bomCategoryId: number) =>
                setIds({
                  vcategoryId: vcategoryId,
                  bomCategoryId: bomCategoryId,
                })
              }
            />

            {supplierList?.length > 0 ? (
              <>
                <Box sx={{ position: "relative", padding: "0.24rem 0.1rem" }}>
                  <input
                    type="search"
                    placeholder="Search"
                    value={search}
                    style={{
                      color: "#00887a",
                      border: "0.001px solid grey",
                      width: "100%", // Ensure it takes full width
                      padding: "0.5rem", // Add padding for better UI
                    }}
                    onChange={handleOnChange}
                  />
                </Box>

                <Box
                  sx={{
                    backgroundColor: "primary.main",
                    padding: "0.5rem",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      marginTop: "0.5rem",
                    }}
                  >
                    Manufacturer Name
                  </Typography>



                  <Box sx={{ display: "flex" }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.2rem",
                          padding: "0.15rem 0.25rem",
                          cursor: "pointer",
                          borderRadius: "0.2rem",
                          "&:hover": {
                            background: "#38abab",
                            borderRadius: "0.2rem",
                            boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                          },
                          background: anchorEl ? "#38abab" : "primary.main",
                          boxShadow: anchorEl
                            ? "rgba(0, 0, 0, 0.1) 0px 4px 12px"
                            : "primary.main",
                        }}
                        onClick={(e: any) => handleClick(e)}
                      >
                        <FilterAltIcon
                          sx={{ color: "#fff", cursor: "pointer" }}
                        />
                        <Typography sx={{ color: "#fff" }}>Filter</Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.2rem",
                          padding: "0.15rem 0.25rem",
                          cursor: "pointer",
                          borderRadius: "0.2rem",
                          "&:hover": {
                            background: "#38abab",
                            borderRadius: "0.2rem",
                            boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                          },
                          background: anchorEll ? "#38abab" : "primary.main",
                          boxShadow: anchorEll
                            ? "rgba(0, 0, 0, 0.1) 0px 4px 12px"
                            : "primary.main",
                        }}
                        onClick={(e: any) => handleClickSort(e)}
                      >
                        <FilterListIcon
                          sx={{ color: "#fff", cursor: "pointer" }}
                        />
                        <Typography sx={{ color: "#fff" }}>Sort</Typography>
                      </Box>
                    </Box>

                    <Menu
                      id="filter-menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                     

                      <MenuItem
                      onClick={()=> setFilterOption({...filterOption, part: filterOption.part === true ? null : true})}
                        selected={filterOption.part}
                        sx={{  backgroundColor:
                          filterOption?.part === true
                            ? "#008080"
                            : "inherit", // Overrides the class style
                        color:
                          filterOption?.part === true ? "#fff" : "inherit", // Adjust text color if necessary
                          "&.Mui-selected": {
                            backgroundColor:
                              filterOption?.part === true
                                ? "#008080"
                                : "inherit", // Overrides the class style
                            color:
                              filterOption?.part === true ? "#fff" : "inherit", // Adjust text color if necessary
                          },
                          "&:hover": {
                            backgroundColor: "inherit", // No hover background effect
                            color: "inherit", // No hover text color change
                          },
                        }}
                      >
                        <ListItemText primary={`Sellers without parts (${suppliersCount?.supplier_without_parts})`} />
                      </MenuItem>

                      <MenuItem
                      onClick={()=> setFilterOption({...filterOption, msp: filterOption.msp === true ? null : true})}
                        selected={filterOption.msp}
                        sx={{  backgroundColor:
                          filterOption?.msp === true
                            ? "#008080"
                            : "inherit", // Overrides the class style
                        color:
                          filterOption?.msp === true ? "#fff" : "inherit", // Adjust text color if necessary
                          "&.Mui-selected": {
                            backgroundColor:
                              filterOption?.msp === true
                                ? "#008080"
                                : "inherit", // Overrides the class style
                            color:
                              filterOption?.msp === true ? "#fff" : "inherit", // Adjust text color if necessary
                          },
                          "&:hover": {
                            backgroundColor: "inherit", // No hover background effect
                            color: "inherit", // No hover text color change
                          },
                        }}
                      >
                        <ListItemText primary={`Sellers with parts not having MSP (${suppliersCount?.supplier_count_without_msp})`} />
                      </MenuItem>

                      <MenuItem
                      onClick={()=> {
                        if (filterOption.unverified === true) {
                          setFilterOption({...filterOption, verified: filterOption.verified === true ? null : true, unverified:null})
                        } else{
                          setFilterOption({...filterOption, verified: filterOption.verified === true ? null : true})
                        }

                        // setFilterOption({...filterOption, verified: !filterOption.verified, unverified:filterOption.verified ===  })
                      }}
                        selected={filterOption.verified}
                        sx={{  backgroundColor:
                          filterOption?.verified === true
                            ? "#008080"
                            : "inherit", // Overrides the class style
                        color:
                          filterOption?.verified === true ? "#fff" : "inherit", // Adjust text color if necessary
                          "&.Mui-selected": {
                            backgroundColor:
                              filterOption?.verified === true
                                ? "#008080"
                                : "inherit", // Overrides the class style
                            color:
                              filterOption?.verified === true ? "#fff" : "inherit", // Adjust text color if necessary
                          },
                          "&:hover": {
                            backgroundColor: "inherit", // No hover background effect
                            color: "inherit", // No hover text color change
                          },
                        }}
                      >
                        <ListItemText primary={`Verified Sellers (${suppliersCount?.verified_supplier_count})`} />
                      </MenuItem>

                      <MenuItem
                      onClick={()=> {
                        if (filterOption.verified === true) {
                          setFilterOption({...filterOption, unverified: filterOption.unverified === true ? null : true,verified:null })
                        } else {
                          setFilterOption({...filterOption, unverified: filterOption.unverified === true ? null : true, })
                        }
                      }}
                        selected={filterOption.unverified}
                        sx={{  backgroundColor:
                          filterOption?.unverified === true
                            ? "#008080"
                            : "inherit", // Overrides the class style
                        color:
                          filterOption?.unverified === true ? "#fff" : "inherit", // Adjust text color if necessary
                          "&.Mui-selected": {
                            backgroundColor:
                              filterOption?.unverified === true
                                ? "#008080"
                                : "inherit", // Overrides the class style
                            color:
                              filterOption?.unverified === true ? "#fff" : "inherit", // Adjust text color if necessary
                          },
                          "&:hover": {
                            backgroundColor: "inherit", // No hover background effect
                            color: "inherit", // No hover text color change
                          },
                        }}
                      >
                        <ListItemText primary={`Unverified Sellers (${suppliersCount?.unverified_supplier_count})`} />
                      </MenuItem>

                      
                    </Menu>

                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEll}
                      onClose={handleCloseSort}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <Typography variant="h6" sx={{ padding: 1 }}>
                        Sort By
                      </Typography>
                      {/* Date Sorting */}
                      <MenuItem
                        onClick={() => handleRequestSort("verified_at", "asc")}
                        sx={{  backgroundColor:
                          (orderBy === "verified_at" && order === "asc")
                            ? "#008080"
                            : "inherit", // Overrides the class style
                        color:
                        (orderBy === "verified_at" && order === "asc") ? "#fff" : "inherit", // Adjust text color if necessary
                          "&.Mui-selected": {
                            backgroundColor:
                               (orderBy === "verified_at" && order === "asc")
                                ? "#008080"
                                : "inherit", // Overrides the class style
                            color:
                               (orderBy === "verified_at" && order === "asc") ? "#fff" : "inherit", // Adjust text color if necessary
                          },
                          
                        }}
                      >
                        <ListItemIcon sx={{color:
                        (orderBy === "verified_at" && order === "asc") ? "#fff" : "inherit", // Adjust text color if necessary
                          "&.Mui-selected": {
                            backgroundColor:
                               (orderBy === "verified_at" && order === "asc")
                                ? "#008080"
                                : "inherit", // Overrides the class style
                            color:
                               (orderBy === "verified_at" && order === "asc") ? "#fff" : "inherit", // Adjust text color if necessary
                          },}}>
                          <CalendarTodayIcon sx={{color:
                        (orderBy === "verified_at" && order === "asc") ? "#fff" : "inherit", // Adjust text color if necessary
                          "&.Mui-selected": {
                            backgroundColor:
                               (orderBy === "verified_at" && order === "asc")
                                ? "#008080"
                                : "inherit", // Overrides the class style
                            color:
                               (orderBy === "verified_at" && order === "asc") ? "#fff" : "inherit", // Adjust text color if necessary
                          },}} />
                        </ListItemIcon>
                        <ListItemText
                          primary="Verified Date"
                          secondary="Ascending"
                        />
                        <ListItemIcon>
                          <ArrowUpwardIcon sx={{color:
                        (orderBy === "verified_at" && order === "asc") ? "#fff" : "inherit", // Adjust text color if necessary
                          "&.Mui-selected": {
                            backgroundColor:
                               (orderBy === "verified_at" && order === "asc")
                                ? "#008080"
                                : "inherit", // Overrides the class style
                            color:
                               (orderBy === "verified_at" && order === "asc") ? "#fff" : "inherit", // Adjust text color if necessary
                          },}} />
                        </ListItemIcon>
                      </MenuItem>
                      <MenuItem
                      sx={{  backgroundColor:
                        (orderBy === "verified_at" && order === "desc")
                          ? "#008080"
                          : "inherit", // Overrides the class style
                      color:
                      (orderBy === "verified_at" && order === "desc") ? "#fff" : "inherit", // Adjust text color if necessary
                        "&.Mui-selected": {
                          backgroundColor:
                             (orderBy === "verified_at" && order === "desc")
                              ? "#008080"
                              : "inherit", // Overrides the class style
                          color:
                             (orderBy === "verified_at" && order === "desc") ? "#fff" : "inherit", // Adjust text color if necessary
                        },
                      }}
                        onClick={() => handleRequestSort("verified_at", "desc")}
                      >
                        <ListItemIcon>
                          <CalendarTodayIcon sx={{backgroundColor:
                        (orderBy === "verified_at" && order === "desc")
                          ? "#008080"
                          : "inherit", // Overrides the class style
                      color:
                      (orderBy === "verified_at" && order === "desc") ? "#fff" : "inherit",}} />
                        </ListItemIcon>
                        <ListItemText
                          primary="Verified Date"
                          secondary="Descending"
                          
                        />
                        <ListItemIcon>
                          <ArrowDownwardIcon  sx={{backgroundColor:
                        (orderBy === "verified_at" && order === "desc")
                          ? "#008080"
                          : "inherit", // Overrides the class style
                      color:
                      (orderBy === "verified_at" && order === "desc") ? "#fff" : "inherit",}} />
                        </ListItemIcon>
                      </MenuItem>
                      <Divider sx={{ color: "grey" }} />

                      {/* Known Parts Sorting */}
                      <MenuItem
                      sx={{  backgroundColor:
                        (orderBy === "known_parts" && order === "asc")
                          ? "#008080"
                          : "inherit", // Overrides the class style
                      color:
                      (orderBy === "known_parts" && order === "asc") ? "#fff" : "inherit", // Adjust text color if necessary
                        "&.Mui-selected": {
                          backgroundColor:
                             (orderBy === "known_parts" && order === "asc")
                              ? "#008080"
                              : "inherit", // Overrides the class style
                          color:
                             (orderBy === "known_parts" && order === "asc") ? "#fff" : "inherit", // Adjust text color if necessary
                        },
                      }}
                        onClick={() => handleRequestSort("known_parts", "asc")}
                      >
                        <ListItemIcon>
                          <ListAltIcon sx={{backgroundColor:
                        (orderBy === "known_parts" && order === "asc")
                          ? "#008080"
                          : "inherit", // Overrides the class style
                      color:
                      (orderBy === "known_parts" && order === "asc") ? "#fff" : "inherit",}} />
                        </ListItemIcon>
                        <ListItemText
                          primary="Known Parts Count"
                          secondary="Ascending"
                        />
                        <ListItemIcon>
                          <ArrowUpwardIcon sx={{backgroundColor:
                        (orderBy === "known_parts" && order === "asc")
                          ? "#008080"
                          : "inherit", // Overrides the class style
                      color:
                      (orderBy === "known_parts" && order === "asc") ? "#fff" : "inherit",}} />
                        </ListItemIcon>
                      </MenuItem>
                      <MenuItem
                      sx={{  backgroundColor:
                        (orderBy === "known_parts" && order === "desc")
                          ? "#008080"
                          : "inherit", // Overrides the class style
                      color:
                      (orderBy === "known_parts" && order === "desc") ? "#fff" : "inherit", // Adjust text color if necessary
                        "&.Mui-selected": {
                          backgroundColor:
                             (orderBy === "known_parts" && order === "desc")
                              ? "#008080"
                              : "inherit", // Overrides the class style
                          color:
                             (orderBy === "known_parts" && order === "desc") ? "#fff" : "inherit", // Adjust text color if necessary
                        },
                      }}
                        onClick={() => handleRequestSort("known_parts", "desc")}
                      >
                        <ListItemIcon>
                          <ListAltIcon  sx={{backgroundColor:
                        (orderBy === "known_parts" && order === "desc")
                          ? "#008080"
                          : "inherit", // Overrides the class style
                      color:
                      (orderBy === "known_parts" && order === "desc") ? "#fff" : "inherit",}}/>
                        </ListItemIcon>
                        <ListItemText
                          primary="Known Parts Count"
                          secondary="Descending"
                          sx={{
                          color:
                          (orderBy === "known_parts" && order === "desc") ? "#fff" : "inherit",}}
                        />
                        <ListItemIcon>
                          <ArrowDownwardIcon  sx={{backgroundColor:
                        (orderBy === "known_parts" && order === "desc")
                          ? "#008080"
                          : "inherit", // Overrides the class style
                      color:
                      (orderBy === "known_parts" && order === "desc") ? "#fff" : "inherit",}}/>
                        </ListItemIcon>
                      </MenuItem>

                      {/*  Potential Sorting */}
                      <MenuItem
                      sx={{  backgroundColor:
                        (orderBy === "potential_parts" && order === "asc")
                          ? "#008080"
                          : "inherit", // Overrides the class style
                      color:
                      (orderBy === "potential_parts" && order === "asc") ? "#fff" : "inherit", // Adjust text color if necessary
                        "&.Mui-selected": {
                          backgroundColor:
                             (orderBy === "potential_parts" && order === "asc")
                              ? "#008080"
                              : "inherit", // Overrides the class style
                          color:
                             (orderBy === "potential_parts" && order === "asc") ? "#fff" : "inherit", // Adjust text color if necessary
                        },
                      }}
                        onClick={() =>
                          handleRequestSort("potential_parts", "asc")
                        }
                      >
                        <ListItemIcon>
                          <ListAltIcon  sx={{color:
                      (orderBy === "potential_parts" && order === "asc") ? "#fff" : "inherit",}} />
                        </ListItemIcon>
                        <ListItemText
                          primary="Potential Parts Count"
                          secondary="Ascending"
                        />
                        <ListItemIcon>
                          <ArrowUpwardIcon sx={{color:
                      (orderBy === "potential_parts" && order === "asc") ? "#fff" : "inherit",}} />
                        </ListItemIcon>
                      </MenuItem>
                      <MenuItem
                        sx={{  backgroundColor:
                          (orderBy === "potential_parts" && order === "desc")
                            ? "#008080"
                            : "inherit", // Overrides the class style
                        color:
                        (orderBy === "potential_parts" && order === "desc") ? "#fff" : "inherit", // Adjust text color if necessary
                          "&.Mui-selected": {
                            backgroundColor:
                               (orderBy === "potential_parts" && order === "desc")
                                ? "#008080"
                                : "inherit", // Overrides the class style
                            color:
                               (orderBy === "potential_parts" && order === "desc") ? "#fff" : "inherit", // Adjust text color if necessary
                          },
                        }}
                          
                        onClick={() =>
                          handleRequestSort("potential_parts", "desc")
                        }
                      >
                        <ListItemIcon>
                          <ListAltIcon sx={{color:
                      (orderBy === "potential_parts" && order === "desc") ? "#fff" : "inherit",}} />
                        </ListItemIcon>
                        <ListItemText
                          primary="Potential Parts Count"
                          secondary="Descending"
                        />
                        <ListItemIcon>
                          <ArrowDownwardIcon sx={{color:
                      (orderBy === "potential_parts" && order === "desc") ? "#fff" : "inherit",}} />
                        </ListItemIcon>
                      </MenuItem>

                      {/* Name Sorting */}
                      <MenuItem
                      sx={{  backgroundColor:
                        (orderBy === "business_name" && order === "asc")
                          ? "#008080"
                          : "inherit", // Overrides the class style
                      color:
                      (orderBy === "business_name" && order === "asc") ? "#fff" : "inherit", // Adjust text color if necessary
                        "&.Mui-selected": {
                          backgroundColor:
                             (orderBy === "business_name" && order === "asc")
                              ? "#008080"
                              : "inherit", // Overrides the class style
                          color:
                             (orderBy === "business_name" && order === "asc") ? "#fff" : "inherit", // Adjust text color if necessary
                        },
                      }}
                        onClick={() =>
                          handleRequestSort("business_name", "asc")
                        }
                      >
                        <ListItemIcon>
                          <ListAltIcon sx={{color:
                      (orderBy === "business_name" && order === "asc") ? "#fff" : "inherit", }}/>
                        </ListItemIcon>
                        <ListItemText primary="Name" secondary="Ascending" />
                        <ListItemIcon>
                          <ArrowUpwardIcon sx={{color:
                      (orderBy === "business_name" && order === "asc") ? "#fff" : "inherit", }} />
                        </ListItemIcon>
                      </MenuItem>
                      <MenuItem
                      sx={{  backgroundColor:
                        (orderBy === "business_name" && order === "desc")
                          ? "#008080"
                          : "inherit", // Overrides the class style
                      color:
                      (orderBy === "business_name" && order === "desc") ? "#fff" : "inherit", // Adjust text color if necessary
                        "&.Mui-selected": {
                          backgroundColor:
                             (orderBy === "business_name" && order === "desc")
                              ? "#008080"
                              : "inherit", // Overrides the class style
                          color:
                             (orderBy === "business_name" && order === "desc") ? "#fff" : "inherit", // Adjust text color if necessary
                        },
                      }}
                        onClick={() =>
                          handleRequestSort("business_name", "desc")
                        }
                      >
                        <ListItemIcon>
                          <ListAltIcon sx={{ color:
                      (orderBy === "business_name" && order === "desc") ? "#fff" : "inherit", }} />
                        </ListItemIcon>
                        <ListItemText primary="Name" secondary="Descending" />
                        <ListItemIcon>
                          <ArrowDownwardIcon  sx={{ color:
                      (orderBy === "business_name" && order === "desc") ? "#fff" : "inherit", }}/>
                        </ListItemIcon>
                      </MenuItem>
                    </Popover>
                </Box>
                <Box
                  sx={{
                    height: { lg: "80vh", xl: "85vh" },
                    width: "100%",
                    overflowY: "auto",
                  }}
                  id="scrollableDiv"
                >
                  <InfiniteScroll
                    dataLength={supplierList?.length}
                    next={loadMoreSuppliers}
                    hasMore={hasMore}
                    loader={<></>}
                    endMessage={
                      <p style={{ textAlign: "center" }}>No more suppliers</p>
                    }
                    scrollableTarget="scrollableDiv" // Set the target to the Box's ID
                  >
                    {/* @ts-ignore */}
                    <RViewerJS>
                      {/* {supplierList.map((supplier: any, index: any) => (
                        <ListItem key={index}>
                          {supplier?.business_name}
                        
                        </ListItem>
                      ))} */}

                      {sortedSuppliers &&
                        sortedSuppliers?.map((item: any, index: any) => {
                          return (
                            <Box
                              key={item?.id}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "0.5rem",
                                backgroundColor:
                                  item?.id === supplierId
                                    ? "primary.light"
                                    : "#fff",
                                cursor: "pointer",
                                borderBottomColor: "primary.light",
                                width: "100%",
                                height: "fit-content",
                                borderBottom: "0.1px solid #7ABA78",
                                ":hover": {
                                  backgroundColor: "primary.light",
                                },
                              }}
                              onClick={() => {
                                setSupplierId(item?.id);
                                let hash = window.location.hash;
                                let parts = hash.split("/");

                                parts[6] = item?.id;
                                let newHash = parts.join("/");
                                window.location.hash = newHash;
                              }}
                            >
                              <Box>
                                <Typography
                                  sx={{
                                    // textDecoration: "underline",
                                    // cursor: "pointer",
                                    fontSize: "0.9rem",

                                    width: "fit-content",
                                  }}
                                  onClick={() => {
                                    setSupplierId(item?.id);
                                    let hash = window.location.hash;
                                    let parts = hash.split("/");

                                    parts[6] = item?.id;
                                    let newHash = parts.join("/");
                                    window.location.hash = newHash;
                                  }}
                                >
                                  {item?.business_name}
                                </Typography>

                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "1rem",
                                    marginTop: "0.25rem",
                                  }}
                                >
                                  <Tooltip
                                    title={`Total Known Parts: ${
                                      item?.known_parts || 0
                                    } | Known Parts with MSP: ${
                                      item?.known_parts_with_msp || 0
                                    }`}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "0.88rem",
                                        color: "#3a3d3d",
                                      }}
                                    >
                                      Known parts:{" "}
                                      <span
                                        style={{
                                          color: "#3b9652",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {item?.known_parts || 0}
                                      </span>
                                      {" | "}
                                      <span
                                        style={{
                                          color: "red",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {item?.known_parts_with_msp || 0}
                                      </span>
                                    </Typography>
                                  </Tooltip>

                                  <Tooltip
                                    title={`Total Potential Parts: ${
                                      item?.potential_parts || 0
                                    } | Potential Parts with MSP: ${
                                      item?.potential_parts_with_msp || 0
                                    }`}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "0.88rem",
                                        color: "#3a3d3d",
                                      }}
                                    >
                                      Potential parts:{" "}
                                      <span
                                        style={{
                                          color: "#3b9652",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {item?.potential_parts || 0}
                                      </span>
                                      {" | "}
                                      <span
                                        style={{
                                          color: "red",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {item?.potential_parts_with_msp || 0}
                                      </span>
                                    </Typography>
                                  </Tooltip>
                                </Box>
                              </Box>

                              <EditIcon
                                sx={{ color: "#9b9e9d", cursor: "pointer" }}
                                onClick={() => {

                                  editSupplier(item?.id);
                                }}
                              />
                            </Box>
                          );
                        })}
                    </RViewerJS>
                  </InfiniteScroll>
                </Box>
              </>
            ) : (
              <>
               <Box sx={{ position: "relative", padding: "0.24rem 0.1rem" }}>
                  <input
                    type="search"
                    placeholder="Search"
                    value={search}
                    style={{
                      color: "#00887a",
                      border: "0.001px solid grey",
                      width: "100%", // Ensure it takes full width
                      padding: "0.5rem", // Add padding for better UI
                    }}
                    onChange={handleOnChange}
                  />
                </Box>

                <Box
                  sx={{
                    backgroundColor: "primary.main",
                    padding: "0.5rem",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      marginTop: "0.5rem",
                    }}
                  >
                    Supplier Name
                  </Typography>



                  <Box sx={{ display: "flex" }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.2rem",
                          padding: "0.15rem 0.25rem",
                          cursor: "pointer",
                          borderRadius: "0.2rem",
                          "&:hover": {
                            background: "#38abab",
                            borderRadius: "0.2rem",
                            boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                          },
                          background: anchorEl ? "#38abab" : "primary.main",
                          boxShadow: anchorEl
                            ? "rgba(0, 0, 0, 0.1) 0px 4px 12px"
                            : "primary.main",
                        }}
                        onClick={(e: any) => handleClick(e)}
                      >
                        <FilterAltIcon
                          sx={{ color: "#fff", cursor: "pointer" }}
                        />
                        <Typography sx={{ color: "#fff" }}>Filter</Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.2rem",
                          padding: "0.15rem 0.25rem",
                          cursor: "pointer",
                          borderRadius: "0.2rem",
                          "&:hover": {
                            background: "#38abab",
                            borderRadius: "0.2rem",
                            boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                          },
                          background: anchorEll ? "#38abab" : "primary.main",
                          boxShadow: anchorEll
                            ? "rgba(0, 0, 0, 0.1) 0px 4px 12px"
                            : "primary.main",
                        }}
                        onClick={(e: any) => handleClickSort(e)}
                      >
                        <FilterListIcon
                          sx={{ color: "#fff", cursor: "pointer" }}
                        />
                        <Typography sx={{ color: "#fff" }}>Sort</Typography>
                      </Box>
                    </Box>

                    <Menu
                      id="filter-menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                     

                      <MenuItem
                      onClick={()=> setFilterOption({...filterOption, part: filterOption.part === true ? null : true})}
                        selected={filterOption.part}
                        sx={{  backgroundColor:
                          filterOption?.part === true
                            ? "#008080"
                            : "inherit", // Overrides the class style
                        color:
                          filterOption?.part === true ? "#fff" : "inherit", // Adjust text color if necessary
                          "&.Mui-selected": {
                            backgroundColor:
                              filterOption?.part === true
                                ? "#008080"
                                : "inherit", // Overrides the class style
                            color:
                              filterOption?.part === true ? "#fff" : "inherit", // Adjust text color if necessary
                          },
                        }}
                      >
                        <ListItemText primary="Sellers without parts" />
                      </MenuItem>

                      <MenuItem
                      onClick={()=> setFilterOption({...filterOption, msp: filterOption.msp === true ? null : true})}
                        selected={filterOption.msp}
                        sx={{  backgroundColor:
                          filterOption?.msp === true
                            ? "#008080"
                            : "inherit", // Overrides the class style
                        color:
                          filterOption?.msp === true ? "#fff" : "inherit", // Adjust text color if necessary
                          "&.Mui-selected": {
                            backgroundColor:
                              filterOption?.msp === true
                                ? "#008080"
                                : "inherit", // Overrides the class style
                            color:
                              filterOption?.msp === true ? "#fff" : "inherit", // Adjust text color if necessary
                          },
                        }}
                      >
                        <ListItemText primary="Sellers with parts not having MSP" />
                      </MenuItem>

                      <MenuItem
                      onClick={()=> {
                        if (filterOption.unverified === true) {
                          setFilterOption({...filterOption, verified: filterOption.verified === true ? null : true, unverified:null})
                        } else{
                          setFilterOption({...filterOption, verified: filterOption.verified === true ? null : true})
                        }

                        // setFilterOption({...filterOption, verified: !filterOption.verified, unverified:filterOption.verified ===  })
                      }}
                        selected={filterOption.verified}
                        sx={{  backgroundColor:
                          filterOption?.verified === true
                            ? "#008080"
                            : "inherit", // Overrides the class style
                        color:
                          filterOption?.verified === true ? "#fff" : "inherit", // Adjust text color if necessary
                          "&.Mui-selected": {
                            backgroundColor:
                              filterOption?.verified === true
                                ? "#008080"
                                : "inherit", // Overrides the class style
                            color:
                              filterOption?.verified === true ? "#fff" : "inherit", // Adjust text color if necessary
                          },
                        }}
                      >
                        <ListItemText primary="Verified Sellers" />
                      </MenuItem>

                      <MenuItem
                      onClick={()=> {
                        if (filterOption.verified === true) {
                          setFilterOption({...filterOption, unverified: filterOption.unverified === true ? null : true,verified:null })
                        } else {
                          setFilterOption({...filterOption, unverified: filterOption.unverified === true ? null : true, })
                        }
                      }}
                        selected={filterOption.unverified}
                        sx={{  backgroundColor:
                          filterOption?.unverified === true
                            ? "#008080"
                            : "inherit", // Overrides the class style
                        color:
                          filterOption?.unverified === true ? "#fff" : "inherit", // Adjust text color if necessary
                          "&.Mui-selected": {
                            backgroundColor:
                              filterOption?.unverified === true
                                ? "#008080"
                                : "inherit", // Overrides the class style
                            color:
                              filterOption?.unverified === true ? "#fff" : "inherit", // Adjust text color if necessary
                          },
                        }}
                      >
                        <ListItemText primary="Unverified Sellers" />
                      </MenuItem>

                      
                    </Menu>

                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEll}
                      onClose={handleCloseSort}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <Typography variant="h6" sx={{ padding: 1 }}>
                        Sort By
                      </Typography>
                      {/* Date Sorting */}
                      <MenuItem
                        onClick={() => handleRequestSort("verified_at", "asc")}
                      >
                        <ListItemIcon>
                          <CalendarTodayIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary="Verified Date"
                          secondary="Ascending"
                        />
                        <ListItemIcon>
                          <ArrowUpwardIcon />
                        </ListItemIcon>
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleRequestSort("verified_at", "desc")}
                      >
                        <ListItemIcon>
                          <CalendarTodayIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary="Verified Date"
                          secondary="Descending"
                        />
                        <ListItemIcon>
                          <ArrowDownwardIcon />
                        </ListItemIcon>
                      </MenuItem>
                      <Divider sx={{ color: "grey" }} />

                      {/* Known Parts Sorting */}
                      <MenuItem
                        onClick={() => handleRequestSort("known_parts", "asc")}
                      >
                        <ListItemIcon>
                          <ListAltIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary="Known Parts Count"
                          secondary="Ascending"
                        />
                        <ListItemIcon>
                          <ArrowUpwardIcon />
                        </ListItemIcon>
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleRequestSort("known_parts", "desc")}
                      >
                        <ListItemIcon>
                          <ListAltIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary="Known Parts Count"
                          secondary="Descending"
                        />
                        <ListItemIcon>
                          <ArrowDownwardIcon />
                        </ListItemIcon>
                      </MenuItem>

                      {/*  Potential Sorting */}
                      <MenuItem
                        onClick={() =>
                          handleRequestSort("potential_parts", "asc")
                        }
                      >
                        <ListItemIcon>
                          <ListAltIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary="Potential Parts Count"
                          secondary="Ascending"
                        />
                        <ListItemIcon>
                          <ArrowUpwardIcon />
                        </ListItemIcon>
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          handleRequestSort("potential_parts", "desc")
                        }
                      >
                        <ListItemIcon>
                          <ListAltIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary="Potential Parts Count"
                          secondary="Descending"
                        />
                        <ListItemIcon>
                          <ArrowDownwardIcon />
                        </ListItemIcon>
                      </MenuItem>

                      {/* Name Sorting */}
                      <MenuItem
                        onClick={() =>
                          handleRequestSort("business_name", "asc")
                        }
                      >
                        <ListItemIcon>
                          <ListAltIcon />
                        </ListItemIcon>
                        <ListItemText primary="Name" secondary="Ascending" />
                        <ListItemIcon>
                          <ArrowUpwardIcon />
                        </ListItemIcon>
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          handleRequestSort("business_name", "desc")
                        }
                      >
                        <ListItemIcon>
                          <ListAltIcon />
                        </ListItemIcon>
                        <ListItemText primary="Name" secondary="Descending" />
                        <ListItemIcon>
                          <ArrowDownwardIcon />
                        </ListItemIcon>
                      </MenuItem>
                    </Popover>
                </Box>

                <Box
                  sx={{
                    height: {
                      lg: "80vh",
                      xl: "85vh",
                      width: "100%",
                    },
                  }}
                  adl-scrollbar="true"
                  adl-scrollbar-width="0.3"
                  id="scrollableDiv"
                >
                  <Box
                    sx={{
                      height: "20vh",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    No suppliers found for the selected options
                  </Box>
                </Box>
              </>
            )}
          </Box>


          {supplierDetails ? (
              <SupplierUI
                supplierDetails={supplierDetails}
                getSupplierDetails={getSupplierDetails}
                verifySupplier={verifySupplier}
                supplierId={supplierId}
                editSupplier={editSupplier}
                filterOption={filterOption} setFilterOption={setFilterOption}
                getTrashCount={getTrashCount}
              />
            ) : (
              <Box
                sx={{
                  width: "76%",
                  border: "0.1px solid #ebeff5",
                  padding: "0.5rem",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                No supplier selected
              </Box>
            )}
        </Box>
      </Box>
    </div>
  );
}

export default NewSupplierUI;
