// react
import React, { useEffect, useState } from "react";
import Plotly from "plotly.js";
import createPlotlyComponent from "react-plotly.js/factory";
import { compact } from "lodash";
import { AxiosError, AxiosResponse } from "axios";
import { API } from "../../../api-services";
import { ADMIN } from "../../../Redux/Services/admin.service";
import { Table, TableBody, TableCell, TableRow,TableHead } from "@mui/material";
import { joinClsx } from "../../../utlis/joinClsx";
import useWindowDimensions from "../../../utlis/useWindowDimensions";
import styles from "./BomAnalysisGraph.module.scss";


// vendors
// services
// utils
// constants
// factories
// core
// redux
// ui-components
// others
// styles

// interfaces and types
// lazy

// ----------------------------------------------------------------------------------

interface BomAnalysisGraphsProps {
  project: any;
  activePage?: any;
  activeCombination?: any;
}

const BomAnalysisGraphs: React.FC<BomAnalysisGraphsProps> = (props) => {
  const { height, width } = useWindowDimensions();
  const [parameter, setParameter] = React.useState<any>([]);
  const [combinationData, setCombinationData] = useState<any>({});
  const { project, activePage, activeCombination } = props;
  const getHeaderValue = () => {
    const dataArr: any = [];
    Object.keys(combinationData).map((value: any, id: any) =>
      dataArr.push(
        <TableHead key={id} className={styles.graphcolheader}>
          {value}
        </TableHead>
      )
    );
    for (let i = dataArr.length; i < 5; i++) {
      dataArr.push(
        <TableHead key={i} className={styles.graphcolheader}>
          -
        </TableHead>
      );
    }
    return dataArr;
  };
  const Plot = createPlotlyComponent(Plotly);
  const [graphArrowCount, setGraphArrowCount] = useState<any>(1);
  const selectGraphType = (data: any) => {
    switch (graphArrowCount) {
      case 1:
        return dataLoader(
          (data as any)["Material Percentage"],
          "Material Percentage" as any
        );
      case 2:
        return dataLoader(
          (data as any)["Complexity Qty"],
          "Complexity Qty" as any
        );

      case 3:
        return dataLoader(
          (data as any)["Complexity Weight (kg)"],
          "Complexity Weight (kg)" as any
        );
      default:
        break;
    }
  };

  const renderGraph = (data: any) => {
    if (/^-?\d+$/.test(Object.keys(data)[0]) || !Object.keys(data)[0]) {
      const totalMaterial: any = Object.values(data)?.reduce(
        (a: any, b: any) => a + b,
        0
      );
      const filteredData = compact(
        Object.entries(data)?.map((values: any) => {
          return (values[1] * 100) / totalMaterial >= 1 ? values : undefined;
        })
      );
      const parameterNameMap = compact(
        filteredData?.flatMap((values) =>
          parameter.map((param: any) => {
            return param.id === +values[0] ? param.name : undefined;
          })
        )
      );
      const parameterDataMap = filteredData.flatMap((values) => values[1]);
      const otherMaterial =
        totalMaterial - parameterDataMap.reduce((a: any, b: any) => a + b, 0);
      parameterDataMap.push(otherMaterial);
      parameterNameMap.push("Others");
      return (
        <span>
          <Plot
            data={[
              {
                values: parameterDataMap,
                labels: parameterNameMap,
                // marker: {
                //     colors: ["blue", "red", "orange"]
                // },
                marker: {
                  color: [
                    "blue",
                    "red",
                    "orange",
                    "green",
                    "yellow",
                    "voilet",
                    "aqua",
                    "indigo",
                    "linen",
                    "gray",
                  ],
                },
                type: "pie",
                textposition: "inside",
              },
            ]}
            layout={{
              font: {
                size: 10,
                color: "black",
              },
              height: height <= 768 ? 150 : 220,
              width: width <= 1366 ? 170 : 230,
              margin: {
                l: 10,
                t: 10,
                r: 10,
                b: 10,
                pad: 0,
              },
              showlegend: false,
            }}
            config={{
              displaylogo: false,
              // scrollZoom: true,
            }}
          />
        </span>
      );
    } else {
      const ComplexityQtyvalues = Object.entries(data).map((values) => [
        values[0],
      ]);
      const flatData = ComplexityQtyvalues.flat();
      const ComplexityQtylables = Object.entries(data).map((values) => [
        values[1],
      ]);
      const flattenData = ComplexityQtylables.flat();

      return (
        <span>
          <Plot
            data={[
              {
                //@ts-ignore
                values: flattenData,
                labels: flatData,
                // marker: {
                //     colors: ["blue", "red", "orange"]
                // },
                marker: {
                    //@ts-ignore
                  color: [
                    "blue",
                    "red",
                    "orange",
                    "green",
                    "yellow",
                    "voilet",
                    "aqua",
                    "indigo",
                    "linen",
                    "gray",
                  ],
                },
                type: "pie",
                textposition: "inside",
              },
            ]}
            layout={{
              font: {
                size: 10,
                color: "black",
              },
              height: height <= 768 ? 150 : 220,
              width: width <= 1366 ? 170 : 230,
              margin: {
                l: 10,
                t: 10,
                r: 10,
                b: 10,
                pad: 0,
              },
              showlegend: false,
            }}
            config={{
              displaylogo: false,
              // scrollZoom: true,
            }}
          />
        </span>
      );
    }
  };

  const prevClick = () => {
    if (graphArrowCount !== 1) {
      setGraphArrowCount((prev: any) => prev - 1);
    }
  };
  const nextClick = () => {
    if (graphArrowCount !== 3) {
      setGraphArrowCount((next: any) => next + 1);
    }
  };

  const setParams = () => {
    API.get("/compare/vault/", {
      module: 10,
      parameter: true,
    }).then(
      (res: AxiosResponse) => {
        setParameter(res.data);
      },
      (err: AxiosError) => {
        // ADMIN.toast.throwError(err, `Could not fetch parameter`);
      }
    );
  };
  const getGraphsData = (length: any) => {
    const dataArr: any = [];
    Object.values(combinationData).map((data: any, index: any) => {
      if (Object.keys(data).length > 0) {
        dataArr.push(
          <TableCell className={styles.graphcol} key={index}>
            {selectGraphType(data)}
          </TableCell>
        );
      } else {
        dataArr.push(
          <TableCell className={styles.graphcol} key={index} sx={{ padding: '0.3rem 0.5rem' }}>
            No Data
          </TableCell>
        );
      }
    });
    for (let i = length; i < 5; i++) {
      dataArr.push(
        <TableCell sx={{padding: '0.3rem 0.5rem'}}
          key={i}
          className={styles.graphcol}
          style={{ textAlign: "center" }}>
          No Data
        </TableCell>
      );
    }
    return dataArr;
  };
  const getCall = () => {
    API.get(
      "/compare/vault/",
      {
        module: 10,
        combination: activeCombination,
        page: activePage,
      },
      0
    ).then(
      (res: AxiosResponse) => {
        setCombinationData(res.data);
      },
      (err: AxiosError) => {
        // ADMIN.toast.throwError(err, `Error while synced`);
      }
    );
  };
  useEffect(() => {
    getCall();
    setParams();
  }, []);

  const sequence = [
    "Material Percentage",
    "Complexity Qty",
    "Complexity Weight (kg)",
  ];
  const selectedsequence = () => {
    switch (graphArrowCount) {
      case 1:
        return [sequence[0]];
      case 2:
        return [sequence[1]];
      case 3:
        return [sequence[2]];
      default:
        break;
    }
  };
  const dataLoader = (data: any, parameter: any) => {
    if (
      parameter === "Length (mm)" ||
      parameter === "Width (mm)" ||
      parameter === "Height (mm)" ||
      parameter === "Weight( kg )"
    ) {
      if (!data) return "--";
    }

    return parameter === "Complexity Qty" ||
      parameter === "Complexity Weight (kg)" ||
      parameter === "Material Percentage"
      ? renderGraph(data)
      : data;
  };

  return (
    <div data-testid="">
      <Table

        className={styles.table + " sticky-header"}
        >
        <TableHead>
          <TableRow>
            {/* <Table.HeaderCell
                     className={ joinClsx( styles.parameterheader ) } >
                     {/* styles["sticky-headercell"] +
                     " " +
                     styles.supplierName, 
                  Parameter
                   } </Table.HeaderCell> */}
            {
              //   getHeaderValue()
              // Object.keys( combinationData ).map(
              //    value => (
              //       <Table.HeaderCell key={ value } width='2' style={ { textAlign: "center" } }>
              //          { value }
              //       </Table.HeaderCell>
              //    )
              // )
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {/* <div className={styles.pageSlide}>
            <Icon
              name="caret up"
              title="Prev Product"
              style={{ color: "#0000FF", fontSize: "2rem" }}
              link
              onClick={() => prevClick()}
              disabled={graphArrowCount === 1 ? true : false}
            />
            <Icon
              name="caret down"
              title="Next Product"
              style={{ color: "#0000FF", fontSize: "2rem" }}
              link
              onClick={() => nextClick()}
              disabled={graphArrowCount === 3 ? true : false}
            />
          </div> */}
          {selectedsequence()?.map((parameter, index) => {
            return (
              <TableRow key={index} className={styles.row}>
                <TableCell sx={{ padding: '0.3rem 0.5rem' }}
                  className={joinClsx(
                    styles["sticky-cell"] + " " + styles.serial,
                    styles.parameter
                  )}>
                  {parameter}
                </TableCell>
                {getGraphsData(Object.values(combinationData).length)}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

// ----------------------------------------------------------------------------------

export default BomAnalysisGraphs;
