import React, { useEffect, useState, useRef, useMemo } from 'react'
import Modal from '@mui/material/Modal';
import { API } from '../../api-services';
import {
	debounce,
	invert,
	isNull,
	isNumber,
	isUndefined,
	mapValues,
	omit,
	pickBy,
	uniqBy,
} from "lodash";
import { ID, ObjectType, fetched } from "../../utlis/commonInterfaces";
import {
	CALCULATOR_INPUT_CONTENT_TYPE,
	CALCULATOR_INPUT_TYPE,
	CALCULATOR_TYPE,
	InputContentType,
	calculatorCategoryOptions,
	calculatorCostInputOptions,
	calculatorDatabaseColumnsOptions,
	calculatorDatabaseOptions,
	calculatorInputBackgroundColorMap,
	calculatorInputOptions,
	BOM_CALCULATOR_INPUT_TYPE,
	BOMcalculatorInputOptions
} from "../../Configuration/Costing/calculator.constant";
import {
	IconProps,
	Input,
	Icon,
	TableCell,
	TableFooter,
	TableHead,
	TableRow,
	TableBody,
	Box,
	Table,
	FormControl,
	Select,
	MenuItem,
	TextField,
	Skeleton,
	Typography,
	Checkbox,
	Button,
	Divider,
	IconButton,
	Popover
} from "@mui/material";
import yupValidate from "../../utlis/yupValidate";
import { pipe } from "../../utlis/other";
import { numericDataTypes } from "../../utlis/parameterDataTypes.constants";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import { useCollection } from '../../CustomHook/useCollection';
import { LoadingButton } from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadIcon from "@mui/icons-material/Upload";
import EditIcon from "@mui/icons-material/Edit";
import VerifiedIcon from "@mui/icons-material/Verified";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FileCopyRoundedIcon from "@mui/icons-material/FileCopyRounded";
import calculatorInputService from "../../Redux/Services/calculatorInput.service";
import bomCalculatorInputService from '../../Redux/Services/bomCalculatorInput.service';

import OpenWithIcon from "@mui/icons-material/OpenWith";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import styles from "./bomcalculator.module.scss";
import * as yup from "yup";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import RViewerJS from "viewerjs-react";
import { CalculatorInput } from '../../Redux/Reducers/calculatorInput.reducer';
import { ParameterConfig } from "../../Redux/Reducers/parameterConfig.reducer";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import { userSelectors } from '../../Redux/Reducers/user.reducer';
import { useSelector } from 'react-redux';
import Swal from "sweetalert2";
import successIcon from "../../Assets/images/success.png";
import RemoveFlagIcon from "../../Assets/images/RemoveFlag.svg";
import SaveIcon from "@mui/icons-material/Save";
import { deleteSwal } from "../../utlis/deleteSwal";
import useFetch from "../../Core/CustomHooks/useFetch";
import InfoIcon from '@mui/icons-material/Info';
import { bomcalculatorInput } from '../../Redux/Reducers/bomCalculatorInput.reducer';



const styleModal = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: "80vw",
	height: '92vh',
	bgcolor: 'background.paper',
	borderRadius: "10px",
	p: 1,
};
interface BOMConfigCalculatorModalProps {


	isOpen: any;
	onCloseModal: any;
	id: any;
	inputData: any;

}

const inputKeysToOmit: Record<number, string[]> = {
	[BOM_CALCULATOR_INPUT_TYPE.BOM_PARAMETER]: [
		"formula",
		"constant",
		"user_input",
	],
	[BOM_CALCULATOR_INPUT_TYPE.CONSTANT]: [
		"formula",
		"parameter",

	],

	[BOM_CALCULATOR_INPUT_TYPE.FORMULA]: [

		"constant",

		"parameter",

	],
	[BOM_CALCULATOR_INPUT_TYPE.USER_INPUT]: [

		"constant",

		"parameter",

		"formula",

	],
};
interface BOMCalculatorInputFromApi {
	id: ID;
	content_type: InputContentType;
	name: string;
	/** string used to refere the input in the formula */
	alias: string;

	parameter?: ID;
	table_element?: ID;

	attribute?: string | any;

	formula?: string;
	constant?: number;
	user_input?: string;
	calculator_category?: number;
	volume?: number;
	calculator_parameter: ID;
	image_url?: string;
	test_input?: number;
	result?: number;
}

const styleDivider = {
	width: '100%',
	height: '1px',
	backgroundColor: 'primary.main',
};

const inputContentTypeToDatabaseMap: ObjectType<number> = {
	Material: 1,
	Machine: 2,
	Labour: 3,
	ToolDatabase: 4,
	FastenerDatabase: 5,
	PurchasingItem: 6,
	StrokeRateDatabase: 7,
	RevisionCurrency: 8
};




const databaseMapToConntentType = invert(inputContentTypeToDatabaseMap);

export const transformCalculatorInputFromApi = (
	input: BOMCalculatorInputFromApi
): bomcalculatorInput => {
	const { content_type, ...rest } = input;

	const obj: any = {};
	if (content_type) {
		if (rest.parameter) {
			if (content_type === CALCULATOR_INPUT_CONTENT_TYPE.BOM_PARAMETER)
				obj.type = CALCULATOR_INPUT_TYPE.BOM_PARAMETER;
			if (content_type === CALCULATOR_INPUT_CONTENT_TYPE.COMMODITY_PARAMETER)
				obj.type = CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER;
		} else if (rest.attribute) {
			if (content_type == CALCULATOR_INPUT_CONTENT_TYPE.COST_INPUT) {
				obj.type = CALCULATOR_INPUT_TYPE.COST_INPUT;
			} else if (content_type === CALCULATOR_INPUT_CONTENT_TYPE.CALCULATOR_RESULT) {

				obj.type = CALCULATOR_INPUT_TYPE.CALCULATOR_RESULT;
			}
			else {
				obj.type = CALCULATOR_INPUT_TYPE.DATABASE;
				obj.database = inputContentTypeToDatabaseMap[content_type];
			}
		}
	}
	if (rest.formula) obj.type = CALCULATOR_INPUT_TYPE.FORMULA;
	else if (!isNull(rest.user_input))
		obj.type = CALCULATOR_INPUT_TYPE.USER_INPUT;
	if (rest.constant || rest.constant == 0) obj.type = CALCULATOR_INPUT_TYPE.CONSTANT;
	if (rest.volume) obj.type = CALCULATOR_INPUT_TYPE.VOLUME;
	//changed {} to {content_type: content_type}
	return Object.assign({ content_type: content_type }, rest, obj);
};

const toApi = async (
	input: bomcalculatorInput
): Promise<BOMCalculatorInputFromApi> => {
	const transformedInput = transformInput(input) as Required<bomcalculatorInput>;

	try {
		const validatedInput = (await yupValidate(
			schema,
			transformedInput
		)) as bomcalculatorInput;

		const { type, database, ...rest } = validatedInput;
		const obj: any = {};

		if (database) {
			obj.content_type = databaseMapToConntentType[database];
		}
		if (type === CALCULATOR_INPUT_TYPE.BOM_PARAMETER) {
			obj.content_type = CALCULATOR_INPUT_CONTENT_TYPE.BOM_PARAMETER;
		}

		if (type === CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER) {
			obj.content_type = CALCULATOR_INPUT_CONTENT_TYPE.COMMODITY_PARAMETER;
		}
		if (type === CALCULATOR_INPUT_TYPE.COST_INPUT) {
			obj.content_type = CALCULATOR_INPUT_CONTENT_TYPE.COST_INPUT;
		}
		if (type === CALCULATOR_INPUT_TYPE.CALCULATOR_RESULT) {
			obj.attribute = input?.attribute
			obj.content_type = CALCULATOR_INPUT_CONTENT_TYPE.CALCULATOR_RESULT;
		}
		if (type === CALCULATOR_INPUT_TYPE.USER_INPUT) {
			obj.content_type = null;
		}
		if (type === CALCULATOR_INPUT_TYPE.VOLUME) {
			obj.content_type = null;
		}

		return pickBy(
			Object.assign({}, rest, obj),
			(value) => !isUndefined(value)
		) as any;
	} catch (e: any) {
		const msg = Object.values(e).join(", ");
		throw new Error(msg);
	}
};

// ----------------------------------------------------------------------------------
// helpers



const generateConfigsOptions = (configs: ParameterConfig[]) => {
	return configs.map((config) => ({
		key: config.id,
		value: config.parameter_id,
		text: config.name,
	}));
};

// to only maintain keys that are related to the input type during saving of data
const transformInputs = (inputs: bomcalculatorInput[]) => {
	return inputs.map(transformInput);
};

/** removes unwanted columns depending on type */
const transformInput = (input: bomcalculatorInput) => {
	return omit(input, inputKeysToOmit[input.type] || []) as bomcalculatorInput;
};

const numericOrTableDataTypes = numericDataTypes.concat("table");
const filterNumericOrTableConfigs = (configs: ParameterConfig[]) => {
	return configs.filter((config) =>
		numericOrTableDataTypes.includes(config.data_type)
	);
};

const filterNonTableColumnConfigs = (configs: ParameterConfig[]) => {
	return configs.filter((config) => !config.parameter_table_id);
};

const filterTableColumnConfigs = (
	configs: ParameterConfig[],
	parameterId: ID
) => {
	return configs.filter((config) => config.parameter_table_id === parameterId);
};

/** configs that need to be shown for BOM/Commodity parameters dropdown */
const getConfigsOptions = pipe(
	filterNumericOrTableConfigs,
	filterNonTableColumnConfigs,
	generateConfigsOptions
);

/** configs that need to shown for columns of selectedParameter */
const getTableColumnConfigsOptions = (
	configs: ParameterConfig[],
	parameterId: ID
) => {
	const tableColumnConfigs = filterTableColumnConfigs(configs, parameterId);
	return pipe(
		filterNumericOrTableConfigs,
		generateConfigsOptions
	)(tableColumnConfigs);
};

// ----------------------------------------------------------------------------------
// schema

const schema = yup.object().shape({
	type: yup.number().required('"Type" is required'),
	name: yup.string().required('"Name" is required'),
	alias: yup.string(),

	parameter: yup.mixed().when("type", {
		is: (type) =>
			[
				CALCULATOR_INPUT_TYPE.BOM_PARAMETER,
				CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER,
			].includes(type),
		then: yup
			.number()
			.transform((value) => (Boolean(value) ? value : undefined))
			.required('"Parameter" is required'),
		otherwise: yup.number().transform(() => undefined),
	}),
	table_element: yup.mixed().when("type", {
		is: (type) =>
			[
				CALCULATOR_INPUT_TYPE.BOM_PARAMETER,
				CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER,
			].includes(type),
		then: yup
			.number()
			.transform((value) => (Boolean(value) ? value : undefined))
			.notRequired(),
		otherwise: yup.number().transform(() => undefined),
	}),
	attribute: yup.string().when("type", {
		is: (type) =>
			[
				CALCULATOR_INPUT_TYPE.DATABASE,
				CALCULATOR_INPUT_TYPE.COST_INPUT,
			].includes(type),
		then: yup
			.string()
			.transform((value) => (isNull(value) ? "" : value))
			.required('"Column/Cost Type" is required'),
		otherwise: yup.string().transform(() => undefined),
	}),

	database: yup.number().when("type", {
		is: CALCULATOR_INPUT_TYPE.DATABASE,
		then: yup
			.number()
			.transform((value) => (Boolean(value) ? value : undefined))
			.required('"Database" is required'),
		otherwise: yup.number().transform(() => undefined),
	}),
	formula: yup.string().when("type", {
		is: CALCULATOR_INPUT_TYPE.FORMULA,
		then: yup
			.string()
			.transform((value) => (isNull(value) ? "" : value))
			.required('"Formula" is required'),
		otherwise: yup.string().transform(() => undefined),
	}),
	constant: yup.number().when("type", {
		is: CALCULATOR_INPUT_TYPE.CONSTANT,
		then: yup
			.number()
			.transform((value) => (Boolean(value) ? value : 0))
			// .notOneOf([0])
			.required('"Constant Value" is required'),
		otherwise: yup.number().transform(() => undefined),
	}),
	// volume: yup.number().when("type", {
	//   is: CALCULATOR_INPUT_TYPE.VOLUME,
	//   then: yup
	//     .number()
	//     .transform((value) => (value ? value : undefined))
	//     .notOneOf([0])
	//     .required("Volume is required"),
	//   otherwise: yup.number().transform(() => undefined),
	// }),
	user_input: yup.string().when("type", {
		is: (type) =>
			[
				CALCULATOR_INPUT_TYPE.USER_INPUT,
				CALCULATOR_INPUT_TYPE.FORMULA,
				CALCULATOR_INPUT_TYPE.CONSTANT,
				CALCULATOR_INPUT_TYPE.VOLUME,
			].includes(type),
		then: yup.string().transform((value) => (isNull(value) ? "" : value)),
		otherwise: yup.string().transform(() => undefined),
	}),
	calculator_category: yup.number().when("type", {
		is: CALCULATOR_INPUT_TYPE.CALCULATOR_RESULT,
		then: yup
			.number()
			.transform((value) => (isNumber(value) ? value : undefined))
			.required('"Calculator Category" is required'),
		otherwise: yup.number().transform(() => undefined),
	}),
});

export const BOMConfigCalculator = (props: BOMConfigCalculatorModalProps) => {
	const { isOpen,
		onCloseModal, id, inputData } = props;
	// const [useraction,setuseraction]=useState<any>(null)

	const [inputsFetched, setInputsFetched] = useState<fetched>("FALSE");
	const [isUploading, setIsUploading] = useState<boolean>(false);
	const [isFlagDeleting, setIsFlagDeleting] = useState<boolean>(false);
	const [textToCopy, setTextToCopy] = useState<any>("");
	const [isCopied, setIsCopied] = useState<any>(false);
	const [activeCopyId, setActiveCopyId] = useState<any>();
	const [currentSelected, setCurrentSelected] = useState<any>(-1);
	const [currentImage, setCurrentImage] = useState<any>("");
	const [color, setColor] = useState<any>("");
	const [colorCalc, setColorCalc] = useState<any>("primary.main");
	const [calcNameLoading, setCalcnameloading] = useState<any>(false);
	const [activeId, setActiveId] = useState<any>();
	const [calcName, setCalcName] = useState<any>("");
	const [validation, setValidation] = useState<any>();
	const [saveApiCallCount, setSaveApiCallCount] = useState<any>(0);
	const inputElRef = useRef<any>();
	const [expandInput, setExpandInput] = useState<any>();
	const [formulaTextArea, setFormulaTextArea] = useState<any>("");
	const [expand, setExpand] = useState<any>(true);
	const imageViewerElRef = useRef<any>();
	const [isCostSync, setIsCostSync] = useState<any>(false);
	const [formulaCheckedStatus, setFormulaCheckedStatus] = useState<any>(false);
	const [syncButtonColor, setSyncButtonColor] = useState<any>("");
	const [syncButtonAPICall, setSyncButtonAPICall] = useState<any>(true);
	const [useraction, setuseraction] = useState<any>(null)
	const [errorMessage, setErrorMessage] = useState<any>();
	const [resultInputColor, setResultInputColor] = useState<any>("");
	const [Lockstatus, setLockstatus] = useState<any>()
	const infoModal = useBaseModal()
	const userProfile = useSelector(userSelectors.selectAll);
	const errorMessageModel = useBaseModal();
	const [calc, setCalc] = useState<any>([]);
	const [fetchCalcList, setFetchCalcList] = useState<any>(true);
	const [labourOptions, setLabourOptions] = useState<any>([]);
	const [machineOptions, setMachineOptions] = useState<any>([]);
	const [materialOptions, setMaterialOptions] = useState<any>([]);
	const [toolOptions, setToolOptions] = useState<any>([]);
	const [bopOptions, setBopOptions] = useState<any>([]);
	const [setUpOptions, setSetUpOptions] = useState<any>([]);
	const inputs: any = useCollection<bomcalculatorInput>();
	console.log(inputs)
	const handleCopyClick = async (alias: any, idd: any) => {
		setTextToCopy(`val.${alias}`);
		setActiveCopyId(idd);
		try {
			await navigator.clipboard.writeText(`val.${alias}`);

			setIsCopied(true);
		} catch (err) {
			console.error("Failed to copy text: ", err);
		}
	};

	const correctFormula = (formula: string) => {
		const openingBrackets = "([{";
		const closingBrackets = ")]}";
		const stack: string[] = [];

		let correctedFormula = "";

		for (const char of formula) {
			if (openingBrackets.includes(char)) {
				stack.push(char); // Push opening brackets onto the stack
			} else if (closingBrackets.includes(char)) {
				const correspondingOpeningBracket =
					openingBrackets[closingBrackets.indexOf(char)];

				// If the stack is not empty and the top of the stack matches the corresponding opening bracket
				if (
					stack.length > 0 &&
					stack[stack.length - 1] === correspondingOpeningBracket
				) {
					stack.pop(); // Pop the matching opening bracket from the stack
				} else {
					// Add a missing opening bracket to the corrected formula
					correctedFormula += correspondingOpeningBracket;
				}
			}

			correctedFormula += char; // Add the current character to the corrected formula
		}

		// Add any missing closing brackets for the remaining opening brackets on the stack
		while (stack.length > 0) {
			const missingClosingBracket =
				closingBrackets[openingBrackets.indexOf(stack.pop()!)]; // Use ! to assert non-null
			correctedFormula += missingClosingBracket;
		}
		if (correctedFormula) {
			setFormulaTextArea(correctedFormula);
			setColor("red");
			setActiveId(expandInput?.id);
			setFormulaCheckedStatus(false);
			inputs.updateOne({
				id: expandInput?.id,
				changes: { formula: correctedFormula },
			});
		}
	};
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	// fetch bom parameters
	// const { 1: bomConfigs } = useFetch<ParameterConfig[]>({
	//   method: bomconfigService.getByProjectId,
	//   args: [projectId, true, true],
	//   dependencies: [dataType, selectedIdTree],
	//   condition: dataType === "calculator",
	//   transformResData(data) {
	// 	const configs: any = transformConfigurationsFromApi(data);

	// 	return uniqBy(configs, (config: any) => config.parameter_id);
	//   },
	//   fallBackErrMsg: "Could not fetch bom parameters",
	// });

	// const bomConfigsOptions = useMemo(() => {
	// 	return bomConfigs ? getConfigsOptions(bomConfigs) : [];
	//   }, [bomConfigs]);

	// const getOptionData = (type: any) => {
	// 	  return bomConfigsOptions;
	//   };






	const TestInputChange = useRef(
		debounce(async (value: any, name: any) => {
			API.put(`/cost/calculator_input/${value}/`, { test_input: name }, {}, 0)
				.then((res: any) => { })
				.catch((err: any) => { });
		}, 500)
	).current;
	const aliasChange = useRef(
		debounce(async (value: any, name: any) => {
			API.put(`/cost/calculator_input/${value}/`, { alias: name }, {}, 0)
				.then((res: any) => {
					setColor("");
					//   fetchInputs();
				})
				.catch((err: any) => { });
		}, 500)
	).current;

	const deleteInput = async (inputId: any) => {
		// delete directly from memory if not created in backend
		if (inputId < 1) return inputs.removeOne(inputId);

		try {
			await bomCalculatorInputService.delete(inputId);
			inputs.removeOne(inputId);

			//   ADMIN.toast.success("Input deleted");
		} catch (e) {
			//   ADMIN.toast.throwError(e, "Could not delete input");
		}
	};

	useEffect(() => {
		return () => {
			//   ExactDebounce.cancel(); // Cancel the debounced function
			TestInputChange.cancel();
		};
	}, []);

	const getData = (data: any) => {
		//@ts-ignore
		if (sessionStorage.getItem('calculator_type') != CALCULATOR_TYPE.OVERHEAD) {
			return data.filter((item: any) => item.value !== 7);
		} else {
			return data;
		}
	};


	const handleFileInputClick = (e: React.MouseEvent) => {
		// clear the prev value on click so that same image can be uploaded again

		(e.target as HTMLInputElement).value = "";
	};


	const handleChange = (inputId: ID) => (e: any) => {
		// setIsSyncRecomended(true)
		setSyncButtonColor("red");

		const { name, value, selectionStart, selectionEnd } = e.target;

		if (name === "formula") {
			setFormulaTextArea(value);
		}

		if (name === "test_input") {
			inputs.updateOne({ id: inputId, changes: { [name]: value } });
			TestInputChange(inputId, value);
		} else if (name === "alias") {
			inputs.updateOne({ id: inputId, changes: { [name]: value } });
			setActiveId(inputId);
			aliasChange(inputId, value);
		}
		else if (name === "formula") {
			setColor("red");
			setActiveId(inputId);
			inputs.updateOne({ id: inputId, changes: { [name]: value } });
		}
		else if (name === "database") {
			setColor("");
			setActiveId(inputId);
			inputs.updateOne({ id: inputId, changes: { [name]: value } });
			inputs.updateOne({ id: inputId, changes: { ["attribute"]: null } });
		}
		else if (name === "type" && value == 11) {
			setExpand(false);
			setActiveId(inputId);
			getVisible(5) && setColor('red');
			inputs.updateOne({ id: inputId, changes: { [name]: value } });
			inputs.updateOne({ id: inputId, changes: { ["parameter"]: null } });
			inputs.updateOne({ id: inputId, changes: { ["database"]: null } });
			inputs.updateOne({ id: inputId, changes: { ["attribute"]: null } });
			inputs.updateOne({ id: inputId, changes: { ["constant"]: null } });
			inputs.updateOne({ id: inputId, changes: { ["user_input"]: "Qty" } });
			inputs.updateOne({ id: inputId, changes: { ["volume"]: 1 } });
			inputs.updateOne({ id: inputId, changes: { ["formula"]: null } });
			inputs.updateOne({ id: inputId, changes: { ["calculator_category"]: null } });
		}
		else if (name === "type" && value == 4) {
			setExpand(false);
			setActiveId(inputId);
			setColor('');
			inputs.updateOne({ id: inputId, changes: { [name]: value } });
			inputs.updateOne({ id: inputId, changes: { ["parameter"]: null } });
			inputs.updateOne({ id: inputId, changes: { ["database"]: null } });
			inputs.updateOne({ id: inputId, changes: { ["attribute"]: null } });
			inputs.updateOne({ id: inputId, changes: { ["constant"]: 0 } });
			inputs.updateOne({ id: inputId, changes: { ["user_input"]: "" } });
			inputs.updateOne({ id: inputId, changes: { ["volume"]: null } });
			inputs.updateOne({ id: inputId, changes: { ["formula"]: null } });
			inputs.updateOne({ id: inputId, changes: { ["calculator_category"]: null } });
		}
		else if (name === "type" && value != 5) {
			setExpand(false);
			setActiveId(inputId);
			setColor('');
			inputs.updateOne({ id: inputId, changes: { [name]: value } });
			inputs.updateOne({ id: inputId, changes: { ["parameter"]: null } });
			inputs.updateOne({ id: inputId, changes: { ["database"]: null } });
			inputs.updateOne({ id: inputId, changes: { ["attribute"]: null } });
			inputs.updateOne({ id: inputId, changes: { ["constant"]: null } });
			inputs.updateOne({ id: inputId, changes: { ["user_input"]: "" } });
			inputs.updateOne({ id: inputId, changes: { ["volume"]: null } });
			inputs.updateOne({ id: inputId, changes: { ["formula"]: null } });
			inputs.updateOne({ id: inputId, changes: { ["calculator_category"]: null } });
		}
		else if (name === "type" && value == 5) {
			setActiveId(inputId);
			inputs.updateOne({ id: inputId, changes: { [name]: value } });
			inputs.updateOne({ id: inputId, changes: { [name]: value } });
			inputs.updateOne({ id: inputId, changes: { ["parameter"]: null } });
			inputs.updateOne({ id: inputId, changes: { ["database"]: null } });
			inputs.updateOne({ id: inputId, changes: { ["attribute"]: null } });
			inputs.updateOne({ id: inputId, changes: { ["constant"]: null } });
			inputs.updateOne({ id: inputId, changes: { ["user_input"]: "" } });
			inputs.updateOne({ id: inputId, changes: { ["volume"]: null } });
			inputs.updateOne({ id: inputId, changes: { ["formula"]: null } });
			inputs.updateOne({ id: inputId, changes: { ["calculator_category"]: null } });
		}
		else if (name === "calculator_category") {
			setColor("");
			setActiveId(inputId);
			inputs.updateOne({ id: inputId, changes: { [name]: value } });
			//   fetchCalc(value);
		}
		else {

			setActiveId(inputId);
			inputs.updateOne({ id: inputId, changes: { [name]: value } });
		}
	};

	const fetchInputs = async () => {
		setInputsFetched("FETCHING");
		try {
			const { data } = await bomCalculatorInputService.get(id);
			inputs.setAll(
				data
					.map(transformCalculatorInputFromApi)
					.sort((a: any, b: any) => a.sequence - b.sequence)
			);
			setInputsFetched("TRUE");
		} catch (e) {
			//   ADMIN.toast.throwError(e, "Could not fetch input");
			setInputsFetched("ERROR");
		}
	};

	useEffect(() => {
		fetchInputs();
	}, [saveApiCallCount]);

	console.log(expand, expandInput, "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa")

	const createInput = async (inputId: ID, input: bomcalculatorInput) => {
		try {
			const validatedInput = await toApi(input);
			console.log(validatedInput, "validatedInput")
			var datavalue = validatedInput;
			// ? { ...validatedInput, attribute: "volume" }
			// : validatedInput;

			const { data } = await bomCalculatorInputService.create(datavalue);
			console.log(data, "data")
			inputs.overlapOne(inputId, transformCalculatorInputFromApi(data));
			setSaveApiCallCount((prev: any) => prev + 1);
		} catch (e: any) {
			setErrorMessage(e.response.data);
			errorMessageModel.open();
		}
	};


	const handleResultChange = (ed: any) => {
		// setCalculator((prev: any) =>
		//   Object.assign({}, prev, { result: ed.target.value })
		// );
		setResultInputColor('red');
	};


	const handleAdd = () => {
		const newInput: bomcalculatorInput = {
			id: Math.random(),
			name: "",
			alias: "",
			calculator_parameter: id,
			type: CALCULATOR_INPUT_TYPE.BOM_PARAMETER,
		};

		inputs.addOne(newInput);
	};

	const handleDelete = async (ed: any) => {
		const confirm: any = await deleteSwal("Delete Input");
		if (confirm) deleteInput(ed);
	};

	const handleSave = (inputId: any) => {
		// create
		setExpand(false);
		const input = inputs.getById(inputId);
		console.log(input, "input");

		if (!input) return;

		if (inputId < 1) {
			createInput(inputId, { ...input, ...{ calculator_parameter: id } });
			console.log(inputId, { ...input, ...{ calculator_parameter: id } });
			return;
		}

		// updateInput(input);
		setFormulaCheckedStatus(false);
		setColor("");
	};

	const clickSync = () => {
		setIsCostSync(true)
		Swal.fire({
			html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
      <br />
      <p style="color:#007fff;"> Cost syncing is in progress in the background. You will be notified once it is finished.
      </p>   
       </div>`,
		});
		API.get("/cost/cost_calculator_update/", { calculatorParameter: id }, 0).then((res: any) => {
			setIsCostSync(false);
			setSyncButtonAPICall(!syncButtonAPICall);
			setSyncButtonColor("")
			// setTriggerSyncButton(0)
			// setCounterApiCall((prev:any)=>prev+1)
		}).catch((err: any) => {
			setIsCostSync(false)
		})
	}

	const getVisible = (inputt: any) => {
		if (expandInput?.id !== inputt.id) {
			return true;
		} else if (inputt.type === CALCULATOR_INPUT_TYPE.FORMULA && !expand) {
			return true;
		} else if (inputt.alias !== "" && !expand) {
			return true;
		} else {
			return false;
		}
	};



	const renderValueColumn = (input: bomcalculatorInput) => {
		console.log(input, "aaaaaaaaaaaaaaaaaaaa");

		switch (input.type) {
			case BOM_CALCULATOR_INPUT_TYPE.BOM_PARAMETER:
			case BOM_CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER:
				console.log(input, "aaaaaaaaaaaaaaaaaaaa");
				return (
					<Box
						sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}
					>
						<FormControl fullWidth>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								variant="standard"
								label="Type"
								placeholder="Select Parameters"
								disabled={
									(Lockstatus?.lock_status == false && useraction?.includes("U")) ? (input.name == null || input.name == "") && Number(input.id) < 1 ? true : false : true
								}
								value={input.parameter || ""}
								onFocus={() => {
									setExpandInput(input);
									setExpand(false);
								}}
								name="parameter"
								sx={{
									width: "50%",
									fontSize: "1rem",
									"&:before": {
										borderBottomColor: "primary.light",
									},
									".MuiSvgIcon-root.MuiSelect-icon": {
										color: "primary.main",
									},
								}}
								onBlur={() => (Lockstatus?.lock_status == false && useraction?.includes("U")) && input.parameter != null && input.parameter != "" && handleSave(input.id)}
								onChange={handleChange(input.id)}
							>
								{/* {getOptionData(input.type)?.map((item: any) => {
                      return (
                        <MenuItem sx={{ fontSize: "1rem" }} value={item.value}>
                          {item.text}
                        </MenuItem>
                      );
                    })} */}
							</Select>
						</FormControl>

						{/* {input.parameter &&
                  isTableParameter(input.type, input.parameter) && (
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        variant="standard"
                        label="Type"
                        disabled={
                          (Lockstatus?.lock_status==false && useraction?.includes("U"))?false:true
                        }
                        value={input.table_element || ""}
                        onChange={handleChange(input.id)}
                        name="table_element"
                        sx={{
                          width: "100%",
                          fontSize: "1rem",
                          "&:before": {
                            borderBottomColor: "primary.light",
                          },
                          ".MuiSvgIcon-root.MuiSelect-icon": {
                            color: "primary.main",
                          },
                        }}
                      >
                        {getdatafromtype(input)?.map((item: any) => {
                          return (
                            <MenuItem sx={{ fontSize: "1rem" }} value={item.value}>
                              {item.text}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )} */}
					</Box>
				);

			case BOM_CALCULATOR_INPUT_TYPE.CONSTANT:
				return (
					<Box
						sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}
					>
						<FormControl fullWidth>
							<TextField
								variant="standard"
								name="constant"
								type="number"
								// disabled={
								//   (Lockstatus?.lock_status==false && useraction?.includes("U"))? (input.name == null || input.name == "") && Number(input.id) < 1 ? true : false : true
								// }
								placeholder="Constant Value"
								required
								value={input.constant || 0}
								//@ts-ignore
								onChange={handleChange(input.id)}
								onFocus={() => {
									setExpandInput(input);
									setExpand(false);
								}}

								// onBlur={()=>(Lockstatus?.lock_status==false && useraction?.includes("U")) && Number(input.id)>1 && input.user_input!=null && input.constant!=null && input.user_input!="" && handleSave(input.id)}
								onBlur={() => Number(input.id) > 1 && input.user_input != null && input.constant != null && input.user_input != "" && handleSave(input.id)}
								sx={{
									"& .MuiInputBase-input.MuiInput-input": {
										fontSize: "1rem",
										paddingLeft: "0.5rem",
										backgroundColor: "white",
									},
									"& .MuiInput-underline:before": {
										borderBottomColor: "primary.light",
									},
									"& .MuiInput-underline:after": {
										borderBottomColor: "primary.main",
									},
								}}
							/>
						</FormControl>
						<FormControl fullWidth>
							<TextField
								variant="standard"
								name="user_input"
								//   disabled={
								//       (Lockstatus?.lock_status==false && useraction?.includes("U"))? (input.name == null || input.name == "") && Number(input.id) < 1 ? true : false : true
								//   }
								placeholder="User Input Unit"
								value={input.user_input || ""}
								onChange={handleChange(input.id)}
								//   onBlur={()=>(Lockstatus?.lock_status==false && useraction?.includes("U")) && input.user_input!=null && input.constant!=null && input.user_input!="" && handleSave(input.id)}
								onBlur={() => input.user_input != null && input.constant != null && input.user_input != "" && handleSave(input.id)}
								onFocus={() => {
									setExpandInput(input);
									setExpand(false);
								}}
								InputProps={{
									style: { fontSize: "1rem" },
								}}
								sx={{
									"& .MuiInput-underline:before": {
										borderBottomColor: "primary.light",
									},
									"& .MuiInput-underline:after": {
										borderBottomColor: "primary.main",
									},
								}}
							/>
						</FormControl>
					</Box>
				);

			case BOM_CALCULATOR_INPUT_TYPE.FORMULA:
				return (
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							columnGap: "0.5rem",
							width: "100%",
						}}
					>
						<TextField
							variant="standard"
							name="formula"
							placeholder="Formula"
							value={input.formula || ""}
							//   disabled={
							//       (Lockstatus?.lock_status==false && useraction?.includes("U"))?false:true
							//   }
							onChange={handleChange(input.id)}
							onFocus={() => {
								console.log("i am here")
								console.log(expand)
								setExpandInput(input);
								setFormulaTextArea(input.formula);
								// setExpand(true);
							}}
							InputProps={{
								style: { fontSize: "1rem" },
							}}
							sx={{
								"& .MuiInputBase-input.MuiInput-input": {
									fontSize: "1rem",
									paddingLeft: "0.5rem",
									backgroundColor: "white",
								},
								"& .MuiInput-underline:before": {
									borderBottomColor: "primary.light",
								},
								"& .MuiInput-underline:after": {
									borderBottomColor: "primary.main",
								},
							}}
						/>
						<TextField
							variant="standard"
							name="user_input"
							placeholder="Unit (optional)"
							//   disabled={
							//       (Lockstatus?.lock_status==false && useraction?.includes("U"))?false:true
							//   }
							value={input.user_input || ""}
							onChange={handleChange(input.id)}
							onFocus={() => {
								setExpandInput(input);
								setColor("red");
							}}
							InputProps={{
								style: { fontSize: "1rem" },
							}}
							sx={{
								"& .MuiInputBase-input.MuiInput-input": {
									fontSize: "1rem",
									paddingLeft: "0.5rem",
									backgroundColor: "white",
								},
								"& .MuiInput-underline:before": {
									borderBottomColor: "primary.light",
								},
								"& .MuiInput-underline:after": {
									borderBottomColor: "primary.main",
								},
							}}
						/>
					</Box>
				);
			case BOM_CALCULATOR_INPUT_TYPE.USER_INPUT:
				return (
					<>
						<TextField
							variant="standard"
							name="user_input"
							placeholder="User Input Unit"
							value={input.user_input || ""}
							//   disabled={
							//       (Lockstatus?.lock_status==false && useraction?.includes("U")) ? (input.name == null || input.name == "") && Number(input.id) < 1 ? true : false : true
							//   }
							onChange={handleChange(input.id)}
							//   onBlur={()=>(Lockstatus?.lock_status==false && useraction?.includes("U")) && input.user_input!=null && input.user_input!="" && handleSave(input.id)}
							onBlur={() => input.user_input != null && input.user_input != "" && handleSave(input.id)}
							onFocus={() => {
								console.log(input)
								setExpandInput(input);
								setExpand(false);
							}}
							InputProps={{
								style: { fontSize: "1rem" },
							}}
							sx={{
								"& .MuiInputBase-input.MuiInput-input": {
									fontSize: "1rem",
									paddingLeft: "0.5rem",
									backgroundColor: "white",
								},
								"& .MuiInput-underline:before": {
									borderBottomColor: "primary.light",
								},
								"& .MuiInput-underline:after": {
									borderBottomColor: "primary.main",
								},
							}}
						/>
					</>
				);



			default:
				return null;
		}
	};

	//   React.useEffect(()=>{
	//       if(triggerSyncButton !=0){
	//           clickSync()
	//       }
	//   },[triggerSyncButton])


	// const bomConfigsOptions = useMemo(() => {
	//     return bomConfigs ? getConfigsOptions(bomConfigs) : [];
	//   }, [bomConfigs]);

	//   const processConfigsOptions = useMemo(() => {
	//     return processConfigs ? getConfigsOptions(processConfigs) : [];
	//   }, [processConfigs]);

	//   const getOptionData = (type: any) => {
	//     if (type === CALCULATOR_INPUT_TYPE.BOM_PARAMETER) {
	//       return bomConfigsOptions;
	//     } else {
	//       return processConfigsOptions;
	//     }
	//   };

	//   const getdatafromtype = (input: any) => {
	//     if (input.type === 1) {
	//       return bomTableConfigOptions(input.parameter);
	//     } else {
	//       return processTableConfigOptions(input.parameter);
	//     }
	//   };


	const open = Boolean(anchorEl);
	const poid = open ? 'simple-popover' : undefined;

	const browseFiles = (index: any) => {
		setCurrentSelected(index);
		return inputElRef.current?.click?.();
	};

	const handleImageView = (index: any) => {
		setCurrentImage(inputs.getCollection()[index].image_url);
		return imageViewerElRef.current?.click?.();
	};

	const handleFileUpload = (
		e: React.ChangeEvent<HTMLInputElement>,
		id: any
	) => {
		setIsUploading(true);
		const data = new FormData();
		if (e.target.files?.length) {
			//@ts-ignore
			data.append(`image0`, e.currentTarget.files[0]);

			API.put(
				`cost/calculator_field_image_upload/${inputs.getCollection()[currentSelected]?.id
				}/`,
				data,
				{},
				0
			)
				.then((res: any) => {
					setIsUploading(false);
					//   ADMIN.toast.success("Data uploaded successfully");
					//   fetchInputs();
				})
				.catch((err: any) => {
					//   ADMIN.toast.error("Could not upload file");
					setIsUploading(false);
				});
		}
	};

	const calculatorList = (calculator_category: any, input: any) => {

		switch (calculator_category) {
			case 1:
				return materialOptions;
			case 2:
				return bopOptions;
			case 3:
				return labourOptions;
			case 4:
				return machineOptions;
			case 5:
				return toolOptions;
			case 6:
				return setUpOptions;

			default:
				break;
		}

	}

	return (
		<div>
			<Modal
				open={isOpen}
				onClose={onCloseModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={styleModal}>
					<Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
						<Typography
							style={{
								fontSize: "1rem",
								fontWeight: "600",
								width: "100%",
								textAlign: "center",
								marginRight: '-2rem'
							}}
						>
							BOM Configuration Calculator
						</Typography>
						<CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
					</Box>
					<Divider
						sx={{
							borderColor: "primary.light",
							margin: "0 1rem",
							marginBottom: "1rem",
						}}
					/>
					<div className={styles.tableWrapper}>
						{inputs.length === 0 && (
							<Box
								sx={{
									width: "100%",
									height: "100%",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>

								<Typography
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "1rem",
										height: "84vh",
									}}
								>
									<AddIcon
										onClick={handleAdd}

										sx={{ fontSize: "4rem", cursor: "pointer" }}
									/>
									<Button onClick={handleAdd}>Create a Cost Model</Button>
								</Typography>

							</Box>
						)}
						{inputs.length > 0 && (
							<>
								<Box
									sx={{
										height:
											expand === true
												? { lg: "56vh", xl: "68.5vh" }
												: { lg: "76vh", xl: "82vh" },
									}}
								// adl-scrollbar="true"
								// adl-scrollbar-width="0.3"         
								>
									<Box sx={{
										maxHeight:
											expand === true
												? { lg: "51.2vh", xl: "65vh" } : { lg: '71vh', xl: '78.5vh' }
									}}
										adl-scrollbar="true"
										adl-scrollbar-width="0.3">
										<Table stickyHeader aria-label="sticky table"  >
											<TableHead>
												<TableRow>
													<TableCell
														sx={{
															padding: "0.2rem 0.5rem",
															backgroundColor: "primary.main",
															borderBottomColor: "primary.light",
															color: "white",
															width: { lg: "3vw", xl: "2vw" },
														}}
													>
														Sl No.
													</TableCell>
													<TableCell
														sx={{
															padding: "0.2rem 0.5rem",
															backgroundColor: "primary.main",
															borderBottomColor: "primary.light",
															color: "white",
															width: "9vw",
														}}
													>
														Input Name
													</TableCell>
													<TableCell
														sx={{
															padding: "0.2rem 0.5rem",
															backgroundColor: "primary.main",
															borderBottomColor: "primary.light",
															color: "white",
															width: { lg: '4vw', xl: "3vw" },
														}}
													>
														as
													</TableCell>
													<TableCell
														sx={{
															padding: "0.2rem 0.5rem",
															backgroundColor: "primary.main",
															borderBottomColor: "primary.light",
															color: "white",
															width: "5vw",
														}}
													>
														Type
													</TableCell>
													<TableCell
														sx={{
															padding: "0.2rem 0.5rem",
															backgroundColor: "primary.main",
															borderBottomColor: "primary.light",
															color: "white",
															width: "15vw",
															textAlign: "center",
														}}
													>
														Selection option
													</TableCell>

													{/* <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light',whiteSpace:'nowrap',color:'white',}}>Result</TableCell> */}
													<TableCell
														sx={{
															padding: "0.2rem 0.5rem",
															backgroundColor: "primary.main",
															borderBottomColor: "primary.light",
															color: "white",
															width: "6vw",
														}}
													>
														Actions
													</TableCell>
													<TableCell
														sx={{
															padding: "0.2rem 0.5rem",
															backgroundColor: "primary.main",
															borderBottomColor: "primary.light",
															color: "white",
															width: "6vw",
															verticalAlign: "center",
														}}
													>
														<Typography
															sx={{
																fontSize: "1rem",
																fontWeight: "500",
																lineHeight: "1.2",
																textAlign: "center",
															}}
														>
															Test Input <br />
															<span
																style={{
																	cursor: "pointer",
																	fontSize: "1rem",
																	textDecoration: "underline",
																}}
															// onClick={fetchInputs}
															>
																Click for results
															</span>
														</Typography>
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{inputs.getCollection().map((input: any, index: any) => (
													<TableRow
														key={input.id}
														sx={{
															backgroundColor:
																expandInput && expandInput.id === input?.id && input.type === 5
																	? "#c4efb3"
																	: "",
														}}
													>
														<TableCell
															sx={{
																padding: "0.2rem 0.5rem",
																borderBottomColor: "primary.light",
															}}
														>

															<TextField
																variant="standard"
																name="sequence"
																type="number"
																// disabled={ ( useraction?.includes("U"))?false:true
																// }
																placeholder="Seq"
																value={input.sequence}
																onChange={handleChange(input.id)}
																// onBlur={()=>useraction?.includes("U") && input.id > 1 && handleSave(input.id)}
																onBlur={() => input.id > 1 && handleSave(input.id)}
																inputProps={{
																	step: 'any',
																	min: 1,
																	style: { fontSize: '1rem', }
																}}
																sx={{
																	"& .MuiInputBase-input.MuiInput-input": {
																		fontSize: "1rem",
																		paddingLeft: "0.5rem",
																		backgroundColor: "white",
																	},
																	"& .MuiInput-underline:before": {
																		borderBottomColor: "primary.light",
																	},
																	"& .MuiInput-underline:after": {
																		borderBottomColor: "primary.main",
																	},
																}}
															/>
														</TableCell>
														<TableCell
															sx={{
																padding: "0.2rem 0.5rem",
																borderBottomColor: "primary.light",
															}}
														>
															<TextField
																variant="standard"
																name="name"
																placeholder="Name"
																// disabled={ (useraction?.includes("U"))?false:true
																// }
																value={input.name}
																onChange={handleChange(input.id)}
																// onBlur={()=>useraction?.includes("U") && input.id > 1 && handleSave(input.id)}
																onBlur={() => input.id > 1 && handleSave(input.id)}
																InputProps={{
																	style: { fontSize: "1rem" },
																}}
																inputProps={{
																	maxLength: 127,
																}}
																sx={{
																	"& .MuiInputBase-input.MuiInput-input": {
																		fontSize: "1rem",
																		paddingLeft: "0.5rem",
																		backgroundColor: "white",
																	},
																	"& .MuiInput-underline:before": {
																		borderBottomColor: "primary.light",
																	},
																	"& .MuiInput-underline:after": {
																		borderBottomColor: "primary.main",
																	},
																}}
															/>
														</TableCell>
														<TableCell
															sx={{
																padding: "0.2rem 0.5rem",
																borderBottomColor: "primary.light",
															}}
														>
															<Box sx={{ display: "flex", columnGap: "0.5rem" }}>
																<TextField
																	variant="standard"
																	name="alias"
																	placeholder="as"
																	disabled={true}
																	value={input.alias}
																	onChange={handleChange(input.id)}
																	InputProps={{
																		style: { fontSize: "1rem" },
																	}}
																	sx={{
																		"& .MuiInputBase-input.MuiInput-input": {
																			fontSize: "1rem",
																			paddingLeft: "0.5rem",
																			backgroundColor: "white",
																		},
																		"& .MuiInput-underline:before": {
																			borderBottomColor: "primary.light",
																		},
																		"& .MuiInput-underline:after": {
																			borderBottomColor: "primary.main",
																		},
																	}}
																/>
																{getVisible(input) && (
																	<FileCopyRoundedIcon

																		sx={{
																			cursor: "pointer",
																			//   opacity:Lockstatus?.lock_status==false ?"1":"0.3",
																			color:
																				isCopied && activeCopyId === input.id
																					? "green"
																					: "",
																		}}
																		onClick={() =>
																			handleCopyClick(input.alias, input.id)
																		}
																	/>
																)}
																{/* {input.type === 5 && input.alias !== "" && !expand ? (
							<OpenWithIcon
							  sx={{ cursor: "pointer" }}
							  onClick={() => {
								setExpandInput(input);
								setFormulaTextArea(input.formula);
								setExpand(true);
								console.log(input.type === 5);
							  }}
							/>
						  ) : (
							<OpenWithIcon sx={{ display: "none" }} />
						  )}
						  {input.type === 5 && expand && (
							<CloseFullscreenIcon
							  sx={{ cursor: "pointer" }}
							  onClick={() => setExpand(false)}
							/>
						  )} */}
															</Box>
														</TableCell>
														<TableCell
															sx={{
																padding: "0.2rem 0.5rem",
																borderBottomColor: "primary.light",
															}}
														>

															{input.type &&
																<Select
																	labelId="demo-simple-select-label"
																	id="demo-simple-select"
																	variant="standard"
																	// disabled={
																	// 	(useraction?.includes("U")) ? (input.name == null || input.name == "") && Number(input.id) < 1 ? true : false : true
																	// }
																	size="small"
																	value={input.type}
																	onChange={handleChange(input.id)}
																	name="type"
																	sx={{
																		width: "100%",
																		fontSize: "1rem",
																		"&:before": {
																			borderBottomColor: "primary.light",
																		},
																		".MuiSvgIcon-root.MuiSelect-icon": {
																			color: "primary.main",
																		},
																	}}
																>
																	{
																		// calculatorInputOptions.filter(input => {
																		//                         /** filter cost_input type, when calculator type is not overhead */
																		//         return selectedTreeData.calculator_type !== CALCULATOR_TYPE.OVERHEAD ? input.value !== CALCULATOR_INPUT_TYPE.COST_INPUT : input;
																		// })
																		getData(BOMcalculatorInputOptions)?.map(
																			(item: any) => {
																				return (
																					<MenuItem
																						sx={{ fontSize: "1rem" }}
																						value={item.value}
																					>
																						{item.text}
																					</MenuItem>
																				);
																			}
																		)
																	}
																</Select>}
														</TableCell>
														<TableCell
															sx={{
																padding: "0.2rem 0.5rem",
																borderBottomColor: "primary.light",
															}}
														>
															{renderValueColumn(input)}
														</TableCell>
														<TableCell
															sx={{
																padding: "0.2rem 0.5rem",
																borderBottomColor: "primary.light",
															}}
														>
															{
																getVisible(input) && color == 'red' && (input.type == CALCULATOR_INPUT_TYPE.FORMULA) && (
																	<LoadingButton
																		sx={{
																			minWidth: "0.5rem",
																			maxWidth: "1.5rem",
																			margin: "0 0.3rem",
																			padding: "0.2rem",
																			".MuiCircularProgress-root": {
																				color: "primary.main",
																			},
																		}}
																		onClick={() => handleSave(input.id)}
																	>
																		<SaveIcon
																			sx={
																				activeId && activeId == input.id
																					? { cursor: "pointer", color: color }
																					: { cursor: "pointer" }
																			}
																			titleAccess="save"
																		/>
																	</LoadingButton>
																)}
															{
																<LoadingButton
																	sx={{
																		minWidth: "0.5rem",
																		maxWidth: "1.5rem",
																		margin: "0 0.2rem",
																		padding: "0.2rem",
																		".MuiCircularProgress-root": {
																			color: "primary.main",
																		},
																	}}
																	onClick={() => handleDelete(input.id)}
																>
																	<DeleteIcon
																		sx={{ cursor: "pointer" }}
																		titleAccess="Delete"
																	/>
																</LoadingButton>
															}
															{
																<LoadingButton
																	sx={{
																		minWidth: "0.5rem",
																		maxWidth: "1.5rem",
																		margin: "0 0.2rem",
																		padding: "0.2rem",
																		".MuiCircularProgress-root": {
																			color: "primary.main",
																		},
																	}}
																	onClick={() => {

																		browseFiles(index)
																	}}
																	loading={
																		isUploading && currentSelected === index
																	}
																	//@ts-ignore
																	disabled={input.id > 1 ? false : true}
																>
																	<UploadIcon
																		sx={
																			//@ts-ignore
																			input.id > 1
																				? { cursor: "pointer" }
																				: { cursor: "not-allowed" }
																		}
																		titleAccess="Upload Image"
																	/>
																</LoadingButton>
															}

															<LoadingButton
																sx={{
																	minWidth: "0.5rem",
																	maxWidth: "1.5rem",
																	margin: "0 0.3rem",
																	padding: "0.2rem",
																	".MuiCircularProgress-root": {
																		color: "primary.main",
																	},
																}}
																onClick={() => handleImageView(index)}
																disabled={input.image_url ? false : true}
															>
																<CameraAltIcon
																	sx={
																		!input.image_url
																			? { cursor: "not-allowed" }
																			: { cursor: "pointer" }
																	}
																	titleAccess="View Image"
																/>
															</LoadingButton>

															<input
																style={{ display: "none" }}
																type="file"
																id={input.id.toString()}
																ref={inputElRef}
																accept="image/*"
																onChange={(
																	e: React.ChangeEvent<HTMLInputElement>
																) => handleFileUpload(e, input.id)}
																onClick={(e: any) => {
																	handleFileInputClick(e);
																}}
															/>

															{
																input.image_url && (
																	<LoadingButton
																		sx={{
																			minWidth: "1.5rem",
																			padding: "0 0.2rem",
																			margin: "0 0.3rem",
																			".MuiCircularProgress-root": {
																				color: "primary.main",
																			},
																		}}
																		loading={
																			isFlagDeleting && currentSelected === index
																		}
																	>
																		{!isFlagDeleting && (
																			<img
																				src={RemoveFlagIcon}
																				style={{ width: "2rem", height: "2rem" }}
																				title="Remove flag"
																				onClick={() => {
																					setCurrentSelected(index);
																					setIsFlagDeleting(true);
																					API.put(
																						`cost/calculator_field_image_upload/${input.id}/`,
																						{ image0: "null" },
																						{},
																						0
																					)
																						.then((res: any) => {
																							setIsFlagDeleting(false);
																							//   ADMIN.toast.success("Data uploaded successfully");
																							//   fetchInputs();
																						})
																						.catch((err: any) => {
																							//   ADMIN.toast.error("Could not upload file");
																							setIsFlagDeleting(false);
																						});
																				}}
																			/>
																		)}
																	</LoadingButton>
																)}
														</TableCell>
														<TableCell
															sx={{
																padding: "0.2rem 0.5rem",
																borderBottomColor: "primary.light",
															}}
														>
															<TextField
																variant="standard"
																name={input?.formula ? "result" : "test_input"}
																placeholder="Enter Value"
																type="number"
																inputProps={{
																	step: 'any',
																	min: 0,
																	style: { fontSize: '1rem', }
																}}
																value={
																	input?.formula ? input.result : input.test_input
																}
																onChange={handleChange(input.id)}

																disabled={(input.formula == null) ? false : true}
																sx={{
																	width: "100%",
																	"& .MuiInputBase-input.MuiInput-input": {
																		fontSize: "1rem",
																		paddingLeft: "0.5rem",
																		backgroundColor: "white",
																	},
																	"& .MuiInput-underline:before": {
																		borderBottomColor: "primary.light",
																	},
																	"& .MuiInput-underline:after": {
																		borderBottomColor: "primary.main",
																	},

																	backgroundColor:
																		calculatorInputBackgroundColorMap[input.type],
																}}
															/>
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</Box>

									<Box sx={{ position: 'sticky', bottom: '0', width: '100%' }}>
										<Button
											title='Add Field'
											onClick={handleAdd}
											size='small'
											sx={{ width: '100%' }}>
											<Box sx={styleDivider} />
											<Box
												sx={{
													display: 'flex',
													alignItems: 'center',
													columnGap: '0.4rem',
													whiteSpace: 'nowrap',
													margin: '0 1rem',
													backgroundColor: 'primary.light',
													padding: '0.2rem 1rem 0.2rem 0.5rem',
													borderRadius: '3px',
												}}>
												<AddIcon />
												Add Field
											</Box>
											<Box sx={styleDivider} />
										</Button>
									</Box>

								</Box>
								<Box>
									<Box>
										{/* formula */}
										{expand && (
											<>
												<Box
													sx={{
														// border: "1px solid #007fff",
														borderRadius: "5px",
														padding: "0.5rem 1rem 0",
														backgroundColor: "primary.light",
														marginBottom: '0.1rem',
													}}
												>
													<Box
														sx={{
															display: "flex",
															alignItems: "center",
															justifyContent: "space-between",
															width: "100%",
															marginBottom: "0.5rem",
														}}
													>
														<Typography
															sx={{
																fontSize: "1rem",
																whiteSpace: "nowrap",
																fontWeight: "500",
															}}
														>
															{expandInput?.sequence}. {expandInput?.name} Formula :{" "}
														</Typography>
														<Box>
															{expand && (
																<Box
																	sx={{
																		display: "flex",
																		alignItems: "center",
																		columnGap: "1rem",
																		width: "100%",
																	}}
																>
																	<IconButton
																		title="Formula Fusion: Multi-Operation Showcase"
																		sx={{ padding: '0' }}
																		aria-describedby={id}
																		onClick={handleClick}>
																		<InfoIcon sx={{ color: 'primary.main' }} />
																	</IconButton>
																	<Popover
																		id={poid}
																		open={open}
																		anchorEl={anchorEl}
																		onClose={handleClose}
																		anchorOrigin={{
																			vertical: 'top',
																			horizontal: 'left',
																		}}
																		transformOrigin={{
																			vertical: 'bottom',
																			horizontal: 'right',
																		}}
																		disableRestoreFocus
																		sx={{
																			'.MuiPaper-root.MuiPopover-paper': {
																				borderRadius: '1rem',
																				//   backgroundColor:'primary.light'
																			},
																		}}
																	>
																		<Typography sx={{ width: '100%', textAlign: 'center', fontSize: '1rem', padding: '0.5rem', fontWeight: '600', backgroundColor: '#ddefff' }}>
																			Formula Fusion: Multi-Operation Showcase
																		</Typography>
																		<Divider sx={{ borderColor: "primary.light" }} />
																		<Box sx={{ display: 'flex', alignItems: 'flex-start', columnGap: '0.5rem', padding: '0.5rem' }}>
																			<Table>
																				<TableBody>
																					<TableRow>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>1</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>val.a + val.b</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>Addition</TableCell>
																					</TableRow>
																					<TableRow>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>2</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>val.a - val.b</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>Subtraction</TableCell>
																					</TableRow>
																					<TableRow>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>3</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>val.a * val.b</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>Multiplication</TableCell>
																					</TableRow>
																					<TableRow>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>4</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>val.a / val.b</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>Division</TableCell>
																					</TableRow>
																					<TableRow>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>5</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>val.a ** val.b</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>Exponentiation</TableCell>
																					</TableRow>
																					<TableRow>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>6</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>val.a % val.b</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>Modulo</TableCell>
																					</TableRow>
																					<TableRow>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>7</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>val.a and val.b</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>AND</TableCell>
																					</TableRow>
																					<TableRow>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>8</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>val.a or val.b</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>OR</TableCell>
																					</TableRow>
																					<TableRow>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>9</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>not val.a</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>NOT</TableCell>
																					</TableRow>
																					<TableRow>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>10</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>val.a == val.b</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>Equal</TableCell>
																					</TableRow>
																					<TableRow>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>11</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>val.a != val.b</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>Not equal</TableCell>
																					</TableRow>
																					<TableRow>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>12</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>val.a {'>'} val.b</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>Greater than</TableCell>
																					</TableRow>
																					<TableRow>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>13</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>val.a {'<'} val.b</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>Less than</TableCell>
																					</TableRow>
																				</TableBody>
																			</Table>
																			<Divider sx={{ borderColor: "primary.light" }} orientation="vertical" variant="middle" flexItem />
																			<Table>
																				<TableBody>
																					<TableRow>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>14</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>val.a {'>'}= val.b</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>Greater than or equal to</TableCell>
																					</TableRow>
																					<TableRow>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>15</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>val.a {'<'}= val.b</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>Less than or equal to</TableCell>
																					</TableRow>
																					<TableRow>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>16</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>val.a + val.b if val.a {'>'} val.b else val.a - val.b</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>If and else condition</TableCell>
																					</TableRow>
																					<TableRow>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>17</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>min(val.a, val.b, val.c, val.d)</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>min</TableCell>
																					</TableRow>
																					<TableRow>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>18</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>max(val.a, val.b, val.c, val.d)</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>max</TableCell>
																					</TableRow>
																					<TableRow>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>19</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>round(val.a, 2)</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>round</TableCell>
																					</TableRow>
																					<TableRow>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>20</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>math.floor(val.a)</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>rounded down</TableCell>
																					</TableRow>
																					<TableRow>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>21</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>math.ceil(val.a)</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>rounded up</TableCell>
																					</TableRow>
																					<TableRow>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>22</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>math.sqrt(val.a)</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>Square root</TableCell>
																					</TableRow>
																					<TableRow>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>23</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>math.sin(val.a)</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>Sine function</TableCell>
																					</TableRow>
																					<TableRow>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>24</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>math.cos(val.a)</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>Cosine function</TableCell>
																					</TableRow>
																					<TableRow>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>25</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>math.log(val.a)</TableCell>
																						<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>Natural logarithm</TableCell>
																					</TableRow>
																				</TableBody>
																			</Table>
																		</Box>
																	</Popover>
																	{formulaCheckedStatus ? (
																		<FactCheckIcon
																			sx={{
																				cursor: "pointer",
																				color: "green",
																			}}
																			titleAccess="Check formula"
																			onClick={() => {
																				correctFormula(formulaTextArea);
																				setFormulaCheckedStatus(true);
																			}}
																		/>
																	) : (
																		<FactCheckOutlinedIcon
																			sx={{
																				cursor: "pointer",
																				// color:  "green" : "",
																			}}
																			titleAccess="Check formula"
																			onClick={() => {
																				correctFormula(formulaTextArea);
																				setFormulaCheckedStatus(true);
																			}}
																		/>
																	)}
																	<SaveIcon
																		titleAccess="Save Formula"
																		sx={{ color: color, cursor: "pointer" }}
																		onClick={() => handleSave(expandInput.id)}
																	/>
																</Box>
															)}
														</Box>
													</Box>
													<TextareaAutosize
														aria-label="minimum height"
														name="formula"
														// value={input.formula || ""}

														//   disabled={
														// 	(Lockstatus?.lock_status==false && useraction?.includes("U"))?false:true
														//   }
														onChange={handleChange(expandInput?.id)} // Set the minimum number of rows
														placeholder="Enter your formula"
														value={formulaTextArea || ""}
														minRows={5}
														style={{
															color: "#007fff",
															width: "100%",
															border: "1px solid #ddefff",
															borderRadius: "5px",
															padding: "0.5rem 1rem 0",
														}} // Set the width
													/>
												</Box>
											</>
										)}
									</Box>
									<Table
										sx={{
											backgroundColor: "primary.light",
											position: "sticky",
											bottom: 0,
											zIndex: 1,
										}}
									>
										<TableBody>
											<TableRow>
												<TableCell
													sx={{
														width: "25rem",
														padding: "0.2rem 0.5rem",
														borderBottomColor: "primary.light",
														color: "primary.main",
													}}
												>
													Result
												</TableCell>
												<TableCell
													sx={{
														padding: "0.2rem 0.5rem",
														borderBottomColor: "primary.light",
														color: "primary.main",
													}}
												>
													<TextField
														variant="standard"
														size="small"
														//   disabled={
														// 	(Lockstatus?.lock_status==false && useraction?.includes("U"))?false:true
														//   }
														name="result"
														placeholder="Formula"
														//   value={calculator?.result || ""}
														onChange={handleResultChange}
														inputProps={{
															style: {
																fontSize: "1rem",
															},
														}}
														sx={{
															backgroundColor: "white",
															"& .MuiInputBase-input.MuiInput-input": {
																fontSize: "1rem",
																paddingLeft: "0.5rem",
																paddingBottom: "0",
																height: "2rem",
															},
															"& .MuiInput-underline:before": {
																borderBottomColor: "primary.light",
															},
															"& .MuiInput-underline:after": {
																borderBottomColor: "primary.main",
															},
														}}
													/>
												</TableCell>
												<TableCell
													sx={{
														padding: "0.2rem 0.5rem",
														borderBottomColor: "primary.light",
													}}
												>
													<LoadingButton
														disabled={Lockstatus?.lock_status}
														sx={{
															minWidth: "0.5rem",
															maxWidth: "1.5rem",
															margin: "0 0.3rem",
															padding: "0",
															color: resultInputColor
														}}
													//   onClick={updateResult}
													>
														<SaveIcon titleAccess="save" />
													</LoadingButton>
												</TableCell>
												<TableCell
													sx={{
														padding: "0.2rem 0.5rem",
														borderBottomColor: "primary.light",
														color: "primary.main",
													}}
												></TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</Box>
							</>
						)}
						<div style={{ display: "none" }}>
							{/* @ts-ignore */}
							<RViewerJS>
								<img src={currentImage} alt="image" ref={imageViewerElRef} />
							</RViewerJS>
						</div>
					</div>






				</Box>
			</Modal>
		</div>
	)
}