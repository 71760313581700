import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { useRouteParams } from '../ui-reusable-component/useRouteParams';
import { Box, Button, IconButton, Pagination } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { API } from '../api-services';
import { useBaseModal } from '../ComponentUI/SearchPage/useBaseModal';
import PublishAmountAndTime from './PublishAmountAndTime';
import { GridCellParams } from '@mui/x-data-grid';
import { useBaseParamsModal } from '../CustomHook/useBaseParamsModal';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import LockIcon from '@mui/icons-material/Lock';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';


interface PublishLockProps {
	setParnetsCounter: any;
}

const PublishLock = (props: PublishLockProps) => {
	const { setParnetsCounter } = props;
	const { requestType } = useRouteParams<any>();

	const [LockList, setLockList] = useState<any>([]);
	const [loader, setLoader] = useState<any>(false);
	const [totalPagecount, settotalPagecount] = useState<any>();

	const [page, setPage] = React.useState(1);

	const [Counter, setCounter] = React.useState(1);
	const Publishmodal = useBaseParamsModal();

	const PaginationHandler = (type: any) => {
		if (type == 'Next') {
			setPage((prev: any) => prev + 1);
		} else {
			setPage((prev: any) => prev - 1);
		}
	};
	useEffect(() => {
		setLoader(true);

		API.get('bom/get_publish_interface_data/', { page: page })
			.then((res: any) => {
				let dataList = res?.data?.map((item: any, index: any) => {
					return { ...item, ids: (page - 1) * res.data?.length + index + 1 };
				});
				setLockList(dataList);
				setLoader(false);
			})
			.catch((err: any) => {
				const { data } = err?.response;
				Swal.fire({
					icon: 'error',
					html: `<div>
          <br />
          <p style="color:"red">${data.detail}</p>   
           </div>`,
				});
				setLoader(false);
			});
	}, [requestType, page, Counter]);
	useEffect(() => {
		setLoader(true);

		API.get('bom/get_publish_interface_data/', { get_page_count: true })
			.then((res: any) => {
				settotalPagecount(res.data?.page_count);
			})
			.catch((err: any) => {
				const { data } = err?.response;
				Swal.fire({
					icon: 'error',
					html: `<div>
          <br />
          <p style="color:"red">${data.detail}</p>   
           </div>`,
				});
			});
	}, [requestType, Counter]);

	const publisandLockClicked = (id: any) => {
		API.put(`/bom/vault_info/${id}/`, { publish: true, lock: true }, {}, 0)
			.then((res: any) => {
				setCounter((prev: any) => prev + 1);
				setParnetsCounter((prev: any) => prev + 1);
				Swal.fire({
					customClass: {
						container: 'swal2Container',
					},
					icon: 'success',
					title: 'Success',
					text: 'Part published successfully, Please check in xcPROC',
				});
			})
			.catch((err: any) => {
				console.log(err);
			});
	};

	const AmoutHandlerandPublish = (data: any) => {
		Publishmodal.open(data);
	};
	const columns: any = [
		{
			field: 'actions',
			headerName: 'Actions',
			width: 170,
			renderCell: (cellValues: any) => {
				return (
					<>						
						{
							<Button
								variant='contained'
								color='primary'
								sx={{ marginRight: '10px' }}
								size='small'
								disabled={cellValues.row?.publish_and_lock}
								title='Publish to xcPROC'
								onClick={() =>
									AmoutHandlerandPublish({
										...cellValues?.row,
										...{ lock: true },
									})
								}>
								{cellValues.row?.publish_and_lock
									? 'Published'
									: <Box sx={{ display: 'flex', alignItems: 'center' }}>
									<LockIcon /> <ArrowRightAltIcon />
									<PublishedWithChangesIcon />
								</Box>}
							</Button>
						}
						{
							<Button
								size='small'
								variant='contained'
								color='primary'								
								// disabled={cellValues.row?.is_published?false:true}
								title='Update'
								onClick={() =>
									AmoutHandlerandPublish({
										...cellValues?.row,
										...{ lock: false },
									})
								}>
								{'Update'}
							</Button>
						}
					</>
				);
			},
		},
		{ field: 'ids', headerName: 'S.No.', width: 50 },
		{
			field: 'name',
			headerName: 'Name',
			width: 200,
			editable: false,
		},
		{
			field: 'part_no',
			headerName: 'Part No.',
			width: 200,
			editable: true,
		},
		{
			field: 'category',
			headerName: 'BOM Category',
			width: 200,
			editable: false,
		},
		{
			field: 'vehicle_category',
			headerName: 'Product Category',
			width: 200,
			editable: false,
		},
        {
			field: 'status',
			headerName: 'BOM Status',
			width: 120,
			editable: false,
            align: 'right',
		},
		{
			field: 'cost_status',
			headerName: 'Cost Status',
			width: 120,
			editable: false,
            align: 'right',
		},		
		{
			field: 'scenario_amount',
			headerName: 'Scenario Amount (in INR)',
			width: 150,
			editable: false,
			align: 'right',
		},

		{
			field: 'scenario_turn_around_time_in_days',
			headerName: 'Scenario Turn Around Time (in Days)',
			width: 240,
			editable: false,
			align: 'right',
		},

	
	];

	return (
		<>
			<PublishAmountAndTime
				onCloseModal={Publishmodal.close}
				isOpen={Publishmodal.isOpen}
				Item={Publishmodal.propsId}
				setCounter={setCounter}
			/>
			<div
				style={{
					display: 'flex',
					justifyContent: 'flex-end',
					alignItems: 'center',
					columnGap: '1rem',
				}}>
				<IconButton
					sx={{ cursor: page > 1 ? 'pointer' : 'not-allowed' }}
					disabled={page > 1 ? false : true}
					title='Previous Page'
					color='inherit'
					onClick={() => PaginationHandler('Previous')}>
					<ArrowCircleLeftIcon
						sx={{ cursor: page > 1 ? 'pointer' : 'not-allowed' }}
					/>
				</IconButton>
				{page}
				<IconButton
					sx={{ cursor: page < totalPagecount ? 'pointer' : 'not-allowed' }}
					disabled={page < totalPagecount ? false : true}
					title='Next Page'
					color='inherit'
					onClick={() => PaginationHandler('Next')}>
					<ArrowCircleRightIcon
						sx={{ cursor: page < totalPagecount ? 'pointer' : 'not-allowed' }}
					/>
				</IconButton>
			</div>
			<Box sx={{ height: { lg: '47vh', xl: '58vh' }, width: '100%' }}>
				<DataGrid
					headerHeight={38}
					rowHeight={38}
					rows={LockList && LockList}
					columns={columns}
					initialState={{
						pagination: {
							//@ts-ignore
							paginationModel: {
								pageSize: 5,
							},
						},
					}}
					sx={{
						'& ::-webkit-scrollbar': {
							width: '0.3rem',
							height: '0.3rem',
						},
						'& ::-webkit-scrollbar-thumb': {
							// background: $thumb-color;
							backgroundImage: 'linear-gradient(#09C6F9, #045DE9)',
							borderRadius: '10px',
						},
						'& ::-webkit-scrollbar-thumb:hover': {
							// width: "4px",
							backgroundColor: '#045DE9 !important',
						},
						'::-webkit-scrollbar-track': {
							backgroundColor: '#e1e1f3 !important',
						},
						'&.MuiDataGrid-root': {
							border: 'none',
						},
						'& .MuiDataGrid-cell': {
							color: 'text.primary',
						},
						'&.MuiDataGrid-root .MuiDataGrid-cell': {
							borderBottomColor: 'primary.light',
						},
					}}
					loading={loader}
					pageSizeOptions={[5]}
					disableRowSelectionOnClick
					hideFooter
				/>
			</Box>
			
		</>
	);
};

export default PublishLock;
