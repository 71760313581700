import {
  Box,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Popover,
  Skeleton,
  Typography,
} from "@mui/material";
import PublicIcon from "@mui/icons-material/Public";
import VpnLockIcon from "@mui/icons-material/VpnLock";
import { useCallback, useEffect, useState } from "react";
import styles from "../InternalLanding/InternalLanding.module.scss";
import MenuIcon from "@mui/icons-material/Menu";
import { API } from "../../api-services";
import { useHistory } from "react-router-dom";
import usreperm from "../../Assets/images/userpermis.svg";
import PushPinIcon from "@mui/icons-material/PushPin";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import config from "../../Assets/images/configuration.svg";
import bomIcon from "../../Assets/images/hierarchy.png";
import CurrencyRupeeRoundedIcon from "@mui/icons-material/CurrencyRupeeRounded";
import { ADMIN } from "../../Redux/Services/admin.service";
import { AUTH } from "../../Redux/Services/auth.service";
import InterLandingStatistics from "./InterLandingStatistics";
import { forEach } from "lodash";

const InterLandingTitleSectionNew = () => {
  const [calculationData, setCalculationData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [calcLoading, setCalcLoading] = useState<boolean>(false);
  const [projectCount, setProjectCount] = useState<number>(0);
  const [isLoadingCount, setIsLoadingCount] = useState<boolean>(false);
  const [apiCallCount, setApiCallCount] = useState<number>(0);
  const subHeaders = {
    "ASI Add Product": [
      "Unpublished BOM",
      "Published BOM",
      "Unpublished Costing",
      "Published Costing",
    ],
    Request: [
      "ZBC Drawing Unpublished Part",
      "ZBC Drawing Published Part",
      "ZBC Physical Part Unpublished",
      "ZBC Physical Part Published",
    ],
    "Requested Scenario": ["Scenario Request", "Scenario Published"],
    Manufacturar: ["Added Part"],
  };
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [activeCategory, setActiveCategory] = useState<any>(null);
  const [anchorElPopover, setAnchorElPopover] =
    useState<HTMLButtonElement | null>(null);
  const openOption = Boolean(anchorElPopover);
  const [anchorElPopoverCosting, setAnchorElPopoverCosting] =
    useState<HTMLButtonElement | null>(null);
  const openOptionCosting = Boolean(anchorElPopoverCosting);
  const history = useHistory();

  const handleClickOpenPopover = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElPopover(event.currentTarget);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const getData = useCallback(() => {
    setIsLoadingCount(true);
    setIsLoading(true);
    setCalcLoading(true);
    API.get("bom/dashboard_info/")
      .then((res: any) => {
        console.log("Calculation Data", res.data);
        setProjectCount(res.data.length);
        setIsLoadingCount(false);
        setCalculationData(res.data);

        setCalcLoading(false);
        setIsLoading(false);
      })
      .catch((err: any) => {
        setCalcLoading(false);
      });
  }, []);

  const handleClosePopover = () => {
    setAnchorElPopover(null);
  };
  const handleClosePopoverCosting = () => {
    setAnchorElPopoverCosting(null);
  };

  const handleClickOpenPopoverCosting = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElPopoverCosting(event.currentTarget);
  };

  const PinedClicked = (vehCateId: any, pinnedStatus: any) => {
    
    if (!pinnedStatus) {
      API.post(
        "/bom/pinned_vehicle_category/",
        {
          vehicle_category: vehCateId,
          user: AUTH.user,
        },
        {},
        0
      )
        .then((res: any) => {
          setApiCallCount((prev: any) => prev + 1);
        })
        .catch((err: any) => {
          ADMIN.toast.error("Something went wrong");
        });
    } else {
      API.delete(`/bom/pinned_vehicle_category/${vehCateId}/`, {}, 0)
        .then((res: any) => {
          setApiCallCount((prev: any) => prev + 1);
        })
        .catch((err: any) => {
          ADMIN.toast.error("Something went wrong");
        });
    }
  };

  useEffect(() => {
    getData();
  }, [getData, apiCallCount]);

  return (
    <>
      <InterLandingStatistics data={calculationData} />
      <Paper>
        <Grid
          container
          sx={{ backgroundColor: "primary.main", color: "white" }}
        >
          <Grid item xs={12} container>
            <Grid
              item
              xs={3}
              style={{
                borderLeft: "1px solid",
                borderTop: "1px solid",
                borderBottom: "1px solid",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                borderColor: "rgba(0,119,138,0.95)",
              }}
            >
              <Typography>Category</Typography>
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                border: "1px solid",
                textAlign: "center",
                borderColor: "rgba(0,119,138,0.95)",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    width: "100%",
                    borderBottom: "1px solid",
                    borderColor: "rgba(0,119,138,0.95)",
                  }}
                >
                  ASI Add Product
                </Typography>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)",
                    width: "100%",
                    height: "3rem",
                  }}
                >
                  {subHeaders["ASI Add Product"].map((each, index) => (
                    <Typography
                      key={index}
                      align="center"
                      sx={{
                        // borderRight: index !== subHeaders["ASI Add Product"].length - 1 ? "1px solid" : "none",
                        borderLeft: index === 0 ? "none" : "1px solid",
                        borderColor: "rgba(0,119,138,0.95)",
                        // borderBottom: "1px solid",
                        backgroundColor: "",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "0.87rem",
                        flex: 1,
                      }}
                    >
                      {each}
                    </Typography>
                  ))}
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                borderRight: "1px solid",
                borderTop: "1px solid",
                borderBottom: "1px solid",
                borderColor: "rgba(0,119,138,0.95)",
                textAlign: "center",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    width: "100%",
                    borderBottom: "1px solid",
                    borderColor: "rgba(0,119,138,0.95)",
                  }}
                >
                  Request
                </Typography>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)",
                    width: "100%",
                    height: "3rem",
                  }}
                >
                  {subHeaders.Request.map((each, index) => (
                    <Typography
                      key={index}
                      align="center"
                      sx={{
                        // borderRight: index !== subHeaders["ASI Add Product"].length - 1 ? "1px solid" : "none",
                        borderLeft: index === 0 ? "none" : "1px solid",
                        borderColor: "rgba(0,119,138,0.95)",
                        // borderBottom: "1px solid",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "0.87rem",
                        flex: 1,
                      }}
                    >
                      {each}
                    </Typography>
                  ))}
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                borderRight: "1px solid",
                borderTop: "1px solid",
                borderBottom: "1px solid",
                borderColor: "rgba(0,119,138,0.95)",
                textAlign: "center",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    width: "100%",
                    borderBottom: "1px solid",
                    borderColor: "rgba(0,119,138,0.95)",
                  }}
                >
                  Requested Scenario
                </Typography>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    width: "100%",
                    height: "3rem",
                  }}
                >
                  {subHeaders["Requested Scenario"].map((each, index) => (
                    <Typography
                      key={index}
                      align="center"
                      sx={{
                        // borderRight: index !== subHeaders["ASI Add Product"].length - 1 ? "1px solid" : "none",
                        borderLeft: index === 0 ? "none" : "1px solid",
                        borderColor: "rgba(0,119,138,0.95)",
                        // borderBottom: "1px solid",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "0.87rem",
                        flex: 1,
                      }}
                    >
                      {each}
                    </Typography>
                  ))}
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={1}
              style={{
                borderRight: "1px solid",
                borderTop: "1px solid",
                borderBottom: "1px solid",
                borderColor: "rgba(0,119,138,0.95)",
                textAlign: "center",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    width: "100%",
                    borderBottom: "1px solid",
                    borderColor: "rgba(0,119,138,0.95)",
                  }}
                >
                  Manufacturer
                </Typography>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(1, 1fr)",
                    width: "100%",
                    height: "3rem",
                  }}
                >
                  {subHeaders.Manufacturar.map((each, index) => (
                    <Typography
                      key={index}
                      align="center"
                      sx={{
                        // borderRight: index !== subHeaders["ASI Add Product"].length - 1 ? "1px solid" : "none",
                        borderLeft: index === 0 ? "none" : "1px solid",
                        borderColor: "rgba(0,119,138,0.95)",
                        // borderBottom: "1px solid",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "0.87rem",
                        flex: 1,
                      }}
                    >
                      {each}
                    </Typography>
                  ))}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          {calcLoading ? (
            <Box sx={{ width: "100%", height: "100vh" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  gap: "2rem",
                  width: "97%",
                  margin: "0 auto",
                  padding: "0.5rem",
                }}
              >
                <Box sx={{ width: "30%", borderRadius: "5px" }}>
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "2rem",
                    width: "100%",
                  }}
                >
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  gap: "2rem",
                  width: "97%",
                  margin: "0 auto",
                  padding: "0.5rem",
                }}
              >
                <Box sx={{ width: "30%", borderRadius: "5px" }}>
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "2rem",
                    width: "100%",
                  }}
                >
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  gap: "2rem",
                  width: "97%",
                  margin: "0 auto",
                  padding: "0.5rem",
                }}
              >
                <Box sx={{ width: "30%", borderRadius: "5px" }}>
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "2rem",
                    width: "100%",
                  }}
                >
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  gap: "2rem",
                  width: "97%",
                  margin: "0 auto",
                  padding: "0.5rem",
                }}
              >
                <Box sx={{ width: "30%", borderRadius: "5px" }}>
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "2rem",
                    width: "100%",
                  }}
                >
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  gap: "2rem",
                  width: "97%",
                  margin: "0 auto",
                  padding: "0.5rem",
                }}
              >
                <Box sx={{ width: "30%", borderRadius: "5px" }}>
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "2rem",
                    width: "100%",
                  }}
                >
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  gap: "2rem",
                  width: "97%",
                  margin: "0 auto",
                  padding: "0.5rem",
                }}
              >
                <Box sx={{ width: "30%", borderRadius: "5px" }}>
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "2rem",
                    width: "100%",
                  }}
                >
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                  <Skeleton
                    animation="pulse"
                    sx={{ height: "2rem", width: "100%" }}
                  />
                </Box>
              </Box>
            </Box>
          ) : (
            calculationData?.map((each: any, index: number) => {
            

              return (
                <Grid
                  item
                  xs={12}
                  container
                  key={index}
                  style={{ backgroundColor: each.color }}
                >
                  <Grid
                    item
                    xs={3}
                    style={{
                      borderLeft: "1px solid",
                      // borderTop: index === 0 ? 'none' : "1px solid",
                      borderBottom: "1px solid",
                      borderColor: "aliceblue",
                      textAlign: "center",
                    }}
                  >
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingY: "0.2rem",
                        paddingX: "0.5rem",
                      }}
                    >
                      <Typography>{each.name}</Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.8rem",
                        }}
                        
                      >
                        <IconButton  
                          sx={{ cursor: "pointer", padding: 0 }}
                          onClick={(e:any)=> {
                            setActiveCategory(each);
                            handleClick(e)
                          }}  
                        >
                        
                          <MenuIcon />

                      
                        </IconButton>

                        <Popover
                            id={id}
                            open={open}
                            elevation={1}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            PaperProps={{
                              sx: { padding: "0.2rem 0.5rem" },
                            }}
                          >
                      
                            <Box
                              sx={{ display: "flex", height: "100%" }}
                              
                            >
                               
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: {
                                    xs: "column",
                                    sm: "column",
                                    md: "row",
                                  },
                                  columnGap: "1rem",
                                  width: "100%",
                                  marginRight: { xs: "0", sm: "0.6rem" },
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    borderTop: {
                                      xs: "1px solid",
                                      sm: "1px solid",
                                      md: "none",
                                      lg: "none",
                                      xl: "none",
                                    },
                                    borderBottom: {
                                      xs: "1px solid",
                                      sm: "1px solid",
                                      md: "none",
                                      lg: "none",
                                      xl: "none",
                                    },
                                    gap: "0.5rem",
                                    justifyContent: "right",
                                    padding: "0",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      margin: "0 auto",
                                      cursor: activeCategory?.has_user_permission
                                        ? "pointer"
                                        : "not-allowed",
                                      opacity: !activeCategory?.has_user_permission
                                        ? "0.4"
                                        : "1",
                                      padding: "0.5rem",
                                    }}
                                    onClick={() =>
                                      activeCategory?.has_user_permission &&
                                      history.push(
                                        `/user-permissions/${activeCategory?.project_id}/0/all/projectSpecific`
                                      )
                                    }
                                  >
                                    <img
                                      title="User Permission"
                                      className={styles.internalIcons}
                                      src={usreperm}
                                      style={{
                                        width: "auto",
                                        height: "2rem",
                                        margin: "auto",
                                        verticalAlign: "middle",
                                      }}
                                    />
                                  </Box>

                                  <Box
                                    sx={{
                                      margin: "0 auto",
                                      cursor: "pointer",
                                      padding: "0.5rem",
                                    }}
                                    onClick={() =>
                                      history.push(
                                        `/configurationmain/${activeCategory?.id}/${activeCategory?.name}`
                                      )
                                    }
                                  >
                                    <img
                                      title="Project Configuration"
                                      className={styles.internalIcons}
                                      src={config}
                                      style={{
                                        width: "2rem",
                                        height: "auto",
                                        verticalAlign: "middle",
                                      }}
                                    />
                                  </Box>

                                  <img
                                    title="BOM Entry"
                                    className={styles.internalIcons}
                                    src={bomIcon}
                                    // /bomentry/23/23-T-P02/46/66446/CA/313/false/false
                                    onClick={(event: any) =>
                                      // history.push(`/bomentry/${item?.id}/${item?.name}/0/0/0/0/false/false`)
                                      handleClickOpenPopover(event)
                                    }
                                    style={{
                                      width: "2rem",
                                      height: "auto",
                                      cursor: "pointer",
                                      margin: "0 auto",
                                      // padding: "0.5rem",
                                    }}
                                  />
                                  <Popover
                                    open={openOption}
                                    onClose={handleClosePopover}
                                    anchorEl={anchorElPopover}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "center",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "center",
                                    }}
                                    elevation={1}
                                  >
                                    {
                                      <Box>
                                        <MenuItem
                                        onClick={() =>
                                            history.push(
                                                `/bomentry/${activeCategory?.id}/${activeCategory?.name}/0/0/0/0/false/false/true`
                                            )
                                        }
                                        >
                                          <ListItemIcon>
                                            <VpnLockIcon
                                              fontSize="small"
                                              sx={{ color: "primary.main" }}
                                            />
                                          </ListItemIcon>
                                          <ListItemText
                                          onClick={() =>

                                              history.push(
                                                  `/bomentry/${activeCategory?.id}/${activeCategory?.name}/0/0/0/0/false/false/true`
                                              )
                                          }
                                          >
                                            Private
                                          </ListItemText>
                                        </MenuItem>

                                        <MenuItem
                                        onClick={() =>
                                            history.push(
                                                `/bomentry/${activeCategory?.id}/${activeCategory?.name}/0/0/0/0/false/false/false`
                                            )
                                        }
                                        >
                                          <ListItemIcon>
                                            <PublicIcon
                                              fontSize="small"
                                              sx={{ color: "primary.main" }}
                                            />
                                          </ListItemIcon>
                                          <ListItemText
                                          onClick={() =>
                                              history.push(
                                                  `/bomentry/${activeCategory?.id}/${activeCategory?.name}/0/0/0/0/false/false/false`
                                              )
                                          }
                                          >
                                            Public
                                          </ListItemText>
                                        </MenuItem>
                                      </Box>
                                    }
                                  </Popover>

                                  <CurrencyRupeeRoundedIcon
                                    titleAccess="Cost Estimation"
                                    className={styles.internalIcons}
                                    color="primary"
                                    sx={{
                                      fontSize: "2.25rem",
                                      cursor: "pointer",
                                      margin: "0 auto",
                                    }}
                                    onClick={(event: any) =>
                                      handleClickOpenPopoverCosting(event)
                                    }
                                  />
                                  <Popover
                                    open={openOptionCosting}
                                    onClose={handleClosePopoverCosting}
                                    anchorEl={anchorElPopoverCosting}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "center",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "center",
                                    }}
                                    elevation={1}
                                  >
                                    {
                                      <Box>
                                        <MenuItem
                                          onClick={() =>
                                            history.push(
                                              `/costingabbrlanding/${activeCategory?.id}/${activeCategory?.name}/0/0/true/0/0/0/0/true`
                                            )
                                          }
                                        >
                                          <ListItemIcon>
                                            <VpnLockIcon
                                              fontSize="small"
                                              sx={{ color: "primary.main" }}
                                            />
                                          </ListItemIcon>
                                          <ListItemText
                                            onClick={() =>
                                              history.push(
                                                `/costingabbrlanding/${activeCategory?.id}/${activeCategory?.name}/0/0/true/0/0/0/0/true`
                                              )
                                            }
                                          >
                                            Private
                                          </ListItemText>
                                        </MenuItem>

                                        <MenuItem
                                          onClick={() =>
                                            history.push(
                                              `/costingabbrlanding/${activeCategory?.id}/${activeCategory?.name}/0/0/true/0/0/0/0/false`
                                            )
                                          }
                                        >
                                          <ListItemIcon>
                                            <PublicIcon
                                              fontSize="small"
                                              sx={{ color: "primary.main" }}
                                            />
                                          </ListItemIcon>
                                          <ListItemText
                                            onClick={() =>
                                              history.push(
                                                `/costingabbrlanding/${activeCategory?.id}/${activeCategory?.name}/0/0/true/0/0/0/0/false`
                                              )
                                            }
                                          >
                                            {" "}
                                            Public
                                          </ListItemText>
                                        </MenuItem>
                                      </Box>
                                    }
                                  </Popover>
                                </Box>
                              </Box>
                            </Box>
                          </Popover>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {!each?.is_pinned && (
                            <PushPinOutlinedIcon
                              sx={{
                                fontSize: "1.5rem",
                                cursor: "pointer",
                                ":hover": {
                                  transform: "scale(1.1)",
                                  transition: "transform 0.5s ease",
                                },
                              }}
                              titleAccess="Pin project on top"
                              onClick={() => {
                                console.log("Pinned Clicked", each?.id, each?.is_pinned);
                                PinedClicked(each?.id, each?.is_pinned);
                              }}
                            />
                          )} 
                          {each?.is_pinned && (
                            <PushPinIcon
                              titleAccess="Unpin"
                              onClick={() => {
                                PinedClicked(each?.id, each?.is_pinned);
                              }}
                              sx={{
                                color: "green",
                                fontSize: "1.5rem",
                                cursor: "pointer",
                                ":hover": {
                                  transform: "scale(1.1)",
                                  transition: "transform 0.5s ease",
                                },
                              }}
                            />
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={3} style={{
                                borderBottom: "1px solid",
                                display: "flex",
                                alignItems: "center",
                                textAlign: "center",
                                justifyContent: "space-between",
                                borderRight: "1px solid",
                                borderColor: 'aliceblue',
                            }}>
                                <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', width: '100%', }}>
                                    <Typography sx={{ padding: '0.2rem 0.5rem', borderLeft: "1px solid", borderColor: 'aliceblue', }}>{each.asi_added_unpublished_bom}</Typography>
                                    <Typography sx={{ padding: '0.2rem 0.5rem', borderLeft: "1px solid", borderColor: 'aliceblue', }}>{each.asi_added_published_bom}</Typography>
                                    <Typography sx={{ padding: '0.2rem 0.5rem', borderLeft: "1px solid", borderColor: 'aliceblue', }}>{each.asi_added_unpublished_costing}</Typography>
                                    <Typography sx={{ padding: '0.2rem 0.5rem', borderLeft: "1px solid", borderColor: 'aliceblue', }}>{each.asi_added_published_costing}</Typography>
                                </Box>
                            </Grid>
                  <Grid item xs={3} style={{
                                borderBottom: "1px solid",
                                display: "flex",
                                alignItems: "center",
                                textAlign: "center",
                                justifyContent: "space-between",
                                borderRight: "1px solid",
                                borderColor: 'aliceblue',
                            }}>
                                <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', width: '100%', }}>
                                    <Typography sx={{ padding: '0.2rem 0.5rem', flex: 1, }}>{each.new_zbc_drawing_unpublished}</Typography>
                                    <Typography sx={{ padding: '0.2rem 0.5rem', flex: 1, borderLeft: "1px solid", borderColor: 'aliceblue', }}>{each.new_zbc_drawing_published}</Typography>
                                    <Typography sx={{ padding: '0.2rem 0.5rem', flex: 1, borderLeft: "1px solid", borderColor: 'aliceblue', }}>{each.new_zbc_physical_unpublished}</Typography>
                                    <Typography sx={{ padding: '0.2rem 0.5rem', flex: 1, borderLeft: "1px solid", borderColor: 'aliceblue', }}>{each.new_zbc_physical_published}</Typography>
                                </Box>

                            </Grid>
                  <Grid item xs={2} style={{
                                borderBottom: "1px solid",
                                display: "flex",
                                alignItems: "center",
                                textAlign: "center",
                                justifyContent: "space-between",
                                borderRight: "1px solid",
                                borderColor: 'aliceblue',
                            }}>
                                <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', width: '100%', }}>
                                    <Typography sx={{ padding: '0.2rem 0.5rem', flex: 1, }}>{each.existing_sc_request}</Typography>
                                    <Typography sx={{ padding: '0.2rem 0.5rem', flex: 1, borderLeft: "1px solid", borderColor: 'aliceblue', }}>{each.existing_sc_published}</Typography>
                                </Box>
                            </Grid>
                  <Grid item xs={1} style={{
                                borderBottom: "1px solid",
                                display: "flex",
                                alignItems: "center",
                                textAlign: "center",
                                justifyContent: "space-between",
                                borderRight: "1px solid",
                                borderColor: 'aliceblue',
                            }} >
                                <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)', width: '100%', }}>
                                    <Typography sx={{ padding: '0.2rem 0.5rem', flex: 1, }}>{each.manufacturer_added_parts}</Typography>
                                </Box>
                            </Grid>
                </Grid>
              );
            })
          )}
        </Grid>
      </Paper>
    </>
  );
};

export default InterLandingTitleSectionNew;
