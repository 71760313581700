import { Box, CircularProgress, TextField, debounce } from '@mui/material';
import * as React from 'react';
import { API } from '../../../api-services';
import { ADMIN } from '../../../Redux/Services/admin.service';
import Swal from 'sweetalert2';
import { useRouteParams } from '../../../ui-reusable-component/useRouteParams';
import { useQueryDispatch } from '../../../CustomHook/useQueryDispatch';
import { getCustomValidations, resetCustomValidations } from '../../../Redux/Actions/customValidations.actions';
import { useSelector } from 'react-redux';
import { customValidationSelectors } from '../../../Redux/Reducers/customValidation.reducer';
import { ID } from '../../../utlis/commonInterfaces';
import { parameterConfigSelectors } from '../../../Redux/Reducers/parameterConfig.reducer';
import { getBOMConfigurationsByCategory, resetParameterConfigs } from '../../../Redux/Actions/parameterConfig.actions';
import { RootState } from '../../../Redux/Store/storeConfigurations';
import { workstationSelectors } from '../../../Redux/Reducers/workstation.reducer';
import validateValues from '../validateValues';
import { customValidate } from '../../../utlis/CustomValidate';
import { pickBy } from 'lodash';
import FormError from '../../../Core/FromError';
import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { userPermissionAction } from './BOMEntryLandingPage';


export interface INumberInputBoxProps {
    item: any,
    details: any,
    BOMValidationStatus: any,
    allValues: any
    getAllValues: any
}

const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

const label = { inputProps: { 'aria-label': 'Color switch demo' } };


export function DateInputBox(props: INumberInputBoxProps) {
    const { item, details, BOMValidationStatus, allValues, getAllValues } = props;
    const { vault, topVault, workstation, copyStatus } = useRouteParams<any>();
    const userAction = React.useContext(userPermissionAction)
    let UserAction = userAction && userAction?.action
    const [value, setValue] = React.useState<any>("");
    const [errorMessage, setErrorMessage] = React.useState<any>('');
    const [loading, setLoading] = React.useState<any>(false);
    const [isError, setIsError] = React.useState<any>(false);
    const [isShow, setIsShow] = React.useState<any>(true);
    const [fieldError, setFieldError] = React.useState<any>(undefined);
    const customValidations = useSelector(customValidationSelectors.selectAll);
    const parameterConfigs = useSelector(
        parameterConfigSelectors.selectNonImageNonTableChildernConfigs
    );

    const getObj = (vlt: any, tv: any) => {
        if (vlt == 0) {
            return { top_vault: tv }
        } else {
            return { vault: vlt }
        }
    }



    const workstationList = useSelector((state: RootState) => {
        return workstationSelectors.selectById(state, +workstation || 0);
    });


    const configToDbMap = parameterConfigs.reduce((result, config) => {
        result[config.id] = config.parameter_id;
        return result;
    }, {} as Record<string, ID>)

    const configToDataTypeMap = parameterConfigs.reduce((result, config) => {
        result[config.id] = config.data_type;
        return result;
    }, {} as Record<string, string>);
    const categoryIncludedInWorkstion = workstationList
        ? workstationList.categories.includes(1)
        : false;

    const allInputData = parameterConfigs.filter(data => data.data_type !== 'table').filter(data => data.data_type !== 'document');
    const allowedParameters = categoryIncludedInWorkstion
        ? workstationList?.allowed_parameters
        : undefined;

    var errors = validateValues(
        allInputData,
        { [item?.key]: value },
        allowedParameters,
        "parameter_id"
    );



    const formatDate = (stringDate:any) => {
        const momentObject = moment(stringDate);
        const formattedDate = momentObject.format('YYYY-MM-DD');
        return formattedDate;
      };




    React.useEffect(() => {
        if (item) {
            setValue(formatDate(item?.value))
        }


    }, [item])




    const validateField = (parameter_id: ID, value?: any) => {
        const val = value ?? { [item?.key]: +value }[parameter_id];
        let error: string | undefined;
        const config: any = parameterConfigs.find(
            config => config.parameter_id === parameter_id
        );

        if (config)
            error = validateValues([config], { [parameter_id]: val })[
                parameter_id
            ];

        // custom validations
        const relation = customValidations?.find(
            relation => relation.parameter_id === parameter_id
        );
        // { [item?.key]: +value },
        allValues[item?.key] = value

        if (relation)
            error = customValidate(
                relation.parameter_id,
                configToDataTypeMap,
                relation.validation,
                undefined,
                allValues,
                details,
                configToDbMap
            );
        setFieldError(error!);
    };



    const updateDataVaultParams = async (name: any, value: any, vlt: any, itm: any, tv: any) => {
        if (!isError) {
            setLoading(true)
            API.put(`/bom/component_details/74699/`, {
                [name]: value,
            }, {
                ...getObj(vlt, tv),
            }, 0).then((res: any) => {
                setLoading(false)
                BOMValidationStatus();
                ADMIN.toast.info("Updated Successfully")
            }).catch((err: any) => {
                const { data } = err.response;
                setLoading(false)
                Swal.fire({
                  title: "Error!",
                  text: data[0],
                  icon: "error",
                  confirmButtonText: "Ok",
                });
            })
        } else {

        }
    }

    const ExactDebounceParameters = React.useRef(
        debounce(async (value: any, name: any, idd: any, itemm: any, tv: any) => {
            await updateDataVaultParams(name, value, idd, itemm, tv);
        }, 1000)
    ).current;

    const handleChangeNumber = (newValue: any, name: any, itemm: any) => {
        validateField(itemm?.key, moment(newValue).format("YYYY-MM-DD"))
        ExactDebounceParameters(moment(newValue).format("YYYY-MM-DD"), name, vault, itemm, topVault);
        setValue(moment(newValue).format("YYYY-MM-DD"));
        setIsError(true)

    }

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <LocalizationProvider
                    variant="standard"
                    // disabled={actions.mapped_status}
                    dateAdapter={AdapterMoment}
                    sx={{
                        ".MuiSvgIcon-root ": {
                            fill: "primary.main !important",
                        },
                    }}
                >
                    <DatePicker
                        // id="revised_on"
                        // @ts-ignore
                        value={value}

                        // minDate={today}
                        disabled={((copyStatus == false || copyStatus == "false") && (UserAction?.includes("U") || UserAction?.includes("C"))) ? false : true}
                        onChange={(newValue: any) => handleChangeNumber(newValue, item?.key, item)}
                        renderInput={(params) => (
                            <TextField
                                name={item?.key}
                                {...params}
                                //@ts-ignore

                                sx={{
                                    marginBottom: "0.5rem",
                                    width: "100%",
                                    "& .MuiSvgIcon-root": {
                                        color: "primary.main",
                                    },
                                    "& .MuiFormLabel-root": {
                                        color: "primary.main",
                                    },
                                    "& .MuiFormLabel-root.Mui-focused": {
                                        color: "primary.main",
                                    },
                                    "& .Mui-error": {
                                        color: "#d32f2f",
                                        borderBottomColor: "#d32f2f",
                                    },
                                    ".MuiInputBase-root.MuiInput-root": {
                                        fontSize: "12px",
                                    },
                                    "& .MuiInput-underline:before": {
                                        borderBottomColor: "primary.light",
                                    },
                                    "& .MuiInput-underline:after": {
                                        borderBottomColor: "primary.main",
                                    },
                                }}
                                variant="standard"

                            />
                        )}
                    />
                </LocalizationProvider>
                {loading && <CircularProgress size={20} sx={{ margin: '5px' }} />}
            </Box>
            {fieldError && (
                <FormError error={fieldError} align="center" />
            )}
        </>
    );
}
