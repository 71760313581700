import React, { useEffect, useRef, useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import FunctionsIcon from "@mui/icons-material/Functions";
import { Card, CardMedia, Divider, Table, TableHead, TableBody, TableRow, TableCell, ButtonProps, Skeleton, Typography, Pagination } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import styles from "./BomViewModule.module.scss";
import { useHistory, useParams } from "react-router-dom";
import { API } from "../api-services";
import SubBouncingLoader from "../ui-reusable-component/SubBouncingLoader";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import RViewerJS from "viewerjs-react";
import { useBaseModal } from "../ComponentUI/SearchPage/useBaseModal";
import { isUndefined } from "lodash";
import Box from "@mui/material/Box";
import { DataGrid, GridCsvExportMenuItem, GridCsvExportOptions, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExportContainer, GridFooterContainer } from "@mui/x-data-grid";
import { TablePagination } from "@mui/material";
import BomThreeSixtyModal from "./BomView/BomThreeSixtyModal";
import Image from '../ViewModule/FeatureLandingPage/ArchitectureImageCard';
import NoImgLoader from '../Assets/images/loading-screen.gif'


import useWindowDimensions from "../ui-reusable-component/useWindowDimensions";
import ValidationImgs from "../Assets/images/validationImg.png";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IconButton } from "material-ui";


interface BomViewModuleProps {

}




const csvOptions: GridCsvExportOptions = { fileName: 'BOM module CSV file', delimiter: ",", utf8WithBom: true };

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: '2rem' }}>
    <span style={{ position: 'absolute', right: '0rem' }}>
      <GridToolbarColumnsButton />
      {/* <GridToolbarDensitySelector /> */}

      <CustomExportButton />
    </span>
  </GridToolbarContainer>
)


const BomViewModule: React.FunctionComponent<BomViewModuleProps> = (props) => {


  const [PartLoader, setPartLoader] = useState<any>(false);
  const [TableLoader, setTableLoader] = useState<any>(false);
  const [BomParameter, setBomParameter] = useState<any>();
  const [TableData, setTableData] = useState<any>();
  const ImageViewer = useRef<any>();
  const [srcImages, setSrcImages] = useState<any>();
  const [BomImages, setBomImages] = useState<any>();
  const [SigmaData, setSigmaData] = useState<any>({});
  const [SingleTableData, setSingleTableData] = useState<any>();
  const [ImageType, setImageType] = useState<any>();
  const { height, width } = useWindowDimensions();
  const [isOpenmodal, setIsopenModal] = useState<any>(false)
  const [ReportsData, setReportsData] = useState<any>()


  const [ThreeSixityImages, setThreeSixityImages] = useState<any>();
  const [ImageContainer, setImageContainer] = useState<any>(false)
  const [isActive, setIsActive] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const TableModalOpen = useBaseModal();
  const history = useHistory<any>()
  const ThreesixsityModalOpen = useBaseModal();
  const [pinStatus, setPinStatus] = useState<any>(false)
  const [pageNumber, setPageNumber] = useState(0);
  const [maxPageNumber, setMaxPageNumber] = useState(0);
  const { projectId, topVault, vault, treeabbr, leafNode } = useParams<any>();
  const [LoaderImages, setLoaderImages] = useState<any>(false)

  const TableContactHandler = (tableName: any) => {
    var data =
      TableData &&
      TableData.filter((item: any, index: any) => {
        return item?.table_name === tableName;
      });

    setSingleTableData(data[0]);

    var dataTableModalOpen = TableModalOpen.open();
  };


  const PinHandler = () => {
    setPinStatus(!pinStatus)
    setIsActive(true);
  }


  const toggleTopvault = () => {
    if (treeabbr == "product") {
      return { top_vault: topVault };
    } else {
      return { vault: vault };
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    console.log(newPage, "newPage")
    setPageNumber(newPage);
  };

  // const customPagination = () => {
  //   return (
  //     <div>
  //       <IconButton disabled={pageNumber == 0}>
  //       <ArrowBackIosIcon
  //         onClick={(e) => handleChangePage(e, pageNumber - 1)}
  //         style={{ cursor: 'pointer' }}

  //       />
  //       </IconButton>
  //       <IconButton disabled={pageNumber == 0}>
  //       <ArrowForwardIosIcon
  //         onClick={(e) => handleChangePage(e, pageNumber + 1)}
  //         style={{ cursor: 'pointer' }}
  //       />
  //       </IconButton>

  //     </div>
  //   )
  // }

  const CustomToolbarIncludingPagination = ({ count }: any) => (
    <GridToolbarContainer style={{ height: '2rem', }}>
      <div style={{ position: 'absolute', right: '0rem', display: "flex", flexDirection: "row" }}>
        <TablePagination
          rowsPerPageOptions={[100]}
          component="div"
          rowsPerPage={100}
          page={pageNumber}
          count={count}
          onPageChange={handleChangePage}
          // You can adjust other TablePagination props here
          sx={{ display: 'flex', justifyContent: 'flex-start', }}
        />
        <GridToolbarColumnsButton />
        <GridFooterContainer>

        </GridFooterContainer>

        <CustomExportButton />
      </div>
    </GridToolbarContainer>
  )




  // console.log(BomParameter,"BomParameterBomParameter")
  const handleClose = () => setIsopenModal(false);

  useEffect(() => {
    setPartLoader(true);
    setTableLoader(true);
    API.get("/cost/Bom_view_data/", {
      part: true,
      ...toggleTopvault(),
    }, 0)
      .then((res: any) => {
        ;
        // console.log(res.data, "Bom_view_data");
        setBomParameter(res?.data);
        setPartLoader(false);
        //   setBomparameter(res?.data?.numeric);
        //   setBomData(res?.data);
      })
      .catch((err: any) => {
        // ADMIN.toast.throwError(err, "Server error in Bom module");
        setBomParameter(null);
        setPartLoader(false);
      });

    API.get("/cost/Bom_view_data/", {
      table: true,
      ...toggleTopvault(),
    }, 0)
      .then((res: any) => {

        console.log(res.data, "Bom_view_data");
        setTableData(res?.data);
        setTableLoader(false);
      })
      .catch((err: any) => {
        // ADMIN.toast.throwError(err, "Server error in Bom module");
        setTableData(null);
        setTableLoader(false);
      });


  }, [vault, treeabbr, topVault]);


  useEffect(() => {

    setLoaderImages(true)
    setBomImages(null);
    API.get("/cost/Bom_view_data/", {
      ...toggleTopvault(),
    }, 0)
      .then((res: any) => {
        setLoaderImages(false)
        // console.log(res.data, "Bom_view_data");
        setBomImages(res?.data);

      })
      .catch((err: any) => {
        // ADMIN.toast.throwError(err, "Server error in Bom module");
        setBomImages(null);

        setLoaderImages(false)
      });

  }, [vault, treeabbr, topVault])

  const SigmaHandler = (Ids: any) => {

    // console.log(Ids, "Idsdddd");
    API.get("bom/table_param_sum/", {
      ...toggleTopvault(),
      parameter: Ids,
    }, 0)
      .then((res: any) => {
        setSigmaData(res?.data);
        // setBomImages(res?.data);
        // setLoader(false);
        //   setBomparameter(res?.data?.numeric);
        //   setBomData(res?.data);
      })
      .catch((err: any) => {
        // ADMIN.toast.throwError(err, "Server error in Bom module");
        // setBomImages(null);
        // setLoader(false);
      });
  };

  const ProductInfo = () => {
    let entries =
      BomParameter && Object.entries(BomParameter["parameter_details"]);

    let data =
      BomParameter &&
      entries.map(([key, val]: any) => {
        return (
          <>
            <TableRow>
              <TableCell sx={{ fontSize: '12px' }}
                className={styles.tableCell}
              >
                {key} :
              </TableCell>
              <TableCell sx={{ fontSize: '12px' }}
                className={styles.tableCell}
              >
                {val}
              </TableCell>
              <TableCell sx={{ fontSize: '12px' }} className={styles.tableCell}> </TableCell>
            </TableRow>
          </>
        );
      });
    return data;
  };


  var NoImagesData = () => {

    var valuedata = BomImages && BomImages["three_sixity"] && BomImages["three_sixity"][0];

    var lengthObj = valuedata && Object?.keys(valuedata)?.length
    return lengthObj;
  }
  // console.log(NoImagesData(), "NoImagesData")
  const BomImagesData = (ImageItem: any) => {
    let entries = BomImages && Object.entries(ImageItem);
    // height: 17.9rem;
    let data =
      BomImages &&
      entries.map(([key, val]: any) => {
        return (
          <Card sx={{ boxShadow: 'none' }}
            className={NoImagesData() == 0 ? styles.bomImageCardImage : NoducmentData() != 0 ? styles.bomImageCardDoc : styles.bomImageCard}
          // sx={{ width: "100%", height: NoImagesData() == 0 ? "16.9rem" : NoducmentData() != 0 ? "115px" : "115px" }}
          >
            {/* <CardMedia
              component="img"
              height="100%"
              src={val}
              alt={key}
              title={key}
              sx={{ objectFit: 'contain', cursor: "pointer" }}
            /> */}
            <Image src={val} placeholderImg={NoImgLoader} alt={key} title={key} style={{ objectFit: 'contain', cursor: "pointer", height: width < 1370 ? "100px" : '140px' }} />
          </Card>
        );
      });
    return data;
  };


  const BomImagesDataOuter = (ImageItem: any) => {
    let entries = BomImages && Object.entries(ImageItem);

    let data =
      BomImages &&
      entries.map(([key, val]: any) => {
        return (
          <Card
            className={styles.bomImageCard}
            sx={{ width: "100%", height: "115px", boxShadow: 'none', cursor: 'pointer' }}
          >
            <CardMedia
              component="img"
              height="100%"
              src={val}
              alt={key}
              title={key}
              sx={{ objectFit: 'contain' }}
            />
          </Card>
        );
      });
    return data;
  };


  const TableHeaderRows = (tableItem: any) => {
    let TableDataentries = TableData && Object.entries(tableItem);

    // console.log(TableDataentries, "TableDataentries");
    // let TableDataValue = TableDataentries.map(([key, val]: any) => key);
    // @ts-ignore

    let data =
      TableData &&
      TableDataentries.map(([key, val]: any) => {
        return (
          <>
            <TableCell
              className={styles.tableCell}
            // style={{ borderLeft: "1px solid rgba(224, 224, 224, 1)" }}
            >
              {SigmaData && Object.keys(SigmaData)?.includes(key)
                ? ` ${SigmaData[key]} `
                : ""}
            </TableCell>
          </>
        );
      });
    return data;
  };

  const BomparameterData = () => {
    var valuedata = BomParameter && BomParameter?.part_details[0];
    var data = BomParameter && valuedata && Object?.keys(valuedata);
    // console.log(data);
    // console.log(valuedata, "valuedata")
    var valuedata =
      data &&
      data?.map((key: any, value: any) => {
        // console.log(key,"checkKey")
        return {
          field: key,
          headerName: key,
          // maxWidth: key=="Value"? 300 : key=="Unit"? 80 :  key=="Parameter"?300: 200,
          // minWidth: key=="Value"? 300 : key=="Unit"? 80 :  key=="Parameter"?300:200,
          width: key == "Value" ? width > 1400 ? 350 : 200 : key == "Unit" ? width > 1400 ? 90 : 80 : key == "Parameter" ? width > 1400 ? 400 : 200 : width > 1400 ? 300 : 210,
          headerClassName: styles.Dataheader,
        };
      });

    return valuedata;

  };

  const ReportRow = () => {

    var valuedata = BomImages && BomImages?.document[0];
    var entriesData = BomImages && Object.entries(valuedata)
    var MapData = BomImages && entriesData.map(([key, val]: any) => {

      return (<>

        <TableRow>
          <TableCell sx={{ padding: '0.2rem 0.5rem' }}>{key}</TableCell>
          <TableCell sx={{ padding: '0.2rem 0.5rem', width: '20rem' }}>
            <a target='_blank' href={val[2]} >{val[0]}</a>
          </TableCell>
          <TableCell sx={{ padding: '0.2rem 0.5rem', width: '5rem' }}>
            {val[1]}
          </TableCell>
        </TableRow>
      </>)

    })
    return MapData;
  }

  const BomparameterValue = () => {
    var valuedata =
      BomParameter &&
      BomParameter?.part_details.map((item: any, id: any) => ({
        ...item,
        id: id + 1,
      }));

    return valuedata;
  };

  const dataRender: any = {
    columns: BomparameterData(),
    rows: BomparameterValue(),
  };




  // const ClosedExpendHandler = () => {
  //   // setExpendBom(false)
  //   // @ts-ignore
  //   sessionStorage.setItem("bomModule", false)
  //   history.push(`/view/${projectId}/${topVault}/${vault}/${treeabbr}/${leafNode}/false`)
  // }

  const handleClick = (srcImg: any) => {
    ImageViewer.current.src = srcImg;
    ImageViewer.current?.click?.();
    setSrcImages(srcImg);
  };


  const ThreeSixityHandler = (ImageType: any) => {
    // let entries = BomImages && Object.entries(BomImages["three_sixity"]);
    ThreesixsityModalOpen.open();
    setIsopenModal(true)
    setImageType(ImageType);


    // let data =
    //   BomImages &&
    //   entries.map(([key, val]: any) => {
    //     return Object.entries(val).map(([key, value]: any) => {
    //       if (key == ImageType) {
    //         return value;
    //       }
    //     });
    //   });



  };

  const ThreeSixityCard = (Images: any) => {

    let TableDataentries = BomImages && Object.keys(Images);
    let TableDatavalue = BomImages && Object.values(Images);
    let entries = BomImages && Object.entries(Images);

    let data =
      BomImages &&
      entries.map(([key, val]: any) => {

        return Object.entries(val)
          .reverse()
          .map(([key1, val1]: any) => {
            return (
              <>
                {key1 == 1 && (
                  <Card sx={{ boxShadow: 'none' }}
                    className={NoImagesData() == 0 ? styles.bomImageCardImage : NoducmentData() != 0 ? styles.bomImageCardDoc : styles.bomImageCard}
                  // className={styles.bomImageCard}
                  // sx={{ width: "100%", height: "115px", cursor: "pointer" }}
                  >
                    <CardMedia
                      component="img"
                      height="100%"
                      src={val1}
                      alt={key}
                      title={key}
                      sx={{ objectFit: 'contain' }}
                      onClick={() => ThreeSixityHandler(key)} />

                  </Card>

                )}

                {key1 == 0 && (
                  // @ts-ignore
                  <RViewerJS>
                    <Card
                      className={NoImagesData() == 0 ? styles.bomImageCardImage : NoducmentData() != 0 ? styles.bomImageCardDoc : styles.bomImageCard}
                      sx={{ width: "100%", height: "115px", cursor: "pointer", boxShadow: 'none' }}
                    >
                      <CardMedia
                        component="img"
                        height="100%"
                        src={val1}
                        alt={key}
                        title={key}
                        sx={{ objectFit: 'contain' }}
                      />
                    </Card>

                  </RViewerJS>
                )}
              </>
            );
          });
      });

    return data;
  };

  const TableBodyRows = (tableItem: any) => {
    let TableDataentries = TableData && Object.entries(tableItem);
    let TableDataValue = TableData && Object.values(tableItem);

    return TableDataentries.map(([key, val]: any) => {
      return (
        <>
          <TableCell className={styles.tablemultiCellData}>
            {key == "Image" ? (
              <CameraAltIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleClick(val);
                }}
              />
            ) : (
              `${val}`
            )}
          </TableCell>
        </>
      );
    });
  };

  const PcbTableHeader = (HeaderData: any) => {
    var Pcbdata: any = BomParameter && Object.keys(HeaderData);

    var valuedata: any =
      HeaderData &&
      HeaderData?.map((key: any, value: any) => {

        return {
          field: key?.name,
          flex: 1,


          align: key?.data_type === "int" && "left",
          headerAlign: key?.data_type === "int" && "left",
          type: key?.data_type === "int" ? "number" : "string",
          //  headerAlign: 'center',
          // type: 'string',
          disableExport: key?.data_type === "image" ? true : false,
          headerName:
            SigmaData && Object.keys(SigmaData)?.includes(key?.name)
              ? `${key?.name} (${SigmaData[key?.name]}) `
              : key?.name,
          headerClassName: styles.Dataheader,


          renderCell: (params: any) => {
            console.log(params.row[key?.name], "params")
            if (key?.data_type == "image") {
              return (
                <CameraAltIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleClick(params.row[key?.name]);
                  }}
                />
              );
            } else {
              return <span title={params.row[key?.name]}>{params.row[key?.name]}</span>;
            }
          },
        };
      });
    return valuedata;
  };

  const PcbTableRow = (Rowdata: any) => {
    var valuedata =
      Rowdata &&
      Rowdata.map((item: any, id: any) => ({
        ...item,
        id: id + 1,
      }));

    return valuedata;
  };

  var NoducmentData = () => {
    var valuedata = BomImages && BomImages?.document[0];
    var lengthObj = valuedata && Object?.keys(valuedata)?.length
    return lengthObj;
  }









  return (
    <>
      <div className={styles.tableContainer}>

        {!isUndefined(BomParameter) && Object.keys(BomParameter?.parameter_details)?.length == 0 ?

          <div style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            margin: "9rem"
          }}>
            <img src={ValidationImgs} style={{ width: "21rem" }} />
            <Typography>We are working on it...</Typography>
          </div>


          :
          <div className={styles.bomViewTableSec}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >

            {isOpenmodal &&
              <BomThreeSixtyModal
                isOpen={isOpenmodal}
                onCloseModal={handleClose}
                ThreeSixityImages={BomImages && BomImages}
                query={toggleTopvault()}

                ImageType={ImageType && ImageType}
              />
            }

            <div style={{ display: "none" }}>
              {/* @ts-ignore */}
              <RViewerJS>
                <img
                  src={ImageViewer.current?.src}
                  alt="image"
                  ref={ImageViewer}
                />
              </RViewerJS>
            </div>
            <Box sx={{ maxHeight: { lg: TableData && TableData?.length !== 0 ? '42rem' : "52rem", xl: TableData && TableData?.length !== 0 ? "44rem" : "56rem" } }} className={styles.bomTop2div}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3">
              {BomParameter && (
                <Box sx={{ display: 'flex', }}>
                  <Box sx={{ width: '55%', borderRight: '1px solid #007fff14' }}>
                    <TableContainer sx={{ padding: "1rem 1rem 0", }}>
                      {PartLoader ? (
                        <>
                          <Skeleton sx={{ width: "37vw", height: "3rem" }} />
                          <Skeleton sx={{ width: "37vw", height: "3rem" }} />
                          <Skeleton sx={{ width: "37vw", height: "3rem" }} />
                          <Skeleton sx={{ width: "37vw", height: "3rem" }} />
                        </>
                      ) : (

                        <Table>
                          <TableBody>{ProductInfo()}</TableBody>
                        </Table>
                      )}
                    </TableContainer>
                    {PartLoader ? (
                      // <SubBouncingLoader />
                      <Box sx={{ height: '4rem', marginTop: '2rem' }}>
                        <Skeleton sx={{ width: "37vw", height: "3rem", marginLeft: '1.2rem' }} />
                        <Skeleton sx={{ width: "37vw", height: "3rem", marginLeft: '1.2rem' }} />
                        <Skeleton sx={{ width: "37vw", height: "3rem", marginLeft: '1.2rem' }} />
                      </Box>
                    ) : (
                      <Box sx={{ width: "100%", padding: "0 1rem" }}>
                        {/* <Typography style={{ width: '100%', textAlign: 'center' }}>BOM Parameters</Typography> */}
                        {BomparameterData() && BomparameterValue() && (
                          <DataGrid
                            {...dataRender}
                            rowHeight={30}
                            hideFooterPagination="true"
                            headerHeight={30}
                            components={{ Toolbar: () => { return CustomToolbar() } }}
                            getRowHeight={() => 'auto'}
                            autoHeight={true}
                            hideFooter={true}
                            sx={{
                              fontSize: '12px',
                              borderColor: "primary.light !important",
                              "& .MuiDataGrid-cell:hover": {
                                color: "primary.main",
                              },

                              '&.MuiDataGrid-root.MuiDataGrid-autoHeight .MuiDataGrid-row--lastVisible .MuiDataGrid-cell': {
                                borderBottomColor: 'primary.light',
                              },
                              '&.MuiDataGrid-root .MuiDataGrid-cell': {
                                borderBottomColor: 'primary.light',
                                minHeight: '26px',
                              },
                            }}
                          />
                        )}
                      </Box>
                    )}
                  </Box>
                  <Box sx={{ width: '44%', margin: '0rem', }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3">

                    {LoaderImages ? <>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          //  height: "20rem",
                        }}>

                        <Box
                          sx={{
                            display: "flex",
                            columnGap: "3rem",
                            flexDirection: "row",
                            height: "8rem",
                            marginTop: '0rem',
                            padding: '0 0.5rem'
                          }}
                        >
                          <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                          <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                          <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            columnGap: "3rem",
                            flexDirection: "row",
                            height: "8rem",
                            marginTop: '0rem',
                            padding: '0 0.5rem'
                          }}
                        >
                          <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                          <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                          <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            columnGap: "3rem",
                            flexDirection: "row",
                            height: "8rem",
                            marginTop: '0rem',
                            padding: '0 0.5rem'
                          }}
                        >
                          <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                          <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                          <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            columnGap: "3rem",
                            flexDirection: "row",
                            height: "8rem",
                            marginTop: '0rem',
                            padding: '0 0.5rem'
                          }}
                        >
                          <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                          <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                          <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                        </Box></Box>
                    </> :
                      <>
                        <Box sx={{ width: '100%' }}>
                          {/* @ts-ignore */}
                          <RViewerJS>
                            {BomImages &&
                              BomImages["image"].map((BomImages: any, indx: any) => {
                                return (
                                  <div
                                    className={NoImagesData() == 0 ? styles.BomImageNoImage : NoducmentData() != 0 ? styles.BomImage : styles.BomImageNoDoc}
                                    // className={styles.BomImage}
                                    adl-scrollbar="true"
                                    adl-scrollbar-width="0.3"
                                  >
                                    {BomImagesData(BomImages)}
                                  </div>
                                );
                              })}
                          </RViewerJS>
                          <Divider sx={{ margin: "1rem", display: NoImagesData() !== 0 ? "block" : NoImagesData() != 0 ? "block" : "none" }} />
                          <div
                            style={{ display: BomImages && BomImages["three_sixity"]?.map((images: any, index: any) => Object?.keys(images)?.length > 0 ? "block" : "none") }}
                          >

                            {BomImages &&
                              BomImages["three_sixity"]?.map((images: any, index: any) => {
                                return (
                                  <>
                                    <div
                                      className={NoducmentData() == 0 ? styles.NoDocmentThreeSixty : styles.BomImagethreeSixty}
                                      adl-scrollbar="true"
                                      adl-scrollbar-width="0.3"
                                    >
                                      {/* @ts-ignore */}
                                      {ThreeSixityCard(images)}
                                    </div>
                                  </>
                                );
                              })}


                          </div>
                          <Divider sx={{ margin: "1rem", display: NoducmentData() !== 0 ? "block" : NoducmentData() != 0 ? "block" : "none" }} />

                          {BomImages && BomImages["document"] && NoducmentData() != 0 &&
                            <div className={styles.documentSec}>
                              <TableContainer>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell sx={{ padding: '0.2rem 0.5rem' }}>Parameter</TableCell>
                                      <TableCell sx={{ padding: '0.2rem 0.5rem', width: '20rem' }}>Document</TableCell>
                                      <TableCell sx={{ padding: '0.2rem 0.5rem', width: '5rem' }}>Type</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {ReportRow()}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>}
                        </Box>



                      </>
                    }
                    {/* </div> */}
                  </Box>
                </Box>
              )}

            </Box>
            <>
              {TableData &&
                TableData.map((tableItem: any, ind: any) => {

                  return (
                    <>
                      {TableLoader ? (
                        <>
                          <Skeleton sx={{ width: "70vw", height: "3rem", marginLeft: '1.2rem' }} />
                          <Skeleton sx={{ width: "70vw", height: "3rem", marginLeft: '1.2rem' }} />
                          <Skeleton sx={{ width: "70vw", height: "3rem", marginLeft: '1.2rem' }} />
                          <Skeleton sx={{ width: "70vw", height: "3rem", marginLeft: '1.2rem' }} />
                        </>
                      ) : (
                        <>
                          <Box sx={{ display: 'flex', columnGap: '1rem', alignItems: 'center', padding: '0.2rem 1rem', width: '50%', zIndex: '1000', position: 'relative' }}>
                            <Typography sx={{ fontSize: '1rem', marginLeft: '1rem' }}> {tableItem?.table_name}</Typography>
                          </Box>
                          <Box sx={{ width: "100%", padding: '0 1rem', marginTop: '-2rem' }}>
                            {/* height: tableItem?.data.length == 1 ? 200 : 337, */}
                            <DataGrid

                              headerHeight={48}
                              rowHeight={36}


                              rows={PcbTableRow(pageNumber * 100 > tableItem?.data.length ? tableItem?.data?.slice((pageNumber) * 100, (pageNumber + 1) * 100) : tableItem?.data?.slice((pageNumber) * 100, tableItem?.data.length))}
                              // rows={PcbTableRow(tableItem?.data)}
                              columns={PcbTableHeader(tableItem && tableItem?.header_item)}
                              // pageSize={5}
                              // rowsPerPageOptions={[5]}
                              sx={{
                                fontSize: '12px',
                                borderColor: 'primary.light',
                                '&.MuiDataGrid-root.MuiDataGrid-autoHeight .MuiDataGrid-row--lastVisible .MuiDataGrid-cell': {
                                  borderBottomColor: 'primary.light',
                                },
                                '&.MuiDataGrid-root .MuiDataGrid-cell': {
                                  borderBottomColor: 'primary.light',
                                },
                                '& .MuiDataGrid-root.MuiDataGrid-autoHeight.MuiDataGrid-root--densityCompact.MuiDataGrid-root': {
                                  border: 'none !important',
                                },

                              }}
                              density="compact"
                              // checkboxSelection
                              // @ts-ignore
                              // components={{ Toolbar: GridToolbar }}

                              pageSize={100}
                              components={{ Toolbar: () => { return tableItem?.data.length > 100 ? <CustomToolbarIncludingPagination count={tableItem?.data.length} /> : <CustomToolbar /> } }}
                              autoHeight={true}
                              disableSelectionOnClick
                              pagination
                              hideFooterPagination
                            // experimentalFeatures={{ newEditingApi: true }}
                            />
                            {/* {tableItem?.data.length > 100 && <Pagination count={Math.ceil(tableItem?.data.length / 100)} shape="rounded" showFirstButton showLastButton onClick={(e:any) => handlePageChange(e)} />  } */}

                          </Box>
                        </>
                      )}
                    </>
                  );
                })}
            </>


          </div>
        }
      </div>
    </>
  );
};

export default BomViewModule;
