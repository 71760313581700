import { MODULES } from "../../Constants/modules.constants";
import { ID } from "../../utlis/commonInterfaces";
import { transformConfigurationsFromApi } from "../../utlis/configuration.constants";
import { ParameterConfig } from "../Reducers/parameterConfig.reducer";
import { API } from "./index.service";

const url = "/bom/parameter_mapping/";

export default {
    /**
     * @param projectId
     * @param all boolean - get all the configs irrespective of table_id
     */
    get ( projectId: ID, all?: boolean,stage?:any ) {
        return API.get( url, {
            project: projectId,
            module: MODULES.IDEA_CONFIGURATION,
            all,
            stage: stage
        }, 0 );
    },
    getById ( parameterId: ID ) {
        return API.get( url, {
            id: parameterId,
        }, 0 );
    },
    getByTableId ( tableId: ID ) {
        return API.get( url, { table_id: tableId }, 0 );
    },
    async inherit (
        parameters: Record<string, any>[],
        meta?: Record<string, any>
    ) {
        const { data, ...rest } = await API.post(
            url,
            Object.assign(
                { parameters, module: MODULES.IDEA_CONFIGURATION },
                meta
            )
        );

        return { data: transformConfigurationsFromApi( data ), ...rest };
    },
    update ( configId: ID, updates: Partial<ParameterConfig> ) {
        return API.put( url + configId + "/", updates );
    },
    delete ( configId: ID ) {
        return API.delete( url + configId + "/" );
    },
};
