import {
  Box,
  ButtonProps,
  Divider,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarContainer,
  GridToolbarExportContainer,
} from "@mui/x-data-grid";
import * as React from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import Popover from "@mui/material/Popover";
import useWindowDimensions from "../../../ui-reusable-component/useWindowDimensions";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { API } from "../../../api-services";

export interface IInfoParameterDetailsProps {
  onCloseModal: any;
  isOpen: any;
  Item: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "60vw",
  maxWidth: "80vw",
  // height: '80vh',
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};
const csvOptions: GridCsvExportOptions = {
  fileName: "Table In CSV",
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: "1.5rem", marginBottom: "0.5rem" }}>
    <span style={{ position: "absolute", right: "0rem" }}>
      {/* <GridToolbarColumnsButton /> */}
      {/* <GridToolbarDensitySelector /> */}
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

export function MHRRevisionInfoModal(props: IInfoParameterDetailsProps) {
  const { height, width } = useWindowDimensions();
  const { projectId } = useRouteParams<any>();
  const [infoData, setInfoData] = React.useState<any>([]);

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [Loader, setLoader] = React.useState<any>(false);

  const open = Boolean(anchorEl);

  const { onCloseModal, isOpen, Item } = props;

  const getInfoData = () => {
    setLoader(true);
    API.get(
      "db/check_variant/",
      {
        type: "machine",
        id: Item && Item?.id,
      },
      0
    )
      .then((res: any) => {
        setLoader(false);
        // setInfoData(res.data)
        let rows: any = [];

        const dataGridDataGenerator: any = res.data?.map(
          (ideaItem: any, index: any) => {
            let objShared: any = {
              id: index + 1,
              ...ideaItem,
            };
            rows.push(objShared);
          }
        );
        setInfoData(rows);
      })
      .catch((err: any) => {
        setLoader(false);
      });
  };

  React.useEffect(() => {
    getInfoData();
  }, [Item]);
  var Columns: any = [
    {
      field: "vehicle_category",
      headerName: "Product Category",
      width: width > 1400 ? 250 : 150,
    },

    {
      field: "part_name",
      headerName: "Part Name",
      minWidth: width > 1400 ? 300 : 220,
      flex: 1,
    },

    {
      field: "part_no",
      headerName: "Part No.",
      minWidth: width > 1400 ? 300 : 220,
      flex: 1,
    },
    {
      field: "costing_name",
      headerName: "Costing/Scenario",
      minWidth: width > 1400 ? 300 : 220,
      flex: 1,
    }
  ];
  return (
    <div>
      <Modal open={isOpen} onClose={onCloseModal}>
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              MHR Revision Info
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box
            sx={{ height: "65vh", width: "100%" }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            {infoData && (
              <DataGrid
                headerHeight={48}
                rowHeight={36}
                loading={Loader}
                rows={infoData && infoData}
                columns={Columns}
                density="compact"
                // autoHeight={true}
                components={{
                  Toolbar: () => {
                    return CustomToolbar();
                  },
                }}
                sx={{
                  "&.MuiDataGrid-root .MuiDataGrid-cell": {
                    borderBottomColor: "primary.light",
                  },
                  "& ::-webkit-scrollbar": {
                    width: "0.3rem",
                    height: "0.3rem",
                  },
                  "& ::-webkit-scrollbar-thumb": {
                    // background: $thumb-color;
                    backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                    borderRadius: "10px",
                  },
                  "& ::- webkit-scrollbar-thumb: hover ": {
                    // width: "4px",
                    backgroundColor: "#045DE9 !important",
                  },
                  "::-webkit-scrollbar-track": {
                    backgroundColor: "#e1e1f3 !important",
                  },
                }}
              />
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
