import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, Skeleton, Box } from "@mui/material";
import moment from "moment";
import { API } from "../../../api-services";
import EqualizerIcon from '@mui/icons-material/Equalizer';
import { useGraphBaseModal } from "../useGraphBaseModal";
import GraphShowModal from "../GraphShowModal";
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';

interface ModuleWiseActivityCountI { }

const ModuleWiseActivityCount: React.FC<ModuleWiseActivityCountI> = (props) => {
    const [dataGet, setDataGet] = useState<any>();
    const [dataLoad, setDataLoad] = useState<boolean>(true);
    const graphBaseModal = useGraphBaseModal();

    useEffect(() => {
        API.get(
            `/cost/module_activity_status/`, {}, 0)
            .then((res: any) => {
                setDataLoad(false);
                setDataGet(res?.data);
            })
    }, [])

    const Module: any = dataGet && Object.keys(dataGet);

    const TimePeriod = (period: any, type: any) => {
        graphBaseModal.open(period, type);
    }

    function Row(props: { item: any }) {
        const { item } = props;
        const [open, setOpen] = React.useState(false); 
        return (
          <React.Fragment>
               <TableRow>
                    <TableCell sx={{ fontWeight: 'bold', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', }}>
                        <IconButton
                           aria-label="expand row"
                           size="small"
                           onClick={() => setOpen(!open)}
                         >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                         </IconButton>
                         {item}
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', }}>{(dataGet?.[item]?.internal?.["1day"] + dataGet?.[item]?.external?.["1day"])?.toLocaleString("en-IN")}</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', }}>{(dataGet?.[item]?.internal?.["7day"] + dataGet?.[item]?.external?.["7day"])?.toLocaleString("en-IN")}</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', }}>{(dataGet?.[item]?.internal?.["30day"] + dataGet?.[item]?.external?.["30day"])?.toLocaleString("en-IN")}</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', }}>{(dataGet?.[item]?.internal?.["365day"] + dataGet?.[item]?.external?.["365day"])?.toLocaleString("en-IN")}</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', }}>{(dataGet?.[item]?.internal?.["all"] + dataGet?.[item]?.external?.["all"])?.toLocaleString("en-IN")}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: '0' }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box>
                    <Table size="small">
                       <TableRow>
                           <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem 0.2rem 3rem', fontSize: '1rem', width:{sm:'14rem',md:'14rem',lg:'11rem',xl:'11rem'} }}>External</TableCell>
                           <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem',width:{sm:'11.2rem',md:'10rem',lg:'7.7rem',xl:'10rem'} }}>{(dataGet?.[item]?.external?.["1day"])?.toLocaleString("en-IN")}</TableCell>
                           <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem',width:{sm:'9.6rem',md:'10.1rem',lg:'8rem',xl:'10.1rem'} }}>{(dataGet?.[item]?.external?.["7day"])?.toLocaleString("en-IN")}</TableCell>
                           <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem',width:{sm:'10.4rem',md:'11.5rem',lg:'8.5rem',xl:'11.5rem'} }}>{(dataGet?.[item]?.external?.["30day"])?.toLocaleString("en-IN")}</TableCell>
                           <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem',width:{sm:'11rem',md:'12.2rem',lg:'9.7rem',xl:'12.2rem'} }}>{(dataGet?.[item]?.external?.["365day"])?.toLocaleString("en-IN")}</TableCell>
                           <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem' }}>{(dataGet?.[item]?.external?.["all"])?.toLocaleString("en-IN")}</TableCell>
                       </TableRow>
                       <TableRow>
                           <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem 0.2rem 3rem', fontSize: '1rem',width:{sm:'14rem',md:'14rem',lg:'11rem',xl:'11rem'} }}>Internal</TableCell>
                           <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem',width:{sm:'11.2rem',md:'10rem',lg:'7.7rem',xl:'10rem'}}}>{(dataGet?.[item]?.internal?.["1day"])?.toLocaleString("en-IN")}</TableCell>
                           <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem',width:{sm:'9.6rem',md:'10.1rem',lg:'8rem',xl:'10.1rem'} }}>{(dataGet?.[item]?.internal?.["7day"])?.toLocaleString("en-IN")}</TableCell>
                           <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem',width:{sm:'10.4rem',md:'11.5rem',lg:'8.5rem',xl:'11.5rem'} }}>{(dataGet?.[item]?.internal?.["30day"])?.toLocaleString("en-IN")}</TableCell>
                           <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem',width:{sm:'11rem',md:'12.2rem',lg:'9.7rem',xl:'12.2rem'} }}>{(dataGet?.[item]?.internal?.["365day"])?.toLocaleString("en-IN")}</TableCell>
                           <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem' }}>{(dataGet?.[item]?.internal?.["all"])?.toLocaleString("en-IN")}</TableCell>
                       </TableRow>
                    </Table>
                    </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
          </React.Fragment>
        );
      }

    return (<>
        {!dataLoad ? <>
            <GraphShowModal Open={graphBaseModal.isOpen} Close={graphBaseModal.close} id={graphBaseModal.id} analysisType={graphBaseModal.analysisType} />
            {dataGet && <Table size="small">
                <TableHead sx={{ position: 'sticky', top: '0', backgroundColor: 'white', zIndex: '1' }}>
                    <TableRow>
                        <TableCell sx={{ cursor: 'pointer', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', textAlign: 'left', lineHeight: '1.2', backgroundColor: 'primary.light',width: {sm:'14rem',md:'14rem',lg:'11rem',xl:'11rem'} }} rowSpan={2}>Module Activity</TableCell>
                        <TableCell sx={{ cursor: 'pointer', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', backgroundColor: 'primary.light',width:{sm:'11.2rem',md:'10rem',lg:'7.7rem',xl:'10rem'} }} title={`${moment().format('DD-MMM')}`}>Today <EqualizerIcon titleAccess='Graph' style={{ cursor: "pointer", marginBottom: '-0.3rem' }} onClick={() => { return TimePeriod('1', 'moduleAnalysis') }} /></TableCell>
                        <TableCell sx={{ cursor: 'pointer', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', backgroundColor: 'primary.light',width:{sm:'9.6rem',md:'10.1rem',lg:'8rem',xl:'10.1rem'} }} title={`${moment().format('DD-MMM')} - ${moment().subtract(7, 'd').format('DD-MMM')}`}>7D <EqualizerIcon titleAccess='Graph' style={{ cursor: "pointer", marginBottom: '-0.3rem' }} onClick={() => { return TimePeriod('7', 'moduleAnalysis') }} /></TableCell>
                        <TableCell sx={{ cursor: 'pointer', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', backgroundColor: 'primary.light',width:{sm:'10.4rem',md:'11.5rem',lg:'8.5rem',xl:'11.5rem'} }} title={`${moment().format('DD-MMM')} - ${moment().subtract(30, 'd').format('DD-MMM')}`}>30D <EqualizerIcon titleAccess='Graph' style={{ cursor: "pointer", marginBottom: '-0.3rem' }} onClick={() => { return TimePeriod('30', 'moduleAnalysis') }} /></TableCell>
                        <TableCell sx={{ cursor: 'pointer', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', backgroundColor: 'primary.light',width:{sm:'11rem',md:'12.2rem',lg:'9.7rem',xl:'12.2rem'} }} title={`${moment().format('DD-MMM')} - ${moment().subtract(365, 'd').format('DD-MMM')}`}>365D <EqualizerIcon titleAccess='Graph' style={{ cursor: "pointer", marginBottom: '-0.3rem' }} onClick={() => { return TimePeriod('365', 'moduleAnalysis') }} /></TableCell>
                        <TableCell sx={{ cursor: 'pointer', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', backgroundColor: 'primary.light',}} title={'From the day one'}>All <EqualizerIcon titleAccess='Graph' style={{ cursor: "pointer", marginBottom: '-0.3rem' }} onClick={() => TimePeriod('all', 'moduleAnalysis')} /></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Module && Module?.map((item: any,index:number) => {
                      return  <Row key={index+1} item={item} />
                    })}
                </TableBody>
            </Table>}
        </>
            :
            <Box sx={{ height: '100%', width: '100%' }}>
                <Skeleton variant="rectangular" height="100%" />
            </Box>
        }
    </>
    )
}

export default ModuleWiseActivityCount;