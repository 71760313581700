import React, { useEffect } from 'react';

const JiraIssueCollector: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://asiindia.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-3ddrgv/b/9/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=730beeeb";
    script.type = "text/javascript";
    script.async = true;

    script.onload = () => {
      console.log("JIRA Issue Collector loaded successfully.");

      if ((window as any).ATL_JQ_PAGE_PROPS) {
        (window as any).ATL_JQ_PAGE_PROPS.triggerFunction = function(showCollectorDialog: any) {
          document.getElementById('jira-collector-trigger')?.addEventListener('click', function(e) {
            e.preventDefault();
            showCollectorDialog();
          });
        };
      }
    };

    script.onerror = (e) => {
      console.error("Failed to load JIRA Issue Collector", e);
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      {/* <button id="jira-collector-trigger">Report an Issue</button> */}
    </div>
  );
};

export default JiraIssueCollector;
