import * as React from 'react';
import Box from '@mui/material/Box';
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarExportContainer,
	GridCsvExportMenuItem,
	GridCsvExportOptions,
	GridToolbarColumnsButton,
} from '@mui/x-data-grid';
import useWindowDimensions from '../../ui-reusable-component/useWindowDimensions';
import Button, { ButtonProps } from '@mui/material/Button';
import ShareIcon from '@mui/icons-material/Share';
import EditIcon from '@mui/icons-material/Edit';
import {
	Bar,
	BarChart,
	Legend,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';
import DeleteIcon from '@mui/icons-material/Delete';
import extlink from '../../Assets/images/ext_link.svg';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import moment from 'moment';
import { API } from '../../api-services';
import {
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography,
	Divider,
	CircularProgress,
	IconButton,
	Badge,
} from '@mui/material';
import Swal from 'sweetalert2';
import { useBaseParamsModal } from '../../CustomHook/useBaseParamsModal';
import { EditScenarioModal } from './EditScenarioModal';
import { ShareScenarioModal } from './ShareScenarioModal';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import ShareDetailsModal from './ShareDetailsModal';
import InfoIcon from '@mui/icons-material/Info';
import Popover from '@mui/material/Popover';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { LoadingButton } from '@mui/lab';
import DownloadIcon from '@mui/icons-material/Download';
import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import { useSelector } from 'react-redux';
import { useBaseModal } from '../SearchPage/useBaseModal';
import { DialogComponent } from '../../utlis/DialogComponent';
import { userSelectors } from '../../Redux/Reducers/user.reducer';
import { useHistory } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AUTH } from '../../Redux/Services/auth.service';
import { AddScenarioModal } from './AddScenarioModal';
import { AddScenarioTemp } from './AddScenarioTemp';
import { EditScenarioModalAdmin } from './EditScenarioModalAdmin';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { object } from 'yup';

import Avatar from '@mui/material/Avatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';

export interface ICostingScenarioSectionProps {
	count: any;
	newScenarioRequestCount: any;
}

const StyledMenu = styled((props: MenuProps) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 6,
		minWidth: 180,
		color:
			theme.palette.mode === 'light'
				? 'rgb(55, 65, 81)'
				: theme.palette.grey[300],
		'& .MuiMenu-list': {
			padding: '4px 0',
		},
		'& .MuiMenuItem-root': {
			'& .MuiSvgIcon-root': {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5),
			},
			'&:active': {
				backgroundColor: alpha(
					theme.palette.primary.main,
					theme.palette.action.selectedOpacity
				),
			},
		},
	},
}));

const csvOptions: GridCsvExportOptions = {
	fileName: 'Idea Approve Table User Session In CSV',
	delimiter: ',',
	utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
	<GridToolbarExportContainer {...props}>
		<GridCsvExportMenuItem options={csvOptions} />
	</GridToolbarExportContainer>
);

const CustomToolbar = () => (
	<GridToolbarContainer
		sx={{ height: '2.5rem', justifyContent: 'flex-end', paddingRight: '1rem' }}>
		<span
		// style={{ position: "absolute", right: "0rem" }}
		>
			<GridToolbarColumnsButton />
			<CustomExportButton />
		</span>
	</GridToolbarContainer>
);

export function CostingScenarioSection(props: ICostingScenarioSectionProps) {
	const { height, width } = useWindowDimensions();
	const requestEditModal = useBaseParamsModal();
	const history = useHistory();
	const { count, newScenarioRequestCount } = props;
	const userProfile = useSelector(userSelectors.selectAll);
	const {
		projectId,
		projectName,
		topVault,
		vault,
		scenarioId,
		treeabbr,
		createdType,
		scenarioType,
		requestId,
	} = useRouteParams<any>();
	const editScenarioModal = useBaseParamsModal();
	const shareScenario = useBaseParamsModal();
	const shareDetails = useBaseParamsModal();
	const [TableData, setTableData] = React.useState<any>([]);
	const [TableDataNew, setTableDataNew] = React.useState<any>([]);
	const [currentPage, setCurrentPage] = React.useState<number>(0);
	const [totalCount, setTotalcount] = React.useState<number>(0);
	const [anchorDownEl, setAnchorDownEl] = React.useState<null | HTMLElement>(
		null
	);
	const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
	const openDownload = Boolean(anchorDownEl);
	const getObj = () => {
		if (vault == 0) {
			return { vehicle_category: projectId };
		} else {
			return { vault: vault };
		}
	};
	const [costStatus, setCostStatus] = React.useState<any>(null);
	const [Loader, setLoader] = React.useState<any>(false);
	const [counter, setCount] = React.useState<any>(0);
	const [CompareGraphData, SetCompareGraphData] = React.useState<any>([]);
	const [hoveredData, setHoveredData] = React.useState<any>({});
	const [copyLoading, setCopyLoading] = React.useState<any>(false);
	const [selectedIdForCopy, setSelectedIdForCopy] = React.useState<any>(null);
	const [iconText, setIconText] = React.useState<any>('');
	const [contentText, setContentText] = React.useState<any>('');
	const [isLeaf, setISLeaf] = React.useState<boolean>(false);
	const AddScenarioModel = useBaseParamsModal();
	const openStatusContent = useBaseModal();
	const [downloadLoader, setDownloadLoader] = React.useState<any>(false);
	const [downloadId, setDownloadId] = React.useState<any>(null);
	const [TotalCompareGraphData, setTotalCompareGraphData] = React.useState<any>(
		[]
	);

	const AddScenarioHandler = (data: any) => {
		AddScenarioModel.open(data);
	};

	// Menu Item
	// 	const [anchorMenuEl, setAnchorMenuEl] = React.useState<null | HTMLElement>(null);
	//   const openMenu = Boolean(anchorMenuEl);
	//   const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
	//     setAnchorMenuEl(event.currentTarget);
	//   };
	//   const handleMenuClose = () => {
	//     setAnchorMenuEl(null);
	//   };
	const [anchorMenuEl, setAnchorMenuEl] = React.useState<null | HTMLElement>(
		null
	);
	const [selectedRow, setSelectedRow] = React.useState<null | any>(null); // Add state to track the selected row
	const openMenu = Boolean(anchorMenuEl);
	const handleMenuClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
		setAnchorMenuEl(event.currentTarget);
		setSelectedRow(row); // Set the selected row
	};
	const handleMenuClose = () => {
		setAnchorMenuEl(null);
		setSelectedRow(null); // Reset the selected row
	};

	// const [Loader, serLoader] = React.useState<any>(false)
	const [TotalLoader, serTotalLoader] = React.useState<any>(false);
	// const getDisableStatus = () => {
	// 	if (sessionStorage.getItem('addScenarioStatus') === '3') {
	// 		return false;
	// 	} else if (sessionStorage.getItem('addScenarioStatus') !== '3') {
	// 		return true;
	// 	} else {
	// 		return false;
	// 	}
	// };
	const getTableData = () => {
		setLoader(true);
		API.get('/cost/scenario_table/', { ...getObj(), page: currentPage + 1 })
			.then((res: any) => {
				// console.log(res?.data);
				setTableData(res?.data);
				setISLeaf(res?.data[0]?.is_leaf);
				setCostStatus(res?.data[0]?.cost_status);
				setLoader(false);
			})
			.catch((err: any) => {
				console.log('Sever Error');
				setLoader(false);
			});
	};

	const getTableDataNew = () => {
		setLoader(true);
		API.get('/cost/costing_scenari_requester/', {
			...getObj(),
			page: currentPage + 1,
		})
			.then((res: any) => {
				// console.log(res?.data);
				setTableDataNew(res?.data?.response);
				setISLeaf(res?.data?.response[0]?.is_leaf);
				setTotalcount(res?.data?.total_count);
				setCostStatus(res?.data?.response[0]?.cost_status);
				setLoader(false);
			})
			.catch((err: any) => {
				console.log('Sever Error');
				setLoader(false);
			});
	};

	React.useEffect(() => {
		if (createdType == 2) {
			getTableDataNew();
		}
	}, [createdType, currentPage, counter, count]);
	React.useEffect(() => {
		setCurrentPage(0);
		setTotalcount(0);
	}, [createdType]);
	// console.log(costStatus, "costStatus")
	const [isActive, setIsActive] = React.useState(true);
	const expandGraph = () => {
		// toggle
		setIsActive((current) => !current);

		// or set to true
		// setIsActive(true);
	};
	// const ScenarioTableModalHandler = () => {
	//     ScenarioTableModal.open()
	// }
	const getCompareGraphData = () => {
		setLoader(true);
		API.get('/cost/scenario_graph/', { ...getObj(), page: currentPage + 1 }, 0)
			.then((res: any) => {
				setLoader(false);
				SetCompareGraphData(res?.data);
			})
			.catch((err: any) => {
				setLoader(false);
				console.log('Server Error');
			});
	};

	const getRedirection = (cellValues: any) => {
		if (cellValues?.scenario_creation == 'Main') {
			window.open(
				`/#/createcostingpart/${projectId}/${projectName}/0/${projectId}/${vault}/${cellValues.abbreviation}/0/0/0/0/0/0/0/0/0`
				// `/#/view/${projectId}/${topVault}/${vault}/${cellValues.abbreviation}/costing`
			);
		} else {
			window.open(
				`/#/createcostingpart/${projectId}/${projectName}/0/${projectId}/${vault}/${cellValues.abbreviation}/0/0/0/0/0/0/${cellValues?.id}/${cellValues.id}/0`
			);
		}
	};

	const getTotalCompareGraphData = () => {
		serTotalLoader(true);

		API.get(
			'/cost/scenario_graph/',
			{ ...getObj(), total_cost: true, page: currentPage + 1 },
			0
		)
			.then((res: any) => {
				setTotalCompareGraphData(res?.data);
				serTotalLoader(false);
			})
			.catch((err: any) => {
				serTotalLoader(false);

				console.log('Server Error');
			});
	};

	const getTotalCount = () => {
		API.get('/cost/scenario_table/', { ...getObj(), total_count: true })
			.then((res: any) => {
				setTotalcount(res?.data?.total_count);
			})
			.catch((err: any) => {
				console.log('Server Error');
			});
	};

	React.useEffect(() => {
		if (createdType == 1) {
			getTotalCount();
			setCurrentPage(0);
		}
	}, [vault, topVault, createdType]);

	React.useEffect(() => {
		if (createdType == 1) {
			getCompareGraphData();
			getTotalCompareGraphData();
		}
	}, [topVault, vault, count, treeabbr, currentPage, createdType]);

	const handlePopoverOpen = (
		event: React.MouseEvent<HTMLElement>,
		rowData: any
	) => {
		setHoveredData(rowData);
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorDownEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorDownEl(null);
	};

	const detailSceanrioDownload = (module: any, cellValues: any) => {
		if (module === 'w') {
			// 		setDownloadLoader(true);
			// 		setDownloadId(cellValues?.id);
			// 		API.get(
			// 			'/cost/consolidated_report/',
			// 			{
			// 				vault: cellValues?.vault,
			// 				with_image: 'true',
			// 				consolidate_type: 'Costing',
			// 				scenario: cellValues?.id,
			// 			},
			// 			0
			// 		)
			// 			.then((res: any) => {
			// 				{downloadLoader && downloadId === cellValues.id ? <CircularProgress size={18} color="primary" /> : ''}
			// 				Swal.fire({
			// 					title: 'Success',
			// 					text: 'Scenario has been downloaded successfully',
			// 					icon: 'success',
			// 					confirmButtonColor: '#007FFF',
			// 				});
			// 				window.open(res?.data, '_self');
			// 			})
			// 			.catch((err: any) => {});
			// 	}
			// });
			setDownloadLoader(true);
			setDownloadId(cellValues?.id);
			Swal.fire({
				title: 'Downloading...',
				text: 'Please wait while the scenario is being downloaded.',
				allowOutsideClick: false,
				didOpen: () => {
					Swal.showLoading();
					setDownloadId(cellValues?.id);
					API.get(
						'/cost/consolidated_report/',
						{
							vault: cellValues?.vault,
							with_image: 'true',
							consolidate_type: 'Costing',
							scenario: cellValues?.id,
						},
						0
					)
						.then((res: any) => {
							setDownloadLoader(false);
							setDownloadId(null);
							Swal.fire({
								title: 'Success',
								text: 'Scenario has been downloaded successfully',
								icon: 'success',
								confirmButtonColor: '#007FFF',
							});
							window.open(res?.data, '_self');
						})
						.catch((err: any) => {
							setDownloadLoader(false);
							setDownloadId(null);
							Swal.fire({
								title: 'Error',
								text: 'There was an error downloading the scenario. Please try again.',
								icon: 'error',
								confirmButtonColor: '#d33',
							});
						});
				},
			});
		} else if (module === 'w/o') {
			setDownloadLoader(true);
			setDownloadId(cellValues?.id);
			Swal.fire({
				title: 'Downloading...',
				text: 'Please wait while the scenario is being downloaded.',
				allowOutsideClick: false,
				didOpen: () => {
					Swal.showLoading();
					setDownloadId(cellValues?.id);
					API.get(
						'/cost/consolidated_report/',
						{
							vault: cellValues?.vault,
							with_image: 'false',
							consolidate_type: 'Costing',
							scenario: cellValues?.id,
						},
						0
					)
						.then((res: any) => {
							setDownloadLoader(false);
							setDownloadId(null);
							Swal.fire({
								title: 'Success',
								text: 'Scenario has been downloaded successfully',
								icon: 'success',
								confirmButtonColor: '#007FFF',
							});
							window.open(res?.data, '_self');
						})
						.catch((err: any) => {
							setDownloadLoader(false);
							setDownloadId(null);
							Swal.fire({
								title: 'Error',
								text: 'There was an error downloading the scenario. Please try again.',
								icon: 'error',
								confirmButtonColor: '#d33',
							});
						});
				},
			});
		} else {
			Swal.fire({
				icon: 'error',
				text: 'Costing is in progress. Please wait for the costing to complete.',
			});
		}
	};

	const detailSceanrioDirectDownload = (cellValues: any) => {
		Swal.fire({
			title: 'Are you sure?',
			text: 'Do you want to download the scenario?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#00887a',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, do it!',
		}).then((result) => {
			if (result.isConfirmed) {
				setDownloadLoader(true);
				setDownloadId(cellValues?.id);
				API.get(
					'bom/individual_report/',
					{
						vault: cellValues?.vault,
						individual_type: 'Costing',
						scenario: cellValues?.id,
					},
					0
				)
					.then((res: any) => {
						setDownloadLoader(false);
						window.open(res?.data, '_self');
					})
					.catch((err: any) => {
						setDownloadLoader(false);
						const data = err.response.data;
						Swal.fire({
							icon: 'error',
							html: `<div>
						<br />
						<p style="color:"red">${data[0]}</p>   
						</div>`,
						});
					});
			}
		});
	};
	console.log(selectedRow, 'cellValues.row');
	const open = Boolean(anchorEl);

	let columns: any = [
		{
			field: 'actions',
			headerName: 'Actions',
			width: width > 1370 ? 100 : 100,
			// flex: 1,
			renderCell: (cellValues: any) => {
				return (
					<>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								columnGap: '0.5rem',
							}}>
							<IconButton
								onClick={(event) => handleMenuClick(event, cellValues.row)}
								size='small'
								sx={{ ml: 1 }}
								aria-controls={openMenu ? 'account-menu' : undefined}
								aria-haspopup='true'
								aria-expanded={openMenu ? 'true' : undefined}>
								<MenuIcon />
							</IconButton>

							<Menu
								anchorEl={anchorMenuEl}
								id='account-menu'
								open={openMenu}
								onClose={handleMenuClose}
								onClick={handleMenuClose}
								PaperProps={{
									elevation: 1,
									sx: {
										overflow: 'visible',
										// filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
										mt: 1.5,
										'& .MuiAvatar-root': {
											width: 32,
											height: 32,
											ml: -0.5,
											mr: 1,
										},
										'&::before': {
											content: '""',
											display: 'block',
											position: 'absolute',
											top: 0,
											right: 14,
											width: 10,
											height: 10,
											bgcolor: 'background.paper',
											transform: 'translateY(-50%) rotate(45deg)',
											zIndex: 0,
										},
									},
								}}
								transformOrigin={{ horizontal: 'right', vertical: 'top' }}
								anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
								{selectedRow?.scenario_creation !== 'Main' &&
									selectedRow?.action?.includes('C') && (
										<MenuItem
											onClick={() => {
												setSelectedIdForCopy(selectedRow.id);
												setCopyLoading(true);
												API.get(
													'/cost/scenario_copy/',
													{
														scenario: selectedRow.id,
														...getObj(),
													},
													0
												)
													.then((res: any) => {
														getTableData();
														setCopyLoading(false);
														getCompareGraphData();
														getTotalCompareGraphData();
														Swal.fire({
															title: 'Success',
															text: 'Scenario has been copied successfully',
															icon: 'success',
															confirmButtonColor: '#007FFF',
														});
													})
													.catch((err: any) => {
														setCopyLoading(false);
													});
											}}>
											<ListItemIcon>
												<FileCopyIcon sx={{ cursor: 'pointer' }} />
											</ListItemIcon>
											Copy
										</MenuItem>
									)}
								{selectedRow?.scenario_creation !== 'Main' &&
									selectedRow?.scenario_creation !== 'Dependent' &&
									selectedRow?.action === 'CRUD' && (
										<MenuItem onClick={() => shareScenario.open(selectedRow)}>
											<ListItemIcon>
												<ShareIcon sx={{ cursor: 'pointer' }} />
											</ListItemIcon>
											Share
										</MenuItem>
									)}

								{selectedRow?.lock != true &&
									selectedRow?.scenario_creation !== 'Main' &&
									selectedRow?.action?.includes('U') && (
										<MenuItem
											onClick={() => {
												editScenarioModal.open(selectedRow);
											}}>
											<ListItemIcon>
												<EditIcon sx={{ cursor: 'pointer' }} />
											</ListItemIcon>
											Edit
										</MenuItem>
									)}

								{costStatus == true && selectedRow?.is_leaf == true ? (
									<MenuItem
										onClick={() => detailSceanrioDirectDownload(selectedRow)}>
										<ListItemIcon>
											<DownloadIcon sx={{ cursor: 'pointer' }} />
										</ListItemIcon>
										Download
									</MenuItem>
								) : (
									<>
										<MenuItem
											onClick={() => detailSceanrioDownload('w', selectedRow)}>
											<ListItemIcon>
												<DownloadIcon sx={{ cursor: 'pointer' }} />
											</ListItemIcon>
											Consolidated with images
										</MenuItem>
										<MenuItem
											onClick={() =>
												detailSceanrioDownload('w/o', selectedRow)
											}>
											<ListItemIcon>
												<DownloadIcon sx={{ cursor: 'pointer' }} />
											</ListItemIcon>
											Consolidated w/o images
										</MenuItem>
									</>
								)}

								{selectedRow?.lock != true &&
									selectedRow?.scenario_creation !== 'Main' &&
									selectedRow?.action?.includes('D') && (
										<MenuItem
											onClick={() => {
												API.get(
													'/cost/costing_scenario_delete/',
													{ scenario_id: selectedRow.id },
													0
												).then((resData: any) => {
													Swal.fire({
														title: 'Are you sure?',
														text: resData.data,
														icon: 'error',
														showCancelButton: true,
														confirmButtonColor: '#007FFF',
														cancelButtonColor: '#d33',
														confirmButtonText: 'Ok Confirm!',
													}).then((result) => {
														if (result.isConfirmed) {
															Swal.fire({
																title: 'Are you sure?',
																text: 'Delete this scenario?',
																icon: 'error',
																showCancelButton: true,
																confirmButtonColor: '#007FFF',
																cancelButtonColor: '#d33',
																confirmButtonText: 'Yes, do it!',
															}).then((res) => {
																if (res.isConfirmed) {
																	API.delete(
																		`/cost/scenario/${selectedRow.id}/`,
																		{ reopen: true },
																		0
																	)
																		.then((res: any) => {
																			getTableData();
																			getCompareGraphData();
																			getTotalCompareGraphData();
																			Swal.fire(
																				'Deleted!',
																				'Scenario has been deleted.',
																				'success'
																			);
																		})
																		.catch((err: any) => {});
																}
															});
														}
													});
												});
											}}>
											<ListItemIcon>
												<DeleteIcon sx={{ cursor: 'pointer' }} />
											</ListItemIcon>
											Delete
										</MenuItem>
									)}
							</Menu>
							{cellValues.row?.scenario_creation !== 'Main' &&
							userProfile &&
							(userProfile[0]?.id === cellValues.row?.created_by ||
								userProfile[0]?.is_superuser) &&
							cellValues.row.scenario_creation !== 'Dependent' &&
							cellValues.row.scenario_creation !== 'Main' &&
							cellValues.row?.action?.includes('U') &&
							cellValues.row?.lock ? (
								<LockIcon
								    titleAccess='Scenario Published'
									sx={{ cursor: 'pointer', color: 'green' }}
									onClick={() => {
										Swal.fire({
											title: 'Are you sure?',
											text: 'You want to Unlock and Unpublish this scenario to xcPROC, which will make it invisible to the client?',
											icon: 'warning',
											showCancelButton: true,
											confirmButtonColor: 'primary.main',
										}).then((result) => {
											/* Read more about isConfirmed, isDenied below */
											if (result.isConfirmed) {
												API.put(
													`/cost/scenario/${cellValues.row.id}/`,
													{
														lock: false,
													},
													{},
													0
												)
													.then((res: any) => {
														Swal.fire({
															title: 'Success',
															text: 'Scenario has been unlocked successfully',
															icon: 'success',
															confirmButtonColor: '#007FFF',
														});
														getTableData();
													})
													.catch((err: any) => {
														const { data } = err.response;
														Swal.fire({
															title: 'Error',
															text: data[0],
															icon: 'error',
															confirmButtonColor: '#007FFF',
														});
													});
											} else if (result.isDenied) {
												Swal.fire('Changes are not saved', '', 'info');
											}
										});
									}}
								/>
							) : (
								cellValues.row.scenario_creation !== 'Main' &&
								cellValues.row.scenario_creation !== 'Dependent' && (
									<LockOpenIcon
										titleAccess='Lock and Publish Scenario to xcPROC'
										sx={{ cursor: 'pointer', color: 'red' }}
										onClick={() => {
											Swal.fire({
												title: 'Are you sure?',
												text: 'You want to lock and publish this scenario to xcPROC, which will make it visible to the client?',
												icon: 'warning',
												showCancelButton: true,
												confirmButtonColor: 'primary.main',
											}).then((result) => {
												/* Read more about isConfirmed, isDenied below */
												if (result.isConfirmed) {
													API.put(
														`/cost/scenario/${cellValues.row.id}/`,
														{
															lock: true,
														},
														{},
														0
													)
														.then((res: any) => {
															Swal.fire({
																title: 'Success',
																text: 'Scenario has been locked and Published to xcPROC successfully',
																icon: 'success',
																confirmButtonColor: '#007FFF',
															});
															getTableData();
														})
														.catch((err: any) => {
															const { data } = err.response;
															Swal.fire({
																title: 'Error',
																text: data[0],
																icon: 'error',
																confirmButtonColor: '#007FFF',
															});
														});
												} else if (result.isDenied) {
													Swal.fire('Changes are not saved', '', 'info');
												}
											});
										}}
									/>
								)
							)}
						</div>
					</>
				);
			},
		},
		{
			field: 'color',
			headerName: 'Color',
			width: width > 1370 ? 60 : 50,
			// flex: 1,
			renderCell: (cellValues: any) => {
				return (
					<>
						<Box
							sx={{
								backgroundColor: cellValues.row.color,
								width: '2.5rem',
								height: '1rem',
							}}></Box>
					</>
				);
			},
		},
		{
			field: 'request_id',
			headerName: 'Request ID',
			minWidth: width > 1370 ? 280 : 160,
		},
		{
			field: 'name',
			headerName: 'Name',
			minWidth: width > 1370 ? 280 : 200,
			flex: 1,
			renderCell: (cellValues: any) => {
				return (
					<>
						<Typography
							aria-owns={open ? 'mouse-over-popover' : undefined}
							aria-haspopup='true'
							onMouseEnter={(event: any) =>
								handlePopoverOpen(event, cellValues.row)
							}
							onMouseLeave={handlePopoverClose}>
							<span
								style={{
									textDecoration:
										cellValues.row.cost !== 'Inprogress' ? 'underline' : '',
									cursor: 'pointer',
									display: 'flex',
									justifyContent: 'end',
								}}
								onClick={() => {
									if (
										cellValues.row.cost !== 'Inprogress' &&
										(cellValues?.row?.cost_status === 3 ||
											cellValues?.row?.cost_status === true) &&
										cellValues?.row?.status == 3
									) {
										getRedirection(cellValues?.row);
									}
								}}
								title={
									(cellValues?.row?.cost_status === 3 ||
										cellValues?.row?.cost_status === true) &&
									cellValues?.row?.status == 3
										? ''
										: 'Validate main costing to enable this scenario'
								}>
								{cellValues?.row?.name}
							</span>
						</Typography>
						{hoveredData?.name != "Main Costing" && <Popover
							id='mouse-over-popover'
							sx={{
								pointerEvents: 'none',
							}}
							open={open}
							anchorEl={anchorEl}
							elevation={0}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
							onClose={handlePopoverClose}
							disableRestoreFocus>
							<Box
								sx={{
									width: '100%',
									display: 'flex',
									alignItems: 'flex-start',
									columnGap:'1px',
									border:'1px solid',
									borderRadius:'10px',
									overflow:'hidden',
								}}>
							
								{hoveredData?.name != "Main Costing" &&<Box>
									<Typography
										sx={{
											fontSize: '1rem',
											textAlign: 'center',
											width: '100%',
											backgroundColor: 'primary.light',
											// color: "white",
										}}>
										Scenario Info
									</Typography>
									<Divider />
									<Table>
										<TableBody>
											<TableRow>
												<TableCell
													sx={{
														padding: '0.2rem 0.5rem',
														borderBottomColor: 'primary.light',
														width: '7rem',
													}}>
													Revision Info
												</TableCell>
												<TableCell
													sx={{
														padding: '0.2rem 0.5rem',
														borderBottomColor: 'primary.light',
													}}>
													{' '}
													: {hoveredData.config}
												</TableCell>
											</TableRow>
											{Object.keys(hoveredData)?.length > 0 && (
												<TableRow>
													<TableCell
														sx={{
															padding: '0.2rem 0.5rem',
															borderBottomColor: 'primary.light',
															width: '7rem',
														}}>
														Created By
													</TableCell>
													<TableCell
														sx={{
															padding: '0.2rem 0.5rem',
															borderBottomColor: 'primary.light',
														}}>
														{' '}
														: {hoveredData.created_by_name}
													</TableCell>
												</TableRow>
											)}
											<TableRow>
												<TableCell
													sx={{
														padding: '0.2rem 0.5rem',
														borderBottomColor: 'primary.light',
														width: '7rem',
													}}>
													Created Date
												</TableCell>
												<TableCell
													sx={{
														padding: '0.2rem 0.5rem',
														borderBottomColor: 'primary.light',
													}}>
													{' '}
													: {hoveredData?.created_at}
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</Box>}

								{/* Request Info */}

								{hoveredData?.scenario_request_info?.annual_volume && (
										<Box>
											<Typography
												sx={{
													fontSize: '1rem',
													textAlign: 'center',
													width: '100%',
													backgroundColor: 'primary.light',
												}}>
												Request Info
											</Typography>
											<Table>
												<TableBody>
													<TableRow>
														<TableCell
															sx={{
																padding: '0.2rem 0.5rem',
																borderBottomColor: 'primary.light',
																width: '7rem',
																whiteSpace:'nowrap',
															}}>
															Annual Volume
														</TableCell>
														<TableCell
															sx={{
																padding: '0.2rem 0.5rem',
																borderBottomColor: 'primary.light',
																whiteSpace:'nowrap',
															}}>
															{' '}
															:{' '}
															{
																hoveredData?.scenario_request_info
																	?.annual_volume
															}
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell
															sx={{
																padding: '0.2rem 0.5rem',
																borderBottomColor: 'primary.light',
																width: '7rem',
																whiteSpace:'nowrap',
															}}>
															Mfg. Location
														</TableCell>
														<TableCell
															sx={{
																padding: '0.2rem 0.5rem',
																borderBottomColor: 'primary.light',
																whiteSpace:'nowrap',
															}}>
															{' '}
															:{' '}
															{hoveredData?.scenario_request_info?.mfg_location}
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell
															sx={{
																padding: '0.2rem 0.5rem',
																borderBottomColor: 'primary.light',
																width: '7rem',
																whiteSpace:'nowrap',
															}}>
															Paid Amount
														</TableCell>
														<TableCell
															sx={{
																padding: '0.2rem 0.5rem',
																borderBottomColor: 'primary.light',
																whiteSpace:'nowrap',
															}}>
															{' '}
															:{' '}
															{
																hoveredData?.scenario_request_info
																	?.paid_currency
															}{' '}
															{hoveredData?.scenario_request_info?.paid_amount}
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell
															sx={{
																padding: '0.2rem 0.5rem',
																borderBottomColor: 'primary.light',
																width: '7rem',
																whiteSpace:'nowrap',
															}}>
															Assignee
														</TableCell>
														<TableCell
															sx={{
																padding: '0.2rem 0.5rem',
																borderBottomColor: 'primary.light',
																whiteSpace:'nowrap',
															}}>
															{' '}
															:{' '}
															{hoveredData?.scenario_request_info?.assignees &&
																hoveredData.scenario_request_info.assignees.map(
																	(assignee: any, index: any) => (
																		<React.Fragment key={index}>
																			{assignee}
																			{index !==
																				hoveredData.scenario_request_info
																					.assignees.length -
																					1 && ', '}
																		</React.Fragment>
																	)
																)}
														</TableCell>
													</TableRow>
												</TableBody>
											</Table>
										</Box>
									)}
							</Box>
						</Popover>}
					</>
				);
			},
		},
		{
			field: 'scenario_creation',
			headerName: 'Type',
			minWidth: width > 1370 ? 100 : 100,
			// flex: 1,
		},
		{
			field: 'cost',
			headerName: `Total Cost ${sessionStorage.getItem('currency')}`,
			minWidth: width > 1370 ? 140 : 90,
			headerAlign: 'right',
			align: 'right',

			renderCell: (cellValues: any) => {
				return (
					<>
						<span>{cellValues?.row?.cost}</span>
					</>
				);
			},
			
		},
		{
			field: 'rm_cost',
			headerName: `RM Cost  ${sessionStorage.getItem('currency')}`,
			minWidth: width > 1370 ? 140 : 100,
			headerAlign: 'right',
			align: 'right',
			
			renderCell: (cellValues: any) => {
				return (
					<>
						<span>{cellValues?.row?.rm_cost}</span>
					</>
				);
			},
		},
		{
			field: 'process_cost',
			headerName:
				width > 1370
					? `Process Cost ${sessionStorage.getItem('currency')}`
					: `PS Cost ${sessionStorage.getItem('currency')}`,
			minWidth: width > 1370 ? 140 : 100,
			headerAlign: 'right',
			align: 'right',
			renderCell: (cellValues: any) => {
				return (
					<>
						<span>{cellValues?.row?.process_cost}</span>
					</>
				);
			},
			
		},
		{
			field: 'overhead_cost',
			headerName:
				width > 1370
					? `Overhead Cost ${sessionStorage.getItem('currency')}`
					: `OH Cost ${sessionStorage.getItem('currency')}`,
			minWidth: width > 1370 ? 140 : 100,
			headerAlign: 'right',
			align: 'right',
			renderCell: (cellValues: any) => {
				return (
					<>
						<span>{cellValues?.row?.overhead_cost}</span>
					</>
				);
			},
			
		},
	];

	let columnsNew: any = [
		{
			field: 'actions',
			headerName: 'Actions',
			minWidth: width > 1370 ? 80 : 80,			
			renderCell: (cellValues: any) => {
				return (
					<>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								columnGap: '0.5rem',
							}}>
							{AUTH.isSuperUser && (
								<AddCircleIcon
									sx={{ cursor: 'pointer' }}
									onClick={() => {
										AddScenarioHandler(cellValues.row);
									}}
								/>
							)}
							{AUTH.isSuperUser && cellValues.row.request_from !== 'client' && (
								<EditIcon
									sx={{ cursor: 'pointer' }}
									onClick={() => {
										requestEditModal.open(cellValues.row);
									}}
								/>
							)}
							{AUTH.isSuperUser && cellValues.row.request_from !== 'client' && (
								<DeleteIcon
									sx={{ cursor: 'pointer' }}
									onClick={() => {
										Swal.fire({
											title: 'Are you sure?',
											text: 'Delete this scenario?',
											icon: 'error',
											showCancelButton: true,
											confirmButtonColor: '#007FFF',
											cancelButtonColor: '#d33',
											confirmButtonText: 'Yes, do it!',
										}).then((res) => {
											if (res.isConfirmed) {
												API.delete(
													`/cost/costing_scenari_requester/${cellValues?.row?.ID}/`,
													{},
													0
												)
													.then(() => {
														setLoader(false);
														getTableDataNew();
													})
													.catch(() => {
														setLoader(false);
													});
											}
										});
									}}
								/>
							)}
						</div>
					</>
				);
			},
		},
		{
			field: 'request_id',
			headerName: 'Request ID',
			minWidth: width > 1370 ? 160 : 140,
			flex: 1,
		},
		{
			field: 'scenario_name',
			headerName: 'Name',
			minWidth: width > 1370 ? 160 : 180,
			flex: 1,
			renderCell: (cellValues: any) => {
				// console.log(cellValues?.row?.cost_percent);
				return (
					<>
						<span
							style={{
								cursor: 'pointer',
								display: 'flex',
								justifyContent: 'end',
							}}>
							{cellValues?.row?.scenario_name}
						</span>
					</>
				);
			},
		},
		{
			field: 'request_from',
			headerName: 'Request From',
			minWidth: width > 1370 ? 100 : 100,
			// flex: 1,created_at
		},
		{
			field: 'created_at',
			headerName: 'Created Date',
			minWidth: width > 1370 ? 80 : 80,
			// flex: 1,created_at
		},
		{
			fidel: 'assignees_name',
			headerName: 'Assignee',
			minWidth: width > 1370 ? 130 : 120,
			renderCell: (cellValues: any) => {
				return (
					<>
						{Array.isArray(cellValues.row?.assignees_name) &&
							cellValues.row?.assignees_name.map((item: any, index: any) => {
								return <Typography sx={{fontSize:'0.87rem'}} key={index}>{item},</Typography>;
							})}
					</>
				);
			},
		},
		{
			field: 'mfg_location',
			headerName: `MFG Location`,
			minWidth: width > 1370 ? 120 : 100,
			headerAlign: 'right',
			align: 'right',
			// flex: 1,
			renderCell: (cellValues: any) => {
				return (
					<>
						<span>{cellValues?.row?.mfg_location}</span>
					</>
				);
			},
		},
		{
			field: 'annual_volume',
			headerName: 'Annual Volume',
			minWidth: width > 1370 ? 130 : 100,
			headerAlign: 'right',
			align: 'right',
			renderCell: (cellValues: any) => {
				return (
					<>
						<span>{cellValues?.row?.annual_volume}</span>
					</>
				);
			},
			// flex: 1,
		},
		{
			field: 'revision_year',
			headerName: 'Revisied On',
			minWidth: width > 1370 ? 200 : 100,
			headerAlign: 'right',
			align: 'right',
			renderCell: (cellValues:any) => (
				<Box sx={{display:'flex',alignItems:'center',columnGap:'5px'}}>
					{cellValues.row?.revision_month?.length > 0 && 
					cellValues.row?.revision_month?.join(", ")
					}
				</Box>
			),			
		},
		{
			field: 'description',
			headerName: 'Description',
			minWidth: width > 1370 ? 160 : 160,
			// headerAlign: 'right',
			// align: 'right',
			renderCell: (cellValues: any) => {
				return (
					<>
						<span title={cellValues?.row?.description}>
							{cellValues?.row?.description}
						</span>
					</>
				);
			},
			// flex: 1,
		},
	];

	if (!isLeaf) {
		columns.push({
			field: 'child_cost',
			headerName:
				width > 1370
					? `Child Part Cost ${sessionStorage.getItem('currency')}`
					: `CP Cost ${sessionStorage.getItem('currency')}`,
			minWidth: width > 1370 ? 150 : 100,
			headerAlign: 'right',
			align: 'right',
			// flex: 1,
			renderCell: (cellValues: any) => {
				return (
					<>
						<span>{cellValues?.row?.child_cost}</span>
					</>
				);
			},
		});
	}
	columns.push({
		field: 'shred_to_user',
		headerName: 'Shared With',
		minWidth: width > 1370 ? 120 : 120,
		flex: 1,
		renderCell: (cellValues: any) => {
			return (
				<>
					{cellValues.row.scenario_creation !== 'Main' && (
						<span
							onClick={() => {
								if (cellValues.row.shred_to_user > 0) {
									shareDetails.open(cellValues.row);
								}
							}}>
							{cellValues.row.shred_to_user > 0 ? (
								<span
									style={{ textDecoration: 'underline', cursor: 'pointer' }}>
									{' '}
									{cellValues?.row?.shred_to_user}{' '}
									{cellValues.row.shred_to_user > 1 ? 'Users' : 'User'}
								</span>
							) : (
								'-'
							)}
						</span>
					)}
				</>
			);
		},
	});

	// In rows which is a array, we are pushing data which is structured in frontEnd so, that we can pass it to dataGrid

	function CustomizedTick(props: any) {
		const { x, y, stroke, payload } = props;
		return (
			<g transform={`translate(${x},${y})`}>
				<text x={0} y={0} dy={8} fill='#666'>
					<tspan
						style={{ fill: 'black', fontSize: '12' }}
						textAnchor='middle'
						x='0'>
						{payload.value}
					</tspan>
				</text>
			</g>
		);
	}

	const CustomizedLabel = (props: any) => {
		const { x, y, fill, value } = props;

		return (
			<text
				x={x}
				y={y}
				dy={-4}
				fontSize='10'
				fontFamily='sans-serif'
				fill={fill}
				textAnchor='inherit'>
				{TotalCompareGraphData && TotalCompareGraphData[0]?.currency} {value}
			</text>
		);
	};

	const CustomizedCostTooltip: any = ({ active, payload, label }: any) => {
		const firstCurrency =
			payload && payload.length > 0 ? payload[0]?.payload?.currency : '';

		if (active && payload && payload.length) {
			return (
				<div
					style={{
						backgroundColor: 'white',
						padding: '0.2rem 0.5rem',
						borderRadius: '5px',
						border: '1px solid',
					}}>
					<Typography
						style={{
							fontWeight: 'bold',
							fontSize: '0.87rem',
							minWidth: '8rem',
							width: '100%',
							textAlign: 'center',
						}}>{`${label}`}</Typography>
					<Typography
						sx={{
							fontSize: '0.87rem',
							width: '100%',
							display: 'flex',
							columnGap: '0.5rem',
							alignItems: 'center',
							fontWeight: 'bold',
						}}>
						<span style={{ width: '6rem', textTransform: 'capitalize' }}>
							Total:
						</span>
						<span style={{ width: '7rem', textAlign: 'right' }}>
							{payload
								.reduce(
									(accumulator: number, currentItem: any) =>
										accumulator + currentItem.value,
									0
								)
								.toFixed(2)}{' '}
							{firstCurrency}
						</span>
					</Typography>

					{payload.map((item: any, index: any) => {
						return (
							<>
								{/* @ts-ignore */}
								{
									<Typography
										sx={{
											fontSize: '0.87rem',
											width: '100%',
											display: 'flex',
											columnGap: '0.5rem',
											alignItems: 'center',
										}}>
										<span
											style={{
												width: '6rem',
												textTransform: 'capitalize',
												whiteSpace: 'nowrap',
											}}>
											{item?.name}:
										</span>
										<span style={{ width: '7rem', textAlign: 'right' }}>
											{item?.value?.toFixed(2)} {item.payload?.currency}
										</span>
									</Typography>
								}
							</>
						);
					})}
				</div>
			);
		}
		return null;
	};

	React.useEffect(() => {
		if (createdType == 1) {
			getTableData();
		}
	}, [vault, topVault, count, treeabbr, currentPage, createdType]);

	var RowData =
		TableData &&
		TableData?.map((item: any, index: any) => ({
			...item,
			sno: index + 1,

			created_at:
				item?.created_at == ''
					? '__'
					: moment(item?.created_at).format('DD-MM-YYYY'),
		}));

	let dataStructureDataGrid: any = {
		columns: columns,
		rows: RowData && RowData,
	};

	var RowDataNewRequest =
		TableDataNew &&
		TableDataNew?.map((item: any, index: any) => ({
			...item,
			sno: index + 1,
			id: index + 1,
			ID: item?.id,
			created_at:
				item?.created_at == ''
					? '__'
					: moment(item?.created_at).format('DD-MM-YYYY'),
		}));

	let dataStructureDataGridNewRequest: any = {
		columns: columnsNew,
		rows: RowDataNewRequest && RowDataNewRequest,
	};
	console.log('newScenarioRequestCount', newScenarioRequestCount);
	return (
		<>
			<Box>
				<Box
					sx={{
						zIndex: 1,
						position: 'relative',
						width: '60%',
						textAlign: 'right',
						marginBottom: '-2rem',
						paddingTop: '2px',
						marginTop: '0.5rem',
					}}>
					<Button
						variant={createdType == 1 ? 'contained' : 'outlined'}
						color='primary'
						onClick={() =>
							history.push(
								`/costingscenario/${projectId}/${projectName}/${topVault}/${vault}/${scenarioType}/${treeabbr}/${scenarioId}/${requestId}/${1}`
							)
						}>
						Created Scenarios
					</Button>

					{newScenarioRequestCount > 0 ? (
						<Badge badgeContent={`${newScenarioRequestCount}`} color='error'>
							<Button
								sx={{ marginLeft: '1rem' }}
								variant={createdType == 2 ? 'contained' : 'outlined'}
								color='primary'
								onClick={() =>
									history.push(
										`/costingscenario/${projectId}/${projectName}/${topVault}/${vault}/${scenarioType}/${treeabbr}/${scenarioId}/${requestId}/${2}`
									)
								}>
								New Scenario Request
							</Button>
						</Badge>
					) : (
						<Button
							sx={{ marginLeft: '1rem' }}
							variant={createdType == 2 ? 'contained' : 'outlined'}
							color='primary'
							onClick={() =>
								history.push(
									`/costingscenario/${projectId}/${projectName}/${topVault}/${vault}/${scenarioType}/${treeabbr}/${scenarioId}/${requestId}/${2}`
								)
							}>
							New Scenario Request
						</Button>
					)}
				</Box>
				{editScenarioModal.isOpen && (
					<EditScenarioModal
						isOpen={editScenarioModal.isOpen}
						onCloseModal={editScenarioModal.close}
						exsistingData={editScenarioModal.propsId}
						getTableData={getTableData}
						getCompareGraphData={getCompareGraphData}
						getTotalCompareGraphData={getTotalCompareGraphData}
					/>
				)}
				{shareDetails.isOpen && (
					<ShareDetailsModal
						isOpen={shareDetails.isOpen}
						onCloseModal={shareDetails.close}
						data={shareDetails.propsId}
						getParentTableData={getTableData}
					/>
				)}
				{shareScenario.isOpen && (
					<ShareScenarioModal
						isOpen={shareScenario.isOpen}
						onCloseModal={shareScenario.close}
						data={shareScenario.propsId}
						getTableData={getTableData}
					/>
				)}
				<Box
					sx={{
						height: isActive
							? { lg: '45vh', xl: '48vh' }
							: { lg: '88vh', xl: '89vh' },
						width: { lg: '74.5vw', xl: '78vw' },
					}}>
					{createdType == 1 ? (
						<DataGrid
							headerHeight={48}
							rowHeight={36}
							{...dataStructureDataGrid}
							loading={Loader}
							disableSelectionOnClick={true}
							// loading={userDataGetLoad}
							components={{
								Toolbar: () => {
									return CustomToolbar();
								},
							}}
							rowClassName={(params: any) => {
								return {
									backgroundColor: params.row.color, // Set the background color dynamically
								};
							}}
							density={'compact'}
							// pageSize={100}
							page={currentPage}
							pageSize={10}
							rowsPerPageOptions={[10]}
							paginationMode='server'
							rowCount={totalCount}
							rows={dataStructureDataGrid.rows}
							onPageChange={(params: any) => {
								setCurrentPage(params);
							}}
							sx={{
								'& ::-webkit-scrollbar': {
									width: '0.3rem',
									height: '0.3rem',
								},
								'& ::-webkit-scrollbar-thumb': {
									// background: $thumb-color;
									backgroundImage: 'linear-gradient(#09C6F9, #045DE9)',
									borderRadius: '10px',
								},
								'& ::- webkit-scrollbar-thumb: hover ': {
									// width: "4px",
									backgroundColor: '#045DE9 !important',
								},
								'::-webkit-scrollbar-track': {
									backgroundColor: '#e1e1f3 !important',
								},
								'&.MuiDataGrid-root': {
									border: 'none',
								},
								'&.MuiDataGrid-root .MuiDataGrid-cell': {
									borderBottomColor: 'primary.light',
								},
							}}
						/>
					) : (
						<DataGrid
							headerHeight={48}
							rowHeight={36}
							{...dataStructureDataGridNewRequest}
							loading={Loader}
							disableSelectionOnClick={true}
							// loading={userDataGetLoad}
							components={{
								Toolbar: () => {
									return CustomToolbar();
								},
							}}
							rowClassName={(params: any) => {
								return {
									backgroundColor: params.row.color, // Set the background color dynamically
								};
							}}
							density={'compact'}
							// pageSize={100}
							page={currentPage}
							pageSize={10}
							rowsPerPageOptions={[10]}
							paginationMode='server'
							rowCount={totalCount}
							rows={dataStructureDataGridNewRequest.rows}
							onPageChange={(params: any) => {
								setCurrentPage(params);
							}}
							sx={{
								'& ::-webkit-scrollbar': {
									width: '0.3rem',
									height: '0.3rem',
								},
								'& ::-webkit-scrollbar-thumb': {
									// background: $thumb-color;
									backgroundImage: 'linear-gradient(#09C6F9, #045DE9)',
									borderRadius: '10px',
								},
								'& ::- webkit-scrollbar-thumb: hover ': {
									// width: "4px",
									backgroundColor: '#045DE9 !important',
								},
								'::-webkit-scrollbar-track': {
									backgroundColor: '#e1e1f3 !important',
								},
								'&.MuiDataGrid-root': {
									border: 'none',
								},
								'&.MuiDataGrid-root .MuiDataGrid-cell': {
									borderBottomColor: 'primary.light',
								},
							}}
						/>
					)}
				</Box>
				{createdType == 1 && (
					<Box
						sx={{
							position: 'absolute',
							marginTop: '-3rem',
							zIndex: '1',
							display: 'flex',
							alignItems: 'center',
							padding: '0 1rem',
						}}>
						{isActive ? (
							<ZoomInMapIcon
								titleAccess='Collapse Graph'
								sx={{ cursor: 'pointer' }}
								onClick={expandGraph}
							/>
						) : (
							<ZoomOutMapIcon
								titleAccess='Expand Graph'
								sx={{ cursor: 'pointer' }}
								onClick={expandGraph}
							/>
						)}
					</Box>
				)}
				{createdType == 1 && (
					<Box
						sx={{
							height: isActive ? '40vh' : '0vh',
							width: '78vw',
							display: 'flex',
							flexDirection: 'row',
						}}>
						<Box sx={{ display: isActive ? 'block' : 'none' }}>
							{TotalCompareGraphData.length <= 5 ? (
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Box
										sx={{ width: { lg: '75vw', xl: '75vw' }, height: '38vh' }}>
										{Loader ? (
											<>
												<Skeleton></Skeleton>
												<Skeleton></Skeleton>
												<Skeleton></Skeleton>
												<Skeleton></Skeleton>
												<Skeleton></Skeleton>
												<Skeleton></Skeleton>
											</>
										) : (
											<ResponsiveContainer width='100%' height='100%'>
												<BarChart
													width={500}
													height={300}
													data={CompareGraphData}
													margin={{ top: 10, right: 30, left: 30, bottom: 0 }}>
													<XAxis
														dataKey='name'
														interval={0}
														style={{
															fontSize: '10px',
															fontWeight: 'bold',
														}}
														stroke='#007FFF'
														tick={<CustomizedTick />}
													/>
													<YAxis
														//@ts-ignore
														left={0}
														stroke='#007FFF'
														fill='#1a4faa'
														fontSize='0.87rem'
														// type="number"
														// domain={[(dataMin:any) => (0 - Math.abs(dataMin)),( dataMax:any) => (dataMax * 2)]}
														domain={[
															() => 0,
															(dataMax: number) =>
																Math.round(dataMax + (dataMax * 5) / 100),
														]}
														label={{
															value: `Currency (${
																CompareGraphData &&
																CompareGraphData[0]?.currency
															})`,
															angle: -90,
															position: 'middle',
															dx: -42,
															fill: 'black',
															fontSize: '12px',
														}}
													/>
													<Legend verticalAlign='bottom' align='center' />
													<Tooltip
														cursor={false}
														content={<CustomizedCostTooltip />}
													/>
													<Bar
														maxBarSize={80}
														dataKey='RM Cost'
														stackId='1'
														stroke='#8884d8'
														fill='#8884d8'
														id='aarea'></Bar>
													<Bar
														maxBarSize={80}
														dataKey='Process Cost'
														stackId='1'
														stroke='#82ca9d'
														fill='#82ca9d'
														id='barea'
														cursor={'pointer'}></Bar>
													<Bar
														maxBarSize={80}
														cursor={'pointer'}
														dataKey='Overhead Cost'
														stackId='1'
														stroke='#ffc658'
														fill='#ffc658'
														id='carea'></Bar>
												</BarChart>
											</ResponsiveContainer>
										)}
									</Box>
								</Box>
							) : (
								<Box
									sx={{ height: { lg: '41vh', xl: '43vh' } }}
									adl-scrollbar='true'
									adl-scrollbar-width='0.3'>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
											height: '41vh',
										}}>
										<Box sx={{ width: '75vw', height: '41vh' }}>
											{Loader ? (
												<>
													<Skeleton></Skeleton>
													<Skeleton></Skeleton>
													<Skeleton></Skeleton>
													<Skeleton></Skeleton>
													<Skeleton></Skeleton>
													<Skeleton></Skeleton>
												</>
											) : (
												<ResponsiveContainer width='100%' height='100%'>
													<BarChart
														// width={500}
														// height={300}
														data={CompareGraphData}
														// stackOffset="expand"
														margin={{
															top: 10,
															right: 20,
															left: 30,
															bottom: CompareGraphData.length > 10 ? 20 : 5,
														}}>
														<XAxis
															dataKey='name'
															stroke='black'
															fontSize='0.75rem'
															angle={CompareGraphData.length > 10 ? -25 : 0}
															textAnchor={
																CompareGraphData.length > 10 ? 'end' : 'middle'
															}
															tick={<CustomizedTick />}
														/>
														<YAxis
															//@ts-ignore
															left={0}
															stroke='#007FFF'
															fill='#1a4faa'
															fontSize={12}
															// tick={<CustomizedYAxisTick />}
															domain={[
																() => 0,
																(dataMax: number) =>
																	Math.round(dataMax + (dataMax * 5) / 100),
															]}
															label={{
																value: `Currency (${
																	CompareGraphData &&
																	CompareGraphData[0]?.currency
																})`,
																angle: -90,
																position: 'middle',
																dx: -42,
																fill: 'black',
																fontSize: '12px',
															}}
														/>
														<Legend verticalAlign='bottom' align='center' />
														<Tooltip
															cursor={false}
															content={<CustomizedCostTooltip />}
														/>
														<Bar
															maxBarSize={80}
															dataKey='RM Cost'
															stackId='1'
															stroke='#8884d8'
															fill='#8884d8'
															id='aarea'></Bar>
														<Bar
															maxBarSize={80}
															dataKey='Process Cost'
															stackId='1'
															stroke='#82ca9d'
															fill='#82ca9d'
															id='barea'
															cursor={'pointer'}></Bar>
														<Bar
															maxBarSize={80}
															cursor={'pointer'}
															dataKey='Overhead Cost'
															stackId='1'
															stroke='#ffc658'
															fill='#ffc658'
															id='carea'></Bar>
													</BarChart>
												</ResponsiveContainer>
											)}
										</Box>
									</Box>
								</Box>
							)}
						</Box>
					</Box>
				)}
			</Box>
			<DialogComponent
				isOpen={openStatusContent.isOpen}
				onClose={openStatusContent.close}
				content={contentText}
				type={iconText}
			/>
			{AddScenarioModel.isOpen && (
				<AddScenarioTemp
					isOpen={AddScenarioModel.isOpen}
					onCloseModal={AddScenarioModel.close}
					setCount={setCount}
					SelectedData={AddScenarioModel.propsId}
				/>
			)}
			{requestEditModal.isOpen && (
				<EditScenarioModalAdmin
					isOpen={requestEditModal.isOpen}
					onCloseModal={requestEditModal.close}
					setCount={setCount}
					rowData={requestEditModal.propsId}
				/>
			)}
		</>
	);
}
