import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { API } from '../../api-services';
import Table from "@mui/material/Table";
import CancelIcon from '@mui/icons-material/Cancel';
import {

  Divider,
  Skeleton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { EditRequiredAtDropdown } from './EditRequiredAtDropdown';
import { EditEditableAtDropdown } from './EditEditableAtDropdown';
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "90rem",
  height: '80vh',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  p: 1
};
interface IdeaParameterTableModalProps {
  isOpen: any;
  onCloseModal: any;
  item: any;

}

const IdeaParameterTableModal = (props: IdeaParameterTableModalProps) => {
  const { isOpen,
    onCloseModal,
    item } = props

  const [paramsData, setParamsData] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState<any>(false);
  useEffect(() => {
    setIsLoading(true);
    API.get("/bom/parameter_mapping/", { table_id: item }).then((res: any) => {
      setIsLoading(false);
      setParamsData(res?.data)
    }).catch((err: any) => {
      setIsLoading(false);
    })

  }, [item])

  return (
    <div>


      <Modal
        open={isOpen}
        // onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Idea Table Parameter
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />

          <Box sx={{ width: '100%', height: { lg: '70vh', xl: '74vh' } }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3">
            <Table>
              <TableHead
                sx={{
                  backgroundColor: "primary.main",
                  color: "white",
                  position: "sticky",
                  top: "0",
                  zIndex: "1",
                }}
              >
                <TableRow>
                  <TableCell sx={{ padding: "0.2rem 0.5rem",borderBottomColor:'primary.light', color: "white" }}>
                    Sequence
                  </TableCell>
                  <TableCell sx={{ padding: "0.2rem 0.5rem",borderBottomColor:'primary.light', color: "white" }}>
                    Name
                  </TableCell>
                  <TableCell sx={{ padding: "0.2rem 0.5rem",borderBottomColor:'primary.light', color: "white" }}>
                    Unit
                  </TableCell>
                  <TableCell sx={{ padding: "0.2rem 0.5rem",borderBottomColor:'primary.light', color: "white" }}>
                    Data Type
                  </TableCell>
                  <TableCell sx={{ padding: "0.2rem 0.5rem",borderBottomColor:'primary.light', color: "white" }}>
                    Exceptions
                  </TableCell>
                  <TableCell sx={{ padding: "0.2rem 0.5rem",borderBottomColor:'primary.light', color: "white" }}>
                    Allowed Values
                  </TableCell>
                  <TableCell sx={{ padding: "0.2rem 0.5rem",borderBottomColor:'primary.light', color: "white" }}>
                    Not Allowed Values
                  </TableCell>
                  <TableCell sx={{ padding: "0.2rem 0.5rem",borderBottomColor:'primary.light', color: "white" }}>
                    Default Value
                  </TableCell>
                  <TableCell sx={{ padding: "0.2rem 0.5rem",borderBottomColor:'primary.light', color: "white" }}>
                    Guideline Doc
                  </TableCell>
                  {/* <TableCell sx={{ padding: "0.2rem 0.5rem",borderBottomColor:'primary.light', color: "white" }}>
              Sequence
            </TableCell> */}
                  <TableCell sx={{ padding: "0.2rem 0.5rem",borderBottomColor:'primary.light', color: "white" }}>
                    Editable at
                  </TableCell>
                  <TableCell sx={{ padding: "0.2rem 0.5rem",borderBottomColor:'primary.light', color: "white" }}>
                    Required at
                  </TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {!isLoading && paramsData ? (
                  paramsData?.map((item: any, index: any) => {
                    return (
                      <TableRow key={index + 1}>
                        <TableCell sx={{ padding: "0.2rem 0.5rem",borderBottomColor:'primary.light' }}>
                          {item.sequence}
                          {/* <EditSequence val={item.sequence} paramId={item.id} getParamsData={getParamsData} /> */}
                        </TableCell>
                        <TableCell sx={{ padding: "0.2rem 0.5rem",borderBottomColor:'primary.light' }}>
                          {item?.parameter?.name}
                        </TableCell>
                        <TableCell sx={{ padding: "0.2rem 0.5rem",borderBottomColor:'primary.light' }}>
                          {item?.parameter?.unit && item?.parameter?.unit?.length > 0
                            ? item?.parameter?.unit
                            : "-"}
                        </TableCell>
                        <TableCell sx={{ padding: "0.2rem 0.5rem",borderBottomColor:'primary.light' }}>
                          {item?.parameter?.data_type}
                        </TableCell>
                        <TableCell sx={{ padding: "0.2rem 0.5rem",borderBottomColor:'primary.light' }}>
                          {item?.parameter?.exceptions?.length > 0
                            ? item?.parameter?.exceptions
                            : "-"}
                        </TableCell>
                        <TableCell sx={{ padding: "0.2rem 0.5rem",borderBottomColor:'primary.light' }}>
                          {item?.parameter?.allowed_values?.length > 0
                            ? item?.parameter?.allowed_values
                            : "-"}
                        </TableCell>
                        <TableCell sx={{ padding: "0.2rem 0.5rem",borderBottomColor:'primary.light' }}>
                          {item?.parameter?.not_allowed_values?.length > 0
                            ? item?.parameter?.not_allowed_values
                            : "-"}
                        </TableCell>
                        <TableCell sx={{ padding: "0.2rem 0.5rem",borderBottomColor:'primary.light' }}>
                          {item?.parameter?.default_value?.length > 0
                            ? item?.parameter?.default_value
                            : "-"}
                        </TableCell>
                        <TableCell sx={{ padding: "0.2rem 0.5rem",borderBottomColor:'primary.light' }}>
                          {item?.parameter?.parameter_info
                            ? item?.parameter?.parameter_info
                            : "-"}
                        </TableCell>
                        {/* <TableCell sx={{ padding: "0.2rem 0.5rem",borderBottomColor:'primary.light' }}>
                    
                  </TableCell> */}
                        <TableCell sx={{ padding: "0.2rem 0.5rem",borderBottomColor:'primary.light' }}>
                          {item?.editable}
                          {/* <EditEditableAtDropdown
                      key={index}
                      val={item?.editable}
                      paramId={item.id}
                    /> */}
                        </TableCell>
                        <TableCell sx={{ padding: "0.2rem 0.5rem",borderBottomColor:'primary.light' }}>
                          {item?.required_on}
                          {/* <EditRequiredAtDropdown
                      key={index}
                      val={item?.required_on}
                      paramId={item.id}
                    /> */}
                        </TableCell>

                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <Box sx={{ width: "100%" }}>
                        <Skeleton />
                        <Skeleton animation="wave" />
                        <Skeleton animation={false} />
                        <Skeleton />
                        <Skeleton animation="wave" />
                        <Skeleton animation={false} />
                        <Skeleton />
                        <Skeleton animation="wave" />
                        <Skeleton animation={false} />
                        <Skeleton />
                        <Skeleton animation="wave" />
                        <Skeleton animation={false} />
                        <Skeleton />
                        <Skeleton animation="wave" />
                        <Skeleton animation={false} />
                        <Skeleton />
                        <Skeleton animation="wave" />
                        <Skeleton animation={false} />
                        <Skeleton />
                        <Skeleton animation="wave" />
                        <Skeleton animation={false} />
                        <Skeleton />
                        <Skeleton animation="wave" />
                        <Skeleton animation={false} />
                        <Skeleton />
                        <Skeleton animation="wave" />
                        <Skeleton animation={false} />
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}

export default IdeaParameterTableModal;