import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  Divider,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import { API } from "../../api-services";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import ErrorModalFilesMail from "../../ComponentUI/ProjectMailModule/ErrorModalFilesMail";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: " 40vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

interface BomCategoryEditModelProps {
  isOpen: any;
  onCloseModal: any;
  reloadBomCategoriesData: any;
  setReloadBomCategoriesData: any;
  id: any;
  name: any;
  prefix: any;
  postfix: any;
  color: any;
  is_leaf: any;
}

const BomCategoryEditModel = (props: BomCategoryEditModelProps) => {
  const {
    isOpen,
    onCloseModal,
    reloadBomCategoriesData,
    setReloadBomCategoriesData,
    id,
    name,
    prefix,
    postfix,
    color,
    is_leaf,
  } = props;
  const [categoryName, setCategoryName] = useState<any>();
  const [prefixName, setPrefixName] = useState<any>();
  const [postfixName, setPostfixName] = useState<any>();
  const [colorSelect, setColorSelect] = useState<any>();
  const [isLeafSelect, setIsLeafSelect] = useState<any>("false");
  const [error, setError] = useState<boolean>(false);
  const [savePopupLoader, setSavePopupLoader] = useState<boolean>(false);
  const [ErrorModalFileMsg, setErrorModalFileMsg] = useState<any>();

  const ErrorModalFiles = useBaseModal();
  
  useEffect(() => {
    setCategoryName("");
    setPrefixName(undefined);
    setPostfixName(undefined);
    setColorSelect(undefined);
    setIsLeafSelect("false");
    setError(false);
    setCategoryName(name);
    setPrefixName(prefix);
    setPostfixName(postfix);
    setColorSelect(color == "" ? undefined : color);
    setIsLeafSelect(is_leaf == "YES" ? "true" : "false");
  }, [isOpen, onCloseModal]);

  const handleCategoryName: any = (e: any) => {
    setCategoryName(e.target.value);
  };

  const handlePrefixName: any = (e: any) => {
    setPrefixName(e.target.value);
  };

  const handlePostfixName: any = (e: any) => {
    setPostfixName(e.target.value);
  };

  const handleColorSelect: any = (e: any) => {
    setColorSelect(e.target.value);
  };

  const handleChangeIsLeaf = (event: SelectChangeEvent) => {
    setIsLeafSelect(event.target.value as string);
  };

  const submitBomCategoryDetails = () => {
    setSavePopupLoader(true);
    if (categoryName == "" || colorSelect == undefined) {
      setError(true);
      setSavePopupLoader(false);
    } else {
      API.put("/db/category/" + id + "/", {
        name: categoryName == name ? undefined : categoryName,
        prefix: prefixName == prefix ? undefined : prefixName,
        postfix: postfixName == postfix ? undefined : postfixName,
        color: colorSelect == color ? undefined : colorSelect,
        is_leaf:
          isLeafSelect == (is_leaf == "YES" ? "true" : "false")
            ? undefined
            : isLeafSelect == "true"
              ? true
              : false,
      }).then((res: any) => {
        setSavePopupLoader(false);
        setReloadBomCategoriesData(!reloadBomCategoriesData);
        onCloseModal();
      }).catch((err) =>{
        setSavePopupLoader(false);
        setErrorModalFileMsg(err.response.data);
        ErrorModalFiles.open();
      })
    }
  };

  return (
    <div>
      <ErrorModalFilesMail
        isOpen={ErrorModalFiles.isOpen}
        onCloseModal={ErrorModalFiles.close}
        ErrorMsg={ErrorModalFileMsg}
      />
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Edit BOM Category
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box
            sx={{
              padding: "0 0.5rem 0.5rem",
              display: "flex",
              flexDirection: "column",
              rowGap: "1rem",
              width: "100%",
            }}
          >
            <FormControl fullWidth>
              <TextField
                autoFocus
                variant="standard"
                InputProps={{
                  style: { color: "#007fff", fontSize: "1rem" },
                }}
                InputLabelProps={{ style: { fontSize: "1rem" } }}
                value={categoryName}
                onChange={handleCategoryName}
                sx={{
                  width: "100%",
                  "& .MuiFormLabel-root": {
                    color: "primary.main",
                  },
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "primary.main",
                  },
                  "& .Mui-focused": {
                    color: "primary.main",
                  },
                  "& .Mui-error": {
                    color: "#d32f2f",
                    borderBottomColor: "#d32f2f",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "primary.light",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                }}
                label="Category Name *"
              />
              {error && categoryName == "" ? (
                <p
                  style={{
                    fontSize: "0.75rem",
                    color: "#d32f2f",
                    marginTop: "0.4rem",
                  }}
                >
                  *Please write category name
                </p>
              ) : (
                <></>
              )}
            </FormControl>
            <Box sx={{ display: 'flex', columnGap: '1rem', alignItems: 'center' }}>
              <FormControl fullWidth>
                <TextField
                  variant="standard"
                  InputProps={{
                    style: { color: "#007fff", fontSize: "1rem" },
                  }}
                  InputLabelProps={{ style: { fontSize: "1rem" } }}
                  value={prefixName}
                  onChange={handlePrefixName}
                  sx={{
                    width: "100%",
                    "& .MuiFormLabel-root": {
                      color: "primary.main",
                    },
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "primary.main",
                    },
                    "& .Mui-focused": {
                      color: "primary.main",
                    },
                    "& .Mui-error": {
                      color: "#d32f2f",
                      borderBottomColor: "#d32f2f",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                  }}
                  label="Prefix "
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  variant="standard"
                  InputProps={{
                    style: { color: "#007fff", fontSize: "1rem" },
                  }}
                  InputLabelProps={{ style: { fontSize: "1rem" } }}
                  value={postfixName}
                  onChange={handlePostfixName}
                  sx={{
                    width: "100%",
                    "& .MuiFormLabel-root": {
                      color: "primary.main",
                    },
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "primary.main",
                    },
                    "& .Mui-focused": {
                      color: "primary.main",
                    },
                    "& .Mui-error": {
                      color: "#d32f2f",
                      borderBottomColor: "#d32f2f",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                  }}
                  label="Postfix"
                />
              </FormControl>
            </Box>
            <Box sx={{ display: 'flex', columnGap: '1rem', alignItems: 'center' }}>
              <FormControl fullWidth sx={{ marginTop: "0.5rem" }}>
                <InputLabel
                  id="demo-simple-select-label-Activity"
                  sx={{
                    color: "primary.main",
                    fontSize: "1.13rem",
                    marginLeft: "-1rem",
                  }}
                >
                  Color *
                </InputLabel>
                <Input
                  type="color"
                  onChange={handleColorSelect}
                  
                  value={colorSelect}
                  sx={{
                    "&.MuiInputBase-root.MuiInput-root:before": {
                      borderBottomColor: 'primary.light'
                    },
                  }}
                />
                {error && colorSelect == undefined ? (
                  <p
                    style={{
                      fontSize: "0.75rem",
                      color: "#d32f2f",
                      marginTop: "0.4rem",
                    }}
                  >
                    *Please select color
                  </p>
                ) : (
                  <></>
                )}
              </FormControl>
              <FormControl fullWidth sx={{ marginTop: "0.5rem" }}>
                <InputLabel
                  id="demo-simple-select-label-Activity"
                  sx={{
                    color: "primary.main",
                    fontSize: "1.13rem",
                    marginLeft: "-1rem",
                  }}
                >
                  Is Leaf *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant="standard"
                  value={isLeafSelect}
                  onChange={handleChangeIsLeaf}
                  sx={{
                    width: "100%",
                    marginTop: "-0.5rem",
                    "&:before": {
                      borderBottomColor: "primary.light",
                    },
                    ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
                    "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                      fontSize: '1rem'
                    },
                  }}
                >
                  <MenuItem sx={{ fontSize: '1rem' }} value={"true"}>YES</MenuItem>
                  <MenuItem sx={{ fontSize: '1rem' }} value={"false"}>NO</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              columnGap: "1rem",
              justifyContent: "flex-end",
              marginTop: "1rem",
            }}
          >
            <LoadingButton
              size="small"
              variant="contained"
              onClick={submitBomCategoryDetails}
              loading={savePopupLoader}
              sx={{
                "&:hover": {
                  transform: "Scale(1.05)",
                  transition: "transform 0.5s ease",
                },
              }}
            >
              Save
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default BomCategoryEditModel;
