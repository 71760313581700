import { FormControl, MenuItem, Select, Skeleton } from "@mui/material";
import React from "react";

export interface IEditNewAssumptionDropdownProps {
    assumptionLoader:any;
    assumptionsList:any;
    selectedAssumption:any;
    handleChange:any;
    itemMain:any;
    setSelectedAssumption:any;
}

export function EditNewAssumptionDropdown (props: IEditNewAssumptionDropdownProps) {
    const {assumptionLoader, assumptionsList, selectedAssumption, handleChange,itemMain,setSelectedAssumption} = props;
    const [selected,setSelected] = React.useState<any>({})
    React.useEffect(()=>{
        if(Object.keys(itemMain).length>0){
        setSelectedAssumption(itemMain?.target_assumption);
        setSelected(itemMain?.target_assumption)
        }
    },[itemMain])
    const handleCahngeInside = (ed:any)=>{
        setSelected(ed.target.value)
    }
  return (
    <FormControl fullWidth>
                              {/* <InputLabel id="demo-simple-select-label">
                                                     Selected Base Scenario
                                                 </InputLabel> */}
                              <Select
                                variant="standard"
                                size="small"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="assumption"
                                value={selected}
                                disabled={true}
                                label="Age"
                                onChange={(ed: any) =>
                                 { handleChange(ed, itemMain)
                                   handleCahngeInside(ed)
                                }
                                }
                                sx={{
                                  width: "100%",
                                  "&:before": {
                                    borderBottomColor: "primary.main",
                                  },
                                  "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                                    {
                                      fontSize: "1rem",
                                    },
                                  ".MuiSvgIcon-root.MuiSelect-icon": {
                                    color: "primary.main",
                                  },
                                }}
                              >
                                {!assumptionLoader && assumptionsList ? (
                                  assumptionsList?.map(
                                    (item: any, index: any) => {
                                      return (
                                        <MenuItem
                                          sx={{ fontSize: "1rem" }}
                                          value={item?.id}
                                          key={index}
                                        >
                                          {item?.revision_name}
                                        </MenuItem>
                                      );
                                    }
                                  )
                                ) : (
                                  <Skeleton animation="wave" />
                                )}
                              </Select>
                            </FormControl>
  );
}
