import * as React from "react";
import Modal from "@mui/material/Modal";
import { Box, ButtonProps, Divider, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";

interface IPartImageModelProps {
  onCloseModal: any;
  isOpen: any;
  partdetails: any;
}

const csvOptions: GridCsvExportOptions = {
  fileName: "Part selling price In CSV",
  delimiter: ",",
  utf8WithBom: true,
  allColumns: true,
};

const CustomToolbar = () => (
  <GridToolbarContainer sx={{ display: "flex", justifyContent: "flex-end" }}>
    <GridToolbarExportContainer
      sx={{
        "& .MuiButtonBase-root": {
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      }}
    >
      <GridCsvExportMenuItem options={csvOptions} />
    </GridToolbarExportContainer>
  </GridToolbarContainer>
);

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "30vw", xl: "20vw" },
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

export default function PriceTableViewModal(props: IPartImageModelProps) {
  const { onCloseModal, isOpen, partdetails } = props;
  const [firstRowData, setFirstRowData] = useState<any>(null);



  let rows: any = [];

  let dataGridDataGenerator: any =
    partdetails  &&
    partdetails?.map((item: any, index: any) => {
      let objShared: any = {
        id: index + 1,
        volume: item.volume,
        price: item.price,
        currency: item.currency,
        flag: item.flag,
        currency_code: item.currency_code,
      };
      rows.push(objShared);
    });

  useEffect(() => {
    if (rows.length > 0) {
      setFirstRowData(rows[0]);
    }
  }, [rows]);

  const columns = [
    {
      field: "volume",
      headerName: "Volume",
      width: 150,
      editable: false,
      renderCell: (params: any) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {params.row.volume} {params.row.volume > 1 ? "+" : ""}
          </div>
        );
      },
    },
    {
      field: "price",
      headerName: "Unit Price",
      width: 200,
      editable: false,
      renderHeader: () => {
        if (firstRowData) {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                columnGap: "0.5rem",
                width: "100%",
              }}
            >
              <Typography sx={{ fontSize: "0.87rem" }}>Unit Price</Typography>
              <Box
                sx={{
                  marginLeft: "2rem",
                  display: "flex",
                  alignItems: "center",
                  columnGap: "0.5rem",
                }}
              >
                <img
                  src={`data:image/png;base64, ${firstRowData.flag}`}
                  style={{
                    width: "1.5rem",
                    height: "1rem",
                  }}
                />{" "}
                {firstRowData?.currency_code}
              </Box>
            </Box>
          );
        }
        return "Price";
      },
      renderCell: (params: any) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {params.row.currency}{" "}
            {params.row &&
              params.row.price &&
              parseFloat(params.row.price)?.toFixed(2)}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "1rem",
              marginBottom: "0.5rem",
            }}
          >
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Supplier Pricing Details
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "2rem",
            }}
          />
          <Box sx={{ height: "60vh" }}>
            <DataGrid
              //@ts-ignore
              headerHeight={40}
              rowHeight={36}
              rows={rows}
              columns={columns}
              hideFooter
              disableSelectionOnClick={true}
              // slots={
              //   {
              //     toolbar: CustomToolbar,
              //   }
              // }
              // components={{
              //   Toolbar: CustomToolbar,
              // }}
              style={{ marginTop: "-2rem", marginRight: "0.5rem" }}
              sx={{
                "& ::-webkit-scrollbar": {
                  width: "0.3rem",
                  height: "0.3rem",
                },
                "& ::-webkit-scrollbar-thumb": {
                  backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                  borderRadius: "10px",
                },
                "& ::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "#045DE9 !important",
                },
                "::-webkit-scrollbar-track": {
                  backgroundColor: "#e1e1f3 !important",
                },
                "&.MuiDataGrid-root": {
                  border: "none",
                },
                "&.MuiDataGrid-root .MuiDataGrid-cell": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "primary.light", // Setyourdesiredcolorherecolor: '#fff',
                },
              }}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
