import React, { useState } from 'react'
import SupplierClients from './SupplierClients'
import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material'
import styles from './SupplierViewPageNew.module.scss'

export const RenderDirectorCard = ({ directorInfo, visibleDirectors }: any) => {
  
    return (
      <Card elevation={1} sx={{ borderRadius: '8px', padding: '1rem', }}>
        <CardContent sx={{ height:  visibleDirectors?.length >= 4 ? '4.5rem' : '6.6rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '0', paddingBottom: '0 !important' }}>
          <Typography sx={{ fontWeight: '600', }}>
            {directorInfo?.firstName} {directorInfo?.middleName} {directorInfo?.lastName}
          </Typography>
          <Typography >
            Appointment Date: &nbsp; {directorInfo?.appointmentDate}
          </Typography>
          <Typography>
            Designation: {directorInfo?.designation}{}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  const getDisplayData = (location: any) => {
    // location?.primaryAddress?.state
    //                   ? location?.primaryAddress?.state
    //                   : location?.address_state
    //                   ? location?.address_state
    //                   : ""
    if (location?.primaryAddress?.state || location?.address_state) {
      return "";
    } else {
      return "none";
    }
  };
  

function SupplierDirectors({supplierDetails}:any) {
    const [showAll, setShowAll] = useState(false);
    const directorsToShow = supplierDetails && supplierDetails?.directors_signatories && supplierDetails?.directors_signatories?.filter((itm: any) => itm?.isCurrentSignatory &&
    ((itm?.firstName !== null && itm?.firstName !== "null" && itm?.firstName !== undefined && itm?.firstName !== "undefined") || (itm?.lastName !== null && itm?.lastName !== "null" && itm?.lastName !== undefined && itm?.lastName !== "undefined") || (itm?.middleName !== null && itm?.middleName !== "null" && itm?.middleName !== undefined && itm?.middleName !== "undefined"))
  )

  const visibleDirectors = showAll
  ? directorsToShow
  : directorsToShow?.slice(0, 4);
const handleShowAllDirectorsClick = () => {
  setShowAll(!showAll);
};



  return (
    <Box sx={{ padding: 2, backgroundColor: '#fff', display: "grid", gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' }, gap: '1rem' }} className={styles.mainContainer}>
    <Box sx={{ borderRadius: '0.5rem', boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)', width: "100%" }}>
        <SupplierClients clients={supplierDetails?.client_information} />
        </Box>

        <Box >
        <Typography sx={{ fontWeight: "600", width: "100%", padding: '0 0.5rem' }} gutterBottom>
          Directors and authorized signatories 
        </Typography>
        {(directorsToShow && Array.isArray(directorsToShow) &&
          directorsToShow?.length >
          0) ? (
          <>
           <Grid container spacing={2}>
        {visibleDirectors?.map((directorInfo: any, index: any) => (
          <Grid item xs={12} md={6} key={index}>
            <RenderDirectorCard directorInfo={directorInfo} visibleDirectors={visibleDirectors} />
          </Grid>
        ))}
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          {directorsToShow?.length > 4 && (
            <Box sx={{ textAlign: "center", mt: 3 }}>
              <Button variant="outlined" onClick={handleShowAllDirectorsClick}>
                {!showAll ? "Show All" : "Show Less"}
              </Button>
            </Box>
          )}
        </Box>

      </Grid>
          </>
        ) : (
          <Box sx={{display:"grid", placeItems:"center", height:"15rem", borderRadius: '0.5rem', boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',}}>
            No directors or authorized signatories found
          </Box>
        )}
      </Box>


      
        
    </Box>
  )
}

export default SupplierDirectors