import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TextField,
  Typography,
  debounce,
  useTheme,
  Paper,
  TableContainer,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import UserManagementDrawer from "../UserManagementDrawer";
import styles from "./CilentPageSearchConfigurations.module.scss";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { API } from "../../../api-services";
import Swal from "sweetalert2";
import { ADMIN } from "../../../Redux/Services/admin.service";
import ConfigurationTable from "./ConfigurationTable";

interface SearchconfigurationsProps {}

type Anchor = "top" | "left" | "bottom" | "right";

export const CilentPageSearchConfigurations = (
  props: SearchconfigurationsProps
) => {
  const theme = useTheme();

  const [FreeuserSearchCount, setFreeuserSearchCount] = useState<any>(0);
  const [FreeuserSupplierCount, setFreeuserSupplierCount] = useState<any>(0);
  const [FreeuserPartCount, setFreeuserPartCount] = useState<any>(0);
  const [permissionData, setPermissionData] = useState<any>(null);
  const [FreeuserAddPartCount, setFreeuserAddPartCount] = useState<any>(0);
  const [BuyerSearchCount, setBuyerSearchCount] = useState<any>(0);
  const [BuyerSupplierCount, setBuyerSupplierCount] = useState<any>(0);
  const [BuyerPartCount, setBuyerPartCount] = useState<any>(0);
  const [BuyerAddPartCount, setBuyerAddPartCount] = useState<any>(0);
  const [SellerSearchCount, setSellerSearchCount] = useState<any>(0);
  const [SellerSupplierCount, setSellerSupplierCount] = useState<any>(0);
  const [SellerPartCount, setSellerPartCount] = useState<any>(0);
  const [SellerAddPartCount, setSellerAddPartCount] = useState<any>(0);
  const [IdSelector, setIdSelector] = useState<any>();
  const [counter, setCounter] = useState<any>(0);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : "auto" }}
      role="presentation"
      //  onClick={toggleDrawer(anchor, false)}
      //  onKeyDown={toggleDrawer(anchor, false)}
    >
      <UserManagementDrawer />
    </Box>
  );

  const ExactDebounceSeachCount = React.useRef(
    debounce(async (value: any, id: any, type: any) => {
      if (type == "freesearch") {
        API.put(
          `payment/search_configuration/${id}/`,
          {
            depth: +value,
          },
          {},
          0
        )
          .then((res: any) => {
            ADMIN.toast.info("Search configuration updated successfully");
            setFreeuserSearchCount(res?.data?.depth);
            // setUpdateCount((prev: any) => prev + 1);
          })
          .catch((err: any) => {
            const { data } = err?.response;
            Swal.fire({
              icon: "error",
              html: `<div>
                    <br />
                    <p style="color:"red">${data[0]}</p>   
                     </div>`,
            });
          });
      } else if (type == "buyersearch") {
        API.put(
          `payment/search_configuration/${id}/`,
          {
            depth: +value,
          },
          {},
          0
        )
          .then((res: any) => {
            ADMIN.toast.info("Search configuration updated successfully");
            setBuyerSearchCount(res?.data?.depth);
            // setUpdateCount((prev: any) => prev + 1);
          })
          .catch((err: any) => {
            const { data } = err?.response;
            Swal.fire({
              icon: "error",
              html: `<div>
                      <br />
                      <p style="color:"red">${data[0]}</p>   
                       </div>`,
            });
          });
      } else if (type == "sellersearch") {
        API.put(
          `payment/search_configuration/${id}/`,
          {
            depth: +value,
          },
          {},
          0
        )
          .then((res: any) => {
            ADMIN.toast.info("Search configuration updated successfully");
            setSellerSearchCount(res?.data?.depth);

            // setUpdateCount((prev: any) => prev + 1);
          })
          .catch((err: any) => {
            const { data } = err?.response;
            Swal.fire({
              icon: "error",
              html: `<div>
                      <br />
                      <p style="color:"red">${data[0]}</p>   
                       </div>`,
            });
          });
      }
    }, 1000)
  ).current;

  const ExactDebounceSupplierCount = React.useRef(
    debounce(async (value: any, id: any, type: any) => {
      if (type == "freesupplier") {
        API.put(
          `payment/search_configuration/${id}/`,
          {
            supplier_view_per_day: +value,
          },
          {},
          0
        )
          .then((res: any) => {
            ADMIN.toast.info("Search configuration updated successfully");
            setFreeuserSupplierCount(res?.data?.supplier_view_per_day);
            // setUpdateCount((prev: any) => prev + 1);
          })
          .catch((err: any) => {
            const { data } = err?.response;
            Swal.fire({
              icon: "error",
              html: `<div>
                    <br />
                    <p style="color:"red">${data[0]}</p>   
                     </div>`,
            });
          });
      } else if (type == "buyersupplier") {
        API.put(
          `payment/search_configuration/${id}/`,
          {
            supplier_view_per_day: +value,
          },
          {},
          0
        )
          .then((res: any) => {
            ADMIN.toast.info("Search configuration updated successfully");
            setBuyerSupplierCount(res?.data?.supplier_view_per_day);
            // setUpdateCount((prev: any) => prev + 1);
          })
          .catch((err: any) => {
            const { data } = err?.response;
            Swal.fire({
              icon: "error",
              html: `<div>
                      <br />
                      <p style="color:"red">${data[0]}</p>   
                       </div>`,
            });
          });
      } else if (type == "sellersupplier") {
        API.put(
          `payment/search_configuration/${id}/`,
          {
            supplier_view_per_day: +value,
          },
          {},
          0
        )
          .then((res: any) => {
            ADMIN.toast.info("Search configuration updated successfully");
            setSellerSupplierCount(res?.data?.supplier_view_per_day);

            // setUpdateCount((prev: any) => prev + 1);
          })
          .catch((err: any) => {
            const { data } = err?.response;
            Swal.fire({
              icon: "error",
              html: `<div>
                      <br />
                      <p style="color:"red">${data[0]}</p>   
                       </div>`,
            });
          });
      }
    }, 1000)
  ).current;

  const ExactDebouncePartCount = React.useRef(
    debounce(async (value: any, id: any, type: any) => {
      if (type == "freepart") {
        API.put(
          `payment/search_configuration/${id}/`,
          {
            part_view_per_day: +value,
          },
          {},
          0
        )
          .then((res: any) => {
            ADMIN.toast.info("Search configuration updated successfully");
            setFreeuserPartCount(res?.data?.part_view_per_day);
            // setUpdateCount((prev: any) => prev + 1);
          })
          .catch((err: any) => {
            const { data } = err?.response;
            Swal.fire({
              icon: "error",
              html: `<div>
                    <br />
                    <p style="color:"red">${data[0]}</p>   
                     </div>`,
            });
          });
      } else if (type == "buyerpart") {
        API.put(
          `payment/search_configuration/${id}/`,
          {
            part_view_per_day: +value,
          },
          {},
          0
        )
          .then((res: any) => {
            ADMIN.toast.info("Search configuration updated successfully");
            setBuyerPartCount(res?.data?.part_view_per_day);
            // setUpdateCount((prev: any) => prev + 1);
          })
          .catch((err: any) => {
            const { data } = err?.response;
            Swal.fire({
              icon: "error",
              html: `<div>
                      <br />
                      <p style="color:"red">${data[0]}</p>   
                       </div>`,
            });
          });
      } else if (type == "sellerpart") {
        API.put(
          `payment/search_configuration/${id}/`,
          {
            part_view_per_day: +value,
          },
          {},
          0
        )
          .then((res: any) => {
            ADMIN.toast.info("Search configuration updated successfully");
            setSellerPartCount(res?.data?.part_view_per_day);

            // setUpdateCount((prev: any) => prev + 1);
          })
          .catch((err: any) => {
            const { data } = err?.response;
            Swal.fire({
              icon: "error",
              html: `<div>
                      <br />
                      <p style="color:"red">${data[0]}</p>   
                       </div>`,
            });
          });
      }
    }, 1000)
  ).current;

  const ExactDebounceAddPartCount = React.useRef(
    debounce(async (value: any, id: any, type: any) => {
      if (type == "freeaddpart") {
        API.put(
          `payment/search_configuration/${id}/`,
          {
            add_part_for_free_limit: +value,
          },
          {},
          0
        )
          .then((res: any) => {
            ADMIN.toast.info("Search configuration updated successfully");
            setFreeuserAddPartCount(res?.data?.add_part_for_free_limit);
            // setUpdateCount((prev: any) => prev + 1);
          })
          .catch((err: any) => {
            const { data } = err?.response;
            Swal.fire({
              icon: "error",
              html: `<div>
                    <br />
                    <p style="color:"red">${data[0]}</p>   
                     </div>`,
            });
          });
      } else if (type == "buyeraddpart") {
        API.put(
          `payment/search_configuration/${id}/`,
          {
            add_part_for_free_limit: +value,
          },
          {},
          0
        )
          .then((res: any) => {
            ADMIN.toast.info("Search configuration updated successfully");
            setBuyerAddPartCount(res?.data?.add_part_for_free_limit);
            // setUpdateCount((prev: any) => prev + 1);
          })
          .catch((err: any) => {
            const { data } = err?.response;
            Swal.fire({
              icon: "error",
              html: `<div>
                      <br />
                      <p style="color:"red">${data[0]}</p>   
                       </div>`,
            });
          });
      } else if (type == "selleraddpart") {
        API.put(
          `payment/search_configuration/${id}/`,
          {
            add_part_for_free_limit: +value,
          },
          {},
          0
        )
          .then((res: any) => {
            ADMIN.toast.info("Search configuration updated successfully");
            setSellerAddPartCount(res?.data?.add_part_for_free_limit);

            // setUpdateCount((prev: any) => prev + 1);
          })
          .catch((err: any) => {
            const { data } = err?.response;
            Swal.fire({
              icon: "error",
              html: `<div>
                      <br />
                      <p style="color:"red">${data[0]}</p>   
                       </div>`,
            });
          });
      }
    }, 1000)
  ).current;

  const onChangeHandler = (e: any, type: any) => {
    if (type == "freesearch") {
      setFreeuserSearchCount(e?.target?.value);
      ExactDebounceSeachCount(
        e?.target?.value,
        IdSelector?.pre_login?.id,
        type
      );
    } else if (type == "freesupplier") {
      setFreeuserSupplierCount(e?.target?.value);
      ExactDebounceSupplierCount(
        e?.target?.value,
        IdSelector?.pre_login?.id,
        type
      );
    } else if (type == "freepart") {
      setFreeuserPartCount(e?.target?.value);
      ExactDebouncePartCount(e?.target?.value, IdSelector?.pre_login?.id, type);
    } else if (type == "freeaddpart") {
      setFreeuserAddPartCount(e?.target?.value);
      ExactDebounceAddPartCount(
        e?.target?.value,
        IdSelector?.pre_login?.id,
        type
      );
    } else if (type == "buyersearch") {
      setBuyerSearchCount(e?.target?.value);
      ExactDebounceSupplierCount(
        e?.target?.value,
        IdSelector?.buyer_login?.id,
        type
      );
    } else if (type == "buyersupplier") {
      setBuyerSupplierCount(e?.target?.value);
      ExactDebounceSupplierCount(
        e?.target?.value,
        IdSelector?.buyer_login?.id,
        type
      );
    } else if (type == "buyerpart") {
      setBuyerPartCount(e?.target?.value);
      ExactDebouncePartCount(
        e?.target?.value,
        IdSelector?.buyer_login?.id,
        type
      );
    } else if (type == "buyeraddpart") {
      setBuyerAddPartCount(e?.target?.value);
      ExactDebounceAddPartCount(
        e?.target?.value,
        IdSelector?.buyer_login?.id,
        type
      );
    } else if (type == "sellersearch") {
      setSellerSearchCount(e?.target?.value);
      ExactDebounceSeachCount(
        e?.target?.value,
        IdSelector?.seller_login?.id,
        type
      );
    } else if (type == "sellersupplier") {
      setSellerSupplierCount(e?.target?.value);
      ExactDebounceSupplierCount(
        e?.target?.value,
        IdSelector?.seller_login?.id,
        type
      );
    } else if (type == "sellerpart") {
      setSellerPartCount(e?.target?.value);
      ExactDebouncePartCount(
        e?.target?.value,
        IdSelector?.seller_login?.id,
        type
      );
    } else if (type == "selleraddpart") {
      setSellerAddPartCount(e?.target?.value);
      ExactDebounceAddPartCount(
        e?.target?.value,
        IdSelector?.seller_login?.id,
        type
      );
    }
  };

  const getPermissionData = () => {
    API.get(`payment/search_configuration/`, {}, 0)
      .then((res: any) => {
        setPermissionData(res?.data);
      })
      .catch((err: any) => {
        Swal.fire({
          title: "?",

          icon: "error",

          confirmButtonText: "Ok",
          cancelButtonText: "No",
          customClass: {
            container: "swal2Container",
          },
        });
      });
  };

  //   const getConfigData = () => {

  // 	API.get(`supplier/search_configuration/`, { }, 0)
  // 	  .then((res: any) => {
  // 		console.log(res.data);

  // 	  })
  // 	  .catch((err: any) => {

  // 		Swal.fire({
  // 			title: err.response.data[0],

  // 			icon: "error",

  // 			confirmButtonText: "Ok",
  // 			cancelButtonText: "No",
  // 			customClass: {
  // 			  container: "swal2Container",
  // 			},
  // 		  })
  // 	  });
  //   }

  useEffect(() => {
    getPermissionData();
    // getConfigData();

    API.get("payment/search_configuration/")
      .then((res: any) => {
        setFreeuserSearchCount(res?.data?.pre_login?.depth);
        setFreeuserSupplierCount(res?.data?.pre_login?.supplier_view_per_day);
        setFreeuserPartCount(res?.data?.pre_login?.part_view_per_day);
        setFreeuserAddPartCount(res?.data?.pre_login?.add_part_for_free_limit);
        setBuyerSearchCount(res?.data?.buyer_login?.depth);
        setBuyerSupplierCount(res?.data?.buyer_login?.supplier_view_per_day);
        setBuyerPartCount(res?.data?.buyer_login?.part_view_per_day);
        setBuyerAddPartCount(res?.data?.buyer_login?.add_part_for_free_limit);

        setSellerSearchCount(res?.data?.seller_login?.depth);
        setSellerSupplierCount(res?.data?.seller_login?.supplier_view_per_day);
        setSellerPartCount(res?.data?.seller_login?.part_view_per_day);
        setSellerAddPartCount(res?.data?.seller_login?.add_part_for_free_limit);
        setIdSelector(res?.data);
      })
      .catch((err: any) => {
        console.log("Server Error");
      });
  }, []);

  useEffect(() => {
    if (counter > 0) {
      getPermissionData();
    }
  }, [counter]);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}
                >
                  <Box
                    sx={{ padding: "1rem 0rem", paddingBottom: "0" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                  >
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}
                    >
                      Select Module
                    </Typography>
                    <Divider sx={{ margin: "0.5rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  style={{
                    transform: "rotate(270deg)",
                    whiteSpace: "nowrap",
                    marginTop: "40vh",
                    cursor: "pointer",
                    fontSize: "1rem",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  Select Module
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>

        {/* <Box
					// @ts-ignore
					sx={{ background: theme.palette.background.customBackground }}
					className={styles.parent_container}>
					<Box sx={{ padding: '0.5rem 2rem' }}>
						<Typography sx={{ fontSize: '1.5rem', fontWeight: '600' }}>
							Search Configurations
						</Typography>
					</Box>
					<Box
						sx={{ backgroundColor: 'background.default' }}
						className={styles.details_table}>

							

						<Table stickyHeader aria-label='sticky table'>
							<TableHead>
								<TableCell
									sx={{
										backgroundColor: 'primary.light',
										// color: 'primary.main',
										padding: '0.5rem',
									}}>
									Account Type
								</TableCell>
								<TableCell
									sx={{
										backgroundColor: 'primary.light',
										// color: 'primary.main',
										padding: '0.5rem',
									}}>
									User Type
								</TableCell>
								<TableCell
									sx={{
										backgroundColor: 'primary.light',
										// color: 'primary.main',
										padding: '0.5rem',
									}}>
									Search Count
								</TableCell>
								<TableCell
									sx={{
										backgroundColor: 'primary.light',
										// color: 'primary.main',
										padding: '0.5rem',
									}}>
									Supplier View Count (In days){' '}
								</TableCell>
								<TableCell
									sx={{
										backgroundColor: 'primary.light',
										// color: 'primary.main',
										padding: '0.5rem',
									}}>
									Part View Count (In days)
								</TableCell>
								<TableCell
									sx={{
										backgroundColor: 'primary.light',
										// color: 'primary.main',
										padding: '0.5rem',
									}}>
									Add Part Count (For Free)
								</TableCell>
							</TableHead>
							<TableBody>
								<TableRow>
									<TableCell
										sx={{
											padding: '0.2rem 0.5rem',
											borderBottomColor: 'primary.light',
											borderRight: '1px solid',
											borderColor: 'primary.light',
										}}>
										Pre Login User
									</TableCell>
									<TableCell
										sx={{
											padding: '0.2rem 0.5rem',
											borderBottomColor: 'primary.light',
										}}>
										Free
									</TableCell>

									<TableCell
										sx={{
											padding: '0.2rem 0.5rem',
											borderBottomColor: 'primary.light',
										}}>
										<TextField
											size='small'
											placeholder='Search Count'
											type='number'
											value={FreeuserSearchCount}
											onChange={(e: any) => onChangeHandler(e, 'freesearch')}
										/>
									</TableCell>
									<TableCell
										sx={{
											padding: '0.2rem 0.5rem',
											borderBottomColor: 'primary.light',
										}}>
										<TextField
											size='small'
											placeholder='Supplier view count'
											type='number'
											value={FreeuserSupplierCount}
											onChange={(e: any) => onChangeHandler(e, 'freesupplier')}
										/>{' '}
									</TableCell>
									<TableCell
										sx={{
											padding: '0.2rem 0.5rem',
											borderBottomColor: 'primary.light',
										}}>
										<TextField
											size='small'
											placeholder='Part view count'
											type='number'
											value={FreeuserPartCount}
											onChange={(e: any) => onChangeHandler(e, 'freepart')}
										/>
									</TableCell>
									<TableCell
										sx={{
											padding: '0.2rem 0.5rem',
											borderBottomColor: 'primary.light',
										}}>
										<TextField
											size='small'
											placeholder='Part view count'
											type='number'
											value={FreeuserAddPartCount}
											onChange={(e: any) => onChangeHandler(e, 'freeaddpart')}
										/>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										rowSpan={2}
										sx={{
											padding: '0.2rem 0.5rem',
											borderBottomColor: 'primary.light',
											borderRight: '1px solid',
											borderColor: 'primary.light',
										}}>
										Post Login Starter User
									</TableCell>
									<TableCell
										sx={{
											padding: '0.2rem 0.5rem',
											borderBottomColor: 'primary.light',
										}}>
										Buyer
									</TableCell>{' '}
									<TableCell
										sx={{
											padding: '0.2rem 0.5rem',
											borderBottomColor: 'primary.light',
										}}>
										<TextField
											size='small'
											placeholder='Search Count'
											type='number'
											value={BuyerSearchCount}
											onChange={(e: any) => onChangeHandler(e, 'buyersearch')}
										/>
									</TableCell>
									<TableCell
										sx={{
											padding: '0.2rem 0.5rem',
											borderBottomColor: 'primary.light',
										}}>
										<TextField
											size='small'
											type='number'
											placeholder='Supplier view count'
											value={BuyerSupplierCount}
											onChange={(e: any) => onChangeHandler(e, 'buyersupplier')}
										/>{' '}
									</TableCell>
									<TableCell
										sx={{
											padding: '0.2rem 0.5rem',
											borderBottomColor: 'primary.light',
										}}>
										<TextField
											size='small'
											type='number'
											placeholder='Part view count'
											value={BuyerPartCount}
											onChange={(e: any) => onChangeHandler(e, 'buyerpart')}
										/>
									</TableCell>
									<TableCell
										sx={{
											padding: '0.2rem 0.5rem',
											borderBottomColor: 'primary.light',
										}}>
										<TextField
											size='small'
											placeholder='Add Part  count'
											type='number'
											value={BuyerAddPartCount}
											onChange={(e: any) => onChangeHandler(e, 'buyeraddpart')}
										/>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell
										sx={{
											padding: '0.2rem 0.5rem',
											borderBottomColor: 'primary.light',
										}}>
										Seller
									</TableCell>
									<TableCell
										sx={{
											padding: '0.2rem 0.5rem',
											borderBottomColor: 'primary.light',
										}}>
										<TextField
											size='small'
											type='number'
											value={SellerSearchCount}
											placeholder='Search Count'
											onChange={(e: any) => onChangeHandler(e, 'sellersearch')}
										/>
									</TableCell>
									<TableCell
										sx={{
											padding: '0.2rem 0.5rem',
											borderBottomColor: 'primary.light',
										}}>
										<TextField
											size='small'
											type='number'
											placeholder='Supplier view count'
											value={SellerSupplierCount}
											onChange={(e: any) =>
												onChangeHandler(e, 'sellersupplier')
											}
										/>{' '}
									</TableCell>
									<TableCell
										sx={{
											padding: '0.2rem 0.5rem',
											borderBottomColor: 'primary.light',
										}}>
										<TextField
											size='small'
											type='number'
											placeholder='Part view count'
											value={SellerPartCount}
											onChange={(e: any) => onChangeHandler(e, 'sellerpart')}
										/>
									</TableCell>
									<TableCell
										sx={{
											padding: '0.2rem 0.5rem',
											borderBottomColor: 'primary.light',
										}}>
										<TextField
											size='small'
											placeholder=' Add Part  count'
											type='number'
											value={SellerAddPartCount}
											onChange={(e: any) => onChangeHandler(e, 'selleraddpart')}
										/>
									</TableCell>
								</TableRow>
								<TableRow sx={{ backgroundColor: '#ffeb3b17' }}>
									<TableCell
										sx={{
											padding: '0.2rem 0.5rem',
											borderBottomColor: 'primary.light',
											borderRight: '1px solid',
											borderColor: 'primary.light',
										}}>
										Post Login Professional User
									</TableCell>

									<TableCell
										colSpan={5}
										align='center'
										sx={{
											// fontSize: '1.3rem',
											// fontWeight: 600,
											padding: '0.5rem',
											borderBottomColor: 'primary.light',
										}}>
										Unlimited
									</TableCell>
								</TableRow>
								<TableRow sx={{ backgroundColor: 'bisque' }}>
									<TableCell
										sx={{
											padding: '0.2rem 0.5rem',
											borderBottomColor: 'primary.light',
											borderRight: '1px solid',
											borderColor: 'primary.light',
										}}>
										Asi Admin
									</TableCell>

									<TableCell
										colSpan={5}
										align='center'
										sx={{
											// fontSize: '1.3rem',
											// fontWeight: 600,
											padding: '0.5rem',
											borderBottomColor: 'primary.light',
										}}>
										Unlimited
									</TableCell>
								</TableRow>
								<TableRow sx={{ backgroundColor: '#ffeb3b63' }}>
									<TableCell
										sx={{
											padding: '0.2rem 0.5rem',
											borderBottomColor: 'primary.light',
											borderRight: '1px solid',
											borderColor: 'primary.light',
										}}>
										Asi User
									</TableCell>
									<TableCell
										colSpan={5}
										align='center'
										sx={{
											// fontSize: '1.3rem',
											// fontWeight: 600,
											padding: '0.5rem',
											borderBottomColor: 'primary.light',
										}}>
										NA
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</Box>
				</Box> */}

        <Box
          sx={{ padding: "1rem", maxHeight: "90vh" }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          <ConfigurationTable
            permissionData={permissionData}
            setCounter={setCounter}
            counter={counter}
          />
        </Box>
      </Box>
    </>
  );
};
