// src/themePalette.ts
const lightPalette = {
	mode: 'light',
	primary: {
		main: 'rgb(0,136,122)',
		light: 'rgb(165, 217, 212)',
		dark: '#005f55',
		contrastText: '#ffffff',
	},
	secondary: {
		main: 'rgb(255,204,188)',
		light: '#fff1ec',
		dark: '#b28e83',
		contrastText: '#ffffff',
	},
	background: {
		default: 'rgb(255,255,255)',
		paper: 'rgb(255,255,255)',
		// @ts-ignore
		customBackground:
			'linear-gradient(290deg, rgb(0, 136, 122) 0%, rgb(255, 255, 255) 100%)',
	},
	common: {
		black: '#000',
		white: '#fff',
	},
	text: {
		primary: 'rgb(33,33,33)',
		secondary: 'rgb(0,136,122)',
		disabled: 'rgba(0, 0, 0, 0.38)',
	},
	divider: 'rgba(255, 255, 255, 0.5)',
	error: {
		main: 'rgb(211,47,47)',
		light: 'rgb(219,88,88)',
		dark: 'rgb(147,32,32)',
		contrastText: 'rgb(255,255,255)',
	},
};

const darkPalette = {
	mode: 'dark',
	primary: {
		main: 'rgb(255,204,188)',
		light: 'rgb(249, 230, 223)',
		dark: 'rgb(255,170,145)',
		contrastText: 'rgb(0, 0, 0)',
	},
	secondary: {
		main: 'rgb(0,136,122)',
		light: '#f5f5f5',
		dark: '#424242',
		contrastText: 'rgb(255, 255, 255)',
	},
	common: {
		black: '#000',
		white: '#fff',
	},
	text: {
		primary: 'rgb(255,255,255)',
		secondary: 'rgba(0,0,0,1)',
		disabled: 'rgba(0, 0, 0, 0.38)',
	},
	background: {
		default: '#121212',
		paper: '#121212',
		// @ts-ignore
		customBackground:
			'linear-gradient(90deg, rgba(255, 255, 255, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%)',
	},
	divider: 'rgba(255, 255, 255, 0.5)',
	error: {
		main: 'rgb(211,47,47)',
		light: 'rgb(219,88,88)',
		dark: 'rgb(147,32,32)',
		contrastText: 'rgb(255,255,255)',
	},
};

export { lightPalette, darkPalette };
