import React from "react";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import * as yup from "yup";
import {
  Autocomplete,
  Divider,
  FormControl,
  InputLabel,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarContainer,
  GridToolbarExportContainer,
} from "@mui/x-data-grid";
import useWindowDimensions from "../ui-reusable-component/useWindowDimensions";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { API } from "../api-services";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
interface AddPartModalProps {
  onCloseModal: any;
  isOpen: any;
  item: any;
  getZBCRequest: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  padding: "1rem",
};
const validationSchema = yup.object({
  name: yup.string().required("Please type Part name"),
  category: yup.mixed().required("Please select BOM category"),
  vehicle_category: yup.mixed().required("Please select vehicle category"),
});
const csvOptions: GridCsvExportOptions = {
  fileName: "Table In CSV",
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: "1.5rem", marginBottom: "0.5rem" }}>
    <span style={{ position: "absolute", right: "0rem" }}>
      {/* <GridToolbarColumnsButton /> */}
      {/* <GridToolbarDensitySelector /> */}
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);
const AddPartModal = (props: AddPartModalProps) => {
  const { onCloseModal, isOpen, item ,getZBCRequest} = props;
  // const { height, width } = useWindowDimensions();
  const [loader, setLoader] = React.useState<boolean>(false);
  const history = useHistory();
  const [BOMCategory, setBOMCategory] = React.useState<any>([]);  
  const [vehicleCategory, setVeicleCategory] = React.useState<any>([]);

  const getVehicleCategory = () => {
    API.get("/cart/get_all_vehiclecategory_list/", {}, 0)
      .then((res: any) => {
        setVeicleCategory(res.data);
      })
      .catch((err: any) => {});
  };

  const getBOMCategory = () => {
    API.get(
      "/cart/get_all_bomcategory_list/",
      {
        //@ts-ignore
        vehicle_category: formik.values.vehicle_category?.id,
        category_type: formik.values.part_type,
      },
      0
    )
      .then((res: any) => {
        setBOMCategory(res.data);
      })
      .catch((err: any) => {});
  };

  React.useEffect(() => {
    getVehicleCategory();
  }, []);


  const formik = useFormik({
    initialValues: {
      category: undefined,
      name: "",
      vehicle_category: item?.auto_select,
      part_type: item?.part_type,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // if(item?.category){
      setLoader(true);
      API.post(
        `/cart/create_part_for_zbc/${item.ID}/`,
        {
          //@ts-ignore
          category: values.category.cat_id,
          name: values.name,
          //@ts-ignore
          vehicle_category:values.vehicle_category?.id
        },
        {},
        0
      )
        .then((res: any) => {
          getZBCRequest();
          setLoader(false);
          onCloseModal();
          // history.push("/data-base/data-base-landing-page");
        })
        .catch((err: any) => {
          const { data } = err?.response;
            Swal.fire({
              icon: "error",
              html: `<div>
              <br />
              <p style="color:"red">${data[0]}</p>   
               </div>`,
            });
          
          setLoader(false);
        });

      // }else{
      //     history.push("/project_directory")
      // }
    },
  });
  React.useEffect(() => {

    //@ts-ignore
     if (formik.values.vehicle_category && Object.keys(formik.values.vehicle_category)?.length > 0)
      
      
      {
      getBOMCategory();
    }
  }, [formik.values,item]);
  React.useEffect(() => {


    if(item?.auto_select){
     
      formik.setFieldValue("vehicle_category", item?.auto_select);
    }
    if (item?.part_name) {
      formik.setFieldValue("name", item.part_name);
    }
  }, [item]);

  return (
    <div>
      <Modal open={isOpen} onClose={onCloseModal}>
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              columnGap: "1rem",
              marginBottom: "0.5rem",
              padding: "1rem",
            }}
          >
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Create Part
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              margin: "0rem",
              marginBottom: "0rem",
            }}
          />
          <form onSubmit={formik.handleSubmit}>
            <FormControl fullWidth variant="standard">
              <InputLabel
                sx={{ color: "primary.main", marginLeft: "1rem" }}
                shrink
                id="demo-simple-select-label"
              >
                Part Name <sup style={{ color: "red" }}>*</sup>
              </InputLabel>
              <TextField
                variant="standard"
                // autoFocus={autoFocusStatus}
                InputProps={{
                  style: { color: "#007fff" },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  margin: "1rem 1rem",
                  "& .Mui-error": {
                    color: "#d32f2f",
                    borderBottomColor: "#d32f2f",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "primary.light",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "primary.main",
                  },
                  "& .MuiFormLabel-root.MuiInputLabel-root ": {
                    color: "primary.main",
                  },
                  ".MuiInputBase-root.MuiInput-root": {
                    fontSize: "1rem !important",
                  },
                }}
                id="name"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </FormControl>
            {
             item?.requested_vehicle_category!==null && 
            
            <FormControl
              sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
              fullWidth
              variant="standard"
            >
              <InputLabel
                sx={{ color: "primary.main", marginLeft: "1rem" }}
                shrink
                id="demo-simple-select-label"
              >
                Requested Product Category
                <sup
                  style={{
                    color: "red",
                    marginTop: "0.5rem",
                    marginLeft: "0.4rem",
                  }}
                >
                  
                </sup>
              </InputLabel>

              <TextField
                variant="standard"
                // autoFocus={autoFocusStatus}
                InputProps={{
                  style: { color: "#007fff" },
                }}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  margin: "1rem 1rem",
                  "& .Mui-error": {
                    color: "#d32f2f",
                    borderBottomColor: "#d32f2f",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "primary.light",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "primary.main",
                  },
                  "& .MuiFormLabel-root.MuiInputLabel-root ": {
                    color: "primary.main",
                  },
                  ".MuiInputBase-root.MuiInput-root": {
                    fontSize: "1rem !important",
                  },
                }}
                id="name"
                name="name"
                 
                value={item?.requested_vehicle_category}
         
              />
          {/* <p>{item?.requested_vehicle_category}</p> */}
            </FormControl>
}
            <FormControl
              sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
              fullWidth
              variant="standard"
            >
              <InputLabel
                sx={{ color: "primary.main", marginLeft: "1rem" }}
                shrink
                id="demo-simple-select-label"
              >
                Product Category
                <sup
                  style={{
                    color: "red",
                    marginTop: "0.5rem",
                    marginLeft: "0.4rem",
                  }}
                >
                  *
                </sup>
              </InputLabel>
              <Autocomplete
                id="vehicle_category"
                getOptionLabel={(option: any) => option.name}
                options={vehicleCategory}
                value={formik.values.vehicle_category}
                onChange={(event: any, newValue: any | null) => {
                  formik.setFieldValue("vehicle_category", newValue);
                }}
                autoSelect
                sx={{ marginTop: "1.4rem" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="vehicle_category"
                    variant="standard"
                    sx={{
                      marginLeft: "1rem",
                      width: "97%",
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                      ".MuiSvgIcon-root": { color: "primary.main" },
                    }}
                  />
                )}
              />
              <div
                style={{
                  fontSize: "0.75rem",
                  color: "#d32f2f",
                  marginTop: "1.5rem",
                  marginLeft: "1.45rem",
                }}
              >
                {formik.touched.vehicle_category &&
                  Boolean(formik.errors.vehicle_category) && (
                    //@ts-ignore
                    <p>{formik.errors.vehicle_category}</p>
                  )}
              </div>
            </FormControl>
            <FormControl
              sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
              fullWidth
              variant="standard"
            >
              <InputLabel
                sx={{ color: "primary.main", marginLeft: "1rem" }}
                shrink
                id="demo-simple-select-label"
              >
                BOM category
                <sup
                  style={{
                    color: "red",
                    marginTop: "0.5rem",
                    marginLeft: "0.4rem",
                  }}
                >
                  *
                </sup>
              </InputLabel>
              <Autocomplete
                id="category"
                getOptionLabel={(option: any) => option.cat_name}
                options={BOMCategory}
                value={formik.values.category}
                onChange={(event: any, newValue: any | null) => {
                  formik.setFieldValue("category", newValue);
                }}
                autoSelect
                sx={{ marginTop: "1.4rem" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="category"
                    variant="standard"
                    sx={{
                      marginLeft: "1rem",
                      width: "97%",
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                      ".MuiSvgIcon-root": { color: "primary.main" },
                    }}
                  />
                )}
              />
              <div
                style={{
                  fontSize: "0.75rem",
                  color: "#d32f2f",
                  marginTop: "0.8em",
                  marginLeft: "1.45rem",
                }}
              >
                {(BOMCategory?.length >0 && formik.touched.category && Boolean(formik.errors.category)) && (
                  //@ts-ignore
                 <p>{formik.errors.category}</p>
                )}
                {BOMCategory?.length ===0  && <p>{"*Please Complete and Validate BOM Configuration to Proceed."}</p>}
              </div>
            </FormControl>
            <LoadingButton
              sx={{ float: "right" }}
              variant="contained"
              type="submit"
              loading={loader}
            >
              Submit
            </LoadingButton>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default AddPartModal;
