import { createEntityAdapter, createReducer, createSelector, EntityState } from "@reduxjs/toolkit";
import { ID } from "../../utlis/commonInterfaces";
import { getUserProfilePic, resetUsers } from "../Actions/user.action";
import { ADMIN } from "../Services/admin.service";
import { RootState } from "../Store/storeConfigurations";



export interface User {
    id: ID;
    first_name: any;
    employee_id: string|any;
    designation: string;
    phone: number;
    last_name: string|any;
    username: any;
    email: any;
    is_active: boolean;
    is_project_admin: boolean;
    is_module_admin: boolean;
    is_superuser: boolean;
    user_info: any;
    remaining_guest_count?: any;
    cover_image?: any;
    user_category?: any;
    user_expiration?: any;
    country_code?:any;
    company_name:any;
    user_type_name:any;    
    tax_id:any;    
    profile_img:any;
    requested_to_claim_business:any;
    my_business:any;
    cart_count?: any;
    is_adminuser?: any;
}

const userAdaptor = createEntityAdapter<User>( {
    selectId: user => user.id,
    sortComparer: ( a, b ) => a.username.localeCompare( b.username ),
} );

type AdditionalFields = {};

type IUserReducer = EntityState<User> & AdditionalFields;
const additionalFields: AdditionalFields = {};

const initialState: IUserReducer = Object.assign(
    {},
    userAdaptor.getInitialState(),
    additionalFields
);


const UserReducer = createReducer( initialState, builder => {
    // get
    // builder
    //     .addCase( getUsers.fulfilled, ( state, action: any ) => {
    //         userAdaptor.setAll( state, action.payload.users );
    //     } )
    //     .addCase( getUsers.rejected, ( _state, action ) => {
    //         ADMIN.toast.throwError( action.payload, "Could not fetch users" );
    //     } );
    //getUserProfilePic

    builder
        .addCase( getUserProfilePic.fulfilled, ( state, action: any ) => {
            userAdaptor.setAll( state, action.payload );
        } )
        .addCase( getUserProfilePic.rejected, ( _state, action ) => {
            // @ts-ignore
            if( action?.payload?.response?.status==401 )
           {
            console.log(action.payload)
           }
            else{
                // ADMIN.toast.throwError( action.payload, "Could not fetch users" );
            }

            
        } );
    // reset reducer
    builder.addCase( resetUsers, () => initialState );
} );
const selectors = userAdaptor.getSelectors<RootState>( state => state.user);

const selectAllNonSuperusers = createSelector( selectors.selectAll, users => {
    return users.filter( user => !user.is_superuser );
} );

const selectNonMatchingUsers = createSelector(
    selectors.selectAll,
    ( _state: RootState, userIds: ID[] ) => userIds,
    ( users, userIds ) => {
        return users.filter( user => !userIds.includes( user.id ) );
    }
);

const selectAllNonSuperusersNonMatchingUsers = createSelector(
    selectAllNonSuperusers,
    ( _state: RootState, userIds: ID[] ) => userIds,
    ( users, userIds ) => {
        return users.filter( user => !userIds.includes( user.id ) );
    }
);

const userSelectors = Object.assign(
    {},
    {
        selectAllNonSuperusers,
        selectNonMatchingUsers,
        selectAllNonSuperusersNonMatchingUsers,
    },
    selectors
);

// -----------------------------------------------------------------------------------
// exports

export { UserReducer, initialState as UserReducerInit, userSelectors };