// react

import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';


// vendors
// services
// utils
// constants
// factories
// core
// redux
// ui-components
// others
// styles
import styles from "./TriangulationTable.module.scss";


import { Table, TableCell, TableHead, TableRow } from '@mui/material';
import { triangulationSelectors } from '../../../Redux/Reducers/triagulation.reducer';
import TriangulationRow from './TriangulationRow';

// interfaces and types
// lazy

// ----------------------------------------------------------------------------------

interface CostTriangulationTableProps {
    setCountValue: ( value: number ) => void;
    dataFetched: any;
}

interface Params {
    topVaultName: string;
}

const TriangulationTable: React.FC<CostTriangulationTableProps> = ( props ) => {

    const { setCountValue,dataFetched } = props;

    const { topVaultName } = useParams<Params>();
    const [count, setCount] = React.useState<number>( 0 );




    const triangulationData: any = useSelector( triangulationSelectors.selectAll );

    React.useEffect( () => {
        setCountValue( count );
    }, [count] );


    const data = [
        {
            name: "Manufacturing cost",
            oem_data: 1,
            asi_data: 2,
            showroom_cost: triangulationData[0] != undefined && triangulationData[0].oem_manufacturing_cost,
            manufacturing_cost: triangulationData[0] != undefined && triangulationData[0].asi_manufacturing_cost,
            percentage: triangulationData[0] != undefined && ( ( ( triangulationData[0].oem_manufacturing_cost / triangulationData[0].asi_manufacturing_cost ) - 1 ) * 100 ).toFixed( 2 ),
        },
        {
            name: "Ex-Showroom pricing excluding GST",
            oem_data: 3,
            asi_data: 4,
            showroom_cost: triangulationData[0] != undefined && triangulationData[0].oem_ex_showroom_cost,
            manufacturing_cost: triangulationData[0] != undefined && triangulationData[0].asi_ex_showroom_cost,
            percentage: triangulationData[0] != undefined && ( ( ( triangulationData[0].oem_ex_showroom_cost / triangulationData[0].asi_ex_showroom_cost ) - 1 ) * 100 ).toFixed( 2 ),
        },
    ];


    return (
        <div data-testid="Cost-Triangulation-Table" className={ styles.container }>
            <div className={ styles.table_wrapper }>
             
                <Table  >
                    <TableHead className={ styles.table_header } >
                        <TableRow>
                            <TableCell sx={{padding:'0.2rem 0.5rem', borderBottomColor:'primary.light',textAlign:'center'}}  >Category</TableCell>
                            <TableCell sx={{padding:'0.2rem 0.5rem', borderBottomColor:'primary.light',textAlign:'center'}} colSpan={2} > From Balance Sheet</TableCell>
                            <TableCell sx={{padding:'0.2rem 0.5rem', borderBottomColor:'primary.light',textAlign:'center'}} colSpan={2} > From Zero-based Costing</TableCell>
                            <TableCell sx={{padding:'0.2rem 0.5rem', borderBottomColor:'primary.light',textAlign:'center'}}>Variance</TableCell>
                        </TableRow>
                    </TableHead>
                    {
                        data.map( ( value: any, index: any ) => {
                            return (
                                <TriangulationRow value={ value } setCountValue={ setCount } key={ index } />
                            );
                        } )
                    }
                </Table>
            </div>

        </div>
    );
};

// ----------------------------------------------------------------------------------

export default TriangulationTable;