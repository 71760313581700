import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from "@mui/material";


const modalStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
   
    maxWidth: "900px",
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 3,
};

interface OtherLocationsModalProps {
    isOpen: boolean;
    onCloseModal: () => void;
    records: [];
}

function OtherLocationsModal({ isOpen, onCloseModal, records }:any) {
    return (
        <Modal
            open={isOpen}
            onClose={onCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            
        >
            <Box sx={modalStyle}>
                <Typography variant="h6" component="h2" gutterBottom>
                    Other Locations
                </Typography>
                <TableContainer component={Paper} sx={{ maxHeight: "70vh", overflow: 'auto' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: "#fff", backgroundColor: "#00887a" }}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Country</Typography>
                                </TableCell>
                                <TableCell sx={{ color: "#fff", backgroundColor: "#00887a" }}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>District</Typography>
                                </TableCell>
                                <TableCell sx={{ color: "#fff", backgroundColor: "#00887a" }}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Line</Typography>
                                </TableCell>
                                <TableCell sx={{ color: "#fff", backgroundColor: "#00887a" }}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Pincode</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {records.map((record:any, index:any) => (
                                <TableRow key={index}>
                                    <TableCell>{record?.address_country ?? 'N/A'}</TableCell>
                                    <TableCell>{record.address_district ?? 'N/A'}</TableCell>
                                    <TableCell>{record.address_line ?? 'N/A'}</TableCell>
                                    <TableCell>{record.address_pincode ?? 'N/A'}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Modal>
    );
};

export default OtherLocationsModal;