import * as React from "react";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { Autocomplete, Chip, TextField } from "@mui/material";
import { API } from "../../api-services";
import { debounce } from "lodash";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  increaseCounter,
  validationCheckCounter,
} from "../../Redux/Actions/compareopen.action";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { useBaseModal } from "../SearchPage/useBaseModal";
import ErrorModalFilesMail from "../ProjectMailModule/ErrorModalFilesMail";

export interface IEditFeatureNameProps {
  value: any;
  idd: any;
  permission?: any;
}

export function EditFeatureName(props: IEditFeatureNameProps) {
  const history = useHistory();
  const store: any = useSelector((state) => state);

  const dispatch = useDispatch();

  const {
    projectId,
    projectname,
    featureId,
    feId,
    pageNumber,
    parent,
    child,
    type,
  } = useRouteParams<any>();
  const { value, idd, permission } = props;
  const [color, setColor] = React.useState<any>("");
  const [featureName, setFeatureName] = React.useState<any>("");
  const [values, setValues] = React.useState({});
  const [UserManualEamil, setUserManualEamil] = React.useState<any>({});
  const [VehicleType, setVehicleType] = React.useState<any>();
  const [FeatureList, setFeatureList] = React.useState<any>([]);
  const ErrorModalFiles=useBaseModal()
  const [ErrorModalFileMsg, setErrorModalFileMsg] = React.useState<any>();
  React.useEffect(() => {
    if (value) {
      setFeatureName(value);
    }
  }, [value]);

  console.log(value,"valuevalue")
  const PartNameHandler = (item: any) => {
    setUserManualEamil(item);
  };

  React.useEffect(() => {
    API.get(
      "/xcpep/feature_groups/",
      {
        project: projectId,
        type: type - 1,
        abbreviation: parent,
      },
      0
    )
      .then((res: any) => {
        var filterData = res.data.filter((item: any) => item?.id == child)[0];
        setVehicleType(filterData?.vehicle_type);
        API.get(`/db/feature_name/`, {
          subsystem_abbreviation: parent,
          vehicle_type: filterData?.vehicle_type,
          feature_type: type == 1 ? 0 : type == 2 ? 1 : 2,
          group_id: child
        })
          .then((res: any) => {
            var filterData = res.data.filter((item: any) => item?.name == value)[0];
            setValues(filterData);

         
            setFeatureList(res?.data);
          })
          .catch((err: any) => {
            console.log("Server Error");
          });
      })
      .catch((err: any) => {});
  }, [value]);

  const ManualEmailHandler = (e: any) => {
    if (e.key == "Enter") {
      var FilterName=FeatureList.filter((item:any)=>item?.name==UserManualEamil)[0]
   {
      API.put(`/xcpep/product_feature/${feId}/`, {
        name: UserManualEamil,
        subsystem_abbreviation: parent,
      }).then((res1: any) => {
        API.get(`/db/feature_name/`, {
          subsystem_abbreviation: parent,
          vehicle_type: VehicleType,
          feature_type: type == 1 ? 0 : type == 2 ? 1 : 2,
          group_id: child
        })
          .then((res: any) => {
            setFeatureList(res?.data);
          })
          .catch((err: any) => {
            console.log("Server Error");
          });
      }).catch((err:any)=>{
        const { data } = err?.response;
      ErrorModalFiles?.open()
      setErrorModalFileMsg(data)
      })
    }
  }
  };

  const PasteEmailHandler = (data: any) => {
    if (data) {
      var FilterName=FeatureList.filter((item:any)=>item?.name==data)[0]
    {


      {
        API.put(`/xcpep/product_feature/${feId}/`, {
          name: data,
          subsystem_abbreviation: parent,
        }).then((res1: any) => {
          API.get(`/db/feature_name/`, {
            subsystem_abbreviation: parent,
            vehicle_type: VehicleType,
            feature_type: type == 1 ? 0 : type == 2 ? 1 : 2,
            group_id: child,
          })
            .then((res: any) => {
              setFeatureList(res?.data);
            })
            .catch((err: any) => {
              console.log("Server Error");
            });
        }).catch((err:any)=>{
         
            const { data } = err?.response;
          ErrorModalFiles?.open()
          setErrorModalFileMsg(data)
          })
        
      }
    }
  }
  };

  const handleSelectChange = (touser: any) => {
    // @ts-ignore
    setValues(touser);
  };
  // const ExactDebounce = React.useRef(
  //   debounce(async (value: any) => {
  //     API.put(`xcpep/product_feature/${feId}/`, { name: value }, {}, 0)
  //       .then((res: any) => {
  //         if(res.data){
  //         var random: any = Math.floor(Math.random() * 1000000 + 1);
  //         // history.push(`/createfeature/${projectId}/${projectname}/${type}/${parent}/${child}/${value}/${feId}/${pageNumber}`);
  //         dispatch(increaseCounter(random));
  //         dispatch(validationCheckCounter(random + 1));
  //         }
  //       })
  //       .catch((err: any) => {
  //         const { data } = err?.response;
  //         Swal.fire({
  //           icon: "error",
  //           html: `<div>
  //           <br />
  //           <p style="color:"red">${data[0]}</p>
  //            </div>`,
  //         });
  //       });
  //   }, 4000)
  // ).current;

  // React.useEffect(() => {
  //   return () => {
  //     ExactDebounce.cancel(); // Cancel the debounced function
  //   };
  // }, []);

  const handleonChange = (e: any) => {
    const { value } = e.target;
    setColor("red");

    setFeatureName(value);
  };

  return (
    <div style={{ display: "flex",alignItems:'center',columnGap:'0.5rem' }}>
      <ErrorModalFilesMail
            isOpen={ErrorModalFiles.isOpen}
            onCloseModal={ErrorModalFiles.close}
            ErrorMsg={ErrorModalFileMsg}
          />
     

      {FeatureList && (
        <Autocomplete
          // disableClearable
          id="User"
          value={values}
          limitTags={6}
          disabled={!permission?.includes("U")}
          disableCloseOnSelect
          sx={{ width: "18vw" }}
          onInputChange={(event, newInputValue) => {
            PartNameHandler(newInputValue);
          }}
          onChange={(event, newValue) => {
            handleSelectChange(newValue);
          }}
          onPaste={(event: any) => {
            PasteEmailHandler(event.clipboardData.getData("text"));
          }}
          options={FeatureList && FeatureList}
          // @ts-ignore
          getOptionLabel={(option) =>
            // @ts-ignore
            option?.name == undefined ? "" : option?.name
          }
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                size="small"
                //  @ts-ignore
                // sx={{ color: "#007fff", background: `${option?.user_color}`, fontSize: '1rem' }}
                // @ts-ignore
                label={option?.name}
                {...getTagProps({ index })}
                // disabled={fixedOptions.indexOf(option) !== -1}
              />
            ))
          }
          renderOption={(props, option, { selected }) => (
            <li {...props} style={{ fontSize: '1rem' }}>
              {/* @ts-ignore */}
              {option?.name}
            </li>
          )}
          onKeyDown={(e: any) => ManualEmailHandler(e)}
          renderInput={(params) => (
            // @ts-ignore
            <TextField
              {...params}
              // @ts-ignore
              variant="standard"
              // @ts-ignore
              placeholder="Enter Feature Name"
              sx={{
                ".MuiInputBase-root.MuiInput-root":{
                  fontSize:'1rem !important'
                },			
                '& .MuiInput-underline:before': { borderBottomColor: 'primary.main' },
                '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                ".MuiSvgIcon-root": { color: "primary.main" },
                // @ts-ignore
                // backgroundColor: `${params?.user_color}`,
              }}
              //   InputProps={{
              //     // background: `${params?.user_color}`
              //     // @ts-ignore
              //     style: "background-color: ${params?.user_color",

              //  }}
              // sx={{ background: `${params?.user_color}` }}
            />
          )}
        />
      )}
      {/* @ts-ignore */}
       { values && values?.name && <LoadingButton
        sx={{
          minWidth: "1rem",
          padding: "0.2rem 0.5rem",
          lineHeight: 1,          
          ".MuiCircularProgress-root": {
            color: "primary.main",
          },
        }}>          
         
        <SaveIcon
          sx={{ cursor: "pointer", color: color }}
          onClick={() => {
            API.put(
              `xcpep/product_feature/${feId}/`,
              // @ts-ignore
              { name: values?.name },
              {},
              0
            )
              .then((res: any) => {
                if (res.data) {
                  var random: any = Math.floor(Math.random() * 1000000 + 1);
                  history.push(
                       // @ts-ignore
                    `/createfeature/${projectId}/${projectname}/${type}/${parent}/${child}/${values?.name}/${feId}/${pageNumber}`
                  );
                  dispatch(increaseCounter(random));
                  dispatch(validationCheckCounter(random + 1));
                  setColor(undefined);
                }
              })
              .catch((err: any) => {
                const { data } = err?.response;
                Swal.fire({
                  icon: "error",
                  html: `<div>
                    <br />
                    <p style="color:"red">${data[0]}</p>   
                     </div>`,
                });
                setColor(undefined);
              });
          }}
        />
      </LoadingButton>}
    </div>
  );
}
