import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Divider, Typography } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CancelIcon from '@mui/icons-material/Cancel';
import { API } from "../../api-services";
import { LoadingButton } from "@mui/lab";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: " 30vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

interface BOMParameterAddModalProps {
  isOpen: any;
  onCloseModal: any;
  parameterListData: any;
  apiCallValidationShowData: any;
  setApiCallValidationShowData: any;
  validationShowData: any;
  setParentCount?: any;
}

const BOMParameterAddModal = (props: BOMParameterAddModalProps) => {
  const { isOpen, onCloseModal, parameterListData, apiCallValidationShowData, setApiCallValidationShowData, validationShowData, setParentCount } = props;
  const [paramterId, setParameterId] = useState<any>();
  const [loaderAddParameter, setLoaderAddParameter] = useState<boolean>(false);

  useEffect(() => {
    setParameterId(undefined)
  }, [isOpen]);

  const handleChange = (event: SelectChangeEvent) => {
    setParameterId(event.target.value);
  };

  const handleSave = () => {
    setLoaderAddParameter(true);
    API.post('bom/custom_validation/', { parameter_relation: paramterId }, 0).then((res: any) => {
      setLoaderAddParameter(false);
      setApiCallValidationShowData(!apiCallValidationShowData)
      setParentCount && setParentCount((prev: any) => prev + 1)
      onCloseModal();
    }).catch((err: any) => {
      console.log(err);
      setLoaderAddParameter(false)
    })
  }

  return (
    <div>
      <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Add Validation
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{padding:'0 0.5rem'}}>
            <FormControl variant="standard" fullWidth>
              <InputLabel sx={{color:'primary.main'}} shrink id="demo-simple-select-standard-label">Select Validation Parameter</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={paramterId}
                onChange={handleChange}
                label="Parameter"
                sx={{
                  "&:before": {
                    borderBottomColor: "primary.light",
                  },
                  "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":{
                    fontSize:'1rem'
                  },
                  '.MuiSvgIcon-root.MuiSelect-icon':{color:'primary.main'},
                }}
              >
                {parameterListData && parameterListData?.filter((item: any) => validationShowData?.every((obj2: any) => obj2.relation_id != item.id))?.map((item: any) => {
                  return (
                    <MenuItem sx={{fontSize:'1rem'}} value={item?.id}>{item?.parameter?.name}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ textAlign: 'right', mr: '1rem', }}>
            <LoadingButton size="small" variant='contained' loading={loaderAddParameter} sx={{
              padding: "0.5rem",
              lineHeight: 1,
              marginTop: "1rem",
              "&:hover": {
                transform: 'Scale(1.05)',
                transition: 'transform 0.5s ease',
              },
              '.MuiCircularProgress-root': {
                color: 'primary.main',
              },
            }} disabled={paramterId == undefined} onClick={handleSave}>Add</LoadingButton>
          </Box>
        </Box>
      </Modal>
    </div >
  );
};

export default BOMParameterAddModal;
