import * as React from 'react';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useHistory } from "react-router-dom";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import CancelIcon from '@mui/icons-material/Cancel';
import { InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { API } from "../../api-services";
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "45vw",
    height: "fit-content",
    bgcolor: "background.paper",
    borderRadius: "10px",
    p: 0,
};

interface IWatermarkModelProps {
    isWMOpen: any;
    onCloseModal: any;
    getGuidelinesData?: any;
}

function WatermarkModel(props: IWatermarkModelProps) {
    const { isWMOpen, onCloseModal, getGuidelinesData } = props;
    const [value, setValue] = React.useState("");
    const [selectedId,setSelectedId]=React.useState<any>()
    const history = useHistory<any>();
    const {projectId}=useRouteParams<any>();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };
    const getWatermarkData = () => {
        API.get("/cost/cost_guideline_watermark/", {
            vault: projectId
        }, 0).then((res: any) => {
            setValue(res.data[0]?.water_mark)
            setSelectedId(res.data[0]?.id)            
        }).catch((err: any) => {

        })
    }
    React.useEffect(() => {
        getWatermarkData()
    }, [])

const saveWatermark=()=>{
    API.patch(`/cost/cost_guideline_watermark/${selectedId}/`, {
        water_mark: value,
        vault:projectId
    }, {}, 0).then((res: any) => {
        getGuidelinesData()   
        onCloseModal()
    }).catch((err: any) => { })
}



    return (
        <div>
            <Modal
                open={isWMOpen}
                onClose={onCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem', backgroundColor: '#007fff14', padding: '0.5rem 1rem', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '5px' }}>
                            <Typography
                                sx={{
                                    fontSize: "1rem",
                                    fontWeight: "600",
                                    width: "100%",
                                    textAlign: "left",
                                }}
                            >
                                Write watermark text
                            </Typography>
                        </Box>
                        <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', }} onClick={onCloseModal} />
                    </Box>
                    <Box sx={{ padding: '2rem', textAlign: 'center' }}>
                        <TextField
                            variant='standard'
                            placeholder='Do not share with any...'
                            multiline
                            value={value}
                            onChange={handleChange}
                            InputProps={{
                                style:{ fontSize: '1rem' }
                            }}
                            sx={{                                
                                width: '100%',
                                '& .MuiInput-underline:before': { borderBottomColor: 'primary.main' },
                                '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                            }}
                        />
                        <Button size='small' variant='contained' sx={{ marginTop: '1rem' }} onClick={saveWatermark}>Save</Button>
                    </Box>
                </Box>
            </Modal>

        </div>
    );
}
export default WatermarkModel;
