import * as React from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Popover,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Typography,
} from "@mui/material";
import styles from "./BOMEntrySection.module.scss";
import InfoIcon from "@mui/icons-material/Info";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { debounce, set } from "lodash";

import { ADMIN } from "../../../Redux/Services/admin.service";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import { NumberInputBox } from "./NumberInputBox";
import { TextInputBox } from "./TextInputBox";
import { DescriptionInputBox } from "./DescriptionInputBox";
import { SingleSelectInputBox } from "./SingleSelectInputBox";
import BomEntryTableComponent from "./BomEntryTableComponent";
import { useQueryDispatch } from "../../../CustomHook/useQueryDispatch";
import {
  getCustomValidations,
  resetCustomValidations,
} from "../../../Redux/Actions/customValidations.actions";
import {
  getBOMConfigurationsByCategory,
  resetParameterConfigs,
} from "../../../Redux/Actions/parameterConfig.actions";
import {
  // getMappedWorkstations,
  resetWorkstations,
} from "../../../Redux/Actions/workstation.actions";

import swal from "sweetalert";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import { BooleanInputBox } from "./BooleanInputBox";
import { DateInputBox } from "./DateInputBox";
import { DatabaseInputBox } from "./DatabaseInputBox";
import { userPermissionAction } from "./BOMEntryLandingPage";
import { CheckBoxComponent } from "./CheckBoxComponent";
import { useSelector } from "react-redux";
import { userSelectors } from "../../../Redux/Reducers/user.reducer";
import LoadingButton from "@mui/lab/LoadingButton";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import BOMImageBasedSearchModal from "./BOMImageBasedSearchModal";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import BOMDeletePartUsedDetailsModal from "./BOMDeletePartUsedDetailsModal";
import JoditEditor from "jodit-react";
import PublishBOMModal from "../../CostingCreateModule/CostingEstimation/PublishBOMModal";
import { useBaseParamsModal } from "../../../CustomHook/useBaseParamsModal";
import AddMspModal from "./AddMspModal";

export interface IBOMEntrySectionProps {
  setrecyclebinCounter: any;
  setParentsCounter: any;
  ParentsCounter: any;
  setSyncColor: any;
  setCategoryCounter: any;
  categoryCounter: any;
}
const label = { inputProps: { "aria-label": "Checkbox demo" } };
type DataItem = Record<string, boolean>;

export default function BOMEntrySection(props: IBOMEntrySectionProps) {
  const {
    setrecyclebinCounter,
    setParentsCounter,
    ParentsCounter,
    setSyncColor,
    setCategoryCounter,
    categoryCounter,
  } = props;
  const history = useHistory();
  const userProfilePic = useSelector(userSelectors.selectAll);
  const inputFieldRef = React.useRef<any>();
  const nextInputFieldRef = React.useRef<any>();
  const partDescriptionRef = React.useRef<any>();
  const {
    vehCateId,
    vehCateName,
    vault,
    topVault,
    treeabbr,
    workstation,
    isLeaf,
    copyStatus,
    privateStatus,
  } = useRouteParams<any>();

  // const [workStationList, setWorkStationList] = React.useState<any>([]);
  // const [workstationLoader, setWorkstationLoader] =
  //   React.useState<boolean>(false);
  const [partName, setPartName] = React.useState<any>("");
  const [checkedList, setCheckedList] = React.useState<any>([]);
  const [originalPartName, setOriginalPartName] = React.useState<any>("");
  const Publishmodal = useBaseModal();
  const mspModal = useBaseParamsModal();
  const [PartData, setPartData] = React.useState<any>();
  const [searchKey, setSearchkey] = React.useState<any>("");
  const [partQty, setPartQty] = React.useState<any>("");
  const [category, setCategory] = React.useState<any>("");
  const [BOMDetails, setBOMDetails] = React.useState<any>([]);
  const [categoryList, setCategoryList] = React.useState<any>([]);
  const [BOMparameters, setBOMparameters] = React.useState<any>([]);
  const [isBOMparametersLoading, setIsBOMparametersLoading] =
    React.useState<boolean>(false);
  const [details, setDetails] = React.useState<any>({});
  const [BOMStatus, setBOMStatus] = React.useState<any>("");
  const [originalPartDescription, setOriginalPartDescription] =
    React.useState("");
  const [partDescription, setPartDescription] = React.useState<any>(
    originalPartDescription
  );
  const [showMSP, setShowMSP] = React.useState<any>(false);
  const [showMSPCounter, setShowMSPCounter] = React.useState<any>(0);
  const [weightLockStatus, setWeightLockStatus] = React.useState<any>(false);
  const [OriginaLoader, setOriginaLoader] = React.useState<any>(false);
  const [loadingStatus, setLoadingstatus] = React.useState<any>(false);
  const [loadingWeightLock, setLoadingWeightLock] = React.useState<any>(false);
  const [ProductloadingWeightLock, setProductloadingWeightLock] =
    React.useState<any>(false);
  const [ProductWeightLock, setProductWeightLock] = React.useState<any>(false);
  const [tableDataGetCount, setTableDataGetCount] = React.useState<any>(1);
  const [allValues, setAllValues] = React.useState<any>({});
  const userAction = React.useContext(userPermissionAction);
  const [leafNode, setLeafNode] = React.useState<any>(false);
  const [SupplierList, setSupplierList] = React.useState<any>([]);
  const [SupplierListPS, setSupplierListPS] = React.useState<any>([]);
  const [selectedSupplier, setSelectedSupplier] = React.useState<any>([]);
  const [selectedSupplierAutocomplete, setSelectedSupplierAutocomplete] =
    React.useState<any>([]);
  const [selectedSupplierAutocompletePS, setSelectedSupplierAutocompletePS] =
    React.useState<any>([]);
  const editorRef = React.useRef<any>(null);

  function checkMinimumTrueValues(data: any, minCount: any) {
    let trueCount = 0;
    for (const value of Object.values(data)) {
      if (value === true) {
        trueCount++;
      }
    }
    return trueCount >= minCount;
  }

  let UserAction = userAction && userAction?.action;
  const obj = (tv: any, vlt: any) => {
    if (vlt == 0) {
      return { vehicle_category: tv };
    } else {
      return { vault: vlt };
    }
  };

  function toggleValueInData(data: DataItem, checkId: string): DataItem | null {
    // Check if the key exists in the data object
    if (checkId in data) {
      // Create a new object with the updated value
      const updatedData = { ...data, [checkId]: !data[checkId] };
      return updatedData; // Return the modified object
    }
    // Return null if the key was not found
    return null;
  }
  React.useEffect(() => {
    setSelectedSupplierAutocomplete([]);
    setSelectedSupplierAutocompletePS([]);
  }, [vault]);
  const handleChangeDescription = (value: any) => {
    setPartDescription(value);
  };
  const handleBlur = (ed: any) => {
    setPartDescription(ed);
    if (`${ed}` !== `${originalPartDescription}`) {
      updateDataVault("about", ed, vault, vehCateId, "wo");
      // Update original value
    }
  };

  const clientViewParametersList = () => {
    API.get(
      "/bom/vault_parameter_publish/",
      {
        vault: vault,
        // publish_details:true
      },
      0
    )
      .then((res: any) => {
        setCheckedList(res.data);
      })
      .catch((err: any) => {
        ADMIN.toast.error("Server Error");
      });
  };

  React.useEffect(() => {
    clientViewParametersList();
  }, [vault]);

  // const ExactDebounceCheckBoxClient = React.useRef(
  //   debounce(async (paramsId: any,checkedListAll:any) => {
  //     let modifiedData = toggleValueInData(checkedListAll, paramsId);
  //    await API.put(
  //     "/bom/vault_parameter_publish/",
  //     {
  //       vault: vault,
  //       publish_details: modifiedData,
  //     },
  //     {},
  //     0
  //   )
  //     .then((res: any) => {
  //       ADMIN.toast.info("Parameter added for client view successfully");
  //       clientViewParametersList();
  //     })
  //     .catch((err: any) => {
  //       ADMIN.toast.error(err.response.data[0]);
  //     });
  //   }, 1000)
  // ).current;
  // React.useEffect(() => {
  //   return () => {
  //     ExactDebounceCheckBoxClient.cancel(); // Cancel the debounced function
  //   };
  // }, []);

  const handleChangeCheckBoxClient = (event: any, paramsId: any) => {
    event.preventDefault();
    let modifiedData = toggleValueInData(checkedList, paramsId);
    API.put(
      "/bom/vault_parameter_publish/",
      {
        vault: vault,
        publish_details: modifiedData,
      },
      {},
      0
    )
      .then((res: any) => {
        ADMIN.toast.info("Parameter added for client view successfully");
        clientViewParametersList();
      })
      .catch((err: any) => {
        ADMIN.toast.error(err.response.data[0]);
      });
  };

  const getobj = (v: any, tv: any) => {
    if (v == 0) {
      return { vehicle_category: tv };
    } else {
      return { vault: v };
    }
  };
  const getVaultDetails = () => {
    setLoadingWeightLock(true);
    API.get(
      "/bom/vault_info/",
      {
        ...obj(vehCateId, vault),
        module: 9,
      },
      0
    )
      .then((res: any) => {
        setDetails(res.data[0]);
        // setBOMStatus(res.data[0]?.status)

        setWeightLockStatus(res.data[0]?.weight_lock);
        setLoadingWeightLock(false);
      })
      .catch((err: any) => {});
  };

  const getAllValues = () => {
    if (vault == 0) {
    } else {
      API.get("/bom/component_details/", { vault: vault }, 0)
        .then((res: any) => {
          setAllValues(res.data[0]?.details);
        })
        .catch((err: any) => {
          Swal.fire({
            title: "Error!",
            text: err.response.data[0],
            icon: "error",
            confirmButtonText: "Ok",
          });
        });
    }
  };

  React.useEffect(() => {
    getAllValues();
  }, [vault, vehCateId]);

  const BOMValidationStatus = () => {
    API.get(
      "/bom/bom_hirearchy_function/",
      {
        bom_status: true,
        ...obj(vehCateId, vault),
      },
      0
    )
      .then((res: any) => {
        setBOMStatus(res.data?.bom_status);
      })
      .catch((err: any) => {});
  };

  React.useEffect(() => {
    BOMValidationStatus();
  }, [vault, vehCateId, workstation]);
  React.useEffect(() => {
    getVaultBOMDetailsData();
  }, [BOMStatus]);

  React.useEffect(() => {
    if (ParentsCounter !== 0) {
      BOMValidationStatus();
    }
  }, [ParentsCounter]);

  React.useEffect(() => {
    getVaultDetails();
  }, [vault]);
  const getBOMParametersData = () => {
    setIsBOMparametersLoading(true);
    API.get(
      "/bom/bom_create_view/",
      {
        ...obj(vehCateId, vault),
        part_details: true,
        // workstation: workstation,
      },
      0
    )
      .then((res: any) => {
        setIsBOMparametersLoading(false);
        setBOMparameters(res.data);
      })
      .catch((err: any) => {
        setIsBOMparametersLoading(false);
      });
  };

  useQueryDispatch({
    query: {
      action: getCustomValidations,
      arg: { categoryId: category && category, vehCateId: +vehCateId },
      condition: Boolean(1),
    },
    cleanup: {
      action: resetCustomValidations,
      args: [],
    },
    dependency: [vehCateId, vault, category],
  });

  // useQueryDispatch({
  // 	query: {
  // 		action: getMappedWorkstations,
  // 		arg: { topVaultId: +vehCateId, vaultId: +vault },
  // 	},
  // 	cleanup: {
  // 		action: resetWorkstations,
  // 		args: [],
  // 	},
  // 	dependency: [vehCateId, vault, category],
  // });

  const configsFetched = useQueryDispatch({
    query: {
      action: getBOMConfigurationsByCategory,
      arg: {
        topVaultId: +vehCateId,
        categoryId: category,
        all: true,
      },
      condition: Boolean(category),
    },
    cleanup: {
      action: resetParameterConfigs,
      args: [],
    },
    dependency: [vehCateId, vault, category],
  });

  React.useEffect(() => {
    getBOMParametersData();
  }, [vault, category, vehCateId, showMSPCounter]);

  // const getWorkStationsData = () => {
  //   setWorkstationLoader(true);
  //   API.get(
  //     "/auth/workstation/",
  //     {
  //       top_vault: topVault,
  //       vault: vault != 0 ? vault : undefined,
  //       workstation_list: true,
  //     },
  //     0
  //   )
  //     .then((res: any) => {
  //       setWorkstationLoader(false);
  //       setWorkStationList(res.data);
  //       if (workstation == 0) {
  //         history.push(
  //           `/bomentry/${vehCateId}/${vehCateName}/${topVault}/${vault}/${treeabbr}/${res.data[0]?.id}/${isLeaf}/${copyStatus}`
  //         );
  //       }
  //     })
  //     .catch((err: any) => {
  //       setWorkstationLoader(false);
  //     });
  // };
  const getCategoryList = () => {
    API.get(
      "/bom/template_config/",
      {
        // top_vault: topVault,
        vehicle_category: vehCateId,
        is_leaf: isLeaf,
      },
      0
    )
      .then((res: any) => {
        setCategoryList(res.data);
      })
      .catch((err: any) => {});
  };

  React.useEffect(() => {
    getCategoryList();
  }, [vault, vehCateId]);
  const handlechangeDown = (e: any) => {
    if (e?.key == "Enter") {
      getSearchData(e.target.value);
      setSearchkey(e.target.value);
    }
  };
  const handlechangeDownPS = (e: any) => {
    if (e?.key == "Enter") {
      getSearchDataPS(e.target.value);
      // setSearchkey(e.target.value);
    }
  };

  // const getBOMTVDetailsData = () => {
  // 	setProductloadingWeightLock(true);
  // 	API.get(
  // 		'/bom/top_vault/',
  // 		{
  // 			top_vault: vehCateId,
  // 			module: 9,
  // 		},
  // 		0
  // 	)
  // 		.then((res: any) => {
  // 			setProductWeightLock(res.data[0]?.weight_lock);
  // 			setBOMDetails(res.data[0]);
  // 			setPartData(res?.data[0]?.product_id);
  // 			setPartName(res.data[0]?.name);
  // 			setCategory(res.data[0]?.category);
  // 			setPartQty(res.data[0]?.quantity);
  // 			setProductloadingWeightLock(false);
  // 		})
  // 		.catch((err: any) => {
  // 			setProductloadingWeightLock(false);
  // 		});
  // };
  const [anchorZBCEl, setAnchorZBCEl] = React.useState<HTMLElement | null>(
    null
  );

  const handlePopoverOpenZBC = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorZBCEl(event.currentTarget);
  };
  const handlePopoverCloseZBC = () => {
    setAnchorZBCEl(null);
  };
  const openZBC = Boolean(anchorZBCEl);

  const getVaultBOMDetailsData = () => {
    setPartDescription("");
    API.get(
      "/bom/vault_info/",
      {
        ...obj(vehCateId, vault),
        module: 9,
      },
      0
    )
      .then((res: any) => {
        setShowMSP(res.data[0]?.ask_pricing_table);

        setPartName(res.data[0]?.name);
        setOriginalPartName(res.data[0]?.name);
        setPartDescription(res.data[0]?.about);
        setOriginalPartDescription(res.data[0]?.about);
        setPartData(res?.data[0]?.part_no);
        setCategory(res.data[0]?.category);
        setPartQty(res.data[0]?.quantity);
        setBOMDetails(res.data[0]);
        setSelectedSupplier(res.data[0]?.supplier);
      })
      .catch((err: any) => {});
  };
  React.useEffect(() => {
    // if (vault == 0) {
    // 	getBOMTVDetailsData();
    // } else {
    getVaultBOMDetailsData();
    // }
  }, [vehCateId, vault]);
  // React.useEffect(() => {
  //   getWorkStationsData();
  // }, [ParentsCounter, vault, topVault, category]); //topvalut & valut removed here for multilple id select looping issue
  const getUrl = (vltt: any) => {
    // if (vltt == 0) {
    //   return "/xcpep/top_vault";
    // } else {
    return "/bom/vault_info";
    // }
  };
  const updateDataVault = async (
    name: any,
    value: any,
    vlt: any,
    topvlt: any,
    rt: any
  ) => {
    API.put(
      `${getUrl(vlt)}/${vlt != 0 ? +vlt : +topvlt}/`,
      {
        [name]: value,
        validated: false,
      },
      {},
      0
    )
      .then((res: any) => {
        setSyncColor("red");
        BOMValidationStatus();
        if (name === "about") {
          setOriginalPartDescription(value);
        }
        // getWorkStationsData();

        ADMIN.toast.info("Updated Successfully");
      })
      .catch((err: any) => {
        const { data } = err.response;
        Swal.fire({
          title: "Error!",
          text: data[0],
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };
  const updateDataVaultPS = async (
    name: any,
    value: any,
    vlt: any,
    topvlt: any,
    rt: any
  ) => {
    API.put(
      `${getUrl(vlt)}/${vlt != 0 ? +vlt : +topvlt}/`,
      {
        [name]: value,
        validated: false,
      },
      {},
      0
    )
      .then((res: any) => {
        setSyncColor("red");
        BOMValidationStatus();
        // getWorkStationsData();

        ADMIN.toast.info("Updated Successfully");
      })
      .catch((err: any) => {
        const { data } = err.response;
        Swal.fire({
          title: "Error!",
          text: data[0],
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  const updateVaultDescription = async (
    name: any,
    value: any,
    vlt: any,
    topvlt: any,
    rt: any
  ) => {
    // console.log(name, value, vlt, topvlt);
    // console.log(`${getUrl(vlt)}/${vlt != 0 ? +vlt : +topvlt}/`);
    // API.put(
    // 	`${getUrl(vlt)}/${vlt != 0 ? +vlt : +topvlt}/`,
    // 	{
    // 		[name]: value,
    // 		validated: false,
    // 	},
    // 	{},
    // 	0
    // )
    // 	.then((res: any) => {
    // 		setSyncColor('red');
    // 		BOMValidationStatus();
    // 		// getWorkStationsData();
    // 		ADMIN.toast.info('Updated Successfully');
    // 	})
    // 	.catch((err: any) => {
    // 		const { data } = err.response;
    // 		Swal.fire({
    // 			title: 'Error!',
    // 			text: data[0],
    // 			icon: 'error',
    // 			confirmButtonText: 'Ok',
    // 		});
    // 	});
  };

  const updateDataVaultParams = async (
    name: any,
    value: any,
    vlt: any,
    tv: any
  ) => {
    API.put(
      `/bom/component_details/74699/`,
      {
        [name]: value,
      },
      {
        ...getobj(vlt, tv),
      },
      0
    )
      .then((res: any) => {
        setSyncColor("red");
        // getBOMParametersData();
        BOMValidationStatus();
        ADMIN.toast.info("Updated Successfully");
      })
      .catch((err: any) => {
        const { data } = err.response;
        Swal.fire({
          title: "Error!",
          text: data[0],
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  const updateDataVaultCheckbox = async (
    name: any,
    value: any,
    vlt: any,
    tv: any
  ) => {
    API.put(
      `/bom/component_details/74699/`,
      {
        [name]: value,
      },
      {
        ...getobj(vlt, tv),
      },
      0
    )
      .then((res: any) => {
        setSyncColor("red");
        getBOMParametersData();
        BOMValidationStatus();
        ADMIN.toast.info("Updated Successfully");
      })
      .catch((err: any) => {
        const { data } = err.response;
        Swal.fire({
          title: "Error!",
          text: data[0],
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  const ExactDebounce = React.useRef(
    debounce(async (value: any, name: any, idd: any, topvlt: any, rt: any) => {
      await updateDataVault(name, value, idd, topvlt, rt);
    }, 1000)
  ).current;

  const ExactDebounceSupplier = React.useRef(
    debounce(
      async (values: any[], name: any, idd: any, topvlt: any, rt: any) => {
        // Assuming updateDataVault can handle an array of values
        await updateDataVault(name, values, idd, topvlt, rt);
      },
      1000
    )
  ).current;
  const ExactDebounceSupplierPS = React.useRef(
    debounce(
      async (values: any[], name: any, idd: any, topvlt: any, rt: any) => {
        // Assuming updateDataVault can handle an array of values
        await updateDataVaultPS(name, values, idd, topvlt, rt);
      },
      1000
    )
  ).current;

  const ExactDebounceParameters = React.useRef(
    debounce(async (value: any, name: any, idd: any, tv) => {
      await updateDataVaultParams(name, value, idd, tv);
    }, 1000)
  ).current;

  const ExactDebounceCheckBox = React.useRef(
    debounce(async (value: any, name: any, idd: any, tv: any) => {
      await updateDataVaultCheckbox(name, value, idd, tv);
    }, 100)
  ).current;

  React.useEffect(() => {
    return () => {
      ExactDebounce.cancel(); // Cancel the debounced function
      ExactDebounceParameters.cancel(); // Cancel the debounced function
    };
  }, []);

  const handleChangeInput = (event: any) => {
    const { name, value } = event.target;

    if (name === "name" || name === "quantity" || name === "about") {
      if (name == "name") {
        setPartName(value);
      } else if (name === "quantity") {
        setPartQty(value);
        ExactDebounce(+value, name, vault, vehCateId, "wo");
      } else if (name === "about") {
        setPartDescription(value);
      }
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "This action will delete the data mapped with the previous category.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result: any) => {
        if (result.isConfirmed == true) {
          API.put(
            `${getUrl(vault)}/${vault != 0 ? +vault : +vehCateId}/`,
            {
              [name]: value,
              validated: false,
            },
            {},
            0
          )
            .then((res: any) => {
              setSyncColor("red");
              BOMValidationStatus();
              setCategory(value);
              getBOMParametersData();
              setTableDataGetCount((prev: any) => prev + 1);
              setParentsCounter((prev: any) => prev + 1);
              ADMIN.toast.info("Updated Successfully");
            })
            .catch((err: any) => {
              const { data } = err.response;
              Swal.fire({
                title: "Error!",
                text: data[0],
                icon: "error",
                confirmButtonText: "Ok",
              });
            });
        }
      });
    }
  };

  const handleSelectSupplierChange = (event: any, newValues: any) => {
    setSelectedSupplierAutocomplete(newValues); // Update the selected suppliers array (for the autocomplete component
    const supplierIDs = newValues.map((supplier: any) => supplier.id); // Extract IDs from selected suppliers
    // setSelectedSupplier(supplierIDs); // Update the selected supplier IDs array
    ExactDebounceSupplier(supplierIDs, "supplier", vault, vehCateId, "wo");
  };
  const handleSelectSupplierChangePS = (event: any, newValues: any) => {
    setSelectedSupplierAutocompletePS(newValues); // Update the selected suppliers array (for the autocomplete component
    const supplierIDs = newValues.map((supplier: any) => supplier.id); // Extract IDs from selected suppliers
    // setSelectedSupplier(supplierIDs); // Update the selected supplier IDs array
    ExactDebounceSupplierPS(
      supplierIDs,
      "potential_supplier",
      vault,
      vehCateId,
      "wo"
    );
  };

  React.useEffect(() => {
    // /bom/template_config/?vehicle_category=10&is_leaf=true
    API.get("/supplier/get_all_supplier_list/", {
      vehicle_category: vehCateId,
      vault: vault,
      key: "",
    })
      .then((res: any) => {
        if (Array.isArray(res?.data) && res.data.length > 0) {
          setSupplierList(res?.data);
          setSelectedSupplierAutocomplete(res.data); // Update the selected suppliers array (for the autocomplete component
        }
      })
      .catch((err: any) => {
        Swal.fire({
          title: "Error!",
          text: err.response.data[0],
          icon: "error",
          confirmButtonText: "Ok",
        });
      });

    API.get("/supplier/get_all_potential_supplier_list/", {
      vehicle_category: vehCateId,
      vault: vault,
      key: "",
    })
      .then((res: any) => {
        if (Array.isArray(res?.data) && res.data.length > 0) {
          setSupplierListPS(res?.data);
          setSelectedSupplierAutocompletePS(res.data); // Update the selected suppliers array (for the autocomplete component
        }
      })
      .catch((err: any) => {
        console.log(err);
        Swal.fire({
          title: "Error!",
          text: err.response.data[0],
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  }, [vault]);

  const getSearchData = (searchKey: any) => {
    API.get("/supplier/get_all_supplier_list/", {
      vehicle_category: vehCateId,
      vault: vault,
      key: searchKey,
    })
      .then((res: any) => {
        if (Array.isArray(res?.data) && res.data.length > 0) {
          setSupplierList(res?.data);
        }
      })
      .catch((err: any) => {
        console.log(err);
        Swal.fire({
          title: "Error!",
          text: err.response.data[0],
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };
  const getSearchDataPS = (searchKey: any) => {
    API.get("/supplier/get_all_potential_supplier_list/", {
      vehicle_category: vehCateId,
      vault: vault,
      key: searchKey,
    })
      .then((res: any) => {
        if (Array.isArray(res?.data) && res.data.length > 0) {
          setSupplierListPS(res?.data);
        }
      })
      .catch((err: any) => {
        console.log(err);
        Swal.fire({
          title: "Error!",
          text: err.response.data[0],
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  // const getChecked = (itemm: any) => {
  //   if (itemm?.exceptions?.includes(itemm?.value)) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }
  const OriginalPartRounting = () => {
    setOriginaLoader(true);

    API.get("bom/vault_copy_info/", { vault_id: vault })

      .then((res: any) => {
        sessionStorage.setItem(
          "BOMCreateNode",
          JSON.stringify(res?.data?.ansector)
        );

        setOriginaLoader(false);

        window.open(
          `/#/bomentry/${vehCateId}/${vehCateName}/${
            res?.data?.id
          }/${treeabbr}/${isLeaf}/${false}/${privateStatus}`,
          "_self"
        );
      })

      .catch((err: any) => {
        console.log("Sever Error");
        setOriginaLoader(false);
      });
  };

  const handleSelectSystems = (
    event: any,
    newSystemValue: any,
    key: any,
    v: any,
    tv: any
  ) => {
    ExactDebounceParameters(newSystemValue, key, v, tv);
  };

  const renderInputs = (item: any, index: any) => {
    switch (item?.data_type) {
      case "number":
      case "float":
      case "nonzero-positive-int":
      case "nonzero-positive-float":
      case "positive-float":
      case "nonzero-float":
      case "percentage":
      case "positive-int":
      case "integer":
        return (
          <TableRow key={index} sx={{ backgroundColor: item?.color }}>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                lineHeight: 1,
              }}
            >
              <Checkbox
                {...label}
                className={styles.customCheckbox}
                disabled={
                  Object.values(checkedList).filter((value) => value === true)
                    ?.length === 5 && checkedList[item?.key]
                }
                sx={{ cursor: "pointer" }}
                checked={checkedList[item?.key]}
                onClick={(event: any) =>
                  handleChangeCheckBoxClient(event, item?.key?.toString())
                }
              />
              {item?.paramter_name}{" "}
              {item?.required && <sup style={{ color: "red" }}>*</sup>}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.exceptions?.length > 0 && (
                <CheckBoxComponent
                  check={item?.exceptions?.includes(item?.value)}
                  item={item}
                  getBOMParametersData={getBOMParametersData}
                  BOMValidationStatus={BOMValidationStatus}
                />
              )}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {!item?.exceptions?.includes(item?.value) ? (
                <NumberInputBox
                  item={item}
                  details={details && details}
                  vaultIdd={vault}
                  BOMValidationStatus={BOMValidationStatus}
                  allValues={allValues && allValues}
                  getAllValues={getAllValues}
                  setSyncColor={setSyncColor}
                />
              ) : (
                item?.value
              )}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.1rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.unit ? item?.unit : "-"}
            </TableCell>
          </TableRow>
        );
      case "text":
      case "string":
        return (
          <TableRow key={index} sx={{ backgroundColor: item?.color }}>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                lineHeight: 1,
              }}
            >
              <Checkbox
                sx={{ cursor: "pointer" }}
                checked={checkedList[item?.key]}
                disabled={
                  Object.values(checkedList).filter((value) => value === true)
                    ?.length === 5 && checkedList[item?.key]
                }
                onClick={(event: any) =>
                  handleChangeCheckBoxClient(event, item?.key?.toString())
                }
              />
              {item?.paramter_name}{" "}
              {item?.required && <sup style={{ color: "red" }}>*</sup>}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.exceptions?.length > 0 && (
                <CheckBoxComponent
                  check={item?.exceptions?.includes(item?.value)}
                  item={item}
                  getBOMParametersData={getBOMParametersData}
                  BOMValidationStatus={BOMValidationStatus}
                />
              )}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {
                <TextInputBox
                  item={item}
                  details={details}
                  BOMValidationStatus={BOMValidationStatus}
                  allValues={allValues && allValues}
                  getAllValues={getAllValues}
                />
              }
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.1rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.unit ? item?.unit : "-"}
            </TableCell>
          </TableRow>
        );

      case "textarea":
      case "longtext":
        return (
          <TableRow key={index} sx={{ backgroundColor: item?.color }}>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                lineHeight: 1,
              }}
            >
              <Checkbox
                sx={{ cursor: "pointer" }}
                checked={checkedList[item?.key]}
                disabled={
                  Object.values(checkedList).filter((value) => value === true)
                    ?.length === 5 && checkedList[item?.key]
                }
                onClick={(event: any) =>
                  handleChangeCheckBoxClient(event, item?.key?.toString())
                }
              />
              {item?.paramter_name}{" "}
              {item?.required && <sup style={{ color: "red" }}>*</sup>}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.exceptions?.length > 0 && (
                <CheckBoxComponent
                  check={item?.exceptions?.includes(item?.value)}
                  item={item}
                  getBOMParametersData={getBOMParametersData}
                  BOMValidationStatus={BOMValidationStatus}
                />
              )}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {
                <DescriptionInputBox
                  item={item}
                  details={details}
                  BOMValidationStatus={BOMValidationStatus}
                  allValues={allValues && allValues}
                  getAllValues={getAllValues}
                  setSyncColor={setSyncColor}
                />
              }
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.1rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.unit ? item?.unit : "-"}
            </TableCell>
          </TableRow>
        );
      case "dropdown":
      case "singleselect":
      case "country":
        return (
          <TableRow key={index} sx={{ backgroundColor: item?.color }}>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                lineHeight: 1,
              }}
            >
              <Checkbox
                sx={{ cursor: "pointer" }}
                checked={checkedList[item?.key]}
                disabled={
                  Object.values(checkedList).filter((value) => value === true)
                    ?.length === 5 && checkedList[item?.key]
                }
                onClick={(event: any) =>
                  handleChangeCheckBoxClient(event, item?.key?.toString())
                }
              />
              {item?.paramter_name}{" "}
              {item?.required && <sup style={{ color: "red" }}>*</sup>}
            </TableCell>

            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.exceptions?.length > 0 && (
                <CheckBoxComponent
                  check={item?.exceptions?.includes(item?.value)}
                  item={item}
                  getBOMParametersData={getBOMParametersData}
                  BOMValidationStatus={BOMValidationStatus}
                />
              )}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              <SingleSelectInputBox
                item={item}
                details={details}
                BOMValidationStatus={BOMValidationStatus}
                allValues={allValues && allValues}
                getAllValues={getAllValues}
                setSyncColor={setSyncColor}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.1rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.unit ? item?.unit : "-"}
            </TableCell>
          </TableRow>
        );

      case "multiselect":
        return (
          <TableRow key={index} sx={{ backgroundColor: item?.color }}>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                lineHeight: 1,
              }}
            >
              <Checkbox
                sx={{ cursor: "pointer" }}
                checked={checkedList[item?.key]}
                disabled={
                  Object.values(checkedList).filter((value) => value === true)
                    ?.length === 5 && checkedList[item?.key]
                }
                onClick={(event: any) =>
                  handleChangeCheckBoxClient(event, item?.key?.toString())
                }
              />
              {item?.paramter_name}{" "}
              {item?.required && <sup style={{ color: "red" }}>*</sup>}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.exceptions?.length > 0 && (
                <CheckBoxComponent
                  check={item?.exceptions?.includes(item?.value)}
                  item={item}
                  getBOMParametersData={getBOMParametersData}
                  BOMValidationStatus={BOMValidationStatus}
                />
              )}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  id="tags-standard"
                  disabled={
                    (copyStatus == false || copyStatus == "false") &&
                    (UserAction?.includes("U") || UserAction?.includes("C"))
                      ? false
                      : true
                  }
                  limitTags={1}
                  // onChange={handleOnChange}
                  disableClearable
                  disableCloseOnSelect
                  sx={{
                    ".MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator":
                      {
                        color: "primary.main",
                      },
                    ".MuiButtonBase-root.MuiChip-root": {
                      backgroundColor: "primary.light",
                      height: "26px",
                    },
                  }}
                  options={item?.allowed_values}
                  getOptionLabel={(option: any) =>
                    option == undefined ? "" : option
                  }
                  defaultValue={item?.value || []}
                  onChange={(event: any, newSystemValue: any) => {
                    handleSelectSystems(
                      event,
                      newSystemValue,
                      item?.key,
                      vault,
                      vehCateId
                    );
                  }}
                  // getOptionDisabled={getOptionDisabled}
                  renderOption={(props, option) => (
                    <li {...props} style={{ fontSize: "12px" }}>
                      {option}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Select Option"
                      sx={{
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "transparent",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "12px !important",
                          backgroundColor: "rgba(255,255,255,0.3)",
                          paddingLeft: "0.3rem",
                        },
                        ".MuiSvgIcon-root": { color: "primary.main" },
                      }}
                    />
                  )}
                />
              </FormControl>
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.1rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.unit ? item?.unit : "-"}
            </TableCell>
          </TableRow>
        );
      case "boolean":
        return (
          <TableRow key={index} sx={{ backgroundColor: item?.color }}>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                lineHeight: 1,
              }}
            >
              <Checkbox
                sx={{ cursor: "pointer" }}
                checked={checkedList[item?.key]}
                disabled={
                  Object.values(checkedList).filter((value) => value === true)
                    ?.length === 5 && checkedList[item?.key]
                }
                onClick={(event: any) =>
                  handleChangeCheckBoxClient(event, item?.key?.toString())
                }
              />
              {item?.paramter_name}{" "}
              {item?.required && <sup style={{ color: "red" }}>*</sup>}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.exceptions?.length > 0 && (
                <CheckBoxComponent
                  check={item?.exceptions?.includes(item?.value)}
                  item={item}
                  getBOMParametersData={getBOMParametersData}
                  BOMValidationStatus={BOMValidationStatus}
                />
              )}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {!item?.exceptions?.includes(item?.value) ? (
                <BooleanInputBox
                  item={item}
                  details={details && details}
                  BOMValidationStatus={BOMValidationStatus}
                  allValues={allValues && allValues}
                  getAllValues={getAllValues}
                />
              ) : (
                item?.value
              )}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.1rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.unit ? item?.unit : "-"}
            </TableCell>
          </TableRow>
        );

      case "date":
        return (
          <TableRow key={index} sx={{ backgroundColor: item?.color }}>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                lineHeight: 1,
              }}
            >
              <Checkbox
                sx={{ cursor: "pointer" }}
                checked={checkedList[item?.key]}
                disabled={
                  Object.values(checkedList).filter((value) => value === true)
                    ?.length === 5 && checkedList[item?.key]
                }
                onClick={(event: any) =>
                  handleChangeCheckBoxClient(event, item?.key?.toString())
                }
              />
              {item?.paramter_name}{" "}
              {item?.required && <sup style={{ color: "red" }}>*</sup>}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.exceptions?.length > 0 && (
                <CheckBoxComponent
                  check={item?.exceptions?.includes(item?.value)}
                  item={item}
                  getBOMParametersData={getBOMParametersData}
                  BOMValidationStatus={BOMValidationStatus}
                />
              )}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {!item?.exceptions?.includes(item?.value) ? (
                <DateInputBox
                  item={item}
                  details={details && details}
                  BOMValidationStatus={BOMValidationStatus}
                  allValues={allValues && allValues}
                  getAllValues={getAllValues}
                />
              ) : (
                item?.value
              )}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.1rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.unit ? item?.unit : "-"}
            </TableCell>
          </TableRow>
        );

      case "database":
        return (
          <TableRow key={index} sx={{ backgroundColor: item?.color }}>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                lineHeight: 1,
              }}
            >
              <Checkbox
                sx={{ cursor: "pointer" }}
                checked={checkedList[item?.key]}
                disabled={
                  Object.values(checkedList).filter((value) => value === true)
                    ?.length === 5 && checkedList[item?.key]
                }
                onClick={(event: any) =>
                  handleChangeCheckBoxClient(event, item?.key?.toString())
                }
              />
              {item?.paramter_name}{" "}
              {item?.required && <sup style={{ color: "red" }}>*</sup>}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.exceptions?.length > 0 && (
                <CheckBoxComponent
                  check={item?.exceptions?.includes(item?.value)}
                  item={item}
                  getBOMParametersData={getBOMParametersData}
                  BOMValidationStatus={BOMValidationStatus}
                />
              )}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {!item?.exceptions?.includes(item?.value) ? (
                <DatabaseInputBox
                  item={item}
                  details={details && details}
                  BOMValidationStatus={BOMValidationStatus}
                  allValues={allValues && allValues}
                  getAllValues={getAllValues}
                />
              ) : (
                "NA"
              )}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                padding: "0.1rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.unit ? item?.unit : "-"}
            </TableCell>
          </TableRow>
        );
    }
  };

  const bomStatus = "Not Started";
  const changetoValidated = (value: any) => {
    API.put(
      `/bom/vault_info/${vault}/`,
      {
        validated: true,
      },
      {},
      0
    )
      .then((res: any) => {
        setSyncColor("red");
        setParentsCounter((prev: any) => prev + 1);
        setBOMStatus(+value);
        getVaultBOMDetailsData();
        setLoadingstatus(false);
        Swal.fire({
          icon: "success",
          html: `<div>
             <br />
             <p style="color:"red">Status Updated Successfully</p>   
              </div>`,
        });
      })
      .catch((err: any) => {
        setLoadingstatus(false);
        const { data } = err.response;
        Swal.fire({
          icon: "error",
          html: `<div>
             <br />
             <p style="color:"red">${data[0]}</p>   
              </div>`,
        });
      });
  };

  const handleChangeBOMStatus = (event: SelectChangeEvent) => {
    const { value } = event.target;
    if (value == "3" && BOMDetails?.ask_main_costing) {
      Publishmodal.open();
    } else {
      setLoadingstatus(true);
      API.put(
        `/bom/vault_info/${vault}/`,
        {
          validated: true,
        },
        {},
        0
      )
        .then((res: any) => {
          setSyncColor("red");
          setParentsCounter((prev: any) => prev + 1);
          setBOMStatus(+value);
          getVaultBOMDetailsData();
          setLoadingstatus(false);
          Swal.fire({
            icon: "success",
            html: `<div>
             <br />
             <p style="color:"red">Status Updated Successfully</p>   
              </div>`,
          });
        })
        .catch((err: any) => {
          setLoadingstatus(false);
          const { data } = err.response;
          Swal.fire({
            icon: "error",
            html: `<div>
             <br />
             <p style="color:"red">${data[0]}</p>   
              </div>`,
          });
        });
    }
  };
  // const handleChangeBOMStatusTopvault = (event: SelectChangeEvent) => {
  // 	const { value } = event.target;
  // 	setLoadingstatus(true);
  // 	API.put(
  // 		`/xcpep/top_vault/${vehCateId}/`,
  // 		{
  // 			validated: true,
  // 		},
  // 		{},
  // 		0
  // 	)
  // 		.then((res: any) => {
  // 			setSyncColor('red');
  // 			setParentsCounter((prev: any) => prev + 1);
  // 			setBOMStatus(+value);
  // 			setLoadingstatus(false);
  // 			Swal.fire({
  // 				icon: 'success',
  // 				html: `<div>
  //          <br />
  //          <p style="color:"red">Status Updated Successfully</p>
  //           </div>`,
  // 			});
  // 		})
  // 		.catch((err: any) => {
  // 			setLoadingstatus(false);
  // 			const { data } = err.response;
  // 			Swal.fire({
  // 				icon: 'error',
  // 				html: `<div>
  //          <br />
  //          <p style="color:"red">${data[0]}</p>
  //           </div>`,
  // 			});
  // 		});
  // };

  const DeleteHandler = () => {
    partDeleteConfirmHandler();
  };

  const ConfirmDeleteHandler = () => {
    if (BOMDetails?.cost_status >= 1) {
      swal({
        title: `This action will delete the BOM Parameter and linked Costing`,
        text: "*Part will be Visible to Client Until it is Permanently Deleted from Trash",
        buttons: ["Cancel", "Ok"],
        icon: "warning",
      }).then((confirm: any) => {
        if (confirm) {
          API.delete(`/bom/vault_info/${BOMDetails?.id}/`, { trash: true }, 0)
            .then((res: any) => {
              setParentsCounter((prev: any) => prev + 1);
              setrecyclebinCounter((prev: any) => prev + 1);

              history.push(
                `/bomentry/${vehCateId}/${vehCateName}/0/${0}/delete/0/${isLeaf}/${copyStatus}/${privateStatus}`
              );
            })
            .catch((err: any) => {
              const { data } = err?.response;
              Swal.fire({
                icon: "error",
                html: `<div>
                     <br />
                     <p style="color:"red">${data[0]}</p>   
                      </div>`,
              });
            });
        }
      });
    } else {
      swal({
        title: `This action will  delete ${BOMDetails?.name}`,
        buttons: ["Cancel", "Ok"],
        icon: "warning",
      }).then((confirm: any) => {
        if (confirm) {
          API.delete(`/bom/vault_info/${BOMDetails?.id}/`, { trash: true }, 0)
            .then((res: any) => {
              setParentsCounter((prev: any) => prev + 1);
              setrecyclebinCounter((prev: any) => prev + 1);

              history.push(
                `/bomentry/${vehCateId}/${vehCateName}/0/${0}/delete/0/${isLeaf}/${copyStatus}/${privateStatus}`
              );
            })
            .catch((err: any) => {
              const { data } = err?.response;
              Swal.fire({
                icon: "error",
                html: `<div>
                     <br />
                     <p style="color:"red">${data[0]}</p>   
                      </div>`,
              });
            });
        }
      });
    }
    // API.delete(`/bom/vault_info/${BOMDetails?.id}/`, { trash: true }, 0)
    // 	.then((res: any) => {
    // 		setParentsCounter((prev: any) => prev + 1);
    // 		setrecyclebinCounter((prev: any) => prev + 1);

    // 		history.push(
    // 			`/bomentry/${vehCateId}/${vehCateName}/0/${0}/delete/0/${isLeaf}/${copyStatus}`
    // 		);
    // 	})
    // 	.catch((err: any) => {
    // 		const { data } = err?.response;
    // 		Swal.fire({
    // 			icon: 'error',
    // 			html: `<div>
    //              <br />
    //              <p style="color:"red">${data[0]}</p>
    //               </div>`,
    // 		});
    // 	});
  };

  const weightLockHandler = (status: any) => {
    if (BOMStatus && BOMStatus === 2) {
      setLoadingWeightLock(true);
      API.put(
        `/bom/vault_info/${BOMDetails?.id}/`,
        { weight_lock: !status },
        {},
        0
      )
        .then((res: any) => {
          setLoadingWeightLock(false);
          setWeightLockStatus(!status);
        })
        .catch((err: any) => {
          const { data } = err?.response;
          Swal.fire({
            icon: "error",
            html: `<div>
                     <br />
                     <p style="color:"red">${data[0]}</p>   
                      </div>`,
          });
        });
    } else {
      Swal.fire({
        icon: "error",
        html: `<div>
           <br />
           <p style="color:"red">Please change the status to Completed</p>   
            </div>`,
      });
    }
  };
  const ProductweightLockHandler = (status: any) => {
    if ((BOMStatus && BOMStatus === 2) || (BOMStatus && BOMStatus == 1)) {
      setProductloadingWeightLock(true);
      API.put(
        `/xcpep/top_vault/${BOMDetails?.id}/`,
        { weight_lock: !status },
        {},
        0
      )
        .then((res: any) => {
          setProductWeightLock(!status);
          setProductloadingWeightLock(false);
        })
        .catch((err: any) => {
          setProductloadingWeightLock(false);
          const { data } = err?.response;
          Swal.fire({
            icon: "error",
            html: `<div>
                     <br />
                     <p style="color:"red">${data[0]}</p>   
                      </div>`,
          });
        });
    } else {
      Swal.fire({
        icon: "error",
        html: `<div>
           <br />
           <p style="color:"red">Please change the status to Completed</p>   
            </div>`,
      });
    }
  };

  const ImageSearchModal = useBaseModal();
  const ImageSearchHandler = () => {
    ImageSearchModal.open();
  };

  const PartUsedConfirmModel = useBaseModal();
  const partDeleteConfirmHandler = () => {
    PartUsedConfirmModel.open();
  };

  const publishPart = () => {
    API.put(`/bom/vault_info/${vault}/`, { bom_publish: true })
      .then((res: any) => {
        ADMIN.toast.success("Part locked Successfully");
        getVaultDetails();
        getVaultBOMDetailsData();
        getBOMParametersData();
      })
      .catch((err: any) => {
        const { data } = err.response;
        Swal.fire({
          title: "Error!",
          text: data[0],
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  return (
    <div>
      {ImageSearchModal.isOpen && (
        <BOMImageBasedSearchModal
          onCloseModal={ImageSearchModal.close}
          isOpen={ImageSearchModal.open}
          vehCateId={vehCateId}
          vehCateName={vehCateName}
          vault={vault}
          treeabbr={treeabbr}
          isLeaf={isLeaf}
          copyStatus={copyStatus}
        />
      )}
      {Publishmodal?.isOpen && BOMDetails && (
        <PublishBOMModal
          onCloseModal={Publishmodal.close}
          isOpen={Publishmodal.isOpen}
          Item={vault}
          scenarioAmount={BOMDetails?.main_costing_amount}
          main_costing_turn_around_time_in_days={
            BOMDetails?.main_costing_turn_around_time_in_days
          }
          getPublishStatus={changetoValidated}
          BOMDetails={BOMDetails}
        />
      )}

      {PartUsedConfirmModel.isOpen && (
        <BOMDeletePartUsedDetailsModal
          onCloseModal={PartUsedConfirmModel.close}
          isOpen={PartUsedConfirmModel.open}
          vault={vault}
          ConfirmDeleteHandler={ConfirmDeleteHandler}
        />
      )}

      {mspModal.isOpen && (
        <AddMspModal
          onCloseModal={mspModal.close}
          isOpen={mspModal.open}
          vault={vault}
          showMSPCounter={showMSPCounter}
          setShowMSPCounter={setShowMSPCounter}
        />
      )}

      <Box className={styles.titleSectionMiddel}>
        {copyStatus == true || copyStatus == "true" ? (
          <Box
            sx={{
              width: "100%",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => OriginalPartRounting()}
          >
            Go to Original Part{" "}
            {OriginaLoader ? <CircularProgress size={20} /> : ""}
          </Box>
        ) : (
          <Box sx={{ width: "100%" }}>
            <Box>{PartData}</Box>
          </Box>
        )}
        <Box
          sx={{
            width: "100%",
            cursor: "pointer",
            whiteSpace: "nowrap",
            textAlign: "center",
          }}
        >
          {/* {!workstationLoader ? ( */}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "0.5rem",
              justifyContent: "center",
              textDecoration: "underline",
            }}
          >
            {" "}
            {BOMDetails && BOMDetails?.is_owned_by_supplier == true ? (
              `${SupplierList[0]?.business_name}`
            ) : (
              <span
                onClick={() => {
                  if (
                    details &&
                    Array.isArray(details?.zbc_info) &&
                    details?.zbc_info[0]?.owner !== "ASI"
                  ) {
                    window.open(
                      `/#/requestPage/1/0/${details?.zbc_info[0]?.owner}`
                    );
                  }
                }}
              >{`${
                details &&
                Array.isArray(details?.zbc_info) &&
                details?.zbc_info[0]?.owner
              }`}</span>
            )}
            {details &&
              Array.isArray(details?.zbc_info) &&
              details?.zbc_info[0]?.owner !== "ASI" && (
                <IconButton
                  sx={{ padding: "4px" }}
                  aria-owns={openZBC ? "mouse-over-popover" : undefined}
                  aria-haspopup="true"
                  onMouseEnter={handlePopoverOpenZBC}
                  onMouseLeave={handlePopoverCloseZBC}
                >
                  <InfoIcon />
                </IconButton>
              )}
          </Box>

          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: "none",
            }}
            open={openZBC}
            anchorEl={anchorZBCEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverCloseZBC}
            disableRestoreFocus
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.3rem",
                      fontWeight: "bold",
                      backgroundColor: "primary.light",
                      textAlign: "center",
                    }}
                    colSpan={2}
                  >
                    ZBC INFORMATION
                  </TableCell>
                </TableRow>
              </TableHead>
              {details && Array.isArray(details?.zbc_info) && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        fontWeight: "bold",
                      }}
                    >
                      Ownership
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      : {details?.zbc_info[0]?.owner}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        fontWeight: "bold",
                      }}
                    >
                      ZBC Type
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      : {details?.zbc_info[0]?.zbc_type}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        fontWeight: "bold",
                      }}
                    >
                      Requester Name
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      : {details?.zbc_info[0]?.username}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        fontWeight: "bold",
                      }}
                    >
                      Part Name
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      : {details?.zbc_info[0]?.part_name}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        fontWeight: "bold",
                      }}
                    >
                      Mfg. Location
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      : {details?.zbc_info[0]?.mfg_location}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        fontWeight: "bold",
                      }}
                    >
                      Annual Volume
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      : {details?.zbc_info[0]?.annual_volume}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        fontWeight: "bold",
                      }}
                    >
                      Revised On
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      :{" "}
                      {details?.zbc_info?.[0]?.revised_on
                        ? details.zbc_info[0].revised_on.join(", ")
                        : details?.zbc_info[0]?.revised_on}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        fontWeight: "bold",
                      }}
                    >
                      Amount Paid
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      : {details?.zbc_info[0]?.paid_currency}{" "}
                      {details?.zbc_info[0]?.paid_amount} (excluding Taxes)
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        fontWeight: "bold",
                      }}
                    >
                      Request Received
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      : {details?.zbc_info[0]?.requested_on}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        fontWeight: "bold",
                      }}
                    >
                      Deadline Date
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        color: "red",
                      }}
                    >
                      : {details?.zbc_info[0]?.tat}
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </Popover>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            columnGap: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              columnGap: "1rem",
              alignItems: "center",
            }}
          >
            {/* {treeabbr == 'product' && (
							<LoadingButton
								sx={{ minWidth: '1rem' }}
								loading={ProductloadingWeightLock}>
								{treeabbr !== 'none' &&
									treeabbr &&
									ProductloadingWeightLock === false &&
									ProductWeightLock && (
										<LockIcon
											sx={{ color: 'green', cursor: 'pointer' }}
											onClick={() =>
												(userProfilePic[0]?.is_superuser ||
													userProfilePic[0]?.is_project_admin) &&
												ProductweightLockHandler(true)
											}
										/>
									)}
								{treeabbr !== 'none' &&
									treeabbr &&
									ProductloadingWeightLock === false &&
									!ProductWeightLock && (
										<LockOpenIcon
											sx={{ color: 'red', cursor: 'pointer' }}
											onClick={() => ProductweightLockHandler(false)}
										/>
									)}
							</LoadingButton>
						)} */}
            {/* {treeabbr != 'product' && (
							<LoadingButton
								sx={{ minWidth: '1rem' }}
								loading={loadingWeightLock}>
								{treeabbr !== 'none' &&
									treeabbr &&
									loadingWeightLock === false &&
									weightLockStatus && (
										<LockIcon
											sx={{ color: 'green', cursor: 'pointer' }}
											onClick={() =>
												(userProfilePic[0]?.is_superuser ||
													userProfilePic[0]?.is_project_admin) &&
												weightLockHandler(true)
											}
										/>
									)}
								{treeabbr !== 'none' &&
									treeabbr &&
									loadingWeightLock === false &&
									!weightLockStatus && (
										<LockOpenIcon
											sx={{ color: 'red', cursor: 'pointer' }}
											onClick={() => weightLockHandler(false)}
										/>
									)}
							</LoadingButton>
						)} */}

            {isLeaf === "false" && (
              <ImageSearchIcon
                titleAccess="Image Based Navigation"
                sx={{ cursor: "pointer" }}
                onClick={() => ImageSearchHandler()}
              />
            )}

            <Box
              sx={{
                display: "flex",
                columnGap: "1rem",
                alignItems: "center",
              }}
            >
              Status:
              {bomStatus && BOMStatus == 0 ? (
                <Box
                  sx={{
                    border: "2px solid #d32f2f",
                    color: "black",
                    borderRadius: "5px",
                    height: { lg: "2rem", xl: "1.85rem" },
                    padding: "0.15rem 0.5rem",
                    width: { lg: "9.2rem", xl: "8rem", textAlign: "center" },
                    whiteSpace: "nowrap",
                  }}
                >
                  {" "}
                  Not Started
                </Box>
              ) : bomStatus && BOMStatus == 1 ? (
                <Box
                  sx={{
                    border: "2px solid #ffc800",
                    color: "#ffc800",
                    borderRadius: "5px",
                    height: { lg: "2rem", xl: "1.85rem" },
                    padding: "0.1rem 0.5rem",
                    width: { lg: "9.2rem", xl: "8rem", textAlign: "center" },
                    whiteSpace: "nowrap",
                  }}
                >
                  In Progress
                </Box>
              ) : bomStatus && BOMStatus == 2 ? (
                <FormControl
                  sx={{
                    minWidth: 110,
                    cursor: bomStatus && BOMStatus == 3 ? "default" : "pointer",
                  }}
                  size="small"
                  variant="standard"
                >
                  <LoadingButton
                    loading={loadingStatus}
                    sx={{ textTransform: "capitalize", padding: "0" }}
                  >
                    {loadingStatus === false && (
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={BOMStatus && BOMStatus}
                        disabled={
                          (copyStatus == false || copyStatus == "false") &&
                          (UserAction?.includes("C") ||
                            UserAction?.includes("U"))
                            ? false
                            : true
                        }
                        label="Status"
                        onChange={(e: any) =>
                          treeabbr == "product"
                            ? ""
                            : // ? handleChangeBOMStatusTopvault(e)
                              handleChangeBOMStatus(e)
                        }
                        // disabled={bomStatus && BOMStatus == 3 ? true : false}
                        sx={{
                          width: { lg: "9rem", xl: "8rem" },
                          cursor:
                            bomStatus && BOMStatus == 3 ? "default" : "pointer",
                          "&:before": {
                            borderBottomColor: "transparent !important",
                            borderRadius: "5px",
                          },
                          "&:after": {
                            borderBottomColor: "transparent !important",
                            borderRadius: "5px",
                          },
                          "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                            {
                              fontSize: "1rem",
                              border: "2px solid",
                              borderRadius: "5px",
                              height: "1rem",
                              padding: "0.1rem 0.5rem 0.1rem",
                              color:
                                bomStatus && BOMStatus == 0
                                  ? "red"
                                  : bomStatus && BOMStatus == 1
                                  ? "black"
                                  : bomStatus && BOMStatus == 2
                                  ? "black"
                                  : bomStatus && BOMStatus == 3
                                  ? "black"
                                  : "red", //status Color update
                            },
                          "& .MuiInputBase-root.MuiInput-root.MuiSelect-root:hover":
                            {
                              border: "2px solid",
                              borderColor: "red !important", //status Color update
                            },
                          ".MuiSvgIcon-root.MuiSelect-icon": {
                            color:
                              bomStatus && BOMStatus == 0
                                ? "red"
                                : bomStatus && BOMStatus == 1
                                ? "#ffc800"
                                : bomStatus && BOMStatus == 2
                                ? "#00887a"
                                : bomStatus && BOMStatus == 3
                                ? "#008000"
                                : "red", //status Color update
                          },
                        }}
                      >
                        {
                          <MenuItem
                            sx={{
                              fontSize: "1rem",
                              color: "red",
                              display: "none",
                            }}
                            value={0}
                          >
                            Not Started
                          </MenuItem>
                        }
                        {
                          <MenuItem
                            sx={{
                              fontSize: "1rem",
                              color: "#ffc800",
                              display: "none",
                            }}
                            value={1}
                          >
                            In Progress
                          </MenuItem>
                        }
                        {
                          <MenuItem
                            sx={{
                              fontSize: "1rem",
                              color: "#ffc800",
                              display: "none",
                            }}
                            value={2}
                          >
                            Completed
                          </MenuItem>
                        }
                        <MenuItem
                          sx={{
                            fontSize: "1rem",
                            color: "#00887a",
                            display: BOMStatus && BOMStatus === 2 ? "" : "none",
                          }}
                          value={3}
                        >
                          Validated
                        </MenuItem>
                      </Select>
                    )}
                  </LoadingButton>
                </FormControl>
              ) : bomStatus && BOMStatus == 3 ? (
                <Box
                  sx={{
                    border: "2px solid #008000",
                    color: "black",
                    borderRadius: "5px",
                    height: { lg: "2rem", xl: "1.85rem" },
                    padding: "0.15rem 0.5rem",
                    width: { lg: "9.2rem", xl: "8rem", textAlign: "center" },
                  }}
                >
                  Validated
                </Box>
              ) : (
                ""
              )}
            </Box>
            {showMSP ? (
              <Box
                sx={{
                  backgroundColor: "#00887a",
                  cursor: "pointer",
                  color: "#fff",
                  borderRadius: "5px",
                  height: { lg: "2rem", xl: "1.85rem" },
                  padding: "0.15rem 0.5rem",
                  width: { lg: "8.2rem", xl: "7rem", textAlign: "center" },
                  fontSize: "0.8rem",
                  display: "grid",
                  placeItems: "center",
                }}
                onClick={() => {
                  mspModal.open(vault);
                }}
              >
                Add MSP
              </Box>
            ) : (
              <Box
                sx={{
                  backgroundColor: "#e0e3de",

                  color: "#969994",
                  borderRadius: "5px",
                  height: { lg: "2rem", xl: "1.85rem" },
                  padding: "0.15rem 0.5rem",
                  width: { lg: "8.2rem", xl: "7rem", textAlign: "center" },
                  fontSize: "0.8rem",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                Add MSP
              </Box>
            )}
          </Box>
          {UserAction?.includes("D") && (
            <DeleteIcon
              titleAccess="Delete Part"
              sx={{ cursor: "pointer" }}
              onClick={() => DeleteHandler()}
            />
          )}

          {BOMDetails?.show_publish_button && BOMDetails?.level === 1 && (
            <Button
              size="small"
              variant="contained"
              disabled={BOMDetails?.bom_published}
              onClick={() => publishPart()}
            >
              Publish
            </Button>
          )}
        </Box>
      </Box>
      <Box>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  border: "1px solid",
                  borderColor: "primary.main",
                  width: { xs: "12rem", sm: "14vw" },
                  fontSize: "12px",
                }}
              >
                Part Name
                <TextField
                  type="text"
                  variant="standard"
                  name="name"
                  placeholder="Part Name *"
                  value={partName}
                  onChange={handleChangeInput}
                  inputProps={{
                    step: "any",
                    min: 0,
                    style: { fontSize: "12px", paddingBottom: "0.1rem" },
                  }}
                  InputLabelProps={{
                    shrink: true,
                    color: "primary",
                  }}
                  sx={{
                    width: "100%",
                    borderBottomColor: "primary.light",
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                  }}
                  onBlur={(event: any) => {
                    if (event.target.value !== originalPartName) {
                      updateDataVault(
                        "name",
                        event.target.value,
                        vault,
                        vehCateId,
                        "wo"
                      );
                      setOriginalPartName(event.target.value); // Update original value
                    }
                  }}
                />
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  border: "1px solid",
                  borderColor: "primary.main",
                  width: { xs: "6rem", sm: "3vw" },
                  fontSize: "12px",
                }}
              >
                {vault == 0 ? "MFG Year" : "Qty"}
                {vault != 0 ? (
                  <TextField
                    type="number"
                    variant="standard"
                    name="quantity"
                    // label="Life in Yrs *"
                    placeholder="Quantity *"
                    disabled={
                      UserAction?.includes("C") || UserAction?.includes("U")
                        ? false
                        : true
                    }
                    value={partQty}
                    onChange={handleChangeInput}
                    inputProps={{
                      step: "any",
                      min: 0,
                      style: { fontSize: "12px", paddingBottom: "0.1rem" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                      color: "primary",
                    }}
                    sx={{
                      width: "100%",
                      borderBottomColor: "primary.light",
                      ".MuiInputBase-root.MuiInput-root": {
                        paddingBottom: "0.1rem",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                    }}
                  />
                ) : (
                  <Box>{BOMDetails?.manufacturing_year}</Box>
                )}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  border: "1px solid",
                  borderColor: "primary.main",
                  fontSize: "12px",
                  width: { xs: "7rem", sm: "8vw" },
                }}
              >
                Category
                <FormControl fullWidth variant="standard">
                  <Select
                    size="small"
                    name="category"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={category}
                    label="Category"
                    disabled={
                      (copyStatus == false || copyStatus == "false") &&
                      (UserAction?.includes("C") || UserAction?.includes("U"))
                        ? false
                        : true
                    }
                    onChange={handleChangeInput}
                    sx={{
                      "&:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                        {
                          fontSize: "12px",
                          paddingTop: "0.3rem",
                          paddingBottom: "0.3rem",
                        },
                      ".MuiSvgIcon-root.MuiSelect-icon": {
                        color: "primary.main",
                      },
                    }}
                  >
                    {categoryList &&
                      categoryList?.map((item: any) => {
                        return (
                          <MenuItem
                            sx={{ fontSize: "12px" }}
                            value={item?.category}
                          >
                            {item?.commodity_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  border: "1px solid",
                  borderColor: "primary.main",
                  // width: {xs:'8rem',sm:"6vw"} ,
                  fontSize: "12px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    width: "100%",
                  }}
                >
                  <span title="Known Sellers">KS</span>{" "}
                  <sup style={{ color: "red" }}>*</sup>
                  <Autocomplete
                    id="combo-box-demo"
                    // value={selectedSupplier}
                    sx={{
                      width: "49%",
                      ".MuiAutocomplete-root .MuiAutocomplete-input": {
                        fontSize: "1rem",
                      },
                      ".MuiButtonBase-root.MuiChip-root": {
                        backgroundColor: "primary.light",
                        height: "26px",
                      },
                    }}
                    multiple
                    value={selectedSupplierAutocomplete}
                    // disableClearaselectedSupplierAutocompleteble
                    limitTags={1}
                    disableCloseOnSelect
                    disabled={BOMDetails?.is_owned_by_supplier}
                    onChange={(event, newValue) => {
                      handleSelectSupplierChange(event, newValue);
                    }}
                    options={SupplierList}
                    renderOption={(props, option) => (
                      <li {...props} style={{ fontSize: "12px" }}>
                        {option.business_name}
                      </li>
                    )}
                    // @ts-ignore
                    getOptionLabel={(option) =>
                      option?.business_name == undefined
                        ? ""
                        : option?.business_name
                    }
                    renderInput={(params) => (
                      <TextField
                        placeholder="Select Supplier"
                        onKeyDown={handlechangeDown}
                        {...params}
                        variant="standard"
                        sx={{
                          width: "100%",
                          "& .MuiInput-underline:before": {
                            borderBottomColor: "primary.light",
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor: "primary.main",
                          },
                          ".MuiInputBase-root.MuiInput-root": {
                            fontSize: "12px !important",
                          },
                          ".MuiSvgIcon-root": { color: "primary.main" },
                        }}
                      />
                    )}
                  />
                  <span title="potential Suppliers">PS</span>
                  <Autocomplete
                    id="combo-box-demo"
                    // value={selectedSupplier}
                    sx={{
                      width: "49%",
                      ".MuiAutocomplete-root .MuiAutocomplete-input": {
                        fontSize: "1rem",
                      },
                      ".MuiButtonBase-root.MuiChip-root": {
                        backgroundColor: "primary.light",
                        height: "26px",
                      },
                    }}
                    multiple
                    value={selectedSupplierAutocompletePS}
                    // disableClearaselectedSupplierAutocompleteble
                    limitTags={1}
                    disableCloseOnSelect
                    disabled={BOMDetails?.is_owned_by_supplier}
                    onChange={(event, newValue) => {
                      handleSelectSupplierChangePS(event, newValue);
                    }}
                    options={SupplierListPS}
                    renderOption={(props, option) => (
                      <li {...props} style={{ fontSize: "12px" }}>
                        {option.business_name}
                      </li>
                    )}
                    // @ts-ignore
                    getOptionLabel={(option) =>
                      option?.business_name == undefined
                        ? ""
                        : option?.business_name
                    }
                    renderInput={(params) => (
                      <TextField
                        placeholder="Select Potential Supplier"
                        onKeyDown={handlechangeDownPS}
                        {...params}
                        variant="standard"
                        sx={{
                          width: "100%",
                          "& .MuiInput-underline:before": {
                            borderBottomColor: "primary.light",
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor: "primary.main",
                          },
                          ".MuiInputBase-root.MuiInput-root": {
                            fontSize: "12px !important",
                          },
                          ".MuiSvgIcon-root": { color: "primary.main" },
                        }}
                      />
                    )}
                  />
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>

      {treeabbr !== "product" ? (
        <Box
          sx={{
            height: {
              xs: "76vh",
              sm: "76vh",
              md: "76vh",
              lg: "76vh",
              xl: "82vh",
            },
            width: "100%",
          }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead sx={{ backgroundColor: "primary.main" }}>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: "12px",
                    padding: "0.1rem 0.5rem",
                    borderBottomColor: "primary.light",
                    backgroundColor: "primary.main",
                    color: "white",
                    width: {
                      xs: "12rem",
                      sm: "12vw",
                      md: "12vw",
                      lg: "12vw",
                      xl: "17vw",
                    },
                  }}
                >
                  Parameters
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "12px",
                    padding: "0.1rem 0rem",
                    borderBottomColor: "primary.light",
                    backgroundColor: "primary.main",
                    color: "white",
                    width: {
                      xs: "3rem",
                      sm: "3rem",
                      md: "3rem",
                      lg: "2rem",
                      xl: "3rem",
                    },
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    fontSize: "12px",
                    padding: "0.1rem 0.5rem",
                    borderBottomColor: "primary.light",
                    backgroundColor: "primary.main",
                    color: "white",
                    width: {
                      xs: "25rem",
                      sm: "25vw",
                      md: "25vw",
                      lg: "25vw",
                      xl: "25vw",
                    },
                  }}
                >
                  Value
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "12px",
                    padding: "0.1rem 0.5rem",
                    borderBottomColor: "primary.light",
                    backgroundColor: "primary.main",
                    color: "white",
                    width: {
                      xs: "7rem",
                      sm: "7rem",
                      md: "7rem",
                      lg: "7rem",
                      xl: "7rem",
                    },
                  }}
                >
                  Unit
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ backgroundColor: "rgba(236, 232, 90, 0.45)" }}>
                <TableCell
                  sx={{
                    fontSize: "12px",
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                    lineHeight: 1,

                    width: "30%",
                  }}
                >
                  Part Description
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "12px",
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                    width: "2%",
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    fontSize: "12px",
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                    width: "68%",
                  }}
                >
                  {/* <TextField
									type='text'
									rows={3}
									multiline
									variant='outlined'
									name='about'
									placeholder='Part Description *'
									onChange={handleChangeInput}
									value={partDescription}
									disabled={
										(copyStatus == false || copyStatus == "false") &&
										(UserAction?.includes("U") || UserAction?.includes("C"))
										  ? false
										  : true
									  }
									  maxRows={10}
									inputProps={{
										step: 'any',
										min: 0,
										style: { fontSize: '12px', paddingBottom: '0.1rem', outline:"none" },
									}}
									InputLabelProps={{
										shrink: true,
										color: 'primary',
									}}
									sx={{
										padding: "0rem",
										outline:"none",
										border:"none",
										width: '100%',
										borderBottomColor: 'primary.light',
										'& .MuiInput-underline:before': {
											borderBottomColor: 'primary.light',
										},
										'& .MuiInput-underline:after': {
											borderBottomColor: 'primary.main',
										},
									}}
									onBlur={(event: any) =>
										updateDataVault(
											'about',
											event.target.value,
											vault,
											vehCateId,
											'wo'
										)
									}
								/> */}

                  {/* <TextField
                    multiline
                    ref={partDescriptionRef}
                    name="about"
                    onChange={handleChangeInput}
                    value={partDescription}
                    rows={3}
                    disabled={
                      (copyStatus == false || copyStatus == "false") &&
                      (UserAction?.includes("U") || UserAction?.includes("C"))
                        ? false
                        : true
                    }
                    maxRows={10}
                    // onChange={(e) => setValue(e.target.value)}
                    placeholder="Enter Value"
                    sx={{
                      width: "100%",
                      padding: "0rem",
                      backgroundColor: "rgba(255,255,255,0.3)",
                      ".MuiInputBase-root.MuiOutlinedInput-root": {
                        padding: "0.3rem",
                      },
                      ".MuiInputBase-input.MuiOutlinedInput-input": {
                        fontSize: "12px",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.light",
                      },
                    }}
                    onBlur={(event: any) => {
                      if (event.target.value !== originalPartDescription) {
                        updateDataVault(
                          "about",
                          event.target.value,
                          vault,
                          vehCateId,
                          "wo"
                        );
                        setOriginalPartDescription(event.target.value); // Update original value
                      }
                    }}
                  /> */}
                  <JoditEditor
                    ref={editorRef}
                    value={partDescription}
                    config={{
                      readonly: !(
                        (copyStatus === false || copyStatus === "false") &&
                        (UserAction?.includes("U") || UserAction?.includes("C"))
                      ),
                      toolbar: true,
                      height: 300,
                      //@ts-ignore
                      placeholder: "Enter Description",
                    }}
                    onBlur={(newContent) => handleBlur(newContent)}
                    className={styles.joditContainer}
                    style={{
                      width: "100%",
                      backgroundColor: "#ece85a2b !important",
                    }}
                  />
                </TableCell>

                <TableCell
                  sx={{
                    fontSize: "12px",
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                ></TableCell>
              </TableRow>
              {!isBOMparametersLoading && BOMparameters ? (
                BOMparameters?.map((item: any, index: any) => {
                  return renderInputs(item, index);
                })
              ) : (
                <>
                  <TableRow>
                    <TableCell
                      sx={{ padding: "1rem 0.5rem", borderBottom: "none" }}
                      colSpan={4}
                    >
                      <Box sx={{ width: "100%" }}>
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          height="2rem"
                          width="100%"
                          sx={{ marginBottom: "1rem" }}
                        />
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          height="2rem"
                          width="100%"
                          sx={{ marginBottom: "1rem" }}
                        />
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          height="2rem"
                          width="100%"
                          sx={{ marginBottom: "1rem" }}
                        />
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          height="2rem"
                          width="100%"
                          sx={{ marginBottom: "1rem" }}
                        />
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          height="2rem"
                          width="100%"
                          sx={{ marginBottom: "1rem" }}
                        />
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          height="2rem"
                          width="100%"
                          sx={{ marginBottom: "1rem" }}
                        />
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          height="2rem"
                          width="100%"
                          sx={{ marginBottom: "1rem" }}
                        />
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          height="2rem"
                          width="100%"
                          sx={{ marginBottom: "1rem" }}
                        />
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          height="2rem"
                          width="100%"
                          sx={{ marginBottom: "1rem" }}
                        />
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          height="2rem"
                          width="100%"
                          sx={{ marginBottom: "1rem" }}
                        />
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          height="2rem"
                          width="100%"
                          sx={{ marginBottom: "1rem" }}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
          <Box sx={{ margin: "1rem 0rem" }}>
            <BomEntryTableComponent
              tableDataGetCount={tableDataGetCount}
              BOMValidationStatus={BOMValidationStatus}
              counter={0}
              handleChangeCheckBoxClient={handleChangeCheckBoxClient}
              checkedList={checkedList}
            />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "85vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography>Please Select Part</Typography>
        </Box>
      )}
    </div>
  );
}
