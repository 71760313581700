// src/ThemeContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import { lightPalette, darkPalette } from './themePalette';
import { createTheme, PaletteOptions, ThemeProvider } from '@mui/material/styles';

interface ThemeContextProps {
  darkMode: boolean;
  toggleDarkMode: () => void;
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useThemeContext must be used within a ThemeContextProvider');
  }
  return context;
};

interface ThemeContextProviderProps {
  children: React.ReactNode;
}

export const ThemeContextProvider: React.FC<ThemeContextProviderProps> = ({ children }) => {
  // Use localStorage to store and retrieve the dark mode state
  const storedDarkMode = localStorage.getItem('darkMode');
  const initialDarkMode = storedDarkMode ? JSON.parse(storedDarkMode) : false;

  const [darkMode, setDarkMode] = useState<boolean>(initialDarkMode);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => {
      const newMode = !prevMode;
      // Save the new mode to localStorage
      localStorage.setItem('darkMode', JSON.stringify(newMode));
      return newMode;
    });
  };

  useEffect(() => {
    // Set up a listener for storage changes (in case the mode changes in another tab/window)
    const storageChangeHandler = (event: StorageEvent) => {
      if (event.key === 'darkMode') {
        setDarkMode(event.newValue === 'true');
      }
    };

    window.addEventListener('storage', storageChangeHandler);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener('storage', storageChangeHandler);
    };
  }, []);

  const theme = createTheme({
    palette: (darkMode ? darkPalette : lightPalette) as PaletteOptions,
    typography: {
      fontFamily: 'IBM Plex Sans, sans-serif',
    },
  });


  return (
    <ThemeContext.Provider value={{ darkMode, toggleDarkMode }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};
