import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  Grid,
  Input,
  ListSubheader,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { AttachFile as AttachFileIcon } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import { useMediaQuery } from "@mui/material";
import AddContactModal from "./AddContactModal";
import { useBaseParamsModal } from "../../../CustomHook/useBaseParamsModal";

import {
  Formik,
  useFormik,
  Form,
  Field,
  ErrorMessage,
  FieldArray,
} from "formik";
import React, { useEffect, useRef, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Divider,
  FormControl,
  InputLabel,
  TextField,
  IconButton,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import PhoneInput from "react-phone-input-2";
import { styled } from "@mui/system";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { API } from "../../../api-services";
import Swal from "sweetalert2";
import * as yup from "yup";
import { set } from "lodash";
import { error, log } from "console";

import { USER } from "../../../Redux/Services/user.service";
import { ADMIN } from "../../../Redux/Services/admin.service";
import ImageUploadS3ProofDocs from "../ImageUploadS3ProofDocs";
import ImageUploadS3ProofDocss from "./ImageUploadS3ProofDocs" 
import CDN_URL from "../../../utlis/CDN_URL";
import { useSelector } from "react-redux";
import { userSelectors } from "../../../Redux/Reducers/user.reducer";

import ContactCard from "./ContactCard";
import Test from "./Test";
import JoditEditor from "jodit-react";
import { LoadingButton } from "@mui/lab";

interface InfoModalProps {
  setActiveStep: any;
  activeStep: any;
  handleBack: any;
  handleNext: any;
  setClaimedId: any;
  climedId: any;
  SupllierId: any;
  claimedData: any;
  setClaimedData: any;
  setToggle: any;
  toggle: any;
  setCounts: any;
  counts: any;
}
const AutoSizeTextarea = styled(TextareaAutosize)(({ theme }) => ({
  width: "100%", // Set the width as needed
  resize: "none", // Disable resizing
  overflowY: "hidden", // Hide vertical scrollbar
  boxSizing: "border-box", // Include padding and border in the element's total width and height
  borderColor: theme.palette.text.primary,
  borderRadius: "5px",
  color: theme.palette.text.primary,
  "& :focus": {
    BorderColor: theme.palette.primary.main,
  },
}));

const urlRegex =
  /^(https?:\/\/)?([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/\S*)?$/;

const validationSchema = yup.object({
  // client_information: yup
  //   .array()
  //   .of(
  //     yup.object().shape({
  //       company_name: yup.string().required("Company name is required"),
  //       // company_logo: yup.string().required("Company logo URL is required"),
  //       // company_desc: yup.string().required("Company description is required"),
  //       // parts_delivered: yup.string().required("Parts delivered is required"),
  //     })
  //   )
  //   .min(1, "At least one client information entry is required") // Ensures at least one item in the array
  //   .required("Client information is required"), // Ensures the array itself is not null or undefined

  vehicle_category: yup
    .array()
    .required("Please Select atleast one item")
    .min(1, "Please select at least one item"),
  spoc_information: yup
    .array()
    .required("Please enter atleast one contact detail")
    .min(1, "Please enter at least one contact detail"),
  bom_category: yup
    .array()
    .required("Please Select atleast one item")
    .min(1, "Please select at least one item"),
  logo: yup.string().required("Please Enter Business Logo"),
  business_website: yup
    .string()
    .required("Please Enter Business Website")
    .matches(urlRegex, "Invalid url format"),
  about_business: yup
    .string()
    .required("Please Enter Something About Business"),
});

function BusinessInfo({
  setActiveStep,
  activeStep,
  handleBack,
  handleNext,
  climedId,

  setClaimedId,
  SupllierId,
  claimedData,
  setClaimedData,
  setFormData,
  setToggle,
  profileToggle,
  setProfileToggle,
  createBusinessId,
  setCreateBusinessId,
  toggle,
  setCounts,
  counts,
  request_type,
}: any) {
  const addContactModal = useBaseParamsModal();
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down("sm")
  );
  const [business_type, setbusiness_type] = React.useState("");
  const [prePopulate, setPrepopulate] = useState<boolean>(false);
  const [vehiclecategoryList, setVehicleCategoryList] = useState([]);
  const [bomcategoryList, setBomCategoryList] = useState([]);
  const [vehicleCategories, setVehicleCategories] = useState([]);
  const [contactErrors, setContactErrors] = useState<any>();
  const [bomcategories, setBomCategories] = useState([]);
  const [vehicleCategoryNames, setVehicleCategoryNames] = React.useState<any>(
    []
  );
  const [clientDetails, setClientDetails] = useState<any>({
    company_name: "",
    company_logo: "",
    company_desc: "",
  });

  const [contactDetails, setContactDetails] = useState<any>({
    spoc_name: "",
    spoc_email: "",
    spoc_contact_number: "",
    spoc_dial_code: "",
    spoc_designation: "",
    spoc_department: "",
  });
  const [cinPreviews, setcinPreviews] = useState([]);
  const [selectedVehicleCategoryNams, setSelctedVehicleCategoryNames] =
    React.useState<string[]>([]);
  const fileInputRefs = useRef<any>({});
  const [bomCategoryNames, setBomCategoryNames] = React.useState<any>([]);
  const [selectedBomCategoryNams, setSelctedBomCategoryNames] = React.useState<
    string[]
  >([]);
  const [initValues, setInitValues] = React.useState<any>({
    vehicle_category: [],
    bom_category: [],
    logo: "",
    logoDocumentFile: {},
    business_website: "",
    about_business: "",

    client_information: [{
      company_desc:"",
      company_logo:"",
      company_name:""

    }],

    spoc_information: [],
  });
  const [validationErrors, setValidationErrors] = useState<any>({});
  const [valiedatedFields, setValidatedFields] = useState<any>({});
  const [activeContact, setActiveContact] = useState<any>();
  const [inputs, setInputs] = useState<{ [key: string]: string }>({});
  const [activeClient, setActiveClient] = useState<any>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [loader, setLoader] = useState<any>(false);
  const [clientLoader, setClientLoader] = useState<any>(false);
  const logoInputRef = useRef<any>();
  const logoImgRef = useRef<any>();
  const [designation, setDesignation] = useState<string>("");
  const [name, setName] = useState<string>("");
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      vehicle_category: [],
      bom_category: [],
      logo: "",
      business_website: "",
      about_business: "",

      client_information: [],

      spoc_information: [],
    },
    onSubmit: (values: any, errors: any) => {},
  });
  const userProfileDetails = useSelector(userSelectors.selectAll);

  const handleSubmitt = (values: any, setFieldValue: any) => {
    if (designation && name) {
      setInputs((prevInputs) => ({
        ...prevInputs,
        [designation]: name,
      }));
      setDesignation("");
      setName("");
    }
  };

  useEffect(() => {
    if (claimedData?.business_name !== "" && !prePopulate) {
      setInitValues({
        ...initValues,
        vehicle_category: claimedData?.vehicle_category || [],
        bom_category: claimedData?.bom_category || [],
        logo: claimedData?.logo ? claimedData?.logo : "",
        business_website: claimedData?.business_website,
        about_business: claimedData?.about_business,
        client_information: claimedData?.client_information || [],
        spoc_information: claimedData?.spoc_information || [],
      });
      setPrepopulate(true);
    }
    if (claimedData?.id) {
      setClaimedId(claimedData?.id);
    }
  }, [claimedData]);

  useEffect(() => {
    if (
      claimedData?.bom_category?.length > 0 ||
      claimedData?.vehicle_category?.length > 0
    ) {
      const filteredVehicleNames =
        Array.isArray(vehiclecategoryList) &&
        vehiclecategoryList
          ?.filter((vehicle: any) =>
            claimedData?.vehicle_category?.includes(vehicle?.id)
          )
          ?.map((vehicle: any) => vehicle?.name);

      const bomIds =
        Array.isArray(claimedData?.bom_category) &&
        claimedData?.bom_category?.map((item: any) => item);
      const filteredBomNames =
        Array.isArray(bomcategoryList) &&
        bomcategoryList
          ?.filter((bom: any) => bomIds?.includes(bom?.category__id))
          ?.map((bom: any) => bom?.category__name);

      setSelctedVehicleCategoryNames(filteredVehicleNames || []);
      setSelctedBomCategoryNames(filteredBomNames || []);
    }
  }, [
    claimedData?.bom_category,
    claimedData?.vehicle_category,
    bomcategoryList,
    vehiclecategoryList,
  ]);

  const handleDelete = (key: string) => {
    const updatedInputs = { ...inputs };
    delete updatedInputs[key];
    setInputs(updatedInputs);
  };
  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const dd = String(today.getDate()).padStart(2, "0");
  const maxDate = `${yyyy}-${mm}-${dd}`;

  const years = Array.from(
    { length: new Date().getFullYear() - 1900 + 1 },
    (_, index) => 1900 + index
  );

  useEffect(() => {
    if (claimedData.business_name !== "") {
      formik.setValues(claimedData);
    }
  }, [claimedData]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const getUser = async () => {
    // setCreateBusinessId(data?.pending_create_business);
  };

  const handleClick = () => {
    logoInputRef.current?.click();
  };

  useEffect(() => {
    API.get("cart/get_all_vehiclecategory_list/", {}, 0).then((res: any) => {
      setVehicleCategoryList(res?.data);

      const names =
        Array.isArray(res?.data) && res?.data?.map((item: any) => item.name);
      setVehicleCategoryNames(names);
    });
    getUser();
  }, []);

  const handleInputLogofile = (e: React.MouseEvent) => {
    // clear the prev value on click so that same image can be uploaded again
    (e.target as HTMLInputElement).value = "";
  };

  useEffect(() => {
    // Define the function
    const getBOMCategoryList = (ids: any) => {
      API.get(`db/get_category_from_vehicle_category/`, {
        get_bom_category: true,
        multiple: true,
        veh_cat: ids?.toString(","),
      }).then((res: any) => {
        setBomCategoryList(res.data);

        const names =
          Array.isArray(res?.data) &&
          res?.data?.map((item: any) => item.category__name);
        setBomCategoryNames(names);
      });
    };

    const ids = formik.values.vehicle_category;

    if (ids?.length > 0) {
      getBOMCategoryList(ids);
    }

    // Call the function
    // formik.values.vehicle_category?.length > 0 && getBOMCategoryList();

    // Clean up the function
  }, [formik.values.vehicle_category]);

  const countValidFields = () => {
    const { values, errors }: any = formik;
    let validCount = 0;

    // Iterate through each field in form values
    Object.keys(values).forEach((key) => {
      // Validate each field against schema
      try {
        validationSchema.validateSyncAt(key, values);

        // No error means field is valid
        if (!errors[key] && key !== "business_country_code") {
          validCount++;
        }
      } catch (error) {
        // Validation failed for this field
      }
    });

    return validCount;
  };

  const validCount = countValidFields();

  useEffect(() => {
    setCounts({ ...counts, v2: validCount });
  }, [validCount]);

  function extractIds(arr: any) {
    return arr?.map((item: any) => item.id);
  }

  function extractBOMIds(arr: any) {
    return arr?.map((item: any) => item?.category__id);
  }
  const editor = useRef<any>(null);
  const editorConfig = {
    readonly: false,
    toolbar: true,
    // @ts-ignore
    spellcheck: true,
    saveSelectionOnFocus: true,
    saveSelectionOnBlur: true,
    language: "en",
    // toolbarButtonSize: 'medium',
    // toolbarAdaptive: false,
    showCharsCounter: true,
    showWordsCounter: true,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,

    //defaultActionOnPaste: "insert_clear_html",
    // buttons: buttons,
    uploader: {
      insertImageAsBase64URI: true,
    },

    controls: {
      font: {
        command: "fontname",
        list: {
          "'Open Sans',sans-serif": "Open Sans",
          "Helvetica,sans-serif": "Helvetica",
          "Arial,Helvetica,sans-serif": "Arial",
          "Georgia,serif": "Georgia",
          "Impact,Charcoal,sans-serif": "Impact",
          "Tahoma,Geneva,sans-serif": "Tahoma",
          "'Times New Roman',Times,serif": "Times New Roman",
          "Verdana,Geneva,sans-serif": "Verdana",
          "Consolas,monaco,monospace": "Consolas",
        },
      },
    },
  };

  const handleAddContact = () => {
    formik.setFieldValue("spoc_information", [
      ...formik.values.spoc_information,
      contactDetails,
    ]);
    setContactDetails({
      spoc_name: "",
      spoc_email: "",
      spoc_contact_number: "",
      spoc_dial_code: "",
      spoc_designation: "",
      spoc_department: "",
    });
  };

  const triggerFileInput = (index: any) => {
    if (fileInputRefs.current[index]) {
      fileInputRefs.current[index].click();
    }
  };

  // const handleFileChange = (index:any, event:any) => {

  // };

  return (
    <Box>
      <Box
        sx={{
          padding: { xs: "1rem 0", md: "3rem 2rem" },
          maxWidth: { xs: "100vw", md: "83vw" },
        }}
      >
        <Formik
          enableReinitialize={true}
          validationSchema={validationSchema}
          initialValues={initValues}
          onSubmit={(values: any, errors: any) => {
            for (const key in claimedData) {
              if (claimedData[key] === null || claimedData[key] === undefined) {
                claimedData[key] = ""; // Replace null or undefined with an empty string
              }
            }
            setFormData(values);
            API.put(`/supplier/supplier_db/${claimedData?.id}/`, values)

              // API.post(`supplier/claim_business/`, values)
              .then((res) => {
                const data = res.data.data;

                const vehicleCategoryIds =
                  Array.isArray(data.vehicle_category) &&
                  typeof data.vehicle_category[0] === "object"
                    ? Array.isArray(data.vehicle_category) &&
                      data.vehicle_category?.map((item: any) => item.id)
                    : data.vehicle_category;

                const bomCategoryIds =
                  Array.isArray(data.bom_category) &&
                  typeof data.bom_category[0] === "object"
                    ? Array.isArray(data.bom_category) &&
                      data.bom_category?.map((item: any) => item.id)
                    : data.bom_category;

                setClaimedData({
                  ...data,
                  vehicle_category: vehicleCategoryIds,
                  bom_category: bomCategoryIds,
                });
                handleNext();

                //
                //
              })

              .catch((err: any) => {
                Swal.fire({
                  icon: "error",
                  title: `${err.response?.data[0]}`,
                });
              })
              .finally(() => {
                setLoader(false);
              });
          }}
        >
          {({
            values,
            setValues,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }: any) => {
            return (
              <Form>
                {addContactModal.isOpen && (
                  <AddContactModal
                    isOpen={addContactModal.isOpen}
                    contactInfo={addContactModal?.propsId}
                    onCloseModal={addContactModal.close}
                    setFieldValue={setFieldValue}
                    values={values}
                    setClaimedData={setClaimedData}
                    handleAddContact={handleAddContact}
                    claimedData={claimedData}
                  />
                )}
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: { xs: "1fr", md: "repeat(2, 1fr)" },
                    gap: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  <FormControl
                    fullWidth
                    variant="standard"
                    sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
                  >
                    <InputLabel
                      id="demo-multiple-name-label"
                      sx={{ fontSize: "0.75rem" }}
                      style={{
                        color:
                          errors.vehicle_category && touched.vehicle_category
                            ? "#e73e3e"
                            : "#00887A",
                      }}
                    >
                      {" "}
                      Applications <span style={{ color: "red" }}> *</span>
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      multiple
                      value={selectedVehicleCategoryNams}
                      // renderValue={(selected) => (
                      //   <div>
                      //     {selected?.slice(0, 3)?.map((value: any) => {

                      //       return (
                      //         <Chip
                      //         key={value}
                      //         label={value}
                      //         style={{
                      //           margin: "2px",
                      //           background: "#00887a",
                      //           color: "#fff",
                      //           borderColor: "red",
                      //         }}
                      //         deleteIcon={
                      //           <CancelIcon style={{ color: "#fff" }}
                      //           />
                      //         }
                      //         onDelete={() => console.log(`Delete ${value}`)} // Replace with your delete handler
                      //       />
                      //       )
                      //     })}
                      //     {selected?.length > 3 && (
                      //       <Chip
                      //         label={`+${selected.length - 3}`}
                      //         style={{
                      //           margin: "2px",
                      //           color: "#00887a",
                      //           marginRight: "0.5rem",
                      //         }}
                      //       />
                      //     )}
                      //   </div>
                      // )}
                      onChange={(event: any) => {
                        setSelctedVehicleCategoryNames(event.target.value);

                        const selectedVehicleIds =
                          Array.isArray(vehiclecategoryList) &&
                          vehiclecategoryList
                            .filter((item: any) =>
                              event.target.value.includes(item.name)
                            )
                            ?.map((obj: any) => obj?.id);

                        setFieldValue("vehicle_category", selectedVehicleIds);
                        formik.setFieldValue(
                          "vehicle_category",
                          selectedVehicleIds
                        );
                        setClaimedData({
                          ...claimedData,
                          vehicle_category: selectedVehicleIds,
                        });
                      }}
                      input={
                        // <OutlinedInput label="Name"   id="standard-input"/>
                        <Input id="standard-input" placeholder="Applications" />
                      }
                      error={
                        touched.vehicle_category &&
                        Boolean(errors.vehicle_category)
                      }
                      //@ts-ignore
                      helperText={
                        touched.vehicle_category && errors.vehicle_category
                      }
                    >
                      {Array.isArray(vehicleCategoryNames) &&
                        vehicleCategoryNames?.map((name: any, index: any) => (
                          <MenuItem key={index} value={name}>
                            {name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>

                  <FormControl
                    fullWidth
                    variant="standard"
                    sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
                  >
                    <InputLabel
                      id="demo-multiple-name-label"
                      sx={{ fontSize: "0.75rem" }}
                      style={{
                        color:
                          errors.bom_category && touched.bom_category
                            ? "#e73e3e"
                            : "#00887A",
                      }}
                    >
                      {" "}
                      Part type <span style={{ color: "red" }}> *</span>
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      multiple
                      value={selectedBomCategoryNams}
                      onChange={(event: any) => {
                        setSelctedBomCategoryNames(event.target.value);

                        const selectedBomIds =
                          Array.isArray(bomcategoryList) &&
                          bomcategoryList
                            .filter((item: any) =>
                              event.target.value.includes(item.category__name)
                            )
                            ?.map((obj: any) => obj?.category__id);

                        setFieldValue("bom_category", selectedBomIds);
                        formik.setFieldValue("bom_category", selectedBomIds);
                        setClaimedData({
                          ...claimedData,
                          bom_category: selectedBomIds,
                        });
                      }}
                      input={
                        // <OutlinedInput label="Name"   id="standard-input"/>
                        <Input id="standard-input" placeholder="Part type" />
                      }
                      error={
                        touched.bom_category && Boolean(errors.bom_category)
                      }
                      //@ts-ignore
                      helperText={touched.bom_category && errors.bom_category}
                    >
                      {bomCategoryNames?.map((name: any, index: any) => (
                        <MenuItem key={index} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: { xs: "1fr", md: "repeat(2, 1fr)" },
                    gap: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  <FormControl
                    fullWidth
                    variant="standard"
                    sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
                  >
                    <TextField
                      fullWidth
                      InputProps={{
                        style: { color: "#007fff", fontSize: 16 },
                      }}
                      label={
                        <span>
                          Business Website URL
                          <span style={{ color: "red" }}> *</span>
                        </span>
                      }
                      InputLabelProps={{
                        shrink: true,
                        style: {
                          fontSize: "1rem",
                          color:
                            touched.business_website &&
                            Boolean(errors.business_website)
                              ? "red"
                              : "",
                        },
                      }}
                      sx={{
                        width: "100%",
                        "& .MuiFormLabel-root": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .Mui-focused": {
                          color: "primary.main",
                        },
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.light",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.light",
                        },
                      }}
                      id="business_website"
                      name="business_website"
                      variant="standard"
                      size="small"
                      value={values.business_website}
                      onChange={(e) => {
                        handleChange(e);
                        formik.handleChange(e);
                        setClaimedData({
                          ...claimedData,
                          business_website: e.target.value,
                        });
                      }}
                      error={
                        touched.business_website &&
                        Boolean(errors.business_website)
                      }
                      //@ts-ignore
                      helperText={
                        touched.business_website && errors.business_website
                      }
                    />
                  </FormControl>

                  <Box>
                    <Typography variant="subtitle1" gutterBottom>
                      Logo <span style={{ color: "red" }}> * </span> (.svg,
                      .jpg, .jpeg, .png){"    "} &nbsp;{" "}
                      {touched.logo && Boolean(errors.logo) && (
                        //@ts-ignore
                        <span style={{ color: "red", fontSize: "0.8rem" }}>
                          Logo Required
                        </span>
                      )}
                      {"   "}
                      <></>
                    </Typography>

                    <Box
                      sx={{
                        marginBottom: "1rem",
                        gap: "0.75rem",
                      }}
                    >
                      <Box
                        sx={{
                          textAlign: "center",
                          cursor: "pointer",
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            overflow: "hidden",
                            display: "flex",
                            flexDirection: { xs: "column", md: "row" },
                            width: "100%",
                            justifyContent: "flex-start",
                            gap: { xs: "2rem", md: "6rem" },
                          }}
                        >
                          {/* {values.logo === "" ? ( */}
                          <>
                            <Button
                              variant="contained"
                              component="label"
                              startIcon={<AttachFileIcon />}
                              sx={{
                                margin: "0.5rem",
                                whiteSpace: "nowrap",

                                height: "3rem",
                              }}
                              onClick={handleClick}
                            >
                              Upload Logo{" "}
                            </Button>

                            <input
                              type="file"
                              ref={(el: any) => {
                                logoInputRef.current = el;
                              }}
                              hidden
                              accept=" .svg, .jpg, .jpeg, .png"
                              onChange={(event: any) => {
                                const file = event.target.files[0];
                                setLoader(true);

                                if (file) {
                                  if (event.target.files.length > 1) {
                                    // setFileError('You can only upload 1 file');
                                    alert("Only one file can be uploaded");
                                    event.target.value = "";
                                    return;
                                  }
                                }
                                if (file.size / (1024 * 1024) < 5) {
                                  if (file?.type == "pdf") {
                                    ADMIN.toast.error(
                                      "uploading pdf files is restricted"
                                    );
                                  } else {
                                    ImageUploadS3ProofDocs(
                                      [file],
                                      setFieldValue,
                                      setLoader,
                                      `logo`
                                    );
                                    setFieldValue(`logoDocumentFile`, {
                                      url: URL.createObjectURL(file),
                                      file: file,
                                    });

                                    formik.setFieldValue(
                                      `logo`,
                                      URL.createObjectURL(file)
                                    );
                                    formik.setFieldValue(`logoDocumentFile`, {
                                      url: URL.createObjectURL(file),
                                      file: file,
                                    });
                                    setClaimedData({
                                      ...claimedData,
                                      logo: URL.createObjectURL(file),
                                    });
                                  }
                                } else if (file.size / (1024 * 1024) > 5) {
                                  Swal.fire({
                                    title: "Warning !",
                                    text: "Total file size exceeds the maximum limit of 5MB",

                                    icon: "warning",
                                    confirmButtonText: "Ok",

                                    customClass: {
                                      container: "swal2Container",
                                    },
                                    confirmButtonColor: "#008878",
                                  });
                                  event.target.value = "";
                                  return;
                                }
                                setLoader(false);
                              }}
                              onClick={handleInputLogofile}
                            />
                          </>
                          {/* ) : ( */}
                          <Box
                            sx={{
                              overflow: "hidden",
                            }}
                          >
                            {loader ? (
                              <Box
                                sx={{
                                  width: "10rem",
                                  height: "9.5rem",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "5px",
                                }}
                              >
                                <CircularProgress />
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  border: "1px solid #dbd9d9",
                                  borderRadius: "5px",
                                }}
                              >
                                {values?.logo !== "" && (
                                  <img
                                    onClick={() =>
                                      window.open(
                                        values.logo
                                          ? `${CDN_URL}${values?.logo}`
                                          : `${CDN_URL}${values?.logoDocumentFile?.url}`
                                      )
                                    }
                                    src={
                                      values.logo
                                        ? `${CDN_URL}${values?.logo}`
                                        : `${CDN_URL}${values?.logoDocumentFile?.url}`
                                    }
                                    style={{
                                      width: "10rem",
                                      height: "10rem",
                                      objectFit: "contain",
                                      borderRadius: "5px",
                                    }}
                                  />
                                )}
                              </Box>
                            )}
                          </Box>
                          {/* )} */}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                {/* SPOC Info */}
                <Box
                  sx={{
                    width: "100%",
                    marginTop: "1.5rem",
                    // padding: "3rem 1rem",
                    // border: "0.1px solid #f0f0f0",
                    // borderRadius: "1rem",
                  }}
                >
                  <Typography
                    variant="h6"
                    color="#4f5250"
                    gutterBottom
                    sx={{ marginBottom: "1rem" }}
                  >
                    Sales Contact Information{" "}
                    {errors.spoc_information && touched?.spoc_information && (
                      <span
                        style={{
                          color: "red",
                          fontSize: "0.8rem",
                          marginLeft: "1rem",
                          fontWeight: "500",
                        }}
                      >
                        {errors?.spoc_information}
                      </span>
                    )}
                  </Typography>
                  {/* <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "baseline",
                      columnGap: "1rem",
                    }}
                  >
                    <TextField
                      size="small"
                      variant="standard"
                      placeholder="Name"
                      value={contactDetails.spoc_name}
                      label={
                        <span>
                          Name <span style={{ color: "#d32f2f" }}>*</span>
                        </span>
                      }
                      onChange={(e) => {
                        setContactDetails({
                          ...contactDetails,
                          spoc_name: e.target.value,
                        });
                      }}
                      sx={{
                        marginTop: { xs: "1rem", md: "0.5rem" },
                        width: { xs: "100%", md: "31%" },
                        "& .MuiFormLabel-root": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .Mui-focused": {
                          color: "primary.main",
                        },
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.light",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.light",
                        },
                      }}
                    />

                    <TextField
                      size="small"
                      variant="standard"
                      type="email"
                      placeholder="Email"
                      value={contactDetails.spoc_email}
                      onChange={(e) => {
                        setContactDetails({
                          ...contactDetails,
                          spoc_email: e.target.value,
                        });
                      }}
                      label={
                        <span>
                          Email <span style={{ color: "#d32f2f" }}>*</span>
                        </span>
                      }
                      sx={{
                        marginTop: { xs: "1rem", md: "0.5rem" },
                        width: { xs: "100%", md: "31%" },
                        "& .MuiFormLabel-root": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .Mui-focused": {
                          color: "primary.main",
                        },
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.light",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.light",
                        },
                      }}
                      // error={row.spoc_email === "" && errors["email"] !== undefined}
                      // //@ts-ignore
                      // helperText={email === "" && errors["email"]}
                    />
A caption functions like a heading for a table. Most screen readers announce the content of captions. Captions help users to find a table and understand what it's about and decide if they want to read it.

￼
                    <TextField
                      size="small"
                      variant="standard"
                      type="number" // Keep input type as number
                      inputProps={{
                        maxLength: 10,
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                        style: {
                          MozAppearance: "textfield", // Removes spinner in Firefox
                        },
                      }}
                      label={
                        <span>
                          Phone Number{" "}
                          <span style={{ color: "#d32f2f" }}>*</span>
                        </span>
                      }
                      placeholder="Phone Number"
                      value={contactDetails.

}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (inputValue.length <= 10) {
                          // Limit to 10 digits
                          setContactDetails({
                            ...contactDetails,
                            spoc_dial_code: "91",
                          });
                          setContactDetails({
                            ...contactDetails,
                            spoc_contact_number: e.target.value,
                          });
                        }
                      }}
                      sx={{
                        width: { xs: "100%", md: "32%" },
                        marginTop: { xs: "1rem", md: "0.5rem" },
                        "& .MuiFormLabel-root": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .Mui-focused": {
                          color: "primary.main",
                        },
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },

                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.light",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.light",
                        },
                        // Removes the spinner in Chrome, Safari, Edge, Opera
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                          {
                            WebkitAppearance: "none",
                            margin: 0,
                          },
                        // Removes the spinner in Firefox
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                      }}
                    />

                    <TextField
                      size="small"
                      type="string"
                      variant="standard"
                      placeholder="Designation"
                      value={contactDetails.spoc_designation}
                      onChange={(e) => {
                        setContactDetails({
                          ...contactDetails,
                          spoc_designation: e.target.value,
                        });
                      }}
                      sx={{
                        margin: "1.5rem 0",
                        width: { xs: "100%", md: "31%" },
                        marginTop: { xs: "1rem", md: "0.5rem" },

                        "& .MuiFormLabel-root": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .Mui-focused": {
                          color: "primary.main",
                        },
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.light",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.light",
                        },
                      }}
                      // error={designation === "" && errors["designation"] !== undefined}
                      // //@ts-ignore
                      // helperText={designation === "" && errors["designation"]}
                    />
                    <TextField
                      size="small"
                      variant="standard"
                      type="string"
                      placeholder="Department"
                      value={contactDetails.spoc_department}
                      onChange={(e) => {
                        setContactDetails({
                          ...contactDetails,
                          spoc_department: e.target.value,
                        });
                      }}
                      sx={{
                        width: { xs: "100%", md: "31%" },
                        marginTop: { xs: "1rem", md: "0.5rem" },
                        "& .MuiFormLabel-root": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .Mui-focused": {
                          color: "primary.main",
                        },
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.light",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.light",
                        },
                      }}
                      // error={department === "" && errors["department"] !== undefined}
                      // //@ts-ignore
                      // helperText={department === "" && errors["department"]}
                    />

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                        width: "33%",
                      }}
                    >
                      <Button
                        type="button"
                        sx={{
                          marginTop: { xs: "1rem", md: "-1rem" },
                          marginBottom: "1rem",
                          width: "100%",
                        }}
                        onClick={() => {
                          if (
                            contactDetails?.spoc_name === "" ||
                            contactDetails?.spoc_email === "" ||
                            contactDetails?.spoc_contact_number === ""
                          ) {
                            setContactErrors(true);
                          } else {
                            setFieldValue(`spoc_information`, [
                              ...values.spoc_information,
                              contactDetails,
                            ]);
                            setClaimedData({
                              ...claimedData,
                              spoc_information: [
                                ...values.spoc_information,
                                contactDetails,
                              ],
                            });
                            handleAddContact();
                            setContactErrors(false);
                          }
                        }}
                        variant="contained"
                      >
                        Add Contact
                      </Button>
                      {contactErrors === true && (
                        <Typography
                          sx={{
                            color: "red",
                            fontSize: "0.8rem",
                            marginTop: "-2rem",
                          }}
                        >
                          Please fill all the required fields in contact details
                        </Typography>
                      )}
                    </Box>
                  </Box> */}

                  <FieldArray name="spoc_information">
                    {({ remove }) => (
                      <TableContainer
                        component={Paper}
                        sx={{ marginTop: "2rem" }}
                      >
                        {/* <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Name</TableCell>
                              <TableCell>Email</TableCell>
                              <TableCell>Contact Number</TableCell>
                              <TableCell sx={{display:{xs:"none", md:"block"}}}>Designation</TableCell>
                              <TableCell sx={{display:{xs:"none", md:"block"}}}>Department</TableCell>
                              <TableCell
                                sx={{ display: { xs: "none", md: "block" } }}
                              >
                                Dial Code
                              </TableCell>
                              <TableCell>Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {values.spoc_information.map(
                              (_: any, index: any) => (
                                <TableRow key={index}>
                                  <TableCell>
                                    <Field
                                      name={`spoc_information[${index}].spoc_name`}
                                      as={TextField}
                                        variant="standard"
                                      placeholder="Name"
                                      fullWidth
                                      onChange={(e: any) => {
                                        handleChange(e);

                                        // Update the initValues state with the new value
                                        setClaimedData((prev: any) => {
                                          const updatedSpocInformation = [
                                            ...prev?.spoc_information,
                                          ];
                                          updatedSpocInformation[
                                            index
                                          ].spoc_name = e.target.value;
                                          return {
                                            ...prev,
                                            spoc_information:
                                              updatedSpocInformation,
                                          };
                                        });
                                      }}
                                      onBlur={handleBlur}
                                      error={
                                        touched.spoc_information?.[index]
                                          ?.spoc_name &&
                                        Boolean(
                                          errors.spoc_information?.[index]
                                            ?.spoc_name
                                        )
                                      }
                                      helperText={
                                        touched.spoc_information?.[index]
                                          ?.spoc_name &&
                                        errors.spoc_information?.[index]
                                          ?.spoc_name
                                      }
                                    />
                                  </TableCell>
                                  <TableCell >
                                    <Field
                                      name={`spoc_information[${index}].spoc_email`}
                                      as={TextField}
                                      variant="standard"
                                      type="email"
                                      placeholder="Email"
                                      fullWidth
                                      onChange={(e: any) => {
                                        handleChange(e);

                                        // Update the initValues state with the new value
                                        setClaimedData((prev: any) => {
                                          const updatedSpocInformation = [
                                            ...prev.spoc_information,
                                          ];
                                          updatedSpocInformation[
                                            index
                                          ].spoc_email = e.target.value;
                                          return {
                                            ...prev,
                                            spoc_information:
                                              updatedSpocInformation,
                                          };
                                        });
                                      }}
                                      onBlur={handleBlur}
                                      error={
                                        touched.spoc_information?.[index]
                                          ?.spoc_email &&
                                        Boolean(
                                          errors.spoc_information?.[index]
                                            ?.spoc_email
                                        )
                                      }
                                      helperText={
                                        touched.spoc_information?.[index]
                                          ?.spoc_email &&
                                        errors.spoc_information?.[index]
                                          ?.spoc_email
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Field
                                      name={`spoc_information[${index}].spoc_contact_number`}
                                      as={TextField}
                                      variant="standard"
                                      placeholder="Phone Number"
                                      fullWidth
                                      onChange={(e: any) => {
                                        handleChange(e);

                                        // Update the initValues state with the new value
                                        setClaimedData((prev: any) => {
                                          const updatedSpocInformation = [
                                            ...prev.spoc_information,
                                          ];
                                          updatedSpocInformation[
                                            index
                                          ].spoc_contact_number =
                                            e.target.value;
                                          return {
                                            ...prev,
                                            spoc_information:
                                              updatedSpocInformation,
                                          };
                                        });
                                      }}
                                      onBlur={handleBlur}
                                      error={
                                        touched.spoc_information?.[index]
                                          ?.spoc_contact_number &&
                                        Boolean(
                                          errors.spoc_information?.[index]
                                            ?.spoc_contact_number
                                        )
                                      }
                                      helperText={
                                        touched.spoc_information?.[index]
                                          ?.spoc_contact_number &&
                                        errors.spoc_information?.[index]
                                          ?.spoc_contact_number
                                      }
                                    />
                                  </TableCell>
                                  <TableCell sx={{display:{xs:"none", md:"block"}}}>
                                    <Field
                                      name={`spoc_information[${index}].spoc_designation`}
                                      as={TextField}
                                      variant="standard"
                                      placeholder="Designation"
                                      fullWidth
                                      onChange={(e: any) => {
                                        handleChange(e);

                                        // Update the initValues state with the new value
                                        setClaimedData((prev: any) => {
                                          const updatedSpocInformation = [
                                            ...prev.spoc_information,
                                          ];
                                          updatedSpocInformation[
                                            index
                                          ].spoc_designation = e.target.value;
                                          return {
                                            ...prev,
                                            spoc_information:
                                              updatedSpocInformation,
                                          };
                                        });
                                      }}
                                      onBlur={handleBlur}
                                    />
                                  </TableCell>
                                  <TableCell sx={{display:{xs:"none", md:"block"}}}>
                                    <Field
                                      name={`spoc_information[${index}].spoc_department`}
                                      as={TextField}
                                      variant="standard"
                                      placeholder="Department"
                                      fullWidth
                                      onChange={(e: any) => {
                                        handleChange(e);

                                        // Update the initValues state with the new value
                                        setClaimedData((prev: any) => {
                                          const updatedSpocInformation = [
                                            ...prev.spoc_information,
                                          ];
                                          updatedSpocInformation[
                                            index
                                          ].spoc_department = e.target.value;
                                          return {
                                            ...prev,
                                            spoc_information:
                                              updatedSpocInformation,
                                          };
                                        });
                                      }}
                                      onBlur={handleBlur}
                                    />
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      display: { xs: "none", md: "block" },
                                    }}
                                  >
                                    <Field
                                      name={`spoc_information[${index}].spoc_dial_code`}
                                      as={TextField}
                                      variant="standard"
                                      placeholder="Dial Code"
                                      fullWidth
                                      onChange={(e: any) => {
                                        handleChange(e);

                                        // Update the initValues state with the new value
                                        setClaimedData((prev: any) => {
                                          const updatedSpocInformation = [
                                            ...prev.spoc_information,
                                          ];
                                          updatedSpocInformation[
                                            index
                                          ].spoc_dial_code = e.target.value;
                                          return {
                                            ...prev,
                                            spoc_information:
                                              updatedSpocInformation,
                                          };
                                        });
                                      }}
                                      onBlur={handleBlur}
                                      error={
                                        touched.spoc_information?.[index]
                                          ?.spoc_dial_code &&
                                        Boolean(
                                          errors.spoc_information?.[index]
                                            ?.spoc_dial_code
                                        )
                                      }
                                      helperText={
                                        touched.spoc_information?.[index]
                                          ?.spoc_dial_code &&
                                        errors.spoc_information?.[index]
                                          ?.spoc_dial_code
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <DeleteIcon
                                      sx={{
                                        color: "#00887a",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => remove(index)}
                                    />
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table> */}

                        <Table sx={{ minWidth: "max-content", width: "100%" }}>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                sx={{ width: "auto", whiteSpace: "nowrap" }}
                              >
                                Name
                              </TableCell>
                              <TableCell
                                sx={{ width: "auto", whiteSpace: "nowrap" }}
                              >
                                Email
                              </TableCell>
                              <TableCell
                                sx={{ width: "auto", whiteSpace: "nowrap" }}
                              >
                                Contact Number
                              </TableCell>
                              <TableCell
                                sx={{
                                  width: "auto",
                                  whiteSpace: "nowrap",
                                  display: { xs: "none", md: "table-cell" },
                                }}
                              >
                                Designation
                              </TableCell>
                              <TableCell
                                sx={{
                                  width: "auto",
                                  whiteSpace: "nowrap",
                                  display: { xs: "none", md: "table-cell" },
                                }}
                              >
                                Department
                              </TableCell>
                              <TableCell
                                sx={{
                                  width: "auto",
                                  whiteSpace: "nowrap",
                                  display: { xs: "none", md: "table-cell" },
                                }}
                              >
                                Dial Code
                              </TableCell>
                              <TableCell
                                sx={{ width: "auto", whiteSpace: "nowrap" }}
                              >
                                Actions
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Array.isArray(values.spoc_information) &&
                              values.spoc_information?.map(
                                (item: any, index: any) => (
                                  <TableRow key={index}>
                                    <TableCell
                                      sx={{
                                        width: "auto",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      <Field
                                        name={`spoc_information[${index}].spoc_name`}
                                        as={TextField}
                                        variant="standard"
                                        placeholder="Name"
                                        fullWidth
                                        onChange={(e: any) => {
                                          handleChange(e);
                                          setClaimedData((prev: any) => {
                                            const updatedSpocInformation = [
                                              ...prev?.spoc_information,
                                            ];
                                            updatedSpocInformation[
                                              index
                                            ].spoc_name = e.target.value;
                                            return {
                                              ...prev,
                                              spoc_information:
                                                updatedSpocInformation,
                                            };
                                          });
                                        }}
                                        onBlur={handleBlur}
                                        error={
                                          touched.spoc_information?.[index]
                                            ?.spoc_name &&
                                          Boolean(
                                            errors.spoc_information?.[index]
                                              ?.spoc_name
                                          )
                                        }
                                        helperText={
                                          touched.spoc_information?.[index]
                                            ?.spoc_name &&
                                          errors.spoc_information?.[index]
                                            ?.spoc_name
                                        }
                                        InputProps={{ readOnly: isSmallScreen }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        width: "auto",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      <Field
                                        name={`spoc_information[${index}].spoc_email`}
                                        as={TextField}
                                        variant="standard"
                                        type="email"
                                        placeholder="Email"
                                        fullWidth
                                        onChange={(e: any) => {
                                          handleChange(e);
                                          setClaimedData((prev: any) => {
                                            const updatedSpocInformation = [
                                              ...prev.spoc_information,
                                            ];
                                            updatedSpocInformation[
                                              index
                                            ].spoc_email = e.target.value;
                                            return {
                                              ...prev,
                                              spoc_information:
                                                updatedSpocInformation,
                                            };
                                          });
                                        }}
                                        onBlur={handleBlur}
                                        error={
                                          touched.spoc_information?.[index]
                                            ?.spoc_email &&
                                          Boolean(
                                            errors.spoc_information?.[index]
                                              ?.spoc_email
                                          )
                                        }
                                        helperText={
                                          touched.spoc_information?.[index]
                                            ?.spoc_email &&
                                          errors.spoc_information?.[index]
                                            ?.spoc_email
                                        }
                                        InputProps={{ readOnly: isSmallScreen }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        width: "auto",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      <Field
                                        name={`spoc_information[${index}].spoc_contact_number`}
                                        as={TextField}
                                        variant="standard"
                                        placeholder="Phone Number"
                                        fullWidth
                                        onChange={(e: any) => {
                                          handleChange(e);
                                          setClaimedData((prev: any) => {
                                            const updatedSpocInformation = [
                                              ...prev.spoc_information,
                                            ];
                                            updatedSpocInformation[
                                              index
                                            ].spoc_contact_number =
                                              e.target.value;
                                            return {
                                              ...prev,
                                              spoc_information:
                                                updatedSpocInformation,
                                            };
                                          });
                                        }}
                                        onBlur={handleBlur}
                                        error={
                                          touched.spoc_information?.[index]
                                            ?.spoc_contact_number &&
                                          Boolean(
                                            errors.spoc_information?.[index]
                                              ?.spoc_contact_number
                                          )
                                        }
                                        helperText={
                                          touched.spoc_information?.[index]
                                            ?.spoc_contact_number &&
                                          errors.spoc_information?.[index]
                                            ?.spoc_contact_number
                                        }
                                        InputProps={{ readOnly: isSmallScreen }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        width: "auto",
                                        whiteSpace: "nowrap",
                                        display: {
                                          xs: "none",
                                          md: "table-cell",
                                        },
                                      }}
                                    >
                                      <Field
                                        name={`spoc_information[${index}].spoc_designation`}
                                        as={TextField}
                                        variant="standard"
                                        placeholder="Designation"
                                        fullWidth
                                        onChange={(e: any) => {
                                          handleChange(e);
                                          setClaimedData((prev: any) => {
                                            const updatedSpocInformation = [
                                              ...prev.spoc_information,
                                            ];
                                            updatedSpocInformation[
                                              index
                                            ].spoc_designation = e.target.value;
                                            return {
                                              ...prev,
                                              spoc_information:
                                                updatedSpocInformation,
                                            };
                                          });
                                        }}
                                        onBlur={handleBlur}
                                        InputProps={{ readOnly: isSmallScreen }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        width: "auto",
                                        whiteSpace: "nowrap",
                                        display: {
                                          xs: "none",
                                          md: "table-cell",
                                        },
                                      }}
                                    >
                                      <Field
                                        name={`spoc_information[${index}].spoc_department`}
                                        as={TextField}
                                        variant="standard"
                                        placeholder="Department"
                                        fullWidth
                                        onChange={(e: any) => {
                                          handleChange(e);
                                          setClaimedData((prev: any) => {
                                            const updatedSpocInformation = [
                                              ...prev.spoc_information,
                                            ];
                                            updatedSpocInformation[
                                              index
                                            ].spoc_department = e.target.value;
                                            return {
                                              ...prev,
                                              spoc_information:
                                                updatedSpocInformation,
                                            };
                                          });
                                        }}
                                        onBlur={handleBlur}
                                        InputProps={{ readOnly: isSmallScreen }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        width: "auto",
                                        whiteSpace: "nowrap",
                                        display: {
                                          xs: "none",
                                          md: "table-cell",
                                        },
                                      }}
                                    >
                                      <Field
                                        name={`spoc_information[${index}].spoc_dial_code`}
                                        as={TextField}
                                        variant="standard"
                                        placeholder="Dial Code"
                                        fullWidth
                                        onChange={(e: any) => {
                                          handleChange(e);
                                          setClaimedData((prev: any) => {
                                            const updatedSpocInformation = [
                                              ...prev.spoc_information,
                                            ];
                                            updatedSpocInformation[
                                              index
                                            ].spoc_dial_code = e.target.value;
                                            return {
                                              ...prev,
                                              spoc_information:
                                                updatedSpocInformation,
                                            };
                                          });
                                        }}
                                        onBlur={handleBlur}
                                        error={
                                          touched.spoc_information?.[index]
                                            ?.spoc_dial_code &&
                                          Boolean(
                                            errors.spoc_information?.[index]
                                              ?.spoc_dial_code
                                          )
                                        }
                                        helperText={
                                          touched.spoc_information?.[index]
                                            ?.spoc_dial_code &&
                                          errors.spoc_information?.[index]
                                            ?.spoc_dial_code
                                        }
                                        InputProps={{ readOnly: isSmallScreen }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        width: "auto",
                                        whiteSpace: "nowrap",
                                        display: "flex",
                                      }}
                                    >
                                      <EditIcon
                                        sx={{
                                          display: { xs: "block", md: "none" },
                                          color: "#00887a",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          addContactModal.open(item)
                                        }
                                      />
                                      <DeleteIcon
                                        sx={{
                                          color: "#00887a",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => remove(index)}
                                      />
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </FieldArray>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginY: "2rem",
                    }}
                  >
                    <Divider
                      sx={{
                        flex: 1,
                        borderBottomWidth: "1px",
                        borderColor: "#ccc",
                      }}
                    />

                    <Button
                      variant="contained"
                      onClick={() => {
                        setClaimedData({
                          ...claimedData,
                          spoc_information: [
                            ...values.spoc_information,
                            {
                              spoc_name: "",
                              spoc_email: "",
                              spoc_contact_number: "",
                              spoc_dial_code: "91", // default dial code
                              spoc_designation: "",
                              spoc_department: "",
                            },
                          ],
                        });
                        setFieldValue(`spoc_information`, [
                          ...values.spoc_information,
                          {
                            spoc_name: "",
                            spoc_email: "",
                            spoc_contact_number: "",
                            spoc_dial_code: "91", // default dial code
                            spoc_designation: "",
                            spoc_department: "",
                          },
                        ]);
                      }}
                      sx={{ display: { xs: "none", md: "block" } }}
                    >
                      Add Contact
                    </Button>
                    <Button
                      sx={{ display: { xs: "block", md: "none" } }}
                      variant="contained"
                      onClick={() => addContactModal.open()}
                    >
                      Add Contact
                    </Button>
                    <Divider
                      sx={{
                        flex: 1,
                        borderBottomWidth: "1px",
                        borderColor: "#ccc",
                      }}
                    />
                  </Box>
                </Box>

                {/* <Box sx={{display:"flex",marginTop:"2rem", padding:{xs:"0", md:"0 2.5rem 0 0"}, marginBottom:"4rem" }}>

                  {values?.spoc_information?.length > 0 && (
                    <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ padding: '1rem', width: 'auto', whiteSpace: 'nowrap', }}>Name</TableCell>
                          <TableCell sx={{ padding: '1rem', width: 'auto', whiteSpace: 'nowrap' }}>Email</TableCell>
                          <TableCell sx={{ padding: '1rem', width: 'auto', whiteSpace: 'nowrap' }}>Contact Number</TableCell>
                          <TableCell sx={{ padding: '1rem', width: 'auto', whiteSpace: 'nowrap', display:{xs:"none", md:"block"} }}>Department</TableCell>
                          <TableCell sx={{ padding: '1rem', width: 'auto', whiteSpace: 'nowrap' }}>Designation</TableCell>
                          <TableCell sx={{ padding: '1rem', width: 'auto', whiteSpace: 'nowrap', display:{xs:"none", md:"block"} }}>Dial Code</TableCell>
                          
                          <TableCell sx={{ padding: '1rem', width: 'auto', whiteSpace: 'nowrap' }}>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {values?.spoc_information.map((spoc:any, index:any) => (
                          <TableRow key={index}>
                        <TableCell sx={{ padding: '1rem', width: 'auto', whiteSpace: 'nowrap',  }}>{spoc.spoc_name}</TableCell>
                        <TableCell sx={{ padding: '1rem', width: 'auto', whiteSpace: 'nowrap', }}>{spoc.spoc_email}</TableCell>
                            <TableCell sx={{ padding: '1rem', width: 'auto', whiteSpace: 'nowrap', }}>{spoc.spoc_contact_number}</TableCell>
                            <TableCell sx={{ padding: '1rem', width: 'auto', whiteSpace: 'nowrap',display:{xs:"none", md:"block"} }}>{spoc.spoc_department}</TableCell>
                            <TableCell sx={{ padding: '1rem', width: 'auto', whiteSpace: 'nowrap', }}>{spoc.spoc_designation}</TableCell>
                            <TableCell sx={{ padding: '1rem', width: 'auto', whiteSpace: 'nowrap',display:{xs:"none", md:"block"} }}>{spoc.spoc_dial_code}</TableCell>
                           
                            <TableCell sx={{ padding: '1rem', width: 'auto', whiteSpace: 'nowrap', }}>
                              <DeleteIcon sx={{color:"#00887a",cursor:"pointer"}}  onClick={(e: any) => {
                    e.stopPropagation(); // Prevent triggering the chip's onClick
                    const updatedContatDetails =
                      formik.values.spoc_information?.filter(
                        (item: any, i: number) =>
                          item?.spoc_email !== spoc?.spoc_email
                      );
                    formik.setFieldValue(
                      "spoc_information",
                      updatedContatDetails
                    );
                    setFieldValue(
                      "spoc_information",
                      updatedContatDetails
                    );
                    setClaimedData({
                      ...claimedData,
                      spoc_information: updatedContatDetails,
                    });
                    if (
                      activeContact?.spoc_email === spoc?.spoc_email
                    ) {
                      setActiveContact(null);
                    }
                  }}/>
             
              </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  )}
                  
                
                </Box> */}

                {/* <FieldArray name="spoc_information">
                  {({ remove }) => (
                    <TableContainer
                      component={Paper}
                      sx={{ marginTop: "2rem" }}
                    >
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Contact Number</TableCell>
                            <TableCell>Designation</TableCell>
                            <TableCell>Department</TableCell>
                            <TableCell
                              sx={{ display: { xs: "none", md: "block" } }}
                            >
                              Dial Code
                            </TableCell>
                            <TableCell>Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {values.spoc_information.map((_: any, index: any) => (
                            <TableRow key={index}>
                              <TableCell>
                                <Field
                                  name={`spoc_information[${index}].spoc_name`}
                                  as={TextField}
                                  variant="standard"
                                  placeholder="Name"
                                  fullWidth
                                  onChange={(e: any) => {
                                    handleChange(e);

                                    // Update the initValues state with the new value
                                    setClaimedData((prev: any) => {
                                      const updatedSpocInformation = [
                                        ...prev?.spoc_information,
                                      ];
                                      updatedSpocInformation[index].spoc_name =
                                        e.target.value;
                                      return {
                                        ...prev,
                                        spoc_information:
                                          updatedSpocInformation,
                                      };
                                    });
                                  }}
                                  onBlur={handleBlur}
                                  error={
                                    touched.spoc_information?.[index]
                                      ?.spoc_name &&
                                    Boolean(
                                      errors.spoc_information?.[index]
                                        ?.spoc_name
                                    )
                                  }
                                  helperText={
                                    touched.spoc_information?.[index]
                                      ?.spoc_name &&
                                    errors.spoc_information?.[index]?.spoc_name
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                <Field
                                  name={`spoc_information[${index}].spoc_email`}
                                  as={TextField}
                                  variant="standard"
                                  type="email"
                                  placeholder="Email"
                                  fullWidth
                                  onChange={(e: any) => {
                                    handleChange(e);

                                    // Update the initValues state with the new value
                                    setClaimedData((prev: any) => {
                                      const updatedSpocInformation = [
                                        ...prev.spoc_information,
                                      ];
                                      updatedSpocInformation[index].spoc_email =
                                        e.target.value;
                                      return {
                                        ...prev,
                                        spoc_information:
                                          updatedSpocInformation,
                                      };
                                    });
                                  }}
                                  onBlur={handleBlur}
                                  error={
                                    touched.spoc_information?.[index]
                                      ?.spoc_email &&
                                    Boolean(
                                      errors.spoc_information?.[index]
                                        ?.spoc_email
                                    )
                                  }
                                  helperText={
                                    touched.spoc_information?.[index]
                                      ?.spoc_email &&
                                    errors.spoc_information?.[index]?.spoc_email
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                <Field
                                  name={`spoc_information[${index}].spoc_contact_number`}
                                  as={TextField}
                                  variant="standard"
                                  inputProps={{
                                    maxLength: 10,
                                    inputMode: "numeric",
                                    pattern: "[0-9]*",
                                    style: {
                                      MozAppearance: "textfield", // Removes spinner in Firefox
                                    },
                                  }}
                                  placeholder="Phone Number"
                                  fullWidth
                                  onChange={(e: any) => {
                                    handleChange(e);

                                    // Update the initValues state with the new value
                                    setClaimedData((prev: any) => {
                                      const updatedSpocInformation = [
                                        ...prev.spoc_information,
                                      ];
                                      updatedSpocInformation[
                                        index
                                      ].spoc_contact_number = e.target.value;
                                      return {
                                        ...prev,
                                        spoc_information:
                                          updatedSpocInformation,
                                      };
                                    });
                                  }}
                                  onBlur={handleBlur}
                                  error={
                                    touched.spoc_information?.[index]
                                      ?.spoc_contact_number &&
                                    Boolean(
                                      errors.spoc_information?.[index]
                                        ?.spoc_contact_number
                                    )
                                  }
                                  helperText={
                                    touched.spoc_information?.[index]
                                      ?.spoc_contact_number &&
                                    errors.spoc_information?.[index]
                                      ?.spoc_contact_number
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                <Field
                                  name={`spoc_information[${index}].spoc_designation`}
                                  as={TextField}
                                  variant="standard"
                                  placeholder="Designation"
                                  fullWidth
                                  onChange={(e: any) => {
                                    handleChange(e);

                                    // Update the initValues state with the new value
                                    setClaimedData((prev: any) => {
                                      const updatedSpocInformation = [
                                        ...prev.spoc_information,
                                      ];
                                      updatedSpocInformation[
                                        index
                                      ].spoc_designation = e.target.value;
                                      return {
                                        ...prev,
                                        spoc_information:
                                          updatedSpocInformation,
                                      };
                                    });
                                  }}
                                  onBlur={handleBlur}
                                />
                              </TableCell>
                              <TableCell>
                                <Field
                                  name={`spoc_information[${index}].spoc_department`}
                                  as={TextField}
                                  variant="standard"
                                  placeholder="Department"
                                  fullWidth
                                  onChange={(e: any) => {
                                    handleChange(e);

                                    // Update the initValues state with the new value
                                    setClaimedData((prev: any) => {
                                      const updatedSpocInformation = [
                                        ...prev.spoc_information,
                                      ];
                                      updatedSpocInformation[
                                        index
                                      ].spoc_department = e.target.value;
                                      return {
                                        ...prev,
                                        spoc_information:
                                          updatedSpocInformation,
                                      };
                                    });
                                  }}
                                  onBlur={handleBlur}
                                />
                              </TableCell>
                              <TableCell
                                sx={{
                                  display: { xs: "none", md: "block" },
                                }}
                              >
                                <Field
                                  name={`spoc_information[${index}].spoc_dial_code`}
                                  as={TextField}
                                  variant="standard"
                                  placeholder="Dial Code"
                                  fullWidth
                                  onChange={(e: any) => {
                                    handleChange(e);

                                    // Update the initValues state with the new value
                                    setClaimedData((prev: any) => {
                                      const updatedSpocInformation = [
                                        ...prev.spoc_information,
                                      ];
                                      updatedSpocInformation[
                                        index
                                      ].spoc_dial_code = e.target.value;
                                      return {
                                        ...prev,
                                        spoc_information:
                                          updatedSpocInformation,
                                      };
                                    });
                                  }}
                                  onBlur={handleBlur}
                                  error={
                                    touched.spoc_information?.[index]
                                      ?.spoc_dial_code &&
                                    Boolean(
                                      errors.spoc_information?.[index]
                                        ?.spoc_dial_code
                                    )
                                  }
                                  helperText={
                                    touched.spoc_information?.[index]
                                      ?.spoc_dial_code &&
                                    errors.spoc_information?.[index]
                                      ?.spoc_dial_code
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                <DeleteIcon
                                  sx={{
                                    color: "#00887a",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => remove(index)}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </FieldArray>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginY: "2rem",
                  }}
                >
                  <Divider
                    sx={{
                      flex: 1,
                      borderBottomWidth: "1px",
                      borderColor: "#ccc",
                    }}
                  />
                  <Button
                    variant="contained"
                    onClick={() => {
                      setClaimedData({
                        ...claimedData,
                        spoc_information: [
                          ...values.spoc_information,
                          {
                            spoc_name: "",
                            spoc_email: "",
                            spoc_contact_number: "",
                            spoc_dial_code: "91", // default dial code
                            spoc_designation: "",
                            spoc_department: "",
                          },
                        ],
                      });
                      setFieldValue(`spoc_information`, [
                        ...values.spoc_information,
                        {
                          spoc_name: "",
                          spoc_email: "",
                          spoc_contact_number: "",
                          spoc_dial_code: "91", // default dial code
                          spoc_designation: "",
                          spoc_department: "",
                        },
                      ]);
                    }}
                  >
                    Add Contact
                  </Button>
                  <Divider
                    sx={{
                      flex: 1,
                      borderBottomWidth: "1px",
                      borderColor: "#ccc",
                    }}
                  />
                </Box> */}

                {/* ClientInformation */}
                <Box
                  sx={{
                    width: "100%",
                    marginTop: "1.5rem",
                    // padding: "3rem 1rem",
                    // border: "0.1px solid #f0f0f0",
                    // borderRadius: "1rem",
                  }}
                >
                  <Typography
                    variant="h6"
                    color="#4f5250"
                    gutterBottom
                    sx={{ marginBottom: "1rem" }}
                  >
                    Customer Information
                  </Typography>
                </Box>

                <FieldArray name="client_information">
                  {({ remove }) => (
                    <TableContainer
                      component={Paper}
                      sx={{ marginTop: "2rem" }}
                    >
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Company Name</TableCell>
                            {/* Add more columns if you uncomment fields */}
                            <TableCell>Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Array.isArray(values.client_information) &&
                            values.client_information?.map(
                              (client: any, index: any) => (
                                <TableRow key={index}>
                                  <TableCell>
                                    <TextField
                                      name={`client_information[${index}].company_name`}
                                      variant="standard"
                                      placeholder="Company Name"
                                      fullWidth
                                      value={
                                        values.client_information[index]
                                          .company_name
                                      }
                                      // onChange={(e) => {
                                      //   handleChange(e);

                                      //   // Update the initValues state with the new value
                                      //   setClaimedData((prev: any) => {
                                      //     const updatedClientInformation = [
                                      //       ...prev.client_information,
                                      //     ];
                                      //     updatedClientInformation[
                                      //       index
                                      //     ].company_name = e.target.value;
                                      //     return {
                                      //       ...prev,
                                      //       client_information:
                                      //         updatedClientInformation,
                                      //     };
                                      //   });
                                      // }}

                                      onChange={(e) => {
                                        handleChange(e);
                                      
                                        // Ensure we have a valid client information entry
                                        setClaimedData((prev: any) => {
                                          const updatedClientInformation = [...(prev.client_information || [])]; // Default to empty array if undefined
                                      
                                          // Check if the current index exists; if not, create a new entry
                                          if (!updatedClientInformation[index]) {
                                            updatedClientInformation[index] = {
                                              company_desc: "",
                                              company_logo: "",
                                              company_name: ""
                                            };
                                          }
                                      
                                          // Update the company_name
                                          updatedClientInformation[index].company_name = e.target.value;
                                      
                                          return {
                                            ...prev,
                                            client_information: updatedClientInformation,
                                          };
                                        });
                                      }}
                                      onBlur={handleBlur}
                                      error={
                                        touched.client_information?.[index]
                                          ?.company_name &&
                                        Boolean(
                                          errors.client_information?.[index]
                                            ?.company_name
                                        )
                                      }
                                      helperText={
                                        touched.client_information?.[index]
                                          ?.company_name &&
                                        errors.client_information?.[index]
                                          ?.company_name
                                      }
                                    />
                                  </TableCell>
                                  {/* Add more cells if you uncomment fields */}
                                  <TableCell sx={{display:"flex", alignItems:"center", gap:"1rem"}}>
                                    <LoadingButton
                                    loading={client.loader}
                                    sx={{flex:1, }}
                                      variant="contained"
                                      color="primary"
                                      onClick={() => triggerFileInput(index)}
                                    >
                                      Upload Logo
                                    </LoadingButton>
                                    <input
                                      type="file"
                                      accept=".png, .jpg, .jpeg, .svg"
                                      ref={(el) =>
                                        (fileInputRefs.current[index] = el)
                                      }
                                      style={{ display: "none" }}
                                      onChange={(event: any) => {
                                        setFieldValue(
                                          `client_information.[${index}].loader`,
                                          true
                                        );
                                        const file = event.target.files[0];
                                       
        
                                        if (file) {
                                          if (event.target.files.length > 1) {
                                            // setFileError('You can only upload 1 file');
                                            alert("Only one file can be uploaded");
                                            event.target.value = "";
                                            return;
                                          }
                                        }
                                        if (file.size / (1024 * 1024) < 5) {
                                          if (file?.type == "pdf") {
                                            ADMIN.toast.error(
                                              "uploading pdf files is restricted"
                                            );
                                          } else {
                                            ImageUploadS3ProofDocss(
                                              [file],
                                              setFieldValue,
                                              setLoader,
                                              `client_information.[${index}].company_logo`
                                            );
                                           
        
                                            formik.setFieldValue(
                                              `client_information.[${index}].company_logo`,
                                              URL.createObjectURL(file)
                                            );
                                           
                                            setClaimedData({
                                              ...claimedData,
                                              logo: URL.createObjectURL(file),
                                            });
                                          }
                                        } else if (file.size / (1024 * 1024) > 5) {
                                          Swal.fire({
                                            title: "Warning !",
                                            text: "Total file size exceeds the maximum limit of 5MB",
        
                                            icon: "warning",
                                            confirmButtonText: "Ok",
        
                                            customClass: {
                                              container: "swal2Container",
                                            },
                                            confirmButtonColor: "#008878",
                                          });
                                          event.target.value = "";
                                          return;
                                        }
                                        setFieldValue(
                                          `client_information.[${index}].loader`,
                                          false
                                        );
                                      }}
                                    />
                                    {client.company_logo && (
                                      <Box sx={{width:"80px", height:"80px", border:"0.1px solid #d9dedd", borderRadius:"1rem", padding:"1rem",   marginTop: "1rem",}}>
                                        <img
                                        src={`${CDN_URL}${client.company_logo}`}
                                        alt="Company Logo"
                                        style={{
                                       
                                          maxWidth: "100%",
                                          maxHeight: "100%",
                                          objectFit: "contain",
                                        }}
                                      />
                                      </Box>
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <DeleteIcon
                                      sx={{
                                        color: "#00887a",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => remove(index)}
                                    />
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </FieldArray>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginY: "2rem",
                  }}
                >
                  <Divider
                    sx={{
                      flex: 1,
                      borderBottomWidth: "1px",
                      borderColor: "#ccc",
                    }}
                  />
                  <Button
                    variant="contained"
                    sx={{ mx: 2 }}
                    onClick={async () => {
                      console.log(errors);

                      const newClientInfo = {
                        company_name: "",
                        company_logo: "",
                        company_desc: "",
                      };

                      const updatedClientInformation = [
                        ...values.client_information,
                        newClientInfo,
                      ];

                      // Update Formik state
                      setFieldValue(
                        "client_information",
                        updatedClientInformation
                      );

                      // Simultaneously update initValues state
                      setClaimedData((prev: any) => ({
                        ...prev,
                        client_information: updatedClientInformation,
                      }));
                    }}
                    // onClick={() => {

                    //   const newClientInfo = {
                    //     company_name: "",
                    //     company_logo: "",
                    //     company_desc: "",
                    //   };

                    //   const updatedClientInformation = [
                    //     ...values.client_information,
                    //     newClientInfo,
                    //   ];

                    //   // Update Formik state
                    //   setFieldValue("client_information", updatedClientInformation);

                    //   // Simultaneously update initValues state
                    //   setClaimedData((prev:any) => ({
                    //     ...prev,
                    //     client_information: updatedClientInformation,
                    //   }));
                    // }}
                  >
                    Add Client
                  </Button>
                  <Divider
                    sx={{
                      flex: 1,
                      borderBottomWidth: "1px",
                      borderColor: "#ccc",
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(1, 1fr)",
                    gap: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  <Box>
                    <Typography variant="subtitle1" gutterBottom>
                      About company <span style={{ color: "red" }}> *</span>
                      {Boolean(errors.about_business) &&
                        touched.about_business &&
                        typeof errors.about_business === "string" && (
                          //@ts-ignore
                          <span
                            style={{
                              color: "#e73e3e",
                              marginLeft: "1rem",
                              fontSize: "0.8rem",
                            }}
                          >
                            {errors.about_business}
                          </span>
                        )}
                    </Typography>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr",
                        columnGap: "1rem",
                        marginBottom: "1rem",
                      }}
                    >
                      {/* <AutoSizeTextarea
                        aria-label="empty textarea"
                        placeholder="Enter your text here..."
                        minRows={4}
                        name="about_business"
                        id="about_business"
                        value={values.about_business}
                        onChange={(e) => {
                          handleChange(e);
                          formik.handleChange(e);
                        }}
                      /> */}
                      <JoditEditor
                        ref={editor}
                        value={values.about_business}
                        // className="InwardMailEditor"
                        config={editorConfig}
                        onBlur={(newContent) => {
                          // handleChange(newContent);
                          console.log("newContentGHGHH", newContent);
                          setFieldValue("about_business", newContent);
                        }}
                        // onChange={(newContent) => setContent(newContent)}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ width: "100%", padding: "1rem 0" }}>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      // color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{
                        "&:hover": {
                          transform: "Scale(1.05)",
                          transition: "transform 0.5s ease",
                        },
                      }}
                    >
                      Previous
                    </Button>
                    <Box sx={{ display: "flex", gap: "1rem" }}>
                      <Button
                        size="small"
                        type="submit"
                        variant="contained"
                        sx={{
                          "&:hover": {
                            transform: "Scale(1.05)",
                            transition: "transform 0.5s ease",
                          },
                        }}
                      >
                        Next
                      </Button>
                    </Box>
                  </Box>
                </Box>

                <Test
                  values={values}
                  errors={errors}
                  setClaimedData={setClaimedData}
                  claimedData={claimedData}
                />
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
}

export default BusinessInfo;
