import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, Divider, FormControl, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { Formik, Form, Field, FieldArray } from 'formik';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import * as Yup from 'yup';
import { AddCircle, RemoveCircle } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../../Redux/Reducers/user.reducer'; 
import { API } from '../../../api-services'; 
import Swal from 'sweetalert2';
import { useBaseParamsModal } from '../../../CustomHook/useBaseParamsModal';
import ManualBusinessDataModal from './ManualForm/ManualBusinessDataModal';
import ManualLocationModal from './ManualForm/ManualLocationModal';
import ManualDirectorModal from './ManualForm/ManualDirectorModal';
import ManualChargesModal from './ManualForm/ManualChargeModal';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddLocationModal from './ManualForm/AddLocationModal';
import { log } from 'console';
import AddDirectorModal from './ManualForm/AddDirectorModal';
import AddChargeModal from './ManualForm/AddChargeModal';
import Test from './Test';

interface Director {
  din: string | null;
  firstName: string;
  middleName: string;
  lastName: string;
  appointmentDate: string;
  designation: string | null;
  isCurrentSignatory: boolean | null;
}

interface BusinessData {
  pan_number: string;
  gst_number: string;
  export_turnover: string;
  gross_turnover: string;
  cin_number: string;
  business_name: string;
  registration_number: string;
  incorporated_date: string;
  type: string;
  status: string;
  address_line: string;
  address_city: string;
  address_district: string;
  address_state: string;
  address_country: string;
  address_pincode: string;
  other_locations: any[];
  directors_signatories: Director[];
  charges: any[];
  financial_year: string;
  msme_registered:boolean;
}



const validationSchema = Yup.object({
  financial_year: Yup.string().required('Financial Year is required'),
  msme_registered: Yup.boolean().required('MSME Registration is required'),
  business_name: Yup.string().required('Business Name is required'),
  cin_number: Yup.string().required('CIN Number is required'),
  registration_number: Yup.string().required('Registration Number is required'),
  incorporated_date: Yup.string().required('Incorporated Date is required'),
  type: Yup.string().required('Type is required'),
  status: Yup.string().required('Status is required'),
  // export_turnover: Yup.mix().required('Export Turnover is required'),
  pan_number: Yup.string().required('PAN No is required'),
  gst_number: Yup.string().required('GST No is required'),
  address_line: Yup.string().required('Address is required'),
  address_city: Yup.string().required('City is required'),
  address_district: Yup.string().required('District is required'),
  address_state: Yup.string().required('State is required'),
  address_country: Yup.string().required('Country is required'),
  address_pincode: Yup.string().required('Pincode is required'),
  //other_locations: Yup.array().of(Yup.string().required('Location is required')),
  directors_signatories: Yup.array().of(
    Yup.object({
      amount: Yup.string().required('DIN is required'),
      firstName: Yup.string().required('First Name is required'),
      middleName: Yup.string(),
      lastName: Yup.string().required('Last Name is required'),
      appointmentDate: Yup.string().required('Appointment Date is required'),
      designation: Yup.string(),
      isCurrentSignatory: Yup.boolean().required('Signatory status is required')
    })
  ),
  charges: Yup.array().of(
    Yup.object({
      amount: Yup.mixed().required('Amount is required'),
      chargeHolder: Yup.string().required('Charge holder is required'),

      chargeStatus: Yup.mixed().required('Status  is required'),

    })
  )
});

const ManualBusinessDataForm = ({ SupllierId, handleNext, claimedData, setClaimedData, isFetched, setIsFetched, counts, setCounts,
  setIsClaimData,
  isClaimData
}: any) => {
    const businessDataModal = useBaseParamsModal();
    
    const addlocationDataModal = useBaseParamsModal();
    const adddirectorDataModal = useBaseParamsModal();
    const addchargesDataModal = useBaseParamsModal();

    const locationDataModal = useBaseParamsModal();
    const directorDataModal = useBaseParamsModal();
    const chargesDataModal = useBaseParamsModal();
  const [initialValues, setInitValues] = useState<any>({
    pan_number: "",
    gst_number: "",
    cin_number: "",
    business_name: "",
    financial_year: "",
    msme_registered:false,
    registration_number: "",
    incorporated_date: "",
    type: "",
    status: "Active",
    address_line: "",
    address_city: "",
    address_district: "",
    address_state: "",
    address_country: "",
    address_pincode: "",
    other_locations: [
      {
        gstin: "",                // GSTIN of the location
        legalName: "",            // Legal name of the location
        pan: "",                  // PAN number of the location
        primaryAddress: {
          district: "",           // District of the location
          street: "",             // Street address of the location
          registered: "",         // Registered status of the location
          status: "",             // Current status of the location
          building: "",           // Building name or number
          state: "",              // State of the location
        },
        status: "",               // Overall status of the location
        registered: "",           // Registration details of the location
      }
      // Additional locations can be added here
    ],
    directors_signatories: [
      {
        din: "",
        firstName: "",
        middleName: "",
        lastName: "",
        appointmentDate: "",
        designation: "",
        isCurrentSignatory: true
      }
      // Additional directors if necessary
    ],
    charges: [
      {
        amount: "",
        chargeHolder: "",
        chargeStatus: "",
      }
      // Additional charges if necessary
    ]
  });

  const activeFields = [

    "business_name",
    "incorporated_date",
    "cin_number",
    "type",
    "registration_number",
    "status",
    "address_line",
    "pan_number",
    "gross_turnover",
    "export_turnover",
    "address_country",
    "address_district",
    "address_city",
    "address_pincode",
    "records",
    "charges",
    "directors_signatories",

  ]


  const isEmpty = (value: any): boolean => {
    if (value === null || value === undefined) return true;
    if (Array.isArray(value) && value?.length === 0) return true;
    if (typeof value === 'object' && Object.keys(value)?.length === 0) return true;
    return false;
  };

  const userProfileDetails:any = useSelector(userSelectors.selectAll);
  const [loader, setLoader] = React.useState(false);
  const [locationEditIndex, setLocationEditIndex] = useState<number | null>(null);
  const [directorIndex, setDirectorIndex] = useState<number | null>(null);
  const [chargesIndex, setChargesIndex] = useState<number | null>(null);
  const [convertedAddress, setConvertedAddress] = useState<any>([]);
  const [convertedCharges, setConvertedCharges] = useState<any>([]);
  const [convertedDirectors, setConvertedDirectors] = useState<any>([]);

  const convertAddress = () => {
    const newAddressArray = claimedData?.records?.map((address: any) => ({
      legalName: address?.legalName || "",
      gstin: address?.gstin || "",
      active: address?.active || "",
      address_city: address?.primaryAddress?.city || "",
      zip: address?.primaryAddress?.zip || "",
      address_district: address?.primaryAddress?.district || "",
      address_country: "India", // Adding a default country or you can add dynamically
      address_pincode: address?.primaryAddress?.zip || "",
      address_line: [
        address?.primaryAddress?.building || "",
        address?.primaryAddress?.buildingName || "",
        address?.primaryAddress?.street || ""
      ].filter(Boolean).join(" "), // Filter out empty strings and join with a space
    }));
    setConvertedAddress(newAddressArray);
  };
  const convertCharges = () => {
    const newChargesArray = claimedData?.charges?.map((charge: any) => ({
      chargeHolder: charge?.chargeHolder,
      amount: charge?.amount,
      chargeStatus: charge?.chargeStatus, // Adding a default country or you can add dynamically

    }));
    setConvertedCharges(newChargesArray);
  };
  const convertDirectors = () => {
    const newChargesArray = claimedData?.directors_signatories?.map((director: any) => ({
      appointmentDate: director?.appointmentDate,
      designation: director?.designation,
      firstName: director?.firstName,
      middleName: director?.middleName,
      lastName: director?.lastName,
      isCurrentSignatory: director?.isCurrentSignatory,


    }));
    setConvertedDirectors(newChargesArray);
  };
  // const filteredFields = activeFields.filter(key => !isEmpty(claimedData?.[key]));
  useEffect(() => {
    if (claimedData?.business_name) {
      convertAddress();
      convertCharges();
      convertDirectors();

    }

      if (claimedData) {
        // Function to clean up the claimedData
        const cleanData = (data: any, defaultValue: any) => {
          return data !== undefined && data !== null && !(Array.isArray(data) && data.length === 0)
            ? data
            : defaultValue;
        };
  
        setInitValues({
          ...initialValues, pan_number: cleanData(claimedData?.pan_number, ""),
          gst_number: cleanData(claimedData?.gst_number, ""),
          cin_number: cleanData(claimedData?.cin_number, ""),
          export_turnover: cleanData(claimedData?.export_turnover, ""),
          gross_turnover: cleanData(claimedData?.gross_turnover, ""),
          business_name: cleanData(claimedData?.business_name, ""),
          msme_registered: cleanData(claimedData?.msme_registered, false),
          financial_year: cleanData(claimedData?.financial_year, ""),
          registration_number: cleanData(claimedData?.registration_number, ""),
          incorporated_date: cleanData(claimedData?.incorporated_date, ""),
          type: cleanData(claimedData?.type, ""),
          status: cleanData(claimedData?.status, "Active"),
          address_line: cleanData(claimedData?.address_line, ""),
          address_city: cleanData(claimedData?.address_city, ""),
          address_district: cleanData(claimedData?.address_district, ""),
          address_state: cleanData(claimedData?.address_state, ""),
          address_country: cleanData(claimedData?.address_country, ""),
          address_pincode: cleanData(claimedData?.address_pincode, ""),
          other_locations: claimedData?.records?.map((location: any) => ({
            active: location?.active || "",
            gstin: location.gstin || "",
            legalName: location.legalName || "",
            pan: location.pan || "",
            primaryAddress: {
              district: location.primaryAddress?.district ? location.primaryAddress?.district :  location?.address_district ? location?.address_district : "",
              street: location.primaryAddress?.street ? location.primaryAddress?.street :  location?.address_line ? location?.address_line : "",
              registered: location.primaryAddress?.registered || "",
              status: location.primaryAddress?.status || "",
              building: location.primaryAddress?.building || "",
              state: location.primaryAddress?.state || "",
            },
            status: location.status || "",
            registered: location.registered || "",
          })) || [],
          directors_signatories: cleanData(claimedData?.directors_signatories, [
            {
              din: "",
              firstName: "",
              middleName: "",
              lastName: "",
              appointmentDate: "",
              designation: "",
              isCurrentSignatory: true
            }
          ]),
          charges: cleanData(claimedData?.charges, [
            {
              amount: "",
              chargeHolder: "",
              chargeStatus: ""
            }
          ])
        });
      }

  
      
    },[claimedData])



    const handleUpdateValues = (updatedValues: typeof initialValues) => {
        console.log("Updated Formik values:", updatedValues);
        // Perform any other actions needed with the updated values
      };




  return (
    <>
   
    <Formik
    enableReinitialize={true}
      initialValues={initialValues}
      // validationSchema={validationSchema}
      onSubmit={(values, errors) => {


        // const formvalues = { ...values };

        


        //   const newAddressArray = values?.other_locations?.map((address: any) => ({
        //     legalName: address?.legalName || "",
        //     gstin: address?.gstin || "",
        //     active: address?.active || "",
        //     address_city: address?.primaryAddress?.city || "",
        //     zip: address?.primaryAddress?.zip || "",
        //     address_district: address?.primaryAddress?.district || "",
        //     address_country: "India", // Adding a default country or you can add dynamically
        //     address_pincode: address?.primaryAddress?.zip || "",
        //     address_line: [
        //       address?.primaryAddress?.building || "",
        //       address?.primaryAddress?.buildingName || "",
        //       address?.primaryAddress?.street || ""
        //     ].filter(Boolean).join(" "), // Filter out empty strings and join with a space
        //   }));
        //   const newChargesArray = values?.charges?.map((charge: any) => ({
        //     chargeHolder: charge?.chargeHolder,
        //     amount: charge?.amount,
        //     chargeStatus: charge?.chargeStatus, // Adding a default country or you can add dynamically

        //   }));
        //   const newChargesDirectors = values?.directors_signatories?.map((director: any) => ({
        //     appointmentDate: director?.appointmentDate,
        //     designation: director?.designation,
        //     firstName: director?.firstName,
        //     middleName: director?.middleName,
        //     lastName: director?.lastName,
        //     isCurrentSignatory: director?.isCurrentSignatory,
        //     din: director?.din,

        //   }));


        //   formvalues["request_type"] = "Create";
        //   formvalues["records"] = newAddressArray;
        //   formvalues["charges"] = newChargesArray;
        //   formvalues["directors_signatories"] = newChargesDirectors;

       

        //   API.post(`/supplier/supplier_db/`, formvalues)
        //     .then((res: any) => {
        //       setClaimedData(res.data?.data);
        //       setIsFetched(true)
        //       handleNext();






        //     })

        //     .catch((err: any) => {


        //       Swal.fire({
        //         icon: "error",
        //         title: `${err.response?.data[0]}`,
        //       });
        //     }).finally(() => {
        //       setLoader(false);
        //     });

        const formvalues = { ...values };

        const newChargesArray = formvalues?.charges?.map((charge: any) => ({
            chargeHolder: charge?.chargeHolder,
            amount: charge?.amount,
            chargeStatus: charge?.chargeStatus, // Adding a default country or you can add dynamically
      
          }));

          const newDirectorArray = formvalues?.directors_signatories?.map((director: any) => ({
            appointmentDate: director?.appointmentDate,
            designation: director?.designation,
            firstName: director?.firstName,
            middleName: director?.middleName,
            lastName: director?.lastName,
            isCurrentSignatory: director?.isCurrentSignatory,
            din:director?.din
      
          }));

          const newAddressArray = formvalues?.other_locations?.map((address: any) => ({
            legalName: address?.legalName || "",
            pan: address?.pan || "",
            gstin: address?.gstin || "",
            active: address?.active === true ? true : address?.active === false ? false : false,
            address_city: address?.primaryAddress?.city || "",
            zip: address?.primaryAddress?.zip || "",
            address_district: address?.primaryAddress?.district || "",
            address_country: "India", // Adding a default country or you can add dynamically
            address_pincode: address?.primaryAddress?.zip || "",
            address_line: [
              address?.primaryAddress?.building || "",
              address?.primaryAddress?.buildingName || "",
              address?.primaryAddress?.street || ""
            ].filter(Boolean).join(" "), // Filter out empty strings and join with a space
          }));

          
          

        formvalues["records"] = newAddressArray;
        formvalues["charges"] = newChargesArray;
        formvalues["directors_signatories"] = newDirectorArray;



       
        
         
          

   
            setLoader(true);
            
           
            formvalues["request_type"] = "Create";

      
           if (SupllierId) {
           
      
            API.put(`/supplier/supplier_db/${SupllierId}/`, formvalues)
              .then((res) => {
      
      
                let data = res?.data?.data;
      
              if (Array.isArray(data?.vehicle_category) && data?.vehicle_category.every((item:any) => typeof item === 'object' && 'id' in item)) {
                data.vehicle_category = Array.isArray(data.vehicle_category) && data.vehicle_category?.map((item:any) => item.id);
              }
          
              // Check if bom_category is an array of objects and extract ids
              if (Array.isArray(data?.bom_category) && data.bom_category.every((item:any) => typeof item === 'object' && 'id' in item)) {
                data.bom_category = Array.isArray(data.bom_category) && data.bom_category?.map((item:any) => item.id);
              }
                setClaimedData(res.data?.data);
                setIsFetched(true);
                handleNext();
              })
      
              .catch((err: any) => {
                Swal.fire({
                  icon: "error",
                  title: `${err.response?.data[0]}`,
                });
              })
              .finally(() => {
                setLoader(false);
              });
           } else {
            API.post(`/supplier/supplier_db/`, formvalues)
              .then((res) => {
                setClaimedData(res.data?.data);
                setIsFetched(true);
                handleNext();
              })
      
              .catch((err: any) => {
                Swal.fire({
                  icon: "error",
                  title: `${err.response?.data[0]}`,
                });
              })
              .finally(() => {
                setLoader(false);
              });
           }
      
          
        


      }}
    >
      {({values, errors, touched, setValues, setFieldValue }) => (
        <Form>
        {directorDataModal.isOpen && <ManualDirectorModal isOpen={directorDataModal.isOpen} onCloseModal={directorDataModal.close} 
            //@ts-ignore
            director={values.directors_signatories[directorIndex]}
            onSave={(updatedDirector:any) => {
                setFieldValue(`directors_signatories.${directorIndex}`, updatedDirector);
        }} />} 
        {locationDataModal.isOpen && <ManualLocationModal isOpen={locationDataModal.isOpen} onCloseModal={locationDataModal.close} 
            //@ts-ignore
            location={values.other_locations[locationEditIndex]}
            onSave={(updatedLocation:any) => {
            setFieldValue(`other_locations.${locationEditIndex}`, updatedLocation);
        }} />}
        {chargesDataModal.isOpen && <ManualChargesModal isOpen={chargesDataModal.isOpen} onCloseModal={chargesDataModal.close} 
            //@ts-ignore
            charge={values.charges[chargesIndex]}
            onSave={(updatedCharge:any) => {
            setFieldValue(`charges.${chargesIndex}`, updatedCharge);
        }} />}             
        {businessDataModal.isOpen && <ManualBusinessDataModal isOpen={businessDataModal.isOpen} onCloseModal={businessDataModal.close} initialValues={values} onSave={(updatedValues:any) => {
              setValues(updatedValues); // Update Formik values
              handleUpdateValues(updatedValues); // Handle form update action
            }} />}
        
          <Box sx={{ padding: { xs: '0', sm: '2rem' }, maxWidth: '90vw', margin: 'auto' }}>
           

            {/* Business Information Section */}
            <Box>
             <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
             <Typography variant="h6" sx={{ fontWeight: '600', marginBottom: '1rem' }}>
                Business Information
              </Typography>
              <Button  variant="contained" color="primary" sx={{ position: "sticky", top: "3rem" }} onClick={()=> businessDataModal.open(values)}>
                Edit 
              </Button>
             </Box>
              <Grid container spacing={{
                xs: 1,  // spacing for extra-small screens
                sm: 2,  // spacing for small screens
                md: 2,  // spacing for medium screens
                lg: 2,  // spacing for large screens
                xl: 2   // spacing for extra-large screens
              }}>
                <Grid item xs={12} md={6}>
                  <Field name="business_name">
                    {({ field }: any) => (
                      <TextField
                        {...field}
                        InputProps={{
                          readOnly: true,
                        }}
                        label="Business Name"
                        variant="outlined"
                        fullWidth
                        sx={{fontSize:'1rem',}}
                        error={touched.business_name && !!errors.business_name}
                        helperText={touched.business_name && errors.business_name}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="cin_number">
                    {({ field }: any) => (
                      <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                        {...field}
                        label="CIN Number"
                        variant="outlined"
                        fullWidth
                        sx={{fontSize:'1rem',}}
                        error={touched.cin_number && !!errors.cin_number}
                        helperText={touched.cin_number && errors.cin_number}
                      />
                    )}
                  </Field>
                </Grid>




                <Grid item xs={12} md={6}>
                  <Field name="registration_number">
                    {({ field }: any) => (
                      <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                        {...field}
                        label="Registration Number"
                        variant="outlined"
                        fullWidth
                        sx={{fontSize:'1rem',}}
                        error={touched.registration_number && !!errors.registration_number}
                        helperText={touched.registration_number && errors.registration_number}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="incorporated_date">
                    {({ field }: any) => (
                      <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                        {...field}
                        label="Incorporated Date"
                        variant="outlined"
                        fullWidth
                        sx={{fontSize:'1rem',}}
                        error={touched.incorporated_date && !!errors.incorporated_date}
                        helperText={touched.incorporated_date && errors.incorporated_date}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12} md={6}>
             
                  <Field name="msme_registered" InputProps={{
    readOnly: true,
  }}>
                    {({ field }: any) => (
                      <FormControl
                        fullWidth
                        
                        variant="outlined"
                        error={
                          touched.msme_registered && !!errors.msme_registered
                        }
                        margin="normal"
                      >
                        <InputLabel id="fmsme-registered-label">
                         MSME Registered
                        </InputLabel>
                        <Select
                          {...field}
                          disabled
                          labelId="fmsme-registered-label"
                          label="MSME Registered"
                        >
                          <MenuItem value="true">Yes</MenuItem>
                          <MenuItem value="false">No</MenuItem>

                          {/* Add more options as needed */}
                        </Select>
                        {/* {touched.msme_registered && errors.msme_registered && (
<FormHelperText>{errors.msme_registered}</FormHelperText>
)} */}
                      </FormControl>
                    )}
                  </Field>
                
                  </Grid>


                <Grid item xs={12} md={6}>

                <Field name="financial_year" InputProps={{
    readOnly: true,
  }}>
        {({ field }: any) => (
          <FormControl
          disabled
            fullWidth
            variant="outlined"
            error={touched.financial_year && !!errors.financial_year}
            margin="normal"
          >
            <InputLabel id="financial-year-label">Financial Year</InputLabel>
            <Select
              {...field}
              labelId="financial-year-label"
              label="Financial Year"
            >
             <MenuItem value="2026-27">2026-27</MenuItem>
              <MenuItem value="2025-26">2025-26</MenuItem>
              <MenuItem value="2024-25">2024-25</MenuItem>
              <MenuItem value="2023-24">2023-24</MenuItem>
              <MenuItem value="2022-23">2022-23</MenuItem>
              <MenuItem value="2021-22">2021-22</MenuItem>
              <MenuItem value="2020-21">2020-21</MenuItem>
              <MenuItem value="2019-20">2019-20</MenuItem>
              <MenuItem value="2018-19">2018-19</MenuItem>
              <MenuItem value="2017-18">2017-18</MenuItem>
              <MenuItem value="2016-17">2016-17</MenuItem>
              <MenuItem value="2015-16">2015-16</MenuItem>
              <MenuItem value="2014-15">2014-15</MenuItem>
              {/* Add more options as needed */}
            </Select>
        
                      </FormControl>
                    )}
                  </Field>
                </Grid>






                <Grid item xs={12} md={6}>
                  <Field name="type">
                    {({ field }: any) => (
                      <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                        {...field}
                        label="Type"
                        variant="outlined"
                        fullWidth
                        sx={{fontSize:'1rem',}}
                        error={touched.type && !!errors.type}
                        helperText={touched.type && errors.type}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="status">
                    {({ field }: any) => (
                      <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                        {...field}
                        label="Status"
                        variant="outlined"
                        fullWidth
                        sx={{fontSize:'1rem',}}
                        error={touched.status && !!errors.status}
                        helperText={touched.status && errors.status}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12} md={6}>
                  {/* <Field name="export_turnover">
                    {({ field }: any) => (
                      <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                        {...field}
                        sx={{fontSize:'1rem',}}
                        label="Export Turnover"
                        variant="outlined"
                        fullWidth
                        error={touched.export_turnover && !!errors.export_turnover}
                        helperText={touched.export_turnover && errors.export_turnover}
                      />
                    )}
                  </Field> */}
                  <Field name="export_turnover">
                    {({ field }: any) => (
                      <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                        {...field}
                        label="Export Turnover"
                        variant="outlined"
                        fullWidth
                        sx={{fontSize:'1rem',}}
                        error={touched.export_turnover && !!errors.export_turnover}
                        helperText={touched.export_turnover && errors.export_turnover}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="gross_turnover">
                    {({ field }: any) => (
                      <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                        {...field}
                        sx={{fontSize:'1rem',}}
                        label="Gross Turnover"
                        variant="outlined"
                        fullWidth
                        error={touched.gross_turnover && !!errors.gross_turnover}
                        helperText={touched.gross_turnover && errors.gross_turnover}
                      />
                    )}
                  </Field>
                </Grid>







                <Grid item xs={12} md={6}>
                  <Field name="pan_number">
                    {({ field }: any) => (
                      <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                        {...field}
                        sx={{fontSize:'1rem',}}
                        label="PAN Number"
                        variant="outlined"
                        fullWidth
                        error={touched.pan_number && !!errors.pan_number}
                        helperText={touched.pan_number && errors.pan_number}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="gst_number">
                    {({ field }: any) => (
                      <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{fontSize:'1rem',}}
                        {...field}
                        label="GST Number"
                        variant="outlined"
                        fullWidth
                        error={touched.gst_number && !!errors.gst_number}
                        helperText={touched.gst_number && errors.gst_number}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="address_line">
                    {({ field }: any) => (
                      <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                        {...field}
                        sx={{fontSize:'1rem',}}
                        label="Address"
                        variant="outlined"
                        fullWidth
                        error={touched.address_line && !!errors.address_line}
                        helperText={touched.address_line && errors.address_line}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="address_city">
                    {({ field }: any) => (
                      <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                        {...field}
                        sx={{fontSize:'1rem',}}
                        label="City"
                        variant="outlined"
                        fullWidth
                        error={touched.address_city && !!errors.address_city}
                        helperText={touched.address_city && errors.address_city}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="address_district">
                    {({ field }: any) => (
                      <TextField
                        {...field}
                        InputProps={{
                          readOnly: true,
                        }}
                        sx={{fontSize:'1rem',}}
                        label="District"
                        variant="outlined"
                        fullWidth
                        error={touched.address_district && !!errors.address_district}
                        helperText={touched.address_district && errors.address_district}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="address_state">
                    {({ field }: any) => (
                      <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{fontSize:'1rem',}}
                        {...field}
                        label="State"
                        variant="outlined"
                        fullWidth
                        error={touched.address_state && !!errors.address_state}
                        helperText={touched.address_state && errors.address_state}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="address_country">
                    {({ field }: any) => (
                      <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                        {...field}
                        sx={{fontSize:'1rem',}}
                        label="Country"
                        variant="outlined"
                        fullWidth
                        error={touched.address_country && !!errors.address_country}
                        helperText={touched.address_country && errors.address_country}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="address_pincode">
                    {({ field }: any) => (
                      <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                        {...field}
                        sx={{fontSize:'1rem',}}
                        label="Pincode"
                        variant="outlined"
                        fullWidth
                        error={touched.address_pincode && !!errors.address_pincode}
                        helperText={touched.address_pincode && errors.address_pincode}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
            </Box>

            {/* Other Locations Section */}
            <Box sx={{ marginTop: '2rem' }}>
            <Accordion>
              <AccordionSummary
             expandIcon={<ExpandMoreIcon sx={{color:"#fff"}} />}
             aria-controls="panel1-content"
            id="panel1-header"
             sx={{background:"#008080", color:"#fff", borderRadius:"5px", marginBottom:"1rem"}}
             >
            <Typography variant="h6" sx={{ fontWeight: '600',}}>
                Other Locations
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
      


            


            <FieldArray name="other_locations">
          {({ push, remove }) => (
            <Box>
               {addlocationDataModal.isOpen && <AddLocationModal isOpen={addlocationDataModal.isOpen} onCloseModal={addlocationDataModal.close} onSave={(newLocation:any) => {
              push(newLocation);
              
                 // Pass the push function to add the new location
            }}/>} 
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ background: "#008080", color: "#fff" }}>
                        GSTIN
                      </TableCell>
                      <TableCell sx={{ background: "#008080", color: "#fff" }}>
                        Active
                      </TableCell>
                      <TableCell sx={{ background: "#008080", color: "#fff" }}>
                        Legal Name
                      </TableCell>
                      <TableCell sx={{ background: "#008080", color: "#fff" }}>
                        PAN
                      </TableCell>
                      <TableCell sx={{ background: "#008080", color: "#fff" }}>
                        District
                      </TableCell>
                      <TableCell sx={{ background: "#008080", color: "#fff" }}>
                        Street
                      </TableCell>
                      <TableCell sx={{ background: "#008080", color: "#fff" }}>
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(values.other_locations) &&
                      values.other_locations.map((location: Location, index: number) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Field name={`other_locations.${index}.gstin`}>
                              {({ field }: any) => (
                                <TextField
                                  {...field}
                                  variant="standard"
                                  fullWidth
                                  // Show error if applicable
                                  error={
                                    // @ts-ignore
                                    touched.other_locations?.[index]?.gstin &&
                                    // @ts-ignore
                                    !!errors.other_locations?.[index]?.gstin
                                  }
                                  helperText={
                                    // @ts-ignore
                                    touched.other_locations?.[index]?.gstin &&
                                    // @ts-ignore
                                    errors.other_locations?.[index]?.gstin
                                  }
                                />
                              )}
                            </Field>
                          </TableCell>

                          <TableCell>
                            <Field name={`other_locations.${index}.active`}>
                              {({ field }: any) => (
                                <TextField
                                  {...field}
                                  value={field.value ? "Yes" : "No"}
                                  variant="standard"
                                  fullWidth
                                  // Show error if applicable
                                  error={
                                    // @ts-ignore
                                    touched.other_locations?.[index]?.active &&
                                    // @ts-ignore
                                    !!errors.other_locations?.[index]?.active
                                  }
                                  helperText={
                                    // @ts-ignore
                                    touched.other_locations?.[index]?.active &&
                                    // @ts-ignore
                                    errors.other_locations?.[index]?.active
                                  }
                                />
                              )}
                            </Field>
                          </TableCell>

                          <TableCell>
                            <Field name={`other_locations.${index}.legalName`}>
                              {({ field }: any) => (
                                <TextField
                                  {...field}
                                  variant="standard"
                                  fullWidth
                                  error={
                                    // @ts-ignore
                                    touched.other_locations?.[index]?.legalName &&
                                    // @ts-ignore
                                    !!errors.other_locations?.[index]?.legalName
                                  }
                                  helperText={
                                    // @ts-ignore
                                    touched.other_locations?.[index]?.legalName &&
                                    // @ts-ignore
                                    errors.other_locations?.[index]?.legalName
                                  }
                                />
                              )}
                            </Field>
                          </TableCell>
                          <TableCell>
                            <Field name={`other_locations.${index}.pan`}>
                              {({ field }: any) => (
                                <TextField
                                  {...field}
                                  variant="standard"
                                  fullWidth
                                  error={
                                    // @ts-ignore
                                    touched.other_locations?.[index]?.pan &&
                                    // @ts-ignore
                                    !!errors.other_locations?.[index]?.pan
                                  }
                                  helperText={
                                    // @ts-ignore
                                    touched.other_locations?.[index]?.pan &&
                                    // @ts-ignore
                                    errors.other_locations?.[index]?.pan
                                  }
                                />
                              )}
                            </Field>
                          </TableCell>
                          <TableCell>
                            <Field name={`other_locations.${index}.primaryAddress.district`}>
                              {({ field }: any) => (
                                <TextField
                                  {...field}
                                  variant="standard"
                                  fullWidth
                                  error={
                                    // @ts-ignore
                                    touched.other_locations?.[index]?.primaryAddress
                                    
                                      ?.district &&
                                      // @ts-ignore
                                    !!errors.other_locations?.[index]?.primaryAddress
                                      ?.district
                                  }
                                  helperText={
                                    // @ts-ignore
                                    touched.other_locations?.[index]?.primaryAddress
                                   
                                      ?.district &&
                                       // @ts-ignore
                                    errors.other_locations?.[index]?.primaryAddress
                                      ?.district
                                  }
                                />
                              )}
                            </Field>
                          </TableCell>
                          <TableCell>
                            <Field name={`other_locations.${index}.primaryAddress.street`}>
                              {({ field }: any) => (
                                <TextField
                                  {...field}
                                  variant="standard"
                                  fullWidth
                                  error={
                                    // @ts-ignore
                                    touched.other_locations?.[index]?.primaryAddress
                                    
                                      ?.street &&
                                      // @ts-ignore
                                    !!errors.other_locations?.[index]?.primaryAddress
                                      ?.street
                                  }
                                  helperText={
                                    // @ts-ignore
                                    touched.other_locations?.[index]?.primaryAddress
                                      ?.street &&
                                      // @ts-ignore
                                    errors.other_locations?.[index]?.primaryAddress
                                      ?.street
                                  }
                                />
                              )}
                            </Field>
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: "flex", gap: "0.5rem" }}>
                              <IconButton
                                color="primary"
                                onClick={() => {
                                  setLocationEditIndex(index);
                                  locationDataModal.open();
                                  }}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                color="secondary"
                                onClick={() => remove(index)}
                              >
                               <RemoveCircle />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginY: "2rem",
                }}
              >
                <Divider
                  sx={{
                    flex: 1,
                    borderBottomWidth: "1px",
                    borderColor: "#ccc",
                  }}
                />
                <Button
                  type="button"
                  variant="contained"
                  startIcon={<AddCircle />}
                  onClick={() => {
                   
                    // push({
                    //   gstin: "",
                    //   legalName: "",
                    //   pan: "",
                    //   primaryAddress: {
                    //     district: "",
                    //     street: "",
                    //   },
                    //   registered: "",
                    // });
                    addlocationDataModal.open();
                  }}
                >
                  Add Location
                </Button>
                <Divider
                  sx={{
                    flex: 1,
                    borderBottomWidth: "1px",
                    borderColor: "#ccc",
                  }}
                />
              </Box>
            </Box>
          )}
        </FieldArray>

        </AccordionDetails>
            </Accordion>
            </Box>

            {/* Directors/Signatories Section */}
            <Box sx={{ marginTop: '2rem' }}>
            <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{color:"#fff"}} />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{background:"#008080", color:"#fff", borderRadius:"5px", marginBottom:"1rem"}}
            >
              <Typography variant="h6" sx={{ fontWeight: '600', marginBottom: '1rem' }}>
                Directors/Signatories
              </Typography>
              </AccordionSummary>
              <AccordionDetails>
             

              <FieldArray name="directors_signatories">
                {({ push, remove, form }) => (
                <Box>
                    {adddirectorDataModal.isOpen && <AddDirectorModal isOpen={adddirectorDataModal.isOpen} onCloseModal={adddirectorDataModal.close} onSave={(newDirector:any) => {
                 push(newDirector);  // Pass the push function to add the new location
                 }}/>} 
                <TableContainer>
                    <Table>
                    <TableHead>
                        <TableRow>
                        <TableCell sx={{background:"#008080", color:"#fff"}}>DIN</TableCell>
                        <TableCell sx={{background:"#008080", color:"#fff"}}>First Name</TableCell>
                        <TableCell sx={{background:"#008080", color:"#fff"}}>Middle Name</TableCell>
                        <TableCell sx={{background:"#008080", color:"#fff"}}>Last Name</TableCell>
                        <TableCell sx={{background:"#008080", color:"#fff"}}>Appointment Date</TableCell>
                        <TableCell sx={{background:"#008080", color:"#fff"}}>Designation</TableCell>
                        <TableCell sx={{background:"#008080", color:"#fff"}}>Current Signatory</TableCell>
                        <TableCell sx={{background:"#008080", color:"#fff"}}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {form.values.directors_signatories.map((director:any, index:any) => (
                        <TableRow key={index}>
                            <TableCell>
                            <Field name={`directors_signatories.${index}.din`}>
                                {({ field }: any) => (
                                <TextField
                                InputProps={{
                                  readOnly: true,
                                }}
                                    {...field}
                                    variant="standard"
                                    fullWidth
                                    // @ts-ignore
                                    error={form.touched.directors_signatories?.[index]?.din && !!form.errors.directors_signatories?.[index]?.din}
                                    // @ts-ignore
                                    helperText={form.touched.directors_signatories?.[index]?.din && form.errors.directors_signatories?.[index]?.din}
                                />
                                )}
                            </Field>
                            </TableCell>
                            <TableCell>
                            <Field name={`directors_signatories.${index}.firstName`}>
                                {({ field }: any) => (
                                <TextField
                                InputProps={{
                                  readOnly: true,
                                }}
                                    {...field}
                                    variant="standard"
                                    fullWidth
                                    // @ts-ignore
                                    error={form.touched.directors_signatories?.[index]?.firstName && !!form.errors.directors_signatories?.[index]?.firstName}
                                    // @ts-ignore
                                    helperText={form.touched.directors_signatories?.[index]?.firstName && form.errors.directors_signatories?.[index]?.firstName}
                                />
                                )}
                            </Field>
                            </TableCell>
                            <TableCell>
                            <Field name={`directors_signatories.${index}.middleName`}>
                                {({ field }: any) => (
                                <TextField
                                InputProps={{
                                  readOnly: true,
                                }}
                                    {...field}
                                    variant="standard"
                                    fullWidth
                                    // @ts-ignore
                                    error={form.touched.directors_signatories?.[index]?.middleName && !!form.errors.directors_signatories?.[index]?.middleName}
                                    // @ts-ignore
                                    helperText={form.touched.directors_signatories?.[index]?.middleName && form.errors.directors_signatories?.[index]?.middleName}
                                />
                                )}
                            </Field>
                            </TableCell>
                            <TableCell>
                            <Field name={`directors_signatories.${index}.lastName`}>
                                {({ field }: any) => (
                                <TextField
                                InputProps={{
                                  readOnly: true,
                                }}
                                    {...field}
                                    variant="standard"
                                    fullWidth
                                    // @ts-ignore
                                    error={form.touched.directors_signatories?.[index]?.lastName && !!form.errors.directors_signatories?.[index]?.lastName}
                                    // @ts-ignore
                                    helperText={form.touched.directors_signatories?.[index]?.lastName && form.errors.directors_signatories?.[index]?.lastName}
                                />
                                )}
                            </Field>
                            </TableCell>
                            <TableCell>
                            <Field name={`directors_signatories.${index}.appointmentDate`}>
                                {({ field }: any) => (
                                <TextField
                                InputProps={{
                                  readOnly: true,
                                }}
                                    {...field}
                                    variant="standard"
                                    fullWidth
                                    // @ts-ignore
                                    error={form.touched.directors_signatories?.[index]?.appointmentDate && !!form.errors.directors_signatories?.[index]?.appointmentDate}
                                    // @ts-ignore
                                    helperText={form.touched.directors_signatories?.[index]?.appointmentDate && form.errors.directors_signatories?.[index]?.appointmentDate}
                                />
                                )}
                            </Field>
                            </TableCell>
                            <TableCell>
                            <Field name={`directors_signatories.${index}.designation`}>
                                {({ field }: any) => (
                                <TextField
                                InputProps={{
                                  readOnly: true,
                                }}
                                    {...field}
                                    variant="standard"
                                    fullWidth
                                    // @ts-ignore
                                    error={form.touched.directors_signatories?.[index]?.designation && !!form.errors.directors_signatories?.[index]?.designation}
                                    // @ts-ignore
                                    helperText={form.touched.directors_signatories?.[index]?.designation && form.errors.directors_signatories?.[index]?.designation}
                                />
                                )}
                            </Field>
                            </TableCell>
                            <TableCell>
                            <Field name={`directors_signatories.${index}.isCurrentSignatory`}>
                              {({ field }: any) => {
                                // Determine the display value based on the actual value
                                const displayValue = field.value === true || field.value === "true" ? "Yes" : "No";

                                return (
                                  <TextField
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    value={displayValue} // Set the value to the displayValue
                                    variant="standard"
                                    fullWidth
                                    // @ts-ignore
                                    error={form.touched.directors_signatories?.[index]?.isCurrentSignatory && !!form.errors.directors_signatories?.[index]?.isCurrentSignatory}
                                    // @ts-ignore
                                    helperText={form.touched.directors_signatories?.[index]?.isCurrentSignatory && form.errors.directors_signatories?.[index]?.isCurrentSignatory}
                                  />
                                );
                              }}
                            </Field>
                          </TableCell>
                            <TableCell>
                            <IconButton color="primary" onClick={() => {
                                setDirectorIndex(index);
                                directorDataModal.open();
                            }}>
                                <EditIcon />
                            </IconButton>
                            <IconButton color="secondary" onClick={() => remove(index)}>
                                <RemoveCircle />
                            </IconButton>
                            </TableCell>
                        </TableRow>
                        ))}
                    </TableBody>
                    </Table>
                    </TableContainer>

                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginY: "2rem",
                  }}>
                  <Divider
                    sx={{
                      flex: 1,
                      borderBottomWidth: "1px",
                      borderColor: "#ccc",
                    }}
                  />
                  <Button
                        type="button"
                        variant="contained"
                        startIcon={<AddCircle />}
                        onClick={() => adddirectorDataModal.open()}
                    >
                        Add Director
                    </Button>
                  <Divider
                    sx={{
                      flex: 1,
                      borderBottomWidth: "1px",
                      borderColor: "#ccc",
                    }}
                  />
                </Box>


                    
                    </Box>
      )}
              </FieldArray>
              </AccordionDetails>
              </Accordion>
            </Box>

            {/* Charges */}
            <Box sx={{ marginTop: '2rem' }}>
            <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{color:"#fff"}} />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{background:"#008080", color:"#fff", borderRadius:"5px", marginBottom:"1rem"}}
        >
              <Typography variant="h6" sx={{ fontWeight: '600', marginBottom: '1rem' }}>
                Charges
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
        


              <FieldArray name="charges">
                {({ push, remove, form }) => (
                    <Box>
                   {addchargesDataModal.isOpen && <AddChargeModal isOpen={addchargesDataModal.isOpen} onCloseModal={addchargesDataModal.close} onSave={(newCharge:any) => {
                 push(newCharge);  // Pass the push function to add the new location
                 }}/>} 
                    <TableContainer>
                        <Table>
                        <TableHead>
                            <TableRow>
                            <TableCell sx={{background:"#008080", color:"#fff"}}>Charge Holder</TableCell>
                            <TableCell sx={{background:"#008080", color:"#fff"}}>Charge Status</TableCell>
                            <TableCell sx={{background:"#008080", color:"#fff"}}>Amount</TableCell>
                            <TableCell sx={{background:"#008080", color:"#fff"}} align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {form.values.charges.map((charge:any, index:any) => (
                            <TableRow key={index}>
                                <TableCell>
                                <Field name={`charges.${index}.chargeHolder`}>
                                    {({ field }: any) => (
                                    <TextField
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                        {...field}
                                        variant="standard"
                                        fullWidth
                                        //@ts-ignore
                                        error={!!(form.touched.charges?.[index]?.chargeHolder && form.errors.charges?.[index]?.chargeHolder)}
                                        //@ts-ignore
                                        helperText={form.touched.charges?.[index]?.chargeHolder && form.errors.charges?.[index]?.chargeHolder}
                                    />
                                    )}
                                </Field>
                                </TableCell>

                                <TableCell>
                                <Field name={`charges.${index}.chargeStatus`}>
                                    {({ field }: any) => (
                                    <TextField
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                        {...field}
                                        variant="standard"
                                        fullWidth
                                        //@ts-ignore
                                        error={!!(form.touched.charges?.[index]?.chargeStatus && form.errors.charges?.[index]?.chargeStatus)}
                                        //@ts-ignore
                                        helperText={form.touched.charges?.[index]?.chargeStatus && form.errors.charges?.[index]?.chargeStatus}
                                    />
                                    )}
                                </Field>
                                </TableCell>

                                <TableCell>
                                <Field name={`charges.${index}.amount`}>
                                    {({ field }: any) => (
                                    <TextField
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                        {...field}
                                        variant="standard"
                                        fullWidth
                                        //@ts-ignore
                                        error={!!(form.touched.charges?.[index]?.amount && form.errors.charges?.[index]?.amount)}
                                        //@ts-ignore
                                        helperText={form.touched.charges?.[index]?.amount && form.errors.charges?.[index]?.amount}
                                    />
                                    )}
                                </Field>
                                </TableCell>

                                <TableCell align="center">
                                <IconButton
                                    color="primary"
                                    onClick={() => {
                                    setChargesIndex(index);
                                    chargesDataModal.open();
                                    }}
                                >
                                    <EditIcon />
                                </IconButton>

                                <IconButton color="secondary" onClick={() => remove(index)}>
                                    <RemoveCircle />
                                </IconButton>
                                </TableCell>
                            </TableRow>
                            ))}
                        </TableBody>
                        </Table>
                    </TableContainer>

                    <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginY: "2rem",
                  }}>
                  <Divider
                    sx={{
                      flex: 1,
                      borderBottomWidth: "1px",
                      borderColor: "#ccc",
                    }}
                  />
                   {/* Add Charge Button */}
                   <Button
                        type="button"
                        variant="contained"
                        startIcon={<AddCircle />}
                        onClick={() =>
                        addchargesDataModal.open()
                        }
                        sx={{ marginTop: "1rem" }}
                    >
                        Add Charge
                    </Button>
                  <Divider
                    sx={{
                      flex: 1,
                      borderBottomWidth: "1px",
                      borderColor: "#ccc",
                    }}
                  />
                </Box>

                   
                    </Box>
                )}
                </FieldArray>
            </AccordionDetails>
            </Accordion>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop:"1rem" }}>
              <Button type='submit' variant="contained" color="primary" sx={{ position: "sticky", top: "3rem" }} >
                Next
              </Button>
            </Box>
            <Test values={values} errors={errors}/>
          </Box>
        </Form>
      )}
    </Formik>

    </>
  );
}

export default ManualBusinessDataForm;
