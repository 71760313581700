// react
import {
  combineReducers,
  CombinedState,
  AnyAction,
  Action,
  Middleware,
} from "redux";

// vendors
import { ThunkAction } from "redux-thunk";
import { configureStore } from "@reduxjs/toolkit";

import logger from "redux-logger";

// services
import { ADMIN, AUTH, API } from "../Services/index.service";
import {
  PermissionsReducer,
  PermissionsReducerInit,
} from "../Slice/permissions.slice";
import { UserReducer, UserReducerInit } from "../Reducers/user.reducer";
import { ProjectReducer, ProjectReducerInit } from "../Reducers/project.reducer";
import { SubsystemListReducer, SubsystemListReducerInit } from "../Reducers/subsytem.reducer";
import { ImageRepositoryReducer, ImageRepositoryReducerInit } from "../Reducers/imageRepository.reducer";
import { ImageRepositoryProductInfoReducer, ImageRepositoryProductInfoReducerInit } from "../Reducers/imageRepositoryProductInfo.reducer";
import { groupingMomReducer, groupingMomReducerInit } from "../Reducers/groupingMom.reducer";
import { momDataSaveReducer, momDataSaveReducerInit } from "../Reducers/momDaveSave.reducer";
import { momDetailSaveReducer, momDetailSaveReducerInit } from "../Reducers/momDetailSave.reducer";
import compareopenReducer from "../Reducers/compareopen.reducer";
import { GanttChartReducer, GanttChartReducerInit } from "../Reducers/ganttChart.reducers";
import { GanttChartNotesReducer, GanttChartNotesReducerInit } from "../Reducers/GannttChartNotes.reducers";
import PermissionRouteReducer from "../Reducers/PermissionRoute.reducer";
import { CalculatorInputReducer, CalculatorInputReducerInit } from "../Reducers/calculatorInput.reducer";
import {BomCalculatorInputReducer, BomCalculatorInputReducerInit} from "../Reducers/bomCalculatorInput.reducer";
import { ParameterConfigReducer, ParameterConfigReducerInit } from "../Reducers/parameterConfig.reducer";
import { CalculatorReducer, CalculatorReducerInit } from "../Reducers/calculator.reducer";
import MOMPermissionReducer from "../Reducers/MOMPermission.reducer";
import { ArchitectureReducer, archimageRepositoryInit } from "../Reducers/architecture.reducer";
import { groupingMediaCreateReducer, groupingMediaCreateReducerInit } from "../Reducers/groupingMediaCreate.reducer";
import { TiangulationReducerInit, TriangulationReducer } from "../Reducers/triagulation.reducer";
import { UserInputReducer, UserInputReducerInit } from "../Reducers/userInput.reducer";
import { RawMaterialCardReducer, RawMaterialCardReducerInit } from "../Reducers/rawMaterialCard.reducer";
import { InputCalculationReducer, InputCalculationReducerInit } from "../Reducers/inputCalculation.reducer";
import { FlaggedAssumptionReducer, FlaggedAssumptionReducerInit } from "../Reducers/flaggedAssumption.reducer";
import { ParameterReducer, ParameterReducerInit } from "../Reducers/parameter.reducer";
import { ProcessCardReducer, ProcessCardReducerInit } from "../Reducers/processCard.reducer";
import { OverheadCardReducer, OverheadCardReducerInit } from "../Reducers/overheadCard.reducer";
import { VaultReducer, VaultReducerInit } from "../Reducers/vault.reducer";
import { TopVaultReducer, TopVaultReducerInit } from "../Reducers/topVault.reducer";
import { CustomValidationReducer, CustomValidationReducerInit } from "../Reducers/customValidation.reducer";
import { WorkstationReducer, WorkstationReducerInit } from "../Reducers/workstation.reducer";
import { IdeaUserInputReducer, IdeaUserInputReducerInit } from "../Reducers/IdeauserInput.reducer";


// ------------------------------------------------------------------------------------------
// reducers

const reducers = combineReducers({
  permissions: PermissionsReducer,
  user: UserReducer,
  projects: ProjectReducer,
  subsystemlist: SubsystemListReducer,
  imageRepository: ImageRepositoryReducer,
  archimageRepository:ArchitectureReducer,
  imageRepositoryProductInfos: ImageRepositoryProductInfoReducer,
  MomDataSave: momDataSaveReducer,
  momGroup: groupingMomReducer,
  MomDetailSave: momDetailSaveReducer,
  is_compare: compareopenReducer,
  recyclebinCounter: compareopenReducer,
  ganttChart: GanttChartReducer,
  ganttChartNotes: GanttChartNotesReducer,
  add_count: compareopenReducer,
  compare_options: compareopenReducer,
  permission_filter: compareopenReducer,
  selected_userId: compareopenReducer,
  actionCount: compareopenReducer,
  validationCounter:compareopenReducer,
  selcetedCalculators: compareopenReducer,
  allBOMData: compareopenReducer,
  userDirectory_filter: PermissionRouteReducer,
  calculatorInputs: CalculatorInputReducer,
  bomcalculatorInputs: BomCalculatorInputReducer,
  parameterConfigs: ParameterConfigReducer,
  calculators: CalculatorReducer,
  MOMPermission: MOMPermissionReducer,
  userInputs: UserInputReducer,
  IdeauserInputs:IdeaUserInputReducer,
  triangulation: TriangulationReducer,
  inputCalculations: InputCalculationReducer,
  mediaCreateGrouping:groupingMediaCreateReducer,
  rawMaterialCard: RawMaterialCardReducer,
  flaggedAssumptions:FlaggedAssumptionReducer,
  parameters: ParameterReducer,
  processCard: ProcessCardReducer,
  overheadCard: OverheadCardReducer,
  vaults: VaultReducer,
  topVaults: TopVaultReducer,
  customValidations: CustomValidationReducer,
  workstations: WorkstationReducer
  
  
});

export type RootState = ReturnType<typeof reducers>;

const defaultState: RootState = {
  permissions: PermissionsReducerInit,
  flaggedAssumptions:FlaggedAssumptionReducerInit,
  user: UserReducerInit,
  projects: ProjectReducerInit,
  subsystemlist: SubsystemListReducerInit,
  imageRepository: ImageRepositoryReducerInit,
  archimageRepository:archimageRepositoryInit,
  inputCalculations: InputCalculationReducerInit,
  imageRepositoryProductInfos: ImageRepositoryProductInfoReducerInit,
  momGroup: groupingMomReducerInit,
  MomDataSave: momDataSaveReducerInit,
  MomDetailSave: momDetailSaveReducerInit,
  triangulation: TiangulationReducerInit,
  is_compare: "false",
  recyclebinCounter: 1,
  userInputs: UserInputReducerInit,
  IdeauserInputs:IdeaUserInputReducerInit,
  ganttChart: GanttChartReducerInit,
  ganttChartNotes: GanttChartNotesReducerInit,
  parameters: ParameterReducerInit,
  add_count: 0,
  compare_options: [],
  permission_filter: [],
  selected_userId: "",
  actionCount: 0,
  validationCounter:1,
  userDirectory_filter: [],
  calculatorInputs: CalculatorInputReducerInit,
  bomcalculatorInputs: BomCalculatorInputReducerInit,
  parameterConfigs: ParameterConfigReducerInit,
  calculators: CalculatorReducerInit,
  MOMPermission: "",
  mediaCreateGrouping:groupingMediaCreateReducerInit,
  rawMaterialCard: RawMaterialCardReducerInit,
  selcetedCalculators: [],
  allBOMData: [],
  processCard: ProcessCardReducerInit,
  overheadCard: OverheadCardReducerInit,
  vaults: VaultReducerInit,
  topVaults: TopVaultReducerInit,
  customValidations: CustomValidationReducerInit,
  workstations: WorkstationReducerInit

  
};

const rootReducer = (
  state: CombinedState<RootState> | undefined,
  action: AnyAction
) => {
  if (action.type === "RESET_ALL") state = defaultState;

  return reducers(state, action);
};

// ------------------------------------------------------------------------------------------
// services

export interface Services {
  ADMIN: typeof ADMIN;
  AUTH: typeof AUTH;
  API: typeof API;
}
const services = {
  ADMIN,
  AUTH,
  API,
};

// ------------------------------------------------------------------------------------------
// niddleware

let middleware: Middleware[] = [];

if (process.env.NODE_ENV !== "production") {
  middleware = [...middleware, logger];
}

// ------------------------------------------------------------------------------------------
// config function

export const storeConfig = () => {
  return configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        thunk: {
          extraArgument: services,
        },
      }).concat(middleware);
    },
  });
};

// exports
// -------------------------------------------------------------------------------------

export {};

// ------------------------------------------------------------------------------------------
// interfaces

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  Services,
  Action
>;
