import { Box, Button, ButtonProps, Table, TableBody, TableCell, TableHead, TableRow, Typography, IconButton } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { API } from '../../api-services';
import Plotly from 'plotly.js';

import createPlotlyComponent from 'react-plotly.js/factory';
import useWindowDimensions from '../../utlis/useWindowDimensions';
import BouncingLoader from '../../ui-reusable-component/BouncingLoader';

import { DataGrid, GridColDef, GridCsvExportMenuItem, GridCsvExportOptions, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExportContainer, GridValueGetterParams } from "@mui/x-data-grid";


import RViewerJS from "viewerjs-react";
import Skeleton from '@mui/material/Skeleton';
// import style from "material-ui/svg-icons/image/style";
import Image from "../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import { BomImageViewer } from "../BomImageViewer";
import BomCompareImg from "./BomCompareImg";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ReplyIcon from '@mui/icons-material/Reply';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Label,
  LabelList,
  CartesianGrid,
  Legend
} from "recharts";
import { numberFormat } from '../../utlis/valueFormat';
import { black } from 'material-ui/styles/colors';
import { positions } from '@mui/system';
import { useBaseModal } from '../../ComponentUI/SearchPage/useBaseModal';
import RidirectionModal from './RidirectionModal';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import CompareCostingGraphmodal from './CompareCostingGraphmodal';
const renderCustomizedLabel = (props: any) => {
  const { content, ...rest } = props;

  return <Label {...rest} fontSize="12" fill="#FFFFFF" fontWeight="Bold" />;
};




const csvOptions: GridCsvExportOptions = { fileName: 'Drive Table In CSV', delimiter: ",", utf8WithBom: true };

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: '2rem' }}>
    <span style={{ position: 'absolute', right: '0rem' }}>
      <GridToolbarColumnsButton />
      {/* <GridToolbarDensitySelector /> */}
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
)
type Props = {
  counts?:any
}
const Plot = createPlotlyComponent(Plotly);
export default function CostingComparePage({ counts}: Props) {


  const { projectId, moduleType, topVaultId, abbr, NodeId, Count, TopId, Partname, Nodeleaf, depthNode, pathname } = useParams<any>();

  const { height, width } = useWindowDimensions();

  const [AllProductData, setAllProductData] = useState([]);
  const [CostingData, setCostingData] = useState([]);
  const [CostingLoader, setCostingLoader] = useState(false);
  const [CostingGraphData, setCostingGraphData] = useState<any>()
  const [LoaderImagePaagination, setLoaderImagePaagination] = useState(false)
  const [loaderImage, setLoaderImage] = useState(false);
  const [Imageslength, setImageslength] = useState()
  const [PageCount, setPageCount] = useState<any>(1)
  const [expanded, setExpanded] = useState<string | false>(false);
  const [parameterName, setParameterName] = useState<any>([])
  const [analyseCost, setAnalyseCost] = useState<any>([]);
  const [CostingGraphLoader, setCostingGraphLoader] = useState<any>(false)
  const [imagesData, setImagesData] = useState<any>();
  const ridirectionopencosting = useBaseModal();

  const graphModal = useBaseModal();



  const topVaultsParam: any =
    topVaultId && typeof topVaultId == "string"
      ? topVaultId?.split(",")?.map(Number)
      : [topVaultId];

  var filterDataIds = topVaultsParam?.sort(function (a: any, b: any) { return a - b });



  React.useEffect(() => {
    if (projectId) {
      setCostingLoader(true)
      // setLoaderProduct(true);
      API.get("/cost/compare_module/", {
        project: projectId,
        product_list: true,
        module: moduleType,
        depth: depthNode, path: pathname
      })
        .then((res: any) => {
          setAllProductData(res.data);
          setCostingLoader(false)
          // setSelectedTopVaultIds([])
          // setLoaderProduct(false);
        })
        .catch((err: any) => {
          setCostingLoader(false)
          setAllProductData([])
          // setLoaderProduct(false);
          console.log("compare error");
        });
    }
  }, [projectId, Count,counts]);


  var selectedTopVaultData = AllProductData && AllProductData.filter((item: any, index: number) => topVaultsParam.includes(item?.id))

  let OrderData = _.orderBy(selectedTopVaultData, ['id'],
    ['asc', 'desc']);



  let OrderTopvaults = _.orderBy(topVaultsParam, ['asc', 'desc']);




  const togglePartName = (name: any) => {
    if (abbr == "product") {
      return { part_name: "product" }
    } else {
      return { part_name: name }
    }
  }


  const topVaultColor: any = [
    "#075482",
    "#e14958",
    "#fe932b",
    "#64d0dc",
    "#33b1e4",
    "#ffcc67",
    "#8dc73f",
    "#8945aa",
    "#9fd3c7",
    "#385170",
  ];




  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };


  useEffect(() => {
    setCostingLoader(true)
    API.get("/cost/compare_module/", {


      top_vaults: filterDataIds.toString(),
      ...togglePartName(Partname),
      module: moduleType,
      is_leaf: Nodeleaf,
      depth: depthNode, path: pathname

    }).then((res: any) => {
      setCostingData(res?.data)
      setCostingLoader(false)
    }).catch((err: any) => {
      setCostingData([])
      setCostingLoader(false)
    })


  }, [Nodeleaf, NodeId, Count,counts])







  const TableRowCost = (rowitem: any) => {


    var FilterData = rowitem && rowitem?.map((item: any, id: any) => ({
      ...item,
      id: id + 1,
    }));

    return FilterData;
  };


  //   console.log(TableRow() ,"TableRow")

  const TableHeaderCost = (HedaerItem: any) => {



    var dataHeader: any = HedaerItem && Object.keys(HedaerItem)
    dataHeader.pop();

    dataHeader.unshift("Parameter");


    var valuedata = dataHeader &&
      dataHeader?.map((key: any, value: any) => {
        // console.log(key,"checkKey")
        return {
          field: key,
          headerName: key,
          maxWidth: 600,
          minWidth: 80,
          width: 300,
          flex: 1,
          //   headerClassName: styles.Dataheader,

        };
      });

    return valuedata;

  };



  useEffect(() => {
    setLoaderImage(true)
    API.get("/cost/compare_module/", {
      top_vaults: filterDataIds.toString(),
      ...togglePartName(Partname),
      module: 1,
      is_leaf: Nodeleaf,
      depth: depthNode,
      image_length: true,
      path: pathname
    })
      .then((res: any) => {
        setImageslength(res?.data?.image_length);
        setLoaderImage(false)
        // setSelectedTopVaultIds([])
        // setLoaderProduct(false);
      })
      .catch((err: any) => {
        // setLoaderProduct(false);
        console.log("compare error");
        setLoaderImage(false)
      });



  }, [Nodeleaf, NodeId, Count, topVaultId,counts])


  useEffect(() => {
    setLoaderImagePaagination(true)
    API.get("/cost/compare_module/", {
      top_vaults: filterDataIds.toString(),
      ...togglePartName(Partname),
      module: 1,
      is_leaf: Nodeleaf,
      depth: depthNode,
      page: PageCount, path: pathname
    })
      .then((res: any) => {
        setImagesData(res?.data);
        setLoaderImagePaagination(false)

      })
      .catch((err: any) => {
        setImagesData(null)
        console.log("compare error");
        setLoaderImagePaagination(false)
      });



  }, [Nodeleaf, NodeId, PageCount, topVaultId,counts])

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active) {
      let totalLabel = "Total";
      switch (label) {
        case "MC":
          totalLabel = "Material Cost";
          break;
        case "PC":
          totalLabel = "Process Cost";
          break;
        case "OC":
          totalLabel = "Overhead Cost";
          break;
        case "CC":
          totalLabel = "Child Cost";
          break;
        case "Total":
          totalLabel = "Total Cost";
          break;
      }
      let total = 0;
      if (payload?.length > 2) {
        payload
          .filter((data: any) => data.name !== "pv")
          .map((data: any) => {
            total += parseFloat(data.value);
          });
      }






      return (
        <div
          className="custom-tooltip"
          style={{
            zIndex: 2,
            backgroundColor: "white",
            padding: "0.1rem",
            border: "1px solid white",
            fontSize: '0.75rem'
          }}
        >
          {total ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                columnGap: "10px",
                padding: '0.2rem 1rem',
                rowGap: '10px',
                textAlign: 'left',
              }}
            >
              <Typography
                style={{
                  color: "primary.main",
                  textAlign: "left",
                  whiteSpace: "nowrap",
                  lineHeight: '1.2',
                  fontSize: '0.75rem'
                }}
              >{` ${totalLabel} :  `}</Typography>
              <Typography style={{ color: "primary.main", lineHeight: '1.2', fontSize: '0.75rem' }}>
                {totalLabel !== "Total Cost"
                  ? numberFormat(total)
                  : ""}
              </Typography>
            </Box>
          ) : (
            ""
          )}
          {payload
            ?.reverse()
            ?.filter((data: any) => data.name !== "pv" && data.value)
            .map((data: any) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    // justifyContent: "space-between",
                    alignItems: 'center',
                    columnGap: "10px",
                    padding: '0.2rem 1rem',
                    textAlign: 'left',
                    // minWidth: '8rem',
                  }}
                >
                  <Box sx={{ width: '0.75rem', height: '0.75rem', backgroundColor: data.fill }}></Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', minWidth: '10rem' }}>
                    <Typography
                      style={{
                        color: 'black',
                        textAlign: "left",
                        whiteSpace: "nowrap",
                        lineHeight: '1.2',
                        fontSize: '0.75rem'
                      }}
                    >{`${data.name} : `}</Typography>
                    <Typography style={{ color: "black", lineHeight: '1.2', fontSize: '0.75rem' }}>
                      {numberFormat(data.value)}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
        </div>
      );
    }

    return null;
  };



  useEffect(() => {
    // setCostLoader(true);
    API.get(
      `/cost/analysis/`,
      { vehicle_ids: topVaultId, analysis_type: "cost" },
      0
    )
      .then((res: any) => {
        setAnalyseCost(res?.data);
        // setCostLoader(false);
      })
      .catch((err: any) => {
        console.log(err, "error");
      });
  }, [projectId, topVaultId]);




  // console.log(CostingGraphData, "CostingGraphData")

  var ImageLengthData = Imageslength && Imageslength;
  const ImgPaginationHandler = (btnType: any) => {

    if (btnType == "next") {
      setPageCount(PageCount + 1)
    }

    if (btnType == "prev") {
      setPageCount(PageCount - 1)
    }
  }
  const Redirection = (item: any) => {

    console.log(item,"itemitemitem")
    API.get("/cost/compare_redirect/", { top_vaults: item, part_name: Partname, is_leaf: Nodeleaf, depth: depthNode, path: pathname }).then((res: any) => {
      // sessionStorage.setItem("redirect_url",);
      sessionStorage.setItem("node", JSON.stringify(res.data?.root_nodes_id));
      if (Object.keys(res?.data)?.length > 0) {

        window.open(
          `/#/view/${res.data?.project}/${res.data?.top_vault}/${res.data?.vault}/${res.data?.abbreviation}/costing`,
          "_blank",
          " noopener"
        );
      }
      else {
        // riderictionPopup.current=true



        ridirectionopencosting.open()



      }
      // history.push(`/view/${res.data?.project}/${res.data?.top_vault}/${res.data?.vault}/${res.data?.abbreviation}/true`)
    })


  }



  const OpenGraphModal = (item: any) => {

    graphModal.open()


    setCostingGraphLoader(true)
    API.get("/cost/compare_module/", {


      top_vaults: filterDataIds.toString(),
      ...togglePartName(Partname),
      module: moduleType,
      is_leaf: Nodeleaf,
      depth: depthNode,
      costing_graph: true, path: pathname,
      key: item,
      colors: topVaultColor
        ?.slice(0, topVaultId && topVaultId.length > 1 ? topVaultId?.split(",").length : filterDataIds.length)
        ?.toString(),

    }).then((res: any) => {
      setCostingGraphData(res?.data)
      setCostingGraphLoader(false)
      // console.log(res.data, "resdata").
    }).catch((err: any) => {

      setCostingGraphLoader(false)
    })
    setParameterName(item)

  }

  const color: any = [
    "#dd6b4d",
    "#ffce76",
    "#716f96",
    "#2b82bf",
    "#003d68",
    "#eaba51",
    "#ff9d9a",
    "#d3d3d3",
    "#a0cbe8",
    "#4e79a7",
    "#d9b364",
    "#e5cd99",
    "#c7e5e3",
    "#91cdc7",
    "#58b4ac",
    "#00688b",
    "#00bfff",
    "#ffa500",
    "#cd6600",
  ];

  const renderColorfulLegendText = (value: string, entry: any) => {

    return <span style={{ color: "#000000" }}>{value}</span>;
  };






  const renderCustomizedTotalCostLabel = (props: any) => {
    const { x, y, value } = props;
    return (
      <text
        x={x}
        y={y}
        dy={-4}
        dx={50}
        fontSize="0.8rem"
        fontFamily="sans-serif"
        fill={"black"}
        textAnchor="middle"
        textRendering="center"
      >
        ₹{value}
      </text>
    );
  };

  





  return (
    <Box sx={{ height: "87vh", }}>
      <RidirectionModal
        isOpen={ridirectionopencosting.isOpen}
        onCloseModal={ridirectionopencosting.close}


      />

      <CompareCostingGraphmodal
        Open={graphModal.isOpen}
        Close={graphModal.close}
        parameterGraphData={CostingGraphData && CostingGraphData}
        ParameterNames={parameterName && parameterName}
        CostingGraphLoader={CostingGraphLoader}
      />
      {LoaderImagePaagination ?
        <Box sx={{ width: '100%', padding: '0 0.5rem', height: '12rem' }}>
          <Skeleton sx={{ height: "12rem", width: "100%" }} />
        </Box>
        :
        <Table>
          <TableHead sx={{ backgroundColor: 'primary.light', padding: '0 1rem' }} >
            <TableRow>
              <TableCell sx={{ padding: "0rem 0.5rem", paddingLeft: "2rem", width: OrderData?.length == 5 ? '16vw' : OrderData?.length == 4 ? '15vw' : OrderData?.length == 3 ? '20vw' : OrderData?.length == 2 ? "auto" : OrderData?.length == 1 ? '25vw' : '30vw', borderBottom: 'none', }}>Costing Comparison</TableCell>
              {/* <TableCell sx={{ padding: "0rem 0.5rem", width: '4rem', borderBottom: 'none', }}></TableCell> */}
              {OrderData && OrderData?.map((item: any, index: any) => {
                return (<>
                  <TableCell sx={{ padding: "0rem 0.5rem", width: OrderData?.length == 5 ? '16vw' : OrderData?.length == 4 ? '15vw' : OrderData?.length == 3 ? '20vw' : OrderData?.length == 2 ? "auto" : OrderData?.length == 1 ? '25vw' : '30vw', borderBottom: 'none', }} align="center">
                    <Box sx={{ display: 'flex', alignItems: "center", }}>
                      <Typography style={{ fontSize: '1rem', width: '100%', textAlign: 'center' }}>{item?.name} ({item?.product_id})</Typography>
                      <ReplyIcon
                        sx={{ fontSize: "1rem", transform: "scaleX(-1)", cursor: 'pointer' }}
                        onClick={() => Redirection(item?.id)}
                        titleAccess="Go to Costing"
                      />
                    </Box>
                  </TableCell>
                </>)
              })}
              {/* <TableCell sx={{ padding: "0rem 0.5rem", width: '2rem', borderBottom: 'none', }}>&nbsp;</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ padding: "0rem 0.5rem", borderBottom: 'none', width: '100%' }}></TableCell>
              <TableCell colSpan={OrderData?.length} sx={{ padding: "0rem 0.5rem", borderBottom: 'none', width: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <IconButton style={{ cursor: "pointer", position: 'relative', left: '15px' }} disabled={PageCount > 1 ? false : true} onClick={() => ImgPaginationHandler("prev")}>
                    <ArrowBackIosIcon />
                  </IconButton>
                  {/* @ts-ignore */}
                  <RViewerJS>
                    {/* @ts-ignore */}
                    <span title={imagesData && imagesData?.parameter}>
                      {/* @ts-ignore */}
                      {imagesData && imagesData?.data?.map((item: any, index: any) => {
                        {/* @ts-ignore */ }
                        return (<><BomCompareImg ImgItem={item} topVaultIds={OrderData?.length} viweimg={imagesData && imagesData?.parameter} />              </>)
                      })}
                    </span>
                  </RViewerJS>
                  <IconButton style={{ cursor: "pointer", position: 'absolute', right: '15px' }}
                    // @ts-ignore
                    disabled={PageCount <= ImageLengthData - 1 ? false : true}
                    onClick={() => ImgPaginationHandler("next")}>
                    <ArrowForwardIosIcon style={{ cursor: "pointer" }} />
                  </IconButton>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      }



      {CostingLoader ? <Box sx={{ height: { lg: "64vh", xl: '65.5vh' } }}
        adl-scrollbar="true"
        adl-scrollbar-width="0.3">
        <Skeleton sx={{ height: "3rem", width: "100%" }} />
        <Skeleton sx={{ height: "3rem", width: "100%" }} />
        <Skeleton sx={{ height: "3rem", width: "100%" }} />
        <Skeleton sx={{ height: "3rem", width: "100%" }} />
        <Skeleton sx={{ height: "3rem", width: "100%" }} />
      </Box> :
        <Box sx={{ height: { lg: "64vh", xl: '65.5vh', padding: '0 1rem' } }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3">
          <Table sx={{ padding: '0 1rem' }}>
            <TableHead sx={{ backgroundColor: 'primary.main', position: 'sricky', top: '0', }}>
              <TableRow>
                <TableCell sx={{ padding: '0rem 0.5rem', color: 'white', borderBottomColor: 'primary.light' }}>Parameter</TableCell>
                <TableCell sx={{ padding: '0rem 0.5rem', color: 'white', borderBottomColor: 'primary.light',textAlign:'right', }}>Unit</TableCell>
                {OrderData && OrderData?.map((item: any, index: any) => {
                  return (<>
                    <TableCell sx={{ padding: '0rem 0.5rem', color: 'white', borderBottomColor: 'primary.light' }} align="right">
                      <Typography style={{ fontSize: '1rem' }}>    {item?.name} ({item?.product_id})</Typography>
                    </TableCell>
                  </>)
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              
              {CostingData && CostingData?.map((costItem: any, index: any) => {
                return (<>
                  {costItem?.name == "Material" ?
                    <TableRow>
                      <TableCell sx={{ padding: '0rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '5px', justifyContent: 'space-between' }}>
                          {costItem?.name}
                          <EqualizerIcon style={{ cursor: "pointer" }} onClick={() => OpenGraphModal(costItem?.name)} />
                        </Box>
                      </TableCell>
                      {costItem?.data?.map((costInnerData: any, inx: any) => {
                        return (<>
                          <TableCell sx={{ padding: '0rem 0.5rem', borderBottomColor: 'primary.light', textAlign: 'right' }}>{costInnerData}</TableCell>
                        </>)
                      })}
                    </TableRow>
                    : costItem?.name == "Raw Material" ?
                      <TableRow>
                        <TableCell sx={{ padding: '0rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '5px', justifyContent: 'space-between' }}>
                            <Typography style={{ padding: '0', lineHeight: '1', paddingLeft: '1rem', fontSize: '1rem' }}> {costItem?.name}</Typography>
                            <EqualizerIcon style={{ cursor: "pointer" }} titleAccess={"Graph"} onClick={() => OpenGraphModal(costItem?.name)} />
                          </Box>
                        </TableCell>
                        {costItem?.data?.map((costInnerData: any, inx: any) => {
                          return (<>
                            <TableCell sx={{ padding: '0rem 0.5rem', borderBottomColor: 'primary.light', textAlign: 'right' }}>{costInnerData}</TableCell>
                          </>)
                        })}
                      </TableRow> : costItem?.name == "Bop" ?
                        <TableRow>
                          <TableCell sx={{ padding: '0rem 0.5rem', borderBottomColor: 'primary.light' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '5px', justifyContent: 'space-between' }}>
                              <Typography style={{ padding: '0', lineHeight: '1', paddingLeft: '1rem', fontSize: '1rem' }}>{costItem?.name}</Typography>
                              <EqualizerIcon style={{ cursor: "pointer" }} titleAccess={"Graph"} onClick={() => OpenGraphModal(costItem?.name)} />
                            </Box>
                          </TableCell>
                          {costItem?.data?.map((costInnerData: any, inx: any) => {
                            return (<>
                              <TableCell sx={{ padding: '0rem 0.5rem', borderBottomColor: 'primary.light', textAlign: 'right' }}>{costInnerData}</TableCell>
                            </>)
                          })}
                        </TableRow> : costItem?.name == "Process" ?
                          <TableRow>
                            <TableCell sx={{ padding: '0rem 0.5rem', borderBottomColor: 'primary.light' }}>
                              <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '5px', justifyContent: 'space-between' }}>
                                {costItem?.name}
                                <EqualizerIcon style={{ cursor: "pointer" }} titleAccess={"Graph"} onClick={() => OpenGraphModal(costItem?.name)} />
                              </Box>
                            </TableCell>
                            {costItem?.data?.map((costInnerData: any, inx: any) => {
                              return (<>
                                <TableCell sx={{ padding: '0rem 0.5rem', borderBottomColor: 'primary.light', textAlign: 'right' }}>{costInnerData}</TableCell>
                              </>)
                            })}
                          </TableRow> :


                          costItem?.name == "Labour" ?
                            <TableRow>
                              <TableCell sx={{ padding: '0rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '5px', justifyContent: 'space-between' }}>
                                  <Typography style={{ padding: '0', lineHeight: '1', paddingLeft: '1rem', fontSize: '1rem' }}> {costItem?.name}</Typography>
                                  <EqualizerIcon style={{ cursor: "pointer" }} titleAccess={"Graph"} onClick={() => OpenGraphModal(costItem?.name)} />
                                </Box>
                              </TableCell>
                              {costItem?.data?.map((costInnerData: any, inx: any) => {
                                return (<>
                                  <TableCell sx={{ padding: '0rem 0.5rem', borderBottomColor: 'primary.light', textAlign: 'right' }}>{costInnerData}</TableCell>
                                </>)
                              })}
                            </TableRow> :
                            costItem?.name == "Machine" ?
                              <TableRow>
                                <TableCell sx={{ padding: '0rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                  <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '5px', justifyContent: 'space-between' }}>
                                    <Typography style={{ padding: '0', lineHeight: '1', paddingLeft: '1rem', fontSize: '1rem' }}>{costItem?.name}</Typography>
                                    <EqualizerIcon style={{ cursor: "pointer" }} titleAccess={"Graph"} onClick={() => OpenGraphModal(costItem?.name)} />
                                  </Box>
                                </TableCell>
                                {costItem?.data?.map((costInnerData: any, inx: any) => {
                                  return (<>
                                    <TableCell sx={{ padding: '0rem 0.5rem', borderBottomColor: 'primary.light', textAlign: 'right' }}>{costInnerData}</TableCell>
                                  </>)
                                })}
                              </TableRow> : costItem?.name == "Tool" ?
                                <TableRow>
                                  <TableCell sx={{ padding: '0rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '5px', justifyContent: 'space-between' }}>
                                      <Typography style={{ padding: '0', lineHeight: '1', paddingLeft: '1rem', fontSize: '1rem' }}>{costItem?.name}</Typography>
                                      <EqualizerIcon style={{ cursor: "pointer" }} titleAccess={"Graph"} onClick={() => OpenGraphModal(costItem?.name)} />
                                    </Box>
                                  </TableCell>
                                  {costItem?.data?.map((costInnerData: any, inx: any) => {
                                    return (<>
                                      <TableCell sx={{ padding: '0rem 0.5rem', borderBottomColor: 'primary.light', textAlign: 'right' }}>{costInnerData}</TableCell>
                                    </>)
                                  })}
                                </TableRow> :

                                costItem?.name == "Setup" ?
                                  <TableRow>
                                    <TableCell sx={{ padding: '0rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                      <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '5px', justifyContent: 'space-between' }}>
                                        <Typography style={{ padding: '0', lineHeight: '1', paddingLeft: '1rem', fontSize: '1rem' }}> {costItem?.name}</Typography>
                                        <EqualizerIcon style={{ cursor: "pointer" }} titleAccess={"Graph"} onClick={() => OpenGraphModal(costItem?.name)} />
                                      </Box>
                                    </TableCell>
                                    {costItem?.data?.map((costInnerData: any, inx: any) => {
                                      return (<>
                                        <TableCell sx={{ padding: '0rem 0.5rem', borderBottomColor: 'primary.light', textAlign: 'right' }}>{costInnerData}</TableCell>
                                      </>)
                                    })}
                                  </TableRow> :

                                  <TableRow>
                                    <TableCell sx={{ padding: '0rem 0.5rem', borderBottomColor: 'primary.light' }}>
                                      <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '5px', justifyContent: 'space-between' }}>
                                        {costItem?.name}
                                        <EqualizerIcon style={{ cursor: "pointer" }} titleAccess={"Graph"} onClick={() => OpenGraphModal(costItem?.name)} />
                                      </Box>
                                    </TableCell>
                                    {costItem?.data?.map((costInnerData: any, inx: any) => {
                                      return (<>
                                        <TableCell sx={{ padding: '0rem 0.5rem', borderBottomColor: 'primary.light', textAlign: 'right' }}>{costInnerData}</TableCell>
                                      </>)
                                    })}
                                  </TableRow>
                  }
                </>

                )
              })}
            </TableBody>
          </Table>
          {/* <Box sx={{ width: "100%", padding: '0 1rem', }}>
          {CostingData && CostingData?.length > 0 &&
            <DataGrid
              rows={TableRowCost(CostingData && CostingData)}
              columns={TableHeaderCost(CostingData && CostingData[0])}
              components={{ Toolbar: () => { return CustomToolbar() } }}
              density="compact"
              autoHeight={true}
              disableSelectionOnClick
              hideFooterPagination={true}
              hideFooter={true}
            />
          }
        </Box> */}
          {/* @ts-ignore */}
        </Box>}
    </Box>
  )
}