import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import OtherIdeaDetailsModal from "./OtherIdeaDetailsModal";
import * as yup from "yup";
import { API } from "../../../api-services";
import { ADMIN } from "../../../Redux/Services/admin.service";
import { useFormik } from "formik";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import Swal from "sweetalert2";
import styles from "../IdeaCreateLandingpage.module.scss";
import { IdeaTypesList } from "../../../utlis/Idea.constant";
import { MODULES } from "../../../Constants/modules.constants";
import newIdeaService from "../../../Redux/Services/newIdea.service";
import { set, uniqBy } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { parameterConfigSelectors } from "../../../Redux/Reducers/parameterConfig.reducer";
import { getCompetitorVaultsList } from "../../../Redux/Actions/vault.actions";
import { useBaseParamsModal } from "../../../CustomHook/useBaseParamsModal";
import { useHistory } from "react-router-dom";
import { on } from "devextreme/events";

export interface IBOMFormProps {
  subSystem: any;
  onCloseModal: any;
  isOpen: any;
  ideaOrigin: any;
  subSystemId?: any;
  getTableData?: any;
  data?: any;
  getUpdatedIdeaList?:any;
}

interface FilmOptionType {
  title: string;
  year: number;
}

const ideaLevelOptions: any = [
  {
    key: 1,
    text: "Easy",
    value: 1,
  },
  {
    key: 2,
    text: "Medium",
    value: 2,
  },
  {
    key: 3,
    text: "Complex",
    value: 3,
  },
];
const durationOptions = [
  {
    key: 1,
    text: "24 Hrs",
    value: 1,
  },
  {
    key: 2,
    text: "48 Hrs",
    value: 2,
  },
  {
    key: 3,
    text: "72 Hrs",
    value: 3,
  },
];
const validationSchema = yup.object({
  origin: yup.mixed(),
  // .required("Please enter your Origin"),
  base: yup.mixed().required("Please enter Base Vault"),
  idea_type: yup.mixed().required("Please enter Idea Type"),
  reference: yup.mixed(),
  parameter: yup.mixed(),
  unit: yup.mixed(),
  competitor_model: yup.mixed().required("Please enter Competitor Model"),
  competitor: yup.mixed().required("Please enter Competitor Vault"),
  duration: yup.mixed().required("Please enter Implemetation time"),
  ease:  yup.number().min(0, "Minimum atleast 0")
  .max(10, "Allowed maximum is 10").required("Please enter Implemetation ease"),
  owner: yup.mixed(),
  idea_level:yup.mixed().required("Please enter your Idea Level"),
  duration_level: yup.mixed(),
  category: yup.mixed()
});

export default function BOMForm(props: IBOMFormProps) {
  const history = useHistory();
  
  const [ideaOrigin, setIdeaOrigin] = React.useState("");
  const [owner, setOwner] = React.useState<any>([]);
  const dispatch = useDispatch();
  const { subSystemId,topVault,projectId ,projectName} = useRouteParams<any>();
  const [baseDropDown, setbaseDropDown] = React.useState<any>([]);
  const [competitorOptions, setCompetitorOptions] = React.useState<any>([]);
  const [competitorVaultOptions, setCompetitorVaultOptions] = React.useState<any>([]);
  const [allParams,setAllParams] =React.useState<any>([]);
  const configs = useSelector( parameterConfigSelectors.selectAll );
  const { onCloseModal, isOpen, subSystem, getTableData, data, getUpdatedIdeaList } = props;
  const [buttonDisable, setButtonDisable] = React.useState(true);
  const [value, setValue] = React.useState<FilmOptionType | null>(null);

// console.log(window.location.href.split("/")[7],  "window.location.pathname.split(/)[5]");
const system = window.location.href.split("/")[7];
  const OtherIdeaModal = useBaseParamsModal();
  const OtherIdeaFormHandler = (ideaId:any) => {
    OtherIdeaModal.open(ideaId);
  };


  const getFilteredConfigOptions = () => {
    API.get("/bom/parameter_mapping/",{
      top_vault:topVault,
      module:6,
      all:true,
      idea_create:true
    },0).then((res:any)=>{
      setAllParams(res.data?.map( (config:any) => ( {
        key: config.parameter?.id,
        value: config.parameter?.id,
        text: config.parameter?.name,
        unit: config.parameter?.unit
    } ) ))
    }).catch((err:any)=>{

    })
};

React.useEffect(()=>{
  getFilteredConfigOptions()
},[])

const fetchCompetitorVaults = async ( modelId: any, origin: number ) => {
  if ( !modelId ) return;
  let actions = dispatch( getCompetitorVaultsList( { top_vault: modelId, abbreviation: subSystem !== "VEH"?subSystem:"VEH", origin: origin } ) );
  //@ts-ignore
  
  const data: any = ( ( await actions ).payload );
  const competitorVaultsOption = data.vaults.map( ( el: any ) => {
      const partText = ( el.part_no ) ? '(' + el.part_no + ')' : "";
      return {
          id: el.id,
          text: el.name + "  " + partText,
          value: el.id
      };
  } );
  setCompetitorVaultOptions( competitorVaultsOption );
};

React.useEffect(() => {
  API.get("/xcpep/top_vault/", {
    project: projectId,
    module: MODULES.IDEA_GENERATION,
    subSystem,
  }).then((res) => {
    const { data } = res;
    const competitorData: any =
      data &&
      data
        .filter((obj: any) => obj.idea_base == false)
        .map((obj: any) => ({ id: obj.id, value: obj.id, text: obj.name }));
    const permissionData: any =
      data &&
      data?.filter((obj: any) => obj.id == topVault)[0]?.allowed_action;
    setCompetitorOptions([...competitorData]);
  });
}, []);

const getOwnerOptions = () => {
  API.get(
    "/auth/idea_user_list/",
    {
      project: projectId,
    },
    0
  )
    .then((res: any) => {
      if (res) {
        setOwner(
          res?.data.map((item: any, index: number) => ({
            key: item.id,
            text: item.username,
            value: item.id,
          }))
        );
      }
    })
    .catch((err: any) => {});
};
React.useEffect(() => {
  getOwnerOptions();
}, []);

  const formik = useFormik({
    initialValues: {
      base: null,
      origin: "",
      idea_type: null,
      feature_type: 0,
      category: null,
      reference: null,
      parameter: null,
      unit: "",
      sub_system: 0,
      cost: 0,
      weight: 0,
      competitor_model: null,
      competitor: null,
      duration: 0,
      ease: 0,
      owner: null,
      idea_level: "",
      duration_level: "",
      feature_param_type: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      let obj = {
        base: values.base?.value,
        origin: 1,
        //@ts-ignore
        idea_type: values.idea_type?.value,
        //@ts-ignore
        category: values.category?.value,
        //@ts-ignore
        reference: values.reference?.value,
        //@ts-ignore
        parameter: values.parameter?.value,
        unit: values.unit,
        sub_system: values.sub_system,
        cost: values.cost,
        weight: values.weight,
        //@ts-ignore
        competitor_model: values.competitor_model?.value,
        //@ts-ignore
        competitor: values.competitor?.value,
        duration: values.duration,
        ease: values.ease,
        owner: values.owner,
        idea_level: values.idea_level,
        duration_level: values.duration_level,
        //@ts-ignore
      };
      
      API.post("/idea/pool/", obj, {}, 0)
        .then((res: any) => {
          ADMIN.toast.info("Idea Added Successfully");
          if(res.data?.id){
            OtherIdeaFormHandler(res.data?.id);
          }
          
          // onCloseModal();
          // console.log(window.location.href.split("/")[4]);
          if(window.location.href.split("/")[4] == "idea-generation-view"){
          history.push(`/idea-generation-view/${projectId}/${projectName}/${topVault}/${subSystem}/${subSystemId}/0/0/${subSystemId}/0/0/${res.data?.id}/${res.data?.idea_id}`)
          }else{
          history.push(`/idea-generation/${projectId}/${projectName}/${topVault}/${system}/${res.data?.id}/${res.data?.idea_id}`)
          }
        })
        .catch((err: any) => {
          const { data } = err.response;
          Swal.fire({
            title: "Error!",
            text: data[0],
            icon: "error",
            confirmButtonText: "Ok",
            customClass: {
              container: "swal2Container",
            },
          });
        });
    },
  });
   
  const fetchChildNodeData = async (ideaVal: any) => {
    let res;
    typeof subSystemId !== "undefined"
      ? (res = await newIdeaService.getTree(ideaVal, MODULES.IDEA_GENERATION))
      : (res = await newIdeaService.getTree(
          ideaVal,
          MODULES.IDEA_GENERATION,
          "idea"
        ));
    const baseData = res.data?.map((obj: any) => ({
      id: obj.id,
      value: obj.id,
      text: `${obj.name} (${obj.part_no})`,
    }));
    if (typeof data?.id !== "undefined") {
      if (baseData.length === 0) {
        baseData.push({
          id: 0,
          value: data?.id,
          text: `${data?.name} (${data?.part_no}) `,
        });
      }
      baseData.unshift({
        id: 0,
        value: data?.id,
        text: `${data?.name} (${data?.part_no}) `,
      });
      setbaseDropDown([...baseData]);
    } else {
      setbaseDropDown([...baseData]);
    }
  };

  React.useEffect(() => {
    fetchChildNodeData(data?.id);
  }, [data]);


  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
      {OtherIdeaModal.isOpen && (
        <OtherIdeaDetailsModal
          onCloseModal={OtherIdeaModal.close}
          isOpen={OtherIdeaModal.open}
          subSystem={subSystem}
          newIdeaId={OtherIdeaModal.propsId}
          data={data}
          closeParentModal={onCloseModal}
          getTableData={getTableData}
          getUpdatedIdeaList={getUpdatedIdeaList}
        />
      )}

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          width: "100%",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <FormControl
          fullWidth
          variant="standard"
          
          sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
        >
          <InputLabel
            sx={{ color: "primary.main" }}
            shrink
            id="demo-simple-select-label"
          >
            Idea Type<sup style={{color:"red", marginTop:"0.5rem", marginLeft:"0.4rem"}}>*</sup>
          </InputLabel>
          <Autocomplete
            id="idea_type"
            autoSelect
            getOptionLabel={(option: any) => option.text}
            value={formik.values.idea_type}
            onChange={(event: any, newValue: any | null) => {
              formik.setFieldValue("idea_type", newValue);
            }}
            options={IdeaTypesList}
            sx={{ marginTop: "1.4rem" }}
            renderOption={(props, option) => (
              <li {...props} style={{ fontSize: '1rem' }}>
                {option.text}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                name="idea_type"
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "primary.light",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                  ".MuiInputBase-root.MuiInput-root": {
                    fontSize: "1rem !important",
                  },
                  ".MuiSvgIcon-root": { color: "primary.main" },
                }}
              />
            )}
          />
          <div className={styles.errormsg}>
            {formik.touched.idea_type && Boolean(formik.errors.idea_type) && (
              //@ts-ignore
              <p>{formik.errors.idea_type}</p>
            )}
          </div>
        </FormControl>
        <FormControl 
          sx={{ ".MuiFormLabel-asterisk": { color: "red" } }} fullWidth variant="standard">
          <InputLabel
            sx={{ color: "primary.main" }}
            shrink
            id="demo-simple-select-label"
          >
            Base Vault ID & Name:<sup style={{color:"red", marginTop:"0.5rem", marginLeft:"0.4rem"}}>*</sup>
          </InputLabel>
          <Autocomplete
            
            id="base"
            getOptionLabel={(option: any) => option.text}
            options={baseDropDown}
            value={formik.values.base}
            onChange={(event: any, newValue: any | null) => {
              formik.setFieldValue("base", newValue);
           }}
            autoSelect
            sx={{ marginTop: "1.4rem" }}
            renderOption={(props, option) => (
              <li {...props} style={{ fontSize: '1rem' }}>
                {option.text}
              </li>
            )}
            renderInput={(params) => (
              <TextField
              name="base"
                {...params}
                variant="standard"
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "primary.light",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                  ".MuiInputBase-root.MuiInput-root": {
                    fontSize: "1rem !important",
                  },
                  ".MuiSvgIcon-root": { color: "primary.main" },
                }}
              />
            )}
          />
          <div className={styles.errormsg}>
            {formik.touched.base && Boolean(formik.errors.base) && (
              //@ts-ignore
              <p>{formik.errors.base}</p>
            )}
          </div>
        </FormControl>
        <FormControl 
          sx={{ ".MuiFormLabel-asterisk": { color: "red" } }} fullWidth variant="standard">
          <InputLabel
            sx={{ color: "primary.main" }}
            shrink
            id="demo-simple-select-label"
          >
            BOM Parameter:<sup style={{color:"red", marginTop:"0.5rem", marginLeft:"0.4rem"}}>*</sup>
          </InputLabel>
          <Autocomplete
            id="parameter"
            getOptionLabel={(option: any) => option.text}
            options={allParams && allParams}
            value={formik.values.parameter}
            onChange={(event: any, newValue: any | null) => {
              formik.setFieldValue("parameter", newValue);
              formik.setFieldValue("unit",newValue?.unit)
           }}
            autoSelect
            sx={{ marginTop: "1.4rem" }}
            renderOption={(props, option) => (
              <li {...props} style={{ fontSize: '1rem' }}>
                {option.text}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                name="parameter"
                variant="standard"
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "primary.light",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                  ".MuiInputBase-root.MuiInput-root": {
                    fontSize: "1rem !important",
                  },
                  ".MuiSvgIcon-root": { color: "primary.main" },
                }}
              />
            )}
          />
          <div className={styles.errormsg}>
            {formik.touched.parameter && Boolean(formik.errors.parameter) && (
              //@ts-ignore
              <p>{formik.errors.parameter}</p>
            )}
          </div>
        </FormControl>
        <FormControl fullWidth variant="standard">
          <TextField
            variant="standard"
            label="Unit"
            disabled={true}
            value={formik.values.unit}
            // autoFocus={autoFocusStatus}
            InputProps={{
              style: { color: "#007fff" },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              "& .Mui-error": {
                color: "#d32f2f",
                borderBottomColor: "#d32f2f",
              },
              "& .MuiInput-underline:before": {
                borderBottomColor: "primary.light",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "primary.main",
              },
              "& .MuiFormLabel-root.Mui-focused": {
                color: "primary.main",
              },
              "& .MuiFormLabel-root.MuiInputLabel-root ": {
                color: "primary.main",
              },
              ".MuiInputBase-root.MuiInput-root": {
                fontSize: "1rem !important",
              },
            }}
            id="unit"
            name="unit"
          />
        </FormControl>
      </Box>
      {/* <Box sx={{ width: "100%", marginBottom: "1rem" }}>
        <FormControlLabel
          control={<Checkbox defaultChecked />}
          label="Competitor not available"
        />
      </Box> */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          width: "100%",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <FormControl
        
            sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
            fullWidth
            variant="standard"
          >
            <InputLabel
              sx={{ color: "primary.main" }}
              shrink
              id="demo-simple-select-label"
            >
              Competitor Models<sup style={{color:"red", marginTop:"0.5rem", marginLeft:"0.4rem"}}>*</sup>
            </InputLabel>
            <Autocomplete
              id="competitor_model"
              options={competitorOptions}
              getOptionLabel={(option: any) => option.text}
              value={formik.values.competitor_model}
              onChange={(event: any, newValue: any | null) => {
                 formik.setFieldValue("competitor_model", newValue);
                 //@ts-ignore
                //  fetchBaseImageNumber( newValue?.value,formik.values.category.value, true)
                 fetchCompetitorVaults(newValue.value,1)
                //  fetchCompetitorVaults( newValue?.value, 2 );
              }}
              autoSelect
              sx={{ marginTop: "1.4rem" }}
              renderOption={(props, option) => (
                <li {...props} style={{ fontSize: '1rem' }}>
                  {option.text}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  name="competitor_model"
                  {...params}
                  variant="standard"
                  sx={{
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                    ".MuiInputBase-root.MuiInput-root": {
                      fontSize: "1rem !important",
                    },
                    ".MuiSvgIcon-root": { color: "primary.main" },
                  }}
                />
              )}
            />
            <div className={styles.errormsg}>
            {formik.touched.competitor_model && Boolean(formik.errors.competitor_model) && (
              //@ts-ignore
              <p>{formik.errors.competitor_model}</p>
            )}
          </div>
          </FormControl>
        <FormControl
          
          sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
          fullWidth
          variant="standard"
        >
          <InputLabel
            sx={{ color: "primary.main" }}
            shrink
            id="demo-simple-select-label"
          >
            Competitor Vaults<sup style={{color:"red", marginTop:"0.5rem", marginLeft:"0.4rem"}}>*</sup>
          </InputLabel>
          <Autocomplete
            
            id="competitor"
            options={competitorVaultOptions}
            value={formik.values.competitor}
            getOptionLabel={(option: any) => option.text}
            autoSelect
            onChange={(event: any, newValue: any | null) => {
              formik.setFieldValue("competitor", newValue);
              
           }}
            sx={{ marginTop: "1.4rem" }}
            renderOption={(props, option) => (
              <li {...props} style={{ fontSize: '1rem' }}>
                {option.text}
              </li>
            )}
            renderInput={(params) => (
              <TextField
              name="competitor"
                {...params}
                variant="standard"
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "primary.light",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                  ".MuiInputBase-root.MuiInput-root": {
                    fontSize: "1rem !important",
                  },
                  ".MuiSvgIcon-root": { color: "primary.main" },
                }}
              />
            )}
          />
          <div className={styles.errormsg}>
            {formik.touched.competitor && Boolean(formik.errors.competitor) && (
              //@ts-ignore
              <p>{formik.errors.competitor}</p>
            )}
          </div>
        </FormControl>
        <FormControl
        
            
            fullWidth
            variant="standard"
          >
            <TextField
            required
              variant="standard"
              label="Implementation Time (In Days)"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
                ".MuiFormLabel-asterisk": { color: "red" } 
              }}
              id="duration"
              name="duration"
              value={formik.values.duration}
              onChange={formik.handleChange}
              error={formik.touched.duration && Boolean(formik.errors.duration)}
              //@ts-ignore
              helperText={formik.touched.duration && formik.errors.duration}
            />
            <div className={styles.errormsg}>
            {formik.touched.duration && Boolean(formik.errors.duration) && (
              //@ts-ignore
              <p>{formik.errors.duration}</p>
            )}
          </div>
          </FormControl>
          <FormControl
          
            fullWidth
            variant="standard"
          >
            <TextField
            required
              variant="standard"
              label="Implementation Ease (1-Easy to 10-difficult)"
              value={formik.values.ease}
              onChange={formik.handleChange}
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
                ".MuiFormLabel-asterisk": { color: "red" }
              }}
              id="ease"
              name="ease"
              error={formik.touched.ease && Boolean(formik.errors.ease)}
              //@ts-ignore
              helperText={formik.touched.ease && formik.errors.ease}
            />
            <div className={styles.errormsg}>
            {formik.touched.ease && Boolean(formik.errors.ease) && (
              //@ts-ignore
              <p>{formik.errors.ease}</p>
            )}
          </div>
          </FormControl>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          columnGap: "1rem",
          margin: "1rem 0",
        }}
      >
        <FormControl fullWidth variant="standard">
            <InputLabel
              sx={{ color: "primary.main" }}
              shrink
              id="demo-simple-select-label"
            >
              Name
            </InputLabel>
            <Select
              id="owner"
              name="owner"
              labelId="demo-select-small"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                  {
                    fontSize: "1rem",
                  },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
              MenuProps={{
                style: {
                  maxHeight: 400,
                  color: "007fff",
                },
              }}
              value={formik.values.owner}
              onChange={formik.handleChange}
            >
              {owner &&
                owner?.map((item: any, index: any) => {
                  return (
                    <MenuItem
                      sx={{ color: "primary.main", fontSize: "1rem" }}
                      value={item?.value}
                    >
                      {item?.text}
                    </MenuItem>
                  );
                })}
            </Select>
            <div className={styles.errormsg}>
              {formik.touched.owner && Boolean(formik.errors.owner) && (
                //@ts-ignore
                <p>{formik.errors.owner}</p>
              )}
            </div>
          </FormControl>
          <FormControl
          
            sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
            fullWidth
            variant="standard"
          >
            <InputLabel
              sx={{ color: "primary.main" }}
              shrink
              id="demo-simple-select-label"
            >
              Idea Level<sup style={{color:"red", marginTop:"0.5rem", marginLeft:"0.4rem"}}>*</sup>
            </InputLabel>
            <Select
              id="idea_level"
              name="idea_level"
              labelId="demo-select-small"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                  {
                    fontSize: "1rem",
                  },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
              MenuProps={{
                style: {
                  maxHeight: 400,
                  color: "007fff",
                },
              }}
              value={formik.values.idea_level}
              onChange={(event: any) => {
                const { value } = event.target;
                formik.setFieldValue("idea_level", value);
                formik.setFieldValue("duration_level", value);
              }}
            >
              {ideaLevelOptions &&
                ideaLevelOptions?.map((item: any) => {
                  return (
                    <MenuItem
                      sx={{ color: "primary.main", fontSize: "1rem" }}
                      value={item?.value}
                    >
                      {item?.text}
                    </MenuItem>
                  );
                })}
            </Select>
            <div className={styles.errormsg}>
              {formik.touched.idea_level &&
                Boolean(formik.errors.idea_level) && (
                  //@ts-ignore
                  <p>{formik.errors.idea_level}</p>
                )}
            </div>
          </FormControl>
          <FormControl
            sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
            fullWidth
            variant="standard"
          >
            <InputLabel
              sx={{ color: "primary.main" }}
              shrink
              id="demo-simple-select-label"
            >
              Duration
            </InputLabel>
            <Select
              id="duration_level"
              name="duration_level"
              labelId="demo-select-small"
              disabled={true}
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                  {
                    fontSize: "1rem",
                  },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
              MenuProps={{
                style: {
                  maxHeight: 400,
                  color: "007fff",
                },
              }}
              value={formik.values.duration_level}
            >
              {durationOptions &&
                durationOptions?.map((item: any) => {
                  return (
                    <MenuItem
                      sx={{ color: "primary.main", fontSize: "1rem" }}
                      value={item?.value}
                    >
                      {item?.text}
                    </MenuItem>
                  );
                })}
            </Select>
            <div className={styles.errormsg}>
              {formik.touched.duration_level &&
                Boolean(formik.errors.duration_level) && (
                  //@ts-ignore
                  <p>{formik.errors.duration_level}</p>
                )}
            </div>
          </FormControl>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          columnGap: "1rem",
          marginBottom: "0.5rem",
        }}
      >
        <Button variant="contained" size="small" onClick={onCloseModal}>
          Close
        </Button>
        <Button
          variant="contained"
          size="small"
          type="submit"
          disabled={formik.values.base === null || formik.values.idea_type === null || formik.values.competitor_model === null || formik.values.competitor === null || formik.values.duration < 0 || formik.values.ease < 1 || formik.values.ease > 10  || formik.values.idea_level === "" || formik.values.parameter === null || formik.values.unit === "" }
        >
          Next
        </Button>
      </Box>
      </form>
    </div>
  );
}
