import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  Backdrop,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { API } from "../../api-services";
import { useEffect, useState } from "react";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { UserRoleEdit } from "./UserRoleEdit";
import DeleteIcon from "@mui/icons-material/Delete";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "25vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 0,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IPartCountDetailsModalProps {
  isOpen: any;
  onCloseModal: any;
  data: any;
  getParentTableData: any;
}

function ShareDetailsModal(props: IPartCountDetailsModalProps) {
  const { isOpen, onCloseModal, data, getParentTableData } = props;
  const { topVault, vault } = useRouteParams<any>();
  const [tableData, setTableData] = useState<any>([]);

  const getObj = () => {
    if (vault == 0) {
      return { top_vault: topVault };
    } else {
      return { vault: vault };
    }
  };

  const getTableData = () => {
    API.get(
      "/cost/scenario_share/",
      {
        scenario: data.id,
      },
      0
    )
      .then((res: any) => {
        setTableData(res?.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTableData();
  }, [isOpen === true]);

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Shared Details
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              // marginBottom: "1rem",
            }}
          />
          <Box
            sx={{
              maxHeight: "80vh",
              padding: " 1rem",
            }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: "primary.light" }}>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    User Name
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Select Role
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                      textAlign:'center'
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData &&
                  tableData?.map((item: any, index: any) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          {item?.user_name}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <UserRoleEdit data={item} idd={data?.id} rowData={data}/>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                            textAlign:'center'
                          }}
                        >
                          <DeleteIcon
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              API.delete(
                                `/cost/scenario_share/${item.id}`,
                                {},
                                0
                              )
                                .then((res: any) => {
                                  getParentTableData();
                                  getTableData();
                                })
                                .catch((err: any) => {});
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
export default ShareDetailsModal;
