

// ---------------------------------------------------------------------------------

import { ID } from "../../utlis/commonInterfaces";
import { SHARING_TYPES } from "../../utlis/sharing.constants";
import { API } from "./index.service";

export interface ShareCustomValidationArg {
    targetTopVaultIds: ID[];
    categoryId: ID;
    vehCateId: ID;
}

// ---------------------------------------------------------------------------------

const shareUrl = "/bom/share_config/";

// ---------------------------------------------------------------------------------

class validations {
    getAllValidations = ( categoryId: ID, vehCateId: ID,) => { 
        // topVaultId: ID,
        return API.get("/bom/custom_validation/", {
            category: categoryId,
            vehicle_category: vehCateId,
        },0);
    };

    createValidation = (validation: any) => {
        return API.post("/config/template_validation/", validation);
    };

    editValidation = (validation: any) => {
        return API.put(
            "/config/template_validation/" + validation.id + "/",
            validation
        );
    };

    deleteValidation = (validationID: any) => {
        return API.delete("/config/template_validation/" + validationID + "/");
    };

    share(arg: ShareCustomValidationArg) {
        return API.post(shareUrl, {
            category: arg.categoryId,
            vehicle_category: arg.vehCateId,
            target_top_vaults: arg.targetTopVaultIds,
            type: SHARING_TYPES.CUSTOM_VALIDATION,
        });
    }
}

const VALIDATIONS = new validations();

export { VALIDATIONS };
