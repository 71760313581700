import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import {
	Divider,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

export interface ISubscriptionHistoryModalProps {
	isOpen: boolean;
	onCloseModal: () => void;
	subHistory: any;
}

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '80vw',
	height: 'auto',
	maxHeight: '90vh',
	bgcolor: 'background.default',
	borderRadius: '1rem',
	p: 1,
	focusVisible: 'none !important',
};

export default function SubscriptionHistoryModal(
	props: ISubscriptionHistoryModalProps
) {
	const { isOpen, onCloseModal, subHistory } = props;

	console.log(subHistory, 'subHistory');

	return (
		<div>
			<Modal
				open={isOpen}
				onClose={onCloseModal}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'>
				<Box sx={style}>
					<Box sx={{ width: '100%' }}>
						<Box
							sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
							<Typography
								style={{
									fontSize: '1rem',
									fontWeight: '600',
									width: '100%',
									textAlign: 'center',
								}}>
								User Subscription History
							</Typography>
							<CancelIcon
								sx={{
									cursor: 'pointer',
									marginLeft: '-2rem',
								}}
								onClick={onCloseModal}
							/>
						</Box>
						<Divider
							sx={{
								borderColor: 'primary.light',
								margin: '0 1rem',
								marginBottom: '1rem',
							}}
						/>
						<Box>
							<Table
								stickyHeader
								aria-label='sticky table'
								adl-scrollbar='true'
								adl-scrollbar-width='0.3'>
								<TableHead>
									<TableRow>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												backgroundColor: 'primary.light',
											}}>
											S.No
										</TableCell>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												backgroundColor: 'primary.light',
											}}>
											Order Id
										</TableCell>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												backgroundColor: 'primary.light',
											}}>
											Payment Date
										</TableCell>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												backgroundColor: 'primary.light',
											}}>
											Subscription Type
										</TableCell>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												backgroundColor: 'primary.light',
											}}>
											Purpose
										</TableCell>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												backgroundColor: 'primary.light',
											}}>
											Tax Id
										</TableCell>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												backgroundColor: 'primary.light',
											}}>
											Amount Paid
										</TableCell>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												backgroundColor: 'primary.light',
											}}>
											Amount in INR
										</TableCell>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												backgroundColor: 'primary.light',
											}}>
											Currency
										</TableCell>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												backgroundColor: 'primary.light',
											}}>
											Status
										</TableCell>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												backgroundColor: 'primary.light',
											}}>
											Invoice
										</TableCell>
									</TableRow>
								</TableHead>
								{subHistory.length > 0 ? (
									<TableBody>
										{subHistory.map((sub: any, index: number) => (
											<TableRow key={index}>
												<TableCell
													sx={{
														padding: '0.2rem 0.5rem',
														borderBottomColor: 'primary.light',
													}}>
													{index + 1}
												</TableCell>
												<TableCell
													sx={{
														padding: '0.2rem 0.5rem',
														borderBottomColor: 'primary.light',
													}}>
													{sub.order_id}
												</TableCell>
												<TableCell
													sx={{
														padding: '0.2rem 0.5rem',
														borderBottomColor: 'primary.light',
													}}>
													{sub.payment_status_updated_at}
												</TableCell>
												<TableCell
													sx={{
														padding: '0.2rem 0.5rem',
														borderBottomColor: 'primary.light',
													}}>
													{sub.subscription_type}
												</TableCell>
												<TableCell
													sx={{
														padding: '0.2rem 0.5rem',
														borderBottomColor: 'primary.light',
													}}>
													{sub.purpose}
												</TableCell>
												<TableCell
													sx={{
														padding: '0.2rem 0.5rem',
														borderBottomColor: 'primary.light',
													}}>
													{sub.tax_id}
												</TableCell>
												<TableCell
													sx={{
														padding: '0.2rem 0.5rem',
														borderBottomColor: 'primary.light',
													}}>
													{sub.amount_paid}
												</TableCell>
												<TableCell
													sx={{
														padding: '0.2rem 0.5rem',
														borderBottomColor: 'primary.light',
													}}>
													{sub.subscription_amount_in_inr}
												</TableCell>
												<TableCell
													sx={{
														padding: '0.2rem 0.5rem',
														borderBottomColor: 'primary.light',
													}}>
													{sub.currency}
												</TableCell>
												<TableCell
													sx={{
														padding: '0.2rem 0.5rem',
														borderBottomColor: 'primary.light',
													}}>
													{sub.payment_status}
												</TableCell>
												<TableCell
													sx={{
														padding: '0.2rem 0.5rem',
														borderBottomColor: 'primary.light',
													}}>
													{sub.invoice}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								) : (
									<TableBody>
										<TableRow>
											<TableCell
												colSpan={11}
												sx={{
													padding: '0.2rem 0.5rem',
													borderBottomColor: 'primary.light',
													textAlign: 'center',
												}}>
												No Data Found
											</TableCell>
										</TableRow>
									</TableBody>
								)}
							</Table>
						</Box>
					</Box>
				</Box>
			</Modal>
		</div>
	);
}
