import * as React from 'react';
import Modal from '@mui/material/Modal';
import { Box, Button, Divider, FormControl, TextField, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useState } from 'react';



interface IPartRejctReasonModelProps {
  onCloseModal: () => void;
  isOpen: boolean;
  partdetails: any;
  setComment: (comment: any) => void; // Correctly define setComment as a function
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '45vw',
    bgcolor: 'background.paper',    
    borderRadius: '10px',
    p: 1,
};



export default function PartRejctReasonModel (props: IPartRejctReasonModelProps) {
    const { onCloseModal, isOpen, partdetails, setComment } = props; 
    
    const [comment, setLocalComment] = useState<string>('');

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();     
      setComment(comment); // Pass comment back to parent
      onCloseModal(); // Close the modal
  };

   

  return (
    <div>
      <Modal
        open={isOpen} onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "1rem",
              marginBottom: "0.5rem",             
            }}
          >
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Part Reject Reason
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor:'primary.light',
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ padding: '1rem', minHeight: '5rem' }}>
          <form onSubmit={handleSubmit}>
              <FormControl fullWidth>
                  <TextField
                      multiline
                      rows={4}
                      fullWidth
                      autoFocus={true}
                      label="Enter Reason for Rejection"
                      variant="outlined"
                      value={comment}
                      onChange={(e) => setLocalComment(e.target.value)}
                  />
              </FormControl>
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                  <Button size='small' variant='contained' type='submit'>Submit</Button>
              </Box>
          </form>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
