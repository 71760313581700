import React, { useEffect, useState } from 'react';
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Paper,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItemButton,
  ListItemText,
  IconButton,
  useTheme,
  useMediaQuery,
  Skeleton,
  Chip,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { API } from '../../api-services';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import { useHistory } from 'react-router-dom';

interface BuyerMailItem {
  id: number;
  user_name: string;
  to_mails: string[];
  cc_mails: string[];
  subject: string;
  mail_body: string;
  mail_created_at: string;
  attachment: string[];
  mail_type: string[];
  part: string;
  supplier: string;
}

interface MailType {
  mail_type: string;
  mail_type_key: number;
  count: number;
}

const MailViewer: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const { mailType, typeId, mailId, selectedMailId } = useRouteParams<any>();
  const [tabs, setTabs] = useState<MailType[]>([]);
  const [selectedTabType, setSelectedTabType] = useState<number>(0);
  const [selectedTab, setSelectedTab] = useState<string>('Seller');
  const [mailsdata, setMailsData] = useState<any[]>([]);
  const [treeData, setTreeData] = useState<any[]>([]);
  const [selectedMail, setSelectedMail] = useState<BuyerMailItem | null>(null);
  const [expandedAccordionId, setExpandedAccordionId] = useState<string | null>(null);
  const [expandedAccordionIdParent, setExpandedAccordionIdParent] = useState<string | null | any>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const typeTabs = ['Seller', 'Buyer', 'Part'];

  // React.useEffect(()=>{
  //   setExpandedAccordionId(null)
  //   setExpandedAccordionIdParent(null)
  // },[typeId])
  // React.useEffect(()=>{
  //   setExpandedAccordionIdParent(null)
  // },[mailType])

  const getPayloadObj = () => {
    switch (typeId) {
      case 'Seller':
        return { supplier_list: true };
      case 'Buyer':
        return { user_list: true };
      case 'Part':
        return { part_list: true };
      default:
        return {};
    }
  };

  const fetchTreeData = async (parentId?: string) => {
    try {
      const response = await API.get('/auth/email_log_view/', {
        mail_type_key: mailType,
        ...getPayloadObj(),
      },0);
      return response.data;
    } catch (error) {
      console.error('Error fetching tree data:', error);
      return [];
    }
  };

  const getMailsDataPayload=(parentId:any)=>{
    switch (typeId) {
      case 'Seller':
        return { supplier_id: parentId };
      case 'Buyer':
        return { user_id: parentId };
      case 'Part':
        return { part_id: parentId };
      default:
        return {};
    }
  }

  const getMailsData = async (parentId: any) => {
    try {
      const response = await API.get('/auth/email_log_view/', {
        mail_type_key: selectedTabType,
        ...getMailsDataPayload(parentId)
      },0);
      return response.data;
    } catch (error) {
      console.error('Error fetching mails data:', error);
      return [];
    }
  };

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        setLoading(true);
        const treeResponse = await fetchTreeData();
        setTreeData(treeResponse);
        const tabsResponse = await API.get('/auth/email_log_view/', { get_module_count: true });
        setTabs(tabsResponse.data);
        if (mailType === '0') {
          history.push(`/maillogs/${tabsResponse.data[0]?.mail_type_key}/${typeId}/${mailId}/${0}`);
          setSelectedTabType(tabsResponse.data[0]?.mail_type_key || 0);
        } else {
          setSelectedTabType(mailType || 1);
        }
      } catch (error) {
        console.error('Error loading initial data:', error);
      } finally {
        setLoading(false);
      }
    };

    loadInitialData();
  }, [mailType,typeId]);
  

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    history.push(`/maillogs/${selectedTabType}/${newValue}/${mailId}/0`);
    setSelectedTab(newValue);
    setSelectedMail(null); // Clear selection on tab change
  };

  const handleTabChangeType = (event: React.SyntheticEvent, newValue: number) => {
    history.push(`/maillogs/${newValue}/${typeId}/${mailId}/0`);
    setSelectedTabType(newValue);
    // Fetch and update tree data based on the new tab selection
    fetchTreeData().then(data => setTreeData(data));
  };

  const handleAccordionToggle = async (accordionId: string) => {
    history.push(`/maillogs/${selectedTabType}/${typeId}/${accordionId}/${0}`);
    setExpandedAccordionId((prevId) => (prevId === accordionId ? null : accordionId));
    if (expandedAccordionId !== accordionId) {
      const childData = await getMailsData(accordionId);
      setTreeData((prevData) =>
        prevData.map((item) =>
          item.id.toString() === accordionId ? { ...item, children: childData } : item
        )
      );
    }
  };

  const handleAccordionToggleParent = async (accordionId: string) => {
    history.push(`/maillogs/${accordionId}/${typeId}/${mailId}/${0}`);
    setExpandedAccordionIdParent((prevId:any) => (prevId === accordionId ? null : accordionId));
    if (expandedAccordionIdParent !== accordionId) {

    }
  };

  const handleMailSelect = (mailItem: BuyerMailItem) => {
    history.push(`/maillogs/${selectedTabType}/${typeId}/${mailId}/${mailItem.id}`);
    setSelectedMail(mailItem);
  };

  const renderAccordionItems = (items: any[]) =>
    items.map((item) => (
      <Accordion
        key={item.id}
        expanded={expandedAccordionId === item.id.toString()}
        onChange={() => handleAccordionToggle(item.id.toString())}
        sx={{
          boxShadow: 'none', // Remove shadow
          marginBottom: '8px',
          '&:before': {
            display: 'none', // Remove default border
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            padding: '8px 16px', // Adjust padding
            borderRadius: '4px',
            '& .MuiAccordionSummary-content': {
              marginLeft: '0', // Align text with icon
            },
            '& .MuiAccordionSummary-expandIcon': {
              order: -1, // Move icon to the left
            },
          }}
        >
          <ListItemText primary={item.user_name || item.name} />
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: '8px 16px', // Adjust padding
          }}
        >
          <Box>
            <Table>
            <TableHead>
                            <TableRow sx={{
                               backgroundColor: "primary.light",
                               color: "Primary.main",
                               width: "100%",
                               textAlign: "left",
                            }}>
                              <TableCell sx={{textAlign:'left'}}>Part</TableCell>
                              <TableCell sx={{textAlign:'left'}}>Seller</TableCell>
                              <TableCell sx={{textAlign:'left'}}>Subject</TableCell>
                              <TableCell sx={{textAlign:'left'}}>Created At</TableCell>

                            </TableRow>
                          </TableHead>
            </Table>
            {item.children && item.children.map((child: any) => (
              <ListItemButton
                key={child.id}
                onClick={() => handleMailSelect(child)}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '8px 16px',
                  borderRadius: '4px',
                  backgroundColor: selectedMail?.id === child.id ? '#e0f7fa' : 'inherit',
                  '&:hover': {
                    backgroundColor: '#e0f7fa',
                  },
                }}
              >
                <ListItemText
                  primary={
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem' }}>
                      {/* <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Part:</Typography>
                      <Typography variant="body2">{child.part}</Typography>
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Seller:</Typography>
                      <Typography variant="body2">{child.supplier}</Typography>
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Subject:</Typography>
                      <Typography variant="body2">{child.subject}</Typography>
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Created At:</Typography>
                      <Typography variant="body2">{new Date(child.mail_created_at).toLocaleString()}</Typography> */}
                          
                          <TableBody>
                            <TableRow>
                              <TableCell>{child.part}</TableCell>
                              <TableCell>{child.supplier}</TableCell>
                              <TableCell>{child.subject}</TableCell>
                              <TableCell>{new Date(child.mail_created_at).toLocaleString()}</TableCell>
                            </TableRow>
                          </TableBody>
                    </Box>
                  }
                />
                <IconButton edge="end" onClick={() => handleMailSelect(child)}>
                  <VisibilityIcon />
                </IconButton>
              </ListItemButton>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
    ));

  return (
    <Box sx={{ display: 'flex', height: '100vh', backgroundColor: '#f9f9f9' }}>
      {/* Left Side Section */}
      <Box
        sx={{
          width: isMobile ? '100%' : '40%',
          borderRight: '1px solid #ddd',
          backgroundColor: '#ffffff',
          overflowY: 'auto',
          boxShadow: '2px 0 4px rgba(0,0,0,0.1)',
        }}
      >
        <Paper square>
          <Tabs
            value={typeId}
            onChange={handleTabChange}
            aria-label="Mail type tabs"
            sx={{ borderBottom: '1px solid #ddd' }}
          >
            {typeTabs.map((tab) => (
              <Tab key={tab} label={tab} value={tab} />
            ))}
          </Tabs>
        </Paper>
        <Divider />
        <Paper
          square
          sx={{ padding: '16px', height: 'calc(100vh - 64px)', overflowY: 'auto' }}
        >
          {loading ? (
            <Skeleton variant="rectangular" height={400} />
          ) : (
            tabs && tabs?.map((item:any,index:number)=>{
              return <Accordion
              key={item.mail_type_key}
              expanded={expandedAccordionIdParent === item.mail_type_key.toString()}
              onChange={() => handleAccordionToggleParent(item.mail_type_key.toString())}
              sx={{
                backgroundColor: '#ffffff',
                borderRadius: '8px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                marginBottom: '8px',
                '&:before': {
                  display: 'none',
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  backgroundColor: '#d3e3fc',
                  borderBottom: '1px solid #ddd',
                  borderRadius: '8px 8px 0 0',
                  '&:hover': {
                    backgroundColor: '#b0d4fc',
                  },
                }}
              >
                <ListItemText primary={`${item?.mail_type}( ${item?.count} )`} />
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ paddingLeft: '16px', paddingBottom: '8px' }}>
                  {renderAccordionItems(treeData)}
                </Box>
              </AccordionDetails>
            </Accordion>
            })
          
          )}
        </Paper>
      </Box>

      {/* Right Side Section */}
      <Box
        sx={{
          width: isMobile ? '100%' : '60%',
          padding: '16px',
          overflowY: 'auto',
          backgroundColor: '#ffffff',
          boxShadow: '-2px 0 4px rgba(0,0,0,0.1)',
        }}
      >
        {selectedMail ? (
         <Paper sx={{ padding: 2, backgroundColor: '#ffffff', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
         <Box sx={{ padding: "0 2rem", height: "75vh", overflowY: "auto", }}>
         <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#00887a',marginTop:'0.5rem' }}>{`${selectedMail?.part} to ${selectedMail?.supplier}`}</Typography>
         <Divider sx={{ marginY: 2 }} />
         <Box mb={3}>
           <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
             <Typography sx={{ width: "6rem", fontWeight: 500 }}>To :</Typography>
             <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
               {selectedMail?.to_mails?.map((item:any, index:number) => (
                 <Chip
                   key={index}
                   label={item}
                   variant="outlined"
                   size="small"
                   sx={{ borderColor: "primary.main" }}
                 />
               ))}
             </Box>
           </Box>
           <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
             <Typography sx={{ width: "6rem", fontWeight: 500 }}>Cc :</Typography>
             <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
               {selectedMail?.cc_mails?.map((item:any, index:number) => (
                 <Chip
                   key={index}
                   label={item}
                   variant="outlined"
                   size="small"
                   sx={{ borderColor: "primary.main" }}
                 />
               ))}
             </Box>
           </Box>
           <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
             <Typography sx={{ width: "6rem", fontWeight: 500 }}>Subject :</Typography>
             <Typography sx={{ fontWeight: 400 }}>{selectedMail?.subject}</Typography>
           </Box>
         </Box>
         <Box sx={{ border: "1px solid #e0e0e0", borderRadius: 2, p: 2 }}>
           <div dangerouslySetInnerHTML={{ __html: selectedMail?.mail_body }}></div>
         </Box>
       </Box>
       </Paper>
        ) : (
          <Typography variant="h6" color="textSecondary">
            Select a mail item to view details.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default MailViewer;