import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import styles from "../AddRevisionModal.module.scss";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";

export interface IElectronicChildPartsFormProps {
  actions: any;
  onCloseModal: any;
  getCategoryData: any;
  category: any;
}
const validationSchema = yup.object({
  name: yup.string().required("Please type Item name"),
  description: yup.string().required("Please type Item description"),
  length: yup.mixed().required("Please type Length"),
  width: yup.mixed().required("Please type Width"),
  unit: yup.mixed(),
  component_type: yup.mixed().required("Please type Component Type"),
  reference: yup.string().required("Please type Item reference"),
  unit_cost: yup.mixed().required("Please enter per unit cost"),
});
const useStyles = makeStyles((theme) => ({
  select: {
    "&.MuiInputBase-root-MuiInput-root-MuiSelect-root": {
      borderBottom: "1px solid red",
    },
  },
}));
export function ElectronicChildPartsForm(
  props: IElectronicChildPartsFormProps
) {
  const { actions, onCloseModal, getCategoryData, category } = props;
  const [isLoadingsave, setIsLoadingSave] = React.useState<any>(false);
  const { categoryName, databaseName, revisionId } = useRouteParams<any>();
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      length: "",
      width: "",
      unit: "",
      component_type: "",
      reference: "",
      unit_cost: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsLoadingSave(true);

      if (actions === "add") {
        API.post(
          "/db/bought_out/",
          {
            ...values,
            ...{ organisation: 1, commodity_category_instance: category },
          },
          {},
          0
        )
          .then((res: any) => {
            if (!databaseName?.includes("DB")) {
              API.post(
                "/db/bought_out_cost/",
                {
                  purchase_items: [res.data.id],
                  revision: revisionId,
                },
                {},
                0
              )
                .then((res: any) => {
                  setIsLoadingSave(false);
                  getCategoryData(categoryName);
                  onCloseModal();
                })
                .catch((err: any) => {
                  setIsLoadingSave(false);
                });
            } else {
              setIsLoadingSave(false);
              getCategoryData(categoryName);
              onCloseModal();
            }
          })
          .catch((err: any) => {
            setIsLoadingSave(false);
          });
      } else {
        API.put(
          `/db/bought_out/${actions?.id}/`,
          {
            ...values,
            ...{
              organisation: 1,
              commodity_category_instance: actions?.commodity_name,
            },
          },
          {},
          0
        )
          .then((res: any) => {
            onCloseModal();
            getCategoryData(categoryName);
            setIsLoadingSave(false);
          })
          .catch((err: any) => {
            setIsLoadingSave(false);
          });
      }
    },
  });
  React.useEffect(() => {
    if (actions !== "add") {
      formik.setFieldValue("name", actions?.name || "");
      formik.setFieldValue("description", actions?.description || "");
      formik.setFieldValue("length", actions?.length || "");
      formik.setFieldValue("width", actions?.width || "");
      formik.setFieldValue("unit", actions?.unit || "");
      formik.setFieldValue("component_type", actions?.component_type || "");
      formik.setFieldValue("reference", actions?.reference || "");
      formik.setFieldValue(
        "unit_cost",
        parseFloat(actions.unit_cost?.slice(1)) || ""
      );
    }
  }, [actions]);
  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "1rem",
            marginBottom: "0.5rem",
          }}
        >
          <FormControl fullWidth variant="standard">
            <InputLabel
              sx={{ color: "primary.main" }}
              shrink
              id="demo-simple-select-label"
            >
              Select Item name{" "}
            </InputLabel>

            <Select
              id="name"
              name="name"
              className={classes.select}
              labelId="demo-select-small"
              // id="demo-select-small"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                  {
                    fontSize: "1rem",
                  },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
              error={formik.touched.name && Boolean(formik.errors.name)}
              //@ts-ignore
              helperText={formik.touched.name && formik.errors.name}
              MenuProps={{
                style: {
                  maxHeight: 400,
                  color: "007fff",
                },
              }}
              onChange={formik.handleChange}
              value={formik.values.name !== null ? formik.values.name : ""}
            >
              {/* <option value={""} style={{ display: "none" }}></option> */}
              {[
                "Resistor",
                "Capacitor",
                "IC",
                "Transistor",
                "Diodes",
                "Inductor",
                "Transformer Items",
                "Wires",
                "Others",
              ].map((item: any, index: any) => {
                return (
                  <MenuItem
                    sx={{ color: "primary.main", fontSize: "1rem" }}
                    key={index}
                    value={item}
                  >
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
            <div className={styles.errormsg}>
              {formik.touched.name && Boolean(formik.errors.name) && (
                <p>{formik.errors.name}</p>
              )}
            </div>
          </FormControl>
          <FormControl fullWidth variant="standard">
            <TextField
              variant="standard"
              label="Descriptions"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="description"
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
              multiline
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "1rem",
            marginBottom: "0.5rem",
          }}
        >
          <FormControl fullWidth variant="standard">
            <TextField
              variant="standard"
              label="Width"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="width"
              name="width"
              onChange={formik.handleChange}
              value={formik.values.width}
              type="number"
              error={formik.touched.width && Boolean(formik.errors.width)}
              helperText={formik.touched.width && formik.errors.width}
            />
          </FormControl>
          <FormControl fullWidth variant="standard">
            <TextField
              variant="standard"
              label="Length"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="length"
              name="length"
              onChange={formik.handleChange}
              value={formik.values.length}
              type="number"
              error={formik.touched.length && Boolean(formik.errors.length)}
              helperText={formik.touched.length && formik.errors.length}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "1rem",
            marginBottom: "0.5rem",
          }}
        >
          <FormControl fullWidth variant="standard">
            <TextField
              variant="standard"
              label="Size Unit"
              multiline
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="unit"
              name="unit"
              onChange={formik.handleChange}
              value={formik.values.unit}
              error={formik.touched.unit && Boolean(formik.errors.unit)}
              helperText={formik.touched.unit && formik.errors.unit}
            />
          </FormControl>
          <FormControl fullWidth variant="standard">
            <TextField
              variant="standard"
              label="Component Type"
              multiline
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="component_type"
              name="component_type"
              onChange={formik.handleChange}
              value={formik.values.component_type}
              error={
                formik.touched.component_type &&
                Boolean(formik.errors.component_type)
              }
              helperText={
                formik.touched.component_type && formik.errors.component_type
              }
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "1rem",
            marginBottom: "0.5rem",
          }}
        >
          <FormControl fullWidth variant="standard">
            <TextField
              variant="standard"
              label="Source"
              multiline
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="reference"
              name="reference"
              onChange={formik.handleChange}
              value={formik.values.reference}
              error={
                formik.touched.reference && Boolean(formik.errors.reference)
              }
              helperText={formik.touched.reference && formik.errors.reference}
            />
          </FormControl>
          <FormControl fullWidth variant="standard">
            <TextField
              variant="standard"
              label="Per unit cost (INR)"
              type="number"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="unit_cost"
              name="unit_cost"
              onChange={formik.handleChange}
              value={formik.values.unit_cost}
              error={
                formik.touched.unit_cost && Boolean(formik.errors.unit_cost)
              }
              helperText={formik.touched.unit_cost && formik.errors.unit_cost}
            />
          </FormControl>
        </Box>
        <Box sx={{ width: "100%", textAlign: "right" }}>
          <LoadingButton
            size="small"
            loading={isLoadingsave}
            variant="contained"
            type="submit"
            sx={{ marginTop: "1rem" }}
          >
            {actions === "add" ? "Add" : "Save"}
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
}
