import * as React from "react";
import { Box, CircularProgress, InputBase, Skeleton } from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import SearchIcon from "@mui/icons-material/Search";
import library from "../../Assets/images/library.svg";
import { IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory, useRouteMatch } from "react-router-dom";
import styles from "./CostingConfigurationLanding.module.scss";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import calculator from "../../Assets/images/calculator.svg";
import ShareIcon from "@mui/icons-material/Share";
import ShareCostingModel from "./ShareCositngModel";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import UploadIcon from '@mui/icons-material/Upload';
import WatermarkModel from "./WatermarkModel";
import CostingConfigurationTreeViewcomponents from "./CostingTreeViewComponents";
import { API } from "../../api-services";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import CreateCostCalculator from "./CreateCostCalculator";
import { useEffect } from "react";
import { debounce } from "lodash";
import CostingConfigurationDrawer from "./CostingConfigurationDrawer";
import { useDocumentTitle } from "../../ui-reusable-component/useDocumentTitle";
import Swal from "sweetalert2";
import LockOpenIcon from '@mui/icons-material/LockOpen';

import LockIcon from '@mui/icons-material/Lock';
type Anchor = "top" | "left" | "bottom" | "right";

interface ICostingConfiguarationCostModelsProps {}

function CostingConfiguarationCostModels(props: ICostingConfiguarationCostModelsProps) {
  const history = useHistory();
  useDocumentTitle( "Costing Config" );
  const { url } = useRouteMatch();
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [open, setOpen] = React.useState(false);
  const { vehCateId, projectname, selectedIdTree, dataType } = useRouteParams<any>();
  const shareModal = useBaseModal();



  const WatermrkModel = useBaseModal();
  const inputElRef = React.useRef<any>();
	const [downloadCalcListLoader, setDownloadCalcListLoader] = React.useState<boolean>(false);
	const [uploadCalcListLoader, setUploadCalcListLoader] = React.useState<boolean>(false);
	const [loadingCalcList, setLoadingCalcList] = React.useState<any>(false);
  const AssumptionCardModal = useBaseModal();
  const [selectedTreeData, setSelectedTreeData] = React.useState<any>({});
  const [searchString, setSearchString] = React.useState<any>("");
  const [AllHierarchy, setAllHierarchy] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState<any>(false);
  const [counterApiCall, setCounterApiCall] = React.useState<any>(1);
  const [isSyncRecomended,setIsSyncRecomended]=React.useState<any>(false)
  const [validationParent,setValidationParent]=React.useState<any>(false)
  const [triggerSyncButton,setTriggerSyncButton]=React.useState<any>(0)
const [useraction,setuseraction]=React.useState<any>("")
const [Lockstatus,setLockstatus]=React.useState<any>()
const [Counter,setCunter]=React.useState<any>(0)
  
  const SyncClick=(itemId:any,HierarchyAllData:any)=>{
    // setIsSyncRecomended(false)
    // setValidationParent(true)
            setTriggerSyncButton((prev:any)=>prev+1)
            history.push(`/costingconfiguration/${vehCateId}/${projectname}/${itemId}/${HierarchyAllData.type}/`)
  }

  const handleDownloadCalc = () => {
    setDownloadCalcListLoader(true)
    API.post("/cost/export_calculator/", { vehicle_category: vehCateId }, {}, 0)
      .then((res: any) => {
        setDownloadCalcListLoader(false)
        window.open(res.data, "_blank", " noopener");
      })
      .catch((err: any) => {
        setDownloadCalcListLoader(false)
        Swal.fire({
          title: "Error!",
          text: "Error in downloading calculator list",
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  
  }
  const browseFiles = () => {
      
    return inputElRef.current?.click?.();
  };
  
  const uploadCalc = (file: any) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("vehicle_category", vehCateId);
  
    setUploadCalcListLoader(true)
    API.post("/cost/upload_calculator_data/", formData , {},  0)
      .then((res: any) => {
        setUploadCalcListLoader(false)
        Swal.fire({
          title: "Success!",
          text: "Calculator uploaded successfully",
          icon: "success",
          confirmButtonText: "Ok",
        });
      })
      .catch((err: any) => {
        setUploadCalcListLoader(false)
        Swal.fire({
          title: "Error!",
          text: "Error in uploading calculator",
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  }
  
  
  const handleChange1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files?.[0];
    //convert into formdata
    
  
  
    uploadCalc(file);
    // setDisable( false );
  };
  const handleInputClick = (e: React.MouseEvent) => {
      // clear the prev value on click so that same image can be uploaded again
      (e.target as HTMLInputElement).value = "";
    };

  React.useEffect(() => {
    API.get(
      "/auth/user_permission_module/",
      {
        action: true,
        configuration: true,
        vehicle_category: vehCateId,
        module:"Costing Configuration",
        
      },
      0
    )
      .then((res: any) => {
        setuseraction(res?.data?.action)
        sessionStorage.setItem("ConfigPermission", res.data.action);
      })
      .catch((err: any) => { });
  }, []);


  const getTreeData = () => {



    setIsLoading(true);
    API.get(
      "/cost/costing_configuration/",
      {
        vehicle_category: vehCateId,
        costing_config_hier: true,
        
      },
      0
    )
      .then((res: any) => {
        setAllHierarchy(res.data[0]?.data);
        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  };


  const SynceHandler=()=>{
    setIsLoading(true);
    API.get(
      "/cost/costing_configuration/",
      {
        vehicle_category: vehCateId,
        costing_config_hier: true,
        sync:true
      },
      0
    )
      .then((res: any) => {
        setAllHierarchy(res.data[0]?.data);
        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  }
  const getCalcData = (data: any) => {
    setSelectedTreeData(data);
  };
  React.useEffect(() => {
    getTreeData();
  }, [counterApiCall]);

  const onSelect = (nodeId: any, id: any, HierarchyAllData: any, itemId: any) => {
    if(selectedIdTree != 0){
			if( isSyncRecomended === true && !validationParent){
        Swal.fire({
          title: "Alert!",
          text: "Cost Sync is required  Please Sync before proceeding",
          icon: "warning",
          confirmButtonText: "Sync and close",
          // preConfirm: ()=>SyncClick(itemId,HierarchyAllData),
        }).then((result) => {
          if (result.isConfirmed) {
            SyncClick(itemId,HierarchyAllData)
          }
        }
        )
      }else{  
        history.push(`/costingconfiguration/${vehCateId}/${projectname}/${itemId}/${HierarchyAllData.type}/`);
      }
		
  }else{
    history.push(`/costingconfiguration/${vehCateId}/${projectname}/${itemId}/${HierarchyAllData.type}/`);
  }
   
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === "keydown" && ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
      
      
      >
      <CostingConfigurationDrawer VehiclecategoryId={vehCateId} projectname={projectname} />
    </Box>
  );

  const handleClick = () => {
    // history.push(`/costingconfiguration/${vehCateId}/${projectname}/guidelines`);
  };
  const ExactDebounce = React.useRef(
    debounce(async (value: any) => {
      setIsLoading(true);
      API.get(
        "/cost/costing_configuration/",
        {
          vehicle_category: vehCateId,
          costing_config_hier: true,
          search: value,
        },
        0
      )
        .then((res: any) => {
          setAllHierarchy(res.data[0]?.data);
          setIsLoading(false);
        })
        .catch((err: any) => {
          setIsLoading(false);
        });
    }, 1000)
  ).current;

  React.useEffect(() => {
    return () => {
      ExactDebounce.cancel(); // Cancel the debounced function
    };
  }, []);
  //@ts-ignore
  const validationStatus = JSON.parse(sessionStorage.getItem("otherValidation"));

  const comapnionHandle = () => {
    API.get(
      "/web_companion/comapanion_activity_log",
      {
        //   get_recycle_bin_data_count: true
        project: vehCateId,
        viewed: true,
      },
      0
    )
      .then((res: any) => {
        // console.log(res?.data.count, "countdata")
      })
      .catch((err: any) => {
        // console.log("Server error")
        //   SetLoader(false)
      });
    history.push(`/companionConfig/${vehCateId}/${projectname}`);
  };

  const commodityHandle = () => {
    history.push(`/costingconfiguration/${vehCateId}/${projectname}/${selectedIdTree}/${dataType}/commodity`);
  };


  // const guideHandler = () => {
  //   history.push(`/costingconfiguration/${vehCateId}/${projectname}/guidelines`);
  // };

  // const assumptionHandler = () => {
  //   history.push(`/costingconfiguration/${vehCateId}/${projectname}/assumptioncard/0/1/true/true`);
  // };
  const handleOnChange = (ed: any) => {
    const { value } = ed.target;
    ExactDebounce(value);
  };

  const LockHandler=(e:any,type:any)=>{

    if (type==false){

      API.post('/cost/costing_configuration/',{vehicle_category:vehCateId,lock_costmodels:false},0).then((res:any)=>{
        setLockstatus(res?.data?.master_lock)
        setCunter((prev:any)=>prev+1)
      })

    //   if ((userProfile && userProfile[0]?.is_superuser || userProfile[0]?.is_project_admin || userProfile[0]?.id==Lockstatus?.locked_user )){
    //     API.put(`cost/calculator/${selectedIdTree}/`,{lock:type},0).then((res:any)=>{
    //       setSaveApiCallCount((prev:any)=>prev+1)
          
    //     })
    //   }else {
    //     infoModal.open()
    //   }
    // }
 
  
    }


    if (type==true){
      API.post('/cost/costing_configuration/',{vehicle_category:vehCateId,lock_costmodels:true},0).then((res:any)=>{
        setLockstatus(res?.data?.master_lock)
        setCunter((prev:any)=>prev+1)
      
      })
    }
  
    }
    useEffect(()=>{
      setLockstatus(null)
  
   
      API.get('/cost/costing_configuration/',{vehicle_category:vehCateId,master_lock_status:true},0).then((res:any)=>{
        setLockstatus(res?.data?.master_lock)
      
      })
    },[vehCateId,Counter])



  return (
    <div>
      {shareModal.isOpen && (
        <ShareCostingModel
          isOpen={shareModal.isOpen}
          onCloseModal={shareModal.close}
        />
      )}
      {WatermrkModel.isOpen && (
        <WatermarkModel
          isWMOpen={WatermrkModel.isOpen}
          onCloseModal={WatermrkModel.close}
        />
      )}
      {/* {AssumptionCardModal.isOpen && (
        <AssumptionCard
          isASCDopen={AssumptionCardModal.isOpen}
          isASCDclose={AssumptionCardModal.close}
        />
      )} */}
      <Box sx={{ margin: "0 0.5rem" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}>
          <Box sx={{ display: "flex", alignItems: "center", columnGap: "1rem" }}>
            <IconButton
              sx={{ cursor: "pointer" }}
              title="Go Back"
              onClick={handleClick}>
              <ArrowBackIcon
                color="primary"
                titleAccess="Go Back"
              />
            </IconButton>
            <Typography sx={{ fontSize: "1rem", fontWeight: "500", lineHeight: "1.2" }}>{projectname} (Costing Configuration-Cost Model)</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", columnGap: "1rem" }}>
          {
             
             downloadCalcListLoader ? (<CircularProgress size={16} />) : <DownloadIcon sx={{ cursor: "pointer", height: '1.7rem' }} onClick={() => handleDownloadCalc()} titleAccess="Download Cost Model" />
             
           }
           {
            
             uploadCalcListLoader ? (<CircularProgress size={16} />) : <UploadIcon sx={{ cursor: "pointer", height: '1.7rem' }} onClick={() => browseFiles()} titleAccess="Upload Cost Model" />
             
           }
            {/* {validationStatus?.guidline_config ? (
              <img
                title="Guide lines"
                src={guidelineValidatedImg}
                style={{ height: "1.65rem", width: "auto", cursor: "pointer" }}
                onClick={() => guideHandler()}
              />
            ) : (
              <img
                title="Guide lines"
                src={guidelines}
                style={{ height: "1.65rem", width: "auto", cursor: "pointer" }}
                onClick={() => guideHandler()}
              />
            )} */}

            {/* onClick={()=>costingModel()} */}
            {/* {
              <CreditCardIcon
                titleAccess="Assumption Card"
                sx={{
                  fontSize: "2.5rem",
                  cursor: "pointer",
                  color: validationStatus?.assumption_cofig ? "green" : "",
                }}
                // onClick={() => AssumptionCardModal.open()}
                onClick={() => assumptionHandler()}
              />
            } */}
            { 
            // useraction?.length>0 && useraction?.includes("C") && 
            
            (
              <ShareIcon
                titleAccess="Share"
                sx={{ cursor: "pointer" }}
                onClick={() => shareModal.open()}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}>
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}>
                  <Box
                    sx={{ padding: "1rem 0rem", paddingBottom: "0" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3">
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}>
                      Costing Configuration
                    </Typography>
                    <Divider sx={{ margin: "0.5rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  style={{
                    transform: "rotate(270deg)",
                    whiteSpace: "nowrap",
                    marginTop: "40vh",
                    cursor: "pointer",
                    fontSize: "1rem",
                  }}
                  onClick={toggleDrawer(anchor, true)}>
                  Costing Configuration
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: { lg: "86vh", xl: "89vh" },
            borderRadius: "10px",
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
            marginRight: "1rem",
          }}
          // adl-scrollbar="true"
          // adl-scrollbar-width="0.3"
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              columnGap: "0.5rem",
              alignItems: "flex-start",
              borderRadius: "10px",
              boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
            }}>
            <Box
              sx={{
                width: "20vw",
                height: { lg: "86vh", xl: "89vh" },
                padding: "0 0.5rem",
                boxShadow: "5px 0 5px -5px rgba(128,128,128,0.7), 0px 0 5px -5px rgba(128,128,128,0.7)",
              }}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  columnGap: "0.5rem",
                  // padding: "0 0.5rem",
                  //   justifyContent: "flex-end",
                }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    borderBottom: "1px solid",
                    borderBottomColor: "primary.light",
                  }}>
                  <InputBase
                    sx={{ ml: 1, flex: 1, fontSize: "1rem", }}
                    placeholder="Search.."
                    onChange={handleOnChange}
                    inputProps={{ "aria-label": "Search.." }}
                  />
                  <IconButton
                    type="button"
                    sx={{ p: "5px" }}
                    aria-label="search">
                    <SearchIcon sx={{ color: "primary.main" }} />
                  </IconButton>
                </Box>
                <CachedIcon
                  titleAccess="Sync Calculator"
                  sx={{ cursor: "pointer" }}
                  onClick={() => SynceHandler()}
                />
                <img
                  title="Library"
                  src={library}
                  style={{ width: "1.5rem", cursor: "pointer" }}
                  onClick={() => commodityHandle()}
                />

{
  AllHierarchy && AllHierarchy?.length>0 && <>
{
				Lockstatus? <IconButton  sx={{padding:'0'}}>					
					<LockIcon  sx={{cursor:"pointer",color:"green",fontSize:"1.5rem"}} titleAccess="Unlock cost model" onClick={(e:any)=>LockHandler(e,false)}/>
					
					</IconButton>
					: <LockOpenIcon  sx={{cursor:"pointer",color:"red",fontSize:"1.5rem"}} titleAccess="Lock cost model" onClick={(e:any)=>LockHandler(e,true)}/>
			}

</>}
              </Box>
              <Box
                sx={{
                  height: { lg: "81vh", xl: "85vh" },
                  // marginRight: "-1rem",
                }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3">
                {!isLoading && AllHierarchy ? (
                  AllHierarchy?.map((item: any) => {
                    return (
                      <CostingConfigurationTreeViewcomponents
                        HierarchyData={item && item}
                        onSelectCallback={onSelect}
                        setSelectedTreeData={getCalcData}
                        setCounterApiCall={setCounterApiCall}
                        useraction={useraction?.length>0&&useraction}
                      />
                    );
                  })
                ) : (
                  <Box sx={{ width: "100%", padding: "0 0.5rem" }}>
                    <Skeleton sx={{ height: "2rem", width: "30%" }} />
                    <Skeleton sx={{ height: "2rem", width: "45%" }} />
                    <Skeleton sx={{ height: "2rem", width: "50%" }} />
                    <Skeleton sx={{ height: "2rem", width: "65%" }} />
                    <Skeleton sx={{ height: "2rem", width: "70%" }} />
                    <Skeleton sx={{ height: "2rem", width: "60%" }} />
                    <Skeleton sx={{ height: "2rem", width: "55%" }} />
                    <Skeleton sx={{ height: "2rem", width: "30%" }} />
                    <Skeleton sx={{ height: "2rem", width: "45%" }} />
                    <Skeleton sx={{ height: "2rem", width: "50%" }} />
                    <Skeleton sx={{ height: "2rem", width: "35%" }} />
                    <Skeleton sx={{ height: "2rem", width: "70%" }} />
                    <Skeleton sx={{ height: "2rem", width: "55%" }} />
                    <Skeleton sx={{ height: "2rem", width: "80%" }} />
                    <Skeleton sx={{ height: "2rem", width: "30%" }} />
                    <Skeleton sx={{ height: "2rem", width: "45%" }} />
                    <Skeleton sx={{ height: "2rem", width: "50%" }} />
                    <Skeleton sx={{ height: "2rem", width: "65%" }} />
                    <Skeleton sx={{ height: "2rem", width: "70%" }} />
                    <Skeleton sx={{ height: "2rem", width: "60%" }} />
                    <Skeleton sx={{ height: "2rem", width: "55%" }} />
                    <Skeleton sx={{ height: "2rem", width: "30%" }} />
                    <Skeleton sx={{ height: "2rem", width: "45%" }} />
                    <Skeleton sx={{ height: "2rem", width: "50%" }} />
                    <Skeleton sx={{ height: "2rem", width: "35%" }} />
                    <Skeleton sx={{ height: "2rem", width: "70%" }} />
                    <Skeleton sx={{ height: "2rem", width: "55%" }} />
                    <Skeleton sx={{ height: "2rem", width: "80%" }} />
                  </Box>
                )}
              </Box>
            </Box>
            {dataType == "calculator" ? (
              <CreateCostCalculator
                selectedTreeData={selectedTreeData && selectedTreeData}
                setCounterApiCall={setCounterApiCall}
                setIsSyncRecomended={setIsSyncRecomended}
                setValidationParent={setValidationParent}
                triggerSyncButton={triggerSyncButton}
                setTriggerSyncButton={setTriggerSyncButton}
                Counter={Counter}
                setCounter={setCunter}
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "80vh",
                  width: "100%",
                }}>
                <Typography sx={{ fontSize: "1rem", textAlign: "center" }}>
                  <img
                    src={calculator}
                    style={{ width: "3rem" }}
                  />{" "}
                  <br />
                  Click on the cost model from hierarchy on the left side
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <input
            type="file"
            style={{ display: "none" }}
            // required
            accept=".enc"
            id="file"
            name="file"
            ref={inputElRef}
            onChange={handleChange1}
            onClick={handleInputClick}
            capture="user"
          />
    </div>
  );
}

export default CostingConfiguarationCostModels;
