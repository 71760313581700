import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";

import { Box, Button, Divider, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { useEffect, useState } from "react";
import { API } from "../../api-services";
import { LoadingButton } from "@mui/lab";

const popstyled = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "40%", xl: "40%" },
  height: { lg: "26vh", xl: "18vh" },
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};


// @ts-ignore
const useStyles = makeStyles((theme) => ({
  /** Changed modalStyle */
  ModalRoot: {
    backgroundColor: "rgba(111, 126, 140, 0.2) !important",
    backdropFilter: "blur(1px) !important",
  },
}));
interface Params {
  projectId: any;
  topVaultId: any;
  categoryId: any;
  parameterId: any;
  projectname: any;
}
export interface BOMCustomValidationModalProps {
  isOpen: any;
  onCloseModal: any;
  idShare: any;
  type: string;
  nameHeader: any;
}

const BOMCustomValidationShareModal = (props: BOMCustomValidationModalProps) => {
  const { isOpen, onCloseModal, idShare, type, nameHeader } = props;
  const { projectId, topVaultId } = useRouteParams<Params>();
  const [projectList, setProjectList] = useState<any>();
  const [productList, setProductList] = useState<any>();
  const [categoryList, setCategoryList] = useState<any>();
  const [selectProject, setSelectProject] = useState<any>();
  const [selectProduct, setSelectProduct] = useState<any>();
  const [selectCategory, setSelectCategory] = useState<any>();
  const [shareLoadings, setShareLoadings] = useState<boolean>(false);
  const [shareCheckOverRides, setShareCheckOverRides] = useState<any>(false);
  const [shareCheckOverRidesAPI, setShareCheckOverRidesAPI] = useState<any>(false);

  const classes = useStyles();

  useEffect(() => {
    if (type == "productLevel") {
      API.get('/bom/custom_match/', { product: idShare }, 0).then((res: any) => {
        setProjectList(res?.data)
      }).catch((err: any) => {
        console.log(err);
      });
    }
    else {
      API.get('/bom/custom_match/', { custom_id: idShare }, 0).then((res: any) => {
        setProductList(res?.data)
      }).catch((err: any) => {
        console.log(err);
      });
    }
  }, [])

  const handleChangeProject = (e: any) => {
    setSelectProject(e.target.value);
    API.get('/bom/custom_match/', { target_project: e.target.value, product: idShare }, 0).then((res: any) => {
      setProductList(res?.data)
    }).catch((err: any) => {
      console.log(err);
    });
  }

  const handleChangeProduct = (e: any) => {
    if (type == "productLevel") {
      setSelectProduct(e.target.value);
      setShareCheckOverRidesAPI(productList && productList?.filter((item: any) => {
        return item?.id == e.target.value
      })[0]?.override);
    }
    else {
      API.get('/bom/custom_match/', { product: e.target.value, custom_id: idShare }, 0).then((res: any) => {
        setCategoryList(res?.data)
      }).catch((err: any) => {
        console.log(err);
      });
    }
  }

  const handleChangeCategory = (e: any) => {
    setSelectCategory(e.target.value);
    setShareCheckOverRidesAPI(categoryList && categoryList?.filter((item: any) => {
      return item?.id == e.target.value
    })[0]?.override);
  }

  const cancelCustomValidations = () => {
    setShareCheckOverRides(false);
  }

  const shareOverrideCustomValidations = () => {
    setShareLoadings(true);
    if (type == "productLevel") {
      API.post('/bom/share_config/', { vehicle_category: idShare, target_top_vault: selectProduct, type: 2 }, 0).then((res: any) => {
        setShareLoadings(false);
        onCloseModal();
      }).catch((err: any) => {
        setShareLoadings(false);
      })
    }
    else {
      API.post('/bom/share_config/', { category: idShare, vehicle_category: topVaultId, target_top_vault: selectProduct, type: 2 }, 0).then((res: any) => {
        setShareLoadings(false);
        onCloseModal();
      }).catch((err: any) => {
        setShareLoadings(false);
      })
    }
  }

  const shareCustomValidations = () => {
    if (shareCheckOverRidesAPI) {
      setShareCheckOverRides(true);
    }
    else {
      setShareLoadings(true);
      if (type == "productLevel") {
        API.post('/bom/share_config/', { vehicle_category: idShare, target_top_vault: selectProduct, type: 2 }, 0).then((res: any) => {
          setShareLoadings(false);
          onCloseModal();
        }).catch((err: any) => {
          setShareLoadings(false);
        })
      }
      else {
        API.post('/bom/share_config/', { target_category:selectCategory, category: idShare, vehicle_category: topVaultId, target_top_vault: selectProduct, type: 2 }, 0).then((res: any) => {
          setShareLoadings(false);
          onCloseModal();
        }).catch((err: any) => {
          setShareLoadings(false);
        })
      }
    }
  }
  console.log(selectCategory, "selectCategoryselectCategory")
  return (
    <div>
      <Modal
        open={isOpen}
        className={classes.ModalRoot}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={popstyled}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}>
              {nameHeader} ({type == "productLevel" ? "Product Level Custom Validation Share" : "Category Level Custom Validation Share"})
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ padding: '0 0.5rem' }}>
            {shareCheckOverRides ?
              <>
                <p>The place where you are sharing custom validation there some validation already exist.</p>
                <p style={{ color: 'black', fontSize: '1.3rem' }}>Do you want to overwrite?</p>
              </> :
              <Box sx={{ display: 'flex', alignItems: 'flex-start', columnGap: '1rem', marginBottom: '0.5rem' }}>
                {type == "productLevel" && <FormControl variant="standard" fullWidth>
                  <InputLabel sx={{ color: "primary.main" }} shrink id="demo-simple-select-standard-label">Project</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={selectProject}
                    onChange={handleChangeProject}
                    label="Project"
                    sx={{
                      "&:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                        fontSize: '1rem'
                      },
                      '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
                    }}
                  >
                    {projectList && projectList?.map((item: any) => {
                      return <MenuItem sx={{ fontSize: '1rem' }} value={item?.id}>{item?.name}</MenuItem>
                    })}
                  </Select>
                </FormControl>}
                <FormControl variant="standard" fullWidth>
                  <InputLabel sx={{ color: "primary.main" }} shrink id="demo-simple-select-standard-label">Select Category</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={selectProduct}
                    onChange={handleChangeProduct}
                    label="Product"
                    sx={{
                      "&:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                        fontSize: '1rem'
                      },
                      '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
                    }}
                  >
                    {productList && productList?.map((item: any) => {
                      return <MenuItem sx={{ fontSize: '1rem' }} value={item?.id}>{item?.name}</MenuItem>
                    })}
                  </Select>
                </FormControl>
                {type == "categoryLevel" && <FormControl variant="standard" fullWidth>
                  <InputLabel sx={{ color: "primary.main" }} shrink id="demo-simple-select-standard-label">Select Validation Parameter</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={selectCategory}
                    onChange={handleChangeCategory}
                    label="Category"
                    sx={{
                      "&:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                        fontSize: '1rem'
                      },
                      '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
                    }}
                  >
                    {categoryList && categoryList?.map((item: any) => {
                      return <MenuItem sx={{ fontSize: '1rem' }} value={item?.id}>{item?.name}</MenuItem>
                    })}
                  </Select>
                </FormControl>}
              </Box>}
            {/* {shareCheckOverRides ?
              <div style={{ textAlign: 'right', marginRight: '0.5rem' }}>
                <span>
                  <LoadingButton sx={{ mr: '0.5rem' }} variant="contained" onClick={cancelCustomValidations}>Cancel</LoadingButton>
                  <LoadingButton loading={shareLoadings} variant="contained" onClick={shareOverrideCustomValidations}>Share</LoadingButton>
                </span>
              </div>
              : <div style={{ textAlign: 'right', marginRight: '0.5rem' }}>
                <LoadingButton loading={shareLoadings} variant="contained" onClick={shareCustomValidations}>Share</LoadingButton>
              </div>} */}
          </Box >
          {
            shareCheckOverRides ?
              <div style={{ width: '100%', textAlign: 'right', marginRight: '0.5rem', marginTop: '1rem' }}>
                <span>
                  <LoadingButton
                    size="small"
                    sx={{
                      mr: '0.5rem',
                      "&:hover": {
                        transform: 'Scale(1.05)',
                        transition: 'transform 0.5s ease',
                      },
                      '.MuiCircularProgress-root': {
                        color: 'primary.main',
                      },
                    }} variant="contained" onClick={cancelCustomValidations}>Cancel</LoadingButton>
                  <LoadingButton size="small" loading={shareLoadings} sx={{
                    "&:hover": {
                      transform: 'Scale(1.05)',
                      transition: 'transform 0.5s ease',
                    },
                    '.MuiCircularProgress-root': {
                      color: 'primary.main',
                    },
                  }} variant="contained" onClick={shareOverrideCustomValidations}>Share</LoadingButton>
                </span>
              </div >
              : <div style={{ textAlign: 'right', marginRight: '0.5rem', marginTop: '1rem' }}>
                <LoadingButton size="small" loading={shareLoadings} variant="contained"
                  sx={{
                    "&:hover": {
                      transform: 'Scale(1.05)',
                      transition: 'transform 0.5s ease',
                    },
                    '.MuiCircularProgress-root': {
                      color: 'primary.main',
                    },
                  }}
                  onClick={shareCustomValidations}>Share</LoadingButton>
              </div>}
        </Box >
      </Modal >
    </div >
  );
};

export default BOMCustomValidationShareModal;


