import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import { Divider } from '@mui/material';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridCsvExportOptions,
    GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import useWindowDimensions from '../../ui-reusable-component/useWindowDimensions';
import { ButtonProps } from "@mui/material/Button";
import ShareIcon from '@mui/icons-material/Share';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import extlink from '../../Assets/images/ext_link.svg';
import { API } from '../../api-services';
import moment from 'moment';


export interface ICostingScenarioTableModalProps {
    isOpen: any;
    onCloseModal: any;
    query:any;
}


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { lg: '90vw', xl: '85vw' },
    bgcolor: 'background.paper',
    borderRadius: '10px',
    p: 1,
};

const csvOptions: GridCsvExportOptions = {
    fileName: "Scenario Table In CSV",
    delimiter: ",",
    utf8WithBom: true
};

const CustomExportButton = (props: ButtonProps) => (
    <GridToolbarExportContainer {...props}>
        <GridCsvExportMenuItem options={csvOptions} />
    </GridToolbarExportContainer>
);

const CustomToolbar = () => (
    <GridToolbarContainer sx={{ height: "2rem", justifyContent: "flex-end", paddingRight: '1rem', marginBottom: '0.5rem' }}>
        <span>
            <GridToolbarColumnsButton />
            <CustomExportButton />
        </span>
    </GridToolbarContainer>
);


export function CostingScenarioTableModal(props: ICostingScenarioTableModalProps) {
    const { isOpen, onCloseModal,query } = props;
    const { height, width } = useWindowDimensions();
    const[TableData,setTableData]=React.useState<any>([])
    const [Loader,setLoader]=React.useState(false)



    let columns: any = [
        { field: "sno", headerName: "SNo.", maxWidth: width > 1370 ? 60 : 50, flex: 1 },
        {
            field: "name",
            headerName: "Name",
            maxWidth: width > 1370 ? 120 : 100,
            flex: 1,
        },
        {
            field: "scenario_creation",
            headerName: "Type",
            maxWidth: width > 1370 ? 120 : 100,
            flex: 1,
        },
        {
            field: "parent",
            headerName: "Parent",
            maxWidth: width > 1370 ? 120 : 100,
            flex: 1,
        },
        {
            field: "cost",
            headerName: "Total Cost",
            maxWidth: width > 1370 ? 120 : 90,
            headerAlign: "center",
            align: 'right',
            flex: 1,
        },
        {
            field: "rm_cost",
            headerName: "RM Cost",
            minWidth: width > 1370 ? 120 : 120,
            headerAlign: "center", align: 'right',
            flex: 1,
        },
        { field: "process_cost", headerName: "Process Cost", maxWidth: width > 1370 ? 150 : width < 1200 ? 140 : 120, headerAlign: "center", align: 'right', flex: 1 },
        { field: "overhead_cost", headerName: "OH Cost", maxWidth: width > 1370 ? 140 : width < 1200 ? 140 : 110, headerAlign: "center", align: 'right', flex: 1 },
        { field: "child_cost", headerName: "Child Part Cost", maxWidth: width > 1370 ? 150 : width < 1200 ? 140 : 120, headerAlign: "center", align: 'right', flex: 1 },
        { field: "config", headerName: "Assumption Card/s", maxWidth: width > 1370 ? 150 : width < 1200 ? 140 : 120, flex: 1 },
        { field: "created_by_name", headerName: "Created By", maxWidth: width > 1370 ? 140 : width < 1200 ? 140 : 110, flex: 1 },
        { field: "created_at", headerName: "Creation Date", maxWidth: width > 1370 ? 150 : width < 1200 ? 140 : 120, flex: 1 },
       
    ];
   


    React.useEffect(()=>{

        setLoader(true)
        API.get("/cost/scenario_table/",{...query}).then((res:any)=>{
            setTableData(res?.data) 
            setLoader(false)
        }).catch((err:any)=>{
            console.log("Sever Error")
            setLoader(false)
        })
    },[query])
    // In rows which is a array, we are pushing data which is structured in frontEnd so, that we can pass it to dataGrid


   



    var RowData = TableData && TableData?.map((item: any, index: any) => ({ ...item, sno:index+1,created_at:item?.created_at==""?"__":moment(item?.created_at).format('DD-MM-YYYY') }));

 
    let dataStructureDataGrid: any = {
        columns: columns,
        rows: RowData && RowData,
    };



    return (
        <div>
            <Modal
                open={isOpen}
                onClose={onCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
                        <Typography
                            style={{
                                fontSize: "1rem",
                                fontWeight: "600",
                                width: "100%",
                                textAlign: "center",
                                marginRight: '-2rem'
                            }}
                        >
                            Scenario Costing Table
                        </Typography>
                        <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
                    </Box>
                    <Divider
                        sx={{
                            borderColor: "primary.light",
                            margin: "0 1rem",
                            marginBottom: "1rem",
                        }}
                    />
                    <Box>

                        <Box sx={{ height: '60vh', width: '100%' }}>
                            <DataGrid
                                headerHeight={48}
                                rowHeight={36}
                                {...dataStructureDataGrid}
                                loading={Loader}
                                disableSelectionOnClick={true}
                                // loading={userDataGetLoad}
                                components={{
                                    Toolbar: () => {
                                        return CustomToolbar();
                                    },
                                }}
                                density={"compact"}
                                pageSize={100}
                                rowsPerPageOptions={[100]}
                                sx={{
                                    "&.MuiDataGrid-root .MuiDataGrid-cell": {
                                        borderBottomColor: "primary.light",
                                    },
                                    "& ::-webkit-scrollbar": {
                                        width: "0.3rem",
                                        height: "0.3rem",
                                    },
                                    "& ::-webkit-scrollbar-thumb": {
                                        // background: $thumb-color;
                                        backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                                        borderRadius: "10px",
                                    },
                                    "& ::- webkit-scrollbar-thumb: hover ": {
                                        // width: "4px",
                                        backgroundColor: "#045DE9 !important",
                                    },
                                    "::-webkit-scrollbar-track": {
                                        backgroundColor: "#e1e1f3 !important",
                                    },
                                }}
                            />
                        </Box>



                    </Box>
                </Box>
            </Modal>

        </div >
    );
}
