import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import * as yup from "yup";
import { useFormik } from "formik";
import { Backdrop, Chip, Divider, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import styles from "./AddRevisionModal.module.scss";
import { API } from "../../api-services";
import { LoadingButton } from "@mui/lab";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CancelIcon from "@mui/icons-material/Cancel";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 0,
};
const RevisionTypeList = [
  { id: 1, text: "Main Costing", value: 1 },
  { id: 2, text: "Scenario Costing", value: 2 },
];
const validationSchema = yup.object({
  category: yup.string().required("Please select a category"),
  material_code: yup.string().required("Please enter material code"),
  material_grade: yup.string(),
  material_subgrade: yup.string(),
  shape: yup.string(),
  density: yup.number().required("Please enter density"),
  description: yup.mixed(),
  material_cost: yup.number().required("Please enter Material Cost"),
  material_source: yup.mixed().required("Please enter Material Source"),
  material_reference: yup.mixed(),
  scrap_cost: yup.number().required("Please enter Scrap Cost"),
  scrap_source: yup.mixed(),
  scrap_reference: yup.mixed(),
});

const useStyles = makeStyles((theme) => ({
  select: {
    "&.MuiInputBase-root-MuiInput-root-MuiSelect-root": {
      borderBottom: "1px solid red",
    },
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface ICreateRevisionDatabaseModalProps {
  isOpen: any;
  onCloseModal: any;
  actions?: any;
  expanded?: any;
  getMaterialData?: any;
  categoryList?: any;
}

function CreateRevisionDatabaseModal(props: ICreateRevisionDatabaseModalProps) {
  const { isOpen, onCloseModal, actions, expanded, getMaterialData, categoryList } = props;
  const { revisionId, typeId, databaseName,categoryName } = useRouteParams<any>();
  const [isLoadingsave, setIsLoadingSave] = React.useState<any>(false);
  const classes = useStyles();
  React.useEffect(() => {
    if (actions !== "add") {
      formik.setFieldValue("description", actions.description || "");
      formik.setFieldValue("material_cost", parseFloat(actions?.material_cost?.slice(1)) || "");
      formik.setFieldValue("material_source", actions.material_source || "");
      formik.setFieldValue("material_reference", actions?.material_reference || "");
      formik.setFieldValue("scrap_cost", parseFloat(actions.scrap_cost?.slice(1)) || "");
      formik.setFieldValue("scrap_source", actions.scrap_source || "");
      formik.setFieldValue("scrap_reference", actions.scrap_reference || "");
      formik.setFieldValue("category", actions.category || "");
      formik.setFieldValue("material_code", actions.material_code || "");
      formik.setFieldValue("material_grade", actions.material_grade || "");
      formik.setFieldValue("material_subgrade", actions.material_subgrade || "");
      formik.setFieldValue("shape", actions.shape || "");
      formik.setFieldValue("density", actions.density || "");
    }
  }, [actions]);



  const formik = useFormik({
    initialValues: {
      category: "",
      material_code: "",
      material_grade: "",
      material_subgrade: "",
      shape: "",
      density: "",
      description: "",
      material_cost: "",
      material_source: "",
      material_reference: "",
      scrap_cost: "",
      scrap_source: "",
      scrap_reference: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {

      if (actions === "add") {
        setIsLoadingSave(true);
        API.post("/db/material/", values, {})
          .then((res1: any) => {
            // console.log("res1res1",res1.data)
            // if(!databaseName?.includes("DB"))
            
            setIsLoadingSave(false);
            getMaterialData(categoryName);
            onCloseModal();
            {
              API.post("/db/material_cost/", {
          material_ids: [res1.data.id],
          revision: revisionId,
        })
          .then((res: any) => {
            setIsLoadingSave(false);
            getMaterialData(categoryName);
            onCloseModal();
            // :revisionId/:typeId/:databaseName/:categoryName/:status/:search
          })
          .catch((err: any) => {
            setIsLoadingSave(false);

          });
              }

              setIsLoadingSave(false);
            
          })
          .catch((err: any) => {
            setIsLoadingSave(false);
          });
      } else {
        setIsLoadingSave(true);
        API.put(`/db/material/${actions.id}/`, values, {}, 0)
          .then((res: any) => {
            setIsLoadingSave(false);
            getMaterialData(categoryName);
            onCloseModal();
          })
          .catch((err: any) => {
            setIsLoadingSave(false);
          });
      }
    },
  });

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}>
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}>
              {actions === "add" ? "Add" : "Edit"} Raw Material Information
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ padding: "0 2rem 1rem", textAlign: "center" }}>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ display: 'flex', alignItems: 'flex-start', columnGap: '1rem', marginBottom: '0.5rem' }}>
                <FormControl
                  fullWidth
                  variant="standard">
                  <InputLabel sx={{ color: "primary.main" }} shrink id="demo-simple-select-label">Select category <sup style={{ color: 'red' }}>*</sup></InputLabel>
                  <Select
                    id="category"
                    name="category"
                    className={classes.select}
                    labelId="demo-select-small"
                    // id="demo-select-small"
                    sx={{
                      "&:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      ".MuiSelect-select.MuiInputBase-input.MuiInput-input": {
                        textAlign: "left",
                        fontSize: "1rem",
                      },
                      ".MuiSvgIcon-root ": {
                        color: "primary.main",
                      },
                    }}
                    error={formik.touched.category && Boolean(formik.errors.category)}
                    //@ts-ignore
                    helperText={formik.touched.category && formik.errors.category}
                    MenuProps={{
                      style: {
                        maxHeight: 400,
                        color: "007fff",
                      },
                    }}
                    onChange={formik.handleChange}
                    value={formik.values.category !== null ? formik.values.category : ""}>
                    {/* <option value={""} style={{ display: "none" }}></option> */}
                    {categoryList &&
                      categoryList.map((item: any, index: any) => {
                        return (
                          <MenuItem
                            sx={{ color: "primary.main", fontSize: "1rem" }}
                            key={index}
                            value={item.category}>
                            {item.category}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <div className={styles.errormsg}>{formik.touched.category && Boolean(formik.errors.category) && <p>{formik.errors.category}</p>}</div>
                </FormControl>
                <FormControl
                  fullWidth
                  variant="standard">
                  <TextField
                    variant="standard"
                    label={<span>Material Code <sup style={{ color: "red" }}>*</sup></span>}
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                      '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: '1rem !important'
                      },
                    }}
                    id="material_code"
                    name="material_code"
                    onChange={formik.handleChange}
                    value={formik.values.material_code}
                    multiline
                    error={formik.touched.material_code && Boolean(formik.errors.material_code)}
                    helperText={formik.touched.material_code && formik.errors.material_code}
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem', marginBottom: '0.5rem' }}>
                <FormControl
                  fullWidth
                  variant="standard">
                  <TextField
                    variant="standard"
                    label="Material Grade"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                      '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: '1rem !important'
                      },
                    }}
                    id="material_grade"
                    name="material_grade"
                    onChange={formik.handleChange}
                    value={formik.values.material_grade}
                    multiline
                    error={formik.touched.material_grade && Boolean(formik.errors.material_grade)}
                    helperText={formik.touched.material_grade && formik.errors.material_grade}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  variant="standard">
                  <TextField
                    variant="standard"
                    label="Material sub-grade"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                      '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: '1rem !important'
                      },
                    }}
                    id="material_subgrade"
                    name="material_subgrade"
                    onChange={formik.handleChange}
                    value={formik.values.material_subgrade}
                    multiline
                    error={formik.touched.material_subgrade && Boolean(formik.errors.material_subgrade)}
                    helperText={formik.touched.material_subgrade && formik.errors.material_subgrade}
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-start', columnGap: '1rem', marginBottom: '0.5rem' }}>
                <FormControl
                  fullWidth
                  variant="standard">
                  <TextField
                    variant="standard"
                    label="Shape"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                      '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: '1rem !important'
                      },
                    }}
                    id="shape"
                    name="shape"
                    onChange={formik.handleChange}
                    value={formik.values.shape}
                    multiline
                    error={formik.touched.shape && Boolean(formik.errors.shape)}
                    helperText={formik.touched.shape && formik.errors.shape}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  variant="standard">
                  <TextField
                    variant="standard"
                    label={<span>Density <sup style={{ color: "red" }}>*</sup></span>}
                    type="number"
                    //   inputProps={{ inputMode: 'numeric', pattern: '[0-9]*',style: { color: "#007fff" }}}                    
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                      '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: '1rem !important'
                      },
                    }}
                    id="density"
                    name="density"
                    onChange={formik.handleChange}
                    value={formik.values.density}
                    error={formik.touched.density && Boolean(formik.errors.density)}
                    helperText={formik.touched.density && formik.errors.density}
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem', marginBottom: '0.5rem' }}>

                <FormControl
                  fullWidth
                  variant="standard">
                  <TextField
                    variant="standard"
                    label="Description"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                      '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: '1rem !important'
                      },
                    }}
                    id="description"
                    name="description"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    multiline
                    error={formik.touched.description && Boolean(formik.errors.description)}
                    helperText={formik.touched.description && formik.errors.description}
                  />
                </FormControl>

              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-start', columnGap: '1rem', marginBottom: '0.5rem' }}>
                <FormControl
                  fullWidth
                  variant="standard">
                  <TextField
                    variant="standard"
                    label={<span>Raw material Cost <sup style={{ color: "red" }}>*</sup></span>}
                    type="number"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                      '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: '1rem !important'
                      },
                    }}
                    id="material_cost"
                    name="material_cost"
                    onChange={formik.handleChange}
                    value={formik.values.material_cost}
                    error={formik.touched.material_cost && Boolean(formik.errors.material_cost)}
                    helperText={formik.touched.material_cost && formik.errors.material_cost}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  variant="standard">
                  <TextField
                    variant="standard"
                    label={<span>Material Source <sup style={{ color: "red" }}>*</sup></span>}
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                      '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: '1rem !important'
                      },
                    }}
                    id="material_source"
                    name="material_source"
                    onChange={formik.handleChange}
                    value={formik.values.material_source}
                    multiline
                    error={formik.touched.material_source && Boolean(formik.errors.material_source)}
                    helperText={formik.touched.material_source && formik.errors.material_source}
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-start', columnGap: '1rem', marginBottom: '0.5rem' }}>

                <FormControl
                  fullWidth
                  variant="standard">
                  <TextField
                    variant="standard"
                    label="Material Reference"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                      '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: '1rem !important'
                      },
                    }}
                    id="material_reference"
                    name="material_reference"
                    onChange={formik.handleChange}
                    value={formik.values.material_reference}
                    multiline
                    error={formik.touched.material_reference && Boolean(formik.errors.material_reference)}
                    helperText={formik.touched.material_reference && formik.errors.material_reference}
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-start', columnGap: '1rem', marginBottom: '0.5rem' }}>
                <FormControl
                  fullWidth
                  variant="standard">
                  <TextField
                    variant="standard"
                    label={<span>Scrap Cost <sup style={{ color: "red" }}>*</sup></span>}
                    type="number"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                      '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: '1rem !important'
                      },
                    }}
                    id="scrap_cost"
                    name="scrap_cost"
                    onChange={formik.handleChange}
                    value={formik.values.scrap_cost}
                    error={formik.touched.scrap_cost && Boolean(formik.errors.scrap_cost)}
                    helperText={formik.touched.scrap_cost && formik.errors.scrap_cost}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  variant="standard">
                  <TextField
                    variant="standard"
                    label="Scrap Source"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                      '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: '1rem !important'
                      },
                    }}
                    id="scrap_source"
                    name="scrap_source"
                    onChange={formik.handleChange}
                    value={formik.values.scrap_source}
                    multiline
                    error={formik.touched.scrap_source && Boolean(formik.errors.scrap_source)}
                    helperText={formik.touched.scrap_source && formik.errors.scrap_source}
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-start', columnGap: '1rem', marginBottom: '0.5rem' }}>
               
                <FormControl
                  fullWidth
                  variant="standard">
                  <TextField
                    variant="standard"
                    label="Scrap Reference"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                      '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: '1rem !important'
                      },
                    }}
                    id="scrap_reference"
                    name="scrap_reference"
                    onChange={formik.handleChange}
                    value={formik.values.scrap_reference}
                    multiline
                    error={formik.touched.scrap_reference && Boolean(formik.errors.scrap_reference)}
                    helperText={formik.touched.scrap_reference && formik.errors.scrap_reference}
                  />
                </FormControl>
              </Box>
              <Box sx={{ width: '100%', textAlign: 'right' }}>
                <LoadingButton
                  size="small"
                  loading={isLoadingsave}
                  variant="contained"
                  type="submit"
                  sx={{ marginTop: "1rem" }}>
                  {actions === "add" ? "Add" : "Save"}
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
export default CreateRevisionDatabaseModal;
