import * as React from 'react';
import styles from './UserList.module.scss';
import SearchBar from 'material-ui-search-bar';
import {
	Box,
	ButtonProps,
	Drawer,
	IconButton,
	InputBase,
	TextField,
	Typography,
	useTheme,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import {
	DataGrid,
	GridCsvExportMenuItem,
	GridCsvExportOptions,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarExportContainer,
} from '@mui/x-data-grid';
import UserManagementDrawer from '../UserManagementDrawer';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { API } from '../../../api-services';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
export interface AdminDashboardProps {}
type Anchor = 'top' | 'left' | 'bottom' | 'right';

const csvOptions: GridCsvExportOptions = {
	fileName: 'User Details In CSV',
	delimiter: ',',
	utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
	<GridToolbarExportContainer {...props}>
		<GridCsvExportMenuItem options={csvOptions} />
	</GridToolbarExportContainer>
);

const CustomToolbar = () => (
	<GridToolbarContainer
		sx={{ height: '2.5rem', padding: '0 0.5rem 0.5rem', width: 'fit-content' }}>
		<GridToolbarColumnsButton />
		<CustomExportButton />
	</GridToolbarContainer>
);

export function UserList(props: AdminDashboardProps) {
	const [userList, setUserList] = useState<any>([]);
	const theme = useTheme();
	const { tabtype, searchquery } = useParams<any>();
	const [searchkey, setSearchKey] = React.useState<any>(
		searchquery == '0' ? '' : searchquery
	);
	const [userListCount, setuserListCount] = useState<any>({});
	const [TabAction, setTabAction] = useState<any>(tabtype);
	const [Loader, setLoader] = useState<any>(false);
	const [PageCount, setPageCount] = useState<any>(1);
	const [totalPageCount, setTotalPageCount] = useState<any>();
	const [state, setState] = React.useState({
		top: false,
		left: false,
		bottom: false,
		right: false,
	});

	const history = useHistory();

	const toggleDrawer =
		(anchor: Anchor, open: boolean) =>
		(event: React.KeyboardEvent | React.MouseEvent) => {
			if (
				event.type === 'keydown' &&
				((event as React.KeyboardEvent).key === 'Tab' ||
					(event as React.KeyboardEvent).key === 'Shift')
			) {
				return;
			}

			setState({ ...state, [anchor]: open });
		};

	const list = (anchor: Anchor) => (
		<Box
			sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : "auto" }}
			role='presentation'
		>
			<UserManagementDrawer />
		</Box>
	);

	const columns = [
		{ field: 'ids', headerName: 'Sr. No.', width: 60 },
		{
			field: 'first_name',
			headerName: 'User Name',
			width: 200,
			flex: 1,
		},
		{
			field: 'email',
			headerName: 'Email Address',
			width: 200,
			flex: 1,
		},
		{
			field: 'phone',
			headerName: 'Phone Number',
			width: 130,
			flex: 1,
		},
		{
			field: 'company_name',
			headerName: 'Company Name',
			width: 180,
			flex: 1,
		},
		{
			field: 'tax_id',
			headerName: 'Tax Id',
			width: 150,
			flex: 1,
		},
		{
			field: 'user_type',
			headerName: 'User Type',
			width: 100,
			flex: 1,
		},
		{
			field: 'subscription',
			headerName: 'Subscription',
			width: 200,
			flex: 1,
			renderCell: (params: any) => {
				return (
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<Typography
							sx={{
								// border: ' 2px solid orange',
								// borderRadius: '8px',
								padding: '0.3rem',
							}}>
							{params.row.subscription}
						</Typography>
					</div>
				);
			},
		},
	];

	// console.log(userListCount,"userListCountuserListCount")
	const TabHandler = (type: any) => {
		setTabAction(type);
		setPageCount(1);
		history.push(`/user-management/user-list/${type}/0`);
	};

	useEffect(() => {
		if (searchquery == 0) {
			setLoader(true);
			API.get('auth/get_all_user_detail/', {
				user_type: tabtype,
				page: PageCount,
			})
				.then((res: any) => {
					setUserList(res?.data);
					setLoader(false);
				})
				.catch((err: any) => {
					setLoader(false);
				});
		} else if (searchquery !== 0) {
			setLoader(true);
			API.get('auth/get_all_user_detail/', { search_key: searchquery?.trim() })
				.then((res: any) => {
					setUserList(res?.data);
					setLoader(false);
				})
				.catch((err: any) => {
					setLoader(false);
				});
		}
	}, [tabtype, PageCount, searchquery]);
	useEffect(() => {
		setLoader(true);
		API.get('auth/get_all_user_detail/', {
			get_page_count: true,
			user_type: tabtype,
		})
			.then((res: any) => {
				setTotalPageCount(res?.data?.page_count);
				setLoader(false);
			})
			.catch((err: any) => {
				setLoader(false);
			});
	}, [tabtype]);

	useEffect(() => {
		API.get('auth/get_all_user_info/').then((res: any) => {
			setuserListCount(res?.data);
		});
	}, []);

	const PreviousHandler = () => {
		setPageCount((prev: any) => prev - 1);
	};
	const NextHandler = () => {
		setPageCount((prev: any) => prev + 1);
	};

	const rowsData =
		userList?.length > 0 &&
		userList?.map((item: any, index: any) => ({ ...item, ids: index + 1 }));
	const handleOnChange = (ed: any) => {
		const { value } = ed.target;
		if (value == '') {
			setSearchKey('');
			history.push(`/user-management/user-list/${tabtype}/0`);
		} else {
			setSearchKey(value);
		}
	};

	const handleSearchIcon = () => {
		if (searchkey == '') {
			history.push(`/user-management/user-list/${tabtype}/0`);
		} else {
			history.push(`/user-management/user-list/${tabtype}/${searchkey}`);
		}
	};
	const searchAPICall = (ed: any) => {
		if (ed.key == 'Enter') {
			if (searchkey == '') {
				history.push(`/user-management/user-list/${tabtype}/0`);
			} else {
				history.push(`/user-management/user-list/${tabtype}/${searchkey}`);
			}
		}
	};
	return (
		<>
			<Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
				<Box className={styles.expandIconBox}>
					<div className={styles.iconSec}>
						{(['left'] as const).map((anchor) => (
							<React.Fragment key={anchor}>
								<IconButton
									size='medium'
									sx={{
										backgroundColor: 'primary.light',
										color: 'primary.main',
									}}
									onClick={toggleDrawer(anchor, true)}>
									<AddIcon sx={{ fontSize: '1.7rem' }} />
								</IconButton>
								<Drawer
									anchor={anchor}
									open={state[anchor]}
									onClose={toggleDrawer(anchor, false)}
									className={styles.drawerContainer}
									PaperProps={{
										sx: {
											backgroundColor: 'aliceblue',
										},
									}}>
									<Box
										sx={{ padding: '1rem 0rem', paddingBottom: '0' }}
										adl-scrollbar='true'
										adl-scrollbar-width='0.3'>
										<Typography
											style={{
												width: '100%',
												textAlign: 'center',
												fontSize: '1rem',
											}}>
											Select Module
										</Typography>
										<Divider sx={{ margin: '0.5rem' }} />
										{list(anchor)}
									</Box>
								</Drawer>
								<Typography
									style={{
										transform: 'rotate(270deg)',
										whiteSpace: 'nowrap',
										marginTop: '40vh',
										cursor: 'pointer',
										fontSize: '1rem',
									}}
									onClick={toggleDrawer(anchor, true)}>
									Select Module
								</Typography>
							</React.Fragment>
						))}
					</div>
				</Box>
				<Box 
                // @ts-ignore 
					sx={{background: theme.palette.background.customBackground }}
				  className={styles.parent_container}>
					<Box sx={{ padding: '0.5rem 2rem' }}>
						<Typography sx={{ fontSize: '1.5rem', fontWeight: '600' }}>
							User Info
						</Typography>
					</Box>
					<Box className={styles.user_tab_container}>
						<Box
							className={styles.all_user_tab}
							onClick={() => TabHandler('all')}
							style={{
								background: TabAction == 'all' && searchquery == 0 ? 'linear-gradient(90deg, #18c9b7 0%, hsl(220, 78%, 29%) 100%)':
								'#c6c6c6',
								// opacity: TabAction == 'all' && searchquery == 0 ? '' : '0.5',
								boxShadow:
									// TabAction == 'all'?
										 '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
										// : '',
							}}>
							All Users
							<span> ({userListCount?.all})</span>
						</Box>
						<Box
							className={styles.starter_user_tab}
							onClick={() => TabHandler('buyer')}
							style={{
								background: TabAction == 'buyer' && searchquery == 0 ? 'linear-gradient(90deg,hsl(122.42deg 100% 73.11%) 0%,#009688 100%)':
								'#c6c6c6',
								// opacity: TabAction == 'buyer' && searchquery == 0 ? '' : '.5',
								boxShadow:
									// TabAction == 'buyer'? 
										'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
										// : '',
							}}>
							Buyer Users
							<span>( {userListCount?.buyer})</span>
						</Box>
						<Box
							className={styles.professional_user_tab}
							onClick={() => TabHandler('seller')}
							style={{
								background: TabAction == 'seller' && searchquery == 0 ? 'linear-gradient(90deg, hsl(44deg 78.19% 68.19%) 0%, hsl(35.76deg 100% 43.38%) 100%)':
								'#c6c6c6',
								// opacity: TabAction == 'seller' && searchquery == 0 ? '' : '0.5',
								boxShadow:
									// TabAction == 'seller'?
										'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
										// : '',
							}}>
							Seller Users
							<span> ({userListCount?.seller})</span>
						</Box>
						<Box
							className={styles.internal_user}
							onClick={() => TabHandler('internal')}
							style={{
								background: TabAction == 'internal' && searchquery == 0 ? 'linear-gradient(90deg, #abaeff 0%, #241c69 50%)':
								'#c6c6c6',
								// opacity: TabAction == 'internal' && searchquery == 0 ? '' : '0.5',
								boxShadow:
									// TabAction == 'internal'?
										'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
										// : '',
							}}>
							Internal Users
							<span> ({userListCount?.internal})</span>
						</Box>
						<Box
							className={styles.admin_user}
							onClick={() => TabHandler('admin')}
							style={{
								background: TabAction == 'admin' && searchquery == 0 ? 'linear-gradient(90deg, #f39c81 0%, #960000 50%)':
								'#c6c6c6',
								// opacity: TabAction == 'admin' ? '' : '0.5',
								boxShadow:
									// TabAction == 'admin'?
										 '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
										// : '',
							}}>
							Admin Users
							<span> ({userListCount?.admin})</span>
						</Box>
					</Box>
					<Box>
						<Box className={styles.search_tab}>
							<InputBase
								sx={{ ml: 1, flex: 1, fontSize: '1rem' }}
								type='search'
								placeholder='Search..'
								onChange={handleOnChange}
								onKeyDown={searchAPICall}
								value={searchkey}
								inputProps={{ 'aria-label': 'Search ....' }}
							/>
							<IconButton
								type='button'
								sx={{ p: '5px' }}
								onClick={handleSearchIcon}
								aria-label='search'>
								<SearchIcon sx={{ color: 'primary.main' }} />
							</IconButton>
						</Box>
						<Box
							sx={{ backgroundColor: 'background.default' }}
							className={styles.details_table}>
							<DataGrid
								headerHeight={38}
								rowHeight={38}
								rows={rowsData}
								columns={columns}
								loading={Loader}
								components={{
									Toolbar: () => {
										return CustomToolbar();
									},
								}}
								sx={{
									'& .MuiDataGrid-cell': {
										color: 'text.primary',
									},
									'&.MuiDataGrid-root .MuiDataGrid-cell': {
										borderBottomColor: 'primary.light',
									},
								}}
								hideFooter
							/>
						</Box>
						{searchquery == 0 ? (
							<Box className={styles.pagination_box}>
								<IconButton
									disabled={PageCount > 1 ? false : true}
									onClick={() => PreviousHandler()}>
									<KeyboardArrowLeftIcon
										sx={{
											cursor: 'pointer',
											fontSize: '1.9rem',
											color: 'primary.main',
										}}
										titleAccess='Previous Page'
									/>
								</IconButton>
								<Typography sx={{ fontSize: '1.4rem', color: 'primary.main' }}>
									{PageCount}
								</Typography>
								<IconButton
									disabled={
										totalPageCount > 1 && PageCount < totalPageCount
											? false
											: true
									}
									onClick={() => NextHandler()}>
									<KeyboardArrowRightIcon
										sx={{
											cursor: 'pointer',
											fontSize: '1.9rem',
											color: 'primary.main',
										}}
										titleAccess='Next Page'
									/>
								</IconButton>
							</Box>
						) : (
							''
						)}
					</Box>
				</Box>
			</Box>
		</>
	);
}
