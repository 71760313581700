import { Box, Divider, Modal, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CancelIcon from '@mui/icons-material/Cancel';
import { API } from '../../api-services';
import FeatureTableRows from './FeatureTableRows';
import { debounce } from 'lodash';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "90rem",
  height: '80vh',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  p: 1,
};

export interface FeatureTableParameterProps {
  isOpen: any;
  onCloseModal: any;
  tableIds: any;
  categoryIds: any;
  setValidationdata: any;
  setFeatureCounter: any;
  setcategoryCount: any
}

const FeatureTableParameter = (props: FeatureTableParameterProps) => {
  const { isOpen,
    onCloseModal,
    tableIds,
    setFeatureCounter,
    setcategoryCount,
    categoryIds,
    setValidationdata

  } = props
  const [loader, setloader] = useState<any>(false)
  const [TableList, setTableList] = useState<any>([])
  const [updateLoader, setupdateLoader] = useState<any>(false);
  const {
    projectId,
    projectname,
    categoryId,
    productId,
    datatype,
    mainId
  } = useRouteParams<any>();
  useEffect((

  ) => {
    setloader(true)
    API.get("/bom/parameter_mapping/", { table_id: tableIds }).then((res: any) => {
      setTableList(res?.data)
      setloader(false)
    }).catch((err: any) => {
      setloader(false)
    })

  }, [tableIds])
  const ExactDebouncedropdown = React.useRef(
    debounce(async (criteria: any, id: any, rowId: any, category: any) => {
      {
        setupdateLoader(true);

        await API.put(`/bom/parameter_mapping/${id}/`, {
          required: criteria,
        }).then((res: any) => {
          API.patch("/config/feature_config/", {
            id: mainId,
            validated: false,
          })
            .then((res: any) => {
              setValidationdata(res?.data?.validated);
              setFeatureCounter((prev: any) => prev + 1)
              setcategoryCount((prev: any) => prev + 1)
            })
            .catch((err: any) => {
              const { data } = err?.response;
              setValidationdata(false);


            });


        });
      }
    }, 900)
  ).current;
  const getDropDownData = (data: any, id: any) => {
    ExactDebouncedropdown(data, id, tableIds, categoryIds);
  };

  const ExactDebounce = React.useRef(
    debounce((criteria: any, id: any, rowId: any, category: any) => {
      if (criteria.toString()?.length > 0) {
        setTableList([])
        setloader(true);
        API.put(`/bom/parameter_mapping/${id}/`, {
          sequence: parseInt(criteria),
        }).then((res: any) => {



          API.get("/bom/parameter_mapping/", { table_id: rowId })
            .then((res1: any) => {

              API.patch("/config/feature_config/", {
                id: mainId,
                validated: false,
              })
                .then((res: any) => {
                  setValidationdata(res?.data?.validated);
                  setFeatureCounter((prev: any) => prev + 1)
                  setcategoryCount((prev: any) => prev + 1)
                })
                .catch((err: any) => {
                  const { data } = err?.response;
                  setValidationdata(false);


                });
              setloader(false);
              setTableList(res1?.data);
            })
            .catch((err: any) => {
              setloader(false);
              console.log("Sever Error");
            });
        }).catch((err: any) => {
          setloader(false)
        })
      }
    }, 900)
  ).current;
  const getData = (data: any, id: any) => {
    ExactDebounce(data, id, tableIds, categoryIds);
  };



  return (
    <div>

      <Modal

        open={isOpen}
        keepMounted
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Feature Table Parameter
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ width: '100%', height: { lg: '70vh', xl: '74vh' } }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3">
            <Table>
              <TableHead
                sx={{
                  backgroundColor: "primary.light",
                  color: "white",
                  position: "sticky",
                  top: "0",
                  zIndex: "1",
                }}>
                <TableRow>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem",borderBottomColor:'primary.light'}}>
                    Sequence
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem",borderBottomColor:'primary.light'}}>
                    Name
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem",borderBottomColor:'primary.light'}}>
                    Unit
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem",borderBottomColor:'primary.light'}}>
                    Data Type
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem",borderBottomColor:'primary.light'}}>
                    Exceptions
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem",borderBottomColor:'primary.light'}}>
                    Allowed Values
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem",borderBottomColor:'primary.light'}}>
                    Not Allowed Values
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem",borderBottomColor:'primary.light'}}>
                    Default Value
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem",borderBottomColor:'primary.light'}}>
                    Guideline Doc
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0.2rem 0.5rem",borderBottomColor:'primary.light'}}>
                    Required at
                  </TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {loader ? (
                  <>
                    <TableRow>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                    </TableRow> <TableRow>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <Box>
                          <Skeleton></Skeleton>
                        </Box>
                      </TableCell>
                    </TableRow>

                  </>
                ) : (
                  <>
                    {TableList &&
                      TableList?.map((item: any, index: any) => {
                        return (
                          <>
                            <FeatureTableRows
                              keys={index}
                              updateLoader={updateLoader}
                              getDropDownData={getDropDownData}
                              getData={getData}
                              sequence={item && item?.sequence}
                              rowItems={item && item}

                            />
                          </>
                        );
                      })}
                  </>
                )}


              </TableBody>
            </Table>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}

export default FeatureTableParameter;