import { Box, Skeleton, TableCell, TableRow, TextField } from "@mui/material";
import { isUndefined } from "lodash";
import React, { useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import { API } from "../../api-services";
import _ from "lodash";
// import swal from 'sweetalert';
import Swal from "sweetalert2";
import { error } from "console";
interface ProductCategoryRowProps {
  rowItem: any;
  savetype: any;
  setsaveType: any;
  setCounter: any;
  GetRowIds: any;
}

interface ColorPickerProps {
  label?: string;
  value?: string;
  onChange: (color: string) => void;
}

const ProductCategoryRow = (props: ProductCategoryRowProps) => {
  const { rowItem, savetype, setsaveType, setCounter, GetRowIds } = props;
  const [loader, setloader] = useState<any>(false);
  const [updatedIds, setUpdatedIds] = useState<any>();
  const [Name, setName] = useState<any>();
  const [Abbreviation, setAbbreviation] = useState<any>();
  const [Description, setDescription] = useState<any>();
  const [ProductId, setProductId] = useState<any>();
  const [errorAcceptance, setErrortAcceptance] = useState<any>();
  const [Color, setColor] = useState<any>(false);
  const [nameError, setnameError] = useState<any>(false);
  const [AbbrError, setAbbrErrorr] = useState<any>(false);
  const [ProductError, setProductError] = useState<any>(false);
  const [errorAcceptanceError, setErrorAcceptanceError] = useState<any>(false);
  const [desError, setdesError] = useState<any>(false);
  const [colorPicker, setColorPicker] = useState<string>("");

  React.useEffect(() => {
    if (rowItem?.color) {
      setColorPicker(rowItem?.color);
    }
  }, [rowItem]);

  const handleChangecolorPicker = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedColor = event.target.value;
    setColorPicker(selectedColor);
  };

  useEffect(() => {
    if (!isUndefined(rowItem)) {
      setName(rowItem?.name);
      setAbbreviation(rowItem?.abbreviation);
      setDescription(rowItem?.description);
      setProductId(rowItem?.product_id);
      var multipliedValue =
        rowItem?.error_acceptance && Number(rowItem?.error_acceptance) * 100; // Multiply by 100
      setErrortAcceptance(multipliedValue);
    }
  }, [rowItem]);

  const checkUpdateFileddata = (Item: any) => {
    var dividedValue = errorAcceptance && Number(errorAcceptance) / 100; // Divide by 100
    return {
      name: Name,
      description: Description,
      abbreviation: Abbreviation,
      error_acceptance: dividedValue,
	  color: colorPicker
    };
  };

  const handleChange = (e: any, type: any) => {
    setColor(true);

    if (type == "name") {
      setnameError(false);
      setName(e?.target?.value);
    }
	if(type == "color"){
		setColorPicker(e.target.value);
	}
    if (type == "abbr") {
      setAbbrErrorr(false);
      setAbbreviation(e?.target?.value);
    }
    if (type == "description") {
      setdesError(false);
      setDescription(e?.target?.value);
    }
    if (type == "product_id") {
      setProductError(false);
      setProductId(e?.target?.value);
    }
    if (type == "error_acceptance") {
      setErrorAcceptanceError(false);
      setErrortAcceptance(e?.target?.value);
    }
  };

  const CreateSaveRowHandler = (Id: any) => {
    if (savetype == "create") {
      if (
        Name !== null &&
        Name?.length > 0 &&
        Abbreviation !== null &&
        Abbreviation?.length > 0 &&
        Description !== null &&
        Description?.length > 0
      ) {
        setUpdatedIds(Id);
        setloader(true);
        API.post("/supplier/vehicle_category/", {
          name: Name,
          abbreviation: Abbreviation,
          description: Description,
          error_acceptance: errorAcceptance,
		  color: colorPicker
        })
          .then((res: any) => {
            setsaveType("update");
            setloader(false);
            setColor(false);
            setCounter((prev: any) => prev + 1);
          })
          .catch((err: any) => {
            setloader(false);
            console.log("Sever error");
          });
      }
      if (Name !== null && Name?.length == 0) {
        setnameError(true);
      }
      if (Description !== null && Description?.length == 0) {
        setdesError(true);
      }
      if (Abbreviation !== null && Abbreviation?.length == 0) {
        setAbbrErrorr(true);
      }
      if (errorAcceptance !== null && errorAcceptance?.length == 0) {
        setErrorAcceptanceError(true);
      }
    } else {
      if (
        Name !== null &&
        Name?.length > 0 &&
        Abbreviation !== null &&
        Abbreviation?.length > 0 &&
        Description !== null &&
        Description?.length > 0 &&
        errorAcceptance !== null &&
        errorAcceptance?.toString()?.length > 0
      ) {
        setloader(true);
        setUpdatedIds(Id);
        API.put(`/supplier/vehicle_category/${Id}/`, {
          ...checkUpdateFileddata(rowItem),
        })
          .then((res: any) => {
            setloader(false);
            setCounter((prev: any) => prev + 1);
            setColor(false);
          })
          .catch((err: any) => {
            setloader(false);
            console.log("Sever error");
          });
      }
      if (Name !== null && Name?.length == 0) {
        setnameError(true);
      }
      if (Description !== null && Description?.length == 0) {
        setdesError(true);
      }
      if (Abbreviation !== null && Abbreviation?.length == 0) {
        setAbbrErrorr(true);
      }
      if (ProductId !== null && ProductId?.toString()?.length == 0) {
        setProductError(true);
      }
      if (
        errorAcceptance !== null &&
        errorAcceptance?.toString()?.length == 0
      ) {
        setErrorAcceptanceError(true);
      }
    }
  };
  const DeleteHanlder = (Id: any) => {
    Swal.fire({
      text: "Are you sure you want to delete ?",
      showCancelButton: true,
      confirmButtonColor: "#e7483d !important",
      confirmButtonText: "Yes, delete it!",
      icon: "warning",
    }).then((confirm) => {
      if (confirm) {
        API.delete(`/supplier/vehicle_category/${Id}/`, { is_deleted: true }, 0)
          .then((res: any) => {
            setCounter((prev: any) => prev + 1);
          })
          .catch((err: any) => {
            const { data } = err?.response;
            Swal.fire({
              icon: "error",
              html: `<div>
                        <br />
                        <p style="font-size: 1rem,color:"red">${data[0]}</p>
                         </div>`,
            });
          });
      }
    });
  };

  const InformationHanlder = (id: any) => {
    GetRowIds(id);
  };

  return (
    <>
      {updatedIds == rowItem?.id && loader ? (
        <>
          <TableRow>
            <TableCell sx={{ padding: "0.2rem 0.5rem" }}>
              <Skeleton animation="wave" />
            </TableCell>
            <TableCell sx={{ padding: "0.2rem 0.5rem" }}>
              <Skeleton animation="wave" />
            </TableCell>
            <TableCell sx={{ padding: "0.2rem 0.5rem" }}>
              <Skeleton animation="wave" />
            </TableCell>
            <TableCell sx={{ padding: "0.2rem 0.5rem" }}>
              <Skeleton animation="wave" />
            </TableCell>
          </TableRow>
        </>
      ) : (
        <TableRow>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <TextField
              variant="standard"
              error={nameError}
              value={Name}
              focused={rowItem?.status}
              onChange={(e: any) => handleChange(e, "name")}
              size="small"
              InputProps={{
                style: { fontSize: "1rem" },
              }}
              sx={{
                "& .MuiInput-underline:before": { borderBottomColor: "white" },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
              }}
            />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <TextField
              focused={rowItem?.status}
              variant="standard"
              error={AbbrError}
              value={Abbreviation}
              onChange={(e: any) => handleChange(e, "abbr")}
              size="small"
              InputProps={{
                style: { fontSize: "1rem" },
              }}
              sx={{
                "& .MuiInput-underline:before": { borderBottomColor: "white" },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
              }}
            />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <TextField
              focused={rowItem?.status}
              variant="standard"
              error={desError}
              value={Description}
              onChange={(e: any) => handleChange(e, "description")}
              size="small"
              InputProps={{
                style: { fontSize: "1rem" },
              }}
              sx={{
                width: "100%",
                "& .MuiInput-underline:before": { borderBottomColor: "white" },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
              }}
            />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {rowItem?.product_id}
          </TableCell>
          <TableCell
            sx={{
              padding: "0.1rem 0.2rem",
              borderBottomColor: "primary.light",
            }}
          >
            <TextField
              focused={rowItem?.status}
              variant="standard"
              error={desError}
              value={errorAcceptance}
              type="number"
              onChange={(e: any) => handleChange(e, "error_acceptance")}
              size="small"
              InputProps={{
                style: { fontSize: "1rem" },
              }}
              sx={{
                "& .MuiInput-underline:before": { borderBottomColor: "white" },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
              }}
            />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.1rem 0.2rem",
              borderBottomColor: "primary.light",
            }}
          >
            <TextField
              //   label={label}
              type="color"
              value={colorPicker}
              //@ts-ignore
              onChange={(e:any)=>handleChange(e, "color")}
              InputLabelProps={{ shrink: true }} // Keeps the label shrunk
              sx={{
                width: "100px",
                "& .MuiInputBase-input": {
                  padding: "8px", // Adjusts padding for better alignment
                },
              }}
            />
          </TableCell>

          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <SaveIcon
              sx={{ color: Color ? "red" : "primary.main", cursor: "pointer" }}
              onClick={() => CreateSaveRowHandler(rowItem?.id)}
            />

            {!rowItem?.allow_delete ? (
              <InfoIcon
                color="primary"
                sx={{ cursor: "pointer" }}
                onClick={() => InformationHanlder(rowItem?.id)}
              />
            ) : (
              <DeleteIcon
                color="primary"
                sx={{ cursor: "pointer" }}
                onClick={() => DeleteHanlder(rowItem?.id)}
              />
            )}
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default ProductCategoryRow;
