import React from 'react';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import * as yup from 'yup';
import {
	Autocomplete,
	Divider,
	FormControl,
	InputLabel,
	TextField,
	Tooltip,
} from '@mui/material';
import {
	GridCsvExportMenuItem,
	GridCsvExportOptions,
	GridToolbarContainer,
	GridToolbarExportContainer,
} from '@mui/x-data-grid';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { API } from '../../api-services';
import { error } from 'console';
import Swal from 'sweetalert2';
interface AddCategoryModalProps {
	onCloseModal: any;
	isOpen: any;
	setCounter: any;
}

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '45vw',
	height: 'fit-content',
	bgcolor: 'background.paper',
	borderRadius: '10px',
	// padding: "1rem",
};
const validationSchema = yup.object({
	name: yup.string().required('Please type Part name'),
	description: yup.mixed().required('Please type description'),
	abbreviation: yup.mixed().required('Please type abbreviation'),
	error_acceptance: yup.number().required('Please type error acceptance'),
	product_id: yup.number().required('Please type category ID'),
});
const csvOptions: GridCsvExportOptions = {
	fileName: 'Table In CSV',
	delimiter: ',',
	utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
	<GridToolbarExportContainer {...props}>
		<GridCsvExportMenuItem options={csvOptions} />
	</GridToolbarExportContainer>
);

const CustomToolbar = () => (
	<GridToolbarContainer style={{ height: '1.5rem', marginBottom: '0.5rem' }}>
		<span style={{ position: 'absolute', right: '0rem' }}>
			{/* <GridToolbarColumnsButton /> */}
			{/* <GridToolbarDensitySelector /> */}
			<CustomExportButton />
		</span>
	</GridToolbarContainer>
);
const AddCategoryPopup = (props: AddCategoryModalProps) => {
	const { onCloseModal, isOpen, setCounter } = props;
	// const { height, width } = useWindowDimensions();
	const [loader, setLoader] = React.useState<boolean>(false);
	// const history = useHistory();
	// const [BOMCategory, setBOMCategory] = React.useState<any>([]);
	// const [vehicleCategory, setVeicleCategory] = React.useState<any>([]);

	const formik = useFormik({
		initialValues: {
			name: '',
			description: '',
			abbreviation: '',
			error_acceptance: '',
			product_id: null,
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			var dividedValue = values.error_acceptance && Number(values.error_acceptance)/ 100; // Divide by 100
			
			setLoader(true);
			API.post(
				`/supplier/vehicle_category/`,
				{
					//@ts-ignore
					name: values.name,
					//@ts-ignore
					description: values.description,
					//@ts-ignore
					abbreviation: values.abbreviation,
					error_acceptance: dividedValue,
					product_id: values.product_id,
				},
				{},
				0
			)
				.then((res: any) => {
					setLoader(false);
					setCounter((prev: any) => prev + 1);
					onCloseModal();
					//   history.push("/data-base/data-base-landing-page");
				})
				.catch((err: any) => {
					setLoader(false);
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: err.response.data[0],
						customClass: {
							container: "swal2Container",
						  },
					});
				});

			// }else{
			//     history.push("/project_directory")
			// }
		},
	});

	return (
		<div>
			<Modal open={isOpen} onClose={onCloseModal}>
				<Box sx={style}>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							columnGap: '1rem',
							marginBottom: '0.5rem',
							// padding: '1rem',
						}}>
						<Typography
							style={{
								fontSize: '1rem',
								fontWeight: '600',
								width: '100%',
								textAlign: 'center',
								marginRight: '-2rem',
							}}>
							Add Product Category
						</Typography>
						<CancelIcon
							titleAccess='Close'
							sx={{ cursor: 'pointer', margin: '0.5rem' }}
							onClick={onCloseModal}
						/>
					</Box>
					<Divider
						sx={{
							borderColor: 'primary.light',
							margin: '0 1rem',
							marginBottom: '1rem',
						}}
					/>
					<Box sx={{ padding: '0 1rem 1rem' }}>
						<form onSubmit={formik.handleSubmit}>
							<FormControl fullWidth variant='standard'>
								<InputLabel
									sx={{ marginLeft: '1rem' }}
									shrink
									id='demo-simple-select-label'>
									Category Name <sup style={{ color: 'red' }}>*</sup>
								</InputLabel>
								<TextField
									variant='standard'
									InputLabelProps={{
										shrink: true,
									}}
									sx={{
										margin: '1rem 1rem',
										'& .Mui-error': {
											color: '#d32f2f',
											borderBottomColor: '#d32f2f',
										},
										'& .MuiInput-underline:before': {
											borderBottomColor: 'primary.light',
										},
										'& .MuiInput-underline:after': {
											borderBottomColor: 'primary.main',
										},
								
										'.MuiInputBase-root.MuiInput-root': {
											fontSize: '1rem !important',
										},
									}}
									id='name'
									name='name'
									onChange={formik.handleChange}
									value={formik.values.name}
									error={formik.touched.name && Boolean(formik.errors.name)}
									helperText={formik.touched.name && formik.errors.name}
								/>
							</FormControl>
							<FormControl fullWidth variant='standard'>
								<InputLabel
									sx={{ marginLeft: '1rem' }}
									shrink
									id='demo-simple-select-label'>
									Description <sup style={{ color: 'red' }}>*</sup>
								</InputLabel>
								<TextField
									variant='standard'
									InputLabelProps={{
										shrink: true,
									}}
									sx={{
										margin: '1rem 1rem',
										'& .Mui-error': {
											color: '#d32f2f',
											borderBottomColor: '#d32f2f',
										},
										'& .MuiInput-underline:before': {
											borderBottomColor: 'primary.light',
										},
										'& .MuiInput-underline:after': {
											borderBottomColor: 'primary.main',
										},
								
										'.MuiInputBase-root.MuiInput-root': {
											fontSize: '1rem !important',
										},
									}}
									id='description'
									name='description'
									onChange={formik.handleChange}
									value={formik.values.description}
									error={formik.touched.description && Boolean(formik.errors.description)}
									helperText={formik.touched.description && formik.errors.description}
								/>
							</FormControl>
							<Box
								sx={{
									display: 'grid',
									gridTemplateColumns: '1fr 1fr',
									columnGap: '1rem',
								}}>
								<FormControl fullWidth variant='standard'>
									<InputLabel
										sx={{ marginLeft: '1rem' }}
										shrink
										id='demo-simple-select-label'>
										Abbreviation <sup style={{ color: 'red' }}>*</sup>
									</InputLabel>
									<TextField
										variant='standard'
										InputLabelProps={{
											shrink: true,
										}}
										sx={{
											margin: '1rem 1rem',
											'& .Mui-error': {
												color: '#d32f2f',
												borderBottomColor: '#d32f2f',
											},
											'& .MuiInput-underline:before': {
												borderBottomColor: 'primary.light',
											},
											'& .MuiInput-underline:after': {
												borderBottomColor: 'primary.main',
											},											
											'.MuiInputBase-root.MuiInput-root': {
												fontSize: '1rem !important',
											},
										}}
										id='abbreviation'
										name='abbreviation'
										onChange={formik.handleChange}
										value={formik.values.abbreviation}
										error={
											formik.touched.abbreviation &&
											Boolean(formik.errors.abbreviation)
										}
										helperText={
											formik.touched.abbreviation && formik.errors.abbreviation
										}
									/>
								</FormControl>
								<FormControl fullWidth variant='standard'>
									<InputLabel
										sx={{ marginLeft: '1rem' }}
										shrink
										id='demo-simple-select-label'>
										Error Acceptance <sup style={{ color: 'red' }}>*</sup>
									</InputLabel>
									<TextField
										variant='standard'										
										inputProps={{
											step: 'any',
											min: 0,
											max: 1,											
										}}
										InputLabelProps={{
											shrink: true,
										}}
										sx={{
											margin: '1rem 1rem',
											'& .Mui-error': {
												color: '#d32f2f',
												borderBottomColor: '#d32f2f',
											},
											'& .MuiInput-underline:before': {
												borderBottomColor: 'primary.light',
											},
											'& .MuiInput-underline:after': {
												borderBottomColor: 'primary.main',
											},
											
											'.MuiInputBase-root.MuiInput-root': {
												fontSize: '1rem !important',
											},
										}}
										type='number'
										id='error_acceptance'
										name='error_acceptance'
										onChange={formik.handleChange}
										value={formik.values.error_acceptance}
										error={
											formik.touched.error_acceptance &&
											Boolean(formik.errors.error_acceptance)
										}
										helperText={
											formik.touched.error_acceptance &&
											formik.errors.error_acceptance
										}
									/>
								</FormControl>
							</Box>
							<FormControl fullWidth variant='standard'>
								<InputLabel
									sx={{ marginLeft: '1rem' }}
									shrink
									id='demo-simple-select-label'>
									Product ID <sup style={{ color: 'red' }}>*</sup>
								</InputLabel>
								<TextField
									variant='standard'
									// autoFocus={autoFocusStatus}
									inputProps={{
										// style: { color: '#007fff' },
										step: 'any',
										min: 1,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									sx={{
										margin: '1rem 1rem',
										'& .Mui-error': {
											color: '#d32f2f',
											borderBottomColor: '#d32f2f',
										},
										'& .MuiInput-underline:before': {
											borderBottomColor: 'primary.light',
										},
										'& .MuiInput-underline:after': {
											borderBottomColor: 'primary.main',
										},
								
										'.MuiInputBase-root.MuiInput-root': {
											fontSize: '1rem !important',
										},
									}}
									type='number'
									id='product_id'
									name='product_id'
									onChange={formik.handleChange}
									value={formik.values.product_id}
									error={
										formik.touched.product_id &&
										Boolean(formik.errors.product_id)
									}
									helperText={
										formik.touched.product_id && formik.errors.product_id
									}
								/>
							</FormControl>
							<Box sx={{ textAlign: 'right', marginRight: '1rem' }}>
								<LoadingButton
									size='small'
									variant='contained'
									type='submit'
									loading={loader}>
									Add Category
								</LoadingButton>
							</Box>
						</form>
					</Box>
				</Box>
			</Modal>
		</div>
	);
};

export default AddCategoryPopup;
