import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import * as React from "react";
import InfoIcon from "@mui/icons-material/Info";
import styles from "./CreateFeature.module.scss";
import { FeatureProductandSystemTreeDetails } from "./FeatureProductandSystemTreeDetails";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import FeatureInfo from "./FeatureInfo";
import { API } from "../../api-services";
import Image from "../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import LoadingImage from "../../Assets/images/loading-screen.gif";
import RViewerJS from "viewerjs-react";
import BarChartIcon from "@mui/icons-material/BarChart";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FeatureImageUpload from "./FeatureImageUpload";
import swal from "sweetalert";
import { ParamsInputText } from "./ParamsInputText";
import { EditAvailability } from "./EditAvailability";
import NotAvailImage from "../../Assets/images/imageIcon.png";
import S3FeatureVideoUpload from "./S3FeatureVideoUpload";
import FeatureVideoRenderModal from "./FeatureVideoRenderModal";
import { FeatureCostUpdate } from "./FeatureCostUpdateInput";
import { ParamsMultiselect } from "./ParamsMultiselect";
import VideoIcon from "../../Assets/images/VideoIcon.svg";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import { AdditionalInput } from "./AdditionalInputs";
import { useBaseModal } from "../SearchPage/useBaseModal";
import VideoMute from "../../Assets/images/VideoMute.svg";
import VideAudio from "../../Assets/images/VideoAudio.svg";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import LoaderImage from "../../Assets/images/loading-screen-landing.gif";
import FeatureStatTable from "./FeatureStatTable";
import featureIcon from "../../Assets/images/FeatureIcon.png";
import { LibraryParameterDropdownBox } from "./LibraryParameterDropdownBox";
import { LibraryParamsInput } from "./LibraryParamsInput";
import { ADMIN } from "../../Redux/Services/admin.service";
import ErrorModalFilesMail from "../ProjectMailModule/ErrorModalFilesMail";
import { validationCheckCounter } from "../../Redux/Actions/compareopen.action";

export interface ICreateFeatureProps { }

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
export function CreateFeature() {
  const {
    projectId,
    projectname,
    type,
    parent,
    child,
    featureId,
    feId,
    pageNumber,
  } = useRouteParams<any>();
  const inputElRefPdf = React.useRef<any>();
  const inputElRefVideo = React.useRef<any>();
  const { height, width } = useWindowDimensions();
  const [totalPages, setTotalPages] = React.useState<any>();
  const [permission, setPermission] = React.useState<any>("");
  const [isActive, setIsActive] = React.useState(true);
  const handleClick = () => {
    // 👇️ toggle
    setIsActive((current) => !current);

    // 👇️ or set to true
    // setIsActive(true);
  };
  React.useEffect(() => {
    if (feId != 0) {
      API.get("/xcpep/feature_details/", {
        feature: parseInt(feId),
        abbreviation: parent,
        total_page: true,
      })
        .then((res: any) => {
          if (res.data) {
            setTotalPages(Math.round(res.data.total_page / 4));
          }
        })
        .catch(() => { });
    }
  }, [feId]);

  React.useEffect(() => {
    API.get(
      "/auth/user_permission_module/",
      {
        action: true,
        project: projectId,
        feature_create: true,
      },
      0
    )
      .then((res: any) => {
        setPermission(res.data[0]?.action);
      })
      .catch((err: any) => { });
  }, [projectId]);

  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const infoModal = useBaseParamsModal();
  const statModal = useBaseModal();
  const store: any = useSelector((state) => state);
  const history = useHistory();
  const [selectedType, setSelectedType] = React.useState<any>(1);
  const [featureData, setFeatureData] = React.useState<any>([]);
  const [selectedId, setSelectedId] = React.useState<any>();
  const [featureDataLoading, setFeatureDataLoading] =
    React.useState<any>(false);
  const [imageUploadLoading, setImageUploadLoading] =
    React.useState<any>(false);
  const [videoLoading, setVideoLoading] = React.useState<any>(false);
  const [uploadingImageId, setUploadingImageId] = React.useState<any>();
  const [loadingImageId, setLoadingImageId] = React.useState<any>();
  const [loadingVideoId, setLoadingVideoId] = React.useState<any>();
  const [videoStatus, setVideoStatus] = React.useState<any>(false);
  const [FeatureCost, setFeatureCost] = React.useState(false)
  const singleVideoRenderModal = useBaseParamsModal();
  const [ErrorModalFileMsg, setErrorModalFileMsg] = React.useState<any>();
  const ErrorModalFiles = useBaseModal()
  const uploadImage = (idd: any, ImageId: any) => {
    setLoadingImageId(ImageId);
    setSelectedId(idd);
    return inputElRefPdf.current?.click?.();
  };

  const uploadVideo = (idd: any, ImageId: any, muteStatus: any) => {
    setVideoStatus(muteStatus);
    setSelectedId(idd);
    setLoadingVideoId(ImageId);
    return inputElRefVideo.current?.click?.();
  };
  const handleInputClick = (e: React.MouseEvent) => {
    // clear the prev value on click so that same file can be uploaded again
    (e.target as HTMLInputElement).value = "";
  };
  const handleInputClickVideo = (e: React.MouseEvent) => {
    // clear the prev value on click so that same file can be uploaded again
    (e.target as HTMLInputElement).value = "";
  };

  const UploadFile = (
    e: any,
    imageId: any,
    parentId: any,
    item: any,
    index: any
  ) => {
    setImageUploadLoading(true);
    if (selectedId) {
      setUploadingImageId(selectedId);
      FeatureImageUpload(
        [e.target.files[0]],
        loadingImageId,
        setImageUploadLoading,
        projectId,
        selectedId,
        getFeatureData,
        dispatch
      );
    }
  };
  const UploadFileVideo = (e: any, imageId: any, is_mute: any) => {
    setVideoLoading(true);
    if (selectedId) {
      setUploadingImageId(imageId);
      S3FeatureVideoUpload(
        [e.target.files[0]],
        loadingVideoId,
        setVideoLoading,
        projectId,
        selectedId,
        getFeatureData,
        videoStatus,
        dispatch
      );
    }
  };
  const handleFeatureTypeChange = (types: any) => {
    history.push(
      `/createfeature/${projectId}/${projectname}/${types}/${0}/${0}/0/0/${pageNumber}`
    );
    setSelectedType(types);
  };
  const getFeatureData = () => {
    setFeatureDataLoading(true);
    API.get("/xcpep/feature_details/", {
      feature: parseInt(feId),
      abbreviation: parent,
      page: Number(pageNumber),
    })
      .then((res) => {
        setFeatureData(res.data);
        setFeatureDataLoading(false);
      })
      .catch(() => {
        setFeatureDataLoading(false);
      });
  };

  const getFeatureDataWOL = () => {
    API.get("/xcpep/feature_details/", {
      feature: parseInt(feId),
      abbreviation: parent,
      page: Number(pageNumber),
    })
      .then((res) => {
        setFeatureData(res.data);
      })
      .catch(() => {
      });
  };

  React.useEffect(() => {
    if (feId != "0") {
      getFeatureData();
    }
  }, [pageNumber, feId, store?.is_compare?.actionCount]);

  React.useEffect(() => {
    API.get(
      `xcpep/product_feature/`,
      {
        id: feId,
      },
      0
    )
      .then((res: any) => {
        setFeatureCost(res?.data[0]?.feature_cost_na);
      })
      .catch((err: any) => { });
  }, [feId]);
  const handleChange3 = (event: any) => {

    API.put(`xcpep/product_feature/${feId}/`, { feature_cost_na: event?.target?.checked }).then((res: any) => {
      API.get(
        `xcpep/product_feature/`,
        {
          id: feId,
        },
        0
      )
        .then((res: any) => {
          setFeatureCost(res?.data[0]?.feature_cost_na);
        })
        .catch((err: any) => { });
    }).catch((err: any) => {
      const { data } = err?.response
      ErrorModalFiles.open()
      setFeatureCost(false);
      setErrorModalFileMsg(data[0])
    })
  };

  return (
    <div>
      {infoModal.isOpen && (
        <FeatureInfo
          isOpen={infoModal.isOpen}
          onCloseModal={infoModal.close}
          type={infoModal.propsId}
        />
      )}
      {
        <ErrorModalFilesMail
          isOpen={ErrorModalFiles.isOpen}
          onCloseModal={ErrorModalFiles.close}
          ErrorMsg={ErrorModalFileMsg}
        />
      }

      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          // height: { lg: "86vh", xl: "89vh" },
          width: { xs: "90rem", sm: "100%" },
          height: {
            xs: "86vh",
            sm: "86vh",
            md: "86vh",
            lg: "85vh",
            xl: "89vh",
          },
          boxShadow:
            "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
          borderRadius: "10px",
          overflow: "visible",
        }}
        adl-scrollbar="true"
        adl-scrollbar-width="0.3"
      >
        <Box className={isActive ? styles.slideactive : styles.slideinactive}>
          <Box className={styles.tabBox}>
            <Box
              className={
                type === 1 ? styles.featuretabActive : styles.featuretabInactive
              }
              onClick={() => handleFeatureTypeChange(1)}
            >
              Feature <InfoIcon onClick={() => infoModal.open(0)} />
            </Box>
            <Box
              className={
                type === 2 ? styles.featuretabActive : styles.featuretabInactive
              }
              onClick={() => handleFeatureTypeChange(2)}
            >
              Specification <InfoIcon onClick={() => infoModal.open(1)} />{" "}
            </Box>
            <Box
              className={
                type === 3 ? styles.featuretabActive : styles.featuretabInactive
              }
              onClick={() => handleFeatureTypeChange(3)}
            >
              Information <InfoIcon onClick={() => infoModal.open(2)} />{" "}
            </Box>
          </Box>
          <Box>
            <FeatureProductandSystemTreeDetails selectedType={selectedType} />
          </Box>
          <Box className={styles.featuretreefoot}>
            <Box></Box>
            <Box className={styles.slideIcon}>
              <ArrowCircleLeftIcon
                onClick={handleClick}
                sx={{ cursor: "pointer" }}
              />
            </Box>
          </Box>
          {isActive ? (
            ""
          ) : (
            <>
              <Box className={styles.slideIcon}>
                <ArrowCircleRightIcon
                  sx={{ marginLeft: "-2.5rem", cursor: "pointer", zIndex: 2 }}
                  onClick={handleClick}
                />
              </Box>
            </>
          )}
        </Box>
        <Box
          sx={{
            width: { xs: "90rem", sm: "100%" },
            height: {
              xs: "86vh",
              sm: "86vh",
              md: "86vh",
              lg: "85vh",
              xl: "89vh",
            },
            display: "flex",
            alignItems: "flex-start",
            padding: isActive ? "0 0 0 0.5rem" : "0",
            zIndex: 1,
            borderTopLeftRadius: "10px",
          }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          {feId != 0 && !featureDataLoading ? (
            <Table
            // sx={{
            //   width: { xs: "20rem", lg: "14vw", xl: "13vw" },
            //   minWidth: { lg: "14vw", xl: "13vw" },
            // }}
            >
              <TableHead
                sx={{
                  backgroundColor: "primary.light",
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                      width: "15vw",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Box>Parameter</Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: "1rem",
                          padding: "0 1rem",
                        }}
                      >
                        {Number(pageNumber) === 2 ? (
                          <IconButton
                            sx={{
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              color: "primary.main",
                              backgroundColor: "#007fff14",
                              borderRadius: "50%",
                              width: "1.7rem",
                              height: "1.7rem",
                            }}
                            disabled={Number(pageNumber) === 1 ? true : false}
                            // '/createfeature/:projectId/:projectname/:type/:parent/:child/:featureId/:feId/:pageNumber'
                            onClick={() =>
                              history.push(
                                `/createfeature/${projectId}/${projectname}/${type}/${parent}/${child}/${featureId}/${feId}/${Number(pageNumber) - 1
                                }`
                              )
                            }
                          >
                            <ChevronLeftIcon
                              titleAccess="Previous"
                              sx={{ fontSize: "1.8rem" }}
                            />
                          </IconButton>
                        ) : (
                          ""
                        )}
                      </Box>
                    </Box>
                  </TableCell>
                  {featureData &&
                    feId != "0" &&
                    featureData?.map((item: any) => {
                      return (
                        <>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item["part_name  "]}
                          </TableCell>
                        </>
                      );
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        height: "2.5rem",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Availability
                    </Typography>
                  </TableCell>
                  {featureData &&
                    feId != "0" &&
                    featureData?.map((item: any) => {
                      return (
                        <>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Box sx={{ height: "2.5rem" }}>
                              <EditAvailability
                                value={item?.details?.Avalability?.value}
                                itemId={item?.id}
                                paramsId={item?.details?.Avalability?.id}
                                getFeatureData={getFeatureData}
                                permission={permission}
                              />
                            </Box>
                          </TableCell>
                        </>
                      );
                    })}
                </TableRow>

                {featureData[0]?.details?.Image?.map((ii: any, ind: any) => {
                  return (
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          height: { lg: "12rem", xl: "12rem" },
                          // textAlign:'right',
                          borderRight: "1px solid",
                          borderColor: "primary.light",
                        }}
                      >
                        {ii?.name}
                        {ii?.is_required && (
                          <sup style={{ color: "red" }}>*</sup>
                        )}
                      </TableCell>
                      {featureData &&
                        feId != "0" &&
                        featureData?.map((item: any, index: any) => {
                          return (
                            <>
                              {item?.details?.Avalability?.value === "Yes" ? (
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                    height: { lg: "12rem", xl: "12rem" },
                                    textAlign: "center",
                                    borderRight: "1px solid",
                                    borderColor: "primary.light",
                                  }}
                                >
                                  {imageUploadLoading &&
                                    loadingImageId ===
                                    item?.details?.Image[ind]?.id &&
                                    selectedId === item?.id ? (
                                    <Box className={styles.imageDiv}>
                                      <Image
                                        placeholderImg={LoaderImage}
                                        src={LoaderImage}
                                        style={{
                                          width: "100%",
                                          height: "65%",
                                          objectFit: "contain",
                                          padding: "0rem",
                                          opacity: 0.3,
                                        }}
                                      />
                                    </Box>
                                  ) : (
                                    <Box className={styles.imageDiv}>
                                      {item?.details?.Image[ind]?.value !==
                                        "Not Available" ? (
                                        <Box
                                          className={
                                            featureData?.length === 2
                                              ? styles.image_card_container2
                                              : featureData?.length === 3
                                                ? styles.image_card_container3
                                                : styles.image_card_container
                                          }
                                        >
                                          {/* @ts-ignore */}
                                          <RViewerJS>
                                            <Image
                                              placeholderImg={LoadingImage}
                                              src={
                                                item.details.Image[ind]?.value
                                              }
                                              style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                                cursor: "pointer",
                                                maxHeight:
                                                  width > 1370
                                                    ? "12rem"
                                                    : "12rem",
                                              }}
                                            />
                                          </RViewerJS>
                                        </Box>
                                      ) : (
                                        <Box
                                          className={
                                            featureData?.length === 2
                                              ? styles.image_card_container2
                                              : featureData?.length === 3
                                                ? styles.image_card_container3
                                                : styles.image_card_container
                                          }
                                        >
                                          <Image
                                            // placeholderImg={LoaderImage}
                                            src={NotAvailImage}
                                            style={{
                                              width: "100%",
                                              height: "65%",
                                              objectFit: "contain",
                                              padding: "0rem",
                                              opacity: 0.3,
                                            }}
                                          />
                                        </Box>
                                      )}
                                      <Box className={styles.action_container}>
                                        {permission.includes("U") && (
                                          <FileUploadIcon
                                            titleAccess="Upload"
                                            sx={{ cursor: "pointer" }}
                                            onClick={() =>
                                              uploadImage(
                                                item?.id,
                                                item?.details?.Image[ind].id
                                              )
                                            }
                                          />
                                        )}
                                        <input
                                          id="PDF Upload"
                                          accept="image/*"
                                          type="file"
                                          style={{ display: "none" }}
                                          onChange={(e: any) =>
                                            UploadFile(
                                              e,
                                              item?.details.Image[ind]?.id,
                                              item?.id,
                                              item,
                                              ind
                                            )
                                          }
                                          onClick={handleInputClick}
                                          ref={inputElRefPdf}
                                        />
                                        {/* <CameraAltIcon
                                        titleAccess="Capture"
                                        sx={{ cursor: "pointer" }}
                                      /> */}
                                        {permission?.includes("D") && (
                                          <DeleteIcon
                                            titleAccess="Delete"
                                            sx={{ cursor: "pointer" }}
                                            onClick={() => {
                                              swal({
                                                text: `Are you sure you want to delete ?`,
                                                icon: "error",
                                                buttons: [
                                                  "Cancel",
                                                  "Yes, Delete",
                                                ],
                                              }).then((confirm) => {
                                                if (confirm) {
                                                  // setPdfFileInfoLoader(true);
                                                  API.put(
                                                    `/xcpep/feature_details/${item?.id}/`,
                                                    {
                                                      [item?.details?.Image[ind]
                                                        ?.id]: null,
                                                    },
                                                    {},
                                                    0
                                                  )
                                                    .then(() => {
                                                      var randomm: any = Math.floor(Math.random() * 1000000 + 1);
                                                      dispatch(validationCheckCounter(randomm + 1));
                                                      getFeatureData();
                                                    })
                                                    .catch(() => { });
                                                }
                                              });
                                            }}
                                          />
                                        )}
                                      </Box>
                                    </Box>
                                  )}
                                </TableCell>
                              ) : (
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                    height: { lg: "12rem", xl: "12rem" },
                                    textAlign: "center",
                                  }}
                                >
                                  <Box className={styles.imageDiv}>
                                    <Box
                                      className={
                                        featureData?.length === 2
                                          ? styles.image_card_container2
                                          : featureData?.length === 3
                                            ? styles.image_card_container3
                                            : styles.image_card_container
                                      }
                                    >
                                      <Box sx={{ width: "100%" }}>
                                        {" "}
                                        <img
                                          src={featureIcon}
                                          style={{
                                            // width: "60%",
                                            height: "8rem",
                                            objectFit: "contain",
                                            opacity: 0.3,
                                          }}
                                        />
                                        <Typography
                                          sx={{
                                            fontSize: "0.87rem",
                                          }}
                                        >
                                          Feature does not exist
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                </TableCell>
                              )}
                            </>
                          );
                        })}
                    </TableRow>
                  );
                })}

                {/* Document Section */}

                {featureData[0]?.details?.Document?.map((ii: any, ind: any) => {
                  return (
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          height: { lg: "4rem", xl: "4rem" },
                          // textAlign:'right',
                          borderRight: "1px solid",
                          borderColor: "primary.light",
                        }}
                      >
                        {ii?.name}
                        {ii?.is_required && (
                          <sup style={{ color: "red" }}>*</sup>
                        )}
                      </TableCell>
                      {featureData &&
                        feId != "0" &&
                        featureData?.map((item: any, index: any) => {
                          return (
                            <>
                              {item?.details?.Avalability?.value === "Yes" ? (
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                    height: "4rem",
                                    textAlign: "center",
                                    borderRight: "1px solid",
                                    borderColor: "primary.light",
                                  }}
                                >
                                  {imageUploadLoading &&
                                    loadingImageId ===
                                    item?.details?.Document[ind]?.id &&
                                    selectedId === item?.id ? (
                                    <Box className={styles.imageDiv}>
                                      <Skeleton
                                        animation="wave"
                                        variant="rectangular"
                                        width="100%"
                                        height="5%"
                                      />
                                    </Box>
                                  ) : (
                                    <Box className={styles.imageDiv}>
                                      {item?.details?.Document[ind]?.value !==
                                        "Not Available" ? (
                                        <Box
                                          className={
                                            featureData?.length === 2
                                              ? styles.image_card_container2
                                              : featureData?.length === 3
                                                ? styles.image_card_container3
                                                : styles.image_card_container
                                          }
                                        >
                                          {/* @ts-ignore */}
                                          {/* <RViewerJS>
                                              <Image
                                                placeholderImg={LoadingImage}
                                                src={item.details.Image[ind]?.value}
                                                style={{
                                                  width: "100%",
                                                  height: "100%",
                                                  objectFit: "cover",
                                                  cursor: "pointer",
                                                  maxHeight:
                                                    width > 1370
                                                      ? "12rem"
                                                      : "12rem",
                                                }}
                                              />
                                            </RViewerJS> */}
                                          <span
                                            style={{
                                              textDecoration: "underline",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              window.open(
                                                item.details.Document[ind]
                                                  ?.value,
                                                "_blank",
                                                "noopener noreferrer"
                                              );
                                            }}
                                          >
                                            View Document
                                          </span>
                                        </Box>
                                      ) : (
                                        <Box
                                          className={
                                            featureData?.length === 2
                                              ? styles.image_card_container2
                                              : featureData?.length === 3
                                                ? styles.image_card_container3
                                                : styles.image_card_container
                                          }
                                        >
                                          Not Available
                                        </Box>
                                      )}
                                      <Box className={styles.action_container}>
                                        {permission.includes("U") && (
                                          <FileUploadIcon
                                            titleAccess="Upload"
                                            sx={{ cursor: "pointer" }}
                                            onClick={() =>
                                              uploadImage(
                                                item?.id,
                                                item?.details?.Document[ind].id
                                              )
                                            }
                                          />
                                        )}
                                        <input
                                          id="PDF Upload"
                                          accept=".doc,.docx,.pdf,.odt,.rtf,.txt,image/*"
                                          type="file"
                                          style={{ display: "none" }}
                                          onChange={(e: any) =>
                                            UploadFile(
                                              e,
                                              item?.details.Document[ind]?.id,
                                              item?.id,
                                              item,
                                              ind
                                            )
                                          }
                                          onClick={handleInputClick}
                                          ref={inputElRefPdf}
                                        />
                                        {/* <CameraAltIcon
                                        titleAccess="Capture"
                                        sx={{ cursor: "pointer" }}
                                      /> */}
                                        {permission?.includes("D") && (
                                          <DeleteIcon
                                            titleAccess="Delete"
                                            sx={{ cursor: "pointer" }}
                                            onClick={() => {
                                              swal({
                                                text: `Are you sure you want to delete ?`,
                                                icon: "error",
                                                buttons: [
                                                  "Cancel",
                                                  "Yes, Delete",
                                                ],
                                              }).then((confirm) => {
                                                if (confirm) {
                                                  // setPdfFileInfoLoader(true);
                                                  API.put(
                                                    `/xcpep/feature_details/${item?.id}/`,
                                                    {
                                                      [item?.details?.Document[
                                                        ind
                                                      ]?.id]: null,
                                                    },
                                                    {},
                                                    0
                                                  )
                                                    .then(() => {
                                                      var randomm: any = Math.floor(Math.random() * 1000000 + 1);
                                                      dispatch(validationCheckCounter(randomm + 1));
                                                      getFeatureData();
                                                    })
                                                    .catch(() => { });
                                                }
                                              });
                                            }}
                                          />
                                        )}
                                      </Box>
                                    </Box>
                                  )}
                                </TableCell>
                              ) : (
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                    height: "4rem",
                                    textAlign: "center",
                                  }}
                                >
                                  {/* <Box className={styles.imageDiv}>
                                    <Box
                                      className={
                                        featureData?.length === 2
                                          ? styles.image_card_container2
                                          : featureData?.length === 3
                                          ? styles.image_card_container3
                                          : styles.image_card_container
                                      }
                                    > */}
                                  <Box sx={{ width: "100%" }}>
                                    {" "}
                                    {/* <img
                                          src={featureIcon}
                                          style={{
                                            // width: "60%",
                                            height: "8rem",
                                            objectFit: "contain",
                                            opacity: 0.3,
                                          }}
                                        /> */}
                                    <Typography
                                      sx={{
                                        fontSize: "0.87rem",
                                      }}
                                    >
                                      Feature does not exist
                                    </Typography>
                                  </Box>
                                  {/* </Box>
                                  </Box> */}
                                </TableCell>
                              )}
                            </>
                          );
                        })}
                    </TableRow>
                  );
                })}

                {/* Video Section */}

                {featureData?.[0]?.details?.Video?.map(
                  (itm: any, indx: any) => {
                    return (
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                            height: { lg: "12rem", xl: "12rem" },
                            //  textAlign:'right',
                            borderRight: "1px solid",
                            borderColor: "primary.light",
                          }}
                        >
                          {itm?.name}
                          {itm?.is_required && (
                            <sup style={{ color: "red" }}>*</sup>
                          )}
                        </TableCell>
                        {featureData &&
                          feId != "0" &&
                          featureData?.map((item: any) => {
                            return (
                              <>
                                {item?.details?.Avalability?.value === "Yes" ? (
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                      height: { lg: "12rem", xl: "12rem" },
                                      textAlign: "center",
                                      borderRight: "1px solid",
                                      borderColor: "primary.light",
                                    }}
                                  >
                                    <Box className={styles.imageDiv}>
                                      {videoLoading &&
                                        selectedId === item?.id &&
                                        loadingVideoId ===
                                        item?.details?.Video[indx]?.id ? (
                                        <Box
                                          className={
                                            featureData?.length === 2
                                              ? styles.image_card_container2
                                              : featureData?.length === 3
                                                ? styles.image_card_container3
                                                : styles.image_card_container
                                          }
                                        >
                                          <Image
                                            placeholderImg={LoaderImage}
                                            src={LoaderImage}
                                            style={{
                                              width: "100%",
                                              height: "65%",
                                              objectFit: "contain",
                                              padding: "0rem",
                                              opacity: 0.3,
                                            }}
                                          />
                                        </Box>
                                      ) : (
                                        <Box
                                          className={
                                            featureData?.length === 2
                                              ? styles.image_card_container2
                                              : featureData?.length === 3
                                                ? styles.image_card_container3
                                                : styles.image_card_container
                                          }
                                        >
                                          {/* @ts-ignore */}
                                          {item?.details?.Video[indx]
                                            ?.thumbnail !== "Not Available" ? (
                                            <Box>
                                              <Image
                                                placeholderImg={LoadingImage}
                                                src={
                                                  item.details.Video[indx]
                                                    ?.thumbnail
                                                }
                                                style={{
                                                  width: "100%",
                                                  height: "100%",
                                                  objectFit: "cover",
                                                  cursor: "pointer",
                                                  maxHeight:
                                                    width > 1370
                                                      ? "12rem"
                                                      : "12rem",
                                                }}
                                                onClick={() =>
                                                  singleVideoRenderModal.open(
                                                    item?.details?.Video[indx]
                                                  )
                                                }
                                              />
                                            </Box>
                                          ) : (
                                            <Image
                                              placeholderImg={LoadingImage}
                                              src={VideoIcon}
                                              style={{
                                                width: "100%",
                                                height: "65%",
                                                objectFit: "contain",
                                                padding: "0rem",
                                                opacity: 0.3,
                                              }}
                                            />
                                          )}
                                        </Box>
                                      )}
                                      <Box className={styles.action_container}>
                                        {permission.includes("U") && (
                                          <img
                                            src={VideoMute}
                                            title="Video without Audio"
                                            style={{
                                              width: "1.5rem",
                                              height: "1.5rem",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              uploadVideo(
                                                item?.id,
                                                item?.details?.Video[indx].id,
                                                true
                                              )
                                            }
                                          />
                                        )}
                                        <input
                                          id="PDF Upload"
                                          accept="video/*"
                                          type="file"
                                          style={{ display: "none" }}
                                          onChange={(e: any) =>
                                            UploadFileVideo(
                                              e,
                                              item?.details.Video[indx]?.id,
                                              true
                                            )
                                          }
                                          onClick={handleInputClickVideo}
                                          ref={inputElRefVideo}
                                        />
                                        {permission?.includes("U") && (
                                          <img
                                            src={VideAudio}
                                            title="Video with Audio"
                                            style={{
                                              width: "1.5rem",
                                              height: "1.5rem",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              uploadVideo(
                                                item?.id,
                                                item?.details?.Video[indx]?.id,
                                                false
                                              )
                                            }
                                          />
                                        )}
                                        <input
                                          id="PDF Upload"
                                          accept="video/*"
                                          type="file"
                                          style={{ display: "none" }}
                                          onChange={(e: any) =>
                                            UploadFileVideo(
                                              e,
                                              item?.details.Video[indx]?.id,
                                              false
                                            )
                                          }
                                          onClick={handleInputClickVideo}
                                          ref={inputElRefVideo}
                                        />
                                        {permission.includes("D") && (
                                          <DeleteIcon
                                            titleAccess="Delete"
                                            sx={{ cursor: "pointer" }}
                                            onClick={() => {
                                              swal({
                                                text: `Are you sure you want to delete ?`,
                                                icon: "error",
                                                buttons: [
                                                  "Cancel",
                                                  "Yes, Delete",
                                                ],
                                              }).then((confirm) => {
                                                if (confirm) {
                                                  // setPdfFileInfoLoader(true);
                                                  API.put(
                                                    `/xcpep/feature_details/${item?.id}/`,
                                                    {
                                                      [item?.details?.Video[
                                                        indx
                                                      ]?.id]: null,
                                                    },
                                                    {},
                                                    0
                                                  )
                                                    .then(() => {
                                                      var randomm: any = Math.floor(Math.random() * 1000000 + 1);
                                                      dispatch(validationCheckCounter(randomm + 1));
                                                      getFeatureData();
                                                    })
                                                    .catch((err: any) => {
                                                      const { data } =
                                                        err?.response;
                                                      Swal.fire({
                                                        icon: "error",
                                                        html: `<div>
                                         <br />
                                         <p style="color:"red">${data[0]}</p>   
                                       </div>`,
                                                      });
                                                    });
                                                }
                                              });
                                            }}
                                          />
                                        )}
                                      </Box>
                                    </Box>
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                      height: { lg: "12rem", xl: "12rem" },
                                      textAlign: "center",
                                      borderRight: "1px solid",
                                      borderColor: "primary.light",
                                    }}
                                  >
                                    <Box className={styles.imageDiv}>
                                      <Box
                                        className={
                                          featureData?.length === 2
                                            ? styles.image_card_container2
                                            : featureData?.length === 3
                                              ? styles.image_card_container3
                                              : styles.image_card_container
                                        }
                                      >
                                        <Box sx={{ width: "100%" }}>
                                          <img
                                            src={featureIcon}
                                            style={{
                                              // width: "60%",
                                              height: "8rem",
                                              objectFit: "contain",
                                              opacity: 0.3,
                                            }}
                                          />
                                          <Typography
                                            sx={{ fontSize: "0.87rem" }}
                                          >
                                            Feature does not exist
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </TableCell>
                                )}
                              </>
                            );
                          })}
                      </TableRow>
                    );
                  }
                )}

                {/* General Parameter  */}

                <TableRow sx={{ backgroundColor: "#f7fbff" }}>
                  <TableCell
                    sx={{
                      padding: "0",
                      border: "none",
                      borderRight: "1px solid",
                      borderColor: "primary.light",
                      // borderBottomColor: "primary.light",
                    }}
                  >
                    <Table>
                      <TableBody>
                        {featureData &&
                          featureData[0]?.details?.Parameters?.map(
                            (item: any, index: any) => {
                              return (
                                <TableRow>
                                  <TableCell
                                    sx={
                                      item?.name !== "Description"
                                        ? {
                                          padding: "0.2rem 0.5rem",
                                          border: "none",
                                          // textAlign: "right",
                                          whiteSpace: "nowrap",
                                          height: "3rem",
                                        }
                                        : {
                                          padding: "0.2rem 0.5rem",
                                          border: "none",
                                          // textAlign: "right",
                                          whiteSpace: "nowrap",
                                          height: "8rem",
                                        }
                                    }
                                  >
                                    {item?.name}
                                    {item?.is_required && (
                                      <sup style={{ color: "red" }}>*</sup>
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )}
                      </TableBody>
                    </Table>
                  </TableCell>
                  {featureData &&
                    feId != "0" &&
                    featureData?.map((item: any) => {
                      return (
                        <>
                          {item?.details?.Avalability?.value === "Yes" ? (
                            <TableCell
                              sx={{
                                padding: "0",
                                border: "none",
                                borderTop: "1px solid",
                                borderRight: "1px solid",
                                borderColor: "primary.light",
                              }}
                            >
                              <Table>
                                <TableBody>
                                  {item?.details?.Parameters?.map(
                                    (itm: any, index: number) => {
                                      return (
                                        <TableRow key={index}>
                                          <TableCell
                                            sx={{
                                              padding: "0.2rem 0.5rem",
                                              border: "none",
                                              height: "3rem",
                                            }}
                                          >
                                            <ParamsInputText
                                              value={itm?.value}
                                              paramsId={itm?.id}
                                              parentId={item?.id}
                                              placeholderName={itm?.name}
                                              dataType={itm?.data_type}
                                              disabled={
                                                !permission.includes("U")
                                              }
                                            />
                                          </TableCell>
                                        </TableRow>
                                      );
                                    }
                                  )}
                                </TableBody>
                              </Table>
                            </TableCell>
                          ) : (
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "transparent",
                                height: { lg: "14rem", xl: "12rem" },
                                textAlign: "center",
                              }}
                            >
                              <Box className={styles.imageDiv}>
                                <Box
                                  className={
                                    featureData?.length === 2
                                      ? styles.image_card_container2
                                      : featureData?.length === 3
                                        ? styles.image_card_container3
                                        : styles.image_card_container
                                  }
                                >
                                  <Box sx={{ width: "100%" }}>
                                    {" "}
                                    <img
                                      src={featureIcon}
                                      style={{
                                        // width: "60%",
                                        height: "8rem",
                                        objectFit: "contain",
                                        opacity: 0.3,
                                      }}
                                    />
                                    <Typography
                                      sx={{
                                        fontSize: "0.87rem",
                                      }}
                                    >
                                      Feature does not exist
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </TableCell>
                          )}
                        </>
                      );
                    })}
                </TableRow>

                {/* Feature Parameter  */}

                <TableRow sx={{ backgroundColor: "#fffdf7" }}>
                  <TableCell
                    sx={{
                      padding: "0",
                      borderBottomColor: "primary.light",
                      borderRight: "1px solid",
                      borderColor: "primary.light",
                    }}
                  >
                    <Table>
                      <TableBody>
                        {featureData &&
                          featureData[0]?.details["Feature Library"]?.map(
                            (item: any, index: any) => {
                              return (
                                <TableRow>
                                  <TableCell
                                    sx={
                                      item?.name !== "Description"
                                        ? {
                                          padding: "0.2rem 0.5rem",
                                          border: "none",
                                          textAlign: "right",
                                          whiteSpace: "nowrap",
                                          height: { lg: "3rem", xl: "3.1rem" },
                                          backgroundColor: item && item?.name_type == 'numerical' ? "#fffdf7" : "#f8f8ff",
                                        }
                                        : {
                                          padding: "0.2rem 0.5rem",
                                          border: "none",
                                          textAlign: "right",
                                          whiteSpace: "nowrap",
                                          height: "8rem",
                                        }
                                    }
                                  >
                                    {/* {item?.name} */}
                                    {item?.is_required && (
                                      <sup className={styles.requiredCss}>
                                        *
                                      </sup>
                                    )}
                                    <LibraryParameterDropdownBox
                                      selectedData={item}
                                      allData={
                                        featureData[0]?.details[
                                        "Feature Library"
                                        ]
                                      }
                                      iddd={featureData && featureData[0]?.id}
                                      getFeatureDataWOL={getFeatureDataWOL}
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )}
                      </TableBody>
                    </Table>
                  </TableCell>
                  {featureData &&
                    feId != "0" &&
                    featureData?.map((item: any) => {
                      return (
                        <>
                          {item?.details?.Avalability?.value === "Yes" ? (
                            <TableCell
                              sx={{
                                padding: "0",
                                borderBottomColor: "primary.light",
                                borderRight: "1px solid",
                                borderColor: "primary.light",
                              }}
                            >
                              <Table>
                                <TableBody>
                                  {item?.details["Feature Library"]?.map(
                                    (itm: any, index: number) => {
                                      return (
                                        <TableRow key={index}>
                                          <TableCell
                                            sx={{
                                              padding: "0.2rem 0.5rem",
                                              border: "none",
                                              height: {
                                                lg: "3rem",
                                                xl: "3.1rem",
                                              },
                                              backgroundColor: itm && itm?.name_type == 'numerical' ? "#fffdf7" : "#f8f8ff",
                                            }}
                                          >
                                            <LibraryParamsInput
                                              value={itm?.value}
                                              paramsId={itm?.id}
                                              parentId={item?.id}
                                              placeholderName={itm?.name}
                                              dataType={
                                                itm?.name_type == "text"
                                                  ? "longtext"
                                                  : "float"
                                              }
                                              disabled={
                                                !permission.includes("U")
                                              }
                                            />
                                          </TableCell>
                                        </TableRow>
                                      );
                                    }
                                  )}
                                </TableBody>
                              </Table>
                            </TableCell>
                          ) : (
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                                height: { lg: "14rem", xl: "12rem" },
                                textAlign: "center",
                              }}
                            >
                              <Box className={styles.imageDiv}>
                                <Box
                                  className={
                                    featureData?.length === 2
                                      ? styles.image_card_container2
                                      : featureData?.length === 3
                                        ? styles.image_card_container3
                                        : styles.image_card_container
                                  }
                                >
                                  <Box sx={{ width: "100%" }}>
                                    <img
                                      src={featureIcon}
                                      style={{
                                        // width: "60%",
                                        height: "8rem",
                                        objectFit: "contain",
                                        opacity: 0.3,
                                      }}
                                    />
                                    <Typography sx={{ fontSize: "0.87rem" }}>
                                      Feature does not exist
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </TableCell>
                          )}
                        </>
                      );
                    })}
                </TableRow>

                {/* Feature Cost  */}

                {type == 1 && (
                  <TableRow sx={{ backgroundColor: "#f7fff8" }}>
                    <TableCell
                      sx={{
                        padding: "0",
                        borderBottomColor: "primary.light",
                        borderRight: "1px solid",
                        borderColor: "primary.light",
                      }}
                    >
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                border: "none",
                                lineHeight: "1.2",
                                backgroundColor: "#fff",
                                height: "3rem",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  columnGap: "1rem",
                                  justifyContent: "space-between",
                                  fontWeight: "600",
                                }}
                              >
                                {" "}
                                <Box>
                                  Feature Cost

                                  {
                                    <Checkbox {...label}
                                      checked={FeatureCost}
                                      size="small"
                                      sx={{ color: "#007fff" }}
                                      onChange={handleChange3}


                                    />

                                  }
                                </Box>
                                <BarChartIcon
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => statModal.open()}
                                />

                              </Box>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableCell>
                    {featureData &&
                      feId != "0" &&
                      featureData?.map((item: any) => {
                        return (
                          <>
                            {item?.details?.Avalability?.value === "Yes" &&
                              type == 1 ? (
                              <TableCell
                                sx={{
                                  padding: "0",
                                  borderBottomColor: "primary.light",
                                  borderRight: "1px solid",
                                  borderColor: "primary.light",
                                }}
                              >
                                <Table>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell
                                        sx={{
                                          padding: "0.2rem 0.5rem",
                                          border: "none",
                                          height: "3rem",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          backgroundColor: "#fff",
                                        }}
                                      >
                                        <FeatureCostUpdate
                                          value={
                                            item?.details["Feature Cost(INR)"]
                                              ?.total_cost
                                          }
                                          itemId={item.id}
                                          productId={item?.product}
                                        />
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            columnGap: "0.2rem",
                                          }}
                                        >
                                          <Button
                                            size="small"
                                            variant="contained"
                                            sx={{
                                              lineHeight: 1,
                                              padding: "0.4rem 0.5rem",
                                            }}
                                            onClick={() => getFeatureData()}
                                          >
                                            Fetch
                                          </Button>
                                          <InfoIcon
                                            sx={{ cursor: "pointer" }}
                                            titleAccess="This is the sum of all the parts required to provide the feature in the product"
                                          />
                                        </Box>
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableCell>
                            ) : (
                              <TableCell
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                  // height: { lg: "14rem", xl: "12rem" },
                                  textAlign: "center",
                                }}
                              >
                                {/* <Box className={styles.imageDiv}>
                                  <Box
                                    className={
                                      featureData?.length === 2
                                        ? styles.image_card_container2
                                        : featureData?.length === 3
                                        ? styles.image_card_container3
                                        : styles.image_card_container
                                    }
                                  > */}
                                <Box sx={{ width: "100%" }}>
                                  {" "}
                                  {/* <img
                                        src={featureIcon}
                                        style={{
                                          // width: "60%",
                                          height: "8rem",
                                          objectFit: "contain",
                                          opacity: 0.3,
                                        }}
                                      /> */}
                                  <Typography
                                    sx={{
                                      fontSize: "0.87rem",
                                    }}
                                  >
                                    Feature does not exist
                                  </Typography>
                                </Box>
                                {/* </Box>
                                </Box> */}
                              </TableCell>
                            )}
                          </>
                        );
                      })}
                  </TableRow>
                )}

                {/* Feature Cost Paramter  */}
                {FeatureCost == false && type == 1 && (
                  <TableRow sx={{ backgroundColor: "#f7fff8" }}>
                    <TableCell
                      sx={{
                        padding: "0",
                        borderBottomColor: "primary.light",
                        borderRight: "1px solid",
                        borderColor: "primary.light",
                      }}
                    >
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                border: "none",
                                whiteSpace: "nowrap",
                                height: "3rem",
                              }}
                            >
                              {" "}
                              System
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                border: "none",
                                whiteSpace: "nowrap",
                                height: "3rem",
                              }}
                            >
                              {" "}
                              Assembly
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                border: "none",
                                whiteSpace: "nowrap",
                                height: "3rem",
                              }}
                            >
                              {" "}
                              Part
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                border: "none",
                                whiteSpace: "nowrap",
                                height: "3rem",
                              }}
                            >
                              Additional Part
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                border: "none",
                                whiteSpace: "nowrap",
                                height: "3rem",
                              }}
                            >
                              Additional Cost
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableCell>
                    {featureData &&
                      feId != "0" &&
                      featureData?.map((item: any) => {
                        return (
                          <>
                            {item?.details?.Avalability?.value === "Yes" &&
                              type == 1 ? (
                              <TableCell
                                sx={{
                                  padding: "0",
                                  borderBottomColor: "primary.light",
                                  borderRight: "1px solid",
                                  borderColor: "primary.light",
                                }}
                              >
                                <Table>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell
                                        sx={{
                                          padding: "0.2rem 0.5rem",
                                          border: "none",
                                          height: "3rem",
                                        }}
                                      >
                                        <ParamsMultiselect
                                          item={{
                                            productId: item?.product,
                                            type: "system",
                                            values:
                                              item?.details["Feature Cost(INR)"]
                                                ?.system,
                                            itemId: item?.id,
                                          }}
                                          key={item?.id}
                                          getFeatureData={getFeatureData}
                                          disabled={!permission?.includes("U")}
                                          productCount={
                                            featureData && featureData.length
                                          }
                                        />
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell
                                        sx={{
                                          padding: "0.2rem 0.5rem",
                                          border: "none",
                                          height: "3rem",
                                        }}
                                      >
                                        <ParamsMultiselect
                                          item={{
                                            productId: item?.product,
                                            type: "assambly",
                                            values:
                                              item?.details["Feature Cost(INR)"]
                                                ?.assembly,
                                            itemId: item?.id,
                                          }}
                                          key={item?.id}
                                          getFeatureData={getFeatureData}
                                          productCount={
                                            featureData && featureData.length
                                          }
                                        />
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell
                                        sx={{
                                          padding: "0.2rem 0.5rem",
                                          border: "none",
                                          height: "3rem",
                                        }}
                                      >
                                        <ParamsMultiselect
                                          item={{
                                            productId: item?.product,
                                            type: "parts",
                                            values:
                                              item?.details["Feature Cost(INR)"]
                                                ?.part,
                                            itemId: item?.id,
                                          }}
                                          key={item?.id}
                                          getFeatureData={getFeatureData}
                                          productCount={
                                            featureData && featureData.length
                                          }
                                        />
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell
                                        sx={{
                                          padding: "0.2rem 0.5rem",
                                          border: "none",
                                          height: "3rem",
                                        }}
                                      >
                                        <AdditionalInput
                                          value={
                                            item?.details?.["Feature Cost(INR)"]
                                              ?.additional_part
                                          }
                                          paramsId={"additional_part"}
                                          parentId={item?.id}
                                          placeholderName={"Additional Part"}
                                          disabled={!permission?.includes("U")}
                                        />
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell
                                        sx={{
                                          padding: "0.2rem 0.5rem",
                                          border: "none",
                                          height: "3rem",
                                        }}
                                      >
                                        {/* <TextField
                                      placeholder="Additional Cost"
                                      variant="standard"
                                      sx={{
                                        ".MuiInputBase-root.MuiInput-root": {
                                          fontSize: "1rem !important",
                                        },
                                        "& .MuiInput-underline:before": { borderBottomColor: "white" },
                                        "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                                      }}
                                    /> */}
                                        <AdditionalInput
                                          value={
                                            item?.details?.["Feature Cost(INR)"]
                                              ?.input_cost
                                          }
                                          paramsId={"input_cost"}
                                          parentId={item?.id}
                                          placeholderName={"Additional Cost"}
                                          disabled={!permission?.includes("U")}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableCell>
                            ) : (
                              <TableCell
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                  height: { lg: "14rem", xl: "12rem" },
                                  textAlign: "center",
                                }}
                              >
                                <Box className={styles.imageDiv}>
                                  <Box
                                    className={
                                      featureData?.length === 2
                                        ? styles.image_card_container2
                                        : featureData?.length === 3
                                          ? styles.image_card_container3
                                          : styles.image_card_container
                                    }
                                  >
                                    <Box sx={{ width: "100%" }}>
                                      {" "}
                                      <img
                                        src={featureIcon}
                                        style={{
                                          // width: "60%",
                                          height: "8rem",
                                          objectFit: "contain",
                                          opacity: 0.3,
                                        }}
                                      />
                                      <Typography
                                        sx={{
                                          fontSize: "0.87rem",
                                        }}
                                      >
                                        Feature does not exist
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </TableCell>
                            )}
                          </>
                        );
                      })}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          ) :
            feId != 0 ?
              <Box sx={{ width: '100%' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem' }} /></TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem' }} /></TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem' }} /></TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem' }} /></TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem' }} /></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem' }} /></TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton variant="rectangular" sx={{ width: '100%', height: '10rem' }} /></TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton variant="rectangular" sx={{ width: '100%', height: '10rem' }} /></TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton variant="rectangular" sx={{ width: '100%', height: '10rem' }} /></TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton variant="rectangular" sx={{ width: '100%', height: '10rem' }} /></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem' }} /></TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton variant="rectangular" sx={{ width: '100%', height: '10rem' }} /></TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton variant="rectangular" sx={{ width: '100%', height: '10rem' }} /></TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton variant="rectangular" sx={{ width: '100%', height: '10rem' }} /></TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton variant="rectangular" sx={{ width: '100%', height: '10rem' }} /></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem' }} /></TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem' }} />
                        <Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem', margin: '15px 0' }} />
                        <Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem', margin: '15px 0' }} />
                        <Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem', margin: '15px 0' }} /></TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem' }} />
                        <Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem', margin: '15px 0' }} />
                        <Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem', margin: '15px 0' }} />
                        <Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem', margin: '15px 0' }} /></TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem' }} />
                        <Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem', margin: '15px 0' }} />
                        <Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem', margin: '15px 0' }} />
                        <Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem', margin: '15px 0' }} /></TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem' }} />
                        <Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem', margin: '15px 0' }} />
                        <Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem', margin: '15px 0' }} />
                        <Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem', margin: '15px 0' }} /></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem' }} /></TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem' }} />
                        <Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem', margin: '15px 0' }} />
                        <Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem', margin: '15px 0' }} />
                        <Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem', margin: '15px 0' }} /></TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem' }} />
                        <Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem', margin: '15px 0' }} />
                        <Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem', margin: '15px 0' }} />
                        <Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem', margin: '15px 0' }} /></TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem' }} />
                        <Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem', margin: '15px 0' }} />
                        <Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem', margin: '15px 0' }} />
                        <Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem', margin: '15px 0' }} /></TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem' }} />
                        <Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem', margin: '15px 0' }} />
                        <Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem', margin: '15px 0' }} />
                        <Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem', margin: '15px 0' }} /></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem' }} /></TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton variant="rectangular" sx={{ width: '100%', height: '10rem' }} /></TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton variant="rectangular" sx={{ width: '100%', height: '10rem' }} /></TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton variant="rectangular" sx={{ width: '100%', height: '10rem' }} /></TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton variant="rectangular" sx={{ width: '100%', height: '10rem' }} /></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton variant="rectangular" sx={{ width: '100%', height: '2rem' }} /></TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton variant="rectangular" sx={{ width: '100%', height: '10rem' }} /></TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton variant="rectangular" sx={{ width: '100%', height: '10rem' }} /></TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton variant="rectangular" sx={{ width: '100%', height: '10rem' }} /></TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton variant="rectangular" sx={{ width: '100%', height: '10rem' }} /></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
              : <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%', fontSize: '1.5rem' }}>Select Feature</Box>
          }
          {totalPages > 1 && feId != 0 ? (
            <Box
              sx={{
                padding: "0.2rem 0.5rem",
                position: "absolute",
                right: 0,
                paddingRight: "1.5rem",
                zIndex: 1,
              }}
            >
              <IconButton
                title="Next Product"
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  color: "primary.main",
                  backgroundColor: "#007fff14",
                  borderRadius: "50%",
                  width: "1.7rem",
                  height: "1.7rem",
                }}
                disabled={Number(pageNumber) === totalPages ? true : false}
                onClick={() =>
                  history.push(
                    `/createfeature/${projectId}/${projectname}/${type}/${parent}/${child}/${featureId}/${feId}/${Number(pageNumber) + 1
                    }`
                  )
                }
              >
                <ChevronRightIcon
                  titleAccess="Next"
                  sx={{ fontSize: "1.8rem" }}
                />
              </IconButton>
            </Box>
          ) : (
            ""
          )}
        </Box>
      </Box>
      {singleVideoRenderModal.isOpen && (
        <FeatureVideoRenderModal
          isOpen={singleVideoRenderModal.isOpen}
          onCloseModal={singleVideoRenderModal.close}
          url={singleVideoRenderModal.propsId}
        />
      )}

      {statModal.isOpen && (
        <FeatureStatTable
          isOpen={statModal.isOpen}
          onCloseModal={statModal.close}
        />
      )}
    </div>
  );
}
