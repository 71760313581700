import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Divider,
  Drawer,
  Button,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Typography,
  Menu,
  Table,
  TableRow,
  TableCell,
  Chip,
  Skeleton,
  Autocomplete,
  TextField
} from "@mui/material";
import { API } from "../../api-services";
import AddIcon from "@mui/icons-material/Add";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory, useRouteMatch } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import styles from "./AnalysisLandingPage.module.scss";
import {
  Bar,
  BarChart,
  Cell,
  Label,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import CancelIcon from '@mui/icons-material/Cancel';
import ConfigureColorModal from "./ConfigureColorModal";

//Dilalog Box
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import TableIcon from "../../Assets/images/svgs/tableIcon.svg";
import { TransitionProps } from '@mui/material/transitions';
import { useBaseModal } from "../SearchPage/useBaseModal";
import { CurrencyChange } from "../../ViewModule/CostingView/CurrencyChange";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { currencyFormat } from "../../utlis/CurrencyFormat";
import { AUTH } from "../../Redux/Services/auth.service";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import { useDocumentTitle } from "../../ui-reusable-component/useDocumentTitle";

type Anchor = "top" | "left" | "bottom" | "right";

interface Params {
  projectId: any;
  topVault: any;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface AnalysisCostingLandingPageProps { }

const AnalysisCostingLandingPage: React.FC<AnalysisCostingLandingPageProps> = (props) => {
  useDocumentTitle( "Costing Analysis" );
  const { projectId, topVault } = useRouteParams<Params>();
  const [selectedProject, setSelectedProject] = useState<any>([]);
  const [selectedProduct, setSelectedProduct] = useState<any>([]);
  const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(
    null
  );
  const [okButtonLoader, setOKButtonLoader] = useState<boolean>(false);

  const [costLoader, setCostLoader] = useState<any>();
  const [costKgLoader, setCostKgLoader] = useState<boolean>(true);
  const [materialLoader, setMaterialLoader] = useState<boolean>(true);
  const [analyseBopDcLoader, setAnalyseBopDcLoader] = useState<boolean>(true);

  const [analyseCost, setAnalyseCost] = useState<any>();
  const [analyseCostKg, setAnalyseCostKg] = useState<any>([]);
  const [analyseMaterial, setAnalyseMaterial] = useState<any>([]);
  const [analyseBopDc, setAnalyseBopDc] = useState<any>();
  const { height, width } = useWindowDimensions();
  const [analyseProjects, setAnalyseProjects] = useState<any>();
  const [analyseProduct, setAnalyseProduct] = useState<any>();
  const [AllProjectdata, setAllProjectdata] = useState()
  const [analysisModuleName, setAnalysisModuleName] = useState<any>();

  const [moduleApi, setModuleApi] = useState<any>();

  const currencyModal = useBaseModal();
  const [count, setCount] = React.useState<any>(0);
  const [Counter, setCounter] = useState(0)
  const history = useHistory();
  const { url } = useRouteMatch();
  const colorConfigurationModal = useBaseModal();
  const [topVaultColorMap, setTopVaultColorMap] = useState<any>({});
  const [topVaultsColor, setTopVaultsColor] = useState<any>([]);
  const [isTopVaultColorFetched, setIsTopVaultColorFetched] = useState<boolean>(false);
  const [allCategory, setAllCategory] = useState<any>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>([]);

  //Dialog BOx
  const [openDialog, setDialogOpen] = React.useState(false);
  const handleDialogClickOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };


  const color: any = [
    "#dd6b4d",
    "#ffce76",
    "#716f96",
    "#2b82bf",
    "#003d68",
    "#eaba51",
    "#ff9d9a",
    "#d3d3d3",
    "#a0cbe8",
    "#4e79a7",
    "#d9b364",
    "#e5cd99",
    "#c7e5e3",
    "#91cdc7",
    "#58b4ac",
    "#00688b",
    "#00bfff",
    "#ffa500",
    "#cd6600",
    "#3c0000",
    "#d22323",
    "#5a4b50",
    "#ffd2d7",
    "#1e64af",
    "#faaf96",
    "#9bb996",
    "#aae6cd",
    "#aaa5a5",
    "#782d2d",
    "#1e4b55",
    "#69785f",
    "#ffd28c",
    "#419bd2",
    "#f57382",
    "#dcebc3",
    "#b44641",
    "#91e6cd",
    "#be6e82",
    "#a5236e",
    "#f5dc50",
    "#2d969b",
    "#fa913c",
    "#ff4164",
    "#64505a",
    "#9b5a64",
    "#376ea5",
    "#c86469",
    "#5f7896",
    "#e1b496",
    "#a0a5a0",
    "#e1e6e6",
    "#6e6437",
    "#2d2d1e",
    "#00324b",
    "#e1d2af",
    "#82dce1",
    "#fff5af",
    "#cddcdc",
    "#f57ddc",
    "#ffb946",
    "#facdaf",
    "#aa96b4",
    "#191423",
    "#beaf5f",
    "#6d6d6d",
    "#3bf210",
    "#666666",
    "#cccc99",
    "#669900",];

  const topVaultColor: any = [
    "#075482",
    "#e14958",
    "#fe932b",
    "#64d0dc",
    "#33b1e4",
    "#ffcc67",
    "#8dc73f",
    "#8945aa",
    "#9fd3c7",
    "#385170",
  ];

  const getPermissionCurrencyButton = () => {

    if (AUTH.isSuperUser) {

      return true;

    } else if (AUTH.user_category !== "Internal") {

      return true;

    } else {

      return false;

    }

  };

  const topVaultsParam: any =
    topVault && typeof topVault == "string"
      ? topVault?.split(",")?.map(Number)
      : [topVault];
  const projectIdParam: any =
    projectId && typeof projectId == "string"
      ? projectId?.split(",")?.map(Number)
      : [projectId];
  var filterDataIds = topVaultsParam?.sort(function (a: any, b: any) { return a - b });

  var projectLength = AllProjectdata && Object.keys(AllProjectdata[0])?.length;

  const deleteProject = (item: any) => {
    setCounter(Counter + 1)
    var dataArray = [item];
    const filteredProject =
      AllProjectdata && AllProjectdata[0] && Object.keys(AllProjectdata[0])
        .filter((key) => !dataArray.includes(key))
        .reduce((obj: any, key: any) => {
          obj[key] = AllProjectdata[0][key];
          return obj;
        }, {});
    const FilterTopVault =
      filteredProject && Object.values(filteredProject)
        .map((item: any, ind: any) => {
          return item;
        })
        .flat(1)
        .map((item: any, ind: any) => item?.id);

    let valProject: any = [];
    let abc: any = filteredProject && Object.entries(filteredProject).forEach(([key, value]: any) => {
      valProject.push(filteredProject && filteredProject[key][0]['project_id']);
    });
    //  @ts-ignore
    history.push(`/analysis_costing/${valProject?.length > 0 && valProject && valProject.toString()}/${FilterTopVault?.toString()}`)
  }

  const handleDelete = (item1: any) => {
    setCounter(Counter + 1)
    var ProjectIdsdata = AllProjectdata && AllProjectdata[0];
    var CollectionProjectids = ProjectIdsdata && Object.values(ProjectIdsdata).map((item: any, index: any) => {
      return item.map((item2: any, index1: any) => { return item2?.id })
    }).flat(1).filter((item3: any, index: any) => { return item3 != item1 })

    var CollectionProjectidsABC = ProjectIdsdata && Object.values(ProjectIdsdata).map((item: any, index: any) => {
      return item.map((item2: any, index1: any) => { return item2?.id })
    })

    let vaultsData: any = [];
    let vaultVal: any = [];
    let abc: any = ProjectIdsdata && Object.entries(ProjectIdsdata).forEach(([key, value]: any) => {
      vaultsData.push(ProjectIdsdata && ProjectIdsdata[key])
      // ?.map((item:any)=> item?.id == item1 && vaultsData.push(item)),"ProjectIdsdata");
    });

    const leftProduct: any = vaultsData?.flat()?.filter((item: any) => item?.id != item1);

    let projectIDLeft: any = [];
    let mapleftProduct: any = leftProduct && leftProduct?.map((item: any) => {
      if (projectIDLeft.includes(item?.project_id)) {
        return;
      }
      else {
        projectIDLeft.push(item?.project_id);
      }
    });
    // @ts-ignore
    history.push(`/analysis/${projectIDLeft.toString()}/${CollectionProjectids?.toString()}`)
  }


  const getAllCategory = () => {
    API.get("/cost/get_product_category_costing/", {
      vehicle_ids: topVault
    }, 0)
      .then((res) => {
        console.log(res?.data?.category, "res?.data")
        setAllCategory(res?.data?.category);
      })
      .catch((e) => {
        console.log(e);
      }
      )
  }

  const getColorCode = () => {
    API.get("/cost/get_product_color/", {
      product_ids: topVault
    }, 0)
      .then((res) => {
        let topVaultColorObj: any = {};
        res?.data?.forEach((item: any, index: any) => {
          topVaultColorObj[item?.product_id] = item?.color;
        })
        console.log(topVaultColorObj, "topVaultColorObjCheck")
        setTopVaultColorMap(topVaultColorObj);
        setIsTopVaultColorFetched(true);

      })
      .catch((e) => {
        console.log(e);
      }
      )



  }

  React.useEffect(() => {
    getColorCode()
    getAllCategory()
  }
    , [topVault, Counter]);

  const updateColor = () => {
    console.log(topVault);
    console.log(topVaultsParam);
    console.log(topVaultColorMap);
    let topVaultsMap: any = {};
    topVault?.split(',')?.forEach((item: any, index: any) => {
      console.log(item);
      if(topVaultColorMap[item] != '' && topVaultColorMap[item] != undefined && topVaultColorMap[item] != null){
        topVaultsMap[item] = topVaultColorMap[item];
      }
      else{
        topVaultsMap[item] = topVaultColor[index];
      }
      
    });
    console.log(topVaultsMap);
    setTopVaultsColor(topVaultsMap);

    //key value
  }

  React.useEffect(() => {
    updateColor()
  }, [topVaultColorMap, Counter]);

  const ChipContainer = (chipItem: any) => {
    var chipdata = AllProjectdata && AllProjectdata[0][chipItem]
    // @ts-ignore
    var mapChipData = chipdata?.map((item: any, index: any) => {
      return (<>{filterDataIds && filterDataIds?.length <= 2 ?
        <Chip
          label={item?.name}
          sx={{
            fontSize: "0.87rem",
            height: { xs: "30px", lg: "20px", xl: "30px" },
            color: "primary.light !important",
            display: 'flex',
            columnGap: '5px',
            backgroundColor: '#007fff14 !important',
            "& .MuiChip-deleteIcon 	.MuiChip-icon	.MuiChip-deleteIconColorPrimary":
            {
              fontSize: "1rem !important",
              color: 'primary.main !important',
            },
          }}
        /> :
        <Chip
          icon={
            <CancelIcon
              onClick={() => handleDelete(item?.id)}
              color="primary"
              sx={{
                fontSize: "1.2rem",
                cursor: "pointer",
                color: 'primary.main !important',
                opacity: 0.8,
                "& :hover": {
                  transform: "scale(1.1)",
                  transition: "transform 0.5s ease",
                },
              }}
              titleAccess="Delete"
            />
          }
          // onDelete={() => handleDelete(item?.id)}
          label={item?.name}
          sx={{
            fontSize: "0.87rem",
            height: { xs: "30px", lg: "20px", xl: "30px" },
            margin: "2px 0",
            color: "primary.light !important",
            backgroundColor: "#007fff14 !important",
            '& :hover': {
              opacity: 1,
            }
          }}
        />}
      </>)
    })
    return mapChipData;
  }

  useEffect(() => {
    API.get(
      "/cost/analysis/",
      { get_drawer_values: "modules" },
      0
    ).then((res: any) => {
      setModuleApi(res?.data);
    });
  }, [])

  useEffect(() => { setAnalysisModuleName(url?.includes('analysis_costing') ? 'analysis_costing' : 'analysis') }, [projectId, topVault])

  useEffect(() => {
    API.get(
      "/cost/costing_conversion/",
      { get_conversion_factor: true, module: 'Analysis' },
      0
    ).then((res: any) => {
      sessionStorage.setItem("currency_analysis", res.data[0]?.current_currency);
      url?.includes('analysis') ? sessionStorage.setItem("actionkey_analysis", res.data[0]?.is_manual ? "mannual" : "auto") : sessionStorage.setItem("actionKey", res.data[0]?.is_manual ? "mannual" : "auto")
      if (res.data[0]?.is_manual) {
        url?.includes('analysis') ? sessionStorage.setItem("currencyrate_analysis_mannual", res.data[0]?.conversion_factor) : sessionStorage.setItem("currencyrate_mannual", res.data[0]?.conversion_factor)
      } else {
        url?.includes('analysis') ? sessionStorage.setItem("currencyrate_analysis", (1 / res.data[0]?.conversion_factor).toString()) : sessionStorage.setItem("currencyrate", (1 / res.data[0]?.conversion_factor).toString())
      }
    });
  }, [count]);

  useEffect(() => {
    API.get("customer/analysis/", { product_ids: topVault, get_drawer_values: url?.includes('analysis_costing') ? 'Costing' : 'BOM' }).then((res: any) => {
      var datavalues = res?.data?.map((item: any, index: any) => {
        return Object.values(item).map((item1: any, index: any) => {
          return item1.map((item2: any, index: any) => { return item2?.project_id })
        })
      }).flat(2)
      //  @ts-ignore
      setAllProjectdata(res.data)
    })
  }, [topVault])

  useEffect(() => {
    API.get(`customer/analysis/`, { get_drawer_values: "project", module: analysisModuleName == 'analysis_costing' ? 'Costing' : 'BOM' }, 0)
      .then((res: any) => {
        setAnalyseProjects(res?.data);
      })
      .catch((err: any) => {
        console.log(err, "error");
      });
  }, [analysisModuleName]);

  useEffect(() => {
    setCostKgLoader(true);
    if(isTopVaultColorFetched){
    API.get(
      `/cost/analysis/`,
      {
        vehicle_ids: topVault,
        analysis_type: "cost_per_kg",
        colors: topVault?.split(',')?.map((item: any, index: any) => {
          console.log(item, topVaultColorMap[item] ,"topVaultColorObjCheck");
          if (topVaultColorMap[item] != '' && topVaultColorMap[item] != undefined && topVaultColorMap[item] != null) {
            return topVaultColorMap[item];
          }
          else {
            return topVaultColor[index];
          }})?.toString(),

      },
      0
    )
      .then((res: any) => {
        setAnalyseCostKg(res?.data);
        setCostKgLoader(false);
      })
      .catch((err: any) => {
        console.log(err, "error");
      });
    }
  }, [projectId, topVault, count, topVaultColorMap, Counter, isTopVaultColorFetched]);

  const handleApplyFilterCategory = () => {
    console.log(selectedCategory, "selectedCategory")
    setMaterialLoader(true);
    API.get(
      `/cost/get_product_all_category/`,
      { vehicle_ids: topVault, category_ids: selectedCategory?.toString() },
      0
    )
      .then((res: any) => {
        // console.log(res?.data, "res?.data")
        setAnalyseMaterial(res?.data);
        setMaterialLoader(false);
      })
      .catch((err: any) => {
        console.log(err, "error");
      });
  
  }

  useEffect(() => {
    setCostLoader(true);
    API.get(
      `/cost/analysis/`,
      { vehicle_ids: topVault, analysis_type: "cost" },
      0
    )
      .then((res: any) => {
        setAnalyseCost(res?.data);
        setCostLoader(false);
      })
      .catch((err: any) => {
        console.log(err, "error");
      });
  }, [projectId, topVault, count]);

  useEffect(() => {
    setMaterialLoader(true);
    API.get(
      `/cost/analysis/`,
      { vehicle_ids: topVault, analysis_type: "category_cost_analysis" },
      0
    )
      .then((res: any) => {
        setAnalyseMaterial(res?.data);
        setMaterialLoader(false);
      })
      .catch((err: any) => {
        console.log(err, "error");
      });
  }, [projectId, topVault, count]);

  useEffect(() => {
    setMaterialLoader(true);
    API.get(
      `/cost/analysis/`,
      { vehicle_ids: topVault, analysis_type: "bop_dc_analysis" },
      0
    )
      .then((res: any) => {
        setAnalyseBopDc(res?.data);
        setAnalyseBopDcLoader(false);
      })
      .catch((err: any) => {
        console.log(err, "error");
      });
  }, [projectId, topVault, count]);

  useEffect(() => {
    if (selectedProject.length > 0 && selectedProject != undefined) {
      API.get(
        `customer/analysis/`,
        { get_drawer_values: "top_vault", project: selectedProject.toString(), module: analysisModuleName == 'analysis_costing' ? 'Costing' : 'BOM' },
        0
      )
        .then((res: any) => {
          setAnalyseProduct(res?.data);
        })
        .catch((err: any) => {
          console.log(err, "error");
        });
    }
  }, [selectedProject, analysisModuleName]);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }
        setSelectedProject(projectId && projectId.toString()?.includes(',') ? projectId?.split(",") : [projectId?.toString()]);
        setSelectedProduct(topVault && topVault.toString()?.includes(',') ? topVault?.split(",") : [topVault?.toString()]);
        setState({ ...state, [anchor]: open });
        if (open) {
          setAnalysisModuleName(url?.includes('analysis_costing') ? 'analysis_costing' : 'analysis');
        }
      }

  const selectAllProject = (productIds: any) => {
    // console.log(selectedProject, productIds ,"selectedProject")
    if (selectedProject?.includes(productIds?.toString())) {
      let letProjects: any = selectedProject && selectedProject?.filter((item: any) => item !== productIds?.toString());
      let remainingProductData: any = analyseProduct && analyseProduct?.filter((item: any) => { return selectedProduct?.includes(item?.id?.toString()) });
      let includedProductData: any = analyseProduct && remainingProductData?.filter((item: any) => { return letProjects?.includes(item?.project?.toString()) });
      let remainingProduct: any = analyseProduct && remainingProductData && includedProductData && includedProductData?.map((item: any) => { return item?.id?.toString() })
      analyseProduct && remainingProductData && includedProductData && remainingProduct && setSelectedProduct(remainingProduct);
      setSelectedProject((prev: any) =>
        prev?.filter((item: any) => item !== productIds?.toString())
      );
    } else {
      setSelectedProject((prev: any) => [...prev, productIds?.toString()]);
    }
  };

  const handleSelectChange = (categoryName: any) => {
    // @ts-ignore
    setSelectedCategory([...categoryName]);
  };

 

  const getselectedProjectBackgroundColor = (idd: any) => {
    if (selectedProject?.includes(idd?.toString())) {
      return "primary.main";
    } else {
      return "primary.light";
    }
  };
  const getSelectedProjectTextColor = (idd: any) => {
    if (selectedProject?.includes(idd?.toString())) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };

  const selectAllProduct = (productIds: any) => {
    if (selectedProduct?.includes(productIds?.toString())) {
      setSelectedProduct((prev: any) =>
        prev?.filter((item: any) => item !== productIds.toString())
      );
    } else {
      setSelectedProduct((prev: any) => [...prev, productIds?.toString()]);
    }
  };

  const getselectedProductBackgroundColor = (idd: any) => {
    if (selectedProduct?.includes(idd?.toString())) {
      return "#2b9cf6eb";
    } else {
      return "primary.light";
    }
  };
  const getSelectedProductTextColor = (idd: any) => {
    if (selectedProduct?.includes(idd?.toString())) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };

  const backButtonHandler: any = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    history.push("/landing");
  };

  let disableOkBtn = () => {
    let temp = false;
    if (selectedProject?.length >= 1) {
      if (selectedProduct?.length >= 1) {
        if (selectedProduct?.length < 8) {
          temp = false;
        } else {
          temp = true;
        }
      } else {
        temp = true;
      }
    } else {
      temp = true;
    }
    return temp;
  };

  const changeOkButton = () => {
    history.push(`/${analysisModuleName}/${selectedProject.toString()}/${selectedProduct.toString()}`);
  }

  const list = (anchor: Anchor) => (
    <Box
      sx={{ minWidth: 300, width: anchor === "top" || anchor === "bottom" ? "auto" : "auto" }}
      role="presentation"
    >
      <List>
        <Box sx={{ width: '100%' }}>
          <div style={{ display: "flex", columnGap: '5px', }}>
            {moduleApi && moduleApi?.includes('BOM') &&
              <div onClick={() => { return setAnalysisModuleName('analysis'), setSelectedProject([]), setSelectedProduct([]), setAnalyseProduct(undefined) }} style={analysisModuleName && analysisModuleName == 'analysis' ? { width: `${moduleApi && moduleApi?.length > 1 ? '50%' : '100%'}`, backgroundColor: '#007fff', padding: '0.2rem', textAlign: 'center', cursor: 'pointer', } : { width: '50%', backgroundColor: '#007fff14', padding: '0.2rem', textAlign: 'center', cursor: 'pointer' }}>
                <Typography style={analysisModuleName && analysisModuleName == 'analysis' ? { color: 'white', fontSize: '1rem' } : { color: '#007fff', fontSize: '1rem' }}>BOM</Typography>
              </div>}
            {moduleApi && moduleApi?.includes('Costing') &&
              <div style={analysisModuleName && analysisModuleName == 'analysis_costing' ? { width: `${moduleApi && moduleApi?.length > 1 ? '50%' : '100%'}`, backgroundColor: '#007fff', padding: '0.2rem', textAlign: 'center', cursor: 'pointer' } : { width: '50%', backgroundColor: '#007fff14', padding: '0.2rem', textAlign: 'center', cursor: 'pointer' }}>
                <Typography onClick={() => { return setAnalysisModuleName('analysis_costing'), setSelectedProject([]), setSelectedProduct([]), setAnalyseProduct(undefined) }} style={analysisModuleName && analysisModuleName == 'analysis_costing' ? { color: 'white', fontSize: '1rem' } : { color: '#007fff', fontSize: '1rem' }}>Costing</Typography>{" "}
              </div>}
          </div>
        </Box>
        <ListItem>
          <Box sx={{ width: "100%" }}>
            <div>
              <Typography
                style={{
                  width: "100%",
                  fontSize: "1rem",
                }}
              >
                Project Name
              </Typography>
              {analyseProjects &&
                analyseProjects?.map((item: any, index: any) => {
                  return (
                    <>
                      <Box sx={{ alignItems: "center" }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Checkbox
                            checked={
                              selectedProject?.includes(item.id?.toString()) ? true : false
                            }
                            onClick={() => selectAllProject(item?.id?.toString())}
                            inputProps={{ "aria-label": "controlled" }}
                            sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                          />
                          <Box
                            onClick={() => selectAllProject(item?.id?.toString())}
                            key={index}
                            className={styles.selectVehBtn}
                            sx={{
                              // margin: "0rem 2rem",
                              padding: "0.2rem 0.5rem",
                              backgroundColor:
                                getselectedProjectBackgroundColor(item.id?.toString()),
                              color: getSelectedProjectTextColor(item.id?.toString()),
                              textAlign: "left",
                              cursor: "pointer",
                              width: "100%",
                            }}
                          >
                            <Typography style={{ fontSize: '1rem', display: 'flex' }}>{item.name} <span style={{ marginLeft: '0.5rem', display: 'inline-block', width: '14rem' }}>[ {item.description} ]</span></Typography>
                          </Box>
                        </Box>
                        <div style={{ marginLeft: "2rem" }}>
                          {analyseProduct &&
                            analyseProduct
                              ?.filter((itemVal: any) => {
                                return itemVal?.project == item?.id;
                              })
                              ?.map((itemVal: any, indexVal: any) => {
                                return (
                                  <>
                                    <Box
                                      sx={{
                                        alignItems: "center",
                                        display: "flex",
                                        width: "18rem",
                                      }}
                                    >
                                      <Checkbox
                                        checked={
                                          selectedProduct?.includes(
                                            itemVal?.id?.toString()
                                          )
                                            ? true
                                            : false
                                        }
                                        onClick={() =>
                                          selectAllProduct(itemVal?.id)
                                        }
                                        inputProps={{
                                          "aria-label": "controlled",
                                        }}
                                        sx={{
                                          "& .MuiSvgIcon-root": {
                                            fontSize: 24,
                                            color: "#2b9cf6eb",
                                          },
                                        }}
                                      />
                                      <Box
                                        onClick={() =>
                                          selectAllProduct(itemVal?.id)
                                        }
                                        key={indexVal}
                                        className={styles.selectVehBtn}
                                        sx={{
                                          // margin: "0rem 2rem",
                                          padding: "0.2rem 0.5rem",
                                          backgroundColor:
                                            getselectedProductBackgroundColor(
                                              itemVal.id
                                            ),
                                          color: getSelectedProductTextColor(
                                            itemVal.id
                                          ),
                                          textAlign: "left",
                                          cursor: "pointer",
                                          display: "inline-block",
                                          width: "100%",
                                        }}
                                      >
                                        <Typography style={{ fontSize: '1rem' }}>{itemVal.name}</Typography>
                                      </Box>
                                    </Box>
                                  </>
                                );
                              })}
                        </div>
                      </Box>
                    </>
                  );
                })}
            </div>
          </Box>
        </ListItem>
      </List>
      <div style={{ marginTop: "1rem", paddingBottom: '1rem', position: 'sticky', bottom: '0', backgroundColor: "aliceblue" }}>
        <Box>
          {selectedProject?.length < 1 && (
            <Typography style={{ margin: "0", marginLeft: "1.8rem", color: "red", fontSize: '1rem' }}>
              * Please select more than one project
            </Typography>
          )}
          {selectedProject?.length >= 1 && selectedProduct?.length < 1 && (
            <Typography style={{ margin: "0", marginLeft: "1.8rem", color: "red", fontSize: '1rem' }}>
              * Please select more than one product
            </Typography>
          )}
          {selectedProduct?.length > 7 && (
            <Typography style={{ margin: "0", marginLeft: "1.8rem", color: "red", fontSize: '1rem' }}>
              * Please select maximum seven products
            </Typography>
          )}
        </Box>
        <Box sx={{ textAlign: "right", paddingRight: '1rem' }}>
          <Button
            className={styles.drawBtn}
            sx={{ marginRight: "1rem" }}
            variant="contained"
            onClick={toggleDrawer(anchor, false)}
            size="small"
          >
            Close
          </Button>
          <LoadingButton
            style={
              disableOkBtn()
                ? { backgroundColor: "aliceblue", cursor: "not-allowed" }
                : {}
            }
            loading={okButtonLoader}
            size="small"
            disabled={disableOkBtn()}
            onClick={changeOkButton}
          >
            <Button
              style={
                disableOkBtn()
                  ? { backgroundColor: "#ddd", cursor: "not-allowed", color: 'grey' }
                  : {}
              }
              variant="contained"
              onClick={toggleDrawer(anchor, false)}
              size="small"
            >
              Ok
            </Button>
          </LoadingButton>
        </Box>
      </div>

    </Box>
  );

  const handleClose = () => {
    setAnchorElMenu(null);
  };

  const CustomizedCostKgLabel = (props: any) => {
    const { x, y, value } = props;
    return (
      <text
        x={x}
        y={y}
        dy={-4}
        dx={50}
        fontSize="0.8rem"
        fontFamily="sans-serif"
        fill={"black"}
        textAnchor="middle"
      >
        {value} {sessionStorage.getItem('currency_analysis')}/Kg
      </text>
    );
  };

  const renderCustomizedTotalCostLabel = (props: any) => {
    const { x, y, value } = props;
    return (
      <text
        x={x}
        y={y}
        dy={-4}
        dx={50}
        fontSize="0.8rem"
        fontFamily="sans-serif"
        fill={"black"}
        textAnchor="middle"
        textRendering="center"
      >
        {currencyFormat(value, sessionStorage.getItem('currency_analysis'))}
      </text>
    );
  };

  const CustomizedCostTooltip: any = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: "white",
            padding: "0.2rem 0.5rem",
            borderRadius: "5px",
          }}
        >
          <Typography style={{ fontWeight: "bold", fontSize: '0.75rem', minWidth: '8rem', width: '100%', textAlign: 'center' }}>{`${label}`}</Typography>
          <Typography style={{ color: "black", fontSize: '0.75rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center', minWidth: '8rem', width: '100%' }}><Typography style={{ color: 'black', fontSize: '0.75rem', width: '5rem' }}>{`Total Cost :`}</Typography> <Typography style={{ color: 'black', fontSize: '0.75rem', minWidth: '3rem' }}>{currencyFormat(payload[0]?.payload?.["total_cost"], sessionStorage.getItem('currency_analysis'))}</Typography></Typography>
          <Typography
            style={{ color: 'black', fontSize: '0.75rem', display: 'flex', alignItems: 'center', columnGap: '5px', }}
          ><Box sx={{ width: "0.75rem", height: '0.75rem', backgroundColor: payload[0]?.color, }}> </Box><Typography style={{ color: 'black', fontSize: '0.75rem', width: '5rem' }}>{`${payload[0]?.dataKey} :`}</Typography> <Typography style={{ color: 'black', fontSize: '0.75rem', textAlign: 'right', minWidth: '3rem' }}>{currencyFormat(payload[0]?.value, sessionStorage.getItem('currency_analysis'))}</Typography></Typography>
          <Typography
            style={{ color: 'black', fontSize: '0.75rem', display: 'flex', alignItems: 'center', columnGap: '5px', }}
          ><Box sx={{ width: "0.75rem", height: '0.75rem', backgroundColor: payload[1]?.color, }}></Box><Typography style={{ color: 'black', fontSize: '0.75rem', width: '5rem' }}>{`${payload[1]?.dataKey} :`} </Typography><Typography style={{ color: 'black', fontSize: '0.75rem', textAlign: 'right', minWidth: '3rem' }}>{currencyFormat(payload[1]?.value, sessionStorage.getItem('currency_analysis'))}</Typography></Typography>
          <Typography
            style={{ color: 'black', fontSize: '0.75rem', display: 'flex', alignItems: 'center', columnGap: '5px', }}
          ><Box sx={{ width: "0.75rem", height: '0.75rem', backgroundColor: payload[2]?.color, }}> </Box><Typography style={{ color: 'black', fontSize: '0.75rem', width: '5rem' }}>{`${payload[2]?.dataKey} : `} </Typography><Typography style={{ color: 'black', fontSize: '0.75rem', textAlign: 'right', minWidth: '3rem' }}>{currencyFormat(payload[2]?.value, sessionStorage.getItem('currency_analysis'))}</Typography></Typography>
        </div>
      );
    }
    return null;
  };

  const CustomizedBopDcTooltip: any = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: "white",
            padding: "0.2rem 0.5rem",
            borderRadius: "5px",
          }}
        >
          <Typography style={{ fontWeight: "bold", fontSize: '0.75rem', minWidth: '8rem', width: '100%', textAlign: 'center' }}>{`${label}`}</Typography>
          <Typography style={{ color: "black", fontSize: '0.75rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center', minWidth: '8rem', width: '100%' }}><Typography style={{ color: 'black', fontSize: '0.75rem', width: '5rem' }}>{`Total Cost :`}</Typography> <Typography style={{ color: 'black', fontSize: '0.75rem', minWidth: '3rem' }}>{currencyFormat(payload[0]?.payload?.["total_cost"], sessionStorage.getItem('currency_analysis'))}</Typography></Typography>
          <Typography
            style={{ color: 'black', fontSize: '0.75rem', display: 'flex', alignItems: 'center', columnGap: '5px', }}
          ><Box sx={{ width: "0.75rem", height: '0.75rem', backgroundColor: payload[0]?.color, }}> </Box><Typography style={{ color: 'black', fontSize: '0.75rem', width: '9rem' }}>{`${payload[0]?.dataKey} :`}</Typography> <Typography style={{ color: 'black', fontSize: '0.75rem', textAlign: 'right', minWidth: '3rem' }}>{currencyFormat(payload[0]?.value, sessionStorage.getItem('currency_analysis'))}</Typography></Typography>
          <Typography
            style={{ color: 'black', fontSize: '0.75rem', display: 'flex', alignItems: 'center', columnGap: '5px', }}
          ><Box sx={{ width: "0.75rem", height: '0.75rem', backgroundColor: payload[1]?.color, }}></Box><Typography style={{ color: 'black', fontSize: '0.75rem', width: '9rem' }}>{`${payload[1]?.dataKey} :`} </Typography><Typography style={{ color: 'black', fontSize: '0.75rem', textAlign: 'right', minWidth: '3rem' }}>{currencyFormat(payload[1]?.value, sessionStorage.getItem('currency_analysis'))}</Typography></Typography>
        </div>
      );
    }
    return null;
  };

  const renderColorfulLegendText = (value: string, entry: any) => {

    return <span style={{ color: "#000000" }}>{value}</span>
  };

  const valGet: any = analyseMaterial.length > 0 && analyseMaterial && analyseMaterial[0];
  const sortable: any = analyseMaterial.length > 0 && analyseMaterial && Object.fromEntries(
    // @ts-ignore
    Object.entries(valGet).sort(([, a], [, b]) => b - a)
  );

  const AnalysisMaterialName: any =
    analyseMaterial.length > 0 && Object.keys(sortable)?.length > 0 &&
    Object.keys(sortable)?.filter((item: any) => {
      return item != "name";
    });


  const CustomizedMaterialTooltip: any = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      let valueNonZero: any = payload && payload?.filter((item: any) => { return item?.value != 0 });
      let a: any = [];
      for (var i = 0; i <= Math.round(payload.length / 2); i++) {
        a.push(<>
          <TableRow>
            {(valueNonZero[2 * i]?.value != undefined && valueNonZero[2 * i]?.value != 0) && <>
              <TableCell sx={{ padding: '0 0.5rem', borderBottomColor: 'primary.light' }}><Box sx={{ width: '0.75rem', height: '0.75rem', backgroundColor: valueNonZero[2 * i]?.color }}></Box></TableCell>
              <TableCell sx={{ padding: "0 0.6rem", color: 'black', fontSize: '0.75rem', borderBottomColor: 'primary.light' }}>{valueNonZero[2 * i]?.dataKey}</TableCell>
              <TableCell sx={{ textAlign: 'right', color: 'black', padding: "0 0.5rem", fontSize: '0.75rem', borderBottomColor: 'primary.light' }}>{(valueNonZero[2 * i]?.value).toFixed(1)} {sessionStorage.getItem('currency_analysis')}</TableCell>
            </>}
            {(valueNonZero[2 * i + 1]?.value != undefined && valueNonZero[2 * i + 1]?.value != 0) && <>
              <TableCell sx={{ padding: '0 0.5rem', borderBottomColor: 'primary.light' }}><Box sx={{ width: '0.75rem', height: '0.75rem', backgroundColor: valueNonZero[2 * i + 1]?.color }}></Box></TableCell>
              <TableCell sx={{ padding: "0 0.5rem", color: 'black', fontSize: '0.75rem', borderBottomColor: 'primary.light' }}>{valueNonZero[2 * i + 1]?.dataKey}</TableCell>
              <TableCell sx={{ textAlign: 'right', color: 'black', padding: "0 0.5rem", fontSize: '0.75rem', borderBottomColor: 'primary.light' }}>{(valueNonZero[2 * i + 1]?.value).toFixed(1)} {sessionStorage.getItem('currency_analysis')}</TableCell>
            </>}
          </TableRow>
        </>)
      }
      return (
        <div style={{ backgroundColor: 'white', borderRadius: '10px', overflow: 'hidden' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}><span style={{ fontSize: '0.75rem', fontWeight: 'bold' }}>{label}</span></div>
          <Table>
            {a}
          </Table>
        </div>
      );

    }
    return null;
  };

  const handleModalOpen = () => {
    colorConfigurationModal.open();
  }

  useEffect(() => {
    setSelectedCategory([]);
  }, [topVault]);

  return (
    <>
      <Box>
        <Box sx={{ padding: '0 0.5rem', display: 'flex', alignItems: 'center', columnGap: '10px' }}>
          <IconButton sx={{ cursor: "pointer" }} onClick={backButtonHandler}>
            <ArrowBackIcon color="primary" titleAccess="Go Back" />
          </IconButton>
          <Box sx={{ padding: '0 0.5rem', display: 'flex', alignItems: 'center', columnGap: '5px', justifyContent: "space-between", width: "100%" }}>
            <Button variant="contained" size="small">
              Default Analysis
            </Button>
            <Button variant="contained" size="small" onClick={handleModalOpen}>Configure Color</Button>
            {/* <span style={{ padding: '0.5rem', marginLeft: '1.3rem', border: '0.05rem solid #0080ff38', borderRadius: '6px', color: 'white', backgroundColor: '#007fff', fontSize: '1rem' }}>Default Analysis</span> */}
            {/* <span style={{ padding: '0.5rem', marginLeft: '0.2rem', border: '0.05rem solid #0080ff38', borderRadius: '6px', fontSize: '1rem' }}>+ Add More Analysis</span> */}

            {/* <Button variant="outlined" disabled size="small" onClick={handleDialogClickOpen}>
              + Add More Analysis
            </Button> */}
            <Dialog
              open={openDialog}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleDialogClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
                <Typography
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    width: "100%",
                    textAlign: "center",
                    marginRight: '-2rem'
                  }}
                >
                  Add More Analysis
                </Typography>
                <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={handleDialogClose} />
              </Box>
              <Divider
                sx={{
                  borderColor: "primary.light",
                  margin: "0 1rem",
                  marginBottom: "1rem",
                }}
              />
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Default Analysis Content section  Default Analysis Content sectionDefault Analysis Content section
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                {/* <Button onClick={handleDialogClose}>Close</Button> */}
                <Button onClick={handleDialogClose}>Ok</Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Box>
        <Box sx={{ height: '3rem', display: 'flex', alignItems: 'center', marginLeft: "4.4rem" }}>
          <Box sx={{ display: "flex", flexDirection: "row", columnGap: '10px' }}>
            {/* @ts-ignore */}
            {AllProjectdata && AllProjectdata?.map((item: any, index: any) => {
              return Object.keys(item).map((key: any, index: any) => {
                return (
                  <Box sx={{ display: 'flex', columnGap: '5px', alignItems: 'center', border: '1px solid', borderColor: 'primary.main', borderRadius: '1.5rem' }}>
                    {(filterDataIds && filterDataIds?.length <= 2 && projectLength && projectLength <= 2) || (projectLength && projectLength == 1 && filterDataIds?.length > 2) || (projectLength && projectLength == 2 && filterDataIds?.length < 5) ? " " :
                      <CancelIcon titleAccess="Delete" sx={{
                        "& :hover": {
                          transform: "scale(1.1)",
                          transition: "transform 0.5s ease",
                        },
                      }} onClick={() => deleteProject(key)} style={{ cursor: "pointer" }} />
                    } <Typography style={{ fontSize: '1rem', paddingLeft: '5px' }}>{key}</Typography>
                    <Box sx={{ display: 'flex', columnGap: '5px' }}>
                      {ChipContainer(key)}
                    </Box>
                  </Box>)
              })
            })}
          </Box>
          <img src={TableIcon} style={{ cursor: 'pointer',position:'absolute',right:'8rem', width: '1.7rem', }} onClick={()=>history.push(`/analysis_costing_table/${ projectId?.toString()}/${topVault?.toString()}`)}/>
          {analyseCost && analyseCost.length > 0 && getPermissionCurrencyButton() && <Button
            variant="contained"
            className={styles.downloadBtn}
            size="small"
            endIcon={<ArrowDropDownIcon />}
            style={{ fontSize: "12px", position: 'absolute', right: '0', marginRight: '2rem' }}
            onClick={() => currencyModal.open()}
          >
            {sessionStorage.getItem("currency_analysis")}
          </Button>}
        </Box>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}
                >
                  <Box
                    sx={{ padding: "1rem 0rem", paddingBottom: '0' }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                  >
                    <Typography style={{ width: "100%", textAlign: "center", fontSize: '1rem', lineHeight: '1.2' }}>
                      Select to Change Analysis <br />( Project / Products )
                    </Typography>
                    <Divider sx={{ margin: "0.5rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  style={{
                    transform: "rotate(270deg)",
                    whiteSpace: "nowrap",
                    marginTop: "49vh",
                    cursor: "pointer",
                    fontSize: '1rem',
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  Select to Change Analysis ( Project / Products )
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        <Box sx={{ display: 'grid', gridTemplateColumns: { sm: '1fr', md: '1fr', lg: '1fr 1fr', xl: '1fr 1fr' }, width: '100%', height: {lg:'82vh',xl:'89vh' }}}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3">
          <Box>
            <>{!costKgLoader ?
              <Box>
                <Box sx={{ width: "100%", height: '42vh' }}
                  adl-scrollbar="true"
                  adl-scrollbar-width="0.3">
                <Box sx={{ width: {sm: '100%',md: '100%', lg: '800px', xl: '100%' }, height: {lg:'36vh',xl:'40vh'} }}>
                  <Box>
                    <Typography
                      style={{
                        textAlign: "center",
                        width: "96%",
                        display: "inline-block",
                        fontSize: '1rem',
                        fontWeight: '600'
                      }}
                    >
                      Cost/Kg Comparison
                    </Typography>
                    {analyseCostKg.length > 0 && <ZoomOutMapIcon
                      titleAccess="Maximize"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        history.push(`/analysis_costing/kgCost/${projectId}/${topVault}`)
                      }
                    />}
                  </Box>
                  {analyseCostKg && analyseCostKg.length > 0 ? (<ResponsiveContainer >
                    <BarChart
                      data={analyseCostKg && analyseCostKg}
                      className={styles.BarChartAnalysis}
                      margin={{
                        top: 20,
                        right: 20,
                        left: 20,
                        bottom: width > 1400 ? 20 : 5,
                      }}

                    >
                      <Bar
                        dataKey="value"
                        barSize={100}
                        style={{ overflow: "hidden" }}
                        label={<CustomizedCostKgLabel />}                                              >
                        {analyseCostKg &&
                          analyseCostKg?.map((entry: any) => (
                            <Cell fill={entry?.color} />
                          ))}
                      </Bar>
                      <XAxis dataKey="name"
                        // angle={-90}

                        textAnchor="middle" fontSize="0.75rem" stroke="#000000" interval={0}
                      />
                      <YAxis stroke="#000000" textAnchor="end" fontSize="0.75rem" tickFormatter={(tick: any) => String(Math.ceil(Number(tick)))}>
                        <Label
                          style={{
                            textAnchor: "middle",
                            fontSize: "0.75rem",
                            fontWeight: '500',
                            fill: '#000000'
                          }}
                          angle={270}
                          dx={-22}
                          value={`${sessionStorage.getItem('currency_analysis')}/Kg`}
                        />
                      </YAxis>
                    </BarChart>
                  </ResponsiveContainer>)
                    : <Box className={styles.analysisModuleNotFound}>
                      Costing Permission is not there
                    </Box>
                  }
                  </Box>
                </Box>
              </Box>
              :
              <Box sx={{ width: '100%', height: '100%' }}>
                <Skeleton animation="pulse" variant="rectangular" width="90%" height="40%" sx={{ marginLeft: '5rem' }} />
              </Box>
            }
            </>
            <> {!materialLoader ? <>
              {analyseMaterial.length > 0 && AnalysisMaterialName?.length > 0 && (


                <Box sx={{ width: "100%", height: '42vh' }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3">
                  <Box sx={{ width: { lg: '800px', xl: '100%' }, height: {lg:'36vh',xl:'40vh'} }}>
                    <Box>
                      <Typography
                        style={{
                          textAlign: "center",
                          width: "96%",
                          display: "inline-block",
                          fontSize: '1rem',
                          fontWeight: '600'
                        }}
                      >
                        Category Wise Part Cost Distribution Comparison
                      </Typography>
                      {allCategory && <Box sx={{display:"flex", justifyContent:"center", alignItems:"center", gap:"1rem"}}><Autocomplete
                        multiple
                        disableClearable
                        id='Category'
                        value={selectedCategory}
                        limitTags={4}
                        disableCloseOnSelect
                        onChange={(event, newValue) => {
                          handleSelectChange(newValue);
                        }}
                        sx={{ marginBottom: '0.5rem', width: '60%' }}
                        options={allCategory && allCategory?.length > 0 ? allCategory : []}
                        // @ts-ignore
                        getOptionLabel={(option) => option}
                        renderOption={(props, option) => (
                          <li {...props} style={{ fontSize: '1rem' }}>
                            {option}
                          </li>
                        )}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              size='small'
                              //  @ts-ignore
                              sx={{
                                backgroundColor: '#007fff14',
                                color: 'primary.main',
                                fontSize: '1rem',
                              }}
                              // @ts-ignore
                              label={option}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // label="Commodity *"
                            variant='standard'
                            sx={{
                              marginTop: '1rem',
                              '& .MuiInput-underline:before': {
                                borderBottomColor: 'primary.light',
                              },
                              '& .MuiInput-underline:after': {
                                borderBottomColor: 'primary.main',
                              },
                              '.MuiSvgIcon-root': { color: 'primary.main' },
                            }}
                          />
                        )}
                      />
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => handleApplyFilterCategory()}
                        sx={{width:"20%"}}
                        >
                        Apply Filter
                      </Button>
                      {analyseMaterial.length > 0 && AnalysisMaterialName?.length > 0 && <ZoomOutMapIcon titleAccess="Maximize"
                        onClick={() => { return history.push(`/analysis_costing/CategoryWise/${projectId}/${topVault}`) }}
                        style={{ cursor: "pointer", position: "relative", zIndex: '1' }}
                      />}
                      </Box>}
                      
                    </Box>
                    {analyseMaterial.length > 0 && AnalysisMaterialName?.length > 0 ? (<ResponsiveContainer>
                      <BarChart
                        layout="vertical"
                        data={analyseMaterial}
                        // stackOffset="expand"
                        margin={{
                          top: 20,
                          right: 50,
                          left: 5,
                          bottom: width > 1400 ? 40 : 5,
                        }}
                      >
                        <Legend
                          layout="vertical"
                          verticalAlign="top"
                          align="right"
                          stroke="#000000"
                          wrapperStyle={{ minHeight: '17.5rem',maxHeight:"21.5rem", overflowY: 'scroll', right: "10px", fontSize: '0.75rem', color: 'black' }}
                          formatter={renderColorfulLegendText}
                        />
                        <XAxis
                          type="number"
                          tickFormatter={(tick) => `${Math.ceil(tick)}`}
                          fontSize="0.75rem"
                          stroke="#000000"
                          interval={0}
                        >
                          <Label value={`${sessionStorage.getItem('currency_analysis')}/Category`} offset={0} fontSize="0.75rem" fontWeight='500' position="insideBottom" fill="#000000" />
                        </XAxis>
                        <YAxis type="category" dataKey="name" interval={0} dx={-3}
                          width={75} fontSize="0.75rem" stroke="#000000" />
                        <Tooltip cursor={false} content={<CustomizedMaterialTooltip />} />
                        {AnalysisMaterialName?.length > 0 &&
                          AnalysisMaterialName?.map((item: any, index: number) => {
                            return (
                              <Bar dataKey={item} stackId="a" fill={color[index]} />
                            );
                          })}
                      </BarChart>
                    </ResponsiveContainer>)
                      : <Box className={styles.analysisModuleNotFound}>
                        Costing Permission is not there
                      </Box>}
                  </Box>
                </Box>
              )} </> :
              <Box sx={{ width: '100%', height: '100%' }}>
                <Skeleton animation="pulse" variant="rectangular" width="90%" height="40%" sx={{ marginLeft: '5rem' }} />
              </Box>
            }</>
          </Box>
          <Box>
            <>
              {!costLoader ? <>
                {analyseCost && analyseCost.length > 0 && (
                  <Box sx={{ width: "100%", height: '42vh' }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3">
                    <Box sx={{ width: { lg: '800px', xl: '100%' }, height: {lg:'36vh',xl:'40vh'} }}>
                      <Box>
                        <Typography
                          style={{
                            textAlign: "center",
                            width: "90%",
                            display: "inline-block",
                            fontSize: '1rem',
                            fontWeight: '600'
                          }}
                        >
                          Manufacturing Cost Comparison
                        </Typography>
                        {analyseCost && analyseCost.length > 0 && <ZoomOutMapIcon titleAccess="Maximize"
                          onClick={() => { return history.push(`/analysis_costing/manufacturing/${projectId}/${topVault}`) }}
                          style={{ cursor: "pointer", position: "relative", zIndex: '1' }}
                        />}
                      </Box>
                      {analyseCost && analyseCost.length > 0 ? (<ResponsiveContainer>
                        <BarChart
                          data={analyseCost && analyseCost}
                          className={styles.BarChartAnalysis}
                          width={880}
                          height={355}
                          margin={{
                            top: 20,
                            right: 50,
                            left: 20,
                            bottom: width > 1400 ? 20 : 5,
                          }}
                        >
                          <Legend
                            layout="vertical"
                            verticalAlign="top"
                            align="right"
                            margin={{ bottom: 10 }}
                            wrapperStyle={{ right: "10px", fontSize: '0.75rem' }}
                            formatter={renderColorfulLegendText}
                          />
                          <XAxis
                            dataKey="name"
                            // angle={-90}
                            textAnchor="middle"
                            interval={0}
                            fontSize="0.75rem"
                            stroke="#000000"
                          />
                          <YAxis tickFormatter={(tick) => currencyFormat(tick, sessionStorage.getItem('currency_analysis')).slice(0, currencyFormat(tick, sessionStorage.getItem('currency_analysis')).indexOf('.'))} fontSize="0.75rem" stroke="#000000" domain={[
                            () => 0,
                            (dataMax: number) => dataMax.toFixed(0),
                          ]} >
                            <Label
                              style={{
                                textAnchor: "middle",
                                fontSize: "0.75rem",
                                fontWeight: '500',
                                fill: '#000000'
                              }}
                              angle={270}
                              dx={-35}
                              value={`Mfg. ( ${sessionStorage.getItem('currency_analysis')} )`}
                            />
                          </YAxis>
                          <Tooltip cursor={false} content={<CustomizedCostTooltip />} />
                          <Bar
                            barSize={100}
                            dataKey={"Raw Material"}
                            stackId="a"
                            fill={color[0]}
                            style={{ cursor: "pointer" }}
                          />
                          <Bar
                            barSize={100}
                            dataKey={"Process"}
                            stackId="a"
                            fill={color[1]}
                            style={{ cursor: "pointer" }}
                          />
                          <Bar
                            barSize={100}
                            dataKey={"Overhead"}
                            stackId="a"
                            fill={color[2]}
                            style={{ cursor: "pointer" }}
                          >
                            <LabelList
                              dataKey="total_cost"
                              position="center"
                              content={renderCustomizedTotalCostLabel}
                            />
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>)
                        : <Box className={styles.analysisModuleNotFound}>
                          Costing Permission is not there
                        </Box>}
                    </Box>
                  </Box>
                )} </> :
                <Box sx={{ width: '100%', height: '100%' }}>
                  <Skeleton animation="pulse" variant="rectangular" width="90%" height="40%" sx={{ marginLeft: '5rem' }} />
                </Box>
              }</>

            {!analyseBopDcLoader ? <>
              {analyseBopDc && analyseBopDc.length > 0 && (
                <Box sx={{ width: "100%", height: '42vh' }}
                  adl-scrollbar="true"
                  adl-scrollbar-width="0.3">
                  <Box sx={{ width: { lg: '800px', xl: '100%' }, height: {lg:'36vh',xl:'40vh'} }}>
                    <Box>
                      <Typography
                        style={{
                          textAlign: "center",
                          width: "90%",
                          display: "inline-block",
                          fontSize: '1rem',
                          fontWeight: '600'
                        }}
                      >
                        Bought Out & Detailed Costing Parts Distribution Comparison
                      </Typography>
                      {analyseBopDc && analyseBopDc.length > 0 && <ZoomOutMapIcon titleAccess="Maximize"
                        onClick={() => { return history.push(`/analysis_costing/bopdc/${projectId}/${topVault}`) }}
                        style={{ cursor: "pointer", position: "relative", zIndex: '1' }}
                      />}
                    </Box>
                    {analyseBopDc && analyseBopDc.length > 0 ? (<ResponsiveContainer>
                      <BarChart
                        data={analyseBopDc && analyseBopDc}
                        className={styles.BarChartAnalysis}
                        width={880}
                        height={355}
                        margin={{
                          top: 20,
                          right: 50,
                          left: 20,
                          bottom: width > 1400 ? 40 : 5,
                        }}
                      >
                        <Legend
                          layout="vertical"
                          verticalAlign="top"
                          align="right"
                          margin={{ bottom: 10 }}
                          wrapperStyle={{ right: "10px", fontSize: '0.75rem' }}
                          formatter={renderColorfulLegendText}
                        />
                        <XAxis
                          dataKey="name"
                          // angle={-90}
                          textAnchor="middle"
                          interval={0}
                          fontSize="0.75rem"
                          stroke="#000000"
                        />
                        <YAxis tickFormatter={(tick) => currencyFormat(tick, sessionStorage.getItem('currency_analysis')).slice(0, currencyFormat(tick, sessionStorage.getItem('currency_analysis')).indexOf('.'))} fontSize="0.75rem" stroke="#000000" domain={[
                          () => 0,
                          (dataMax: number) => dataMax.toFixed(0),
                        ]} >
                          <Label
                            style={{
                              textAnchor: "middle",
                              fontSize: "0.75rem",
                              fontWeight: '500',
                              fill: '#000000'
                            }}
                            angle={270}
                            dx={-35}
                            value={`${sessionStorage.getItem('currency_analysis')}`}
                          />
                        </YAxis>
                        <Tooltip cursor={false} content={<CustomizedBopDcTooltip />} />
                        <Bar
                          barSize={100}
                          dataKey={"Bought-Out Part Costing"}
                          stackId="a"
                          fill={color[0]}
                          style={{ cursor: "pointer" }}
                        />
                        <Bar
                          barSize={100}
                          dataKey={"Detailed Costing"}
                          stackId="a"
                          fill={color[2]}
                          style={{ cursor: "pointer" }}
                        >
                          <LabelList
                            dataKey="total_cost"
                            position="center"
                            content={renderCustomizedTotalCostLabel}
                          />
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>)
                      : <Box className={styles.analysisModuleNotFound}>
                        Costing Permission is not there
                      </Box>}
                  </Box>
                </Box>
              )} </> :
              <Box sx={{ width: '100%', height: '100%' }}>
                <Skeleton animation="pulse" variant="rectangular" width="90%" height="40%" sx={{ marginLeft: '5rem' }} />
              </Box>
            }
          </Box>
        </Box>
      </Box>
      <CurrencyChange
        isOpen={currencyModal.isOpen}
        onCloseModal={currencyModal.close}
        setCount={setCount}
      />
      {
        colorConfigurationModal.isOpen && (
          <ConfigureColorModal isOpen={colorConfigurationModal.isOpen} onClose={colorConfigurationModal.close} AllProjectdata={AllProjectdata} topVaultColor={topVaultsColor} setCounter={setCounter} />
        )
      }
    </>
  )
}

export default AnalysisCostingLandingPage;