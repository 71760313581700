import {
	Box,
	Grid,
	Paper,
	Skeleton,
	Typography,
	useTheme,
} from '@mui/material';
import * as React from 'react';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import { useRouteParams } from '../ui-reusable-component/useRouteParams';
import { useHistory } from 'react-router-dom';
// import {
//   PieChart,
//   Pie,
//   Sector,
//   Cell,
//   ResponsiveContainer,
//   Legend,
// } from "recharts";
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { ZBCTable } from './ZBCTable';
import ProductProgress from '../ComponentUI/Landing/ProductProgress';
import { SupplierActionTable } from './SupplierActionTable';
import { RequestScenarioTable } from './RequestScenarioTable';
import { CreatePartTable } from './CreatePartTable';
import { API } from '../api-services';
import ClaimBusinessandPartProgress from '../ComponentUI/Landing/ClaimBusinessandPartProgress';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import LockIcon from '@mui/icons-material/Lock';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import PublishLock from './PublishLock';
import ScenarioRequestProgressBar from '../ComponentUI/Landing/ScenarioRequestProgressBar';
import UnlistedPartsTable from './UnlistedPartsTable';
export interface IRequestMainPageProps {}

export function RequestMainPage(props: IRequestMainPageProps) {
	const [cardDetails, setCardDetails] = React.useState<any>([]);
	const [cardLoading, setCardLoading] = React.useState<boolean>(false);
	const [parentsCounter, setParnetsCounter] = React.useState<any>(0);
	const {searchKey}=useRouteParams<any>();
	const theme = useTheme();
	const history = useHistory();
	const { requestType } = useRouteParams<any>();

	
	const getCardDetails = () => {
		setCardLoading(true);
		API.get('/cart/request_dashboard_info/', {}, 0)
			.then((res: any) => {
				setCardDetails(res.data);
				setCardLoading(false);
			})
			.catch((err: any) => {
				setCardLoading(false);
				console.log('error in getting card details', err);
			});
	};

	React.useEffect(() => {
		getCardDetails();
	}, [parentsCounter]);

	const getAllTable = () => {
		console.log('requestType', requestType);
		switch (requestType) {
			case 1:
				return <ZBCTable setParnetsCounter={setParnetsCounter} />;
			case 2:
				return <RequestScenarioTable />;
			case 3:
				return <SupplierActionTable />;
			case 4:
				return <CreatePartTable />;
			case 5:
				return <PublishLock setParnetsCounter={setParnetsCounter} />;
				case 6:
					return <UnlistedPartsTable />;
			default:
				return <ZBCTable setParnetsCounter={setParnetsCounter} />;
		}
	};

	const RedirectToPartSection = (index: number) => {
		sessionStorage.removeItem('supplierId');
		history.push(`/requestPage/${index + 1}/${0}/${0}`);
	}
	return (
		<Box
			sx={{
				padding: '0.5rem 0',
				height: { lg: '92.5vh', xl: '95vh', md: '90vh', sm: '90vh', xs: '90vh' },
				//@ts-ignore
				background: theme.palette.background.customBackground,
			}}
			adl-scrollbar='true'
			adl-scrollbar-width='0.3'>
			<Box sx={{ cursor: 'pointer', padding: '0 2rem' }}>
				<Grid container spacing={1}>
					<Typography sx={{ margin: '1rem 1rem 0' }} variant='h4'>
						Request Dashboard Page
					</Typography>
					<Grid
						item
						xs={12}
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							gap: { xs: 3, lg: 3, xl: 6 },
						}}>
						{!cardLoading ? (
							cardDetails?.map((value: any, index: number) => (
								<>
								<Box key={index} sx={{ width: '100%' }}>
									{value.request_type == 'LOCK & PUBLISH ASI DB PART' ? (
										<Paper
											sx={{
												height: '100%',
												width: '100%',
												borderRadius: 2,
												boxShadow:
													index + 1 == requestType
														? 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
														: 0,
												padding: value?.id == requestType ? '0.5rem' : '0.5rem',
												backgroundColor:
													index + 1 == requestType
														? '#6dd5ed'
														: 'secondary.light',
											}}
											onClick={() => history.push(`/requestPage/${index + 1}/${0}/${0}`)}>
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'center',
													rowGap: 1,
													alignItems: 'center',
													height: '100%',
													color: 'black',
												}}>
												<Box>
													<LockIcon sx={{ width: '3rem', height: '3rem' }} />
													<ArrowRightAltIcon
														sx={{ width: '3rem', height: '3rem' }}
													/>
													<PublishedWithChangesIcon
														sx={{ width: '3rem', height: '3rem' }}
													/>
												</Box>
												<Box
													sx={{
														fontSize: '1rem',
														fontWeight: '600',
														textAlign: 'center',
													}}>
													{/* {value.request_type} */}
													Lock & Publish ASI Added Parts
												</Box>
												<Box
													sx={{
														display: 'grid',
														gridTemplateColumns: '1fr 1fr',
														columnGap: { lg: 1, xl: 2 },
														rowGap: { lg: 1, xl: 1 },
														width: '100%',
													}}>
													{value.request_type ==
													'LOCK & PUBLISH ASI DB PART' ? (
														<Box
															sx={{
																display: 'flex',
																alignItems: 'center',
																columnGap: '1rem',
																justifyContent: 'space-between',
															}}>
															<Box
																sx={{
																	fontSize: '0.87rem',
																	width: 'fit-content',
																	whiteSpace: 'nowrap',
																}}>
																Pending Parts
															</Box>
															<Box sx={{ fontSize: '0.87rem' }}>
																{value.pending}
															</Box>
														</Box>
													) : (
														''
													)}
													<Box
														sx={{
															display: 'flex',
															alignItems: 'center',
															columnGap: '1rem',
															justifyContent: 'space-between',
														}}>
														<Box
															sx={{
																fontSize: '0.87rem',
																width: 'fit-content',
																whiteSpace: 'nowrap',
															}}>
															Total Parts
														</Box>
														<Box sx={{ fontSize: '0.87rem' }}>
															{value.total}
														</Box>
													</Box>

													<Box
														sx={{
															display: 'flex',
															alignItems: 'center',
															columnGap: '1rem',
															justifyContent: 'space-between',
														}}>
														<Box
															sx={{
																fontSize: '0.87rem',
																width: 'fit-content',
																whiteSpace: 'nowrap',
															}}>
															Published Parts
														</Box>
														<Box sx={{ fontSize: '0.87rem' }}>
															{value.published}
														</Box>
													</Box>
												</Box>
												<Box
													sx={{
														height: '1.2rem',
														width: '100%',
													}}></Box>
												<Box
													sx={{
														display: 'flex',
														gap: 1,
														width: '100%',
														// marginTop: '2rem',
													}}>
													<Box
														sx={{
															display: 'flex',
															flexDirection: 'column',
															gap: 1,
															width: '100%',
														}}>
														<ProductProgress
															progressData={{
																not_started: value.pending,
																in_progress: 0,
																completed: value.published,
															}}
														/>
													</Box>
												</Box>
											</Box>
										</Paper>
									) 
									: value.request_type == 'FIRST ZERO-BASED COSTING' ? (
										<Paper
											sx={{
												height: '100%',
												width: '100%',
												borderRadius: 2,
												boxShadow:
													index + 1 == requestType
														? 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
														: 0,
												padding: value?.id == requestType ? '0.5rem' : '0.5rem',
												backgroundColor:
													index + 1 == requestType
														? '#6dd5ed'
														: 'secondary.light',
											}}
											onClick={() => history.push(`/requestPage/${index + 1}/${0}/${0}`)}>
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'center',
													rowGap: 1,
													alignItems: 'center',
													height: '100%',
													color: 'black',
												}}>
												<Box>
													<DonutSmallIcon
														sx={{ width: '3rem', height: '3rem' }}
													/>
												</Box>
												<Box
													sx={{
														fontSize: '1rem',
														fontWeight: '600',
														textAlign: 'center',
													}}>
													{/* {value.request_type} */}
													First ZBC from drawing or physical Part
												</Box>
												<Box
													sx={{
														display: 'grid',
														gridTemplateColumns: '1fr 1fr',
														columnGap: { lg: 1, xl: 2 },
														rowGap: { lg: 1, xl: 1 },
														width: '100%',
													}}>
													<Box
														sx={{
															display: 'flex',
															alignItems: 'center',
															columnGap: '1rem',
															justifyContent: 'space-between',
														}}>
														<Box
															sx={{
																fontSize: '0.87rem',
																width: 'fit-content',
																whiteSpace: 'nowrap',
															}}>
															Quote Pending
														</Box>
														<Box sx={{ fontSize: '0.87rem' }}>
															{value.quote_pending}
														</Box>
													</Box>

													<Box
														sx={{
															display: 'flex',
															alignItems: 'center',
															columnGap: '1rem',
															justifyContent: 'space-between',
														}}>
														<Box
															sx={{
																fontSize: '0.87rem',
																width: 'fit-content',
																whiteSpace: 'nowrap',
															}}>
															Payment Pending
														</Box>
														<Box sx={{ fontSize: '0.87rem' }}>
															{value.payment_pending}
														</Box>
													</Box>
													<Box
														sx={{
															display: 'flex',
															alignItems: 'center',
															columnGap: '1rem',
															justifyContent: 'space-between',
														}}>
														<Box
															sx={{
																fontSize: '0.87rem',
																width: 'fit-content',
																whiteSpace: 'nowrap',
															}}>
															Ongoing
														</Box>
														<Box sx={{ fontSize: '0.87rem' }}>
															{value.ongoing}
														</Box>
													</Box>
													<Box
														sx={{
															display: 'flex',
															alignItems: 'center',
															columnGap: '1rem',
															justifyContent: 'space-between',
														}}>
														<Box
															sx={{
																fontSize: '0.87rem',
																width: 'fit-content',
																whiteSpace: 'nowrap',
															}}>
															Published
														</Box>
														<Box sx={{ fontSize: '0.87rem' }}>
															{value.published}
														</Box>
													</Box>
												</Box>
												<Box
													sx={{
														display: 'flex',
														alignItems: 'center',
														columnGap: '1rem',
														justifyContent: 'space-between',
														width: '100%',
													}}>
													<Box
														sx={{
															fontSize: '0.87rem',
															width: 'fit-content',
															whiteSpace: 'nowrap',
															fontWeight: 'bold',
														}}>
														Total Amount Received
													</Box>
													<Box sx={{ fontSize: '0.87rem', fontWeight: 'bold' }}>
														{value.total_amount_paid_currency}{' '}
														{value.total_amount_paid}
													</Box>
												</Box>
												<Box
													sx={{
														display: 'flex',
														gap: 1,
														width: '100%',
														// marginTop: '2rem',
													}}>
													<Box
														sx={{
															display: 'flex',
															flexDirection: 'column',
															gap: 1,
															width: '100%',
														}}>
														<ProductProgress
															progressData={{
																not_started: value.payment_pending,
																in_progress: value.ongoing,
																completed: value.published,
															}}
														/>
													</Box>
												</Box>
											</Box>
										</Paper>
									) : value.request_type == 'OTHER ZERO-BASED COSTING' ? (
										<Paper
											sx={{
												height: '100%',
												width: '100%',
												borderRadius: 2,
												boxShadow:
													index + 1 == requestType
														? 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
														: 0,
												padding: value?.id == requestType ? '0.5rem' : '0.5rem',
												backgroundColor:
													index + 1 == requestType
														? '#6dd5ed'
														: 'secondary.light',
											}}
											onClick={() => history.push(`/requestPage/${index + 1}/${0}/${0}`)}>
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'center',
													rowGap: 1,
													alignItems: 'center',
													height: '100%',
													color: 'black',
												}}>
												<Box>
													<RequestQuoteIcon
														sx={{ width: '3rem', height: '3rem' }}
													/>
												</Box>
												<Box
													sx={{
														fontSize: '1rem',
														fontWeight: '600',
														textAlign: 'center',
													}}>
													{/* {value.request_type} */}
													Other ZBC for existing parts													
												</Box>
												<Box
													sx={{
														display: 'grid',
														gridTemplateColumns: '1fr 1fr',
														columnGap: { lg: 1, xl: 2 },
														rowGap: { lg: 1, xl: 1 },
														width: '100%',
													}}>
													<Box
														sx={{
															display: 'flex',
															alignItems: 'center',
															columnGap: '1rem',
															justifyContent: 'space-between',
														}}>
														<Box
															sx={{
																fontSize: '0.87rem',
																width: 'fit-content',
																whiteSpace: 'nowrap',
															}}>
															Request Raised
														</Box>

														<Box sx={{ fontSize: '0.87rem' }}>
															{value.request_raised}
														</Box>
													</Box>
													<Box
														sx={{
															display: 'flex',
															alignItems: 'center',
															columnGap: '1rem',
															justifyContent: 'space-between',
														}}>
														<Box
															sx={{
																fontSize: '0.87rem',
																width: 'fit-content',
																whiteSpace: 'nowrap',
															}}>
															Payment Done
														</Box>
														<Box sx={{ fontSize: '0.87rem' }}>
															{value.payment_done}
														</Box>
													</Box>
													<Box
														sx={{
															display: 'flex',
															alignItems: 'center',
															columnGap: '1rem',
															justifyContent: 'space-between',
														}}>
														<Box
															sx={{
																fontSize: '0.87rem',
																width: 'fit-content',
																whiteSpace: 'nowrap',
															}}>
															In Progress
														</Box>
														<Box sx={{ fontSize: '0.87rem' }}>
															{value.in_progress}
														</Box>
													</Box>
													<Box
														sx={{
															display: 'flex',
															alignItems: 'center',
															columnGap: '1rem',
															justifyContent: 'space-between',
														}}>
														<Box
															sx={{
																fontSize: '0.87rem',
																width: 'fit-content',
																whiteSpace: 'nowrap',
															}}>
															Published
														</Box>
														<Box sx={{ fontSize: '0.87rem' }}>
															{value.published}
														</Box>
													</Box>
												</Box>
												<Box
													sx={{
														display: 'flex',
														alignItems: 'center',
														columnGap: '1rem',
														justifyContent: 'space-between',
														width: '100%',
													}}>
													<Box
														sx={{
															fontSize: '0.87rem',
															width: 'fit-content',
															whiteSpace: 'nowrap',
															fontWeight: 'bold',
														}}>
														Total Amount Received
													</Box>
													<Box sx={{ fontSize: '0.87rem', fontWeight: 'bold' }}>
														{value.total_amount_paid_currency}{' '}
														{value.total_amount_paid}
													</Box>
												</Box>
												<Box
													sx={{
														display: 'flex',
														gap: 1,
														width: '100%',
														// marginTop: '2rem',
													}}>
													<Box
														sx={{
															display: 'flex',
															flexDirection: 'column',
															gap: 1,
															width: '100%',
														}}>
														<ScenarioRequestProgressBar
															progressData={{
																request_raised: value.request_raised,
																payment_done: value.payment_done,
																in_progress: value.in_progress,
																published: value.published,
															}}
														/>
													</Box>
												</Box>
											</Box>
										</Paper>
									) : value.request_type == 'CLAIM/CREATE BUSINESS' ? (
										<Paper
											sx={{
												height: '100%',
												width: '100%',
												borderRadius: 2,
												boxShadow:
													index + 1 == requestType
														? 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
														: 0,
												padding: value?.id == requestType ? '0.5rem' : '0.5rem',
												backgroundColor:
													index + 1 == requestType
														? '#6dd5ed'
														: 'secondary.light',
											}}
											onClick={() => history.push(`/requestPage/${index + 1}/${0}/${0}`)}>
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'center',
													rowGap: 1,
													alignItems: 'center',
													height: '100%',
													color: 'black',
												}}>
												<Box>
													<PrecisionManufacturingIcon
														sx={{ width: '3rem', height: '3rem' }}
													/>
												</Box>
												<Box
													sx={{
														fontSize: '1rem',
														fontWeight: '600',
														textAlign: 'center',
													}}>
													{/* {value.request_type == "CLAIM/CREATE BUSINESS"?"CLAIM/CREATE/EDIT BUSINESS":value.request_type} */}
													Add Business ( By Seller )
												</Box>
												<Box
													sx={{
														display: 'grid',
														gridTemplateColumns: '1fr 1fr',
														columnGap: { lg: 1, xl: 2 },
														rowGap: { lg: 1, xl: 1 },
														width: '100%',
													}}>
													<Box
														sx={{
															display: 'flex',
															alignItems: 'center',
															columnGap: '1rem',
															justifyContent: 'space-between',
														}}>
														<Box
															sx={{
																fontSize: '0.87rem',
																width: 'fit-content',
																whiteSpace: 'nowrap',
															}}>
															Accepted
														</Box>
														<Box sx={{ fontSize: '0.87rem' }}>
															{value.accepted}
														</Box>
													</Box>
													<Box
														sx={{
															display: 'flex',
															alignItems: 'center',
															columnGap: '1rem',
															justifyContent: 'space-between',
														}}>
														<Box
															sx={{
																fontSize: '0.87rem',
																width: 'fit-content',
																whiteSpace: 'nowrap',
															}}>
															Pending
														</Box>
														<Box sx={{ fontSize: '0.87rem' }}>
															{value.pending}
														</Box>
													</Box>
													<Box
														sx={{
															display: 'flex',
															alignItems: 'center',
															columnGap: '1rem',
															justifyContent: 'space-between',
														}}>
														<Box
															sx={{
																fontSize: '0.87rem',
																width: 'fit-content',
																whiteSpace: 'nowrap',
															}}>
															Rejected
														</Box>
														<Box sx={{ fontSize: '0.87rem' }}>
															{value.rejected}
														</Box>
													</Box>
												</Box>
												<Box
													sx={{
														height: '1.2rem',
														width: '100%',
													}}></Box>
												<Box
													sx={{
														display: 'flex',
														gap: 1,
														width: '100%',
														// marginTop: '2rem',
													}}>
													<Box
														sx={{
															display: 'flex',
															flexDirection: 'column',
															gap: 1,
															width: '100%',
														}}>
														<ClaimBusinessandPartProgress
															progressData={{
																rejected: value.rejected,
																pending: value.pending,
																accepted: value.accepted,
															}}
														/>
													</Box>
												</Box>
											</Box>
										</Paper>
									) : value.request_type == 'PARTS FOR APPROVAL' ? (
										<Paper
											sx={{
												height: '100%',
												width: '100%',
												borderRadius: 2,
												boxShadow:
													index + 1 == requestType
														? 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
														: 0,
												padding: value?.id == requestType ? '0.5rem' : '0.5rem',
												backgroundColor:
													index + 1 == requestType
														? '#6dd5ed'
														: 'secondary.light',
											}}
											onClick={() => RedirectToPartSection(index)}>
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'center',
													rowGap: 1,
													alignItems: 'center',
													height: '100%',
													color: 'black',
												}}>
												<Box>
													<AgricultureIcon
														sx={{ width: '3rem', height: '3rem' }}
													/>
												</Box>
												<Box
													sx={{
														fontSize: '1rem',
														fontWeight: '600',
														textAlign: 'center',
													}}>
													{/* {value.request_type} */}
													Add Parts ( By Seller )
												</Box>
												<Box
													sx={{
														display: 'grid',
														gridTemplateColumns: '1fr 1fr',
														columnGap: { lg: 1, xl: 2 },
														rowGap: { lg: 1, xl: 1 },
														width: '100%',
													}}>
													<Box
														sx={{
															display: 'flex',
															alignItems: 'center',
															columnGap: '1rem',
															justifyContent: 'space-between',
														}}>
														<Box
															sx={{
																fontSize: '0.87rem',
																width: 'fit-content',
																whiteSpace: 'nowrap',
															}}>
															Accepted
														</Box>
														<Box sx={{ fontSize: '0.87rem' }}>
															{value.accepted}
														</Box>
													</Box>
													<Box
														sx={{
															display: 'flex',
															alignItems: 'center',
															columnGap: '1rem',
															justifyContent: 'space-between',
														}}>
														<Box
															sx={{
																fontSize: '0.87rem',
																width: 'fit-content',
																whiteSpace: 'nowrap',
															}}>
															Pending
														</Box>
														<Box sx={{ fontSize: '0.87rem' }}>
															{value.pending}
														</Box>
													</Box>
													<Box
														sx={{
															display: 'flex',
															alignItems: 'center',
															columnGap: '1rem',
															justifyContent: 'space-between',
														}}>
														<Box
															sx={{
																fontSize: '0.87rem',
																width: 'fit-content',
																whiteSpace: 'nowrap',
															}}>
															Rejected
														</Box>
														<Box sx={{ fontSize: '0.87rem' }}>
															{value.rejected}
														</Box>
													</Box>
												</Box>
												<Box
													sx={{
														height: '1.2rem',
														width: '100%',
													}}></Box>
												<Box
													sx={{
														display: 'flex',
														gap: 1,
														width: '100%',
														//  marginTop: '2rem',
													}}>
													<Box
														sx={{
															display: 'flex',
															flexDirection: 'column',
															gap: 1,
															width: '100%',
														}}>
														<ClaimBusinessandPartProgress
															progressData={{
																rejected: value.rejected,
																pending: value.pending,
																accepted: value.accepted,
															}}
														/>
													</Box>
												</Box>
											</Box>
										</Paper>
									) : value.request_type == 'UNLISTED PART REQUESTS' ? (
										<Paper
											sx={{
												height: '100%',
												width: '100%',
												borderRadius: 2,
												boxShadow:
													index + 1 == requestType
														? 
														'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
														 : 0
														,
												padding:
												 value?.id == requestType ? '0.5rem' : '0.5rem'
												,
												backgroundColor:
													 index + 1 == requestType
													 	? '#6dd5ed'
													 	: 'secondary.light'
													
											}}
											onClick={() => history.push(`/requestPage/${index + 1}/${0}/${0}`)}>
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'center',
													rowGap: 1,
													alignItems: 'center',
													height: '100%',
													color: 'black',
												}}>
												<Box>
													<AlignHorizontalLeftIcon
														sx={{ width: '3rem', height: '3rem' }}
													/>
												</Box>
												<Box
													sx={{
														fontSize: '1rem',
														fontWeight: '600',
														textAlign: 'center',
													}}>
													{value.request_type}
											
												</Box>
												<Box
													sx={{
														display: 'grid',
														gridTemplateColumns: '1fr 1fr',
														columnGap: { lg: 1, xl: 2 },
														rowGap: { lg: 1, xl: 1 },
														width: '100%',
													}}>
													<Box
														sx={{
															display: 'flex',
															alignItems: 'center',
															columnGap: '1rem',
															justifyContent: 'space-between',
														}}>
														<Box
															sx={{
																fontSize: '0.87rem',
																width: 'fit-content',
																whiteSpace: 'nowrap',
															}}>
															Accepted
														</Box>
														<Box sx={{ fontSize: '0.87rem' }}>
															{value?.fulfilled}
														</Box>
													</Box>
													<Box
														sx={{
															display: 'flex',
															alignItems: 'center',
															columnGap: '1rem',
															justifyContent: 'space-between',
														}}>
														<Box
															sx={{
																fontSize: '0.87rem',
																width: 'fit-content',
																whiteSpace: 'nowrap',
															}}>
															Pending
														</Box>
														<Box sx={{ fontSize: '0.87rem' }}>
															{value?.pending}
														</Box>
													</Box>
													<Box
														sx={{
															display: 'flex',
															alignItems: 'center',
															columnGap: '1rem',
															justifyContent: 'space-between',
														}}>
														<Box
															sx={{
																fontSize: '0.87rem',
																width: 'fit-content',
																whiteSpace: 'nowrap',
															}}>
															Rejected
														</Box>
														<Box sx={{ fontSize: '0.87rem' }}>
														{value?.rejected ? value?.rejected : 0}
														</Box>
													</Box>
												</Box>
												<Box
													sx={{
														height: '1.2rem',
														width: '100%',
													}}></Box>
												<Box
													sx={{
														display: 'flex',
														gap: 1,
														width: '100%',
														//  marginTop: '2rem',
													}}>
													<Box
														sx={{
															display: 'flex',
															flexDirection: 'column',
															gap: 1,
															width: '100%',
														}}>
														<ClaimBusinessandPartProgress
															progressData={{
																rejected: value.rejected ? value.rejected : 0,
																pending: value.pending,
																accepted: value.fulfilled,
															}}
														/>
													</Box>
												</Box>
											</Box>
										</Paper>
									)
									: ""}


									
								</Box>
								
								</>

								
								
							))
						) : (
							<>
								<Grid item>
									<Paper
										sx={{
											height: 'fit-content',
											width: '100%',
											borderRadius: 2,
											boxShadow: 10,
											padding: '1rem',
										}}>
										<Skeleton
											variant='rectangular'
											animation='wave'
											sx={{ width: '15vw', height: 'auto' }}
											height={160} // Specify the height if needed
										/>
									</Paper>
								</Grid>
								<Grid item>
									<Paper
										sx={{
											height: 'fit-content',
											width: '100%',
											borderRadius: 2,
											boxShadow: 10,
											padding: '1rem',
										}}>
										<Skeleton
											variant='rectangular'
											animation='wave'
											sx={{ width: '15vw', height: 'auto' }}
											height={160} // Specify the height if needed
										/>
									</Paper>
								</Grid>
								<Grid item>
									<Paper
										sx={{
											height: 'fit-content',
											width: '100%',
											borderRadius: 2,
											boxShadow: 10,
											padding: '1rem',
										}}>
										<Skeleton
											variant='rectangular'
											animation='wave'
											sx={{ width: '15vw', height: 'auto' }}
											height={160} // Specify the height if needed
										/>
									</Paper>
								</Grid>
								<Grid item>
									<Paper
										sx={{
											height: 'fit-content',
											width: '100%',
											borderRadius: 2,
											boxShadow: 10,
											padding: '1rem',
										}}>
										<Skeleton
											variant='rectangular'
											animation='wave'
											sx={{ width: '15vw', height: 'auto' }}
											height={160} // Specify the height if needed
										/>
									</Paper>
								</Grid>
								<Grid item>
									<Paper
										sx={{
											height: 'fit-content',
											width: '100%',
											borderRadius: 2,
											boxShadow: 10,
											padding: '1rem',
										}}>
										<Skeleton
											variant='rectangular'
											animation='wave'
											sx={{ width: '15vw', height: 'auto' }}
											height={160} // Specify the height if needed
										/>
									</Paper>
								</Grid>
							</>
						)}
					</Grid>
				</Grid>
			</Box>
			<Box sx={{ padding: '2rem 2rem 0rem' }}>
				<Box
					sx={{
						height: { lg: '50vh', xl: '62vh' },
						width: '100%',
						columnGap: '2rem',
					}}>
					<Box
						sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
						<Paper
							sx={{
								width: '100%',
								height: { lg: '50vh', xl: '62vh' },
								backgroundColor: 'background.default',
								borderRadius: '1rem',
								boxShadow: 10,
								overflow: 'hidden',
							}}>
							<Box
								sx={{
									height: '100%',
									width: '100%',
								}}>
								<Box>{getAllTable()}</Box>
							</Box>
						</Paper>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
