import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
    Typography,
  } from "@mui/material";
  import { Formik, Field, Form } from "formik";
  import CancelIcon from "@mui/icons-material/Cancel";
  import React from "react";
  
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50rem",
    height: "fit-content",
    bgcolor: "background.paper",
    borderRadius: "10px",
    p: 1,
  };
  
  function ManualChargesModal({ isOpen, onCloseModal, charge, onSave }: any) {
    return (
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Edit Charge
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
  
          <Formik
            initialValues={charge}
            onSubmit={(values) => {
              onSave(values); // Save updated charge data
              onCloseModal(); // Close modal after saving
            }}
          >
            {({ touched, errors, dirty }) => (
              <Form>
                <DialogContent>
                  <Field name="chargeHolder">
                    {({ field }: any) => (
                      <TextField
                        {...field}
                        label="Charge Holder"
                        variant="outlined" // Correct variant
                        fullWidth
                        margin="normal"
                        error={!!(touched.chargeHolder && errors.chargeHolder)}
                        helperText={touched.chargeHolder && errors.chargeHolder}
                      />
                    )}
                  </Field>
                  
                  <Field name="chargeStatus">
                  {({ field, form }: any) => (
                    <FormControl fullWidth variant="outlined" margin="normal">
                      <InputLabel id="chargeStatus-label">Current Signatory</InputLabel>
                      <Select
                        labelId="chargeStatus-label"
                        label="Charge Status"
                        value={field.value ? String(field.value) : ''} // Ensure value is a string for Select
                        onChange={(event) => {
                         // const value = event.target.value === "true"; // Convert string to boolean
                          form.setFieldValue(field.name, event.target.value); // Update Formik state
                        }}
                      >
                        <MenuItem value="Open">Open</MenuItem>
                        <MenuItem value="Closed">Closed</MenuItem>
                      </Select>
                      {/* {touched.isCurrentSignatory && errors.isCurrentSignatory && (
                        <Typography color="error">{errors.isCurrentSignatory}</Typography>
                      )} */}
                    </FormControl>
                  )}
                </Field>
                  <Field name="amount">
                    {({ field }: any) => (
                      <TextField
                        {...field}
                        label="Amount"
                        variant="outlined" // Correct variant
                        fullWidth
                        margin="normal"
                        error={!!(touched.amount && errors.amount)}
                        helperText={touched.amount && errors.amount}
                      />
                    )}
                  </Field>
                </DialogContent>
                <DialogActions>
                  <Button onClick={onCloseModal}>Cancel</Button>
                  <Button type="submit" variant="contained" color="primary" disabled={!dirty}>
                    Save Changes
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    );
  }
  
  export default ManualChargesModal;