import React from 'react'
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';

import { Divider, FormControl, TextField, Tooltip } from '@mui/material';
import { DataGrid, GridColDef, GridCsvExportMenuItem, GridCsvExportOptions, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExportContainer, GridValueGetterParams } from "@mui/x-data-grid";
import useWindowDimensions from '../../ui-reusable-component/useWindowDimensions';
import { useFormik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import * as yup from "yup";
import { API } from '../../api-services';
interface InfoModalProps {


  onCloseModal: any;
  isOpen: any;
  Item: any;
setCounter:any;
swalalterHandler:any;

}

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "45vw",
    height: "fit-content",
    bgcolor: "background.paper",
    borderRadius: "10px",
    p: 0,
  };
const validationSchema = yup.object({
    
    shots: yup.string().required("Please enter Life"),
    price: yup.string().required("Please enter Tool cost"),
    details: yup.string().required("Please enter reference"),
   
   
  });

const ToolEditModal = (props: InfoModalProps) => {

  const { height, width } = useWindowDimensions();
  const [isLoadingsave, setIsLoadingSave] = React.useState<any>(false);
  const { onCloseModal,
    isOpen,
    Item ,setCounter,swalalterHandler} = props

    React.useEffect(() => {
        if(Item)
       {
          formik.setFieldValue("details", Item?.reference);
          formik.setFieldValue("price", Item?.toolCost);
          formik.setFieldValue("shots", Item?.life);
          
        }
      }, [Item]);

      console.log(Item,"vfvjfvjnfvfvnfn")
    
    
    
      const formik = useFormik({
        initialValues: {
            details: "",
            price: "",
            shots: "",
         
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
          //   setIsloading(true);
          let obj = {};
          setIsLoadingSave(true)
        //   if (values.revision === "true")
          
          {
            obj = {
              details: values.details,
              price: values.price,
              shots: values.shots,
              
            };

            API.put(`db/tool_cost/${Item?.Ids}/`, obj, {}, 0)
            .then((res: any) => {
                setIsLoadingSave(false)
                swalalterHandler()
                onCloseModal()
              setCounter((prev: any) => prev + 1)
            })
            .catch((err: any) => {
                setIsLoadingSave(false)
            });
        }
          
        },
    
      });


  return (
    <div>


      <Modal
        open={isOpen}
        onClose={onCloseModal}
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            > 
              Edit Tool Revision
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ padding: "0 2rem 1rem", textAlign: "center" }}>
            <form onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth>
                  <TextField
                    variant="standard"
                    label={
                      <span>
                        Life <sup style={{ color: "red" }}>*</sup>
                      </span>
                    }
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="shots"
                    name="shots"
                    onChange={formik.handleChange}
                    value={formik.values.shots}
                    multiline
                    error={
                      formik.touched.shots &&
                      Boolean(formik.errors.shots)
                    }
                    helperText={
                      formik.touched.shots && formik.errors.shots
                    }
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    variant="standard"
                    // autoFocus={autoFocusStatus}
                    label={
                      <span>
                        Tool Cost (INR) <sup style={{ color: "red" }}>*</sup>
                      </span>
                    }
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="price"
                    name="price"
                    error={formik.touched.price && Boolean(formik.errors.price)}
                    //@ts-ignore
                    helperText={formik.touched.price && formik.errors.price}
                    onChange={formik.handleChange}
                    value={formik.values.price}
                    multiline
                  />
                  {/* <div className={styles.errormsg}>{formik.touched.revision && Boolean(formik.errors.revision) && <p>{formik.errors.revision}</p>}</div> */}
                </FormControl>
              </Box>
            
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth>
                  <TextField
                    variant="standard"
                    label={
                      <span>
                        Reference 
                      </span>
                    }
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="details"
                    name="details"
                    onChange={formik.handleChange}
                    value={formik.values.details}
                    multiline
                    error={
                      formik.touched.details && Boolean(formik.errors.details)
                    }
                    helperText={formik.touched.details && formik.errors.details}
                  />
                </FormControl>
              </Box>
            
              <Box sx={{ width: "100%", textAlign: "right" }}>
                <LoadingButton
                  size="small"
                  loading={isLoadingsave}
                  variant="contained"
                  type="submit"
                  sx={{
                    marginTop: "1rem",
                    "&:hover": {
                      transform: "Scale(1.05)",
                      transition: "transform 0.5s ease",
                    },
                    ".MuiCircularProgress-root": {
                      color: "primary.main",
                    },
                  }}
                >
                  { "Save"}
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}

export default ToolEditModal;