import React from "react";
import {
  Button,
  Checkbox,
  Skeleton,
  TableCell,
  TableRow,
  IconButton,
  Table,
  Badge,
  TableHead,
  TableBody,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import scenerioDoneIcon from "../../Assets/images/scenarioDone.svg";
import LppIcon from "../../Assets/images/LppIcon.svg";
import scenerioInProgressIcon from "../../Assets/images/scenarioInProgress.svg";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled, alpha } from "@mui/material/styles";
import { API } from "../../api-services";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import styles from "./CostingCreateSystemInsideBodyRow.module.scss";
import Swal from "sweetalert2";
import swal from "sweetalert";
import successIcon from "../../Assets/images/success.png";
import { useHistory } from "react-router-dom";
import { useBaseModal } from "../SearchPage/useBaseModal";
import { DialogComponent } from "../../utlis/DialogComponent";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { useSelector } from "react-redux";
import { userSelectors } from "../../Redux/Reducers/user.reducer";
import CostingSheetviewModal from "./CostingSheetviewModal";
import CopySourceToDestModal from "./CopySourceToDestModal";
import InfoIcon from "@mui/icons-material/Info";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    minWidth: 150,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

interface Params {
  projectId: any;
  projectName: any;
  top_vault: any;
  abbr: any;
  top_vaultName: any;
  initialTopVault: any;
  topVaultIds: any;
  expandTable: any;
  vault_id: any;
}
interface ICostingCreateSystemInsideBodyRowProps {
  costingTableData: any;
  handleOpenHierarchy: any;
  SheetViewHandler: any;
  sessionGetInto: any;
  costingTableDataParent: any;
  setIconText: any;
  setContentText: any;
  openStatusContent: any;
  categoryCopy: any;
  setCategoryCopy: any;
  handleChangePasteCheckbox: any;
  pasteVaultNoList: any;
  copyVaultCategory: any;
  setCopyVaultCategory: any;
  setCopyVaultNo: any;
  showCheckboxCopyClicked: any;
  setShowCheckboxCopyClicked: any;
  hoveredIndex: any;
  setHoveredIndex: any;
  reloadCostingData: any;
  setReloadCostingData: any;
  permissionCosting: any;
  loaderRecursiveId: any;
  handleOpenFlagModel: any;
}

const CostingCreateSystemInsideBodyRow: React.FC<
  ICostingCreateSystemInsideBodyRowProps
> = (props) => {
  const {
    costingTableData,
    handleOpenHierarchy,
    SheetViewHandler,
    sessionGetInto,
    costingTableDataParent,
    setIconText,
    setContentText,
    openStatusContent,
    categoryCopy,
    setCategoryCopy,
    handleChangePasteCheckbox,
    pasteVaultNoList,
    copyVaultCategory,
    setCopyVaultCategory,
    setCopyVaultNo,
    showCheckboxCopyClicked,
    setShowCheckboxCopyClicked,
    hoveredIndex,
    setHoveredIndex,
    reloadCostingData,
    setReloadCostingData,
    permissionCosting,
    loaderRecursiveId,
    handleOpenFlagModel,
  } = props;
  const {
    projectId,
    projectName,
    top_vault,
    abbr,
    top_vaultName,
    initialTopVault,
    topVaultIds,
    expandTable,
    vault_id,
  } = useRouteParams<Params>();

  // ZBC Ownership Information
  const [anchorZBCEl, setAnchorZBCEl] =
    React.useState<HTMLButtonElement | null>(null);
  const ZBChandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorZBCEl(event.currentTarget);
  };

  const ZBChandleClose = () => {
    setAnchorZBCEl(null);
  };

  const openZBC = Boolean(anchorZBCEl);
  const id = openZBC ? "simple-popover" : undefined;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElDownload, setAnchorElDownload] =
    React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorElDownload);
  const openDownload = Boolean(anchorEl);
  const [anchorElCopy, setAnchorElCopy] = React.useState<null | HTMLElement>(
    null
  );
  const handleDownloadClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElDownload(event.currentTarget);
  };
  const handleCloseDownload = () => {
    setAnchorElDownload(null);
  };
  const [sourceId, setSourceID] = React.useState<any>();
  const openCopy = Boolean(anchorElCopy);
  const getBackgroundColorRow = (status: any) => {
    if (status) {
      return "#99dfbb";
    } else {
      return "";
    }
  };
  const openDialogModal = useBaseModal();
  const userProfilePic = useSelector(userSelectors.selectAll);
  const sheetview = useBaseModal();
  const history = useHistory();
  const CopySrcToDest = useBaseModal();

  const handleExcelReport = async (vaultIDGet: any) => {
    openDialogModal.open();
    try {
      const { data: url } = await API.get(
        "/bom/individual_report/",
        {
          vault: vaultIDGet,
          individual_type: "Costing",
          scenario: 0,
        },
        0
      );
      window.open(url, "_blank", "noopener noreferrer");
      handleCloseDownload();
    } catch (e) {}
  };

  const handleExcelDetailReport = async (vaultIDGet: any) => {
    openDialogModal.open();
    try {
      const { data: url } = await API.get(
        "/bom/individual_report/",
        {
          vault: vaultIDGet,
          individual_type: "Costing",
          scenario: 0,
          detail_report: true,
        },
        0
      );
      window.open(url, "_blank", "noopener noreferrer");
      handleCloseDownload();
    } catch (e) {}
  };

  const hadleDetailedReportDownload = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    costingTableData?.is_leaf
      ? handleExcelDetailReport(costingTableData?.id)
      : setAnchorEl(event.currentTarget);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    costingTableData?.is_leaf
      ? handleExcelReport(costingTableData?.id)
      : setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickCopy = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorElCopy(event.currentTarget);
  };

  const handleCloseCopy = () => {
    setAnchorElCopy(null);
  };

  const handleChangeCopyCheckbox: any = (
    vaultIdComing: any,
    vaultCategory: any
  ) => {
    setCopyVaultNo(vaultIdComing);
    setCopyVaultCategory(vaultCategory);
    setShowCheckboxCopyClicked(true);
    handleCloseCopy();
  };

  const handleRedirect = (module: any, id: any) => {
    if (module === "w") {
      API.get(
        "/cost/check_consolidate_downoad/",
        {
          vault: id,
          with_image: true,
          consolidate_type: "Costing",
          scenario: undefined,
        },
        0
      ).then((res: any) => {
        if (res.data.status === "Failed") {
          setIconText("failed");
          setContentText(res.data.message);
          openStatusContent.open();
        } else if (res.data.status === "First Download") {
          setIconText("firstdownload");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
          API.get(
            "customer/consolidated_report/",
            {
              vault: id,
              with_image: true,
              consolidate_type: "Costing",
              scenario: 0,
            },
            0
          ).then((res: any) => {
            handleClose();
            window.open(res?.data, "_self");
          });
        } else if (res.data.status === "In Progress") {
          setIconText("reportProgress");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
        } else if (res.data.status === "Completed") {
          handleClose();
          window.open(res?.data?.url, "_self");
        }
      });
    } else if (module === "w/o") {
      API.get(
        "/cost/check_consolidate_downoad/",
        {
          vault: id,
          with_image: false,
          consolidate_type: "Costing",
          scenario: undefined,
        },
        0
      ).then((res: any) => {
        if (res.data.status === "Failed") {
          setIconText("failed");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
        } else if (res.data.status === "First Download") {
          setIconText("firstdownload");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
          API.get(
            "customer/consolidated_report/",
            {
              vault: id,
              with_image: false,
              scenario: 0,
              consolidate_type: "Costing",
            },
            0
          ).then((res: any) => {
            handleClose();
            window.open(res?.data, "_self");
          });
        } else if (res.data.status === "In Progress") {
          setIconText("reportProgress");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
        } else if (res.data.status === "Completed") {
          handleClose();
          window.open(res?.data?.url, "_self");
        }
      });
    }
  };

  const handleCopyToOther: any = (e: any, id: any) => {
    e.stopPropagation();

    setSourceID(id);
    CopySrcToDest.open();
  };

  const handleDeleteCosting: any = (e: any, id: any) => {
    e.stopPropagation();
    swal({
      title: `Delete Data & Cost model`,
      text: `Are you sure?`,
      icon: "error",
      //@ts-ignore
      buttons: {
        cancel: "Cancel",
        permanent: {
          text: `Delete`,
          value: `deleteCosting`,
        },
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
      className: "delete-swal",
    }).then(async (type: string) => {
      switch (type) {
        case "deleteCosting":
          API.delete("cost/share_calculator/", { vault: id, type: "config" }, 0)
            .then((res: any) => {
              Swal.fire({
                html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
                          <br />
                          <p style="color:#007fff;"> The cost model with data has been deleted.
                          </p>   
                        </div>`,
              });
              setReloadCostingData(!reloadCostingData);
            })
            .catch((err: any) => {
              // console.log(
              //   err,
              //   "Unable to delete cost model & data. Server Error"
              // );
            });
          break;
        default:
          swal(`Data & Cost model saved from deleting`, { icon: "error" });
      }
    });
  };

  const redirectBOMnotValidated = () => {
    Swal.fire({
      icon: "error",
      html: `<div>
      <br />
      <p style="color:"red">Please validate BOM first to redirect to the costing page.</p>   
       </div>`,
    });
  };

  const redirectToCostingPage = () => {
    sessionStorage.setItem(
      "CostingCreateCalc",
      JSON.stringify(costingTableData?.ancestors)
    );
    window.open(
      `/#/createcostingpart/${projectId}/${projectName}/${top_vaultName}/${projectId}/${
        costingTableData?.id
      }/${
        costingTableData?.abbreviation == null
          ? "none"
          : costingTableData?.abbreviation
      }/0/0/0/0/0/0/0/0/0`
    );
  };

  const redirectToCostingMainpage = (e: any, item: any) => {
    let LastIndex = item?.ancestors?.length - 1;
    let mainIds = item?.ancestors[LastIndex];

    sessionStorage.setItem(
      "CostingCreateCalc",
      JSON.stringify(item?.ancestors)
    );
    window.open(
      `/#/createcostingpart/${projectId}/${projectName}/${top_vaultName}/${top_vault}/${mainIds}/${
        item?.abbreviation == null ? "none" : item?.abbreviation
      }/0/0/0/0/0/0/0/0/0`
    );
  };

  const handleRedirectToLpp = (
    abbreviationComing: any,
    partid: any,
    depth: any
  ) => {
    if (abbreviationComing == null) {
      window.open(
        `/#/costingqualitycheck/${projectId}/${projectName}/${initialTopVault}/${topVaultIds}/${expandTable}/Part/${partid}/${depth}/${vault_id}`
      );
    } else {
      window.open(
        `/#/costingqualitycheck/${projectId}/${projectName}/${initialTopVault}/${topVaultIds}/${expandTable}/${abbreviationComing}/0/0/${vault_id}`
      );
    }
  };
  const handleSheetView = (e: any, item: any) => {
    SheetViewHandler(item);
  };

  return (
    <>
      <DialogComponent
        isOpen={openDialogModal.isOpen}
        onClose={openDialogModal.close}
        content={
          "We've got this report downloading faster than a cheetah running. You will also receive a notification.But in the meantime, keep using xcPEP like the productivity champion !"
        }
        type={"firstdownload"}
      />
      <TableRow
        sx={
          hoveredIndex == costingTableData?.id
            ? { backgroundColor: "#f7e1f4", zIndex: 1, position: "relative" }
            : {
                backgroundColor: getBackgroundColorRow(
                  costingTableData?.publish_status
                ),
              }
        }
        onMouseEnter={() => setHoveredIndex(costingTableData?.id)}
        onMouseLeave={() => setHoveredIndex(null)}
      >
        <TableCell
          className={styles?.CheckboxTable}
          sx={{ padding: "0.5rem", borderBottomColor: "primary.light" }}
        >
          <span
            style={
              sessionGetInto?.includes(costingTableDataParent?.id)
                ? {
                    marginLeft: `${costingTableData?.ancestors?.length - 2}rem`,
                    display: "flex",
                    alignItems: "center",
                  }
                : { display: "flex", alignItems: "center" }
            }
          >
            {!costingTableData?.is_leaf &&
              (!sessionGetInto?.includes(costingTableData?.id) ? (
                <AddCircleOutlineIcon
                  sx={{ marginRight: "0.5rem", cursor: "pointer" }}
                  onClick={() => handleOpenHierarchy(costingTableData?.id)}
                />
              ) : (
                <RemoveCircleOutlineIcon
                  sx={{ marginRight: "0.5rem", cursor: "pointer" }}
                  onClick={() => handleOpenHierarchy(costingTableData?.id)}
                />
              ))}
            {costingTableData?.abbreviation != abbr &&
              copyVaultCategory == costingTableData?.category &&
              costingTableData?.cost_status != 3 &&
              showCheckboxCopyClicked && (
                <Checkbox
                  checked={pasteVaultNoList?.includes(costingTableData?.id)}
                  onChange={() =>
                    handleChangePasteCheckbox(costingTableData?.id)
                  }
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{ p: 0, mr: 1 }}
                  disabled={costingTableData?.bom_status < 3}
                />
              )}
            <span
              onClick={() => {
                costingTableData?.is_copy == false &&
                  (costingTableData?.bom_status === 3
                    ? redirectToCostingPage()
                    : redirectBOMnotValidated());
              }}
              onContextMenu={handleClickCopy}
              style={
                !costingTableData?.is_leaf
                  ? {
                      color: `${costingTableData?.color}`,
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      columnGap: "0.5rem",
                    }
                  : costingTableData?.abbreviation != abbr &&
                    copyVaultCategory == costingTableData?.category &&
                    costingTableData?.cost_status != 3 &&
                    showCheckboxCopyClicked
                  ? {
                      color: `${costingTableData?.color}`,
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      columnGap: "0.5rem",
                    }
                  : {
                      marginLeft: "2.3rem",
                      color: `${costingTableData?.color}`,
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      columnGap: "0.5rem",
                    }
              }
            >
              {costingTableData?.part_no}

              {costingTableData?.is_copy && (
                <CopyrightIcon style={{ color: "red" }} />
              )}
            </span>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              elevation={3}
              anchorEl={anchorElCopy}
              open={openCopy}
              onClose={handleCloseCopy}
            >
              {costingTableData?.cost_status == 3 &&
                permissionCosting &&
                permissionCosting?.action?.includes("U") && (
                  <MenuItem
                    sx={{
                      fontSize: "1rem",
                      color: "primary.main",
                      padding: "0.2rem 0.5rem",
                    }}
                    onClick={() =>
                      handleChangeCopyCheckbox(
                        costingTableData?.id,
                        costingTableData?.category
                      )
                    }
                  >
                    Copy
                  </MenuItem>
                )}
              {costingTableData?.cost_status == 3 && (
                // permissionCosting &&
                // permissionCosting?.action?.includes('U') && (

                // )
                <MenuItem
                  sx={{
                    fontSize: "1rem",
                    color: "primary.main",
                    padding: "0.2rem 0.5rem",
                  }}
                  onClick={(e: any) =>
                    handleCopyToOther(e, costingTableData?.id)
                  }
                >
                  Copy to Other Product Category
                </MenuItem>
              )}

              {CopySrcToDest.isOpen && (
                <CopySourceToDestModal
                  isOpen={CopySrcToDest.isOpen}
                  onClose={CopySrcToDest.close}
                  sourceId={sourceId}
                  handleAnchorClose={handleCloseCopy}
                />
              )}
              {costingTableData?.is_copy == false &&
                userProfilePic &&
                userProfilePic[0]?.is_superuser && (
                  <MenuItem
                    sx={{
                      fontSize: "1rem",
                      color: "primary.main",
                      padding: "0.2rem 0.5rem",
                    }}
                    onClick={(e: any) =>
                      handleDeleteCosting(e, costingTableData?.id)
                    }
                  >
                    Delete Costing
                  </MenuItem>
                )}

              {costingTableData?.is_leaf == false && (
                <MenuItem
                  sx={{
                    fontSize: "1rem",
                    color: "primary.main",
                    padding: "0.2rem 0.5rem",
                  }}
                  onClick={(e: any) => handleSheetView(e, costingTableData)}
                >
                  Sheet View
                </MenuItem>
              )}

              {permissionCosting &&
                permissionCosting?.action?.includes("C") &&
                costingTableData?.is_copy == true && (
                  <MenuItem
                    sx={{
                      fontSize: "1rem",
                      color: "primary.main",
                      padding: "0.2rem 0.5rem",
                    }}
                    onClick={(e: any) =>
                      redirectToCostingMainpage(e, costingTableData)
                    }
                  >
                    Go to original costing
                  </MenuItem>
                )}
            </StyledMenu>
          </span>
        </TableCell>
        <TableCell
          onClick={() => {
            costingTableData?.is_copy == false &&
              (costingTableData?.bom_status === 3
                ? redirectToCostingPage()
                : redirectBOMnotValidated());
          }}
          sx={{
            padding: "0.5rem",
            borderBottomColor: "primary.light",
            cursor: "pointer",
          }}
        >
          <span style={{ textTransform: "capitalize" }}>
            {costingTableData?.name.toLowerCase()}
          </span>
        </TableCell>
        <TableCell
          sx={{ padding: "0.5rem", borderBottomColor: "primary.light" }}
        >
          {costingTableData?.category}
        </TableCell>
        <TableCell
          sx={{ padding: "0.5rem", borderBottomColor: "primary.light" }}
        >
          {costingTableData?.ownership?.owner}
          {costingTableData?.ownership?.owner &&
          costingTableData?.ownership?.owner == "ASI" ? (
            ""
          ) : (
            <IconButton
              sx={{ marginLeft: "0.7rem", padding: "0" }}
              aria-describedby={id}
              onClick={ZBChandleClick}
            >
              <InfoIcon
                titleAccess="ZBC Information"
                sx={{ color: "primary.main", cursor: "pointer" }}
              />
            </IconButton>
          )}
          <Popover
            id={id}
            open={openZBC}
            anchorEl={anchorZBCEl}
            onClose={ZBChandleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ padding: "0.3rem", fontWeight: "bold" }}
                    colSpan={2}
                  >
                    ZBC INFORMATION
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ padding: "0.3rem", fontWeight: "bold" }}>
                    Ownership
                  </TableCell>
                  <TableCell sx={{ padding: "0.3rem" }}>
                    : {costingTableData?.ownership?.owner}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: "0.3rem", fontWeight: "bold" }}>
                    ZBC Type
                  </TableCell>
                  <TableCell sx={{ padding: "0.3rem" }}>
                    : {costingTableData?.ownership?.zbc_type}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: "0.3rem", fontWeight: "bold" }}>
                    Requester Name
                  </TableCell>
                  <TableCell sx={{ padding: "0.3rem" }}>
                    : {costingTableData?.ownership?.username}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: "0.3rem", fontWeight: "bold" }}>
                    Part Name
                  </TableCell>
                  <TableCell sx={{ padding: "0.3rem" }}>
                    : {costingTableData?.ownership?.part_name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: "0.3rem", fontWeight: "bold" }}>
                    Mfg. Location
                  </TableCell>
                  <TableCell sx={{ padding: "0.3rem" }}>
                    : {costingTableData?.ownership?.mfg_location}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: "0.3rem", fontWeight: "bold" }}>
                    Annual Volume
                  </TableCell>
                  <TableCell sx={{ padding: "0.3rem" }}>
                    : {costingTableData?.ownership?.annual_volume}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: "0.3rem", fontWeight: "bold" }}>
                    Revised On
                  </TableCell>
                  <TableCell sx={{ padding: "0.3rem" }}>
                    : {costingTableData?.ownership?.revised_on}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: "0.3rem", fontWeight: "bold" }}>
                    Amount Paid
                  </TableCell>
                  <TableCell sx={{ padding: "0.3rem" }}>
                    : {costingTableData?.ownership?.paid_currency}{" "}
                    {costingTableData?.ownership?.paid_amount} (Excluding Taxes)
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Popover>
        </TableCell>
        <TableCell
          sx={{
            padding: "0.5rem",
            borderBottomColor: "primary.light",
            textAlign: "right",
          }}
        >
          {costingTableData?.weight == 0
            ? costingTableData?.weight
            : costingTableData?.weight?.toFixed(2)}
        </TableCell>
        <TableCell
          sx={{
            padding: "0.5rem",
            borderBottomColor: "primary.light",
            textAlign: "right",
          }}
        >
          {costingTableData?.vault_details?.Qty}
        </TableCell>
        <TableCell
          sx={{
            padding: "0.5rem",
            borderBottomColor: "primary.light",
            textAlign: "right",
          }}
        >
          {costingTableData?.vault_details?.cost_per_kg}
        </TableCell>
        <TableCell
          sx={{
            padding: "0.5rem",
            borderBottomColor: "primary.light",
            textAlign: "right",
          }}
        >
          {costingTableData?.vault_details?.per_unit_cost == 0
            ? costingTableData?.vault_details?.per_unit_cost
            : costingTableData?.vault_details?.per_unit_cost?.toFixed(3)}
        </TableCell>
        <TableCell
          sx={{
            padding: "0.5rem",
            borderBottomColor: "primary.light",
            textAlign: "right",
          }}
        >
          {costingTableData?.vault_details?.material_cost == 0
            ? costingTableData?.vault_details?.material_cost
            : costingTableData?.vault_details?.material_cost?.toFixed(3)}
        </TableCell>
        <TableCell
          sx={{
            padding: "0.5rem",
            borderBottomColor: "primary.light",
            textAlign: "right",
          }}
        >
          {costingTableData?.vault_details?.process_cost == 0
            ? costingTableData?.vault_details?.process_cost
            : costingTableData?.vault_details?.process_cost?.toFixed(3)}
        </TableCell>
        <TableCell
          sx={{
            padding: "0.5rem",
            borderBottomColor: "primary.light",
            textAlign: "right",
          }}
        >
          {costingTableData?.vault_details?.overhead_cost == 0
            ? costingTableData?.vault_details?.overhead_cost
            : costingTableData?.vault_details?.overhead_cost?.toFixed(3)}
        </TableCell>
        <TableCell
          sx={{
            padding: "0.5rem",
            borderBottomColor: "primary.light",
            textAlign: "right",
          }}
        >
          {costingTableData?.vault_details?.child_part_cost == 0
            ? costingTableData?.vault_details?.child_part_cost
            : costingTableData?.vault_details?.child_part_cost?.toFixed(3)}
        </TableCell>
        <TableCell
          sx={{
            padding: "0.5rem",
            borderBottomColor: "primary.light",
            textAlign: "right",
          }}
        >
          {costingTableData?.vault_details?.total_cost == 0
            ? costingTableData?.vault_details?.total_cost
            : costingTableData?.vault_details?.total_cost?.toFixed(3)}
        </TableCell>
        <TableCell
          sx={{
            padding: "0.5rem",
            borderBottomColor: "primary.light",
            // textAlign: 'right',
          }}
        >
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              columnGap: "1rem",
            }}
          >
            {costingTableData?.scenario_show &&
              (costingTableData?.scenario_exists ? (
                <Badge
                  sx={{
                    ".MuiBadge-badge": {
                      height: "1.2rem",
                      padding: "1px 3px",
                    },
                  }}
                  badgeContent={costingTableData?.scenario_count}
                  color="error"
                >
                  <img
                    src={scenerioDoneIcon}
                    height="18px"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      sessionStorage.removeItem("nodeCostingScenario");
                      sessionStorage.setItem(
                        "nodeCostingScenario",
                        JSON.stringify(costingTableData?.ancestors)
                      );
                      sessionStorage.setItem("addScenarioStatus", "3");
                      window.open(
                        `/#/costingscenario/${projectId}/${projectName}/${top_vault}/${
                          costingTableData?.id
                        }/1/${
                          costingTableData?.abbreviation
                            ? costingTableData?.abbreviation
                            : "none"
                        }/0/0/1`
                      );
                    }}
                  />
                </Badge>
              ) : (
                <img
                  src={scenerioInProgressIcon}
                  height="18px"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    sessionStorage.removeItem("nodeCostingScenario");
                    sessionStorage.setItem(
                      "nodeCostingScenario",
                      JSON.stringify(costingTableData?.ancestors)
                    );
                    sessionStorage.setItem("addScenarioStatus", "3");
                    window.open(
                      `/#/costingscenario/${projectId}/${projectName}/${top_vault}/${
                        costingTableData?.id
                      }/1/${
                        costingTableData?.abbreviation
                          ? costingTableData?.abbreviation
                          : "none"
                      }/0/0/1`
                    );
                  }}
                />
              ))}

            {costingTableData?.cost_status == 3 &&
            !costingTableData?.is_leaf ? (
              <Button
                id="demo-customized-button"
                aria-controls={
                  openDownload ? "demo-customized-menu" : undefined
                }
                aria-haspopup="true"
                aria-expanded={openDownload ? "true" : undefined}
                disableElevation
                onClick={(e: any) => handleClick(e)}
                sx={{
                  padding: "0rem",
                  minWidth: "1rem",
                  margin: "0 0.2rem",
                  color: "black",
                }}
              >
                <CloudDownloadIcon sx={{ cursor: "pointer" }} />
              </Button>
            ) : (
              <Button
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                disableElevation
                onClick={handleDownloadClick}
                sx={{
                  padding: "0rem",
                  minWidth: "1rem",
                  margin: "0 0.2rem",
                  color: "black",
                }}
              >
                <CloudDownloadIcon sx={{ cursor: "pointer" }} />
              </Button>
            )}
            <InfoIcon
              titleAccess="Flag Information"
              sx={{ cursor: "pointer" }}
              onClick={handleOpenFlagModel}
            />
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={openDownload}
              onClose={handleClose}
            >
              <MenuItem
                sx={{
                  fontSize: "1rem",
                  color: "primary.main",
                  padding: "0.2rem 0.5rem",
                }}
                onClick={() => handleRedirect("w", costingTableData?.id)}
              >
                Consolidated with images
              </MenuItem>
              <MenuItem
                sx={{
                  fontSize: "1rem",
                  color: "primary.main",
                  padding: "0.2rem 0.5rem",
                }}
                onClick={() => handleRedirect("w/o", costingTableData?.id)}
              >
                Consolidated w/o images
              </MenuItem>
            </StyledMenu>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorElDownload}
              open={open}
              onClose={handleCloseDownload}
            >
              <MenuItem
                sx={{
                  fontSize: "1rem",
                  color: "primary.main",
                  padding: "0.2rem 0.5rem",
                }}
                onClick={hadleDetailedReportDownload}
              >
                Detailed Costing Report
              </MenuItem>
              <MenuItem
                sx={{
                  fontSize: "1rem",
                  color: "primary.main",
                  padding: "0.2rem 0.5rem",
                }}
                onClick={handleClick}
              >
                Summary Costing Report
              </MenuItem>
            </StyledMenu>
          </span>
        </TableCell>
      </TableRow>

      {loaderRecursiveId == costingTableData?.id && (
        <>
          <TableRow>
            <TableCell
              className={styles?.CheckboxTable}
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              className={styles?.CheckboxTable}
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell
              className={styles?.CheckboxTable}
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
          </TableRow>
        </>
      )}
      {costingTableData?.data?.map((item: any, index: number) => {
        return (
          sessionGetInto?.includes(costingTableData?.id) && (
            <CostingCreateSystemInsideBodyRow
              costingTableData={item}
              handleOpenHierarchy={handleOpenHierarchy}
              SheetViewHandler={SheetViewHandler}
              sessionGetInto={sessionGetInto}
              costingTableDataParent={costingTableData}
              setIconText={setIconText}
              setContentText={setContentText}
              openStatusContent={openStatusContent}
              categoryCopy={categoryCopy}
              setCategoryCopy={setCategoryCopy}
              handleChangePasteCheckbox={handleChangePasteCheckbox}
              pasteVaultNoList={pasteVaultNoList}
              copyVaultCategory={copyVaultCategory}
              setCopyVaultCategory={setCopyVaultCategory}
              setCopyVaultNo={setCopyVaultNo}
              showCheckboxCopyClicked={showCheckboxCopyClicked}
              setShowCheckboxCopyClicked={setShowCheckboxCopyClicked}
              hoveredIndex={hoveredIndex}
              setHoveredIndex={setHoveredIndex}
              reloadCostingData={reloadCostingData}
              setReloadCostingData={setReloadCostingData}
              permissionCosting={permissionCosting}
              loaderRecursiveId={loaderRecursiveId}
              handleOpenFlagModel={handleOpenFlagModel}
            />
          )
        );
      })}
    </>
  );
};

export default CostingCreateSystemInsideBodyRow;
