import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Typography } from "@mui/material";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { useHistory } from "react-router-dom";
import styles from "./CostingScenarioLanding.module.scss";
import { CostingScenarioPage } from "./CostingScenarioPage";
import { AddScenarioModal } from "./AddScenarioModal";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import { useBaseModal } from "../SearchPage/useBaseModal";
import AddAssumptionModal from "./AddAssumptionModal";
import { API } from "../../api-services";
import { CostingScenarioCreateDrawer } from "../CostingCreateModule/CostingScenario/CostingScenarioCreateDrawer";
import { AxiosError, AxiosResponse } from "axios";
import { CostingCreateScenarioMainDrawer } from "../CostingCreateModule/CostingScenario/CostingCreateScenarioMainDrawer";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { CurrencyChange } from "../../ViewModule/CostingView/CurrencyChange";
import { ADMIN } from "../../Redux/Services/admin.service";
import InfoIcon from "@mui/icons-material/Info";
import Popover from "@mui/material/Popover";
import CloseIcon from "@mui/icons-material/Close";
import RevisionListModal from "./RevisionListModal";
import { AddScenarioModalAdmin } from "./AddScenarioModalAdmin";

export interface ICostingScenarioLandingProps {}

interface Params {
  projectId: any;
  projectName: any;
  topVaultIds: any;
  expandTable: any;
  initialTopVault: any;
  scenarioType: any;
}

type Anchor = "top" | "left" | "bottom" | "right";

export function CostingScenarioLanding(props: ICostingScenarioLandingProps) {
  const {
    projectId,
    projectName,
    topVault,
    vault,
    scenarioId,
    treeabbr,
    scenarioType,
    requestId,
  } = useRouteParams<any>();
  const [count, setCount] = React.useState<any>(0);
  const [status, setStatus] = React.useState<any>(null);
  const [productList, setProductList] = React.useState<any>([]);
  const [selectedProduct, setSelectedProduct] = React.useState<any>([]);
  const [counts, setCounts] = React.useState<any>(0);
  const [assumptionsCardCount, setAssumptionCardCount] = React.useState<any>(0);
  const [assCardCounts, setAssCardCounts] = React.useState<any>(0);
  const [configPermission, setConfigPermission] = React.useState<any>("");
  const [permission, setPermission] = React.useState<any>("");
  const [costStatus, setCostStatus] = React.useState<any>(null);
  const [revisionStatus, setRevisionStatus] = React.useState<any>(null);
  const [title, setTitle] = React.useState<any>(null);
  const [warningMsg, setwarningMsg] = useState<any>();
  const [scenarioDashboardOpen, setScenarioDashboardOpen] =
    React.useState<any>(false);
  const [reqSceanrio, setReqScenarioId] = React.useState<any>(null);

  const [newScenarioRequestCount, setNewScenarioRequestCount] = React.useState(0);
  // New scenario request Info
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const revisionListModal = useBaseModal();

  const currencyModal = useBaseModal();
  const history = useHistory();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const getDisableStatus = () => {
    if (revisionStatus) {
      return false;
    } else if (!revisionStatus) {
      return true;
    } else {
      return false;
    }
  };

  const getPermission = () => {
    API.get(
      "/cost/scenario_permission/",
      {
        top_vault: topVault,
      },
      0
    )
      .then((res: any) => {
        setPermission(res.data);
      })
      .catch((err: any) => {});
  };

  const checkPermission = () => {
    API.get(
      "/auth/user_permission_module/",
      {
        costing_assumption: true,
        action: true,
        top_vault: topVault,
      },
      0
    )
      .then((res: any) => {
        const tab = res.data.cost_main ? 1 : 2;
        window.open(
          `/#/costingconfiguration/${projectId}/${projectName}/assumptioncard/${topVault}/${tab}/${res.data.cost_main}/${res.data.cost_scenario}`
        );
      })
      .catch((err: any) => {});
  };

  React.useEffect(() => {
    API.get(
      "/auth/user_permission_module/",
      {
        action: true,
        configuration: true,
        project: projectId,
        module: "Costing Configuration",
      },
      0
    )
      .then((res: any) => {
        setConfigPermission(res?.data?.action);
        // sessionStorage.setItem("ConfigPermission", res.data.action);
      })
      .catch((err: any) => {});
  }, []);

  const getObj = () => {
    if (vault == 0) {
      return { vehicle_category: projectId };
    } else {
      return { vault: vault };
    }
  };

  const getTableData = () => {
    API.get("/cost/scenario_table/", { ...getObj(), main_cost_info: true })
      .then((res: any) => {
        setCostStatus(res?.data[0]?.cost_status);
        setRevisionStatus(res?.data[0]?.has_revision);
      })
      .catch((err: any) => {
        console.log("Sever Error");
      });
  };

  // xcpep/vault_info/id

  const getTitleFromVault = () => {
    API.get("bom/vault_info/", { vault: vault }, 0)
      .then((res: any) => {
        setTitle(`${res?.data[0]?.part_no} (${res?.data[0]?.name})`);
        setNewScenarioRequestCount(res?.data[0]?.new_scenario_count);
      })
      .catch((err: any) => {
        console.log("Server Error");
      });
  };

  const getTitleFromTopVault = () => {
    API.get("supplier/vehicle_category/", { vehicle_category_id: projectId }, 0)
      .then((res: any) => {
        setTitle(`${res?.data[0]?.name}`);
      })
      .catch((err: any) => {
        console.log("Server Error");
      });
  };

  React.useEffect(() => {
    getPermission();
    getTableData();
    if (vault == 0) {
      // getTitleFromTopVault();
    } else {
      getTitleFromVault();
    }
  }, [topVault, vault,count]);

  const getLocationsName = () => {
    API.get(
      "cost/assumption_card/",
      {
        revision_type: 2,
        project: projectId,
      },
      0
    )
      .then((res: any) => {
        setCounts(res.data?.length);
      })
      .catch((err: any) => {});
  };
  const totalAssumptionCardCounts = () => {
    API.get(
      "cost/assumption_card/",
      {
        top_vault: topVault,
        assumption_type: 2,
      },
      0
    )
      .then((res: any) => {
        setAssumptionCardCount(res?.data?.length);
      })
      .catch((err: any) => {});
    // https://api-stage2.xcpep.com/cost/assumption_card/?top_vault=49&assumption_type=2
  };
  React.useEffect(() => {
    getLocationsName();
    totalAssumptionCardCounts();
  }, [topVault, vault, projectId]);
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      API.get(`/supplier/product_category_view/`, {}, 0)
        .then((res: AxiosResponse) => {
          setProductList(res?.data);
          setSelectedProduct([topVault]);
        })
        .catch((err: AxiosError) => {
          console.log(err, "error");
        });
      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <CostingCreateScenarioMainDrawer
      anchorComing={anchor}
      toggleDrawer={toggleDrawer}
      productList={productList}
      setProductList={setProductList}
      selectedProduct={selectedProduct}
      setSelectedProduct={setSelectedProduct}
    />
  );

  const backButtonHandler: any = () => {
    history.goBack();
  };

  useEffect(() => {
    API.get(
      "/cost/costing_conversion/",

      { get_conversion_factor: true, module: "costing_create" },

      0
    ).then((res: any) => {
      sessionStorage.setItem(
        "costingtable",

        res.data[0]?.current_currency
      );
      // currency
      sessionStorage.setItem(
        "currency",

        res.data[0]?.current_currency
      );

      sessionStorage.setItem(
        "actionkey_costingtable",

        res.data[0]?.is_manual ? "mannual" : "auto"
      );

      if (res.data[0]?.is_manual) {
        sessionStorage.setItem(
          "currencyrate_costingtable_mannual",

          res.data[0]?.conversion_factor
        );
      } else {
        sessionStorage.setItem(
          "currencyrate_costingtable",

          (1 / res.data[0]?.conversion_factor).toString()
        );
      }
    });
  }, [count]);

  const AddScenarioModel = useBaseParamsModal();
  const addAssumptionModal = useBaseModal();
  const AddScenarioHandler = () => {
    AddScenarioModel.open();
  };

  useEffect(() => {
    API.get(`/cost/scenario/`, { scenario_delta: true, vault: vault }, 0)
      .then((res: any) => {
        // setWarningLoader(false);
        setwarningMsg(res?.data);
      })
      .catch((err: any) => {
        // setWarningLoader(false);
      });
  }, [vault]);

  const DeltaHandler = (id: any) => {
    API.get("/cost/cost_calculator_update/", { vault: warningMsg?.vault }, 0)
      .then((res: any) => {
        ADMIN.toast.info("Delta sync is in progress.");
        setCount((prev: any) => prev + 1);
        API.get(`/cost/scenario/`, { scenario_delta: true, vault: vault }, 0)
          .then((res: any) => {
            // setWarningLoader(false);
            setwarningMsg(res?.data);
          })
          .catch((err: any) => {
            // setWarningLoader(false);
          });
      })
      .catch((err: any) => {});
  };

  const handleAssumptionRedirection = () => {
    window.open(
      `/#/costingconfiguration/${projectId}/${projectName}/assumptioncard/${topVault}/1/true/true`
    );
  };

  //New Scenario Request Info
  React.useEffect(() => {
    API.get("/cart/get_all_scenario_requests/", {
      request_id: requestId,
    })
      .then((res: any) => {
        setReqScenarioId(res.data);
      })
      .catch((err: any) => {});
  }, [requestId]);
  
  const removeRequestId = () => {
    history.push(
      `/costingscenario/${projectId}/${projectName}/${topVault}/${vault}/${scenarioId}/${treeabbr}/${scenarioType}/0/1`
    );
  };

  return (
    <div>
      {AddScenarioModel.isOpen && (
        <AddScenarioModalAdmin
          isOpen={AddScenarioModel.isOpen}
          onCloseModal={AddScenarioModel.close}
          setCount={setCount}          
        />
      )}
      {addAssumptionModal.isOpen && (
        <AddAssumptionModal
          isOpen={addAssumptionModal.isOpen}
          onCloseModal={addAssumptionModal.close}
          //   setCounter={setCount}
        />
      )}
      {currencyModal.isOpen && (
        <CurrencyChange
          isOpen={currencyModal.isOpen}
          onCloseModal={currencyModal.close}
          setCount={setCount}
        />
      )}
      {
        revisionListModal.isOpen && (
          <RevisionListModal
            isOpen={revisionListModal.isOpen}
            onCloseModal={revisionListModal.close}
          />
        )
      }
      <Box>
        <Box className={styles.titleSection}>
          <Box className={styles.titleSectionLeft}>
            <IconButton sx={{ cursor: "pointer" }} onClick={backButtonHandler}>
              <ArrowBackIcon color="primary" titleAccess="Go Back" />
            </IconButton>
            <Typography
              sx={{
                fontSize: "1rem",
                whiteSpace: "nowrap",
                display: "inline-block",
              }}
            >
              Scenario Costing ( {projectName} )
            </Typography>
          </Box>
          <Box className={styles.titleSectionRight}>
            {/* @ts-ignore */}
            {!JSON.parse(sessionStorage.getItem("isCategory"))?.[1] && (
              <Box>{title} </Box>
            )}
            {/* @ts-ignore */}
            {JSON.parse(sessionStorage.getItem("isCategory"))?.[1] && (
              <Box>
                Scenario Dashboard ( {/* @ts-ignore */}
                {JSON.parse(sessionStorage.getItem("isCategory"))?.[0]} )
              </Box>
            )}
            <Box>
              {reqSceanrio && reqSceanrio != null && requestId !== "0" && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    backgroundColor: "#f5f5f5",
                  }}
                >
                  <Button
                    sx={{ minWidth: "2rem", padding: "0.2rem" }}
                    aria-describedby={id}
                    onClick={handleClick}
                  >
                    <InfoIcon />
                  </Button>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <Typography sx={{ p: 2, fontSize: "0.87rem" }}>
                      <Box sx={{ width: "fit-content" }}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: "0.5rem",
                            width: "100%",
                          }}
                        >
                          <span style={{ width: "6rem" }}>Name</span>
                          <span>:</span>
                          <span style={{ textAlign: "left" }}>
                            {reqSceanrio.username}
                          </span>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: "0.5rem",
                            width: "100%",
                          }}
                        >
                          <span style={{ width: "6rem" }}>Description</span>{" "}
                          <span>:</span>
                          <span style={{ textAlign: "left" }}>
                            {reqSceanrio.description}
                          </span>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: "0.5rem",
                            width: "100%",
                          }}
                        >
                          <span style={{ width: "6rem" }}>Volume</span>
                          <span>:</span>
                          <span style={{ textAlign: "left" }}>
                            {reqSceanrio.annual_volume}
                          </span>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: "0.5rem",
                            width: "100%",
                          }}
                        >
                          <span style={{ width: "6rem" }}>Email</span>
                          <span>:</span>
                          <span style={{ textAlign: "left" }}>
                            {reqSceanrio.email}
                          </span>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: "0.5rem",
                            width: "100%",
                          }}
                        >
                          <span style={{ width: "6rem" }}>Location</span>
                          <span>:</span>
                          <span style={{ textAlign: "left" }}>
                            {reqSceanrio.mfg_location}
                          </span>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: "0.5rem",
                            width: "100%",
                          }}
                        >
                          <span style={{ width: "6rem" }}>Revised On</span>
                          <span>:</span>
                          <span style={{ textAlign: "left" }}>
                            {reqSceanrio.revision_month}{" "}
                            {reqSceanrio.revision_year}
                          </span>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: "0.5rem",
                            width: "100%",
                          }}
                        >
                          <span style={{ width: "6rem" }}>Guideline Doc</span>{" "}
                          <span>:</span>
                          <span
                            style={{
                              textAlign: "left",
                              textDecoration: "underline",
							                cursor: "pointer",
                            }}
                            onClick={() =>
                              window.open(reqSceanrio.costing_guidelines)
                            }
                          >
                            Link
                          </span>
                        </Box>
                      </Box>
                    </Typography>
                  </Popover>
                  <Typography>Request ID : {reqSceanrio.request_id}</Typography>
                  <Button
                    sx={{ minWidth: "2rem", padding: "0.2rem" }}
                    onClick={removeRequestId}
                  >
                    <CloseIcon />
                  </Button>
                </Box>
              )}
            </Box>
            {/* @ts-ignore */}
            {!JSON.parse(sessionStorage.getItem("isCategory"))?.[1] && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                }}
              >
                {/*	{treeabbr != 'none' &&
									treeabbr != 'PRODUCT' &&
									scenarioType == '1' &&  (
										<>
											{!scenarioDashboardOpen ? (
												<Button
													variant='contained'
													className={styles.downloadBtn}
													size='small'
													sx={{
														// padding: "0.2rem 0.7rem",
														minWidth: '1.5rem',
														// lineHeight: 1,
														'&:hover': {
															transform: 'Scale(1.05)',
															transition: 'transform 0.5s ease',
														},
													}}
													onClick={() => setScenarioDashboardOpen(true)}>
													Scenario Dashboard
												</Button>
											) : (
												<Button
													variant='contained'
													className={styles.downloadBtn}
													size='small'
													onClick={() => setScenarioDashboardOpen(false)}
													sx={{
														// padding: "0.2rem 0.7rem",
														minWidth: '1.5rem',
														// lineHeight: 1,
														'&:hover': {
															transform: 'Scale(1.05)',
															transition: 'transform 0.5s ease',
														},
													}}>
													Scenario Main Page
												</Button>
											)}
										</>
									)}/}
								{/* { */}
                {/* // (warningMsg?.is_delta==true &&  warningMsg?.updated==false) && */}
                
                {/* <Button
                  variant="contained"
                  className={styles.downloadBtn}
                  size="small"
                  sx={{
                    // padding: "0.2rem 0.7rem",
                    minWidth: "1.5rem",
                    // lineHeight: 1,
                    "&:hover": {
                      transform: "Scale(1.05)",
                      transition: "transform 0.5s ease",
                    },
                  }}
                  onClick={() => revisionListModal.open()}
                >
                  Go to Revision
                </Button> */}

                {/* <Button
                  variant="contained"
                  className={styles.downloadBtn}
                  size="small"
                  sx={{
                    // padding: "0.2rem 0.7rem",
                    minWidth: "1.5rem",
                    // lineHeight: 1,
                    "&:hover": {
                      transform: "Scale(1.05)",
                      transition: "transform 0.5s ease",
                    },
                  }}
                  onClick={() => DeltaHandler(warningMsg)}
                >
                  Delta Update
                </Button> */}
                {/* } */}

                <Button
                  variant="contained"
                  className={styles.downloadBtn}
                  size="small"
                  endIcon={<ArrowDropDownIcon sx={{ fontSize: "1rem" }} />}
                  style={{
                    fontSize: "12px",
                    minWidth: "3rem",
                    // lineHeight: 1,
                    padding: "0.2rem 0.7rem",
                  }}
                  onClick={() => currencyModal.open()}
                >
                  {sessionStorage.getItem("currency")}
                </Button>

                {permission &&
                permission?.includes("C") &&
                costStatus &&
                revisionStatus === true ? (
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<AddIcon sx={{ marginRight: "-0.5rem" }} />}
                    title="Add Scenario"
                    // disabled={getDisableStatus()}
                    onClick={() => AddScenarioHandler()}
                    sx={{
                      // padding: "0.2rem 0.7rem",
                      minWidth: "1.5rem",
                      // lineHeight: 1,
                      "&:hover": {
                        transform: "Scale(1.05)",
                        transition: "transform 0.5s ease",
                      },
                    }}
                  >
                    Add Scenario
                  </Button>
                ) : permission &&
                  permission?.includes("C") &&
                  costStatus === false &&
                  revisionStatus === true ? (
                  <Typography sx={{ fontSize: "0.9rem", color: "red" }}>
                    <sup style={{ color: "red" }}>*</sup> Validate main costing
                    to add scenario
                  </Typography>
                ) : (
                  <Typography sx={{ fontSize: "0.9rem", color: "red" }}>
                    <sup style={{ color: "red" }}>*</sup> Create Revision to add
                    scenario
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
          <Box className={styles.expandIconBox}>
            <div className={styles.iconSec}>
              {(["left"] as const).map((anchor) => (
                <React.Fragment key={anchor}>
                  <IconButton
                    size="medium"
                    sx={{
                      backgroundColor: "primary.light",
                      color: "primary.main",
                    }}
                    onClick={toggleDrawer(anchor, true)}
                  >
                    <AddIcon sx={{ fontSize: "1.7rem" }} />
                  </IconButton>
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    className={styles.drawerContainer}
                    PaperProps={{
                      sx: {
                        backgroundColor: "aliceblue",
                      },
                    }}
                  >
                    <Box
                      sx={{ padding: "1rem" }}
                      adl-scrollbar="true"
                      adl-scrollbar-width="0.3"
                    >
                      <Typography
                        style={{ width: "100%", textAlign: "center" }}
                      >
                        Selection Options
                      </Typography>
                      <Divider sx={{ margin: "1rem" }} />
                      {list(anchor)}
                    </Box>
                  </Drawer>
                  <Typography
                    style={{ cursor: "pointer" }}
                    className={styles.sideTitle}
                    onClick={toggleDrawer(anchor, true)}
                  >
                    Selection Options
                  </Typography>
                </React.Fragment>
              ))}
            </div>
          </Box>
          <Box>
            {/* sx={{ width: {lg:'95.5%',xl:"96.5%"}, height: { lg: "86vh", xl: "86vh" } }} */}
            {/* adl-scrollbar="true"
          adl-scrollbar-width="0.3" */}
            <CostingScenarioPage
              count={count}
              setStatus={setStatus}
              scenarioDashboardOpen={scenarioDashboardOpen}
              setScenarioDashboardOpen={setScenarioDashboardOpen}
              newScenarioRequestCount={newScenarioRequestCount}
            />
          </Box>
        </Box>
      </Box>
    </div>
  );
}
