import { Save } from '@mui/icons-material';
import { Autocomplete, CircularProgress, TableCell, TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { API } from '../../api-services';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';

interface WorkstationRenderCellProps {
  rowItem: any;
  CategoryList: any;
  premissionData: any;

  setCategoryCount: any;

}

export const WorkstationRenderCell = (props: WorkstationRenderCellProps) => {
  const { rowItem, CategoryList,


    premissionData,
    setCategoryCount

  } = props;
  const {
    projectId,
    projectname,
    categoryId,
    productId,
    datatype,
    validationId,
    categoryname,
  } = useRouteParams<any>();

  const [Categoriesdata, setCategoriesdata] = useState<any>([])
  const [categoriesIds, setCategoriesIds] = useState<any>([])
  const [ParamterdataListIds, setParamterdataListIds] = useState<any>([])
  const [ParamterdataList, setParamterdataList] = useState<any>([])
  const [Color, setColor] = useState<any>(false)
  const [ParameterStatus, setParameterStatus] = useState<any>(false)
  const [CategoryStatus, setCategoryStatus] = useState<any>(false)
  const [loader, setLoader] = useState<any>(false)
  const [loaderautocompeleted, setloaderautocompeleted] = useState<any>(false)
  const [Counter, setCounter] = useState<any>(0)



  useEffect(() => {

    var filterdIds = CategoryList?.filter((item: any, index: any) => { return rowItem?.categories?.includes(item?.id) })
    var remainingdata = CategoryList?.filter((item: any, index: any) => { return !rowItem?.categories?.includes(item?.id) })

    setCategoriesdata(remainingdata && remainingdata)
    setCategoriesIds(filterdIds)
    var selcetedIds = filterdIds?.map((item: any, index: any) => { return item?.id })

    {
      if (selcetedIds && selcetedIds?.length > 0) {
        setloaderautocompeleted(true)
        API.get("/bom/parameter_mapping/", { top_vault: productId, module: 6, workstation_category: true, category_list: selcetedIds?.toString() }, 0).then((res: any) => {

          // console.log(res?.data, "dataresdata")
          setloaderautocompeleted(false)
          var ParameterfilterdIds = res?.data?.filter((item: any, index: any) => { return rowItem?.allowed_parameters?.includes(item?.parameter.id) })
          var Parameterremainingdata = res?.data?.filter((item: any, index: any) => { return !rowItem?.allowed_parameters?.includes(item?.parameter?.id) })

          setParamterdataList(Parameterremainingdata && Parameterremainingdata)
          setParamterdataListIds(ParameterfilterdIds)
        }).catch((err: any) => {
          console.log("Server Error")
          setloaderautocompeleted(false)
        })
      }
    }


  }, [rowItem, CategoryList])

  var CategoriesoptionData = Categoriesdata && Categoriesdata;
  var ParametetroptionData = ParamterdataList && ParamterdataList;


  const SaveHandler = () => {
    if (ParamterdataListIds?.length > 0 && categoriesIds?.length > 0) {
      var Allowed_parameters = ParamterdataListIds?.map((item: any) => { return item?.parameter?.id })
      var Categories = categoriesIds?.map((item: any) => { return item?.id })
      setColor(false)
      setLoader(true)
      API.put(`/config/workstation/${rowItem?.id}/`, { allowed_parameters: Allowed_parameters, categories: Categories }).then((res: any) => {

        setLoader(false)
        setCategoryCount((prev: any) => prev + 1)

      }).catch((err: any) => {
        setLoader(false)
        console.log("Sever Error")
      })

    }
    if (ParamterdataListIds?.length == 0) {
      setParameterStatus(true)
    } if (categoriesIds?.length == 0) {
      setCategoryStatus(true)
    }
  }


  const handleSelectChange = (item: any, type?: any) => {
    if (type == "category") {
      setColor(true)
      setCategoriesIds(item)
      setCategoryStatus(false)
      var ids = item?.map((item: any, index: any) => { return item?.id })
      var filterdata = CategoryList?.filter((items: any, index: any) => { return !ids.includes(items?.id) })

      setCategoriesdata(filterdata)

      if (ids && ids?.length > 0) {

        setloaderautocompeleted(true)
        API.get("/bom/parameter_mapping/", { top_vault: productId, module: 6, workstation_category: true, category_list: ids?.toString() }, 0).then((res: any) => {

          console.log(res?.data, "dataresdata")
          setloaderautocompeleted(false)

          var ParameterfilterdIds = res?.data?.filter((item: any, index: any) => { return rowItem?.allowed_parameters?.includes(item?.parameter.id) })
          var Parameterremainingdata = res?.data?.filter((item: any, index: any) => { return !rowItem?.allowed_parameters?.includes(item?.parameter?.id) })

          setParamterdataList(Parameterremainingdata && Parameterremainingdata)
          setParamterdataListIds(ParameterfilterdIds)
        }).catch((err: any) => {
          console.log("Server Error")
          setloaderautocompeleted(false)
        })
      }
      if (ids?.length == 0) {
        setParamterdataList([])
        setParamterdataListIds([])
      }

    } else {
      setColor(true)
      setParamterdataListIds(item)
      setParameterStatus(false)
      var ids = item?.map((item: any, index: any) => { return item?.parameter.id })
      var filterdata = ParamterdataList?.filter((items: any, index: any) => { return !ids.includes(items?.parameter?.id) })
      setParamterdataList(filterdata && filterdata)

    }
  }
  return (
    <>
      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
        <Autocomplete
          multiple
          disabled={premissionData && premissionData?.includes("U") ? false : true}
          id="size-small-standard"
          size="small"
          disableClearable
          limitTags={4}
          disableCloseOnSelect
          sx={{
            borderColor: CategoryStatus ? "red" : "primary.main",
            '.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator': {
              color: 'primary.main'
            },
            '.MuiButtonBase-root.MuiChip-root': {
              backgroundColor: 'primary.light',
            },
          }}
          onChange={(event, newValue) => {
            handleSelectChange(newValue, "category");
          }}
          options={CategoriesoptionData && CategoriesoptionData}
          getOptionLabel={(option) => option.name}
          value={categoriesIds && categoriesIds}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder="Categories"
              sx={{
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: '1rem !important'
                },
                '& .MuiInput-underline:before': { borderBottomColor: ParameterStatus ? "res" : 'primary.light' },
                '& .MuiInput-underline:after': { borderBottomColor: ParameterStatus ? "res" : 'primary.light' },
              }}
            />
          )}
        />
      </TableCell>
      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
        {loaderautocompeleted ? <CircularProgress size={20}
          thickness={4} /> :
          <Autocomplete
            multiple
            disabled={premissionData && premissionData?.includes("U") ? false : true}
            id="size-small-standard"
            size="small"
            disableClearable
            limitTags={4}
            disableCloseOnSelect
            onChange={(event, newValue) => {
              handleSelectChange(newValue);
            }}
            sx={{
              '.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator': {
                color: 'primary.main'
              },
              '.MuiButtonBase-root.MuiChip-root': {
                backgroundColor: 'primary.light',
              },
            }}
            options={ParametetroptionData}
            getOptionLabel={(option) => option?.parameter?.name}
            value={ParamterdataListIds && ParamterdataListIds}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                sx={{
                  ".MuiInputBase-root.MuiInput-root": {
                    fontSize: '1rem !important'
                  },
                  '& .MuiInput-underline:before': { borderBottomColor: ParameterStatus ? "res" : 'primary.light' },
                  '& .MuiInput-underline:after': { borderBottomColor: ParameterStatus ? "res" : 'primary.light' },
                }}
                placeholder="Parameters"
              />
            )}

          />
        }
      </TableCell>
      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
        {rowItem?.save_status ?
          <LoadingButton disabled={premissionData && premissionData?.includes("U") ? false : true} loading={loader} onClick={() => SaveHandler()} endIcon={<SaveIcon sx={{ color: Color ? "red" : "#007fff", cursor: "pointer" }} titleAccess='Save' />} /> : ""}

      </TableCell>
    </>
  )
}