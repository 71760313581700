

import React, { useEffect, useState } from "react";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  IconButton,
  Paper,
  Button,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import CircularProgress from '@mui/material/CircularProgress';
import {
  Delete as DeleteIcon,
  FileUpload as FileUploadIcon,
  CloudUpload as CloudUploadIcon,
} from "@mui/icons-material";
import ImageUploadS3ProofDocs from "./ImageUploadS3ProofDocs";
import Swal from "sweetalert2";
import { API } from "../../../api-services";
// import { useSearchParams } from "next/navigation";
import { useParams } from "react-router-dom";
import { useBaseModal } from "../../../ComponentUI/SearchPage/useBaseModal"; 
import CDN_URL from "../../../utlis/CDN_URL";

interface UploadedFile {
  file: File;
  progress: number;
}

function FileUploadComponent({
  claimedData,
  setClaimedData,
  setCounts,
  counts,
  setActiveStep,
  activeStep,
  handleBack,
  handleNext,
  climedId,

  setClaimedId,

  request_type,
}: any) {
  const Addpartmodal = useBaseModal();
  // const searchParams = useSearchParams();
  const {claimId}:any = useParams();
  const [initValues, setInitValues] = React.useState({
    awards_recognition_docs: [],
    product_catalogue: [],
    quality_certificate: [],
  });
  const [claimrequestId, setClaimRequestId] = useState<any>();
  // const claimId: any = searchParams.get("claimId");

  
  const [loader, setLoader] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      awards_recognition_docs: [],
      product_catalogue: [],
      quality_certificate: [],
    },
    onSubmit: (values) => {
      const sanitizeCertificates = (certificates: any) => {
        const sanitized: any = {};
        for (const key in certificates) {
          if (Array.isArray(certificates[key])) {
            const filtered = certificates[key].filter((obj: any) =>
              Object.values(obj).every((value) => value !== "")
            );
            sanitized[key] = filtered.length > 0 ? filtered : [];
          } else {
            sanitized[key] = certificates[key];
          }
        }
        return sanitized;
      };

      // Sanitize initial values
      const sanitizedInitialValues = sanitizeCertificates(values);
      sanitizedInitialValues["form_completed"] = true;
     
      API.put(
        `/supplier/supplier_db/${claimedData?.id}/`,
        sanitizedInitialValues
      )

        // API.post(`supplier/claim_business/`, values)
        .then((res) => {
          setClaimedData(res.data?.data);
          Swal.fire({
            icon: "success",
            title: res.data?.data?.id ? `Supplier Edited Successfully` : `Supplier Created Successfully`,
            
            
            confirmButtonText: "Go to Supplier Page",
            
            allowOutsideClick: false, // Prevent closing by clicking outside
          }).then((result) => {
            if (result.isConfirmed) {
              // User clicked "View Your Request"
              window.location.href = `/#/data-base/supplier-database/1/0/0/${res.data?.data?.id}`;
              // window.open("/dashboard/?tab_type=myRequest&sub_tab=create_claim_business");
            }
          });
        })

        .catch((err: any) => {
          Swal.fire({
            icon: "error",
            title: `${err.response?.data[0]}`,
          });
        })
        .finally(() => {
          // setLoader(false);
        });
    },
  });

  const handleFilesUpload = (
    event: React.ChangeEvent<HTMLInputElement>,
    docType: string
  ) => {
    if (docType === "awards_recognition_docs") {
      setAwardLoader(true)
    } else if (docType === "quality_certificate") {
      setQualityLoader(true)
    } else{
      setProductLoader(true)
    }
    
    
    const files = event.target.files;
    if (files) {
      const totalSize = Array.from(files).reduce(
        (acc, file) => acc + file.size,
        0
      );

      // Check if total size exceeds 200 MB (200 * 1024 * 1024 bytes)
      if (totalSize > 200 * 1024 * 1024) {
        Swal.fire({
          title: "Warning!",
          text: "Total file size exceeds the maximum limit of 200MB",
          icon: "warning",
          confirmButtonText: "Ok",
          customClass: {
            container: "swal2Container",
          },
          confirmButtonColor: "#008878",
        }).then((result) => {
          if (result.isConfirmed) {
            if (docType === "awards_recognition_docs") {
              setAwardLoader(false)
            } else if (docType === "quality_certificate") {
              setQualityLoader(false)
            } else{
              setProductLoader(false)
            }
          } 
        });
        return; // Exit the function if the size exceeds the limit
      }

      const filesArray = Array.from(files).map((file, index) => {
        ImageUploadS3ProofDocs(
          [file],
          formik.setFieldValue,
          // docType === "awards_recognition_docs" ? setAwardLoader :  docType === "quality_certificate" ? setQualityLoader  : setProductLoader,
          setLoader,
          `${docType}.[${index}].doc_url`
        );

        return {
          doc_name: file.name,
          documentFile: URL.createObjectURL(file),
          doc_url: URL.createObjectURL(file),
        };
      });

      formik.setFieldValue(docType, [
        ...formik.values[docType as keyof typeof formik.values],
        ...filesArray,
      ]);
    }


    if (docType === "awards_recognition_docs") {
      setAwardLoader(false)
    } else if (docType === "quality_certificate") {
      setQualityLoader(false)
    } else{
      setProductLoader(false)
    }
  };

  const [awardLoader, setAwardLoader] = React.useState(false);
  const [productLoader, setProductLoader] = React.useState(false);
  const [qualityLoader, setQualityLoader] = React.useState(false);

  const handleDelete = (docType: string, fileIndex: number) => {
    const updatedFiles = formik.values[
      docType as keyof typeof formik.values
    ].filter((_: any, index: number) => index !== fileIndex);
    formik.setFieldValue(docType, updatedFiles);
  };

  const handleDocNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    docType: string,
    index: number
  ) => {
    const updatedFiles: any = [
      ...formik.values[docType as keyof typeof formik.values],
    ];
    updatedFiles[index].doc_name = event.target.value;
    formik.setFieldValue(docType, updatedFiles);
  };

  

  useEffect(() => {
    if (claimedData) {
      setInitValues({
        ...initValues,
        awards_recognition_docs:
          claimedData.awards_recognition_docs?.length > 0
            ? claimedData.awards_recognition_docs
            : [],
        quality_certificate:
          claimedData.quality_certificate?.length > 0
            ? claimedData.quality_certificate
            : [],
        product_catalogue:
          claimedData.product_catalogue?.length > 0
            ? claimedData.product_catalogue
            : [],
      });
    }
  }, [claimedData]);

  useEffect(() => {
    formik.setFieldValue(
      "quality_certificate",
      claimedData?.quality_certificate?.length > 0
        ? claimedData?.quality_certificate
        : []
    );

    formik.setFieldValue(
      "awards_recognition_docs",
      claimedData?.awards_recognition_docs?.length > 0
        ? claimedData?.awards_recognition_docs
        : []
    );
    formik.setFieldValue(
      "product_catalogue",
      claimedData?.product_catalogue?.length > 0
        ? claimedData?.product_catalogue
        : []
    );
  }, [initValues]);

  const countFieldsWithDocuments = () => {
    // Get the document arrays from Formik values
    const awardsDocs = formik.values.awards_recognition_docs || [];
    const productDocs = formik.values.product_catalogue || [];
    const qualityDocs = formik.values.quality_certificate || [];


    
    [awardsDocs, productDocs, qualityDocs]?.map((item)=> {
      console.log(item);
      
    })

    // Count how many of these arrays have at least one document
    const count = [awardsDocs, productDocs, qualityDocs]?.filter(
      (docArray) => docArray?.length > 0
    )?.length;

    console.log(count);
    

    return count;
  };

  useEffect(() => {
    // Update the document count whenever Formik values change
 
    
    const count = countFieldsWithDocuments();
    setCounts({ ...counts, v3: count });
  }, [formik.values]);

  const addPartHandler = (requestId:any) => {
    setClaimRequestId(requestId);
    Addpartmodal.open();
  }
  

  const renderUploadedFiles = (docType: string) => (
    <List>
      {(formik.values[docType as keyof typeof formik.values] as any[]).map(
        (file, index) => (
          <ListItem key={index} sx={{ borderBottom: "1px solid #E0E0E0" }}>
      
            <ListItemIcon sx={{cursor:"pointer"}} onClick={()=> {
              console.log(file);
              
              window.open(`${CDN_URL}${file.doc_url}`)
            }}>
              {docType === "awards_recognition_docs" ? (
                <FileCopyIcon color="primary" />
              ) : docType === "product_catalogue" ? (
                <FileCopyIcon color="secondary" />
              ) : (
                <FileCopyIcon color="success" />
              )}
            </ListItemIcon>
            <TextField
              variant="outlined"
              size="small"
              value={file.doc_name}
              onChange={(event: any) =>
                handleDocNameChange(event, docType, index)
              }
              fullWidth
            />
            <IconButton onClick={() => handleDelete(docType, index)}>
              <DeleteIcon />
            </IconButton>
          </ListItem>
        )
      )}
    </List>
  );

  // const countFilledFields = () => {
  //   let count = 0;
  
  
  //   // Check awards_recognition_docs? array
  //   if (formik.values?.awards_recognition_docs?.length > 0 && hasFilledObject(formik.values?.awards_recognition_docs)) {
  //     count++;
  //   }
  
  //   // Check gst_certificate array
  //   if (formik.values?.product_catalogue?.length > 0 && hasFilledObject(formik.values?.product_catalogue)) {
  //     count++;
  //   }
  
  //     // Check quality_certificate array
  //     if (formik.values?.quality_certificate?.length > 0 && hasFilledObject(formik.values?.quality_certificate)) {
  //       count++;
  //     }
  
  //   // // Check pan_document array
  //   // if (formik.values?.additional_information?.length > 0 && hasFilledObject(formik.values?.additional_information)) {
  //   //   count++;
  //   // }
  
    
  
  //   return count;
  // };

  return (
    <Box
      sx={{
        marginTop: "3rem",
        p: 2,
        border: "1px solid #E0E0E0",
        borderRadius: "8px",
        width: "100%",
        bgcolor: "#fff",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Box sx={{ mb: 2 }}>
        <input
          type="file"
          multiple
          accept=".pdf,.jpg,.jpeg,.png"
          style={{ display: "none" }}
          id="awards-recognition-upload"
          onChange={(event) =>
            handleFilesUpload(event, "awards_recognition_docs")
          }
        />
      {awardLoader ? <Box sx={{ width: "100%", padding: "16px", cursor: "pointer" }}>
      <Paper
              variant="outlined"
              sx={{
                border: "2px dashed #90caf9",
                padding: "24px",
                textAlign: "center",
                backgroundColor: "#fafafa",
                "&:hover": {
                  backgroundColor: "#f0f0f0",
                },
                display:"flex",
                justifyContent:"center",
              }}
            >
              
              <Box sx={{ display: 'flex' }}>
      <CircularProgress sx={{fontSize:"1rem"}}/>
    </Box>
            </Paper>
      </Box> :   <label htmlFor="awards-recognition-upload">
          <Box sx={{ width: "100%", padding: "16px", cursor: "pointer" }}>
            <Typography
              sx={{
                marginBottom: "8px",
                fontSize: "1.1rem",
                fontWeight: "500",
              }}
            >
              Awards & Recognition Documents
            </Typography>
            <Paper
              variant="outlined"
              sx={{
                border: "2px dashed #90caf9",
                padding: "24px",
                textAlign: "center",
                backgroundColor: "#fafafa",
                "&:hover": {
                  backgroundColor: "#f0f0f0",
                },
              }}
            >
              <Typography
                variant="body1"
                sx={{ color: "#1976d2", cursor: "pointer" }}
              >
                Click to upload file
              </Typography>
              <Typography
                variant="caption"
                display="block"
                sx={{ marginTop: "8px", color: "#757575" }}
              >
                (Total file size limit is 200 MB)
              </Typography>
            </Paper>
          </Box>
        </label>}
        {renderUploadedFiles("awards_recognition_docs")}
      </Box>

      <Box sx={{ mb: 2 }}>
        <input
          type="file"
          multiple
          accept=".pdf,.jpg,.jpeg,.png"
          style={{ display: "none" }}
          id="product-catalogue-upload"
          onChange={(event) => handleFilesUpload(event, "product_catalogue")}
        />
        {productLoader ? <Box sx={{ width: "100%", padding: "16px", cursor: "pointer" }}>
      <Paper
              variant="outlined"
              sx={{
                border: "2px dashed #90caf9",
                padding: "24px",
                textAlign: "center",
                backgroundColor: "#fafafa",
                "&:hover": {
                  backgroundColor: "#f0f0f0",
                },
                display:"flex",
                justifyContent:"center",
              }}
            >
              
              <Box sx={{ display: 'flex' }}>
      <CircularProgress sx={{fontSize:"1rem"}}/>
    </Box>
            </Paper>
      </Box> :  <label htmlFor="product-catalogue-upload">
          <Box sx={{ width: "100%", padding: "16px", cursor: "pointer" }}>
            <Typography
              sx={{
                marginBottom: "8px",
                fontSize: "1.1rem",
                fontWeight: "500",
              }}
            >
              Product Catalogue
            </Typography>
            <Paper
              variant="outlined"
              sx={{
                border: "2px dashed #90caf9",
                padding: "24px",
                textAlign: "center",
                backgroundColor: "#fafafa",
                "&:hover": {
                  backgroundColor: "#f0f0f0",
                },
              }}
            >
              <Typography
                variant="body1"
                sx={{ color: "#1976d2", cursor: "pointer" }}
              >
                Click to upload file
              </Typography>
              <Typography
                variant="caption"
                display="block"
                sx={{ marginTop: "8px", color: "#757575" }}
              >
                (Total file size limit is 200 MB)
              </Typography>
            </Paper>
          </Box>
        </label>}
       
        {renderUploadedFiles("product_catalogue")}
      </Box>

      <Box sx={{ mb: 2 }}>
        <input
          type="file"
          multiple
          accept=".pdf,.jpg,.jpeg,.png"
          style={{ display: "none" }}
          id="quality-certificate-upload"
          onChange={(event) => handleFilesUpload(event, "quality_certificate")}
        />
       
        {qualityLoader ? <Box sx={{ width: "100%", padding: "16px", cursor: "pointer" }}>
      <Paper
              variant="outlined"
              sx={{
                border: "2px dashed #90caf9",
                padding: "24px",
                textAlign: "center",
                backgroundColor: "#fafafa",
                "&:hover": {
                  backgroundColor: "#f0f0f0",
                },
                display:"flex",
                justifyContent:"center",
              }}
            >
              
              <Box sx={{ display: 'flex' }}>
      <CircularProgress sx={{fontSize:"1rem"}}/>
    </Box>
            </Paper>
      </Box>  : <label htmlFor="quality-certificate-upload">
          <Box sx={{ width: "100%", padding: "16px", cursor: "pointer" }}>
            <Typography
              sx={{
                marginBottom: "8px",
                fontSize: "1.1rem",
                fontWeight: "500",
              }}
            >
              Quality Certificate
            </Typography>
            <Paper
              variant="outlined"
              sx={{
                border: "2px dashed #90caf9",
                padding: "24px",
                textAlign: "center",
                backgroundColor: "#fafafa",
                "&:hover": {
                  backgroundColor: "#f0f0f0",
                },
              }}
            >
              <Typography
                variant="body1"
                sx={{ color: "#1976d2", cursor: "pointer" }}
              >
                Click to upload file
              </Typography>
              <Typography
                variant="caption"
                display="block"
                sx={{ marginTop: "8px", color: "#757575" }}
              >
                (Total file size limit is 200 MB)
              </Typography>
            </Paper>
          </Box>
        </label>}
        {renderUploadedFiles("quality_certificate")}
      </Box>

      <Box sx={{ width: "100%", padding: "1rem 3rem" }}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  // color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{
                    "&:hover": {
                      transform: "Scale(1.05)",
                      transition: "transform 0.5s ease",
                    },
                  }}
                >
                  Previous
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                <Box sx={{ display: "flex", gap: "1rem" }}>
                
                <Button
          variant="contained"
          color="primary"
         
          onClick={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
        >
          Submit
        </Button>
                </Box>
              </Box>
            </Box>

      {/* <Box sx={{ mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<CloudUploadIcon />}
          onClick={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
        >
          Upload
        </Button>
      </Box> */}
    </Box>
  );
}

export default FileUploadComponent;
