"use client";
import { API } from "../../../api-services"; 
import { Box, Button, Step, StepLabel, Stepper, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import BusinessInfo from "./BusinessInfo";
import StaticBusinessData from "./StaticBusinessData";
import AddBusinessLoader from "./AddBusinessLoader";
import Swal from "sweetalert2";

import FileUploadComponent from "./FileUploadComponent";

import ManualBusinessDataForm from "./ManualAddBusinessForm";
import styled from "@emotion/styled";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import EditBusinessDataForm from "./EditBusinessDataForm";

function AddBusinessPage() {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
 
  const steps = [
    "Business Information",
    // "Business Claimer",
    "Additional Information",
    "Business Documents",
  ];

  const {SupllierId} = useRouteParams<any>();

// const SupllierId = searchParams.get('supplier_id');
const request_type = searchParams.get('request_type');
const claimId: any = searchParams.get("claimId");
const manual = searchParams.get('manual');
const history = useHistory()



  // let { SupllierId, manual, request_type, claimId }:any = useParams();
  const [formData, setFormData] = React.useState<any>(null);
 const [gstNumber, setGstNumber] = React.useState<any>("");
  const [climedId, setClaimedId] = React.useState<any>(null);
  const [activeStep, setActiveStep] = React.useState<any>(0);
  const [fetchLoader, setFetchLoader] = React.useState<any>(false);
  const [isFetched, setIsFetched] = useState<any>(false);
  const [isClaimData, setIsClaimData] = useState<any>();
  const [toggle, setToggle] = React.useState<any>(false);
  const [apiData, setApiData] = React.useState<any>(null);
  const [manualData, setManualData] = React.useState<any>(null);

  

  const [counts, setCounts] = useState({
    v1: 0,
    v2: 0,
    v3: 0,
    v4: 0,
    v5: 0,
    v6: 0,
  });
  const [total, setTotal] = useState({
    v1: 16,
    v2: 7,
    v3: 3,
    v4: 1,
    v5: 4,
    v6: 1,
  });

  const handleBack = () => {
     setActiveStep((prevActiveStep: any) => prevActiveStep - 1);
  //   let path = window.location.pathname;
  //   let parts = path?.split("/");
  //  if (parts[2] === "1") {
  //   parts[2] = "0"
  //  } else if (parts[2] === "2") {
  //   parts[2] = "1"
    
  //  }
  //   let newPath = parts.join("/");
  //   window.location.pathname = newPath;
  };

  const handleNext = () => {
     setActiveStep((prevActiveStep: any) => prevActiveStep + 1);
  //   let path = window.location.pathname;
  //   let parts = path?.split("/");
  //  if (parts[2] === "0") {
  //   parts[2] = "1"
  //  } else if (parts[2] === "1") {
  //   parts[2] = "2"
    
  //  }
  //   let newPath = parts.join("/");
  //   window.location.pathname = newPath;
  };

  const fetchApiData = () => {
  
    setFetchLoader(true);
    API.get(
      `/supplier/get_company_details/`,{gst_no:gstNumber},0
    ).then((res: any) => {
   
      if (res.data?.
        message === "We’ve successfully received your GST and PAN numbers, but some business details are still being fetched. You can still proceed with the business registration. Rest assured, xcPROC will automatically add all missing details to your profile.") {

        Swal.fire({
          icon: "info",
          // title: `Unable to automatically generate the business`,
          text: res.data?.message,
          showCancelButton: true, // Show the second button
          confirmButtonText: "Continue with Add Business", // Change the text of the confirm button
          cancelButtonText: "Go to Home Page", // Change the text of the cancel button
          allowOutsideClick: false, // Prevent closing by clicking outside
        }).then((result) => {
          if (result.isConfirmed) {
            // User clicked "Continue with Add Business"
            // setActiveStep(0);
            history.push("/data-base/add-business/0?manual=true");
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            // User clicked "Go to Home Page"
            window.location.href = "/";
          }
        });
        // setActiveStep(1)
        
        // router.push(`/add-business/0?claimId=${res.data?.claim_request_id}`);
      }else if (res.data?.business_name) {
       

        setApiData(res.data);
        // let path = window.location.pathname;
        // let parts = path?.split("/");
        // parts[2] = "0"
        // let newPath = parts.join("/");
        // window.location.pathname = newPath;


        // Use router.push to change the route without reloading
        // router.push(newPath);
        setFetchLoader(false);
        setActiveStep(0)


        
        
      }
    }).catch((error: any) => {
      setFetchLoader(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        customClass: {
            container: "swal2Container",
        },
        text: error.response.data[0],
    });
    })
    .finally(() => {
      setFetchLoader(false);
    });
    
 
    
    
  };

  const countsArray = Object.entries(counts).map(([key, value]) => value);
  const TotalArray = Object.entries(total).map(([key, value]) => value);

  // const filteredVehicleIds = res.data?.data?.vehicle_category?.map((vehicle:any) => vehicle?.id);
  // const bomIds = res.data?.data?.bom_category?.map((item: any) => item?.id);

  const hasFilledObject = (array: any) => {
    // Function to check if an object has all fields filled with non-empty strings
    const isObjectFilled = (obj: any) => {
      for (let key in obj) {
        if (obj[key] === "" || obj[key] === undefined) {
          return false;
        }
      }
      return true;
    };

    // Iterate through the array and check each object
    for (let i = 0; i < array.length; i++) {
      if (isObjectFilled(array[i])) {
        return true; // Return true as soon as we find a filled object
      }
    }

    return false; // Return false if no object is completely filled
  };

  const countBusinessDocsFields = () => {
    let count = 0;

    // Check quality_certificate array
    if (
      apiData?.quality_certificate?.length > 0 &&
      hasFilledObject(apiData?.quality_certificate)
    ) {
      count++;
    }

    // Check awards_recognition_docs array
    if (
      apiData?.awards_recognition_docs?.length > 0 &&
      hasFilledObject(apiData?.awards_recognition_docs)
    ) {
      count++;
    }


    // Check quality_certificate array
    if (
      apiData?.quality_certificate?.length > 0 &&
      hasFilledObject(apiData?.quality_certificate)
    ) {
      count++;
    }




      
    

    return count;
  };

  const isEmpty = (value: any): boolean => {
    if (value === null || value === undefined) return true;
    if (Array.isArray(value) && value.length === 0) return true;
    if (typeof value === 'object' && Object.keys(value).length === 0) return true;
    return false;
  };

  const activeFields1 = [
    "business_name",
    "incorporated_date",
    "cin_number",
    "type",
    "registration_number",
    "business_status",
    "address_line",
    "pan_number",
    "gross_turnover",
    "export_turnover",
    "address_country",
  
    "address_city",
    "address_pincode",
    "records",
    "charges",
    "directors_signatories",
  ];

  

  const activeFields = [
    "vehicle_category",
    "bom_category",
    "business_website",
    "logo",
    "spoc_information",
    "client_information",
    "about_business",
   
   ]
  
    
   const filteredFields1 = useMemo(()=> activeFields1.filter(

    (key) => {
      if (apiData && apiData[key] !== null && apiData[key] !== undefined) {
        return !isEmpty(apiData[key]);
      }
      return false; // Exclude the field if apiData[key] is null or undefined
  
      
     
    }
  ),[apiData]);

   const businessDocCount = countBusinessDocsFields();
  // let filteredFields = activeFields?.filter(key => !isEmpty(apiData?.[key]));

  // const businessDocCount = useMemo(countBusinessDocsFields, [apiData]);

const filteredFields = useMemo(
  () => activeFields?.filter((key) => {
   
    return !isEmpty(apiData?.[key]);
  }),
  [apiData]
);



useEffect(()=> {
  setCounts({ ...counts, v3:businessDocCount, v2: filteredFields?.length, v1: filteredFields1?.length });
},[businessDocCount,filteredFields ])
useEffect(()=>{
  sessionStorage.removeItem("redirectForm")
},[])

useEffect(()=> {
 
  
   if (SupllierId && SupllierId !== null && SupllierId !== "null") {
    API.get(
      `supplier/supplier_db/`,
      {
       id:SupllierId
      },
      0
    ).then((res: any) => {
    
      const data = res.data?.[0];

          const vehicleCategoryIds = Array.isArray(data.vehicle_category) &&
          typeof data.vehicle_category[0] === 'object' ?
          data.vehicle_category.map((item: any) => item.id) :
          data.vehicle_category;
  
          const bomCategoryIds = Array.isArray(data.bom_category) &&
          typeof data.bom_category[0] === 'object' ?
          data.bom_category.map((item: any) => item.id) :
          data.bom_category;

          setApiData({
            ...data,
            vehicle_category: vehicleCategoryIds,
            bom_category: bomCategoryIds
          });
         setActiveStep(0)
      
    });
      
  }

  
},[SupllierId])

// useEffect(()=> {
// console.log(window.location.pathname);

// },[window.location.pathname])
const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
  if (e.key === 'Enter' && gstNumber.length === 15) {
    fetchApiData();
  }
};




  
  return (
    
    <Box sx={{ display: "flex", justifyContent: "center" ,height: "90vh", }} adl-scrollbar='true'
    adl-scrollbar-width='0.3'>
      <Box sx={{ 
       width: { xs: "100vw", md: "83vw" }, 
        margin: "auto 0",
        // border: "0.1px solid #f0f0f0",
        borderRadius: "1rem",
        height: "100%",
        
  
       }}>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "1.2rem",
            fontWeight: "600",
            padding: { xs: "0.5rem 0 0.2rem 0", md: "1rem 0" }, 
        
            borderBottom:"0.1px solid #f0f0f0", 
          }}
        >
          Add Business Form
        </Typography>
       {(!apiData || apiData === null) &&  !fetchLoader && !manual &&  <Box sx={{padding:"1rem"}}>
          <Box
            sx={{ marginBottom: 2, display: "flex", alignItems: "flex-end" }}
          >
            <TextField
              variant="standard"
              sx={{
                flex: 1,
                height: "0",
                marginRight: 2,
                padding: 0, // Remove any internal padding if necessary
              }}
              id="gstNumber"
              name="business_gst_no"
              label="GST Number"
              value={gstNumber}
              inputProps={{ maxLength: 15 }} // Restrict to 15 characters
              onChange={(e)=> setGstNumber(e.target.value)}
              onKeyDown={handleKeyDown} // Handle the Enter key press

              
            />
            {gstNumber?.length === 15 && (
              <Button
                sx={{
                  marginTop: "1rem",
                  color: "#fff",
                  background: "#00887a",
                  height: "2rem", // Match the height of the filled TextField
                  alignSelf: "stretch", // Ensures the button aligns with the height of the TextField
                  padding: "0 16px", // Adjust padding for a consistent look
                  ':hover': {
                    color: "#fff",
                    background: "#00887a",
                  }
                }}
                onClick={fetchApiData}
              >
                Fetch Details
              </Button>
            )}
          </Box>
          <Typography
            sx={{ textAlign: "left", fontSize: "0.9rem", color: "red" }}
          >
            Please enter GST number to get the details
          </Typography>
        </Box>}

     
        { (!apiData || apiData === null) && fetchLoader && !manual && <Box sx={{ height:"30rem", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
          <AddBusinessLoader/>


          <Typography variant="h6" sx={{ fontWeight: '600', marginTop:"1.5rem" }}>
        Fetching business details...
      </Typography>
      <Typography variant="body1" sx={{ color: '#888', marginTop: '0.5rem' }}>
        Please wait while we retrieve the information for you.
      </Typography>
          </Box>
          }
        

        {apiData && apiData !== null && !fetchLoader && !manual && (
          <Box sx={{padding:"1.5rem"}}>
            <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: { optional?: React.ReactNode } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel
                  {...labelProps}
                  // onClick={() => setActiveStep(index)}
                  sx={{}}
                >
                  <div>{label} </div>
                  
                  <Box
                     sx={{ 
                      cursor: "pointer",
                      fontSize: { xs: "0.85rem", md: "1.3rem" }, // Responsive font size
                      marginTop: "0.5rem",
                      background: index === activeStep ? "#d5e8db" : "#e6e6e6",
                      padding: "0.5rem 0.2rem",
                      borderRadius: "0.75rem",
                    }}
                  >
                    {countsArray[index]} / {TotalArray?.[index]}
                  </Box>
                </StepLabel>
              </Step>
            );
          })}
            </Stepper>

            {activeStep === 0 && (
              <ManualBusinessDataForm  handleNext={handleNext}  claimedData={apiData}  setClaimedData={setApiData}  isFetched={isFetched}  setIsFetched={setIsFetched} counts={counts} setCounts={setCounts}
              isClaimData={isClaimData}
              SupllierId={SupllierId}/>
              
              //   <StaticBusinessData 
              // handleNext={handleNext} 
              // claimedData={apiData} 
              // setClaimedData={setApiData} 
              // isFetched={isFetched} 
              // setIsFetched={setIsFetched}  
              // setIsClaimData={setIsClaimData}
              // counts={counts}
              // setCounts={setCounts}
              // isClaimData={isClaimData}
              // SupllierId={SupllierId}/>
        
            )}
            
            {activeStep === 1 && (
            <BusinessInfo
              setActiveStep={setActiveStep}
              activeStep={activeStep}
              handleBack={handleBack}
              handleNext={handleNext}
              climedId={climedId}
              setClaimedId={setClaimedId}
              claimedData={apiData}
              setClaimedData={setApiData}
              SupllierId={SupllierId}
              setToggle={setToggle}
              toggle={toggle}
              setCounts={setCounts}
              counts={counts}
              request_type={request_type}
              setFormData={setFormData}
             
     
            />
          )}
          {activeStep === 2 && (
            <FileUploadComponent 
           formData={formData} 

            setActiveStep={setActiveStep}
            activeStep={activeStep}
            handleBack={handleBack}
            handleNext={handleNext}
            climedId={climedId}
            claimedData={apiData}
            setClaimedData={setApiData}
              setClaimedId={setClaimedId}
              setToggle={setToggle}
              toggle={toggle}
              setCounts={setCounts}
              counts={counts}
              request_type={request_type}
         
           
            
            />
          )}
          
          </Box>
        )}
        

{ !fetchLoader && manual && (
          <Box sx={{padding:"1.5rem"}}>
            <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: { optional?: React.ReactNode } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel
                  {...labelProps}
                  // onClick={() => setActiveStep(index)}
                  sx={{}}
                >
                  <div>{label} </div>
                  <Box
                    sx={{
                      cursor: "pointer",
                      fontSize: { xs: "0.85rem", md: "1.3rem" }, // Responsive font size
                      marginTop: "0.5rem",
                      background: index === activeStep ? "#d5e8db" : "#e6e6e6",
                      padding: "0.5rem 0.2rem",
                      borderRadius: "0.75rem",
                    }}
                  >
                    {countsArray[index]} / {TotalArray?.[index]}
                  </Box>
                </StepLabel>
              </Step>
            );
          })}
            </Stepper>

            {activeStep === 0 && (
              <>
           
                <ManualBusinessDataForm   
                handleNext={handleNext} 
                claimedData={manualData} 
                setClaimedData={setManualData} 
                isFetched={isFetched} 
                setIsFetched={setIsFetched}  
                setIsClaimData={setIsClaimData}
                counts={counts}
                setCounts={setCounts}
                isClaimData={isClaimData}  />
              


              
              </>
              
            )}
            
            {activeStep === 1 && (
            <BusinessInfo
              setActiveStep={setActiveStep}
              activeStep={activeStep}
              handleBack={handleBack}
              handleNext={handleNext}
              climedId={climedId}
              setClaimedId={setClaimedId}
              claimedData={manualData}
              setClaimedData={setManualData}
              SupllierId={SupllierId}
              setToggle={setToggle}
              toggle={toggle}
              setCounts={setCounts}
              counts={counts}
              request_type={request_type}
              setFormData={setFormData}
             
     
            />
          )}
          {activeStep === 2 && (
            <FileUploadComponent 
           formData={formData} 

            setActiveStep={setActiveStep}
            activeStep={activeStep}
            handleBack={handleBack}
            handleNext={handleNext}
            climedId={climedId}
            claimedData={manualData}
            setClaimedData={setManualData}
              setClaimedId={setClaimedId}
              setToggle={setToggle}
              toggle={toggle}
              setCounts={setCounts}
              counts={counts}
              request_type={request_type}
         
           
            
            />
          )}
          
          </Box>
        )}






      </Box>
    </Box>
  );
}

export default AddBusinessPage;
