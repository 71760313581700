// src/components/DirectorsSignatoriesModal.tsx
import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from "@mui/material";


const modalStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    maxWidth: "900px",
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 3,
};

interface DirectorsSignatoriesModalProps {
    isOpen: boolean;
    onCloseModal: () => void;
    directors: [];
}

function DirectorsSignatoriesModal ({ isOpen, onCloseModal, directors }:any){
    return (
        <Modal
            open={isOpen}
            onClose={onCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={modalStyle}>
                <Typography variant="h6" component="h2" gutterBottom>
                    Directors and Signatories
                </Typography>
                <TableContainer component={Paper} sx={{ maxHeight: "70vh", overflow: 'auto' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: "#fff", backgroundColor: "#00887a" }}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}> Name</Typography>
                                </TableCell>
                           
                                <TableCell sx={{ color: "#fff", backgroundColor: "#00887a" }}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Designation</Typography>
                                </TableCell>
                                <TableCell sx={{ color: "#fff", backgroundColor: "#00887a" }}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Appointment Date</Typography>
                                </TableCell>
                                <TableCell sx={{ color: "#fff", backgroundColor: "#00887a" }}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Current Signatory</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {directors?.map((director:any, index:any) => (
                                <TableRow key={index}>
                                    <TableCell>{director?.firstName ?? ''} &nbsp; {director?.middleName ?? ''}&nbsp;{director?.lastName ?? ''}</TableCell>
                                 
                                    <TableCell>{director?.designation ?? 'N/A'}</TableCell>
                                    <TableCell>{director?.appointmentDate ?? 'N/A'}</TableCell>
                                    <TableCell>{director?.isCurrentSignatory ? 'Yes' : 'No'}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Modal>
    );
};

export default DirectorsSignatoriesModal;