// react
import React, { useMemo } from "react";

// vendors
import Plotly from "plotly.js";
import createPlotlyComponent from "react-plotly.js/factory";

// constants
// factories
// core
// redux
// ui-components
// others
// styles
import styles from "../FeatureLandingPage/CostChart.module.scss";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList,
  ResponsiveContainer,
} from "recharts";
import useWindowDimensions from "../../utlis/useWindowDimensions";
import { toFixed } from "../../utlis/toFixed";
import { Box, Typography } from "@mui/material";
import { currencyFormat } from "../../utlis/CurrencyFormat";
import { fontSize } from "@mui/system";

// interfaces and types
// lazy

// ----------------------------------------------------------------------------------
// initializations

// ----------------------------------------------------------------------------------

interface CostChartProps {
  costEstimation: any | null;
}

const CostViewChart: React.FC<CostChartProps> = (props) => {
  const { costEstimation } = props;

  // const [toggle, setToggle] = React.useState<boolean>( false );

  const widthref = React.useRef(null);
  const [Customwidth, setCustomwidth] = React.useState(0);
  // ----------------------------------------------------------------------------------
  // jsx

  const { width } = useWindowDimensions();

  const totalRawMaterialCost: any = useMemo(() => {
    if (!costEstimation) return 0;
    const value = costEstimation.raw_material + costEstimation.bop;
    return value;
  }, [costEstimation]);

  const processCost: any = useMemo(() => {
    if (!costEstimation) return 0;
    const value =
      costEstimation.tool +
      costEstimation.setup +
      costEstimation.labour +
      costEstimation.machine;
    return value;
  }, [costEstimation]);

  const overheadCost: any = useMemo(() => {
    if (!costEstimation) return 0;

    const value = Object.values(costEstimation.overhead || {}).reduce(
      (result: any, val: any) => result + val,
      0
    );
    return value;
  }, [costEstimation]);

  const childrenCost: any = useMemo(() => {
    if (!costEstimation) return 0;

    const value = Object.values(costEstimation.children_cost_info || {}).reduce(
      (result: any, val: any) => result + val,
      0
    );
    return value;
  }, [costEstimation]);

  const perUnitCost: any = useMemo(() => {
    return processCost + totalRawMaterialCost + overheadCost + childrenCost;
  }, [processCost, totalRawMaterialCost, overheadCost, childrenCost]);

  const getValue = (key: keyof any) => {
    const value = costEstimation?.[key];
    if (typeof value !== "number") return 0;
    return toFixed(value);
  };

  let materialData: any = [];

  const rm = getValue("raw_material");
  const pi = getValue("bop");

  rm &&
    materialData.push({
      name: "Raw Material",
      "Raw Material": rm, // uv is the part of the graph we want to show
      pv: 0, // pv is the floating part (transparent)})
    });

  pi &&
    materialData.push({
      name: "BOP",
      "BOP Cost": pi, // uv is the part of the graph we want to show
      pv: rm, // pv is the floating part (transparent)})
    });

  // totalRawMaterialCost &&
  //   materialData.push({
  //     name: "MC",
  //     "Raw Material": rm,
  //     "Purchasing Item": pi, // uv is the part of the graph we want to
  //     // uv: totalRawMaterialCost,
  //     pv: 0,
  //   });
  const l: any = getValue("labour");
  const m: any = getValue("machine");
  const t: any = getValue("tool");
  const s: any = getValue("setup");


  l &&
    materialData.push({
      name: "Labour",
      Labour: l,
      pv: totalRawMaterialCost,
    });
  m &&
    materialData.push({
      name: "Machine",
      Machine: m,
      pv: totalRawMaterialCost + parseFloat(l),
    });
  t &&
    materialData.push({
      name: "Tool",
      Tool: t,
      pv: totalRawMaterialCost + parseFloat(l) + parseFloat(m),
    });
  s &&
    materialData.push({
      name: "Setup",
      Setup: s,
      pv: totalRawMaterialCost + parseFloat(l) + parseFloat(m) + parseFloat(t),

    });

  // processCost &&
  //   materialData.push({
  //     name: "PC",
  //     Labour: l,
  //     Machine: m,
  //     Setup: s,
  //     Tool: t,
  //     pv: totalRawMaterialCost,
  //   });

  costEstimation.overhead &&
    materialData.push({
      name: "Overhead",
      "Overhead Cost": costEstimation.overhead.toFixed(2),
      pv: totalRawMaterialCost + processCost,
    });

  childrenCost &&
    materialData.push({
      name: "Child",
      "Child Cost": toFixed(childrenCost),
      pv: totalRawMaterialCost + processCost + costEstimation.overhead,
    });
  perUnitCost &&
    materialData.push({
      name: "Total",
      // uv: toFixed( perUnitCost ),
      "Raw Material": getValue("raw_material"),
      "BOP Cost": getValue("bop"),
      Labour: l,
      Machine: m,
      Tool: t,
      Setup: s,

      "Overhead Cost": costEstimation.overhead.toFixed(2),
      "Child Cost": toFixed(childrenCost),
      pv: 0,
    });
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active) {
      let totalLabel = "";
      switch (label) {
        case "MC":
          totalLabel = "Material Cost";
          break;
        case "PC":
          totalLabel = "Process Cost";
          break;
        case "OC":
          totalLabel = "Overhead Cost";
          break;
        case "CC":
          totalLabel = "Child Cost";
          break;
        case "Total":
          totalLabel = "Total Cost";
          break;
      }
      let total = 0;
      if (payload?.length > 2) {
        payload
          .filter((data: any) => data.name !== "pv")
          .map((data: any) => {
            total += parseFloat(data.value);
          });
      }

      return (
        <div
          className="custom-tooltip"
          style={{
            zIndex: 2,
            backgroundColor: "white",
            padding: "1rem",
            border: "1px solid white",
          }}
        >
          {total ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                columnGap: "5px",
               
              }}
            >
              <Typography                
                sx={{
                  color: "black",
                  textAlign: "left",
                  whiteSpace: "nowrap",
                  fontSize:'0.75rem',
                  width:'4rem',
                }}
              >{` ${totalLabel} :  `}</Typography>
              <Typography  sx={{ color: "black",fontSize:'0.75rem' }}>
                {totalLabel !== "Total Cost"
                  ? currencyFormat(total, sessionStorage.getItem("currency"))
                  : currencyFormat(
                    (
                      costEstimation.tool +
                      costEstimation.setup +
                      costEstimation.labour +
                      costEstimation.machine +
                      costEstimation.raw_material +
                      costEstimation.bop +
                      costEstimation.overhead +
                      costEstimation.child_cost
                    ).toFixed(2),
                    sessionStorage.getItem("currency")
                  )}
              </Typography>
            </Box>
          ) : (
            ""
          )}
          {payload
            ?.reverse()
            ?.filter((data: any) => data.name !== "pv" && data.value)
            .map((data: any) => {
              return (
                <Box
                  sx={{
                    display: "flex",                    
                    alignItems:'center',
                    columnGap: "5px",
                  }}
                >
                  <Box sx={{ width: '0.75rem', height: '0.75rem', backgroundColor: data.fill }}>&nbsp;</Box>
                  <Box sx={{display:'flex',alignItems:'center',justifyContent:"space-between",width:"9rem",columnGap:'5px'}}>
                    <Typography                      
                      sx={{
                        color: 'black',  
                        textAlign:'left' ,                     
                        whiteSpace: "nowrap",
                        fontSize:'0.75rem',                        
                      }}
                    >{`${data.name} : `}</Typography>
                    <Typography sx={{ color: 'black',fontSize:'0.75rem',width:'fit-content',textAlign:'right',whiteSpace:"nowrap" }}>
                      {currencyFormat(
                        data.value,
                        sessionStorage.getItem("currency")
                      )}
                    </Typography>
                  </Box>
                  <Box sx={{display:'flex',alignItems:'center',justifyContent:"space-between",color:'black'}}>
                    {/* <Typography                     
                      sx={{
                        color: 'black',
                        textAlign: "left",
                        whiteSpace: "nowrap",
                        fontSize:'0.75rem',
                        width:'4rem',
                      }}
                    >{`Percentage : `}</Typography> */}
                    (<Typography sx={{ color: 'black',fontSize:'0.75rem',textAlign:'right',width:'2.8rem',}}>
                      {`${((data.value * 100) / (
                          costEstimation.tool +
                          costEstimation.setup +
                          costEstimation.labour +
                          costEstimation.machine +
                          costEstimation.raw_material +
                          costEstimation.bop +
                          costEstimation.overhead +
                          costEstimation.child_cost
                        ))?.toFixed(2)} %`} </Typography>)
                  </Box>
                </Box>
              );
            })}
        </div>
      );
    }

    return null;
  };
  let pieData = [
    toFixed(totalRawMaterialCost),
    toFixed(processCost),
    costEstimation.overhead?.toFixed(2),
  ];

  if (childrenCost) {
    pieData = [...pieData, childrenCost];
  }

  React.useEffect(() => {
    // when the component gets mounted
    // @ts-ignore
    setCustomwidth(widthref?.current?.offsetWidth);
    // to handle page resize
    const getwidth = () => {
      // @ts-ignore
      setCustomwidth(widthref?.current?.offsetWidth);
    };
    window.addEventListener("resize", getwidth);
    // remove the event listener before the component gets unmounted
    return () => window.removeEventListener("resize", getwidth);
  }, [widthref]);

  const Plot = createPlotlyComponent(Plotly);
  function CustomizedTick(props: any) {
    const { x, y, stroke, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={width > 1366 ? 10 : 8} fill="#666">
          <tspan style={{fill:"black", fontSize: width > 1366 ? "12" : "9" }} textAnchor="middle" x="0">
            {payload.value}
          </tspan>
          <tspan style={{fill:"black", fontSize: width > 1366 ? "12" : "9" }} textAnchor="middle" x="0" dy={width > 1366 ? "14" : "8"}>
            Cost
          </tspan>
        </text>
      </g>
    );
  }

  if (!costEstimation) return null;

  return (
    <div>
      {costEstimation?.show_graph && (
        <div className={styles.chartDiv}>
          <div style={{ textAlign: "center" }} className={styles.chartLeftDiv}>
            <div style={{ width: "100%", margin: "0 auto" }}>
              <Plot
                // isAnimationActive={true}
                data={[
                  {
                    values: pieData,
                    labels: [
                      "Raw Material Cost",
                      "Process Cost",
                      "Overhead Cost",
                      "Child Parts Cost",
                    ],
                    marker: {
                      colors: [
                        "rgb(255,179,71)",
                        "rgb(184,216,190)",
                        "rgb(212,212,212)",
                        "rgb(255,167,129)",
                      ],
                    },
                    type: "pie",
                    textposition: "inside",
                    automargin: true,
                  },
                ]}
                layout={{
                  height: width <= 1440 ? 130 : 169,
                  width: width <= 1440 ? 120 : 170,
                  yaxis: { range: [0, totalRawMaterialCost] },
                  xaxis: { range: [0, totalRawMaterialCost] },

                  hoverlabel: {
                    bordercolor: 'transparent',
                    font: { color: 'black', size: 11 }
                  },
                  // height: 200,
                  // width:  250,
                  margin: {
                    l: 0,
                    t: 5,
                    r: 0,
                    b: 0,
                    // @ts-ignore
                    padding: 0,
                  },
                  showlegend: false,

                  legend: {
                    x: 9,
                    y: 0,

                    traceorder: "normal",
                    bordercolor: 'none',
                    font: {
                      family: "roboto",
                      size: 9,
                      color: "#007fff",
                    },
                    orientation: "h",
                    xanchor: "left",
                  },
                }}
                config={{
                  displaylogo: false,
                }}
              />
            </div>
          </div>
          <div
            style={{
              width: width > 1440 ? 500 : 370,
              height: width > 1440 ? 200 : 160,
            }}
          >
            <ResponsiveContainer width="100%" height="100%">
              {materialData && materialData?.length > 0 ? (
                <BarChart
                  maxBarSize={30}
                  width={800}
                  height={150}
                  data={materialData}
                  // barGap={10}
                  // stackOffset="sign"
                  // layout="radial"
                  // @ts-ignore
                  margin={{ top: 20, left: 10, right: 3, bottom: 6 }}
                // padding={0}
                >
                  <CartesianGrid
                    fill="#fff"
                    vertical={false}
                    horizontal={false}
                    strokeDasharray="3 3"
                  />
                  <XAxis
                    dataKey="name"
                    interval={0}
                    style={{
                      fontSize: "10px",
                      fontWeight: "bold",
                    }}
                    stroke="#007FFF"
                    tick={<CustomizedTick />}
                  />
                  <YAxis
                    //@ts-ignore
                    left={0}
                    stroke="#007FFF"
                    fill="#1a4faa"
                    fontSize={12}
                    domain={[() => 0, (dataMax: number) => dataMax.toFixed(2)]}
                    label={{                                           
                      value: `Currency (${sessionStorage.getItem("currency")})`,
                      angle: -90,
                      position: "middle",
                      dx: -32,  
                      fill:'black',                     
                      fontSize:'12px'                                       
                    }}
                  />

                  <Tooltip
                    cursor={{ fill: "transparent" }}
                    content={<CustomTooltip />}
                  />

                  {/* <Tooltip /> */}
                  {/* <Legend /> */}
                  <Bar
                    isAnimationActive={true}
                    dataKey="pv"
                    stackId="a"
                    fill="transparent"
                  />

                  <Bar
                    isAnimationActive={true}
                    dataKey="Raw Material"
                    stackId="a"
                    fill="#FFB347"
                    width="3rem"
                  >
                    {/* {rm ? (
                    Number(perUnitCost * 0.04) < Number(rm) ? (
                      <LabelList
                        fill="#fff"
                        dataKey="Raw Material"
                        position="inside"
                      />
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )} */}
                  </Bar>
                  <Bar
                    isAnimationActive={true}
                    dataKey="BOP Cost"
                    stackId="a"
                    fill="#ffcb75"
                  >
                    {/* {pi ? (
                    Number(perUnitCost * 0.05) < Number(pi) ? (
                      <LabelList
                        fill="#fff"
                        dataKey="Purchasing Item"
                        position="outside"
                      />
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )} */}
                  </Bar>
                  <Bar
                    isAnimationActive={true}
                    dataKey="Labour"
                    stackId="a"
                    fill="#b8d8be"
                  >
                    {/* {l ? (
                    perUnitCost * 0.04 < l ? (
                      <LabelList
                        fill="#fff"
                        dataKey="Labour"
                        position="outside"
                      />
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )} */}
                  </Bar>
                  <Bar
                    isAnimationActive={true}
                    dataKey="Machine"
                    stackId="a"
                    fill="#c8e1cc"
                  >
                    {/* {m ? (
                    perUnitCost * 0.04 < m ? (
                      <LabelList
                        fill="#fff"
                        dataKey="Machine"
                        position="outside"
                      />
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )} */}
                  </Bar>
                  <Bar
                    isAnimationActive={true}
                    dataKey="Tool"
                    stackId="a"
                    fill="#d2e7d6"
                  >
                    {/* {t ? (
                    perUnitCost * 0.04 < t ? (
                      <LabelList
                        fill="#fff"
                        dataKey="Tool"
                        position="outside"
                      />
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )} */}
                  </Bar>
                  <Bar
                    isAnimationActive={true}
                    dataKey="Setup"
                    stackId="a"
                    fill="#e0f0e3"
                  >
                    {/* {s ? (
                    perUnitCost * 0.04 < s ? (
                      <LabelList
                        fill="#fff"
                        dataKey="Setup"
                        position="insideBottomRight"
                      />
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )} */}
                  </Bar>

                  <Bar
                    isAnimationActive={true}
                    dataKey="Overhead Cost"
                    stackId="a"
                    fill="#d4d4d4"
                  >
                    {/* {overheadCost ? (
                    perUnitCost * 0.04 < overheadCost ? (
                      <LabelList
                        fill="#fff"
                        dataKey="Overhead Cost"
                        position="outside"
                      />
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )} */}
                  </Bar>
                  <Bar
                    isAnimationActive={true}
                    dataKey="Child Cost"
                    stackId="a"
                    fill="#FFA781"
                  >
                    {/* {childrenCost ? (
                    perUnitCost * 0.04 < childrenCost ? (
                      <LabelList
                        fill="#fff"
                        dataKey="Child Cost"
                        position="outside"
                      />
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )} */}
                  </Bar>

                  <Bar
                    isAnimationActive={true}
                    dataKey="tc"
                    stackId="a"
                    fill="transparent"
                  >
                    {perUnitCost ? (
                      <LabelList dataKey="tc" position="top" />
                    ) : (
                      ""
                    )}
                  </Bar>
                </BarChart>
              ) : (
                <Typography align="center" mt={5}>
                  No Graph Available
                </Typography>
              )}
            </ResponsiveContainer>
          </div>
        </div>
      )}
    </div>
  );
};

// ----------------------------------------------------------------------------------

export default CostViewChart;
