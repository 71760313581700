import * as React from "react";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Box, Button, ButtonProps, IconButton, Skeleton, Typography, styled } from "@mui/material";
import { DataGrid, GridCsvExportMenuItem, GridCsvExportOptions, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExportContainer } from "@mui/x-data-grid";
import InfoIcon from "@mui/icons-material/Info";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import LaunchIcon from "@mui/icons-material/Launch";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import move from "../../../Assets/images/right move.svg";
import { useBaseParamsModal } from "../../../CustomHook/useBaseParamsModal";
import { useBaseModal } from "../../../ComponentUI/SearchPage/useBaseModal";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import useWindowDimensions from "../../../ui-reusable-component/useWindowDimensions";
import { API } from "../../../api-services";
import BoughtoutEditModal from "./BoughtoutEditModal";
import { BearingsColumns, ConnectorsColumns, ConsumablesColumns, ElectronicChildColumns, FastenersCols, MEPartsColumns, OthersColumns } from "../../../utlis/BoughtOutPart.constant";
import { BulbColumns, RemoteKeys, HornModules, IgnitionMOdules, FlasherModules } from "../../../utlis/BoughtOutElectricalParts.constant";
import FetchBoughtOutModal from "./FetchBoughtOutModal";
import InfoBoughtoutrevisionModal from "./InfoBoughtoutrevisionModal";
import BoughtoutGraph from "./BoughtoutGraph";
import FlagIcon from "@mui/icons-material/Flag";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import RMBOPmoveModal from "./RMBOPmoveModal";
import LockIcon from '@mui/icons-material/Lock';

import LockOpenIcon from '@mui/icons-material/LockOpen';

import { userSelectors } from "../../../Redux/Reducers/user.reducer";
import { useSelector } from "react-redux";
import LockStatusInfoModal from "../LockStatusInfoModal";
export interface IRawMaterialRevisionTableProps { }

// const rows: any = [];

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    square
    {...props}
  />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", color: "primary.main" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const csvOptions: GridCsvExportOptions = {
  fileName: "Idea Approve Table User Session In CSV",
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer sx={{
    width: { lg: '87%', xl: '91%' },
    height: '2.3rem',
    justifyContent: 'flex-end',
    paddingRight: '1rem',			
    marginTop:'-2.4rem', }}>
    <span
    // style={{ position: "absolute", right: "0rem" }}
    >
      <GridToolbarColumnsButton />
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

export function BOPRevisionSearch(props: IRawMaterialRevisionTableProps) {
  const { tab, year,month, revisionId, typeId, databaseName, status, search, categoryName } =
  useRouteParams<any>();
  const bopMoveModal = useBaseParamsModal();
  // const BOPtoRMMoveModal = useBaseParamsModal();
  const subRevisionEdit = useBaseParamsModal();
  const [electricalCategories, setElectricalCategories] = React.useState<any>([]);
  const graphModal = useBaseParamsModal();
  const fetchRawMaterial = useBaseModal();
  const history = useHistory();
  const { height, width } = useWindowDimensions();
  const [expanded, setExpanded] = React.useState<string | false>(categoryName && categoryName);
  const [categoryList, setCategoryList] = React.useState<any>([]);
  const [categoryListMaterial, setCategoryListMaterial] = React.useState<any>([]);
  const [count, setCount] = React.useState<number>(0);
  const [tableDataLoading, setTabledataLoading] = React.useState<any>(false);
  const [accordionLoading, setAccordionLoading] = React.useState<any>(false);
  const [tableData, setTableData] = React.useState<any>([]);
  const [electricalTableData, setElectricalTableData] = React.useState<any>([]);
  const [subElectricalLoadings, setSubElectricalLoadings] = React.useState<any>(false);
  const [expandedElectrical, setExpandedElectrical] = React.useState<string | false>(false);
  const infoRawMaterialModal = useBaseParamsModal();
  const [useraction,setuseraction]=React.useState<any>(null);
  const [selectedTab, setSelectedTab] = React.useState('');
  const [selectedModule, setSelectedModule] = React.useState('');
  const [LockData, SetLockData] = React.useState<any>(null)
  const userProfile:any = useSelector(userSelectors.selectAll);
  const lockmodal = useBaseModal()
  const handleOpenLinkReference = (reference: string) => {
    window.open(reference);
  };
  React.useEffect(() => {
    API.get(
      "db/revision_database/",
      {
        category_type: "materialcost",
        revision_id: revisionId,
        serach: search
      },
      0
    ).then((res: any) => {
      setCategoryListMaterial(res.data);
    });
  }, [revisionId, search]);

  React.useEffect(() => {
    API.get(
      "/auth/user_permission_module/",
      {
        action: true,
        database: true,
        revision_id:revisionId
      },
      0
    )
      .then((res: any) => {
        setuseraction(res?.data?.action)
        sessionStorage.setItem("DBPermission", res.data.action);
      })
      .catch((err: any) => { });
  }, [revisionId]);


  const LockHandler = (item: any, type: any) => {
    if (type==false){
      if ((userProfile && userProfile[0]?.is_superuser || userProfile[0]?.is_project_admin || userProfile[0]?.id == item?.locked_user)) {

        API.put(`db/bought_out_cost/${item?.id}/`, {
          lock: type, revision: revisionId,
          category: categoryName
        }).then((res: any) => {
  
          getMaterialData(categoryName)
        }).catch((err: any) => {
          console.log("Server error")
        })
      } else {
        SetLockData(item)
        lockmodal.open()
      }
    } if (type==true){
      API.put(`db/bought_out_cost/${item?.id}/`, {
        lock: type, revision: revisionId,
        category: categoryName
      }).then((res: any) => {

        getMaterialData(categoryName)
      }).catch((err: any) => {
        console.log("Server error")
      })
    }
    

  }
  const handleChangeElectrical = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    event.stopPropagation();
    event.preventDefault();
    setExpandedElectrical(newExpanded ? panel : false);
    if (newExpanded !== expanded) {
      setElectricalTableData([]);
      setSubElectricalLoadings(true);

      API.get(
        "db/bought_out/",
        {
          commodity: expanded,
          module_type: panel,
          revision: revisionId,
          search: search
        },
        0
      )
        .then((res: any) => {
          setElectricalTableData(res.data);
          setSubElectricalLoadings(false);
        })
        .catch((err: any) => {
          setSubElectricalLoadings(false);
        });
    }
    // if (newExpanded) {
    //   if (expanded !== panel) {
    //     getMaterialData(panel);
    //   }
    //   // db/bought_out/?commodity=Electrical %26 Electronics Proprietary Parts&module_count=true
    //   if(panel === "Electrical & Electronics Proprietary Parts"){
    //     API.get("db/bought_out/",{
    //       commodity:panel,
    //       module_count:true
    //     },0).then((res:any)=>{
    //       setElectricalCategories(res.data);
    //     }).catch((err:any)=>{

    //     })
    //   }
    //   // typeId, databaseName,categoryName
    //   history.push(`/data-base/revision-database/${revisionId}/${typeId}/${databaseName}/${panel}`);
    // }
  };
  React.useEffect(() => {
    if (expanded === "Electrical & Electronics Proprietary Parts") {
      API.get(
        "db/bought_out/",
        {
          commodity: expanded,
          module_count: true,
          revision: revisionId,
          search: search
        },
        0
      )
        .then((res: any) => {
          setElectricalCategories(res.data);
        })
        .catch((err: any) => { });
    }
  }, [expanded, search]);

  const getCategoryList = () => {
    setAccordionLoading(true);
    API.get(
      "db/revision_database/",
      {
        category_type: "boughtoutpart",
        revision_id: revisionId,
        search: search
      },
      0
    )
      .then((res: any) => {
        setCategoryList(res.data);
        setAccordionLoading(false);
      })
      .catch((err: any) => {
        setAccordionLoading(false);
      });
  };



  React.useEffect(() => {
    getCategoryList();
  }, [revisionId]);
  const editSubRevision = (item: any) => {
    subRevisionEdit.open(item);
  };
  const getMaterialData = (category: any) => {
    setTabledataLoading(true);
    console.log(search);
    // if(search.includes(',')){
    //   console.log("search",search);
    //   API.get(
    //     "db/bought_out_cost/",
    //     {
    //       revision: revisionId,
    //       commodity: category,
    //       search: search,
    //       item_name: search.split(',')[0],
    //       item_description: search.split(',')[1]
    //     },
    //     0
    //   )
    //     .then((res: any) => {
    //       setTableData(res.data);
    //       setTabledataLoading(false);
    //     })
    //     .catch((err: any) => {
    //       setTabledataLoading(false);
    //     });
    // }
    // else{
    API.get(
      "db/bought_out_cost/",
      {
        revision: revisionId,
        commodity: category,
        search: search
      },
      0
    )
      .then((res: any) => {
        setTableData(res.data);
        setTabledataLoading(false);
      })
      .catch((err: any) => {
        setTabledataLoading(false);
      });
    
  };

  // React.useEffect(() => {
	// 	if (categoryList && categoryList.length > 0) {
	// 	  // Set the selected tab to the first item in categoryList when the component loads
	// 	  setSelectedTab(categoryList[0].category);
	// 	  getMaterialData(categoryList[0].category);
	// 	}
	//   }, [categoryList]);

  // const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
  //   setExpanded(newExpanded ? panel : false);
  //   if (newExpanded) {
  //     getMaterialData(panel);
  //     // typeId, databaseName,categoryName
  //     history.push(`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/${databaseName}/${panel}/${status}/${search}`);
  //   }
  // };
  const handleChange = (categoryName: any) => {
		setSelectedTab(categoryName);
    getMaterialData(categoryName);   
    history.push(`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/${databaseName}/${categoryName}/${status}/${search}`);   
	};

  React.useEffect(() => {
    if (categoryName) {
      getMaterialData(categoryName);
    }
  }, [categoryName, search]);
  const getColumnDataElectrical = (category: any) => {
    switch (category) {
      case "Bulb":
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: width > 1370 ? 200 : width < 1200 ? 205 : 200,
            renderCell: (cellValues: any) => {
              return (
                <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
                  {cellValues.row.mapped_status ? (
                    <IconButton
                      title="BOP Info"
                      sx={{ padding: "0", color: "primary.main", cursor: "pointer" }}
                      onClick={() => infoRawMaterialModal.open(cellValues.row.id)}>
                      <InfoIcon />
                    </IconButton>
                  ) : (
                    useraction &&   useraction?.includes("D") && <IconButton
                      title="Delete"
                      sx={{ padding: "0", color: "primary.main", cursor: "pointer" }}
                      onClick={() => {
                        swal({
                          title: `Delete material`,
                          text: `Are you sure you want to delete ?`,
                          icon: "error",
                          buttons: ["Cancel", "Yes, Delete"],
                        }).then((confirm) => {
                          if (confirm) {
                            API.delete(`db/bought_out_cost/${cellValues.row.id}/`, {}, 0)
                              .then((res: any) => {
                                getMaterialData(categoryName);
                              })
                              .catch((err: any) => { });
                          }
                        });
                      }}>
                      <DeleteIcon titleAccess="Delete" />
                    </IconButton>
                  )}
                  <EqualizerIcon
                    titleAccess="Graph"
                    sx={{ cursor: "pointer" }}
                    onClick={() => graphModal.open(cellValues.row)}
                  />
                  <LaunchIcon
                    titleAccess="Redirect"
                    sx={{ cursor: "pointer" }}
                    onClick={() => history.push(`/data-base/revision-database/${tab}/${year}/${month}/1/1/bopDB/${categoryName}/${status}/${search}`)}
                  />
                  {cellValues.row?.flag_reference ? (
                    <IconButton
                      title="Flag"
                      sx={{ padding: "0", color: "primary.main", cursor: "pointer" }}
                      onClick={() => window.open(cellValues.row?.flag_reference, "_blank", " noopener")}>
                      <FlagIcon sx={{ color: "green" }} />
                    </IconButton>
                  ) : (
                    <IconButton
                      title="Flag"
                      sx={{ padding: "0", color: "primary.main" }}>
                      <FlagOutlinedIcon />
                    </IconButton>
                  )}
                  {!cellValues.row.mapped_status && useraction &&   useraction?.includes("U") && (
                    <img
                      src={move}
                      title="Move"
                      style={{ height: "1.3rem", cursor: "pointer" }}
                      onClick={() => bopMoveModal.open(cellValues.row)}
                    />
                  )}
                  {useraction &&   useraction?.includes("U") && <IconButton
                    title="Edit"
                    sx={{ padding: "0", color: "primary.main" }}
                    onClick={() => editSubRevision(cellValues.row)}>
                    <EditIcon sx={{ cursor: "pointer" }} />
                  </IconButton>}
                </Box>
              );
            },
          },
          ...BulbColumns,
        ];
      case "Remote Key/Lock":
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: width > 1370 ? 200 : width < 1200 ? 205 : 200,
            renderCell: (cellValues: any) => {
              return (
                <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
                  {cellValues.row.mapped_status ? (
                    <InfoIcon
                      titleAccess="Info"
                      sx={{ cursor: "pointer" }}
                      onClick={() => infoRawMaterialModal.open(cellValues.row.id)}
                    />
                  ) : (
                    useraction &&   useraction?.includes("D") && <DeleteIcon
                      titleAccess="Delete"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        swal({
                          title: `Delete material`,
                          text: `Are you sure you want to delete ?`,
                          icon: "error",
                          buttons: ["Cancel", "Yes, Delete"],
                        }).then((confirm) => {
                          if (confirm) {
                            API.delete(`db/bought_out_cost/${cellValues.row.id}/`, {}, 0)
                              .then((res: any) => {
                                getMaterialData(categoryName);
                              })
                              .catch((err: any) => { });
                          }
                        });
                      }}
                    />
                  )}
                  <EqualizerIcon
                    titleAccess="Graph"
                    sx={{ cursor: "pointer" }}
                    onClick={() => graphModal.open(cellValues.row)}
                  />
                  <LaunchIcon
                    titleAccess="Redirect"
                    sx={{ cursor: "pointer" }}
                    onClick={() => history.push(`/data-base/revision-database/${tab}/${year}/${month}/1/1/bopDB/${categoryName}/${status}/${search}`)}
                  />
                  {/* http://localhost:3000/#/data-base/revision-database/1/1/rmDB/STEEL/false/0 */}
                  {cellValues.row?.flag_reference ? (
                    <FlagIcon
                      titleAccess="Flag"
                      sx={{ cursor: "pointer", color: "green" }}
                      onClick={() => window.open(cellValues.row?.flag_reference, "_blank", " noopener")}
                    />
                  ) : (
                    <FlagOutlinedIcon
                      titleAccess="Flag"
                      sx={{ color: "primary.main" }}
                    />
                  )}
                  {!cellValues.row.mapped_status && useraction &&   useraction?.includes("U") && (
                    <img
                      src={move}
                      title="Move"
                      style={{ height: "1.3rem", cursor: "pointer" }}
                      onClick={() => bopMoveModal.open(cellValues.row)}
                    />
                  )}
                  {useraction &&   useraction?.includes("U") && <EditIcon
                    titleAccess="Edit"
                    sx={{ cursor: "pointer" }}
                    onClick={() => editSubRevision(cellValues.row)}
                  />}
                </Box>
              );
            },
          },
          ...RemoteKeys,
        ];

      case "Horn":
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: width > 1370 ? 200 : width < 1200 ? 205 : 200,
            renderCell: (cellValues: any) => {
              return (
                <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
                  {cellValues.row.mapped_status ? (
                    <InfoIcon
                      titleAccess="Info"
                      sx={{ cursor: "pointer" }}
                      onClick={() => infoRawMaterialModal.open(cellValues.row.id)}
                    />
                  ) : (
                    useraction &&   useraction?.includes("D") && <DeleteIcon
                      titleAccess="Delete"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        swal({
                          title: `Delete material`,
                          text: `Are you sure you want to delete ?`,
                          icon: "error",
                          buttons: ["Cancel", "Yes, Delete"],
                        }).then((confirm) => {
                          if (confirm) {
                            API.delete(`db/bought_out_cost/${cellValues.row.id}/`, {}, 0)
                              .then((res: any) => {
                                getMaterialData(categoryName);
                              })
                              .catch((err: any) => { });
                          }
                        });
                      }}
                    />
                  )}
                  <EqualizerIcon
                    titleAccess="Graph"
                    sx={{ cursor: "pointer" }}
                    onClick={() => graphModal.open(cellValues.row)}
                  />

                  <LaunchIcon
                    titleAccess="Redirect"
                    sx={{ cursor: "pointer" }}
                    onClick={() => history.push(`/data-base/revision-database/${tab}/${year}/${month}/1/1/bopDB/${categoryName}/${status}/${search}`)}
                  />
                  {cellValues.row?.flag_reference ? (
                    <FlagIcon
                      titleAccess="Flag"
                      sx={{ cursor: "pointer", color: "green" }}
                      onClick={() => window.open(cellValues.row?.flag_reference, "_blank", " noopener")}
                    />
                  ) : (
                    <FlagOutlinedIcon
                      titleAccess="Flag"
                      sx={{ color: "primary.main" }}
                    />
                  )}
                  {!cellValues.row.mapped_status && useraction &&   useraction?.includes("U") && (
                    <img
                      src={move}
                      title="Move"
                      style={{ height: "1.3rem", cursor: "pointer" }}
                      onClick={() => bopMoveModal.open(cellValues.row)}
                    />
                  )}
                  {useraction &&   useraction?.includes("U") && <EditIcon
                    titleAccess="Edit"
                    sx={{ cursor: "pointer" }}
                    onClick={() => editSubRevision(cellValues.row)}
                  />}
                </Box>
              );
            },
          },
          ...HornModules,
        ];

      case "Ignition":
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: width > 1370 ? 200 : width < 1200 ? 205 : 200,
            renderCell: (cellValues: any) => {
              return (
                <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
                  {cellValues.row.mapped_status ? (
                    <InfoIcon
                      titleAccess="Info"
                      sx={{ cursor: "pointer" }}
                      onClick={() => infoRawMaterialModal.open(cellValues.row.id)}
                    />
                  ) : (
                    useraction &&   useraction?.includes("U") && <DeleteIcon
                      titleAccess="Delete"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        swal({
                          title: `Delete material`,
                          text: `Are you sure you want to delete ?`,
                          icon: "error",
                          buttons: ["Cancel", "Yes, Delete"],
                        }).then((confirm) => {
                          if (confirm) {
                            API.delete(`db/bought_out_cost/${cellValues.row.id}/`, {}, 0)
                              .then((res: any) => {
                                getMaterialData(categoryName);
                              })
                              .catch((err: any) => { });
                          }
                        });
                      }}
                    />
                  )}
                  <EqualizerIcon
                    titleAccess="Graph"
                    sx={{ cursor: "pointer" }}
                    onClick={() => graphModal.open(cellValues.row)}
                  />

                  <LaunchIcon
                    titleAccess="Redirect"
                    sx={{ cursor: "pointer" }}
                    onClick={() => history.push(`/data-base/revision-database/${tab}/${year}/${month}/1/1/bopDB/${categoryName}/${status}/${search}`)}
                  />

                  {cellValues.row?.flag_reference ? (
                    <FlagIcon
                      titleAccess="Flag"
                      sx={{ cursor: "pointer", color: "green" }}
                      onClick={() => window.open(cellValues.row?.flag_reference, "_blank", " noopener")}
                    />
                  ) : (
                    <FlagOutlinedIcon
                      titleAccess="Flag"
                      sx={{ color: "primary.main" }}
                    />
                  )}
                  {!cellValues.row.mapped_status && useraction &&   useraction?.includes("U") && (
                    <img
                      src={move}
                      title="Move"
                      style={{ height: "1.3rem", cursor: "pointer" }}
                      onClick={() => bopMoveModal.open(cellValues.row)}
                    />
                  )}
                  {useraction &&   useraction?.includes("U") && <EditIcon
                    titleAccess="Edit"
                    sx={{ cursor: "pointer" }}
                    onClick={() => editSubRevision(cellValues.row)}
                  />}
                </Box>
              );
            },
          },
          ...IgnitionMOdules,
        ];

      case "Flasher":
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: width > 1370 ? 200 : width < 1200 ? 205 : 200,
            renderCell: (cellValues: any) => {
              return (
                <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
                  {cellValues.row.mapped_status ? (
                    <InfoIcon
                      titleAccess="Info"
                      sx={{ cursor: "pointer" }}
                      onClick={() => infoRawMaterialModal.open(cellValues.row.id)}
                    />
                  ) : (
                    useraction &&   useraction?.includes("D") && <DeleteIcon
                      titleAccess="Delete"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        swal({
                          title: `Delete material`,
                          text: `Are you sure you want to delete ?`,
                          icon: "error",
                          buttons: ["Cancel", "Yes, Delete"],
                        }).then((confirm) => {
                          if (confirm) {
                            API.delete(`db/bought_out_cost/${cellValues.row.id}/`, {}, 0)
                              .then((res: any) => {
                                getMaterialData(categoryName);
                              })
                              .catch((err: any) => { });
                          }
                        });
                      }}
                    />
                  )}
                  <EqualizerIcon
                    titleAccess="Graph"
                    sx={{ cursor: "pointer" }}
                    onClick={() => graphModal.open(cellValues.row)}
                  />

                  <LaunchIcon
                    titleAccess="Redirect"
                    sx={{ cursor: "pointer" }}
                    onClick={() => history.push(`/data-base/revision-database/${tab}/${year}/${month}/1/1/bopDB/${categoryName}/${status}/${search}`)}
                  />
                  {cellValues.row?.flag_reference ? (
                    <FlagIcon
                      titleAccess="Flag"
                      sx={{ cursor: "pointer", color: "green" }}
                      onClick={() => window.open(cellValues.row?.flag_reference, "_blank", " noopener")}
                    />
                  ) : (
                    <FlagOutlinedIcon
                      titleAccess="Flag"
                      sx={{ color: "primary.main" }}
                    />
                  )}
                  {!cellValues.row.mapped_status && useraction &&   useraction?.includes("U") && (
                    <img
                      src={move}
                      title="Move"
                      style={{ height: "1.3rem", cursor: "pointer" }}
                      onClick={() => bopMoveModal.open(cellValues.row)}
                    />
                  )}
                  {useraction &&   useraction?.includes("U") && <EditIcon
                    titleAccess="Edit"
                    sx={{ cursor: "pointer" }}
                    onClick={() => editSubRevision(cellValues.row)}
                  />}
                </Box>
              );
            },
          },
          ...FlasherModules,
        ];
      default:
        return [];
    }
  };

  const getColumnData = (category: any) => {
    switch (category) {
      case "Connectors":
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: width > 1370 ? 200 : width < 1200 ? 205 : 200,
            renderCell: (cellValues: any) => {
              return (
                <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
                  {cellValues.row.mapped_status ? (
                    <InfoIcon
                      titleAccess="Info"
                      sx={{ cursor: "pointer" }}
                      onClick={() => infoRawMaterialModal.open(cellValues.row.id)}
                    />
                  ) : (
                    useraction &&   useraction?.includes("D") && <DeleteIcon
                      titleAccess="Delete"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        swal({
                          title: `Delete material`,
                          text: `Are you sure you want to delete ?`,
                          icon: "error",
                          buttons: ["Cancel", "Yes, Delete"],
                        }).then((confirm) => {
                          if (confirm) {
                            API.delete(`db/bought_out_cost/${cellValues.row.id}/`, {}, 0)
                              .then((res: any) => {
                                getMaterialData(categoryName);
                              })
                              .catch((err: any) => { });
                          }
                        });
                      }}
                    />
                  )}

{
                    cellValues?.row?.lock_status ? <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'green' }} onClick={() => LockHandler(cellValues?.row, false)}>
                      <LockIcon />
                    </IconButton> : <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'primary.main' }} onClick={() => LockHandler(cellValues?.row, true)}>
                      <LockOpenIcon sx={{color:'red'}}/>
                    </IconButton>
                  }
                  <EqualizerIcon
                    titleAccess="Graph"
                    sx={{ cursor: "pointer" }}
                    onClick={() => graphModal.open(cellValues.row)}
                  />

                  <LaunchIcon
                    titleAccess="Redirect"
                    sx={{ cursor: "pointer" }}
                    onClick={() => history.push(`/data-base/revision-database/${tab}/${year}/${month}/1/1/bopDB/${categoryName}/${status}/${search}`)}
                  />
                  {/* http://localhost:3000/#/data-base/revision-database/1/1/rmDB/STEEL/false/0 */}
                  {cellValues.row?.flag_reference ? (
                    <FlagIcon
                      titleAccess="Flag"
                      sx={{ cursor: "pointer", color: "green" }}
                      onClick={() => window.open(cellValues.row?.flag_reference, "_blank", " noopener")}
                    />
                  ) : (
                    <FlagOutlinedIcon
                      titleAccess="Flag"
                      sx={{ color: "primary.main" }}
                    />
                  )}
                  {!cellValues.row.mapped_status && useraction &&   useraction?.includes("U") && (
                    <img
                      src={move}
                      title="Move"
                      style={{ height: "1.3rem", cursor: "pointer" }}
                      onClick={() => bopMoveModal.open(cellValues.row)}
                    />
                  )}
                  {useraction &&   useraction?.includes("U") && <EditIcon
                    titleAccess="Edit"
                    sx={{ cursor: "pointer" }}
                    onClick={() => editSubRevision(cellValues.row)}
                  />}
                </Box>
              );
            },
          },
          ...ConnectorsColumns,
        ];
      case "Bearings":
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: width > 1370 ? 200 : width < 1200 ? 205 : 200,
            renderCell: (cellValues: any) => {
              return (
                <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
                  {cellValues.row.mapped_status ? (
                    <InfoIcon
                      titleAccess="Info"
                      sx={{ cursor: "pointer" }}
                      onClick={() => infoRawMaterialModal.open(cellValues.row.id)}
                    />
                  ) : (
                    useraction &&   useraction?.includes("D") && <DeleteIcon
                      titleAccess="Delete"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        swal({
                          title: `Delete material`,
                          text: `Are you sure you want to delete ?`,
                          icon: "error",
                          buttons: ["Cancel", "Yes, Delete"],
                        }).then((confirm) => {
                          if (confirm) {
                            API.delete(`db/bought_out_cost/${cellValues.row.id}/`, {}, 0)
                              .then((res: any) => {
                                getMaterialData(categoryName);
                              })
                              .catch((err: any) => { });
                          }
                        });
                      }}
                    />
                  )}
                     {
                    cellValues?.row?.lock_status ? <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'green' }} onClick={() => LockHandler(cellValues?.row, false)}>
                      <LockIcon />
                    </IconButton> : <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'primary.main' }} onClick={() => LockHandler(cellValues?.row, true)}>
                      <LockOpenIcon sx={{color:'red'}}/>
                    </IconButton>
                  }
                  <EqualizerIcon
                    titleAccess="Graph"
                    sx={{ cursor: "pointer" }}
                    onClick={() => graphModal.open(cellValues.row)}
                  />

                  <LaunchIcon
                    titleAccess="Redirect"
                    sx={{ cursor: "pointer" }}
                    onClick={() => history.push(`/data-base/revision-database/${tab}/${year}/${month}/1/1/bopDB/${categoryName}/${status}/${search}`)}
                  />
                  {/* http://localhost:3000/#/data-base/revision-database/1/1/rmDB/STEEL/false/0 */}
                  {cellValues.row?.flag_reference ? (
                    <FlagIcon
                      titleAccess="Flag"
                      sx={{ cursor: "pointer", color: "green" }}
                      onClick={() => window.open(cellValues.row?.flag_reference, "_blank", " noopener")}
                    />
                  ) : (
                    <FlagOutlinedIcon
                      titleAccess="Flag"
                      sx={{ color: "primary.main" }}
                    />
                  )}
                  {!cellValues.row.mapped_status && useraction &&   useraction?.includes("U") && (
                    <img
                      src={move}
                      title="Move"
                      style={{ height: "1.3rem", cursor: "pointer" }}
                      onClick={() => bopMoveModal.open(cellValues.row)}
                    />
                  )}
                  {useraction &&   useraction?.includes("U") && <EditIcon
                    titleAccess="Edit"
                    sx={{ cursor: "pointer" }}
                    onClick={() => editSubRevision(cellValues.row)}
                  />}
                </Box>
              );
            },
          },
          ...BearingsColumns,
        ];
      case "Consumables":
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: width > 1370 ? 200 : width < 1200 ? 205 : 200,
            renderCell: (cellValues: any) => {
              return (
                <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
                  {cellValues.row.mapped_status ? (
                    <InfoIcon
                      titleAccess="Info"
                      sx={{ cursor: "pointer" }}
                      onClick={() => infoRawMaterialModal.open(cellValues.row.id)}
                    />
                  ) : (
                    useraction &&   useraction?.includes("D") && <DeleteIcon
                      titleAccess="Delete"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        swal({
                          title: `Delete material`,
                          text: `Are you sure you want to delete ?`,
                          icon: "error",
                          buttons: ["Cancel", "Yes, Delete"],
                        }).then((confirm) => {
                          if (confirm) {
                            API.delete(`db/bought_out_cost/${cellValues.row.id}/`, {}, 0)
                              .then((res: any) => {
                                getMaterialData(categoryName);
                              })
                              .catch((err: any) => { });
                          }
                        });
                      }}
                    />
                  )}
                     {
                    cellValues?.row?.lock_status ? <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'green' }} onClick={() => LockHandler(cellValues?.row, false)}>
                      <LockIcon />
                    </IconButton> : <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'primary.main' }} onClick={() => LockHandler(cellValues?.row, true)}>
                      <LockOpenIcon sx={{color:'red'}}/>
                    </IconButton>
                  }
                  <EqualizerIcon
                    titleAccess="Graph"
                    sx={{ cursor: "pointer" }}
                    onClick={() => graphModal.open(cellValues.row)}
                  />

                  <LaunchIcon
                    titleAccess="Redirect"
                    sx={{ cursor: "pointer" }}
                    onClick={() => history.push(`/data-base/revision-database/${tab}/${year}/${month}/1/1/bopDB/${categoryName}/${status}/${search}`)}
                  />
                  {/* http://localhost:3000/#/data-base/revision-database/1/1/rmDB/STEEL/false/0 */}
                  {cellValues.row?.flag_reference ? (
                    <FlagIcon
                      titleAccess="Flag"
                      sx={{ cursor: "pointer", color: "green" }}
                      onClick={() => window.open(cellValues.row?.flag_reference, "_blank", " noopener")}
                    />
                  ) : (
                    <FlagOutlinedIcon
                      titleAccess="Flag"
                      sx={{ color: "primary.main" }}
                    />
                  )}
                  {!cellValues.row.mapped_status && useraction &&   useraction?.includes("U") && (
                    <img
                      src={move}
                      title="Move"
                      style={{ height: "1.3rem", cursor: "pointer" }}
                      onClick={() => bopMoveModal.open(cellValues.row)}
                    />
                  )}
                  {useraction &&   useraction?.includes("U") && <EditIcon
                    titleAccess="Edit"
                    sx={{ cursor: "pointer" }}
                    onClick={() => editSubRevision(cellValues.row)}
                  />}
                </Box>
              );
            },
          },
          ...ConsumablesColumns,
        ];
      case "Electronic Child Components":
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: width > 1370 ? 200 : width < 1200 ? 205 : 200,
            renderCell: (cellValues: any) => {
              return (
                <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
                  {cellValues.row.mapped_status ? (
                    <InfoIcon
                      titleAccess="Info"
                      sx={{ cursor: "pointer" }}
                      onClick={() => infoRawMaterialModal.open(cellValues.row.id)}
                    />
                  ) : (
                    useraction &&   useraction?.includes("D") && <DeleteIcon
                      titleAccess="Delete"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        swal({
                          title: `Delete material`,
                          text: `Are you sure you want to delete ?`,
                          icon: "error",
                          buttons: ["Cancel", "Yes, Delete"],
                        }).then((confirm) => {
                          if (confirm) {
                            API.delete(`db/bought_out_cost/${cellValues.row.id}/`, {}, 0)
                              .then((res: any) => {
                                getMaterialData(categoryName);
                              })
                              .catch((err: any) => { });
                          }
                        });
                      }}
                    />
                  )}
                     {
                    cellValues?.row?.lock_status ? <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'green' }} onClick={() => LockHandler(cellValues?.row, false)}>
                      <LockIcon />
                    </IconButton> : <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'primary.main' }} onClick={() => LockHandler(cellValues?.row, true)}>
                      <LockOpenIcon sx={{color:'red'}} />
                    </IconButton>
                  }
                  <EqualizerIcon
                    titleAccess="Graph"
                    sx={{ cursor: "pointer" }}
                    onClick={() => graphModal.open(cellValues.row)}
                  />

                  <LaunchIcon
                    titleAccess="Redirect"
                    sx={{ cursor: "pointer" }}
                    onClick={() => history.push(`/data-base/revision-database/${tab}/${year}/${month}/1/1/bopDB/${categoryName}/${status}/${search}`)}
                  />
                  {/* http://localhost:3000/#/data-base/revision-database/1/1/rmDB/STEEL/false/0 */}
                  {cellValues.row?.flag_reference ? (
                    <FlagIcon
                      titleAccess="Flag"
                      sx={{ cursor: "pointer", color: "green" }}
                      onClick={() => window.open(cellValues.row?.flag_reference, "_blank", " noopener")}
                    />
                  ) : (
                    <FlagOutlinedIcon
                      titleAccess="Flag"
                      sx={{ color: "primary.main" }}
                    />
                  )}
                  {!cellValues.row.mapped_status && useraction &&   useraction?.includes("U") && (
                    <img
                      src={move}
                      title="Move"
                      style={{ height: "1.3rem", cursor: "pointer" }}
                      onClick={() => bopMoveModal.open(cellValues.row)}
                    />
                  )}
                  {useraction &&   useraction?.includes("U") && <EditIcon
                    titleAccess="Edit"
                    sx={{ cursor: "pointer" }}
                    onClick={() => editSubRevision(cellValues.row)}
                  />}
                </Box>
              );
            },
          },
          ...ElectronicChildColumns,
        ];
      case "Mechanical Proprietory Parts":
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: width > 1370 ? 200 : width < 1200 ? 205 : 200,
            renderCell: (cellValues: any) => {
              return (
                <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
                  {cellValues.row.mapped_status ? (
                    <InfoIcon
                      titleAccess="Info"
                      sx={{ cursor: "pointer" }}
                      onClick={() => infoRawMaterialModal.open(cellValues.row.id)}
                    />
                  ) : (
                    useraction &&   useraction?.includes("D") && <DeleteIcon
                      titleAccess="Delete"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        swal({
                          title: `Delete material`,
                          text: `Are you sure you want to delete ?`,
                          icon: "error",
                          buttons: ["Cancel", "Yes, Delete"],
                        }).then((confirm) => {
                          if (confirm) {
                            API.delete(`db/bought_out_cost/${cellValues.row.id}/`, {}, 0)
                              .then((res: any) => {
                                getMaterialData(categoryName);
                              })
                              .catch((err: any) => { });
                          }
                        });
                      }}
                    />
                  )}
                     {
                    cellValues?.row?.lock_status ? <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'green' }} onClick={() => LockHandler(cellValues?.row, false)}>
                      <LockIcon />
                    </IconButton> : <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'primary.main' }} onClick={() => LockHandler(cellValues?.row, true)}>
                      <LockOpenIcon sx={{color:'red'}} />
                    </IconButton>
                  }
                  <EqualizerIcon
                    titleAccess="Graph"
                    sx={{ cursor: "pointer" }}
                    onClick={() => graphModal.open(cellValues.row)}
                  />

                  <LaunchIcon
                    titleAccess="Redirect"
                    sx={{ cursor: "pointer" }}
                    onClick={() => history.push(`/data-base/revision-database/${tab}/${year}/${month}/1/1/bopDB/${categoryName}/${status}/${search}`)}
                  />
                  {/* http://localhost:3000/#/data-base/revision-database/1/1/rmDB/STEEL/false/0 */}
                  {cellValues.row?.flag_reference ? (
                    <FlagIcon
                      titleAccess="Flag"
                      sx={{ cursor: "pointer", color: "green" }}
                      onClick={() => window.open(cellValues.row?.flag_reference, "_blank", " noopener")}
                    />
                  ) : (
                    <FlagOutlinedIcon
                      titleAccess="Flag"
                      sx={{ color: "primary.main" }}
                    />
                  )}
                  {!cellValues.row.mapped_status && useraction &&   useraction?.includes("U") && (
                    <img
                      src={move}
                      title="Move"
                      style={{ height: "1.3rem", cursor: "pointer" }}
                      onClick={() => bopMoveModal.open(cellValues.row)}
                    />
                  )}
                  {useraction &&   useraction?.includes("U") && <EditIcon
                    titleAccess="Edit"
                    sx={{ cursor: "pointer" }}
                    onClick={() => editSubRevision(cellValues.row)}
                  />}
                </Box>
              );
            },
          },
          ...MEPartsColumns,
        ];
      case "Fasteners":
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: width > 1370 ? 200 : width < 1200 ? 205 : 200,
            renderCell: (cellValues: any) => {
              return (
                <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
                  {cellValues.row.mapped_status ? (
                    <InfoIcon
                      titleAccess="Info"
                      sx={{ cursor: "pointer" }}
                      onClick={() => infoRawMaterialModal.open(cellValues.row.id)}
                    />
                  ) : (
                    useraction &&   useraction?.includes("D") && <DeleteIcon
                      titleAccess="Delete"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        swal({
                          title: `Delete material`,
                          text: `Are you sure you want to delete ?`,
                          icon: "error",
                          buttons: ["Cancel", "Yes, Delete"],
                        }).then((confirm) => {
                          if (confirm) {
                            API.delete(`db/bought_out_cost/${cellValues.row.id}/`, {}, 0)
                              .then((res: any) => {
                                getMaterialData(categoryName);
                              })
                              .catch((err: any) => { });
                          }
                        });
                      }}
                    />
                  )}
                     {
                    cellValues?.row?.lock_status ? <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'green' }} onClick={() => LockHandler(cellValues?.row, false)}>
                      <LockIcon />
                    </IconButton> : <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'primary.main' }} onClick={() => LockHandler(cellValues?.row, true)}>
                      <LockOpenIcon sx={{color:'red'}} />
                    </IconButton>
                  }
                  <EqualizerIcon
                    titleAccess="Graph"
                    sx={{ cursor: "pointer" }}
                    onClick={() => graphModal.open(cellValues.row)}
                  />

                  <LaunchIcon
                    titleAccess="Redirect"
                    sx={{ cursor: "pointer" }}
                    onClick={() => history.push(`/data-base/revision-database/${tab}/${year}/${month}/1/1/bopDB/${categoryName}/${status}/${search}`)}
                  />

                  {cellValues.row?.flag_reference ? (
                    <FlagIcon
                      titleAccess="Flag"
                      sx={{ cursor: "pointer", color: "green" }}
                      onClick={() => window.open(cellValues.row?.flag_reference, "_blank", " noopener")}
                    />
                  ) : (
                    <FlagOutlinedIcon
                      titleAccess="Flag"
                      sx={{ color: "primary.main" }}
                    />
                  )}
                  {!cellValues.row.mapped_status && useraction &&   useraction?.includes("U") && (
                    <img
                      src={move}
                      title="Move"
                      style={{ height: "1.3rem", cursor: "pointer" }}
                      onClick={() => bopMoveModal.open(cellValues.row)}
                    />
                  )}
                  {useraction &&   useraction?.includes("U") && <EditIcon
                    titleAccess="Edit"
                    sx={{ cursor: "pointer" }}
                    onClick={() => editSubRevision(cellValues.row)}
                  />}
                </Box>
              );
            },
          },
          ...FastenersCols,
        ];
      case "Uncategorized":
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: width > 1370 ? 200 : width < 1200 ? 205 : 200,
            renderCell: (cellValues: any) => {
              return (
                <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
                  {cellValues.row.mapped_status ? (
                    <InfoIcon
                      titleAccess="Info"
                      sx={{ cursor: "pointer" }}
                      onClick={() => infoRawMaterialModal.open(cellValues.row.id)}
                    />
                  ) : (
                    useraction &&   useraction?.includes("D") && <DeleteIcon
                      titleAccess="Delete"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        swal({
                          title: `Delete material`,
                          text: `Are you sure you want to delete ?`,
                          icon: "error",
                          buttons: ["Cancel", "Yes, Delete"],
                        }).then((confirm) => {
                          if (confirm) {
                            API.delete(`db/bought_out_cost/${cellValues.row.id}/`, {}, 0)
                              .then((res: any) => {
                                getMaterialData(categoryName);
                              })
                              .catch((err: any) => { });
                          }
                        });
                      }}
                    />
                  )}
                     {
                    cellValues?.row?.lock_status ? <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'green' }} onClick={() => LockHandler(cellValues?.row, false)}>
                      <LockIcon />
                    </IconButton> : <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'primary.main' }} onClick={() => LockHandler(cellValues?.row, true)}>
                      <LockOpenIcon sx={{color:'red'}}/>
                    </IconButton>
                  }
                  <EqualizerIcon
                    titleAccess="Graph"
                    sx={{ cursor: "pointer" }}
                    onClick={() => graphModal.open(cellValues.row)}
                  />

                  <LaunchIcon
                    titleAccess="Redirect"
                    sx={{ cursor: "pointer" }}
                    onClick={() => history.push(`/data-base/revision-database/${tab}/${year}/${month}/1/1/bopDB/${categoryName}/${status}/${search}`)}
                  />
                  {/* http://localhost:3000/#/data-base/revision-database/1/1/rmDB/STEEL/false/0 */}
                  {cellValues.row?.flag_reference ? (
                    <FlagIcon
                      titleAccess="Flag"
                      sx={{ cursor: "pointer", color: "green" }}
                      onClick={() => window.open(cellValues.row?.flag_reference, "_blank", " noopener")}
                    />
                  ) : (
                    <FlagOutlinedIcon
                      titleAccess="Flag"
                      sx={{ color: "primary.main" }}
                    />
                  )}
                  {!cellValues.row.mapped_status && useraction &&   useraction?.includes("U") && (
                    <img
                      src={move}
                      title="Move"
                      style={{ height: "1.3rem", cursor: "pointer" }}
                      onClick={() => bopMoveModal.open(cellValues.row)}
                    />
                  )}
                  {useraction &&   useraction?.includes("U") && <EditIcon
                    titleAccess="Edit"
                    sx={{ cursor: "pointer" }}
                    onClick={() => editSubRevision(cellValues.row)}
                  />}
                </Box>
              );
            },
          },
          ...OthersColumns,
        ];
      default:
        return [];
    }
  };
  const getRowDataElectrical = (category: any) => {
    switch (category) {
      case "Bulb":
        let rowsBulb: any = [];
        let connectors: any =
          electricalTableData &&
          electricalTableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              type: item?.type,
              rated_voltage: item?.rated_voltage,
              base_code: item?.base_code,
              no_of_filament: item?.no_of_filament,
              watttage: item?.watttage,
              reference: item?.reference,
              unit_cost: `₹ ${item?.unit_cost}`,
              commodity_name: item?.commodity_name,
              module_type: item?.module_type,
              mapped_status: item?.mapped_status,
              purchase_item: item?.purchase_item,
              flag_reference: item?.flag_reference,
            };
            rowsBulb.push(objShared);
          });

        let dataStructureDataGridBulb: any = {
          columns: getColumnDataElectrical(selectedModule) && getColumnDataElectrical(selectedModule),
          rows: rowsBulb && rowsBulb,
        };
        return dataStructureDataGridBulb && dataStructureDataGridBulb;

      case "Remote Key/Lock":
        let rowsRemoteKey: any = [];
        let remoteKey: any =
          electricalTableData &&
          electricalTableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              type: item?.type,
              rated_voltage: item?.rated_voltage,
              base_code: item?.base_code,
              no_of_filament: item?.no_of_filament,
              watttage: item?.watttage,
              reference: item?.reference,
              unit_cost: `₹ ${item?.unit_cost}`,
              commodity_name: item?.commodity_name,
              module_type: item?.module_type,
              mapped_status: item?.mapped_status,
              purchase_item: item?.purchase_item,
              flag_reference: item?.flag_reference,
            };
            rowsRemoteKey.push(objShared);
          });

        let dataStructureDataGridRemoteKey: any = {
          columns: getColumnDataElectrical(selectedModule) && getColumnDataElectrical(selectedModule),
          rows: rowsRemoteKey && rowsRemoteKey,
        };
        return dataStructureDataGridRemoteKey && dataStructureDataGridRemoteKey;

      case "Horn":
        let rowsHorn: any = [];
        let horn: any =
          electricalTableData &&
          electricalTableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              type: item?.type,
              rated_voltage: item?.rated_voltage,
              base_code: item?.base_code,
              no_of_filament: item?.no_of_filament,
              watttage: item?.watttage,
              reference: item?.reference,
              unit_cost: `₹ ${item?.unit_cost}`,
              commodity_name: item?.commodity_name,
              module_type: item?.module_type,
              mapped_status: item?.mapped_status,
              purchase_item: item?.purchase_item,
              flag_reference: item?.flag_reference,
            };
            rowsHorn.push(objShared);
          });

        let dataStructureDataGridHorn: any = {
          columns: getColumnDataElectrical(selectedModule) && getColumnDataElectrical(selectedModule),
          rows: rowsHorn && rowsHorn,
        };
        return dataStructureDataGridHorn && dataStructureDataGridHorn;

      case "Ignition":
        let rowsIgnition: any = [];
        let Ignition: any =
          electricalTableData &&
          electricalTableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              type: item?.type,
              rated_voltage: item?.rated_voltage,
              base_code: item?.base_code,
              no_of_filament: item?.no_of_filament,
              watttage: item?.watttage,
              reference: item?.reference,
              unit_cost: `₹ ${item?.unit_cost}`,
              commodity_name: item?.commodity_name,
              module_type: item?.module_type,
              mapped_status: item?.mapped_status,
              purchase_item: item?.purchase_item,
              flag_reference: item?.flag_reference,
            };
            rowsIgnition.push(objShared);
          });

        let dataStructureDataGridIgnition: any = {
          columns: getColumnDataElectrical(selectedModule) && getColumnDataElectrical(selectedModule),
          rows: rowsIgnition && rowsIgnition,
        };
        return dataStructureDataGridIgnition && dataStructureDataGridIgnition;

      case "Flasher":
        let rowsFlasher: any = [];
        let Flasher: any =
          electricalTableData &&
          electricalTableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              type: item?.type,
              rated_voltage: item?.rated_voltage,
              base_code: item?.base_code,
              no_of_filament: item?.no_of_filament,
              watttage: item?.watttage,
              reference: item?.reference,
              unit_cost: `₹ ${item?.unit_cost}`,
              commodity_name: item?.commodity_name,
              module_type: item?.module_type,
              mapped_status: item?.mapped_status,
              purchase_item: item?.purchase_item,
              flag_reference: item?.flag_reference,
            };
            rowsFlasher.push(objShared);
          });

        let dataStructureDataGridFlasher: any = {
          columns: getColumnDataElectrical(selectedModule) && getColumnDataElectrical(selectedModule),
          rows: rowsFlasher && rowsFlasher,
        };
        return dataStructureDataGridFlasher && dataStructureDataGridFlasher;
    }
  };

  const getRowData = (category: any) => {
    switch (category) {
      case "Connectors":
        let rowsConnectors: any = [];
        let connectors: any =
          tableData &&
          tableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              no_of_pins: item?.no_of_pins,
              postion_config: item?.postion_config,
              fit_type: item?.fit_type,
              pin_pitch: item?.pin_pitch,
              shape: item?.shape,
              connection_type: item?.connection_type,
              gender: item?.gender,
              material: item?.material,
              reference: item?.reference,
              unit_cost: `₹ ${item?.unit_cost}`,
              commodity_name: item?.commodity_name,
              mapped_status: item?.mapped_status,
              purchase_item: item?.purchase_item,
              flag_reference: item?.flag_reference,
              lock_status
              : item?.lock_status,

            locked_on
              :
              item?.locked_on,
            locked_user
              :
              item?.locked_user,
            locked_user_email
              :
              item?.locked_user_email,
            locked_user_name
              :
              item?.locked_user_name,
            locked_user_phone
              :
              item?.locked_user_phone,
            };
            rowsConnectors.push(objShared);
          });

        let dataStructureDataGridConnectors: any = {
          columns: getColumnData(selectedTab) && getColumnData(selectedTab),
          rows: rowsConnectors && rowsConnectors,
        };
        return dataStructureDataGridConnectors && dataStructureDataGridConnectors;

      case "Bearings":
        let rowsBearings: any = [];
        let dataGridDataGeneratorBearings: any =
          tableData &&
          tableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              code: item?.code,
              height: item?.height,
              weight: item?.weight,
              item_id: item?.item_id,
              item_od: item?.item_od,
              bearing_code: item?.bearing_code,
              reference: item?.reference,
              unit_cost: `₹ ${item?.unit_cost}`,
              commodity_name: item?.commodity_name,
              mapped_status: item?.mapped_status,
              purchase_item: item?.purchase_item,
              flag_reference: item?.flag_reference,
              lock_status
              : item?.lock_status,

            locked_on
              :
              item?.locked_on,
            locked_user
              :
              item?.locked_user,
            locked_user_email
              :
              item?.locked_user_email,
            locked_user_name
              :
              item?.locked_user_name,
            locked_user_phone
              :
              item?.locked_user_phone,
            };
            rowsBearings.push(objShared);
          });

        let dataStructureDataGridBearings: any = {
          columns: getColumnData(selectedTab) && getColumnData(selectedTab),
          rows: rowsBearings && rowsBearings,
        };
        return dataStructureDataGridBearings && dataStructureDataGridBearings;

      case "Consumables":
        let rowsConsumables: any = [];
        let dataGridDataGeneratorConsumables: any =
          tableData &&
          tableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              code: item?.code,
              description: item?.description,
              unit: item?.unit,
              reference: item?.reference,
              unit_cost: `₹ ${item?.unit_cost}`,
              commodity_name: item?.commodity_name,
              mapped_status: item?.mapped_status,
              purchase_item: item?.purchase_item,
              flag_reference: item?.flag_reference,
              lock_status
              : item?.lock_status,

            locked_on
              :
              item?.locked_on,
            locked_user
              :
              item?.locked_user,
            locked_user_email
              :
              item?.locked_user_email,
            locked_user_name
              :
              item?.locked_user_name,
            locked_user_phone
              :
              item?.locked_user_phone,
            };
            rowsConsumables.push(objShared);
          });

        let dataStructureDataGridConsumables: any = {
          columns: getColumnData(selectedTab) && getColumnData(selectedTab),
          rows: rowsConsumables && rowsConsumables,
        };
        return dataStructureDataGridConsumables && dataStructureDataGridConsumables;

      case "Electronic Child Components":
        let rowsElectronicsChild: any = [];
        let dataGridDataGeneratorElectricChild: any =
          tableData &&
          tableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              length: item?.length,
              description: item?.description,
              width: item?.width,
              unit: item?.unit,
              component_type: item?.component_type,
              mapped_status: item?.mapped_status,
              reference: item?.reference,
              unit_cost: `₹ ${item?.unit_cost}`,
              commodity_name: item?.commodity_name,
              purchase_item: item?.purchase_item,
              flag_reference: item?.flag_reference,
              lock_status
              : item?.lock_status,

            locked_on
              :
              item?.locked_on,
            locked_user
              :
              item?.locked_user,
            locked_user_email
              :
              item?.locked_user_email,
            locked_user_name
              :
              item?.locked_user_name,
            locked_user_phone
              :
              item?.locked_user_phone,
            };
            rowsElectronicsChild.push(objShared);
          });

        let dataStructureDataGridElectricalChild: any = {
          columns: getColumnData(selectedTab) && getColumnData(selectedTab),
          rows: rowsElectronicsChild && rowsElectronicsChild,
        };
        return dataStructureDataGridElectricalChild && dataStructureDataGridElectricalChild;

      case "Mechanical Proprietory Parts":
        let rowsMEParts: any = [];
        let dataGridDataGeneratorMEParts: any =
          tableData &&
          tableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              code: item?.code,
              length: item?.length,
              description: item?.description,
              width: item?.width,
              height: item?.height,
              weight: item?.weight,
              brand_name: item?.brand_name,
              mapped_status: item?.mapped_status,
              reference: item?.reference,
              unit_cost: `₹ ${item?.unit_cost}`,
              commodity_name: item?.commodity_name,
              purchase_item: item?.purchase_item,
              flag_reference: item?.flag_reference,
              lock_status
              : item?.lock_status,

            locked_on
              :
              item?.locked_on,
            locked_user
              :
              item?.locked_user,
            locked_user_email
              :
              item?.locked_user_email,
            locked_user_name
              :
              item?.locked_user_name,
            locked_user_phone
              :
              item?.locked_user_phone,
            };
            rowsMEParts.push(objShared);
          });

        let dataStructureDataGridMEParts: any = {
          columns: getColumnData(selectedTab) && getColumnData(selectedTab),
          rows: rowsMEParts && rowsMEParts,
        };
        return dataStructureDataGridMEParts && dataStructureDataGridMEParts;
      case "Uncategorized":
        let rowsOthers: any = [];
        let dataGridDataGeneratorOthers: any =
          tableData &&
          tableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              code: item?.code,
              length: item?.length,
              item_detail: item?.description,
              width: item?.width,
              height: item?.height,
              weight: item?.weight,
              commodity_name: item?.commodity_name,
              mapped_status: item?.mapped_status,
              reference: item?.reference,
              unit_cost: `₹ ${item?.unit_cost}`,
              place: item.place,
              purchase_item: item?.purchase_item,
              flag_reference: item?.flag_reference,
              lock_status
              : item?.lock_status,

            locked_on
              :
              item?.locked_on,
            locked_user
              :
              item?.locked_user,
            locked_user_email
              :
              item?.locked_user_email,
            locked_user_name
              :
              item?.locked_user_name,
            locked_user_phone
              :
              item?.locked_user_phone,
            };
            rowsOthers.push(objShared);
          });

        let dataStructureDataGridOthers: any = {
          columns: getColumnData(selectedTab) && getColumnData(selectedTab),
          rows: rowsOthers && rowsOthers,
        };
        return dataStructureDataGridOthers && dataStructureDataGridOthers;

      case "Fasteners":
        let rowsFasteners: any = [];
        let dataGridDataGeneratorFasteners: any =
          tableData &&
          tableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              code: item?.code,
              type: item?.type,
              grade: item?.grade,
              material: item?.material,
              m_size: item?.m_size,
              length: item?.length,
              thread_length: item?.thread_length,
              weight: item?.weight,
              commodity_name: item?.commodity_name,
              mapped_status: item?.mapped_status,
              reference: item?.reference,
              unit_cost: `₹ ${item?.unit_cost}`,
              place: item.place,
              purchase_item: item?.purchase_item,
              flag_reference: item?.flag_reference,
              lock_status
              : item?.lock_status,

            locked_on
              :
              item?.locked_on,
            locked_user
              :
              item?.locked_user,
            locked_user_email
              :
              item?.locked_user_email,
            locked_user_name
              :
              item?.locked_user_name,
            locked_user_phone
              :
              item?.locked_user_phone,
            };
            rowsFasteners.push(objShared);
          });

        let dataStructureDataGridFasteners: any = {
          columns: getColumnData(selectedTab) && getColumnData(selectedTab),
          rows: rowsFasteners && rowsFasteners,
        };
        return dataStructureDataGridFasteners && dataStructureDataGridFasteners;

      default:
        return [];
    }
  };

  return (
    <div
      style={{ width: "100%", height: "90vh" }}
      adl-scrollbar="true"
      adl-scrollbar-width="0.3">
      {accordionLoading ? (
        <Box sx={{ padding: "0 1rem", width: "100%" }}>
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />

          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />

          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />

          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
        </Box>
      ) : (
        <>
        <Box sx={{
							display: 'flex',
							alignItems: 'center',
							columnGap: '0.5rem',
							width: '100%',
							borderBottom: '1px solid',
						}} adl-scrollbar='true'
						adl-scrollbar-width='0.2'>
          {categoryList &&
            categoryList?.map((item: any, index: any) => {
              return (
                <>
                 <Box
										key={index}
											sx={{
												color: 'primary.main',
												height: '2rem',
												minHeight: '2.1rem',
												maxWidth: '100% !important',
												padding: '0',
												fontSize: '1rem',
												width: '100%',
												backgroundColor:
													item?.category == selectedTab
														? 'primary.main'
														: 'primary.light',
												cursor: 'pointer',
												display: 'flex',
												alignItems: 'center',
											}}>
											<Typography
												sx={{
													color:
														item?.category == selectedTab ? 'white' : 'primary.main',
													height: '2rem',
													minHeight: '2.1rem',
													maxWidth: '100% !important',
													padding: '0 0.5rem',
													fontSize: '1rem',
													width: '100%',
													textAlign: 'center',
													display: 'flex',
													whiteSpace:'nowrap',
													alignItems: 'center',
													justifyContent: 'center',
													textTransform: 'capitalize',
												}}
												onClick={() => handleChange(item?.category)}>
												{item?.category.toLowerCase()} ({item?.count})
											</Typography>
										</Box>               
                </>
              );
            })}
          </Box>
          <Box sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: 'flex-end',
						marginLeft: "0.5rem",
						columnGap: "0.5rem",
            width: '100%',
					}}>
          {useraction &&   useraction?.includes("C") && (
            <Box sx={{ width: "9rem", textAlign: "right", paddingRight: "1rem" }}>
              <Button
                size="small"
                startIcon={<AddIcon sx={{ marginRight: "-0.5rem" }} />}
                sx={{ whiteSpace: "nowrap" }}
                onClick={() => fetchRawMaterial.open()}>
                Add BOP
              </Button>
            </Box>
          )}
          </Box>
          {tableData && selectedTab && categoryName !== "Electrical & Electronics Proprietary Parts" && getColumnData(selectedTab)?.length > 0 ? (<Box
                      sx={{
                        height: selectedTab !== "Electrical & Electronics Proprietary Parts" ? {lg:'78vh',xl:'83vh'} : "100%",
                        width: "100%",
                        "& .super-app-theme--cell": {
                          backgroundColor: "#ddefff !important",
                          textAlign: "center !important",
                        },
                      }}>                      
                        <DataGrid
                          headerHeight={42}
                          rowHeight={36}
                          {...getRowData(selectedTab)}
                          loading={tableDataLoading}
                          // checkboxSelection
                          // disableRowSelectionOnClick
                          components={{
                            Toolbar: () => {
                              return CustomToolbar();
                            },
                          }}
                          sx={{
                            "& ::-webkit-scrollbar": {
                              width: "0.3rem",
                              height: "0.3rem",
                            },
                            "& ::-webkit-scrollbar-thumb": {
                              // background: $thumb-color;
                              backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                              borderRadius: "10px",
                            },
                            "& ::- webkit-scrollbar-thumb: hover ": {
                              // width: "4px",
                              backgroundColor: "#045DE9 !important",
                            },
                            "::-webkit-scrollbar-track": {
                              backgroundColor: "#e1e1f3 !important",
                            },

                            "&.MuiDataGrid-root": {
                              border: "none",
                            },

                            "&.MuiDataGrid-root .MuiDataGrid-cell": {
                              borderBottomColor: "primary.light",
                            },
                          }}
                        />                     
                    </Box>
                    ) : tableData && selectedTab && categoryName == "Electrical & Electronics Proprietary Parts" && getColumnData(selectedTab)?.length > 0 ? (
                      <Box
                        sx={{
                          marginTop: '-2.1rem',
                          height: selectedTab !== "Electrical & Electronics Proprietary Parts" ? {lg:'73vh',xl:'79vh'} : " ",
                          width: "100%",
                          "& .super-app-theme--cell": {
                            backgroundColor: "#ddefff !important",
                            textAlign: "center !important",
                          },
                        }}>
                          <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            columnGap: '0.5rem',
                            width: '100%',
                            borderBottom: '1px solid',
                              }} adl-scrollbar='true'
                                adl-scrollbar-width='0.2'>
                              {electricalCategories &&
                                selectedTab &&
                                electricalCategories?.map((item: any, index: any) => {
                                  return (<>
                                    <Accordion
                                      expanded={expandedElectrical === item?.module_type}
                                      onChange={handleChangeElectrical(item?.module_type)}
                                      sx={{ marginLeft: "1rem" }}>
                                      <AccordionSummary
                                        aria-controls="panel1d-content"
                                        id="panel1d-header"
                                        sx={{ width: "100%", minHeight: "36px" }}>
                                        <Typography sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", marginLeft: "0.5rem" }}>
                                          <Typography sx={{ fontSize: "1rem" }}>{item?.module_type}</Typography>
                                          <Typography sx={{ fontSize: "1rem" }}>{item?.count} Bought-out Parts</Typography>
                                        </Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Box
                                          sx={{
                                            height: 400,
                                            width: "100%",
                                            "& .super-app-theme--cell": {
                                              backgroundColor: "#ddefff !important",
                                              textAlign: "center !important",
                                            },
                                          }}>
                                          {expandedElectrical && getColumnDataElectrical(expandedElectrical)?.length > 0 && (
                                            <DataGrid
                                              headerHeight={42}
                                              rowHeight={36}
                                              {...getRowDataElectrical(expandedElectrical)}
                                              loading={subElectricalLoadings}
                                              // checkboxSelection
                                              // disableRowSelectionOnClick
                                              components={{
                                                Toolbar: () => {
                                                  return CustomToolbar();
                                                },
                                              }}
                                              sx={{
                                                "& ::-webkit-scrollbar": {
                                                  width: "0.3rem",
                                                  height: "0.3rem",
                                                },
                                                "& ::-webkit-scrollbar-thumb": {
                                                  // background: $thumb-color;
                                                  backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                                                  borderRadius: "10px",
                                                },
                                                "& ::- webkit-scrollbar-thumb: hover ": {
                                                  // width: "4px",
                                                  backgroundColor: "#045DE9 !important",
                                                },
                                                "::-webkit-scrollbar-track": {
                                                  backgroundColor: "#e1e1f3 !important",
                                                },

                                                "&.MuiDataGrid-root": {
                                                  border: "none",
                                                },

                                                "&.MuiDataGrid-root .MuiDataGrid-cell": {
                                                  borderBottomColor: "primary.light",
                                                },
                                              }}
                                            />
                                          )}
                                        </Box>
                                      </AccordionDetails>
                                    </Accordion>
                                    </>
                                  );
                                })}
                            </Box>
                          <Box
                              sx={{
                                marginTop: '2.5rem',
                                height: {lg:'70vh',xl:'75vh'},
                                width: {lg:"76.5vw",xl:'81.5vw'},
                                "& .super-app-theme--cell": {
                                  backgroundColor: "#ddefff !important",
                                  textAlign: "center !important",
                                },
                              }}>
                                {selectedModule && getColumnDataElectrical(selectedModule)?.length > 0 && (
                                                  <DataGrid
                                                    headerHeight={42}
                                                    rowHeight={36}
                                                    {...getRowDataElectrical(selectedModule)}
                                                    loading={subElectricalLoadings}
                                                    // checkboxSelection
                                                    // disableRowSelectionOnClick
                                                    components={{
                                                      Toolbar: () => {
                                                        return CustomToolbar();
                                                      },
                                                    }}
                                                    sx={{
                                                      "& ::-webkit-scrollbar": {
                                                        width: "0.3rem",
                                                        height: "0.3rem",
                                                      },
                                                      "& ::-webkit-scrollbar-thumb": {
                                                        // background: $thumb-color;
                                                        backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                                                        borderRadius: "10px",
                                                      },
                                                      "& ::- webkit-scrollbar-thumb: hover ": {
                                                        // width: "4px",
                                                        backgroundColor: "#045DE9 !important",
                                                      },
                                                      "::-webkit-scrollbar-track": {
                                                        backgroundColor: "#e1e1f3 !important",
                                                      },

                                                      "&.MuiDataGrid-root": {
                                                        border: "none",
                                                      },

                                                      "&.MuiDataGrid-root .MuiDataGrid-cell": {
                                                        borderBottomColor: "primary.light",
                                                      },
                                                    }}
                                                  />
                                                )}
                          </Box>
                      </Box>
                    ):  <Box sx={{width:'100%',height:'92%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <Typography>Select Category</Typography>
                  </Box>}
        </>
      )}
      {subRevisionEdit.isOpen && (
        <BoughtoutEditModal
          isOpen={subRevisionEdit.isOpen}
          onCloseModal={subRevisionEdit.close}
          actions={subRevisionEdit.propsId}
          expanded={expanded}
          getMaterialData={getMaterialData}
        />
      )}
      {fetchRawMaterial.isOpen && (
        <FetchBoughtOutModal
          isOpen={fetchRawMaterial.isOpen}
          onCloseModal={fetchRawMaterial.close}
          categorydata={categoryList && categoryList}
          electricalCategories={electricalCategories}
        />
      )}
      {infoRawMaterialModal.isOpen && (
        <InfoBoughtoutrevisionModal
          isOpen={infoRawMaterialModal.isOpen}
          onCloseModal={infoRawMaterialModal.close}
          itemId={infoRawMaterialModal.propsId}
        />
      )}
      {graphModal.isOpen && (
        <BoughtoutGraph
          isOpen={graphModal.isOpen}
          onCloseModal={graphModal.close}
          rowItems={graphModal.propsId}
        />
      )}
      {bopMoveModal.isOpen && (
        <RMBOPmoveModal
          isOpen={bopMoveModal.isOpen}
          onCloseModal={bopMoveModal.close}
          actions={bopMoveModal.propsId}
          expanded={expanded}
          getMaterialData={getMaterialData}
          categoryList={categoryList}
          getCategoryData={getCategoryList}
          categoryListMaterial={categoryListMaterial}
        />
      )}
    </div>
  );
}
