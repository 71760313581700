import { useEffect, useState } from "react";
import { API } from "../../api-services";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import BomCategories from "../BOM Categories/BomCategories";

function useFetchSuppliers(page: any, search: any, vid: any, bid: any, part?: any, msp?: any, verified?: any) {
  const [loading, setLoading] = useState<boolean>(false);
  const { vehCategory, BOMCategory } = useRouteParams<any>();
  const [suppliers, setSuppliers] = useState<any>([]);
  const [suppliersCount, setSuppliersCount] = useState<any>([]);

  const arr: any = [];

  const fetchSuppliers = async (page: any, search: any, vid: any, bid: any) => {
  
  
    setLoading(true);
    try {
      const result = await API.get(`db/get_supplier_db/`, {
        product_category: vehCategory != 0 ? vid : 0,
        get_supplier: BOMCategory != 0 ? bid : 0,
        page: page,
        search_key: search,
        part: part || null,
        msp: msp || null,
        verified: verified === true ? true : verified === false ? false : null,
      });

      return result;
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchSuppliers(page, search, vid, bid).then((res: any) => {
      setSuppliers(res.data?.data);
      setSuppliersCount(res.data?.count);
    });
  

    //
  }, [page, search, vid, bid, part, msp, verified]);

  return {loading, suppliers, suppliersCount}; 
}

export default useFetchSuppliers;
