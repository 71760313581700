import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import styles from "../AddRevisionModal.module.scss";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { API } from "../../../api-services";

export interface IBulbFormProps {
  actions: any;
  onCloseModal: any;
  getMaterialData: any;
  subCategory: any;
}
const validationSchema = yup.object({
  name: yup.string().required("Please type Item name"),
  type: yup.string().required("Please select type"),
  rated_voltage: yup.string(),
  base_code: yup.mixed().required("Please type Base Code"),
  no_of_filament: yup.mixed().required("Please type No. of filaments"),
  watttage: yup.mixed(),
  reference: yup.string(),
  unit_cost: yup.mixed().required("Please enter per unit cost"),
});
const useStyles = makeStyles((theme) => ({
  select: {
    "&.MuiInputBase-root-MuiInput-root-MuiSelect-root": {
      borderBottom: "1px solid red",
    },
  },
}));
export function BulbForm(props: IBulbFormProps) {
  const { actions, onCloseModal, getMaterialData, subCategory } = props;
  const [isLoadingsave, setIsLoadingSave] = React.useState<any>(false);
  const classes = useStyles();
  const { categoryName, databaseName, revisionId } = useRouteParams<any>();
  const formik = useFormik({
    initialValues: {
      name: "",
      type: "",
      rated_voltage: "",
      base_code: "",
      no_of_filament: "",
      watttage: "",
      reference: "",
      unit_cost: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsLoadingSave(true);
      if (actions === "add") {
        API.post(
          "/db/bought_out/",
          {
            ...values,
            ...{
              organisation: 1,
              commodity_category_instance: subCategory,
              module_type: "Bulb",
            },
          },
          {},
          0
        )
          .then((res: any) => {
            if (!databaseName?.includes("DB")) {
              API.post(
                "/db/bought_out_cost/",
                {
                  purchase_items: [res.data.id],
                  revision: revisionId,
                },
                {},
                0
              )
                .then((res: any) => {
                  setIsLoadingSave(false);
                  getMaterialData("Bulb");
                  onCloseModal();
                })
                .catch((err: any) => {
                  setIsLoadingSave(false);
                });
            } else {
              setIsLoadingSave(false);
              getMaterialData();
              onCloseModal();
            }
          })
          .catch((err: any) => {
            setIsLoadingSave(false);
          });
      } else {
        API.put(
          `/db/bought_out/${actions?.id}/`,
          {
            ...values,
            ...{
              organisation: 1,
              commodity_category_instance: actions?.commodity_name,
              module_type: actions?.module_type,
            },
          },
          {},
          0
        )
          .then((res: any) => {
            onCloseModal();
            setIsLoadingSave(false);
            getMaterialData();
          })
          .catch((err: any) => {
            setIsLoadingSave(false);
          });
      }
    },
  });
  React.useEffect(() => {
    if (actions !== "add") {
      formik.setFieldValue("name", actions?.name || "");
      formik.setFieldValue("type", actions?.type || "");
      formik.setFieldValue("rated_voltage", actions?.rated_voltage || "");
      formik.setFieldValue("base_code", actions?.base_code || "");
      formik.setFieldValue("no_of_filament", actions?.no_of_filament || "");
      formik.setFieldValue("watttage", actions?.watttage || "");
      formik.setFieldValue("reference", actions?.reference || "");
      formik.setFieldValue(
        "unit_cost",
        parseFloat(actions.unit_cost?.slice(1)) || ""
      );
    }
  }, [actions]);
  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            columnGap: "1rem",
            marginBottom: "0.5rem",
          }}
        >
          <FormControl fullWidth variant="standard">
            <TextField
              variant="standard"
              label="Item Name"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="name"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              multiline
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </FormControl>
          <FormControl fullWidth variant="standard">
            <InputLabel
              sx={{ color: "primary.main" }}
              shrink
              id="demo-simple-select-label"
            >
              Select Type{" "}
            </InputLabel>

            <Select
              id="type"
              name="type"
              className={classes.select}
              labelId="demo-select-small"
              // id="demo-select-small"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                  {
                    fontSize: "1rem",
                  },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
              error={formik.touched.type && Boolean(formik.errors.type)}
              //@ts-ignore
              helperText={formik.touched.type && formik.errors.type}
              MenuProps={{
                style: {
                  maxHeight: 400,
                  color: "007fff",
                },
              }}
              onChange={formik.handleChange}
              value={formik.values.type !== null ? formik.values.type : ""}
            >
              {/* <option value={""} style={{ display: "none" }}></option> */}
              {["LED", "Halogen", "Others"].map((item: any, index: any) => {
                return (
                  <MenuItem
                    sx={{ color: "primary.main", fontSize: "1rem" }}
                    key={index}
                    value={item}
                  >
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
            <div className={styles.errormsg}>
              {formik.touched.type && Boolean(formik.errors.type) && (
                <p>{formik.errors.type}</p>
              )}
            </div>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            columnGap: "1rem",
            marginBottom: "0.5rem",
          }}
        >
          <FormControl fullWidth variant="standard">
            <TextField
              variant="standard"
              label="Related Voltage"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="rated_voltage"
              name="rated_voltage"
              onChange={formik.handleChange}
              value={formik.values.rated_voltage}
              multiline
              error={
                formik.touched.rated_voltage &&
                Boolean(formik.errors.rated_voltage)
              }
              helperText={
                formik.touched.rated_voltage && formik.errors.rated_voltage
              }
            />
          </FormControl>
          <FormControl fullWidth variant="standard">
            <TextField
              variant="standard"
              label="Base Code"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="base_code"
              name="base_code"
              onChange={formik.handleChange}
              value={formik.values.base_code}
              multiline
              error={
                formik.touched.base_code && Boolean(formik.errors.base_code)
              }
              helperText={formik.touched.base_code && formik.errors.base_code}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            columnGap: "1rem",
            marginBottom: "0.5rem",
          }}
        >
          <FormControl fullWidth variant="standard">
            <TextField
              variant="standard"
              label="No. of filaments"
              type="number"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="no_of_filament"
              name="no_of_filament"
              onChange={formik.handleChange}
              value={formik.values.no_of_filament}
              error={
                formik.touched.no_of_filament &&
                Boolean(formik.errors.no_of_filament)
              }
              helperText={
                formik.touched.no_of_filament && formik.errors.no_of_filament
              }
            />
          </FormControl>
          <FormControl fullWidth variant="standard">
            <TextField
              variant="standard"
              label="Filament wattage"
              multiline
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="watttage"
              name="watttage"
              onChange={formik.handleChange}
              value={formik.values.watttage}
              error={formik.touched.watttage && Boolean(formik.errors.watttage)}
              helperText={formik.touched.watttage && formik.errors.watttage}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            columnGap: "1rem",
            marginBottom: "0.5rem",
          }}
        >
          <FormControl fullWidth variant="standard">
            <TextField
              variant="standard"
              label="Source"
              multiline
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="reference"
              name="reference"
              onChange={formik.handleChange}
              value={formik.values.reference}
              error={
                formik.touched.reference && Boolean(formik.errors.reference)
              }
              helperText={formik.touched.reference && formik.errors.reference}
            />
          </FormControl>
          <FormControl fullWidth variant="standard">
            <TextField
              variant="standard"
              label="Per unit cost (INR)"
              type="number"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="unit_cost"
              name="unit_cost"
              onChange={formik.handleChange}
              value={formik.values.unit_cost}
              error={
                formik.touched.unit_cost && Boolean(formik.errors.unit_cost)
              }
              helperText={formik.touched.unit_cost && formik.errors.unit_cost}
            />
          </FormControl>
        </Box>
        <Box sx={{ width: "100%", textAlign: "right" }}>
          <LoadingButton
            size="small"
            variant="contained"
            sx={{
              padding: "0.5rem",
              lineHeight: 1,
              marginTop: "1rem",
              "&:hover": {
                transform: "Scale(1.05)",
                transition: "transform 0.5s ease",
              },
              ".MuiCircularProgress-root": {
                color: "primary.main",
              },
            }}
            loading={isLoadingsave}
            type="submit"
          >
            {actions === "add" ? "Add" : "Save"}
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
}
