import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from "@mui/material";


const modalStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    maxWidth: "900px",
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 3,
};



function ClientInformationModal ({ isOpen, onCloseModal, clientInformation }:any) {
    return (
        <Modal
            open={isOpen}
            onClose={onCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={modalStyle}>
                <Typography variant="h6" component="h2" gutterBottom>
                    Client Information
                </Typography>
                <TableContainer component={Paper} sx={{ maxHeight: "70vh", overflow: 'auto' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: "#fff", backgroundColor: "#00887a" }}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Company Name</Typography>
                                </TableCell>
                                <TableCell sx={{ color: "#fff", backgroundColor: "#00887a" }}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Company Description</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {clientInformation.length > 0 ? (
                                clientInformation.map((client:any, index:any) => (
                                    <TableRow key={index}>
                                        <TableCell>{client?.company_name ?? 'N/A'}</TableCell>
                                        <TableCell>{client?.company_desc ?? 'N/A'}</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={2} align="center">
                                        <Typography variant="body2">No data available</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Modal>
    );
};

export default ClientInformationModal;