import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { API } from '../../api-services';
import CancelIcon from '@mui/icons-material/Cancel';
import { Divider, Typography } from '@mui/material';
import { fontSize, padding } from '@mui/system';
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "20%",
  minHeight: "17%",
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  borderRadius: "6px",
  boxShadow: 24,
  // pt: 2,
  // px: 4,
  // pb: 3,
};
export interface ReportsCreateModalProps {
  Open: any;
  isClose: any;
  CreateName: any;
  setCreateName: any;
  selecteData: any;
  setUsersName: any;
  ProjectIdData: any

}
const ReportsCreateModal = (props: ReportsCreateModalProps) => {
  const { Open, isClose, CreateName, setCreateName, selecteData, setUsersName, ProjectIdData } = props;


  const [error, seterror] = React.useState<any>(false)
  const handleChange = (e: any) => {
    setCreateName(e.target.value)
    seterror(false)
  }


  const submitHandler = () => {
    // selecteData

    API.patch(`/drive/group/${selecteData}/`, { name: CreateName }).then((res: any) => {

      API.get("/cost/get_drive_groups/", { group_type: "created", project: ProjectIdData }).then((res: any) => {
        // @ts-ignore

        setUsersName(res.data)
        seterror(false)
        isClose()
      })

    }).catch((err: any) => {

      seterror(true)
      console.log(err)
    })

  }



  return (
    <React.Fragment>

      <Modal
        open={Open}
        onClose={isClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, }}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Create Folder
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={isClose} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ display: "flex", alignItems: 'center', columnGap: '10px', padding: '0 1rem', width: '100%' }}>
            <input type="text"
              style={{
                borderBottom: "1px solid aliceblue",
                color: " #007fff",
                borderTop: "none",
                borderRight: "none",
                borderLeft: "none",
                width: '100%',
                fontSize:'1rem',
                paddingBottom:'5px'
              }}
              defaultValue={CreateName || ""}
              autoFocus
              onChange={(e: any) => handleChange(e)} />
            <Button variant='contained'
              size="small" color='primary'
              onClick={() => submitHandler()}
            >save</Button>
          </Box>

          {error ? <Typography style={{ color: "red",padding:'0 1rem',fontSize:'1rem' }}> Same folder name already exist</Typography> : ""}

          {/* <Button onClick={isClose}>Close Child Modal</Button> */}
        </Box>
      </Modal>
    </React.Fragment>
  );
}
export default ReportsCreateModal;