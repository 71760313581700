import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { TreeItemProps, treeItemClasses } from "@mui/lab/TreeItem";
import Collapse from "@mui/material/Collapse";
// web.cjs is required for IE11 support

import { TransitionProps } from "@mui/material/transitions";
import { animated, useSpring } from "@react-spring/web";
import openFolder from "../../../Assets/images/svgs/openfolderblue.svg";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FolderIcon from "@mui/icons-material/Folder";
import styles from "../../../ViewModule/ViewComponents.module.scss";
import { useParams, useRouteMatch } from "react-router-dom";
import { Typography } from "@mui/material";
// import MenuItem from "@mui/material/MenuItem";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import { DialogComponent } from "../../../utlis/DialogComponent";
import InfoIcon from "@mui/icons-material/Info";
import TreeInfoModal from "../TreeInfoModal";
import { useBaseParamsModal } from "../../../CustomHook/useBaseParamsModal";

function TransitionComponent(props: TransitionProps) {
  /*  */
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
      display: "none",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
      display: "block",
    },
  });

  return (
    <animated.div style={style} className="Tempdekho">
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = styled((props: TreeItemProps) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 10,
    paddingLeft: 8,
    borderLeft: `1px dashed ${theme.palette.text.primary}`,
  },
}));

interface TreeViewComponentsProps {
  HierarchyData: any;
  onSelectCallback: any;
}
const CreateEstimationTree = (props: TreeViewComponentsProps) => {
  const { HierarchyData, onSelectCallback } = props;
  const { topVault, vault } = useParams<any>();
  const infoModal = useBaseParamsModal();
  const { url } = useRouteMatch();
  const openStatusContent = useBaseModal();
  const [activeId, setActiveId] = React.useState<any>(null);
  const [contentText, setContentText] = React.useState<any>("");
  const [iconText, setIconText] = React.useState<any>("");
  const [HierarchyAllData, setHierarchyAllData] = React.useState<any>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // useEffect(()=>{
  //   sessionStorage.setItem(
  //     "nodeCostingEstimate",
  //     JSON.stringify([Number(topVault)])
  //   );

  // },[])

  const [expanded, setExpanded] = React.useState<any>([parseInt(topVault)]);
  const [titleString, setTitleString] = React.useState<any>("");
  const [selected, setSelected] = React.useState<any>(parseInt(topVault));

  React.useEffect(() => {
    let a: any = sessionStorage.getItem("nodeCostingEstimate");
    let val: any = JSON.parse(a) || [];
    if (!val?.includes(parseInt(topVault))) {

      sessionStorage.setItem(
        "nodeCostingEstimate",
        JSON.stringify([parseInt(topVault)])
      );
      sessionStorage.setItem("node", JSON.stringify([parseInt(topVault)]));
    }
  }, [topVault])

  // useEffect(()=>{return sessionStorage.setItem("node",JSON.stringify([Number(topVault)]));},[topVault])

  const handleChange = (e: any, nodeId: any, abbreviation: any, statusss: any) => {
    console.log("dskjgfkdsg", statusss)
    let a: any = sessionStorage.getItem("nodeCostingEstimate");
    let val: any = JSON.parse(a) || [];
    if (val?.includes(nodeId)) {
      let noDuplicate: any = val?.filter((item: any) => {
        return item != nodeId;
      });
      sessionStorage.setItem(
        "nodeCostingEstimate",
        JSON.stringify(noDuplicate)
      );
      sessionStorage.setItem("node", JSON.stringify(noDuplicate));
    } else {
      val?.push(nodeId);
      sessionStorage.setItem("nodeCostingEstimate", JSON.stringify(val));
      sessionStorage.setItem("node", JSON.stringify(val));
    }
    setActiveId(nodeId);
    onSelectCallback(nodeId, abbreviation, topVault, statusss);
    const index = expanded.indexOf(nodeId);

    const copyExpanded = [...expanded];
    if (index === -1) {
      copyExpanded.push(nodeId);
    } else {
      copyExpanded.splice(index, 1);
    }
    setExpanded(copyExpanded);
    setSelected(copyExpanded);
  };

  useEffect(() => {
    let a: any = sessionStorage.getItem("nodeCostingEstimate");
    let val: any = JSON.parse(a);
    if (val != null) {
      setExpanded(val);
      setSelected(val);
    }
  }, [topVault, sessionStorage.getItem("nodeCostingEstimate")]);

  useEffect(() => {
    setHierarchyAllData(HierarchyData && HierarchyData);
  }, [HierarchyData]);

  useEffect(() => {
    document.getElementById(vault)?.scrollIntoView({
      block: "nearest",
      inline: "start",
      behavior: "smooth",
    });
  }, [vault]);

  return (
    <>
      <TreeView
        expanded={expanded}
        key={HierarchyAllData?.id}
        id={HierarchyAllData?.id}
        defaultParentIcon={<FolderIcon fill="primary" />}
        className={styles.treetemp}
        defaultCollapseIcon={
          HierarchyAllData &&
          HierarchyAllData?.is_leaf == false && (
            <img src={openFolder} style={{ width: "18px", color: "#007fff" }} />
          )
        }
        defaultExpandIcon={
          HierarchyAllData?.is_leaf == false && <FolderIcon color="primary" />
        }
        defaultEndIcon={
          HierarchyAllData?.is_leaf ? (
            <InsertDriveFileIcon color="primary" />
          ) : (
            <FolderIcon color="primary" />
          )
        }
        onNodeSelect={(e: any) =>
          handleChange(e, HierarchyAllData?.id, HierarchyAllData?.abbreviation, HierarchyAllData?.status)
        }
        selected={selected}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        sx={{
          maxWidth: '30rem',
          "&.MuiTreeItem-content{}.Mui-selected": {
            backgroundColor:
              HierarchyAllData?.id == vault
                ? "#cee6fa !important"
                : "white !important",
          },
        }}
      >
        <StyledTreeItem
          data-index={HierarchyAllData?.id}
          nodeId={HierarchyAllData?.id}
          //   onMouseEnter={() =>
          //     hoverData(
          //       `${HierarchyAllData?.part_no} ${
          //         HierarchyAllData?.part_no ? "x" : ""
          //       } ${HierarchyAllData?.quantity} ${HierarchyAllData?.name}`,
          //       HierarchyAllData.id
          //     )
          //   }
          title={titleString && url.includes("costing") && titleString}
          label={
            <Typography
              className={styles.treeViewData}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                style={{
                  fontSize: "12px",
                  color: HierarchyAllData?.colour,
                  lineHeight: "1.2",
                  padding: "0.2rem 0",                  
                }}
                className={styles.treeViewText}
              // onContextMenu={(event: any) =>
              //   handleClick(
              //     event,
              //     HierarchyAllData.id,
              //     HierarchyAllData.is_leaf,
              //     HierarchyAllData
              //   )
              // }stopPropagation
              >
                {HierarchyAllData?.part_no} x {HierarchyAllData?.quantity}({" "}
                {HierarchyAllData?.total_cost} INR )
                <br /> {HierarchyAllData?.name}
              </Typography>
              {HierarchyAllData?.abbreviation !== "PRODUCT" && (
                <InfoIcon
                  sx={{ cursor: "pointer" }}
                  onClick={(event: any) => {
                    event.stopPropagation();
                    event.preventDefault();
                    infoModal.open({ idd: HierarchyAllData?.id, name: HierarchyAllData?.name, total_cost: HierarchyAllData?.total_cost });
                  }}
                />
              )}
            </Typography>
          }
        >
          {HierarchyAllData &&
            HierarchyAllData?.subsystem?.map((subsystemData: any) => {
              return (
                <>
                  <CreateEstimationTree
                    HierarchyData={subsystemData}
                    key={subsystemData?.id}
                    onSelectCallback={onSelectCallback}
                  />
                </>
              );
            })}
        </StyledTreeItem>
      </TreeView>
      <DialogComponent
        isOpen={openStatusContent.isOpen}
        onClose={openStatusContent.close}
        content={contentText}
        type={iconText}
      />
      {infoModal.isOpen && (
        <TreeInfoModal
          isOpen={infoModal.isOpen}
          onCloseModal={infoModal.close}
          item={infoModal.propsId}
        />
      )}
    </>
  );
};

export default CreateEstimationTree;
