import { useHistory, useParams, useRouteMatch } from "react-router-dom";
// import { makeStyles } from '@mui/styles';
import Typography from "@mui/material/Typography";
// import Typography from "@material-ui/core/Typography";
// import Typography from 'material-ui/styles/typography';
import React, { useEffect, useState } from "react";
import ViewComponents from "./ViewComponents";
import styles from "./MediaModule.module.scss";
import { styled } from "@mui/material/styles";
import NoImages from "../Assets/images/loading-screen.gif";
import { makeStyles } from "@mui/styles";
import { API } from "../api-services";
import BouncingLoader from "../ui-reusable-component/BouncingLoader";
import SubBouncingLoader from "../ui-reusable-component/SubBouncingLoader";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import InfiniteScroll from "react-infinite-scroll-component";
import ReplyIcon from "@mui/icons-material/Reply";
import useFetch from "../Core/CustomHooks/useFetch";
import projectService from "../Redux/Services/project.service";
import { MODULES } from "../Constants/modules.constants";
import { isNull } from "lodash";
import Image from "../../Components/ViewModule/FeatureLandingPage/ArchitectureImageCard";
import RViewerJS from "viewerjs-react";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import { Box, Skeleton } from "@mui/material";
export interface MediaModuleProps {}
const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#1976d2 !important",
    marginTop: "1% !important",
    color: " white !important",
    height: "1rem !important",
  },
  details: {
    padding: " 0px !important",
  },
  imageContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    justifyContent: " space-around",
    gap: "0.5rem",
    padding: "0.2rem",
    height: "60vh",
  },
}));

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme?.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme?.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme?.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function MediaModule(props: MediaModuleProps) {
  // @ts-ignoreTopLevel

  const { projectId, topVault, vault, leafNode, treeabbr } = useParams<any>();
  const classes = useStyles();

  const [loader, setLoader] = useState<any>(false);
  const [PreLoader, setPreLoader] = useState<any>(false);
  const [PostLoader, setPostLoader] = useState<any>(false);
  const [expanded, setExpanded] = useState<any | false>();
  const [MediaData, setMediaData] = useState<any>([]);
  const [OtherData, setOtherData] = useState<any>([]);
  const [PostMediaData, setPostMediaData] = useState<any>([]);
  const [categoryType, setCotegoryType] = useState<any>();
  const [hasMore, sethasMore] = useState(true);
  const [PosthasMore, setPosthasMore] = useState(true);
  const [OtherhasMore, setOtherhasMore] = useState<any>(true);
  const [page, setpage] = useState(2);
  const [postpage, setPostpage] = useState(2);
  const [otherpage, setOtherpage] = useState<any>(2);
  const [MediaDataCount, setMediaDataCount] = useState<any>();
  const [ModuleCount, setModuleCount] = useState<any>()
  const { path } = useRouteMatch();
  const history = useHistory();

  const toggleTopvault = () => {
    if (treeabbr == "product") {
      return { top_vault: topVault };
    } else {
      return { vault: vault };
    }
  };

  const togglePreImage = () => {
    if (treeabbr == "product") {
      return { image_category: 1 };
    } else {
      return { image_category: 2 };
    }
  };

  useEffect(() => {
    API.get(
      "customer/view_data_module/",
      { ...toggleTopvault(), module_count: true },
      0
    ).then((res: any) => {
      setModuleCount(res.data);
    });
  }, [vault]);


  const handleChange =
    (panel: any) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      sethasMore(true);

      setExpanded(newExpanded ? panel : false);
      if (panel == 1) {
        setPreLoader(true);
        setPostMediaData([]);
        setOtherData([]);
        setCotegoryType(panel);
        setpage(2);
        sethasMore(true);
        API.get("/cost/view_data/", {
          media_type: "image",
          ...togglePreImage(),
          ...toggleTopvault(),
          page_items: 16,
          page_no: 1,
        }).then((res: any) => {
          setLoader(false);
          setPreLoader(false);

          setMediaData(res.data);
        });
      } else if (panel == 3) {
        setCotegoryType(panel);
        setPosthasMore(true);
        setPostpage(2);
        setOtherData([]);
        setMediaData([]);
        setPostLoader(true);
        API.get("/cost/view_data/", {
          media_type: "image",
          image_category: 3,
          page_items: 16,
          page_no: 1,
          ...toggleTopvault(),
        }).then((res: any) => {
          setPostLoader(false);

          setPostMediaData(res.data);
        });
      } else if (panel == 5) {
        setOtherhasMore(true);
        setOtherpage(2);
        setPostMediaData([]);

        setMediaData([]);

        setCotegoryType(panel);
        // setPostLoader(true);
        setLoader(true);
        API.get("/cost/view_data/", {
          media_type: "image",
          image_category: 5,
          ...toggleTopvault(),
          page_items: 16,
          page_no: 1,
        }).then((res: any) => {
          // setPostLoader(false);
          setLoader(false);
          setOtherData(res.data);
        });
      }
    };

  useEffect(() => {
    setLoader(false);
    API.get(
      "customer/view_data_module/",
      { ...toggleTopvault(), media_category_count: true },
      0
    ).then((res: any) => {
      // setModuleCount(res.data)
      setMediaDataCount(res.data);
      setLoader(false);
    });
  }, [topVault, vault]);

  const fetchComments = async () => {
    if (categoryType == 1) {
      try {
        //@ts-ignore
        const { data } = await API.get("/cost/view_data/", {
          media_type: "image",
          ...togglePreImage(),
          ...toggleTopvault(),
          page_items: 16,
          page_no: page,
        });

        return data;
      } catch (e) {
        console.error("server error", e);
        return null;
      }
    }
    if (categoryType == 3) {
      try {
        //@ts-ignore
        const { data } = await API.get("/cost/view_data/", {
          media_type: "image",
          image_category: 3,
          ...toggleTopvault(),
          page_items: 16,
          page_no: postpage,
        });

        return data;
      } catch (e) {
        console.error("server error", e);
        return null;
      }
    }
    if (categoryType == 5) {
      try {
        //@ts-ignore
        const { data } = await API.get("/cost/view_data/", {
          media_type: "image",
          image_category: 5,
          ...toggleTopvault(),
          page_items: 16,
          page_no: otherpage,
        });

        return data;
      } catch (e) {
        console.error("server error", e);
        return null;
      }
    }
  };

  const fetchData = async () => {
    const commentsFormServer: any = await fetchComments();

    setMediaData([...MediaData, ...commentsFormServer]);
    if (commentsFormServer?.length === 0) {
      sethasMore(false);
    }
    setpage(page + 1);
  };

  const fetchDatapost = async () => {
    const commentsFormServer: any = await fetchComments();

    setPostMediaData([...PostMediaData, ...commentsFormServer]);
    if (commentsFormServer?.length === 0) {
      setPosthasMore(false);
    }
    setPostpage(postpage + 1);
  };

  const fetchDataOther = async () => {
    const commentsFormServer: any = await fetchComments();

    setOtherData([...OtherData, ...commentsFormServer]);
    if (commentsFormServer?.length === 0) {
      setOtherhasMore(false);
    }
    setOtherpage(otherpage + 1);
  };

  useEffect(() => {
    setLoader(false);
  }, [vault]);

  useEffect(() => {
    return () => {
      setExpanded(false);

      // sethasMore(true)
      // setOtherhasMore(true)
      // setPosthasMore(true)
    };
  }, [vault, topVault]);

  const [TopVaults, setTopVaults] = useState<any>([]);
  const [buttonHide, setButtonHide] = useState<any>(false);
  const [fetchedTopVaults, setFetchedTopVaults] = useState<any>(false);

  // const {
  //   0: fetchedTopVaults,
  //   1: TopVaults,
  //   3: fetchTopVaults,
  // } = useFetch({
  //   method: projectService.getTopVault,
  //   args: [projectId && projectId, MODULES.IMAGE_REPOSITORY],
  //   fallBackErrMsg: "Could not fetch projects",
  //   dependencies: [projectId],
  // });

  useEffect(() => {
    setButtonHide(false);
    setFetchedTopVaults(false);
    const apiForPreTearDown: any = API.get(
      `/image_repository/subsystem_fetch/`,
      {
        project: projectId,
        abbreviation: treeabbr == "product" ? 0 : treeabbr,
        image_category: treeabbr == "product" ? 1 : 2,
        media_type: "image",
      }
    ).then((res: any) => {
      setFetchedTopVaults(true);
      const preTearDown: any = res.data?.filter((item: any) => {
        return item?.count != 0;
      });
      if (preTearDown?.length > 0) {
        setTopVaults(preTearDown);
        setButtonHide(true);
      } else {
        const apiForTearDown: any = API.get(
          `/image_repository/subsystem_fetch/`,
          {
            project: projectId,
            abbreviation: treeabbr == "product" ? 0 : treeabbr,
            image_category: 3,
            media_type: "image",
          }
        ).then((res: any) => {
          setFetchedTopVaults(true);
          const tearDown: any = res.data?.filter((item: any) => {
            return item?.count != 0;
          });
          if (tearDown?.length > 0) {
            setTopVaults(tearDown);
            setButtonHide(true);
          } else {
            const apiForOthers: any = API.get(
              `/image_repository/subsystem_fetch/`,
              {
                project: projectId,
                abbreviation: treeabbr == "product" ? 0 : treeabbr,
                image_category: 5,
                media_type: "image",
              }
            ).then((res: any) => {
              setFetchedTopVaults(true);
              const others: any = res.data?.filter((item: any) => {
                return item?.count != 0;
              });
              if (others?.length > 0) {
                setTopVaults(others);
                setButtonHide(true);
              } else {
                setButtonHide(false);
              }
            });
          }
        });
      }
    });
  }, [projectId, treeabbr]);

  const topVaultKey: any =
    TopVaults &&
    TopVaults?.map((tvault: any) => {
      return tvault?.id;
    });
  const topVaults: any = topVaultKey?.slice(0, 5);
  const redirectToMedia = () => {
    const apiForActivity: any = API.get(
      `/image_repository/ma_activity_mapping/`,
      {
        type: "media",
        project: projectId,
      }
    )
    sessionStorage.setItem("media", window.location.href);
    if (fetchedTopVaults === true) {
      if (treeabbr == "product") {
        TopVaults &&
          history.push(
            `/media-repository/image/${projectId}/0/1/0/${topVaults.toString()}`
          );
      } else {
        TopVaults &&
          history.push(
            `/media-repository/image/${projectId}/${treeabbr}/2/0/${topVaults.toString()}`
          );
      }
    }
  };

  return (
    <div>
      <div>
        <div className={styles.header}>
          <Typography style={{ fontSize: "12px" }}>Media</Typography>
          <span className={styles.pageView}>
            {buttonHide && (
              <ReplyIcon
                style={{cursor:'pointer',transform: "scaleX(-1)" }}
                onClick={redirectToMedia}
                titleAccess="Go to Media"
              />
            )}
          </span>
        </div>
        <div>
    {loader? (
      // <SubBouncingLoader />
      <div>
      <Skeleton />
      <Skeleton />
      <Skeleton />
      </div>
    ) : (<>
        {MediaDataCount?.pre_td_images == true && (
          <Accordion expanded={expanded === 1} onChange={handleChange(1)} sx={{ marginLeft: '3%', }}>
            <AccordionSummary
              sx={{ minHeight: "35px" }}
              // expandIcon="nonde"
              aria-controls="panel1a-content"
              id="panel1a-header"
            // className={classes.root}
            >
              <Typography style={{ fontSize: "12px", marginLeft: "8px" }}>
                Pre Teardown
              </Typography>
            </AccordionSummary>
            {PreLoader ? (
              <> <Box sx={{ width: "100%" ,padding:'0 0.5rem' }}>
              <Skeleton animation="pulse" height="95%"/></Box>
              </>
            ) : (
              <AccordionDetails>
                {MediaData && MediaData && MediaData?.length > 0 && (
                  <Box
                    // className={classes.imageContainer}
                    id="scrollableDiv"
                    // className={styles.MediaInfiniteScrollBar}
                    sx={{
                      height: { xs: '72vh', sm: '72vh', md: '72vh', lg: '69vh', xl: '74vh' },
                      // overflow: "auto",
                      display: "grid",
                      gridTemplateColumns: 'repeat( auto-fit, minmax(7vw, 1fr) );',
                      // justifyContent: "space-around",
                      // flexDirection: 'column-reverse',
                    }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                  >
                    <InfiniteScroll
                      dataLength={MediaData && MediaData?.length} //This is important field to render the next data
                      next={fetchData}
                      hasMore={hasMore}
                      // loader={<Loader />}
                      loader={<h4></h4>}
                      endMessage={
                        <p style={{ textAlign: "center" }}>
                          <b>** End **</b>
                        </p>
                      }
                      scrollableTarget="scrollableDiv"
                    >
                      {/* @ts-ignore */}
                      <RViewerJS>
                        <div className={ModuleCount && ModuleCount?.valid_module_count == 6 ? styles.archData6 : ModuleCount && ModuleCount?.valid_module_count == 5 ? styles.archData5 : ModuleCount && ModuleCount?.valid_module_count == 4 ? styles.archData4 : ModuleCount && ModuleCount?.valid_module_count == 3 ? styles.archData3 : ModuleCount && ModuleCount?.valid_module_count == 2 ? styles.archData3 : ModuleCount && ModuleCount?.valid_module_count == 1 ? styles.archData1 : styles.archData}>
                          {MediaData &&
                            MediaData.map((medialItem: any, index: any) => {
                              return (
                                <>
                                  {/* <img
                                  src={medialItem?.original || NoImages}
                                  className={styles.imagePostTD}
                                /> */}
                                  <Image
                                    className={styles.imagePostTD}
                                    src={medialItem?.original}
                                    placeholderImg={NoImages}
                                  />
                                </>
                              );
                            })}
                        </div>
                      </RViewerJS>
                    </InfiniteScroll>
                  </Box>
                )}

              </AccordionDetails>
            )}
          </Accordion>
        )}

        {MediaDataCount?.teardown == true && (
          <Accordion expanded={expanded === 3} onChange={handleChange(3)} sx={{ marginLeft: '3%', }}>
            <AccordionSummary
              sx={{ minHeight: "35px" }}
              // expandIcon="nonde"
              aria-controls="panel1a-content"
              id="panel1a-header"
            // className={classes.root}
            >
              <Typography style={{ fontSize: "12px", marginLeft: "8px" }}>
                Teardown
              </Typography>
            </AccordionSummary>
            {PostLoader ? (<>
              <Box sx={{ width: "100%" ,padding:'0 0.5rem' }}>
              <Skeleton animation="pulse" height="95%" /></Box>
              </>
            ) : (
              <AccordionDetails>
                {PostMediaData &&
                  PostMediaData &&
                  PostMediaData?.length > 0 && (
                    <Box
                      // className={classes.imageContainer}
                      id="scrollableDiv1"
                      sx={{
                        height: { xs: '72vh', sm: '72vh', md: '72vh', lg: '64vh', xl: '74vh' },
                        // overflow: "auto",
                        display: "grid",
                        gridTemplateColumns: 'repeat( auto-fit, minmax(7vw, 1fr) );',
                        // justifyContent: "space-around",
                        // flexDirection: 'column-reverse',
                      }}
                      // className={styles.MediaInfiniteScrollBar}
                      adl-scrollbar="true"
                      adl-scrollbar-width="0.3"
                    >
                      <InfiniteScroll
                        dataLength={PostMediaData && PostMediaData?.length} //This is important field to render the next data
                        next={fetchDatapost}
                        hasMore={PosthasMore}
                        // loader={<Loader />}
                        loader={<h4></h4>}
                        endMessage={
                          <p style={{ textAlign: "center" }}>
                            <b>** End **</b>
                          </p>
                        }
                        scrollableTarget="scrollableDiv1"
                      >
                        {/* @ts-ignore */}
                        <RViewerJS>
                          <div className={ModuleCount && ModuleCount?.valid_module_count == 6 ? styles.archData6 : ModuleCount && ModuleCount?.valid_module_count == 5 ? styles.archData5 : ModuleCount && ModuleCount?.valid_module_count == 4 ? styles.archData4 : ModuleCount && ModuleCount?.valid_module_count == 3 ? styles.archData3 : ModuleCount && ModuleCount?.valid_module_count == 2 ? styles.archData3 : ModuleCount && ModuleCount?.valid_module_count == 1 ? styles.archData1 : styles.archData}>
                            {PostMediaData &&
                              PostMediaData.map(
                                (medialItem: any, index: any) => {
                                  return (
                                    <>
                                      {/* <img
                                  src={medialItem?.original|| NoImages}
                                  style={{
                                    width: "100%",
                                    objectFit: "cover",
                                    height: "7rem",
                                  }}
                                  // className={styles.imagePostTD}
                                /> */}
                                      <Image
                                        style={{
                                          width: "100%",
                                          objectFit: "cover",
                                          maxHeight: "7rem",
                                        }}
                                        src={medialItem?.original}
                                        placeholderImg={NoImages}
                                      />
                                    </>
                                  );
                                }
                              )}
                          </div>
                        </RViewerJS>
                      </InfiniteScroll>
                    </Box>
                  )}

              </AccordionDetails>
            )}
          </Accordion>
        )}
        {MediaDataCount?.others == true && (
          <Accordion expanded={expanded === 5} onChange={handleChange(5)} sx={{ marginLeft: '3%', }}>
            <AccordionSummary
              sx={{ minHeight: "35px" }}
              // expandIcon="none"
              aria-controls="panel2a-content"
              id="panel2a-header"
            // className={classes.root}
            >
              <Typography style={{ fontSize: "12px", marginLeft: "8px" }}>
                Others
              </Typography>
            </AccordionSummary>
            {loader ? (
              // <SubBouncingLoader />
              <Skeleton />
            ) : (
              <AccordionDetails>
                {OtherData && OtherData?.length > 0 && (
                  <Box
                    // className={classes.imageContainer}
                    id="scrollableDiv2"
                    sx={{
                      height: { xs: '72vh', sm: '72vh', md: '72vh', lg: '65vh', xl: '67vh' },
                      display: "grid",
                      gridTemplateColumns: 'repeat( auto-fit, minmax(7vw, 1fr) );',

                    }}
                    // className={styles.MediaInfiniteScrollBar}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                  >
                    <InfiniteScroll
                      dataLength={OtherData && OtherData?.length} //This is important field to render the next data
                      next={fetchDataOther}
                      hasMore={OtherhasMore}
                      // loader={<Loader />}
                      loader={<h4></h4>}
                      endMessage={
                        <p style={{ textAlign: "center" }}>
                          <b>** End **</b>
                        </p>
                      }
                      scrollableTarget="scrollableDiv2"
                    >
                      {/* @ts-ignore */}

                      <RViewerJS>
                        <div className={ModuleCount && ModuleCount?.valid_module_count == 6 ? styles.archData6 : ModuleCount && ModuleCount?.valid_module_count == 5 ? styles.archData5 : ModuleCount && ModuleCount?.valid_module_count == 4 ? styles.archData4 : ModuleCount && ModuleCount?.valid_module_count == 3 ? styles.archData3 : ModuleCount && ModuleCount?.valid_module_count == 2 ? styles.archData3 : ModuleCount && ModuleCount?.valid_module_count == 1 ? styles.archData1 : styles.archData}>
                          {OtherData &&
                            OtherData.map((medialItem: any, index: any) => {
                              return (
                                <>
                                  {/* <img
                                  src={medialItem?.original ||NoImages}
                                  className={styles.imagePostTD}
                                /> */}
                                  <Image
                                    className={styles.imagePostTD}
                                    src={medialItem?.original}
                                    placeholderImg={NoImages}
                                  />
                                </>
                              );
                            })}
                        </div>
                      </RViewerJS>
                    </InfiniteScroll>
                  </Box>
                )}


              </AccordionDetails>
            )}
          </Accordion>
        )}

        {

         (MediaDataCount?.pre_td_images == false && MediaDataCount?.teardown == false && MediaDataCount?.others == false ) && 
         <Box sx={{display: "flex",
          justifyContent: "center",
          marginTop: "20rem"}}>
         <Typography>
            
          Not Available
         </Typography>
         </Box>
        }
         </>)}
       </div>
      </div>
    </div>
  );
}
