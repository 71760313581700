import React, { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { API } from '../../../api-services';
import { useRouteParams } from '../../../ui-reusable-component/useRouteParams';
import { debounce } from 'lodash';

interface Option {
	label: string;
	value: string;
}

interface SearchAutocompleteProps {
	setSupplierData: any;
	setCount: any;
	supplierData: any;
	setSelectedId: any;
}

const SearchAutocomplete: React.FC<SearchAutocompleteProps> = (props) => {
	const { setSupplierData, setCount, supplierData, setSelectedId } = props;
	const [inputValue, setInputValue] = useState<any>('');
	const [selectedValue, setSelectedValue] = useState<any>({});
	const { vault, topVault } = useRouteParams<any>();
	const [options, setOptions] = useState<any>([]);
	const getObj = (vlt: any, tv: any) => {
		if (vlt == 0) {
			return { top_vault: tv }
		} else {
			return { vault: vlt }
		}
	}

	React.useEffect(() => {
		if (supplierData?.id) {
			setSelectedValue(supplierData);
			setInputValue(supplierData?.name);
			setOptions([supplierData]);
		}
	}, [supplierData]);

	const getSearchOptions = async (value: any, vlt: any, tv: any) => {
		API.get(
			'/supplier/get_all_supplier_list/',
			{ is_oem: false, ...getObj(vlt, tv), search: value },
			0
		)
			.then((res: any) => {
				if (res.data?.length > 0) {
					setOptions(res.data);
				}
			})
			.catch((err: any) => { });
	};

	const ExactDebounceParameters = React.useRef(
		debounce(async (value: any, vlt: any, tv: any) => {
			await getSearchOptions(value, vlt, tv);
		}, 1000)
	).current;

	const handleInputChange = (e: any, newInputValue: any) => {
		if (e?.type == 'change' && typeof newInputValue === 'string') {
			if (newInputValue && typeof newInputValue === 'string') {
				setInputValue(newInputValue);
			}

			if (typeof newInputValue === 'string') {
				// Make an API call here to fetch search results
				ExactDebounceParameters(newInputValue, vault, topVault);
			} else {
				setOptions([]); // Clear the options when the input is empty
			}
		}
	};

	return (
		<Autocomplete
			options={options}
			getOptionLabel={(option: any) => option.name}
			value={selectedValue && selectedValue}
			inputValue={inputValue}
			onChange={(event: any, newValue: any) => {
				if (newValue) {
					setSelectedValue(newValue);
					setInputValue(newValue?.name);
					setSupplierData({
						id: selectedValue?.id,
						name: selectedValue?.name,
						plants: selectedValue?.plants,
					});
					setSelectedId(newValue?.id);
					
				} else {
					setSelectedValue({});
					setInputValue('');
				}
			}}
			sx={{
				width: { lg: 150, xl: 200 },
				'.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator':
				{
					color: 'primary.main',
				},
				'.MuiButtonBase-root.MuiChip-root': {
					backgroundColor: 'primary.light',
				},
			}}
			onInputChange={(e: any, newInputValue: any) =>
				handleInputChange(e, newInputValue)
			}
			renderInput={(params) => (
				<TextField
					{...params}
					variant='standard'
					placeholder='Search Supplier'
					sx={{
						'& .MuiInput-underline:before': {
							borderBottomColor: 'transparent',
						},
						'& .MuiInput-underline:after': {
							borderBottomColor: 'primary.main',
						},
						'.MuiInputBase-root.MuiInput-root': {
							fontSize: '12px',
							backgroundColor: 'rgba(255,255,255,0.3)',
							paddingLeft: '0.3rem',
						},
						'.MuiSvgIcon-root': { color: 'primary.main' },
					}}
				/>
			)}
		/>
	);
};

export default SearchAutocomplete;
