import React from "react";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import * as yup from "yup";
import { Autocomplete, Divider, FormControl, InputLabel, TextField, Tooltip } from "@mui/material";
import {
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarContainer,
  GridToolbarExportContainer,
} from "@mui/x-data-grid";
import useWindowDimensions from "../ui-reusable-component/useWindowDimensions";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { API } from "../api-services";
import { LoadingButton } from "@mui/lab";
import { ADMIN } from "../Redux/Services/admin.service";
import Swal from "sweetalert2";
interface InfoModalProps {
  onCloseModal: any;
  isOpen: any;
  item:any;
  getZBCRequest: any;
}

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30vw",
    height: "fit-content",
    bgcolor: "background.paper",
    borderRadius: "10px",
   padding: "1rem",
  };
const validationSchema = yup.object({
  quoted_amount: yup.number()
  .required("Please type quote for money")
  .max(410000, "Quote amount cannot exceed ₹4.1 lakhs."),
  tat: yup.number().required("Please type turn over time in days"),
  assignees: yup.string().required("Please select minimum one assignee"),
});
const csvOptions: GridCsvExportOptions = {
  fileName: "Table In CSV",
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: "1.5rem", marginBottom: "0.5rem" }}>
    <span style={{ position: "absolute", right: "0rem" }}>
      {/* <GridToolbarColumnsButton /> */}
      {/* <GridToolbarDensitySelector /> */}
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);
const QuoteModal = (props: InfoModalProps) => {
  const { onCloseModal, isOpen,item ,getZBCRequest} = props;
  // const { height, width } = useWindowDimensions();
  const [loader,setLoader]=React.useState<boolean>(false)
  const history = useHistory();
  const [userList,setUserList]=React.useState<any>([])
  const getUserList=()=>{
    API.get("/auth/get_all_internaluser_list/",{},0).then((res:any)=>{
      setUserList(res.data)
    }).catch((err:any)=>{

    })
  }

  React.useEffect(()=>{
    getUserList()
  },[])

  React.useEffect(()=>{
    if(item?.quoted_amount){
        formik.setFieldValue("quoted_amount",item.quoted_amount)
        formik.setFieldValue("tat",item.zbc_tat_days)
        formik.setFieldValue("assignees",item?.assignees)
    }
  },[item])
 console.log(item,"itemitem",userList)
 
  const formik = useFormik({
    initialValues: {
      tat: "",
      quoted_amount: "",
      assignees: item?.assignees,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
        // if(item?.category){
          setLoader(true)
          API.post(`/cart/generate_quote_for_zbc/${item.ID}/`,{
            tat:values.tat,
            quoted_amount:values.quoted_amount,
            assignees:values.assignees?.map((item: any) => item.id)

          },{},0).then((res:any)=>{
            getZBCRequest();
            setLoader(false)
            ADMIN.toast.info("Quote generated successfully")
            onCloseModal()
          }).catch((err:any)=>{
            setLoader(false)
              const { data } = err?.response;
              Swal.fire({
                icon: "error",
                html: `<div>                
                <p style="color:"red">${data[0]}</p>   
                </div>`,
                customClass: {
                  container: "swal2Container",
                },
              });
          })
            
        // }else{
        //     history.push("/project_directory")
        // }
    },
  });

  return (
    <div>
      <Modal open={isOpen} onClose={onCloseModal}>
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "1rem",
              marginBottom: "0.5rem",             
            }}
          >
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Quote Money and Turn Around Time to the Client
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor:'primary.light',
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <form onSubmit={formik.handleSubmit}>
            <FormControl fullWidth variant="standard">
            <InputLabel
              sx={{ color: "primary.main", marginLeft: "1rem", }}
              shrink
              id="demo-simple-select-label"
            >
              Estimated cost for this Activity - Excluding Taxes (in INR) <sup style={{ color: "red" }}>*</sup>
            </InputLabel>
              <TextField
                variant="standard"
                // autoFocus={autoFocusStatus}
                disabled={item?.quoted_amount?true:false}
                InputProps={{
                  style: { color: "#007fff" },
                }}
                InputLabelProps={{
                  shrink: true,
                  
                }}
                sx={{
                    margin: "1rem 1rem",
                  "& .Mui-error": {
                    color: "#d32f2f",
                    borderBottomColor: "#d32f2f",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "primary.light",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "primary.main",
                  },
                  "& .MuiFormLabel-root.MuiInputLabel-root ": {
                    color: "primary.main",
                  },
                  ".MuiInputBase-root.MuiInput-root": {
                    fontSize: "1rem !important",
                  },
                }}
                type="number"
                id="quoted_amount"
                name="quoted_amount"
                onChange={formik.handleChange}
                value={formik.values.quoted_amount}
                error={formik.touched.quoted_amount && Boolean(formik.errors.quoted_amount)}
                helperText={formik.touched.quoted_amount && formik.errors.quoted_amount}
              />
            </FormControl>
            <FormControl fullWidth variant="standard">
            <InputLabel
              sx={{ color: "primary.main", marginLeft: "1rem", }}
              shrink
              id="demo-simple-select-label"
            >
              Turn around time (in Days) <sup style={{ color: "red" }}>*</sup>
            </InputLabel>
              <TextField
                variant="standard"
                disabled={item?.quoted_amount?true:false}
                // autoFocus={autoFocusStatus}
                InputProps={{
                  style: { color: "#007fff" },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                type="number"
                sx={{
                    margin: "1rem 1rem",
                  "& .Mui-error": {
                    color: "#d32f2f",
                    borderBottomColor: "#d32f2f",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "primary.light",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "primary.main",
                  },
                  "& .MuiFormLabel-root.MuiInputLabel-root ": {
                    color: "primary.main",
                  },
                  ".MuiInputBase-root.MuiInput-root": {
                    fontSize: "1rem !important",
                  },
                }}
                id="tat"
                name="tat"
                onChange={formik.handleChange}
                value={formik.values.tat}
                error={formik.touched.tat && Boolean(formik.errors.tat)}
                helperText={formik.touched.tat && formik.errors.tat}
              />
            </FormControl>
            <FormControl 
            sx={{ ".MuiFormLabel-asterisk": { color: "red" } }} fullWidth variant="standard">
            <InputLabel
              sx={{ color: "primary.main", marginLeft: "1rem" }}
              shrink
              id="demo-simple-select-label"
            >
              Assign to<sup style={{color:"red", marginTop:"0.5rem", marginLeft:"0.4rem"}}>*</sup>
            </InputLabel>
            <Autocomplete
              id="parameter"
              getOptionLabel={(option: any) => option.first_name}
              options={userList}
              autoHighlight
              disabled={item?.quoted_amount?true:false}
              disableCloseOnSelect
              limitTags={8}
              filterSelectedOptions
              value={formik.values.assignees}
              onChange={(event: any, newValue: any | null) => {
                formik.setFieldValue("assignees", newValue)
             }}
             multiple              
              sx={{ marginTop: "1.4rem" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="assignees"
                  variant="standard"
                  sx={{
                    marginLeft: "1rem",
                    width:"97%",
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                    ".MuiInputBase-root.MuiInput-root": {
                      fontSize: "1rem !important",
                    },
                    ".MuiSvgIcon-root": { color: "primary.main" },
                  }}
                />
              )}
            />
            <div style={{
                fontSize: "0.75rem",
                color: "#d32f2f",
                marginTop: "0.4rem",
                marginLeft: "1.45rem",
            }}>
              {formik.touched.assignees && Boolean(formik.errors.assignees) && (
                //@ts-ignore
                <p>{formik.errors.assignees}</p>
              )}
            </div>
          </FormControl>
            <LoadingButton size="small" disabled={item?.quoted_amount?true:false} sx={{float:'right'}} variant="contained" type="submit" loading={loader}>
              Submit
            </LoadingButton>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default QuoteModal;
