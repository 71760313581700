import {
  Autocomplete,
  Box,
  Chip,
  InputAdornment,
  Skeleton,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import swal from "sweetalert";
import React, { useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import ReplyIcon from "@mui/icons-material/Reply";
import { API } from "../../api-services";
import { isNumber } from "lodash";
import { number } from "yup";
import { useRouteMatch } from "react-router-dom";
interface OEMlistRowProps {
  rowItem: any;
  keys: any;

  setplantListCounter: any;
  rowId: any
  useraction?:any;
}

const PlantlistRow = (props: OEMlistRowProps) => {
  const { rowItem, keys, setplantListCounter, rowId ,useraction} = props;

  const [loader, setLoader] = useState<any>(false);
  const [rowIds, setRowIds] = useState<any>();
  const [Color, setColor] = useState<any>(false);
  const [city, setCity] = useState<any>();
  const [state, setstate] = useState<any>();
  const [latitude, setlatitude] = useState<any | number>();
  const [longitude, setlongititude] = useState<any | number>();
  const [cityError, setCityError] = useState<any>(false)
  const [stateError, setStateError] = useState<any>(false)
  const [latitudeError, setlatitudeError] = useState<any>(false)
  const [longitudeError, setlongitudeError] = useState<any>(false)
  const {url} = useRouteMatch();
  useEffect(() => {
    if (rowItem !== undefined) {
      setCity(rowItem && rowItem?.city);
      setstate(rowItem && rowItem?.state);
      setlatitude(rowItem && rowItem?.latitude);
      setlongititude(rowItem && rowItem?.longitude);
    }
  }, [rowItem]);

  const UpdateField = () => {
    if (rowItem?.city !== city) {
      return { city: city };
    }
    if (rowItem?.state !== state) {
      return { state: state };
    }
    if (rowItem?.longitude !== longitude) {
      return { longitude: Number( longitude) };
    }
    if (rowItem?.latitude !== latitude) {
      return { latitude: Number(latitude)};
    }
  };

  const DeleteHandler = (Id: any) => {
    swal({
      text: "Are you sure you want to delete ?",
      buttons: ["No", "Yes"],
    }).then((confirm) => {
      if (confirm) {
        setRowIds(Id);
        setLoader(true);

        API.delete(`/db/supplier_plant/${Id}`).then((res: any) => {
          setplantListCounter((prev: any) => prev + 1);
          setLoader(false);
        });
      }
    });
  };

  const Handlechange = (e: any, type: any) => {
    setColor(true)
    if (type == "city") {
      setCityError(false)
      setCity(e?.target?.value);
    }
    if (type == "state") {
      setStateError(false)
      setstate(e?.target?.value);
    }
    if (type == "latitude") {
      setlatitudeError(false)
      setlatitude(e?.target?.value );
    }
    if (type == "longitude") {
      setlongitudeError(false)
      setlongititude(e?.target?.value);
    }


  };



  const SaveHandler = (id: any, status: any) => {
    var Checkcity = /\S+/.test(city);
    var checkState= /\S+/.test(state);
   
    const longitudeString = longitude.toString();
    const longitudeLength = longitudeString?.length;
    const latitudeString = latitude.toString();
    const latitudeLength = latitudeString?.length;
 

    setRowIds(id)

    if (status == true) {


if( checkState==true && Checkcity==true && longitudeLength>0 && latitudeLength>0   ){
   setLoader(true)


     
    API.post("/db/supplier_plant/", {
      city: city,

      id: rowItem?.id,

      latitude: latitude,

      longitude: longitude,

      state: state,

      supplier:rowId
    })
      .then((res: any) => {
 setColor(false)
 setplantListCounter((prev: any) => prev + 1);
        setLoader(false)
      })
      .catch((err: any) => {
        setLoader(false)
        setColor(false)
        console.log("Sever Error");
      });
    }
    
    else{
    
      if( latitudeLength==0 ){
        setlatitudeError(true)
      }
      if(longitudeLength==0){
        setlongitudeError(true)
      }
      if( Checkcity==false  ){
        setCityError(true)
      }
        
      if(checkState==false){
        setStateError(true)
      }
    }
    }




    else{
      if(checkState==true && Checkcity==true &&  longitudeLength>0 && latitudeLength>0 ){

        setLoader(true)
 
      API.put(`/db/supplier_plant/${id}/`,{...UpdateField(),supplier:rowId}).then((res:any)=>{
        setLoader(false)
        setColor(false)
        setplantListCounter((prev: any) => prev + 1);
  
      }).catch((err:any)=>{
        setLoader(false)
      })
    }
      else{
        if(latitudeLength==0 ){
          setlatitudeError(true)
        }
        if(longitudeLength==0){
          setlongitudeError(true)
        }
        if (Checkcity == false) {
          setCityError(true)
        }

        if (checkState == false) {
          setStateError(true)
        }
      }
    }




   

  };


  return ( loader && rowIds == rowItem?.id ? (
    <TableRow>
      <TableCell sx={{ padding: '0.5rem' }}>
        <Skeleton></Skeleton>
      </TableCell>
      <TableCell sx={{ padding: '0.5rem' }}>
        <Skeleton></Skeleton>
      </TableCell>
      <TableCell sx={{ padding: '0.5rem' }}>
        <Skeleton></Skeleton>
      </TableCell>
      <TableCell sx={{ padding: '0.5rem' }}>
        <Skeleton></Skeleton>
      </TableCell>
      <TableCell sx={{ padding: '0.5rem' }}>
        <Skeleton></Skeleton>
      </TableCell>
      <TableCell sx={{ padding: '0.5rem' }}>
        <Skeleton></Skeleton>
      </TableCell>
    </TableRow>
  ) : (
    <TableRow key={keys}>
      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>{keys && keys}</TableCell>
      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
        <TextField
          // error={Errorname}
          id="input-with-icon-textfield"
          value={city}
          error={cityError}
          focused={rowItem?.rowstatus}
          placeholder="city"
          onChange={(e: any) => Handlechange(e, "city")}
          variant="standard"
          sx={{
            '& .MuiInput-underline:before': { borderBottomColor: 'white' },
            // '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
          }}
        />
      </TableCell>
      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
        <TextField
        error={stateError}
          // error={ErrorAboutUs}
          id="input-with-icon-textfield"
          value={state}
          focused={rowItem?.rowstatus}
          onChange={(e: any) => Handlechange(e, "state")}
          placeholder="state"
          variant="standard"
          sx={{
            '& .MuiInput-underline:before': { borderBottomColor: 'white' },
            // '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
          }}
        />
      </TableCell>

      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
        <TextField
          id="input-with-icon-textfield"
          value={longitude}
          error={longitudeError}
          focused={rowItem?.rowstatus}
          placeholder="longitude"
          onChange={(e: any) => Handlechange(e, "longitude")}
          variant="standard"
          type="number"
          sx={{
            '& .MuiInput-underline:before': { borderBottomColor: 'white' },
            // '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
          }}
        />
      </TableCell>
      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light',}}>
        <TextField
          id="input-with-icon-textfield"
          value={latitude}
          error={latitudeError}
          focused={rowItem?.rowstatus}
          placeholder="latitude"
          onChange={(e: any) => Handlechange(e, "latitude")}
          variant="standard"
          type="number"
          sx={{
            '& .MuiInput-underline:before': { borderBottomColor: 'white' },
            // '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
          }}
        />
      </TableCell>
      {url?.includes('supplier-database')?<>
      {( useraction && useraction?.includes("U") ||useraction && useraction?.includes("D"))  &&<TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
        {useraction && useraction?.includes("U") &&
        <SaveIcon
          sx={{ color: Color ? "red" : "#007fff", cursor: "pointer" }}
          onClick={() => SaveHandler(rowItem?.id, rowItem?.rowstatus)}
        />
        }
        {
         useraction && useraction?.includes("D") && 
         <DeleteIcon
            sx={{ cursor: "pointer" }}
            onClick={() => DeleteHandler(rowItem?.id)}
          />
        }
      </TableCell>}
      </>:
        <><SaveIcon
            sx={{ color: Color ? "red" : "#007fff", cursor: "pointer" }}
            onClick={() => SaveHandler(rowItem?.id, rowItem?.rowstatus)} /><DeleteIcon
              sx={{ cursor: "pointer" }}
              onClick={() => DeleteHandler(rowItem?.id)} /></>
      }
    </TableRow>
  )
  )
};

export default PlantlistRow;
