import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import * as React from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import styles from "../AddRevisionModal.module.scss";
import makeStyles from "@mui/styles/makeStyles";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { useRouteMatch } from "react-router-dom";
import Swal from "sweetalert2";
import successIcon from "../../../Assets/images/success.png"


export interface IBearingFormProps {
  actions: any;
  onCloseModal: any;
  getCategoryData: any;
  category: any;
  onCloseParent:any;
}
const validationSchema = yup.object({
  name: yup.string().required("Please type Item name"),
  no_of_pins: yup.string().required("Please type No. of pins"),
  postion_config: yup.mixed().required("Please enter Position Config(mxn)"),
  shape: yup.mixed().required("Please type Shape"),
  pin_pitch: yup.mixed().required("Please enter Terminal pin pitch"),
  connection_type: yup.mixed().required("Please type Connection Type"),
  gender: yup.string().required("Please select Gender"),
  fit_type: yup.string().required("Please select fit type"),
  material: yup.string().required("Please type Item material"),
  reference: yup.string().required("Please type Item reference"),
  unit_cost: yup.mixed().required("Please enter per unit cost"),
});
const useStyles = makeStyles((theme) => ({
  select: {
    "&.MuiInputBase-root-MuiInput-root-MuiSelect-root": {
      borderBottom: "1px solid red",
    },
  },
}));
export function ConnectorsFormMove(props: IBearingFormProps) {
  const { actions, onCloseModal, getCategoryData, category,onCloseParent } = props;
  const classes = useStyles();
  const { url } = useRouteMatch();
  const { categoryName } = useRouteParams<any>();
  const [isLoadingsave, setIsLoadingSave] = React.useState<any>(false)
  const formik = useFormik({
    initialValues: {
      name: "",
      no_of_pins: "",
      postion_config: "",
      shape: "",
      pin_pitch: "",
      connection_type: "",
      gender: "",
      fit_type: "",
      material: "",
      reference: "",
      unit_cost: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
        setIsLoadingSave(true);
        API.post("db/move_cost_data/", {
          ...values, ...{ target_commodity: category, id: url.includes("DB") ? actions.id : actions.purchase_item, boughtout: true }
        }, {}, 0).then((res: any) => {
          getCategoryData(categoryName)
          Swal.fire({
  
            html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
  
        <br />
  
        <p style="color:#007fff;">Moved Successfully!</p>   
  
         </div>`,
  
          });
          onCloseModal();
          onCloseParent();
        }).catch((err: any) => { })
          
         
    },
  });
  React.useEffect(() => {
    if (actions !== "add") {
      formik.setFieldValue("name", actions?.name || "");
      formik.setFieldValue("no_of_pins", actions?.no_of_pins || "");
      formik.setFieldValue("postion_config", actions?.postion_config || "");
      formik.setFieldValue("shape", actions?.shape || "");
      formik.setFieldValue("pin_pitch", actions?.pin_pitch || "");
      formik.setFieldValue("connection_type", actions?.connection_type || "");
      formik.setFieldValue("gender", actions?.gender || "");
      formik.setFieldValue("fit_type", actions?.fit_type || "");
      formik.setFieldValue("material", actions?.material || "");
      formik.setFieldValue("reference", actions?.reference || "");
      formik.setFieldValue("unit_cost", parseFloat(actions.unit_cost?.slice(1)) || "");

    }
  }, [actions])
  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem', marginBottom: '0.5rem' }}>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="Item Name"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: '1rem !important'
                },
              }}
              id="name"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              multiline
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </FormControl>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="No. of pins"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: '1rem !important'
                },
              }}
              id="no_of_pins"
              name="no_of_pins"
              onChange={formik.handleChange}
              value={formik.values.no_of_pins}
              type="number"
              error={formik.touched.no_of_pins && Boolean(formik.errors.no_of_pins)}
              helperText={formik.touched.no_of_pins && formik.errors.no_of_pins}
            />
          </FormControl>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem', marginBottom: '0.5rem' }}>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="Position Config"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: '1rem !important'
                },
              }}
              id="postion_config"
              name="postion_config"
              onChange={formik.handleChange}
              value={formik.values.postion_config}
              multiline
              error={formik.touched.postion_config && Boolean(formik.errors.postion_config)}
              helperText={formik.touched.postion_config && formik.errors.postion_config}
            />
          </FormControl>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="Terminal pin pitch"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: '1rem !important'
                },
              }}
              id="pin_pitch"
              name="pin_pitch"
              onChange={formik.handleChange}
              value={formik.values.pin_pitch}
              multiline
              error={formik.touched.pin_pitch && Boolean(formik.errors.pin_pitch)}
              helperText={formik.touched.pin_pitch && formik.errors.pin_pitch}
            />
          </FormControl>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem', marginBottom: '0.5rem' }}>

          <FormControl
            fullWidth
            variant="standard">
            <InputLabel sx={{ color: 'primary.main' }} shrink id="demo-simple-select-label">Select shape </InputLabel>

            <Select
              id="shape"
              name="shape"
              className={classes.select}
              labelId="demo-select-small"
              // id="demo-select-small"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                  fontSize: '1rem'
                },
                '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
              }}
              error={formik.touched.shape && Boolean(formik.errors.shape)}
              //@ts-ignore
              helperText={formik.touched.shape && formik.errors.shape}
              MenuProps={{
                style: {
                  maxHeight: 400,
                  color: "007fff",
                },
              }}
              onChange={formik.handleChange}
              value={formik.values.shape !== null ? formik.values.shape : ""}>
              {/* <option value={""} style={{ display: "none" }}></option> */}
              {["Circular", "Rectangular", "Blade type", "Pin and Socket", "Others"].map((item: any, index: any) => {
                return (
                  <MenuItem
                    sx={{ color: "primary.main", fontSize: "1rem" }}
                    key={index}
                    value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
            <div className={styles.errormsg}>{formik.touched.shape && Boolean(formik.errors.shape) && <p>{formik.errors.shape}</p>}</div>
          </FormControl>
          <FormControl
            fullWidth
            variant="standard">
            <InputLabel sx={{ color: 'primary.main' }} shrink id="demo-simple-select-label">Select Connection type </InputLabel>

            <Select
              id="connection_type"
              name="connection_type"
              className={classes.select}
              labelId="demo-select-small"
              // id="demo-select-small"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                  fontSize: '1rem'
                },
                '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
              }}
              error={formik.touched.connection_type && Boolean(formik.errors.connection_type)}
              //@ts-ignore
              helperText={formik.touched.connection_type && formik.errors.connection_type}
              MenuProps={{
                style: {
                  maxHeight: 400,
                  color: "007fff",
                },
              }}
              onChange={formik.handleChange}
              value={formik.values.connection_type !== null ? formik.values.connection_type : ""}>
              {/* <option value={""} style={{ display: "none" }}></option> */}
              {["Wire to Wire", "Wire to Board", "Board to Board", "Others"].map((item: any, index: any) => {
                return (
                  <MenuItem
                    sx={{ color: "primary.main", fontSize: "1rem" }}
                    key={index}
                    value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
            <div className={styles.errormsg}>{formik.touched.connection_type && Boolean(formik.errors.connection_type) && <p>{formik.errors.connection_type}</p>}</div>
          </FormControl>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem', marginBottom: '0.5rem' }}>
          <FormControl
            fullWidth
            variant="standard">
            <InputLabel sx={{ color: 'primary.main' }} shrink id="demo-simple-select-label">Select Gender </InputLabel>

            <Select
              id="gender"
              name="gender"
              className={classes.select}
              labelId="demo-select-small"
              // id="demo-select-small"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                  fontSize: '1rem'
                },
                '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
              }}
              error={formik.touched.gender && Boolean(formik.errors.gender)}
              //@ts-ignore
              helperText={formik.touched.gender && formik.errors.gender}
              MenuProps={{
                style: {
                  maxHeight: 400,
                  color: "007fff",
                },
              }}
              onChange={formik.handleChange}
              value={formik.values.gender !== null ? formik.values.gender : ""}>
              {/* <option value={""} style={{ display: "none" }}></option> */}
              {["Male", "Female"].map((item: any, index: any) => {
                return (
                  <MenuItem
                    sx={{ color: "primary.main", fontSize: "1rem" }}
                    key={index}
                    value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
            <div className={styles.errormsg}>{formik.touched.gender && Boolean(formik.errors.gender) && <p>{formik.errors.gender}</p>}</div>
          </FormControl>
          <FormControl
            fullWidth
            variant="standard">
            <InputLabel sx={{ color: 'primary.main' }} shrink id="demo-simple-select-label">Select Fit Type </InputLabel>

            <Select
              id="fit_type"
              name="fit_type"
              className={classes.select}
              labelId="demo-select-small"
              // id="demo-select-small"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                  fontSize: '1rem'
                },
                '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
              }}
              error={formik.touched.fit_type && Boolean(formik.errors.fit_type)}
              //@ts-ignore
              helperText={formik.touched.fit_type && formik.errors.fit_type}
              MenuProps={{
                style: {
                  maxHeight: 400,
                  color: "007fff",
                },
              }}
              onChange={formik.handleChange}
              value={formik.values.fit_type !== null ? formik.values.fit_type : ""}>
              {/* <option value={""} style={{ display: "none" }}></option> */}
              {["Push fit", "Bayonet Fit", "Others"].map((item: any, index: any) => {
                return (
                  <MenuItem
                    sx={{ color: "primary.main", fontSize: "1rem" }}
                    key={index}
                    value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
            <div className={styles.errormsg}>{formik.touched.fit_type && Boolean(formik.errors.fit_type) && <p>{formik.errors.fit_type}</p>}</div>
          </FormControl>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem', marginBottom: '0.5rem' }}>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="Material"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: '1rem !important'
                },
              }}
              id="material"
              name="material"
              onChange={formik.handleChange}
              value={formik.values.material}
              multiline
              error={formik.touched.material && Boolean(formik.errors.material)}
              helperText={formik.touched.material && formik.errors.material}
            />
          </FormControl>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="References"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: '1rem !important'
                },
              }}
              id="reference"
              name="reference"
              onChange={formik.handleChange}
              value={formik.values.reference}
              multiline
              error={formik.touched.reference && Boolean(formik.errors.reference)}
              helperText={formik.touched.reference && formik.errors.reference}
            />
          </FormControl>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem', marginBottom: '0.5rem' }}>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="Per Unit Cost"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: '1rem !important'
                },
              }}
              id="unit_cost"
              name="unit_cost"
              onChange={formik.handleChange}
              value={formik.values.unit_cost}
              type="number"
              error={formik.touched.unit_cost && Boolean(formik.errors.unit_cost)}
              helperText={formik.touched.unit_cost && formik.errors.unit_cost}
            />
          </FormControl>
        </Box>
        <Box sx={{ width: '100%', textAlign: 'right' }}>
          <LoadingButton
            size="small"
            variant="contained"
            sx={{
              padding: "0.5rem",
              lineHeight: 1,
              marginTop: "1rem",
              "&:hover": {
                transform: 'Scale(1.05)',
                transition: 'transform 0.5s ease',
              },
              '.MuiCircularProgress-root': {
                color: 'primary.main',
              },
            }}
            loading={isLoadingsave}
            type="submit"
          >
            Move
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
}
