import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { API } from "../api-services";
import Swal from "sweetalert2";
import { log } from "console";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  padding: "1rem",
};

function PublishPartModal({
  onCloseModal,
  isOpen,
  rowData,
  setCouner,
  getAllUnlistedPartReq,
}: any) {
  const [fullfilledParts, setFullfilledParts] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [selectedPart, setSelectedPart] = useState<string>();
  const [loader, setLoader] = useState<any>(false);
  const filter = createFilterOptions();

  const getAllParts = () => {
    API.get(`cart/fulfill_unlisted_part/`, {
      
    }, 0)
      .then((res: any) => {
        setFullfilledParts(res.data);
      })
      .catch((err: any) => {
        const { data } = err.response;

        if (err && err.response && data) {
          Swal.fire({
            icon: "error",
            html: `<div>
            <br />
            <p style="color:"red">${data[0]}</p>   
             </div>`,
          });
        }
      });
  };
  React.useEffect(() => {
    getAllParts();
  }, []);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedPart(event.target.value as string);
  };

  const handleAutocompleteChange = (event: any, newValue: any) => {
    // handleChange(newValue ? newValue.id : '');
    setSelectedPart(newValue?.id);
  };

  const customFilterOptions = (options: any, state: any) => {
    const { inputValue } = state;
    return filter(options, state).filter(
      (option: any) =>
        option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.id.toString().includes(inputValue)
    );
  };

  const handlePublish = () => {
    setLoader(true);
    API.put(`cart/fulfill_unlisted_part/${rowData?.ID}/`, {
      mapped_vault_id: selectedPart,
    })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: `${res.data}`,
          confirmButtonText: "OK",
          preConfirm: () => {
            // Call the onCloseModal function
            getAllUnlistedPartReq();
            onCloseModal();
          },
        });
      })

      .catch((err: any) => {
        Swal.fire({
          icon: "error",
          title: `${err.response.data[0]}`,
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };


  useEffect(() => {
    if (search) {
      getAllParts();
    }
  }, [search]);


  return (
    <Modal open={isOpen} onClose={onCloseModal}>
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "1rem",
            marginBottom: "0.5rem",
          }}
        >
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: "-2rem",
            }}
          >
            Publist part requested by {rowData?.user_details?.name}
          </Typography>
          <CancelIcon
            titleAccess="Close"
            sx={{ cursor: "pointer", margin: "0.5rem" }}
            onClick={onCloseModal}
          />
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
            marginBottom: "1rem",
          }}
        />

        <Box>
          {/* <FormControl
            fullWidth
            variant="standard"
            sx={{
              marginBottom: "2rem",
              ".MuiFormLabel-asterisk": { color: "red" },
            }}
          >
            <InputLabel htmlFor="select-actions">Select Part</InputLabel>
            <Select
              sx={{
                border: "none",
                "& .MuiSelect-select": {
                  padding: "0.5rem", // Adjust padding if needed
                },
              }}
              variant="standard"
              value={selectedPart}
              onChange={(event: any) => handleChange(event)}
              displayEmpty
              inputProps={{ "aria-label": "Actions" }}
            >
              {fullfilledParts.map((option: any) => (
                <MenuItem key={option?.id} value={option?.id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}

          <Autocomplete
            value={
              fullfilledParts.find(
                (option: any) => option.id === selectedPart
              ) || null
            }
            onChange={handleAutocompleteChange}
            options={fullfilledParts}
            getOptionLabel={(option: any) => {
                return option.name;
            }} // How to display each option in the dropdown
            isOptionEqualToValue={(option: any, value: any) =>
              option.id === value.id
            } // How to check if option is selected
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Part"
                variant="standard"
                sx={{
                  marginBottom: "2rem",
                  ".MuiInputLabel-asterisk": { color: "red" },
                }}
              />
            )}
            fullWidth
            disableClearable
          />

          



          {selectedPart && (
            <LoadingButton
              variant="contained"
              component="label"
              disabled={loader}
              loading={loader}
              sx={{
                marginTop: "2rem",
                margin: "0.5rem",
                whiteSpace: "nowrap",
                width: "100%",
                height: "2rem",
              }}
              onClick={handlePublish}
            >
              Publish
            </LoadingButton>
          )}
        </Box>
      </Box>
    </Modal>
  );
}

export default PublishPartModal;
