import React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ArchCustomImageEditor from "./ArchCustomImageEditor";
import { LoadingButton } from "@mui/lab";
import CancelIcon from "@mui/icons-material/Cancel";
import { Divider } from "@mui/material";
import { useRouteMatch } from "react-router-dom";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "69vw",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};
interface ArchImageEditModalProps {
  imageURI: any;
  open: any;
  closeModal: () => void;
  Loader: any;
  imageUrl?: any;
  updateImage?: any;
  isPCB?: boolean;
  setImageUrl?: any;
}

const ArchImageEditModal = (props: ArchImageEditModalProps) => {
  const { open, closeModal, updateImage, imageURI, imageUrl, Loader, isPCB,setImageUrl } = props;
  const imageEditor = React.createRef<HTMLDivElement | any>();
  const {url} = useRouteMatch();

  const handleUpdateImage = () => {
    const updatedImage = imageEditor?.current?.imageEditorInst;

    const updatedData = updatedImage.toDataURL().split(",")[1];
    const byteCharacters = atob(updatedData);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([byteArray], {
      type: "image/png",
    });

    updateImage(file, 0);
    setImageUrl(URL.createObjectURL(file))
    closeModal();
  };

  return (
    <div>
      <Modal
        disableEnforceFocus
        open={open}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            { isPCB ? <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}>
              Edit PCB Image
            </Typography> :  <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}>
              Edit {url?.includes('bomentry') ? 'BOM Image' : url?.includes('mediacreateinternal') ? 'Image' : "Diagram"}
            </Typography>}
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={closeModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <ArchCustomImageEditor
            imageURI={imageURI}
            imageEditor={imageEditor}
            imageUrl={imageUrl}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1rem",
            }}>
            <LoadingButton
              size="small"
              sx={{
                padding: "0.5rem",
                lineHeight: 1,
                "&:hover": {
                  transform: 'Scale(1.05)',
                  transition: 'transform 0.5s ease',
                },
                '.MuiCircularProgress-root': {
                  color: 'primary.main',
                },
              }}
              variant="contained"
              onClick={handleUpdateImage}
              loading={Loader}>
              Save
            </LoadingButton>
          </Box>


        </Box>
      </Modal>
    </div>
  );
};

export default ArchImageEditModal;
