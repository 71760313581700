import React from "react";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import * as yup from "yup";
import {
  Autocomplete,
  Divider,
  FormControl,
  InputLabel,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarContainer,
  GridToolbarExportContainer,
} from "@mui/x-data-grid";
import useWindowDimensions from "../ui-reusable-component/useWindowDimensions";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { API } from "../api-services";
import { LoadingButton } from "@mui/lab";
import { ADMIN } from "../Redux/Services/admin.service";
interface InfoModalProps {
  onCloseModal: any;
  isOpen: any;
  item: any;
  getScenarioData: any;
  setCouner?: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  padding: "1rem",
};
const validationSchema = yup.object({
  assignees: yup.string().required("Please select minimum one assignee"),
  scenario_name: yup.string(),
});
const csvOptions: GridCsvExportOptions = {
  fileName: "Table In CSV",
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: "1.5rem", marginBottom: "0.5rem" }}>
    <span style={{ position: "absolute", right: "0rem" }}>
      {/* <GridToolbarColumnsButton /> */}
      {/* <GridToolbarDensitySelector /> */}
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);
const ScenarioAssignModal = (props: InfoModalProps) => {
  const { onCloseModal, isOpen, item, getScenarioData, setCouner } = props;
  // const { height, width } = useWindowDimensions();
  const [loader, setLoader] = React.useState<boolean>(false);
  const history = useHistory();
  const [userList, setUserList] = React.useState<any>([]);
  const getUserList = () => {
    API.get("/auth/get_all_internaluser_list/", {}, 0)
      .then((res: any) => {
        setUserList(res.data);
      })
      .catch((err: any) => {});
  };

  React.useEffect(() => {
    getUserList();
  }, []);

  React.useEffect(() => {
    if (item?.assignees) {
      formik.setFieldValue("assignees", item?.assignees);
    }
    if (item?.scenario_name) {
      formik.setFieldValue("scenario_name", item?.scenario_name);
    }
  }, [item]);
  //  console.log(item,"itemitem",userList)

  const formik = useFormik({
    initialValues: {
      assignees: item?.assignees,
      scenario_name: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoader(true);
      API.put(
        `/cart/update_item_from_cart/ScenarioCostingCart/${item.ID}/`,
        {
          assignees: values.assignees?.map((item: any) => item.id),
          scenario_name: values?.scenario_name,
        },
        {},
        0
      )
        .then((res: any) => {
          setCouner((prev: any) => prev + 1);
          getScenarioData();
          setLoader(false);
          ADMIN.toast.info("Representative Assigned successfully");
          onCloseModal();
        })
        .catch((err: any) => {
          setLoader(false);
        });
    },
  });

  return (
    <div>
      <Modal open={isOpen} onClose={onCloseModal}>
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "1rem",
              marginBottom: "0.5rem",
            }}
          >
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Assign Representative to this Activity
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <form onSubmit={formik.handleSubmit}>
            <FormControl
              sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
              fullWidth
              variant="standard"
            >
              <InputLabel
                sx={{ color: "primary.main", marginLeft: "1rem" }}
                shrink
                id="demo-simple-select-label"
              >
                Assign to
                <sup
                  style={{
                    color: "red",
                    marginTop: "0.5rem",
                    marginLeft: "0.4rem",
                  }}
                >
                  *
                </sup>
              </InputLabel>
              <Autocomplete
                id="parameter"
                getOptionLabel={(option: any) => option.first_name}
                options={userList}
                autoHighlight
                disableCloseOnSelect
                limitTags={8}
                disabled={item?.scenario_id ? true : false}
                filterSelectedOptions
                value={formik.values.assignees}
                onChange={(event: any, newValue: any | null) => {
                  formik.setFieldValue("assignees", newValue);
                }}
                multiple
                sx={{ marginTop: "1.4rem" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="assignees"
                    variant="standard"
                    sx={{
                      marginLeft: "1rem",
                      width: "97%",
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                      ".MuiSvgIcon-root": { color: "primary.main" },
                    }}
                  />
                )}
              />
              <div
                style={{
                  fontSize: "0.75rem",
                  color: "#d32f2f",
                  marginTop: "0.4rem",
                  marginLeft: "1.45rem",
                }}
              >
                {formik.touched.assignees &&
                  Boolean(formik.errors.assignees) && (
                    //@ts-ignore
                    <p>{formik.errors.assignees}</p>
                  )}
              </div>
            </FormControl>
            <FormControl
              sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
              fullWidth
              variant="standard"
            >
              {item?.costing_type != "Main-Costing" && (
                <InputLabel
                  sx={{
                    color: "primary.main",
                    marginLeft: "1rem",
                    marginBottom: "4rem",
                  }}
                  shrink
                  id="demo-simple-select-label"
                >
                  Scenario Name
                  <sup
                    style={{
                      color: "red",
                      marginTop: "0.5rem",
                      marginLeft: "0.4rem",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
              )}
              {item?.costing_type != "Main-Costing" && (
                <FormControl fullWidth>
                  <TextField
                    autoFocus
                    variant="standard"
                    InputProps={{
                      style: { color: "#007fff", fontSize: "1rem" },
                    }}
                    id="scenario_name"
                    name="scenario_name"
                    disabled={item?.scenario_id ? true : false}
                    InputLabelProps={{ style: { fontSize: "1rem" } }}
                    value={formik.values?.scenario_name}
                    onChange={formik.handleChange}
                    sx={{
                      width: "97.2%",
                      marginTop: "1.4rem",
                      marginLeft: "1rem",
                      "& .MuiFormLabel-root": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .Mui-focused": {
                        color: "primary.main",
                      },
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                    }}
                    //   label="Enter Scenario Name *"
                    error={
                      formik.touched.scenario_name &&
                      Boolean(formik.errors.scenario_name)
                    }
                    helperText={
                      formik.touched.scenario_name &&
                      formik.errors.scenario_name
                    }
                  />
                </FormControl>
              )}
            </FormControl>
            <LoadingButton
              disabled={
                formik.values?.assignees &&
                item?.scenario_id === null &&
                formik.values?.assignees?.length > 0
                  ? false
                  : true
              }
              sx={{ float: "right", marginTop: "1.5rem" }}
              variant="contained"
              type="submit"
              loading={loader}
            >
              Submit
            </LoadingButton>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default ScenarioAssignModal;
