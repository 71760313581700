import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  Container,
  IconButton,
  MenuItem,
} from "@mui/material";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import VerifiedIcon from "@mui/icons-material/Verified";
import { API } from "../../api-services";
import Swal from "sweetalert2";
import { ADMIN } from "../../Redux/Services/admin.service";
import CDN_URL from "../../utlis/CDN_URL";

const validationSchema = yup.object({
  quality_certificate: yup
    .array()
    .of(
      yup.object().shape({
        doc_name: yup.string().required("Document Name is required"),
        doc_number: yup.mixed().required("Document Number is required"),
        issue_date: yup.string().required("Document Name is required"),
        doc_url: yup.string().required("Document Name is required"),
        // Add more validations for other fields as needed
      })
    )
    .min(1, "At least one award document is required"),
  awards_recognition_docs: yup
    .array()
    .of(
      yup.object().shape({
        doc_name: yup.string().required("Document Name is required"),
        doc_number: yup.mixed().required("Document Number is required"),
        issue_date: yup.string().required("Document Name is required"),
        doc_url: yup.string().required("Document Name is required"),
        // Add more validations for other fields as needed
      })
    )
    .min(1, "At least one award document is required"),
    product_catalogue: yup
    .array()
    .of(
      yup.object().shape({
        doc_name: yup.string().required("Document Name is required"),
        doc_number: yup.mixed().required("Document Number is required"),
        issue_date: yup.string().required("Document Name is required"),
        doc_url: yup.string().required("Document Name is required"),
        // Add more validations for other fields as needed
      })
    )
    .min(1, "At least one award document is required"),
});

const VerificationStepEdit3 = ({ prefetchedDataId, handleNext,
  activeStep,
  setActiveStep,
  handleBack,
  counts,
  request_type,
  setCounts,
  getAllClaimBusinessReq,
  onCloseModal,
  steps, }: any) => {
  const [prefetchedData, setPrefetchedData] = useState<any>(null);
  const [loaded, setLoaded] = React.useState(false);
  const [verificationStatus, setVerificationStatus] = useState<any>({
    // business_type: { verified: false, rejected: true },
    awards_recognition_docs: { verified: false, rejected: true },
    product_catalogue: { verified: false, rejected: true },
    quality_certificate: { verified: false, rejected: true },

  });



  const getPreFetchedData = () => {
    API.get("/cart/get_all_claim_requests/", { id: prefetchedDataId }, 0)
      .then((res: any) => {
        setPrefetchedData(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    if (prefetchedDataId) {
      getPreFetchedData();
    }
  }, [prefetchedDataId]);

  const handleVerificationToggle = (fieldName: any) => {
    // if (!loaded) {
    //   setLoaded(true);
    // }
    ADMIN.toast.info("Verification Successful");
    setVerificationStatus((prevStatus: any) => ({
      ...prevStatus,
      [fieldName]: {
        verified: !prevStatus[fieldName].verified,
        rejected: prevStatus[fieldName].verified ? true : false, // Toggle rejected based on the new verified state
      },
    }));
 
  };

  const handleToggleVerification = () => {
    // if (!loaded) {
    //   setLoaded(true);
    // }
    const allVerified = Object.keys(verificationStatus).every(
      (key) => verificationStatus[key].verified === true
    );

    const updatedStatus = Object.keys(verificationStatus).reduce(
      (acc: any, key: any) => {
        acc[key] = { ...verificationStatus[key], verified: !allVerified };
        return acc;
      },
      {}
    );

    setVerificationStatus(updatedStatus);
    ADMIN.toast.info("Verification Successful");
  };

  useEffect(()=>{
    setVerificationStatus((prevStatus:any) => ({
      ...prevStatus,
      quality_certificate: {
        ...prevStatus.quality_certificate,
        verified: prefetchedData?.verification_status?.quality_certificate || false,
      },
      product_catalogue: {
        ...prevStatus.product_catalogue,
        verified: prefetchedData?.verification_status?.product_catalogue || false,
      },
      awards_recognition_docs: {
        ...prevStatus.awards_recognition_docs,
        verified: prefetchedData?.verification_status?.awards_recognition_docs || false,
      },
     



      
      
    }));


  },[prefetchedData?.verification_status])


  const handleSubmit = () => {

    const simplifiedStatus:any = {};
            Object.keys(verificationStatus).forEach((key:any) => {
              simplifiedStatus[key] = verificationStatus[key].verified;
            });

            API.put(
              `cart/get_all_claim_requests/${prefetchedDataId}/`,
              {...simplifiedStatus, [request_type === 'Create' ? 'validate_create_business' : 'validate_claim_business']: true}
            )
              .then((res) => {
                getPreFetchedData();
              })

              .catch((err: any) => {
                Swal.fire({
                  icon: "error",
                  title: "Something went wrong",
                });
              }).finally(()=> {
                onCloseModal();
              });
  };

  useEffect(()=> {
    if (loaded) {
      handleSubmit();
      setLoaded(false);
    }

  },[verificationStatus, loaded])

  const verifiedCount = Object.keys(verificationStatus).filter(
    (key) => verificationStatus[key].verified === true
  )?.length;
  useEffect(()=> {
    
    setCounts({...counts, v3:verifiedCount})
  },[verifiedCount])

  return (
    <Box sx={{ padding: "1rem " }}>
      <Box
        sx={{
          marginTop: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
      
        <Formik
          initialValues={{


              awards_recognition_docs: prefetchedData
              ? prefetchedData.awards_recognition_docs
              : [],
              product_catalogue: prefetchedData
              ? prefetchedData.product_catalogue
              : [],
              quality_certificate
: prefetchedData
              ? prefetchedData.quality_certificate

              : [],
          }}
          // validationSchema={validationSchema}
          onSubmit={(values: any) => {
            getPreFetchedData();
             
                  API.put(
                    `/cart/get_all_claim_requests/${prefetchedDataId}/`,
                    {
                   
                      approve: true,
                    },
                    {},
                    0
                  )
                    .then((res: any) => {
                      //getAllClaimBusinessReq();
                      Swal.fire({
                        icon: "success",
                        title: "Request has been Approved Successfully",
                        showConfirmButton: false,
                        timer: 1500,
                        didClose: () => {
                         // getAllClaimBusinessReq();
                          onCloseModal();

                          window.location.reload();
                        }
                        });
                             
                          
                           
                    
                    })
                    .catch((err: any) => {
                      const { data } = err.response;
                      if (err && err.response && data) {
                        Swal.fire({
                          icon: "error",
                          html: `<div>
              <br />
              <p style="color:"red">${data[0]}</p>   
              </div>`,
                        });
                      }
                    });

            

        

            
            
          }}
          enableReinitialize={true} // Enable reinitialization when initialValues change
        >
          {({ errors, touched }: any) => (
            <Form>
              <Box sx={{ width: "100%" }}>
              <Box
                  sx={{ display: "flex", width: "100%", background: "#00887a" }}
                >
                  <Box
                    sx={{
                      padding: "1rem",
                      width: "18vw",
                      color: "#fff",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Field
                  </Box>
                  <Box
                    sx={{
                      padding: "1rem",
                      width: "18vw",
                      color: "#fff",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Old Value
                  </Box>
                  <Box
                    sx={{
                      padding: "1rem",
                      width: "18vw",
                      color: "#fff",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    New Value
                  </Box>
                  <Box
                    sx={{
                      padding: "1rem",
                      width: "18vw",
                      color: "#fff",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Verify{" "}
                    <span
                      style={{ cursor: "pointer", marginLeft: "1rem" }}
                      onClick={handleToggleVerification}
                    >
                      {`(Verify All)`}
                    </span>
                  </Box>
                </Box>
                {Object.keys(validationSchema?.fields).map(
                  (fieldName: any, index: any) => {
                    

                    return (
                      <Box
                        key={index}
                        // sx={{
                        //   padding: "1rem",
                        //   width: "25vw",
                        //   color: "#fff",
                        //   borderBottomColor: "primary.light",
                        // }}
                        sx={{
                          border: "0.1px solid primary.light",
                          display: "flex",
                        }}
                      >
                        <Box
                          key={index}
                          sx={{
                            width: "18vw",
                            padding: "1rem",
                            border: "0.1px solid #dee0e0",
                            display: "flex",
                          }}
                        >
                          {fieldName
                            ?.replace(/_/g, " ")
                            ?.replace(/\b\w/g, (char: any) =>
                              char.toUpperCase()
                            )}
                        </Box>
                        <Box
                          key={index}
                          sx={{
                            width: "18vw",
                            padding: "1rem",
                            border: "0.1px solid #dee0e0",
                            display: "flex",
                            flexWrap:"wrap"
                          }}
                        >
                          {prefetchedData?.old_data &&
                          Array.isArray(prefetchedData?.old_data?.[fieldName]) ?
                          prefetchedData?.old_data?.[fieldName].length > 0 ? (
                            prefetchedData?.old_data?.[fieldName].map((doc:any, index:any) => (
                              <Typography sx={{ fontSize: "1rem", textDecoration:"underline", color:"#247a9e", marginLeft:"1rem", cursor:"pointer" }} key={index} onClick={() => window.open(`${CDN_URL}${doc?.doc_url}`)}>
                                {doc?.doc_name}.pdf
                              </Typography>
                            ))
                          ) : (
                            <Typography variant="body2">
                              --
                            </Typography>
                          ) : (
                            <Typography variant="body2">
                              --
                            </Typography>
                          
                          )}
                        </Box>
                        <Box
                          key={index}
                          sx={{
                            width: "18vw",
                            padding: "1rem",
                            border: "0.1px solid #dee0e0",
                            display: "flex",
                            flexWrap:"wrap"
                          }}
                        >
                          {prefetchedData &&
                          Array.isArray(prefetchedData[fieldName]) ?
                          prefetchedData[fieldName].length > 0 ? (
                            prefetchedData[fieldName].map((doc:any, index:any) => (
                              <Typography sx={{ fontSize: "1rem", textDecoration:"underline", color:"#247a9e", marginLeft:"1rem", cursor:"pointer" }} key={index} onClick={() => window.open(`${CDN_URL}${doc?.doc_url}`)}>
                                {doc?.doc_name}.pdf
                              </Typography>
                            ))
                          ) : (
                            <Typography variant="body2">
                              --
                            </Typography>
                          ) : (
                            <Typography variant="body2">
                              --
                            </Typography>
                          
                          )}
                        </Box>

                        <Box
                          key={index}
                          sx={{
                            width: "18vw",
                            padding: "1rem",
                            border: "0.1px solid #dee0e0",
                            display: "flex",
                            // justifyContent: "center"
                          }}
                        >
                          {verificationStatus[fieldName].verified ? (
                            <VerifiedIcon
                              sx={{ cursor: "pointer", color: "green" }}
                              titleAccess="Verified"
                              onClick={() =>
                                handleVerificationToggle(fieldName)
                              }
                            />
                          ) : (
                            <VerifiedIcon
                              sx={{ cursor: "pointer", color: "#ffc800" }}
                              titleAccess="Not Verified"
                              onClick={() =>
                                handleVerificationToggle(fieldName)
                              }
                            />
                          )}
                        </Box>
                      </Box>
                    );
                  }
                )}
                 <Grid
                  container
                  justifyContent="space-between"
                  spacing={2}
                  sx={{ mt: 2 }}
                >
                  <Grid item>
                    <Button  onClick={handleBack} >
                    Previous
                    </Button>
                  </Grid>
                  <Grid item>
                  <Box sx={{display:"flex", gap:"1rem"}}>
                  
                    <Button variant="contained" color="primary" type="submit">
                      Submit
                    </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default VerificationStepEdit3;
