import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import CancelIcon from '@mui/icons-material/Cancel';
import {
	Divider,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import { API } from '../../api-services';
import { ScenarioColors } from './ScenarioColors.constants';
import { useBaseParamsModal } from '../../CustomHook/useBaseParamsModal';
import PartCountDetailsModal from './PartCountDetailsModal';
import { AddAssumptionScenarioDropdown } from './AddAssumptionScenarioDropdown';
import ScenarioCloseAlertModal from './ScenarioCloseAlertModal';
import Swal from 'sweetalert2';
import successIcon from '../../Assets/images/success.png';
import { useHistory } from 'react-router-dom';
import { Formik, useFormik } from 'formik';
import * as yup from 'yup';
import { set } from 'lodash';
import { AUTH } from '../../Redux/Services/auth.service';
import Autocomplete from '@mui/material/Autocomplete';

export interface IAddScenarioModalProps {
	isOpen: any;
	onCloseModal: any;
	setCount: any;
}
const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: { xs: '86vw', sm: '45vw' },
	height: 'fit-content',
	bgcolor: 'background.paper',
	borderRadius: '10px',
	p: 0,
};

const validationSchema = yup.object({
	scenario_name: yup.string().required('Please enter  Scenario Name'),
	mfg_location: yup.string().required('Please enter MFG. location'),
	annual_volume: yup.string().required('Please enter Annual Volume'),
	revision_month: yup.array().of(yup.string()).required('Please select Revision Month'),
	description: yup.string(),
	//Array of string how to validate?
	assignees: yup.array().of(yup.string().required('Please select Assignees')),
});

export function AddScenarioModalAdmin(props: IAddScenarioModalProps) {
	const { isOpen, onCloseModal, setCount } = props;
	const {
		projectId,
		projectName,
		topVault,
		vault,
		scenarioId,
		treeabbr,
		scenarioType,
		requestId,
	} = useRouteParams<any>();
	const [loader, setLoader] = React.useState<any>(false);
	const [savedScenarioId, setSavedScenarioId] = React.useState<any>('');
	React.useState<any>(false);
	const [allInternalUsers, setAllInternalUsers] = React.useState<any>([]);
	const [isShowTable, setIsShowTable] = React.useState<boolean>(false);
	React.useState<any>(null);

	const formik = useFormik({
		initialValues: {
			scenario_name: '',
			mfg_location: '',
			annual_volume: '',
			revision_month: [] as string[],
			description: '',
			assignees: [],
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			setLoader(true);

			let obj = {
				scenario_name: values.scenario_name,
				mfg_location: values.mfg_location,
				annual_volume: values.annual_volume,
				revision_month: values.revision_month,
				description: values.description,
				assignees: values.assignees,
				vault: vault,
				user: AUTH.user,
			};
			API.post('/cost/costing_scenari_requester/', obj, {}, 0)
				.then(() => {
					setLoader(false);
					setCount((prev: any) => prev + 1);
					onCloseModal();
				})
				.catch(() => {
					setLoader(false);
				});
		},
	});

	const getAllInternalUsersList = () => {
		API.get('/auth/get_all_internaluser_list/', {}, 0)
			.then((res: any) => {
				setAllInternalUsers(res?.data);
			})
			.catch((err) => {
				const message = err?.response?.data?.detail;
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: message,
					customClass: {
						container: 'swal2Container',
					},
				});
			});
	};

	React.useEffect(() => {
		getAllInternalUsersList();
	}, []);

	const ScenarioCloseAlert = useBaseParamsModal();
	const ScenarioCloseModalHandler = () => {
		if (isShowTable && savedScenarioId) {
			ScenarioCloseAlert.open(savedScenarioId);
		} else {
			onCloseModal();
		}
	};


  var currentDate = new Date();

  // Calculate the end date (six months ago, not including the current month)
  var endDate = new Date();
  endDate.setMonth(currentDate.getMonth() - 6);
  
  // Adjust the current date to the previous month
  currentDate.setMonth(currentDate.getMonth() - 1);
  
  // Loop through each month from the previous month backwards to six months ago
  const data = [];
  while (currentDate >= endDate) {
    // Extract month and year
    var month = currentDate.toLocaleString('default', { month: 'short' });
    var year = currentDate.getFullYear().toString().substr(2, 2);
    // Display the month and year pair
    const newObject = `${month} ${year}`;
    data.push(newObject);
    // Move to the previous month
    currentDate.setMonth(currentDate.getMonth() - 1);
  }
  
  const handleOptionSelect = (event:any, value:any) => {
	formik.setFieldValue('revision_month', value);
  };


	return (
		<div>
			<Modal
				open={isOpen}
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'>
				<Box sx={style}>
					<Box sx={{ display: 'flex', alignItems: 'center', height: '3.5rem' }}>
						<Typography
							style={{
								fontSize: '1rem',
								fontWeight: '600',
								width: '100%',
								textAlign: 'center',
								marginRight: '-2rem',
							}}>
							Add New Scenario Request <br />
						</Typography>
						<CancelIcon
							titleAccess='Close'
							sx={{ cursor: 'pointer', margin: '0.5rem' }}
							onClick={ScenarioCloseModalHandler}
						/>
					</Box>
					<Divider
						sx={{
							borderColor: 'primary.light',
							margin: '0 1rem',
							marginBottom: '1rem',
						}}
					/>

					<Box sx={{ padding: '0 1rem 1rem' }}>
						<form onSubmit={formik.handleSubmit}>
							<Box
								sx={{
									display: 'grid',
									gridTemplateColumns: '1fr 1fr',
									gap: '1rem',
									marginBottom: '2rem',
								}}>
								<FormControl variant='standard' fullWidth>
									<InputLabel
										shrink
										// sx={{ color: '#007fff' }}
										id='demo-simple-select-label'>
										Enter Scenario Name <sup style={{ color: 'red' }}>*</sup>
									</InputLabel>
									<TextField
										//   label="Enter Scenario Name"
										// defaultValue="Enter Scenario Name"
										//   placeholder="Enter Scenario Name"
										variant='standard'
										name='scenario_name'
										id='scenario_name'
										fullWidth
										size='small'
										value={formik.values.scenario_name}
										onChange={formik.handleChange}
										sx={{
											marginTop: '1rem',
											'.MuiInputBase-root.MuiInput-root': {
												fontSize: '1rem !important',
											},
											'& .MuiInput-underline:before': {
												borderBottomColor: 'primary.main',
											},
											'& .MuiInput-underline:after': {
												borderBottomColor: 'primary.main',
											},
										}}
										error={
											formik.touched.scenario_name &&
											Boolean(formik.errors.scenario_name)
										}
										helperText={formik.errors.scenario_name}
									/>
								</FormControl>

								<FormControl variant='standard' fullWidth>
									<InputLabel
										shrink
										// sx={{ color: '#007fff' }}
										id='demo-simple-select-label'>
										Enter MFG. location <sup style={{ color: 'red' }}>*</sup>
									</InputLabel>
									<TextField
										//   label="Enter Scenario Name"
										// defaultValue="Enter Scenario Name"
										//   placeholder="Enter Scenario Name"
										variant='standard'
										name='mfg_location'
										id='mfg_location'
										fullWidth
										size='small'
										value={formik.values.mfg_location}
										onChange={formik.handleChange}
										sx={{
											marginTop: '1rem',
											'.MuiInputBase-root.MuiInput-root': {
												fontSize: '1rem !important',
											},
											'& .MuiInput-underline:before': {
												borderBottomColor: 'primary.main',
											},
											'& .MuiInput-underline:after': {
												borderBottomColor: 'primary.main',
											},
										}}
										error={
											formik.touched.mfg_location &&
											Boolean(formik.errors.mfg_location)
										}
										helperText={
											Boolean(formik.errors.mfg_location) ? (
												formik.errors.mfg_location
											) : (
												<span style={{ fontSize: '0.75rem' }}>
													City, Country.
												</span>
											)
										}
									/>
								</FormControl>
							</Box>
							<Box
								sx={{
									display: 'grid',
									gridTemplateColumns: '1fr 1fr',
									gap: '1rem',
									marginBottom: '2rem',
								}}>
								<FormControl variant='standard' fullWidth>
									<InputLabel
										shrink
										// sx={{ color: '#007fff' }}
										id='demo-simple-select-label'>
										Enter Annual Volume <sup style={{ color: 'red' }}>*</sup>
									</InputLabel>
									<TextField
										inputProps={{
											step: 'any',
											min: 1,
										}}
										variant='standard'
										name='annual_volume'
										id='annual_volume'
										type='number'
										fullWidth
										size='small'
										value={formik.values.annual_volume}
										onChange={formik.handleChange}
										sx={{
											marginTop: '1rem',
											'.MuiInputBase-root.MuiInput-root': {
												fontSize: '1rem !important',
											},
											'& .MuiInput-underline:before': {
												borderBottomColor: 'primary.main',
											},
											'& .MuiInput-underline:after': {
												borderBottomColor: 'primary.main',
											},
										}}
										error={
											formik.touched.annual_volume &&
											Boolean(formik.errors.annual_volume)
										}
										helperText={formik.errors.annual_volume}
									/>
								</FormControl>

								<FormControl variant='standard' fullWidth>
									<InputLabel
										shrink
										// sx={{ color: '#007fff' }}
										id='demo-simple-select-label'>
										Enter Descriptions
									</InputLabel>
									<TextField
										//   label="Enter Scenario Name"
										// defaultValue="Enter Scenario Name"
										//   placeholder="Enter Scenario Name"
										variant='standard'
										name='description'
										id='description'
										fullWidth
										size='small'
										value={formik.values.description}
										onChange={formik.handleChange}
										sx={{
											marginTop: '1rem',
											'.MuiInputBase-root.MuiInput-root': {
												fontSize: '1rem !important',
											},
											'& .MuiInput-underline:before': {
												borderBottomColor: 'primary.main',
											},
											'& .MuiInput-underline:after': {
												borderBottomColor: 'primary.main',
											},
										}}
										error={
											formik.touched.description &&
											Boolean(formik.errors.description)
										}
										helperText={formik.errors.description}
									/>
								</FormControl>
							</Box>
							<Box
								sx={{
									display: 'grid',
									gridTemplateColumns: '1fr 1fr',
                 					alignItems:'flex-start',
									gap: '1rem',
	
								}}>
								<FormControl
									variant='standard'
									fullWidth
									sx={{ marginBottom: '2rem' }}>
									<InputLabel
										shrink
										sx={{ color: 'primary.main' }}
										id='demo-simple-select-label'>
										Select Revision Month<sup style={{ color: 'red' }}>*</sup>
									</InputLabel>
									<Autocomplete
										multiple
										id='revision_month'
										options={data.filter(option => !formik.values.revision_month.includes(option))}
										getOptionLabel={(option) => option}
										value={formik.values.revision_month}
										onChange={handleOptionSelect}
										sx={{
											'.MuiButtonBase-root.MuiChip-root': {
												backgroundColor: 'primary.light',
												height: '26px',
											},
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												variant='standard'
												// label='Select Revision Month'
												// placeholder='Select months'
												sx={{
													marginTop: '1rem',
													'.MuiInputBase-root.MuiInput-root': {
														fontSize: '1rem !important',
													},
													'& .MuiInput-underline:before': {
														borderBottomColor: 'primary.main',
													},
													'& .MuiInput-underline:after': {
														borderBottomColor: 'primary.main',
													},
												}}
												error={
													formik.touched.revision_month &&
													Boolean(formik.errors.revision_month)
												}
												helperText={
													formik.touched.revision_month &&
													formik.errors.revision_month
												}
											/>
										)}
									/>                 
                 {formik.values.revision_month.length > 1 && (
                    <Typography variant="body2" color="error" sx={{marginTop:'0.2rem',lineHeight:'1.2'}}>
                    Note:-  If more than one month chosen average of selected months will be taken
                    </Typography>
                  )}
								</FormControl>
								<Box>
									<FormControl variant='standard' fullWidth>
										<InputLabel
											shrink
											sx={{ color: 'primary.main' }}
											id='demo-simple-select-label'>
											Assign To <sup style={{ color: 'red' }}>*</sup>
										</InputLabel>
										<Select
											size='small'
											labelId='demo-simple-select-label'
											id='assignees'
											multiple
											name='assignees'
											value={formik.values.assignees}
											onChange={formik.handleChange}
											sx={{
												width: '100%',
												'&:before': {
													borderBottomColor: 'primary.main',
												},
												'& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select':
													{
														fontSize: '1rem',
														paddingLeft: '0.5rem',
														paddingBottom: '0rem',
														paddingTop: '0.5rem',
													},
												'.MuiSvgIcon-root.MuiSelect-icon': {
													color: 'primary.main',
												},
											}}>
											{allInternalUsers ? (
												allInternalUsers?.map((item: any) => {
													return (
														<MenuItem
															sx={{ fontSize: '1rem' }}
															key={item.id}
															value={item.id}>
															{item?.first_name}
														</MenuItem>
													);
												})
											) : (
												<Skeleton animation='wave' />
											)}
										</Select>
                    <div
										style={{
											color: '#d32f2f',
											fontFamily: 'Roboto',
											fontWeight: 400,
											fontSize: '0.8571428571428571rem',
											lineHeight: 1.66,
											textAlign: 'left',
											marginTop: '3px',
											marginRight: 0,
											marginBottom: 0,
											marginLeft: 0,
										}}>
										{formik.touched.assignees &&
											Boolean(formik.errors.assignees) && (
												<p>{formik.errors.assignees}</p>
											)}
									</div>
									</FormControl>
									
								</Box>
							</Box>

							<Box sx={{ width: '100%', textAlign: 'right' }}>								
								<LoadingButton
									loading={loader}
									size='small'
									type='submit'
									variant='contained'>
									Add Scenario
								</LoadingButton>
							</Box>
						</form>
					</Box>
				</Box>
			</Modal>
		</div>
	);
}
