import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import {
	Divider,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

export interface IZBCHistoryModalProps {
	isOpen: boolean;
	onCloseModal: () => void;
	zbcHistory: any;
}

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '95vw',
	height: 'auto',
	maxHeight: '90vh',
	bgcolor: 'background.default',
	borderRadius: '1rem',
	p: 1,
	focusVisible: 'none !important',
};

export default function ZBCHistoryModal(
	props: IZBCHistoryModalProps
) {
	const { isOpen, onCloseModal, zbcHistory } = props;

	console.log(zbcHistory, 'zbcHistory');

	return (
		<div>
			<Modal
				open={isOpen}
				onClose={onCloseModal}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'>
				<Box sx={style}>
					<Box sx={{ width: '100%' }}>
						<Box
							sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
							<Typography
								style={{
									fontSize: '1rem',
									fontWeight: '600',
									width: '100%',
									textAlign: 'center',
								}}>
								Zero-based Costing Request History
							</Typography>
							<CancelIcon
								sx={{
									cursor: 'pointer',
									marginLeft: '-2rem',
								}}
								onClick={onCloseModal}
							/>
						</Box>
						<Divider
							sx={{
								borderColor: 'primary.light',
								margin: '0 1rem',
								marginBottom: '1rem',
							}}
						/>
						<Box>
							<Table
								stickyHeader
								aria-label='sticky table'
								adl-scrollbar='true'
								adl-scrollbar-width='0.3'>
								<TableHead>
									<TableRow>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												backgroundColor: 'primary.light',
											}}>
											S.No
										</TableCell>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												backgroundColor: 'primary.light',
											}}>
											Request Id
										</TableCell>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												backgroundColor: 'primary.light',
											}}>
											ZBC Type
										</TableCell>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												backgroundColor: 'primary.light',
											}}>
											Part Name
										</TableCell>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												backgroundColor: 'primary.light',
											}}>
											Currency
										</TableCell>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												backgroundColor: 'primary.light',
											}}>
											Quoted Amount
										</TableCell>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												backgroundColor: 'primary.light',
											}}>
											Paid Amount After Discount
										</TableCell>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												backgroundColor: 'primary.light',
											}}>
											Payment Date
										</TableCell>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												backgroundColor: 'primary.light',
											}}>
											Payment Status
										</TableCell>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												backgroundColor: 'primary.light',
											}}>
											Amount Paid
										</TableCell>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												backgroundColor: 'primary.light',
											}}>
											Location
										</TableCell>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												backgroundColor: 'primary.light',
											}}>
											Part Received Date
										</TableCell>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												backgroundColor: 'primary.light',
											}}>
											Due Date
										</TableCell>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												backgroundColor: 'primary.light',
											}}>
											Annual Volume
										</TableCell>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												backgroundColor: 'primary.light',
											}}>
											Revised On
										</TableCell>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												backgroundColor: 'primary.light',
											}}>
											Request Status
										</TableCell>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												backgroundColor: 'primary.light',
											}}>
											Invoice
										</TableCell>
									</TableRow>
								</TableHead>
								{zbcHistory.length > 0 ? (
									<TableBody>
										{zbcHistory.map((sub: any, index: number) => (
											<TableRow key={index}>
												<TableCell
													sx={{
														padding: '0.2rem 0.5rem',
														borderBottomColor: 'primary.light',
													}}>
													{index + 1}
												</TableCell>
												<TableCell
													sx={{
														padding: '0.2rem 0.5rem',
														borderBottomColor: 'primary.light',
													}}>
													{sub.request_id}
												</TableCell>
												<TableCell
													sx={{
														padding: '0.2rem 0.5rem',
														borderBottomColor: 'primary.light',
													}}>
													{sub.zbc_type}
												</TableCell>
												<TableCell
													sx={{
														padding: '0.2rem 0.5rem',
														borderBottomColor: 'primary.light',
													}}>
													{sub.part_name}
												</TableCell>
												<TableCell
													sx={{
														padding: '0.2rem 0.5rem',
														borderBottomColor: 'primary.light',
													}}>
													{sub.currency}
												</TableCell>
												<TableCell
													sx={{
														padding: '0.2rem 0.5rem',
														borderBottomColor: 'primary.light',
													}}>
													{sub.quoted_amount}
												</TableCell>
												<TableCell
													sx={{
														padding: '0.2rem 0.5rem',
														borderBottomColor: 'primary.light',
													}}>
													{sub.payable_amount_after_discount}
												</TableCell>
												<TableCell
													sx={{
														padding: '0.2rem 0.5rem',
														borderBottomColor: 'primary.light',
													}}>
													{sub.payment_status_updated_at}
												</TableCell>
												<TableCell
													sx={{
														padding: '0.2rem 0.5rem',
														borderBottomColor: 'primary.light',
													}}>
													{sub.payment_status}
												</TableCell>
												<TableCell
													sx={{
														padding: '0.2rem 0.5rem',
														borderBottomColor: 'primary.light',
													}}>
													{sub.cart_value_paid}
												</TableCell>
												<TableCell
													sx={{
														padding: '0.2rem 0.5rem',
														borderBottomColor: 'primary.light',
													}}>
													{sub.mfg_location}
												</TableCell>
												<TableCell
													sx={{
														padding: '0.2rem 0.5rem',
														borderBottomColor: 'primary.light',
													}}>
													{sub.mfg_location}
												</TableCell>
												<TableCell
													sx={{
														padding: '0.2rem 0.5rem',
														borderBottomColor: 'primary.light',
													}}>
													{sub.mfg_location}
												</TableCell>
												<TableCell
													sx={{
														padding: '0.2rem 0.5rem',
														borderBottomColor: 'primary.light',
													}}>
													{sub.annual_volume}
												</TableCell>
												<TableCell
													sx={{
														padding: '0.2rem 0.5rem',
														borderBottomColor: 'primary.light',
													}}>
													{sub.revised_on}
												</TableCell>
												<TableCell
													sx={{
														padding: '0.2rem 0.5rem',
														borderBottomColor: 'primary.light',
													}}>
													{sub.revised_on}
												</TableCell>
												<TableCell
													sx={{
														padding: '0.2rem 0.5rem',
														borderBottomColor: 'primary.light',
													}}>
													{sub.invoice}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								) : (
									<TableBody>
										<TableRow>
											<TableCell
												colSpan={11}
												sx={{
													padding: '0.2rem 0.5rem',
													borderBottomColor: 'primary.light',
													textAlign: 'center',
												}}>
												No Data Found
											</TableCell>
										</TableRow>
									</TableBody>
								)}
							</Table>
						</Box>
					</Box>
				</Box>
			</Modal>
		</div>
	);
}
