import * as React from "react";
import styles from "./QuickCompareLanding.module.scss";
import {
  Box,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import RestoreIcon from "@mui/icons-material/Restore";
import { useState } from "react";
import PushPinIcon from "@mui/icons-material/PushPin";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useHistory } from "react-router-dom";
import { API } from "../../api-services";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useBaseModal } from "../SearchPage/useBaseModal";
import CompareHistoryModal from "./CompareHistoryModal";
import Image from "../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import placeholderImg from "../../Assets/images/loading-screen.gif";
// import compro from "../../Assets/images/Compareproduct.png";
import Draggable from "react-draggable";
import compareempty from "../../Assets/images/compareempty.png";

interface IQuickCompareLandingProps {
  setCloseCounts: any;
}

function QuickCompareLanding(props: IQuickCompareLandingProps) {
  const { setCloseCounts } = props;
  const BoxRef = React.useRef<HTMLDivElement>(null);
  const histroyModal = useBaseModal();
  const store = useSelector((state) => state);
  const [isActive, setIsActive] = useState(true);
  const [modalClick, setModalClick] = useState<any>(false);
  const [searchHistory, setSearchHistory] = React.useState<any>([]);
  const [compareOptions, setCompareOptions] = useState<any>([]);
  const [closeCount, setCloseCount] = useState<any>(0);
  const { projectId } = useRouteParams<any>();

  const dispatch = useDispatch();

  const compareOpen = () => {
    // 👇️ toggle
    setIsActive((current) => !current);

    // 👇️ or set to true
    // setIsActive(true);
  };

  const divRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        // Click occurred outside the div, so hide it
        // Perform any necessary logic her
        if (event.target instanceof SVGPathElement) {
        } else if (event.target instanceof SVGSVGElement) {
        } else {
          setIsActive(true);
        }
      }
    };

    // Bind the event listener when the component mounts
    document.addEventListener("mousedown", handleOutsideClick);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const compareButtonClicked = () => {
    API.post(
      "/cost/compare_cart/",
      {
        add_compare: true,
      },
      {},
      0
    )
      .then((res: any) => {
        setIsActive((current) => !current);
        history.push(`/CompareCombination/${res.data?.id}`);
      })
      .catch((err: any) => {});
  };

  const history = useHistory<any>();
  const getHistoryData = () => {
    API.get(
      "/cost/compare_cart/",
      {
        project: sessionStorage.getItem("project"),
        compare_history: true,
      },
      0
    )
      .then((res: any) => {
        setSearchHistory(res.data);
      })
      .catch((err: any) => {});
  };
  React.useEffect(() => {
    if (!isActive) {
      getHistoryData();
    }
  }, [isActive, closeCount]);
  const openHistory = () => {
    setModalClick(true);
    histroyModal.open();
  };
  const historyCard = () => {
    API.get("/cost/compare_cart/", {}, 0)
      .then((res: any) => {
        setCompareOptions(res.data);
        // compareOptions[0]?.vault_list
        // dispatch(setCompareOPtions(compareOptions[0]?.vault_list))
      })
      .catch((err: any) => {});
  };

  React.useEffect(() => {
    historyCard();
    //@ts-ignore
  }, [isActive, closeCount, store.is_compare?.add_count]);
  //@ts-ignore
  const getBlankCard = (count: any) => {
    let Arr = [];
    if (!isNaN(count)) {
      for (let i = 1; i <= count; i++) {
        Arr.push(
          <Box sx={{ display: "flex", columnGap: "5px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: "5px",
                height: "5rem",
                width: "100%",
                margin: "auto",
              }}
            >
              <Box sx={{ width: "18rem", textAlign: "center" }}>
                <Image
                  src={compareempty}
                  placeholderImg={placeholderImg}
                  style={{ height: "5rem", width: "auto", margin: "auto" }}
                />
                {/* <Typography sx={{ fontSize: "1rem" }}>Add Compare</Typography> */}
              </Box>
            </Box>
            {i !== count && (
              <Divider orientation="vertical">
                <Box
                  sx={{
                    width: "2rem",
                    height: "2rem",
                    backgroundColor: "#007fff14",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  VS
                </Box>
              </Divider>
            )}
          </Box>
        );
      }
    } else {
      for (let i = 1; i <= 4; i++) {
        Arr.push(
          <Box sx={{ display: "flex", columnGap: "5px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                columnGap: "5px",
                height: "5rem",
                width: "100%",
                margin: "auto",
              }}
            >
              <Box sx={{ textAlign: "center" }}>
                <Image
                  src={compareempty}
                  placeholderImg={placeholderImg}
                  style={{ height: "5rem", width: "auto", margin: "auto" }}
                />
              </Box>
              {/* <Typography sx={{ fontSize: "1rem" }}>Add Compare</Typography> */}
            </Box>
          </Box>
        );
      }
    }
    return Arr;
  };

  return (
    <>
      {/* <div id="dropDiv" style={{backgroundColor:'green',width:'100vh',height:'10rem'}}></div> */}
      <div ref={divRef}>
        <Box
          className={
            isActive ? styles.quickCompareEnable : styles.quickCompareOpen
          }
        >
          <Box className={styles.mainCompare}>
            <Draggable
              axis="x"
              // defaultPosition={{x: 200, y: 0}}
              // bounds={{left: 0, right: width > 1400 ? 1200 : 800,}}
            >
              <div style={{ padding: "0 1rem", cursor: "pointer" }}>
                <Typography
                  className={styles.titleText}
                  sx={{ padding: "0 1rem" }}
                >
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      cursor: "grab",
                      color: "white",
                      marginLeft: "0.3rem",
                      lineHeight: "1",
                      "&:hover": {
                        cursor: "grabbing",
                      },
                    }}
                  >
                    Quick Compare
                  </Typography>

                  {compareOptions &&
                    compareOptions[0]?.vault_list?.length > 0 && (
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: "1rem",
                          textAlign: "center",
                          marginLeft: "-0.5rem",
                          width: "1.5rem",
                          height: "1.5rem",
                          borderRadius: "50%",
                          margin: "auto",
                          backgroundColor: "red",
                        }}
                        onClick={compareOpen}
                      >
                        {compareOptions[0]?.vault_list?.length}
                      </Typography>
                    )}
                  {isActive ? (
                    <KeyboardArrowUpIcon
                      sx={{ color: "white", marginLeft: "-4px" }}
                      onClick={compareOpen}
                    />
                  ) : (
                    <KeyboardArrowDownIcon
                      sx={{ color: "white", marginLeft: "-4px" }}
                      onClick={compareOpen}
                    />
                  )}
                </Typography>
              </div>
            </Draggable>

            <Box className={styles.contSection}>
              {/* <Typography
              sx={{ fontSize: "1rem", width: "100%", textAlign: "center" }}
            >
              Quick Compare
            </Typography>
            <Divider /> */}
              {compareOptions && compareOptions[0]?.vault_list?.length > 0 ? (
                <Box
                  sx={{
                    height: "10rem",
                    // marginRight: "-1rem",
                    // paddingRight: "1rem",
                  }}
                  adl-scrollbar="true"
                  adl-scrollbar-width="0.3"
                >
                  <Box
                    sx={{
                      display: "flex",
                      margin: "0 auto",
                      width: "100%",
                      height: "10rem",
                    }}
                  >
                    {compareOptions &&
                      compareOptions[0]?.vault_list?.map(
                        (item: any, index: any) => {
                          return (
                            <>
                              <Box sx={{ width: "18rem", height: "6rem" }}>
                                <Box
                                  sx={{ display: "block", columnGap: "5px" }}
                                >
                                  <Box
                                    sx={{
                                      width: "100%",
                                      textAlign: "right",
                                      marginBottom: "-1rem",
                                    }}
                                  >
                                    <HighlightOffIcon
                                      titleAccess="Remove"
                                      sx={{ cursor: "pointer" }}
                                      onClick={() => {
                                        API.post(
                                          "/cost/compare_cart/",
                                          {
                                            vault: item?.id,
                                            remove_part: true,
                                          },
                                          {},
                                          0
                                        )
                                          .then((res: any) => {
                                            setCloseCount(
                                              (prev: any) => prev + 1
                                            );
                                          })
                                          .catch((err: any) => {});
                                      }}
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      textAlign: "center",
                                      marginTop: "-2.4rem",
                                    }}
                                  >
                                    <Image
                                      src={
                                        item?.image ? item?.image : compareempty
                                      }
                                      placeholderImg={placeholderImg}
                                      style={{
                                        height: "5rem",
                                        width: "auto",
                                        margin: "auto",
                                        objectFit: "contain",
                                      }}
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      columnGap: "5px",
                                    }}
                                  >
                                    <Box>
                                      <InsertDriveFileIcon />
                                    </Box>
                                    <Box sx={{ width: "100%" }}>
                                      <Typography
                                        sx={{
                                          fontSize: "1rem",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                          width: "16rem",
                                        }}
                                      >
                                        {item?.name}
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontSize: "1rem",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                          width: "16rem",
                                        }}
                                      >
                                        {item?.parent_name}
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontSize: "1rem",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                          width: "16rem",
                                        }}
                                      >
                                        {item?.product_name}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                              {index !== 3 && (
                                <Divider orientation="vertical">
                                  <Box
                                    sx={{
                                      width: "2rem",
                                      height: "2rem",
                                      backgroundColor: "#007fff14",
                                      borderRadius: "50%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    VS
                                  </Box>
                                </Divider>
                              )}
                            </>
                          );
                        }
                      )}
                    {getBlankCard(4 - compareOptions[0]?.vault_list?.length)}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: "2rem",
                        columnGap: "2rem",
                      }}
                    >
                      <Button
                        onClick={() => compareButtonClicked()}
                        variant="contained"
                        size="small"
                        sx={{
                          "&:hover": {
                            transform: "Scale(1.05)",
                            transition: "transform 0.5s ease",
                          },
                        }}
                      >
                        Compare
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => openHistory()}
                        size="small"
                        sx={{
                          "&:hover": {
                            transform: "Scale(1.05)",
                            transition: "transform 0.5s ease",
                          },
                        }}
                      >
                        Compare History
                      </Button>
                    </Box>
                    {/* <Typography sx={{ fontSize: "1rem", color:'red',display: 'flex', alignItems: 'center', justifyContent: 'center' }}>* Add compare using right click on Hierarchy </Typography> */}
                  </Box>
                  {/* <Box>
                <Box sx={{ display: "flex", marginBottom: "0.2rem" }}>
                  <RestoreIcon />
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      width: "100%",
                      textAlign: "center",v
                      lineHeightL: "1.2",
                    }}
                  >
                    Compare History
                  </Typography>
                </Box>
                <Divider />
                <Box>
                  <Table>
                    <TableBody>
                      {searchHistory &&
                        searchHistory?.map((item: any, index: any) => {
                          return (
                            <TableRow sx={{cursor:"pointer"}} onClick={()=>history.push(`/CompareCombination/${item?.id}`)}>
                              <TableCell
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottom: "none",
                                }}
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottom: "none",
                                }}
                              >
                                <Typography
                                  sx={{ fontSize: "1rem", width: "10.6rem" }}
                                >
                                  {item?.name}
                                </Typography>
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottom: "none",
                                }}
                              >
                                <PushPinIcon />
                                <DeleteForeverIcon />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </Box>
              </Box> */}
                </Box>
              ) : (
                <Box
                  sx={{
                    height: "10rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography sx={{ fontSize: "1rem" }}>
                    {
                      "Add parts/assemblies for comparison by [right click -> add to compare] on that part in the BOM tree."
                    }
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        {histroyModal.isOpen && (
          <CompareHistoryModal
            Open={histroyModal.isOpen}
            Close={histroyModal.close}
            setModalClick={setModalClick}
          />
        )}
      </div>
    </>
  );
}
export default QuickCompareLanding;
