import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import swal from "sweetalert";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { useEffect, useState } from "react";
import { API } from "../../api-services";
import React from "react";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import InfoIcon from "@mui/icons-material/Info";
import Popover from '@mui/material/Popover';



const popstyled = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50rem",
  height: 'fit-content',
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

// @ts-ignore

const useStyles = makeStyles((theme) => ({
  /** Changed modalStyle */
  ModalRoot: {
    backgroundColor: "rgba(111, 126, 140, 0.2) !important",
    backdropFilter: "blur(1px) !important",
  },
}));

export interface BOMCustomValidationModalProps {
  isOpen: any;
  onCloseModal: any;

  type: string;
  nameHeader: any;
  ShareSwalAlertHandler: any;
  setParentCount?: any;
  setDeleteCounter?: any;
}

const BomConfigurationShareModal = (props: BOMCustomValidationModalProps) => {
  const { isOpen, onCloseModal, type, nameHeader, ShareSwalAlertHandler, setParentCount,
    setDeleteCounter } =
    props;

  const {
    projectId,
    vehicleCategoryId,
    projectname,
    categoryId,
    productId,
    datatype,
    validationId,
    categoryname,
  } = useRouteParams<any>();
  const [projectList, setProjectList] = useState<any>([]);
  const [selectProject, setSelectProject] = useState<any>({});
  const [productList, setProductList] = useState<any>([]);
  const [categoryList, setCategoryList] = useState<any>([])
  const [selectcategory, setSelectcategory] = useState<any>({});
  const [selectProduct, setSelectProduct] = useState<any>({});
  const [shareLoadings, setShareLoadings] = useState<boolean>(false);
  const [Loadings, setLoadings] = useState<boolean>(false);
  //   const [categoryList,setCategoryList]=useState<any>();
  //   const [selectCategory,setSelectCategory]=useState<any>();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);




  const classes = useStyles();

  useEffect(() => {
    if (type == "category") {
      API.get("/bom/template_match/", { temp_category: nameHeader?.id }, 0)
        .then((res: any) => {
          setProductList(res?.data);
        })
        .catch((err: any) => {
          console.log("Server Error");
        });
    }
    else {
      API.get("/bom/template_match/", { cur_product: productId }, 0)
        .then((res: any) => {
          setProjectList(res?.data);
        })
        .catch((err: any) => {
          console.log("Server Error");
        });
    }


  }, [nameHeader]);

  const handleChangeProject = (value: any) => {
    setSelectProject(value);
    setSelectProduct({});
    setProductList([]);
    setLoadings(true);
    API.get(
      "/bom/template_match/",
      { target_project: value?.p_id, product: productId },
      0
    )
      .then((res: any) => {
        setProductList(res?.data);
        setLoadings(false);
      })
      .catch((err: any) => {
        console.log(err);
        setLoadings(false);
      });
  };
  const handleChangeshareProduct = (value: any) => {
    setSelectProduct(value);
    setSelectcategory({});
    setCategoryList([]);
    setLoadings(true);
    API.get(
      "/bom/template_match/",
      { product_category: value?.id },
      0
    )
      .then((res: any) => {
        setCategoryList(res?.data);
        setLoadings(false);
      })
      .catch((err: any) => {
        console.log(err);
        setLoadings(false);
      });
  };




  const handleChangeCategory = (value: any) => {
    setSelectcategory(value);
  };
  const handleChangeProduct = (value: any) => {
    setSelectProduct(value);
  };

  const Handlershare = () => {
    setShareLoadings(true);
    if (type == "productLevel") {
      var Selectdata = [selectProduct?.id];

      // selectProject &&
      API.post("/bom/share_config/", {
        force: true,
        vehicle_category: vehicleCategoryId,
        module: 6,
        type: 3,
        target_top_vaults: Selectdata,
      },{},0)
        .then((res: any) => {
          setProjectList(res?.data);
          ShareSwalAlertHandler(true);
          setParentCount((prev: any) => prev + 1)
          setDeleteCounter((prev: any) => prev + 1)
          setShareLoadings(false);
          onCloseModal();
        })
        .catch((err: any) => {
          var { data } = err?.response;

          setShareLoadings(false);

          if (data?.type == "warning") {
            swal({
              text: `${data?.message}`,
              icon: "Warning",
              //@ts-ignore
              buttons: {
                cancel: "No",
                permanent: {
                  text: `Yes `,
                  value: `deleteGroup`,
                },
              },
              closeOnClickOutside: false,
              closeOnEsc: false,
              className: "delete-swal",
            }).then(async (type: string) => {
              switch (type) {
                case "deleteGroup":
                  API.post("/bom/share_config/", {
                    force: true,
                    vehicle_category: vehicleCategoryId,
                    module: 6,
                    type: 3,
                    target_top_vaults: Selectdata,
                  },{},0)
                    .then((res: any) => {
                      ShareSwalAlertHandler(true);
                      setParentCount((prev: any) => prev + 1)
                      setDeleteCounter((prev: any) => prev + 1)
                      onCloseModal();
                    })
                    .catch((err: any) => {
                      console.log(err, "Unable to upload file. Server Error");
                      onCloseModal();

                    });
                  break;
                default:
              }
            });
          }
          if (data?.type == "Error") {
            onCloseModal();
            Swal.fire({
              icon: "error",
              html: `<div>
    <br />
    <p style="color:red;">${data?.message}
    </p>
     </div>`,
            });
          } else {
            onCloseModal();

          }
        });
    }

    if (type == "workstations") {
      var Selectdata = [selectProduct?.id];

      API.post("/bom/share_config/", {
        vehicle_category: vehicleCategoryId,
        type: 1,
        target_top_vaults: Selectdata,
      },{},0)
        .then((res: any) => {
          setProjectList(res?.data);
          ShareSwalAlertHandler(true);
          setShareLoadings(false);
          onCloseModal();
          setParentCount((prev: any) => prev + 1)
          setDeleteCounter((prev: any) => prev + 1)
        })
        .catch((err: any) => {
          var { data } = err?.response;

          setShareLoadings(false);

          if (data?.type == "warning") {
            swal({
              text: `${data?.message}`,
              icon: "Warning",
              //@ts-ignore
              buttons: {
                cancel: "No",
                permanent: {
                  text: `Yes `,
                  value: `deleteGroup`,
                },
              },
              closeOnClickOutside: false,
              closeOnEsc: false,
              className: "delete-swal",
            }).then(async (type: string) => {
              switch (type) {
                case "deleteGroup":
                  API.post("/bom/share_config/", {
                    force: true,
                    vehicle_category: vehicleCategoryId,
                    module: 6,
                    type: 3,
                    target_top_vaults: Selectdata,
                  },{},0)
                    .then((res: any) => {
                      ShareSwalAlertHandler(true);
                      setParentCount((prev: any) => prev + 1)
                      setDeleteCounter((prev: any) => prev + 1)
                      onCloseModal();
                    })
                    .catch((err: any) => {
                      console.log(err, "Unable to upload file. Server Error");
                      onCloseModal();

                    });
                  break;
                default:
              }
            });
          }
          if (data?.type == "Error") {
            onCloseModal();
            Swal.fire({
              icon: "error",
              html: `<div>
    <br />
    <p style="color:red;">${data?.message}
    </p>
     </div>`,
            });
          } else {
            onCloseModal();

          }
        });
    } if (type == "category") {

      var Selectdata = [selectProduct?.id];
      // selectProject &&
      API.post("/bom/share_config/", {
        force: false,
        vehicle_category: vehicleCategoryId,
        category: categoryId,
        module: 6,
        type: 3,
        selected_category: selectcategory?.cat_id,
        target_top_vaults: Selectdata,
      },{},0)
        .then((res: any) => {
          setProjectList(res?.data);
          setShareLoadings(false);
          ShareSwalAlertHandler(true);
          setParentCount((prev: any) => prev + 1)
          setDeleteCounter((prev: any) => prev + 1)
          onCloseModal();
        })
        .catch((err: any) => {
          var { data } = err?.response;

          setShareLoadings(false);

          if (data?.type == "Warning") {
            swal({
              text: `${data?.message}`,
              icon: "warning",
              //@ts-ignore
              buttons: {
                cancel: "No",
                permanent: {
                  text: `Yes `,
                  value: `deleteGroup`,
                },
              },
              closeOnClickOutside: false,
              closeOnEsc: false,
              className: "delete-swal",
            }).then(async (type: string) => {
              switch (type) {
                case "deleteGroup":
                  API.post("/bom/share_config/", {
                    force: true,
                    vehicle_category: vehicleCategoryId,
                    category: categoryId,
                    module: 6,
                    type: 3,
                    selected_category: selectcategory?.cat_id,
                    target_top_vaults: Selectdata,
                  },{},0)
                    .then((res: any) => {
                      ShareSwalAlertHandler(true);
                      setParentCount((prev: any) => prev + 1)
                      setDeleteCounter((prev: any) => prev + 1)
                      onCloseModal();
                    })
                    .catch((err: any) => {
                      console.log(err, "Unable to upload file. Server Error");
                      onCloseModal();

                    });
                  break;
                default:
              }
            });
          }
          if (data?.type == "Error") {
            onCloseModal();
            Swal.fire({
              icon: "error",
              html: `<div>
    <br />
    <p style="color:red;">${data?.message}
    </p>
     </div>`,
            });
          } else {
            onCloseModal();

          }
        });
    }
  };




  return (
    <div>
      <Modal
        open={isOpen}
        className={classes.ModalRoot}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={popstyled}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                columnGap: '1rem',

              }}>
              {type == "productLevel"
                ? nameHeader?.name
                : type == "workstations"
                  ? ""
                  : nameHeader?.commodity_name}
              &nbsp;
              {type == "productLevel"
                ? "Product Level Share"
                : type == "workstations"
                  ? "Work Station Share"
                  : "Category Level  Share"}

              {type == "category" && (
                <div>
                  <Typography sx={{ lineHeight: '1' }}
                    aria-owns={open ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                  >
                    <InfoIcon />
                  </Typography>
                  <Popover
                    id="mouse-over-popover"
                    sx={{
                      pointerEvents: 'none',
                      paddingRight: '1rem'
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                  >
                    <Box sx={{ padding: '0.5rem' }}>
                      <Typography sx={{ fontSize: '1rem' }}>Sharing Information</Typography>
                      <Divider sx={{ borderColor: 'primary.light' }} />
                      <Typography sx={{ fontSize: '0.87rem' }}>
                        1. Category & Parameter can be shared within the project
                        only.
                      </Typography>
                      <Typography sx={{ fontSize: '0.87rem' }}>
                        2. If the destination product does not have the source
                        category.The source categories along with parameters
                        will be created.
                      </Typography>
                      <Typography sx={{ fontSize: '0.87rem' }}>
                        3. If the destination product has the category the
                        parameter will be overwritten.
                      </Typography>
                    </Box>
                  </Popover>
                </div>

                // <Tooltip
                //   title={
                //     <p>
                //       <ul style={{ fontSize: "0.8rem" }}>
                //         <li>
                //           Category & Parameter can be shared within the project
                //           only.
                //         </li>
                //         <li>
                //           If the destination product does not have the source
                //           category.The source categories along with parameters
                //           will be created.
                //         </li>
                //         <li>
                //           If the destination product has the category the
                //           parameter will be overwritten.
                //         </li>
                //       </ul>
                //     </p>
                //   }
                //   placement="right-start">
                //   <InfoIcon />
                // </Tooltip>
              )}
            </Typography>

            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />

          <Box
            sx={{
              display: "flex",
              alignItems: 'center',
              columnGap: "1rem",
              padding: '0 0.5rem',
            }}>
            {type == "category" ? (
              <>
                <FormControl fullWidth>
                  <InputLabel sx={{ color: 'primary.main', marginLeft: '-1rem' }} shrink>Product</InputLabel>
                  <Autocomplete
                    value={selectProduct && selectProduct}
                    onChange={(event, newValue) => {
                      handleChangeshareProduct(newValue);
                    }}
                    options={productList && productList}
                    getOptionLabel={(option) =>
                      option?.name == undefined ? "" : option?.name
                    }
                    sx={{
                      marginTop: '0.5rem',
                      '.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator': {
                        color: 'primary.main'
                      },
                    }}

                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label="Product"
                        // placeholder="Product"
                        variant="standard"
                        sx={{
                          ".MuiInputBase-root.MuiInput-root": {
                            fontSize: '1rem !important'
                          },
                          '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                          '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                        }}
                      />
                    )}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel sx={{ color: 'primary.main', marginLeft: '-1rem' }} shrink>Category</InputLabel>
                  <Autocomplete
                    value={selectcategory && selectcategory}
                    onChange={(event, newValue) => {
                      handleChangeCategory(newValue);
                    }}
                    options={categoryList && categoryList}
                    getOptionLabel={(option) =>
                      option?.cat_name == undefined ? "" : option?.cat_name
                    }
                    sx={{
                      marginTop: '0.5rem',
                      '.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator': {
                        color: 'primary.main'
                      },
                    }}

                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label="Category"
                        variant="standard"
                        // placeholder="Category"
                        sx={{
                          ".MuiInputBase-root.MuiInput-root": {
                            fontSize: '1rem !important'
                          },
                          '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                          '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                        }}
                      />
                    )}
                  />
                </FormControl>
              </>
            ) : (
              <>
                <FormControl fullWidth>
                  <InputLabel sx={{ color: 'primary.main', marginLeft: '-1rem' }} shrink>Project</InputLabel>
                  <Autocomplete
                    value={selectProject && selectProject}
                    onChange={(event, newValue) => {
                      handleChangeProject(newValue);
                    }}
                    options={projectList && projectList}
                    getOptionLabel={(option) =>
                      option?.p_name == undefined ? "" : option?.p_name
                    }
                    sx={{
                      marginTop: '0.5rem',
                      '.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator': {
                        color: 'primary.main'
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label="Project"
                        variant="standard"
                        // placeholder="Project"
                        sx={{
                          ".MuiInputBase-root.MuiInput-root": {
                            fontSize: '1rem !important'
                          },
                          '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                          '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                        }}
                      />
                    )}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel sx={{ color: 'primary.main', marginLeft: '-1rem' }} shrink>Product</InputLabel>
                  <Autocomplete
                    value={selectProduct && selectProduct}
                    onChange={(event, newValue) => {
                      handleChangeProduct(newValue);
                    }}
                    options={productList && productList}
                    getOptionLabel={(option) =>
                      option?.name == undefined ? "" : option?.name
                    }
                    sx={{
                      marginTop: '0.5rem',
                      '.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator': {
                        color: 'primary.main'
                      },
                    }}

                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label="Product"
                        // placeholder="Product"
                        variant="standard"
                        sx={{
                          ".MuiInputBase-root.MuiInput-root": {
                            fontSize: '1rem !important'
                          },
                          '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                          '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                        }}
                      />
                    )}
                  />
                </FormControl>
              </>
            )}
          </Box>
          <div style={{ padding: '0 0.5rem', width: '100%', textAlign: "right", marginRight: "0.5rem" }}>
            <LoadingButton
              loading={shareLoadings}
              size="small"
              variant="contained"
              sx={{
                padding: "0.5rem",
                lineHeight: 1,
                marginTop: "1rem",
                "&:hover": {
                  transform: 'Scale(1.05)',
                  transition: 'transform 0.5s ease',
                },
                // '.MuiCircularProgress-root': {
                //   color: 'primary.main',
                // },
              }}
              disabled={
                selectProduct && Object.keys(selectProduct).length > 0
                  ? false
                  : true
              }
              onClick={() => Handlershare()}>
              Share
            </LoadingButton>
          </div>
        </Box>
      </Modal >
    </div >
  );
};

export default BomConfigurationShareModal;
