import React, { useEffect, useRef, useState } from 'react';
import styles from './ProjectDirectoryLandingPage.module.scss';
import { useHistory } from 'react-router-dom';
import {
	Avatar,
	Box,
	IconButton,
	Typography,
} from '@mui/material';
import { AxiosResponse } from 'axios';
import { API } from '../../api-services';
import ProjectDirectoryVehiclePage from './ProjectDirectoryVehiclePage';
import moment from 'moment';
import useWindowDimensions from '../../ui-reusable-component/useWindowDimensions';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDocumentTitle } from '../../ui-reusable-component/useDocumentTitle';

interface ProjectDirectoryLandingPageI {}

const ProjectDirectoryLandingPage: React.FC<ProjectDirectoryLandingPageI> = (
	props
) => {
	const [
		projectDirectoryOrganisationDetails,
		setProjectDirectoryOrganisationDetails,
	] = useState<any>();
	useDocumentTitle('Product Directory');
	const inputElRef = useRef<any>(null);
	const [UserList, setUserList] = useState<any>([]);
	const history = useHistory();

	const { height, width } = useWindowDimensions();

	const browseFiles = () => {
		return inputElRef.current?.click?.();
	};


	useEffect(() => {
		API.get('auth/super_user_list/').then((res: AxiosResponse) => {
			setUserList(res?.data);
		});
	}, []);



	var AvatarName = (item: any) => {
		const [first, last] = item?.split(' ');

		const FirstLatter = first?.charAt(0);

		const lastWord = last?.charAt(0);

		return `${FirstLatter}${lastWord}`;
	};

	const handleClick = () => {
		history.push('/internallanding');
	};

	const ProjectListredirection = (type: any) => {
		history.push(`/Project-Directory-list/${type}/0`);
	};
	return (
		<>
			<Box sx={{ margin: '0 1rem' }}>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						width: '100%',
					}}>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<IconButton
							sx={{ cursor: 'pointer' }}
							title='Go Back'
							onClick={handleClick}>
							<ArrowBackIcon color='primary' titleAccess='Go Back' />
						</IconButton>
						<Typography
							sx={{
								fontSize: '1rem',
								whiteSpace: 'nowrap',
								fontWeight: '500',
							}}>
							Product Directory
						</Typography>
					</Box>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							columnGap: '10px',
							marginRight: '1rem',
						}}>
						
					</Box>
				</Box>
			</Box>
			<Box className={styles.container}>
				<Box
					className={styles.topContainer}
					sx={{
						height: { xl: '88vh' },
						boxShadow:
							'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
						borderRadius: '10px',
					}}>
					
					<Typography
						sx={{ fontSize: '1rem', marginLeft: '1rem', fontWeight: '500' }}>
						Administrators :{' '}
					</Typography>
					<Box
						className={styles.userconatiner}
						sx={{ height: { lg: '34vh', xl: '47vh' } }}
						adl-scrollbar='true'
						adl-scrollbar-width='0.3'>
						<Box>
							{UserList &&
								UserList?.map((Item: any, index: any) => {
									return (
										<>
											<Box className={styles.userlists}>
												<Box
													sx={{
														display: 'flex',
														// width: "20rem",
														alignItems: 'center',
													}}>
													{Item?.profile_url !== null ? (
														<Typography
															sx={{ marginRight: '1rem', lineHeight: '1' }}>
															<img
																style={{
																	width: '3rem',
																	borderRadius: '50%',
																	height: '3rem',
																	objectFit: 'cover',
																}}
																src={Item?.profile_url}
															/>
														</Typography>
													) : (
														// text.charAt(0)
														<Typography
															sx={{ marginRight: '1rem', lineHeight: '1' }}>
															<Avatar
																src='/broken-image.jpg'
																sx={{
																	width: '3rem',
																	height: '3rem',
																	objectFit: 'cover',
																	backgroundColor: '#007fff !important',
																}}>
																{AvatarName(Item?.name)}
															</Avatar>
														</Typography>
													)}

													<Typography sx={{ fontSize: '1rem' }}>
														{Item?.name}
													</Typography>
												</Box>
												<Box
													sx={{
														display: 'flex',
														justifyContent: 'space-between',
														alignItems: 'center',
														width: '100%',
													}}>
													<Typography
														sx={{ fontSize: '1rem', whiteSpace: 'nowrap' }}>
														{Item?.email}
													</Typography>
													<Typography
														sx={{ fontSize: '1rem', whiteSpace: 'nowrap' }}>
														{Item?.phone_no}
													</Typography>
												</Box>
											</Box>
										</>
									);
								})}
						</Box>
					</Box>
				</Box>
				{/* <Divider orientation="vertical" variant="middle" sx={{ marginRight: '1rem', borderColor: 'primary.light' }} flexItem /> */}
				<Box sx={{
						height: {lg:'85vh', xl: '88vh' },
						boxShadow:
							'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
						borderRadius: '10px',
					}} className={styles.bottomContainer}>
					<ProjectDirectoryVehiclePage />
				</Box>
			</Box>
		</>
	);
};

export default ProjectDirectoryLandingPage;
