import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import * as yup from "yup";
import { Form, useFormik } from "formik";
import {
  Backdrop,
  Divider,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import styles from "../../AddRevisionModal.module.scss";
import { LoadingButton } from "@mui/lab";
import CancelIcon from "@mui/icons-material/Cancel";
import { API } from "../../../../api-services";
import { useRouteParams } from "../../../../ui-reusable-component/useRouteParams";
import { useBaseModal } from "../../../../ComponentUI/SearchPage/useBaseModal";
import ErrorModalFileCosting from "../../ErrorModalFileCosting";
import SuccessModalFileCosting from "../../SuccessModalFileCosting";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 0,
};

const validationSchema = yup.object({
  category: yup.string().required("Please select Commodity"),
  operationCode: yup.string().required("Please select Commodity"),
  description: yup.string().required("Please enter Tool Code"),
  // remark: yup.string().required("Please enter Tool Name"),
  unit: yup.string().required("Please enter Specification"),
  rate: yup
    .number()
    .min(0, "Must be a more positive number")
    .required("Please enter Rate/Unit (INR)"),
});

const useStyles = makeStyles((theme) => ({
  select: {
    "&.MuiInputBase-root-MuiInput-root-MuiSelect-root": {
      borderBottom: "1px solid red",
    },
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IAddOperationDatabaseModel {
  isOpen: any;
  onCloseModal: any;
  id: any;
  actionSend: any;
  apiCallAccordianInsideData: any;
  setApiCallAccordianInsideData: any;
  valueEdit?: any;
  apiCallAccordianData?: any;
  setApiCallAccordianData?: any;
}

function AddOperationDatabaseModel(props: IAddOperationDatabaseModel) {
  const {
    isOpen,
    onCloseModal,
    id,
    actionSend,
    apiCallAccordianInsideData,
    setApiCallAccordianInsideData,
    apiCallAccordianData,
    setApiCallAccordianData,
    valueEdit,
  } = props;
  const { categoryName, search, databaseName, revisionId } =
    useRouteParams<any>();
  const [commodityData, setCommodityData] = React.useState<any>([]);
  const [isLoadingsave, setIsLoadingSave] = React.useState<any>(false);
  const [ErrorModalFileMsg, setErrorModalFileMsg] = React.useState<any>();
  const [SuccessModalFileMsg, setSuccessModalFileMsg] = React.useState<any>();

  const ErrorModalFiles = useBaseModal();
  const SuccessModalFiles = useBaseModal();
  const classes = useStyles();

  useEffect(() => {
    API.get(
      "db/cost_databases/",
      { database_type: "operationdb" },
      0
    ).then((res: any) => {
      setCommodityData(res?.data);
    });
  }, [isOpen]);

  React.useEffect(() => {
    if (commodityData != undefined && actionSend !== "add") {
      commodityData &&
        formik.setFieldValue(
          "category",
          String(
            commodityData?.filter(
              (item: any) => item?.category == valueEdit?.category
            )[0]?.category
          )
        );
      formik.setFieldValue("operationCode", valueEdit?.operationCode);
      formik.setFieldValue("description", valueEdit?.description);
      formik.setFieldValue("remark", valueEdit?.remark);
      formik.setFieldValue("rate", valueEdit?.rate);
      formik.setFieldValue("unit", valueEdit?.unit);
    }
  }, [actionSend, commodityData]);

  const formik = useFormik({
    initialValues: {
      category: "",
      operationCode: "",
      description: "",
      remark: "",
      rate: "",
      unit: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoadingSave(true);
      if (actionSend === "add") {
        API.post(`db/stroke_rate/`, {
          type: 2,
          code: values?.operationCode,
          category: values?.category,
          description: values?.description,
          remark: values?.remark,
          unit: values?.unit,
          rate_per_unit: values?.rate,
        })
          .then((res: any) => {
            if (!databaseName?.includes("DB")) {
              setIsLoadingSave(true);
              API.post("db/stroke_rate_revision/", {
                revision: revisionId,
                strok_rate_id: [res.data.id],
              }).then((res: any) => {
                setIsLoadingSave(false);
                setSuccessModalFileMsg("Operation added successfully!");
                SuccessModalFiles.open();
                search == "0" && setApiCallAccordianData(!apiCallAccordianData);
                setApiCallAccordianInsideData(!apiCallAccordianInsideData);
                onCloseModal();
              });
            } else {
              setIsLoadingSave(false);
              setSuccessModalFileMsg("Operation added successfully!");
              SuccessModalFiles.open();
              search == "0" && setApiCallAccordianData(!apiCallAccordianData);
              setApiCallAccordianInsideData(!apiCallAccordianInsideData);
            }
          })
          .catch((err) => {
            setIsLoadingSave(false);
            setErrorModalFileMsg(err.response.data);
            ErrorModalFiles.open();
          });
      } else {
        API.put(`db/stroke_rate/${id}/`, {
          type: 2,
          code: values?.operationCode,
          category: values?.category,
          description: values?.description,
          remark: values?.remark,
          unit: values?.unit,
          rate_per_unit: values?.rate,
        })
          .then(async (res: any) => {
            setIsLoadingSave(false);
            setSuccessModalFileMsg("Operation edited successfully!");
            await SuccessModalFiles.open();
            search == "0" && setApiCallAccordianData(!apiCallAccordianData);
            setApiCallAccordianInsideData(!apiCallAccordianInsideData);
          })
          .catch((err) => {
            setIsLoadingSave(false);
            setErrorModalFileMsg(err.response.data);
            ErrorModalFiles.open();
          });
      }
    },
  });

  return (
    <div>
      {ErrorModalFiles.isOpen && (
        <ErrorModalFileCosting
          isOpen={ErrorModalFiles.isOpen}
          onCloseModal={ErrorModalFiles.close}
          ErrorMsg={ErrorModalFileMsg}
          onCloseParentModel={onCloseModal}
        />
      )}
      {SuccessModalFiles.isOpen && (
        <SuccessModalFileCosting
          isOpen={SuccessModalFiles.isOpen}
          onCloseModal={SuccessModalFiles.close}
          successMsg={SuccessModalFileMsg}
          onCloseParentModel={onCloseModal}
        />
      )}
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              {actionSend !== "add" ? "Edit" : "Add"} Operations
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ padding: "2rem", textAlign: "center" }}>
            <form onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth variant="standard">
                  <InputLabel
                    sx={{ color: "primary.main" }}
                    shrink
                    id="demo-simple-select-label"
                  >
                    Select Commodity
                  </InputLabel>
                  <Select
                    id="category"
                    disabled={actionSend !== "add"}
                    name="category"
                    className={classes.select}
                    labelId="demo-select-small"
                    // id="demo-select-small"
                    sx={{
                      "&:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      ".MuiSelect-select.MuiInputBase-input.MuiInput-input": {
                        fontSize: "1rem",
                        textAlign: "left",
                      },
                      ".MuiSvgIcon-root ": {
                        color: "primary.main",
                      },
                    }}
                    error={
                      formik.touched.category && Boolean(formik.errors.category)
                    }
                    //@ts-ignore
                    helperText={
                      formik.touched.category && formik.errors.category
                    }
                    MenuProps={{
                      style: {
                        maxHeight: 400,
                        color: "007fff",
                      },
                    }}
                    onChange={formik.handleChange}
                    value={
                      formik.values.category !== null
                        ? formik.values.category
                        : ""
                    }
                  >
                    {commodityData &&
                      commodityData.map((item: any, index: any) => {
                        return (
                          <MenuItem
                            sx={{ color: "primary.main", fontSize: "1rem" }}
                            key={index}
                            value={item.category}
                          >
                            {item.category}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <div className={styles.errormsg}>
                    {formik.touched.category &&
                      Boolean(formik.errors.category) && (
                        <p>{formik.errors.category}</p>
                      )}
                  </div>
                </FormControl>
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label="Operation Code"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="operationCode"
                    name="operationCode"
                    onChange={formik.handleChange}
                    value={formik.values.operationCode}
                    multiline
                    error={
                      formik.touched.operationCode &&
                      Boolean(formik.errors.operationCode)
                    }
                    helperText={
                      formik.touched.operationCode &&
                      formik.errors.operationCode
                    }
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label="Description"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="description"
                    name="description"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    multiline
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />
                </FormControl>
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label="Remarks"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="remark"
                    name="remark"
                    onChange={formik.handleChange}
                    value={formik.values.remark}
                    multiline
                    error={
                      formik.touched.remark && Boolean(formik.errors.remark)
                    }
                    helperText={formik.touched.remark && formik.errors.remark}
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth>
                  <TextField
                    variant="standard"
                    type="number"
                    label="Rate/Unit (INR)"
                    inputProps={{
                      step: "any",
                      style: { color: "#007fff" },
                      min: 0,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="rate"
                    name="rate"
                    onChange={formik.handleChange}
                    value={formik.values.rate}
                    error={formik.touched.rate && Boolean(formik.errors.rate)}
                    helperText={formik.touched.rate && formik.errors.rate}
                  />
                </FormControl>
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label="Unit"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="unit"
                    name="unit"
                    onChange={formik.handleChange}
                    value={formik.values.unit}
                    multiline
                    error={formik.touched.unit && Boolean(formik.errors.unit)}
                    helperText={formik.touched.unit && formik.errors.unit}
                  />
                </FormControl>
              </Box>
              <Box sx={{ width: "100%", textAlign: "right" }}>
                <LoadingButton
                  size="small"
                  loading={isLoadingsave}
                  variant="contained"
                  type="submit"
                  sx={{
                    marginTop: "1rem",
                    "&:hover": {
                      transform: "Scale(1.05)",
                      transition: "transform 0.5s ease",
                    },
                  }}
                >
                  {actionSend !== "add" ? "Save" : "Add"}
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
export default AddOperationDatabaseModel;
