import * as React from "react";
import { Box, Checkbox, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory, useRouteMatch } from "react-router-dom";
import styles from "./ConfigurationMainLanding.module.scss";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";
import { useRouteParams } from "../ui-reusable-component/useRouteParams";
import CompanionConfig from "../CompanionConfig/CompanionConfig";
import { useEffect } from "react";
import { API } from "../api-services";
import CostingConfigurationDrawer from "./Costing/CostingConfigurationDrawer";
import { useDocumentTitle } from "../ui-reusable-component/useDocumentTitle";

type Anchor = "top" | "left" | "bottom" | "right";

interface IConfigurationMainLandingProps {
  projectId: any;
  module: any;
  categoryId: any;
}

function ConfigurationMainLanding(props: IConfigurationMainLandingProps) {
  const history = useHistory();
  useDocumentTitle("Configuration");
  const { url } = useRouteMatch();
  const { projectId, projectname, categoryId, VehiclecategoryId } =
    useRouteParams<any>();
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [state, setState] = React.useState({
    top: false,
    left: true,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <CostingConfigurationDrawer
        VehiclecategoryId={VehiclecategoryId}
        projectname={projectname}
      />
    </Box>
  );

  const handleClick = () => {
    history.goBack();
  };

  const costingModel = () => {
    history.push(`/costingconfiguration/${projectId}/${projectname}/0/0`);
  };
  const commodityHandle = () => {
    history.push(
      `/costingconfiguration/${projectId}/${projectname}/0/commodity`
    );
  };

  const comapnionHandle = () => {
    API.get(
      "/web_companion/comapanion_activity_log",
      {
        //   get_recycle_bin_data_count: true
        project: projectId,
        viewed: true,
      },
      0
    )
      .then((res: any) => {
        // console.log(res?.data.count, "countdata")
      })
      .catch((err: any) => {
        // console.log("Server error")
        //   SetLoader(false)
      });
    history.push(`/companionConfig/${projectId}/${projectname}`);
  };

  return (
    <div>
      <Box sx={{ margin: "0 0.5rem" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: "1rem" }}
          >
            <IconButton
              sx={{ cursor: "pointer" }}
              title="Go Back"
              onClick={handleClick}
            >
              <ArrowBackIcon color="primary" titleAccess="Go Back" />
            </IconButton>
            <Typography
              sx={{
                fontSize: "1rem",
                whiteSpace: "nowrap",
                fontWeight: "bold",
              }}
            >
              {projectname}-Configuration
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: "1rem" }}
          ></Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}
                >
                  <Box
                    sx={{ padding: "1rem 0rem", paddingBottom: "0" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                  >
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}
                    >
                      Select Configuration Module
                    </Typography>
                    <Divider sx={{ margin: "0.5rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  style={{
                    transform: "rotate(270deg)",
                    whiteSpace: "nowrap",
                    marginTop: "40vh",
                    cursor: "pointer",
                    fontSize: "1rem",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  Select Configuration Module
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        <Box
          sx={{ width: "100%", height: "89vh" }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        ></Box>
      </Box>
    </div>
  );
}
export default ConfigurationMainLanding;
