import { ADMIN } from "../../../Redux/Services/admin.service";
import { API } from "../../../api-services";
import CDN_URL from "../../../utlis/CDN_URL";
import { s3Url } from "../../../utlis/s3url.utils"; 
import axios from "axios";


const ImageUploadS3ProofDocs = (fileList: any,setGetUrl:any,setLoader:any,formikKey:any) => {
  // const file_name = file?.name;
  var random: any = Math.floor(Math.random() * 1000000 + 1);
  var obj: Array<any> = [];
  const sortAlphaNum = (a: any, b: any) =>
    a.localeCompare(b, "en", { numeric: true });
  // const dispatch = useAppDispatch();
  // + "-" + uuidv4();
  API.get("/cart/get_signed_policy/", {}).then((res) => {
    const data = Array.from(fileList).forEach((file: any, index: any) => {
      const formData = new FormData();

      formData.append(
        "key",
        `${"create_business"}/${"logo"}/${index}/${random}/${file.name}`
      );
      formData.append("Content-Type", file?.type);
      formData.append("x-amz-server-side-encryption", "AES256");
      formData.append("X-Amz-Credential", res.data.data.x_amz_credential);
      formData.append("X-Amz-Algorithm", "AWS4-HMAC-SHA256");
      formData.append("X-Amz-Date", res.data.data.x_amz_date);
      formData.append("X-Amz-Signature", res.data.data.aws_policy_signature);
      formData.append("Policy", res.data.data.aws_policy);
      formData.append("file", file, file?.name);




      // -------------------
      setLoader(true)
      axios
        .post(s3Url, formData, {
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
          },
        })
        .then(async (res: any) => {
         {
            setLoader(false)
            const CDN=CDN_URL
            let val=`/${"create_business"}/${"logo"}/${index}/${random}/${file.name}`
            // setGetUrl((prev:any)=>[...prev,val])
            setGetUrl(formikKey,val)
            
            ADMIN.toast.info("Uploaded Successfully")
            return val;
          }
        })
        .catch((err) => {
            setLoader(true)
          return ADMIN?.toast?.error(`Files not uploaded are : ${file?.name}`);
        });
    });
  });
};

// ----------------------------------------------------------------------------------

export default ImageUploadS3ProofDocs;