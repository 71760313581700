import React, { useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Box,
  Modal,
  Typography,
  Divider,
  Chip,
} from "@mui/material";
import { Formik, Field, Form, FormikHelpers } from "formik";
import * as Yup from "yup";
import CancelIcon from "@mui/icons-material/Cancel";
import { API } from "../../api-services";
import { ADMIN } from "../../Redux/Services/admin.service";

interface IPartImageModelProps {
  onCloseModal: () => void;
  isOpen: boolean;
  partdetails: any;
  supplierPartData: any;
  onCloseParentModal?: any;
}

const validationSchema = Yup.object({
  selectedOption: Yup.mixed().required("BOM Category is required"),
  textValue: Yup.mixed().required("Part Name is required"),
});

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "40vw", xl: "35vw" },
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 3,
  boxShadow: 24,
};

export default function AddConflictPart(props: IPartImageModelProps) {
  const { onCloseModal, isOpen, partdetails, supplierPartData, onCloseParentModal } = props;
  const [options, setOtions] = useState([]);

  const getOptions = () => {
    API.get(
      "/cart/get_all_bomcategory_list/",
      {
        vehicle_category: partdetails?.vehicle_category_id,
      },
      0
    )
      .then((res: any) => {
        setOtions(res.data);
      })
      .catch((err: any) => {});
  };

  React.useEffect(() => {
    getOptions();
  }, []);

  const handleSubmit = (
    values: { selectedOption: string; textValue: string },
    actions: FormikHelpers<any>
  ) => {
    API.post("/bom/vault_info/", {
      abbreviation: partdetails?.abbreviation,
      category: values.selectedOption,
      name: values.textValue,
      quantity: 1,
      vehicle_category: partdetails?.vehicle_category_id,
    }, {}, 0).then((res: any) => {
      API.put(`/cart/get_all_part_requests_for_internal/`, {
        conflicted_vault_id: res.data?.id,
        part_id: partdetails?.ID,
      }, {}, 0).then((res: any) => {
        actions.setSubmitting(false);
        supplierPartData();
        onCloseParentModal();
        onCloseModal(); // Close the modal on submit if needed
      });
    }).catch((err: any) => {
      ADMIN.toast.error("Error in adding part");
    });
  };

  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={modalStyle}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
            px: 2,
          }}
        >
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            textAlign="center"
            flexGrow={1}
          >
            Add Part for {partdetails?.supplier_name}
          </Typography>
          <CancelIcon
            titleAccess="Close"
            sx={{ cursor: "pointer", ml: 1 }}
            onClick={onCloseModal}
          />
        </Box>
        <Divider sx={{ mb: 2 }} />
        
        {/* Information Section */}
        <Box sx={{ mb: 2, p: 2, bgcolor: "#f5f5f5", borderRadius: "8px" }}>
          {/* <Typography variant="h6" gutterBottom>
            Information
          </Typography> */}
          <Typography variant="body1" mb={1}>
            <strong>Product Category:</strong> {partdetails?.vehicle_category || "N/A"}
          </Typography>
          <Typography variant="body1" mb={1}>
            <strong>Potential Sellers:</strong>
            {partdetails?.potential_sellers?.map((seller: string, index: number) => (
              <Chip key={index} label={seller} sx={{ m: 0.5 }} />
            )) || "N/A"}
          </Typography>
          <Typography variant="body1">
            <strong>Known Sellers:</strong>
            {partdetails?.known_sellers?.map((seller: string, index: number) => (
              <Chip key={index} label={seller} sx={{ m: 0.5, bgcolor: "lightgreen" }} />
            )) || "N/A"}
          </Typography>
        </Box>
        
        <Formik
          initialValues={{ selectedOption: partdetails?.category_id, textValue: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            handleChange,
            handleBlur,
            errors,
            touched,
            isSubmitting,
          }) => (
            <Form>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}
              >
                <FormControl
                  fullWidth
                  error={touched.selectedOption && !!errors.selectedOption}
                >
                  <InputLabel id="select-option-label">
                    Select BOM Category
                  </InputLabel>
                  <Field
                    as={Select}
                    labelId="select-option-label"
                    name="selectedOption"
                    value={values.selectedOption}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Select BOM Category"
                  >
                    {options.map((option: any, index: any) => (
                      <MenuItem key={index} value={option.cat_id}>
                        {option?.cat_name}
                      </MenuItem>
                    ))}
                  </Field>
                  {touched.selectedOption && errors.selectedOption && (
                    <Box sx={{ color: "error.main", mt: 1 }}>
                      {/* @ts-ignore */}
                      {errors.selectedOption}
                    </Box>
                  )}
                </FormControl>

                <Field
                  as={TextField}
                  label="Enter Part Name"
                  variant="outlined"
                  name="textValue"
                  value={values.textValue}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  error={touched.textValue && !!errors.textValue}
                  helperText={touched.textValue && errors.textValue}
                />

                <Button
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting}
                  sx={{ mt: 2 }}
                >
                  Create Part
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}
