import * as React from 'react';
import Modal from '@mui/material/Modal';
import { Box, Divider, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CDN_URL from '../../utlis/CDN_URL';
import { useState } from 'react';


interface IPartImageModelProps {
    onCloseModal: any;
    isOpen: any;
    partdetails:any;
    docType:any;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {lg:'60vw',xl:'55vw'},
    bgcolor: 'background.paper',    
    borderRadius: '10px',
    p: 1,
};



export default function PartImageModel (props: IPartImageModelProps) {
    const { onCloseModal, isOpen, partdetails,docType } = props; 

    const [imageError, setImageError] = useState(false);

    const handleImageError = () => {
      setImageError(true);
    };

    console.log(partdetails,docType,'partdetails');
  return (
    <div>
      <Modal
        open={isOpen} onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "1rem",
              marginBottom: "0.5rem",             
            }}
          >
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Part images uploaded by {partdetails?.supplier_name}
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor:'primary.light',
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{maxHeight:'80vh'}}
           adl-scrollbar="true"
           adl-scrollbar-width="0.3">
          {docType === 'old' ? <Box sx={{display:'grid',gridTemplateColumns:'1fr 1fr 1fr',gap:'1rem',padding:'1rem'}}>
              {Array.isArray(partdetails?.images) && partdetails?.images?.map((part:any, index:number) => (
                <div key={index}>
                  {part.old_url !=='' && (
                    <img
                      src={`${CDN_URL}.${part.old_url}`}
                      alt="Part"
                      style={{ width: "100%", height: '14rem',objectFit:'cover',borderRadius:'10px' }}                      
                    />
                  )}
                </div>
              ))}
            </Box> : 
            <Box sx={{display:'grid',gridTemplateColumns:'1fr 1fr 1fr',gap:'1rem',padding:'1rem'}}>             
              {Array.isArray(partdetails.images) && partdetails?.images?.map((part:any, index:number) => (
                <div key={index}>
                  {part.url !=='' && (
                    <img
                      src={`${CDN_URL}.${part.url}`}
                      alt="Part"
                      style={{ width: "100%", height: '14rem',objectFit:'cover',borderRadius:'10px' }}                     
                    />
                  )}
                </div>
              ))}
            </Box>}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
