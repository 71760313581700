import { Avatar, Box, Typography, Button } from "@mui/material";
import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
import LockIcon from "@mui/icons-material/Lock";
import styles from "./SupplierViewPageNew.module.scss";



function SupplierContact({ spoc_information }: any) {
 

  return (
    <Box sx={{padding:2,}}>
         <Typography
            sx={{
            fontSize: "1.1rem",
            fontWeight: "600",
            marginBottom: "0.5rem",
            }}>
                Sales contact information
            </Typography>
    <Box sx={{ display: "flex", gap: "1rem", flexWrap: "wrap",   }} className={styles.contactscontainer}>
       
      {spoc_information?.map((spoc: any, index: any) => {
       const displayLetter = spoc?.spoc_name?.charAt(0).toUpperCase() || '';

        
        return (
            <Box key={index} className={styles.contactCard} sx={{ background: "#f5f5f5", padding: "0.5rem" }}>
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "0.5rem",
                borderRadius: "10px",
                marginBottom: "1rem",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <Box sx={{ height: "5.5rem", width: "5.5rem" }}>
      <Avatar
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: "#cad9d3",
          color:"grey",
          borderRadius: "50%",
          fontSize: "2rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {displayLetter ? displayLetter : <PersonIcon />}
      </Avatar>
    </Box>
              <Box sx={{ flex: "1", position: "relative" }}>
                {/* Contact Details */}
                <Box sx={{  transition: "filter 0.3s ease-in-out" }}>
                  <Typography sx={{ color: "#424141e6", fontSize: "1.1rem", fontWeight: "600", }}>
                    {spoc?.spoc_name}
                  </Typography>
  
                  <Typography sx={{ color: "#000000e6", fontSize: "0.98rem",filter:'none !important' }}>
                    {spoc?.spoc_designation}
                  </Typography>
                  <Typography sx={{ color: "#797a7a", fontSize: "0.9rem",filter:'none !important' }}>
                    {spoc?.spoc_department}
                  </Typography>
  
                  <Box sx={{ display: "flex", gap: "1rem", marginTop: "0.5rem" ,}} className={styles.contactFlex}>
                    {(spoc?.spoc_email !== null && spoc?.spoc_email?.length>0) && (
                      <Box display="flex" alignItems="center" gap={1}>
                        <MailIcon sx={{ color: "#558070", fontSize: "1rem" }} />
                        <Typography sx={{ color: "#797a7a", fontSize: "0.98rem" }}>
                          {spoc?.spoc_email}
                        </Typography>
                      </Box>
                    )}
                    {/* {spoc?.spoc_contact_number !== null && (
                      <Box display="flex" alignItems="center" gap={1}>
                        <PhoneIcon sx={{ color: "#558070", fontSize: "1rem" }} />
                        <Typography sx={{ color: "#797a7a", fontSize: "0.98rem" }}>
                          {spoc?.spoc_dial_code}&nbsp;
                          {spoc?.spoc_contact_number}
                        </Typography>
                      </Box>
                    )} */}
                  </Box>
                </Box>   
              </Box>
                         
            </Box>          
          </Box>
        )
      })}
    </Box>
    </Box>

  );
}

export default SupplierContact;
