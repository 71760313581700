import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import * as yup from "yup";
import { useFormik } from "formik";
import { Backdrop, Chip, Divider, Input, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { LoadingButton } from "@mui/lab";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CancelIcon from "@mui/icons-material/Cancel";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { API } from "../../../api-services";
import uploadS3BOPFlag from "./uploadS3BOPFlag";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import Image from "../../../ViewModule/FeatureLandingPage/ArchitectureImageCard"
import LoadingImage from "../../../Assets/images/loading-screen.gif";
import RViewerJS from "viewerjs-react";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 0,
};
const RevisionTypeList = [
  { id: 1, text: "Main Costing", value: 1 },
  { id: 2, text: "Scenario Costing", value: 2 },
];
const validationSchema = yup.object({
  reference: yup.mixed().required("Please type source"),
  unit_cost: yup.mixed().required("Please type per unit cost"),
});

const useStyles = makeStyles((theme) => ({
  select: {
    "&.MuiInputBase-root-MuiInput-root-MuiSelect-root": {
      borderBottom: "1px solid red",
    },
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IWatermarkModelProps {
  isOpen: any;
  onCloseModal: any;
  actions?: any;
  expanded?: any;
  getMaterialData?: any;
}

function BoughtoutEditModal(props: IWatermarkModelProps) {
  const { isOpen, onCloseModal, actions, expanded, getMaterialData } = props;
  const {categoryName}=useRouteParams<any>()
  const [isLoadingsave, setIsLoadingSave] = React.useState<any>(false);
  const [flag, setFlag] = React.useState<any>();
  const classes = useStyles();
  const handleChange = (e: any) => {
    setFlag(e.currentTarget.files);
  };
  React.useEffect(() => {
    if (actions) {
      
      formik.setFieldValue("reference", actions.reference);
      formik.setFieldValue("unit_cost", parseFloat(actions?.unit_cost?.slice(1)));
    }
  }, [actions]);
  const formik = useFormik({
    initialValues: {
      reference: "",
      unit_cost: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoadingSave(true);

      if (flag) {
        await uploadS3BOPFlag(actions?.id, values, flag, setIsLoadingSave, "BOPRevision", onCloseModal,getMaterialData,categoryName);
      } else {
        
        API.put(`db/bought_out_cost/${actions.id}/`, values, {}, 0)
          .then((res: any) => {
            setIsLoadingSave(false);
            getMaterialData(categoryName);
            onCloseModal();
          })
          .catch((err: any) => {
            setIsLoadingSave(false);
          });
      }
    },
  });

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}>
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}>
              {actions === "add" ? "Add" : "Edit"} Revision Bought-out Parts
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ padding: "0 2rem 1rem", textAlign: "center" }}>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ display: "flex", alignItems: "center", columnGap: "1rem", marginBottom: "0.5rem" }}>
                <FormControl
                  fullWidth
                  variant="standard">
                  <TextField
                    variant="standard"
                    label="Reference"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                      "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="reference"
                    name="reference"
                    onChange={formik.handleChange}
                    value={formik.values.reference}
                    multiline
                    error={formik.touched.reference && Boolean(formik.errors.reference)}
                    helperText={formik.touched.reference && formik.errors.reference}
                  />
                </FormControl>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center", columnGap: "1rem", marginBottom: "0.5rem" }}>
                <FormControl
                  fullWidth
                  variant="standard">
                  <TextField
                    variant="standard"
                    label="Per unit Cost INR/Unit"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                      "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="unit_cost"
                    name="unit_cost"
                    onChange={formik.handleChange}
                    value={formik.values.unit_cost}
                    type="number"
                    error={formik.touched.unit_cost && Boolean(formik.errors.unit_cost)}
                    helperText={formik.touched.unit_cost && formik.errors.unit_cost}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel shrink
                    id="demo-simple-select-label-Activity"
                    sx={{color: "primary.main",marginLeft:'-1rem',marginTop:'0.5rem' }}>
                    Flag
                  </InputLabel>
                  <Input
                    type="file"
                    style={{fontSize:'1rem'}}
                    onChange={handleChange}
                  />
                </FormControl>
                
              </Box>
              <Box sx={{display:"flex", justifyContent:"start", alignItems:"center",  width:"100%"}}>
                <Typography>Previous uploaded file - </Typography>
              {
                  actions?.flag_reference && (
                    <div>
                      {actions?.flag_reference?.split("/").pop()?.split(".").pop() == "pdf" ? <Box sx={{borderBottom:"1px solid #007dff"}} onClick={() => {
                        window.open(actions?.flag_reference, "_blank")
                      }}>{actions?.flag_reference?.split("/").pop()}</Box> : 
                      // @ts-ignore 
                      <RViewerJS>
            
              <Image
                src={actions?.flag_reference}
                placeholderImg={LoadingImage}
                title={actions?.flag_reference?.split("/").pop()}
                style={{ width: "60%", height: "6rem", objectFit: "contain", marginLeft: "1rem", cursor: "pointer", alignSelf: "center" }}
                alt={actions?.flag_reference?.split("/").pop()}
                key={actions?.flag_reference?.split("/").pop()}
              />
            
          </RViewerJS>}
                    </div>
                  )
                }
                </Box>
              {/* flag_reference */}
              <Box sx={{ width: "100%", textAlign: "right" }}>
                <LoadingButton
                  size="small"
                  loading={isLoadingsave}
                  variant="contained"
                  type="submit"
                  sx={{
                    marginTop: "1rem",
                    "&:hover": {
                      transform: "Scale(1.05)",
                      transition: "transform 0.5s ease",
                    },
                    ".MuiCircularProgress-root": {
                      color: "primary.main",
                    },
                  }}>
                  Save
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
export default BoughtoutEditModal;
