import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { API } from "../../api-services";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Skeleton,
  Snackbar,
  Typography,
} from "@mui/material";
import BOMAddValidation from "./BOMAddValidation";
import { uuid } from "uuidv4";
import BOMCustomValidationInBetween from "./BOMCustomValidationInBetween";
import SkeletonLoader from "../../ComponentUI/SkeletonLoader/SkeletonLoader";
import CloseIcon from "@mui/icons-material/Close";
import ErrorModalFilesMail from "../../ComponentUI/ProjectMailModule/ErrorModalFilesMail";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import BOMParameterAddModal from "./BOMParameterAddModal";
import VerifiedIcon from "@mui/icons-material/Verified";
import Swal from "sweetalert2";
import AddIcon from "@mui/icons-material/Add";
import { AUTH } from "../../Redux/Services/auth.service";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: "0.9rem", color: "primary.main" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

interface Params {
  projectId: any;
  vehicleCategoryId: any;
  topVaultId: any;
  categoryId: any;
  parameterId: any;
  projectname: any;
  lockStatus: any;
}

interface BomCustomValidationI {
  CategoryList: any;
  setParentCount: any;
}

const BOMCustomValidation: React.FC<BomCustomValidationI> = (props) => {
  const { CategoryList, setParentCount } = props;
  const {
    projectId,
    projectname,
    topVaultId,
    categoryId,
    parameterId,
    vehicleCategoryId,
    lockStatus,
  } = useRouteParams<Params>();
  // const [expanded, setExpanded] = React.useState<string | false>(parameterId);
  const [validationShowData, setValidationShowData] = useState<any>();
  const [parameterListData, setParameterListData] = useState<any>();
  const [apiCallValidationShowData, setApiCallValidationShowData] =
    useState<boolean>(false);
    const [counter,setCounter]=useState<number>(0)
  const [parameterListDataLoader, setParameterListDataLoader] =
    useState<boolean>(true);
  const [validationCount, setValidationCount] = useState<number>(0);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState();
  const [validationdata, setValidationdata] = useState<any>();
  const [validAddID, setValidAddID] = useState<any>(0);
  const history = useHistory();
  const deleteModelShow = useBaseModal();
  const parameterAdded = useBaseModal();

  const handleClickSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  useEffect(() => {
    const checkboxvalue =
      CategoryList &&
      CategoryList?.filter((item: any, index: any) => {
        return item?.category == categoryId;
      })[0];
    setValidationdata(checkboxvalue?.is_validated);
  }, [CategoryList, categoryId]);

  useEffect(() => {
    API.get(
      `bom/parameter_mapping/`,
      {
        module: "6",
        category: categoryId,
        vehicle_category: vehicleCategoryId,
      },
      0
    ).then((res: any) => {
      setParameterListData(res?.data);
    });
  }, [categoryId, parameterAdded.isOpen]);

  useEffect(() => {
    setParameterListDataLoader(true);
    API.get(
      `bom/custom_validation/`,
      { category: categoryId, vehicle_category: vehicleCategoryId },
      0
    ).then((res: any) => {
      setValidationShowData(res?.data.filter((item: any) => item?.id == parameterId));
      setParameterListDataLoader(false);
    });
  }, [categoryId,parameterId, apiCallValidationShowData,counter,lockStatus]);

  // console.log(validationShowData);
  // const handleChange =
  //   (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
  //     setExpanded(newExpanded ? panel : false);
  //     setValidationCount(0);
  //     newExpanded == true
  //       ? history.push(
  //           `/customValidation/${vehicleCategoryId}/${projectname}/${topVaultId}/${categoryId}/${panel}/${lockStatus}`
  //         )
  //       : history.push(
  //           `/customValidation/${vehicleCategoryId}/${projectname}/${topVaultId}/${categoryId}/0/${lockStatus}`
  //         );
  //   };

  const handleValidationAdd: any = (e: any, AddId:any) => {
    e.stopPropagation();
    setValidAddID(AddId);
    setValidationCount((prev: number) => prev + 1);
  };

    
  
  if(validAddID !== null && validAddID !== undefined) {
    sessionStorage.removeItem('validationparamId');
    sessionStorage.setItem("validationparamId", validAddID);    
  }

  const validdationIDCheck = sessionStorage.getItem('validationparamId');
 
  const validationCountMap = (idParameter: any, relationIDParameter: any) => {
    const countTimes: any = [];
   
      if(validdationIDCheck == idParameter){       
        for (let i = 0; i < validationCount; i++) {
          const validationCreateuuid = uuid();
          countTimes.push(
            <BOMAddValidation
              key={i}
              validationCreateuuid={validationCreateuuid}
              parameterListData={parameterListData}
              idParameter={idParameter}
              relationIDParameter={relationIDParameter}
              apiCallValidationShowData={apiCallValidationShowData}
              setApiCallValidationShowData={setApiCallValidationShowData}
              handleClickSnackbar={handleClickSnackbar}
              deleteModelShow={deleteModelShow}
              setErrorMessage={setErrorMessage}
            />
          );
        }
        return countTimes;
      } 
    };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackbar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleChangeCheckBox = () => {
    const checkboxvalue =
      CategoryList &&
      CategoryList?.filter((item: any, index: any) => {
        return item?.category == categoryId;
      })[0];
    API.patch("bom/custom_validation_config/", {
      id: checkboxvalue?.id,
      validated: !validationdata,
    })
      .then((res: any) => {
        setValidationdata(!validationdata);
        setParentCount((prev: any) => prev + 1);
      })
      .catch((err: any) => {
        const { data } = err?.response;
        Swal.fire({
          icon: "error",
          html: `<div>
                  <br />
                  <p style="color:#00887a;">${data}</p>   
                </div>`,
        });
      });
  };
  const lockHandler = (rows: any,event:any) => {
    event.stopPropagation();
    event.preventDefault();
    Swal.fire({
      title: rows?.lock == true ? "Unlock" : "Lock",
      text:
        rows?.lock == true
          ? "Are you sure you want to Unlock this parameter?"
          : "Are you sure you want to lock this parameter?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        API.put(`/bom/custom_validation/${rows?.id}/`, {
          lock: rows?.lock == true ? false : true,
        }).then((res: any) => {
          setCounter((prev:any)=>prev+1)
        });
      }
    });
  };

  return (
    <div>
      {parameterAdded?.isOpen && (
        <BOMParameterAddModal
          isOpen={parameterAdded.isOpen}
          onCloseModal={parameterAdded.close}
          parameterListData={parameterListData}
          apiCallValidationShowData={apiCallValidationShowData}
          setApiCallValidationShowData={setApiCallValidationShowData}
          validationShowData={validationShowData}
          setParentCount={setParentCount}
        />
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Moved to Validation Logic!"
        action={action}
      />

      <ErrorModalFilesMail
        isOpen={deleteModelShow?.isOpen}
        onCloseModal={deleteModelShow?.close}
        ErrorMsg={errorMessage}
      />

      {parameterListDataLoader ? (
        <div style={{ marginTop: "2.2rem", padding: "0 1rem" }}>
          <Skeleton sx={{ height: "3rem" }}></Skeleton>
          <Skeleton sx={{ height: "3rem" }}></Skeleton>
          <Skeleton sx={{ height: "3rem" }}></Skeleton>
          <Skeleton sx={{ height: "3rem" }}></Skeleton>
          <Skeleton sx={{ height: "3rem" }}></Skeleton>
          <Skeleton sx={{ height: "3rem" }}></Skeleton>
          <Skeleton sx={{ height: "3rem" }}></Skeleton>
          <Skeleton sx={{ height: "3rem" }}></Skeleton>
          <Skeleton sx={{ height: "3rem" }}></Skeleton>
          <Skeleton sx={{ height: "3rem" }}></Skeleton>
          <Skeleton sx={{ height: "3rem" }}></Skeleton>
          <Skeleton sx={{ height: "3rem" }}></Skeleton>
          <Skeleton sx={{ height: "3rem" }}></Skeleton>
          <Skeleton sx={{ height: "3rem" }}></Skeleton>
          <Skeleton sx={{ height: "3rem" }}></Skeleton>
          <Skeleton sx={{ height: "3rem" }}></Skeleton>
          <Skeleton sx={{ height: "3rem" }}></Skeleton>
          <Skeleton sx={{ height: "3rem" }}></Skeleton>
          <Skeleton sx={{ height: "3rem" }}></Skeleton>
        </div>
      ) : (
        <>
          
          <Box
            sx={{ height: { lg: "85vh", xl: "89vh" }, }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >{validationShowData &&
            validationShowData?.map((item: any) =>{
              return (<>
              <Box
                        sx={{
                          fontSize: "1rem",
                          padding:'0 1rem',
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',gap:'1rem'}}>
                        <span style={{fontWeight:'600'}}>{item?.key}</span>                        
                        </Box>
                        <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',gap:'1rem'}}>
                        {(AUTH.isSuperUser && item?.lock == true && lockStatus == "false") ? (
                          <LockIcon
                            sx={{ cursor: "pointer", color:'green' }}
                            titleAccess="Unlock Parameter"
                            onClick={(event:any) => lockHandler(item,event)}
                          />
                        ) : (
                          (AUTH.isSuperUser && lockStatus == "false") && (
                            <LockOpenIcon
                              sx={{ cursor: "pointer" ,color:'red'}}
                              titleAccess="Unlock Parameter"
                              onClick={(event:any) =>
                                lockHandler(item,event)
                              }
                            />
                          )
                        )}
                        <Button
                          size="small"                         
                          startIcon={
                            <AddIcon sx={{ marginRight: "-0.5rem" ,}} />
                          }
                          sx={{
                            float: "right",
                            fontSize: "1rem",
                            lineHeight: 1,
                            opacity:(item?.lock ==true || lockStatus =="true")?"0.5":"1"
                          }}
                          onClick={(e: any) => {
                            if(item?.lock !=true && lockStatus != "true"){
                            handleValidationAdd(e,item.id)
                            }

                          }}
                        >
                          Validation
                        </Button>
                        </Box>
                        <Box
                          sx={{
                            textAlign: "right",
                            // marginRight: "1rem",
                          }}
                        >
                          {categoryId == 0 ? (
                            <></>
                          ) : (
                            <>
                              {
                                <Checkbox
                                  checkedIcon={
                                    <Box
                                      sx={{
                                        backgroundColor: "white",
                                        borderRadius: "50%",
                                        height: "1rem",
                                        width: "1rem",
                                      }}
                                    >
                                      <VerifiedIcon
                                        sx={{
                                          color: "#39FF14",
                                          position: "relative",
                                          margin: "-0.35rem -0.25rem 0",
                                        }}
                                        onClick={handleChangeCheckBox}
                                      />
                                    </Box>
                                  }
                                  onChange={(e) => handleChangeCheckBox()}
                                  checked={validationdata}
                                  title="Validated"
                                  color="success"
                                  sx={{
                                    "& .MuiSvgIcon-root": { fontSize: 22 },
                                    color: "primary.main",
                                    position: "relative",
                                    zIndex: 1200,
                                  }}
                                />
                              }
                              <Button
                                size="small"
                                startIcon={<AddIcon sx={{ marginRight: "-0.5rem" }} />}
                                sx={{
                                  position: "relative",
                                  zIndex: 1200,
                                  fontSize: "1rem",
                                  lineHeight: 1,
                                  opacity:lockStatus=="true"?"0.5":"1"
                                }}
                                onClick={() => {if(lockStatus != "true"){parameterAdded.open()}}}
                              >
                                Add Validation Parameter
                              </Button>
                            </>
                          )}
                        </Box>
                      </Box>
            <Box
                        sx={{ height: {lg:'79vh',xl:"85vh"},}}
                        adl-scrollbar="true"
                        adl-scrollbar-width="0.3"
                      >
                        {item?.validation?.map((itemInner: any) => {
                          return (
                            <BOMCustomValidationInBetween
                              validationuuid={itemInner?.uid}
                              addedRowSize={itemInner?.expressions?.length}
                              itemInner={itemInner}
                              parameterListData={parameterListData}
                              parameterID={item?.id}
                              relationID={item?.relation_id}                              
                              apiCallValidationShowData={
                                apiCallValidationShowData
                              }
                              setApiCallValidationShowData={
                                setApiCallValidationShowData
                              }
                              handleClickSnackbar={handleClickSnackbar}
                              deleteModelShow={deleteModelShow}
                              setErrorMessage={setErrorMessage}
                              item={item}
                            />
                          );
                        })}
                        {validationCountMap(item?.id, item?.relation_id)}
                      </Box>
                      </>);
              })}
            {/* {validationShowData &&
              validationShowData?.map((item: any) => {
                return (
                  <Accordion
                    key={item?.id}
                    expanded={expanded === item?.id}
                    onChange={handleChange(item?.id)}
                  >
                    <AccordionSummary
                      aria-controls={item?.id}
                      id={item?.id}
                      sx={{
                        minHeight: "36px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          m: "0rem 0rem 0rem 1rem",
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',gap:'1rem'}}>
                        <span>{item?.key}</span>
                        {(AUTH.isSuperUser && item?.lock && lockStatus == "false") ? (
                          <LockIcon
                            sx={{ cursor: "pointer", color:'green' }}
                            titleAccess="Unlock Parameter"
                            onClick={(event:any) => lockHandler(item,event)}
                          />
                        ) : (
                          (AUTH.isSuperUser && lockStatus == "false") && (
                            <LockOpenIcon
                              sx={{ cursor: "pointer" ,color:'red'}}
                              titleAccess="Unlock Parameter"
                              onClick={(event:any) =>
                                lockHandler(item,event)
                              }
                            />
                          )
                        )}
                        </Box>
                        <Button
                          size="small"
                          startIcon={
                            <AddIcon sx={{ marginRight: "-0.5rem" ,}} />
                          }
                          sx={{
                            float: "right",
                            fontSize: "1rem",
                            lineHeight: 1,
                            opacity:(item?.lock ==true || lockStatus =="true")?"0.5":"1"
                          }}
                          onClick={(e: any) => {
                            if(item?.lock !=true && lockStatus != "true"){
                            handleValidationAdd(e)
                            }

                          }}
                        >
                          Validation
                        </Button>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div
                        style={{ height: "65vh" }}
                        adl-scrollbar="true"
                        adl-scrollbar-width="0.3"
                      >
                        {item?.validation?.map((itemInner: any) => {
                          return (
                            <BOMCustomValidationInBetween
                              validationuuid={itemInner?.uid}
                              addedRowSize={itemInner?.expressions?.length}
                              itemInner={itemInner}
                              parameterListData={parameterListData}
                              parameterID={item?.id}
                              relationID={item?.relation_id}
                              apiCallValidationShowData={
                                apiCallValidationShowData
                              }
                              setApiCallValidationShowData={
                                setApiCallValidationShowData
                              }
                              handleClickSnackbar={handleClickSnackbar}
                              deleteModelShow={deleteModelShow}
                              setErrorMessage={setErrorMessage}
                              item={item}
                            />
                          );
                        })}
                        {validationCountMap(item?.id, item?.relation_id)}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                );
              })} */}
          </Box>
        </>
      )}
    </div>
  );
};

export default BOMCustomValidation;
