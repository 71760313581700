import axios, { AxiosError, AxiosResponse } from "axios";
import { API } from "../../../../api-services";
import { MODULES } from "../../../../Constants/modules.constants";
import { s3Url } from "../../../../utlis/s3url.utils";

const uploadS3OperationFlag = (
    id:any,
    rate:any,
    unit:any,
    fileList:any,
    setIsLoadingSave:any,
    pathName:any,
    setApiCallAccordianInsideData:any,
    apiCallAccordianInsideData:any,
    onCloseModal:any,
    setSuccessModalFileMsg:any,
    setErrorModalFileMsg:any,
    SuccessModalFiles:any,
    ErrorModalFiles:any
) => {
    var random: any = Math.floor( Math.random() * 1000000 + 1 );
    var editedImageName: any = `EditedImage${random}`;
    var obj: Array<any> = [];
    API.get( "cart/get_signed_policy/", {
        module: MODULES.CAD,
    } ).then( res => {
        const data = Array.from( fileList ).forEach( ( file: any, index: any ) => {
            const formData = new FormData();
            formData.append( "key", `${pathName}/${random}/${file.name}` );
            formData.append( "Content-Type", file?.type );
            formData.append( "x-amz-server-side-encryption", "AES256" );
            formData.append( "X-Amz-Credential", res.data.data.x_amz_credential );
            formData.append( "X-Amz-Algorithm", "AWS4-HMAC-SHA256" );
            formData.append( "X-Amz-Date", res.data.data.x_amz_date );
            formData.append(
                "X-Amz-Signature",
                res.data.data.aws_policy_signature
            );
            formData.append( "Policy", res.data.data.aws_policy );
            formData.append( "file", file, file?.name );
            axios
                .post( s3Url, formData, {
                    onUploadProgress: data => {
                        //Set the progress value to show the progress bar
                    },
                } )
                .then( async ( res: any ) => {
                    API.put(`/db/stroke_rate_revision/${id}/`, { rate: rate,flag:`/${pathName}/${random}/${file.name}`,operation_unit:unit}, 0).then((res: AxiosResponse) => {
                        setIsLoadingSave(false);
                        setSuccessModalFileMsg('Operation edited successfully!')
                        SuccessModalFiles.open();
                        onCloseModal()
                        setApiCallAccordianInsideData(!apiCallAccordianInsideData);
                    }).catch((err)=>{
                        setIsLoadingSave(false);
                        setErrorModalFileMsg(err.response.data);
                        ErrorModalFiles.open();
                    })
                })
        } );
    } );
};
// ----------------------------------------------------------------------------------
export default uploadS3OperationFlag;