import SUPPLIERDB from "../Redux/Services/suppliers.service";

const commonDataTypes = [
    { key: "float", value: "float", text: "Float" },
    { key: "nonzero-float", value: "nonzero-float", text: "Non Zero, Float" },
    { key: "positive-float", value: "positive-float", text: "Positive Float" },
    {
        key: "nonzero-positive-float",
        value: "nonzero-positive-float",
        text: "Non Zero, Positive Float",
    },
    { key: "negative-float", value: "negative-float", text: "Negative Float" },
    {
        key: "nonzero-negative-float",
        value: "nonzero-negative-float",
        text: "Non Zero, Negative Float",
    },

    // { key: "number", value: "number", text: "Number" },
    { key: "integer", value: "integer", text: "Integer" },
    {
        key: "nonzero-integer",
        value: "nonzero-integer",
        text: "Non Zero, Integer",
    },
    { key: "positive-int", value: "positive-int", text: "Positive Integer" },
    {
        key: "nonzero-positive-int",
        value: "nonzero-positive-int",
        text: "Non Zero, Positive Integer",
    },
    { key: "negative-int", value: "negative-int", text: "Negative Integer" },
    {
        key: "nonzero-negative-int",
        value: "nonzero-negative-int",
        text: "Non Zero, Negative Integer",
    },

    { key: "percentage", value: "percentage", text: "Percentage" },

    { key: "txt", value: "string", text: "Text" },
    { key: "longtext", value: "longtext", text: "Long Text" },
    {key : "360Video" , value : "360_video" , text : "360 Video"},
    { key: "textarea", value: "textarea", text: "Paragraph" },

    // { key: "file", value: "file", text: "File" },
    { key: "table", value: "table", text: "Table" },
    { key: "image", value: "image", text: "Image" },

    { key: "boolean", value: "boolean", text: "Yes / No" },

    { key: "singleselect", value: "singleselect", text: "Single Select" },
    { key: "multiselect", value: "multiselect", text: "Multi Select" },
    { key: "video", value: "video", text: "Video" },
    { key: "database", value: "database", text: "Database" },
    { key: "country", value: "country", text: "Country" },
    { key: "document", value: "document", text: "Document" },
    { key: "date", value: "date", text: "Date" },
    { key: "data&time", value: "date_time", text: "Date And Time" },
];

const numericDataTypes = [
    "float",
    "nonzero-float",
    "positive-float",
    "nonzero-positive-float",
    "negative-float",
    "nonzero-negative-float",
    "integer",
    "nonzero-integer",
    "positive-int",
    "nonzero-positive-int",
    "negative-int",
    "nonzero-negative-int",
    "percentage",
];
const booleanDataTypes = [
    "Yes / No",
    "Yes",
    "No",
    "boolean",
];
const dropdownDataTypes = [
    "singleselect",
    "multiselect",
];

const textDataTypes = [
    "string",
    "longtext",
    "textarea",
    "singleselect",
    "multiselect",
];

const BOMEntryDatabasesMap = {
    SUPPLIER: "SupplierDatabase",
};

const BOMEntryDatabases = [
    {
        key: "supplier_db",
        value: BOMEntryDatabasesMap.SUPPLIER,
        text: "Supplier DB",
    },
];
export interface IDatabaseConfig {
    [key: string]: {
        searchApi: ( ...args: any ) => Promise<any>;
        getSpecificApi: ( ...args: any ) => Promise<any>;
        searchByKey: string;
    };
}

const DatabaseConfig: IDatabaseConfig = {
    SupplierDatabase: {
        searchApi: SUPPLIERDB.searchSuppliers,
        getSpecificApi: SUPPLIERDB.getSpecificSupplier,
        searchByKey: "name",
    },
};

const tableDataTypes = [
    { key: "float", value: "float", text: "Float" },
    { key: "nonzero-float", value: "nonzero-float", text: "Non Zero, Float" },
    { key: "positive-float", value: "positive-float", text: "Positive Float" },
    {
        key: "nonzero-positive-float",
        value: "nonzero-positive-float",
        text: "Non Zero, Positive Float",
    },
    { key: "negative-float", value: "negative-float", text: "Negative Float" },
    {
        key: "nonzero-negative-float",
        value: "nonzero-negative-float",
        text: "Non Zero, Negative Float",
    },

    // { key: "number", value: "number", text: "Number" },
    { key: "integer", value: "integer", text: "Integer" },
    {
        key: "nonzero-integer",
        value: "nonzero-integer",
        text: "Non Zero, Integer",
    },
    { key: "positive-int", value: "positive-int", text: "Positive Integer" },
    {
        key: "nonzero-positive-int",
        value: "nonzero-positive-int",
        text: "Non Zero, Positive Integer",
    },
    { key: "negative-int", value: "negative-int", text: "Negative Integer" },
    {
        key: "nonzero-negative-int",
        value: "nonzero-negative-int",
        text: "Non Zero, Negative Integer",
    },

    { key: "percentage", value: "percentage", text: "Percentage" },

    { key: "txt", value: "string", text: "Text" },
    // { key: "textarea", value: "textarea", text: "Paragraph" },

    { key: "boolean", value: "boolean", text: "Yes / No" },

    { key: "singleselect", value: "singleselect", text: "Single Select" },
    { key: "multiselect", value: "multiselect", text: "Multi Select" },
    { key: "document", value: "document", text: "Document" },
    { key: "date", value: "date", text: "Date" },
    { key: "data&time", value: "date_time", text: "Date And Time" },
    { key: "image", value: "image", text: "Image" },

];

const featureDataTypes = [
    ...commonDataTypes,
    { key: "video", value: "video", text: "Video" },
    { key: "document", value: "document", text: "Document" },
    { key: "date", value: "date", text: "Date" },
    { key: "data&time", value: "date_time", text: "Date And Time" },
];

const dataTypeMap: Record<string, any> = {
    float: "Float",
    "nonzero-float": "Non Zero, Float",
    "positive-float": "Positive Float",
    "nonzero-positive-float": "Non Zero, Positive Float",
    "negative-float": "Negative Float",
    "nonzero-negative-float": "Non Zero, Negative Float",

    integer: "Integer",
    "nonzero-integer": "Non Zero, Integer",
    "positive-int": "Positive Integer",
    "nonzero-positive-int": "Non Zero, Positive Integer",
    "negative-int": "Negative Integer",
    "nonzero-negative-int": "Non Zero, Negative Integer",

    percentage: "Percentage",

    string: "Text",
    longtext: "Long Text",
    textarea: "Paragraph",

    file: "File",
    table: "Table",
    image: "Image",
    video: "Video",

    boolean: "Yes / No",
    // "yesno": "Yes / No",
    // "checkbox": "Checkbox",

    singleselect: "Single Select",
    multiselect: "Multi Select",

    database: "Database",
    document: 'Document',
    date: "Date",
    date_time: "Date And Time",
   "360_video": "360 Video",

};

const getDefaultValue = ( dataType: string ) => {
    switch ( dataType ) {
        case "boolean":
            return false;
        case "video":
        case "image":
        case "document":
            return null;
        case "date":
            return null;
        case "date&time":
            return null;
        case "multiselect":
            return [];
        case "table":
            return [{}];
        default:
            return "";
    }
};

export {
    commonDataTypes,
    textDataTypes,
    tableDataTypes,
    dataTypeMap,
    featureDataTypes,
    getDefaultValue,
    BOMEntryDatabases,
    BOMEntryDatabasesMap,
    DatabaseConfig,
    numericDataTypes,
    booleanDataTypes,
    dropdownDataTypes,
};
