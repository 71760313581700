import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';

import { useFormik } from 'formik';
import * as yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { Divider, FormControl, InputLabel, TextField } from '@mui/material';

import Swal from 'sweetalert2';
import { API } from '../../../api-services';
import { ADMIN } from '../../../Redux/Services/admin.service';
interface InfoModalProps {
	onCloseModal: any;
	isOpen: any;
	Item: any;
	scenarioAmount?: any;
	main_costing_turn_around_time_in_days?: any;
	getPublishStatus?: any;
	BOMDetails?:any;
}

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	minWidth: '10vw',
	maxWidth: '20vw',
	height: 'fit-content',
	bgcolor: 'background.paper',
	borderRadius: '10px',
	p: 1,
};
const validationSchema = yup.object({
	main_costing_amount: yup
		.number()
		.required('Please Enter ZBC Amount (in INR)')
		.max(410000, "Quote amount cannot exceed ₹4.1 lakhs."),
	main_costing_turn_around_time_in_days: yup
		.string()
		.required('Please Enter ZBC Turn Around Time (in Days)'),
});
const PublishBOMModal = (props: InfoModalProps) => {
	const { onCloseModal, isOpen, Item, getPublishStatus, scenarioAmount, main_costing_turn_around_time_in_days,BOMDetails } = props;
	const [loader, setloader] = useState<any>(false);

	const formik = useFormik({
		initialValues: {
			main_costing_turn_around_time_in_days: '',
			main_costing_amount: '',
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			
			setloader(true);
			API.put(`/bom/vault_info/${Item}/`,values, {}, 0).then((res:any)=>{
				getPublishStatus();
				onCloseModal();
			}).catch((err:any)=>{})	
		},
	});

	

	// console.log(Item,scenarioAmount,main_costing_turn_around_time_in_days, 'ItemscenarioAmount');
	React.useEffect(() => {
		formik.setFieldValue('main_costing_turn_around_time_in_days', main_costing_turn_around_time_in_days);
		formik.setFieldValue('main_costing_amount', scenarioAmount);
	}, [main_costing_turn_around_time_in_days, scenarioAmount]);

	return (
		<div>
			<Modal open={isOpen} onClose={onCloseModal}>
				<Box sx={style}>
						<Box
							sx={{
							display: "flex",
							alignItems: "center",
							columnGap: "1rem",
							marginBottom: "0.5rem",             
							}}
						>
							<Typography
							style={{
								fontSize: "1rem",
								fontWeight: "600",
								width: "100%",
								textAlign: "center",
								marginRight: "-2rem",
							}}
							>
							Main ZBC Information
						</Typography>
						<CancelIcon
							titleAccess='Close'
							sx={{ cursor: 'pointer', margin: '0.5rem' }}
							onClick={() => {
								formik.resetForm();
								onCloseModal();
							}}
						/>
					</Box>
					<Divider
						sx={{
							borderColor:'primary.light',
							margin: '0 1rem',
							marginBottom: '1rem',
						}}
					/>
					<Box sx={{ height: 'fit-content', width: '100%' }}>
						<form onSubmit={formik.handleSubmit}>
							<FormControl fullWidth variant='standard'>
								<InputLabel
									sx={{ color: 'primary.main', marginLeft: '1rem' }}
									shrink
									id='demo-simple-select-label'>
									Main ZBC Amount (in INR) <sup style={{ color: 'red' }}>*</sup>
								</InputLabel>
								<TextField
									variant='standard'
									// autoFocus={autoFocusStatus}
									InputProps={{
										style: { color: '#007fff' },
									}}
									InputLabelProps={{
										shrink: true,
									}}
									sx={{
										margin: '1rem 1rem',
										'& .Mui-error': {
											color: '#d32f2f',
											borderBottomColor: '#d32f2f',
										},
										'& .MuiInput-underline:before': {
											borderBottomColor: 'primary.light',
										},
										'& .MuiInput-underline:after': {
											borderBottomColor: 'primary.main',
										},
										'& .MuiFormLabel-root.Mui-focused': {
											color: 'primary.main',
										},
										'& .MuiFormLabel-root.MuiInputLabel-root ': {
											color: 'primary.main',
										},
										'.MuiInputBase-root.MuiInput-root': {
											fontSize: '1rem !important',
										},
									}}
									id='main_costing_amount'
									type='number'
									name='main_costing_amount'
									onChange={formik.handleChange}
									value={formik.values.main_costing_amount}
									error={
										formik.touched.main_costing_amount &&
										Boolean(formik.errors.main_costing_amount)
									}
									helperText={
										formik.touched.main_costing_amount &&
										formik.errors.main_costing_amount
									}
								/>
							</FormControl>
							<FormControl fullWidth variant='standard'>
								<InputLabel
									sx={{ color: 'primary.main', marginLeft: '1rem' }}
									shrink
									id='demo-simple-select-label'>
									Main ZBC Information Turn Around Time (in Days){' '}
									<sup style={{ color: 'red' }}>*</sup>
								</InputLabel>
								<TextField
									variant='standard'
									// autoFocus={autoFocusStatus}
									InputProps={{
										style: { color: '#007fff' },
									}}
									InputLabelProps={{
										shrink: true,
									}}
									sx={{
										margin: '1rem 1rem',
										'& .Mui-error': {
											color: '#d32f2f',
											borderBottomColor: '#d32f2f',
										},
										'& .MuiInput-underline:before': {
											borderBottomColor: 'primary.light',
										},
										'& .MuiInput-underline:after': {
											borderBottomColor: 'primary.main',
										},
										'& .MuiFormLabel-root.Mui-focused': {
											color: 'primary.main',
										},
										'& .MuiFormLabel-root.MuiInputLabel-root ': {
											color: 'primary.main',
										},
										'.MuiInputBase-root.MuiInput-root': {
											fontSize: '1rem !important',
										},
									}}
									type='number'
									id='main_costing_turn_around_time_in_days'
									name='main_costing_turn_around_time_in_days'
									onChange={formik.handleChange}
									value={formik.values.main_costing_turn_around_time_in_days}
									error={formik.touched.main_costing_turn_around_time_in_days && Boolean(formik.errors.main_costing_turn_around_time_in_days)}
									helperText={formik.touched.main_costing_turn_around_time_in_days && formik.errors.main_costing_turn_around_time_in_days}
								/>
							</FormControl>

							<Box sx={{ textAlign: 'right', marginRight: '1rem' }}>
								<LoadingButton
									size='small'
									variant='contained'
									type='submit'
									loading={loader}>
									{Item.lock ? 'Lock & Publish' : 'Update'}
								</LoadingButton>
							</Box>
						</form>
					</Box>
				</Box>
			</Modal>
		</div>
	);
};

export default PublishBOMModal;
