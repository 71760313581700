// react



import axios from "axios";
import React from "react";
import { s3Url } from "../../utlis/s3url.utils";
import { MODULES } from "../../Constants/modules.constants";
import { API } from "../../api-services";
import { uploadFileTriangulation } from "../../Redux/Actions/calculatorInput.reducer";


const FlagFileUpload = (
    fileList: any,
    id: any,
    dispatch: any,
    pathName: any,
    setFile: any,
    setLoading: any,

) => {
    var random: any = Math.floor( Math.random() * 1000000 + 1 );
    API.get( "cart/get_signed_policy/", {
        module: MODULES.CAD,
    } ).then( res => {
        const data = Array.from( fileList ).forEach( ( file: any, index: any ) => {
            const formData = new FormData();
            formData.append( "key", `${pathName}/${id}/${file.name}` );
            formData.append( "Content-Type", file?.type );
            formData.append( "x-amz-server-side-encryption", "AES256" );
            formData.append( "X-Amz-Credential", res.data.data.x_amz_credential );
            formData.append( "X-Amz-Algorithm", "AWS4-HMAC-SHA256" );
            formData.append( "X-Amz-Date", res.data.data.x_amz_date );
            formData.append(
                "X-Amz-Signature",
                res.data.data.aws_policy_signature
            );
            formData.append( "Policy", res.data.data.aws_policy );
            formData.append( "file", file, file?.name );

            axios
                .post( s3Url, formData, {
                    onUploadProgress: data => {
                        //Set the progress value to show the progress bar
                        const progress = data.loaded / data.total;
                        console.log( progress );

                    },
                } )
                .then( async ( res: any ) => {
                    if ( res.status === 204 ) {
                        setLoading( true );

                        const action = await dispatch( uploadFileTriangulation( { id: id, file: `/${pathName}/${id}/${file.name}` } ) );

                        setLoading( false );
                        const fileValue = await action.payload.inputs.image_url;

                        if ( uploadFileTriangulation.fulfilled.match( action ) ) {
                            setFile( fileValue );
                            // ADMIN.toast.success( "File Uploaded successfully" );
                        }
                        else {
                            // ADMIN.toast.error( "File Upload failed" );
                        }
                    }
                } );
        } );
    } );
};

// ----------------------------------------------------------------------------------

export default FlagFileUpload;
