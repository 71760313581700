import { Box, Typography } from "@mui/material";
import * as React from "react";
import BOMEntrySection from "./BOMEntrySection";
import BOMImagesandVideosCombine from "./BOMImagesandVideos/BOMImagesandVideosCombine";
import BOMEntryTreeSection from "./BOMEntryTreeSection";
import { useHistory, useParams } from "react-router-dom";

import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { API } from "../../../api-services";

export interface IBOMCreateCombinePageProps {
  setrecyclebinCounter: any;
}

interface Params {
  vehCateId: any;
  vehCateName: any;
  vault: any;
  // initialTopVault: any;
  // topVaultIds: any;
  // expandTable: any;
}

export default function BOMCreateCombinePage(
  props: IBOMCreateCombinePageProps
) {
  const [isActive, setIsActive] = React.useState(true);
  const { vehCateId, vehCateName } = useParams<Params>();
  const { treeabbr } = useParams<any>();
  const [ParentsCounter, setParentsCounter] = React.useState<any>(0);
  const [syncColor, setSyncColor] = React.useState<any>("");
  const [validateFunc, setValidateFunc] = React.useState<any>();
  const [categoryCounter, setCategoryCounter] = React.useState<any>(0);
  const [childState, setChildState] = React.useState(false);
  const history = useHistory();
  const { setrecyclebinCounter } = props;
  const BOMTreeSecOpen = () => {
    // toggle
    setIsActive((current) => !current);
    // or set to true
    // setIsActive(true);
  };
  React.useEffect(() => {
    sessionStorage.removeItem("TragetIds");
    sessionStorage.removeItem("copyId");
  }, []);
  const { vault, topVault } = useParams<any>();
  const [bomType, setBomType] = React.useState(
    sessionStorage.getItem("bomType") == null ||
      sessionStorage.getItem("bomType") == ""
      ? 1
      : sessionStorage.getItem("bomType") == "Supplier"
      ? 3
      : sessionStorage.getItem("bomType") == "Category"
      ? 2
      : 1
  );

  const changeBomHandler = (typeBom: any) => {
    sessionStorage.setItem("bomType", typeBom);
    setBomType(
      typeBom === "BOM"
        ? 1
        : typeBom === "Category"
        ? 2
        : typeBom === "Supplier"
        ? 3
        : 1
    );
    sessionStorage.removeItem("nodeBomType");
    sessionStorage.setItem("nodeBomType", JSON.stringify([topVault]));
    sessionStorage.removeItem("isCategory");
    sessionStorage.setItem(
      "isCategory",
      JSON.stringify([treeabbr, false, topVault])
    );

    // if (typeBom == "BOM") {
    //   history.push(
    //     `/bomentry/${vehCateId}/${vehCateName}/${topVault}/${vault}/1/PRODUCT/0/0`
    //   );
    // } else if (typeBom == "Category") {
    //   history.push(
    //     `/bomentry/${vehCateId}/${vehCateName}/${topVault}/${vault}/2/PRODUCT/0/0`
    //   );
    // } else  {
    //   history.push(
    //     `/bomentry/${vehCateId}/${vehCateName}/${topVault}/${vault}/2/PRODUCT/0/0`
    //   );
    // }
  };

  const obj = (tv: any, vlt: any) => {
    if (vlt == 0) {
      return { vehicle_category: tv };
    } else {
      return { vault: vlt };
    }
  };

  const getVaultBOMDetailsData = () => {
    API.get(
      "/bom/vault_info/",
      {
        ...obj(vehCateId, vault),
        module: 9,
      },
      0
    ).then((res) => {
      if (res.data) {
        setChildState(res.data[0]?.is_owned_by_supplier);
      }
    });
  };

  React.useEffect(() => {
    getVaultBOMDetailsData();
  }, [vehCateId, vault]);

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          width: "100%",
          padding: "0 0.5rem",
          height: "100%",
          border: "2px solid",
          borderColor: childState == true ? "red" : "white",
        }}
      >
        <Box
          sx={{
            width: isActive
              ? { xs: "20rem", sm: "25vw", md: "25vw", lg: "25vw", xl: "22vw" }
              : "0vw",
            borderRight: isActive ? "1px solid #ddefff" : "0px",
            height: { xs: "84vh", sm: "", md: "", lg: "87vh", xl: "88vh" },
            transition: "width 1s ease-in-out",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              borderBottom: "1px solid",
              borderBottomColor: "primary.light",
            }}
          >
            <Box
              sx={
                bomType == 1
                  ? {
                      backgroundColor: "primary.main",
                      color: "white",
                      cursor: "pointer",
                      width: "100%",
                      textAlign: "center",
                      border: "1px solid",
                      borderColor: "#ddefff",
                    }
                  : {
                      width: "100%",
                      textAlign: "center",
                      cursor: "pointer",
                      border: "1px solid",
                      borderColor: "#ddefff",
                    }
              }
              onClick={() => changeBomHandler("BOM")}
            >
              BOM
            </Box>
            <Box
              sx={
                bomType == 2
                  ? {
                      backgroundColor: "primary.main",
                      color: "white",
                      cursor: "pointer",
                      width: "100%",
                      textAlign: "center",
                      border: "1px solid",
                      borderColor: "#ddefff",
                    }
                  : {
                      width: "100%",
                      textAlign: "center",
                      cursor: "pointer",
                      border: "1px solid",
                      borderColor: "#ddefff",
                    }
              }
              onClick={() => changeBomHandler("Category")}
            >
              Category
            </Box>
            <Box
              sx={
                bomType == 3
                  ? {
                      backgroundColor: "primary.main",
                      color: "white",
                      cursor: "pointer",
                      width: "100%",
                      textAlign: "center",
                      border: "1px solid",
                      borderColor: "#ddefff",
                    }
                  : {
                      width: "100%",
                      textAlign: "center",
                      cursor: "pointer",
                      border: "1px solid",
                      borderColor: "#ddefff",
                    }
              }
              onClick={() => changeBomHandler("Supplier")}
            >
              Supplier
            </Box>
          </Box>
          <BOMEntryTreeSection
            bomType={bomType}
            setrecyclebinCounter={setrecyclebinCounter}
            setParentsCounter={setParentsCounter}
            ParentsCounter={ParentsCounter}
            syncColor={syncColor}
            setSyncColor={setSyncColor}
          />
          <Box sx={{ width: "1rem" }}>
            <Box
              sx={{
                position: "relative",
                marginTop: "-2rem",
                marginLeft: "-3.5rem",
              }}
            >
              {isActive ? (
                <ArrowCircleLeftIcon
                  color="primary"
                  titleAccess="Collapse Tree"
                  onClick={BOMTreeSecOpen}
                  sx={{ cursor: "pointer" }}
                />
              ) : (
                <ArrowCircleRightIcon
                  color="primary"
                  titleAccess="Expand Tree"
                  onClick={BOMTreeSecOpen}
                  sx={{ cursor: "pointer" }}
                />
              )}
            </Box>
          </Box>
        </Box>
        {treeabbr != 0 && (
          <Box
            sx={{
              width: isActive
                ? { xs: "50rem", sm: "", md: "", lg: "50vw", xl: "50vw" }
                : { xs: "70rem", sm: "", md: "", lg: "75vw", xl: "72vw" },
              borderRight: "1px solid #ddefff",
              height: { xs: "84vh", sm: "", md: "", lg: "87vh", xl: "89vh" },
              padding: "0 0.5rem",
              transition: "width 1s ease-in-out",
            }}
          >
            {treeabbr == "delete" ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "30rem",
                }}
              >
                {" "}
                <Typography>Select Subsystem/Assembly/Part.</Typography>
              </Box>
            ) : (
              <BOMEntrySection
                setrecyclebinCounter={setrecyclebinCounter}
                setParentsCounter={setParentsCounter}
                ParentsCounter={ParentsCounter}
                setSyncColor={setSyncColor}
                setCategoryCounter={setCategoryCounter}
                categoryCounter={categoryCounter}
              />
            )}
          </Box>
        )}
        {treeabbr != 0 && (
          <Box
            sx={{
              width: {
                xs: "20rem",
                sm: "20rem",
                md: "20vw",
                lg: "20vw",
                xl: "23vw",
              },
              height: {
                xs: "84vh",
                sm: "86vh",
                md: "86vh",
                lg: "86vh",
                xl: "89.5vh",
              },
              padding: "0 0.5rem",
            }}
          >
            {treeabbr !== "delete" && (
              <BOMImagesandVideosCombine
                setParentsCounter={setParentsCounter}
                ParentsCounter={categoryCounter}
              />
            )}
          </Box>
        )}
      </Box>
    </div>
  );
}
