// vendors
import { createEntityAdapter, createReducer, createSelector, EntityState } from "@reduxjs/toolkit";
import { ID } from "../../utlis/commonInterfaces";
import { copyRawMaterial, createRawMaterialCardCosts, deleteRawMaterialCardCost, getRawMaterialCardCosts, resetRawMaterialCard, updateProcureRawMaterialCardCost, updateRawMaterialCardCost } from "../Actions/rawMaterialCard.actions";
import { RootState } from "../Store/storeConfigurations";

// services
// import { ADMIN } from "app/services";
// import { Material } from "app/services/material.service";

// redux
// import {
//     copyRawMaterial,
//     createRawMaterialCardCosts,
//     deleteRawMaterialCardCost,
//     getRawMaterialCardCosts,
//     resetRawMaterialCard,
//     updateProcureRawMaterialCardCost,
//     updateRawMaterialCardCost
// } from "app/Redux/Actions/rawMaterialCard.actions";
// import { RootState } from "app/Redux/storeConfigurations";

// interfaces
// import { ID } from "app/commonInterfaces";
// import { IFastener } from "app/Components/Databases/FastenersDatabase/FastenersDatabase";
// import { PurchasingItem } from "app/services/purchasingItemsDatabase.service";
// import { ProcureDataCir } from "./procuredata.reducer";

// -----------------------------------------------------------------------------------
// entity adaptor

export interface RawMaterialCardCost {
    id: ID;
    sequence:any;
    // readonly material: Material;
    // readonly fastener: IFastener;
    // readonly purchasing_item: PurchasingItem;
    // readonly procure: ProcureDataCir;

    material_result: string | null;
    bop_result: string | null;
    readonly material_cost: number;
    readonly bop_cost: number;
    alias: string | null;
    quantity: number;
    l_a_w: any;

    /** ids for redirection(has no other inportance) */
    readonly link: {
        commodity_mapping: ID;
        process: ID;
    } | null;
}

const collator = new Intl.Collator( undefined, { numeric: true, sensitivity: "base" } );

const adaptor = createEntityAdapter<RawMaterialCardCost>( {
    selectId: ( rawMaterialCard ) => rawMaterialCard?.id,
} );

// -----------------------------------------------------------------------------------
// interfaces & initialState

type AdditionalFields = {};

type IRawMaterialCardReducer = EntityState<RawMaterialCardCost> & AdditionalFields;

const additionalFields: AdditionalFields = {};

const initialState: IRawMaterialCardReducer = Object.assign( {}, adaptor.getInitialState(), additionalFields );

// -----------------------------------------------------------------------------------
// reducer

const RawMaterialCardReducer = createReducer( initialState, builder => {
    // get
    builder
        .addCase( getRawMaterialCardCosts.fulfilled, ( state, action: any ) => {
            adaptor.setAll( state, action.payload.costs );
        } )
        .addCase( getRawMaterialCardCosts.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not fetch costs" );
        } );

    // create
    builder
        .addCase( createRawMaterialCardCosts.fulfilled, ( state, action: any ) => {
            adaptor.addMany( state, action.payload.costs );

            // ADMIN.toast.success( "Raw material cost(s) created" );
        } )
        .addCase( createRawMaterialCardCosts.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not create cost(s)" );
        } );


    // update
    builder
        .addCase( updateRawMaterialCardCost.fulfilled, ( state, action: any ) => {
            adaptor.upsertOne( state, action.payload.cost );

            // ADMIN.toast.success( "Raw material cost updated" );
        } )
        .addCase( updateRawMaterialCardCost.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not update cost" );
        } );
    //procure update----------

    builder
        .addCase( updateProcureRawMaterialCardCost.fulfilled, ( state, action: any ) => {
            adaptor.upsertOne( state, action.payload.cost );

            // ADMIN.toast.success( "Raw material cost updated" );
        } )
        .addCase( updateProcureRawMaterialCardCost.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not update cost" );
        } );
    // delete
    builder
        .addCase( deleteRawMaterialCardCost.fulfilled, ( state, action ) => {
            const { id } = action.meta.arg;
            adaptor.removeOne( state, id );

            // ADMIN.toast.success( "Raw material cost deleted" );
        } )
        .addCase( deleteRawMaterialCardCost.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not delete cost" );
        } );


    // material copy----------------------
    builder
        .addCase( copyRawMaterial.fulfilled, ( state, action: any ) => {
              adaptor.addMany( state, action.payload.costs);
            // ADMIN.toast.success( "Raw material cost(s) Copied" );
        } )
        .addCase( copyRawMaterial.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not create cost(s)" );
        } );

    // reset
    builder.addCase( resetRawMaterialCard, () => initialState );
} );

// -----------------------------------------------------------------------------------
// selectors

const selectors = adaptor.getSelectors<RootState>( ( state ) => state.rawMaterialCard );

const selectMaterialIds = createSelector(
    selectors.selectAll,
    ( costs ) => {
        // @ts-ignore
        return costs.map( cost => cost?.material?.id );
    }
);

const rawMaterialCardSelectors = Object.assign( {}, { selectMaterialIds }, selectors );

// -----------------------------------------------------------------------------------
// exports

export {
    RawMaterialCardReducer,
    initialState as RawMaterialCardReducerInit,
    rawMaterialCardSelectors
};