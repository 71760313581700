import * as React from 'react';
import { Box, Checkbox, IconButton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import styles from './BillinginfoMainPage.module.scss';
import AddIcon from '@mui/icons-material/Add';
import { Divider, Drawer } from '@mui/material';
import { API } from '../../../api-services';
import BillingInfoTable from './BillingInfoTable';
import { get } from 'lodash';
import UserManagementDrawer from '../UserManagementDrawer';

export interface IBillinginfoMainPageProps {}

type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface Params {
	type: string;
}


export default function BillinginfoMainPage(props: IBillinginfoMainPageProps) {
	const history = useHistory();
    // const { url } = useRouteMatch();
    // const { type } = useParams<Params>();
    // // const [BillingData, setBillingData] = React.useState<any>([]);
    // const [billingLoader, setBillingLoader] = React.useState<boolean>(false);
    // const [billingType, setBillingType] = React.useState<string>('');
	const handleClick = () => {
		history.push('/internallanding');
	};
	const [state, setState] = React.useState({
		top: false,
		left: false,
		bottom: false,
		right: false,
	});

	const toggleDrawer =
		(anchor: Anchor, open: boolean) =>
		(event: React.KeyboardEvent | React.MouseEvent) => {
			if (
				event.type === 'keydown' &&
				((event as React.KeyboardEvent).key === 'Tab' ||
					(event as React.KeyboardEvent).key === 'Shift')
			) {
				return;
			}

			setState({ ...state, [anchor]: open });
		};
    
//     const billingHandler = (type: any) => {
//         let billingType = type;
//         setBillingLoader(true);
//         API.get('payment/get_all_billing_detail/', {
//             billing_type: billingType,
//         }, 0)
//             .then((res: any) => {
//                 setBillingData(res.data);
//                 history.push(`/user-management/billing-info/${billingType}`);
//                 setBillingLoader(false);
//             })
//             .catch((err: any) => {
//                 console.log(err);
//             });
//     };

//    React.useEffect(() => {
//         billingHandler(type);
//     }, [type]);

//     React.useEffect(() => {        
//         if (type === 'subscription') {
//             setBillingType('Subscription');
//         } else if (type === 'zbc_request') {
//             setBillingType('ZBC Request');
//         } else if (type === 'scenario_request') {
//             setBillingType('Scenario Request');
//         } else if (type === 'add_part') {
//             setBillingType('Add Part');
//         }
//     }, [type]);



	const list = (anchor: Anchor) => (
		<UserManagementDrawer />
	);

	return (
		<div>
			{/* <Box sx={{ margin: '0 0.5rem' }}>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						width: '100%',
					}}>
					<Box
						sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
						<IconButton
							sx={{ cursor: 'pointer' }}
							title='Go Back'
							onClick={handleClick}>
							<ArrowBackIcon color='primary' titleAccess='Go Back' />
						</IconButton>
						<Typography
							sx={{
								fontSize: '1rem',
								whiteSpace: 'nowrap',
								fontWeight: 'bold',
							}}>
							Billing Info ({billingType})
						</Typography>
					</Box>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							columnGap: '1rem',
						}}></Box>
				</Box>
			</Box> */}
			<Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
				<Box className={styles.expandIconBox}>
					<div className={styles.iconSec}>
						{(['left'] as const).map((anchor) => (
							<React.Fragment key={anchor}>
								<IconButton
									size='medium'
									sx={{
										backgroundColor: 'primary.light',
										color: 'primary.main',
									}}
									onClick={toggleDrawer(anchor, true)}>
									<AddIcon sx={{ fontSize: '1.7rem' }} />
								</IconButton>
								<Drawer
									anchor={anchor}
									open={state[anchor]}
									onClose={toggleDrawer(anchor, false)}
									className={styles.drawerContainer}
									PaperProps={{
										sx: {
											backgroundColor: 'aliceblue',
										},
									}}>
									<Box
										sx={{ padding: '1rem' }}
										adl-scrollbar='true'
										adl-scrollbar-width='0.3'>
										<Typography style={{ width: '100%', textAlign: 'center' }}>
											Selection Options
										</Typography>
										<Divider sx={{ margin: '1rem' }} />
										{list(anchor)}
									</Box>
								</Drawer>
								<Typography
									style={{ cursor: 'pointer' }}
									className={styles.sideTitle}
									onClick={toggleDrawer(anchor, true)}>
									Selection Options
								</Typography>
							</React.Fragment>
						))}
					</div>
				</Box>
				<Box className={styles.parent_container}>
                       <BillingInfoTable />             
				</Box>
			</Box>
		</div>
	);
}
