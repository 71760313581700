import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import styles from "../AddRevisionModal.module.scss";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { useRouteMatch } from "react-router-dom";
import Swal from "sweetalert2";
import successIcon from "../../../Assets/images/success.png";

export interface IBearingFormProps {
  actions: any;
  onCloseModal: any;
  getCategoryData: any;
  category: any;
  onCloseParent: any;
}
const validationSchema = yup.object({
  code: yup.string().required("Please type a Item Code"),
  name: yup.string().required("Please type Item name"),
  bearing_code: yup.string().required("Please type Item bearing code"),
  item_id: yup.mixed().required("Please type Item ID"),
  item_od: yup.mixed().required("Please type Item OD"),
  height: yup.mixed().required("Please enter Height"),
  weight: yup.mixed().required("Please type Item weight"),
  reference: yup.string().required("Please type Item reference"),
  unit_cost: yup.mixed().required("Please enter per unit cost"),
});
const useStyles = makeStyles((theme) => ({
  select: {
    "&.MuiInputBase-root-MuiInput-root-MuiSelect-root": {
      borderBottom: "1px solid red",
    },
  },
}));
export function BearingFormMove(props: IBearingFormProps) {
  const [isLoadingsave, setIsLoadingSave] = React.useState<any>(false);
  const { url } = useRouteMatch();
  const { actions, onCloseModal, getCategoryData, category, onCloseParent } = props;
  const { categoryName } = useRouteParams<any>();
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      code: "",
      name: "",
      bearing_code: "",
      item_id: "",
      item_od: "",
      height: "",
      weight: "",
      reference: "",
      unit_cost: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsLoadingSave(true);
      API.post(
        "db/move_cost_data/",
        {
          ...values,
          ...{ target_commodity: category, id: url.includes("DB") ? actions.id : actions.purchase_item, boughtout: true },
        },
        {},
        0
      )
        .then((res: any) => {
          getCategoryData(categoryName);
          Swal.fire({
            html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />

      <br />

      <p style="color:#007fff;">Moved Successfully!</p>   

       </div>`,
          });
          onCloseModal();
          onCloseParent();
        })
        .catch((err: any) => {});
    },
  });
  React.useEffect(() => {
    if (actions !== "add") {
      formik.setFieldValue("code", actions?.code || "");
      formik.setFieldValue("name", actions?.name || "");
      formik.setFieldValue("bearing_code", actions?.bearing_code || "");
      formik.setFieldValue("item_id", actions?.item_id || "");
      formik.setFieldValue("item_od", actions?.item_od || "");
      formik.setFieldValue("height", actions?.height || "");
      formik.setFieldValue("weight", actions?.weight || "");
      formik.setFieldValue("reference", actions?.reference || "");
      formik.setFieldValue("unit_cost", parseFloat(actions.unit_cost?.slice(1)) || "");
    }
  }, [actions]);
  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: "flex", alignItems: "flex-start", columnGap: "1rem", marginBottom: "0.5rem" }}>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="Item Code"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="code"
              name="code"
              onChange={formik.handleChange}
              value={formik.values.code}
              multiline
              error={formik.touched.code && Boolean(formik.errors.code)}
              helperText={formik.touched.code && formik.errors.code}
            />
          </FormControl>
          <FormControl
            fullWidth
            variant="standard">
            <InputLabel
              sx={{ color: "primary.main" }}
              shrink
              id="demo-simple-select-label">
              Select Item name{" "}
            </InputLabel>

            <Select
              id="name"
              name="name"
              className={classes.select}
              labelId="demo-select-small"
              // id="demo-select-small"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                  fontSize: "1rem",
                },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
              error={formik.touched.name && Boolean(formik.errors.name)}
              //@ts-ignore
              helperText={formik.touched.name && formik.errors.name}
              MenuProps={{
                style: {
                  maxHeight: 400,
                  color: "007fff",
                },
              }}
              onChange={formik.handleChange}
              value={formik.values.name !== null ? formik.values.name : ""}>
              {/* <option value={""} style={{ display: "none" }}></option> */}
              {["Ball Bearing", "Roller Bearing", "Needle Bearing", "Conical Bearing", "Thrust Bearing", "Others"].map((item: any, index: any) => {
                return (
                  <MenuItem
                    sx={{ color: "primary.main", fontSize: "1rem" }}
                    key={index}
                    value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
            <div className={styles.errormsg}>{formik.touched.name && Boolean(formik.errors.name) && <p>{formik.errors.name}</p>}</div>
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-start", columnGap: "1rem", marginBottom: "0.5rem" }}>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="Bearing Code"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="bearing_code"
              name="bearing_code"
              onChange={formik.handleChange}
              value={formik.values.bearing_code}
              multiline
              error={formik.touched.bearing_code && Boolean(formik.errors.bearing_code)}
              helperText={formik.touched.bearing_code && formik.errors.bearing_code}
            />
          </FormControl>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="ID(mm)"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="item_id"
              name="item_id"
              onChange={formik.handleChange}
              value={formik.values.item_id}
              type="number"
              error={formik.touched.item_id && Boolean(formik.errors.item_id)}
              helperText={formik.touched.item_id && formik.errors.item_id}
            />
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-start", columnGap: "1rem", marginBottom: "0.5rem" }}>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="OD(mm)"
              type="number"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="item_od"
              name="item_od"
              onChange={formik.handleChange}
              value={formik.values.item_od}
              error={formik.touched.item_od && Boolean(formik.errors.item_od)}
              helperText={formik.touched.item_od && formik.errors.item_od}
            />
          </FormControl>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="Height(mm)"
              type="number"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="height"
              name="height"
              onChange={formik.handleChange}
              value={formik.values.height}
              error={formik.touched.height && Boolean(formik.errors.height)}
              helperText={formik.touched.height && formik.errors.height}
            />
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-start", columnGap: "1rem", marginBottom: "0.5rem" }}>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="Weight(g)"
              type="number"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="weight"
              name="weight"
              onChange={formik.handleChange}
              value={formik.values.weight}
              error={formik.touched.weight && Boolean(formik.errors.weight)}
              helperText={formik.touched.weight && formik.errors.weight}
            />
          </FormControl>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="Per unit cost (INR)"
              type="number"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="unit_cost"
              name="unit_cost"
              onChange={formik.handleChange}
              value={formik.values.unit_cost}
              error={formik.touched.unit_cost && Boolean(formik.errors.unit_cost)}
              helperText={formik.touched.unit_cost && formik.errors.unit_cost}
            />
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-start", columnGap: "1rem", marginBottom: "0.5rem" }}>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="Source"
              multiline
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="reference"
              name="reference"
              onChange={formik.handleChange}
              value={formik.values.reference}
              error={formik.touched.reference && Boolean(formik.errors.reference)}
              helperText={formik.touched.reference && formik.errors.reference}
            />
          </FormControl>
        </Box>
        <Box sx={{ width: "100%", textAlign: "right" }}>
          <LoadingButton
            size="small"
            loading={isLoadingsave}
            variant="contained"
            type="submit"
            sx={{ marginTop: "1rem" }}>
            Move
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
}
