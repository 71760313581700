import {
  Box,
  Checkbox,
  Divider,
  Drawer,
  Button,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Typography,
  Menu,
  Table,
  TableRow,
  TableCell,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { API } from "../../api-services";
import { MODULES } from "../../Constants/modules.constants";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import styles from "./IdeaLandingPage.module.scss";
import AddIcon from "@mui/icons-material/Add";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import ImageIcon from "@mui/icons-material/Image";
import InfoIcon from "@mui/icons-material/Info";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import Popper, { PopperPlacementType } from "@mui/material/Popper";
import { ButtonProps } from "@mui/material/Button";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarColumnsButton,
  getGridNumericOperators,
} from "@mui/x-data-grid";
import moment from "moment";
import { useIdeaImageOpenModal } from "./useIdeaImageOpenModal";
import IdeaImageViewModal from "./IdeaImageViewModal";
import LoadingButton from "@mui/lab/LoadingButton";
import excelIcon from "../../Assets/images/excel.png";
import pdfIcon from "../../Assets/images/pdf.png";
import { useBaseModal } from "../SearchPage/useBaseModal";
import { DialogComponent } from "../../utlis/DialogComponent";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";



type Anchor = "top" | "left" | "bottom" | "right";

// Below code is used for the toolbar in DataGrid Table

const csvOptions: GridCsvExportOptions = {
  fileName: "Idea Approve Table In CSV",
  delimiter: ",",
  utf8WithBom: true
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: "3.5rem" }}>
    <span style={{ position: "absolute", right: "0rem" }}>
      <GridToolbarColumnsButton />
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

interface Params {
  projectId: any;
  topVaultId: any;
  abbreviation: any;
  vaultId: any;
}

interface IdeaLandingPageProps { }

const IdeaLandingPage: React.FC<IdeaLandingPageProps> = (props) => {
  const { projectId, topVaultId, abbreviation, vaultId } =
    useRouteParams<Params>();
  const [selectedProducts, setSelectedProducts] = useState<any>([]);

  useEffect(() => {
    sessionStorage.setItem("historyProject", projectId);
  }, [projectId]);

  const [projectAvailable, setProjectAvailable] = useState<any>([]);
  const [abbrAvailable, setAbbrAvailable] = useState<any>([]);
  const [apiIdeaDataFetch, setApiIdeaDataFetch] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorElMenu);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState<PopperPlacementType>();
  const [ideaData, setIdeaData] = useState<any>();
  const [okButtonLoader, setOKButtonLoader] = useState<boolean>(false);
  let refText = useRef<any>(null);
  const history = useHistory();
  const imageIdeaFile = useIdeaImageOpenModal();
  const [selectedAbbr, setSelectedAbbr] = useState<any>([]);
  const [loadAllLogs, setLoadAllLogs] = useState<boolean>(true);

  const [anchorElDownload, setAnchorElDownload] = React.useState<null | HTMLElement>(null);
  const openDownload = Boolean(anchorElDownload);
  const [anchorElDownloadConsolidated, setAnchorElDownloadConsolidated] = React.useState<null | HTMLElement>(null);
  const openConsolidated = Boolean(anchorElDownloadConsolidated);
  const { height, width } = useWindowDimensions();
  const [contentText, setContentText] = React.useState<any>("Report download is running in background");
  const [iconText, setIconText] = React.useState<any>("success")

  const [ideaIdGet, setideaIdGet] = useState<any>();

  const openDialogModal = useBaseModal();

  const openImageIdeaFileModal = async (id: string, imageData?: number) => {
    imageIdeaFile.open(id, imageData);
  };

  useEffect(() => {
    setSelectedAbbr(abbreviation);
  }, [abbreviation])

  const selectAllAbbr = (abbr: any) => {
    setSelectedAbbr([abbr])
  };

  useEffect(() => {
    setApiIdeaDataFetch(true);
    if (abbreviation == "0") {
      API.get(
        `/cost/idea_data/`,
        {
          project: projectId,
          module: MODULES.IDEA_GENERATION,
          top_vault_id: topVaultId,
        },
        0
      ).then((res: any) => {
        return setIdeaData(res?.data), setApiIdeaDataFetch(false);
      });
    } else {
      API.get(
        `/cost/idea_data/`,
        {
          project: projectId,
          module: MODULES.IDEA_GENERATION,
          abbreviation: abbreviation,
          vault_id: vaultId,
        },
        0
      ).then((res: any) => {
        return setIdeaData(res?.data), setApiIdeaDataFetch(false);
      });
    }
  }, [abbreviation, projectId]);

  useEffect(() => {
    setLoadAllLogs(true);
    API.get(
      `/xcpep/top_vault/`,
      {
        project: projectId,
        module: MODULES.IDEA_APPROVAL,
        abbreviation: abbreviation,
      },
      0
    ).then((res: any) => {
      setLoadAllLogs(false);
      setProjectAvailable(res?.data);
    });
  }, [projectId, abbreviation, topVaultId]);
  useEffect(() => {
    API.get(
      `customer/dashboard/`,
      { top_vault_id: topVaultId, get_idea_abbreviation: true },
      0
    ).then((res: any) => {
      setAbbrAvailable(res?.data);
    });
  }, [projectId, topVaultId]);

  const subsystemListData: any = [];
  const valueUser =
    abbrAvailable &&
    abbrAvailable?.map((subsystemList: any, index: number) => {
      subsystemListData.push({
        title: subsystemList?.vault_name,
        value: subsystemList?.abbreviation,
      });
    });

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }
        setSelectedProducts([topVaultId]);
        setSelectedAbbr(abbreviation);
        setState({ ...state, [anchor]: open });
      };


  const getselectedBackgroundColor = (idd: any) => {
    if (selectedProducts?.includes(idd)) {
      return "primary.main";
    } else {
      return "primary.light";
    }
  };
  const getSelectedTextColor = (idd: any) => {
    if (selectedProducts?.includes(idd)) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };

  const backButtonHandler: any = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseDownload = () => {
    setAnchorElDownload(null);
  };

  const handlePDFImage = () => {
    handleCloseDownload();
  }

  const handleExcelReport = async (ideaIdComing:any) => {
    handleCloseDownload();
    setIconText("firstdownload");
    setContentText("We've got this report downloading faster than a cheetah running. You will also receive a notification.But in the meantime, keep using xcPEP like the productivity champion !");
    openDialogModal.open();
    try {
      const { data: url } = await API.get(
        "/cost/individual_report/",
        {
          idea_id: ideaIdComing,
          individual_type: "Idea"
        },
        0
      );
      window.open(url, "_blank", "noopener noreferrer");
    } catch (e) {
    }
  };

  const handleCloseDownloadConsolidated = () => {
    setAnchorElDownloadConsolidated(null);
  };

  const handleWithoutImageConsolidated = () => {
    handleCloseDownloadConsolidated();
    API.get("/cost/check_consolidate_downoad/", { vault: vaultId, with_image: false, consolidate_type: "Idea" }, 0).then((res: any) => {
      if (res.data.status === "Failed") {
        openDialogModal.open();
        setIconText("failed")
        setContentText(res.data.message)
      } else if (res.data.status === "First Download") {
        openDialogModal.open();
        setIconText("firstdownload")
        setContentText(res.data.message)
        API.get(
          "/cost/consolidated_report/",
          {
            vault: vaultId,
            consolidate_type: "Idea",
            with_image: false
          },
          0
        ).then((res: any) => {
          window.open(res?.data, "_blank");
        }).catch((err: any) => {
          openDialogModal.open();
          setIconText("failed")
          setContentText(err.response.data)
        });
      } else if (res.data.status === "In Progress") {
        openDialogModal.open();
        setIconText("reportProgress")
        setContentText(res.data.message)
      } else if (res.data.status === "Completed") {
        window.open(res?.data?.url, "_blank");
      }
    })
  }

  const handleWithImageConsolidated = async () => {
    handleCloseDownloadConsolidated();
    API.get("/cost/check_consolidate_downoad/", { vault: vaultId, with_image: true, consolidate_type: "Idea" }, 0).then((res: any) => {
      if (res.data.status === "Failed") {
        openDialogModal.open();
        setIconText("failed")
        setContentText(res.data.message)
      } else if (res.data.status === "First Download") {
        openDialogModal.open();
        setIconText("firstdownload")
        setContentText(res.data.message)
        API.get(
          "/cost/consolidated_report/",
          {
            vault: vaultId,
            consolidate_type: "Idea",
            with_image: true
          }, 0).then((res: any) => {
            window.open(res?.data, "_blank");
          }).catch((err: any) => {
            openDialogModal.open();
            setIconText("failed")
            setContentText(err.response.data)
          });
      } else if (res.data.status === "In Progress") {
        openDialogModal.open();
        setIconText("reportProgress")
        setContentText(res.data.message)
      } else if (res.data.status === "Completed") {
        window.open(res?.data?.url, "_blank");
      }
    })
  }

  const handleClickDownloadConsolidated = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElDownloadConsolidated(event.currentTarget);
  };

  const handleClickDownload = (event: React.MouseEvent<HTMLButtonElement>, idIdea: any) => {
    setAnchorElDownload(event.currentTarget);
    setideaIdGet(idIdea)
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    newPlacement: PopperPlacementType,
    text: any
  ) => {
    refText.current = null;
    refText.current = { parameter: text[0], origin: text[1], submittedBy: text[2], implementationEase: text[3], implementationTime: text[4] }
    setAnchorEl(event.currentTarget);
    setOpen(open == true ? false : true);
    setPlacement(newPlacement);
  };

  const handleChangeIdea = () => {
    setOKButtonLoader(true);
    if (selectedProducts[0] == topVaultId) {
      const abbFiltered: any = abbrAvailable?.filter(
        (subsystemList: any, index: number) => {
          return subsystemList?.abbreviation == selectedAbbr;
        }
      );
      setOKButtonLoader(false);
      if (selectedAbbr == 0) {
        // history.push(`/idea/${projectId}/${topVaultId}/0/product`);
      } else {
        history.push(
          `/idea/${projectId}/${topVaultId}/${selectedAbbr}/${abbFiltered[0]?.vault_id}`
        );
      }
    }
  };

  const redirectInsideIdea = (id: any) => {
    history.push(
      `/idea/${projectId}/${topVaultId}/${abbreviation}/${vaultId}/${id}`
    );
  };

  // columns is a variable where column data is stored. This columns is then used as a prop in dataGrid.
  // If changeEvent is false then it for My files and true is for shared with me.

  let columns: any = [
    {
      field: "idea_id", headerName: "Idea ID", minWidth: width > 1400 ? 180 : 140 , flex: 1,
      renderCell: (cellValues: any) => {
        return (
          <>
            <p style={{ textDecoration: 'underline' }} onClick={() => redirectInsideIdea(cellValues?.row?.id)}>{cellValues?.row?.idea_id}</p>
            <IconButton
            size="small"
              color="inherit"
              style={{ marginLeft: "1rem" }}
              onClick={()=>handleExcelReport(cellValues?.row?.id)}            
            >
              <DownloadIcon style={{ cursor: "pointer" }} />
            </IconButton>            
          </>)
      }
    },
    {
      field: "text_data",
      headerName: "Idea Description",
      minWidth: width > 1400 ? 1000 : 620,
      flex: 1,
    },
    { field: "cost", headerName: "Cost Saving (INR)", type: 'number', minWidth: 130, flex: 1 },
    {
      field: "weight",
      headerName: "Weight Saving(g)",
      type: 'number',
      minWidth: 130,
      flex: 1,
    },
    {
      field: "details",
      headerName: "Details",
      minWidth: width > 1400 ? 130 : 90,
      flex: 1,
      disableExport: true,
      renderCell: (cellValues: any) => {
        return (
          <>
            <Popper
              open={open}
              anchorEl={anchorEl}
              placement={placement}
              transition
              id={cellValues?.row?.id}
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper sx={{ boxShadow: 'none', borderRadius: '5px' }}>
                    <Table size="small" aria-label="a dense table">
                      <TableRow>
                        <TableCell sx={{ borderBottomColor: 'primary.light' }}>
                          Parameter
                        </TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light' }}>
                          {refText.current.parameter}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ borderBottomColor: 'primary.light' }}>
                          Origin
                        </TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light' }}>
                          {refText.current.origin}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ borderBottomColor: 'primary.light' }}>
                          Submitted By
                        </TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light' }}>
                          {refText.current.submittedBy}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ borderBottomColor: 'primary.light' }}>
                          Implementation Ease
                        </TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light' }}>
                          {refText.current.implementationEase}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ borderBottomColor: 'primary.light' }}>
                          Implementation Time
                        </TableCell>
                        <TableCell sx={{ borderBottomColor: 'primary.light' }}>
                          {refText.current.implementationTime} Days
                        </TableCell>
                      </TableRow>
                    </Table>
                  </Paper>
                </Fade>
              )}
            </Popper>
            <IconButton color="inherit">
              <ImageIcon
                onClick={() =>
                  openImageIdeaFileModal(
                    cellValues?.row?.id,
                    cellValues?.row?.image_url
                  )
                }
              />
            </IconButton>
            <IconButton
              onClick={(e: any) =>
                handleClick(e, "left", [cellValues?.row?.parameter, cellValues?.row?.origin, cellValues?.row?.owner_name, cellValues?.row?.ease, cellValues?.row?.duration])
              }
              style={{ marginLeft: "1rem" }}
              color="inherit"
            >
              <InfoIcon />
            </IconButton>
          </>
        );
      },
    },
    { field: "stage", headerName: "Status", minWidth: width > 1400 ? 120 : 90, flex: 1 },
  ];

  // In rows which is a array, we are pushing data which is structured in frontEnd so, that we can pass it to dataGrid

  let rows: any = [];

  let dataGridDataGenerator: any =
    ideaData &&
    ideaData?.map((ideaItem: any, index: any) => {
      let objShared: any = {
        id: ideaItem?.id,
        color: ideaItem?.color,
        cost: ideaItem?.cost,
        weight: (ideaItem?.weight * 1000).toFixed(2),
        parameter: ideaItem?.parameter_unit,
        created_at: ideaItem?.created_at,
        due_date: ideaItem?.due_date
          ? moment(ideaItem?.due_date).format("DD/MM/YYYY")
          : null,
        duration: ideaItem?.duration,
        ease: ideaItem?.ease,
        idea_id: ideaItem?.idea_id,
        image_url: ideaItem?.image_url,
        origin:
          ideaItem?.origin == 1
            ? "BOM"
            : ideaItem?.origin == 2
              ? "Architecture"
              : ideaItem?.origin == 3
                ? "Feature"
                : ideaItem?.origin == 4
                  ? "Costing"
                  : "Media",
        owner_name: ideaItem?.owner_name,
        stage:
          ideaItem?.stage == 0 && ideaItem?.rejected == false
            ? "Initiated"
            : ideaItem?.stage == 1 && ideaItem?.rejected == false
              ? "In Review"
              : ideaItem?.stage == 2 && ideaItem?.rejected == false
                ? "Submitted"
                : ideaItem?.stage == 3 && ideaItem?.rejected == false
                  ? "Accepted"
                  : "Rejected",
        status_update: moment(ideaItem?.status_update).format("DD/MM/YYYY"),
        text_data: ideaItem?.text_data[0]?.replace('\n', ' '),
      };
      rows.push(objShared);
    });

  // dataStructureDataGrid is the total data format which is ready to pass to the dataGrid Props

  let dataStructureDataGrid: any = {
    columns: columns,
    rows: rows,
  };

  const getselectedAbbrBackgroundColor = (abbr: any) => {
    if (selectedAbbr?.includes(abbr)) {
      return "primary.main";
    } else {
      return "primary.light";
    }
  };
  const getSelectedAbbrTextColor = (abbr: any) => {
    if (selectedAbbr?.includes(abbr)) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ minWidth: 300, width: anchor === "top" || anchor === "bottom" ? "auto" : "auto" }}
      role="presentation"
    >
      <List>
        <ListItem>
          <Box sx={{ width: "100%" }}>
            <div>
              <Typography
                style={{
                  width: "100%",
                  fontSize: "1rem",
                }}
              >
                Product Name
              </Typography>
              {projectAvailable &&
                projectAvailable
                  ?.filter((item: any) => {
                    return item?.idea_base == true && item?.id == topVaultId;
                  })
                  ?.map((item: any, index: any) => {
                    return (
                      <>
                        <Box sx={{ alignItems: "center", display: "flex" }}>
                          <Box
                            key={index}
                            className={styles.selectVehBtn}
                            sx={{
                              padding: "0.2rem 0.5rem",
                              backgroundColor: getselectedBackgroundColor(
                                item.id
                              ),
                              color: getSelectedTextColor(item.id),
                              textAlign: "center",
                              cursor: "pointer",
                              display: "inline-block",
                              fontSize: '1rem',
                              width: "100%",
                              marginBottom: "1rem",
                            }}
                          >
                            <Typography style={{ fontSize: "1rem" }}>{item.name}</Typography>
                          </Box>
                        </Box>
                      </>
                    );
                  })}
            </div>
          </Box>
        </ListItem>
      </List>
      <List>
        <ListItem style={{ padding: '0rem' }}>
          <Box sx={{ width: "100%" }}>
            <Typography
              style={{
                width: "100%",
                fontSize: "1rem",
                marginLeft: '1rem'
              }}
            >
              Subsystem Name*
            </Typography>
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
              {subsystemListData &&
                subsystemListData?.map((item: any, index: any) => {
                  return (
                    <>
                      <Box sx={{ alignItems: "center", display: "flex" }}>
                        <Checkbox
                          checked={
                            selectedAbbr?.includes(item?.value)
                              ? true
                              : false
                          }
                          onClick={() => selectAllAbbr(item?.value)}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                        />
                        <Box
                          key={index}
                          onClick={() => selectAllAbbr(item?.value)}
                          className={styles.selectVehBtn}
                          sx={{
                            padding: "0.2rem",
                            margin: '0.5rem,0rem',
                            backgroundColor: getselectedAbbrBackgroundColor(
                              item.value
                            ),
                            color: getSelectedAbbrTextColor(item.value),
                            textAlign: "left",
                            cursor: "pointer",
                            display: "inline-block",
                            width: "100%"
                            // marginBottom: '1rem'
                          }}
                        >
                          <Typography style={{ fontSize: "0.8rem" }}>{item?.title}</Typography>
                        </Box>
                      </Box>
                    </>
                  );
                })}
            </Box>
          </Box>
        </ListItem>
      </List>
      <div style={{ textAlign: "right", marginTop: "1rem" }}>
        <Button
          className={styles.drawBtn}
          sx={{ marginRight: "1rem" }}
          variant="contained"
          onClick={toggleDrawer(anchor, false)}
          size="small"
        >
          Close
        </Button>
        <LoadingButton
          className={styles.drawBtn}
          loading={okButtonLoader}
          onClick={handleChangeIdea}
          size="small"
          disabled={
            selectedProducts?.length < 1 || selectedProducts?.length > 1
          }
        >
          <Button
            className={styles.drawBtn}
            variant="contained"
            onClick={toggleDrawer(anchor, false)}
            size="small"
          >
            Ok
          </Button>
        </LoadingButton>
      </div>
    </Box>
  );

  const handleRedirectUrl: any = (type: any) => {
    if (type === 'ViewData') {
      let a: any = sessionStorage.getItem("idea");
      // console.log(a, "a");
      if (a == null) {
        history.push(`/view/${projectId}/${topVaultId}/${vaultId}/${abbreviation}/false`);
      }
      else {
        window.location.href = a;
      }
      handleClose();
    }
    else {
      history.push(`/idea_dashboard/${projectId}/${topVaultId}`);
      handleClose();
    }
  }
  const handleClose = () => {
    setAnchorElMenu(null);
  };

  return (
    <>
      <IdeaImageViewModal
        isOpen={imageIdeaFile.isOpen}
        onClose={imageIdeaFile.close}
        id={imageIdeaFile.id}
        ideaImage={imageIdeaFile.imageData}
      />
      <DialogComponent isOpen={openDialogModal.isOpen} onClose={openDialogModal.close} content={contentText} type={iconText} />
      <Box sx={{ width: '3rem' }}>
        <IconButton sx={{ cursor: 'pointer' }} onClick={backButtonHandler}>
          <ArrowBackIcon color="primary" titleAccess="Go Back" />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorElMenu}
          open={openMenu}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          sx={{
            ".MuiList-root.MuiMenu-list": {
              padding: 0,
            }
          }}
        >
          <MenuItem sx={{ fontSize: '1rem' }} onClick={() => handleRedirectUrl('ViewData')}>View Data</MenuItem>
          <MenuItem sx={{ fontSize: '1rem' }} onClick={() => handleRedirectUrl('IdeaDashboard')}>Idea Dashboard</MenuItem>
        </Menu>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}
                >
                  <Box
                    sx={{ padding: "1rem" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                  >
                    <Typography style={{ width: "100%", textAlign: "center" }}>
                      Select Idea
                    </Typography>
                    <Divider sx={{ margin: "1rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  style={{
                    transform: "rotate(270deg)",
                    whiteSpace: "nowrap",
                    marginTop: "40vh",
                    cursor: "pointer"
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  Select Subsystem
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        <Box
          sx={{
            height: '95vh',
            width: "97%",
            marginTop: '-3.5rem',
            "& .cold": {
              cursor: "pointer",
              textDecoration: "underline",
            },
            "& .super-app-theme--Initiated": {
              bgcolor: "#bdbdbd20",
              color: "#4646d3",
            },
            "& .super-app-theme--In-Review": {
              bgcolor: "#ffff00",
              color: "#000",
              "&:hover": {
                bgcolor: "#DBDBFF !important",
              },
            },
            "& .super-app-theme--Submitted": {
              bgcolor: "#bed7ed",
              color: "#4646d3",
            },
            "& .super-app-theme--Accepted": {
              bgcolor: "#a3c182",
              color: "#4646d3",
              "&:hover": {
                bgcolor: "#BEF18E !important",
              },
            },
            "& .super-app-theme--Rejected": {
              bgcolor: "#ee6a59",
              color: "white",
              "&:hover": {
                color: "white",
                bgcolor: "#EC5F2B !important",
              },
            },
          }}
        >
          {!loadAllLogs ?
            (
              <><Box sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
                <Button
                  className="btnlogin"
                  id="basic-button"
                  sx={{ position: 'absolute', right: '14rem', top: { lg: '3.85rem', xl: '4.1rem' }, zIndex: '10' }}
                  aria-controls={openConsolidated ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openConsolidated ? "true" : undefined}
                  onClick={handleClickDownloadConsolidated}
                  size="small">
                  Download <DownloadIcon sx={{ fontSize: '1.5rem' }} />
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorElDownloadConsolidated}
                  open={openConsolidated}
                  onClose={handleCloseDownloadConsolidated}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  sx={{
                    ".MuiList-root.MuiMenu-list": {
                      padding: 0,
                    }
                  }}
                >
                  <MenuItem sx={{ fontSize: '1rem' }} onClick={handleWithImageConsolidated}>With Image Report</MenuItem>
                  <MenuItem sx={{ fontSize: '1rem' }} onClick={handleWithoutImageConsolidated}> Without Image Report </MenuItem>
                </Menu>
              </Box>
                <DataGrid
                  // headerHeight={48}
                  // rowHeight={36}
                  {...dataStructureDataGrid}
                  loading={apiIdeaDataFetch}
                  disableSelectionOnClick={true}
                  components={{
                    Toolbar: () => {
                      return CustomToolbar();
                    },
                  }}
                  getRowClassName={(params) => `super-app-theme--${params.row.stage}`}
                  sx={{
                    fontSize: "1rem",
                    "&.MuiDataGrid-root.MuiDataGrid-autoHeight .MuiDataGrid-row--lastVisible .MuiDataGrid-cell":
                    {
                      borderBottomColor: "primary.light",
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-cell": {
                      borderBottomColor: "primary.light",
                    },
                    "& ::-webkit-scrollbar": {
                      width: "0.3rem",
                      height: "0.3rem",
                    },
                    "& ::-webkit-scrollbar-thumb": {
                      // background: $thumb-color;
                      backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                      borderRadius: "10px",
                    },
                    "& ::- webkit-scrollbar-thumb: hover ": {
                      // width: "4px",
                      backgroundColor: "#045DE9 !important",
                    },
                    "::-webkit-scrollbar-track": {
                      backgroundColor: "#e1e1f3 !important",
                    },
                  }}
                  density={"compact"}
                  pageSize={21}
                  rowsPerPageOptions={[21]}
                  getCellClassName={(params: any) => {
                    return params?.field == "idea_id" && "cold";
                  }}
                />

              </>)
            : <div>
              <Box>
                <Box sx={{ height: '100%', marginTop: '1.5rem', padding: "0 1rem" }}>
                  {/* sx={{ display: "flex", rowGap: "4rem",flexDirection:"row",height:'3rem' }}> */}
                  <Skeleton sx={{ height: "3rem", width: "100%" }} />
                  <Skeleton sx={{ height: "3rem", width: "100%", marginTop: '0.1rem' }} />
                  <Skeleton sx={{ height: "3rem", width: "100%", marginTop: '0.1rem' }} />
                  <Skeleton sx={{ height: "3rem", width: "100%", marginTop: '0.1rem' }} />
                  <Skeleton sx={{ height: "3rem", width: "100%", marginTop: '0.1rem' }} />
                  <Skeleton sx={{ height: "3rem", width: "100%", marginTop: '0.1rem' }} />
                </Box>
              </Box>
            </div>
          }
        </Box>
      </Box>
    </>
  );
};
export default IdeaLandingPage;
