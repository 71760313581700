import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
	AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { API } from '../../api-services';
import {
	Autocomplete,
	Box,
	Button,
	MenuItem,
	Select,
	Table,
	TableCell,
	TableHead,
	TableRow,
	TextField,
} from '@mui/material';
import ProjectDirectoryRows from './ProjectDirectoryRows';
import { useBaseModal } from '../SearchPage/useBaseModal';
import ProjectDirectoryModal from './ProjectDirectoryModal';
import AddIcon from '@mui/icons-material/Add';
import ProjectDirectoryProductModal from './ProjectDirectoryProductModal';
import EditIcon from '@mui/icons-material/Edit';
import Skeleton from '@mui/material/Skeleton';
import addIcon from '../../Assets/images/createFolderDrive.png';
import SkeletonLoader from '../SkeletonLoader/SkeletonLoader';
import { isUndefined, set } from 'lodash';

import ProductCategory from './ProductCategory';

type ProjectDirectoryVehiclePageProps = {};

const Accordion = styled((props: AccordionProps) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {
		borderBottom: 0,
	},
	'&:before': {
		display: 'none',
	},
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor:
		theme.palette.mode === 'dark'
			? 'rgba(255, 255, 255, .05)'
			: 'rgba(0, 0, 0, .03)',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1),
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const ProjectDirectoryVehiclePage = (
	props: ProjectDirectoryVehiclePageProps
) => {
	const [expanded, setExpanded] = React.useState<string | false>();
	const [VehiclaList, setVehiclaList] = useState<any>();
	const [vehicleCategory, setVehicleCategory] = useState<any>();
	const [accLoader, setAccLoader] = useState<any>(false);
	const [projectexpanded, setProjectexpanded] = React.useState<
		string | false
	>();

	const [ProductListData, setProductListData] = useState<any>([]);
	const [productCounter, setproductCounter] = React.useState<any>(0);
	const [projectIds, setprojectIds] = React.useState(null);
	const [Counter, setCounter] = useState<any>(0);

	const openDialogModal = useBaseModal();
	const openDialogModal2 = useBaseModal();
	const [ProductName, setProductName] = useState<any>(null);
	const [FormType, setFormType] = useState<any>();
	const [ProjectData, setProjectData] = useState<any>(null);
	const [ProductDescription, setProductDescription] = useState<any>(null);

	const [vehicletype, setvehicletype] = useState<any>(null);

	const [loader, setLoader] = useState<any>(false);
	const handleChange =
		(panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
			setExpanded(newExpanded ? panel : false);
		};

	const ProjecthandleChange =
		(panel: string, projectIds: any) =>
		(event: React.SyntheticEvent, newExpanded: boolean) => {
			setProjectexpanded(newExpanded ? projectIds : false);

			if (newExpanded !== false) {
				setLoader(true);
				setProductListData([]);

				API.get('xcpep/product_list/', { project: projectIds }).then(
					(res: any) => {
						setLoader(false);
						setProductListData(res?.data);
					}
				);
			}
		};

	const HandleOpenModal = (Ids: any) => {
		openDialogModal.open();
		setFormType('Add');
		setprojectIds(Ids);
		// setExpanded(false);
	};

	const HandleOpenModalProduct = (
		Id: any,
		type: any,
		item: any,
		VehicleIds: any
	) => {
		if (type == 'Edit') {
			openDialogModal.open();
			setFormType(type);
			setProjectData(item);

			setprojectIds(Id);
			// setProjectexpanded(false);
		}

		if (type === 'Variant') {
			openDialogModal2.open();
			setProductName(null);
			setvehicletype(VehicleIds);
			setProductDescription(null);
			setprojectIds(Id);
			// setProjectexpanded(false);
		}
	};

	useEffect(() => {
		setAccLoader(true);
		API.get('/db/vehicle_type/', { module: 40 })
			.then((res: any) => {
				setVehiclaList(res?.data);
				setAccLoader(false);
			})
			.catch((err: any) => {
				console.log('server error');
				setAccLoader(false);
			});
	}, [Counter]);

	// useEffect(() => {
	// 	setAccLoader(true);
	// 	API.get('/supplier/vehicle_category/')
	// 		.then((res: any) => {
	// 			setVehicleCategory(res?.data);
	// 			setAccLoader(false);
	// 		})
	// 		.catch((err: any) => {
	// 			console.log('server error');
	// 			setAccLoader(false);
	// 		});
	// }, [Counter]);

	// console.log(vehicleCategory,"vehicleCategory");

	return (
		<div>
			{openDialogModal.isOpen && (
				<ProjectDirectoryModal
					isOpen={openDialogModal.isOpen}
					onCloseModal={openDialogModal.close}
					type={'projectType'}
					FormType={FormType}
					setCounter={setCounter}
					ProjectData={ProjectData && ProjectData}
					projectIds={projectIds && projectIds}
				/>
			)}
			{openDialogModal2.isOpen && (
				<ProjectDirectoryProductModal
					isOpen={openDialogModal2.isOpen}
					onCloseModal={openDialogModal2.close}
					setproductCounter={setCounter}
					projectIds={projectIds && projectIds}
					setProductListData={setProductListData}
					VehicleIdstype={vehicletype}
					setProjectexpanded={setProjectexpanded}
				/>
			)}

			<Box
				sx={{ height: {lg:'85vh',xl:'88vh'} }}
				adl-scrollbar='true'
				adl-scrollbar-width='0.3'>
				<ProductCategory />
			</Box>
		</div>
	);
};

export default ProjectDirectoryVehiclePage;
