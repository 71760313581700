import React, { useState, useEffect } from "react";
import { debounce } from "lodash";
import { FieldArray, useFormik } from "formik";
import * as yup from "yup";
import Popover from "@mui/material/Popover";
import {
  Autocomplete,
  Button,
  IconButton,
  TextField,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Modal,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { API } from "../../api-services";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import { ADMIN } from "../../Redux/Services/admin.service";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "80vw",
  maxWidth: "80vw",
  // height: '80vh',
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

const rowSchema = yup.object({
  volume: yup
    .number()
    .required("Volume is required")
    .min(0.1, "Volume must be greater than zero")
    .test("is-valid-volume", "Volume must be a valid number", (value: any) => {
      return !isNaN(value) && value !== "";
    }),
  price: yup
    .number()
    .required("Price is required")
    .min(0.1, "Price must be greater than zero")
    .test("is-valid-price", "Price must be a valid number", (value: any) => {
      return !isNaN(value) && value !== "";
    }),
});

const validationSchema = yup.object({
  quantity_pricing: yup
    .array()
    .of(rowSchema)
    .min(1, "At least one row is required"),
  currency: yup.mixed().test("currency", "Currency is required", (value) => {
    return (
      typeof value === "string" ||
      typeof value === "number" ||
      typeof value === "object"
    );
  }),
});

interface InfoModalProps {
  onCloseModal: any;
  isOpen: any;
  partdetails: any;
  fetchPartsData:any
}

function CreatePriceMapTable(props: InfoModalProps) {
  const { onCloseModal, isOpen, partdetails, fetchPartsData } = props;
  const { supplier } = useRouteParams<any>();
  const [anchorEl, setAnchorEl] = useState(null);
  const [countryList, setCountryList] = useState<any>([
    {
      id: 158,
      currency_id: 234,
      currency_name: "Vietnam",
      currency_code: "VND",
      symbol: "₫",
    },
  ]);
  const [countryLoading, setCountryLoading] = useState<boolean>(false);
  const [pricemaprows, setPricemaprows] = React.useState<any>([
    {
      volume: 1,
      price: 1,
    },
  ]);
  const [errors, setErrors] = useState<any>({});

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  React.useEffect(() => {
    if (
      partdetails &&
      partdetails?.quantity_pricing &&
      partdetails?.quantity_pricing.length > 0
    ) {
      setPricemaprows(partdetails?.quantity_pricing);
      let val =
        countryList?.length > 0 &&
        countryList.filter(
          (item: any) =>
            item.currency_code ==
            partdetails?.quantity_pricing[0]?.currency_code
        );
      formik.setFieldValue("currency", val[0]);
    }
  }, [partdetails]);

  const open = Boolean(anchorEl);
  const id = open ? "info-popover" : undefined;

  const handleInputChange = (index: any, field: any, value: any) => {
    const newRows: any = [...pricemaprows];
    newRows[index][field] = value;
    setPricemaprows(newRows);
    formik.setFieldValue("quantity_pricing", newRows);

    validateRow(index, newRows[index]);
  };

  const validateRow = (index: any, row: any) => {
    let rowErrors: any = {};

    if (row.volume <= 0 || row.volume === "" || isNaN(row?.volume)) {
      rowErrors.volume = "Quantity must be greater than zero.";
    } else if (!isVolumeUnique(pricemaprows, row.volume, index)) {
      rowErrors.volume = "Quantity must be unique.";
    }

    if (row.price <= 0 || row.price === "" || isNaN(row?.price)) {
      rowErrors.price = "Price must be greater than zero.";
    }

    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [index]: rowErrors,
    }));
  };
  const formik = useFormik({
    initialValues: {
      quantity_pricing: [{ volume: "1", price: "1" }],
      currency: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      values["quantity_pricing"] = pricemaprows;
      API.put(
        "db/get_supplier_db/",
        {
          currency: values.currency?.id,
          price_table: values.quantity_pricing,
          vault: partdetails?.id,
          supplier: supplier,
        },
        {},
        0
      ).then((res: any) => {
        ADMIN.toast.info("Price Map Updated Successfully");
        fetchPartsData();
        onCloseModal();
      });
    },
  });

  const getCountryData = () => {
    setCountryLoading(true);
    API.get("/db/currency_for_parts/", { all_currencies: true }, 0)
      .then((res: any) => {
        setCountryList(res.data);
        setCountryLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setCountryLoading(false);
      });
  };

  const isVolumeUnique = (
    list: any,
    volume: any,
    currentIndex: number
  ): boolean => {
    return !list.some(
      (item: any, index: number) =>
        item.volume == volume && index !== currentIndex
    );
  };

  const ExactDebounce = React.useRef(
    debounce(async (criteria: any) => {
      setCountryLoading(true);
      setCountryList([]);
      API.get("customer/currency_search/", { search_key: criteria }, 0)
        .then((res: any) => {
          setCountryList(
            res.data?.filter(
              (item: any) =>
                item?.currency_code !== sessionStorage.getItem("currency")
            )
          );

          setCountryLoading(false);
        })
        .catch(() => {
          setCountryLoading(false);
        });
    }, 500)
  ).current;

  useEffect(() => {
    getCountryData();
  }, []);
  React.useEffect(() => {
    pricemaprows?.map((itm: any, ind: any) => {
      validateRow(ind, itm);
    });
  }, []);

  const addRow = () => {
    //@ts-ignore
    setPricemaprows([...pricemaprows, { volume: "", price: "" }]);
    validateRow(pricemaprows?.length, [
      ...pricemaprows,
      { volume: "", price: "" },
    ]);
    formik.setFieldValue("quantity_pricing", [
      ...pricemaprows,
      { volume: "", price: "" },
    ]);
  };

  const deleteRow = (index: any) => {
    if (pricemaprows.length === 1) {
      return;
    }
    const newRows = [...pricemaprows];
    newRows.splice(index, 1);
    setPricemaprows(newRows);
    formik.setFieldValue("quantity_pricing", newRows);
    const newErrors = { ...errors };
    delete newErrors[index];
    setErrors(newErrors);
  };

  const handleCurrencyChange = (val: any) => {
    formik.setFieldValue("currency", val);
  };

  const getDisablestatus = () => {
    let status = false;
    Object.keys(errors).forEach((item) => {
      if (Object.keys(errors[item]).length > 0) {
        status = true;
      } else {
        status = false;
      }
    });
    return status;
  };

  console.log(partdetails);

  return (
    <Modal open={isOpen} onClose={()=> {
      fetchPartsData();
      onCloseModal();
    }}>
      <Box sx={style}>
        <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              // marginRight: '-2rem',
            }}
          >
            Add Price as per Quantity for {partdetails?.name} (
            {partdetails?.vehicle_category?.name} ,{" "}
            {partdetails?.category?.name})
          </Typography>
          <CancelIcon
            titleAccess="Close"
            sx={{ cursor: "pointer", margin: "0.5rem" }}
            onClick={()=> {
              fetchPartsData();
              onCloseModal();
            }}
          />
        </Box>
        <Box sx={{ margin: "0 1rem", marginBottom: "2rem" }}>
          <Box
            sx={{
              margin: "1rem 0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ marginTop: "1rem" }}
              >
                Price Table
              </Typography>
              <Button
                variant="contained"
                sx={{ marginTop: "1rem" }}
                onClick={addRow}
                disabled={getDisablestatus()}
              >
                Add Row
              </Button>
            </Box>

            <Autocomplete
              sx={{ width: "50%" }}
              filterOptions={(options, state) => options}
              value={formik.values.currency}
              onChange={(event: any, newValue: string | null) => {
                handleCurrencyChange(newValue);
              }}
              options={countryList}
              getOptionLabel={(option: any) =>
                option.currency_code == undefined ? "" : option.currency_code
              }
              renderOption={(props, option) => (
                <Box
                  component="li"
                  style={{
                    display: "flex",
                    fontSize: "1rem",
                  }}
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    //@ts-ignore
                    src={`data:image/png;base64, ${option.flag}`}
                    style={{
                      width: "1.5rem",
                      height: "1.5rem",
                      marginLeft: "1rem",
                      marginRight: "1rem",
                    }}
                  />
                  <div
                    style={{
                      marginLeft: "0.5rem",
                      fontSize: "1rem",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                    //@ts-ignore
                  >{`${option.currency_name}  ${
                    //@ts-ignore
                    option.currency_code
                  } ${
                    //@ts-ignore
                    option.symbol
                      ? //@ts-ignore
                        "(" + option.symbol + ")"
                      : " "
                  }`}</div>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="currency"
                  id="currency"
                  inputProps={{
                    ...params.inputProps,
                  }}
                  variant="standard"
                  label={
                    <span>
                      Select Currency <sup style={{ color: "red" }}>*</sup>
                    </span>
                  }
                  sx={{
                    fontSize: "1rem",
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.light",
                    },
                  }}
                  error={
                    formik.touched.currency && Boolean(formik.errors.currency)
                  }
                  //@ts-ignore
                  helperText={formik.touched.currency && formik.errors.currency}
                />
              )}
            />
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Table>
              <TableHead sx={{ backgroundColor: "primary.main" }}>
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: "12px",
                      padding: "0.1rem 0.5rem",
                      borderBottomColor: "primary.light",
                      backgroundColor: "primary.main",
                      color: "white",
                      width: {
                        xs: "12rem",
                        sm: "12vw",
                        md: "12vw",
                        lg: "12vw",
                        xl: "17vw",
                      },
                    }}
                  >
                    Quantity
                  </TableCell>

                  <TableCell
                    sx={{
                      fontSize: "12px",
                      padding: "0.1rem 0.5rem",
                      borderBottomColor: "primary.light",
                      backgroundColor: "primary.main",
                      color: "white",
                      width: {
                        xs: "50%",
                        sm: "50%",
                        md: "50%",
                        lg: "50%",
                        xl: "50%",
                      },
                    }}
                  >
                    Selling Price (Excluding all taxes)
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "12px",
                      padding: "0.1rem 0.5rem",
                      borderBottomColor: "primary.light",
                      color: "primary.main",
                    }}
                  >
                    <Popover
                      id="info"
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Typography sx={{ p: 2 }}>Excluding Taxes</Typography>
                    </Popover>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pricemaprows?.map((item: any, index: any) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{
                        fontSize: "12px",
                        padding: "0.1rem 0.5rem",
                        borderBottomColor: "primary.light",
                        color: "primary.main",
                        width: {
                          xs: "50%",
                          sm: "50%",
                          md: "50%",
                          lg: "50%",
                          xl: "50%",
                        },
                      }}
                    >
                      <TextField
                        fullWidth
                        InputProps={{
                          style: {
                            color: "#007fff",
                            fontSize: 16,
                          },
                          inputProps: { min: 1 },
                        }}
                        type="number"
                        value={item.volume}
                        disabled={index === 0}
                        sx={{
                          "& input[type=number]": {
                            "-moz-appearance": "textfield",
                          },
                          "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                            {
                              "-webkit-appearance": "none",
                              margin: 0,
                            },
                        }}
                        onChange={(e) => {
                          const value = parseInt(e.target.value);
                          value > 0 &&
                            handleInputChange(index, "volume", value);
                        }}
                        error={Boolean(errors[index]?.volume)}
                        helperText={errors[index]?.volume}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px",
                        padding: "0.1rem 0.5rem",
                        borderBottomColor: "primary.light",
                        color: "primary.main",
                      }}
                    >
                      <TextField
                        fullWidth
                        InputProps={{
                          style: { color: "#007fff", fontSize: 16 },
                          inputProps: { min: 1, step: "0.01" },
                        }}
                        type="number"
                        sx={{
                          "& input[type=number]": {
                            "-moz-appearance": "textfield",
                          },
                          "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                            {
                              "-webkit-appearance": "none",
                              margin: 0,
                            },
                        }}
                        value={item.price}
                        onChange={(e) => {
                          const value = parseFloat(e.target.value);
                          value > 0 && handleInputChange(index, "price", value);
                        }}
                        error={Boolean(errors[index]?.price)}
                        helperText={errors[index]?.price}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "12px",
                        padding: "0.1rem 0.5rem",
                        borderBottomColor: "primary.light",
                        color: "primary.main",
                      }}
                    >
                      <IconButton
                        size="small"
                        onClick={() => deleteRow(index)}
                        sx={{
                          "&:hover": {
                            backgroundColor: "transparent",
                            color: "primary.main",
                          },
                          "&:focus": {
                            outline: "none",
                          },
                          color: "primary.main",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={false}
              sx={{ marginTop: "1rem", float: "right" }}
            >
              Save
            </LoadingButton>
          </form>
        </Box>
      </Box>
    </Modal>
  );
}

export default CreatePriceMapTable;
