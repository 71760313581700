// lazy

import {
    debounce,
    invert,
    isNull,
    isNumber,
    isUndefined,
    mapValues,
    omit,
    pickBy,
    uniqBy,
  } from "lodash";
  import { ID, ObjectType, fetched } from "../../utlis/commonInterfaces";
  import {
    CALCULATOR_INPUT_CONTENT_TYPE,
    CALCULATOR_INPUT_TYPE,
    CALCULATOR_TYPE,
    InputContentType,
    calculatorCategoryOptions,
    calculatorCostInputOptions,
    calculatorDatabaseColumnsOptions,
    calculatorDatabaseOptions,
    calculatorInputBackgroundColorMap,
    calculatorInputOptions,
  } from "./calculator.constant";
  import { CalculatorInput } from "../../Redux/Reducers/calculatorInput.reducer";
  import { ParameterConfig } from "../../Redux/Reducers/parameterConfig.reducer";
  import { numericDataTypes } from "../../utlis/parameterDataTypes.constants";
  import { pipe } from "../../utlis/other";
  import * as yup from "yup";
  import CameraAltIcon from "@mui/icons-material/CameraAlt";
  import {
    IconProps,
    Input,
    Icon,
    TableCell,
    TableFooter,
    TableHead,
    TableRow,
    TableBody,
    Box,
    Table,
    FormControl,
    Select,
    MenuItem,
    TextField,
    Skeleton,
    Typography,
    Checkbox,
  } from "@mui/material";
  import { Button } from "devextreme-react";
  import { useState, useRef, useMemo, useEffect } from "react";
  import { RouteComponentProps, useParams } from "react-router-dom";
  import RViewerJS from "viewerjs-react";
  import useFetch from "../../Core/CustomHooks/useFetch";
  import { ICalculator } from "../../Redux/Reducers/calculator.reducer";
  import { ADMIN } from "../../Redux/Services/admin.service";
  import calculatorService from "../../Redux/Services/calculator.service";
  import calculatorInputService from "../../Redux/Services/calculatorInput.service";
  import processConfigService from "../../Redux/Services/processConfig.service";
  import { API } from "../../api-services";
  import BouncingLoader from "../../ui-reusable-component/BouncingLoader";
  import { transformConfigurationsFromApi } from "../../utlis/configuration.constants";
  import { useCollection } from "../../CustomHook/useCollection";
  import bomconfigService from "../../Redux/Services/bomconfig.service";
  import SyncIcon from "@mui/icons-material/Sync";
  import AddIcon from "@mui/icons-material/Add";
  import styles from "./createCostCalculator.module.scss";
  import SaveIcon from "@mui/icons-material/Save";
  import yupValidate from "../../utlis/yupValidate";
  import { deleteSwal } from "../../utlis/deleteSwal";
  import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
  import { LoadingButton } from "@mui/lab";
  import DeleteIcon from "@mui/icons-material/Delete";
  import UploadIcon from "@mui/icons-material/Upload";
  import EditIcon from "@mui/icons-material/Edit";
  import VerifiedIcon from "@mui/icons-material/Verified";
import React from "react";
  
  // ----------------------------------------------------------------------------------
  // params
  
  type Params = {
    processId: string;
    commodityMappingId: string;
    pId: string;
    type: string;
    calculatorCategory: string;
    calculatorId: string;
  };
  
  // ----------------------------------------------------------------------------------
  // constants
  
  const inputKeysToOmit: Record<number, string[]> = {
    [CALCULATOR_INPUT_TYPE.BOM_PARAMETER]: [
      "database",
      "formula",
      "constant",
      "volume",
      "attribute",
      "user_input",
    ],
    [CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER]: [
      "database",
      "formula",
      "attribute",
      "constant",
      "user_input",
    ],
    [CALCULATOR_INPUT_TYPE.CONSTANT]: [
      "database",
      "formula",
      "parameter",
      "user_input",
      "volume",
      "table_element",
    ],
    [CALCULATOR_INPUT_TYPE.VOLUME]: [
      "database",
      "formula",
      "parameter",
      "user_input",
      "table_element",
    ],
    [CALCULATOR_INPUT_TYPE.DATABASE]: [
      "formula",
      "constant",
      "volume",
      "parameter",
      "user_input",
      "table_element",
    ],
    [CALCULATOR_INPUT_TYPE.FORMULA]: [
      "database",
      "constant",
      "volume",
      "parameter",
      "attribute",
      "table_element",
    ],
    [CALCULATOR_INPUT_TYPE.USER_INPUT]: [
      "database",
      "constant",
      "volume",
      "parameter",
      "attribute",
      "formula",
      "table_element",
    ],
  };
  interface CalculatorInputFromApi {
    id: ID;
    content_type: InputContentType;
    name: string;
    /** string used to refere the input in the formula */
    alias: string;
  
    parameter?: ID;
    table_element?: ID;
  
    attribute?: string;
  
    formula?: string;
    constant?: number;
    user_input?: string;
    calculator_category?: number;
    volume?: number;
    calculator: ID;
    image_url?: string;
    test_input?: number;
    result?: number;
  }
  
  const inputContentTypeToDatabaseMap: ObjectType<number> = {
    Material: 1,
    Machine: 2,
    Labour: 3,
    ToolDatabase: 4,
    FastenerDatabase: 5,
    PurchasingItem: 6,
    StrokeRateDatabase: 7,
  };
  
  const databaseMapToConntentType = invert(inputContentTypeToDatabaseMap);
  
  export const transformCalculatorInputFromApi = (
    input: CalculatorInputFromApi
  ): CalculatorInput => {
    const { content_type, ...rest } = input;
    const obj: any = {};
    if (content_type) {
      if (rest.parameter) {
        if (content_type === CALCULATOR_INPUT_CONTENT_TYPE.BOM_PARAMETER)
          obj.type = CALCULATOR_INPUT_TYPE.BOM_PARAMETER;
        if (content_type === CALCULATOR_INPUT_CONTENT_TYPE.COMMODITY_PARAMETER)
          obj.type = CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER;
      } else if (rest.attribute) {
        if (content_type === CALCULATOR_INPUT_CONTENT_TYPE.COST_INPUT) {
          obj.type = CALCULATOR_INPUT_TYPE.COST_INPUT;
        } else {
          obj.type = CALCULATOR_INPUT_TYPE.DATABASE;
          obj.database = inputContentTypeToDatabaseMap[content_type];
        }
      } else if (isNumber(rest.calculator_category)) {
        obj.type = CALCULATOR_INPUT_TYPE.CALCULATOR_RESULT;
      }
    }
    if (rest.formula) obj.type = CALCULATOR_INPUT_TYPE.FORMULA;
    else if (!isNull(rest.user_input))
      obj.type = CALCULATOR_INPUT_TYPE.USER_INPUT;
    if (rest.constant) obj.type = CALCULATOR_INPUT_TYPE.CONSTANT;
    if (rest.volume) obj.type = CALCULATOR_INPUT_TYPE.VOLUME;
    //changed {} to {content_type: content_type}
    return Object.assign({ content_type: content_type }, rest, obj);
  };
  
  const toApi = async (
    input: CalculatorInput
  ): Promise<CalculatorInputFromApi> => {
    const transformedInput = transformInput(input) as Required<CalculatorInput>;
    try {
      const validatedInput = (await yupValidate(
        schema,
        transformedInput
      )) as CalculatorInput;
  
      const { type, database, ...rest } = validatedInput;
      const obj: any = {};
  
      if (database) {
        obj.content_type = databaseMapToConntentType[database];
      }
      if (type === CALCULATOR_INPUT_TYPE.BOM_PARAMETER) {
        obj.content_type = CALCULATOR_INPUT_CONTENT_TYPE.BOM_PARAMETER;
      }
  
      if (type === CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER) {
        obj.content_type = CALCULATOR_INPUT_CONTENT_TYPE.COMMODITY_PARAMETER;
      }
      if (type === CALCULATOR_INPUT_TYPE.COST_INPUT) {
        obj.content_type = CALCULATOR_INPUT_CONTENT_TYPE.COST_INPUT;
      }
      if (type === CALCULATOR_INPUT_TYPE.CALCULATOR_RESULT) {
        obj.content_type = CALCULATOR_INPUT_CONTENT_TYPE.CALCULATOR_RESULT;
      }
      if (type === CALCULATOR_INPUT_TYPE.USER_INPUT) {
        obj.content_type = null;
      }
      if (type === CALCULATOR_INPUT_TYPE.VOLUME) {
        obj.content_type = null;
      }
  
      return pickBy(
        Object.assign({}, rest, obj),
        (value) => !isUndefined(value)
      ) as any;
    } catch (e: any) {
      const msg = Object.values(e).join(", ");
      throw new Error(msg);
    }
  };
  
  // ----------------------------------------------------------------------------------
  // helpers
  
  const generateConfigsOptions = (configs: ParameterConfig[]) => {
    return configs.map((config) => ({
      key: config.id,
      value: config.parameter_id,
      text: config.name,
    }));
  };
  
  // to only maintain keys that are related to the input type during saving of data
  const transformInputs = (inputs: CalculatorInput[]) => {
    return inputs.map(transformInput);
  };
  
  /** removes unwanted columns depending on type */
  const transformInput = (input: CalculatorInput) => {
    return omit(input, inputKeysToOmit[input.type] || []) as CalculatorInput;
  };
  
  const numericOrTableDataTypes = numericDataTypes.concat("table");
  const filterNumericOrTableConfigs = (configs: ParameterConfig[]) => {
    return configs.filter((config) =>
      numericOrTableDataTypes.includes(config.data_type)
    );
  };
  
  const filterNonTableColumnConfigs = (configs: ParameterConfig[]) => {
    return configs.filter((config) => !config.parameter_table_id);
  };
  
  const filterTableColumnConfigs = (
    configs: ParameterConfig[],
    parameterId: ID
  ) => {
    return configs.filter((config) => config.parameter_table_id === parameterId);
  };
  
  /** configs that need to be shown for BOM/Commodity parameters dropdown */
  const getConfigsOptions = pipe(
    filterNumericOrTableConfigs,
    filterNonTableColumnConfigs,
    generateConfigsOptions
  );
  
  /** configs that need to shown for columns of selectedParameter */
  const getTableColumnConfigsOptions = (
    configs: ParameterConfig[],
    parameterId: ID
  ) => {
    const tableColumnConfigs = filterTableColumnConfigs(configs, parameterId);
    return pipe(
      filterNumericOrTableConfigs,
      generateConfigsOptions
    )(tableColumnConfigs);
  };
  
  // ----------------------------------------------------------------------------------
  // schema
  
  const schema = yup.object().shape({
    type: yup.number().required('"Type" is required'),
    name: yup.string().required('"Name" is required'),
    alias: yup.string(),
  
    parameter: yup.mixed().when("type", {
      is: (type) =>
        [
          CALCULATOR_INPUT_TYPE.BOM_PARAMETER,
          CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER,
        ].includes(type),
      then: yup
        .number()
        .transform((value) => (Boolean(value) ? value : undefined))
        .required('"Parameter" is required'),
      otherwise: yup.number().transform(() => undefined),
    }),
    table_element: yup.mixed().when("type", {
      is: (type) =>
        [
          CALCULATOR_INPUT_TYPE.BOM_PARAMETER,
          CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER,
        ].includes(type),
      then: yup
        .number()
        .transform((value) => (Boolean(value) ? value : undefined))
        .notRequired(),
      otherwise: yup.number().transform(() => undefined),
    }),
    attribute: yup.string().when("type", {
      is: (type) =>
        [
          CALCULATOR_INPUT_TYPE.DATABASE,
          CALCULATOR_INPUT_TYPE.COST_INPUT,
        ].includes(type),
      then: yup
        .string()
        .transform((value) => (isNull(value) ? "" : value))
        .required('"Column/Cost Type" is required'),
      otherwise: yup.string().transform(() => undefined),
    }),
  
    database: yup.number().when("type", {
      is: CALCULATOR_INPUT_TYPE.DATABASE,
      then: yup
        .number()
        .transform((value) => (Boolean(value) ? value : undefined))
        .required('"Database" is required'),
      otherwise: yup.number().transform(() => undefined),
    }),
    formula: yup.string().when("type", {
      is: CALCULATOR_INPUT_TYPE.FORMULA,
      then: yup
        .string()
        .transform((value) => (isNull(value) ? "" : value))
        .required('"Formula" is required'),
      otherwise: yup.string().transform(() => undefined),
    }),
    constant: yup.number().when("type", {
      is: CALCULATOR_INPUT_TYPE.CONSTANT,
      then: yup
        .number()
        .transform((value) => (Boolean(value) ? value : 0))
        .notOneOf([0])
        .required('"Constant Value" is required'),
      otherwise: yup.number().transform(() => undefined),
    }),
    volume: yup.number().when("type", {
      is: CALCULATOR_INPUT_TYPE.VOLUME,
      then: yup
        .number()
        .transform((value) => (value ? value : undefined))
        .notOneOf([0])
        .required("Volume is required"),
      otherwise: yup.number().transform(() => undefined),
    }),
    user_input: yup.string().when("type", {
      is: (type) =>
        [
          CALCULATOR_INPUT_TYPE.USER_INPUT,
          CALCULATOR_INPUT_TYPE.FORMULA,
        ].includes(type),
      then: yup.string().transform((value) => (isNull(value) ? "" : value)),
      otherwise: yup.string().transform(() => undefined),
    }),
    calculator_category: yup.number().when("type", {
      is: CALCULATOR_INPUT_TYPE.CALCULATOR_RESULT,
      then: yup
        .number()
        .transform((value) => (isNumber(value) ? value : undefined))
        .required('"Calculator Category" is required'),
      otherwise: yup.number().transform(() => undefined),
    }),
  });
  
  // ----------------------------------------------------------------------------------
  
  interface InputConfigurationProps {
    selectedTreeData: any;
    setCounterApiCall?: any;
    dataType?: any;
    vault:any
  }
  
  const CostingCostCalculator: React.FC<InputConfigurationProps> = (props) => {
    const { selectedTreeData, setCounterApiCall,dataType,vault } = props;
    const { process, id } = selectedTreeData;
    const { projectId } = useRouteParams<any>();
    // ----------------------------------------------------------------------------------
    // state
  
    const [inputsFetched, setInputsFetched] = useState<fetched>("FALSE");
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [currentSelected, setCurrentSelected] = useState<any>(-1);
    const [currentImage, setCurrentImage] = useState<any>("");
    const [color, setColor] = useState<any>("blue");
    const [colorCalc, setColorCalc] = useState<any>("primary.main");
    const [calcNameLoading, setCalcnameloading] = useState<any>(false);
    const [activeId, setActiveId] = useState<any>();
    const [calcName, setCalcName] = useState<any>("");
    const [validation, setValidation] = useState<any>()
    const [saveApiCallCount, setSaveApiCallCount] = useState<any>(0);
    const inputElRef = useRef<any>();
    const imageViewerElRef = useRef<any>();
    const [useraction,setuseraction]=useState<any>(null)
    // ----------------------------------------------------------------------------------
    // collection hook to hold inputs
  
    const inputs: any = useCollection<CalculatorInput>();
  
    // ----------------------------------------------------------------------------------
    // fetch parameter mappings
  
    // fetch bom parameters
    const { 1: bomConfigs } = useFetch<ParameterConfig[]>({
      method: bomconfigService.getByProjectId,
      args: [projectId, true, true],
      dependencies: [selectedTreeData],
      condition: selectedTreeData?.type === "calculator",
      transformResData(data) {
        const configs: any = transformConfigurationsFromApi(data);
  
        return uniqBy(configs, (config: any) => config.parameter_id);
      },
      fallBackErrMsg: "Could not fetch bom parameters",
    });
  
    // fetch commodity parameters
    const { 1: processConfigs } = useFetch<ParameterConfig[]>({
      method: processConfigService.get,
      args: [process, true],
      dependencies: [selectedTreeData],
      condition: selectedTreeData?.type === "calculator",
      transformResData: transformConfigurationsFromApi,
      fallBackErrMsg: "Could not fetch commodity parameters",
    });
  
    const getAllResult = () => {
      API.get(
        "/cost/share_costing_config/",
        {
          project: projectId,
          costing_config: true,
        },
        0
      )
        .then((res: any) => {
          sessionStorage.setItem("otherValidation", JSON.stringify(res.data[0]));
        })
        .catch((err: any) => { });
    };
  
    useEffect(() => {
      getAllResult();
    }, []);
  
    const { 1: calculator, 2: setCalculator } = useFetch<ICalculator>({
      method: calculatorService.getById,
      args: [{ id: selectedTreeData?.id }],
      getZeroth: true,
      condition: selectedTreeData?.type === "calculator",
      dependencies: [selectedTreeData],
    });
    const ExactDebounce = useRef(
      debounce(async (value: any) => {
        await API.put(
          `/cost/calculator/${selectedTreeData?.id}/`,
          {
            name: value,
          },
          {},
          0
        )
          .then((res: any) => {
            // setCalcName(value);
          })
          .catch((err: any) => {
            setCalcName((prev: any) => prev);
          });
      }, 500)
    ).current;
    const TestInputChange = useRef(
      debounce(async (value: any, name: any) => {
        API.put(`/cost/calculator_input/${value}/`, { test_input: name }, {}, 0)
          .then((res: any) => { })
          .catch((err: any) => { });
      }, 500)
    ).current;
  
    const aliasChange = useRef(
      debounce(async (value: any, name: any) => {
        API.put(`/cost/calculator_input/${value}/`, { alias: name }, {}, 0)
          .then((res: any) => {
            setColor("");
            fetchInputs();
          })
          .catch((err: any) => { });
      }, 500)
    ).current;
  
    useEffect(() => {
      return () => {
        ExactDebounce.cancel(); // Cancel the debounced function
        TestInputChange.cancel();
      };
    }, []);
    const handleModelNameChange = (ed: any) => {
      setColorCalc("red");
      const { value } = ed.target;
      setCalcName(value);
      // ExactDebounce(value);
    };
    useEffect(() => {
      if (calculator) {
        setCalcName(calculator?.name);
        setValidation(calculator?.validation)
      }
    }, [calculator]);
    // ----------------------------------------------------------------------------------
    // helpers
  
    const bomTableConfigOptions = (parameterId: ID) => {
      return bomConfigs
        ? getTableColumnConfigsOptions(bomConfigs, parameterId)
        : [];
    };
  
    const processTableConfigOptions = (parameterId: ID) => {
      return processConfigs
        ? getTableColumnConfigsOptions(processConfigs, parameterId)
        : [];
    };
  
    type DE_TYPE =
      | typeof CALCULATOR_INPUT_TYPE.BOM_PARAMETER
      | typeof CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER;
    /**
     * indicates whether the give parameter id is table or not
     * @param type whether the parameterId needs to found in bom or peocess paramaters
     * @param parameterId id
     */
    const isTableParameter = (type: DE_TYPE, parameterId: ID) => {
      const configs = type === 1 ? bomConfigs : processConfigs;
      return (
        configs?.find((config) => config.parameter_id === parameterId)
          ?.data_type === "table" ?? false
      );
    };
  
    // ----------------------------------------------------------------------------------
    // memorization
  
    const bomConfigsOptions = useMemo(() => {
      return bomConfigs ? getConfigsOptions(bomConfigs) : [];
    }, [bomConfigs]);
  
    const processConfigsOptions = useMemo(() => {
      return processConfigs ? getConfigsOptions(processConfigs) : [];
    }, [processConfigs]);
  
    // ----------------------------------------------------------------------------------
    // api helpers
    const fetchInputs = async () => {
      setInputsFetched("FETCHING");
      try {
        const { data } = await calculatorInputService.get(selectedTreeData?.id);
  
        inputs.setAll(
          data
            .map(transformCalculatorInputFromApi)
            .sort((a: any, b: any) => a.sequence - b.sequence)
        );
        setInputsFetched("TRUE");
      } catch (e) {
        //   ADMIN.toast.throwError(e, "Could not fetch input");
        setInputsFetched("ERROR");
      }
    };
  
    useEffect(() => {
      fetchInputs();
    }, [saveApiCallCount]);



    React.useEffect(() => {
      API.get(
        "/auth/user_permission_module/",
        {
          action: true,
          configuration: true,
          project: projectId,
          module:"Costing Configuration",
          
        },
        0
      )
        .then((res: any) => {
          setuseraction(res?.data?.action)
          // sessionStorage.setItem("ConfigPermission", res.data.action);
        })
        .catch((err: any) => { });
    }, []);
  
    const createInput = async (inputId: ID, input: CalculatorInput) => {
      try {
        const validatedInput = await toApi(input);
        var datavalue = validatedInput?.volume
          ? { ...validatedInput, attribute: "volume" }
          : validatedInput;
  
        const { data } = await calculatorInputService.create(datavalue);
        inputs.overlapOne(inputId, transformCalculatorInputFromApi(data));
        setSaveApiCallCount((prev: any) => prev + 1);
        //   ADMIN.toast.success("Input Created");
      } catch (e) {
        //   ADMIN.toast.throwError(e, "Could not create input", true);
      }
    };
  
    const updateInput = async (input: Partial<CalculatorInput>) => {
      try {
        const validatedInput = await toApi(input as any);
        var datavalue = validatedInput?.volume
          ? { ...validatedInput, attribute: "volume" }
          : validatedInput;
  
        await calculatorInputService.update(datavalue);
        setSaveApiCallCount((prev: any) => prev + 1);
        //   ADMIN.toast.success("Input Updated");
      } catch (e) {
        //   ADMIN.toast.throwError(e, "Could not update input", true);
      }
    };
  
    const getData = (data: any) => {
      if (selectedTreeData.calculator_type !== CALCULATOR_TYPE.OVERHEAD) {
        return data.filter((item: any) => item.value !== 7);
      } else {
        return data;
      }
    };
  
    const deleteInput = async (inputId: any) => {
      // delete directly from memory if not created in backend
      if (inputId < 1) return inputs.removeOne(inputId);
  
      try {
        await calculatorInputService.delete(inputId);
        inputs.removeOne(inputId);
  
        //   ADMIN.toast.success("Input deleted");
      } catch (e) {
        //   ADMIN.toast.throwError(e, "Could not delete input");
      }
    };
  
    const updateResult = async () => {
      if (!calculator) return;
      if (!calculator.result) return "";
  
      try {
        await calculatorService.update({
          id: selectedTreeData?.id,
          updates: { result: calculator.result },
        });
        //   ADMIN.toast.success("Result updated");
      } catch (e) {
        //   ADMIN.toast.throwError(e, "Could not update result");
      }
    };
  
    // ----------------------------------------------------------------------------------
    // handlers
  
    const handleChange = (inputId: ID) => (e: any) => {
      const { name, value } = e.target;
  
      if (name === "test_input") {
        inputs.updateOne({ id: inputId, changes: { [name]: value } });
        TestInputChange(inputId, value);
      } else if (name === "alias") {
        inputs.updateOne({ id: inputId, changes: { [name]: value } });
        setColor("red");
        setActiveId(inputId);
        aliasChange(inputId, value);
      } else {
        setColor("red");
        setActiveId(inputId);
        inputs.updateOne({ id: inputId, changes: { [name]: value } });
      }
    };
  
    const handleResultChange = (ed: any) => {
      setCalculator((prev: any) =>
        Object.assign({}, prev, { result: ed.target.value })
      );
    };
  
    const handleAdd = () => {
      const newInput: CalculatorInput = {
        id: Math.random(),
        name: "",
        alias: "",
        calculator: id,
        type: CALCULATOR_INPUT_TYPE.BOM_PARAMETER,
      };
  
      inputs.addOne(newInput);
    };
  
    const handleDelete = async (ed: any) => {
      const confirm: any = await deleteSwal("Delete Input");
      if (confirm) deleteInput(ed);
    };
  
    const handleSave = (inputId: any) => () => {
      // create
      const input = inputs.getById(inputId);
      if (!input) return;
  
      if (inputId < 1) {
        createInput(inputId, { ...input, ...{ calculator: selectedTreeData?.id } });
        return;
      }
  
      updateInput(input);
      setColor("");
    };
    const getOptionData = (type: any) => {
      if (type === CALCULATOR_INPUT_TYPE.BOM_PARAMETER) {
        return bomConfigsOptions;
      } else {
        return processConfigsOptions;
      }
    };
  
    const getdatafromtype = (input: any) => {
      if (input.type === 1) {
        return bomTableConfigOptions(input.parameter);
      } else {
        return processTableConfigOptions(input.parameter);
      }
    };
  
    // ----------------------------------------------------------------------------------
    // side effects
  
    // fetch input for the caluclator on mount
    useEffect(() => {
      if (selectedTreeData?.type === "calculator") {
        fetchInputs();
      }
    }, [selectedTreeData]);
  
    // ----------------------------------------------------------------------------------
    // jsx
  
    // TODO: substitute 1,2,3 w/ CALC_INPUT_MAP values
    const renderValueColumn = (input: CalculatorInput) => {
      switch (input.type) {
        case CALCULATOR_INPUT_TYPE.BOM_PARAMETER:
        case CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER:
          return (
            <Box
              sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}
            >
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant="standard"
                  label="Type"
                  disabled={true}
                  value={input.parameter || ""}
                  name="parameter"
                  sx={{
                    width: "50%",
                    fontSize: "1rem",
                    "&:before": {
                      borderBottomColor: "primary.light",
                    },
                    ".MuiSvgIcon-root.MuiSelect-icon": {
                      color: "primary.main",
                    },
                  }}
                  onChange={handleChange(input.id)}
                >
                  {getOptionData(input.type)?.map((item: any) => {
                    return (
                      <MenuItem sx={{ fontSize: "1rem" }} value={item.value}>
                        {item.text}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
  
              {input.parameter &&
                isTableParameter(input.type, input.parameter) && (
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      variant="standard"
                      label="Type"
                      disabled={true}
                      value={input.table_element || ""}
                      onChange={handleChange(input.id)}
                      name="table_element"
                      sx={{
                        width: "100%",
                        fontSize: "1rem",
                        "&:before": {
                          borderBottomColor: "primary.light",
                        },
                        ".MuiSvgIcon-root.MuiSelect-icon": {
                          color: "primary.main",
                        },
                      }}
                    >
                      {getdatafromtype(input)?.map((item: any) => {
                        return (
                          <MenuItem sx={{ fontSize: "1rem" }} value={item.value}>
                            {item.text}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
            </Box>
          );
        case CALCULATOR_INPUT_TYPE.DATABASE:
          return (
            <Box
              sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}
            >
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant="standard"
                  label="Type"
                  disabled={true}
                  value={input.database || ""}
                  onChange={handleChange(input.id)}
                  name="database"
                  sx={{
                    width: "50%",
                    fontSize: "1rem",
                    "&:before": {
                      borderBottomColor: "primary.light",
                    },
                    ".MuiSvgIcon-root.MuiSelect-icon": {
                      color: "primary.main",
                    },
                  }}
                >
                  {calculatorDatabaseOptions?.map((item: any) => {
                    return (
                      <MenuItem sx={{ fontSize: "1rem" }} value={item.value}>
                        {item.text}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant="standard"
                  label="Type"
                  disabled={true}
                  value={input.attribute || ""}
                  onChange={handleChange(input.id)}
                  name="attribute"
                  sx={{
                    width: "50%",
                    fontSize: "1rem",
                    "&:before": {
                      borderBottomColor: "primary.light",
                    },
                    ".MuiSvgIcon-root.MuiSelect-icon": {
                      color: "primary.main",
                    },
                  }}
                >
                  {calculatorDatabaseColumnsOptions[input.database || 0]?.map(
                    (item: any) => {
                      return (
                        <MenuItem sx={{ fontSize: "1rem" }} value={item.value}>
                          {item.text}
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              </FormControl>
            </Box>
          );
        case CALCULATOR_INPUT_TYPE.CONSTANT:
          return (
            <TextField
              variant="standard"
              name="constant"
              type="number"
              disabled={true}
              placeholder="Constant Value"
              required
              value={input.constant || 0}
              //@ts-ignore
              onChange={handleChange(input.id)}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
              }}
            />
          );
        case CALCULATOR_INPUT_TYPE.VOLUME:
          return (
            <Box
              sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}
            >
              <TextField
                variant="standard"
                name="volume"
                type="number"
                disabled={true}
                placeholder="volume"
                value={input.volume || ""}
                onChange={handleChange(input.id)}
                InputProps={{
                  style: { fontSize: "1rem" },
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "primary.light",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                }}
              />
              <TextField
                variant="standard"
                name="user_input"
                disabled={true}
                placeholder="User Input Unit (optional)"
                value={input.user_input || ""}
                style={{ display: "none" }}
                onChange={handleChange(input.id)}
                InputProps={{
                  style: { fontSize: "1rem" },
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "primary.light",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                }}
              />
            </Box>
          );
        case CALCULATOR_INPUT_TYPE.FORMULA:
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: "0.5rem",
                width: "100%",
              }}
            >
              <TextField
                variant="standard"
                name="formula"
                placeholder="Formula"
                value={input.formula || ""}
                disabled={true}
                onChange={handleChange(input.id)}
                InputProps={{
                  style: { fontSize: "1rem" },
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "primary.light",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                }}
              />
              <TextField
                variant="standard"
                name="user_input"
                placeholder="Unit (optional)"
                disabled={true}
                value={input.user_input || ""}
                onChange={handleChange(input.id)}
                InputProps={{
                  style: { fontSize: "1rem" },
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "primary.light",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                }}
              />
            </Box>
          );
        case CALCULATOR_INPUT_TYPE.USER_INPUT:
          return (
            <>
              <TextField
                variant="standard"
                name="user_input"
                placeholder="User Input Unit (optional)"
                value={input.user_input || ""}
                disabled={true}
                onChange={handleChange(input.id)}
                InputProps={{
                  style: { fontSize: "1rem" },
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "primary.light",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                }}
              />
            </>
          );
  
        case CALCULATOR_INPUT_TYPE.COST_INPUT:
          return (
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="standard"
                disabled={true}
                size="small"
                label="Cost Type"
                value={input.attribute || ""}
                onChange={handleChange(input.id)}
                name="attribute"
                sx={{
                  width: "100%",
                  fontSize: "1rem",
                  "&:before": {
                    borderBottomColor: "primary.light",
                  },
                  ".MuiSvgIcon-root.MuiSelect-icon": {
                    color: "primary.main",
                  },
                }}
              >
                {calculatorCostInputOptions?.map((item: any) => {
                  return (
                    <MenuItem sx={{ fontSize: "1rem" }} value={item.value}>
                      {item.text}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          );
        case CALCULATOR_INPUT_TYPE.CALCULATOR_RESULT:
          return (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="standard"
              size="small"
              disabled={true}
              label="calculator_category"
              value={input.calculator_category ?? ""}
              onChange={handleChange(input.id)}
              name="calculator_category"
              sx={{
                width: "100%",
                fontSize: "1rem",
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                ".MuiSvgIcon-root.MuiSelect-icon": {
                  color: "primary.main",
                },
              }}
            >
              {calculatorCategoryOptions[selectedTreeData.calculator_type]?.map(
                (item: any) => {
                  return (
                    <MenuItem sx={{ fontSize: "1rem" }} value={item.value}>
                      {item.text}
                    </MenuItem>
                  );
                }
              )}
            </Select>
          );
        default:
          return null;
      }
    };
    //this code is for multiple image upload
  
    const browseFiles = (index: any) => {
      setCurrentSelected(index);
      return inputElRef.current?.click?.();
    };
    const handleImageView = (index: any) => {
      setCurrentImage(inputs.getCollection()[index].image_url);
      return imageViewerElRef.current?.click?.();
    };
  
    const handleFileUpload = (
      e: React.ChangeEvent<HTMLInputElement>,
      id: any
    ) => {
      setIsUploading(true);
      const data = new FormData();
      if (e.target.files?.length) {
        //@ts-ignore
        data.append(`image0`, e.currentTarget.files[0]);
  
        API.put(
          `cost/calculator_field_image_upload/${inputs.getCollection()[currentSelected].id
          }/`,
          data,
          {},
          0
        )
          .then((res: any) => {
            setIsUploading(false);
            //   ADMIN.toast.success("Data uploaded successfully");
            fetchInputs();
          })
          .catch((err: any) => {
            //   ADMIN.toast.error("Could not upload file");
            setIsUploading(false);
          });
      }
    };
  
    const handleFileInputClick = (e: React.MouseEvent) => {
      // clear the prev value on click so that same image can be uploaded again
  
      (e.target as HTMLInputElement).value = "";
    };
  
    // ----------------------------------------------------------------------------------
    // jsx
  
    if (["FALSE", "FETCHING"].includes(inputsFetched))
      return (
        <Box sx={{ width: "100%", padding: "0 1rem" }}>
          <Skeleton animation="wave" sx={{ height: "2rem" }} />
          <Skeleton animation="wave" sx={{ height: "2rem" }} />
          <Skeleton animation="wave" sx={{ height: "2rem" }} />
          <Skeleton animation="wave" sx={{ height: "2rem" }} />
          <Skeleton animation="wave" sx={{ height: "2rem" }} />
          <Skeleton animation="wave" sx={{ height: "2rem" }} />
          <Skeleton animation="wave" sx={{ height: "2rem" }} />
          <Skeleton animation="wave" sx={{ height: "2rem" }} />
          <Skeleton animation="wave" sx={{ height: "2rem" }} />
          <Skeleton animation="wave" sx={{ height: "2rem" }} />
          <Skeleton animation="wave" sx={{ height: "2rem" }} />
          <Skeleton animation="wave" sx={{ height: "2rem" }} />
          <Skeleton animation="wave" sx={{ height: "2rem" }} />
          <Skeleton animation="wave" sx={{ height: "2rem" }} />
          <Skeleton animation="wave" sx={{ height: "2rem" }} />
        </Box>
      );
    return (
      <div data-testid="input-configuration" className={styles.container}>
        <div className={styles.options} style={{display:'none'}}>
          <Box>
            
            
          </Box>
  
          <Box sx={{ display: "flex", alignItems: "center", columnGap: "1rem" }}>           
            {useraction?.includes("L") &&<AddIcon onClick={handleAdd} titleAccess="Add" sx={{ cursor: "pointer" }} />}
            {/* <SyncIcon onClick={fetchInputs} titleAccess="sync result" sx={{ cursor: "pointer" }} /> */}
          </Box>
        </div>
        <div className={styles.tableWrapper}>
          {inputs.length === 0 && (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              { useraction?.includes("L")&&<Typography sx={{ textAlign: "center", fontSize: "1rem" }}>
                <AddIcon
                  onClick={handleAdd}
                  sx={{ fontSize: "4rem", cursor: "pointer" }}
                />
                <Button onClick={handleAdd}>No inputs created yet</Button>
              </Typography>}
            </Box>
          )}
          {inputs.length > 0 && (
            <Box
              sx={{ height: { lg: "55vh", xl: "56vh" } }}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3"
            >
              <Table>
                <TableHead
                  sx={{
                    backgroundColor: "primary.main",
                    position: "sticky",
                    top: "0",
                    zIndex: "1",
                  }}
                >
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        color: "white",
                        width: { lg: "3vw", xl: "2vw" },
                      }}
                    >
                      Sl No.
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        color: "white",
                        width: "9vw",
                      }}
                    >
                      Input Name
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        color: "white",
                        width: "2vw",
                      }}
                    >
                      as
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        color: "white",
                        width: "4vw",
                      }}
                    >
                      Type
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        color: "white",
                        width: "15vw",
                      }}
                    >
                      formula/unit
                    </TableCell>
  
                    {/* <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light',color:'white',}}>Result</TableCell> */}
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        color: "white",
                        width: "4vw",
                      }}
                    >
                      Actions
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        color: "white",
                        width: "6vw",
                        verticalAlign: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          fontWeight: "500",
                          lineHeight: "1.2",
                          textAlign: 'center',
                        }}
                      >
                        Test Input <br />
                        <span
                          style={{
                            cursor: "pointer",
                            fontSize: "1rem",
                            textDecoration: "underline",
                          }}
                          onClick={fetchInputs}
                        >
                          Click for results
                        </span>
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inputs.getCollection().map((input: any, index: any) => (
                    <TableRow key={input.id}>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        <TextField
                          variant="standard"
                          name="sequence"
                          type="number"
                          disabled={true}
                          placeholder="Seq"
                          value={input.sequence}
                          onChange={handleChange(input.id)}
                          InputProps={{
                            style: { fontSize: "1rem" },
                          }}
                          sx={{
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "primary.light",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "primary.main",
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        <TextField
                          variant="standard"
                          name="name"
                          placeholder="Name"
                          disabled={true}
                          value={input.name}
                          onChange={handleChange(input.id)}
                          InputProps={{
                            style: { fontSize: "1rem" },
                          }}
                          sx={{
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "primary.light",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "primary.main",
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        <TextField
                          variant="standard"
                          name="alias"
                          placeholder="as"
                          // disabled={true}
                          value={input.alias}
                          disabled={true}
                          onChange={handleChange(input.id)}
                          InputProps={{
                            style: { fontSize: "1rem" },
                          }}
                          sx={{
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "primary.light",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "primary.main",
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          variant="standard"
                          disabled={true}
                          size="small"
                          value={input.type}
                          onChange={handleChange(input.id)}
                          name="type"
                          sx={{
                            width: "100%",
                            fontSize: "1rem",
                            "&:before": {
                              borderBottomColor: "primary.light",
                            },
                            ".MuiSvgIcon-root.MuiSelect-icon": {
                              color: "primary.main",
                            },
                          }}
                        >
                          {
                            // calculatorInputOptions.filter(input => {
                            //                         /** filter cost_input type, when calculator type is not overhead */
                            //         return selectedTreeData.calculator_type !== CALCULATOR_TYPE.OVERHEAD ? input.value !== CALCULATOR_INPUT_TYPE.COST_INPUT : input;
                            // })
                            getData(calculatorInputOptions)?.map((item: any) => {
                              return (
                                <MenuItem
                                  sx={{ fontSize: "1rem" }}
                                  value={item.value}
                                >
                                  {item.text}
                                </MenuItem>
                              );
                            })
                          }
                        </Select>
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {renderValueColumn(input)}
                      </TableCell>
                      {/* <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light',}}>
                      <div
                        style={{
                          backgroundColor:
                            calculatorInputBackgroundColorMap[input.type],
                          color: "blue",
                        }}
                      >
                        <Input
                          name="result"
                          placeholder=""
                          value={input.result}
                          disabled
                        />
                      </div>
                    </TableCell> */}
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                       {/* {sessionStorage.getItem("ConfigPermission")?.includes("L") && <LoadingButton
                          sx={{
                            minWidth: "0.5rem",
                            maxWidth: "1.5rem",
                            margin: "0 0.2rem",
                            padding: "0.2rem",
                          }}
                          onClick={handleSave(input.id)}
                        >
                          <SaveIcon
                            sx={
                              activeId && activeId == input.id
                                ? { cursor: "pointer", color: color }
                                : { cursor: "pointer" }
                            }
                            titleAccess="save"
                          />
                        </LoadingButton>} */}
                      { useraction?.includes("L") && <LoadingButton
                          sx={{
                            minWidth: "0.5rem",
                            maxWidth: "1.5rem",
                            margin: "0 0.2rem",
                            padding: "0.2rem",
                          }}
                          onClick={() => handleDelete(input.id)}
                        >
                          <DeleteIcon
                            sx={{ cursor: "pointer" }}
                            titleAccess="Delete"
                          />
                        </LoadingButton>}
                       { useraction?.includes("L") &&<LoadingButton
                          sx={{
                            minWidth: "0.5rem",
                            maxWidth: "1.5rem",
                            margin: "0 0.2rem",
                            padding: "0.2rem",
                          }}
                          onClick={() => browseFiles(index)}
                          loading={isUploading && currentSelected === input.id}
                          //@ts-ignore
                          disabled={input.id > 1 ? false : true}
                        >
                          <UploadIcon
                            sx={
                              //@ts-ignore
                              input.id > 1
                                ? { cursor: "pointer" }
                                : { cursor: "not-allowed" }
                            }
                            titleAccess="Upload Image"
                          />
                        </LoadingButton>}
                        <LoadingButton
                          sx={{
                            minWidth: "0.5rem",
                            maxWidth: "1.5rem",
                            margin: "0 0.2rem",
                            padding: "0.2rem",
                          }}
                          onClick={() => handleImageView(index)}
                          disabled={input.image_url ? false : true}
                        >
                          <CameraAltIcon
                            // sx={
                            //   !input.image_url
                            //     ? { cursor: "not-allowed" }
                            //     : { cursor: "pointer" }
                            // }
                            titleAccess="View Image"
                          />
                        </LoadingButton>
                        {useraction?.includes("L") &&<input
                          style={{ display: "none" }}
                          type="file"
                          id={input.id.toString()}
                          ref={inputElRef}
                          accept="image/*"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleFileUpload(e, input.id)
                          }
                          onClick={(e: any) => {
                            handleFileInputClick(e);
                          }}
                        />}
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        <TextField
                          variant="standard"
                          name={input?.formula ? "result" : "test_input"}
                          placeholder="Enter Value"
                          value={input?.formula ? input.result : input.test_input}
                          onChange={handleChange(input.id)}
                          disabled={input.formula !== null ? true : false}
                          sx={{
                            width: "100%",
                            "& .MuiInputBase-input.MuiInput-input": {
                              fontSize: "1rem",
                              paddingLeft: "0.5rem",
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "primary.light",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "primary.main",
                            },
  
                            backgroundColor:
                              calculatorInputBackgroundColorMap[input.type],
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter sx={{ backgroundColor: "primary.light",position:'sticky',bottom:'0',zIndex:1 }}>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        color: "primary.main",
                      }}
                    >
                      -
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        color: "primary.main",
                      }}
                    >
                      Result
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        color: "primary.main",
                      }}
                    >
                      -
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        color: "primary.main",
                      }}
                    >
                      {/* <Input value="Result" /> */}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        color: "primary.main",
                      }}
                    >
                      <TextField
                        variant="standard"
                        size="small"
                        disabled={!useraction?.includes("L")}
                        name="result"
                        placeholder="Formula"
                        value={calculator?.result || ""}
                        onChange={handleResultChange}
                        inputProps={{
                          style: { fontSize: "1rem" },
                        }}
                        sx={{
                          backgroundColor: "white",
                          "& .MuiInputBase-input.MuiInput-input": {
                            fontSize: "1rem",
                            paddingLeft: "0.5rem",
                            paddingBottom: "0.2rem",
                          },
                          "& .MuiInput-underline:before": {
                            borderBottomColor: "primary.light",
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor: "primary.main",
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      {/* <LoadingButton
                        sx={{
                          minWidth: "0.5rem",
                          maxWidth: "1.5rem",
                          margin: "0 0.2rem",
                          padding: "0",
                        }}
                        onClick={updateResult}
                      >
                        <SaveIcon titleAccess="save" />
                      </LoadingButton> */}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        color: "primary.main",
                      }}
                    ></TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </Box>
          )}
          <div style={{ display: "none" }}>
            {/* @ts-ignore */}
            <RViewerJS>
              <img src={currentImage} alt="image" ref={imageViewerElRef} />
            </RViewerJS>
          </div>
        </div>
      </div>
    );
  };
  
  // ----------------------------------------------------------------------------------
  
  export default CostingCostCalculator;
  