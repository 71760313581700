// react

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import RViewerJS from "viewerjs-react";

// vendors
// services
// utils
// constants
// factories
// core
// redux
// ui-components
// others
// styles
import styles from "./TriagulationImageSection.module.scss";
import { updateTriangulation } from "../../../Redux/Actions/triangulation.action";
import { triangulationSelectors } from "../../../Redux/Reducers/triagulation.reducer";
// import NoImgLoader from "../../Assets/images/loading-screen.gif";
import NoImgLoader from "../../../Assets/images/loading-screen.gif";
//  import Image from "../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import Image from "../../../../Components/ViewModule/FeatureLandingPage/ArchitectureImageCard";
import { debounce } from "lodash";



// interfaces and types
// lazy

// ----------------------------------------------------------------------------------

interface ImageSectionCostAnalysisProps {}

const TriagulationImageSection: React.FC<
  ImageSectionCostAnalysisProps
> = () => {
  const [imageload, setImageLoad] = React.useState(false);
  const [interfacedata, setInterfacedata] = React.useState<string>("");
  const [AllImageList, setAllImageList] = useState<any>([]);
  const imageViewer = useRef<any>(null);
  const dispatch = useDispatch();

  const triangulationData: any = useSelector(triangulationSelectors.selectAll);

  const permisson =
    triangulationData.length != 0 && triangulationData[0].allowed_action
      ? triangulationData[0].allowed_action.includes("C") ||
        triangulationData[0].allowed_action.includes("U") ||
        triangulationData[0].allowed_action.includes("D")
        ? false
        : true
      : false;

  const deletePermission =
    triangulationData.length != 0 && triangulationData[0].allowed_action
      ? triangulationData[0].allowed_action.includes("D")
      : false;

  useEffect(() => {
    if (triangulationData[0] != undefined) {
      setInterfacedata(triangulationData[0].inferences);
    }
  }, [triangulationData[0]]);

  const fallbackImage = () => {
    return (
      <div className={styles.fallback_img}>
        {/* <Icon name='image' /> */}
        <span>{"NO Image"}</span>
      </div>
    );
  };

  const handleLoading = () => {
    setImageLoad(true);
  };

  const onImageLoad = () => {
    return <div className={styles.loder}>{/* <SubBoucningLoader /> */}</div>;
  };

 
  const ExactDebounce = React.useRef(
    debounce(async (values: any,Id:any) => {
      if ( !values ) return;
     
      const action: any =  dispatch(updateTriangulation({ id: Id, inference: values }));
      // 
    //   if ( updateUserInputTriangulation.fulfilled.match( action ) ) {
    //       setCount((prev:any)=> prev + 1 );
    //   }
    }, 500)
  ).current;

  const handleInterfaces = (e: any) => {
    console.log(e.target.value.length, deletePermission);
    if (deletePermission) {
        ExactDebounce(e.target.value,triangulationData[0] && triangulationData[0].id)
      setInterfacedata(e.target.value);
    } else if (!deletePermission) {
        ExactDebounce(e.target.value,triangulationData[0] && triangulationData[0].id)
      // setInterfacedata( e.target.value );
      if (e.target.value.length == 0) {
        
        setInterfacedata(triangulationData[0].inference);
        // ADMIN.toast.error("You don't have permission to delete this Inference");
      } else {
        setInterfacedata(e.target.value);
        ExactDebounce(e.target.value,triangulationData[0] && triangulationData[0].id)
      }
    }
  };



  const onSubmit = (id: number) => {
    dispatch(updateTriangulation({ id: id, inference: interfacedata }));
  };

  const imageHandler = (item: any) => {
    // imageViewer.current.value=url
    const position = triangulationData[0]?.image
      ?.map((e: any) => e?.top_vault)
      .indexOf(item?.top_vault);

    let topVaultIdGet: any = [];
    let a: any = topVaultIdGet?.push(
      triangulationData[0]?.image?.slice(
        0 + 1,
        triangulationData[0]?.image?.length
      )
    )[0];

    let b: any = topVaultIdGet?.push(
      triangulationData[0]?.image?.slice(0, position + 1)
    )[0];

    let topVaultIds: any = topVaultIdGet?.flat();
    setAllImageList(topVaultIds);

    // console.log("isViewerOpenisViewerOpenisViewerOpen",url)
    return imageViewer.current?.click?.();
  };

  console.log(AllImageList, "permissonpermisson");

  return (
    <div data-testid="Image-Section-Cost-Analysis" className={styles.container}>
      <div style={{ display: "none" }}>
        {/* @ts-ignore */}
        <RViewerJS>
          {/* <img src={imageViewer?.current?.value} alt="image" ref={imageViewer} /> */}
          {triangulationData[0]?.image?.length &&
            AllImageList?.map((item: any, index: any) => {
              return (
                <>
                  <img
                    src={item}
                    // alt={item}
                    ref={imageViewer}
                  />
                </>
              );
            })}
        </RViewerJS>
      </div>
     
      <div className={styles.interface_area}
      adl-scrollbar="true"
      adl-scrollbar-width="0.3">
        <textarea
          placeholder="Inference"
          className={styles.text_area}
          value={interfacedata}
          onChange={(e: any) => handleInterfaces(e)}
          spellCheck={true}
          readOnly={permisson}
        />
        {/* <Icon name='save' className={styles.save_interface} link={!permisson} onClick={() => onSubmit(triangulationData[0] && triangulationData[0].id)} disabled={permisson} /> */}
      </div>
       {/* @ts-ignore */}
     <RViewerJS> 
      <div
        className={styles.imageSection}
        adl-scrollbar="true"
        adl-scrollbar-width="0.3">
        {triangulationData[0]
          ? triangulationData[0].image.length > 0
            ? triangulationData[0].image.map((imageData: any, index: any) => (
                <>
           
                <Image
                    className={styles.image}
                    // src={NoImgLoader}
                    src={   imageData &&    imageData}
                    
                    key={index}
                    placeholderImg={NoImgLoader}
                    onLoad={() => handleLoading()}
                    style={{
                      objectFit: "contain",
                      height: "14rem",
                      width: "100%",
                      cursor: "pointer",
                    }}
                    
                    />
                    </>
                // <img
                //   src={imageData}
                //   alt="placeholder"
                //   draggable={false}
                //   onLoad={() => handleLoading()}
                //   className={imageload ? styles.image : ""}
                //   key={index}
                // />
              ))
            : fallbackImage()
          : onImageLoad()}
        {/* {triangulationData[0] ?
                        triangulationData[0].image.length > 0 ?
                            triangulationData[0].image[0] && <img onClick={()=>imageHandler(0)} src={triangulationData[0].image[0] } alt="placeholder" draggable={false} onLoad={() => handleLoading()} className={imageload ? styles.image : ""}/>
                            : fallbackImage() : onImageLoad()} */}
      </div>
       </RViewerJS> 
     
    </div>
  );
};

// ----------------------------------------------------------------------------------

export default TriagulationImageSection;
