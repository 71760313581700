import React from 'react';
import { Modal, Box, TextField, Button, Typography, Divider, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Field, Form, Formik, FieldProps } from 'formik';
import CancelIcon from '@mui/icons-material/Cancel';

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50rem",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

const AddChargeModal: React.FC<any> = ({ isOpen, onCloseModal, onSave }) => {
  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: '-2rem'
            }}
          >
            Add Charge
          </Typography>
          <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
            marginBottom: "1rem",
          }}
        />
<Formik
          initialValues={{
            chargeHolder: '',
            chargeStatus: '',
            amount: '',
          }}
            onSubmit={(values) => {
                onSave(values);
                onCloseModal();
            }}
        
        >
          {({ errors, touched }) => (
            <Form>
              <Field name="chargeHolder"   >
                {({ field }: FieldProps) => (
                  <TextField
                  sx={{marginBottom:"1rem"}}
                    {...field}
                    label="Charge Holder"
                    variant="outlined"
                    fullWidth
                    error={touched.chargeHolder && Boolean(errors.chargeHolder)}
                    helperText={touched.chargeHolder && errors.chargeHolder}
                  />
                )}
              </Field>
              <Field name="chargeStatus"  sx={{marginBottom:"1rem"}}>
                {({ field, form }: FieldProps) => (
                  <FormControl fullWidth variant="outlined" error={touched.chargeStatus && Boolean(errors.chargeStatus)} sx={{marginBottom:"1rem"}}>
                    <InputLabel>Charge Status</InputLabel>
                    <Select
                      {...field}
                      label="Charge Status"
                      onChange={(event) => form.setFieldValue("chargeStatus", event.target.value)}
                      value={field.value}
                    >
                      <MenuItem value="Open">Open</MenuItem>
                      <MenuItem value="Closed">Closed</MenuItem>
                    </Select>
                    {touched.chargeStatus && errors.chargeStatus && (
                      <span style={{ color: 'red' }}>{errors.chargeStatus}</span>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field name="amount"  >
                {({ field }: FieldProps) => (
                  <TextField
                  sx={{marginBottom:"1rem"}}
                    {...field}
                    label="Amount"
                    variant="outlined"
                    fullWidth
                    type="number"
                    error={touched.amount && Boolean(errors.amount)}
                    helperText={touched.amount && errors.amount}
                  />
                )}
              </Field>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Add Charge
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default AddChargeModal;