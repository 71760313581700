// react
import axios from "axios";
import { API } from "../../api-services";
import { MODULES } from "../../Constants/modules.constants";
import { s3Url } from "../../utlis/s3url.utils";
import { getArchImages } from "../../Redux/Actions/architecture.action";

const ArchitectureSingleUploadModalS3 = async (
  fileList: any,
  group_id:any,
  sequence: any,
  topvault:any,

  vault:any,
  project: any,
  abbr:any,

  setLoader:any,
  imageListdata:any,
  setSequenceCounter:any
  
) => {
  // const file_name = file?.name;
  var random: any = Math.floor(Math.random() * 1000000 + 1);

  var obj: Array<any> = [];
  const sortAlphaNum = (a: any, b: any) =>
    a.localeCompare(b, "en", { numeric: true });
  var img: any = "";
  // const dispatch = useAppDispatch();
  // + "-" + uuidv4();
  // setLoader(false)
  // // setImageLoader(true)
  // setLoader(true)
  API.get("cart/get_signed_policy/", {
    module: MODULES.CAD,
    product:topvault
  }).then((res) => {
    const data = Array.from(fileList).forEach((file: any, index: any) => {
      const formData = new FormData();
      formData.append(
        "key",
        `${group_id}/${topvault}/${vault}/${project}/${random}/${file.name}`
      );
      formData.append( "Content-Type", file?.type );
      formData.append( "x-amz-server-side-encryption", "AES256" );
      formData.append( "X-Amz-Credential", res.data.data.x_amz_credential );
      formData.append( "X-Amz-Algorithm", "AWS4-HMAC-SHA256" );
      formData.append( "X-Amz-Date", res.data.data.x_amz_date );
      formData.append(
         "X-Amz-Signature",
         res.data.data.aws_policy_signature
      );
      formData.append( "Policy", res.data.data.aws_policy );
      formData.append( "file", file, file?.name );
   setLoader(true)
      axios
        .post(s3Url, formData, {
          onUploadProgress: (data) => {
            setLoader(true)
            //Set the progress value to show the progress bar
          },
        })
        .then((res: any) => {
          setLoader(true)

        
          if (res.status === 204) {

            const formData = new FormData();

            formData.append(
              "image",`/${group_id}/${topvault}/${vault}/${project}/${random}/${file.name}`
            );
            setLoader(true)
            var data = abbr == 0 ? { top_vault: topvault } : { vault: vault }
            // setImageLoader(false)

            if(imageListdata?.image_url_cdn==""){
              API.post(
                `/circuit_diagram/archs/`,
                
                  formData,
                
                {
              ...data,
               
  
                  categories: 3,
  
                  group: group_id,
  
                  rgb: false,
                  // abbreviation: abbr,
                  sequence: sequence,
                },
                0
              ).then((res:any)=>{
                setSequenceCounter((prev:any)=>prev+1)
                // dispatch(
                //   getArchImages({
                //     project: project,
                //     col_page:productpage,
                //     abbreviation: abbr,
        
                //     page: pageNumber,
                //     group_id: imageListdata?.group,
                //   })
                // );
                setLoader(false)
            }).catch((err:any)=>{
              setLoader(false)
            })
            }else{
              API.put(
                `/circuit_diagram/archs/${imageListdata?.id}/`,
                
                  formData,
                
                {
              ...data,
               
  
                  categories:3,
  
                  group: group_id,
  
                  rgb: false,
                  // abbreviation: abbr,
                  sequence:sequence,
                },
                0
              ).then((res:any)=>{
                setSequenceCounter((prev:any)=>prev+1)
                // dispatch(
                //   getArchImages({
                //     project: project,
        
                //     abbreviation: abbr,
                //     col_page:productpage,
                //     page: pageNumber,
                //     group_id: imageListdata?.group,
                //   })
                // );
                // API.put(`/circuit_diagram/archs/${imageListdata?.id}/`, {
                //   validation: false,
                // }).then((res:any)=>{})
               
                setLoader(false)
            }).catch((err:any)=>{
              setLoader(false)
            })
            }
       
          
          }
        });
    });
  });
};

// ----------------------------------------------------------------------------------

export default ArchitectureSingleUploadModalS3;
