import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import {
	Checkbox,
	Divider,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Tooltip,
} from '@mui/material';
import {
	DataGrid,
	GridColDef,
	GridCsvExportMenuItem,
	GridCsvExportOptions,
	GridToolbar,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExportContainer,
	GridValueGetterParams,
} from '@mui/x-data-grid';
import { API } from '../../../api-services';
import { LoadingButton } from '@mui/lab';
import { useBaseModal } from '../../SearchPage/useBaseModal';
import ErrorModalFilesMail from '../../ProjectMailModule/ErrorModalFilesMail';


interface InfoModalProps {
	onCloseModal: any;
	isOpen: any;
	materialItemsData: any;
	SelectedmulIds: any;
	selectedCategory: any;
	revisionsName: any;
	ApplyCostType: any;
}
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '80vw',
	// height: '80vh',
	bgcolor: 'background.paper',
	borderRadius: '10px',
	p: 1,
};
const csvOptions: GridCsvExportOptions = {
	fileName: 'Table In CSV',
	delimiter: ',',
	utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
	<GridToolbarExportContainer {...props}>
		<GridCsvExportMenuItem options={csvOptions} />
	</GridToolbarExportContainer>
);

const CustomToolbar = () => (
	<GridToolbarContainer style={{ height: '1.5rem', marginBottom: '0.5rem' }}>
		<span style={{ position: 'absolute', right: '0rem' }}>
			{/* <GridToolbarColumnsButton /> */}
			{/* <GridToolbarDensitySelector /> */}
			<CustomExportButton />
		</span>
	</GridToolbarContainer>
);
const BoughtOutCombinedAssumtionModal = (props: InfoModalProps) => {
	const {
		onCloseModal,
		isOpen,
		materialItemsData,
		SelectedmulIds,
		selectedCategory,
		revisionsName,
		ApplyCostType,
	} = props;
	const [DataList, setDataList] = useState<any>([]);
	const [SelectedMaterial, setSelectedMaterial] = useState<any>([]);

	const [loader, setLoader] = useState<any>(false);




	const [costData, setCostData] = useState<any>(null)

	const [Count, setCount] = useState<any>(1)
	const [MaterialType, setMaterialType] = useState<any>(null)


	const [NextLoader, setNextLoader] = useState<any>(false)
	const [PreviousLoader, setPreviousLoader] = useState<any>(false)
	const ErrorModalFiles = useBaseModal()
	const [ErrorModalFileMsg, setErrorModalFileMsg] = useState<any>();


	var materialIds =
		materialItemsData &&
		materialItemsData
			?.filter((item: any, index: any) => {
				return item?.calculator_mapped == true;
			})
			?.map((item2: any, index: any) => {
				return item2?.purchasing_item?.id;
			});




	var calIds: any = materialItemsData &&
		materialItemsData
			?.filter((item: any, index: any) => {
				return item?.calculator_mapped == true;
			})[0]






	const MaterialCheckBoxhandler = (id: any, item: any) => {

		var Material_EstimationIds = materialItemsData &&
			materialItemsData
				?.filter((item: any, index: any) => {
					return item?.calculator_mapped == true && (DataList[Count - 1] && DataList[Count - 1]?.purchase_item_id == item?.purchasing_item?.id);
				})[0]




		if (id == "avg") {
			API.post(`/cost/update_calculation/`, {

				material_estimation: Material_EstimationIds?.id,

				calculator_ids: calIds?.cal_ids,

				bop_type: "avg",




			}).then((res: any) => {



				// 	setCostData(null)
				// setCostScrapData(null)
				// setScrapCostType([])

			}).catch((err: any) => {
				setNextLoader(false)
				const { data } = err.response;

				ErrorModalFiles.open();
				setErrorModalFileMsg(data);
				console.log("Server Error")
			})
		}
		else if (id == "min") {
			API.post(`/cost/update_calculation/`, {

				material_estimation: Material_EstimationIds?.id,

				calculator_ids: calIds?.cal_ids,

				bop_type: "min",




			}).then((res: any) => {



				// 	setCostData(null)
				// setCostScrapData(null)
				// setScrapCostType([])

			}).catch((err: any) => {
				setNextLoader(false)
				const { data } = err.response;

				ErrorModalFiles.open();
				setErrorModalFileMsg(data);
				console.log("Server Error")
			})
		} else if (id == "max") {
			API.post(`/cost/update_calculation/`, {

				material_estimation: Material_EstimationIds?.id,

				calculator_ids: calIds?.cal_ids,

				bop_type: "max",




			}).then((res: any) => {



				// 	setCostData(null)
				// setCostScrapData(null)
				// setScrapCostType([])

			}).catch((err: any) => {
				setNextLoader(false)
				const { data } = err.response;

				ErrorModalFiles.open();
				setErrorModalFileMsg(data);
				console.log("Server Error")
			})
		} else {
			API.post(`/cost/update_calculation/`, {

				material_estimation: Material_EstimationIds?.id,

				calculator_ids: calIds?.cal_ids,

				bop_type: "single",
				revision: item?.revision_id




			}).then((res: any) => {



				// 	setCostData(null)
				// setCostScrapData(null)
				// setScrapCostType([])

			}).catch((err: any) => {
				setNextLoader(false)
				const { data } = err.response;

				ErrorModalFiles.open();
				setErrorModalFileMsg(data);
				console.log("Server Error")
			})
		}



		setSelectedMaterial([id]);

	};




	// @ts-ignore

	useEffect(() => {
		setLoader(true)
		API.get(`/db/bought_out_cost/`, {
			commodity: selectedCategory,
			revision: revisionsName,
			assumption_list: SelectedmulIds?.toString(),
			purchasing_item: materialIds?.toString(),
		}).then((res: any) => {
			setDataList(res?.data);

			setLoader(false)


			// setMaterialCostType(DataMinmax);
			// setScrapCostType(DataMinmax);
			// setSelectedScrap(Data);
			// setSelectedMaterial(Data);
		}).catch((err: any) => {

			setLoader(false)
		})
	}, [SelectedmulIds]);




	const PreviousHandler = () => {
		setCount((prev: any) => prev - 1)

		var Material_EstimationIds = materialItemsData &&
			materialItemsData
				?.filter((item: any, index: any) => {
					return item?.calculator_mapped == true && (DataList[Count - 2] && DataList[Count - 2]?.purchase_item_id == item?.purchasing_item?.id);
				})[0]


		console.log(materialItemsData, "cdcdcnjdnjkcdnkjcnkjdcnkjdkcnj", Material_EstimationIds, DataList, Count, DataList[Count - 2])





		API.get(`/cost/update_calculation/`, {

			material_estimation: Material_EstimationIds?.id,

			bop_type: true


		}, 0).then((res: any) => {



			if (res?.data?.bop_type == "single") {
				setMaterialType("single")
				let DataListvalue = DataList && DataList[Count - 2]?.other_revision.filter((item: any) => { return item?.id == res?.data?.purchase_item })[0]
				let data = DataList && DataList[Count - 2]?.other_revision.filter((item: any) => { console.log(item, res?.data?.purchase_item, "cdgiucgudgucdgucgduy") })
				let Name = DataListvalue?.revision_name;
				let value = DataListvalue?.unit_cost
				setCostData({ name: Name, value: value })
				console.log(DataListvalue, "DataListvalue")
				// setCostData(DataListvalue)
				setSelectedMaterial([DataListvalue?.id])
			} if (res?.data?.bop_type == "min") {
				let DataListvalue = DataList && DataList[Count - 2]?.min_unit_cost
				setCostData(DataListvalue)
				setMaterialType("min")
				setSelectedMaterial(["min_material_cost"])
			} if (res?.data?.bop_type == "max") {
				setMaterialType("max")
				let DataListvalue = DataList && DataList[Count - 2]?.max_unit_cost
				setCostData(DataListvalue)
				setSelectedMaterial(["max_material_cost"])
			} if (res?.data?.bop_type == "avg") {
				setMaterialType("avg")
				let DataListvalue = DataList && DataList[Count - 2]?.avg_unit_cost
				setCostData(DataListvalue)
				setSelectedMaterial(["avg_material_cost"])
			}

			if (res?.data?.bop_type == null) {
				setCostData(null)

				setSelectedMaterial([])
			}

			// 	setScrapType(null)
			// setMaterialType(null)
			// setSelectedScrap([])
			// 	setSelectedMaterial([])
			// 	setCostData(null)
			// setCostScrapData(null)
			// setScrapCostType([])

		}).catch((err: any) => {
			setPreviousLoader(false)
			console.log("Server Error")
		})

		// setPreviousLoader(true)


		// 	setScrapType(null)
		// setMaterialType(null)
		// setSelectedScrap([])
		// 	setSelectedMaterial([])
		// 	setCostData(null)
		// setCostScrapData(null)
		// setScrapCostType([])


		// API.post(,{},0).then((res:any)=>{

		// 	setSelectedScrap([])
		// 	setSelectedMaterial([])
		// 	setCostData(null)
		// setCostScrapData(null)
		// setScrapCostType([])
		// })

	}
	const NextHandler = () => {

		setCount((prev: any) => prev + 1)

		var Material_EstimationIds = materialItemsData &&
			materialItemsData
				?.filter((item: any, index: any) => {
					return item?.calculator_mapped == true && (DataList[Count] && DataList[Count]?.purchase_item_id == item?.purchasing_item?.id);
				})[0]


		// setPreviousLoader(true)

		API.get(`/cost/update_calculation/`, {

			material_estimation: Material_EstimationIds?.id,
			bop: true



		}, 0).then((res: any) => {



			if (res?.data?.bop_type == "single") {
				setMaterialType("single")
				let DataListvalue = DataList && DataList[Count]?.other_revision.filter((item: any) => { return item?.id == res?.data?.purchase_item })[0]
				let Name = DataListvalue?.revision_name;
				let value = DataListvalue?.unit_cost
				setCostData({ name: Name, value: value })
				console.log(DataListvalue, "DataListvalue")
				// setCostData(DataListvalue)
				setSelectedMaterial([DataListvalue?.id])
			} if (res?.data?.bop_type == "min") {
				let DataListvalue = DataList && DataList[Count]?.min_unit_cost
				setCostData(DataListvalue)
				setMaterialType("min")
				setSelectedMaterial(["min"])
			} if (res?.data?.bop_type == "max") {
				setMaterialType("max")
				let DataListvalue = DataList && DataList[Count]?.max_unit_cost
				setCostData(DataListvalue)
				setSelectedMaterial(["max"])
			} if (res?.data?.bop_type == "avg") {
				setMaterialType("avg")
				let DataListvalue = DataList && DataList[Count]?.avg_unit_cost
				setCostData(DataListvalue)
				setSelectedMaterial(["avg"])
			}



			if (res?.data?.bop_type == null) {
				setCostData(null)

				setSelectedMaterial([])
			}
			// 	setScrapType(null)
			// setMaterialType(null)
			// setSelectedScrap([])
			// 	setSelectedMaterial([])
			// 	setCostData(null)
			// setCostScrapData(null)
			// setScrapCostType([])

		}).catch((err: any) => {
			setPreviousLoader(false)
			console.log("Server Error")
		})






	}


	const ApplyHandler = () => {
		ApplyCostType();

	};

	const closedHandler = () => {
		onCloseModal()
		ApplyCostType();
	}





	return (
		<div>
			<Modal open={isOpen}
			// onClose={onCloseModal}
			>



				<Box sx={style}>

					{ErrorModalFiles.isOpen && <ErrorModalFilesMail
						isOpen={ErrorModalFiles.isOpen}
						onCloseModal={ErrorModalFiles.close}
						ErrorMsg={ErrorModalFileMsg}
					/>}
					<Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
						<Typography
							style={{
								fontSize: '1rem',
								fontWeight: '600',
								width: '100%',
								textAlign: 'center',
								marginRight: '-2rem',
							}}>
							Select BOP Cost
						</Typography>
						<CancelIcon
							titleAccess='Close'
							sx={{ cursor: 'pointer', margin: '0.5rem' }}
							onClick={() => closedHandler()}
						/>
					</Box>
					<Divider
						sx={{
							borderColor: 'primary.light',
							margin: '0 1rem',
							marginBottom: '1rem',
						}}
					/>
					<Box
						sx={{ maxHeight: '65vh', width: '100%' }}
						adl-scrollbar='true'
						adl-scrollbar-width='0.3'>
						{loader ? <>
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
						</>
							:

							<>

								<Typography sx={{ fontSize: '1rem', fontWeight: '600' }}> {Count}. BOP Code : {DataList && DataList[Count - 1] && DataList[Count - 1]?.code} ({DataList[Count - 1] && DataList[Count - 1]?.other_revision?.length})</Typography>
								<Table
									stickyHeader
									aria-label='sticky table'
									sx={{ minWidth: 650 }}>
									<TableHead
										sx={{ background: '#ddefff !important' }}>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												borderBottomColor: 'primary.light',
												backgroundColor: 'primary.light',
											}}>
											Name
										</TableCell>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												borderBottomColor: 'primary.light',
												backgroundColor: 'primary.light',
											}}>
											Unit Cost (INR/kg)
										</TableCell>

									</TableHead>
									<TableBody>
										{DataList && DataList[Count - 1]?.other_revision?.map(
											(item: any, inde: any) => {
												return (
													<TableRow>
														<TableCell
															component='th'
															scope='row'
															sx={{ borderBottom: 'none', padding: '0.2rem 0.5rem' }}>
															{item?.revision_name}
														</TableCell>
														<TableCell
															component='th'
															scope='row'
															sx={{
																borderBottom: 'none', padding: '0.2rem 0.5rem',
																borderRigth: '1px solid rgba(224, 224, 224, 1) !important',
															}}>
															{item?.unit_cost?.toFixed(2)}

															<Checkbox
																style={{ padding: '3px', margin: '0 1rem 0 0.5rem' }}
																{...label}
																// checked={chekboxvalueMaterial?.includes(
																// 	item?.id
																// )}
																checked={SelectedMaterial && SelectedMaterial?.includes(item?.id)}
																// onChange={(e: any) => MaterialCheckBoxhandler(
																// 	item?.id,
																// 	DataList[0]?.material_code,

																// )} 
																onChange={(e: any) => MaterialCheckBoxhandler(
																	item?.id,
																	item,

																)}
															/>
														</TableCell>

													</TableRow>
												);
											}
										)}
										<TableRow>
											<TableCell
												sx={{ borderBottom: 'none', padding: '0.2rem 0.5rem' }}></TableCell>
											<TableCell sx={{ borderBottom: 'none', padding: '0.2rem 0.5rem' }}>
												<TableRow sx={{ display: 'flex !important' }}>
													<TableCell
														sx={{
															borderBottom: 'none',
															padding: '0rem !important',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															width: '100%',
														}}>
														<Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap', color: 'green' }}>Minimum  ({DataList && DataList[Count - 1]?.min_unit_cost?.toFixed(2)})</Typography>
														<Checkbox
															style={{ padding: '3px', margin: '0 1rem 0 0.5rem', color: 'green' }}
															{...label}
															// checked={datavalue(DataList[0])[0] &&
															// 	datavalue(DataList[0])[0][DataList[0].material_code] &&
															// 	datavalue(DataList[0])[0][DataList[0].material_code]
															// 		?.Min
															// 	? true
															// 	: false}

															checked={SelectedMaterial && SelectedMaterial?.includes("min") ? true : false}

															onChange={(e: any) => MaterialCheckBoxhandler(
																"min",
																DataList[Count - 1]?.code,

															)}
														// onChange={() => CostType(
														// 	DataList[0],
														// 	DataList[0]?.min_material_cost,
														// 	DataList[0],
														// 	'Min'
														// )} 

														/>
													</TableCell>
													<TableCell
														sx={{
															borderBottom: 'none',
															padding: '0rem !important',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															width: '100%',
														}}>
														<Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap', color: '#ffc800' }}>Average ({DataList && DataList[Count - 1]?.avg_unit_cost?.toFixed(2)})</Typography>
														<Checkbox
															style={{ padding: '3px', margin: '0 1rem 0 0.5rem', color: '#ffc800' }}
															{...label}
															// checked={datavalue(DataList[0])[0] &&
															// 	datavalue(DataList[0])[0][DataList[0].material_code] &&
															// 	datavalue(DataList[0])[0][DataList[0].material_code]
															// 		?.Average
															// 	? true
															// 	: false}

															checked={SelectedMaterial && SelectedMaterial?.includes("avg") ? true : false}

															onChange={(e: any) => MaterialCheckBoxhandler(
																"avg",
																DataList[Count - 1]?.code,

															)}
														// onChange={() => CostType(
														// 	DataList[0],
														// 	DataList[0]?.avg_material_cost,
														// 	DataList[0],
														// 	'Average'
														// )}

														/>
													</TableCell>
													<TableCell
														sx={{
															borderBottom: 'none',
															padding: '0rem !important',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															width: '100%',
														}}>
														<Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap' }}>Maximum ({DataList && DataList[Count - 1]?.max_unit_cost?.toFixed(2)})</Typography>
														<Checkbox
															style={{ padding: '3px', margin: '0 1rem 0 0.5rem', color: '#007fff' }}
															{...label}
															// checked={datavalue(DataList[0])[0] &&
															// 	datavalue(DataList[0])[0][DataList[0].material_code] &&
															// 	datavalue(DataList[0])[0][DataList[0].material_code]
															// 		?.Max
															// 	? true
															// 	: false

															// }

															// onChange={() => CostType(
															// 	DataList[0],
															// 	DataList[0]?.max_material_cost,
															// 	DataList[0],
															// 	'Max'
															// )} 

															checked={SelectedMaterial && SelectedMaterial?.includes("max") ? true : false}
															onChange={(e: any) => MaterialCheckBoxhandler(
																"max",
																DataList[Count - 1]?.code,

															)}

														/>
													</TableCell>

												</TableRow>
												<TableRow>
													<TableCell
														colSpan={3}
														sx={{
															background: '#ddefff !important',
															padding: '0.2rem 0',
															width: '100%',
														}}>

														{/* Value :&nbsp;
													{datavalue(DataList[0])[0] &&
														datavalue(DataList[0])[0][DataList[0].material_code] &&
														datavalue(DataList[0])[0][DataList[0].material_code] &&
														Object.values(
															datavalue(DataList[0])[0] &&
															datavalue(DataList[0])[0][DataList[0].material_code] &&
															datavalue(DataList[0])[0][DataList[0].material_code]
														)} */}

														{
															SelectedMaterial && SelectedMaterial?.length > 0 ? <>


																Selected Value:
																{SelectedMaterial && SelectedMaterial?.includes("max") ? <span>



																	Maximum  <b>{costData && costData} INR/kg</b>
																</span> : SelectedMaterial && SelectedMaterial?.includes("min") ? <span>Minimum  <b>{costData && costData} INR/kg</b> </span> : SelectedMaterial && SelectedMaterial?.includes("avg") ? <span>Average <b>{costData && costData} INR/kg</b></span> : <span>{costData && costData !== undefined && costData?.name}  <b>{costData && costData !== undefined && costData?.value} INR/kg</b></span>


																}
															</> :
																<>
																	Selected Value:
																</>



														}
													</TableCell>
												</TableRow>
											</TableCell>

										</TableRow>





									</TableBody>
								</Table></>
						}
					</Box>
					{loader == false &&
						<Box sx={{ display: 'flex', columnGap: '2rem', justifyContent: 'flex-end', alignItems: 'center', width: '100%', padding: '0.5rem' }}>
							<LoadingButton loading={PreviousLoader} disabled={Count != 1 ? false : true} startIcon={<NavigateBeforeIcon sx={{ marginRight: '-0.5rem' }} />} size='small' variant='contained' onClick={() => PreviousHandler()}>
								Previous {Count - 1 != 0 && (`${Count - 1}`)}
							</LoadingButton>
							{
								DataList && DataList?.length == Count ?

									<LoadingButton
										disabled={SelectedMaterial && SelectedMaterial?.length == 0 ? true : false}
										// disabled={DataList?.length<=Count?false:true} 
										size='small' variant='contained' onClick={() => ApplyHandler()}>
										Proceed
									</LoadingButton>
									:

									<LoadingButton loading={NextLoader}

										disabled={SelectedMaterial && SelectedMaterial?.length == 0 ? true : false}
										endIcon={<NavigateNextIcon sx={{ marginLeft: '-0.5rem' }} />} size='small' variant='contained' onClick={() => NextHandler()}>
										Next ({DataList?.length - Count})
									</LoadingButton>
							}

						</Box>}
				</Box>
			</Modal>
		</div>
	);
};

export default BoughtOutCombinedAssumtionModal;
