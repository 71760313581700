import axios from "axios";
import { API } from "../../api-services";
import { MODULES } from "../../Constants/modules.constants";
import { ADMIN } from "../../Redux/Services/admin.service";
import { ID } from "../../utlis/commonInterfaces";
import { s3Url } from "../../utlis/s3url.utils";

const milestoneFileUpload = (
    fileList: any,
    project_id: ID,
    pathName: any,
    setisloading: any,
    setFilePathGet:any,
    setFilePathName?:any,
    setGetdocumentDataAPICall?:any,
    getdocumentDataAPICall?:any

) => {
    var random: any = Math.floor( Math.random() * 1000000 + 1 );
    var editedImageName: any = `EditedImage${random}`;
    var obj: Array<any> = [];
    API.get( "cart/get_signed_policy/", {
        module: MODULES.CAD,
    } ).then( res => {
        const data = Array.from( fileList ).forEach( ( file: any, index: any ) => {
            const formData = new FormData();
            formData.append( "key", `${pathName}/${project_id}/${random}/${file.name}` );
            formData.append( "Content-Type", file?.type );
            formData.append( "x-amz-server-side-encryption", "AES256" );
            formData.append( "X-Amz-Credential", res.data.data.x_amz_credential );
            formData.append( "X-Amz-Algorithm", "AWS4-HMAC-SHA256" );
            formData.append( "X-Amz-Date", res.data.data.x_amz_date );
            formData.append(
                "X-Amz-Signature",
                res.data.data.aws_policy_signature
            );
            formData.append( "Policy", res.data.data.aws_policy );
            formData.append( "file", file, file?.name );
            axios
                .post( s3Url, formData, {
                    onUploadProgress: data => {
                        //Set the progress value to show the progress bar
                    },
                } )
                .then( async ( res: any ) => {
                    if ( res.status === 204 ) {
                        API.put( "/xcpep/project/"+ project_id + "/", {proposal_document: `${pathName}/${project_id}/${random}/${file.name}`}).then((res:any)=>{
                            return (
                                API.get(`xcpep/proposal_document_view`, { id: project_id }, 0)
                                .then((res: any) => {
                                  setFilePathGet(res?.data);
                                  const lastIndex = res?.data.lastIndexOf('/');
                                  const substring = res?.data.substring(lastIndex + 1);
                                  setFilePathName(substring);
                                  setisloading( false );
                                  setGetdocumentDataAPICall(getdocumentDataAPICall);
                                })
                                .catch((err: any) => {
                                  console.log(err, "error");
                                })
                            )});
                    }
                } ).catch( ( err ) => {
                    ADMIN.toast.error( "Not able to upload PDF" );
                    setisloading( false );
                } );
        } );
    } );
};
// ----------------------------------------------------------------------------------
export default milestoneFileUpload;