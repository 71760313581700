import { Box, MenuItem, Select } from "@mui/material";
import * as React from "react";
import UploadIcon from "@mui/icons-material/Upload";
import PreviewIcon from "@mui/icons-material/Preview";
import { LoadingButton } from "@mui/lab";
import { ChangeEvent, useRef, useState } from "react";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import RViewerJS from "viewerjs-react";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { useParams } from "react-router-dom";
import { userPermissionAction } from "./BOMEntryLandingPage";
import CameraModal from "./CameraModal";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import { set } from "lodash";
export interface ISingleSelectInputBoxProps {
  key1: any;
  val: any;
  setDataForUpdate: any;
  target: any;
  AllData: any;
  allOptions: any;
  option: any;
  itmKey: any;
  setColorButton: any;
  setShowButton: any;
  setCounter: any;
  setSaveDisabled: any;
  rowData: any;
  setRowData: any;
}

export const ImageEditViewBox=React.memo((props: ISingleSelectInputBoxProps)=> {
  const userAction = React.useContext(userPermissionAction);
  let UserAction = userAction && userAction?.action;

  const {
    key1,
    val,
    setDataForUpdate,
    target,
    AllData,
    allOptions,
    option,
    itmKey,
    setColorButton,
    setShowButton,
    setCounter,
    setSaveDisabled,
    rowData,
    setRowData
  } = props;
  const cameraIconModal = useBaseModal();
  const [value, setValue] = React.useState<any>("");
  const { copyStatus } = useParams<any>();
  const [loading, setLoading] = React.useState<any>(false);
  const { vault, topVault } = useRouteParams<any>();
  // const [ImageObjKey, setImageObjKey] = React.useState<any>();
  const [ImageObjValue, setImageObjValue] = React.useState<any>();
  const getObj = () => {
    if (vault == 0) {
      return { top_vault: topVault };
    } else {
      return { vault: vault };
    }
  };
  
  const ImageViewer = useRef<any>();
  const [srcImages, setSrcImages] = useState<any>();
  const [options, setOptions] = React.useState<any>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleImageSelect = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };
  const handleClick = (srcImg: any, e:any) => {
    console.log(srcImg, "srcImg")
    e.stopPropagation();
    if (srcImg?.length > 0) {
      ImageViewer.current.src = srcImg;
      ImageViewer.current?.click?.();
      setSrcImages(srcImg);
    }
  };

  const handleOpenCameraModal = () => {
    cameraIconModal.open();
  }

  React.useEffect(() => {
    if (allOptions?.length > 0) {
      setOptions(allOptions);
    }
  }, [allOptions]);

  

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      setSelectedFile(file);
      const formData = new FormData();
      formData.append("image", file);
      setLoading(true);
      API.post(
        `/bom/table_parameter_image/`,
        formData,
        {
          ...getObj(),
          parameter: itmKey,
          image_param_id: option[0]?.key,
          index: target,
          new_ui: true,
        },
        0
      )
        .then((res: any) => {
            setColorButton("red")
            setShowButton(true)
            console.log(res.data[key1]);
            setRowData({...rowData, [key1]: res.data[key1]})
          setValue(res.data[key1]);
          AllData.filter((item: any, index: any) => index === target)[0][key1] =
            res.data[key1] ? res.data[key1] : "";
          setDataForUpdate(AllData);
          setCounter((prev: any) => prev + 1);
          setLoading(false);
          setSaveDisabled(false);
        })
        .catch((err: any) => {
          setLoading(false);
        });
      // onImageSelect(file);
    }
  };

  React.useEffect(() => {
    if (val) {
      setValue(val);
    } else {
      setValue("");
    }
  }, [val]);
  // const handleChange = (event: any) => {
  //     const { value } = event.target;
  //     setValue(value)
  //     AllData.filter((item: any, index: any) => index === target)[0][key1] = value;
  //     setDataForUpdate(AllData)
  // }
  


  return (
    <Box sx={{ display: "flex" }}>

      <div style={{ display: "none" }}>
        {/* @ts-ignore */}
        <RViewerJS>
          <img src={ImageViewer.current?.src} alt="image" ref={ImageViewer} />
        </RViewerJS>
      </div>

      <CameraAltIcon style={{cursor:'pointer'}} onClick = {()=> handleOpenCameraModal()} />
      {
        cameraIconModal.isOpen && (
          <CameraModal
            isOpen={cameraIconModal.isOpen}
            onCloseModal={cameraIconModal.close}
            itmKey={itmKey}
            option={option}
            target={target}
            key1={key1}
            val={val}
            setDataForUpdate={setDataForUpdate}
            AllData={AllData}
            setColorButton={setColorButton}
            setShowButton={setShowButton}
            setCounter={setCounter}
            setSaveDisabled={setSaveDisabled}
            type="edit"
            
            
            />)
      }

      {(copyStatus == false || copyStatus == "false") &&
        (UserAction?.includes("U") || UserAction?.includes("C")) && (
          <LoadingButton
            size="small"
            sx={{
              padding: "0.1rem 0.5rem",
              lineHeight: 1,
              minWidth: "1rem",
              "&:hover": {
                transform: "Scale(1.05)",
                transition: "transform 0.5s ease",
              },
              ".MuiCircularProgress-root": {
                color: "primary.main",
              },
            }}
            loading={loading}
          >
            <input
              type="file"
              ref={inputRef}
              style={{ display: "none" }}
              accept="image/*"
              onChange={handleFileChange}
            />
            <UploadIcon
              sx={{ cursor: "pointer" }}
              onClick={handleImageSelect}
            />
          </LoadingButton>
        )}

      <LoadingButton
        size="small"
        disabled={value != undefined && value?.length > 0  ? false : true}
        sx={{
          padding: "0.1rem 0.5rem",
          lineHeight: 1,
          minWidth: "1rem",
          "&:hover": {
            transform: "Scale(1.05)",
            transition: "transform 0.5s ease",
          },
          ".MuiCircularProgress-root": {
            color: "primary.main",
          },
        }}
      >
        {value != undefined && value?.length > 0 && <PreviewIcon
          sx={{
            cursor: value != undefined && value?.length > 0  ? "pointer" : "not-allowed",
            color: value != undefined && value?.length > 0 ? "green" : "red",
          }}
          onClick={(e:any) => 
            
            
            handleClick(`${option[0]?.cdn}${value}`, e)
            
            
          }
        />}
      </LoadingButton>
    </Box>
  );
})
