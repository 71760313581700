import * as React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import BomIcon from "../../../Assets/images/hierarchy.png";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { API } from "../../../api-services";
import { useEffect } from "react";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import CopyHierarchyBOMModal from "./CopyHierarchyBOMModal";
import { LoadingButton } from "@mui/lab";
import { useHistory, useParams } from "react-router-dom";
import ErrorModalFilesMail from "../../ProjectMailModule/ErrorModalFilesMail";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "45vw", xl: "35vw" },
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

export interface IBOMEntryCopytoOtherModalProps {
  onCloseModal: any;
  isOpen: any;
  getData: any;
  setParentsCounter: any;
  SucessAndCoptpartToOther: any;
}

export default function BOMEntryCopytoOtherModal(
  props: IBOMEntryCopytoOtherModalProps
) {
  const {
    onCloseModal,
    isOpen,
    getData,
    setParentsCounter,
    SucessAndCoptpartToOther,
  } = props;
  const {
    projectId,
    vehCateId
    // topVault,
    // vault,
    // leafNode,
    // treeabbr,
    // scenarioId,
    // projectName,
    // workstation,
    // isLeaf,
  } = useParams<any>();

  const [SelectedProject, setSelectedProject] = React.useState<any>({});
  const [SelectedProduct, setSelectedProduct] = React.useState<any>({});
  const [ProductLoader, setProductLoader] = React.useState<any>(false);
  const [ProjectList, setProjectList] = React.useState<any>([]);

  const BomHierarchyModal = useBaseModal();

  const [ProductList, setProductList] = React.useState<any>([]);
  const [CopyInfoLoader, setCopyInfoLoader] = React.useState(false);
  const [ApplyData, setApplyData] = React.useState<any>();
  const [CopyInfo, setCopyInfo] = React.useState<any>({});
  const [ProjectError, setProjectError] = React.useState(false);
  const [DestinationError, setDestinationError] = React.useState<any>(false);
  const [Loader, setLoader] = React.useState<any>(false);
  const [ErrorModalFileMsg, setErrorModalFileMsg] = React.useState<any>();
  const [Checkboxitem, setCheckboxitem] = React.useState<any>(false);
  const ErrorModalFiles = useBaseModal();

  const history = useHistory<any>();
  React.useEffect(() => {
    API.get(`/supplier/product_category_view/`, { module: "BOM" })
      .then((res: any) => {
        setProjectList(res?.data?.filter((item:any)=>item?.id!=vehCateId));
      })
      .catch((err: any) => {
        console.log("Server Error");
      });
  }, []);

  const ProjectHandleChange = (newData: any) => {
    setSelectedProject(newData);

    if (newData !== null) {
      setProductLoader(true);
      setProjectError(false);
      API.get(`/bom/product_list_new/`, {
        module: "BOM-Create",
        vehicle_category: newData?.id,
      })
        .then((res: any) => {
          setProductLoader(false);
          setProductList(res?.data);
        })
        .catch((err: any) => {
          setProductLoader(false);
          console.log("Server error");
        });
    }
  };

  const ProductHandleChange = (newData: any) => {
    setSelectedProduct(newData);
  };

  React.useEffect(() => {
    setCopyInfoLoader(true);
    API.get(`bom/vault_copy_info/`, { vault: getData?.id })
      .then((res: any) => {
        setCopyInfo(res?.data);
        setCopyInfoLoader(false);
      })
      .catch((err: any) => {
        setCopyInfoLoader(false);
        console.log("Server Error");
      });
  }, [getData]);
  const ApplyHandler = (data: any) => {
    setApplyData(data);
    setDestinationError(false);
  };
  const BOmIconHandler = () => {
    // @ts-ignore

    BomHierarchyModal.open();
  };
  const CheckboxHandler = (e: any) => {
    setCheckboxitem(e?.target?.checked);
  };
  const PasteHandler = () => {
    if (
      ApplyData &&
      Object.keys(ApplyData)?.length > 0 &&
      SelectedProject &&
      Object.keys(SelectedProject)?.length > 0
    ) {
      setLoader(true);
      API.post(
        `bom/copy_vault/`,
        {
          copy_to_other_product: true,
          source: getData?.id,
          root: ApplyData?.id,
          is_costing: Checkboxitem,
        },
        {},
        0
      )
        .then((res: any) => {
          setLoader(false);

          onCloseModal();
          setParentsCounter((prev: any) => prev + 1);
 
           {
            sessionStorage.removeItem("TragetIds");
            sessionStorage.removeItem("copyId");

            SucessAndCoptpartToOther();
          }
        })
        .catch((err: any) => {
          setLoader(false);
          const { data } = err.response;

          ErrorModalFiles.open();
          setErrorModalFileMsg(data);
        });
    }

    if (
      (ApplyData && Object.keys(ApplyData).length == 0) ||
      ApplyData == undefined
    ) {
      setDestinationError(true);
    }
    if (
      (SelectedProject && Object.keys(SelectedProject).length == 0) ||
      SelectedProject == undefined
    ) {
      setProjectError(true);
    }
  };

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ErrorModalFilesMail
            isOpen={ErrorModalFiles.isOpen}
            onCloseModal={ErrorModalFiles.close}
            ErrorMsg={ErrorModalFileMsg}
          />
          {BomHierarchyModal.isOpen && (
            <CopyHierarchyBOMModal
              onCloseModal={BomHierarchyModal.close}
              isOpen={BomHierarchyModal.open}
              SelectedProduct={SelectedProject}
              getModalData={getData}
              ApplyHandler={ApplyHandler}
            />
          )}

          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "500",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Copy to Other Part/Assembly
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ padding: "0 1rem 1rem" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: "1rem",
                width: "100%",
              }}
            >
              <Box sx={{ width: "40%" }}>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "0.5rem",
                  }}
                >
                  Copied Part/Assembly
                </Typography>
                <Typography sx={{ fontSize: "1rem", fontWeight: "500" }}>
                  Total vaults getting copied
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: "1rem", marginBottom: "0.5rem" }}>
                  :{" "}
                  {CopyInfoLoader ? (
                    <CircularProgress size={20} />
                  ) : (
                    CopyInfo?.name
                  )}
                </Typography>
                <Typography sx={{ fontSize: "1rem" }}>
                  :{" "}
                  {CopyInfoLoader ? (
                    <CircularProgress size={20} />
                  ) : (
                    CopyInfo?.total_copied
                  )}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                columnGap: "1rem",
                width: "100%",
                margin: "3rem 0",
              }}
            >
              <FormControl fullWidth variant="standard">
                <InputLabel
                  shrink
                  sx={{ color: "primary.main" }}
                  id="demo-simple-select-standard-label"
                >
                  Select Project
                </InputLabel>
                <Autocomplete
                  id="country-select-demo"
                  sx={{ marginTop: "1.2rem" }}
                  options={ProjectList}
                  autoHighlight
                  value={SelectedProject}
                  onChange={(event, newValue) => {
                    ProjectHandleChange(newValue);
                  }}
                  // @ts-ignore
                  getOptionLabel={(option) =>
                    option.name == undefined ? "" : option?.name
                  }
                  renderOption={(props, option) => (
                    <li {...props} style={{ fontSize: "1rem" }}>
                      {/* @ts-ignore */}
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      // placeholder="Select Project"
                      {...params}
                      variant="standard"
                      sx={{
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.light",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                        ".MuiSvgIcon-root": { color: "primary.main" },
                      }}
                    />
                  )}
                />
                {ProjectError ? (
                  <span style={{ color: "red" }}> Please Select Project*</span>
                ) : (
                  ""
                )}
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: "1rem",
                width: "100%",
                marginTop: "2rem",
              }}
            >
              <Typography
                sx={{ fontSize: "1rem", fontWeight: "500", width: "40%" }}
              >
                Select Destination from Hierarchy{" "}
                <sup style={{ color: "red" }}>*</sup>
              </Typography>
              <img
                src={BomIcon}
                style={{
                  height: "1.5rem",
                  cursor: "pointer",
                  opacity:
                  SelectedProject && Object.keys(SelectedProject)?.length > 0
                      ? "1.5"
                      : "0.5",
                }}
                onClick={() =>
                  SelectedProject &&
                  Object.keys(SelectedProject)?.length > 0 &&
                  BOmIconHandler()
                }
              />
              {DestinationError ? (
                <span style={{ color: "red" }}>
                  Please Select Destination from Hierarchy *
                </span>
              ) : (
                ""
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: "1rem",
                width: "100%",
                marginTop: "1rem",
              }}
            >
              <Typography
                sx={{ fontSize: "1rem", fontWeight: "500", width: "40%" }}
              >
                Selected Destination Assembly
              </Typography>
              <Typography sx={{ fontSize: "1rem" }}>
                :{" "}
                {(ApplyData && Object.keys(ApplyData)?.length == 0) ||
                ApplyData == undefined
                  ? "......"
                  : `${ApplyData?.name}  (${ApplyData?.part_no})`}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                columnGap: "1rem",
                width: "100%",
                marginTop: "2rem",
              }}
            >
              <Box>
                {CopyInfo?.is_costing && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Checkboxitem}
                        onClick={(e: any) => CheckboxHandler(e)}
                        sx={{
                          color: "primary.main",
                        }}
                      />
                    }
                    label="With Costing"
                  />
                )}
              </Box>
              <LoadingButton
                loading={Loader}
                variant="contained"
                size="small"
                sx={{
                  marginLeft: "2rem",
                  width: "8rem",
                  "&:hover": {
                    transform: "Scale(1.05)",
                    transition: "transform 0.5s ease",
                  },
                  ".MuiCircularProgress-root": {
                    color: "primary.main",
                  },
                }}
                onClick={() => PasteHandler()}
              >
                Paste
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
