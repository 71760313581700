import { Box, Divider, FormControl, Grid, InputLabel, MenuItem, Modal, Select, Typography } from '@mui/material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { Formik, Field, Form } from "formik";
import React from 'react'
import CancelIcon from '@mui/icons-material/Cancel';


const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: " 50rem",
    height: "fit-content",
    bgcolor: "background.paper",
    borderRadius: "10px",
    p: 1,
  };

function ManualBusinessDataModal({isOpen, onCloseModal, initialValues, onSave}:any) {
  return (
    <Modal
    open={isOpen}
    onClose={onCloseModal}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
     <Box sx={style}>
     <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Add Instrument Calibration
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />


<Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          onSave(values); // Pass updated values back to parent
          onCloseModal(); // Close the modal
        }}
      >
        {({ touched, errors,dirty }) => (
          <Form>
            <DialogContent>
              {/* Prefilled form fields inside the modal */}
          
            

              <Grid container spacing={{
                xs: 1,  // spacing for extra-small screens
                sm: 2,  // spacing for small screens
                md: 2,  // spacing for medium screens
                lg: 2,  // spacing for large screens
                xl: 2   // spacing for extra-large screens
              }}>
                <Grid item xs={12} md={6}>
                  <Field name="business_name">
                    {({ field }: any) => (
                      <TextField
                        {...field}
                        label="Business Name"
                        variant="outlined"
                        fullWidth
                        sx={{fontSize:'1rem',}}
                        error={touched.business_name && !!errors.business_name}
                        helperText={touched.business_name && errors.business_name}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="cin_number">
                    {({ field }: any) => (
                      <TextField
                        {...field}
                        label="CIN Number"
                        variant="outlined"
                        fullWidth
                        sx={{fontSize:'1rem',}}
                        error={touched.cin_number && !!errors.cin_number}
                        helperText={touched.cin_number && errors.cin_number}
                      />
                    )}
                  </Field>
                </Grid>




                <Grid item xs={12} md={6}>
                  <Field name="registration_number">
                    {({ field }: any) => (
                      <TextField
                        {...field}
                        label="Registration Number"
                        variant="outlined"
                        fullWidth
                        sx={{fontSize:'1rem',}}
                        error={touched.registration_number && !!errors.registration_number}
                        helperText={touched.registration_number && errors.registration_number}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
              
                  <Field name="incorporated_date">
                    {({ field }: any) => (
                      <TextField
                        {...field}
                        label="Incorporated Date"
                        variant="outlined"
                        fullWidth
                        sx={{fontSize:'1rem',}}
                        error={touched.incorporated_date && !!errors.incorporated_date}
                        helperText={touched.incorporated_date && errors.incorporated_date}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12} md={6}>
             
                  <Field name="msme_registered">
                    {({ field }: any) => (
                      <FormControl
                        fullWidth
                        variant="outlined"
                        error={
                          touched.msme_registered && !!errors.msme_registered
                        }
                        margin="normal"
                      >
                        <InputLabel id="fmsme-registered-label">
                         MSME Registered
                        </InputLabel>
                        <Select
                          {...field}
                          labelId="fmsme-registered-label"
                          label="MSME Registered"
                        >
                          <MenuItem value="true">Yes</MenuItem>
                          <MenuItem value="false">No</MenuItem>

                          {/* Add more options as needed */}
                        </Select>
                        {/* {touched.msme_registered && errors.msme_registered && (
<FormHelperText>{errors.msme_registered}</FormHelperText>
)} */}
                      </FormControl>
                    )}
                  </Field>
                
                  </Grid>


                <Grid item xs={12} md={6}>

                <Field name="financial_year">
        {({ field }: any) => (
          <FormControl
            fullWidth
            variant="outlined"
            error={touched.financial_year && !!errors.financial_year}
            margin="normal"
          >
            <InputLabel id="financial-year-label">Financial Year</InputLabel>
            <Select
              {...field}
              labelId="financial-year-label"
              label="Financial Year"
            >
             <MenuItem value="2026-27">2026-27</MenuItem>
              <MenuItem value="2025-26">2025-26</MenuItem>
              <MenuItem value="2024-25">2024-25</MenuItem>
              <MenuItem value="2023-24">2023-24</MenuItem>
              <MenuItem value="2022-23">2022-23</MenuItem>
              <MenuItem value="2021-22">2021-22</MenuItem>
              <MenuItem value="2020-21">2020-21</MenuItem>
              <MenuItem value="2019-20">2019-20</MenuItem>
              <MenuItem value="2018-19">2018-19</MenuItem>
              <MenuItem value="2017-18">2017-18</MenuItem>
              <MenuItem value="2016-17">2016-17</MenuItem>
              <MenuItem value="2015-16">2015-16</MenuItem>
              <MenuItem value="2014-15">2014-15</MenuItem>
              {/* Add more options as needed */}
            </Select>
            {/* {touched.financial_year && errors.financial_year && (
              <FormHelperText>{errors.financial_year}</FormHelperText>
            )} */}
                      </FormControl>
                    )}
                  </Field>
                </Grid>






                <Grid item xs={12} md={6}>
                  <Field name="type">
                    {({ field }: any) => (
                      <TextField
                        {...field}
                        label="Type"
                        variant="outlined"
                        fullWidth
                        sx={{fontSize:'1rem',}}
                        error={touched.type && !!errors.type}
                        helperText={touched.type && errors.type}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="status">
                    {({ field }: any) => (
                      <TextField
                        {...field}
                        label="Status"
                        variant="outlined"
                        fullWidth
                        sx={{fontSize:'1rem',}}
                        error={touched.status && !!errors.status}
                        helperText={touched.status && errors.status}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field name="export_turnover">
                    {({ field }: any) => (
                      <TextField
                        {...field}
                        sx={{fontSize:'1rem',}}
                        label="Export Turnover"
                        variant="outlined"
                        fullWidth
                        error={touched.export_turnover && !!errors.export_turnover}
                        helperText={touched.export_turnover && errors.export_turnover}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="gross_turnover">
                    {({ field }: any) => (
                      <TextField
                        {...field}
                        sx={{fontSize:'1rem',}}
                        label="Gross Turnover"
                        variant="outlined"
                        fullWidth
                        error={touched.gross_turnover && !!errors.gross_turnover}
                        helperText={touched.gross_turnover && errors.gross_turnover}
                      />
                    )}
                  </Field>
                </Grid>







                <Grid item xs={12} md={6}>
                  <Field name="pan_number">
                    {({ field }: any) => (
                      <TextField
                        {...field}
                        sx={{fontSize:'1rem',}}
                        label="PAN Number"
                        variant="outlined"
                        fullWidth
                        error={touched.pan_number && !!errors.pan_number}
                        helperText={touched.pan_number && errors.pan_number}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="gst_number">
                    {({ field }: any) => (
                      <TextField
                      sx={{fontSize:'1rem',}}
                        {...field}
                        label="GST Number"
                        variant="outlined"
                        fullWidth
                        error={touched.gst_number && !!errors.gst_number}
                        helperText={touched.gst_number && errors.gst_number}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="address_line">
                    {({ field }: any) => (
                      <TextField
                        {...field}
                        sx={{fontSize:'1rem',}}
                        label="Address"
                        variant="outlined"
                        fullWidth
                        error={touched.address_line && !!errors.address_line}
                        helperText={touched.address_line && errors.address_line}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="address_city">
                    {({ field }: any) => (
                      <TextField
                        {...field}
                        sx={{fontSize:'1rem',}}
                        label="City"
                        variant="outlined"
                        fullWidth
                        error={touched.address_city && !!errors.address_city}
                        helperText={touched.address_city && errors.address_city}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="address_district">
                    {({ field }: any) => (
                      <TextField
                        {...field}
                        sx={{fontSize:'1rem',}}
                        label="District"
                        variant="outlined"
                        fullWidth
                        error={touched.address_district && !!errors.address_district}
                        helperText={touched.address_district && errors.address_district}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="address_state">
                    {({ field }: any) => (
                      <TextField
                      sx={{fontSize:'1rem',}}
                        {...field}
                        label="State"
                        variant="outlined"
                        fullWidth
                        error={touched.address_state && !!errors.address_state}
                        helperText={touched.address_state && errors.address_state}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="address_country">
                    {({ field }: any) => (
                      <TextField
                        {...field}
                        sx={{fontSize:'1rem',}}
                        label="Country"
                        variant="outlined"
                        fullWidth
                        error={touched.address_country && !!errors.address_country}
                        helperText={touched.address_country && errors.address_country}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="address_pincode">
                    {({ field }: any) => (
                      <TextField
                        {...field}
                        sx={{fontSize:'1rem',}}
                        label="Pincode"
                        variant="outlined"
                        fullWidth
                        error={touched.address_pincode && !!errors.address_pincode}
                        helperText={touched.address_pincode && errors.address_pincode}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>

              
            </DialogContent>

            <DialogActions>
              <Button onClick={onCloseModal}>Cancel</Button>
              <Button type="submit" variant="contained" color="primary" disabled={!dirty}>
                Save Changes
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Box>
    </Modal>
  )
}

export default ManualBusinessDataModal