import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Button, Divider, FormControl, Input, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { API } from "../api-services";
import moment from "moment";
import CalibrationFileUpload from "./CalibrationFileUpload";
import CancelIcon from '@mui/icons-material/Cancel';
import styles from './CalibrationModel.module.scss';
import { LoadingButton } from "@mui/lab";
import CalibrationFileS3send from "./CalibrationFileS3send";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: " 50rem",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

interface CalibrationEditModelProps {
  isOpen: any;
  onCloseModal: any;
  reloadCalibrationData: any;
  setReloadCalibrationData: any;
  id: any
  instrument_Description: any;
  date_Of_Purchase: any;
  life_In_Yrs: any;
  calibration_Required: any;
  last_Calibration_Date: any;
  calibration_ExpiryDate: any;
  calibrationReport: any;
  status_get: any;
  ownership_get: any;
  quantityGet: any;
  calibrationImageGet: any;
}

const CalibrationEditModel = (props: CalibrationEditModelProps) => {
  const { isOpen, onCloseModal, reloadCalibrationData, setReloadCalibrationData, id, instrument_Description, date_Of_Purchase, life_In_Yrs, calibration_Required, last_Calibration_Date, calibration_ExpiryDate, calibrationReport, status_get, ownership_get, quantityGet, calibrationImageGet } = props;
  const [instrumentDescription, setInstrumentDescription] = useState<any>();
  const [dateOfPurchase, setDateOfPurchase] = useState<any>();
  const [lifeInYrs, setLifeInYrs] = useState<any>();
  const [calibrationRequired, setCalibrationRequired] = useState<any>();
  const [lastCalibrationDate, setLastCalibrationDate] = useState<any>();
  const [calibrationExpiryDate, setCalibrationExpiryDate] = useState<any>();
  const [inputFileCalibration, setInputFileCalibration] = useState<any>();
  const [quantity, setQuantity] = useState<any>();
  const [inputCalibrationImage, setInputCalibrationImage] = useState<any>();
  const [status, setStatus] = useState<any>();
  const [ownership, setOwnership] = useState<any>();
  const [error, setError] = useState<boolean>(false);
  const [savePopupLoader, setSavePopupLoader] = useState<boolean>(false);
  const sendImageAndReportCalibration = useRef<any>({ value: [] });

  useEffect(() => {
    setInstrumentDescription(instrument_Description);
    setDateOfPurchase(date_Of_Purchase);
    setLifeInYrs(Number(life_In_Yrs));
    setCalibrationRequired(calibration_Required);
    setLastCalibrationDate(last_Calibration_Date == null ? new Date() : last_Calibration_Date);
    setCalibrationExpiryDate(calibration_ExpiryDate == null ? new Date() : calibration_ExpiryDate);
    setStatus(status_get);
    setOwnership(ownership_get);
    setInputFileCalibration(undefined);
    setInputCalibrationImage(undefined);
    setQuantity(Number(quantityGet))
  }, [isOpen])

  const handleAddDuration: any = (e: any) => {
    setLifeInYrs(Number(e.target.value));
  }

  const handleInstrumentDescription: any = (e: any) => {
    setInstrumentDescription(e.target.value);
  }

  const handleChangeCalibrationRequired = (event: SelectChangeEvent) => {
    setCalibrationRequired(event.target.value as string);
  };

  const handleChangeStatus = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string);
  };

  const handleChangeOwnership = (event: SelectChangeEvent) => {
    setOwnership(event.target.value as string);
  };

  const handlerFileChange = (e: any) => {
    setInputFileCalibration(e.target.files)
  }

  const handlerImageChange = (e: any) => {
    setInputCalibrationImage(e.target.files)
  }

  const handleAddQuantity: any = (e: any) => {
    setQuantity(Number(e.target.value));
  }

  const submitInstrumentDetails = async () => {
    if (instrumentDescription == '' || calibrationImageGet == null && inputCalibrationImage == undefined || (calibrationReport == null && calibrationRequired == 'YES' && inputFileCalibration == undefined)) {
      setError(true);
    }
    else {
      setSavePopupLoader(true);
      if (inputFileCalibration && inputCalibrationImage) {
        await CalibrationFileS3send(
          [inputFileCalibration[0], inputCalibrationImage[0]],
          instrumentDescription,
          dateOfPurchase,
          lifeInYrs,
          calibrationRequired,
          lastCalibrationDate,
          calibrationExpiryDate,
          status,
          ownership,
          reloadCalibrationData,
          setReloadCalibrationData,
          onCloseModal,
          "editCalibration",
          setSavePopupLoader,
          id,
          quantity,
          sendImageAndReportCalibration
        )
      }
      else if (inputFileCalibration) {
        CalibrationFileUpload(
          inputFileCalibration,
          instrumentDescription,
          dateOfPurchase,
          lifeInYrs,
          calibrationRequired,
          lastCalibrationDate,
          calibrationExpiryDate,
          status,
          ownership,
          reloadCalibrationData,
          setReloadCalibrationData,
          onCloseModal,
          "editCalibrationReport",
          setSavePopupLoader,
          id,
          quantity
        )
      }
      else if (inputCalibrationImage) {
        CalibrationFileUpload(
          inputCalibrationImage,
          instrumentDescription,
          dateOfPurchase,
          lifeInYrs,
          calibrationRequired,
          lastCalibrationDate,
          calibrationExpiryDate,
          status,
          ownership,
          reloadCalibrationData,
          setReloadCalibrationData,
          onCloseModal,
          "editCalibrationImage",
          setSavePopupLoader,
          id,
          quantity
        )
      }
      else if (calibrationRequired == 'YES') {
        API.put('/xcpep/instrument_calibration/' + id + '/', { instrument_description: instrumentDescription, date_of_purchase: moment(dateOfPurchase).format('YYYY-MM-DD'), instrument_life: lifeInYrs, calibration_required: calibrationRequired == "YES" ? true : false, last_calibration_date: calibrationRequired == 'YES' ? moment(lastCalibrationDate).format('YYYY-MM-DD') : null, calibration_expiry_date: calibrationRequired == 'YES' ? moment(calibrationExpiryDate).format('YYYY-MM-DD') : null, instrument_status: status, ownership: ownership, instrument_quantity: quantity }).then((res: any) => {
          setReloadCalibrationData(!reloadCalibrationData);
          setSavePopupLoader(false);
          onCloseModal();
        })
      }
      else {
        API.put('/xcpep/instrument_calibration/' + id + '/', { instrument_description: instrumentDescription, date_of_purchase: moment(dateOfPurchase).format('YYYY-MM-DD'), instrument_life: lifeInYrs, calibration_required: calibrationRequired == "YES" ? true : false, instrument_status: status, ownership: ownership, instrument_quantity: quantity }).then((res: any) => {
          setReloadCalibrationData(!reloadCalibrationData);
          setSavePopupLoader(false);
          onCloseModal();
        })
      }
    }
  }

  const viewFile = (file: string) => {
    window.open(file)
  };


  return (
    <div>
      <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Edit Instrument Calibration
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />

          <Box sx={{ padding: '0 0.5rem 0.5rem', display: 'flex', flexDirection: 'column', rowGap: '1rem', width: '100%' }}>
            <FormControl fullWidth>
              <TextField
                autoFocus
                InputProps={{
                  style: { color: "#007fff", fontSize: "1rem" },
                }}
                variant="standard"
                InputLabelProps={{ style: { fontSize: "1rem" } }}
                value={instrumentDescription}
                onChange={handleInstrumentDescription}
                sx={{
                  width: "100%",
                  "& .MuiFormLabel-root": {
                    color: "primary.main",
                  },
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "primary.main",
                  },
                  "& .Mui-focused": {
                    color: "primary.main",
                  },
                  "& .Mui-error": {
                    color: "#d32f2f",
                    borderBottomColor: "#d32f2f",
                  },
                  '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                  '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                }}
                label="Instrument Description *"
              />
              {error && instrumentDescription == '' ? <p
                style={{
                  fontSize: "0.75rem",
                  color: "#d32f2f",
                  marginTop: "0.4rem",
                }}
              >
                *Please write instrument description
              </p> : <></>}
            </FormControl>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'baseline', columnGap: '1rem' }}>
              <LocalizationProvider variant="standard" dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label="Date of Purchase *"
                  value={dateOfPurchase}
                  inputFormat="DD/MM/YYYY"
                  maxDate={new Date()}
                  onChange={(newValue) => { return setDateOfPurchase(newValue) }}
                  renderInput={(params) => <TextField {...params} variant="standard" sx={{
                    width: "100%",
                    "& .MuiFormLabel-root": {
                      color: 'primary.main',
                    },
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: 'primary.main'
                    },
                    "& .MuiSvgIcon-root": {
                      color: 'primary.main',
                    },
                    ".MuiInputBase-input.MuiInput-input": {
                      fontSize: '1rem'
                    },
                    "& .Mui-error": {
                      color: '#d32f2f',
                      borderBottomColor: '#d32f2f',
                    },
                    '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                    '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                    '& .MuiInput-underline': { fontSize: '1rem' },
                  }} />}
                />
              </LocalizationProvider>
              <FormControl fullWidth>
                <InputLabel sx={{ color: 'primary.main', marginLeft: '-1rem', marginTop: '-0.45rem' }} shrink>Life in Yrs *</InputLabel>
                <TextField
                  type="number"
                  variant='standard'
                  // label="Life in Yrs *"
                  placeholder="Life in Yrs *"
                  value={lifeInYrs}
                  onChange={handleAddDuration}
                  inputProps={{
                    min: 0,
                    step: 'any',
                    style: { fontSize: '1rem' },
                  }}
                  InputLabelProps={{
                    shrink: true,
                    color: 'primary',
                  }}
                  sx={{
                    width: "100%",
                    fontSize: '1rem',
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "primary.main",
                    },
                    '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                    '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                  }}
                />
              </FormControl>
            </Box>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'baseline', columnGap: '1rem' }}>
              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label-Activity" shrink
                  sx={{ color: "primary.main", marginLeft: '-1rem' }}
                >
                  Calibration Required
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant='standard'
                  value={calibrationRequired}
                  onChange={handleChangeCalibrationRequired}
                  label="Calibration Required"
                  sx={{
                    width: '100%',
                    fontSize: '1rem',
                    "&:before": {
                      borderBottomColor: "primary.light",
                    },
                    '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
                  }}
                >
                  <MenuItem sx={{ fontSize: '1rem', color: 'green' }} value={"YES"}>YES</MenuItem>
                  <MenuItem sx={{ fontSize: '1rem', color: 'red' }} value={"NO"}>NO</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ marginTop: '0.8rem' }}>
                <InputLabel sx={{ color: 'primary.main', marginLeft: '-1rem' }} shrink>Quantity *</InputLabel>
                <TextField
                  type="number"
                  variant='standard'
                  // label="Life in Yrs *"
                  placeholder="Quantity *"
                  value={quantity}
                  onChange={handleAddQuantity}
                  inputProps={{
                    step: 'any',
                    min: 1,
                    style: { fontSize: '1rem', marginTop: '0.4rem' }
                  }}
                  InputLabelProps={{
                    shrink: true,
                    color: 'primary',
                  }}
                  sx={{
                    width: "100%",
                    borderBottomColor: 'primary.light',
                    '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                    '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                  }}
                />
              </FormControl>
            </Box>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'baseline', columnGap: '1rem' }}>
              {calibrationRequired == 'NO' ? <></> : <LocalizationProvider variant="standard" dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label="Last Calibration Date *"
                  value={lastCalibrationDate}
                  inputFormat="DD/MM/YYYY"
                  maxDate={new Date()}
                  onChange={(newValue) => { return setLastCalibrationDate(newValue) }}
                  renderInput={(params) => <TextField {...params} variant="standard" sx={{
                    width: "100%",
                    "& .MuiFormLabel-root": {
                      color: 'primary.main'
                    },
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: 'primary.main'
                    },
                    "& .MuiSvgIcon-root": {
                      color: 'primary.main',
                    },
                    ".MuiInputBase-input.MuiInput-input": {
                      fontSize: '1rem'
                    },
                    "& .Mui-error": {
                      color: '#d32f2f',
                      borderBottomColor: '#d32f2f',
                    },
                    '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                    '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                    '& .MuiInput-underline': { fontSize: '1rem' },
                  }} />}
                />
                <DesktopDatePicker
                  label="Calibration Expiry Date *"
                  value={calibrationExpiryDate}
                  inputFormat="DD/MM/YYYY"
                  minDate={new Date()}
                  onChange={(newValue) => { return setCalibrationExpiryDate(newValue) }}
                  renderInput={(params) => <TextField {...params} variant="standard" sx={{
                    width: "100%",
                    "& .MuiFormLabel-root": {
                      color: 'primary.main'
                    },
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: 'primary.main'
                    },
                    "& .MuiSvgIcon-root": {
                      color: 'primary.main',
                    },
                    ".MuiInputBase-input.MuiInput-input": {
                      fontSize: '1rem'
                    },
                    "& .Mui-error": {
                      color: '#d32f2f',
                      borderBottomColor: '#d32f2f',
                    },
                    '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                    '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                    '& .MuiInput-underline': { fontSize: '1rem' },
                  }} />}
                />
              </LocalizationProvider>}
            </Box>
            {calibrationImageGet && <Box sx={{ width: '100%', display: 'flex', columnGap: '1rem' }}>
              <div style={{ marginBottom: "1rem", width: '100%' }}>
                <div
                  style={{
                    color: "#007fff",
                    fontSize: "0.9rem",
                    // paddingLeft: "0.5rem",
                    lineHeight: "1.4375em",
                    letterSpacing: "0.00938em",
                  }}
                >
                  Previous Uploaded Instrument Image File View
                </div>
                <div
                  onClick={() => viewFile(calibrationImageGet)}
                  style={{
                    cursor: "pointer",
                    marginTop: "0.2rem",
                    textDecoration: "underline",
                  }}
                >
                  {calibrationImageGet && calibrationImageGet}
                </div>
              </div>
            </Box>}
            <Box sx={{ width: '100%', display: 'flex', columnGap: '1rem' }}>
              <FormControl fullWidth>
                <InputLabel style={{ marginLeft: '-14px', marginTop: '0.5rem', color: '#007fff' }} shrink>Instrument Image File</InputLabel>
                <input
                  type="file"
                  // required
                  id="file"
                  className={styles.fileupload}
                  name="file"
                  accept=".pdf,.jpeg,.png,.jpg"
                  onChange={handlerImageChange}
                  style={{ width: '100%', marginTop: '1.5rem' }}
                />
              </FormControl>
              {error && calibrationImageGet == null && inputCalibrationImage == undefined ? <p
                style={{
                  fontSize: "0.75rem",
                  color: "#d32f2f",
                  marginTop: "0.4rem",
                }}
              >
                *Please upload instrument image file view
              </p> : <></>}
            </Box>
            {calibrationRequired == 'NO' ? <></> : calibrationReport && <Box sx={{ width: '100%', display: 'flex', columnGap: '1rem' }}>
              <div style={{ marginBottom: "1rem", width: '100%' }}>
                <div
                  style={{
                    color: "#007fff",
                    fontSize: "0.9rem",
                    // paddingLeft: "0.5rem",
                    lineHeight: "1.4375em",
                    letterSpacing: "0.00938em",
                  }}
                >
                  Previous Uploaded Calibration Report File View
                </div>
                <div
                  onClick={() => viewFile(calibrationReport)}
                  style={{
                    cursor: "pointer",
                    // paddingLeft: "0.5rem",
                    textDecoration: "underline",
                  }}
                >
                  {calibrationReport && calibrationReport}
                </div>
              </div>
            </Box>}
            <Box sx={{ width: '100%', display: 'flex', columnGap: '1rem' }}>
              {calibrationRequired == 'NO' ? <></> : (<>
                <FormControl fullWidth>
                  <InputLabel style={{ marginLeft: '-14px', color: '#007fff' }} shrink>Calibration Report File</InputLabel>
                  <input
                    type="file"
                    // required
                    id="file"
                    className={styles.fileupload}
                    name="file"
                    accept=".pdf,.jpeg"
                    onChange={handlerFileChange}
                    style={{ width: '100%', marginTop: '1rem' }}
                  />
                </FormControl>
              </>)}
              {error && calibrationReport == null && calibrationRequired == 'YES' && inputFileCalibration == undefined ? <p
                style={{
                  fontSize: "0.75rem",
                  color: "#d32f2f",
                  marginTop: "0.4rem",
                }}
              >
                *Please upload calibration report
              </p> : <></>}
            </Box>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'baseline', columnGap: '1rem', marginTop: '0.5rem' }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label-Activity" sx={{ color: "primary.main", marginLeft: '-1rem', }} shrink>
                  Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant='standard'
                  value={status}
                  onChange={handleChangeStatus}
                  sx={{
                    width: '100%',
                    marginTop: '0.5rem !important',
                    "&:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                      fontSize: '1rem'
                    },
                    '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
                  }}
                >
                  <MenuItem sx={{ fontSize: '1rem' }} value={"Working"}>Working</MenuItem>
                  <MenuItem sx={{ fontSize: '1rem' }} value={"Obsolete"}>Obsolete</MenuItem>
                  <MenuItem sx={{ fontSize: '1rem' }} value={"Not-Working"}>Not-Working</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label-Activity" sx={{ color: "primary.main", marginLeft: '-1rem', }} shrink>
                  Ownership
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant='standard'
                  value={ownership}
                  onChange={handleChangeOwnership}
                  sx={{
                    width: '100%',
                    marginTop: '0.5rem !important',
                    "&:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                      fontSize: '1rem'
                    },
                    '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
                  }}
                >
                  <MenuItem sx={{ fontSize: '1rem' }} value={"BOM"}>BOM</MenuItem>
                  <MenuItem sx={{ fontSize: '1rem' }} value={"CO"}>CO</MenuItem>
                  <MenuItem sx={{ fontSize: '1rem' }} value={"ID"}>ID</MenuItem>
                  <MenuItem sx={{ fontSize: '1rem' }} value={"MAF"}>MAF</MenuItem>
                  <MenuItem sx={{ fontSize: '1rem' }} value={"E&E"}>E&E</MenuItem>
                  <MenuItem sx={{ fontSize: '1rem' }} value={"PCB"}>PCB</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ width: '100%', textAlign: 'right', margin: '0.5rem', paddingRight: '1rem' }}>
              <LoadingButton
                onClick={submitInstrumentDetails} loading={savePopupLoader}
                size="small"
                variant="contained"
                sx={{
                  padding: "0.5rem",
                  lineHeight: 1,
                  "&:hover": {
                    transform: 'Scale(1.05)',
                    transition: 'transform 0.5s ease',
                  },
                  '.MuiCircularProgress-root': {
                    color: 'primary.main',
                  },
                }}>
                Save
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div >
  );
};

export default CalibrationEditModel;
