// import { transformConfigurationsFromApi } from "app/constants/configuration.constants";
// import { MODULES } from "app/constants/modules.constants";
// import { SHARING_TYPES } from "app/constants/sharing.constants";
// import { GetBOMConfigsByDatatypeArg } from "app/Redux/Actions/parameterConfig.actions";
// import { ParameterConfig } from "app/Redux/Reducers/parameterConfig.reducer";
import { CancelToken } from "axios";
import { isArray } from "lodash";
import { API } from "../../api-services";
import { ID } from "../../utlis/commonInterfaces";
import { MODULES } from "../../Constants/modules.constants";
import { SHARING_TYPES } from "../../utlis/sharing.constants";
import { transformConfigurationsFromApi } from "../../utlis/configuration.constants";
import { ParameterConfig } from "../Reducers/parameterConfig.reducer";
import { GetBOMConfigsByDatatypeArg } from "../Actions/parameterConfig.actions";

// ---------------------------------------------------------------------------------

export interface ShareTemplateConfigurationsArg {
    /** current top vault, necessary for filtering parameter mappings */
    topVaultId: ID;
    /** top vaults to which the configuration(parameter-mappings) has to shared with */
    targetTopVaultIds: ID[];
    categoryId: ID;
    force:boolean
}

export interface shareFeatureConfigurationArg {
    category: number;
    type: number;
    module: number;
    source: any;
    target_project: ID;

}

export interface shareVehicleArg {
    target_top_vault: any;
    module: number;
    source: any;
    target_project: any;

}
// ---------------------------------------------------------------------------------

const url = "/bom/parameter_mapping/";
const shareUrl = "/bom/share_config/";
const sellUrl = "/sell/parameter_mapping/";
const featureShareUrl = "/config/share_feature/";
const vehicleShareUrl = "config/vehicle_config_share/";
const optionUrl = "/analytics/da_parameter_relation";
const CustomValidation="/config/custom_validation_config/";
// ---------------------------------------------------------------------------------

export default {
    /**
     * @param projectId
     * @param all boolean - get all the configs irrespective of table_id
     */
    getByCategoryId (
        vehCateId: ID,
        categoryId: ID | ID[],
        all?: boolean,
        cancelToken?: CancelToken,
        vaultId?: any,
    ) {
        return API.get(
            url,
            {
                vehicle_category: vehCateId,
                module: vaultId ? "33" : MODULES.TEMPLATE_CONFIGURATION,
                category: isArray( categoryId ) ? undefined : categoryId,
                categories: isArray( categoryId ) ? categoryId.join() : undefined,
                vault: vaultId,
                all,
            },
            0
        );
    },

    get ( topVaultId: ID, all?: boolean ) {
        return API.get( url, {
            top_vault: topVaultId,
            module: MODULES.TEMPLATE_CONFIGURATION,
            all,
        }, 0 );
    },

    //Data analysis dropdown option
    getParameters ( categoryId: ID, project: ID, module: ID ) {
        return API.get( url, {
            category: categoryId,
            project: project,
            module: module,
        }, 0 );
    },
    getCustomValidation(product:any,category:any){
return API.get(CustomValidation,{
    product,category
})
    },

    getParametersOptions ( categoryId: ID, project: ID, module: ID ) {
        return API.get( optionUrl, {
            category: categoryId,
            project: project,
            module: module,
        }, 0 );
    },
    getCombinationOptions ( type: ID ) {
        return API.get( optionUrl, {
            type: type
        }, 0 );
    },
    getByProjectId ( vehCateId: ID, all?: boolean,calculator?:boolean ) {
        return API.get( url, {
            vehicle_category: vehCateId,
            module: MODULES.TEMPLATE_CONFIGURATION,
            all,
            calculator:calculator
        }, 0 );
    },

    getById ( parameterId: ID ) {
        return API.get( url, { id: parameterId }, 0 );
    },

    getByTableId ( tableId: ID ) {
        return API.get( url, { table_id: tableId }, 0 );
    },

    getByDataType ( arg: GetBOMConfigsByDatatypeArg ) {
        return API.get( url, {
            data_type: arg.dataType,
            category: arg.categoryId,
            top_vault: arg.topVaultId,
            module: MODULES.TEMPLATE_CONFIGURATION,
        }, 0 );
    },

    async inherit (
        parameters: Record<string, any>[],
        meta?: Record<string, any>
    ) {
        const { data, ...rest } = await API.post(
            url,
            Object.assign(
                { parameters, module: MODULES.TEMPLATE_CONFIGURATION },
                meta
            )
        );
        return { data: transformConfigurationsFromApi( data ), ...rest };
    },

    update ( configId: any, updates: Partial<ParameterConfig> ) {
        return API.put( url + configId + "/", updates );
    },

    delete ( configId: ID ) {
        return API.delete( url + configId + "/" );
    },

    share ( arg: ShareTemplateConfigurationsArg ) {
        return API.post( shareUrl, {
            category: arg.categoryId,
            module: MODULES.TEMPLATE_CONFIGURATION,
            top_vault: arg.topVaultId,
            target_top_vaults: arg.targetTopVaultIds,
            type: SHARING_TYPES.TEMPLATE_CONFIGURATION,
            force:arg.force
        } );
    },

    shareFeature ( arg: shareFeatureConfigurationArg ) {
        return API.post( featureShareUrl, {
            category: +arg.category,
            module: MODULES.FEATURE_CONFIGURATION,
            source: +arg.source,
            target_project: +arg.target_project,
            type: SHARING_TYPES.FEATURE_CONFIGURATION,
        } );

    },
    shareVehicle ( arg: shareVehicleArg ) {
        return API.post( vehicleShareUrl, {
            source: +arg.source,
            module: MODULES.VEHICLE_INWARDING,
            // target_project: +arg.target_project,
            target_top_vault: arg.target_top_vault
        } );

    }
};
