import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import {
	Checkbox,
	Divider,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Tooltip,
} from '@mui/material';
import {
	DataGrid,
	GridColDef,
	GridCsvExportMenuItem,
	GridCsvExportOptions,
	GridToolbar,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExportContainer,
	GridValueGetterParams,
} from '@mui/x-data-grid';
import { API } from '../../../api-services';
import { LoadingButton } from '@mui/lab';
import { useBaseModal } from '../../SearchPage/useBaseModal';
import ErrorModalFilesMail from '../../ProjectMailModule/ErrorModalFilesMail';


interface InfoModalProps {
	onCloseModal: any;
	isOpen: any;
	CalculatorIds:any;
    SelectedItem:any
	ApplyCostType: any;
    assumptionList:any;
    userInput:any;
	selection:any;
	DataBaseStatus:any;
}
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '80vw',
	// height: '80vh',
	bgcolor: 'background.paper',
	borderRadius: '10px',
	p: 1,
};
const csvOptions: GridCsvExportOptions = {
	fileName: 'Table In CSV',
	delimiter: ',',
	utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
	<GridToolbarExportContainer {...props}>
		<GridCsvExportMenuItem options={csvOptions} />
	</GridToolbarExportContainer>
);

const CustomToolbar = () => (
	<GridToolbarContainer style={{ height: '1.5rem', marginBottom: '0.5rem' }}>
		<span style={{ position: 'absolute', right: '0rem' }}>
			{/* <GridToolbarColumnsButton /> */}
			{/* <GridToolbarDensitySelector /> */}
			<CustomExportButton />
		</span>
	</GridToolbarContainer>
);
const OtherAssumptionSingleCardModal = (props: InfoModalProps) => {
	const {
		onCloseModal,
		isOpen,
		 CalculatorIds,
		ApplyCostType,
        SelectedItem,assumptionList,userInput,selection,DataBaseStatus
	} = props;
	const [DataList, setDataList] = useState<any>([]);
	const [SelectedMaterial, setSelectedMaterial] = useState<any>([]);
	const [SelectedScrap, setSelectedScrap] = useState<any>([]);
	const [loader, setLoader] = useState<any>(false);

	const [MaterialCostType, setMaterialCostType] = useState<any>([]);

	const [ScrapCostType, setScrapCostType] = useState<any>([]);


	const [costData, setCostData] = useState<any>(null)
	const [costScrapData, setCostScrapData] = useState<any>(null)
	const [Count, setCount] = useState<any>(0)
	const [MaterialType, setMaterialType] = useState<any>(null)
	const [revisionIdMaterila, setRevisionIdMaterial] = useState<any>(null)
	const [ScrapType, setScrapType] = useState<any>(null)
	const [NextLoader, setNextLoader] = useState<any>(false)
	const [PreviousLoader, setPreviousLoader] = useState<any>(false)
	const ErrorModalFiles=useBaseModal()
	const [ErrorModalFileMsg, setErrorModalFileMsg] = useState<any>();
	const [revisionIdScrap, setRevisionIdScrap] = useState<any>(null)


	

console.log(DataBaseStatus,"giuegiegiuiugegiuegiugeiufgiu")


	







	const MaterialCheckBoxhandler = (id: any, item: any) => {

		if (id == "min_material_cost") {
			setMaterialType("min")
			let DataListvalue = DataList && DataList[Count]?.min_material_cost
			setCostData(DataListvalue)


			
			API.post(`/cost/update_calculation/`, {

				calculation_id:userInput?.id,

				material_type: "min",




			}).then((res: any) => {


				setMaterialType("min")

				setSelectedMaterial([id])
				// 	setCostData(null)
				// setCostScrapData(null)
				// setScrapCostType([])

			}).catch((err: any) => {
				setNextLoader(false)
				const { data } = err.response;

          ErrorModalFiles.open();
          setErrorModalFileMsg(data);
				console.log("Server Error")
			})



		} else if (id == "max_material_cost") {
			let DataListvalue = DataList && DataList[Count]?.max_material_cost
			setMaterialType("max")
			
			API.post(`/cost/update_calculation/`, {

				calculation_id:userInput?.id,

				material_type: "max",




			}).then((res: any) => {

				setNextLoader(false)

				setMaterialType("max")

				setSelectedMaterial([id])
				// 	setCostData(null)
				// setCostScrapData(null)
				// setScrapCostType([])

			}).catch((err: any) => {
				setNextLoader(false)
				const { data } = err.response;

          ErrorModalFiles.open();
          setErrorModalFileMsg(data);
				console.log("Server Error")
			})
			setCostData(DataListvalue)
		} else if (id == "avg_material_cost") {
			let DataListvalue = DataList && DataList[Count]?.avg_material_cost
			setCostData(DataListvalue)
			setMaterialType("avg")
		
			API.post(`/cost/update_calculation/`, {

				calculation_id:userInput?.id,

				material_type: "avg",




			}).then((res: any) => {

				setNextLoader(false)

				setMaterialType("avg")

				setSelectedMaterial([id])
				// 	setCostData(null)
				// setCostScrapData(null)
				// setScrapCostType([])

			}).catch((err: any) => {
				setNextLoader(false)
				const { data } = err.response;

          ErrorModalFiles.open();
          setErrorModalFileMsg(data);
				console.log("Server Error")
			})
		}
		else {
			setMaterialType("single")
			let DataListValue = item?.material_cost
			let Name = item?.revision_name
			setCostData({ name: Name, value: DataListValue })
			setRevisionIdMaterial(item?.revision_id)
			
			API.post(`/cost/update_calculation/`, {

				calculation_id:userInput?.id,

				material_type: "single",

				revision_id: item?.revision_id,




			}).then((res: any) => {

				setNextLoader(false)

				setMaterialType("single")

				setSelectedMaterial([id])
				// 	setCostData(null)
				// setCostScrapData(null)
				// setScrapCostType([])

			}).catch((err: any) => {
				setNextLoader(false)
				const { data } = err.response;

          ErrorModalFiles.open();
          setErrorModalFileMsg(data);
				console.log("Server Error")
			})

		}
		// const updatedTodos = SelectedMaterial?.map((todo: any, index: any) => {
		// 	if (Object.keys(todo) == item) {
		// 		let filter = Object.values(todo).flat(1);
		// 		if (filter?.includes(id)) {
		// 			return { ...todo, [item]: [] };
		// 		} else {
		// 			return { ...todo, [item]: [id] };
		// 		}
		// 	} else {
		// 		return { ...todo };
		// 	}
		// });

		setSelectedMaterial([id]);

	};

	const ScrapCheckBoxhandler = (id: any, item: any) => {

		if (id == "min_scrap_cost") {
			


			let DataListvalue = DataList && DataList[Count]?.min_scrap_cost
			setCostScrapData(DataListvalue)




			
			API.post(`/cost/update_calculation/`, {

				calculation_id:userInput?.id,

				scrap_type: "min",




			}).then((res: any) => {


				setScrapType("min")

				setSelectedScrap([id])
				// 	setCostData(null)
				// setCostScrapData(null)
				// setScrapCostType([])

			}).catch((err: any) => {
				setNextLoader(false)
				const { data } = err.response;

          ErrorModalFiles.open();
          setErrorModalFileMsg(data);
				console.log("Server Error")
			})



		}




		else if (id == "max_scrap_cost") {
			
			let DataListvalue = DataList && DataList[Count]?.max_scrap_cost
		
			API.post(`/cost/update_calculation/`, {

				calculation_id:userInput?.id,

				scrap_type: "max",




			}).then((res: any) => {


				setScrapType("max")

				setSelectedScrap([id])
				// 	setCostData(null)
				// setCostScrapData(null)
				// setScrapCostType([])

			}).catch((err: any) => {
				setNextLoader(false)
				console.log("Server Error")
				const { data } = err.response;

          ErrorModalFiles.open();
          setErrorModalFileMsg(data);
			})

			setCostScrapData(DataListvalue)
		} else if (id == "avg_scrap_cost") {
			
			let DataListvalue = DataList && DataList[Count]?.avg_scrap_cost
			setCostScrapData(DataListvalue)
			
			API.post(`/cost/update_calculation/`, {

				calculation_id:userInput?.id,

				scrap_type: "avg",




			}).then((res: any) => {


				setScrapType("avg")

				setSelectedScrap([id])
				// 	setCostData(null)
				// setCostScrapData(null)
				// setScrapCostType([])

			}).catch((err: any) => {
				setNextLoader(false)
				const { data } = err.response;

          ErrorModalFiles.open();
          setErrorModalFileMsg(data);
				console.log("Server Error")
			})
		}
		else {
			let DataListValue = item?.scrap_cost
			
			let Name = item?.revision_name
			setCostScrapData({ name: Name, value: DataListValue })
			setRevisionIdMaterial(item?.revision_id)
			
			API.post(`/cost/update_calculation/`, {

				calculation_id:userInput?.id ,

				scrap_type: "single",
				revision_id:item?.revision_id




			}).then((res: any) => {


				setScrapType("single")

				setSelectedScrap([id])
				// 	setCostData(null)
				// setCostScrapData(null)
				// setScrapCostType([])

			}).catch((err: any) => {
				setNextLoader(false)
				console.log("Server Error")
				const { data } = err.response;

          ErrorModalFiles.open();
          setErrorModalFileMsg(data);
			})

		}
		setSelectedScrap([id])
		// const updatedTodos = SelectedScrap?.map((todo: any, index: any) => {
		// 	if (Object.keys(todo) == item) {
		// 		let filter = Object.values(todo).flat(1);
		// 		if (filter?.includes(id)) {
		// 			return { ...todo, [item]: [] };
		// 		} else {
		// 			return { ...todo, [item]: [id] };
		// 		}
		// 	} else {
		// 		return { ...todo };
		// 	}
		// });
		// setSelectedScrap(updatedTodos);
	};



	const CostScrapType = (id: any, item: any, row: any, type: any) => {
		setScrapCostType(item)
		// let updatedCostType = ScrapCostType?.map((rowItem: any, index: any) => {
		// 	if (Object.keys(rowItem) == row?.material_code) {
		// 		let filter: any = Object.values(rowItem)[0];

		// 		if (Object.keys(filter)?.length > 0) {
		// 			if (Object.keys(filter) == type) {
		// 				return { ...rowItem, [row?.material_code]: {} };
		// 			} else {
		// 				return { ...rowItem, [row?.material_code]: { [type]: item } };
		// 			}
		// 		} else {
		// 			return { ...rowItem, [row?.material_code]: { [type]: item } };
		// 		}
		// 	} else {
		// 		return { ...rowItem };
		// 	}
		// });
		// setScrapCostType(updatedCostType);
	};
	// @ts-ignore

    const AssuptionList= assumptionList && assumptionList?.map((item:any)=>{return  item?.id})
console.log(AssuptionList,"cucgucgudgucdgugducgdu")
	useEffect(() => {
		setLoader(true)
		API.get(`/db/material_cost/`, {
			category: SelectedItem?.catgory,
			revision: SelectedItem?.revision_id,
			assumption_list: AssuptionList?.toString(),
			material_list:SelectedItem?.material_id
		}).then((res: any) => {

            // console.log(res?.data,"cucvdvchvjdvcdvjcvdj")
			 setDataList(res?.data);
			setLoader(false)
			

			
		}).catch((err: any) => {

			setLoader(false)
		})
	}, [SelectedItem]);

	

	
	


	const PreviousHandler = () => {
	
			// 	setScrapType(null)
			// setMaterialType(null)
			// setSelectedScrap([])
			// 	setSelectedMaterial([])
			// 	setCostData(null)
			// setCostScrapData(null)
			// setScrapCostType([])

		
		// API.post(,{},0).then((res:any)=>{

		// 	setSelectedScrap([])
		// 	setSelectedMaterial([])
		// 	setCostData(null)
		// setCostScrapData(null)
		// setScrapCostType([])
		// })

	}

console.log(Count,"CountCountCount",DataBaseStatus)

	const ApplyHandler = () => {
		ApplyCostType();
		
	};

	const closedHandler = () => {
		onCloseModal()
		ApplyCostType();
	}

	console.log(SelectedMaterial, "SelectedMaterialSelectedMaterial")



	return (
		<div>
			<Modal open={isOpen}
			// onClose={onCloseModal}
			>



				<Box sx={style}>

				{ ErrorModalFiles.isOpen &&  <ErrorModalFilesMail
            isOpen={ErrorModalFiles.isOpen}
            onCloseModal={ErrorModalFiles.close}
            ErrorMsg={ErrorModalFileMsg}
          />}
					<Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
						<Typography
							style={{
								fontSize: '1rem',
								fontWeight: '600',
								width: '100%',
								textAlign: 'center',
								marginRight: '-2rem',
							}}>
							Select RM Cost
						</Typography>
						<CancelIcon
							titleAccess='Close'
							sx={{ cursor: 'pointer', margin: '0.5rem' }}
							onClick={() => closedHandler()}
						/>
					</Box>
					<Divider
						sx={{
							borderColor: 'primary.light',
							margin: '0 1rem',
							marginBottom: '1rem',
						}}
					/>
					<Box
						sx={{ maxHeight: '65vh', width: '100%' }}
						adl-scrollbar='true'
						adl-scrollbar-width='0.3'>
						{loader ? <>
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
						</>
							:

							<>

								<Typography sx={{ fontSize: '1rem', fontWeight: '600' }}> {1}. Material Code : {DataList && DataList[Count] && DataList[Count].material_code} ({DataList[Count] && DataList[Count]?.other_revision?.length})</Typography>
								<Table
									stickyHeader
									aria-label='sticky table'
									sx={{ minWidth: 650 }}>
									<TableHead
										sx={{ background: '#ddefff !important' }}>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												borderBottomColor: 'primary.light',
												backgroundColor: 'primary.light',
											}}>
											Name
										</TableCell>
										{
											(DataBaseStatus && DataBaseStatus?.is_material==true) &&
										
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												borderBottomColor: 'primary.light',
												backgroundColor: 'primary.light',
											}}>
											Material Cost (INR/kg)
										</TableCell>
}
										{
											(DataBaseStatus && DataBaseStatus?.is_scrap==true) &&
										
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												borderBottomColor: 'primary.light',
												backgroundColor: 'primary.light',
											}}>
											Scrap Cost  (INR/kg)
										</TableCell>
}
									</TableHead>
									<TableBody>
										{DataList && DataList[Count]?.other_revision?.map(
											(item: any, inde: any) => {
												return (
													<TableRow>
														<TableCell
															component='th'
															scope='row'
															sx={{ borderBottom: 'none', padding: '0.2rem 0.5rem' }}>
															{item?.revision_name}
														</TableCell>
														{
															(DataBaseStatus && DataBaseStatus?.is_material==true )&&
														
														<TableCell
															component='th'
															scope='row'
															sx={{
																borderBottom: 'none', padding: '0.2rem 0.5rem',
																borderRigth: '1px solid rgba(224, 224, 224, 1) !important',
															}}>
															{item?.material_cost?.toFixed(2)}

															<Checkbox
																style={{ padding: '3px', margin: '0 1rem 0 0.5rem' }}
																{...label}
																// checked={chekboxvalueMaterial?.includes(
																// 	item?.id
																// )}
																checked={SelectedMaterial && SelectedMaterial?.includes(item?.id)}
																// onChange={(e: any) => MaterialCheckBoxhandler(
																// 	item?.id,
																// 	DataList[0]?.material_code,

																// )} 
																onChange={(e: any) => MaterialCheckBoxhandler(
																	item?.id,
																	item,

																)}
															/>
														</TableCell>
											}
														{
															(DataBaseStatus && DataBaseStatus?.is_scrap==true) &&
														
														<TableCell
															component='th'
															scope='row'
															sx={{ borderBottom: 'none', padding: '0.2rem 0.5rem' }}>
															{item?.scrap_cost?.toFixed(2)}{' '}
															<Checkbox
																style={{ padding: '3px', margin: '0 1rem 0 0.5rem' }}
																{...label}
																// checked={chekboxvalueScrap?.includes(
																// 	item?.id
																// )}
																checked={SelectedScrap && SelectedScrap?.includes(item?.id) ? true : false}
																onChange={(e: any) => ScrapCheckBoxhandler(
																	item?.id,
																	item,

																)}

															/>
														</TableCell>
											}
													</TableRow>
												);
											}
										)}
										<TableRow>
											<TableCell
												sx={{ borderBottom: 'none', padding: '0.2rem 0.5rem' }}></TableCell>
												{
													DataBaseStatus && DataBaseStatus?.is_material==true &&
												
											<TableCell sx={{ borderBottom: 'none', padding: '0.2rem 0.5rem' }}>
												<TableRow sx={{ display: 'flex !important' }}>
													<TableCell
														sx={{
															borderBottom: 'none',
															padding: '0rem !important',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															width: '100%',
														}}>
														<Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap', color: 'green' }}>Minimum  ({DataList && DataList[Count]?.min_material_cost?.toFixed(2)})</Typography>
														<Checkbox
															style={{ padding: '3px', margin: '0 1rem 0 0.5rem', color: 'green' }}
															{...label}
															// checked={datavalue(DataList[0])[0] &&
															// 	datavalue(DataList[0])[0][DataList[0].material_code] &&
															// 	datavalue(DataList[0])[0][DataList[0].material_code]
															// 		?.Min
															// 	? true
															// 	: false}

															checked={SelectedMaterial && SelectedMaterial?.includes("min_material_cost") ? true : false}

															onChange={(e: any) => MaterialCheckBoxhandler(
																"min_material_cost",
																DataList[Count - 1]?.material_code,

															)}
														// onChange={() => CostType(
														// 	DataList[0],
														// 	DataList[0]?.min_material_cost,
														// 	DataList[0],
														// 	'Min'
														// )} 

														/>
													</TableCell>
													<TableCell
														sx={{
															borderBottom: 'none',
															padding: '0rem !important',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															width: '100%',
														}}>
														<Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap', color: '#c95e0d' }}>Average ({DataList && DataList[Count]?.avg_material_cost?.toFixed(2)})</Typography>
														<Checkbox
															style={{ padding: '3px', margin: '0 1rem 0 0.5rem', color: '#c95e0d' }}
															{...label}
															// checked={datavalue(DataList[0])[0] &&
															// 	datavalue(DataList[0])[0][DataList[0].material_code] &&
															// 	datavalue(DataList[0])[0][DataList[0].material_code]
															// 		?.Average
															// 	? true
															// 	: false}

															checked={SelectedMaterial && SelectedMaterial?.includes("avg_material_cost") ? true : false}

															onChange={(e: any) => MaterialCheckBoxhandler(
																"avg_material_cost",
																DataList[Count]?.material_code,

															)}
														// onChange={() => CostType(
														// 	DataList[0],
														// 	DataList[0]?.avg_material_cost,
														// 	DataList[0],
														// 	'Average'
														// )}

														/>
													</TableCell>
													<TableCell
														sx={{
															borderBottom: 'none',
															padding: '0rem !important',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															width: '100%',
														}}>
														<Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap' }}>Maximum ({DataList && DataList[Count]?.max_material_cost?.toFixed(2)})</Typography>
														<Checkbox
															style={{ padding: '3px', margin: '0 1rem 0 0.5rem', color: '#007fff' }}
															{...label}
															// checked={datavalue(DataList[0])[0] &&
															// 	datavalue(DataList[0])[0][DataList[0].material_code] &&
															// 	datavalue(DataList[0])[0][DataList[0].material_code]
															// 		?.Max
															// 	? true
															// 	: false

															// }

															// onChange={() => CostType(
															// 	DataList[0],
															// 	DataList[0]?.max_material_cost,
															// 	DataList[0],
															// 	'Max'
															// )} 

															checked={SelectedMaterial && SelectedMaterial?.includes("max_material_cost") ? true : false}
															onChange={(e: any) => MaterialCheckBoxhandler(
																"max_material_cost",
																DataList[Count]?.material_code,

															)}

														/>
													</TableCell>

												</TableRow>
												<TableRow>
													<TableCell
														colSpan={3}
														sx={{
															background: '#ddefff !important',
															padding: '0.2rem 0',
															width: '100%',
														}}>

														{/* Value :&nbsp;
													{datavalue(DataList[0])[0] &&
														datavalue(DataList[0])[0][DataList[0].material_code] &&
														datavalue(DataList[0])[0][DataList[0].material_code] &&
														Object.values(
															datavalue(DataList[0])[0] &&
															datavalue(DataList[0])[0][DataList[0].material_code] &&
															datavalue(DataList[0])[0][DataList[0].material_code]
														)} */}

														{
															SelectedMaterial && SelectedMaterial?.length > 0 ? <>


																Selected Value:
																{SelectedMaterial && SelectedMaterial?.includes("max_material_cost") ? <span>



																Maximum  <b>{costData && costData?.toFixed(2)} INR/kg</b>
																</span> : SelectedMaterial && SelectedMaterial?.includes("min_material_cost") ? <span>Minimum  <b>{costData && costData?.toFixed(2)} INR/kg</b> </span> : SelectedMaterial && SelectedMaterial?.includes("avg_material_cost") ? <span>Average <b>{costData && costData?.toFixed(2)} INR/kg</b></span> : <span>{costData &&  costData!==undefined && costData?.name}  <b>{costData && costData!==undefined && costData?.value?.toFixed(2)} INR/kg</b></span>


																}
															</> :
																<>
																	Selected Value:
																</>



														}
													</TableCell>
												</TableRow>
											</TableCell>
}

{
	DataBaseStatus && DataBaseStatus?.is_scrap==true &&

											<TableCell sx={{ borderBottom: 'none', padding: '0.2rem 0.5rem' }}>
												<TableRow sx={{ display: 'flex !important' }}>
													<TableCell
														sx={{
															borderBottom: 'none',
															padding: '0rem !important',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															width: '100%',
														}}>
														<Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap', color: 'green' }}>Minimum  ({DataList && DataList[Count]?.min_scrap_cost?.toFixed(2)})</Typography>
														<Checkbox
															style={{ padding: '3px', margin: '0 1rem 0 0.5rem', color: 'green' }}
															{...label}
															// checked={ScrapCostchekboxvalue(DataList[0])[0] &&
															// 	ScrapCostchekboxvalue(DataList[0])[0][DataList[0].material_code] &&
															// 	ScrapCostchekboxvalue(DataList[0])[0][DataList[0].material_code].Min
															// 	? true
															// 	: false}
															// onChange={() => CostScrapType(
															// 	DataList[0],
															// 	DataList[0]?.min_scrap_cost,
															// 	DataList[0],
															// 	'Min'
															// )}

															checked={SelectedScrap && SelectedScrap?.includes("min_scrap_cost") ? true : false}
															onChange={(e: any) => ScrapCheckBoxhandler(
																"min_scrap_cost",
																DataList[Count]?.material_code,

															)}
														/>
													</TableCell>
													<TableCell
														sx={{
															borderBottom: 'none',
															padding: '0rem !important',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															width: '100%',
														}}>
														<Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap', color: '#c95e0d' }}>Average ({DataList && DataList[Count]?.avg_scrap_cost?.toFixed(2)})</Typography>
														<Checkbox
															style={{ padding: '3px', margin: '0 1rem 0 0.5rem', color: '#c95e0d' }}
															{...label}
															// checked={ScrapCostchekboxvalue(DataList[0])[0] &&
															// 	ScrapCostchekboxvalue(DataList[0])[0][DataList[0].material_code] &&
															// 	ScrapCostchekboxvalue(DataList[0])[0][DataList[0].material_code]?.Average
															// 	? true
															// 	: false}
															// onChange={() => CostScrapType(
															// 	DataList[0],
															// 	DataList[0]?.avg_scrap_cost,
															// 	DataList[0],
															// 	'Average'
															// )}

															checked={SelectedScrap && SelectedScrap?.includes("avg_scrap_cost") ? true : false}
															onChange={(e: any) => ScrapCheckBoxhandler(
																"avg_scrap_cost",
																DataList[Count]?.material_code,

															)}

														/>
													</TableCell>
													<TableCell
														sx={{
															borderBottom: 'none',
															padding: '0rem !important',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															width: '100%',
														}}>
														<Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap' }}>Maximum ({DataList && DataList[Count]?.max_scrap_cost?.toFixed(2)})</Typography>
														<Checkbox
															style={{ padding: '3px', margin: '0 1rem 0 0.5rem', color: '#007fff' }}
															{...label}
															// checked={ScrapCostchekboxvalue(DataList[0])[0] &&
															// 	ScrapCostchekboxvalue(DataList[0])[0][DataList[0].material_code] &&
															// 	ScrapCostchekboxvalue(DataList[0])[0][DataList[0].material_code]?.Max
															// 	? true
															// 	: false}
															// onChange={() => CostScrapType(
															// 	DataList[0],
															// 	DataList[0]?.max_scrap_cost,
															// 	DataList[0],
															// 	'Max'
															// )}

															checked={SelectedScrap && SelectedScrap?.includes("max_scrap_cost") ? true : false}
															onChange={(e: any) => ScrapCheckBoxhandler(
																"max_scrap_cost",
																DataList[Count]?.material_code,

															)}


														/>
													</TableCell>
												</TableRow>
												<TableRow>



													<TableCell
														colSpan={3}
														sx={{
															background: '#ddefff !important',
															padding: '0.2rem 0',
															width: '100%',
														}}>
														{/* Value :&nbsp;
													{ScrapCostchekboxvalue(DataList[0])[0] &&
														ScrapCostchekboxvalue(DataList[0])[0][DataList[0].material_code] &&
														Object.values(
															ScrapCostchekboxvalue(DataList[0])[0] &&
															ScrapCostchekboxvalue(DataList[0])[0][DataList[0].material_code]
														)} */
														}

														{
															SelectedScrap && SelectedScrap?.length > 0 ? <>


																Selected Value:
																{SelectedScrap && SelectedScrap?.includes("max_scrap_cost") ? <span>



																Maximum  <b>{costScrapData && costScrapData?.toFixed(2)} INR/kg</b>
																</span> : SelectedScrap && SelectedScrap?.includes("min_scrap_cost") ? <span>Minimum <b>{costScrapData && costScrapData?.toFixed(2)} INR/kg</b></span> : SelectedScrap && SelectedScrap?.includes("avg_scrap_cost") ? <span>Average    <b>{costScrapData && costScrapData?.toFixed(2)} INR/kg</b></span> : <span> {costScrapData && costScrapData?.name} <b>{costScrapData && costScrapData?.value?.toFixed(2)} INR/kg</b></span>


																}
															</> :
																<>
																	Selected Value:
																</>



														}
													</TableCell>

												</TableRow>
											</TableCell>
}
										</TableRow>





									</TableBody>
								</Table></>
						}
					</Box>
					{loader == false &&
						<Box sx={{ display: 'flex', columnGap: '2rem', justifyContent: 'flex-end', alignItems: 'center', width: '100%', padding: '0.5rem' }}>
							<LoadingButton    size='small' variant='contained' onClick={() => closedHandler()}>
								Proceed
							</LoadingButton>

						

						</Box>}
				</Box>
			</Modal>
		</div>
	);
};

export default OtherAssumptionSingleCardModal;
