import {
  Box,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
//@ts-ignore
import { CSVLink } from "react-csv";

export interface ICostingTableSectionProps {
  bomData: any
}

export function CostingTableSection(props: ICostingTableSectionProps) {
  const { bomData } = props;
  const col = [{
    field: "name",
    headerName: "Parameter",
    minWidth: 180,
    flex: 1.5,
  },
  {
    field: "value",
    headerName: "Value",
    minWidth: 100,
    flex: 1.5,
  },
  {
    field: "unit",
    headerName: "Unit",
    minWidth: 100,
    flex: 1.5,
  }
  ]
  const getRow = () => {
    let data: any = [];
    if (bomData) {
      bomData && Object.keys(bomData).map((item: any, index: any) => {
        data.push({ id: index, name: item, value: bomData[item][0]?.val, unit: bomData[item][0]?.unit })
      })
    }
    return data;
  }
  let dataStructureDataGrid: any = {
    columns: col,
    rows: getRow() && getRow(),
  };
  const CustomToolbar = () => (
    <GridToolbarContainer
      style={{
        height: "2rem",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "0.2rem",
        width: "100%",
      }}
    >
      <Typography style={{ color: "red", fontSize: "12px" }}>
      
      </Typography>
      {/* <CustomExportButton />      */}
      <CSVLink
        data={getRow()}
        filename={"product-details-cost.csv"}
        className="btn btn-primary"
        target="_blank"
        sx={{ textDecoration: 'none !important' }}
      >
        <Box
          sx={{
            color: "#007fff",
            display: "flex",
            alignItems: "center",
            columnGap: "10px",
            // textDecoration: "none",
          }}
        >
          <SaveAltIcon sx={{ fontSize: "1.2rem" }} />
          EXPORT
          {/* <GetAppIcon /> */}
        </Box>
      </CSVLink>
      {/* <CSVDownload data={rows} target="_blank" /> */}
    </GridToolbarContainer>
  );
  return (
    <Box
      adl-scrollbar="true"
      adl-scrollbar-width="0.3"
      sx={{ marginTop:'0.5rem',
        height: { lg: '37.5vh', xl: '51vh' },
        "& .MuiDataGrid-columnHeaderTitle": {
          color: '#fff !important',
        },        
        "& .MuiDataGrid-columnHeaders.MuiDataGrid-columnHeaders":{
          backgroundColor: "primary.main",
          // minHeight: '30px !important',
          // maxHeight: '30px !important',
          // lineHeight: '30px !important'
        }
      }}>
      <DataGrid rowHeight={30}
      headerHeight={48}
        {...dataStructureDataGrid}
        //   initialState={{
        //     sorting: {
        //       sortModel: [{ field: "name", sortable: false }],
        //     },
        //   }}
        isRowSelectable={() => false}
        density="compact"
        //@ts-ignore
        hideFooter="true"
        components={{
          Toolbar: () => {
            return CustomToolbar();
          },
        }}

        sx={{
          fontSize: "12px",
          boxShadow: 2,
          border: 2,
          cursor: "pointer",
          ".MuiDataGrid-cell": {
            borderBottomColor: 'primary.light',        
          },
          "& ::-webkit-scrollbar": {
            width: "0.3rem",
            height: "0.3rem",
          },
          "& ::-webkit-scrollbar-thumb": {
            // background: $thumb-color;
            backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
            borderRadius: "10px",
          },
          "& ::- webkit-scrollbar-thumb: hover ": {
            // width: "4px",
            backgroundColor: "#045DE9 !important",
          },
          "::-webkit-scrollbar-track": {
            backgroundColor: "#e1e1f3 !important",
          },

        }}
      />
    </Box>
  );
}
