import React, { useEffect, useState } from 'react'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { API } from '../../api-services';
import { Autocomplete, Chip, Divider, FormControl, InputLabel, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import sucessIcon from "../../Assets/images/success.png";
import { LoadingButton } from '@mui/lab';
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "30vw",
  bgcolor: 'background.paper',
  borderRadius: "0.5rem",
  p: 1,
};



export interface AddCategoryModalProps {
  isOpen: any;
  onCloseModal: any;
  projectId: any;
  selectedData: any;
  setcategoryCount: any;
}

const AddCategoryModal = (props: AddCategoryModalProps) => {
  const { isOpen,
    onCloseModal, projectId, selectedData, setcategoryCount } = props
  const [categoryList, setcategoryList] = useState<any>([])

  const [SelectedCategory, setSelectedCategory] = useState<any>([])
  const [SuccessLoader, setSuccessLoader] = useState<any>(false)
  const [loader, setLoader] = useState<any>(false)


  const handleSelectProduct = (item: any) => {

    setSelectedCategory(item)
  }

  useEffect(() => {

    setSuccessLoader(false)
  }, [])
  const SaveHandler = () => {
    var Ids = selectedData?.map((item: any) => { return item?.id })
    var selectedIds = SelectedCategory && SelectedCategory?.map((item: any, index: any) => { return item?.id })


    var allIds = [...Ids,]
    setLoader(true)

    API.post(`/config/feature_config/`, { category: selectedIds?.toString(), project: projectId }).then((res: any) => {
      setLoader(false)
      setSuccessLoader(true)
      setcategoryCount((prev: any) => prev + 1)
    }).catch((err: any) => {
      setLoader(false)
      setSuccessLoader(false)
      console.log("SErver Error")
    })
  }
  useEffect(() => {

    API.get("/db/category/", { module: 17, project: projectId }).then((res: any) => {
      setcategoryList(res?.data)
    }).catch((err: any) => {
      console.log("server error")
    })
  }, [projectId])




  return (
    <div>

      <Modal
        open={isOpen}

        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {SuccessLoader ?
          <Box sx={style}>
            <Box sx={{ textAlign: 'center', }} >
              <img src={sucessIcon} style={{ width: '7rem' }} />
              <Typography sx={{ color: "green", marginTop: '1rem' }}>Category Added Successfully</Typography>
              <Button sx={{ marginTop: '1rem' }} variant='contained' size='small' onClick={onCloseModal}>ok</Button>
            </Box>
          </Box> :
          <Box sx={style}>
            <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "center",
                  marginRight: '-2rem'
                }}
              >
                Select Category
              </Typography>
              <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
            </Box>
            <Divider
              sx={{
                borderColor: "primary.light",
                margin: "0 1rem",
                marginBottom: "1rem",
              }}
            />
            <Box sx={{ display: "flex", columnGap: '1rem', alignItems: 'center', padding: '0 1rem' }}>
              <Box sx={{ width: '100%' }}>
                <FormControl fullWidth>
                  <InputLabel sx={{ color: 'primary.main', marginLeft: '-1rem' }} shrink>Select Category</InputLabel>
                  <Autocomplete
                    multiple
                    id="multiple-limit-tags"
                    limitTags={3}
                    placeholder="Select vehicle"
                    disableCloseOnSelect
                    //@ts-ignore

                    options={categoryList && categoryList}
                    //@ts-ignore
                    getOptionLabel={(option) => option.abbreviation}
                    // defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
                    sx={{
                      marginTop: '0.5rem',
                      '.MuiSvgIcon-root': { color: 'primary.main' },
                    }}
                    value={SelectedCategory}
                    onChange={(event, newValue, situation) =>
                      handleSelectProduct(newValue)
                    }

                    // @ts-ignore

                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          size="small"
                          sx={{ background: "#007fff14", fontSize: "1rem" }}
                          //@ts-ignore
                          label={option?.name}
                          {...getTagProps({ index })}
                        // disabled={fixedOptions.indexOf(option) !== -1}
                        />
                      ))
                    }
                    renderOption={(props, option, { selected }) => (
                      <li style={{ fontSize: "1rem" }} {...props}>
                        {/*@ts-ignore */}
                        {option?.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // @ts-ignore 
                        // placeholder={"Select category"}
                        variant="standard"
                        sx={{
                          width: '100%',
                          fontSize: "1rem",
                          ".MuiInputBase-root.MuiInput-root": {
                            fontSize: '1rem !important'
                          },
                          '& .MuiInput-underline:before': { borderBottomColor: 'primary.main' },
                          '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Box>
              <Box>
                <LoadingButton
                  loading={loader}
                  sx={{
                    minWidth: '1rem',
                    '.MuiCircularProgress-root': {
                      color: 'primary.main',
                    },
                  }}
                >
                  <SaveIcon sx={{ cursor: "pointer" }} onClick={() => SaveHandler()} />
                </LoadingButton>
              </Box>
            </Box>
          </Box>}
      </Modal>
    </div>
  )
}
export default AddCategoryModal;