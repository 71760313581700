import * as React from "react";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import { useHistory } from "react-router-dom";
import {
  Backdrop,
  Chip,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  makeStyles
} from "@mui/material";
import { API } from "../../api-services";
import { LoadingButton } from "@mui/lab";
import CancelIcon from "@mui/icons-material/Cancel";
import Swal from "sweetalert2";
import successIcon from "../../Assets/images/success.png";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import rightArrIcon from "../../Assets/images/rightArrows.svg";
import ReplyIcon from "@mui/icons-material/Reply";
import AssumptionCardModalDetail from "./AssumptionCardModalDetail";
import { useBaseModal } from "../SearchPage/useBaseModal";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import Skeleton from "@mui/material/Skeleton";
import rightArrow from "../../Assets/images/rightArrows.svg";
import rightArrowBrown from "../../Assets/images/rightArrowBrown.svg";
import { get } from "http";
import { Info } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import { AssumptionUsedDetails } from "../../Configuration/Costing/AssumptionUsedDetails";


const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 0,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IWatermarkModelProps {
  isOpen: any;
  onCloseModal: any;
  // setCounter: any;
}

function AddAssumptionModal(props: IWatermarkModelProps) {
  const { isOpen, onCloseModal } = props;
  const history = useHistory();
  const { topVault, projectId, vault } = useRouteParams<any>();
  const [volume, setVolume] = React.useState<any>("");
  const [revision, setRevision] = React.useState<any>("");
  const [locationList, setLocationList] = React.useState<any>([]);
  const [permission, setPermission] = React.useState<any>(null);
  const [tab, setTab] = React.useState<any>(1);
  const addAssumptionCardModalDetail = useBaseModal();
  const [mainCostCount, setMainCostCount] = React.useState<any>(0);
  const [scenarioCostCount, setScenarioCostCount] = React.useState<any>(0);
  const [assumptionsData, setAssumptionsData] = React.useState<any>([]);
  const [isassumptionLoading, setIsassumptionLoading] =
    React.useState<any>(true);
    const [currentPage, setCurrentPage] = React.useState<any>(0);
  const assumptionUsedDetailsModal = useBaseParamsModal();

  const getAssumptionsData = (tab: any, currentPage : any) => {
    setIsassumptionLoading(true);

    API.get(
      "/cost/assumption_card/",
      {
        top_vault: topVault,
        assumption_type: tab,
        page: currentPage + 1,
      },
      0
    )
      .then((res: any) => {
        console.log(res.data);
        setAssumptionsData(res.data);
        setIsassumptionLoading(false);
      })
      .catch((err: any) => {
        setIsassumptionLoading(false);
      });
  };
  const handleRedirect = (
    item: any,
    name: string,
    id: string,
    revisionId: string
  ): void => {
    // Create a routes object to map the name of the item to the url
    const routes: { [key: string]: string } = {
      "Raw Material": "rm",
      "Bought-Out Parts": "bop",
      "Machine Hour Rate": "machine",
      "Labor Hour Rate": "labor",
      "Tooling Cost DB": "tools",
      Operations: "operations",
    };

    // Create a url string with the revisionId, id, and route name
    // let tabValue = value=="main" ? 1 : 2;
    const url: string = `#/data-base/revision-database/${tab}/${item?.year}/${item?.month}/${revisionId}/${id}/${routes[name]}/0/false/0`;

    // Use the history object to push the url to the application
    window.open(url);
    // history.push(url);
  };

  React.useEffect(() => {
    getAssumptionsData(tab, currentPage);
  }, [currentPage]);
  const columns: any = [
    { field: "revision_name", headerName: "Revision Name", width: 200 },
    {
      field: "name",
      headerName: "Name",
      width: 200,
    },
    {
      field: "volume",
      headerName: "Volume",
      width: 150,
    },
    {
      field: "created_at",
      headerName: "Created At",
      minwidth: 150,
    },
    {
      field: "revised_on",
      headerName: "Revised On",
      minwidth: 150,
    },
    {
      field: "created_by",
      headerName: "Created By",
      width: 122,
    },
    {
      field: "total_cost",
      headerName: "Total Cost",
      width: 80,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (cellValues: any) => {
        return (
          <Box>
            <img
              src={tab == 1 ? rightArrow : rightArrowBrown}
              alt="rightArrow"
              style={{ height: "1.4rem", cursor: "pointer" }}
              onClick={() =>
                handleRedirect(
                  cellValues.row,
                  cellValues.row.data[0].name,
                  cellValues.row.data[0].id,
                  cellValues.row.revision_id
                )
              }
            />
          </Box>
        );
      },
    },

    {
      field: "mh_rate",
      headerName: "MHR",
      width: 80,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (cellValues: any) => {
        return (
          <Box>
            <img
              src={tab == 1 ? rightArrow : rightArrowBrown}
              alt="rightArrow"
              style={{ height: "1.4rem", cursor: "pointer" }}
              onClick={() =>
                handleRedirect(
                  cellValues.row,
                  cellValues.row.data[1].name,
                  cellValues.row.data[1].id,
                  cellValues.row.revision_id
                )
              }
            />
          </Box>
        );
      },
    },
    {
      field: "raw_material",
      headerName: "RM",
      width: 80,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (cellValues: any) => {
        return (
          <Box>
            <img
              src={tab == 1 ? rightArrow : rightArrowBrown}
              alt="rightArrow"
              style={{ height: "1.4rem", cursor: "pointer" }}
              onClick={() =>
                handleRedirect(
                  cellValues.row,
                  cellValues.row.data[2].name,
                  cellValues.row.data[2].id,
                  cellValues.row.revision_id
                )
              }
            />
          </Box>
        );
      },
    },
    {
      field: "lh_rate",
      headerName: "LHR",
      width: 80,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (cellValues: any) => {
        return (
          <Box>
            <img
              src={tab == 1 ? rightArrow : rightArrowBrown}
              alt="rightArrow"
              style={{ height: "1.4rem", cursor: "pointer" }}
              onClick={() =>
                handleRedirect(
                  cellValues.row,
                  cellValues.row.data[3].name,
                  cellValues.row.data[3].id,
                  cellValues.row.revision_id
                )
              }
            />
          </Box>
        );
      },
    },
    {
      field: "bop",
      headerName: "BOP",
      width: 80,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (cellValues: any) => {
        return (
          <Box>
            <img
              src={tab == 1 ? rightArrow : rightArrowBrown}
              alt="rightArrow"
              style={{ height: "1.4rem", cursor: "pointer" }}
              onClick={() =>
                handleRedirect(
                  cellValues.row,
                  cellValues.row.data[5].name,
                  cellValues.row.data[5].id,
                  cellValues.row.revision_id
                )
              }
            />
          </Box>
        );
      },
    },
    {
      field: "operations",
      headerName: "Operations",
      width: 80,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (cellValues: any) => {
        return (
          <Box>
            <img
              src={tab == 1 ? rightArrow : rightArrowBrown}
              alt="rightArrow"
              style={{ height: "1.4rem", cursor: "pointer" }}
              onClick={() =>
                handleRedirect(
                  cellValues.row,
                  cellValues.row.data[4].name,
                  cellValues.row.data[4].id,
                  cellValues.row.revision_id
                )
              }
            />
          </Box>
        );
      },
    },
    {
      field: "currency",
      headerName: "Currency",
      width: 80,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (cellValues: any) => {
        return (
          <Box>
            <img
              src={tab == 1 ? rightArrow : rightArrowBrown}
              alt="rightArrow"
              style={{ height: "1.4rem", cursor: "pointer" }}
              onClick={() =>
                handleRedirect(
                  cellValues.row,
                  cellValues.row.data[6].name,
                  cellValues.row.data[6].id,
                  cellValues.row.revision_id
                )
              }
            />
          </Box>
        );
      },
    },
    {
      field: "action",
      headerName: "Usage info",
      sortable: false,
      width: 80,
      headerAlign: "center",
      align: "center",
      renderCell: (cellValues: any) => {
        return (
          <Box>
            <InfoIcon
              sx={{ cursor: "pointer" }}
              onClick={() =>
                assumptionUsedDetailsModal.open(cellValues?.row?.id)
              }
            />
          </Box>
        );
      },
    },
  ];

  const getTotalItemsCount = (tab: any) => {
    API.get("/cost/assumption_card/", {
      top_vault: topVault,
      page_count: true,
      assumption_type: tab,
    }).then((res: any) => {
      if (tab === 1) {
        setMainCostCount(res.data.total_count);
      } else {
        setScenarioCostCount(res.data.total_count);
      }
    });
  };

  const handleTabChange = (type: string) => {
    if (type === "main") {
      getAssumptionsData(1, 0);
      setCurrentPage(0);
      setTab(1);
    } else {
      
      getAssumptionsData(2, 0);
      setCurrentPage(0);
      setTab(2);
    }
  };

  const csvOptions: GridCsvExportOptions = {
    fileName: "Revision Table In CSV",
    delimiter: ",",
    utf8WithBom: true,
  };
  
  const CustomExportButton = (props: ButtonProps) => (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
    </GridToolbarExportContainer>
  );
  
  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{ height: "2.5rem", justifyContent: "flex-end", paddingRight: "1rem" }}
    >
        <GridToolbarColumnsButton />
        <CustomExportButton />
    </GridToolbarContainer>
  );
  
  const getRevisionPermission = () => {
    API.get(
      "auth/user_permission_module/",
      {
        action: true,
        database: true,
        module: "DB-Revision Scenario",
      },
      0
    )
      .then((res: any) => {
        setPermission(res.data?.action);
      })
      .catch((err: any) => {});
  };

  React.useEffect(() => {
    getRevisionPermission();
    getTotalItemsCount(1);
    getTotalItemsCount(2);
  }, [topVault, projectId, vault]);
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "volume") {
      setVolume(value);
    } else {
      setRevision(value);
    }
  };

  const getLocationsName = () => {
    API.get(
      "cost/assumption_card/",
      {
        revision_type: 2,
        project: projectId,
      },
      0
    )
      .then((res: any) => {
        setLocationList(res.data);
      })
      .catch((err: any) => {});
  };
  React.useEffect(() => {
    getLocationsName();
  }, []);

  

  

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          {addAssumptionCardModalDetail.isOpen && (
            <AssumptionCardModalDetail
              isOpen={addAssumptionCardModalDetail.isOpen}
              onCloseModal={addAssumptionCardModalDetail.close}
              tab={tab}
              // data={addAssumptionCardModalDetail.data}
            />
          )}
          {permission &&
          locationList?.length === 0 &&
          !permission?.includes("C") ? (
            <Box sx={style}>
              <Box
                sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}
              >
                <Typography
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    color: "red",
                    width: "100%",
                    textAlign: "center",
                    marginRight: "-2rem",
                    marginBottom: "2rem",
                  }}
                >
                  Please contact the ASI team as no revision has been created.{" "}
                  <sup style={{ color: "red" }}>*</sup>
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box sx={style}>
              <Box
                sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}
              >
                <Typography
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    width: "100%",
                    textAlign: "center",
                    marginRight: "-14rem",
                  }}
                >
                  Add Assumption
                </Typography>
                {permission && permission?.includes("C") && (
                  <Button
                    // variant="contained"
                    size="small"
                    endIcon={
                      <ReplyIcon
                        sx={{
                          marginTop: "-0.1rem",
                          transform: "scaleX(-1)",
                          color: "primary.main",
                          marginLeft: "-0.5rem",
                        }}
                      />
                    }
                    sx={{
                      marginRight: "1rem",
                      whiteSpace: "nowrap",
                      padding: "0.2rem 0.5rem",
                    }}
                    onClick={() =>
                      window.open(
                        "/#/data-base/revision-database/2/0/0/0/0/0/0/true/0"
                      )
                    }
                  >
                    Create Revision
                  </Button>
                )}
                <CancelIcon
                  titleAccess="Close"
                  sx={{ cursor: "pointer", margin: "0.5rem" }}
                  onClick={onCloseModal}
                />
              </Box>
              <Divider
                sx={{
                  borderColor: "primary.light",
                  margin: "0 1rem",
                  marginBottom: "1rem",
                }}
              />
              <Box
                sx={{
                  padding: " 1rem 2rem",
                  // textAlign: "center",
                  display: "flex",
                  alignItems: "baseline",
                  columnGap: "1rem",
                }}
              >
                <FormControl fullWidth>
                  <InputLabel
                    shrink
                    sx={{ color: "primary.main", marginLeft: "-1rem" }}
                    id="demo-simple-select-label"
                  >
                    Select Revision
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    variant="standard"
                    label="Type"
                    value={revision}
                    name="revision"
                    sx={{
                      width: "100%",
                      fontSize: "1rem",
                      "&:before": {
                        borderBottomColor: "primary.light",
                      },
                      ".MuiSvgIcon-root.MuiSelect-icon": {
                        color: "primary.main",
                      },
                    }}
                    onChange={handleChange}
                  >
                    {locationList &&
                      locationList?.map((item: any) => {
                        return (
                          <MenuItem sx={{ fontSize: "1rem" }} value={item.id}>
                            {item.revision_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                <FormControl fullWidth variant="standard">
                  <InputLabel
                    shrink
                    sx={{ color: "primary.main" }}
                    id="demo-simple-select-label"
                  >
                    Volume
                  </InputLabel>
                  <TextField
                    variant="standard"
                    name="volume"
                    type="number"
                    // placeholder="Volume"
                    value={volume || ""}
                    onChange={handleChange}
                    sx={{
                      width: "100%",
                      marginTop: "1rem",
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                    }}
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  padding: "0.5rem 1rem",
                  columnGap: { lg: "1rem", xl: "2rem" },
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "end",
                      columnGap: "1rem",
                      padding: "0.5rem 0",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: "0.5rem",
                        width: { lg: "81%", xl: "83%" },
                        borderBottom: "1px solid",
                      }}
                    >
                      {mainCostCount > 0 && (
                        <Box
                          sx={{
                            color: "#007fff",
                            height: "2rem",
                            minHeight: "2.1rem",
                            maxWidth: "100% !important",
                            padding: "0",
                            fontSize: "1rem",
                            width: "100%",
                            backgroundColor: "primary.light",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#007fff",
                              height: "2rem",
                              minHeight: "2.1rem",
                              maxWidth: "100% !important",
                              padding: "0",
                              fontSize: "1rem",
                              width: "100%",
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={() => handleTabChange("main")}
                          >
                            Main Costing{" "}
                            {mainCostCount > 0 && (
                              <span style={{ marginLeft: "0.5rem" }}>
                                ({mainCostCount})
                              </span>
                            )}
                          </Typography>
                        </Box>
                      )}
                      {scenarioCostCount > 0 && (
                        <Box
                          sx={{
                            color: "#833406",
                            height: "2rem",
                            minHeight: "2.1rem",
                            maxWidth: "100% !important",
                            padding: "0",
                            fontSize: "1rem",
                            width: "100%",
                            backgroundColor: "#faebd7",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#833406",
                              height: "2rem",
                              minHeight: "2.1rem",
                              maxWidth: "100% !important",
                              padding: "0",
                              fontSize: "1rem",
                              width: "100%",
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={() => handleTabChange("scenario")}
                          >
                            Scenario Costing{" "}
                            {scenarioCostCount > 0 && (
                              <span style={{ marginLeft: "0.5rem" }}>
                                ({scenarioCostCount})
                              </span>
                            )}
                          </Typography>
                        </Box>
                      )}
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        
                      }}
                    >
                      <LoadingButton
                        size="small"
                        disabled={
                          revision === "" || volume === "" ? true : false
                        }
                        variant="contained"
                        sx={{ marginTop: "1rem" }}
                        onClick={() => {
                          API.post(
                            "/cost/assumption/",
                            {
                              top_vault: topVault,
                              project: projectId,
                              revision_id: revision,
                              volume: volume,
                              assumption_type: 2,
                            },
                            {},
                            0
                          )
                            .then((res: any) => {
                              Swal.fire({
                                html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
          
                <br />
          
                <p style="color:#007fff;">Assumption card created successfully!</p>   
          
                 </div>`,
                              });
                              onCloseModal();
                            })
                            .catch((err: any) => {
                              Swal.fire({
                                icon: "error",
                                html: `<div>
          
                <br />
          
                <p style="color:#007fff;">Assumption card creation failed!</p>   
          
                 </div>`,
                              });
                            });
                        }}
                      >
                        Create
                      </LoadingButton>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    height: 400,
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "& .main": {
                      color: "#007fff",
                      // backgroundColor: "primary.light",
                    },
                    "& .scenario": {
                      color: "#833406",
                      // backgroundColor: "#faebd7",
                    },
                  }}
                >
                  {isassumptionLoading ? (
                    <Box sx={{ width: "100%", padding: "0" }}>
                      <Skeleton sx={{ height: "4rem", width: "100%" }} />
                      <Skeleton sx={{ height: "4rem", width: "100%" }} />
                      <Skeleton sx={{ height: "4rem", width: "100%" }} />
                      <Skeleton sx={{ height: "4rem", width: "100%" }} />
                      <Skeleton sx={{ height: "4rem", width: "100%" }} />
                      <Skeleton sx={{ height: "4rem", width: "100%" }} />
                    </Box>
                  ) : (
                    <DataGrid
                      rows={assumptionsData}
                      columns={columns}
                      page= {currentPage}
                      components={{
                        Toolbar: () => {
                          return CustomToolbar();
                        },
                      }}
                      density={"compact"}
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                      paginationMode="server"
                      disableSelectionOnClick
                      rowCount={tab == 1 ? mainCostCount : scenarioCostCount}
                      // rows={assumptionsData}
                      getCellClassName={() => {
                        return tab == 1 ? "main" : "scenario";
                      }}
                      onPageChange={(params: any) => {
                        setCurrentPage(params);
                      }}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
      <Box sx={style}>
        {assumptionUsedDetailsModal.isOpen && (
          <AssumptionUsedDetails
            isOpen={assumptionUsedDetailsModal.isOpen}
            onCloseModal={assumptionUsedDetailsModal.close}
            item={assumptionUsedDetailsModal.propsId}
          />
        )}
      </Box>
    </div>
  );
}
export default AddAssumptionModal;
