import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import {
  CellNameOne,
  CellNameSecond,
  CellNameThird,
} from "./ParameterHeaderType";
import { useHistory, useParams } from "react-router-dom";
import { API } from "../../api-services";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import swal from "sweetalert";
import InfoIcon from "@mui/icons-material/Info";
import { Button, ButtonProps, TextField } from "@mui/material";
import { AddParameterDataBaseModal } from "./AddParameterDataBaseModal";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TableChartIcon from "@mui/icons-material/TableChart";
import AddIcon from "@mui/icons-material/Add";
import { TableParameterModal } from "./TableParameterModal";
import { BOMConfigCalculator } from "./BOMCalculatorModal";
import SearchIcon from "@mui/icons-material/Search";
import { ParameterlibraryInfoModal } from "./ParameterlibraryInfoModal";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import { darken, lighten, styled } from "@mui/material/styles";
import { userSelectors } from "../../Redux/Reducers/user.reducer";
import { useSelector } from "react-redux";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Swal from "sweetalert2";

const getBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .super-app-theme--Open": {
    backgroundColor: getBackgroundColor(
      theme.palette.info.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode
        ),
      },
    },
  },
  "& .super-app-theme--Filled": {
    backgroundColor: getBackgroundColor(
      theme.palette.success.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.success.main,
          theme.palette.mode
        ),
      },
    },
  },
  "& .super-app-theme--PartiallyFilled": {
    backgroundColor: getBackgroundColor(
      theme.palette.warning.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.warning.main,
          theme.palette.mode
        ),
      },
    },
  },
  "& .super-app-theme--Rejected": {
    backgroundColor: getBackgroundColor(
      theme.palette.error.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode
        ),
      },
    },
  },
}));

export interface ParameterTableProps {
  setParentsCounter: any;
  TableCounter: any;
  setRecyclebinCounter: any;
  useraction?: any;
}
const csvOptions: GridCsvExportOptions = {
  fileName: "Table In CSV",
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: "1.5rem", marginBottom: "0.5rem" }}>
    <span style={{ position: "absolute", right: "0rem" }}>
      {/* <GridToolbarColumnsButton /> */}
      {/* <GridToolbarDensitySelector /> */}
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);
export const ParameterTable = (props: ParameterTableProps) => {
  const { setParentsCounter, TableCounter, setRecyclebinCounter } = props;
  const { datatype, search } = useParams<any>();
  const [TableRows, setTableRows] = useState<any>([]);
  const [loader, setLoader] = useState<any>(false);
  const [Counter, setCounter] = useState<any>(0);
  const [PageCount, setPageCount] = useState<any>();
  const [count, setCount] = useState<any>(1);
  const parameterModal = useBaseParamsModal();
  const [rows, setrows] = useState<any>();
  const tableModal = useBaseParamsModal();
  const calculatorModal = useBaseModal();
  const infoModal = useBaseModal();
  const [searchvalue, setsearchvalue] = useState<any>(null);
  const [EditCounter, setEditCounter] = useState<any>(0);
  const [searchkey, setSearchkey] = useState<any>(null);
  const { height, width } = useWindowDimensions();
  const history = useHistory<any>();
  const EditHandler = (Item: any) => {
    parameterModal.open(Item);
    setrows(Item);
  };
  const userProfile = useSelector(userSelectors.selectAll);

  // console.log(userProfile, 'userprofile');
  useEffect(() => {
    API.get("db/parameter_page_count/", {
      data_type: datatype,
    }).then((res: any) => {
      setPageCount(res?.data?.page_count);
    });
  }, [datatype]);

  useEffect(() => {
    setsearchvalue(datatype == "0" || datatype == 0 ? null : search);
  }, [search, datatype]);

  // Paginaion for the table
  const PrveCount = () => {
    if (count > 1) {
      setCount((prev: any) => prev - 1);
    }
  };
  const NextCount = () => {
    if (count < PageCount) {
      setCount((prev: any) => prev + 1);
    }
  };
  React.useEffect(() => {
    setCount(1);
  }, [PageCount]);

  const TableHandler = (id: any) => {
    tableModal.open(id);
  };

  const CalculatorHandler = (id: any) => {
    calculatorModal.open();
  };

  const DeleteHanlder = (id: any) => {
    swal({
      title: "Delete",
      text: "Are sure you want to delete ?",
      buttons: ["Cancel", "Ok"],
      // icon: "sucess",
    }).then((confirm: any) => {
      if (confirm) {
        API.delete(`/db/parameter/${id}/`, { trash: true }).then((res: any) => {
          setCounter((prev: any) => prev + 1);
          setRecyclebinCounter((prev: any) => prev + 1);
          setParentsCounter((prev: any) => prev + 1);
        });
      }
    });
  };

  const InfoHandler = (item: any) => {
    infoModal?.open();
    setrows(item);
  };
  const handlechange = (event: any) => {
    setsearchvalue(event?.target.value);
    if (event?.target.value.length == 0) {
      setsearchvalue(null);
      setSearchkey(null);
      setLoader(true);
      history.push(`/data-base/Parameter-library/${datatype}/0`);
    }
  };

  const handlechangeDown = (e: any) => {
    if (e?.key == "Enter") {
      if (searchvalue !== null) {
        setSearchkey(searchvalue);
        history.push(`/data-base/Parameter-library/${datatype}/${searchvalue}`);
      }
    }
  };

  const SearchHandler = () => {
    if (searchvalue != null) {
      setSearchkey(searchvalue);
      history.push(`/data-base/Parameter-library/${datatype}/${searchvalue}`);
    }
  };

  const lockHandler = (rows: any) => {
    Swal.fire({
      title: rows?.lock == true ? "Unlock" : "Lock",
      text:
        rows?.lock == true
          ? "Are you sure you want to Unlock this parameter?"
          : "Are you sure you want to lock this parameter?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        API.put(`/db/parameter/${rows?.id}/`, {
          lock: rows?.lock == true ? false : true,
        }).then((res: any) => {
          // retruve data
          API.get(`/db/parameter/`, {
            data_types: datatype,
            key: count - 1,
          })
            .then((res: any) => {
              setEditCounter((prev: any) => prev + 1);
              setLoader(false);
            })
            .catch((err: any) => {
              console.log("Server Error");
              setLoader(false);
            });
        });
      }
    });
  };
  useEffect(() => {
    setLoader(true);
    setTableRows([]);
    if (search == "0" || searchvalue == 0) {
      API.get(`/db/parameter/`, {
        data_types: datatype,
        key: count - 1,
      })
        .then((res: any) => {
          setTableRows(res?.data);
          setLoader(false);
        })
        .catch((err: any) => {
          console.log("Server Error");
          setLoader(false);
        });
    } else {
      API.get(`db/parameter/`, {
        search_key: search?.trim(),
      })
        .then((res: any) => {
          setTableRows(res?.data);
          setLoader(false);
        })
        .catch((err: any) => {
          console.log("Server Error");
          setLoader(false);
        });
    }
  }, [datatype, Counter, count, TableCounter, EditCounter, search]);

  var RowData =
    TableRows &&
    TableRows?.map((item: any, index: any) => ({
      ...item,
      Ids: index + 1 + count * 20 - 20,
    }));

  const SearchColumsFiled = [
    { field: "id", hide: true },
    { field: "Ids", headerName: "Sl No.", width: 50 },
    {
      field: "name",
      headerName: "Parameter Name",
      width: width > 1400 ? 250 : 200,
    },
    {
      field: "data_type",
      headerName: "Data Type",
      width: width > 1400 ? 100 : 100,
    },
    {
      field: "description",
      headerName: "Description",
      width: width > 1400 ? 100 : 100,
      renderCell: (cellValues: any) => {
        return (
          <>
            {cellValues.row.description == null
              ? "-"
              : cellValues.row.description}
          </>
        );
      },
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },
    {
      field: "unit",
      headerName: "Unit",
      width: width > 1400 ? 100 : 100,
      renderCell: (cellValues: any) => {
        return <>{cellValues.row.unit == "" ? "-" : cellValues.row.unit}</>;
      },
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },
    {
      field: "exceptions",
      headerName: "Exceptions",
      width: width > 1400 ? 100 : 100,
      renderCell: (cellValues: any) => {
        return (
          <>
            {cellValues.row.exceptions?.length == 0
              ? "-"
              : cellValues?.row?.exceptions.toString()}
          </>
        );
      },
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },
    {
      field: "allowed_values",
      headerName: "Allowed Values",
      width: width > 1400 ? 100 : 100,
      renderCell: (cellvalues: any) => {
        return (
          <>
            {cellvalues.row.allowed_values?.length == 0
              ? "-"
              : cellvalues?.row?.allowed_values.toString()}
          </>
        );
      },
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },
    {
      field: "not_allowed_values",
      headerName: "Prohibited Values",
      width: width > 1400 ? 100 : 100,
      renderCell: (cellvalues: any) => {
        return (
          <>
            {cellvalues.row.not_allowed_values?.length == 0
              ? "-"
              : cellvalues?.row?.not_allowed_values.toString()}
          </>
        );
      },
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },

    {
      field: "default_value",
      headerName: "Default Values",
      width: width > 1400 ? 100 : 100,
      renderCell: (cellvalues: any) => {
        return (
          <>
            {cellvalues.row.default_value == ""
              ? "-"
              : cellvalues?.row?.default_value}
          </>
        );
      },
    },
    {
      field: "info_doc",
      headerName: "Guideline",
      width: width > 1400 ? 100 : 100,
      renderCell: (cellvalues: any) => {
        return (
          <>
            {cellvalues.row.info_doc == null ? (
              "-"
            ) : (
              <VisibilityIcon
                sx={{ cursor: "pointer" }}
                onClick={() => window.open(cellvalues?.row?.info_doc, "_blank")}
              />
            )}
          </>
        );
      },
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },
    {
      field: "mapped_status",
      headerName: "Status",
      width: width > 1400 ? 100 : 100,

      renderCell: (cellValues: any) => {
        return <>{cellValues.row.mapped_status ? "In Use" : "Not Used"}</>;
      },
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },
    {
      field: "created_by",
      headerName: "Created By",
      width: width > 1400 ? 100 : 100,
      renderCell: (cellValues: any) => {
        return (
          <>
            {cellValues.row.created_by == null
              ? "-"
              : cellValues.row.created_by}
          </>
        );
      },
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },
    {
      field: "updated_on",
      headerName: "Last Updated On",
      width: width > 1400 ? 100 : 100,
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },
    {
      field: "actions",
      headerName: "Action",
      width: width > 1400 ? 100 : 100,
      renderCell: (cellvalues: any) => {
        return (
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: "1rem" }}
          >
            {cellvalues?.row?.editable === true &&
            cellvalues.row.lock != true ? (
              <EditIcon
                titleAccess="Edit"
                sx={{ cursor: "pointer" }}
                onClick={() => EditHandler(cellvalues?.row)}
              />
            ) : (
              <EditIcon titleAccess="Edit" sx={{ opacity: "0.5" }} />
            )}
            {cellvalues?.row?.mapped_status ? (
              <InfoIcon
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                    InfoHandler(cellvalues?.row);
                }}
              />
            ) : (
              <DeleteIcon
                onClick={() => {
                  if (cellvalues.row.lock != true) {
                    DeleteHanlder(cellvalues?.row?.id);
                  }
                }}
                titleAccess="Delete"
                sx={{
                  cursor: "pointer",
                  opacity: cellvalues.row.lock == true ? "0.5" : "",
                }}
              />
            )}
            {userProfile[0]?.is_adminuser == true &&
            cellvalues.row.lock == true ? (
              <LockIcon
                sx={{ cursor: "pointer",color:'green' }}
                onClick={() => lockHandler(cellvalues?.row)}
              />
            ) : userProfile[0]?.is_adminuser == false &&
              cellvalues.row.lock == true ? (
              <LockIcon sx={{ opacity: "0.5" }} />
            ) : userProfile[0]?.is_adminuser == false &&
              cellvalues.row.lock == false ? (
              ""
            ) : (
              <LockOpenIcon
                sx={{ cursor: "pointer",color:'red' }}
                onClick={() => lockHandler(cellvalues?.row)}
              />
            )}
          </Box>
        );
      },
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },
  ];

  const columnsFiled = CellNameOne.includes(datatype)
    ? [
        { field: "id", hide: true },
        { field: "Ids", headerName: "Sl No." },
        {
          field: "name",
          headerName: "Parameter Name",
          width: width > 1400 ? 300 : 200,
        },
        {
          field: "description",
          headerName: "Description",
          width: width > 1400 ? 200 : 130,
          renderCell: (cellValues: any) => {
            return (
              <>
                {cellValues.row.description == null
                  ? "-"
                  : cellValues.row.description}
              </>
            );
          },
        },
        {
          field: "unit",
          headerName: "Unit",
          width: width > 1400 ? 100 : 80,
          renderCell: (cellValues: any) => {
            return <>{cellValues.row.unit == "" ? "-" : cellValues.row.unit}</>;
          },
        },
        {
          field: "exceptions",
          headerName: "Exceptions",
          width: width > 1400 ? 100 : 100,
          renderCell: (cellValues: any) => {
            return (
              <>
                {cellValues.row.exceptions?.length == 0
                  ? "-"
                  : cellValues?.row?.exceptions.toString()}
              </>
            );
          },
        },
        {
          field: "allowed_values",
          headerName: "Allowed Values",
          width: width > 1400 ? 110 : 100,
          renderCell: (cellvalues: any) => {
            return (
              <>
                {cellvalues.row.allowed_values?.length == 0
                  ? "-"
                  : cellvalues?.row?.allowed_values.toString()}
              </>
            );
          },
        },
        {
          field: "not_allowed_values",
          headerName: "Prohibited Values",
          width: width > 1400 ? 120 : 100,
          renderCell: (cellvalues: any) => {
            return (
              <>
                {cellvalues.row.not_allowed_values?.length == 0
                  ? "-"
                  : cellvalues?.row?.not_allowed_values.toString()}
              </>
            );
          },
        },

        {
          field: "default_value",
          headerName: "Default Values",
          width: width > 1400 ? 120 : 100,
          renderCell: (cellvalues: any) => {
            return (
              <>
                {cellvalues.row.default_value == ""
                  ? "-"
                  : cellvalues?.row?.default_value}
              </>
            );
          },
        },
        {
          field: "info_doc",
          headerName: "Guideline",
          width: width > 1400 ? 100 : 100,
          renderCell: (cellvalues: any) => {
            return (
              <>
                {cellvalues.row.info_doc == null ? (
                  "-"
                ) : (
                  <VisibilityIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open(cellvalues?.row?.info_doc, "_blank")
                    }
                  />
                )}
              </>
            );
          },
        },
        {
          field: "mapped_status",
          headerName: "Status",
          width: width > 1400 ? 90 : 90,

          renderCell: (cellValues: any) => {
            return <>{cellValues.row.mapped_status ? "In Use" : "Not Used"}</>;
          },
        },
        {
          field: "created_by",
          headerName: "Created By",
          width: width > 1400 ? 120 : 120,
          renderCell: (cellValues: any) => {
            return (
              <>
                {cellValues.row.created_by == null
                  ? "-"
                  : cellValues.row.created_by}
              </>
            );
          },
        },
        {
          field: "updated_on",
          headerName: "Last Updated On",
          width: width > 1400 ? 100 : 100,
        },
        {
          field: "actions",
          headerName: "Action",
          width: width > 1400 ? 120 : 120,
          renderCell: (cellvalues: any) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                }}
              >
                {(cellvalues?.row?.editable === true &&
                cellvalues.row.lock != true )? (
                  <EditIcon
                    titleAccess="Edit"
                    sx={{ cursor: "pointer" }}
                    onClick={() => EditHandler(cellvalues?.row)}
                  />
                ) : (
                  <EditIcon titleAccess="Edit" sx={{ opacity: "0.5" }} />
                )}

                {cellvalues?.row?.mapped_status ? (
                  <InfoIcon
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                        InfoHandler(cellvalues?.row);
                    }}
                  />
                ) : (
                  <DeleteIcon
                    onClick={() => {
                      if (cellvalues.row.lock != true) {
                        DeleteHanlder(cellvalues?.row?.id);
                      }
                    }}
                    titleAccess="Delete"
                    sx={{
                      cursor: "pointer",
                      opacity: cellvalues.row.lock == true ? "0.5" : "",
                    }}
                  />
                )}
                {userProfile[0]?.is_adminuser == true &&
                cellvalues.row.lock == true ? (
                  <LockIcon
                    sx={{ cursor: "pointer",color:'green' }}
                    onClick={() => lockHandler(cellvalues?.row)}
                  />
                ) : userProfile[0]?.is_adminuser == false &&
                  cellvalues.row.lock == true ? (
                  <LockIcon sx={{ opacity: "0.5" }} />
                ) : userProfile[0]?.is_adminuser == false &&
                  cellvalues.row.lock == false ? (
                  ""
                ) : (
                  <LockOpenIcon
                    sx={{ cursor: "pointer",color:'red'}}
                    onClick={() => lockHandler(cellvalues?.row)}
                  />
                )}
              </Box>
            );
          },
        },
      ]
    : CellNameSecond.includes(datatype)
    ? [
        { field: "id", hide: true },
        {
          field: "Ids",
          headerName: "Sl No.",
          minWidth: 20,
        },
        {
          field: "name",
          headerName: "Parameter Name",
          width: width > 1400 ? 300 : 220,
        },
        {
          field: "description",
          headerName: "Description",
          width: width > 1400 ? 220 : 170,
          renderCell: (cellValues: any) => {
            return (
              <>
                {cellValues.row.description == null
                  ? "-"
                  : cellValues.row.description}
              </>
            );
          },
        },

        {
          field: "info_doc",
          headerName: "Guideline",
          width: width > 1400 ? 200 : 120,
          renderCell: (cellvalues: any) => {
            return (
              <>
                {cellvalues.row.info_doc == null ? (
                  "-"
                ) : (
                  <VisibilityIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open(cellvalues?.row?.info_doc, "_blank")
                    }
                  />
                )}
              </>
            );
          },
        },
        {
          field: "mapped_status",
          headerName: "Status",
          width: width > 1400 ? 200 : 100,
          renderCell: (cellValues: any) => {
            return <>{cellValues.row.mapped_status ? "In Use" : "Not Used"}</>;
          },
        },
        {
          field: "created_by",
          headerName: "Created By",
          width: width > 1400 ? 200 : 150,
          renderCell: (cellValues: any) => {
            return (
              <>
                {cellValues.row.created_by == null
                  ? "-"
                  : cellValues.row.created_by}
              </>
            );
          },
        },
        {
          field: "updated_on",
          headerName: "Last Updated On",
          width: width > 1400 ? 200 : 100,
        },
        {
          field: "actions",
          headerName: "Action",
          width: width > 1400 ? 200 : 140,
          renderCell: (cellvalues: any) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                }}
              >
                {(cellvalues?.row?.editable === true &&
                cellvalues.row.lock != true) ? (
                  <EditIcon
                    titleAccess="Edit"
                    sx={{ cursor: "pointer" }}
                    onClick={() => EditHandler(cellvalues?.row)}
                  />
                ) : (
                  <EditIcon titleAccess="Edit" sx={{ opacity: "0.5" }} />
                )}
                {cellvalues?.row?.data_type == "table" && (
                  <TableChartIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => TableHandler(cellvalues?.row?.id)}
                  />
                )}
                {cellvalues?.row?.mapped_status ? (
                  <InfoIcon
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                        InfoHandler(cellvalues?.row);
                    }}
                  />
                ) : (
                  <DeleteIcon
                    onClick={() => {
                      if (cellvalues.row.lock != true) {
                        DeleteHanlder(cellvalues?.row?.id);
                      }
                    }}
                    titleAccess="Delete"
                    sx={{
                      cursor: "pointer",
                      opacity: cellvalues.row.lock == true ? "0.5" : "",
                    }}
                  />
                )}

                {userProfile[0]?.is_adminuser == true &&
                cellvalues.row.lock == true ? (
                  <LockIcon sx={{cursor:'pointer',color:'green'}} onClick={() => lockHandler(cellvalues?.row)} />
                ) : userProfile[0]?.is_adminuser == false &&
                  cellvalues.row.lock == true ? (
                  <LockIcon sx={{ opacity: "0.5" }} />
                ) : userProfile[0]?.is_adminuser == false &&
                  cellvalues.row.lock == false ? (
                  ""
                ) : (
                  <LockOpenIcon sx={{cursor:'pointer',color:'red'}} onClick={() => lockHandler(cellvalues?.row)} />
                )}
              </Box>
            );
          },
        },
      ]
    : CellNameThird.includes(datatype)
    ? [
        { field: "id", hide: true },
        { field: "Ids", headerName: "Sl No." },
        {
          field: "name",
          headerName: "Parameter Name",
          width: width > 1400 ? 280 : 220,
        },
        {
          field: "description",
          headerName: "Description",
          width: width > 1400 ? 190 : 140,
          renderCell: (cellValues: any) => {
            return (
              <>
                {cellValues.row.description == null
                  ? "-"
                  : cellValues.row.description}
              </>
            );
          },
        },
        {
          field: "unit",
          headerName: "Unit",
          width: width > 1400 ? 100 : 80,
          renderCell: (cellValues: any) => {
            return <>{cellValues.row.unit == "" ? "-" : cellValues.row.unit}</>;
          },
        },
        {
          field: "exceptions",
          headerName: "Exceptions",
          width: width > 1400 ? 100 : 100,
          renderCell: (cellValues: any) => {
            return (
              <>
                {cellValues.row.exceptions?.length == 0
                  ? "-"
                  : cellValues?.row?.exceptions.toString()}
              </>
            );
          },
        },
        {
          field: "allowed_values",
          headerName: "Allowed Values",
          width: width > 1400 ? 200 : 100,
          renderCell: (cellvalues: any) => {
            return (
              <>
                {cellvalues.row.allowed_values?.length == 0
                  ? "-"
                  : cellvalues?.row?.allowed_values.toString()}
              </>
            );
          },
        },

        {
          field: "info_doc",
          headerName: "Guideline",
          width: width > 1400 ? 130 : 100,
          renderCell: (cellvalues: any) => {
            return (
              <>
                {cellvalues.row.info_doc == null ? (
                  "-"
                ) : (
                  <VisibilityIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open(cellvalues?.row?.info_doc, "_blank")
                    }
                  />
                )}
              </>
            );
          },
        },
        {
          field: "mapped_status",
          headerName: "Status",
          width: width > 1400 ? 100 : 100,
          renderCell: (cellValues: any) => {
            return <>{cellValues.row.mapped_status ? "In Use" : "Not Used"}</>;
          },
        },
        {
          field: "created_by",
          headerName: "Created By",
          width: width > 1400 ? 150 : 100,
          renderCell: (cellValues: any) => {
            return (
              <>
                {cellValues.row.created_by == null
                  ? "-"
                  : cellValues.row.created_by}
              </>
            );
          },
        },
        {
          field: "updated_on",
          headerName: "Last Updated On",
          width: width > 1400 ? 150 : 100,
        },
        {
          field: "actions",
          headerName: "Action",
          width: width > 1400 ? 150 : 110,
          renderCell: (cellvalues: any) => {
            return (
				<Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                }}
              >
                {(cellvalues?.row?.editable === true &&
                cellvalues.row.lock != true) ? (
                  <EditIcon
                    titleAccess="Edit"
                    sx={{ cursor: "pointer" }}
                    onClick={() => EditHandler(cellvalues?.row)}
                  />
                ) : (
                  <EditIcon titleAccess="Edit" sx={{ opacity: "0.5" }} />
                )}
                {cellvalues?.row?.data_type == "table" && (
                  <TableChartIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => TableHandler(cellvalues?.row?.id)}
                  />
                )}
                {cellvalues?.row?.mapped_status ? (
                  <InfoIcon
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                        InfoHandler(cellvalues?.row);
                    }}
                  />
                ) : (
                  <DeleteIcon
                    onClick={() => {
                      if (cellvalues.row.lock != true) {
                        DeleteHanlder(cellvalues?.row?.id);
                      }
                    }}
                    titleAccess="Delete"
                    sx={{
                      cursor: "pointer",
                      opacity: cellvalues.row.lock == true ? "0.5" : "",
                    }}
                  />
                )}

                {userProfile[0]?.is_adminuser == true &&
                cellvalues.row.lock == true ? (
                  <LockIcon sx={{cursor:'pointer',color:'green'}} onClick={() => lockHandler(cellvalues?.row)} />
                ) : userProfile[0]?.is_adminuser == false &&
                  cellvalues.row.lock == true ? (
                  <LockIcon sx={{ opacity: "0.5" }} />
                ) : userProfile[0]?.is_adminuser == false &&
                  cellvalues.row.lock == false ? (
                  ""
                ) : (
                  <LockOpenIcon sx={{cursor:'pointer',color:'red'}} onClick={() => lockHandler(cellvalues?.row)} />
                )}
              </Box>
            );
          },
        },
      ]
    : [
        { field: "id", hide: true },
        { field: "Ids", headerName: "Sl No." },
        {
          field: "name",
          headerName: "Parameter Name",
          width: width > 1400 ? 300 : 220,
        },

        {
          field: "description",
          headerName: "Description",
          width: width > 1400 ? 180 : 200,
          renderCell: (cellValues: any) => {
            return (
              <>
                {cellValues.row.description == null
                  ? "-"
                  : cellValues.row.description}
              </>
            );
          },
        },

        {
          field: "info_doc",
          headerName: "Guideline",
          width: width > 1400 ? 200 : 100,
          renderCell: (cellvalues: any) => {
            return (
              <>
                {cellvalues.row.info_doc == null ? (
                  "-"
                ) : (
                  <VisibilityIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open(cellvalues?.row?.info_doc, "_blank")
                    }
                  />
                )}
              </>
            );
          },
        },
        {
          field: "mapped_status",
          headerName: "Status",
          width: width > 1400 ? 100 : 100,
          renderCell: (cellValues: any) => {
            return <>{cellValues.row.mapped_status ? "In Use" : "Not Used"}</>;
          },
        },
        {
          field: "created_by",
          headerName: "Created By",
          width: width > 1400 ? 200 : 150,
          renderCell: (cellValues: any) => {
            return (
              <>
                {cellValues.row.created_by == null
                  ? "-"
                  : cellValues.row.created_by}
              </>
            );
          },
        },
        {
          field: "updated_on",
          headerName: "Last Updated On",
          width: width > 1400 ? 140 : 120,
        },
        {
          field: "actions",
          headerName: "Action",
          width: width > 1400 ? 150 : 120,
          renderCell: (cellvalues: any) => {
            return (
				<Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                }}
              >
                {(cellvalues?.row?.editable === true &&
                cellvalues.row.lock != true) ? (
                  <EditIcon
                    titleAccess="Edit"
                    sx={{ cursor: "pointer" }}
                    onClick={() => EditHandler(cellvalues?.row)}
                  />
                ) : (
                  <EditIcon titleAccess="Edit" sx={{ opacity: "0.5" }} />
                )}
                {cellvalues?.row?.data_type == "table" && (
                  <TableChartIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => TableHandler(cellvalues?.row?.id)}
                  />
                )}
                {cellvalues?.row?.mapped_status ? (
                  <InfoIcon
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                        InfoHandler(cellvalues?.row);
                    }}
                  />
                ) : (
                  <DeleteIcon
                    onClick={() => {
                      if (cellvalues.row.lock != true) {
                        DeleteHanlder(cellvalues?.row?.id);
                      }
                    }}
                    titleAccess="Delete"
                    sx={{
                      cursor: "pointer",
                      opacity: cellvalues.row.lock == true ? "0.5" : "",
                    }}
                  />
                )}

                {userProfile[0]?.is_adminuser == true &&
                cellvalues.row.lock == true ? (
                  <LockIcon sx={{cursor:'pointer',color:'green'}} onClick={() => lockHandler(cellvalues?.row)} />
                ) : userProfile[0]?.is_adminuser == false &&
                  cellvalues.row.lock == true ? (
                  <LockIcon sx={{ opacity: "0.5" }} />
                ) : userProfile[0]?.is_adminuser == false &&
                  cellvalues.row.lock == false ? (
                  ""
                ) : (
                  <LockOpenIcon sx={{cursor:'pointer',color:'red'}} onClick={() => lockHandler(cellvalues?.row)} />
                )}
              </Box>
            );
          },
        },
      ];

  return (
    <>
      {parameterModal.isOpen && (
        <AddParameterDataBaseModal
          Modaltype={"Edit"}
          datatype={datatype}
          isOpen={parameterModal.isOpen}
          onCloseModal={parameterModal.close}
          Item={parameterModal?.Item}
          rows={rows}
          setTableCounter={setEditCounter}
        />
      )}
      {tableModal.isOpen && (
        <TableParameterModal
          isOpen={tableModal.isOpen}
          onCloseModal={tableModal.close}
          Item={tableModal?.propsId}
          setCounter={setCounter}
        />
      )}
      {/* 
      {calculatorModal.isOpen &&
      <BOMConfigCalculator isOpen={calculatorModal.isOpen} onCloseModal={calculatorModal.close} id={calculatorModal.} />} */}

      {infoModal.isOpen && (
        <ParameterlibraryInfoModal
          isOpen={infoModal.isOpen}
          onCloseModal={infoModal.close}
          Item={rows}
        />
      )}
      <Box sx={{ position: "absolute", marginTop: "-2.5rem", right: "17rem" }}>
        {datatype && (
          <>
            <TextField
              type="search"
              placeholder="Search"
              value={searchvalue == 0 ? "" : searchvalue}
              style={{ color: "#007fff", fontSize: "0.9rem" }}
              onKeyDown={(event: any) => handlechangeDown(event)}
              onChange={(event: any) => handlechange(event)}
              variant="standard"
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.main",
                },
              }}
            />
            <SearchIcon
              sx={{
                color: "primary.main",
                cursor: "pointer",
                fontSize: { lg: "2.4rem", xl: "2.25rem" },
                padding: "3px",
                // borderBottom: '1px solid',
              }}
              onClick={() => SearchHandler()}
            />
          </>
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
          height: { lg: "81vh", xl: "85vh" },
          "& .super-app-theme--true": {
            bgcolor: "#ecffec",
          },
          "& .super-app-theme--lock": {
            bgcolor: "#c5c5c5",
          },
        }}
      >
        {RowData && (
          <DataGrid
            headerHeight={48}
            rowHeight={42}
            rows={RowData}
            columns={searchvalue == 0 ? columnsFiled : SearchColumsFiled}
            loading={loader}
            density="compact"
            // autoHeight={true}
            // components={{
            //   Toolbar: () => {
            //     return CustomToolbar();
            //   },
            // }}
            sx={{
              "& ::-webkit-scrollbar": {
                width: "0.3rem",
                height: "0.3rem",
              },
              "& ::-webkit-scrollbar-thumb": {
                // background: $thumb-color;
                backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                borderRadius: "10px",
              },
              "& ::- webkit-scrollbar-thumb: hover ": {
                // width: "4px",
                backgroundColor: "#045DE9 !important",
              },
              "::-webkit-scrollbar-track": {
                backgroundColor: "#e1e1f3 !important",
              },
              "&.MuiDataGrid-root .MuiDataGrid-cell": {
                borderBottomColor: "primary.light",
              },
            }}
            getRowClassName={(params) =>
              `super-app-theme--${
                params.row.lock === true
                  ? "lock"
                  : params.row.mapped_status === true
                  ? "true"
                  : "false"
              }`
            }
            disableSelectionOnClick={true}
            hideFooter={true}
            hideFooterPagination={true}
          />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          columnGap: "0.5rem",
        }}
      >
        {searchvalue == 0 ? (
          <>
            <Button
              sx={{ cursor: "pointer", padding: "0.5rem", minWidth: "1rem" }}
              disabled={count !== 1 ? false : true}
            >
              <ChevronLeftIcon onClick={() => PrveCount()} />
            </Button>
            <Box
              sx={{
                backgroundColor: "primary.light",
                borderRadius: "50%",
                width: "2rem",
                height: "2rem",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              {count}
            </Box>
            <Button
              sx={{ cursor: "pointer", padding: "0.5rem", minWidth: "1rem" }}
              disabled={count <= PageCount - 1 ? false : true}
            >
              <ChevronRightIcon
                onClick={() => count <= PageCount && NextCount()}
              />
            </Button>
          </>
        ) : (
          ""
        )}
      </Box>
    </>
  );
};
