import React, { useEffect } from 'react'

function Test({values, errors, setClaimedData, claimedData}:any) {
    // useEffect(() => {
    //     if (values?.logo !== "") {
    //       setClaimedData({...claimedData, logo: values?.logo});
            
    //     }
    // },[values.logo])


   console.log("values", values);
    console.log("errors", errors);
    
    

    
    
  return (
    <div>
        
    </div>
  )
}

export default Test