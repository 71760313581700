import React from "react";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import * as yup from "yup";
import {
  Autocomplete,
  Divider,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import useWindowDimensions from "../ui-reusable-component/useWindowDimensions";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import DescriptionIcon from "@mui/icons-material/Description";

import VerifiedIcon from "@mui/icons-material/Verified";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

interface InfoModalProps {
  onCloseModal: any;
  isOpen: any;
  item: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  padding: "1rem",
};

const csvOptions: GridCsvExportOptions = {
  fileName: "Table In CSV",
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: "1.5rem", marginBottom: "0.5rem" }}>
    <span style={{ position: "absolute", right: "0rem" }}>
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

const ZBCDocs = (props: InfoModalProps) => {
  const { height, width } = useWindowDimensions();
  const history = useHistory();
  const { onCloseModal, isOpen, item } = props;

  // const CadName = item?.cad_doc !== null && item?.cad_doc.split("/");
  // let GuidlineName =
  //   item?.costing_guidelines !== null && item?.costing_guidelines.split("/");
  // console.log(
  //   CadName,
  //   "CadNameCadName",
  //   item,
  //   GuidlineName[GuidlineName?.length - 1]
  // );

  // const dochandler = (url: any) => {
  //   window.open(url);
  // };

  return (
    <div>
      <Modal open={isOpen} onClose={onCloseModal}>
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              columnGap: "1rem",
              marginBottom: "0.5rem",
              padding: "1rem",
            }}
          >
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Zero-based Costing request Documents
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box
            sx={{
              margin: "1rem",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ background: "#008080", color: "#fff" }}>
                    Documents Type
                  </TableCell>
                  <TableCell sx={{ background: "#008080", color: "#fff" }}>
                    Documents Name
                  </TableCell>
                  <TableCell sx={{ background: "#008080", color: "#fff" }}>
                    Documents
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography>CAD Document</Typography>
                  </TableCell>
                  <TableCell>
                    __
                    {/* {item?.cad_doc?.length === 0 ? (
                      "__"
                    ) : (
                      <span>{item?.cad_doc?.[0]?.name}</span>
                    )} */}
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: "0.5rem",
                      }}
                    >
                      {item?.cad_doc &&
                        Array.isArray(item?.cad_doc) &&
                        item?.cad_doc.length > 0 &&
                        item?.cad_doc?.map((item: any) => {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                columnGap: "0.5rem",
                              }}
                            >
                              <DescriptionIcon
                                color="primary"
                                sx={{ cursor: "pointer" }}
                                onClick={() => window.open(item?.document_url)}
                              />
                            </Box>
                          );
                        })}
                    </Box>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography>Drawings</Typography>
                  </TableCell>
                  <TableCell>
                    __
                    {/* {item?.part_documents?.length === 0 ? (
                      "__"
                    ) : (
                      <span>{item?.part_documents?.[0]?.name}</span>
                    )} */}
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: "0.5rem",
                      }}
                    >
                      {item?.part_documents &&
                        Array.isArray(item?.part_documents) &&
                        item?.part_documents.length > 0 &&
                        item?.part_documents?.map((item: any) => {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                columnGap: "0.5rem",
                              }}
                            >
                              <DescriptionIcon
                                color="primary"
                                sx={{ cursor: "pointer" }}
                                onClick={() => window.open(item?.document_url)}
                              />
                            </Box>
                          );
                        })}
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ZBCDocs;
