import { Box, Divider, IconButton, Skeleton, Typography } from "@mui/material";
import * as React from "react";
import { CostingScenarioSection } from "./CostingScenarioSection";
import { API } from "../../api-services";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import CostingScenarioTree from "./CostingScenarioTree";
import { useHistory, useRouteMatch } from "react-router-dom";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import SearchIcon from "@mui/icons-material/Search";
import SyncIcon from "@mui/icons-material/Sync";
import { userPermissionAction } from "../BOMCreate/BOMEntry/BOMEntryLandingPage";
import { debounce } from "lodash";
import styles from "./CostingScenarioPage.module.scss";
import CostingScenarioCategoryDashboard from "./CostingScenarioCategoryDashboard";


export interface ICostingScenarioPageProps {
  count: any;
  setStatus: any;
  syncColor?: any;
  setSyncColor?: any;
  scenarioDashboardOpen: any;
  setScenarioDashboardOpen: any;
  getTableDataNew?: any;
  newScenarioRequestCount?: any;
}

export function CostingScenarioPage(props: ICostingScenarioPageProps) {
  const {
    projectId,
    projectName,
    topVault,
    vault,
    scenarioId,
    treeabbr,
    scenarioType,
    requestId,
  } = useRouteParams<any>();
  const {
    count,
    setStatus,
    syncColor,
    setSyncColor,
    scenarioDashboardOpen,
    setScenarioDashboardOpen,
    getTableDataNew,
    newScenarioRequestCount,
  } = props;
  const { url } = useRouteMatch();
  const [treeData, setTreeData] = React.useState<any>([]);
  const [treeDataLoading, setTreeDataLoading] = React.useState<boolean>(false);
  const [selectedNode, setSelectedNode] = React.useState<any>(null);
  const [selectedNodeCheck, setSelectedNodeCheck] =
    React.useState<boolean>(false);
  const history = useHistory();

  const descRef = React.useRef<any>();
  const OuterRef = React.useRef<any>();
  const userAction = React.useContext(userPermissionAction);

  const [selectedValue, setSelectedValue] = React.useState<any>(null);
  const [SearchValue, setSearchValue] = React.useState<any>([]);
  const inputRef = React.useRef<any>(null);

  

  const handleOnChange = async (event: any) => {
    setSelectedValue(event.target.value);
    var DataSplit = event?.target?.value?.split("(")?.shift();
    inputRef.current.style.display = "block";
    if (event?.target?.value?.length > 0) {
      ExactDebounce(DataSplit);
    } else {
      inputRef.current.style.display = "none";
      setSearchValue([]);
    }
    // setSearchValue
  };

  const ExactDebounce = React.useRef(
    debounce(async (criteria: any) => {
      await API.get("/bom/vault/search/", {
        search: criteria,
        vehicle_category: projectId,
        type: scenarioType == 1 ? null : 2,
      }).then((res: any) => {
        setSearchValue(res.data);
      });
    }, 500)
  ).current;

 

  const getTreeData = () => {
    setTreeDataLoading(true);  
    API.get(
      "/cost/scenario_hierachy/",
      {
        vault: vault !=0?vault:undefined,
        vehicle_category: vault==0?projectId:undefined,
        type: scenarioType == 1 ? null : 2,
      },
      0
    )
      .then((res: any) => {
        setTreeData(res.data);
        setTreeDataLoading(false);
      })
      .catch((err: any) => {
        setTreeDataLoading(false);
      });    
  };


  React.useEffect(() => {
    if (sessionStorage.getItem("isCategory") == undefined) {
      sessionStorage.setItem(
        "isCategory",
        JSON.stringify([treeabbr, false, vault])
      );
    }
    getTreeData();
  }, [ count, scenarioType,projectId]);

  const onSelect = (
    value: any,
    abbreviation: any,
    topv: any,
    statuss: any,
    isCategory: any
  ) => {
    setScenarioDashboardOpen(false);
    setStatus(statuss);
    sessionStorage.removeItem("isCategory");
    // below toggle state code do not remove as it is used to call the api for category as change. It help to call api in costingDashboard category.
    setSelectedNodeCheck(!selectedNodeCheck);
    sessionStorage.setItem(
      "isCategory",
      JSON.stringify([abbreviation, isCategory, value])
    );
    if (abbreviation == "PRODUCT") {
      sessionStorage.setItem("addScenarioStatus", "3");
      !isCategory &&
        history.push(
          `/costingscenario/${projectId}/${projectName}/${topVault}/${0}/${scenarioType}/${"PRODUCT"}/0/0/1`
        );
    } else {
      sessionStorage.setItem("addScenarioStatus", "3");
      !isCategory &&
        history.push(
          `/costingscenario/${projectId}/${projectName}/${topVault}/${value}/${scenarioType}/${
            abbreviation ? abbreviation : "none"
          }/0/0/1`
        );
    }
  };

  const changeScenarioHandler = (typeScenario: any) => {
    sessionStorage.removeItem("nodeCostingScenario");
    sessionStorage.setItem("nodeCostingScenario", JSON.stringify([topVault]));
    sessionStorage.removeItem("isCategory");
    sessionStorage.setItem(
      "isCategory",
      JSON.stringify([treeabbr, false, topVault])
    );
    if (typeScenario == "BOM") {
      history.push(
        `/costingscenario/${projectId}/${projectName}/${topVault}/${vault}/1/PRODUCT/0/0/1`
      );
    } else {
      setScenarioDashboardOpen(false);
      history.push(
        `/costingscenario/${projectId}/${projectName}/${topVault}/${vault}/2/PRODUCT/0/0/1`
      );
    }
  };

  


  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          width: "100%",
          border: "1px solid #ddefff",
          borderRadius: "10px",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: { lg: "20vw", xl: "18vw" },
            borderRight: "1px solid #ddefff",
            height: { lg: "86vh", xl: "89vh" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              borderBottom: "1px solid",
              borderBottomColor: "primary.light",
            }}
          >
            <Box
              sx={
                scenarioType == 1
                  ? {
                      backgroundColor: "primary.main",
                      color: "white",
                      cursor: "pointer",
                      display:'flex',
                      justifyContent:'center',
                      alignItems:'center',
                      gap:"0.8rem",
                      width: "100%",
                      textAlign: "center",
                      borderRight: "1px solid",
                      borderRightColor: "#ddefff",
                    }
                  : {
                      width: "100%",
                      textAlign: "center",
                      display:'flex',
                      justifyContent:'center',
                      alignItems:'center',
                      gap:"0.8rem",
                      cursor: "pointer",
                      borderRight: "1px solid",
                      borderRightColor: "#ddefff",                      
                    }
              }
              onClick={() => changeScenarioHandler("BOM")}
            >
              BOM {scenarioType == 1 && <SyncIcon sx={{cursor:'pointer'}} onClick={()=>{
                setTreeDataLoading(true);  
                API.get(
                  "/cost/scenario_hierachy/",
                  {
                    vault: vault !=0?vault:undefined,
                    vehicle_category: vault==0?projectId:undefined,
                    type: scenarioType == 1 ? null : 2,
                    sync:true
                  },
                  0
                )
                  .then((res: any) => {
                    setTreeData(res.data);
                    setTreeDataLoading(false);
                  })
                  .catch((err: any) => {
                    setTreeDataLoading(false);
                  }); 
              }}/>}
            </Box>
            <Box
              sx={
                scenarioType == 2
                  ? {
                      backgroundColor: "primary.main",
                      color: "white",
                      display:'flex',
                      justifyContent:'center',
                      alignItems:'center',
                      gap:"0.8rem",
                      cursor: "pointer",
                      width: "100%",
                      textAlign: "center",
                      borderRight: "1px solid",
                      borderRightColor: "#ddefff",
                    }
                  : {
                      width: "100%",
                      textAlign: "center",
                      cursor: "pointer",
                      borderRight: "1px solid",
                      borderRightColor: "#ddefff",
                      display:'flex',
                      justifyContent:'center',
                      alignItems:'center',
                      gap:"0.8rem",
                    }
              }
              onClick={() => changeScenarioHandler("Category")}
            >
              Category {scenarioType == 2 && <SyncIcon sx={{cursor:'pointer'}} onClick={()=>{
                 setTreeDataLoading(true);  
                 API.get(
                   "/cost/scenario_hierachy/",
                   {
                     vault: vault !=0?vault:undefined,
                     vehicle_category: vault==0?projectId:undefined,
                     type: scenarioType == 1 ? null : 2,
                     sync:true
                   },
                   0
                 )
                   .then((res: any) => {
                     setTreeData(res.data);
                     setTreeDataLoading(false);
                   })
                   .catch((err: any) => {
                     setTreeDataLoading(false);
                   }); 
              }}/>}
            </Box>
            <Box
              style={
                scenarioType == 3
                  ? {
                      backgroundColor: "#007fff",
                      color: "white",
                      cursor: "pointer",
                      width: "100%",
                      textAlign: "center",
                    }
                  : {
                      width: "100%",
                      textAlign: "center",
                      cursor: "not-allowed",
                    }
              }
              title="We are working on it.."
            >
              Supplier
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid",
              borderBottomColor: "primary.light",
              height: "2.5rem",
              margin: "0 0.5rem",
              overflow: "hidden",
            }}
          >
            <div
              className={styles.inputbox}
              ref={OuterRef}
              // tabIndex={1} onKeyDown={(event:any)=>HandleKeyDown(event)}
            >
              
              {
                <input
                  type="search"
                  placeholder="Search Part Name / Number"
                  value={selectedValue}
                  className={styles.searchInput}
                  autoFocus={false}
                  style={{ color: "#007fff" }}
                  // @ts-expect-
                  ref={descRef}
                  onKeyPress={(event: any) => handleOnChange(event)}
                  onChange={(event: any) => handleOnChange(event)}
                  // onFocus={(e) => FocusHandler(e)}
                />
              }

              <div
                style={{ display: "none", zIndex: 900, position: "absolute" }}
                className={styles.AutoSearchBox}
                ref={inputRef}
              >
                <>
                  <div className={styles.searchSubText}>
                    {SearchValue.map((item: any, index: any) => {
                      return (
                        <div>
                          <div
                            key={index}
                            //  ref={focusedIndex==index?resItConatiner:null} style={{backgroundColor:index==focusedIndex?"red":""}}
                            className={styles.innserSearchSubText}
                            onClick={() => {
                              setSelectedValue(item.name);
                              inputRef.current.style.display = "none";
                              sessionStorage.setItem(
                                "isCategory",
                                JSON.stringify([treeabbr, false, item?.id])
                              );
                              sessionStorage.removeItem("nodeCostingScenario");
                              sessionStorage.setItem(
                                "nodeCostingScenario",
                                JSON.stringify(item?.ancestors)
                              );
                              history.push(
                                `/costingscenario/${projectId}/${projectName}/${topVault}/${
                                  item?.id
                                }/${scenarioType}/${
                                  item?.abbreviation
                                    ? item?.abbreviation
                                    : "none"
                                }/${0}/0/1`
                              );
                            }}
                          >
                            <span
                            // onClick={() => {
                            //   handleChangeSelection(item?.vault_id, item?.value, item?.vault_name, item?.part_no, item?.topvault_name, item?.topvault_id, item?.model_ob);
                            // }}
                            >
                              <>
                                {/* <span>{item?.value} </span> */}
                                <span>
                                  {item?.vault_name}
                                  <span>
                                    <span>(</span>
                                    {item?.part_no}
                                    <span>)</span>
                                  </span>
                                  | <span>{item?.name}</span>
                                </span>
                              </>
                            </span>
                            <span
                              style={{
                                // marginRight: "0.5rem",
                                paddingTop: "5px",
                              }}
                            >
                              <SearchIcon />
                            </span>
                          </div>
                        </div>
                      );
                    })}
                    {SearchValue && SearchValue?.length == 0 && (
                      <p
                        style={{
                          paddingLeft: "1rem",
                          fontSize: "0.9rem",
                        }}
                      >
                        No Data
                      </p>
                    )}
                    
                  </div>
                </>
              </div>
            </div>
            <Divider
              orientation="vertical"
              sx={{ borderColor: "primary.light" }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "4rem",
                justifyContent: "space-evenly",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1rem",
                  textAlign: "center",
                  fontWeight: "500",
                  color: "green",
                  width: "2rem",
                }}
              >
                I
              </Typography>
              <Typography
                sx={{
                  fontSize: "1rem",
                  textAlign: "center",
                  fontWeight: "500",
                  color: "green",
                  width: "2rem",
                }}
              >
                D
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: { lg: "20vw", xl: "18vw" },
              borderRight: "1px solid #ddefff",
              height: { lg: "81vh", xl: "85vh" },
            }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            {!treeDataLoading && treeData ? (
              <CostingScenarioTree
                HierarchyData={treeData && treeData} // hierarchyData={HierarchyData && HierarchyData}
                onSelectCallback={onSelect}
              />
            ) : (
              <Box sx={{ width: "100%", padding: "0 0.5rem" }}>
                <Skeleton sx={{ height: "2rem", width: "30%" }} />
                <Skeleton sx={{ height: "2rem", width: "45%" }} />
                <Skeleton sx={{ height: "2rem", width: "50%" }} />
                <Skeleton sx={{ height: "2rem", width: "65%" }} />
                <Skeleton sx={{ height: "2rem", width: "70%" }} />
                <Skeleton sx={{ height: "2rem", width: "60%" }} />
                <Skeleton sx={{ height: "2rem", width: "55%" }} />
                <Skeleton sx={{ height: "2rem", width: "30%" }} />
                <Skeleton sx={{ height: "2rem", width: "45%" }} />
                <Skeleton sx={{ height: "2rem", width: "50%" }} />
                <Skeleton sx={{ height: "2rem", width: "65%" }} />
                <Skeleton sx={{ height: "2rem", width: "70%" }} />
                <Skeleton sx={{ height: "2rem", width: "60%" }} />
                <Skeleton sx={{ height: "2rem", width: "55%" }} />
                <Skeleton sx={{ height: "2rem", width: "30%" }} />
                <Skeleton sx={{ height: "2rem", width: "45%" }} />
                <Skeleton sx={{ height: "2rem", width: "50%" }} />
                <Skeleton sx={{ height: "2rem", width: "65%" }} />
                <Skeleton sx={{ height: "2rem", width: "70%" }} />
                <Skeleton sx={{ height: "2rem", width: "60%" }} />
                <Skeleton sx={{ height: "2rem", width: "55%" }} />
                <Skeleton sx={{ height: "2rem", width: "30%" }} />
                <Skeleton sx={{ height: "2rem", width: "45%" }} />
                <Skeleton sx={{ height: "2rem", width: "50%" }} />
                <Skeleton sx={{ height: "2rem", width: "65%" }} />
                <Skeleton sx={{ height: "2rem", width: "70%" }} />
                <Skeleton sx={{ height: "2rem", width: "60%" }} />
                <Skeleton sx={{ height: "2rem", width: "55%" }} />
                <Skeleton sx={{ height: "2rem", width: "30%" }} />
                <Skeleton sx={{ height: "2rem", width: "45%" }} />
                <Skeleton sx={{ height: "2rem", width: "50%" }} />
                <Skeleton sx={{ height: "2rem", width: "65%" }} />
                <Skeleton sx={{ height: "2rem", width: "70%" }} />
                <Skeleton sx={{ height: "2rem", width: "60%" }} />
                <Skeleton sx={{ height: "2rem", width: "55%" }} />
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{ width: { lg: "75.5vw", xl: "78.5vw" }, padding: "0 0.5rem" }}
        >
          {/* @ts-ignore */}
          {!JSON.parse(sessionStorage.getItem("isCategory"))?.[1] ? (
            scenarioDashboardOpen ? (
              <CostingScenarioCategoryDashboard />
            ) : (
              <CostingScenarioSection count={count} newScenarioRequestCount={newScenarioRequestCount} />
            )
          ) : (
            <CostingScenarioCategoryDashboard />
          )}
        </Box>
      </Box>
    </div>
  );
}
