import React, { useEffect, useState } from 'react';
import styles from './ProjectScopeLanding.module.scss';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Button, Checkbox, Divider, Drawer, FormGroup, IconButton, Skeleton, Switch, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { API } from '../../../api-services';
import { LoadingButton } from '@mui/lab';
import bomIcon from "../../../Assets/images/hierarchy.png";
import CurrencyRupeeRoundedIcon from "@mui/icons-material/CurrencyRupeeRounded";
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ideaCreate from "../../../Assets/images/IdeaCreate.png";
import HandshakeIcon from '@mui/icons-material/Handshake';
import { AxiosError, AxiosResponse } from 'axios';
import { MileStone } from '../../../Redux/Services/milestone.service';
import MilestoneRows from '../MilestoneRows';
import VerifiedIcon from '@mui/icons-material/Verified';
import cash from "../../../Assets/images/cash.png";
import MAF from "../../../Assets/images/MAF.png";
import handshake from "../../../Assets/images/handshake.png";
import { useDocumentTitle } from '../../../ui-reusable-component/useDocumentTitle';


type Anchor = "top" | "left" | "bottom" | "right";

interface Params {
  projectId: any;
  dept: any;
}

interface ProjectScopeLandingI { }

const ProjectScopeLanding: React.FC<ProjectScopeLandingI> = (props) => {
  useDocumentTitle( "Planner" );
  const { projectId, dept } = useParams<Params>();
  const [projectDetails, setProjectDetails] = React.useState<any>();
  const [selectedProject, setSelectedProject] = useState<any>([]);
  const [products, setproducts] = useState<any>();
  const [mileStone, setMileStone] = useState<any>();
  const [deptInfo, setDeptInfo] = useState<any>();
  const [mileStoneDataCopy, setMileStoneDataCopy] = useState<any>();
  const [projectList, setProjectList] = useState<any>();
  const [idUpdated, setIdUpdated] = React.useState<any>();
  const [apiLoader, setApiLoader] = useState<boolean>(false);
  const [checked, setChecked] = React.useState<boolean>(false);
  const [allProductCheckbox, setAllProductCheckbox] = React.useState<boolean>();
  const [checkboxShow, setCheckboxShow] = React.useState<any>();

  const history: any = useHistory();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });


  useEffect(() => {
    API.get("/xcpep/project/", {
      id: projectId,
    },
      0).then((res: AxiosResponse) => {
        setProjectDetails(res?.data);
      });

  }, [projectId])

  useEffect(() => {
    API.get(`/cost/dashboard/`, { project_list_bin: true }, 0)
      .then((res: AxiosResponse) => {
        setProjectList(res?.data);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });
  }, []);

  useEffect(() => {
    setApiLoader(false);
    API.get("xcpep/project_milestone/", { project: Number(projectId), dept: dept }).then((res: AxiosResponse) => {
      setMileStone(res?.data);
      setMileStoneDataCopy(res?.data);
      setIdUpdated(undefined);
      setApiLoader(true);
    })
    setChecked(false);
    setAllProductCheckbox(false);
    API.get("/xcpep/mileStone_dept_activity/", {
      project: projectId,
      dept:dept
    },
      0).then((res: AxiosResponse) => {
        setCheckboxShow(res?.data);
      });
  }, [dept, projectId])

  useEffect(() => {
    MileStone.getProject(projectId)
      .then((res: AxiosResponse) => {
        setproducts(res.data);
      })
      .catch((err: AxiosError) => console.log(err, "Server Error"));

    API.get("/xcpep/project_scope_verification/", { project: Number(projectId) }).then((res: AxiosResponse) => {
      setDeptInfo(res?.data);
    })
  }, [projectId]);

  const getDetailsUpdate = () => {
    API.get("xcpep/project_milestone/", { project: Number(projectId), dept: dept }).then((res: AxiosResponse) => {
      if(checked){
        setMileStone(res?.data?.filter((item: any) => item.is_active == checked));
      }
      else{
        setMileStone(res?.data);
      }
      setMileStoneDataCopy(res?.data);
      setIdUpdated(undefined);
      API.get("/xcpep/project_scope_verification/", { project: Number(projectId) }).then((res: AxiosResponse) => {
        setDeptInfo(res?.data);
      })
    })
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    if (event.target.checked == true) {
      setMileStone(mileStone && mileStone?.filter((item: any) => item?.is_active == true));
    }
    else {
      setMileStone(mileStoneDataCopy);
    }
  };

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAllProductCheckbox(event.target.checked);
    API.put("/xcpep/mileStone_dept_activity/", {
      id:checkboxShow?.id,
      status:false
    }).then((res: any) => {
      API.get("/xcpep/mileStone_dept_activity/", {
        project: projectId,
        dept:dept
      },
        0).then((res: AxiosResponse) => {
          setCheckboxShow(res?.data);
        });
    });
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }
        setState({ ...state, [anchor]: open });
        if (open) {
          setSelectedProject([Number(projectId)]);
        }
      };

  const selectAllProject = (productIds: any) => {
    setSelectedProject([productIds]);
  };

  const getselectedProjectBackgroundColor = (idd: any) => {
    if (selectedProject?.includes(idd)) {
      return "primary.main";
    } else {
      return "primary.light";
    }
  };

  const getSelectedProjectTextColor = (idd: any) => {
    if (selectedProject?.includes(idd)) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };

  const redirectToProject: any = () => {
    history.push(`/project-scope/${selectedProject[0]}/BD`);
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        minWidth: 300,
        width: anchor === "top" || anchor === "bottom" ? "auto" : "auto",
      }}
      role="presentation"
    >
      <Box sx={{ width: "100%", padding: "0" }}>
        <Box
          sx={{ width: "100%", padding: "0 1rem" }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          <Box>
            <Typography
              style={{
                width: "100%",
                fontSize: "0.75rem",
              }}
            >
              Project Name
            </Typography>
            {projectList &&
              projectList?.map((item: any, index: any) => {
                return (
                  <>
                    <Box sx={{ alignItems: "center" }}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                          style={{ padding: "3px" }}
                          checked={
                            selectedProject?.includes(item.id) ? true : false
                          }
                          onClick={() => selectAllProject(item?.id)}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                        />
                        <Box
                          onClick={() => selectAllProject(item?.id)}
                          key={index}
                          className={styles.selectVehBtn}
                          sx={{
                            // margin: "0rem 2rem",
                            padding: "0.2rem 0.5rem",
                            backgroundColor: getselectedProjectBackgroundColor(
                              item.id
                            ),
                            color: getSelectedProjectTextColor(item.id),
                            textAlign: "left",
                            cursor: "pointer",
                            width: "100%",
                          }}
                        >
                          <Typography
                            style={{ fontSize: "0.87rem", display: "flex" }}
                          >
                            {item.name}{" "}
                            <span
                              style={{
                                marginLeft: "0.5rem",
                                display: "inline-block",
                                width: "14rem",
                              }}
                            >
                              [ {item.description} ]
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </>
                );
              })}
          </Box>
        </Box>
        <div
          style={{
            paddingBottom: "0.5rem",
            position: "sticky",
            bottom: "0",
            height: "4rem",
            backgroundColor: "aliceblue",
            marginTop: "2rem",
          }}
        >
          <Box sx={{ textAlign: "right", paddingRight: "1rem" }}>
            <Button
              className={styles.drawBtn}
              sx={{
                marginRight: "1rem",
                fontSize: "0.75rem",
                "&:hover": {
                  transform: "Scale(1.1)",
                  transition: "transform 0.5s ease",
                },
              }}
              variant="contained"
              onClick={toggleDrawer(anchor, false)}
              size="small"
            >
              Close
            </Button>
            <LoadingButton size="small" onClick={redirectToProject}>
              <Button
                variant="contained"
                sx={{
                  fontSize: "0.75rem",
                  "&:hover": {
                    transform: "Scale(1.1)",
                    transition: "transform 0.5s ease",
                  },
                }}
                onClick={toggleDrawer(anchor, false)}
                size="small"
              >
                Ok
              </Button>
            </LoadingButton>
          </Box>
        </div>
      </Box>
    </Box>
  );

  return (
    <div className={styles.container}>
      <Box sx={{ display: 'flex', alignItems: 'center', padding: '0 0.5rem', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            sx={{ cursor: "pointer" }}
            title="Go Back"
            onClick={() => history.push(`/internallanding`)}
          >
            <ArrowBackIcon color="primary" titleAccess="Go Back" />
          </IconButton>
          {projectDetails && <Typography sx={{ marginLeft: "1rem", fontSize: '1rem' }}>
            {`${projectDetails && projectDetails?.[0]?.name} [ ${projectDetails && projectDetails?.[0]?.description} ]`}
          </Typography>}
          {dept == 'MAF' && <span style={{ marginLeft: '1rem', display: 'flex' }}>
            <span style={{ display: 'flex', alignItems: 'center', marginRight: '1rem' }}>
              Media
              {deptInfo ? <Checkbox
                style={deptInfo && deptInfo?.filter((item: any) => item?.dept == "Media")[0]?.verified ? { padding: '7px' } : { padding: '3px' }}
                // icon={<VerifiedIcon />}
                checkedIcon={<Box sx={{ backgroundColor: 'white', borderRadius: '50%', height: '1rem', width: '1rem' }}>
                  <VerifiedIcon sx={{ color: '#39FF14', position: 'relative', margin: '-0.35rem -0.25rem 0', }} />
                </Box>}
                checked={deptInfo && deptInfo?.filter((item: any) => item?.dept == "Media")[0]?.verified}
                title="Validated"
                color="success"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 22 }, color: "#007fff" }}
              /> : <Box style={{ display: 'flex', justifyContent: 'center' }}>
                <Skeleton sx={{ height: "2rem", width: "1rem" }} />
              </Box>}
            </span>
            <span style={{ display: 'flex', alignItems: 'center', marginRight: '1rem' }}>
              Architecture
              {deptInfo ? <Checkbox
                style={deptInfo && deptInfo?.filter((item: any) => item?.dept == "Architecture")[0]?.verified ? { padding: '7px' } : { padding: '3px' }}
                // icon={<VerifiedIcon />}
                checkedIcon={<Box sx={{ backgroundColor: 'white', borderRadius: '50%', height: '1rem', width: '1rem' }}>
                  <VerifiedIcon sx={{ color: '#39FF14', position: 'relative', margin: '-0.35rem -0.25rem 0', }} />
                </Box>}
                checked={deptInfo && deptInfo?.filter((item: any) => item?.dept == "Architecture")[0]?.verified}
                title="Validated"
                color="success"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 22 }, color: "#007fff" }}
              /> : <Box style={{ display: 'flex', justifyContent: 'center' }}>
                <Skeleton sx={{ height: "2rem", width: "1rem" }} />
              </Box>}
            </span>
            <span style={{ display: 'flex', alignItems: 'center', marginRight: '1rem' }}>
              Features
              {deptInfo ? <Checkbox
                style={deptInfo && deptInfo?.filter((item: any) => item?.dept == "Features")[0]?.verified ? { padding: '7px' } : { padding: '3px' }}
                // icon={<VerifiedIcon />}
                checkedIcon={<Box sx={{ backgroundColor: 'white', borderRadius: '50%', height: '1rem', width: '1rem' }}>
                  <VerifiedIcon sx={{ color: '#39FF14', position: 'relative', margin: '-0.35rem -0.25rem 0', }} />
                </Box>}
                checked={deptInfo && deptInfo?.filter((item: any) => item?.dept == "Features")[0]?.verified}
                title="Validated"
                color="success"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 22 }, color: "#007fff" }}
              /> : <Box style={{ display: 'flex', justifyContent: 'center' }}>
                <Skeleton sx={{ height: "2rem", width: "1rem" }} />
              </Box>}
            </span>
          </span>}
        </Box>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: '1rem' }}><Switch size="small" checked={checked} onChange={handleChange} /> <span>Hide disabled activities</span></span>
          {!allProductCheckbox && <span style={{ marginRight: '1rem' }}>
          {checkboxShow && checkboxShow?.status && <FormGroup style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Checkbox checked={allProductCheckbox} onChange={handleChangeCheckbox} /> <span>Select all products in all activities</span>
            </FormGroup>}
          </span>}
          <Button sx={{ padding: '0.2rem 0.5rem', marginLeft: '0.5rem',textTransform:'capitalize' }} variant='contained' onClick={() => history.push(`/Milestone/${projectId}`)}><Typography sx={{fontSize:'1rem'}}>Next {`>>`}</Typography></Button>
        </span>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}
                >
                  <Box
                    sx={{ padding: "0.5rem 0rem", paddingBottom: "0" }}
                  >
                    <Box sx={{ position: "sticky", top: "0" }}>
                      <Typography
                        style={{
                          width: "100%",
                          textAlign: "center",
                          fontSize: "1rem",
                          lineHeight: "1.2",
                        }}
                      >
                        Select to Change Project
                      </Typography>
                      <Divider sx={{ margin: "0.5rem" }} />
                    </Box>
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  style={{
                    transform: "rotate(270deg)",
                    whiteSpace: "nowrap",
                    marginTop: "45vh",
                    cursor: "pointer",
                    fontSize: "1rem",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  Select to Change Project
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        <Box sx={{ width: '12%' }}>
          <Box sx={{ height: { lg: '71%', xl: '99%' } }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3">
            <div style={{ margin: '0 1rem' }}>
              <Box
                sx={{
                  borderRadius: "10px",
                  boxShadow:
                    "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
                  marginBottom: "1rem",
                  backgroundColor: dept == "BD" ? "#ddefff" : "",
                  cursor: "pointer",
                  textAlign: 'center',
                  position: 'relative',
                  paddingBottom: '0.5rem'
                }}
                onClick={() => { history.push(`/project-scope/${projectId}/BD`) }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <FormGroup>
                    {deptInfo ? <Checkbox
                      style={deptInfo && deptInfo?.filter((item: any) => item?.dept == "BD")[0]?.verified ? { padding: '7px' } : { padding: '3px' }}
                      // icon={<VerifiedIcon />}
                      checkedIcon={<Box sx={{ backgroundColor: 'white', borderRadius: '50%', height: '1rem', width: '1rem' }}>
                        <VerifiedIcon sx={{ color: '#39FF14', position: 'relative', margin: '-0.35rem -0.25rem 0', }} />
                      </Box>}
                      checked={deptInfo && deptInfo?.filter((item: any) => item?.dept == "BD")[0]?.verified}
                      title="Validated"
                      color="success"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 22 }, color: "#007fff" }} /> :
                      <Box style={{ display: 'flex', justifyContent: 'center' }}>
                        <Skeleton sx={{ height: "2rem", width: "1rem", margin: '0.2rem 0.5rem' }} />
                      </Box>}
                  </FormGroup>
                  <Typography className={styles.activeCount}>{deptInfo ? `${deptInfo && deptInfo?.filter((item: any) => item?.dept == "BD")[0]?.active_count}/${deptInfo && deptInfo?.filter((item: any) => item?.dept == "BD")[0]?.total_count}` : <Skeleton sx={{ height: "0.8rem", width: "100%", minWidth: '1.2rem', minHeight: '1.3rem' }} />}</Typography>
                </Box>
                <BusinessCenterIcon titleAccess="BD"
                  sx={{ fontSize: "3rem", cursor: 'pointer', marginTop: '-1.3rem' }} />

                <Typography style={{ fontSize: '1rem' }}>Business Development</Typography>
                <Typography style={{ fontSize: '1rem' }}>(BD)</Typography>
              </Box>
            </div>
            <div style={{ margin: '0 1rem' }}>
              <Box
                sx={{
                  borderRadius: "10px",
                  boxShadow:
                    "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
                  marginBottom: "1rem",
                  backgroundColor: dept == "FIN" ? "#ddefff" : "",
                  cursor: "pointer",
                  textAlign: 'center',
                  position: 'relative',
                  paddingBottom: '0.5rem',
                }}
                onClick={() => { history.push(`/project-scope/${projectId}/FIN`) }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <FormGroup>
                    {deptInfo ? <Checkbox
                      style={deptInfo && deptInfo?.filter((item: any) => item?.dept == "FIN")[0]?.verified ? { padding: '7px' } : { padding: '3px' }}
                      // icon={<VerifiedIcon />}
                      checkedIcon={<Box sx={{ backgroundColor: 'white', borderRadius: '50%', height: '1rem', width: '1rem' }}>
                      <VerifiedIcon sx={{ color: '#39FF14', position: 'relative', margin: '-0.35rem -0.25rem 0', }} />
                    </Box>}
                      checked={deptInfo && deptInfo?.filter((item: any) => item?.dept == "FIN")[0]?.verified}
                      title="Validated"
                      color="success"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 22 }, color: "#007fff" }}
                    /> : <Box style={{ display: 'flex', justifyContent: 'center' }}>
                      <Skeleton sx={{ height: "2rem", width: "1rem", margin: '0.2rem 0.5rem' }} />
                    </Box>}
                  </FormGroup>
                  <Typography className={styles.activeCount}>{deptInfo ? `${deptInfo && deptInfo?.filter((item: any) => item?.dept == "FIN")[0]?.active_count}/${deptInfo && deptInfo?.filter((item: any) => item?.dept == "FIN")[0]?.total_count}` : <Skeleton sx={{ height: "0.8rem", width: "100%", minWidth: '1.2rem', minHeight: '1.3rem' }} />}</Typography>
                </Box>
                <img title="FIN" className={styles.internalIcons}
                  src={cash}
                  style={{ width: "4rem", height: "auto", cursor: 'pointer', marginTop: '-1.3rem' }}
                />
                <Typography style={{ fontSize: '1rem' }}>Finance</Typography>
                <Typography style={{ fontSize: '1rem' }}>(FIN)</Typography>
              </Box>
            </div>
            <div style={{ margin: '0 1rem' }}>
              <Box
                sx={{
                  borderRadius: "10px",
                  boxShadow:
                    "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
                  marginBottom: "1rem",
                  backgroundColor: dept == "PM" ? "#ddefff" : "",
                  cursor: "pointer",
                  textAlign: 'center',
                  position: 'relative',
                  paddingBottom: '0.5rem',
                }}
                onClick={() => { history.push(`/project-scope/${projectId}/PM`) }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <FormGroup>
                    {deptInfo ?
                    
                    <Checkbox
                      style={deptInfo && deptInfo?.filter((item: any) => item?.dept == "PM")[0]?.verified ? { padding: '7px' } : { padding: '3px' }}
                      // icon={<VerifiedIcon />}
                      checkedIcon={<Box sx={{ backgroundColor: 'white', borderRadius: '50%', height: '1rem', width: '1rem' }}>
                        <VerifiedIcon sx={{ color: '#39FF14', position: 'relative', margin: '-0.35rem -0.25rem 0', }} />


                      </Box>}
                      checked={deptInfo && deptInfo?.filter((item: any) => item?.dept == "PM")[0]?.verified}
                      title="Validated"
                      color="success"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 22 }, color: "#007fff" }}
                    /> 
                    
                    : <Box style={{ display: 'flex', justifyContent: 'center' }}>
                      <Skeleton sx={{ height: "2rem", width: "1rem", margin: '0.2rem 0.5rem' }} />
                    </Box>}
                  </FormGroup>
                  <Typography className={styles.activeCount}>{deptInfo ? `${deptInfo && deptInfo?.filter((item: any) => item?.dept == "PM")[0]?.active_count}/${deptInfo && deptInfo?.filter((item: any) => item?.dept == "PM")[0]?.total_count}` : <Skeleton sx={{ height: "0.8rem", width: "100%", minWidth: '1.2rem', minHeight: '1.3rem' }} />}</Typography>
                </Box>
                <img src={handshake} title="PM" style={{ width: "4rem", height: "auto", cursor: 'pointer', marginTop: '-1.3rem' }} />
                <Typography style={{ fontSize: '1rem' }}>Project Management</Typography>
                <Typography style={{ fontSize: '1rem' }}>(PM)</Typography>
              </Box>
            </div>
            <div style={{ margin: '0 1rem' }}>
              <Box
                sx={{
                  borderRadius: "10px",
                  boxShadow:
                    "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
                  marginBottom: "1rem",
                  backgroundColor: dept == "MAF" ? "#ddefff" : "",
                  cursor: "pointer",
                  textAlign: 'center',
                  position: 'relative',
                  paddingBottom: '0.5rem',
                }}
                onClick={() => { history.push(`/project-scope/${projectId}/MAF`) }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <FormGroup>
                    {deptInfo ? <Checkbox
                      style={deptInfo && deptInfo?.filter((item: any) => item?.dept == "MAF")[0]?.verified ? { padding: '7px' } : { padding: '3px' }}
                      // icon={<VerifiedIcon />}
                      checkedIcon={<Box sx={{ backgroundColor: 'white', borderRadius: '50%', height: '1rem', width: '1rem' }}>
                        <VerifiedIcon sx={{ color: '#39FF14', position: 'relative', margin: '-0.35rem -0.25rem 0', }} />
                      </Box>}
                      checked={deptInfo && deptInfo?.filter((item: any) => item?.dept == "MAF")[0]?.verified}
                      title="Validated"
                      color="success"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 22 }, color: "#007fff" }}
                    /> : <Box style={{ display: 'flex', justifyContent: 'center' }}>
                      <Skeleton sx={{ height: "2rem", width: "1rem", margin: '0.2rem 0.5rem' }} />
                    </Box>}
                  </FormGroup>
                  <Typography className={styles.activeCount}>{deptInfo ? `${deptInfo && deptInfo?.filter((item: any) => item?.dept == "MAF")[0]?.active_count}/${deptInfo && deptInfo?.filter((item: any) => item?.dept == "MAF")[0]?.total_count}` : <Skeleton sx={{ height: "0.8rem", width: "100%", minWidth: '1.2rem', minHeight: '1.3rem' }} />}</Typography>
                </Box>
                <img title="MAF" className={styles.internalIcons}
                  src={MAF}
                  style={{ width: "4rem", height: "auto", cursor: 'pointer', marginTop: '-1.3rem' }}
                />
                <Typography style={{ fontSize: '1rem' }}>Media Architecture & Features</Typography>
                <Typography style={{ fontSize: '1rem' }}>(MAF)</Typography>
              </Box>
            </div>
            <div style={{ margin: '0 1rem' }}>
              <Box
                sx={{
                  borderRadius: "10px",
                  boxShadow:
                    "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
                  marginBottom: "1rem",
                  backgroundColor: dept == "BOM" ? "#ddefff" : "",
                  cursor: "pointer",
                  textAlign: 'center',
                  position: 'relative',
                  paddingBottom: '0.5rem',
                }}
                onClick={() => { history.push(`/project-scope/${projectId}/BOM`) }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <FormGroup>
                    {deptInfo ? <Checkbox
                      style={deptInfo && deptInfo?.filter((item: any) => item?.dept == "BOM")[0]?.verified ? { padding: '7px' } : { padding: '3px' }}
                      // icon={<VerifiedIcon />}
                      checkedIcon={<Box sx={{ backgroundColor: 'white', borderRadius: '50%', height: '1rem', width: '1rem' }}>
                        <VerifiedIcon sx={{ color: '#39FF14', position: 'relative', margin: '-0.35rem -0.25rem 0', }} />
                      </Box>}
                      checked={deptInfo && deptInfo?.filter((item: any) => item?.dept == "BOM")[0]?.verified}
                      title="Validated"
                      color="success"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 22 }, color: "#007fff" }}
                    /> : <Box style={{ display: 'flex', justifyContent: 'center' }}>
                      <Skeleton sx={{ height: "2rem", width: "1rem", margin: '0.2rem 0.5rem' }} />
                    </Box>}
                  </FormGroup>
                  <Typography className={styles.activeCount}>{deptInfo ? `${deptInfo && deptInfo?.filter((item: any) => item?.dept == "BOM")[0]?.active_count}/${deptInfo && deptInfo?.filter((item: any) => item?.dept == "BOM")[0]?.total_count}` : <Skeleton sx={{ height: "0.8rem", width: "100%", minWidth: '1.2rem', minHeight: '1.3rem' }} />}</Typography>
                </Box>
                <img title="BOM" className={styles.internalIcons}
                  src={bomIcon}
                  style={{ width: "3rem", height: "auto", cursor: 'pointer', marginTop: '-1.3rem' }}
                />
                <Typography style={{ fontSize: '1rem' }}>Bill Of Material</Typography>
                <Typography style={{ fontSize: '1rem' }}>(BOM)</Typography>

              </Box>
            </div>
            <div style={{ margin: '0 1rem' }}>
              <Box
                sx={{
                  borderRadius: "10px",
                  boxShadow:
                    "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
                  marginBottom: "1rem",
                  backgroundColor: dept == "Costing" ? "#ddefff" : "",
                  cursor: "pointer",
                  textAlign: 'center',
                  position: 'relative',
                  paddingBottom: '0.5rem',
                }}
                onClick={() => { history.push(`/project-scope/${projectId}/Costing`) }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <FormGroup>
                    {deptInfo ? 
                    
                    <Checkbox
                      style={deptInfo && deptInfo?.filter((item: any) => item?.dept == "Costing")[0]?.verified ? { padding: '7px' } : { padding: '3px' }}
                      // icon={<VerifiedIcon />}
                      checkedIcon={<Box sx={{ backgroundColor: 'white', borderRadius: '50%', height: '1rem', width: '1rem' }}>
                        <VerifiedIcon sx={{ color: '#39FF14', position: 'relative', margin: '-0.35rem -0.25rem 0', }} />
                      </Box>}
                      checked={deptInfo && deptInfo?.filter((item: any) => item?.dept == "Costing")[0]?.verified}
                      title="Validated"
                      color="success"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 22 }, color: "#007fff" }}

                    /> 
                    
                    : <Box style={{ display: 'flex', justifyContent: 'center' }}>
                      <Skeleton sx={{ height: "2rem", width: "1rem", margin: '0.2rem 0.5rem' }} />
                    </Box>}
                  </FormGroup>
                  <Typography className={styles.activeCount}>{deptInfo ? `${deptInfo && deptInfo?.filter((item: any) => item?.dept == "Costing")[0]?.active_count}/${deptInfo && deptInfo?.filter((item: any) => item?.dept == "Costing")[0]?.total_count}` : <Skeleton sx={{ height: "0.8rem", width: "100%", minWidth: '1.2rem', minHeight: '1.3rem' }} />}</Typography>
                </Box> 
                <CurrencyRupeeRoundedIcon titleAccess="CO" sx={{ fontSize: "3rem", cursor: 'pointer', marginTop: '-1.3rem' }} />
                <Typography style={{ fontSize: '1rem' }}>Cost Estimation</Typography>
                <Typography style={{ fontSize: '1rem' }}>(CO)</Typography>
              </Box>
            </div>
            <div style={{ margin: '0 1rem' }}>
              <Box
                sx={{
                  borderRadius: "10px",
                  boxShadow:
                    "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
                  marginBottom: "1rem",
                  backgroundColor: dept == "Idea" ? "#ddefff" : "",
                  cursor: "pointer",
                  textAlign: 'center',
                  position: 'relative',
                  paddingBottom: '0.5rem',
                }}
                onClick={() => { history.push(`/project-scope/${projectId}/Idea`) }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <FormGroup>
                  {deptInfo ? <Checkbox
                    style={deptInfo && deptInfo?.filter((item: any) => item?.dept == "Idea")[0]?.verified ? { padding: '7px' } : { padding: '3px' }}
                    checkedIcon={<Box sx={{ backgroundColor: 'white', borderRadius: '50%', height: '1rem', width: '1rem' }}>
                      <VerifiedIcon sx={{ color: '#39FF14', position: 'relative', margin: '-0.35rem -0.25rem 0', }} />
                    </Box>}
                    checked={deptInfo && deptInfo?.filter((item: any) => item?.dept == "Idea")[0]?.verified}
                    title="Validated"
                    readOnly
                    color="success"
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 22 }, color: "#007fff" }}
                  />
                    : <Box style={{ display: 'flex', justifyContent: 'center' }}>
                      <Skeleton sx={{ height: "2rem", width: "1rem", margin: '0.2rem 0.5rem' }} />
                    </Box>}
                </FormGroup>
                <Typography className={styles.activeCount}>{deptInfo ? `${deptInfo && deptInfo?.filter((item: any) => item?.dept == "Idea")[0]?.active_count}/${deptInfo && deptInfo?.filter((item: any) => item?.dept == "Idea")[0]?.total_count}` : <Skeleton sx={{ height: "0.8rem", width: "100%", minWidth: '1.2rem', minHeight: '1.3rem' }} />}</Typography>
                </Box>
                <img title="Idea" className={styles.internalIcons}
                  src={ideaCreate}
                  style={{ width: "3rem", height: "auto", cursor: 'pointer', marginTop: '-1.3rem' }}
                />
                <Typography style={{ fontSize: '1rem' }}>Idea Generation</Typography>
                <Typography style={{ fontSize: '1rem' }}>(IDEA)</Typography>                
              </Box>
            </div>
          </Box>
        </Box>
        <Box
          sx={{ width: '85%', height: {lg:'85.5vh',xl:'88.5vh'} }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          {apiLoader ? <Table>
            <TableHead
              sx={{
                backgroundColor: "primary.light",
                position: "sticky",
                top: 0,
                zIndex: "1",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    padding: "0 0.5rem",
                    fontSize: "1rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  Seq.
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0 0.5rem",
                    fontSize: "1rem",
                    borderBottomColor: "primary.light",
                    minWidth: "5vw",
                    maxWidth: "5vw",
                  }}
                >
                  Group
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0 0.5rem",
                    fontSize: "1rem",
                    borderBottomColor: "primary.light",
                    minWidth: { lg: "15vw", xl: "18vw" },
                    maxWidth: { lg: "15vw", xl: "18vw" },
                  }}
                >
                  Activity
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0 0.5rem",
                    fontSize: "1rem",
                    borderBottomColor: "primary.light",
                    textAlign: "left",
                    minWidth: { lg: "9vw", xl: "16vw" },
                    maxWidth: { lg: "9vw", xl: "16vw" },
                  }}
                >
                  Products
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0 0.5rem",
                    fontSize: "1rem",
                    borderBottomColor: "primary.light",
                    minWidth: "9vw",
                    maxWidth: "9vw",
                  }}
                >
                  Start Date
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0 0.5rem",
                    fontSize: "1rem",
                    borderBottomColor: "primary.light",
                    minWidth: "5vw",
                    maxWidth: "5vw",
                  }}
                >
                  <Typography
                    sx={{
                      lineHeight: "1.2",
                      textAlign: "center",
                      fontSize: "1rem",
                      fontWeight: "500",
                    }}
                  >
                    Duration (Days)
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0 0.5rem",
                    fontSize: "1rem",
                    borderBottomColor: "primary.light",
                    minWidth: "7vw",
                    maxWidth: "7vw",
                  }}
                >
                  Activity Type
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0 0.5rem",
                    fontSize: "1rem",
                    borderBottomColor: "primary.light",
                    minWidth: "10vw",
                    maxWidth: "10vw",
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.1rem 0.5rem",
                    fontSize: "1rem",
                    borderBottomColor: "primary.light",
                    minWidth: "3vw",
                    maxWidth: "4vw",
                    textAlign: "center",
                    lineHeight: 1.2,
                  }}
                >
                  ON/OFF xcPEP
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0 0.5rem",
                    fontSize: "1rem",
                    borderBottomColor: "primary.light",
                    textAlign: "center",
                    minWidth: { lg: "5vw", xl: "6vw" },
                    maxWidth: { lg: "5vw", xl: "6vw" },
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mileStone &&
                mileStone?.map((item: any, index: number) => {
                  return (
                    <MilestoneRows
                      item={item}
                      products={products}
                      index={index}
                      getDetailsUpdate={getDetailsUpdate}
                      setIdUpdated={setIdUpdated}
                      idUpdated={idUpdated}
                      allProductCheckbox={allProductCheckbox}
                    />
                  );
                })}
            </TableBody>
          </Table> : <>
            <Skeleton sx={{ height: "5rem", width: "100%" }} />
            <Skeleton sx={{ height: "5rem", width: "100%" }} />
            <Skeleton sx={{ height: "5rem", width: "100%" }} />
            <Skeleton sx={{ height: "5rem", width: "100%" }} />
            <Skeleton sx={{ height: "5rem", width: "100%" }} />
            <Skeleton sx={{ height: "5rem", width: "100%" }} />
            <Skeleton sx={{ height: "5rem", width: "100%" }} />
            <Skeleton sx={{ height: "5rem", width: "100%" }} />
            <Skeleton sx={{ height: "5rem", width: "100%" }} />
            <Skeleton sx={{ height: "5rem", width: "100%" }} />
            <Skeleton sx={{ height: "5rem", width: "100%" }} />
            <Skeleton sx={{ height: "5rem", width: "100%" }} />
          </>}
        </Box>
      </Box >
    </div >)
}

export default ProjectScopeLanding;