import { Box, IconButton } from '@mui/material';
import * as React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import LaunchIcon from '@mui/icons-material/Launch';
import FlagIcon from '@mui/icons-material/Flag';
import move from '../../../Assets/images/right move.svg';
import EqualizerIcon from '@mui/icons-material/Equalizer';

export interface IToolsActionComponentsProps {
	cellValues?: any;
	rowId?: any;
    handleListToolRevisionUse?: any;
    handleDelete?: any;
    handleShareWith?: any;
    history?: any;
    tab?: any;
    year?: any;
    month?: any;
    revisionId?: any;
    typeId?: any;
    categoryName?: any;
    status?: any;
    search?: any;    
	useraction?: any;
	setAnchorEl?: any;
}

export default function ToolsActionComponents(
	props: IToolsActionComponentsProps
) {
	const {
		cellValues,
		rowId,
        handleListToolRevisionUse,
        handleDelete,
        history,
        tab,
        year,
        month,
        revisionId,
        typeId,
        categoryName,
        status,
        search,
		handleShareWith,
		useraction,
		setAnchorEl,
	} = props;

	// console.log("cellValues", cellValues);
	// console.log('rowId', rowId);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				width: '4rem',
			}}>
			 {rowId?.mapped_status ? <IconButton title='Tools Info' sx={{ color: '#007fff', padding: '0.5rem' }} onClick={() => handleListToolRevisionUse(rowId?.idGet)}> <InfoIcon /> </IconButton> 
            :( rowId?.lock_status==false && useraction && useraction?.includes("D") )&& <IconButton title='Delete' sx={{ color: '#007fff', padding: '0.5rem' }} onClick={(e: any) => handleDelete(e, rowId?.idGet)}> <DeleteIcon /> </IconButton>}      
            <IconButton title='Redirect' sx={{ color: '#007fff', padding: '0.5rem' }} onClick={() => history.push(`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/toolsDB/${categoryName}/${status}/${search}`)}> <LaunchIcon /> </IconButton>
            {rowId?.flag !== null && <IconButton title='flag' sx={{ color: '#007fff', cursor: 'pointer', padding: '0.5rem' }} onClick={() => rowId?.flag != null && window.open(rowId?.flag)}> <FlagIcon sx={{ color: 'green' }} /> </IconButton>}
            
            {( rowId?.lock_status==false && useraction && useraction?.includes("U")) &&
              <IconButton title='Move' sx={{ color: '#007fff', padding: '0.5rem' }} onClick={() => handleShareWith(rowId?.id, rowId?.toolCode, rowId?.toolName, rowId?.commodity)}> <img src={move} style={{ height: '1.3rem' }} /> </IconButton>}
		</Box>
	);
}
