import React, { useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Divider,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import styles from './SupplierViewPageNew.module.scss';
// import SupplierDirectors from './SupplierDirectors';

interface Record {
  address_country?: string;
  address_district?: string;
  address_line?: string;
  address_pincode?: string;
}

interface SupplierDetails {
  business_name?: string;
  business_full_name: string;
  cin_number?: string;
  registration_number?: string;
  incorporated_date?: string;
  type?: string;
  status?: string;
  pan_number?: string;
  address_line?: string;
  address_pincode?: string;
  gst_number?: string;
  address_city?: string;
  address_country?: string;
  address_district?: string;
  records?: Record[];
  gst_no?: string;
  gst_status?: string;
  business_status?: string;
}

interface Props {
  supplierDetails: SupplierDetails;
}

const StyledCard = styled(Card)(({ theme }) => ({
  minWidth: '200px',
  margin: theme.spacing(1),
  padding: theme.spacing(2),
  // backgroundColor: '#e8f5e9',
  borderRadius: '8px',
  boxShadow: theme.shadows[3],
}));

const SupplierBusinessInfo: React.FC<Props> = ({ supplierDetails }: any) => {
  // console.log(supplierDetails, 'supplierDetails');

  const [showAll, setShowAll] = useState(false);


  const handleShowAllClick = () => {
    setShowAll(!showAll);
  };

  const filteredRecords = Array.isArray(supplierDetails?.records) && supplierDetails?.records?.filter((item: any) =>
    item?.active === true
  )

  const visibleRecords = showAll
    ? filteredRecords
    : filteredRecords && Array.isArray(filteredRecords) && filteredRecords?.slice(0, 4);



  const removeHtmlTags = (htmlString: string): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  };



  return (
    <Box sx={{ padding: '1rem', backgroundColor: '#fff', display: "grid", gridTemplateColumns: { xs: '1fr', md: '50% 50%' }, gap: '1rem' }} className={styles.mainContainer}>
      <Box
        sx={{ gap: '1rem', width: '100%' }}>
        <Typography sx={{ fontWeight: "600", width: "100%" }} gutterBottom>
          Business Information 
        </Typography>

        <Grid container spacing={0.25} sx={{padding:"0 0.5rem", borderRadius: '0.5rem', boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)', width: "100%" }}>
          {supplierDetails?.business_name && (
            <Grid item xs={12} sm={12} >
              <Box sx={{ padding: '0.5rem 1rem', display: 'flex', gap: { xs: '1rem', sm: '2rem' }, }} className={styles.businessDataResponsive}>
                <Typography

                  sx={{ minWidth: '12rem', fontSize: "1rem" }}
                >
                  Business Name:
                </Typography>
                <Typography>
                  {supplierDetails?.business_full_name || 'N/A'}
                </Typography>
              </Box>
            </Grid>
          )}

          {supplierDetails?.incorporated_date && (
            <Grid item xs={12} sm={12}>
              <Box sx={{ padding: '0.5rem 1rem', display: 'flex', gap: { xs: '1rem', sm: '2rem' }, }} className={styles.businessDataResponsive}>
                <Typography

                  sx={{ minWidth: '12rem', fontSize: "1rem" }}
                >
                  Incorporated Date:
                </Typography>
                <Typography>
                  {supplierDetails.incorporated_date || 'N/A'}
                </Typography>
              </Box>
            </Grid>
          )}

          {supplierDetails?.cin_number && (
            <Grid item xs={12} sm={12}>
              <Box sx={{ padding: '0.5rem 1rem', display: 'flex', gap: { xs: '1rem', sm: '2rem' }, fontSize: "0.9rem" }} className={styles.businessDataResponsive}>
                <Typography

                  sx={{ minWidth: '12rem', }}
                >
                  CIN:
                </Typography>
                <Typography>
                  {supplierDetails.cin_number || 'N/A'}
                </Typography>
              </Box>
            </Grid>
          )}

          {supplierDetails?.type && (
            <Grid item xs={12} sm={12}>
              <Box sx={{ padding: '0.5rem 1rem', display: 'flex', gap: { xs: '1rem', sm: '2rem' }, fontSize: "0.9rem" }} className={styles.businessDataResponsive}>
                <Typography

                  sx={{ minWidth: '12rem', }}
                >
                  Type:
                </Typography>
                <Typography>
                  {supplierDetails.type || 'N/A'}
                </Typography>
              </Box>
            </Grid>
          )}


          {supplierDetails?.business_status && (
            <Grid item xs={12} sm={12} sx={{ paddingTop: "4rem" }}>
              <Box sx={{ padding: '0.5rem 1rem', display: 'flex', gap: { xs: '1rem', sm: '2rem' }, fontSize: "0.9rem" }} className={styles.businessDataResponsive}>
                <Typography

                  sx={{ minWidth: '12rem', }}
                >
                  Status:
                </Typography>
                <Typography>
                  {supplierDetails.business_status || 'N/A'}
                </Typography>
              </Box>
            </Grid>
          )}

          {/* {supplierDetails?.gst_number && (
            <Grid item xs={12} sm={12}>
              <Box sx={{ padding: '0.5rem 1rem', display: 'flex', gap: { xs: '1rem', sm: '2rem' }, fontSize: "0.9rem" }} className={styles.businessDataResponsive}>
                <Typography

                  sx={{ minWidth: '12rem', }}
                >
                  GST:
                </Typography>
                <Typography>
                  {supplierDetails.gst_number || 'N/A'}
                </Typography>
              </Box>
            </Grid>
          )} */}

          {supplierDetails?.pan_number && (
            <Grid item xs={12} sm={12}>
              <Box sx={{ padding: '0.5rem 1rem', display: 'flex', gap: { xs: '1rem', sm: '2rem' }, fontSize: "0.9rem" }} className={styles.businessDataResponsive}>
                <Typography

                  sx={{ minWidth: '12rem', }}
                >
                  PAN
                </Typography>
                <Typography>
                  {supplierDetails.pan_number || 'N/A'}
                </Typography>
              </Box>
            </Grid>
          )}

          {supplierDetails?.address_line || supplierDetails?.address_city || supplierDetails?.address_district || supplierDetails?.address_country || supplierDetails?.address_pincode ? (
            <Grid item xs={12} sm={12}>
              <Box sx={{ padding: '0.5rem 1rem', display: 'flex', gap: { xs: '1rem', sm: '2rem' }, fontSize: "0.9rem" }} className={styles.businessDataResponsive}>
                <Typography

                  sx={{ minWidth: '12rem', }}
                >
                  Registered Address:
                </Typography>

               
                <Typography sx={{                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    display: "inline-block",
                    maxWidth: "calc(100% - 12rem)", }}>
                  {`${supplierDetails.address_line || ''}, ${supplierDetails.address_city || ''}, ${supplierDetails.address_district || ''}, ${supplierDetails.address_country || ''}, ${supplierDetails.address_pincode || ''}` || 'N/A'}
                </Typography>
              </Box>
            </Grid>
          ) : null}

        </Grid>
      </Box>

      <Box>
        <Typography sx={{ fontWeight: "600", width: "100%", padding: '0 0.5rem' }} gutterBottom>
          About Business
        </Typography>

        <Box
              id="about_seller"
              className={styles.aboutSellerContainer}

              sx={{
                // borderTop: "0.1px solid #f0f0f0",
                padding: "1.5rem 0",
                height:"16rem",
           borderRadius: '0.5rem', boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)', width: "100%"
               
                
              }}
            >
              {removeHtmlTags(supplierDetails?.about_business) &&
                removeHtmlTags(supplierDetails?.about_business) !== null &&
                removeHtmlTags(supplierDetails?.about_business) !== "" ? (
                <Box
                  className={styles.aboutSeller}
                  sx={{
                    width: "100%",
                    height: "100%",  // Ensure it fits the container height
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 4, // Number of lines to show before truncating
                    WebkitBoxOrient: "vertical",
                    lineHeight: "1.5rem",  // Adjust based on your font size
                    boxSizing: "border-box",
                   
                  }}
                // id="about_seller"
                // // className="inner-container"

                // className="inner-container"
                >
                  {supplierDetails?.about_business &&
                    supplierDetails?.about_business !== null &&
                    supplierDetails?.about_business !== undefined &&
                    supplierDetails?.about_business !== "null" && (
                      <Typography
                        sx={{ textAlign: "justify", overflowY: "scroll", textOverflow: "ellipsis", width: "100%",
                        height: "100%",   padding:"0.5rem 1rem" }}
                        variant="body2"
                        
                        gutterBottom
                      //style={{filter: "blur(10px)", WebkitFilter: "blur(2.5px)"}}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: supplierDetails?.about_business,
                          }}
                        ></div>

                        
                      </Typography>
                    )}
                </Box>
              ) : (
                ""
              )}
             
            </Box>
       
      </Box>

{/* <Box
      sx={{
        width: '100%',
        height: '18.5rem',
        overflow: 'hidden',
        border: '1px solid #ccc',
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
      }}
    >
      <Typography
        variant="body2"
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          display: '-webkit-box',
          WebkitLineClamp: 3,  // Adjust the number of lines as needed
          WebkitBoxOrient: 'vertical',
          whiteSpace: 'normal',
        }}
      >
        <div
          dangerouslySetInnerHTML={{ __html: supplierDetails?.about_business }}
          style={{
            display: 'block',
            width: '100%',
            height: '100%',
            overflowY: 'scroll',
            textOverflow: 'ellipsis',
          }}
        />
      </Typography>
    </Box> */}

    
    </Box>
  );
};

export default SupplierBusinessInfo;