import {
	Table,
	TableBody,
	TableRow,
	TableCell,
	TableHead,
	Box,
	TableContainer,
	ButtonProps,
	Typography,
	useTheme,
} from '@mui/material';
import * as React from 'react';
import SkeletonLoader from '../../SkeletonLoader/SkeletonLoader';
import { useHistory, useParams } from 'react-router-dom';
import styles from './BillingInfoTable.module.scss';
import {
	DataGrid,
	GridColDef,
	GridCsvExportMenuItem,
	GridCsvExportOptions,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarExportContainer,
} from '@mui/x-data-grid';
import { API } from '../../../api-services';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useBaseParamsModal } from '../../../CustomHook/useBaseParamsModal';
import SubscriptionHistoryModal from './SubscriptionHistoryModal';
import useWindowDimensions from '../../../ui-reusable-component/useWindowDimensions';
import ZBCHistoryModal from './ZBCHistoryModal';
import ScenarioRequestHistoryModal from './ScenarioRequestHistoryModal';

export interface IBillingInfoTableProps {
	// billingData: any;
	// loader: any;
}
interface Params {
	type: string;
}

const csvOptions: GridCsvExportOptions = {
	fileName: 'Billing Details In CSV',
	delimiter: ',',
	utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
	<GridToolbarExportContainer {...props}>
		<GridCsvExportMenuItem options={csvOptions} />
	</GridToolbarExportContainer>
);

const CustomToolbar = () => (
	<GridToolbarContainer
		sx={{padding: '0 0.5rem', width: 'fit-content' }}>
		<GridToolbarColumnsButton />
		<CustomExportButton />
	</GridToolbarContainer>
);

export default function BillingInfoTable(props: IBillingInfoTableProps) {
	const theme = useTheme();
	// const { billingData, loader } = props;
	const history = useHistory();
	const { type } = useParams<Params>();
	const subhistroyModal = useBaseParamsModal();
	const zbchistroyModal = useBaseParamsModal();
	const scenarioHistroyModal = useBaseParamsModal();
	// console.log(billingData);
	// const { tabtype, searchquery } = useParams<any>();
	// const [TabAction, setTabAction] = React.useState<any>(tabtype);
	const [billingData, setBillingData] = React.useState<any>([]);
	const [billingLoader, setBillingLoader] = React.useState<boolean>(false);
	const [billingType, setBillingType] = React.useState<string>('');
	const [subHistory, setSubHistory] = React.useState<any>([]);
	const [zbcHistory, setzbcHistory] = React.useState<any>([]);
	const [scenarioHistory, setscenarioHistory] = React.useState<any>([]);
	const {width, height} = useWindowDimensions();
	let columns: GridColDef<(typeof rows)[number]>[] = [];
	let rows: any[] = [];

	const userSubsHistoryHandler = (params: any) => {
		console.log(params, 'history');
		setSubHistory(params.row.subscriptionHistory);
		subhistroyModal.open();
		// console.log(subHistory, 'history');
	};
	const userzbcHistoryHandler = (params: any) => {
		// console.log(params, 'history');
		setzbcHistory(params.row.requestHistory);
		zbchistroyModal.open();
		// console.log(zbcHistory, 'history');
	};
	const userScenarioHistoryHandler = (params: any) => {
		// console.log(params, 'history');
		setscenarioHistory(params.row.scenarioHistory);
		scenarioHistroyModal.open();
		// console.log(zbcHistory, 'history');
	};


	React.useEffect(() => {
		if (type === 'subscription') {
			setBillingType('Subscription');
		} else if (type === 'zbc_request') {
			setBillingType('ZBC Request');
		} else if (type === 'scenario_request') {
			setBillingType('Scenario Request');
		} else if (type === 'add_part') {
			setBillingType('Add Part');
		}
	}, [type]);

	if (type === 'subscription') {
		columns = [
			{
				field: 'sub_user_details',
				headerName: 'View',
				width: 50,
				renderCell: (params) => (
					<Box sx={{ cursor: 'pointer' }}>
						<VisibilityIcon
							titleAccess='Subscription History'
							onClick={() => userSubsHistoryHandler(params)}
						/>
					</Box>
				),
			},
			// { field: 'id', headerName: 'ID', width: 60 },
			{
				field: 'ID',
				headerName: 'User Id',
				minWidth: 100,
				editable: false,
				// flex: 1,
			},

			{
				field: 'company_name',
				headerName: 'Company Name',
				width: 150,
				editable: false,
				flex: 1,
			},
			{
				field: 'name',
				headerName: 'First Name',
				width: 110,
				editable: false,
				flex: 1,
			},
			{
				field: 'email',
				headerName: 'Email',
				description: 'This column has a value getter and is not sortable.',
				sortable: false,
				minWidth: width > 1400 ? 300 : 250,
				flex: 1,				
			},
			{
				field: 'phone',
				headerName: 'Phone',
				description: 'This column has a value getter and is not sortable.',
				sortable: false,
				width: 160,
				flex: 1,
			},
			{
				field: 'is_active',
				headerName: 'Active Status',
				description: 'This column has a value getter and is not sortable.',
				sortable: false,
				width: 160,
				flex: 1,
			},
			{
				field: 'user_type',
				headerName: 'User Type',
				description: 'This column has a value getter and is not sortable.',
				sortable: false,
				width: 160,
				flex: 1,
			},
			{
				field: 'date_joined',
				headerName: 'Date Joined',
				description: 'This column has a value getter and is not sortable.',
				sortable: false,
				width: 160,
				flex: 1,
			},
			{
				field: 'tax_id',
				headerName: 'Tax ID',
				description: 'This column has a value getter and is not sortable.',
				sortable: false,
				width: 160,
				flex: 1,
			},
		];
		rows =
			billingData &&
			billingData?.map((item: any, index: number) => {
				return {
					id: index + 1,
					ID: item.id,
					company_name: item.company_name,
					name: item.first_name,
					email: item.email,
					phone: item.phone,
					is_active: item.is_active,
					user_type: item.user_type,
					date_joined: item.date_joined,
					tax_id: item.tax_id,
					subscriptionHistory: item.subscription
				};
			});
	} else if (type === 'zbc_request') {
		columns = [
			{
				field: 'zbc_request_details',
				headerName: 'View',
				width: 50,
				renderCell: (params) => (					
					<Box sx={{ cursor: 'pointer' }}>
						<VisibilityIcon
							titleAccess='ZBC Request History'
							onClick={() => userzbcHistoryHandler(params)}
						/>
					</Box>
				),				
			},
			// { field: 'id', headerName: 'ID', width: 60 },
			{
				field: 'ID',
				headerName: 'User Id',
				width: 100,
				editable: false,
			},

			{
				field: 'company_name',
				headerName: 'Company Name',
				width: 150,
				editable: false,
				flex: 1,
			},
			{
				field: 'name',
				headerName: 'First Name',				
				width: 110,
				editable: false,
				flex: 1,
			},
			{
				field: 'email',
				headerName: 'Email',
				description: 'This column has a value getter and is not sortable.',
				sortable: false,
				width: 160,
				flex: 1,
			},
			{
				field: 'phone',
				headerName: 'Phone',
				description: 'This column has a value getter and is not sortable.',
				sortable: false,
				width: 140 ,
			},
			{
				field: 'is_active',
				headerName: 'Active Status',
				description: 'This column has a value getter and is not sortable.',
				sortable: false,
				width: width > 1400 ? 160 : 100,
			},
			{
				field: 'user_type',
				headerName: 'User Type',
				description: 'This column has a value getter and is not sortable.',
				sortable: false,
				width: width > 1400 ? 160 : 100,
			},
			{
				field: 'date_joined',
				headerName: 'Date Joined',
				description: 'This column has a value getter and is not sortable.',
				sortable: false,
				width: 160,
			},
			{
				field: 'tax_id',
				headerName: 'Tax ID',
				description: 'This column has a value getter and is not sortable.',
				sortable: false,
				width: 160,
			},
		];
		rows =
			billingData &&
			billingData?.map((item: any, index: number) => {
				return {
					id: index + 1,
					ID: item.id,
					company_name: item.company_name,
					name: item.first_name,
					email: item.email,
					phone: item.phone,
					is_active: item.is_active,
					user_type: item.user_type,
					date_joined: item.date_joined,
					tax_id: item.tax_id,
					requestHistory: item.requests,
				};
			});
	} else if (type === 'scenario_request') {
		columns = [
			{
				field: 'sub_user_details',
				headerName: 'View',
				width: 50,
				renderCell: (params) => (					
					<Box sx={{ cursor: 'pointer' }}>
						<VisibilityIcon
							titleAccess='Scenario Request History'
							onClick={() => userScenarioHistoryHandler(params)}
						/>
					</Box>
				),
			},
			// { field: 'id', headerName: 'ID', width: 60 },
			{
				field: 'ID',
				headerName: 'User Id',
				width: 150,
				editable: false,
			},

			{
				field: 'company_name',
				headerName: 'Company Name',
				width: 150,
				editable: false,
			},
			{
				field: 'first_name',
				headerName: 'First Name',
				type: 'number',
				width: 110,
				editable: false,
			},
			{
				field: 'email',
				headerName: 'Email',
				description: 'This column has a value getter and is not sortable.',
				sortable: false,
				width: 160,
			},
			{
				field: 'phone',
				headerName: 'Phone',
				description: 'This column has a value getter and is not sortable.',
				sortable: false,
				width: 160,
			},
			{
				field: 'is_active',
				headerName: 'Active Status',
				description: 'This column has a value getter and is not sortable.',
				sortable: false,
				width: 160,
			},
			{
				field: 'user_type',
				headerName: 'User Type',
				description: 'This column has a value getter and is not sortable.',
				sortable: false,
				width: 160,
			},
			{
				field: 'date_joined',
				headerName: 'Date Joined',
				description: 'This column has a value getter and is not sortable.',
				sortable: false,
				width: 160,
			},
			{
				field: 'tax_id',
				headerName: 'Tax ID',
				description: 'This column has a value getter and is not sortable.',
				sortable: false,
				width: 160,
			},
		];
		rows =
			billingData &&
			billingData?.map((item: any, index: number) => {
				return {
					id: index + 1,
					ID: item.id,
					company_name: item.company_name,
					name: item.first_name,
					email: item.email,
					phone: item.phone,
					is_active: item.is_active,
					user_type: item.user_type,
					date_joined: item.date_joined,
					tax_id: item.tax_id,
					scenarioHistory: item.requests,
				};
			});
	} else if (type === 'add_part') {
		columns = [
			{
				field: 'sub_user_details',
				headerName: 'View',
				width: 50,
				renderCell: (params) => (
					<Box sx={{ cursor: 'pointer' }}>
						<VisibilityIcon />
					</Box>
				),
			},
			// { field: 'id', headerName: 'ID', width: 60 },
			{
				field: 'ID',
				headerName: 'User Id',
				width: 150,
				editable: false,
			},

			{
				field: 'company_name',
				headerName: 'Company Name',
				width: 150,
				editable: false,
			},
			{
				field: 'first_name',
				headerName: 'First Name',
				type: 'number',
				width: 110,
				editable: false,
			},
			{
				field: 'email',
				headerName: 'Email',
				description: 'This column has a value getter and is not sortable.',
				sortable: false,
				width: 160,
			},
			{
				field: 'phone',
				headerName: 'Phone',
				description: 'This column has a value getter and is not sortable.',
				sortable: false,
				width: 160,
			},
			{
				field: 'is_active',
				headerName: 'Active Status',
				description: 'This column has a value getter and is not sortable.',
				sortable: false,
				width: 160,
			},
			{
				field: 'user_type',
				headerName: 'User Type',
				description: 'This column has a value getter and is not sortable.',
				sortable: false,
				width: 160,
			},
			{
				field: 'date_joined',
				headerName: 'Date Joined',
				description: 'This column has a value getter and is not sortable.',
				sortable: false,
				width: 160,
			},
			{
				field: 'tax_id',
				headerName: 'Tax ID',
				description: 'This column has a value getter and is not sortable.',
				sortable: false,
				width: 160,
			},
		];
		rows =
			billingData &&
			billingData?.map((item: any, index: number) => {
				return {
					id: index + 1,
					ID: item.id,
					company_name: item.company_name,
					name: item.first_name,
					email: item.email,
					phone: item.phone,
					is_active: item.is_active,
					user_type: item.user_type,
					date_joined: item.date_joined,
					tax_id: item.tax_id,
				};
			});
	}

	React.useEffect(() => {
		API.get(
			'/payment/get_all_billing_info/',
			{
				billing_type: type,
			},
			0
		)
			.then((res: any) => {
				console.log(res.data);
			})
			.catch((err: any) => {
				console.log(err);
			});
	}, [type]);

	const billingHandler = (type: any) => {
		// setTabAction(type);
		let billingType = type;		
		setBillingLoader(true);
		API.get(
			'payment/get_all_billing_detail/',
			{
				billing_type: billingType,
			},
			0
		)
			.then((res: any) => {
				setBillingData(res.data);
				history.push(`/user-management/billing-info/${billingType}`);
				setBillingLoader(false);
			})
			.catch((err: any) => {
				console.log(err);
			});
	};


	React.useEffect(() => {
		billingHandler(type);
	}, [type]);

	

	return (
		<div>
			<Box sx={{padding:'0.5rem 2rem'}}>
				<Typography sx={{fontSize:'1.5rem',fontWeight:'600',}}>Billing Info ({billingType}) </Typography>
			</Box>
			{/* <Box className={styles.user_tab_container}>
				<Box
					className={styles.subscription_tab}
					onClick={() => billingHandler('subscription')}
					sx={{
						opacity:
							TabAction == 'subscription' ? 1 : '0.5',
						boxShadow:
							TabAction == 'subscription'
								? '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
								: '',
					}}>
					Subscription
					<span> ({userListCount?.all})</span>
				</Box>
				<Box
					className={styles.zbc_request_tab}
					onClick={() => billingHandler('zbc_request')}
					sx={{
						opacity: TabAction == 'zbc_request' ? 1 : '.5',
						boxShadow:
							TabAction == 'zbc_request'
								? '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
								: '',
					}}>
					ZBC Request
					 <span>( {userListCount?.buyer})</span>
				</Box>
				<Box
					className={styles.scenario_request_tab}
					onClick={() => billingHandler('scenario_request')}
					sx={{
						opacity:
							TabAction == 'scenario_request' ? 1 : '0.5',
						boxShadow:
							TabAction == 'scenario_request'
								? '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
								: '',
					}}>
					Scenario Request
					<span> ({userListCount?.seller})</span> 
				</Box>
				<Box
					className={styles.add_part_tab}
					onClick={() => billingHandler('add_part')}
					sx={{
						opacity: TabAction == 'add_part' ? 1 : '0.5',
						boxShadow:
							TabAction == 'add_part'
								? '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
								: '',
					}}>
					Add Part
					 <span> ({userListCount?.internal})</span>
				</Box>
			</Box> */}
			{subhistroyModal.isOpen && (
				<SubscriptionHistoryModal
					isOpen={subhistroyModal.isOpen}
					onCloseModal={subhistroyModal.close}
					subHistory={subHistory}
				/>
			)}
			{zbchistroyModal.isOpen && (
				<ZBCHistoryModal
					isOpen={zbchistroyModal.isOpen}
					onCloseModal={zbchistroyModal.close}
					zbcHistory={zbcHistory}
				/>
			)}			
			{scenarioHistroyModal.isOpen && (
				<ScenarioRequestHistoryModal
					isOpen={scenarioHistroyModal.isOpen}
					onCloseModal={scenarioHistroyModal.close}
					scenarioHistory={scenarioHistory}
				/>
			)}

			{billingLoader ? (
				<Box sx={{ width: '100%', padding: '0 1rem' }}>
					<SkeletonLoader />
					<SkeletonLoader />
					<SkeletonLoader />
				</Box>
			) : (
				<>
					<Box sx={{backgroundColor:'background.default'}} className={styles.details_table}>
						<DataGrid
							headerHeight={38}
							rowHeight={38}
							rows={rows}
							columns={columns}
							initialState={{
								pagination: {
									// @ts-ignore
									paginationModel: {
										pageSize: 5,
									},
								},
							}}
							sx={{
								'& .MuiDataGrid-cell': {
									color: 'text.primary',
								},
								'&.MuiDataGrid-root .MuiDataGrid-cell': {
									borderBottomColor: 'primary.light',
								},
							}}
							components={{
								Toolbar: () => {
									return CustomToolbar();
								},
							}}
							pageSizeOptions={[5]}
						/>
					</Box>
				</>
			)}
		</div>
	);
}
