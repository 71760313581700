import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  Container,
  IconButton,
  MenuItem,
} from "@mui/material";
import { Formik, Field, Form, ErrorMessage } from "formik";
import VisibilityIcon from "@mui/icons-material/Visibility";
import * as Yup from "yup";
import VerifiedIcon from "@mui/icons-material/Verified";
import { API } from "../../api-services";
import Swal from "sweetalert2";
import { ADMIN } from "../../Redux/Services/admin.service";
import CDN_URL from "../../utlis/CDN_URL";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import SpocInformationModal from "./ContactModal";
import ClientInformationModal from "./ClientsModal";

const validationSchema = Yup.object({
  //       // company_logo: yup.string().required("Company logo URL is required"),
  //       // company_desc: yup.string().required("Company description is required"),
  //       // parts_delivered: yup.string().required("Parts delivered is required"),
  //     })
  //   )
  //   .min(1, "At least one client information entry is required") // Ensures at least one item in the array
  client_information: Yup.array()
    .required("Please Select atleast one item")
    .min(1, "Please select at least one item"),
  vehicle_category: Yup.array()
    .required("Please Select atleast one item")
    .min(1, "Please select at least one item"),
  spoc_information: Yup.array()
    .required("Please enter atleast one contact detail")
    .min(1, "Please enter at least one contact detail"),
  bom_category: Yup.array()
    .required("Please Select atleast one item")
    .min(1, "Please select at least one item"),
  logo: Yup.string().required("Please Enter Business Logo"),
  business_website: Yup.string().required("Please Enter Business Website"),

  about_business: Yup.string().required(
    "Please Enter Something About Business"
  ),
});

export function RenderContent({ fieldName, prefetchedData }: any) {
  return <></>;
}

const VerificationStepEdit2 = ({
  prefetchedDataId,
  handleNext,
  activeStep,
  setActiveStep,
  handleBack,
  counts,
  setCounts,
  request_type,
  steps,
}: any) => {
  const contactModal = useBaseParamsModal();
  const clientModal = useBaseParamsModal();
  const [prefetchedData, setPrefetchedData] = useState<any>(null);
  const [loaded, setLoaded] = React.useState(false);
  const [verificationStatus, setVerificationStatus] = useState<any>({
    vehicle_category: { verified: false, rejected: true },
    bom_category: { verified: false, rejected: true },
    logo: { verified: false, rejected: true },
    business_website: { verified: false, rejected: true },
    about_business: { verified: false, rejected: true },

    client_information: { verified: false, rejected: true },

    spoc_information: { verified: false, rejected: true },
  });

  const getPreFetchedData = () => {
    API.get("/cart/get_all_claim_requests/", { id: prefetchedDataId }, 0)
      .then((res: any) => {
        setPrefetchedData(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    if (prefetchedDataId) {
      getPreFetchedData();
    }
  }, [prefetchedDataId]);

  const handleVerificationToggle = (fieldName: any) => {
    if (!loaded) {
      setLoaded(true);
    }
    ADMIN.toast.info("Verification Successful");
    setVerificationStatus((prevStatus: any) => ({
      ...prevStatus,
      [fieldName]: {
        verified: !prevStatus[fieldName].verified,
        rejected: prevStatus[fieldName].verified ? true : false, // Toggle rejected based on the new verified state
      },
    }));
  };

  const handleToggleVerification = () => {
    if (!loaded) {
      setLoaded(true);
    }
    ADMIN.toast.info("Verification Successful");
    const allVerified = Object.keys(verificationStatus).every(
      (key) => verificationStatus[key].verified === true
    );

    const updatedStatus = Object.keys(verificationStatus).reduce(
      (acc: any, key: any) => {
        acc[key] = { ...verificationStatus[key], verified: !allVerified };
        return acc;
      },
      {}
    );

    setVerificationStatus(updatedStatus);
  };

  useEffect(() => {
    setVerificationStatus((prevStatus: any) => ({
      ...prevStatus,
      vehicle_category: {
        ...prevStatus.vehicle_category,
        verified:
          prefetchedData?.verification_status?.vehicle_category || false,
      },
      bom_category: {
        ...prevStatus.bom_category,
        verified: prefetchedData?.verification_status?.bom_category || false,
      },
      logo: {
        ...prevStatus.logo,
        verified: prefetchedData?.verification_status?.logo || false,
      },
      business_website: {
        ...prevStatus.business_website,
        verified:
          prefetchedData?.verification_status?.business_website || false,
      },

      about_business: {
        ...prevStatus.about_business,
        verified: prefetchedData?.verification_status?.about_business || false,
      },
      client_information: {
        ...prevStatus.client_information,
        verified:
          prefetchedData?.verification_status?.client_information || false,
      },
      spoc_information: {
        ...prevStatus.spoc_information,
        verified:
          prefetchedData?.verification_status?.spoc_information || false,
      },
    }));
  }, [prefetchedData?.verification_status]);

  const handleSubmit = () => {
    const simplifiedStatus: any = {};
    Object.keys(verificationStatus).forEach((key: any) => {
      simplifiedStatus[key] = verificationStatus[key].verified;
    });

    API.put(`cart/get_all_claim_requests/${prefetchedDataId}/`, {
      ...simplifiedStatus,
      [request_type === "Create"
        ? "validate_create_business"
        : "validate_claim_business"]: true,
    })
      .then((res) => {
        getPreFetchedData();

        // setClaimedData(res.data?.data)
        // Swal.fire({
        //   icon: "success",
        //   title: "Informations submitted successfully",
        // });
      })

      .catch((err: any) => {
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
        });
      });
  };

  useEffect(() => {
    if (loaded) {
      handleSubmit();
      setLoaded(false);
    }
  }, [verificationStatus, loaded]);

  const verifiedCount = Object.keys(verificationStatus).filter(
    (key) => verificationStatus[key].verified === true
  )?.length;
  useEffect(() => {
    setCounts({ ...counts, v2: verifiedCount });
  }, [verifiedCount]);


  const truncateString = (str: any, maxLength: any) => {
    if (str?.length <= maxLength) {
      return str; // Return the full string if it's less than or equal to maxLength
    } else {
      return str?.slice(0, maxLength - 3) + "..."; // Truncate and add "..." for longer strings
    }
  };

  return (
    <>

    {contactModal.isOpen && (
      <SpocInformationModal isOpen={contactModal.isOpen} onCloseModal={()=> contactModal?.close()} spocInformation={contactModal?.propsId} />
    )}
     {clientModal.isOpen && (
      <ClientInformationModal isOpen={clientModal.isOpen} onCloseModal={()=> clientModal?.close()} clientInformation={clientModal?.propsId} />
    )}

<Box sx={{ padding: "1rem " }}>
      <Box
        sx={{
          marginTop: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Formik
          initialValues={{
            vehicle_category: prefetchedData
              ? prefetchedData.vehicle_category
              : [],

            bom_category: prefetchedData ? prefetchedData.bom_category : [],
            logo: prefetchedData ? prefetchedData.logo : "",
            business_website: prefetchedData
              ? prefetchedData.business_website
              : "",
            about_business: prefetchedData ? prefetchedData.about_business : "",

            client_information: prefetchedData
              ? prefetchedData.client_information
              : [],
            spoc_information: prefetchedData
              ? prefetchedData.spoc_information
              : [],
          }}
          // validationSchema={validationSchema}
          onSubmit={(values: any) => {
            handleNext();
            // const simplifiedStatus:any = {};
            // Object.keys(verificationStatus).forEach((key:any) => {
            //   simplifiedStatus[key] = verificationStatus[key].verified;
            // });

            //   API.put(
            //     `cart/get_all_claim_requests/${prefetchedDataId}/`,
            //     {...simplifiedStatus, validate_claim_business:true}
            //   )
            //     .then((res) => {
            //       getPreFetchedData();

            //       // setClaimedData(res.data?.data)
            //       // Swal.fire({
            //       //   icon: "success",
            //       //   title: "Informations submitted successfully",
            //       // });
            //       handleNext();
            //     })

            //     .catch((err: any) => {
            //       Swal.fire({
            //         icon: "error",
            //         title: "Something went wrong",
            //       });
            //     });
          }}
          enableReinitialize={true} // Enable reinitialization when initialValues change
        >
          {({ errors, touched }: any) => (
            <Form>
              <Box sx={{ width: "100%" }}>
              <Box
                  sx={{ display: "flex", width: "100%", background: "#00887a" }}
                >
                  <Box
                    sx={{
                      padding: "1rem",
                      width: "18vw",
                      color: "#fff",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Field
                  </Box>
                  <Box
                    sx={{
                      padding: "1rem",
                      width: "18vw",
                      color: "#fff",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Old Value
                  </Box>
                  <Box
                    sx={{
                      padding: "1rem",
                      width: "18vw",
                      color: "#fff",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    New Value
                  </Box>
                  <Box
                    sx={{
                      padding: "1rem",
                      width: "18vw",
                      color: "#fff",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Verify{" "}
                    <span
                      style={{ cursor: "pointer", marginLeft: "1rem" }}
                      onClick={handleToggleVerification}
                    >
                      {`(Verify All)`}
                    </span>
                  </Box>
                </Box>
                {Object.keys(validationSchema?.fields).map(
                  (fieldName: any, index: any) => {
                 

                    return (
                      <Box
                        key={index}
                        // sx={{
                        //   padding: "1rem",
                        //   width: "25vw",
                        //   color: "#fff",
                        //   borderBottomColor: "primary.light",
                        // }}
                        sx={{
                          border: "0.1px solid primary.light",
                          display: "flex",
                        }}
                      >
                        <Box
                          key={index}
                          sx={{
                            width: "18vw",
                            padding: "1rem",
                            border: "0.1px solid #dee0e0",
                            display: "flex",
                          }}
                        >
                          {fieldName
                            ?.replace(/_/g, " ")
                            ?.replace(/\b\w/g, (char: any) =>
                              char.toUpperCase()
                            )}
                        </Box>
                        <Box
                          key={index}
                          sx={{
                            width: "18vw",
                            padding: "1rem",
                            border: "0.1px solid #dee0e0",
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          {prefetchedData?.old_data &&
                          Array.isArray(prefetchedData?.old_data?.[fieldName]) ? (
                            prefetchedData?.old_data?.[fieldName].length > 0 ? (
                              <>
                                {(fieldName === "vehicle_category" ||
                                fieldName === "bom_category")
                                  ? prefetchedData?.old_data?.[fieldName].map(
                                      (doc: any, index: any) => {
                                        return prefetchedData?.old_data?.[fieldName]
                                          ?.length > 0
                                          ? prefetchedData?.old_data?.[fieldName]
                                              ?.map((item: any) => item.name)
                                              ?.join(", ")
                                          : "-";
                                      }
                                    )
                                  : (fieldName === "spoc_information" || fieldName === "client_information") ? (
                                    <IconButton
                                    
                                    aria-haspopup="true"
                                  >
                                    <VisibilityIcon
                                        titleAccess=""
                                        sx={{ cursor: "pointer" }}
                                        style={{ color: "#569479" }}
                                        onClick={(e) => {
                                          if (fieldName === "spoc_information") {
                                            contactModal.open(prefetchedData?.old_data?.[fieldName])
                                          }
                                          if (fieldName === "client_information") {
                                            clientModal.open(prefetchedData?.old_data?.[fieldName])
                                          }                                 
                                        }}
                                       />
                                    </IconButton>
                                  ) :""}
                              </>
                            ) : (
                              <Typography variant="body2">--</Typography>
                            )
                          ) : (
                            <>
                            {fieldName === "logo" ? (
                              <VisibilityIcon      sx={{ cursor: "pointer" }}
                              style={{ color: "#569479" }}   onClick={() => {
                                prefetchedData?.old_data?.[fieldName] && window.open(`${CDN_URL}${prefetchedData?.old_data?.[fieldName]}`);
                              }} />
                            ) : fieldName === "about_business" ? (
                              truncateString(prefetchedData?.old_data?.[fieldName], 150)
                            )
                            : fieldName === "business_website" ? (
                              <a href={prefetchedData?.old_data?.[fieldName]} style={{color:"#00887a"}}  target="_blank"
                              rel="noopener noreferrer">
                              {prefetchedData?.old_data?.[fieldName]}
                            </a>
                            ) 
                            : ""} 
                            </>
                          )}
                        </Box>
                        <Box
                          key={index}
                          sx={{
                            width: "18vw",
                            padding: "1rem",
                            border: "0.1px solid #dee0e0",
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          {prefetchedData &&
                          Array.isArray(prefetchedData[fieldName]) ? (
                            prefetchedData[fieldName].length > 0 ? (
                              <>
                                {(fieldName === "vehicle_category" ||
                                fieldName === "bom_category")
                                  ? prefetchedData[fieldName].map(
                                      (doc: any, index: any) => {
                                        return prefetchedData?.[fieldName]
                                          ?.length > 0
                                          ? prefetchedData?.[fieldName]
                                              ?.map((item: any) => item.name)
                                              ?.join(", ")
                                          : "-";
                                      }
                                    )
                                  : (fieldName === "spoc_information" || fieldName === "client_information") ? (
                                    <IconButton
                                    
                                    aria-haspopup="true"
                                  >
                                    <VisibilityIcon
                                        titleAccess=""
                                        sx={{ cursor: "pointer" }}
                                        style={{ color: "#569479" }}
                                        onClick={(e) => {
                                          if (fieldName === "spoc_information") {
                                            contactModal.open(prefetchedData[fieldName])
                                          }
                                          if (fieldName === "client_information") {
                                            clientModal.open(prefetchedData[fieldName])
                                          }                                 
                                        }}
                                       />
                                    </IconButton>
                                  ) :""}
                              </>
                            ) : (
                              <Typography variant="body2">--</Typography>
                            )
                          ) : (
                            <>
                            {fieldName === "logo" ? (
                              <VisibilityIcon      sx={{ cursor: "pointer" }}
                              style={{ color: "#569479" }}   onClick={() => {
                                prefetchedData?.[fieldName] && window.open(`${CDN_URL}${prefetchedData?.[fieldName]}`);
                              }} />
                            ) : fieldName === "about_business" ? (
                              truncateString(prefetchedData?.[fieldName], 150)
                            )
                            : fieldName === "business_website" ? (
                              <a href={prefetchedData?.[fieldName]} style={{color:"#00887a"}}  target="_blank"
                              rel="noopener noreferrer">
                              {prefetchedData?.[fieldName]}
                            </a>
                            ) 
                            : ""} 
                            </>
                          )}
                        </Box>

                        <Box
                          key={index}
                          sx={{
                            width: "18vw",
                            padding: "1rem",
                            border: "0.1px solid #dee0e0",
                            display: "flex",
                            // justifyContent: "center"
                          }}
                        >
                          {verificationStatus[fieldName].verified ? (
                            <VerifiedIcon
                              sx={{ cursor: "pointer", color: "green" }}
                              titleAccess="Verified"
                              onClick={() =>
                                handleVerificationToggle(fieldName)
                              }
                            />
                          ) : (
                            <VerifiedIcon
                              sx={{ cursor: "pointer", color: "#ffc800" }}
                              titleAccess="Not Verified"
                              onClick={() =>
                                handleVerificationToggle(fieldName)
                              }
                            />
                          )}
                        </Box>
                      </Box>
                    );
                  }
                )}
              </Box>
              <Grid
                container
                justifyContent="space-between"
                spacing={2}
                sx={{ mt: 2 }}
              >
                <Grid item>
                  <Button onClick={handleBack}>Previous</Button>
                </Grid>
                <Grid item>
                  <Box sx={{ display: "flex", gap: "1rem" }}>
                    {/* <Button
                        size="small"
                        type="button"
                        variant="contained"
                        sx={{
                          background:"#d65151",
                          "&:hover": {
                            background:"#f21313",
                            transform: "Scale(1.05)",
                            transition: "transform 0.5s ease",
                          },
                        }}
                      >
                        Reject
                      </Button> */}
                    <Button variant="contained" color="primary" type="submit">
                      Next
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              {/* <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    select
                    name="business_type"
                    variant="outlined"
                    fullWidth
                    label="Type of Business"
                    error={touched.business_type && Boolean(errors.business_type)}
                    helperText={<ErrorMessage name="business_type" />}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <IconButton
                          edge="end"
                          aria-label="verification-toggle"
                          onClick={() => handleVerificationToggle("business_type")}
                        >
                          {verificationStatus.business_type.verified ? (
                            <VerifiedIcon sx={{ color: "green" }} titleAccess="Verified" />
                          ) : (
                            <VerifiedIcon sx={{ color: "#ffc800" }} titleAccess="Not Verified" />
                          )}
                        </IconButton>
                      ),
                    }}
                  >
                    <MenuItem value="0-50 Million">0-50 Million</MenuItem>
                    <MenuItem value="50-500 Million">50-500 Million</MenuItem>
                    <MenuItem value="Large > 500 Million">{`Large > 500 Million`}</MenuItem>
                  </Field>
                </Grid>
                {["business_registration_no", "issuing_authority"].map((fieldName, index) => (
                  <Grid item xs={12} key={index}>
                    <Field
                      as={TextField}
                      name={fieldName}
                      variant="outlined"
                      fullWidth
                      label={fieldName.replace(/_/g, " ").replace(/\b\w/g, (char: any) => char.toUpperCase())}
                      error={touched[fieldName] && Boolean(errors[fieldName])}
                      helperText={<ErrorMessage name={fieldName} />}
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <IconButton
                            edge="end"
                            aria-label="verification-toggle"
                            onClick={() => handleVerificationToggle(fieldName)}
                          >
                            {verificationStatus[fieldName].verified ? (
                              <VerifiedIcon sx={{ color: "green" }} titleAccess="Verified" />
                            ) : (
                              <VerifiedIcon sx={{ color: "#ffc800" }} titleAccess="Not Verified" />
                            )}
                          </IconButton>
                        ),
                      }}
                    />
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="registration_date"
                    variant="outlined"
                    fullWidth
                    label="Business Registration Date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={touched.registration_date && Boolean(errors.registration_date)}
                    helperText={<ErrorMessage name="registration_date" />}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <IconButton
                          edge="end"
                          aria-label="verification-toggle"
                          onClick={() => handleVerificationToggle("registration_date")}
                        >
                          {verificationStatus.registration_date.verified ? (
                            <VerifiedIcon sx={{ color: "green" }} titleAccess="Verified" />
                          ) : (
                            <VerifiedIcon sx={{ color: "#ffc800" }} titleAccess="Not Verified" />
                          )}
                        </IconButton>
                      ),
                    }}
                  />
                </Grid>
              </Grid> */}
              {/* <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2 }}
                disabled
              >
                Submit
              </Button> */}
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
    
    </>
   
  );
};

export default VerificationStepEdit2;
