import { Box, IconButton, Typography } from "@mui/material";
import * as React from "react";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ActivityLogsClient from "../CostingCreateModule/ActivityLogsClient";
import ActivitylogTree from "./ActivitylogTree";

export interface IActivityMainPageProps {}

export function ActivityMainPage(props: IActivityMainPageProps) {
  const history = useHistory();
  const [option, setOption] = React.useState({});
  const { activityType, userId } = useRouteParams<any>();
  const handleClickBack = () => {
    history.push("/");
  };
  const getType = (type: string) => {
    switch (type) {
      case "buyers":
        return "Buyers";
      case "sellers":
        return "Manufacturers";
      case "preloggedin":
        return "Pre Logged In";
      default:
        return "Buyers";
    }
  }

  return (
    <Box>
      <Box>
        <IconButton sx={{ cursor: "pointer" }} onClick={handleClickBack}>
          <ArrowBackIcon color="primary" titleAccess="Go Back" />
        </IconButton>
        <Typography
          sx={{
            fontSize: "1rem",
            whiteSpace: "nowrap",
            display: "inline-block",
          }}
        >
          Activity Logs ( {`${getType(activityType)} `} )
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "1.5rem",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            backgroundColor: "",
            height: "86vh",
            width: "25%",
            marginLeft: "1rem",
          }}
        >
          <ActivitylogTree setOption={setOption} />
        </Box>
        <Box sx={{ height: "100%", width: "75%" }}>
          {/* <Box sx={{ marginLeft: "2rem", marginRight: "2rem" }}>
          <ActivityLogsCards />
        </Box> */}
          <Box sx={{  marginRight: "2rem",height:'86vh'}}>
            {( userId != 0)? (
              <ActivityLogsClient activityTypeProps={activityType} option={option} />
            ) : (
              <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',}}>
                <Typography sx={{color:'primary.main'}}> Please select a subscription </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
