// react
import React, { useEffect, useRef, useState } from 'react';

// vendors
import update from 'immutability-helper';
// ui-components
// others
// styles
import './BOMImageMeasurement.module.scss';
import { ADMIN } from '../../../../Redux/Services/admin.service';
import { API } from '../../../../api-services';
import { useRouteParams } from '../../../../ui-reusable-component/useRouteParams';
import useFetch from '../../../../Core/CustomHooks/useFetch';
import { Project } from '../../../../Redux/Reducers/project.reducer';
import projectService from '../../../../Redux/Services/project.service';
import {
	Box,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import styles from './BOMImageMeasurement.module.scss';
import { random } from 'lodash';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import InfoIcon from '@mui/icons-material/Info';
import Select from '@mui/material/Select';

// ----------------------------------------------------------------------------------------------
// helpers

/**
 * returns the width an height of the element without margin,
 * if "getComputedStyle" is present in window object
 */
const getWHforEL = (el: any) => {
	if ('getComputedStyle' in window) {
		var computedStyle = getComputedStyle(el);

		let elementHeight = el.clientHeight; // height with padding
		let elementWidth = el.clientWidth; // width with padding

		elementHeight -=
			parseFloat(computedStyle.marginTop) +
			parseFloat(computedStyle.marginBottom);
		elementWidth -=
			parseFloat(computedStyle.marginLeft) +
			parseFloat(computedStyle.marginRight);

		return {
			width: elementWidth,
			height: elementHeight,
		};
	}

	return {
		width: el.clientWidth,
		height: el.clientHeight,
	};
};

/**
 * returns the resized width and height with aspect ratio in tact
 */
const getAspectWH = (
	width: number,
	height: number,
	maxWidth: number,
	maxHeight: number
) => {
	// console.log(width, height, maxWidth, maxHeight, 'aspectWidth');
	// if (width <= maxWidth && height <= maxHeight) >>>> (width=width; height=height)
	if (width <= maxWidth && height <= maxHeight) {
		// If the image is smaller than both maxWidth and maxHeight, fit it to the maximum dimension
		const maxDimension = Math.max(width, height);
	
		return {
		  width: (width / maxDimension) * maxWidth,
		  height: (height / maxDimension) * maxHeight,
		};
	  }
	
	  const aspectRatio = width / height;
	
	  if (aspectRatio > maxWidth / maxHeight) {
		// If the image is wider than the maxWidth allows
		return {
		  width: maxWidth,
		  height: maxWidth / aspectRatio,
		};
	  } else {
		// If the image is taller than the maxHeight allows
		return {
		  width: maxHeight * aspectRatio,
		  height: maxHeight,
		};
	  }
	
};

// ----------------------------------------------------------------------------------------------
// constants

const unitOptions = [
	{
		key: 'cm',
		value: 'cm',
		text: 'cm',
	},
	{
		key: 'mm',
		value: 'mm',
		text: 'mm',
	},
	{
		key: 'm',
		value: 'm',
		text: 'm',
	},
];

function operation(num1: number, num2: number, type: 'add' | 'sub') {
	return type === 'add' ? num1 + num2 : num1 - num2;
}

// ----------------------------------------------------------------------------------------------

interface BOMImageMeasurementProps {}

interface Params {
	topVault: any;
	vault: any;
	image_id: any;
	vehCateId: any;
	vehCateName: any;
}

const BOMImageMeasurement = (props: BOMImageMeasurementProps) => {
	const { topVault, vault, image_id, vehCateId, vehCateName } =
		useRouteParams<Params>();
	const canvasRef = useRef<any>(null);
	const imageRef = useRef<any>(null);
	const [mouseDown, setMouseDown] = useState<boolean>(false);
	const [previousPointX, setPreviousPointX] = useState<any>(null); //To draw line from starting line
	const [previousPointY, setPreviousPointY] = useState<any>(null); //---------------||------------------
	const [constant, setConstant] = useState<number | null>(null); //constant multiplier
	const [benchMark, setBenchMark] = useState<any>(''); //Initial Value
	const [unit, setUnit] = useState<string>('');
	const [lines, setLines] = useState<any>([]); //Storing all the lines drawn
	const [coOrdinates, setCoOrdinates] = useState<any>({}); //CoOrdinates object to push into lines array
	const [imageURL, setImageURL] = useState<string>('');
	const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
	
	const [changeVariable, setChangeVariable] = useState<boolean>(true); //Used as toggler to run redraw function
	const canvasWrapperEl = useRef<any>(null);
	// get project info, for displaying box dimensions
	const { 1: project } = useFetch<Project>({
		method: projectService.getById,
		args: [vehCateId ?? 0],
		condition: Boolean(vehCateId),
		fallBackErrMsg: 'Could not fetch project',
		getZeroth: true,
	});

	const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

	const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	useEffect(() => {
		API.get(
			`/bom/bom_create_view/`,
			{
				image_id: image_id,
				vault: vault == '0' ? null : vault,
				top_vault: vault == '0' ? topVault : null,
			},
			0
		).then((res: any) => {
			setImageURL(res.data?.image_url);
		});
	}, []);

	useEffect(() => {
		const canvas = canvasRef.current;
		const img = imageRef.current;

		const ctx = canvas.getContext('2d');
		ctx.clearRect(0, 0, canvas.width, canvas.height);

		ctx.drawImage(img, 0, 0, imageDimensions.width, imageDimensions.height);

		drawLines(lines, ctx);
	}, [changeVariable]);

	const handleMouseDown = (e: any) => {
		if (benchMark === '') {
			return ADMIN.toast.error('Please Enter Initial Value');
		}
		setMouseDown(true);
		const canvas = canvasRef.current;
		const rect = canvas.getBoundingClientRect();

		var x = e.nativeEvent.clientX - rect.left;
		var y = e.nativeEvent.clientY - rect.top;

		setPreviousPointX(x);
		setPreviousPointY(y);
		const newCoOrdinates = update(coOrdinates, {
			['x1']: { $set: x },
			['y1']: { $set: y },
		});
		setCoOrdinates(newCoOrdinates);
		var ctx = canvas.getContext('2d');
		ctx.beginPath();
		ctx.moveTo(x, y);
		ctx.lineTo(x, y);
		ctx.stroke();
	};

	//Todo draw line on move
	const handleMouseMove = (e: any) => {
		if (!mouseDown) return;

		const canvas = canvasRef.current;
		const img = imageRef.current;
		const rect = canvas.getBoundingClientRect();

		var x2: any = e.nativeEvent.clientX - rect.left;
		var y2: any = e.nativeEvent.clientY - rect.top;

		const ctx = canvas.getContext('2d');
		ctx.clearRect(0, 0, canvas.width, canvas.height);
		ctx.drawImage(img, 0, 0, imageDimensions.width, imageDimensions.height);

		drawLines(lines, ctx);

		let strokeStyle;
		if (constant === null) {
			strokeStyle = '#FF0000';
		} else {
			strokeStyle = '#03fc3d';
		}
		ctx.beginPath();
		ctx.moveTo(previousPointX, previousPointY);
		ctx.lineTo(x2, y2);
		ctx.strokeStyle = strokeStyle;
		ctx.lineWidth = 3;
		ctx.font = ctx.font.replace(/\d+px/, '20px');
		ctx.stroke();
	};

	/** paints/draw lines that are already dropped(created) */
	const drawLines = (lines: any[], ctx: CanvasRenderingContext2D) => {
		lines.forEach((el: any, index: any) => {
			ctx.beginPath();

			ctx.moveTo(el.x1, el.y1);
			ctx.lineTo(el.x2, el.y2);

			let displayLength;
			let strokeColor;
			if (index === 0) {
				displayLength = el.value;
				strokeColor = '#FF0000';
			} else {
				displayLength = el.value;
				strokeColor = '#03fc3d';
			}
			ctx.strokeStyle = strokeColor;
			ctx.lineWidth = 3;
			ctx.font = ctx.font.replace(/\d+px/, '20px');
			ctx.fillText(`${displayLength?.toFixed(2)}${unit}`, el.x2, el.y2);
			ctx.stroke();

			ctx.closePath();
		});
	};

	const handleMouseUp = (e: any) => {
		if (benchMark === '') return;
		setMouseDown(false);
		const canvas = canvasRef.current;
		const rect = canvas.getBoundingClientRect();

		var x2: any = e.nativeEvent.clientX - rect.left;
		var y2: any = e.nativeEvent.clientY - rect.top;

		var ctx = canvas.getContext('2d');
		ctx.moveTo(previousPointX, previousPointY);
		ctx.lineTo(x2, y2);

		let displayLength: any;
		let strokeStyle;
		const x = Math.pow(x2 - previousPointX, 2);
		const y = Math.pow(y2 - previousPointY, 2);
		const total = x + y;
		const length: any = Math.sqrt(total);
		if (constant === null) {
			const constant = benchMark / length;
			setConstant(constant);
			displayLength = benchMark;
			strokeStyle = '#FF0000';
		} else {
			displayLength = constant * length;
			strokeStyle = '#03fc3d';
		}
		const newCoOrdinates = update(coOrdinates, {
			['x2']: { $set: x2 },
			['y2']: { $set: y2 },
			['value']: { $set: displayLength },
			['op']: { $set: 'add' },
		});
		setCoOrdinates(newCoOrdinates);
		ctx.strokeStyle = strokeStyle;
		ctx.lineWidth = 3;
		ctx.font = ctx.font.replace(/\d+px/, '20px');
		ctx.fillText(`${displayLength?.toFixed(2)}${unit}`, x2, y2);
		ctx.stroke();
		ctx.closePath();
		setLines((prev: any) => [...prev, newCoOrdinates]);
	};

	const onChangeHandler = (e: any) => {
		const number = parseFloat(e.target.value);
		setBenchMark(number);
	};

	const selectChangeHandler = (e: any) => {
		setUnit(e.target.value);
	};

	const onImgLoad = (e: any) => {
		let width = imageRef.current.width;
		let height = imageRef.current.height;
		const { width: maxWidth, height: maxHeight } = getWHforEL(
			canvasWrapperEl.current
		);


		// Ensure the canvas dimensions match the container dimensions
		if(width > maxWidth || height > maxHeight){
			const { width: newWidth, height: newHeight } = getAspectWH(
				width,
				height,
				maxWidth,
				maxHeight
			);
			width = newWidth;
			height = newHeight;
		}
		setImageDimensions({ width, height });
		
		// Ensure the canvas dimensions match the container dimensions
		canvasRef.current.width = maxWidth;
		canvasRef.current.height = maxHeight;

		setChangeVariable((prev) => !prev);
	};

	const clearLine = (line: any, index: any) => {
		let lineState = JSON.parse(JSON.stringify(lines));
		lineState.splice(index, 1);
		setLines(lineState);
		setChangeVariable((prev: any) => !prev);
	};

	

	const handleChangeOp = (line:any, index: number) => (e: React.ChangeEvent) => {
		const val = (e.target as HTMLSelectElement).value as any;
		const updated = update(lines, {
			[index]: {
				op: { $set: val },
			},
		});

		setLines(updated);
	};

	// const getTotal = () => {
	// 	return (lines as any[])
	// 		.reduce((result, el, index) => {
	// 			return index ? operation(result, el.value, el.op) : result;
	// 		}, 0)
	// 		.toFixed(2);
	// };

	const getTotal = () => {
		if (lines.length === 0) {
		  return '0.00';
		}
	  
		let total = 0;
	  
		for (let i = 0; i < lines.length; i++) {
		  const { value, op } = lines[i];
		  if (op === 'add') {
			total = operation(total, value, op);
		  } else if (op === 'sub') {
			// Only subtract the value if it's the selected row
			if (lines[i].selected) {
			  total -= value;
			}
		  }
		}
	  
		return total.toFixed(2);
	  };
	
	return (
		<React.Fragment>
			<Box className={styles.imageMeasurementContainer}>
				<div className={styles.imageMeasurementWrapper}>
					<Box className={styles.measurements}>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								columnGap: '1rem',								
                                height:'2rem',
                                backgroundColor:'#ddefff',
                                borderTopLeftRadius:'10px',
							}}>
							<Box
								style={{
									display: 'flex',
									alignItems: 'center',
                                    justifyContent:'center',
									width: '100%',
									padding: '0 0.5rem',                                    
								}}>
								<Typography sx={{ fontSize: '1rem',fontWeight:'600', }}>
									Image Measurment
								</Typography>
							</Box>
						</Box>
						<div
							style={{
								display: 'flex',
								justifyContent: 'flex-start',
								alignItems: 'flex-end',
								columnGap: '1rem',
                                padding:'0.2rem 1rem',
							}}>
							<FormControl fullWidth variant='standard'>
								<TextField
									type='number'
									variant='standard'
									label='Initial reference value'
									//   placeholder="Initial reference value"
									value={benchMark}
									onChange={onChangeHandler}
									inputProps={{
										step: 'any',
										min: 0,
										style: { fontSize: '1rem' },
									}}
									InputLabelProps={{
										shrink: true,
										sx: {
											color: 'primary.main',
										},
									}}
									sx={{
										width: '100%',
										borderBottomColor: 'primary.light',
										'& .MuiInput-underline:before': {
											borderBottomColor: 'primary.light',
										},
										'& .MuiInput-underline:after': {
											borderBottomColor: 'primary.main',
										},
									}}
								/>
							</FormControl>
							<FormControl fullWidth variant='standard'>
								<InputLabel
									sx={{ color: 'primary.main', marginTop: '0.5rem' }}
									shrink>
									Select a unit
								</InputLabel>
								<Select
									labelId='demo-simple-select-standard-label'
									id='demo-simple-select-standard'
									value={unit}
									onChange={selectChangeHandler}
									sx={{
										width: '100%',
										'&:before': {
											borderBottomColor: 'primary.light',
										},
										'& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select':
											{
												fontSize: '1rem',
											},
										'.MuiSvgIcon-root.MuiSelect-icon': {
											color: 'primary.main',
										},
									}}>
									{unitOptions &&
										unitOptions?.map((item: any) => {
											return (
												<MenuItem value={item.value}>{item.text}</MenuItem>
											);
										})}
								</Select>
							</FormControl>
							<Typography
								sx={{ width: '2rem' }}
								aria-owns={open ? 'mouse-over-popover' : undefined}
								aria-haspopup='true'
								onMouseEnter={handlePopoverOpen}
								onMouseLeave={handlePopoverClose}>
								<InfoIcon />
							</Typography>
							<Popover
								id='mouse-over-popover'
								sx={{
									pointerEvents: 'none',
								}}
								open={open}
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'left',
								}}
								onClose={handlePopoverClose}
								disableRestoreFocus>
								<Box>
									<Box style={{ margin: '0.5rem 1rem' }}>
										<Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
											Instructions:
										</Typography>
										<div
											style={{
												display: 'flex',
												alignItems: 'flex-start',
												columnGap: '1rem',
											}}>
											<Typography sx={{ fontSize: '1rem' }}>
												Step - 1: Input known reference measurement value.{' '}
												{project && <span>({project.box_dimension} mm)</span>}{' '}
												<br />
												Step - 2: Select unit of the measurement. <br />
												Step - 3: Select initial point for reference on image
												and drag the pointer till the end point.
												<br />
												Step - 4: Select the start point of your distance
												measurement & drag till the end point.
											</Typography>
										</div>
									</Box>
								</Box>
							</Popover>
						</div>
						<div className={styles.total}>
							<span style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
								Total :
							</span>
							<span style={{ fontSize: '1.5rem', marginLeft: '0.5rem' }}>
								{getTotal()} {unit}
							</span>
						</div>
						<Box
							sx={{ maxHeight: { lg: '75vh', xl: '81vh' } }}
							adl-scrollbar='true'
							adl-scrollbar-width='0.3'>
							{lines.length > 0 && (
								<Table stickyHeader aria-label='sticky table'>
									<TableHead>
										<TableRow>
											<TableCell
												sx={{
													padding: '0.2rem 0.5rem',
													borderBottomColor: 'primary.light',
													backgroundColor: 'primary.light',
												}}>
												Distance
											</TableCell>
											<TableCell
												sx={{
													padding: '0.2rem 0.5rem',
													borderBottomColor: 'primary.light',
													backgroundColor: 'primary.light',
												}}>
												Operation
											</TableCell>
											<TableCell
												sx={{
													padding: '0.2rem 0.5rem',
													borderBottomColor: 'primary.light',
													backgroundColor: 'primary.light',
												}}>
												Delete
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{lines?.map((line: any, index: any) => {
											return (
												<TableRow key={index}>
													<TableCell
														sx={{
															padding: '0.2rem 0.5rem',
															borderBottomColor: 'primary.light',
														}}>{`${line.value?.toFixed(2)} ${unit}`}</TableCell>
													<TableCell
														sx={{
															padding: '0.2rem 0.5rem',
															borderBottomColor: 'primary.light',
														}}>
														<select
															style={{
																fontSize: '1rem',
																borderColor: '#ddefff',
															}}
															onChange={handleChangeOp(line, index)}>
															<option value='add'>Add</option>
															<option value='sub'>Subtract</option>
														</select>
													</TableCell>
													<TableCell
														sx={{
															padding: '0.2rem 0.5rem',
															borderBottomColor: 'primary.light',
														}}>
														<DeleteIcon
															sx={{ cursor: 'pointer' }}
															onClick={() => clearLine(line, index)}
														/>
													</TableCell>
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							)}
						</Box>
					</Box>
					<div className={styles.imageMeasurementCont}>
						<div className={styles.canvasWrapper} ref={canvasWrapperEl}>
							<canvas
								ref={canvasRef}
								width={imageDimensions.width}
								height={imageDimensions.height}
								onMouseDown={handleMouseDown}
								onMouseMove={handleMouseMove}
								onMouseUp={handleMouseUp}
								// className={styles.canvas}
							/>
						</div>
						<img
							// crossOrigin="anonymous" // for enabling download // TODO: test in prod
							ref={imageRef}
							src={imageURL}
							onLoad={onImgLoad}
							style={{ display: 'none',objectFit: 'contain', width: '100%', height: '100%' }}
						/>
					</div>
				</div>
			</Box>
		</React.Fragment>
	);
};

export default BOMImageMeasurement;
