import * as React from "react";
import Modal from "@mui/material/Modal";
import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Button from "@mui/material/Button";
import VerifiedIcon from "@mui/icons-material/Verified";
import { API } from "../../api-services";
import Swal from "sweetalert2";
import successIcon from "../../Assets/images/success.png";
import SkeletonLoader from "../../ComponentUI/SkeletonLoader/SkeletonLoader";
import { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import StepLabel from "@mui/material/StepLabel";
import { ADMIN } from "../../Redux/Services/admin.service";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import PartImageModel from "./PartImageModel";
import CDN_URL from "../../utlis/CDN_URL";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { LoadingButton } from "@mui/lab";
import FormControlLabel from "@mui/material/FormControlLabel";
import axios from "axios";

interface IPartVerifyandApproveModelProps {
  onCloseModal: any;
  isOpen: any;
  partdetails: any;
  supplierId: any;
  updateSupplierData: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "68vw", xl: "55vw" },
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#00887a",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#00887a",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: "#00887a",
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "#00887a",
      // zIndex: 1,
      fontSize: 28,
    },
    "& .QontoStepIcon-circle": {
      width: 24,
      height: 24,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  })
);

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <CheckCircleIcon
          sx={{ fontSize: "2rem" }}
          className="QontoStepIcon-completedIcon"
        />
      ) : (
        <RadioButtonCheckedIcon sx={{ fontSize: "2rem" }} />
      )}
    </QontoStepIconRoot>
  );
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: "primary.main",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: "primary.main",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "primary.main",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundColor: "primary.main",
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <RadioButtonUncheckedIcon />,
    2: <RadioButtonUncheckedIcon />,
    3: <RadioButtonUncheckedIcon />,
    4: <RadioButtonUncheckedIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const steps = [
  "General Parameters",
  "Documents",
  "Pricing",
  "Additional Information",
  "ZBC",
];

export default function PartVerifyandApproveModel(
  props: IPartVerifyandApproveModelProps
) {
  const { onCloseModal, isOpen, partdetails, supplierId, updateSupplierData } =
    props;
  const addCategoryOpenModal = useBaseParamsModal();
  const [categoriesAll, setCategoriesAll] = useState<any>("");
  const [showError, setShowerror] = useState<any>(false);
  const [partCategory, setPartCategory] = useState<any>("");
  const [pageData, setPageData] = React.useState<any>([]);
  const [pageloader, setPageLoader] = React.useState(false);
  // console.log(partdetails, 'partdetails');
  const [validateloader, setValidateLoader] = React.useState(false);
  const [validatekey, setValidatekey] = React.useState("");
  const [activeStep, setActiveStep] = React.useState(1);
  const [completed, setCompleted] = React.useState<{ [k: number]: boolean }>(
    {}
  );
  const [loader, setLoader] = React.useState<boolean>(false);
  const partImgModel = useBaseParamsModal();
  const [imageData, setImageData] = useState<any>([]);
  const [currentStepLabel, setCurrentStepLabel] = useState(steps[activeStep]);
  const [validationCount, setValidationCount] = useState<any>(0);
  const [countloader, setCountLoader] = React.useState<boolean>(false);

  const [docType, setDocType] = useState<any>();

  const [checked, setChecked] = useState<any>(false);
  const [mainCostingAmount, setMainCostingAmount] = useState("");
  const [scenarioCostingAmount, setScenarioCostingAmount] = useState("");
  const [approvalComments, setApprovalComments] = useState("");
  const [turnAroundTimeMain, setTurnAroundTimeMain] = useState("");
  const [turnAroundTimeOther, setTurnAroundTimeOther] = useState("");

  const handleChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  React.useEffect(() => {
    if (
      mainCostingAmount !== "" &&
      scenarioCostingAmount !== "" &&
      turnAroundTimeMain !== "" &&
      turnAroundTimeOther !== ""
    ) {
      setShowerror(false);
    }
  }, [
    mainCostingAmount,
    scenarioCostingAmount,
    turnAroundTimeMain,
    turnAroundTimeOther,
  ]);
  const handleTextFieldChange = (event: any, setState: any) => {
    setState(event.target.value);
  };

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps();
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
    getPageData(newActiveStep); // Fetch data for the next step
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    getPageData(activeStep - 1); // Fetch data for the previous step
  };



  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep - 1] = true;
    setCompleted(newCompleted);
    if (checked) {
      API.put(
        "cart/get_all_part_requests_for_internal/",
        {
          part_id: partdetails.ID,
          approve: true,
          zbc_available: true,
          main_costing_amount: mainCostingAmount,
          scenario_costing_amount: scenarioCostingAmount,
          main_costing_turn_around_time: turnAroundTimeMain,
          scenario_turn_around_time: turnAroundTimeOther,
          comments: approvalComments,
        },
        0
      ).then((response) => {
        updateSupplierData();
        // handleNext();
        Swal.fire({
          customClass: {
            container: "swal2Container",
          },
          html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
        <br /><br />
        <p style="color:#00887a;">Part Approved Successfully</p>   
        </div>`,
        });
        onCloseModal();
      });
    } else {
      API.put(
        "cart/get_all_part_requests_for_internal/",
        {
          part_id: partdetails.ID,
          approve: true,
          zbc_available: false,
          comments: approvalComments,
        },
        0
      ).then((response) => {
        updateSupplierData();
        // handleNext();
        Swal.fire({
          customClass: {
            container: "swal2Container",
          },
          html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
        <br /><br />
        <p style="color:#00887a;">Part Approved Successfully</p>   
        </div>`,
        });
        onCloseModal();
      });
    }
  };
  const createCategory = () => {
    addCategoryOpenModal.open(partdetails);
  };

  const handleReset = () => {
    setActiveStep(1);
    setCompleted({});
  };

  const getPageData = (step: number) => {
    if (step !== 5) {
      setPageLoader(true);
      API.get(
        "cart/get_all_part_requests_for_internal/",
        { part_id: partdetails.ID, tab_data: step },
        0
      )
        .then((response) => {
          setPageData(response.data);
          setPageLoader(false);
        })
        .catch((error) => {
          console.log(error);
          setPageLoader(false);
        });
    } else {
      setPageLoader(true);
      API.get(
        "cart/get_all_part_requests_for_internal/",
        { part_id: partdetails.ID, tab_data: step },
        0
      )
        .then((response) => {
          setPageLoader(false);
          if (response?.data) {
            setMainCostingAmount(response?.data[1]?.value || "");
            setScenarioCostingAmount(response?.data[2]?.value || "");
            setTurnAroundTimeMain(response?.data[4]?.value);
            setTurnAroundTimeOther(response?.data[3]?.value || "");
            setApprovalComments(response?.data[5]?.value || "");
            setChecked(response?.data[0]?.value || true);
          }
        })
        .catch((error) => {
          console.log(error);
          setPageLoader(false);
        });
    }
  };

  React.useEffect(() => {
    getPageData(activeStep);
  }, []);

  const getPageUpdateData = (step: number) => {
    // setPageLoader(true);
    API.get(
      "cart/get_all_part_requests_for_internal/",
      { part_id: partdetails.ID, tab_data: step },
      0
    )
      .then((response) => {
        setPageData(response.data);
        setPageLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setPageLoader(false);
      });
  };

  const validateStatusUpdate = (item: any) => {
    setValidateLoader(true);
    setValidatekey(item.key);
    API.put(
      "cart/get_all_part_requests_for_internal/",
      {
        part_id: partdetails.ID,
        field_name: item.key,
        verify: item?.verification_status == true ? false : true,
      },
      0
    ).then((response) => {
      getPageUpdateData(activeStep);
      setValidateLoader(false);
      getCountStatusUpdate();
      {
        item?.verification_status == true
          ? ADMIN.toast.warn("Unverified Successfully")
          : ADMIN.toast.success("Verified Successfully");
      }
    });
  };

  React.useEffect(() => {
    if (checked == false) {
      setShowerror(false);
    }
  }, [checked]);

  const validateAddinfoStatusUpdate = (item: any) => {
    setValidateLoader(true);
    setValidatekey(item.key);
    API.put(
      "cart/get_all_part_requests_for_internal/",
      {
        part_id: partdetails.ID,
        field_name: "component_details",
        field_value: item.key,
        verify: item?.verification_status == true ? false : true,
      },
      0
    ).then((response) => {
      getPageUpdateData(activeStep);
      setValidateLoader(false);
      getCountStatusUpdate();
      ADMIN.toast.success("Verified Successfully");
    });
  };

  const verifyallParameters = () => {
    setValidateLoader(true);
    API.put(
      "cart/get_all_part_requests_for_internal/",
      {
        part_id: partdetails.ID,
        field_name: "component_details",
        verify_all_component_details: true,
        verify: true,
      },
      0
    ).then((response) => {
      getPageUpdateData(activeStep);
      setValidateLoader(false);
      getCountStatusUpdate();
      ADMIN.toast.success("All Parameters Verified Successfully");
    });
  };

  const openPartImages = (colpart: any, type: any) => {
    setDocType(type);
    partImgModel.open({
      images: colpart,
      supplier_name: partdetails.supplier_name,
    });
  };

  const getCountStatusUpdate = () => {
    setCountLoader(true);
    API.get(
      "cart/get_all_part_requests_for_internal/",
      {
        part_id: partdetails.ID,
        supplier_id: supplierId,
        fields_verification_status: true,
      },
      0
    )
      .then((res: any) => {
        setValidationCount(res.data);
        setCountLoader(false);
      })
      .catch((err: any) => {
        const { data } = err?.response;
        Swal.fire({
          icon: "error",
          html: `<div>
					<br />
					<p style="color:"red">${data.detail}</p>   
					</div>`,
        });
        setLoader(false);
      });
  };

  React.useEffect(() => {
    getCountStatusUpdate();
  }, []);

  return (
    <div>
      {partImgModel.isOpen && (
        <PartImageModel
          isOpen={partImgModel.isOpen}
          onCloseModal={partImgModel.close}
          partdetails={partImgModel.propsId}
          docType={docType}
        />
      )}

      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "1rem",
              marginBottom: "0.5rem",
            }}
          >
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Verify details for requested part{" "}
              <span style={{ color: "#00887a" }}>{partdetails.name}</span>,
              requested by{" "}
              <span style={{ color: "#00887a" }}>
                {partdetails.supplier_name}
              </span>
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ width: "100%" }}>
            <Stepper
              alternativeLabel
              activeStep={activeStep - 1}
              connector={<QontoConnector />}
            >
              {steps.map((label: any, index: any) => (
                <Step>
                  <StepLabel
                    StepIconComponent={QontoStepIcon}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: "1rem",
                      cursor: "pointer",
                    }}
                    // onClick={() => {
                    //   console.log("ldjasklj", index + 1);
                    //   setActiveStep(index+1);
                    //   getPageData(index+1);
                    //   setCurrentStepLabel(steps[index]);
                    // }}
                  >
                    {""}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: "0.5rem",
                width: "100%",
                marginTop: "0.5rem",
              }}
            >
              <Box
                sx={{
                  fontSize: "1rem",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  columnGap: "1rem",
                  justifyContent: "center",
                  width: "100%",
                  fontWeight: activeStep == 1 ? "600" : "",
                  borderRadius: "5px",
                  padding: "0.2rem",
                  backgroundColor: activeStep == 1 ? "primary.light" : "",
                  color:
                   "primary.main",
                }}
                onClick={() => {
                  setActiveStep(1);
                  getPageData(1);
                }}
              >
                General Parameters (
                {validationCount[0] && validationCount[0].general_verifiedCount}
                /{validationCount[0] && validationCount[0].general_totalCount})
              </Box>
              <Box
                sx={{
                  fontSize: "1rem",
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                  justifyContent: "center",
                  width: "100%",
                  cursor: "pointer",
                  fontWeight: activeStep == 2 ? "600" : "",
                  borderRadius: "5px",
                  padding: "0.2rem",
                  backgroundColor: activeStep == 2 ? "primary.light" : "",
                  color:
                   "primary.main",
                }}
                onClick={() => {
                  setActiveStep(2);
                  getPageData(2);
                }}
              >
                Documents (
                {validationCount[0] &&
                  validationCount[0].document_verifiedCount}
                /{validationCount[0] && validationCount[0].document_totalCount})
              </Box>
              <Box
                sx={{
                  fontSize: "1rem",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  columnGap: "1rem",
                  justifyContent: "center",
                  width: "100%",
                  fontWeight: activeStep == 3 ? "600" : "",
                  borderRadius: "5px",
                  padding: "0.2rem",
                  backgroundColor: activeStep == 3 ? "primary.light" : "",
                  color:
                   "primary.main",
                }}
                onClick={() => {
                  setActiveStep(3);
                  getPageData(3);
                }}
              >
                Pricing (
                {validationCount[0] && validationCount[0].pricing_verifiedCount}
                /{validationCount[0] && validationCount[0].pricing_totalCont})
              </Box>
              <Box
                sx={{
                  fontSize: "1rem",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  columnGap: "1rem",
                  justifyContent: "center",
                  width: "100%",
                  fontWeight: activeStep == 4 ? "600" : "",
                  borderRadius: "5px",
                  padding: "0.2rem",
                  backgroundColor: activeStep == 4 ? "primary.light" : "",
                  color:
                   "primary.main",
                }}
                onClick={() => {
                  setActiveStep(4);
                  getPageData(4);
                }}
              >
                Additional Information (
                {validationCount[0] &&
                  validationCount[0].additional_param_verifiedCount}
                /
                {validationCount[0] &&
                  validationCount[0].additional_param_totalCount}
                )
              </Box>
              <Box
                sx={{
                  fontSize: "1rem",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  columnGap: "1rem",
                  justifyContent: "center",
                  width: "100%",
                  fontWeight: activeStep == 5 ? "600" : "",
                  borderRadius: "5px",
                  padding: "0.2rem",
                  backgroundColor: activeStep == 5 ? "primary.light" : "",
                  color:
                   "primary.main",
                }}
                onClick={() => {
                  setActiveStep(5);
                  getPageData(5);
                }}
              >
                Zero-based Costing
              </Box>
            </Box>
            <div>
              {allStepsCompleted() ? (
                <React.Fragment>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button onClick={handleReset}>Reset</Button>
                  </Box>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {pageloader ? (
                    <Box sx={{ padding: "1rem" }}>
                      <SkeletonLoader />
                      <SkeletonLoader />
                      <SkeletonLoader />
                    </Box>
                  ) : (
                    <>
                      <Box
                        sx={{
                          height: "fit-content",
                          marginTop: "1rem",
                          padding: "0 1rem",
                        }}
                      >
                        {activeStep === 1 && (
                          <Table>
                            <TableHead
                              sx={{
                                backgroundColor: "primary.main",
                                color: "white",
                              }}
                            >
                              <TableRow>
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    backgroundColor: "primary.main",
                                    color: "white",
                                  }}
                                >
                                  Paramter Name
                                </TableCell>
                                {partdetails.request_type !== "Add" && (
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      backgroundColor: "primary.main",
                                      color: "white",
                                    }}
                                  >
                                    Current Value
                                  </TableCell>
                                )}
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    backgroundColor: "primary.main",
                                    color: "white",
                                  }}
                                >
                                  {partdetails.request_type !== "Add"
                                    ? "New Value"
                                    : "Value"}{" "}
                                </TableCell>
                              { !partdetails?.conflicted &&  <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    textAlign: "center",
                                    backgroundColor: "primary.main",
                                    color: "white",
                                  }}
                                >
                                  Verify
                                </TableCell>}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {pageData &&
                                pageData?.map((item: any, index: any) => (
                                  <TableRow key={index}>
                                    <TableCell
                                      sx={{
                                        padding: "0.2rem 0.5rem",
                                        width: item.old_value ? "25%" : "40%",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {item.url ? (
                                        <img
                                          src={item.url}
                                          alt="Item"
                                          style={{
                                            maxWidth: "100px",
                                            maxHeight: "100px",
                                          }}
                                        />
                                      ) : (
                                        item.key
                                      )}
                                    </TableCell>
                                    {item.old_value && (
                                      <TableCell
                                        sx={{
                                          padding: "0.2rem 0.5rem",
                                          width: "30%",
                                        }}
                                      >
                                        {item.old_value ? item.old_value : "-"}{" "}
                                      </TableCell>
                                    )}
                                    <TableCell
                                      sx={{
                                        padding: "0.2rem 0.5rem",
                                        width: item.old_value ? "30%" : "40%",
                                      }}
                                    >
                                      {item?.value}{" "}
                                      {item.key == "weight" ? `(g)` : ""}
                                    </TableCell>
                                   {!partdetails?.conflicted && <TableCell
                                      sx={{
                                        padding: "0.2rem 0.5rem",
                                        width: item.old_value ? "15%" : "20%",
                                        textAlign: "center",
                                      }}
                                    >
                                      {validateloader &&
                                      item.key == validatekey ? (
                                        <CircularProgress size={20} />
                                      ) : (
                                        <Checkbox
                                          icon={
                                            <VerifiedIcon
                                              sx={{ color: "#ffc800" }}
                                            />
                                          }
                                          checkedIcon={
                                            <VerifiedIcon
                                              sx={{ color: "green" }}
                                            />
                                          }
                                          checked={item?.verification_status}
                                          onClick={() =>
                                            validateStatusUpdate(item)
                                          }
                                        />
                                      )}
                                    </TableCell>}
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        )}
                        {activeStep === 2 && (
                          <>
                            <Table>
                              <TableHead
                                sx={{ backgroundColor: "primary.main" }}
                              >
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      backgroundColor: "primary.main",
                                      color: "white",
                                    }}
                                  >
                                    Paramter Name
                                  </TableCell>
                                  {partdetails.request_type !== "Add" && (
                                    <TableCell
                                      sx={{
                                        padding: "0.2rem 0.5rem",
                                        backgroundColor: "primary.main",
                                        color: "white",
                                      }}
                                    >
                                      Current Value
                                    </TableCell>
                                  )}
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      backgroundColor: "primary.main",
                                      color: "white",
                                    }}
                                  >
                                    {partdetails.request_type !== "Add"
                                      ? "New Value"
                                      : "Value"}{" "}
                                  </TableCell>
                                 {!partdetails?.conflicted && <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      backgroundColor: "primary.main",
                                      color: "white",
                                      textAlign: "center",
                                    }}
                                  >
                                    Verify
                                  </TableCell>}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {pageData &&
                                  pageData?.map((item: any, index: any) => (
                                    <TableRow key={index}>
                                      <TableCell
                                        sx={{
                                          padding: "0.2rem 0.5rem",
                                          width:
                                            partdetails.request_type !== "Add"
                                              ? "25%"
                                              : "40%",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {item.key}
                                      </TableCell>
                                      {partdetails.request_type !== "Add" && (
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            width: "30%",
                                          }}
                                        >
                                          {item?.old_url !== null &&
                                          item?.old_url !== "" ? (
                                            <VisibilityIcon
                                              sx={{ cursor: "pointer" }}
                                              onClick={() => {
                                                if (item.data_type === "file") {
                                                  window.open(
                                                    `${CDN_URL}.${item?.old_url}`,
                                                    "_blank"
                                                  );
                                                } else {
                                                  openPartImages(
                                                    item.part_image,
                                                    "old"
                                                  );
                                                }
                                              }}
                                            />
                                          ) : (
                                            <Typography
                                              sx={{ padding: "0 0.5rem" }}
                                            >
                                              -
                                            </Typography>
                                          )}{" "}
                                        </TableCell>
                                      )}
                                      <TableCell
                                        sx={{
                                          padding: "0.2rem 0.5rem",
                                          width:
                                            partdetails.request_type !== "Add"
                                              ? "30%"
                                              : "40%",
                                        }}
                                      >
                                        {item?.url !== null &&
                                        item?.url !== "" ? (
                                          <VisibilityIcon
                                            sx={{ cursor: "pointer" }}
                                            onClick={() => {
                                              if (item.data_type === "file") {
                                                window.open(
                                                  `${CDN_URL}.${item?.url}`,
                                                  "_blank"
                                                );
                                              } else {
                                                openPartImages(
                                                  item?.part_image,
                                                  "new"
                                                );
                                              }
                                            }}
                                          />
                                        ) : (
                                          <Typography
                                            sx={{ padding: "0 0.5rem" }}
                                          >
                                            -
                                          </Typography>
                                        )}
                                      </TableCell>
                                    {!partdetails?.conflicted &&  <TableCell
                                        sx={{
                                          padding: "0.2rem 0.5rem",
                                          width:
                                            partdetails.request_type !== "Add"
                                              ? "15%"
                                              : "20%",
                                          textAlign: "center",
                                        }}
                                      >
                                        {validateloader &&
                                        item.key == validatekey ? (
                                          <CircularProgress size={20} />
                                        ) : (
                                          <Checkbox
                                            icon={
                                              <VerifiedIcon
                                                sx={{ color: "#ffc800" }}
                                              />
                                            }
                                            checkedIcon={
                                              <VerifiedIcon
                                                sx={{ color: "green" }}
                                              />
                                            }
                                            checked={item?.verification_status}
                                            onClick={() =>
                                              validateStatusUpdate(item)
                                            }
                                          />
                                        )}
                                      </TableCell>}
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </>
                        )}
                        {activeStep === 3 && (
                          <>
                            <Table>
                              <TableHead
                                sx={{ backgroundColor: "primary.main" }}
                              >
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      backgroundColor: "primary.main",
                                      color: "white",
                                    }}
                                  >
                                    Paramter Name
                                  </TableCell>
                                  {partdetails.request_type !== "Add" && (
                                    <TableCell
                                      sx={{
                                        padding: "0.2rem 0.5rem",
                                        backgroundColor: "primary.main",
                                        color: "white",
                                      }}
                                    >
                                      Current Value
                                    </TableCell>
                                  )}
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      backgroundColor: "primary.main",
                                      color: "white",
                                    }}
                                  >
                                    {partdetails.request_type !== "Add"
                                      ? "New Value"
                                      : "Value"}{" "}
                                  </TableCell>
                                 {!partdetails?.conflicted && <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      backgroundColor: "primary.main",
                                      color: "white",
                                      textAlign: "center",
                                    }}
                                  >
                                    Verify
                                  </TableCell>}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {pageData &&
                                  pageData?.map((item: any, index: any) => (
                                    <TableRow key={index}>
                                      <TableCell
                                        sx={{
                                          padding: "0.2rem 0.5rem",
                                          width:
                                            partdetails.request_type !== "Add"
                                              ? "25%"
                                              : "40%",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {item.key}
                                      </TableCell>
                                      {partdetails.request_type !== "Add" && (
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            width: "30%",
                                          }}
                                        >
                                          {Array.isArray(item?.old_value) &&
                                          item?.data_type == "table" ? (
                                            <Table>
                                              <TableHead
                                                sx={{
                                                  backgroundColor:
                                                    "primary.light",
                                                }}
                                              >
                                                <TableRow>
                                                  <TableCell
                                                    sx={{
                                                      padding: "0.2rem 0.5rem",
                                                      width: "20%",
                                                    }}
                                                  >
                                                    Quantity
                                                  </TableCell>
                                                  <TableCell
                                                    sx={{
                                                      padding: "0.2rem 0.5rem",
                                                      width: "20%",
                                                    }}
                                                  >
                                                    <Box
                                                      sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                          "space-between",
                                                        columnGap: "1rem",
                                                        width: "100%",
                                                      }}
                                                    >
                                                      Unit Price
                                                      <Box
                                                        sx={{
                                                          display: "flex",
                                                          alignItems: "center",
                                                          columnGap: "1rem",
                                                        }}
                                                      >
                                                        <img
                                                          src={`data:image/png;base64, ${item?.old_value[0].currency_flag}`}
                                                          style={{
                                                            width: "1.5rem",
                                                            height: "1rem",
                                                          }}
                                                        />{" "}
                                                        {
                                                          item?.old_value[0]
                                                            .currency_code
                                                        }
                                                      </Box>
                                                    </Box>
                                                  </TableCell>
                                                  {/* <TableCell
                                                    sx={{
                                                      padding: "0.2rem 0.5rem",
                                                      width: "20%",
                                                    }}
                                                  >
                                                    Currency
                                                  </TableCell> */}
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                {item?.old_value.map(
                                                  (item: any, index: any) => (
                                                    <TableRow key={index}>
                                                      <TableCell
                                                        sx={{
                                                          padding:
                                                            "0.2rem 0.5rem",
                                                          width: "20%",
                                                          borderBottomColor:
                                                            "primary.light",
                                                        }}
                                                      >
                                                        {item.volume}
                                                      </TableCell>
                                                      <TableCell
                                                        sx={{
                                                          padding:
                                                            "0.2rem 0.5rem",
                                                          width: "20%",
                                                          borderBottomColor:
                                                            "primary.light",
                                                        }}
                                                      >
                                                        {item.currency}{" "}
                                                        {item.price}
                                                      </TableCell>
                                                    </TableRow>
                                                  )
                                                )}
                                              </TableBody>
                                            </Table>
                                          ) : (
                                            <Box
                                              sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                columnGap: "1rem",
                                              }}
                                            >
                                              <img
                                                src={`data:image/png;base64, ${item?.old_currency?.flag}`}
                                                style={{
                                                  width: "1.5rem",
                                                  height: "1rem",
                                                }}
                                              />{" "}
                                              {
                                                item?.old_currency
                                                  ?.currency_code
                                              }
                                            </Box>
                                          )}{" "}
                                        </TableCell>
                                      )}
                                      <TableCell
                                        sx={{
                                          padding: "0.2rem 0.5rem",
                                          width:
                                            partdetails.request_type !== "Add"
                                              ? "30%"
                                              : "40%",
                                        }}
                                      >
                                        {Array.isArray(item?.value) ? (
                                          <Table>
                                            <TableHead
                                              sx={{
                                                backgroundColor:
                                                  "primary.light",
                                              }}
                                            >
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    padding: "0.2rem 0.5rem",
                                                    width: "20%",
                                                  }}
                                                >
                                                  Quantity
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    padding: "0.2rem 0.5rem",
                                                    width: "20%",
                                                  }}
                                                >
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent:
                                                        "space-between",
                                                      columnGap: "1rem",
                                                      width: "100%",
                                                    }}
                                                  >
                                                    Unit Price
                                                    <Box
                                                      sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        columnGap: "1rem",
                                                      }}
                                                    >
                                                      <img
                                                        src={`data:image/png;base64, ${item?.value[0].currency_flag}`}
                                                        style={{
                                                          width: "1.5rem",
                                                          height: "1rem",
                                                        }}
                                                      />{" "}
                                                      {
                                                        item?.value[0]
                                                          .currency_code
                                                      }
                                                    </Box>
                                                  </Box>
                                                </TableCell>
                                                {/* <TableCell
                                                  sx={{
                                                    padding: "0.2rem 0.5rem",
                                                    width: "20%",
                                                  }}
                                                >
                                                  Currency
                                                </TableCell> */}
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {item?.value.map(
                                                (item: any, index: any) => (
                                                  <TableRow key={index}>
                                                    <TableCell
                                                      sx={{
                                                        padding:
                                                          "0.2rem 0.5rem",
                                                        width: "20%",
                                                        borderBottomColor:
                                                          "primary.light",
                                                      }}
                                                    >
                                                      {item.volume}
                                                    </TableCell>
                                                    <TableCell
                                                      sx={{
                                                        padding:
                                                          "0.2rem 0.5rem",
                                                        width: "20%",
                                                        borderBottomColor:
                                                          "primary.light",
                                                      }}
                                                    >
                                                      {item.currency}{" "}
                                                      {item.price}
                                                    </TableCell>
                                                    {/* <TableCell
                                                      sx={{
                                                        padding:
                                                          "0.2rem 0.5rem",
                                                        width: "20%",
                                                        borderBottomColor:
                                                          "primary.light",
                                                      }}
                                                    >
                                                      <Box
                                                        sx={{
                                                          display: "flex",
                                                          alignItems: "center",
                                                          columnGap: "1rem",
                                                        }}
                                                      >
                                                        <img
                                                          src={`data:image/png;base64, ${item.currency_flag}`}
                                                          style={{
                                                            width: "1.5rem",
                                                            height: "1rem",
                                                          }}
                                                        />{" "}
                                                        {item.currency_code}
                                                      </Box>
                                                    </TableCell> */}
                                                  </TableRow>
                                                )
                                              )}
                                            </TableBody>
                                          </Table>
                                        ) : (
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              columnGap: "1rem",
                                            }}
                                          >
                                            <img
                                              src={`data:image/png;base64, ${item?.currency?.flag}`}
                                              style={{
                                                width: "1.5rem",
                                                height: "1rem",
                                              }}
                                            />{" "}
                                            {item?.currency?.currency_code}
                                          </Box>
                                        )}
                                      </TableCell>
                                      {!partdetails?.conflicted &&<TableCell
                                        sx={{
                                          padding: "0.2rem 0.5rem",
                                          width:
                                            partdetails.request_type !== "Add"
                                              ? "15%"
                                              : "20%",
                                          textAlign: "center",
                                        }}
                                      >
                                        {validateloader &&
                                        item.key == validatekey ? (
                                          <CircularProgress size={20} />
                                        ) : (
                                          <Checkbox
                                            icon={
                                              <VerifiedIcon
                                                sx={{ color: "#ffc800" }}
                                              />
                                            }
                                            checkedIcon={
                                              <VerifiedIcon
                                                sx={{ color: "green" }}
                                              />
                                            }
                                            checked={item?.verification_status}
                                            onClick={() =>
                                              validateStatusUpdate(item)
                                            }
                                          />
                                        )}
                                      </TableCell>}
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </>
                        )}
                        {activeStep === 4 && (
                          <Box
                            sx={{ maxHeight: "70vh" }}
                            adl-scrollbar="true"
                            adl-scrollbar-width="0.3"
                          >
                            <Table stickyHeader>
                              <TableHead
                                sx={{ backgroundColor: "primary.main" }}
                              >
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      backgroundColor: "primary.main",
                                      color: "white",
                                    }}
                                  >
                                    Paramter Name
                                  </TableCell>
                                  {partdetails.request_type !== "Add" && (
                                    <TableCell
                                      sx={{
                                        padding: "0.2rem 0.5rem",
                                        backgroundColor: "primary.main",
                                        color: "white",
                                      }}
                                    >
                                      Current Value
                                    </TableCell>
                                  )}
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      backgroundColor: "primary.main",
                                      color: "white",
                                    }}
                                  >
                                    {partdetails.request_type !== "Add"
                                      ? "New Value"
                                      : "Value"}{" "}
                                  </TableCell>
                                  {!partdetails?.conflicted &&<TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      backgroundColor: "primary.main",
                                      color: "white",
                                    }}
                                  >
                                    Verify
                                    {pageData[1] &&
                                    pageData[1].additional_info_all_verified ? (
                                      ""
                                    ) : (
                                      <LoadingButton
                                        size="small"
                                        title="Verify All Parameters"
                                        sx={{
                                          backgroundColor: "primary.light",
                                          marginLeft: "1.5rem",
                                          lineHeight: 1,
                                          ":hover": {
                                            backgroundColor: "primary.light",
                                          },
                                          ".MuiCircularProgress-root": {
                                            color: "white",
                                          },
                                        }}
                                        loading={validateloader}
                                        onClick={verifyallParameters}
                                      >
                                        Verify All
                                      </LoadingButton>
                                    )}
                                  </TableCell>}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {Array.isArray(pageData[0]) &&
                                  pageData[0]?.map((item: any, index: any) => (
                                    <TableRow key={index}>
                                      <TableCell
                                        sx={{
                                          padding: "0.2rem 0.5rem",
                                          width:
                                            partdetails.request_type !== "Add"
                                              ? "30%"
                                              : "40%",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {item.paramter_name}
                                      </TableCell>
                                      {partdetails.request_type !== "Add" && (
                                        <TableCell
                                          sx={{
                                            padding: "0.2rem 0.5rem",
                                            width: "25%",
                                          }}
                                        >
                                          {item.data_type == "image" &&
                                          item?.old_value !== "" ? (
                                            <VisibilityIcon
                                              sx={{ cursor: "pointer" }}
                                              onClick={() => {
                                                window.open(
                                                  `${CDN_URL}.${item?.old_value}`,
                                                  "_blank"
                                                );
                                              }}
                                            />
                                          ) : (
                                            <>
                                              {item.old_value == null ||
                                              item.old_value == ""
                                                ? "-"
                                                : item.old_value}{" "}
                                              {item.unit == null ||
                                              (item.unit == "" &&
                                                item.old_value == null) ||
                                              item.old_value == ""
                                                ? "-"
                                                : `(${item.unit})`}
                                            </>
                                          )}
                                        </TableCell>
                                      )}
                                      <TableCell
                                        sx={{
                                          padding: "0.2rem 0.5rem",
                                          width:
                                            partdetails.request_type !== "Add"
                                              ? "25%"
                                              : "40%",
                                        }}
                                      >
                                        {item.data_type == "image" &&
                                        item?.value !== "" ? (
                                          <VisibilityIcon
                                            sx={{ cursor: "pointer" }}
                                            onClick={() => {
                                              window.open(
                                                `${CDN_URL}.${item?.value}`,
                                                "_blank"
                                              );
                                            }}
                                          />
                                        ) : (
                                          <>
                                            {item.value == null ||
                                            item.value == ""
                                              ? "-"
                                              : item.value}{" "}
                                            {item.unit == null ||
                                            (item.unit == "" &&
                                              item.value == null) ||
                                            item.value == ""
                                              ? "-"
                                              : `(${item.unit})`}
                                          </>
                                        )}
                                      </TableCell>
                                      {!partdetails?.conflicted &&<TableCell
                                        sx={{
                                          padding: "0.2rem 0.5rem",
                                          width:
                                            partdetails.request_type !== "Add"
                                              ? "20%"
                                              : "20%",
                                        }}
                                      >
                                        <Box sx={{ paddingLeft: "0.3rem" }}>
                                          {validateloader &&
                                          item.key == validatekey ? (
                                            <CircularProgress size={20} />
                                          ) : (
                                            <Checkbox
                                              style={{ padding: "2px" }}
                                              icon={
                                                <VerifiedIcon
                                                  sx={{ color: "#ffc800" }}
                                                />
                                              }
                                              checkedIcon={
                                                <VerifiedIcon
                                                  sx={{ color: "green" }}
                                                />
                                              }
                                              checked={
                                                item?.verification_status
                                              }
                                              onClick={() =>
                                                validateAddinfoStatusUpdate(
                                                  item
                                                )
                                              }
                                            />
                                          )}
                                        </Box>
                                      </TableCell>}
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </Box>
                        )}
                        {activeStep === 5 && (
                          <Box
                            sx={{ maxHeight: "70vh" }}
                            adl-scrollbar="true"
                            adl-scrollbar-width="0.3"
                          >
                            <Box sx={{ padding: "1rem" }}>
                              <Typography>
                                <span style={{ color: "red" }}>*Note:</span>{" "}
                                Please fill the required details if Zero-based
                                costing is allowed to this part.
                              </Typography>
                            </Box>
                            <Box sx={{ padding: "1rem" }}>
                              <FormControlLabel
                                label="Allow Zero-based costing request"
                                control={
                                  <Checkbox
                                    checked={checked}
                                    disabled={
                                      partdetails.request_type === "Edit"
                                    }
                                    onChange={handleChange1}
                                  />
                                }
                              />

                             {checked &&  <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell
                                      sx={{
                                        padding: "0.2rem 0.5rem",
                                        borderBottomColor: "primary.light",
                                        backgroundColor: "primary.main",
                                        color: "white",
                                      }}
                                    >
                                      First ZBC amount
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        padding: "0.2rem 0.5rem",
                                        borderBottomColor: "primary.light",
                                        backgroundColor: "primary.main",
                                        color: "white",
                                      }}
                                    >
                                      First ZBC TAT
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        padding: "0.2rem 0.5rem",
                                        borderBottomColor: "primary.light",
                                        backgroundColor: "primary.main",
                                        color: "white",
                                      }}
                                    >
                                      Other ZBC amount
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        padding: "0.2rem 0.5rem",
                                        borderBottomColor: "primary.light",
                                        backgroundColor: "primary.main",
                                        color: "white",
                                      }}
                                    >
                                      Other ZBC TAT
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell
                                      sx={{
                                        padding: "0.2rem 0.5rem",
                                        borderBottomColor: "primary.light",
                                      }}
                                    >
                                      <TextField
                                        label={
                                          <span>
                                            First ZBC amount{" "}
                                            {checked && (
                                              <sup style={{ color: "red" }}>
                                                *
                                              </sup>
                                            )}
                                          </span>
                                        }
                                        sx={{}}
                                        disabled={!checked}
                                        value={mainCostingAmount}
                                        type="number"
                                        onChange={(e) =>
                                          handleTextFieldChange(
                                            e,
                                            setMainCostingAmount
                                          )
                                        }
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment
                                              disableTypography={!checked}
                                              position="start"
                                            >
                                              INR
                                            </InputAdornment>
                                          ),
                                        }}
                                        variant="filled"
                                      />
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        padding: "0.2rem 0.5rem",
                                        borderBottomColor: "primary.light",
                                      }}
                                    >
                                      {" "}
                                      <TextField
                                        label={
                                          <span>
                                            First ZBC turn around time
                                            {checked && (
                                              <sup style={{ color: "red" }}>
                                                *
                                              </sup>
                                            )}
                                          </span>
                                        }
                                        // sx={{ m: 1, width: "21ch" }}
                                        disabled={!checked}
                                        type="number"
                                        value={turnAroundTimeMain}
                                        onChange={(e) =>
                                          handleTextFieldChange(
                                            e,
                                            setTurnAroundTimeMain
                                          )
                                        }
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment
                                              disableTypography={!checked}
                                              position="start"
                                            >
                                              Days
                                            </InputAdornment>
                                          ),
                                        }}
                                        variant="filled"
                                      />
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        padding: "0.2rem 0.5rem",
                                        borderBottomColor: "primary.light",
                                      }}
                                    >
                                      <TextField
                                        label={
                                          <span>
                                            Other ZBC amount
                                            {checked && (
                                              <sup style={{ color: "red" }}>
                                                *
                                              </sup>
                                            )}
                                          </span>
                                        }
                                        // sx={{ m: 1, width: "21ch" }}
                                        disabled={!checked}
                                        type="number"
                                        value={scenarioCostingAmount}
                                        onChange={(e) =>
                                          handleTextFieldChange(
                                            e,
                                            setScenarioCostingAmount
                                          )
                                        }
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment
                                              disableTypography={!checked}
                                              position="start"
                                            >
                                              INR
                                            </InputAdornment>
                                          ),
                                        }}
                                        variant="filled"
                                      />
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        padding: "0.2rem 0.5rem",
                                        borderBottomColor: "primary.light",
                                      }}
                                    >
                                      <TextField
                                        label={
                                          <span>
                                            Other ZBC Turn around time
                                            {checked && (
                                              <sup style={{ color: "red" }}>
                                                *
                                              </sup>
                                            )}
                                          </span>
                                        }
                                        // sx={{ m: 1, width: "21ch" }}
                                        disabled={!checked}
                                        type="number"
                                        value={turnAroundTimeOther}
                                        onChange={(e) =>
                                          handleTextFieldChange(
                                            e,
                                            setTurnAroundTimeOther
                                          )
                                        }
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment
                                              disableTypography={!checked}
                                              position="start"
                                            >
                                              Days
                                            </InputAdornment>
                                          ),
                                        }}
                                        variant="filled"
                                      />
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>}
                              {showError && (
                                <sup style={{ color: "red" }}>
                                  *All fields are required. Please uncheck and
                                  try to save
                                </sup>
                              )}
                            </Box>
                            <Box>
                              <FormControl fullWidth variant="standard">
                                <TextField
                                  variant="standard"
                                  label="Approval Comments"
                                  // autoFocus={autoFocusStatus}
                                  InputProps={{
                                    style: { color: "#007fff" },
                                  }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  sx={{
                                    "& .Mui-error": {
                                      color: "#d32f2f",
                                      borderBottomColor: "#d32f2f",
                                    },
                                    "& .MuiInput-underline:before": {
                                      borderBottomColor: "primary.light",
                                    },
                                    "& .MuiInput-underline:after": {
                                      borderBottomColor: "primary.main",
                                    },
                                    "& .MuiFormLabel-root.Mui-focused": {
                                      color: "primary.main",
                                    },
                                    "& .MuiFormLabel-root.MuiInputLabel-root ":
                                      {
                                        color: "primary.main",
                                      },
                                    ".MuiInputBase-root.MuiInput-root": {
                                      fontSize: "1rem !important",
                                    },
                                  }}
                                  id="description"
                                  name="description"
                                  onChange={(e: any) =>
                                    handleTextFieldChange(
                                      e,
                                      setApprovalComments
                                    )
                                  }
                                  value={approvalComments}
                                  multiline
                                />
                              </FormControl>
                            </Box>
                          </Box>
                        )}
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          pt: 2,
                          alignItems: "center",
                          justifyContent: "flex-end",
                          columnGap: "1rem",
                        }}
                      >
                        <Button
                          size="small"
                          color="inherit"
                          disabled={activeStep === 1}
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                        >
                          Previous
                        </Button>

                        {activeStep !== 5 && (
                          <Button
                            size="small"
                            disabled={activeStep === 5}
                            onClick={handleNext}
                            sx={{ mr: 1 }}
                          >
                            Next
                          </Button>
                        )}

                        {/* {activeStep - 1 !== steps.length &&
                        (completed[activeStep - 1] ? (
                          <Typography variant="caption" sx={{ display: 'inline-block' }}>
                            Step {activeStep} already completed
                          </Typography>
                        ) : (
                          <Button size='small' disabled={validationCount[0] && validationCount[0].all_parameter_verified == false ? true :false} onClick={handleComplete}>
                            {completedSteps() === totalSteps() - 1
                              ? 'Finish'
                              : 'Approve'}
                          </Button>
                        ))} */}

                        {activeStep === 5 &&
                          validationCount[0] &&
                          validationCount[0].all_parameter_verified && (
                            <Button
                              variant="contained"
                              size="small"
                              disabled={
                                validationCount[0] &&
                                validationCount[0].all_parameter_verified ==
                                  false
                                  ? true
                                  : false
                              }
                              // main_costing_amount: mainCostingAmount,
                              // scenario_costing_amount: scenarioCostingAmount,
                              // main_costing_turn_around_time: turnAroundTimeMain,
                              // scenario_turn_around_time: turnAroundTimeOther,
                              onClick={() => {
                                if (checked) {
                                  if (
                                    mainCostingAmount !== "" &&
                                    scenarioCostingAmount !== "" &&
                                    turnAroundTimeMain !== "" &&
                                    turnAroundTimeOther !== ""
                                  ) {
                                    handleComplete();
                                  } else {
                                    setShowerror(true);
                                  }
                                } else {
                                  handleComplete();
                                }
                              }}
                            >
                              Approve
                            </Button>
                          )}
                      </Box>
                    </>
                  )}
                </React.Fragment>
              )}
            </div>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
