import React, { useEffect, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { Box, Button, Divider, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import CancelIcon from '@mui/icons-material/Cancel';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { Bar, BarChart, CartesianGrid, Cell, Label, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import styles from '../CostingCreateSystemTable.module.scss';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import scenerioDoneIcon from '../../../Assets/images/scenarioDone.svg';
import scenerioInProgressIcon from '../../../Assets/images/scenarioInProgress.svg';
import { useHistory } from 'react-router-dom';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import { LoadingButton } from '@mui/lab';
import { useRouteParams } from '../../../ui-reusable-component/useRouteParams';
import { useBaseModal } from '../../SearchPage/useBaseModal';
import { API } from '../../../api-services';
import { CurrencyChange } from '../../../ViewModule/CostingView/CurrencyChange';
import { DialogComponent } from '../../../utlis/DialogComponent';
import CostingScenerioCreateSystemTableCompressedRow from './CostingScenerioCreateSystemTableCompressedRow';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red', 'pink'];

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

interface Params {
  projectId: any;
  projectName: any;
  topVaultIds: any;
  expandTable: any;
  initialTopVault: any;
}

interface ICostingScenerioCreateSystemTableCompressedProps { }

const CostingScenerioCreateSystemTableCompressed: React.FC<ICostingScenerioCreateSystemTableCompressedProps> = (props) => {

  const { projectId, projectName, topVaultIds, expandTable, initialTopVault } = useRouteParams<Params>();
  const [totalCostProduct, setTotalCostProduct] = useState<any>([]);
  const [totalCostCalculationData, setTotalCostCalculationData] = useState<any>([]);
  const [openTooltip, setOpenTooltip] = React.useState(false);
  const [openStatisticsModel, setOpenStatisticsModel] = React.useState(false);
  const [partInfo, setPartInfo] = React.useState<any>({});
  const [statisticsData, setStatisticsData] = React.useState<any>([]);
  const [subsystemName, setSubsystemName] = React.useState<any>('');
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [count, setCount] = React.useState<any>(0);
  const [totalCostCalculationDataLoader, setTotalCostCalculationDataLoader] = useState<boolean>(false);
  const [contentText, setContentText] = React.useState<any>("");
  const [iconText, setIconText] = React.useState<any>("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [statisticsDataLoader, setStatisticsDataLoader] = React.useState(false);
  const openDownload = Boolean(anchorEl);
  const history = useHistory<any>();
  const openStatusContent = useBaseModal();
  
  const currencyModal = useBaseModal();

  useEffect(() => {
    API.get(
      "/cost/costing_conversion/",
      { get_conversion_factor: true, module: "costing_create" },
      0
    ).then((res: any) => {
      sessionStorage.setItem(
        "costingtable",
        res.data[0]?.current_currency
      );
      sessionStorage.setItem(
        "actionkey_costingtable",
        res.data[0]?.is_manual ? "mannual" : "auto"
      )
      if (res.data[0]?.is_manual) {
        sessionStorage.setItem(
          "currencyrate_costingtable_mannual",
          res.data[0]?.conversion_factor
        )
      } else {
        sessionStorage.setItem(
          "currencyrate_costingtable",
          (1 / res.data[0]?.conversion_factor).toString()
        )
      }
    });
  }, [count]);

  useEffect(() => {
    setTotalCostCalculationDataLoader(true);
    API.get(`/cost/cost_info_total_count/`, { project: projectId, scenario: 0, top_vault: topVaultIds }, 0)
      .then((res: AxiosResponse) => {
        setTotalCostProduct(res.data);
        setTotalCostCalculationDataLoader(false);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });

    API.get(`cost/costing_create_ui/`, { project: projectId, scenario: 0, top_vault: topVaultIds, get_costing_details: true }, 0)
      .then((res: AxiosResponse) => {
        setTotalCostCalculationData(res.data);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });

  }, [projectId, topVaultIds, count]);



  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTooltipClose = () => {
    setOpenTooltip(false);
    setPartInfo({});
  };

  const handleTooltipOpen = (partIndoData: any) => {
    setOpenTooltip(true);
    setPartInfo(partIndoData);
  };

  const handleStatisticsClose = () => {
    setOpenStatisticsModel(false);
    setSubsystemName('');
    setStatisticsData([]);
  }

  const handleStaticticsOpen = (subSystem: any, type: any) => {
    setStatisticsDataLoader(true);
    API.get(`cost/costing_create_ui/`, { start_top_vault: initialTopVault, get_costing_graph_data: projectId, abbreviation: null }, 0)
      .then((res: AxiosResponse) => {
        setStatisticsData(res.data);
        setSubsystemName(type);
        setOpenStatisticsModel(true);
        setStatisticsDataLoader(false);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });
  }

  const handleRedirect = (module: any, id: any) => {

    if (module === "w") {
      API.get(
        "/cost/check_consolidate_downoad/",
        {
          top_vault: id,
          with_image: true,
          consolidate_type: "Costing",
          scenario: undefined,
        },
        0
      ).then((res: any) => {

        if (res.data.status === "Failed") {
          setIconText("failed");
          setContentText(res.data.message);
          openStatusContent.open();
        } else if (res.data.status === "First Download") {
          setIconText("firstdownload");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
          API.get(
            "customer/consolidated_report/",
            {
              top_vault: id,
              with_image: true,
              consolidate_type: "Costing",
              scenario: 0,
            },
            0
          ).then((res: any) => {
            handleClose();
            window.open(res?.data, "_self");
          });
        } else if (res.data.status === "In Progress") {
          setIconText("reportProgress");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
        } else if (res.data.status === "Completed") {
          handleClose();
          window.open(res?.data?.url, "_self");
        }
      });
    }
    else if (module === "w/o") {
      API.get(
        "/cost/check_consolidate_downoad/",
        {
          top_vault: id,
          with_image: false,
          consolidate_type: "Costing",
          scenario: undefined,
        },
        0
      ).then((res: any) => {
        if (res.data.status === "Failed") {
          setIconText("failed");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
        } else if (res.data.status === "First Download") {
          setIconText("firstdownload");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
          API.get(
            "customer/consolidated_report/",
            {
              top_vault: id,
              with_image: false,
              scenario: 0,
              consolidate_type: "Costing",
            },
            0
          ).then((res: any) => {
            handleClose();
            window.open(res?.data, "_self");
          });
        } else if (res.data.status === "In Progress") {
          setIconText("reportProgress");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
        } else if (res.data.status === "Completed") {
          handleClose();
          window.open(res?.data?.url, "_self");
        }
      });
    }
  };

  const getPath = (x: any, y: any, width: any, height: any) => {
    return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3}
        ${x + width / 2}, ${y}
        C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
        Z`;
  };

  const TriangleBar = (props: any) => {
    const { fill, x, y, width, height } = props;

    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
  };
  const TriagulationRouting = (data: any) => {

    history.push(`/costing/${16}/costing-triangulation/${projectId}/${data?.top_vault_id}/${data?.name}`)
    console.log(data, "Cscbcgducgudcgud")

  }
  return (
    <>
      {currencyModal.isOpen &&
        <CurrencyChange
          isOpen={currencyModal.isOpen}
          onCloseModal={currencyModal.close}
          setCount={setCount}
        />}

      {currencyModal.isOpen &&
        <CurrencyChange
          isOpen={currencyModal.isOpen}
          onCloseModal={currencyModal.close}
          setCount={setCount}
        />}

      {openStatusContent.isOpen &&
        <DialogComponent
          isOpen={openStatusContent.isOpen}
          onClose={openStatusContent.close}
          content={contentText}
          type={iconText}
        />}

      <Dialog
        open={openTooltip}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '.MuiPaper-root.MuiDialog-paper': {
            // maxWidth: '50vw',
            borderRadius: '10px',
          }
        }}
      >
        <DialogContent sx={{ padding: "0rem", width: '24rem' }}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Parts Information
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={handleTooltipClose}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box
            sx={{
              margin: "1rem",
              Width: '100%',
            }}
          >
            <TableRow>
              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', width: '100%' }}>Total Parts</TableCell>
              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', width: '100%' }}>{partInfo && partInfo?.total_parts}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', width: '100%' }}>Validated</TableCell>
              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', width: '100%' }}>{partInfo && partInfo?.validated}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', width: '100%' }}>Completed</TableCell>
              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', width: '100%' }}>{partInfo && partInfo?.completed}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', width: '100%' }}>In-progress</TableCell>
              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', width: '100%' }}>{partInfo && partInfo?.in_progress}</TableCell>
            </TableRow>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openStatisticsModel}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '.MuiPaper-root.MuiDialog-paper': {
            minWidth: '80vw',
            borderRadius: '10px',
          }
        }}
      >
        <DialogContent sx={{ padding: "0rem", width: '80vw' }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3">
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem", position: 'sticky', top: 0, zIndex: 2, backgroundColor: 'white' }}>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Statistics ( {subsystemName} )
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={handleStatisticsClose}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box
            sx={{
              margin: "0.5rem",
              Width: '100%',
            }}
          >
            <div>
              <Box sx={{ margin: '0rem', width: '78vw', height: '62vh', padding: '1rem 1rem' }}>
                <ResponsiveContainer>
                  <BarChart
                    width={900}
                    height={600}
                    data={statisticsData}
                    margin={{
                      top: 20,
                      right: 10,
                      left: 25,
                      bottom: 55,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="product_name" interval={0} angle={0} textAnchor="middle" />
                    <YAxis>
                      <Label
                        style={{
                          textAnchor: "middle",
                          fontSize: "100%",
                          fontWeight: "600",
                        }}
                        angle={270}
                        dx={subsystemName == "Product" ? -46 : -28}
                        value={`Cost ( ${sessionStorage.getItem("costingtable")} )`}
                      />
                    </YAxis>
                    <Bar dataKey="cost" fill="#8884d8" shape={<TriangleBar />} label={{ position: 'top' }}>
                      {statisticsData?.map((entry: any, index: any) => (
                        <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
      {totalCostCalculationDataLoader
        ?  topVaultIds?.toString()?.split(',')?.map((item: any) => <span style={{ width: "90%", margin: '0 auto' }}>
          <Skeleton animation="wave" height={40} />
          <Skeleton animation="wave" height={40} />
          <Skeleton animation="wave" height={40} />
          <Skeleton animation="wave" height={40} />
          <Skeleton animation="wave" height={40} />
          <Skeleton animation="wave" height={40} />
          <Skeleton animation="wave" height={40} />
          <Skeleton animation="wave" height={40} />
          <Skeleton animation="wave" height={40} />
          <Skeleton animation="wave" height={40} />
          <Skeleton animation="wave" height={40} />
          <Skeleton animation="wave" height={40} />
          <Skeleton animation="wave" height={40} />
          <Skeleton animation="wave" height={40} />
          <Skeleton animation="wave" height={40} />
          <Skeleton animation="wave" height={40} />
          <Skeleton animation="wave" height={40} />
          <Skeleton animation="wave" height={40} />
          <Skeleton animation="wave" height={40} />
          <Skeleton animation="wave" height={40} />
          <Skeleton animation="wave" height={40} />
        </span>) :
        totalCostProduct?.map((item: any, index: any) => {
          return (<Table stickyHeader aria-label="sticky table">
            <TableHead sx={{ backgroundColor: 'primary.light', position: 'sticky', top: 0, zIndex: 3 }} key={index + 1}>
              <TableRow>
                {index == 0 && <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', backgroundColor: 'primary.light', position: 'sticky', left: 0, zIndex: 3 }} rowSpan={2}>
                  <Button
                    variant="contained"
                    className={styles.downloadBtn}
                    size="small"
                    endIcon={<ArrowDropDownIcon />}
                    style={{ fontSize: "12px", marginRight: "1rem" }}
                    onClick={() => currencyModal.open()}
                  >
                    {sessionStorage.getItem("costingtable")}
                  </Button>
                </TableCell>}
                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', textAlign: 'center', backgroundColor: 'primary.light' }} colSpan={5}><span style={{ display: 'inline-block', width: '8rem', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }} title={item?.name}>{item?.product_id}</span></TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', backgroundColor: 'primary.light', whiteSpace: 'nowrap', textAlign: 'right' }}>Cost ({sessionStorage.getItem("costingtable")})</TableCell>
                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', textAlign: 'center', width: '9rem', backgroundColor: 'primary.light', whiteSpace: 'nowrap' }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ position: 'sticky', top: '4.9rem', zIndex: 3 }}>
                {index == 0 && <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', fontWeight: 'bold', backgroundColor: 'white', position: 'sticky', left: 0, zIndex: 3 }}><span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', columnGap: '0.5rem' }}>Product <LoadingButton loading={statisticsDataLoader} sx={{
                  p: 0, minWidth: '1rem',
                  '.MuiCircularProgress-root': {
                    color: 'primary.main',
                  },
                }}><LeaderboardIcon titleAccess="Statistics" sx={{ cursor: 'pointer' }} onClick={() => handleStaticticsOpen(item?.name, "Product")} /></LoadingButton></span></TableCell>}
                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', backgroundColor: 'white', textAlign: 'right' }}>{item?.total_cost?.toFixed(3)}</TableCell>
                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', backgroundColor: 'white' }}>
                  <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                    {item?.scenario_show && (item?.scenario_exists ? <img src={scenerioDoneIcon} height='18px' style={{ marginRight: '0.5rem' }} /> : <img src={scenerioInProgressIcon} height='18px' style={{ marginRight: '0.5rem' }} />)}
                    <Button
                      id="demo-customized-button"
                      aria-controls={openDownload ? 'demo-customized-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openDownload ? 'true' : undefined}
                      disableElevation
                      onClick={handleClick}
                      style={{ padding: '0rem', minWidth: '1rem' }}
                    >
                      <CloudDownloadIcon sx={{ cursor: 'pointer' }} />
                    </Button>
                    <StyledMenu
                      id="demo-customized-menu"
                      MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                      }}                      
                      anchorEl={anchorEl}
                      open={openDownload}
                      onClose={handleClose}
                    >
                      <MenuItem
                        sx={{
                          fontSize: "1rem",
                          color: "primary.main",
                          padding: "0.2rem 0.5rem",
                        }}
                        onClick={() =>
                          handleRedirect(
                            "w",
                            item?.top_vault_id
                          )
                        }
                      >
                        Consolidated with images
                      </MenuItem>
                      <MenuItem
                        sx={{
                          fontSize: "1rem",
                          color: "primary.main",
                          padding: "0.2rem 0.5rem",
                        }}
                        onClick={() =>
                          handleRedirect(
                            "w/o",
                            item?.top_vault_id
                          )
                        }
                      >
                        Consolidated w/o images
                      </MenuItem>
                    </StyledMenu>
                    <ChangeHistoryIcon titleAccess='Triangulation' onClick={() => TriagulationRouting(item)} sx={{ cursor: 'pointer' }} />
                  </span>
                </TableCell>
              </TableRow>
              {totalCostCalculationData && totalCostCalculationData?.map((internalData: any, indexInternal: number) => {
                return (
                  <CostingScenerioCreateSystemTableCompressedRow key={indexInternal + 1} item={item} index={index} setHoveredIndex={setHoveredIndex} internalData={internalData} indexInternal={indexInternal} hoveredIndex={hoveredIndex} handleTooltipOpen={handleTooltipOpen} openStatusContent={openStatusContent} setContentText={setContentText} setIconText={setIconText} setStatisticsData={setStatisticsData} setSubsystemName={setSubsystemName} setOpenStatisticsModel={setOpenStatisticsModel} />
                )
              })}
            </TableBody>
          </Table >)
        })}
    </>)
}

export default CostingScenerioCreateSystemTableCompressed;