import * as React from 'react';
import styles from './MediaCreateProjectSelection.module.scss';
import {
    Box,
    Checkbox,
    Divider,
    Drawer,
    IconButton,
    InputBase,
    Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import { useBaseModal } from '../SearchPage/useBaseModal';

import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import { API } from '../../api-services';
import { LoadingButton } from '@mui/lab';
import { AxiosError, AxiosResponse } from 'axios';
import { MediaCreateDrawer } from './MediaCreateDrawer';
import { useDocumentTitle } from '../../ui-reusable-component/useDocumentTitle';


interface Params {
    projectId: any;
    projectname: any;
}

export interface IMediaCreateProjectSelectionProps {

}

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export function MediaCreateProjectSelection(props: IMediaCreateProjectSelectionProps) {
    useDocumentTitle( "Media Create" );
    const { projectId, projectname } = useRouteParams<Params>();
    const [projectList, setProjectList] = useState<any>();
    const [selectedProject, setSelectedProject] = useState<any>([]);
    const history = useHistory();
    const { url } = useRouteMatch();

    const [state, setState] = React.useState({
        top: false,
        left: true,
        bottom: false,
        right: false,
    });

    React.useEffect(() => {
        API.get(`/cost/compare_module/`, { project: true, module: 1 }, 0)
            .then((res: AxiosResponse) => {
                setProjectList(res?.data);
            })
            .catch((err: AxiosError) => {
                console.log(err, "error");
            });
    }, []);

    const selectAllProject = (productIds: any) => {
        setSelectedProject([productIds]);
    };

    const getselectedProjectBackgroundColor = (idd: any) => {
        if (selectedProject?.includes(idd)) {
            return "primary.main";
        } else {
            return "primary.light";
        }
    };

    const getSelectedProjectTextColor = (idd: any) => {
        if (selectedProject?.includes(idd)) {
            return "primary.light";
        } else {
            return "primary.main";
        }
    };

    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === "keydown" &&
                    ((event as React.KeyboardEvent).key === "Tab" ||
                        (event as React.KeyboardEvent).key === "Shift")
                ) {
                    return;
                }
                setState({ ...state, [anchor]: open });
                if (open) {
                    setSelectedProject([Number(projectId)]);
                }
            };

    const list = (anchor: Anchor) => (
        <MediaCreateDrawer anchorComing={anchor} toggleDrawer={toggleDrawer}/>
    );

    const redirectToProject: any = () => {
        history.push(`/mediacreate/${selectedProject[0]}/${projectList && projectList?.filter((item: any) => item?.id == selectedProject[0])[0]?.name}/0/0/0/0/true`);
    };

    const handleClick = () => {
        history.goBack();
    };


    return (
        <div>
            <Box sx={{ padding: "0 0.5rem" }}>
                <Box sx={{ display: 'flex', columnGap: '1rem', alignItems: 'center' }}>
                    <IconButton sx={{ cursor: "pointer", }} onClick={handleClick}>
                        <ArrowBackIcon
                            color="primary"
                            titleAccess="Go Back"
                        />
                    </IconButton>
                    <Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap' }}> Upload Media [ {projectname} ]</Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
                <Box className={styles.expandIconBox}>
                    <div className={styles.iconSec}>
                        {(["left"] as const).map((anchor) => (
                            <React.Fragment key={anchor}>
                                <IconButton
                                    size="medium"
                                    sx={{
                                        backgroundColor: "primary.light",
                                        color: "primary.main",
                                    }}
                                    onClick={toggleDrawer(anchor, true)}
                                >
                                    <AddIcon sx={{ fontSize: "1.7rem" }} />
                                </IconButton>
                                <Drawer
                                    anchor={anchor}
                                    open={state[anchor]}
                                    onClose={toggleDrawer(anchor, false)}
                                    className={styles.drawerContainer}
                                    PaperProps={{
                                        sx: {
                                            backgroundColor: "aliceblue",
                                        },
                                    }}
                                >
                                    <Box
                                        sx={{ padding: "0.5rem 0", position: "sticky", top: "0" }}
                                        adl-scrollbar="true"
                                        adl-scrollbar-width="0.3"
                                    >
                                        <Typography
                                            style={{
                                                width: "100%",
                                                textAlign: "center",
                                                fontSize: "1rem",
                                            }}
                                        >
                                            Select Project
                                        </Typography>
                                        <Divider sx={{ margin: "0.2rem" }} />
                                        {list(anchor)}
                                    </Box>
                                </Drawer>
                                <Typography
                                    sx={{ fontSize: "1rem", color: "primary.main" }}
                                    onClick={toggleDrawer(anchor, true)}
                                    className={styles.sideTitle}
                                >
                                    Selection Options
                                </Typography>
                            </React.Fragment>
                        ))}
                    </div>
                </Box>
                <Box sx={{ width: { lg: '96%', xl: '97%' } }}>

                </Box>
            </Box>

        </div>
    );
}
