import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import {
	Checkbox,
	Divider,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Tooltip,
} from '@mui/material';
import {
	DataGrid,
	GridColDef,
	GridCsvExportMenuItem,
	GridCsvExportOptions,
	GridToolbar,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExportContainer,
	GridValueGetterParams,
} from '@mui/x-data-grid';
import { API } from '../../../api-services';
import { LoadingButton } from '@mui/lab';
import { useBaseModal } from '../../SearchPage/useBaseModal';
import ErrorModalFilesMail from '../../ProjectMailModule/ErrorModalFilesMail';


interface InfoModalProps {
	onCloseModal: any;
	isOpen: any;
	CalculatorIds: any;
	SelectedItem: any
	ApplyCostType: any;
	assumptionList: any;
	userInput: any;
	currencyDataBaseList: any;

}
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '80vw',
	// height: '80vh',
	bgcolor: 'background.paper',
	borderRadius: '10px',
	p: 1,
};
const csvOptions: GridCsvExportOptions = {
	fileName: 'Table In CSV',
	delimiter: ',',
	utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
	<GridToolbarExportContainer {...props}>
		<GridCsvExportMenuItem options={csvOptions} />
	</GridToolbarExportContainer>
);

const CustomToolbar = () => (
	<GridToolbarContainer style={{ height: '1.5rem', marginBottom: '0.5rem' }}>
		<span style={{ position: 'absolute', right: '0rem' }}>
			{/* <GridToolbarColumnsButton /> */}
			{/* <GridToolbarDensitySelector /> */}
			<CustomExportButton />
		</span>
	</GridToolbarContainer>
);
const CurrencyAssumptionModal = (props: InfoModalProps) => {
	const {
		onCloseModal,
		isOpen,
		CalculatorIds,
		ApplyCostType,
		SelectedItem, assumptionList, userInput, currencyDataBaseList
	} = props;
	const [DataList, setDataList] = useState<any>([]);
	const [SelectedMaterial, setSelectedMaterial] = useState<any>([]);

	const [loader, setLoader] = useState<any>(false);




	const [costData, setCostData] = useState<any>(null)

	const [Count, setCount] = useState<any>(0)
	const [MaterialType, setMaterialType] = useState<any>(null)


	const [NextLoader, setNextLoader] = useState<any>(false)
	const [PreviousLoader, setPreviousLoader] = useState<any>(false)
	const ErrorModalFiles = useBaseModal()
	const [ErrorModalFileMsg, setErrorModalFileMsg] = useState<any>();



	let RevisionData = currencyDataBaseList && currencyDataBaseList?.filter((item: any) => { return item?.id == SelectedItem[0] })[0]





	const MaterialCheckBoxhandler = (id: any, item: any) => {



		if (id == "avg") {
			setCostData(item?.avg_manual_value)
			API.post(`/cost/update_calculation/`, {
				calculation_id: userInput?.id,




				currency_type: "avg",




			}).then((res: any) => {




				// setCostScrapData(null)
				// setScrapCostType([])

			}).catch((err: any) => {
				setNextLoader(false)
				const { data } = err.response;

				ErrorModalFiles.open();
				setErrorModalFileMsg(data);
				console.log("Server Error")
			})
		}
		else if (id == "min") {
			setCostData(item?.min_manual_value)
			API.post(`/cost/update_calculation/`, {

				calculation_id: userInput?.id,



				currency_type: "min",




			}).then((res: any) => {



				// 	setCostData(null)
				// setCostScrapData(null)
				// setScrapCostType([])

			}).catch((err: any) => {
				setNextLoader(false)
				const { data } = err.response;

				ErrorModalFiles.open();
				setErrorModalFileMsg(data);
				console.log("Server Error")
			})
		} else if (id == "max") {
			setCostData(item?.max_manual_value)
			API.post(`/cost/update_calculation/`, {

				calculation_id: userInput?.id,

				currency_type: "max",




			}).then((res: any) => {



				// 	setCostData(null)
				// setCostScrapData(null)
				// setScrapCostType([])

			}).catch((err: any) => {
				setNextLoader(false)
				const { data } = err.response;

				ErrorModalFiles.open();
				setErrorModalFileMsg(data);
				console.log("Server Error")
			})
		} else {
			
			setCostData({ name: item?.revision_name, value: item?.manual_value })
			API.post(`/cost/update_calculation/`, {

				calculation_id: userInput?.id,

				currency_type: "single",
				revision: item?.revision




			}).then((res: any) => {



				// 	setCostData(null)
				// setCostScrapData(null)
				// setScrapCostType([])

			}).catch((err: any) => {
				setNextLoader(false)
				const { data } = err.response;

				ErrorModalFiles.open();
				setErrorModalFileMsg(data);
				console.log("Server Error")
			})
		}



		setSelectedMaterial([id]);

	};



	const AssuptionList = assumptionList && Array.isArray(assumptionList) && assumptionList?.map((item: any) => { return item?.id }) || []



	useEffect(() => {
		setLoader(true)
		API.get(`/db/revision_currency/`, {

			revision: RevisionData?.revision,
			assumption_list: AssuptionList?.toString(),
			currency_id: RevisionData?.id,
		}).then((res: any) => {


			setDataList(res?.data);
			setLoader(false)



		}).catch((err: any) => {

			setLoader(false)
		})
	}, [SelectedItem]);








	const ApplyHandler = () => {
		ApplyCostType();

	};

	const closedHandler = () => {
		onCloseModal()
		ApplyCostType();
	}





	return (
		<div>
			<Modal open={isOpen}
			// onClose={onCloseModal}
			>



				<Box sx={style}>

					{ErrorModalFiles.isOpen && <ErrorModalFilesMail
						isOpen={ErrorModalFiles.isOpen}
						onCloseModal={ErrorModalFiles.close}
						ErrorMsg={ErrorModalFileMsg}
					/>}
					<Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
						<Typography
							style={{
								fontSize: '1rem',
								fontWeight: '600',
								width: '100%',
								textAlign: 'center',
								marginRight: '-2rem',
							}}>
							Select Currency Cost
						</Typography>
						<CancelIcon
							titleAccess='Close'
							sx={{ cursor: 'pointer', margin: '0.5rem' }}
							onClick={() => closedHandler()}
						/>
					</Box>
					<Divider
						sx={{
							borderColor: 'primary.light',
							margin: '0 1rem',
							marginBottom: '1rem',
						}}
					/>
					<Box
						sx={{ maxHeight: '65vh', width: '100%' }}
						adl-scrollbar='true'
						adl-scrollbar-width='0.3'>
						{loader ? <>
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
						</>
							:

							<>

								<Typography sx={{ fontSize: '1rem', fontWeight: '600' }}> {1}. Currency Name: {DataList && DataList[Count] && DataList[Count]?.country_name} ({DataList[Count] && DataList[Count]?.currency_code}) ({DataList[Count] && DataList[Count]?.other_revision?.length})</Typography>
								<Table
									stickyHeader
									aria-label='sticky table'
									sx={{ minWidth: 650 }}>
									<TableHead
										sx={{ background: '#ddefff !important' }}>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												borderBottomColor: 'primary.light',
												backgroundColor: 'primary.light',
											}}>
											Name
										</TableCell>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												borderBottomColor: 'primary.light',
												backgroundColor: 'primary.light',
											}}>
											Currency Cost ({DataList[Count] && DataList[Count]?.currency_code})
										</TableCell>

									</TableHead>
									<TableBody>
										{DataList && DataList[Count]?.other_revision?.map(
											(item: any, inde: any) => {
												return (
													<TableRow>
														<TableCell
															component='th'
															scope='row'
															sx={{ borderBottom: 'none', padding: '0.2rem 0.5rem' }}>
															{item?.revision_name}
														</TableCell>
														<TableCell
															component='th'
															scope='row'
															sx={{
																borderBottom: 'none', padding: '0.2rem 0.5rem',
																borderRigth: '1px solid rgba(224, 224, 224, 1) !important',
															}}>
															{item?.manual_value?.toFixed(2)}

															<Checkbox
																style={{ padding: '3px', margin: '0 1rem 0 0.5rem' }}
																{...label}
																// checked={chekboxvalueMaterial?.includes(
																// 	item?.id
																// )}
																checked={SelectedMaterial && SelectedMaterial?.includes(item?.id)}
																// onChange={(e: any) => MaterialCheckBoxhandler(
																// 	item?.id,
																// 	DataList[0]?.material_code,

																// )} 
																onChange={(e: any) => MaterialCheckBoxhandler(
																	item?.id,
																	item,

																)}
															/>
														</TableCell>

													</TableRow>
												);
											}
										)}
										<TableRow>
											<TableCell
												sx={{ borderBottom: 'none', padding: '0.2rem 0.5rem' }}></TableCell>
											<TableCell sx={{ borderBottom: 'none', padding: '0.2rem 0.5rem' }}>
												<TableRow sx={{ display: 'flex !important' }}>
													<TableCell
														sx={{
															borderBottom: 'none',
															padding: '0rem !important',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															width: '100%',
														}}>
														<Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap', color: 'green' }}>Minimum  ({DataList && DataList[Count]?.min_manual_value?.toFixed(2)})</Typography>
														<Checkbox
															style={{ padding: '3px', margin: '0 1rem 0 0.5rem', color: 'green' }}
															{...label}
															// checked={datavalue(DataList[0])[0] &&
															// 	datavalue(DataList[0])[0][DataList[0].material_code] &&
															// 	datavalue(DataList[0])[0][DataList[0].material_code]
															// 		?.Min
															// 	? true
															// 	: false}

															checked={SelectedMaterial && SelectedMaterial?.includes("min") ? true : false}

															onChange={(e: any) => MaterialCheckBoxhandler(
																"min",
																DataList[Count]

															)}
														// onChange={() => CostType(
														// 	DataList[0],
														// 	DataList[0]?.min_material_cost,
														// 	DataList[0],
														// 	'Min'
														// )} 

														/>
													</TableCell>
													<TableCell
														sx={{
															borderBottom: 'none',
															padding: '0rem !important',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															width: '100%',
														}}>
														<Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap', color: '#ffc800' }}>Average ({DataList && DataList[Count]?.avg_manual_value?.toFixed(2)})</Typography>
														<Checkbox
															style={{ padding: '3px', margin: '0 1rem 0 0.5rem', color: '#ffc800' }}
															{...label}
															// checked={datavalue(DataList[0])[0] &&
															// 	datavalue(DataList[0])[0][DataList[0].material_code] &&
															// 	datavalue(DataList[0])[0][DataList[0].material_code]
															// 		?.Average
															// 	? true
															// 	: false}

															checked={SelectedMaterial && SelectedMaterial?.includes("avg") ? true : false}

															onChange={(e: any) => MaterialCheckBoxhandler(
																"avg",
																DataList[Count]

															)}
														// onChange={() => CostType(
														// 	DataList[0],
														// 	DataList[0]?.avg_material_cost,
														// 	DataList[0],
														// 	'Average'
														// )}

														/>
													</TableCell>
													<TableCell
														sx={{
															borderBottom: 'none',
															padding: '0rem !important',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															width: '100%',
														}}>
														<Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap' }}>Maximum ({DataList && DataList[Count]?.max_manual_value?.toFixed(2)})</Typography>
														<Checkbox
															style={{ padding: '3px', margin: '0 1rem 0 0.5rem', color: '#007fff' }}
															{...label}
															// checked={datavalue(DataList[0])[0] &&
															// 	datavalue(DataList[0])[0][DataList[0].material_code] &&
															// 	datavalue(DataList[0])[0][DataList[0].material_code]
															// 		?.Max
															// 	? true
															// 	: false

															// }

															// onChange={() => CostType(
															// 	DataList[0],
															// 	DataList[0]?.max_material_cost,
															// 	DataList[0],
															// 	'Max'
															// )} 

															checked={SelectedMaterial && SelectedMaterial?.includes("max") ? true : false}
															onChange={(e: any) => MaterialCheckBoxhandler(
																"max",
																DataList[Count]

															)}

														/>
													</TableCell>

												</TableRow>
												<TableRow>
													<TableCell
														colSpan={3}
														sx={{
															background: '#ddefff !important',
															padding: '0.2rem 0',
															width: '100%',
														}}>

														{/* Value :&nbsp;
													{datavalue(DataList[0])[0] &&
														datavalue(DataList[0])[0][DataList[0].material_code] &&
														datavalue(DataList[0])[0][DataList[0].material_code] &&
														Object.values(
															datavalue(DataList[0])[0] &&
															datavalue(DataList[0])[0][DataList[0].material_code] &&
															datavalue(DataList[0])[0][DataList[0].material_code]
														)} */}

														{
															SelectedMaterial && SelectedMaterial?.length > 0 ? <>


																Selected Value:
																{SelectedMaterial && SelectedMaterial?.includes("max") ? <span>



																	Maximum  <b>{costData && costData?.toFixed(2)} {DataList[Count] && DataList[Count]?.currency_code}</b>
																</span> : SelectedMaterial && SelectedMaterial?.includes("min") ? <span>Minimum  <b>{costData && costData} {DataList[Count] && DataList[Count]?.currency_code}</b> </span> : SelectedMaterial && SelectedMaterial?.includes("avg") ? <span>Average <b>{costData && costData?.toFixed(2)} {DataList[Count] && DataList[Count]?.currency_code}</b></span> : <span>{costData && costData !== undefined && costData?.name}  <b>{costData && costData !== undefined && costData?.value?.toFixed(2)} {DataList[Count] && DataList[Count]?.currency_code}</b></span>


																}
															</> :
																<>
																	Selected Value:
																</>



														}
													</TableCell>
												</TableRow>
											</TableCell>

										</TableRow>





									</TableBody>
								</Table></>
						}
					</Box>
					{loader == false &&
						<Box sx={{ display: 'flex', columnGap: '2rem', justifyContent: 'flex-end', alignItems: 'center', width: '100%', padding: '0.5rem' }}>

							{


								<LoadingButton
									disabled={SelectedMaterial && SelectedMaterial?.length == 0 ? true : false}
									// disabled={DataList?.length<=Count?false:true} 
									size='small' variant='contained' onClick={() => ApplyHandler()}>
									Proceed
								</LoadingButton>

							}

						</Box>}
				</Box>
			</Modal>
		</div>
	);
};

export default CurrencyAssumptionModal;
