import * as React from 'react';
import Modal from '@mui/material/Modal';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Autocomplete, Box, Button, Checkbox, Divider, FormControl, IconButton, InputLabel, MenuItem, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, TextField, TextareaAutosize, Typography, } from '@mui/material';
import auto from '../../../Assets/images/auto.png';
import Switch from '@mui/material/Switch';
import FunctionsIcon from '@mui/icons-material/Functions';
import IdeaTableOptionsInput from './IdeaInputComponent/IdeaTableOptionsInput';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import IdeaEntryTableComponents from './IdeaEntryTable/IdeaEntryTableComponents';
import { API } from '../../../api-services';
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { CircularProgress, } from "@mui/material";
import styles from "./BOMEntrySection.module.scss";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { debounce } from "lodash";
import { ADMIN } from "../../../Redux/Services/admin.service";
// import { NumberInputBox } from "./NumberInputBox";
// import { TextInputBox } from "./TextInputBox";
// import { DescriptionInputBox } from "./DescriptionInputBox";
// import { SingleSelectInputBox } from "./SingleSelectInputBox";
import { useQueryDispatch } from "../../../CustomHook/useQueryDispatch";
import {
  getCustomValidations,
  resetCustomValidations,
} from "../../../Redux/Actions/customValidations.actions";
import {
  getBOMConfigurationsByCategory,
  resetParameterConfigs,
} from "../../../Redux/Actions/parameterConfig.actions";
// import {
//   getMappedWorkstations,
//   resetWorkstations,
// } from "../../../Redux/Actions/workstation.actions";
import swal from "sweetalert";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
// import { BooleanInputBox } from "./BooleanInputBox";
// import { DateInputBox } from "./DateInputBox";
// import { DatabaseInputBox } from "./DatabaseInputBox";
// import { CheckBoxComponent } from "./CheckBoxComponent";
import { useSelector } from "react-redux";
import { userSelectors } from "../../../Redux/Reducers/user.reducer";
// import ImageSearchIcon from '@mui/icons-material/ImageSearch';
// import BOMImageBasedSearchModal from "./BOMImageBasedSearchModal";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import { userPermissionAction } from '../../BOMCreate/BOMEntry/BOMEntryLandingPage';
import IdeaTextInputBox from './IdeaInputComponent/IdeaTextInputBox';
import IdeaSingleSelectInputBox from './IdeaInputComponent/IdeaSingleSelectInputBox';
import IdeaNumberInputBox from './IdeaInputComponent/IdeaNumberInputBox';
import IdeaDescriptionInputBox from './IdeaInputComponent/IdeaDescriptionInputBox';
import IdeaBooleanInputBox from './IdeaInputComponent/IdeaBooleanInputBox';
import IdeaDateInputBox from './IdeaInputComponent/IdeaDateInputBox';
import IdeaDatabaseInputBox from './IdeaInputComponent/IdeaDatabaseInputBox';
import IdeaCheckBoxComponent from './IdeaInputComponent/IdeaCheckBoxComponent';
import IdeaImagesandVideosCombine from './IdeaEntryImagesandVideos/IdeaImagesandVideosCombine';
import { ParametershowCheckBox } from './IdeaInputComponent/ParametershowCheckBox';
import { IdeaCreateCostModalComponentsForm } from './IdeaCreateCostModalComponentsForm';
// import { IdeaCreateCostModelForm } from './IdeaCreateCostModal/IdeaCreateCostModelForm';
// import { IdeaCreateCostModelForm } from '../IdeaCreateCostModelForm';






const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {lg:'95vw',xl:'90vw'},
    bgcolor: 'background.paper',
    borderRadius: '10px',
    p: 1,
  };


export interface IOtherIdeaDetailsModalProps {
    onCloseModal: any;
    isOpen: any;
    subSystem: any;
    setrecyclebinCounter?: any;
    setParentsCounter?: any;
    ParentsCounter?: any;
    setSyncColor?: any;
    newIdeaId?:any;
    data?:any
    closeParentModal:any;
    getTableData?: any;
    getUpdatedIdeaList?: any;
}

export default function OtherIdeaDetailsModal (props: IOtherIdeaDetailsModalProps) {
    const { onCloseModal, isOpen, subSystem,newIdeaId,data, closeParentModal, getTableData, getUpdatedIdeaList } = props;
    
    const [age, setAge] = React.useState('');


    const top100Films = [
      { title: 'The Shawshank Redemption', year: 1994 },
      { title: 'The Godfather', year: 1972 },
      { title: 'The Godfather: Part II', year: 1974 },
      { title: 'The Dark Knight', year: 2008 },
      { title: '12 Angry Men', year: 1957 },
      { title: "Schindler's List", year: 1993 },
    ];
  
    // const defaultProps = {
    //   options: top100Films,
    //   getOptionLabel: (option: FilmOptionType) => option.title,
    // };
    // const flatProps = {
    //   options: top100Films.map((option) => option.title),
    // };
    // const [value, setValue] = React.useState<FilmOptionType | null>(null);
  
    const [checked, setChecked] = React.useState(true);

    const SwitchhandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.checked);
    };


    const {
      setrecyclebinCounter,
      setParentsCounter,
      ParentsCounter,
      setSyncColor,
    } = props;


    const history = useHistory();
    const userProfilePic = useSelector(userSelectors.selectAll);
    const inputFieldRef = React.useRef<any>();
    const nextInputFieldRef = React.useRef<any>();
    const { subSystemId,topVault,projectId ,projectName,system,ideaId,ideaIdName} = useRouteParams<any>();
    const [workStationList, setWorkStationList] = React.useState<any>([]);
    const [workstationLoader, setWorkstationLoader] =
      React.useState<boolean>(false);
    const [partName, setPartName] = React.useState<any>("");
    const [PartData, setPartData] = React.useState<any>();
    const [partQty, setPartQty] = React.useState<any>("");
    const [category, setCategory] = React.useState<any>("");
    const [BOMDetails, setBOMDetails] = React.useState<any>([]);
    const [categoryList, setCategoryList] = React.useState<any>([]);
    const [Ideaparameters, setIdeaparameters] = React.useState<any>([]);
    const [isIdeaparametersLoading, setIsIdeaparametersLoading] =
      React.useState<boolean>(false);
    const [details, setDetails] = React.useState<any>({});
    const [BOMStatus, setBOMStatus] = React.useState<any>("");

    const [tableDataGetCount, setTableDataGetCount] = React.useState<any>(1);
    const [allValues, setAllValues] = React.useState<any>({});
    const userAction = React.useContext(userPermissionAction);
    const [leafNode, setLeafNode] = React.useState<any>(false);


    let UserAction = userAction && userAction?.action;
    const obj = (tv: any, vlt: any) => {
      if (vlt == 0) {
        return { top_vault: tv };
      } else {
        return { system: vlt };
      }
    };
  
    const getobj = (v: any, tv: any) => {
      if (v == 0) {
        return { top_vault: tv };
      } else {
        return { system: v };
      }
    };
  
  
    const getAllValues = () => {
      API.get("/idea/idea_create_view/", { ...obj(topVault, subSystem) }, 0)
        .then((res: any) => {
          setAllValues(res.data[0]?.details);
        })
        .catch((err: any) => {});
    };
  
    React.useEffect(() => {
      getAllValues();
    }, [subSystem, topVault]);
  
    // const BOMValidationStatus = () => {
    //   API.get(
    //     "/xcpep/bom_hirearchy_function/",
    //     {
    //       bom_status: true,
    //       ...obj(topVault, system),
    //     },
    //     0
    //   )
    //     .then((res: any) => {
    //       setBOMStatus(res.data?.bom_status);
    //     })
    //     .catch((err: any) => {});
    // };
  
    // React.useEffect(() => {
    //   BOMValidationStatus();
    // }, [system, topVault]);
  
    // React.useEffect(() => {
    //   if (ParentsCounter !== 0) {
    //     BOMValidationStatus();
    //   }
    // }, [ParentsCounter]);
  

    const getIdeaParametersData = () => {
      setIsIdeaparametersLoading(true);
      API.get(
        "/idea/idea_create_view/",
        {
          id:ideaId,
          part_details: true,
          top_vault: topVault,
        },
        0
      )
        .then((res: any) => {
          setIsIdeaparametersLoading(false);
          setIdeaparameters(res.data);
        })
        .catch((err: any) => {
          setIsIdeaparametersLoading(false);
        });
    };
  
    // useQueryDispatch({
    //   query: {
    //     action: getCustomValidations,
    //     arg: { categoryId: category && category, topVaultId: +topVault },
    //     condition: Boolean(1),
    //   },
    //   cleanup: {
    //     action: resetCustomValidations,
    //     args: [],
    //   },
    //   dependency: [topVault, subSystem, category],
    // });
  
    // useQueryDispatch({
    //   query: {
    //     action: getMappedWorkstations,
    //     arg: { topVaultId: +topVault, vaultId: +system },
    //   },
    //   cleanup: {
    //     action: resetWorkstations,
    //     args: [],
    //   },
    //   dependency: [topVault, system, category],
    // });
  
    const configsFetched = useQueryDispatch({
      query: {
        action: getBOMConfigurationsByCategory,
        arg: {
          topVaultId: +topVault,
          categoryId: category,
          all: true,
        },
        condition: Boolean(category),
      },
      cleanup: {
        action: resetParameterConfigs,
        args: [],
      },
      dependency: [topVault, system, category],
    });
  
    React.useEffect(() => {
      if(ideaId !=0){
      setTimeout(()=>{
        getIdeaParametersData();
        
      },500)
    }
      
    }, [subSystem, category, topVault,ideaId]);
  
    // const getWorkStationsData = () => {
    //   setWorkstationLoader(true);
    //   API.get(
    //     "/auth/workstation/",
    //     {
    //       top_vault: topVault,
    //       system: system != 0 ? system : undefined,
    //       workstation_list: true,
    //     },
    //     0
    //   )
    //     .then((res: any) => {
    //       setWorkstationLoader(false);
    //       setWorkStationList(res.data);
    //       if (workstation == 0) {
    //         history.push(
    //           `/bomentry/${projectId}/${projectName}/${topVault}/${system}/${treeabbr}/${res.data[0]?.id}/${isLeaf}/${copyStatus}`
    //         );
    //       }
    //     })
    //     .catch((err: any) => {
    //       setWorkstationLoader(false);
    //     });
    // };
    const getCategoryList = () => {
      API.get(
        "/config/template_config/",
        {
          top_vault: topVault,
          bom_create: true,
          is_leaf: false,
        },
        0
      )
        .then((res: any) => {
          setCategoryList(res.data);
        })
        .catch((err: any) => {});
    };
  
    React.useEffect(() => {
      getCategoryList();
    }, [subSystem, topVault]);
  
    const getBOMTVDetailsData = () => {
      API.get(
        "/xcpep/top_vault/",
        {
          top_vault: topVault,
          module: 9,
        },
        0
      )
        .then((res: any) => {
          setBOMDetails(res.data[0]);
          setPartData(res?.data[0]?.product_id);
          setPartName(res.data[0]?.name);
          setCategory(res.data[0]?.category);
          setPartQty(res.data[0]?.quantity);
        })
        .catch((err: any) => {});
    };
    const getVaultBOMDetailsData = () => {
      API.get(
        "/xcpep/vault_info/",
        {
          ...obj(topVault, subSystem),
          module: 9,
        },
        0
      )
        .then((res: any) => {
          setPartName(res.data[0]?.name);
          setPartData(res?.data[0]?.part_no);
          setCategory(res.data[0]?.category);
          setPartQty(res.data[0]?.quantity);
          setBOMDetails(res.data[0]);
        })
        .catch((err: any) => {});
    };
    React.useEffect(() => {
      if (subSystem == 0) {
        getBOMTVDetailsData();
      } else {
        getVaultBOMDetailsData();
      }
    }, [topVault, subSystem]);
    // React.useEffect(() => {
    //   getWorkStationsData();
    // }, [ParentsCounter, system, topVault, category]); //topvalut & valut removed here for multilple id select looping issue
    const getUrl = (vltt: any) => {
      if (vltt == 0) {
        return "/xcpep/top_vault";
      } else {
        return "/xcpep/vault_info";
      }
    };
    const updateDataVault = async (
      name: any,
      value: any,
      vlt: any,
      topvlt: any,
      rt: any
    ) => {
      API.put(
        `${getUrl(vlt)}/${vlt != 0 ? +vlt : +topvlt}/`,
        {
          [name]: value,
          validated: false,
        },
        {},
        0
      )
        .then((res: any) => {
          setSyncColor("red");
          // BOMValidationStatus();
          // getWorkStationsData();
  
          ADMIN.toast.info("Updated Successfully");
        })
        .catch((err: any) => {
          const { data } = err.response;
          Swal.fire({
            title: "Error!",
            text: data[0],
            icon: "error",
            confirmButtonText: "Ok",
          });
        });
    };
  
    const updateDataVaultParams = async (
      name: any,
      value: any,
      vlt: any,
      tv: any
    ) => {
      API.put(
        `/idea/pool/${ideaId}/`,
        {
          ...{parameter_json:{[name]: value}},
        },
        {
          ...getobj(vlt, tv),
          module:15
        },
        0
      )
        .then((res: any) => {
          setSyncColor("red");
          // getIdeaParametersData();
          // BOMValidationStatus();
          ADMIN.toast.info("Updated Successfully");
        })
        .catch((err: any) => {
          const { data } = err.response;
          Swal.fire({
            title: "Error!",
            text: data[0],
            icon: "error",
            confirmButtonText: "Ok",
          });
        });
    };
  
    // const updateDataVaultCheckbox = async (
    //   name: any,
    //   value: any,
    //   vlt: any,
    //   tv: any
    // ) => {
    //   API.put(
    //     `/xcpep/component_details/74699/`,
    //     {
    //       [name]: value,
    //     },
    //     {
    //       ...getobj(vlt, tv),
    //     },
    //     0
    //   )
    //     .then((res: any) => {
    //       setSyncColor("red");
    //       getIdeaParametersData();
    //       // BOMValidationStatus();
    //       ADMIN.toast.info("Updated Successfully");
    //     })
    //     .catch((err: any) => {
    //       const { data } = err.response;
    //       Swal.fire({
    //         title: "Error!",
    //         text: data[0],
    //         icon: "error",
    //         confirmButtonText: "Ok",
    //       });
    //     });
    // };
  
    const ExactDebounce = React.useRef(
      debounce(async (value: any, name: any, idd: any, topvlt: any, rt: any) => {
        await updateDataVault(name, value, idd, topvlt, rt);
      }, 1000)
    ).current;
  
    const ExactDebounceParameters = React.useRef(
      debounce(async (value: any, name: any, idd: any, tv) => {
        await updateDataVaultParams(name, value, idd, tv);
      }, 1000)
    ).current;
  
   
  
    React.useEffect(() => {
      return () => {
        ExactDebounce.cancel(); // Cancel the debounced function
      };
    }, []);
  
    // const handleChangeInput = (event: any) => {
    //   const { name, value } = event.target;
    //   if (name === "name" || name === "quantity") {
    //     if (name === "quantity") {
    //       ExactDebounce(+value, name, system, topVault, "wo");
    //     } 
    //   } else {
    //     Swal.fire({
    //       title: "Are you sure?",
    //       text: "This action will delete the data mapped with the previous category.",
    //       icon: "warning",
    //       showCancelButton: true,
    //       confirmButtonText: "Yes",
    //       cancelButtonText: "No",
    //     }).then((result: any) => {
    //       if (result.isConfirmed) {
    //         API.put(
    //           `${getUrl(system)}/${system != 0 ? +system : +topVault}/`,
    //           {
    //             [name]: value,
    //             validated: false,
    //           },
    //           {},
    //           0
    //         )
    //           .then((res: any) => {
    //             setSyncColor("red");
    //             BOMValidationStatus();
    //             API.get(
    //               "/auth/workstation/",
    //               {
    //                 top_vault: topVault,
    //                 system: system != 0 ? system : undefined,
    //                 workstation_list: true,
    //               },
    //               0
    //             )
    //               .then((res: any) => {
    //                 setWorkstationLoader(false);
    //                 setWorkStationList(res.data);
    //                 // if (workstation == 0) {
    //                 //   history.push(
    //                 //     `/bomentry/${projectId}/${projectName}/${topVault}/${system}/${treeabbr}/${res.data[0]?.id}/${isLeaf}/${copyStatus}`
    //                 //   );
    //                 // }
    //               })
  
                  
    //               .catch((err: any) => {
    //                 setWorkstationLoader(false);
    //               });
    //             getIdeaParametersData();
    //             setTableDataGetCount((prev: any) => prev + 1);
    //             ADMIN.toast.info("Updated Successfully");
    //           })
    //           .catch((err: any) => {
    //             const { data } = err.response;
    //             Swal.fire({
    //               title: "Error!",
    //               text: data[0],
    //               icon: "error",
    //               confirmButtonText: "Ok",
    //             });
    //           });
    //       }
    //     });
    //   }
    //   if (name == "name") {
    //     setPartName(value);
    //   } else if (name == "quantity") {
    //     setPartQty(value);
    //   } else if (name == "category") {
    //     setCategory(value);
    //   }
    // };
  
    // const getChecked = (itemm: any) => {
    //   if (itemm?.exceptions?.includes(itemm?.value)) {
    //     return true
    //   } else {
    //     return false
    //   }
    // }

    const handleSelectSystems = (
      event: any,
      newSystemValue: any,
      key: any,
      v: any,
      tv: any
    ) => {
      ExactDebounceParameters(newSystemValue, key, v, tv);
    };
    
    const renderBoleanInputs = (item: any, index: any) => {
      switch (item?.data_type) {        
        case "boolean":
          return (
            <Box sx={{ backgroundColor: item?.color,display:'flex',alignItems:'center' }}>
                <Box
              sx={{
                fontSize: "0.87rem",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                width:'4rem',
              }}
            >               
                {!item?.exceptions?.includes(item?.value) ? (
                  <IdeaBooleanInputBox
                    item={item}
                    details={details && details}
                    // BOMValidationStatus={BOMValidationStatus}
                    allValues={allValues && allValues}
                    getAllValues={getAllValues}
                  />
                ) : (
                  item?.value
                )}
             </Box> 
                
                <Box
              sx={{
                fontSize: "0.87rem",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                lineHeight: 1,
                width:'100%',
              }}
            >
                {item?.paramter_name}{" "}
                {item?.required && <sup style={{ color: "red" }}>*</sup>}
                </Box>           
                        
          </Box>
          );
      };
    };



    const renderInputs = (item: any, index: any) => {
      switch (item?.data_type) {
        case "number":
        case "float":
        case "nonzero-positive-int":
        case "nonzero-positive-float":
        case "positive-float":
        case "nonzero-float":
        case "percentage":
        case "positive-int":
        
        case "integer":
          return (
            <TableRow sx={{ backgroundColor: item?.color }}>
              <TableCell
                sx={{
                  fontSize: "12px",
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                  lineHeight: 1,
                }}
              >
                {item?.paramter_name}{" "}
                {item?.required && <sup style={{ color: "red" }}>*</sup>}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "12px",
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {/* {item?.exceptions?.length > 0 && (
                  <IdeaCheckBoxComponent
                    check={item?.exceptions?.includes(item?.value)}
                    item={item}
                    getIdeaParametersData={getIdeaParametersData}
                    // BOMValidationStatus={BOMValidationStatus}
                  />
                )} */}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "12px",
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              ><Box sx={{display:'flex',alignItems:'center',columnGap:'1rem'}}>
                {!item?.exceptions?.includes(item?.value) ? (
                  <IdeaNumberInputBox
                    item={item}
                    details={details && details}
                    vaultIdd={subSystem}
                    // BOMValidationStatus={BOMValidationStatus}
                    allValues={allValues && allValues}
                    getAllValues={getAllValues}
                  />
                ) : (
                  item?.value
                )}

                  {item?.exceptions?.length > 0 && (
                  <IdeaCheckBoxComponent
                    check={item?.exceptions?.includes(item?.value)}
                    item={item}
                    getIdeaParametersData={getIdeaParametersData}
                    // BOMValidationStatus={BOMValidationStatus}
                  />
                  )}
               </Box>
              </TableCell>
              {/* <TableCell
                sx={{
                  fontSize: "12px",
                  padding: "0.1rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {item?.unit ? item?.unit : "-"}
              </TableCell> */}
            </TableRow>
          );
        case "text":
        case "string":
          return (
            <TableRow sx={{ backgroundColor: item?.color }}>
              <TableCell
                sx={{
                  fontSize: "12px",
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                  lineHeight: 1,
                }}
              >
                {item?.paramter_name}{" "}
                {item?.required && <sup style={{ color: "red" }}>*</sup>}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "12px",
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {item?.exceptions?.length > 0 && (
                  <IdeaCheckBoxComponent
                    check={item?.exceptions?.includes(item?.value)}
                    item={item}
                    getIdeaParametersData={getIdeaParametersData}
                    // BOMValidationStatus={BOMValidationStatus}
                  />
                )}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "12px",
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {
                  <IdeaTextInputBox
                    item={item}
                    details={details}
                    // BOMValidationStatus={BOMValidationStatus}
                    allValues={allValues && allValues}
                    getAllValues={getAllValues}
                  />
                }
              </TableCell>              
            </TableRow>
          );
  
        case "textarea":
        case "longtext":
          return (
            <TableRow sx={{ backgroundColor: item?.color }}>
              <TableCell
                sx={{
                  fontSize: "12px",
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                  lineHeight: 1,
                }}
              >
                {item?.paramter_name}{" "}
                {item?.required && <sup style={{ color: "red" }}>*</sup>}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "12px",
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                <ParametershowCheckBox checkedValue={item?.text_parameter} item={item} type={"text"}/>
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "12px",
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {
                  <IdeaDescriptionInputBox
                    item={item}
                    details={details}
                    // BOMValidationStatus={BOMValidationStatus}
                    allValues={allValues && allValues}
                    getAllValues={getAllValues}
                  />
                }
              </TableCell>              
            </TableRow>
          );
              
        case "multiselect":
          return (
            <TableRow sx={{ backgroundColor: item?.color }}>
              <TableCell
                sx={{
                  fontSize: "12px",
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                  lineHeight: 1,
                }}
              >
                {item?.paramter_name}{" "}
                {item?.required && <sup style={{ color: "red" }}>*</sup>}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "12px",
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {item?.exceptions?.length > 0 && (
                  <IdeaCheckBoxComponent
                    check={item?.exceptions?.includes(item?.value)}
                    item={item}
                    getIdeaParametersData={getIdeaParametersData}
                    // BOMValidationStatus={BOMValidationStatus}
                  />
                )}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "12px",
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                <FormControl fullWidth>
                  <Autocomplete
                    multiple
                    id="tags-standard"
                    disabled={!item?.editable}
                    // disabled={
                    //   (copyStatus == false || copyStatus == "false") &&
                    //   (UserAction?.includes("U") || UserAction?.includes("C"))
                    //     ? false
                    //     : true
                    // }
                    limitTags={1}
                    // onChange={handleOnChange}
                    disableClearable
                    disableCloseOnSelect
                    sx={{
                      ".MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator":
                        {
                          color: "primary.main",
                        },
                      ".MuiButtonBase-root.MuiChip-root": {
                        backgroundColor: "primary.light",
                        height: "26px",
                      },
                    }}
                    options={item?.allowed_values}
                    getOptionLabel={(option: any) => option}
                    defaultValue={item?.value || []}
                    onChange={(event: any, newSystemValue: any) => {
                      handleSelectSystems(
                        event,
                        newSystemValue,
                        item?.key,
                        subSystem,
                        topVault
                      );
                    }}
                    // getOptionDisabled={getOptionDisabled}
                    renderOption={(props, option) => (
                      <li {...props} style={{ fontSize: "12px" }}>
                        {option}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Select Option"
                        sx={{
                          "& .MuiInput-underline:before": {
                            borderBottomColor: "transparent",
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor: "primary.main",
                          },
                          ".MuiInputBase-root.MuiInput-root": {
                            fontSize: "12px !important",
                            backgroundColor: "rgba(255,255,255,0.3)",
                            paddingLeft: "0.3rem",
                          },
                          ".MuiSvgIcon-root": { color: "primary.main" },
                        }}
                      />
                    )}
                  />
                </FormControl>
              </TableCell>              
            </TableRow>
          );
        case "country":
        case "dropdown":
        case "singleselect": 
            return (
              <TableRow sx={{ backgroundColor: item?.color }}>
                <TableCell
                  sx={{
                    fontSize: "12px",
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                    lineHeight: 1,
                  }}
                >
                  {item?.paramter_name}{" "}
                  {item?.required && <sup style={{ color: "red" }}>*</sup>}
                </TableCell>
    
                <TableCell
                  sx={{
                    fontSize: "12px",
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  {item?.exceptions?.length > 0 && (
                    <IdeaCheckBoxComponent
                      check={item?.exceptions?.includes(item?.value)}
                      item={item}
                      getIdeaParametersData={getIdeaParametersData}
                      // BOMValidationStatus={BOMValidationStatus}
                    />
                  )}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "12px",
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  <IdeaSingleSelectInputBox
                    item={item}
                    details={details}
                    // BOMValidationStatus={BOMValidationStatus}
                    allValues={allValues && allValues}
                    getAllValues={getAllValues}
                  />
                </TableCell>              
              </TableRow>
            );
  
        case "date":
          return (
            <TableRow sx={{ backgroundColor: item?.color }}>
              <TableCell
                sx={{
                  fontSize: "12px",
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                  lineHeight: 1,
                }}
              >
                {item?.paramter_name}{" "}
                {item?.required && <sup style={{ color: "red" }}>*</sup>}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "12px",
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {item?.exceptions?.length > 0 && (
                  <IdeaCheckBoxComponent
                    check={item?.exceptions?.includes(item?.value)}
                    item={item}
                    getIdeaParametersData={getIdeaParametersData}
                    // BOMValidationStatus={BOMValidationStatus}
                  />
                )}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "12px",
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {!item?.exceptions?.includes(item?.value) ? (
                  <IdeaDateInputBox
                    item={item}
                    details={details && details}
                    // BOMValidationStatus={BOMValidationStatus}
                    allValues={allValues && allValues}
                    getAllValues={getAllValues}
                  />
                ) : (
                  item?.value
                )}
              </TableCell>
              
            </TableRow>
          );
  
        case "database":
          return (
            <TableRow sx={{ backgroundColor: item?.color }}>
              <TableCell
                sx={{
                  fontSize: "12px",
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                  lineHeight: 1,
                }}
              >
                {item?.paramter_name}{" "}
                {item?.required && <sup style={{ color: "red" }}>*</sup>}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "12px",
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {item?.exceptions?.length > 0 && (
                  <IdeaCheckBoxComponent
                    check={item?.exceptions?.includes(item?.value)}
                    item={item}
                    getIdeaParametersData={getIdeaParametersData}
                    // BOMValidationStatus={BOMValidationStatus}
                  />
                )}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "12px",
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {!item?.exceptions?.includes(item?.value) ? (
                  <IdeaDatabaseInputBox
                    item={item}
                    details={details && details}
                    // BOMValidationStatus={BOMValidationStatus}
                    allValues={allValues && allValues}
                    getAllValues={getAllValues}
                  />
                ) : (
                  "NA"
                )}
              </TableCell>              
            </TableRow>
          );
      }
    };
  
    // const bomStatus = "Not Started";
  

  
    const DeleteHandler = () => {
      if (BOMDetails?.cost_status >= 1) {
        swal({
          // text: `Are you sure ?`,
          title: `This action will delete the BOM Parameter and link Costing`,
          buttons: ["Cancel", "Ok"],
          icon: "warning",
        }).then((confirm: any) => {
          if (confirm) {
            API.delete(`/xcpep/vault_info/${BOMDetails?.id}/`, { trash: true }, 0)
              .then((res: any) => {
                setParentsCounter((prev: any) => prev + 1);
                setrecyclebinCounter((prev: any) => prev + 1);
  
                // history.push(
                //   `/bomentry/${projectId}/${projectName}/${topVault}/${0}/delete/${workstation}/${isLeaf}/${copyStatus}`
                // );
                // getFeaturesData(targetFeatureData?.group);
                // var random: any = Math.floor(Math.random() * 1000000 + 1);
                // dispatch(increaseCounter(random));
                // dispatch(recyclebinCounter(random + 1));
              })
              .catch((err: any) => {
                const { data } = err?.response;
                Swal.fire({
                  icon: "error",
                  html: `<div>
                       <br />
                       <p style="color:"red">${data[0]}</p>   
                        </div>`,
                });
              });
          }
        });
      } else {
        swal({
          // text: `Are you sure ?`,
          title: `This action will  delete ${BOMDetails?.name}`,
  
          buttons: ["Cancel", "Ok"],
          icon: "warning",
        }).then((confirm: any) => {
          if (confirm) {
            API.delete(`/xcpep/vault_info/${BOMDetails?.id}/`, { trash: true }, 0)
              .then((res: any) => {
                setParentsCounter((prev: any) => prev + 1);
                setrecyclebinCounter((prev: any) => prev + 1);
  
                // history.push(
                //   `/bomentry/${projectId}/${projectName}/${topVault}/${0}/delete/${workstation}/${isLeaf}/${copyStatus}`
                // );
                // getFeaturesData(targetFeatureData?.group);
                // var random: any = Math.floor(Math.random() * 1000000 + 1);
                // dispatch(increaseCounter(random));
                // dispatch(recyclebinCounter(random + 1));
              })
              .catch((err: any) => {
                const { data } = err?.response;
                Swal.fire({
                  icon: "error",
                  html: `<div>
                       <br />
                       <p style="color:"red">${data[0]}</p>   
                        </div>`,
                });
              });
          }
        });
      }
    };
  
  
  
    const ImageSearchModal = useBaseModal();
    const ImageSearchHandler = () => {
      ImageSearchModal.open();
    }

  
    interface FilmOptionType {
      title: string;
      year: number;
    }

    const handleCloseModal = () => {
      onCloseModal();
      closeParentModal();
      if(window.location.href.split("/")[4] == "idea-generation-view"){
        console.log(getUpdatedIdeaList)
        getUpdatedIdeaList()

      } 
      else{
        getTableData();
      }     
    }


  return (
    <div>
        <Modal
        disableEscapeKeyDown={true} 
        // disableBackdropClick={true}       
        open={isOpen}
        // onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "center",
                  marginRight: '-2rem'
                }}
              >
                Idea Entry Form ({data?.name} - {ideaIdName})
              </Typography>
              {/* <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} /> */}
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{padding:'0 1rem',width:'100%'}}>
              <Box sx={{display:'grid',gridTemplateColumns:'1fr 0.5fr',width:'100%',marginBottom:'1rem'}}>
                <Box sx={{width:'100%',maxHeight:'81vh',padding:'0',backgroundColor:'#faebd7'}}
                 adl-scrollbar="true"
                 adl-scrollbar-width="0.3">   
                   <Box sx={{display:'flex',alignItems:'flex-start',backgroundColor:'#ffddba',padding:'0 0.5rem'}}>      
                    <Box sx={{width:'100%',display:'grid',gridTemplateColumns:'1fr 1fr 1fr',}}>  
                        {!isIdeaparametersLoading && Ideaparameters ? (
                            Ideaparameters.filter((item: any) => item.data_type === 'boolean').map((item: any, index: any) => {              
                            return renderBoleanInputs(item, index);               
                          })
                        ) : (<>
                          <TableRow>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton /></TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton /></TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton /></TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton /></TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton /></TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton /></TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton /></TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton /></TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton /></TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton /></TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton /></TableCell>
                              <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><Skeleton /></TableCell>
                          </TableRow></>
                        )}                    
                    </Box> 
                  </Box>                         
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead sx={{ backgroundColor: "primary.main" }}>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontSize: "12px",
                            padding: "0.1rem 0.5rem",
                            borderBottomColor: "primary.light",
                            backgroundColor: "primary.main",
                            color: "white",
                            width: { lg: "12vw", xl: "11vw" },
                          }}
                        >
                          Parameters
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "12px",
                            padding: "0.1rem 0rem",
                            borderBottomColor: "primary.light",
                            backgroundColor: "primary.main",
                            color: "white",
                            width: { lg: "2rem", xl: "3rem" },
                          }}
                        ></TableCell>
                        <TableCell
                          sx={{
                            fontSize: "12px",
                            padding: "0.1rem 0.5rem",
                            borderBottomColor: "primary.light",
                            backgroundColor: "primary.main",
                            color: "white",
                            width: { lg: "30vw", xl: "35vw" },
                          }}
                        >
                          Value
                        </TableCell>
                        {/* <TableCell
                          sx={{
                            fontSize: "12px",
                            padding: "0.1rem 0.5rem",
                            borderBottomColor: "primary.light",
                            backgroundColor: "primary.main",
                            color: "white",
                            width: "5rem",
                          }}
                        >
                          Unit
                        </TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!isIdeaparametersLoading && Ideaparameters ? (
                        Ideaparameters?.map((item: any, index: any) => {
                          return renderInputs(item, index);
                        })
                      ) : (
                        <>
                          <TableRow>
                            <TableCell
                              sx={{ padding: "1rem 0.5rem", borderBottom: "none", }}
                              colSpan={4}
                            >
                              <Box sx={{ width: "100%" }}>
                                <Skeleton
                                  animation="wave"
                                  variant="rectangular"
                                  height="2rem"
                                  width="100%"
                                  sx={{ marginBottom: "1rem" }}
                                />
                                <Skeleton
                                  animation="wave"
                                  variant="rectangular"
                                  height="2rem"
                                  width="100%"
                                  sx={{ marginBottom: "1rem" }}
                                />
                                <Skeleton
                                  animation="wave"
                                  variant="rectangular"
                                  height="2rem"
                                  width="100%"
                                  sx={{ marginBottom: "1rem" }}
                                />
                                <Skeleton
                                  animation="wave"
                                  variant="rectangular"
                                  height="2rem"
                                  width="100%"
                                  sx={{ marginBottom: "1rem" }}
                                />
                                <Skeleton
                                  animation="wave"
                                  variant="rectangular"
                                  height="2rem"
                                  width="100%"
                                  sx={{ marginBottom: "1rem" }}
                                />
                                <Skeleton
                                  animation="wave"
                                  variant="rectangular"
                                  height="2rem"
                                  width="100%"
                                  sx={{ marginBottom: "1rem" }}
                                />
                                <Skeleton
                                  animation="wave"
                                  variant="rectangular"
                                  height="2rem"
                                  width="100%"
                                  sx={{ marginBottom: "1rem" }}
                                />
                                <Skeleton
                                  animation="wave"
                                  variant="rectangular"
                                  height="2rem"
                                  width="100%"
                                  sx={{ marginBottom: "1rem" }}
                                />
                                <Skeleton
                                  animation="wave"
                                  variant="rectangular"
                                  height="2rem"
                                  width="100%"
                                  sx={{ marginBottom: "1rem" }}
                                />
                                <Skeleton
                                  animation="wave"
                                  variant="rectangular"
                                  height="2rem"
                                  width="100%"
                                  sx={{ marginBottom: "1rem" }}
                                />
                                <Skeleton
                                  animation="wave"
                                  variant="rectangular"
                                  height="2rem"
                                  width="100%"
                                  sx={{ marginBottom: "1rem" }}
                                />
                              </Box>
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                  </Table>
                  <Box>
                    {/*Cost Model  */}
                      {ideaId != 0 && <IdeaCreateCostModalComponentsForm />}
                  </Box>
                  <Box sx={{ margin: "1rem 0rem" }}>
                    <IdeaEntryTableComponents
                      tableDataGetCount={tableDataGetCount}                     
                    />
                  </Box>
                </Box>                               
                <Box sx={{maxHeight:'81vh',width:'100%',padding:'0 1rem'}}>                 
                    <IdeaImagesandVideosCombine />      
                </Box>
              </Box>
             
              <Box sx={{ display: 'flex', alignItems: 'center',justifyContent:'flex-end', columnGap: '1rem', marginBottom: '0.5rem' }}>               
                <Button variant="contained" size='small' onClick={handleCloseModal}>Close</Button>
              </Box>
            </Box>

        </Box>
      </Modal>
      
    </div>
  );
}
