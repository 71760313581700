import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import * as React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  RoleListScenario,
  RoleListScenarioLock,
} from "../../Constants/projectspecificmodules.constants";
import { API } from "../../api-services";

export interface IUserRoleEditProps {
  data: any;
  idd: any;
  rowData: any;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function UserRoleEdit(props: IUserRoleEditProps) {
  const { data, idd, rowData } = props;
  const [roles, setRoles] = React.useState<any>([]);
  React.useEffect(() => {
    if (data) {
      setRoles(data?.action);
    }
  }, [data]);
  const handleSelectChange = (event: any) => {
    const { name, value } = event.target;
    if (name === "roles") {
      setRoles(value);
      API.put(
        `/cost/scenario_share/${data.id}/`,
        {
          action: value,
        },
        {},
        0
      )
        .then((res: any) => {})
        .catch((err: any) => {});
    }
  };

  const getData = () => {
    if (rowData?.lock) {
      return RoleListScenarioLock;
    } else {
      return RoleListScenario;
    }
  };
  return (
    <FormControl fullWidth variant="standard">
      {/* <InputLabel
        shrink
        sx={{ color: "primary.main" }}
        id="demo-simple-select-label"
      >
        Update Permission
      </InputLabel> */}
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={roles && roles}
        multiple
        multiline
        name="roles"
        label="Role"
        placeholder="Select Role"
        onChange={handleSelectChange}
        size="small"
        sx={{
          "&:before": {
            borderBottomColor: "primary.main",
          },
          ".MuiSvgIcon-root.MuiSelect-icon": {
            color: "primary.main",
          },
          ".MuiButtonBase-root.MuiChip-root": {
            backgroundColor: "primary.light",
          },
        }}
        renderValue={(selected) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 0.5,
            }}
          >
            {getData()
              ?.filter((item: any) => selected?.includes(item?.value))
              ?.map((value: any) => (
                <Chip
                  size="small"
                  sx={{ height: "1.5rem" }}
                  key={value.value}
                  label={value.value}
                  onDelete={() => {
                    if (roles?.length > 1) {
                      setRoles((prev: any) =>
                        prev?.filter((item: any) => value.value !== item)
                      );
                      API.put(
                        `/cost/scenario_share/${data.id}/`,
                        {
                          action: roles?.filter(
                            (item: any) => value.value !== item
                          ),
                        },
                        {},
                        0
                      )
                        .then((res: any) => {})
                        .catch((err: any) => {});
                    }
                  }}
                  deleteIcon={
                    <CancelIcon
                      onMouseDown={(event) => event.stopPropagation()}
                    />
                  }
                />
              ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {getData() &&
          getData()?.map((item: any, index: number) => {
            return (
              !roles?.includes(item.value) && (
                <MenuItem key={index} value={item?.value}>
                  {item.text}
                </MenuItem>
              )
            );
          })}
      </Select>
    </FormControl>
  );
}
