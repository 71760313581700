import React, { useEffect, useState } from 'react';
// import { Header } from '../Header/Header';
import InternalProjectTitleSection from '../InternalLanding/InternalProjectTitleSection';
import styles from './InternalLanding.module.scss';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Header } from '../Header/Header';
import { Box, Switch } from '@mui/material';
import { API } from '../../api-services';
import Sliderhomepage from './Sliderhomepage';
import InterLandingTitleSectionNew from './InternalLandingTitleSectionNew';

interface InternalLandingMainPageProps {}
const InternalLandingMainPage: React.FC<InternalLandingMainPageProps> = () => {
	// Wallpaper
	// const [wallpaperData, setWallpaperData] = useState<any>();
	// const [wallpaperLoader, setWallpaperLoader] = useState<boolean>();

	// const [checked, setChecked] = React.useState(true);
	// const { url } = useRouteMatch();
	sessionStorage.removeItem('ProjectId');
	sessionStorage.removeItem('ActiveIndex');
	sessionStorage.removeItem('SearchKey');
	sessionStorage.removeItem('projectKey');
	sessionStorage.removeItem('ProjectIds');
	sessionStorage.removeItem('ExactSearchKey');
	sessionStorage.removeItem('VoultId');
	// @ts-ignore
	sessionStorage.setItem('FreeseStatus', false);
	const history = useHistory();
	{
		sessionStorage.removeItem('recentKey');
	}

	// useEffect(()=>{
	//   url.includes("/internallanding") && setChecked(true)
	// },[url])
	// const switchpage = (event: React.ChangeEvent<HTMLInputElement>) => {event.target.checked==false && history.push("/landing")};

	// useEffect(() => {
	//   setWallpaperLoader(false);
	//   API.get("customer/profile/", { landing_page_wallpaper: true },
	//     0).then((res: any) => {
	//       setWallpaperLoader(true);
	//       setWallpaperData(res?.data);
	//     });
	// }, []);

	console.log('InternalLandingMainPage');
	

	return (
		<Box className={styles.projectMainDiv}>
			<Box>
				{/* sx={{ backgroundImage: `url(${wallpaperData?.selected_wallpaper})`, backgroundSize: "cover", minHeight: "100%", minWidth: { xs: '364px', sm: "1024px" } }} */}
				<Header />
				<Box
					className={styles.landingPage}
					adl-scrollbar='true'
					adl-scrollbar-width='0.6'>
					<InterLandingTitleSectionNew />
				</Box>
				{/* <Box
					adl-scrollbar='true'
					adl-scrollbar-width='0.6'>
            		<Sliderhomepage />
				</Box> */}
			</Box>
		</Box>
	);
};

export default InternalLandingMainPage;
