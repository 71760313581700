import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, Skeleton} from "@mui/material";
import moment from "moment";
import { API } from "../../../api-services";
import EqualizerIcon from '@mui/icons-material/Equalizer';
import { useGraphBaseModal } from "../useGraphBaseModal";
import GraphShowModal from "../GraphShowModal";

interface TimeSpentI { }

const TimeSpent: React.FC<TimeSpentI> = (props) => {
  const [dataGet, setDataGet] = useState<any>();
  const [dataLoad, setDataLoad] = useState<boolean>(true);
  const graphBaseModal=useGraphBaseModal();

  useEffect(() => {
    setDataLoad(true);
    API.get(
      `/cost/activity_log_session/`, {}, 0)
      .then((res: any) => {
        setDataLoad(false);
        setDataGet(res?.data);
      })
  }, [])

const TimePeriod=(period:any,type:any)=>{
  graphBaseModal.open(period,type);
}

  return (
    <>
      {!dataLoad ? <>
        <GraphShowModal Open={graphBaseModal.isOpen} Close={graphBaseModal.close} id={graphBaseModal.id} analysisType={graphBaseModal.analysisType}/>
        {dataGet &&
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{cursor:'pointer', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', textAlign: 'left', lineHeight: '1.2', width: '14rem', backgroundColor: 'primary.light'  }} rowSpan={2}>Time Spent</TableCell>
                <TableCell sx={{cursor:'pointer', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', backgroundColor: 'primary.light' }} title={`${moment().format('DD-MMM')}`}>Today <EqualizerIcon titleAccess='Graph' style={{ cursor: "pointer",marginBottom:'-0.3rem' }} onClick={()=>{return TimePeriod('1','timeAnalysis')}}/></TableCell>
                <TableCell sx={{cursor:'pointer', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', backgroundColor: 'primary.light' }} title={`${moment().format('DD-MMM')} - ${moment().subtract(7, 'd').format('DD-MMM')}`}>7D <EqualizerIcon titleAccess='Graph' style={{ cursor: "pointer",marginBottom:'-0.3rem' }} onClick={()=>{return TimePeriod('7','timeAnalysis')}}/></TableCell>
                <TableCell sx={{cursor:'pointer', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', backgroundColor: 'primary.light' }} title={`${moment().format('DD-MMM')} - ${moment().subtract(30, 'd').format('DD-MMM')}`}>30D <EqualizerIcon titleAccess='Graph' style={{ cursor: "pointer",marginBottom:'-0.3rem' }} onClick={()=>{return TimePeriod('30','timeAnalysis')}}/></TableCell>
                <TableCell sx={{cursor:'pointer', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', backgroundColor: 'primary.light' }}  title={`${moment().format('DD-MMM')} - ${moment().subtract(365, 'd').format('DD-MMM')}`}>365D<EqualizerIcon titleAccess='Graph' style={{ cursor: "pointer",marginBottom:'-0.3rem' }} onClick={()=>{return TimePeriod('365','timeAnalysis')}}/></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem',width: '14rem', }}>No. of Sessions</TableCell>
                <TableCell sx={{ fontWeight: 'bold', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', }}>{(dataGet?.session_count?.All["1day"])?.toLocaleString("en-IN")}</TableCell>
                <TableCell sx={{ fontWeight: 'bold', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', }}>{(dataGet?.session_count?.All["7day"])?.toLocaleString("en-IN")}</TableCell>
                <TableCell sx={{ fontWeight: 'bold', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', }}>{(dataGet?.session_count?.All["30day"])?.toLocaleString("en-IN")}</TableCell>
                <TableCell sx={{ fontWeight: 'bold', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', }}>{(dataGet?.session_count?.All["365day"])?.toLocaleString("en-IN")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem 0.2rem 2rem', fontSize: '1rem',width: '14rem', }}>External</TableCell>
                <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', }}>{(dataGet?.session_count?.External["1day"])?.toLocaleString("en-IN")}</TableCell>
                <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', }}>{(dataGet?.session_count?.External["7day"])?.toLocaleString("en-IN")}</TableCell>
                <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', }}>{(dataGet?.session_count?.External["30day"])?.toLocaleString("en-IN")}</TableCell>
                <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', }}>{(dataGet?.session_count?.External["365day"])?.toLocaleString("en-IN")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem 0.2rem 2rem', fontSize: '1rem',width: '14rem', }}>Internal</TableCell>
                <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', }}>{(dataGet?.session_count?.Internal["1day"])?.toLocaleString("en-IN")}</TableCell>
                <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', }}>{(dataGet?.session_count?.Internal["7day"])?.toLocaleString("en-IN")}</TableCell>
                <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', }}>{(dataGet?.session_count?.Internal["30day"])?.toLocaleString("en-IN")}</TableCell>
                <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', }}>{(dataGet?.session_count?.Internal["365day"])?.toLocaleString("en-IN")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontWeight: 'bold', fontSize: '1rem',width: '14rem', }}>Total Time Spent</TableCell>
                <TableCell sx={{ fontWeight: 'bold', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', }}>{dataGet?.total_time?.All["1day"]}</TableCell>
                <TableCell sx={{ fontWeight: 'bold', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', }}>{dataGet?.total_time?.All["7day"]}</TableCell>
                <TableCell sx={{ fontWeight: 'bold', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', }}>{dataGet?.total_time?.All["30day"]}</TableCell>
                <TableCell sx={{ fontWeight: 'bold', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', }}>{dataGet?.total_time?.All["365day"]}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem 0.2rem 2rem', fontSize: '1rem',width: '14rem', }}>External(mins)</TableCell>
                <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', }}>{dataGet?.total_time?.External["1day"]}</TableCell>
                <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', }}>{dataGet?.total_time?.External["7day"]}</TableCell>
                <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', }}>{dataGet?.total_time?.External["30day"]}</TableCell>
                <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', }}>{dataGet?.total_time?.External["365day"]}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem 0.2rem 2rem', fontSize: '1rem',width: '14rem', }}>Internal(mins)</TableCell>
                <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', }}>{dataGet?.total_time?.Internal["1day"]}</TableCell>
                <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', }}>{dataGet?.total_time?.Internal["7day"]}</TableCell>
                <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', }}>{dataGet?.total_time?.Internal["30day"]}</TableCell>
                <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', fontSize: '1rem', }}>{dataGet?.total_time?.Internal["365day"]}</TableCell>
              </TableRow>
            </TableBody>
          </Table>} </>
        :
        <Skeleton variant="rectangular" height='25vh' />
      }
    </>
  )
}

export default TimeSpent;