import React, { useContext, useEffect, useState } from 'react';
import {
	CellNameFive,
	CellNameOne,
	CellNameSecond,
	CellNameThird,
} from './BomTemplatedHeader';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import {
	Autocomplete,
	Box,
	Chip,
	IconButton,
	MenuItem,
	Select,
	SelectChangeEvent,
	TableCell,
	TableRow,
	TextField,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { API } from '../../api-services';
import { debounce, set } from 'lodash';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';
import TableChartIcon from '@mui/icons-material/TableChart';
import { UserContext } from './BomConfigurationLanding';
import { useBaseModal } from '../../ComponentUI/SearchPage/useBaseModal';
import { useBaseParamsModal } from '../../CustomHook/useBaseParamsModal';
import { BOMConfigCalculator } from '../../DataBaseModule/ParameterLibrary/BOMCalculatorModal';
import SettingsIcon from '@mui/icons-material/Settings';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { ADMIN } from '../../Redux/Services/admin.service';
import { AUTH } from '../../Redux/Services/auth.service';
interface BomConfigurationsRowrenderProps {
	rowItem: any;
	keys: any;
	setCounter: any;
	setUpdatedCounter: any;
	workstationList: any;
	handleChangeCheckBox: any;
	tablecallBackhandler: any;
	InfoModalhandler: any;
	validationdata?: any;
}

const BomConfigurationsRowrender = (props: BomConfigurationsRowrenderProps) => {
	const {
		rowItem,
		keys,
		setCounter,
		workstationList,
		handleChangeCheckBox,
		setUpdatedCounter,
		tablecallBackhandler,
		InfoModalhandler,
		validationdata,
	} = props;
	const history = useHistory<any>();
	const {
		projectId,
		projectname,
		categoryId,
		productId,
		datatype,
		validationId,
	} = useRouteParams<any>();

	const [tagData, settagData] = useState<any>(rowItem?.tag);
	const userpremission = useContext(UserContext);
	const [sequence, setSequence] = useState<any>(rowItem?.sequence);
	const [requiredValue, setrequiredValue] = useState<any>(rowItem?.required);
	const [WorkStations, setWorkStations] = useState<any>([]);
	const [database, setDataBase] = useState<any>({});
	const [workstationData, setworkstationData] = useState<any>([]);
	const [inputData, setInputData] = useState<any>([]);
	const calculatorModal = useBaseParamsModal();
	const DataBaseName = [{ text: 'SupplierDatabase', name: 'Supplier DB' }];
	useEffect(() => {
		var filterdIds = workstationList?.filter((item: any, index: any) => {
			return rowItem?.workstation?.includes(item?.id);
		});

		setworkstationData(workstationList && workstationList);
		setWorkStations(filterdIds);
		var data = DataBaseName?.filter((item: any, index: any) => {
			return item?.text == rowItem?.database;
		});

		setDataBase(data[0]);
	}, [rowItem]);

	const parameterDatabaseRidirection = () => {
		window.open(
			`/#/data-base/Parameter-library/${rowItem?.data_type}/0`,
			'__blank'
		);
	};

	const lockparameterHandler = (lockstatus: any) => {
		if (lockstatus == false) {
			API.put(`/bom/parameter_mapping/${rowItem?.id}/`, {
				lock: true,
			}).then((res: any) => {
				setCounter((prev: any) => prev + 1);
			});
		} else {
			API.put(`/bom/parameter_mapping/${rowItem?.id}/`, {
				lock: false,
			}).then((res: any) => {
				setCounter((prev: any) => prev + 1);
			});
		}
	};

	const CalculatorHandler = (id: any) => {
		calculatorModal.open(id);
	};

	var optionData = workstationData && workstationData;
	const ExactDebounce = React.useRef(
		debounce(
			async (
				criteria: any,
				Id: any,
				type: any,
				topVault: any,
				validationsIds: any
			) => {
				if (type == 'Tag') {
					await API.put(`/bom/parameter_mapping/${Id}/`, {
						tag: criteria,
						// top_vault:
						//   validationsIds == 0
						//     ? topVault.toString()
						//     : validationsIds?.toString(),
					}).then((res: any) => {
						settagData(res.data?.tag);
						handleChangeCheckBox();
						// setCounter((prev:any)=>prev+1)
					});
				} else if (type == 'sequence') {
					if (criteria !== null) {
						await API.put(`/bom/parameter_mapping/${Id}/`, {
							sequence: parseInt(criteria),
							// top_vault:
							//   validationsIds == 0
							//     ? topVault?.toString()
							//     : validationsIds?.toString(),
						}).then((res: any) => {
							//  setSequence(res.data?.sequence );
							setUpdatedCounter((prev: any) => prev + 1);
							handleChangeCheckBox();
						});
					}
				} else if (type == 'required') {
					await API.put(`/bom/parameter_mapping/${Id}/`, {
						required: criteria,
					}).then((res: any) => {
						setrequiredValue(res.data?.required);
						handleChangeCheckBox();
						// setCounter((prev:any)=>prev+1)
					});
				} else if (type == 'workstations') {
					await API.put(`/bom/parameter_mapping/${Id}/`, {
						workstation: criteria,
						top_vault:
							validationsIds == 0
								? topVault?.toString()
								: validationsIds?.toString(),
					}).then((res: any) => {
						var filterdIds = workstationList?.filter(
							(item: any, index: any) => {
								return res.data?.workstation?.includes(item?.id);
							}
						);

						setworkstationData(workstationList && workstationList);
						setWorkStations(filterdIds);
					});
				}
				if (type == 'dataBase') {
					await API.put(`/bom/parameter_mapping/${Id}/`, {
						database: criteria == undefined ? null : criteria,
						// top_vault:
						//   validationsIds == 0
						//     ? topVault?.toString()
						//     : validationsIds?.toString(),
					}).then((res: any) => {
						var data = DataBaseName?.filter((item: any, index: any) => {
							return item?.text == res?.data?.database;
						});

						setDataBase(data[0]);
					});
				}
			},
			700
		)
	).current;

	const handleChange = (e: any, type: any) => {
		if (type == 'Tag') {
			settagData(e.target.value);
			ExactDebounce(
				e.target.value,
				rowItem?.id,
				'Tag',
				productId,
				validationId
			);
			// setCounter((prev:any)=>prev+1)
		} else if (type == 'sequence') {
			setSequence(e.target.value);
			if (e.target.value !== null || e?.target?.value?.length !== 0) {
				ExactDebounce(
					e.target.value,
					rowItem?.id,
					'sequence',
					productId,
					validationId
				);
			}
		}

		if (type == 'required') {
			setrequiredValue(e.target.value);
			ExactDebounce(
				e.target.value,
				rowItem?.id,
				'required',
				productId,
				validationId
			);
			// setCounter((prev:any)=>prev+1)
		}
	};

	const handleSelectChange = (item: any) => {
		setWorkStations(item);
		var ids = item?.map((item: any, index: any) => {
			return item?.id;
		});
		var filterdata = workstationData?.filter((items: any, index: any) => {
			return !ids.includes(items?.id);
		});

		ExactDebounce(ids, rowItem?.id, 'workstations', productId, validationId);
		setworkstationData(filterdata);
	};

	const handledatabseSelectChange = (item: any) => {
		setDataBase(item);
		ExactDebounce(item?.text, rowItem?.id, 'dataBase', productId, validationId);
	};

	const DeleteHandler = () => {
		swal({
			text: `Are you sure you want to delete ?`,
			icon: 'error',
			buttons: ['Cancel', 'Yes, Delete'],
		}).then((confirm) => {
			if (confirm) {
				API.delete(
					`/bom/parameter_mapping/${rowItem?.id}/`,
					{
						// vehicle_category :
						// 	validationId == 0
						// 		? productId?.toString()
						// 		: validationId?.toString(),
					},
					0
				)
					.then((res: any) => {
						setCounter((prev: any) => prev + 1);
						handleChangeCheckBox();
					})
					.catch((err: any) => {});
			}
		});
	};

	const TableHandler = (item: any) => {
		tablecallBackhandler(item);
	};
	const Infohandler = (item: any) => {
		InfoModalhandler(item);
	};

	return (
		<>
			{CellNameOne.includes(datatype) ? (
				<TableRow>
					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
						}}>
						<TextField
							// disabled={
							//   userpremission && userpremission?.includes("U") ? false : true
							// }
							disabled={rowItem?.lock}
							value={sequence}
							type='number'
							variant='standard'
							inputProps={{
								step: 'any',
								min: 1,
								// style: { fontSize: '1rem', marginTop: '0.4rem' }
							}}
							onChange={(e: any) => handleChange(e, 'sequence')}
							placeholder='sequence'
							sx={{
								'.MuiInputBase-root.MuiInput-root': {
									fontSize: '1rem !important',
								},
								'& .MuiInput-underline:before': {
									borderBottomColor: 'primary.light',
								},
								'& .MuiInput-underline:after': {
									borderBottomColor: 'primary.main',
								},
							}}
						/>
					</TableCell>
					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
						}}>
						{rowItem?.parameter?.name}
					</TableCell>
					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
						}}>
						{rowItem?.parameter?.description}
					</TableCell>
					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
						}}>
						{rowItem?.parameter?.unit}
					</TableCell>
					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
						}}>
						{rowItem?.parameter?.exceptions}
					</TableCell>
					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
						}}>
						{rowItem?.parameter?.allowed_values?.length == 0
							? '_'
							: rowItem?.parameter?.allowed_values.toString()}
					</TableCell>
					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
						}}>
						{' '}
						{rowItem?.parameter?.not_allowed_values?.length == 0
							? '_'
							: rowItem?.parameter?.not_allowed_values.toString()}
					</TableCell>
					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
						}}>
						{rowItem?.parameter?.default_value}
						{rowItem?.parameter?.default_value == ''
							? '_'
							: rowItem?.parameter?.default_value}
					</TableCell>
					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
						}}
						onClick={() =>
							rowItem?.info_doc != null &&
							window.open(rowItem?.info_doc, '_blank')
						}>
						{rowItem?.info_doc == null ? (
							'-'
						) : (
							<VisibilityOutlinedIcon sx={{ cursor: 'pointer' }} />
						)}
					</TableCell>
					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
						}}>
						<Select
							// disabled={
							//   userpremission && userpremission?.includes("U") ? false : true
							// }
							labelId='demo-simple-select-standard-label'
							id='demo-simple-select-standard'
							value={requiredValue}
							disabled={rowItem?.lock}
							variant='standard'
							sx={{
								'&:before': {
									borderBottomColor: 'primary.light',
								},
								'& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select':
									{
										fontSize: '1rem',
									},
								'.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
							}}
							onChange={(e: any) => handleChange(e, 'required')}
							label='required'>
							{/* @ts-ignore */}
							<MenuItem sx={{ fontSize: '1rem' }} value={true}>
								Yes
							</MenuItem>
							{/* @ts-ignore */}
							<MenuItem sx={{ fontSize: '1rem' }} value={false}>
								No
							</MenuItem>
						</Select>
					</TableCell>
					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
							textAlign: 'center',
						}}>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								columnGap: '0.5rem',
								justifyContent: 'center',
							}}>
							{rowItem?.map_status ? (
								<InfoIcon
									titleAccess='Info'
									onClick={() => Infohandler(rowItem)}
									sx={{ cursor: 'pointer' }}
								/>
							) : (
								<>
									{
										// userpremission && userpremission?.includes("D")

										// ?

										<DeleteIcon
											sx={{ cursor: 'pointer', opacity: rowItem.lock===true?"0.5":"1" }}
											titleAccess='Delete'
											onClick={() => {
												if(rowItem?.lock != true){
												DeleteHandler()
												}
											}}
										/>

										// :
										// <DeleteIcon
										//   sx={{ opacity: 0.3 }}
										//   titleAccess="Delete"
										// />
									}
								</>
								// <IconButton
								//   disabled={
								//     userpremission && userpremission?.includes("D") ? false : true
								//   }>
								//   <DeleteIcon
								//     sx={{ cursor: "pointer" }}
								//     titleAccess="Delete"
								//     onClick={() => DeleteHandler()}
								//   />
								// </IconButton>
							)}
							<LaunchOutlinedIcon
								sx={{ cursor: 'pointer' }}
								titleAccess='Go to Parameter-DataBase'
								onClick={() => parameterDatabaseRidirection()}
							/>
							{(AUTH.isSuperUser && rowItem?.lock && validationdata ) ? (
								<LockIcon
									sx={{ cursor: 'pointer',color:'green' }}
									titleAccess='Unlock Parameter'
									onClick={() => lockparameterHandler(rowItem?.lock)}
								/>
							) : (
								(AUTH.isSuperUser && validationdata ) && (
									<LockOpenIcon
										sx={{ cursor: 'pointer',color:'red' }}
										titleAccess='Unlock Parameter'
										onClick={() => lockparameterHandler(rowItem?.lock)}
									/>
								)
							)}
						</Box>
					</TableCell>
				</TableRow>
			) : CellNameSecond.includes(datatype) ? (
				<TableRow>
					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
						}}>
						<TextField
							variant='standard'
							value={sequence}
							type='number'
							inputProps={{
								step: 'any',
								min: 1,
								// style: { fontSize: '1rem', marginTop: '0.4rem' }
							}}
							disabled={rowItem?.lock}
							onChange={(e: any) => handleChange(e, 'sequence')}
							placeholder='sequence'
							sx={{
								'.MuiInputBase-root.MuiInput-root': {
									fontSize: '1rem !important',
								},
								'& .MuiInput-underline:before': {
									borderBottomColor: 'primary.light',
								},
								'& .MuiInput-underline:after': {
									borderBottomColor: 'primary.main',
								},
							}}
						/>
					</TableCell>
					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
						}}>
						{rowItem?.parameter?.name}
					</TableCell>

					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
						}}>
						{rowItem?.parameter?.description}
					</TableCell>

					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
						}}
						onClick={() =>
							rowItem?.info_doc != null &&
							window.open(rowItem?.info_doc, '_blank')
						}>
						{rowItem?.info_doc == null ? (
							'-'
						) : (
							<VisibilityOutlinedIcon sx={{ cursor: 'pointer' }} />
						)}
					</TableCell>
					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
						}}>
						<Select
							// disabled={
							//   userpremission && userpremission?.includes("U") ? false : true
							// }
							labelId='demo-simple-select-standard-label'
							id='demo-simple-select-standard'
							value={requiredValue}
							disabled={rowItem?.lock}
							variant='standard'
							sx={{
								'&:before': {
									borderBottomColor: 'primary.light',
								},
								'& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select':
									{
										fontSize: '1rem',
									},
								'.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
							}}
							onChange={(e: any) => handleChange(e, 'required')}
							label='required'>
							{/* @ts-ignore */}
							<MenuItem sx={{ fontSize: '1rem' }} value={true}>
								Yes
							</MenuItem>
							{/* @ts-ignore */}
							<MenuItem sx={{ fontSize: '1rem' }} value={false}>
								No
							</MenuItem>
						</Select>
					</TableCell>

					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
							textAlign: 'center',
						}}>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								columnGap: '0.5rem',
								justifyContent: 'center',
							}}>
							{rowItem?.map_status ? (
								<InfoIcon
									titleAccess='Info'
									onClick={() => Infohandler(rowItem)}
									sx={{ cursor: 'pointer' }}
								/>
							) : (
								<>
									{/* {userpremission && userpremission?.includes("D") ? */}

									<DeleteIcon
											sx={{ cursor: 'pointer', opacity: rowItem.lock===true?"0.5":"1" }}
											titleAccess='Delete'
											onClick={() => {
												if(rowItem?.lock != true){
												DeleteHandler()
												}
											}}
										/>
									{/* :
                  <DeleteIcon
                    sx={{ opacity: 0.3 }}
                    titleAccess="Delete"
                  />
                } */}
								</>
							)}
							<LaunchOutlinedIcon
								sx={{ cursor: 'pointer' }}
								titleAccess='Go to Parameter-DataBase'
								onClick={() => parameterDatabaseRidirection()}
							/>
							{(AUTH.isSuperUser && rowItem?.lock && validationdata) ? (
								<LockIcon
									sx={{ cursor: 'pointer',color:'green' }}
									titleAccess='Unlock Parameter'
									onClick={() => lockparameterHandler(rowItem?.lock)}
								/>
							) : (
								(AUTH.isSuperUser && validationdata) && (
									<LockOpenIcon
										sx={{ cursor: 'pointer',color:'red' }}
										titleAccess='Unlock Parameter'
										onClick={() => lockparameterHandler(rowItem?.lock)}
									/>
								)
							)}
							{datatype == 'table' && (
								<>
									{/*  <IconButton
                   disabled={
                     false : true
                   }> */}
									{/* {userpremission && userpremission?.includes("U") ? */}

									<TableChartIcon
										onClick={() => TableHandler(rowItem)}
										sx={{ cursor: 'pointer', opacity: '1' }}
									/>

									{/* : <TableChartIcon sx={{ opacity: '0.3' }} />} */}
									{/* </IconButton> */}
								</>
							)}
						</Box>
					</TableCell>
				</TableRow>
			) : CellNameThird.includes(datatype) ? (
				<TableRow>
					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
						}}>
						<TextField
							// disabled={
							//   userpremission && userpremission?.includes("U") ? false : true
							// }
							variant='standard'
							value={sequence}
							disabled={rowItem?.lock}
							onChange={(e: any) => handleChange(e, 'sequence')}
							type='number'
							inputProps={{
								step: 'any',
								min: 1,
								// style: { fontSize: '1rem', marginTop: '0.4rem' }
							}}
							placeholder='sequence'
							sx={{
								'.MuiInputBase-root.MuiInput-root': {
									fontSize: '1rem !important',
								},
								'& .MuiInput-underline:before': {
									borderBottomColor: 'primary.light',
								},
								'& .MuiInput-underline:after': {
									borderBottomColor: 'primary.main',
								},
							}}
						/>
					</TableCell>
					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
						}}>
						{rowItem?.parameter?.name}
					</TableCell>

					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
						}}>
						{rowItem?.parameter?.description}
					</TableCell>
					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
						}}>
						{rowItem?.parameter?.unit}
					</TableCell>
					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
						}}>
						{rowItem?.parameter?.exceptions}
					</TableCell>
					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
						}}>
						{rowItem?.parameter?.allowed_values?.length == 0
							? '_'
							: rowItem?.parameter?.allowed_values.toString()}
					</TableCell>

					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
						}}
						onClick={() =>
							rowItem?.info_doc != null &&
							window.open(rowItem?.info_doc, '_blank')
						}>
						{rowItem?.info_doc == null ? (
							'-'
						) : (
							<VisibilityOutlinedIcon sx={{ cursor: 'pointer' }} />
						)}
					</TableCell>

					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
						}}>
						<Select
							// disabled={
							//   userpremission && userpremission?.includes("U") ? false : true
							// }
							labelId='demo-simple-select-standard-label'
							id='demo-simple-select-standard'
							disabled={rowItem?.lock}
							value={requiredValue}
							variant='standard'
							sx={{
								'&:before': {
									borderBottomColor: 'primary.light',
								},
								'& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select':
									{
										fontSize: '1rem',
									},
								'.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
							}}
							onChange={(e: any) => handleChange(e, 'required')}
							label='required'>
							{/* @ts-ignore */}
							<MenuItem sx={{ fontSize: '1rem' }} value={true}>
								Yes
							</MenuItem>
							{/* @ts-ignore */}
							<MenuItem sx={{ fontSize: '1rem' }} value={false}>
								No
							</MenuItem>
						</Select>
					</TableCell>

					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
							textAlign: 'center',
						}}>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								columnGap: '0.5rem',
								justifyContent: 'center',
							}}>
							{rowItem?.map_status ? (
								<InfoIcon
									titleAccess='Info'
									onClick={() => Infohandler(rowItem)}
									sx={{ cursor: 'pointer' }}
								/>
							) : (
								<>
									{/* {userpremission && userpremission?.includes("D") ? */}
									<DeleteIcon
											sx={{ cursor: 'pointer', opacity: rowItem.lock===true?"0.5":"1" }}
											titleAccess='Delete'
											onClick={() => {
												if(rowItem?.lock != true){
												DeleteHandler()
												}
											}}
										/>
									{/* <DeleteIcon
                  sx={{ opacity: 0.3 }}
                  titleAccess="Delete"
                />
              }              */}
								</>
							)}
							<LaunchOutlinedIcon
								sx={{ cursor: 'pointer' }}
								titleAccess='Go to Parameter-DataBase'
								onClick={() => parameterDatabaseRidirection()}
							/>
							{(AUTH.isSuperUser && rowItem?.lock && validationdata) ? (
								<LockIcon
									sx={{ cursor: 'pointer',color:'green' }}
									titleAccess='Unlock Parameter'
									onClick={() => lockparameterHandler(rowItem?.lock)}
								/>
							) : (
								(AUTH.isSuperUser && validationdata) && (
									<LockOpenIcon
										sx={{ cursor: 'pointer',color:'red' }}
										titleAccess='Unlock Parameter'
										onClick={() => lockparameterHandler(rowItem?.lock)}
									/>
								)
							)}
						</Box>
					</TableCell>
				</TableRow>
			) : CellNameFive.includes(datatype) ? (
				<TableRow>
					{/* <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', }}>{keys + 1}</TableCell> */}
					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
						}}>
						<TextField
							// disabled={
							//   userpremission && userpremission?.includes("U") ? false : true
							// }
							value={sequence}
							disabled={rowItem?.lock}
							variant='standard'
							type='number'
							inputProps={{
								step: 'any',
								min: 1,
								// style: { fontSize: '1rem', marginTop: '0.4rem' }
							}}
							onChange={(e: any) => handleChange(e, 'sequence')}
							placeholder='sequence'
							sx={{
								'.MuiInputBase-root.MuiInput-root': {
									fontSize: '1rem !important',
								},
								'& .MuiInput-underline:before': {
									borderBottomColor: 'primary.light',
								},
								'& .MuiInput-underline:after': {
									borderBottomColor: 'primary.main',
								},
							}}
						/>
					</TableCell>
					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
						}}>
						{rowItem?.parameter?.name}
					</TableCell>

					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
						}}>
						{rowItem?.parameter?.description}
					</TableCell>

					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
						}}
						onClick={() =>
							rowItem?.info_doc != null &&
							window.open(rowItem?.info_doc, '_blank')
						}>
						{rowItem?.info_doc == null ? (
							'-'
						) : (
							<VisibilityOutlinedIcon sx={{ cursor: 'pointer' }} />
						)}
					</TableCell>

					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
						}}>
						<Select
							// disabled={
							//   userpremission && userpremission?.includes("U") ? false : true
							// }
							labelId='demo-simple-select-standard-label'
							id='demo-simple-select-standard'
							value={requiredValue}
							disabled={rowItem?.lock}
							variant='standard'
							sx={{
								'&:before': {
									borderBottomColor: 'primary.light',
								},
								'& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select':
									{
										fontSize: '1rem',
									},
								'.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
							}}
							onChange={(e: any) => handleChange(e, 'required')}
							label='required'>
							{/* @ts-ignore */}
							<MenuItem sx={{ fontSize: '1rem' }} value={true}>
								Yes
							</MenuItem>
							{/* @ts-ignore */}
							<MenuItem sx={{ fontSize: '1rem' }} value={false}>
								No
							</MenuItem>
						</Select>
					</TableCell>

					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
							textAlign: 'center',
						}}>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								columnGap: '0.5rem',
								justifyContent: 'center',
							}}>
							{rowItem?.map_status ? (
								<InfoIcon
									titleAccess='Info'
									onClick={() => Infohandler(rowItem)}
									sx={{ cursor: 'pointer' }}
								/>
							) : (
								<>
									{/* {userpremission && userpremission?.includes("D") ? */}
									<DeleteIcon
											sx={{ cursor: 'pointer', opacity: rowItem.lock===true?"0.5":"1" }}
											titleAccess='Delete'
											onClick={() => {
												if(rowItem?.lock != true){
												DeleteHandler()
												}
											}}
										/>
									
									{/* <DeleteIcon
                  sx={{ opacity: 0.3 }}
                  titleAccess="Delete"
                />
              } */}
								</>
							)}
							<LaunchOutlinedIcon
								sx={{ cursor: 'pointer' }}
								titleAccess='Go to Parameter-DataBase'
								onClick={() => parameterDatabaseRidirection()}
							/>
							{(AUTH.isSuperUser && rowItem?.lock && validationdata) ? (
								<LockIcon
									sx={{ cursor: 'pointer',color:'green' }}
									titleAccess='Unlock Parameter'
									onClick={() => lockparameterHandler(rowItem?.lock)}
								/>
							) : (
								(AUTH.isSuperUser && validationdata) && (
									<LockOpenIcon
										sx={{ cursor: 'pointer',color:'red' }}
										titleAccess='Unlock Parameter'
										onClick={() => lockparameterHandler(rowItem?.lock)}
									/>
								)
							)}
							<SettingsIcon
								sx={{ cursor: 'pointer',opacity: rowItem.lock===true?"0.5":"1"}}
								titleAccess='BOM Calculator Configuration'
								onClick={() => {
									if(rowItem?.lock != true){
									CalculatorHandler(rowItem?.parameter?.id)
									}
								}}
							/>
						</Box>
					</TableCell>
				</TableRow>
			) : (
				<TableRow>
					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
						}}>
						<TextField
							// disabled={
							//   userpremission && userpremission?.includes("U") ? false : true
							// }
							value={sequence}
							variant='standard'
							type='number'
							disabled={rowItem?.lock}
							inputProps={{
								step: 'any',
								min: 1,
								// style: { fontSize: '1rem', marginTop: '0.4rem' }
							}}
							onChange={(e: any) => handleChange(e, 'sequence')}
							placeholder='sequence'
							sx={{
								'.MuiInputBase-root.MuiInput-root': {
									fontSize: '1rem !important',
								},
								'& .MuiInput-underline:before': {
									borderBottomColor: 'primary.light',
								},
								'& .MuiInput-underline:after': {
									borderBottomColor: 'primary.main',
								},
							}}
						/>
					</TableCell>
					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
						}}>
						{rowItem?.parameter?.name}
					</TableCell>

					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
						}}>
						{rowItem?.parameter?.description}
					</TableCell>

					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
						}}
						onClick={() =>
							rowItem?.info_doc != null &&
							window.open(rowItem?.info_doc, '_blank')
						}>
						{rowItem?.info_doc == null ? (
							'-'
						) : (
							<VisibilityOutlinedIcon sx={{ cursor: 'pointer' }} />
						)}
					</TableCell>

					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
						}}>
						<Select
							// disabled={
							//   userpremission && userpremission?.includes("U") ? false : true
							// }
							labelId='demo-simple-select-standard-label'
							id='demo-simple-select-standard'
							value={requiredValue}
							variant='standard'
							disabled={rowItem?.lock}
							sx={{
								'&:before': {
									borderBottomColor: 'primary.light',
								},
								'& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select':
									{
										fontSize: '1rem',
									},
								'.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
							}}
							onChange={(e: any) => handleChange(e, 'required')}
							label='required'>
							{/* @ts-ignore */}
							<MenuItem sx={{ fontSize: '1rem' }} value={true}>
								Yes
							</MenuItem>
							{/* @ts-ignore */}
							<MenuItem sx={{ fontSize: '1rem' }} value={false}>
								No
							</MenuItem>
						</Select>
					</TableCell>

					<TableCell
						sx={{
							padding: '0.2rem 0.5rem',
							borderBottomColor: 'primary.light',
							textAlign: 'center',
						}}>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								columnGap: '0.5rem',
								justifyContent: 'center',
							}}>
							{rowItem?.map_status ? (
								<InfoIcon
									titleAccess='Info'
									onClick={() => Infohandler(rowItem)}
									sx={{ cursor: 'pointer' }}
								/>
							) : (
								<>
									{/* {userpremission && userpremission?.includes("D") ? */}
									<DeleteIcon
											sx={{ cursor: 'pointer', opacity: rowItem.lock===true?"0.5":"1" }}
											titleAccess='Delete'
											onClick={() => {
												if(rowItem?.lock != true){
												DeleteHandler()
												}
											}}
										/>

									{/* :
                <DeleteIcon
                  sx={{ opacity: 0.3 }}
                  titleAccess="Delete"
                />
              } */}
								</>
							)}
							<LaunchOutlinedIcon
								sx={{ cursor: 'pointer' }}
								titleAccess='Go to Parameter-DataBase'
								onClick={() => parameterDatabaseRidirection()}
							/>
							{(AUTH.isSuperUser && rowItem?.lock && validationdata) ? (
								<LockIcon
									sx={{ cursor: 'pointer',color:'green' }}
									titleAccess='Unlock Parameter'
									onClick={() => lockparameterHandler(rowItem?.lock)}
								/>
							) : (
								(AUTH.isSuperUser && validationdata) && (
									<LockOpenIcon
										sx={{ cursor: 'pointer',color:'red' }}
										titleAccess='Unlock Parameter'
										onClick={() => lockparameterHandler(rowItem?.lock)}
									/>
								)
							)}
						</Box>
					</TableCell>
				</TableRow>
			)}

			{calculatorModal.isOpen && (
				<BOMConfigCalculator
					isOpen={calculatorModal.isOpen}
					onCloseModal={calculatorModal.close}
					id={calculatorModal.propsId}
					inputData={inputData}
				/>
			)}
		</>
	);
};

export default BomConfigurationsRowrender;
