import { Avatar, Box, Button, Card, CardContent, CardMedia, Typography } from '@mui/material';
import React, { useState } from 'react';
//@ts-ignore
import Slider from 'react-slick';

import "./CustomStyleReactSlick.module.scss"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { deepOrange } from '@mui/material/colors';
import CDN_URL from '../../utlis/CDN_URL';
import { array } from 'yup';

function SampleNextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <Box
      className={className}
      onClick={onClick}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent',
        border: '1.5px solid grey',
        borderRadius: '50%',
        width: '1.75rem',
        height: '1.75rem',
        position: 'absolute',
        top: '-40px',
        right: '10px',
        zIndex: 1,
        cursor: 'pointer',
        '&::before': {
          display: 'none',
        },
        '&:hover': {
          backgroundColor: '#cfd4d2',
        },
        '@media (max-width: 600px)': {
          display: 'none',
        },
      }}
    >
      <ArrowForwardIosIcon style={{ color: 'grey', fontSize: "0.85rem" }} />
    </Box>
  );
}

function SamplePrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <Box
      className={className}
      onClick={onClick}
      sx={{

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent',
        border: '1.5px solid grey',
        borderRadius: '50%',
        width: '1.75rem',
        height: '1.75rem',
        position: 'absolute',
        top: '-40px',
        left: '95%',
        right: '60px', // Adjust as needed to not overlap with next arrow
        zIndex: 1,
        cursor: 'pointer',
        '&::before': {
          display: 'none',
        },
        '&:hover': {
          backgroundColor: '#cfd4d2',
        },
        '@media (max-width: 600px)': {
          display: 'none',
        },
      }}
    >
      <ArrowBackIosIcon style={{ color: 'grey', fontSize: "0.85rem", marginLeft: "0.3rem" }} />
    </Box>
  );
}


const renderAvatar = (companyName?: string) => {
  if (!companyName) {
    return (
      <Avatar sx={{ bgcolor: "#629696", width: 100, height: 100, margin: "1.3rem 0" }}>
        <Typography variant="h4">?</Typography>
      </Avatar>
    );
  }

  const initials = companyName
    .split(' ')
    .map((word) => word[0])
    .join('')
    .toUpperCase();

  return (
    <Box sx={{}}>
      <Avatar sx={{ bgcolor: "#629696", width: 100, height: 100, margin: "1.3rem 0" }}>
        <Typography variant="h5">{initials}</Typography>
      </Avatar>
    </Box>

  );
};

function SupplierClients({ clients }: any) {
  const [showAll, setShowAll] = useState(false);
  const settings = {
    dots: true,
    speed: 1000,
    slidesToShow:
      //clients?.length,
      clients?.length < 4 ? clients?.length : 4,
    slidesToScroll: 1,
    // infinite: true,
    arrows: false,
    autoplay: false,
    nav: false,
    autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024, // For screens smaller than 1024px
        settings: {
          arrows: false,
          slidesToShow:
            clients?.length < 3 ? clients?.length : 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // For screens smaller than 768px
        settings: {
          arrows: false,
          slidesToShow:
            clients?.length < 2 ? clients?.length : 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // For screens smaller than 480px
        settings: {
          arrows: false,
          slidesToShow:
            clients?.length < 1 ? clients?.length : 1,
          slidesToScroll: 1,
        },
      },
    ],
    infinite: clients?.length >= 3
  };

  const visibleClients = showAll
  ? clients
  : clients?.slice(0, 3);

  const handleShowAllDirectorsClick = () => {
    setShowAll(!showAll);
  };

// console.log(clients);

  return (
    <Box sx={{ width:"100%",padding:"0.5rem"}}>
<Typography
            sx={{
            fontSize: "1.1rem",
            fontWeight: "600",
            marginBottom: "0.5rem",
            }}>
                Customers
            </Typography>
      {/* <Slider {...settings}> */}
      <Box sx={{display:"flex",flexWrap:"wrap" }}>

     
        {Array.isArray(visibleClients) && visibleClients?.map((client: any, index: any) => (

          <Box key={index} sx={{ height: "fit-content", padding: "0.5rem 1rem", width: "32%", border: "none", }}>
            <Card elevation={1} sx={{ marginTop: '2px', height: "13rem", width: "100%", borderRadius: "10px",  }}>
              {client.company_logo ? (
                <CardMedia
                  component="img"
                  height="90%"
                  image={`${CDN_URL}${client.company_logo}`}
                  alt={client.company_name}
                  sx={{ objectFit: 'contain' }}
                />
              ) : (
                <Box display="flex" justifyContent="center" alignItems="center" height="140" >
                 
                  {renderAvatar(client.company_name)}
                </Box>
              )}
              <CardContent>
                <Typography gutterBottom component="div" sx={{ fontSize: "1rem", fontWeight: "600", textAlign: "center" }}>
                  {client.company_name || ''}
                </Typography>
              
              </CardContent>
            </Card>
          </Box>


        ))}
        
         </Box>
         <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          {clients?.length > 3 && (
            <Box sx={{ textAlign: "center", mt: 3 }}>
              <Button variant="outlined" onClick={handleShowAllDirectorsClick}>
                {!showAll ? "Show All" : "Show Less"}
              </Button>
            </Box>
          )}
        </Box>
      {/* </Slider> */}
    </Box>
  );
}

export default SupplierClients;