import * as React from "react";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Menu,
  MenuProps,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import styles from "./CreateCostingPartLanding.module.scss";
import { CreateCostingPart } from "./CreateCostingPart";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import DescriptionIcon from "@mui/icons-material/Description";
import { CreateCostingDrawer } from "./CreateCostingDrawer";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import validated from "../../../Assets/images/Validated.svg";
import ToggleButton from "@mui/material/ToggleButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { API } from "../../../api-services";
import { AUTH } from "../../../Redux/Services/auth.service";
import DeleteIcon from "@mui/icons-material/Delete";
import arrowRight from "../../../Assets/images/rightArrowswhite.svg";
import scnarioprog from "../../../Assets/images/scenarioInProgress.svg";
import scnarioComp from "../../../Assets/images/scenarioDone.svg";
import Swal from "sweetalert2";
import swal from "sweetalert";
import { AxiosError, AxiosResponse } from "axios";
import { useDocumentTitle } from "../../../ui-reusable-component/useDocumentTitle";
import { useStage } from "spritespin";
import WariningModalFilesMail from "./WariningModalFilesMail";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import { useBaseParamsModal } from "../../../CustomHook/useBaseParamsModal";
import PublishAmountAndTimeCostingPage from "./PublishAmountAndTimeCostingPage";
import { set } from "lodash";
import CostingDataModal from "./CostingDataModal";

interface ICreateCostingPartLandingProps {}

interface Params {
  projectId: any;
  projectName: any;
  product_name: any;
  productId: any;
}

type Anchor = "top" | "left" | "bottom" | "right";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",

      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",

      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,

    border: "0.1px solid #007fff",

    minWidth: 150,

    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],

    "& .MuiMenu-list": {
      padding: "4px 0",
    },

    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,

        color: theme.palette.text.secondary,

        marginRight: theme.spacing(1.5),
      },

      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,

          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export function CreateCostingPartLanding(
  props: ICreateCostingPartLandingProps
) {
  const {
    projectId,
    projectName,
    productId,
    topVault,
    vault,
    abbrebation,
    scenarioId,
    cardtype,
    calcultorId,
    calctype,
    condition,
    tableCounter,
    costEstimationId,
    tableId,
    privateStatus,
    lockStatusCost
  } = useRouteParams<any>();
  useDocumentTitle("Zero-based Costing");
  const history = useHistory();
  const costModal = useBaseModal();
  const [selected, setSelected] = React.useState(false);
  const [validateCounter, setValidateCounter] = React.useState<any>(0);
  const [selectedScenario, setselectedScenario] = React.useState(false);
  const [selectedValidation, setselectedValidation] = React.useState(false);
  const [downloadLoader, setDownloadLoader] = React.useState<any>(false);
  const [hierarchyData, setHierarchyData] = React.useState<any>({});
  const [anchorElRedirect, setAnchorElRedirect] =
    React.useState<null | HTMLElement>(null);

  const openRedirect = Boolean(anchorElRedirect);

  const [redirectData, setRedirectData] = React.useState<any>([]);
  const [PermissionData, setPermissionData] = React.useState<any>();
  const [CalulatorList, setCalulatorList] = React.useState<any>();
  const [publishStatus, setPublishStatus] = React.useState<any>(false);
  const [mailStatus, setMailStatus] = React.useState<any>(false);
  const [isShowValidateScenario, setIsShowValidateScenario] =
    React.useState<any>(false);
  const ErrorModalFiles = useBaseModal();
  const [hierarchyDataLoading, setHierarchyDataLoading] =
    React.useState<any>(false);
  const [costData, setCostData] = React.useState<any>({});
  const [allProducts, setAllProducts] = React.useState<any>([]);
  const [ValidationLoader, setValidationLoader] = React.useState<any>(false);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [openBox, setOpenBox] = React.useState<any>(false);
  const getObj = (val: any) => {
    if (val === "dc") {
      return { dc_validation: true };
    } else {
      return { bop_validation: true };
    }
  };
  const [sendMail, setSendMail] = React.useState(false);
  const [status, setStatus] = React.useState("");
  const [costingStatus, setCostingStatus] = React.useState<any>("");
  const [count, setCount] = React.useState<any>(0);
  const [treeCount, setTreeCount] = React.useState<any>(1);

  const getAllTopVaults = () => {
    API.get(
      "/auth/costing_product_list/",
      {
        project: projectId,
        top_vault: topVault,
        costing_create: true,
      },
      0
    )
      .then((res: any) => {
        setAllProducts(res.data);
      })
      .catch((err: any) => {});
  };

  const getObjj = () => {
    if (vault == 0) {
      return { vehicle_category: projectId };
    } else {
      return { vault: vault };
    }
  };
  const getTableData = () => {
    API.get("/cost/scenario_table/", { ...getObjj() })
      .then((res: any) => {
        setRedirectData(res?.data);
      })
      .catch((err: any) => {
        console.log("Sever Error");
      });
  };

  const getScenarioVAlidationStatus = () => {
    API.get(
      "/cost/costing_view/",
      { ...getObjj(), level: "scenario_validation", scenario: scenarioId },
      0
    )
      .then((res: any) => {
        setIsShowValidateScenario(res.data?.scenario_validation);
      })
      .catch((err: any) => {});
  };

  React.useEffect(() => {
    if (scenarioId != 0) {
      getScenarioVAlidationStatus();
    }
  }, [condition]);

  React.useEffect(() => {
    getTableData();
  }, [vault, topVault, scenarioId]);

  const handleClickRedirect = async (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElRedirect(event.currentTarget);
  };

  const handleCloseRedirect = () => {
    setAnchorElRedirect(null);
  };

  React.useEffect(() => {
    getAllTopVaults();
  }, []);
  const handleChange = (event: SelectChangeEvent) => {
    API.put(
      `cost/update_estimation/${costData?.cost_estimation}/`,
      {
        ...getObj(event.target.value),
      },
      {},
      0
    )
      .then((res: any) => {
        setStatus(event.target.value);
        setCount((prev: any) => prev + 1);
      })
      .catch((err: any) => {
        const { data }: any = err?.response;
        Swal.fire({
          icon: "error",
          html: `<div>
                      <br />
                      <p style="color:"red">${data[0]}</p>   
                    </div>`,
        });
      });
  };
  const handleChangeCostingStatus = (event: SelectChangeEvent) => {
    if (event.target.value == "validated") {
      // API.get(`cost/unmapped_calculator/`, { vault: vault })
      // 	.then((res: any) => {

      // 		// if (res?.data?.status == true) {
      // 		// 	setCalulatorList(res?.data?.error);
      // 		// 	ErrorModalFiles.open();
      // 		// 	getPublishStatus();

      // 		// 	// API.put(
      // 		// 	//   `/cost/update_estimation/${costData?.cost_estimation}/`,
      // 		// 	//   { [event.target.value]: true },
      // 		// 	//   {},
      // 		// 	//   0
      // 		// 	// )
      // 		// 	//   .then((res: any) => {
      // 		// 	//     setCostingStatus(event.target.value);
      // 		// 	//     setTreeCount((prev: any) => prev + 1);
      // 		// 	//     // setCount((prev: any) => prev + 1);
      // 		// 	//   })
      // 		// 	//   .catch((err: any) => {
      // 		// 	//     const { data }: any = err?.response;
      // 		// 	//     Swal.fire({
      // 		// 	//       icon: "error",
      // 		// 	//       html: `<div>
      // 		// 	//                 <br />
      // 		// 	//                 <p style="color:"red">${data[0]}</p>
      // 		// 	//               </div>`,
      // 		// 	//     });
      // 		// 	//   });
      // 		// } else {
      // 		// 	setValidationLoader(true);
      // 		// 	API.put(
      // 		// 		`/cost/update_estimation/${costData?.cost_estimation}/`,
      // 		// 		{ [event.target.value]: true },
      // 		// 		{},
      // 		// 		0
      // 		// 	)
      // 		// 		.then((res: any) => {
      // 		// 			setCostingStatus(event.target.value);
      // 		// 			setTreeCount((prev: any) => prev + 1);
      // 		// 			setValidationLoader(false);
      // 		// 			getPublishStatus();
      // 		// 			// setCount((prev: any) => prev + 1);
      // 		// 		})
      // 		// 		.catch((err: any) => {
      // 		// 			const { data }: any = err?.response;
      // 		// 			setValidationLoader(false);
      // 		// 			Swal.fire({
      // 		// 				icon: 'warning',
      // 		// 				html: `<div>
      //         //       <br />
      //         //       <p style="color:"red">${data[0]}</p>
      //         //     </div>`,
      // 		// 			});
      // 		// 		});
      // 		// }
      // 		// costModal.open();
      // 	})
      // 	.catch((err: any) => {
      // 		console.log('Server Error');
      // 	});
      costModal.open();
    } else {
      setValidationLoader(true);
      API.put(
        `/cost/update_estimation/${costData?.cost_estimation}/`,
        { [event.target.value]: true },
        {},
        0
      )
        .then((res: any) => {
          setCostingStatus(event.target.value);
          setTreeCount((prev: any) => prev + 1);
          setValidationLoader(false);
          getPublishStatus();
          // setCount((prev: any) => prev + 1);
        })
        .catch((err: any) => {
          const { data }: any = err?.response;
          setValidationLoader(false);
          Swal.fire({
            icon: "error",
            html: `<div>
                      <br />
                      <p style="color:"red">${data[0]}</p>   
                    </div>`,
          });
        });
    }
  };

  React.useEffect(() => {
    if (Object.keys(costData)?.length > 0) {
      setStatus(costData?.costing_type);
      getPublishStatus();
      setCostingStatus(costData?.costing_status);
    }
  }, [costData]);

  const getHierarchyData = () => {
    setHierarchyDataLoading(true);
    API.get(
      "/cost/cost_hierachy/",
      {
        top_vault: topVault,
      },
      0
    )
      .then((res: any) => {
        setHierarchyData(res?.data);
        setHierarchyDataLoading(false);
      })
      .catch((err: any) => {
        setHierarchyDataLoading(false);
      });
  };

  React.useEffect(() => {
    API.get(
      "/auth/user_permission_module/",
      {
        action: true,
        costing_create: true,
        vault: vault,
        scenario: scenarioId ? scenarioId : undefined,
      },
      0
    )
      .then((res: any) => {
        setPermissionData(res?.data);
      })
      .catch((err: any) => {
        console.log("Srver Error");
      });
  }, [vault]);

  React.useEffect(() => {
    if (openBox === true) {
      getHierarchyData();
    }
  }, [openBox]);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setState({ ...state, [anchor]: open });
      setOpenBox(open);
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        minWidth: 300,
        width: anchor === "top" || anchor === "bottom" ? "auto" : "auto",
      }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <CreateCostingDrawer
        hierarchyData={hierarchyData}
        hierarchyDataLoading={hierarchyDataLoading}
      />
    </Box>
  );

  const handleClick = (dataAbbr: any) => {
    if (scenarioId) {
      history.push(
        `/costingscenario/${projectId}/${projectName}/${topVault}/${vault}/1/${abbrebation}/0/1`
      );
    } else {
      dataAbbr &&
        history.push(
          `/costingabbrlanding/${projectId}/${projectName}/${topVault}/${allProducts?.toString()}/${true}/${topVault}/${productId}/${
            dataAbbr?.parent_abbreviation
              ? dataAbbr?.parent_abbreviation
              : "none"
          }/${dataAbbr?.parent_id}/${lockStatusCost}`
        );
    }
    // history.goBack();
  };
  const guidelinesHandler = () => {
    window.open(`/#/costingguidelines/${vault}/${projectName}`, "_blank");
    // history.push(`/costingguidelines/${projectId}/${projectname}`);

    // setDownloadLoader(true);
    // try {
    //   // API.apiBase + `cost/generate_cost_guide_line/?project=${projectId}&user=${AUTH.user}`

    //   fetch(
    //     API.apiBase +
    //       `cost/generate_cost_guide_line/?project=${projectId}&user=${AUTH.user}`,
    //     {
    //       method: "GET",
    //     }
    //   ).then((resp) => window.open(resp.url));
    // } catch (e) {
    //   setDownloadLoader(false);
    // }
  };

  const redirectScenario = () => {
    if (vault == 0) {
      sessionStorage.removeItem("nodeCostingScenario");
      sessionStorage.setItem("nodeCostingScenario", JSON.stringify([topVault]));
      sessionStorage.setItem("addScenarioStatus", "3");
      window.open(
        `/#/costingscenario/${projectId}/${projectName}/${topVault}/${vault}/1/${abbrebation}/0/0/1`
      );
    } else {
      API.get(`bom/vault_info/`, { vault: vault }, 0)
        .then((res: AxiosResponse) => {
          sessionStorage.removeItem("nodeCostingScenario");
          sessionStorage.setItem(
            "nodeCostingScenario",
            JSON.stringify(res?.data?.[0]?.ancestors)
          );
          sessionStorage.setItem("addScenarioStatus", "3");
          window.open(
            `/#/costingscenario/${projectId}/${projectName}/${topVault}/${vault}/1/${abbrebation}/0/0/1`
          );
        })
        .catch((err: AxiosError) => {});
    }
  };

  const getPublishStatus = () => {
    API.get("bom/get_vault_publish_status/", { vault: vault }, 0)
      .then((res: any) => {
        setPublishStatus(res.data?.disable_lock_publish);
        setMailStatus(res.data?.zbc_first_mail_sent);
      })
      .catch((err: any) => {});
  };

  React.useEffect(() => {
    getPublishStatus();
  }, []);

  const Publishmodal = useBaseParamsModal();
  // const AmoutHandlerandPublish = () => {
  //   Publishmodal.open();
  // };

  console.log(mailStatus);

  const AmoutHandlerandPublish = () => {
      if (mailStatus) {

    Swal.fire({
      title: 'Are you sure?',
      html: `<div>
                <style>
                  .swal-label {
                    margin-top: 15px;
                    display: block;
                    font-size: 1.2rem;
                    font-weight: 600;

                    padding: 10px;
                    background-color: #fffcfc; /* Highlighting background */
                    border: 0.5px solid #007bff; /* Border to make it stand out */
                    border-radius: 5px;
                    color: #333; /* Text color */
                  }
                  input[type="checkbox"] {
                    transform: scale(1.5); /* Increase the scale to make the checkbox larger */
                    margin-right: 10px; /* Add some space between the checkbox and the label text */
                  }
                
                </style>
                <div>
                  <p>You want to lock and publish this part to xcPROC, which will make it visible to the client?</p>
                  <label class="swal-label">
                    <input type="checkbox" id="sendMailCheckbox" ${sendMail ? 'checked' : ''} />
                    Send mail notification to client
                  </label>
                </div>`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'primary.main',
      customClass: {
        container: 'swal2Container',
      },
      preConfirm: () => {
        const checkbox = document.getElementById('sendMailCheckbox') as HTMLInputElement;
        return checkbox.checked ? "true" : "false";
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const sendMailValue = result.value;

        // Now proceed with your API call or any other logic
        API.put(`/bom/vault_info/${vault}/`, { cost_publish: true, trigger_mail: sendMailValue === "true" ? true : false }, {}, 0)
          .then((res: any) => {
            getPublishStatus();
            Swal.fire({
              icon: 'success',
              html: `<div>
                        <br />
                        <p style="color:#00887a">Locked and Published to xcPROC</p>
                     </div>`,
            });
          })
          .catch((err: any) => {
            console.error('Error:', err);
          });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
      } else {
        Swal.fire({
          title: 'Are you sure?',
          text: 'You want to lock and publish this part to xcPROC, which will make it visible to the client?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: 'primary.main',
          customClass: {
            container: 'swal2Container',
          },

        }).then((result) => {
          if (result.isConfirmed) {
          API.put(`/bom/vault_info/${vault}/`, { cost_publish: true , trigger_mail: true}, {}, 0)
            .then((res: any) => {
              getPublishStatus();
              Swal.fire({
                icon: 'success',
                html: `<div>
            <br />
            <p style="color:"red">Locked and Published to xcPROC</p>
            </div>`,
              });
            })
            .catch((err: any) => {});

          } else if (result.isDenied) {
            Swal.fire("Changes are not saved", "", "info");
            }
          });

      }


  };

  const redirectTOBOM = () => {
    sessionStorage.removeItem("BOMCreateNode");
    sessionStorage.setItem("BOMCreateNode", JSON.stringify(costData.ancestors));
    history.push(
      `/bomentry/${projectId}/${projectName}/0/${vault}/${
        abbrebation === "none" ? "none" : abbrebation
      }/0/false/false/${false}`
    );
  };

  const scenarioAmount = costData?.scenario_amount;
  const tat = costData?.tat;

  return (
    <div>
      <Box
        sx={{
          padding: "0 0.5rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {costModal.isOpen && (
          <CostingDataModal
            onCloseModal={costModal.close}
            isOpen={costModal.isOpen}
            Item={vault}
            scenarioAmount={scenarioAmount}
            tat={tat}
            getPublishStatus={getPublishStatus}
            ErrorModalFiles={ErrorModalFiles}
            setCalulatorList={setCalulatorList}
            setValidationLoader={setValidationLoader}
            costData={costData}
            setCostingStatus={setCostingStatus}
            setTreeCount={setTreeCount}
          />
        )}

        {ErrorModalFiles?.isOpen && (
          <WariningModalFilesMail
            isOpen={ErrorModalFiles.isOpen}
            onCloseModal={ErrorModalFiles.close}
            ErrorMsg={CalulatorList}
            setCostingStatus={setCostingStatus}
            setTreeCount={setTreeCount}
            costData={costData}
            onCloseCostModal={costModal.close}
          />
        )}

        {Publishmodal?.isOpen && (
          <PublishAmountAndTimeCostingPage
            onCloseModal={Publishmodal.close}
            isOpen={Publishmodal.isOpen}
            Item={vault}
            scenarioAmount={scenarioAmount}
            tat={tat}
            getPublishStatus={getPublishStatus}
          />
        )}

        <Box
          sx={{
            display: "flex",
            columnGap: "1rem",
            alignItems: "center",
            width: "100%",
            maxWidth: { lg: "43.8vw", xl: "38vw" },
          }}
        >
          {Object.keys(costData)?.length > 0 ? (
            <IconButton
              sx={{ cursor: "pointer" }}
              onClick={() => handleClick(costData)}
            >
              <ArrowBackIcon color="primary" titleAccess="Go Back" />
            </IconButton>
          ) : (
            <IconButton
              sx={{ cursor: "pointer" }}
              // onClick={() => handleClick(costData)}
            >
              <ArrowBackIcon color="primary" titleAccess="Go Back" />
            </IconButton>
          )}
          <Typography sx={{ fontSize: "1rem", whiteSpace: "nowrap" }}>
            {projectName} Estimate {scenarioId != 0 && "Scenario"} Cost
            {" > "}
            {scenarioId ? (
              <span style={{ textTransform: "capitalize" }}>
                {costData?.parent_name} {" > "} {costData?.scenario_name}(
                {costData?.scenario_type})
              </span>
            ) : (
              <span style={{ textTransform: "capitalize" }}>
                {costData?.parent_name}
              </span>
            )}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "1rem",
            justifyContent: "space-between",
            paddingRight: "1rem",
            width: "100%",
          }}
        >
          {scenarioId ? (
            <Box></Box>
          ) : (
            <Box
              sx={{ display: "flex", columnGap: "1rem", alignItems: "center" }}
            >
              Status:{" "}
              {ValidationLoader ? (
                <CircularProgress size={17} />
              ) : (
                <>
                  {costingStatus == "Not Started" ? (
                    <Box
                      sx={{
                        border: "2px solid #d32f2f",
                        color: "#d32f2f",
                        borderRadius: "5px",
                        height: { lg: "2rem", xl: "1.85rem" },
                        padding: "0.15rem 0.5rem",
                        width: {
                          lg: "9.2rem",
                          xl: "8rem",
                          textAlign: "center",
                        },
                      }}
                    >
                      {" "}
                      Not Started
                    </Box>
                  ) : costingStatus == "In Progress" ? (
                    <FormControl
                      sx={{ minWidth: 110 }}
                      size="small"
                      variant="standard"
                    >
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={costingStatus}
                        disabled={
                          PermissionData &&
                          PermissionData?.action?.includes("U")
                            ? false
                            : true
                        }
                        label="Status"
                        onChange={handleChangeCostingStatus}
                        sx={{
                          "&:before": {
                            borderBottomColor: "transparent !important",
                            borderRadius: "5px",
                          },
                          "&:after": {
                            borderBottomColor: "transparent !important",
                            borderRadius: "5px",
                          },
                          "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                            {
                              fontSize: "1rem",
                              border: "2px solid",
                              borderColor: "#ffc800",
                              borderRadius: "5px",
                              height: "1rem",
                              padding: "0.1rem 0.5rem 0",
                              // color:
                              // 	costingStatus == 'Not Started'
                              // 		? 'red'
                              // 		: costingStatus == 'In Progress'
                              // 		? '#ffc800'
                              // 		: costingStatus == 'completed'
                              // 		? '#007fff'
                              // 		: costingStatus == 'validated'
                              // 		? '#008000'
                              // 		: 'red', //status Color update
                            },
                          "& .MuiInputBase-root.MuiInput-root.MuiSelect-root:hover":
                            {
                              border: "2px solid",
                              borderColor: "red !important", //status Color update
                            },
                          ".MuiSvgIcon-root.MuiSelect-icon": {
                            color: "#ffc800",
                          },
                        }}
                      >
                        <MenuItem
                          sx={{
                            fontSize: "1rem",
                            color: "red",
                            display: "none",
                          }}
                          value={"Not Started"}
                        >
                          Not Started
                        </MenuItem>
                        <MenuItem
                          sx={{
                            fontSize: "1rem",
                            color: "#ffc800",
                            display: "none",
                          }}
                          value={"In Progress"}
                        >
                          In Progress
                        </MenuItem>
                        <MenuItem
                          sx={{ fontSize: "1rem", color: "#007fff" }}
                          value={"completed"}
                        >
                          Completed
                        </MenuItem>
                        {
                          <MenuItem
                            sx={{
                              fontSize: "1rem",
                              color: "#008000",
                              display:
                                costData?.costing_status === "In Progress"
                                  ? "none"
                                  : "",
                            }}
                            value={"validated"}
                          >
                            Validated
                          </MenuItem>
                        }
                      </Select>
                    </FormControl>
                  ) : costingStatus == "completed" ? (
                    <FormControl
                      sx={{ minWidth: 110 }}
                      size="small"
                      variant="standard"
                    >
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={costingStatus}
                        disabled={costData?.show_costing_guideline}
                        label="Status"
                        onChange={handleChangeCostingStatus}
                        sx={{
                          "&:before": {
                            borderBottomColor: "transparent !important",
                            borderRadius: "5px",
                          },
                          "&:after": {
                            borderBottomColor: "transparent !important",
                            borderRadius: "5px",
                          },
                          "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                            {
                              fontSize: "1rem",
                              border: "2px solid",
                              borderColor: "#007fff",
                              borderRadius: "5px",
                              height: "1rem",
                              padding: "0.1rem 0.5rem 0",
                              // color:
                              // 	costingStatus == 'Not Started'
                              // 		? 'red'
                              // 		: costingStatus == 'In Progress'
                              // 		? '#ffc800'
                              // 		: costingStatus == 'completed'
                              // 		? '#007fff'
                              // 		: costingStatus == 'validated'
                              // 		? '#008000'
                              // 		: 'red', //status Color update
                            },
                          "& .MuiInputBase-root.MuiInput-root.MuiSelect-root:hover":
                            {
                              border: "2px solid",
                              borderColor: "red !important", //status Color update
                            },
                          ".MuiSvgIcon-root.MuiSelect-icon": {
                            color: "#007fff",
                          },
                        }}
                      >
                        <MenuItem
                          sx={{
                            fontSize: "1rem",
                            color: "red",
                            display: "none",
                          }}
                          value={"Not Started"}
                        >
                          Not Started
                        </MenuItem>
                        <MenuItem
                          sx={{
                            fontSize: "1rem",
                            color: "#ffc800",
                            display: "none",
                          }}
                          value={"In Progress"}
                        >
                          In Progress
                        </MenuItem>
                        {
                          <MenuItem
                            sx={{
                              fontSize: "1rem",
                              color: "#007fff",
                              display:
                                costingStatus == "completed" ? "none" : "",
                            }}
                            value={"completed"}
                          >
                            Completed
                          </MenuItem>
                        }
                        {
                          <MenuItem
                            sx={{
                              fontSize: "1rem",
                              color: "#008000",
                              display:
                                costData?.costing_status === "In Progress"
                                  ? "none"
                                  : "",
                            }}
                            value={"validated"}
                          >
                            Validated
                          </MenuItem>
                        }
                      </Select>
                    </FormControl>
                  ) : costingStatus == "validated" ? (
                    <Box
                      sx={{
                        border: "2px solid #008000",
                        // color: '#008000',
                        borderRadius: "5px",
                        height: { lg: "2rem", xl: "1.85rem" },
                        padding: "0.15rem 0.5rem",
                        width: {
                          lg: "9.2rem",
                          xl: "8rem",
                          textAlign: "center",
                        },
                      }}
                    >
                      Validated
                    </Box>
                  ) : (
                    ""
                  )}
                </>
              )}
              {costData?.show_costing_guideline && (
                <sup style={{ color: "red" }}>
                  {"*Costing guideline is not validated"}
                </sup>
              )}
            </Box>
          )}

          {scenarioId ? (
            <Box></Box>
          ) : (
            <Box>
              {costData?.level === "L1" && (
                <Button
                  sx={{ width: "10rem" }}
                  size="small"
                  variant="contained"
                  disabled={publishStatus}
                  onClick={AmoutHandlerandPublish}
                >
                  Lock & Publish
                </Button>
              )}
            </Box>
          )}
          <Box>
            {isShowValidateScenario && (
              <Button
                sx={{ width: "14rem" }}
                size="small"
                variant="contained"
                // disabled={!isShowValidateScenario}
                onClick={() => {
                  API.put(
                    `/cost/scenario/${scenarioId}/`,
                    { validation: true },
                    {},
                    0
                  )
                    .then((res: any) => {
                      getScenarioVAlidationStatus();
                      Swal.fire({
                        icon: "success",
                        html: `<div>
                    <br />
                    <p style="color:"red">Scenario costing validated successfully</p>   
                    </div>`,
                      });
                    })
                    .catch((err: any) => {
                      const { data } = err?.response;
                      Swal.fire({
                        icon: "error",
                        html: `<div>
                        <br />
                        <p style="color:"red">${data[0]}</p>   
                        </div>`,
                      });
                    });
                }}
              >
                Validate Scenario Costing
              </Button>
            )}
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: "1rem" }}
          >
            <Button
              variant="contained"
              endIcon={<img src={arrowRight} style={{ height: "1.1rem" }} />}
              title="GO to BOM"
              sx={{
                padding: "0.4rem 0.7rem",
                minWidth: "1.5rem",
                lineHeight: 1,
              }}
              onClick={() => {
                redirectTOBOM();
              }}
            >
              BOM
            </Button>
            {scenarioId ? (
              <Box>
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={anchorElRedirect}
                  open={openRedirect}
                  onClose={handleCloseRedirect}
                >
                  {redirectData &&
                    redirectData
                      ?.filter((item: any) => item.id != scenarioId)
                      ?.map((item: any) => {
                        return (
                          <MenuItem
                            sx={{
                              fontSize: "1rem",

                              color: "primary.main",

                              padding: "0.2rem 0.5rem",
                            }}
                            // /view/:projectId/:topVault/:vault/:treeabbr/costing/</StyledMenu>
                            onClick={() =>
                              item?.name === "Main Costing"
                                ? window.open(
                                    `/#/createcostingpart/${projectId}/${projectName}/${item?.product_name}/${topVault}/${vault}/${abbrebation}/${cardtype}/${tableId}/${costEstimationId}/${tableCounter}/${condition}/${calctype}/0/0/0`
                                  )
                                : window.open(
                                    `/#/createcostingpart/${projectId}/${projectName}/${item?.product_name}/${topVault}/${vault}/${abbrebation}/${cardtype}/${tableId}/${costEstimationId}/${tableCounter}/${condition}/${calctype}/${calcultorId}/${item?.id}/0`
                                  )
                            }
                          >
                            <span
                              style={{
                                marginRight: "0.5rem",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                width: "12rem",
                              }}
                              title={item.name}
                            >
                              {item?.name}
                            </span>

                            {/* <span
                            style={{ marginRight: "0.5rem", color: "green" }}
                          >
                            {item?.currency}
                          </span> */}

                            <span style={{ color: "green" }}>{item?.cost}</span>
                          </MenuItem>
                        );
                      })}
                </StyledMenu>
                <Button
                  variant="contained"
                  endIcon={
                    <img src={arrowRight} style={{ height: "1.1rem" }} />
                  }
                  title="Other Scenarios"
                  sx={{
                    padding: "0.4rem 0.7rem",
                    minWidth: "1.5rem",
                    lineHeight: 1,
                  }}
                  onClick={handleClickRedirect}
                >
                  Other Scenarios
                </Button>
              </Box>
            ) : (
              <DescriptionIcon
                titleAccess="Costing Guideline"
                sx={{ cursor: "pointer", fontSize: "2rem" }}
                onClick={() => guidelinesHandler()}
              />
            )}

            {scenarioId ? (
              <Box></Box>
            ) : (
              <img
                title="Go to Scenario"
                src={scnarioprog}
                style={{ height: "1.8rem", cursor: "pointer" }}
                onClick={() => redirectScenario()}
              />
            )}
            {!scenarioId && (
              <FormControl
                sx={{ m: 1, minWidth: 60 }}
                size="small"
                variant="standard"
              >
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={status}
                  disabled={
                    PermissionData && PermissionData?.action?.includes("U")
                      ? false
                      : true
                  }
                  label="BOP"
                  onChange={handleChange}
                  sx={{
                    display: scenarioId ? "none" : "",
                    "&:before": {
                      borderBottomColor: "primary.main",
                      borderRadius: "5px",
                    },
                    "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                      {
                        fontSize: "1rem",
                        border: "2px solid",
                        borderRadius: "5px",
                        height: "1rem",
                        padding: "0.1rem 0.5rem 0",
                      },
                    ".MuiSvgIcon-root.MuiSelect-icon": {
                      color: "primary.main",
                    },
                  }}
                >
                  <MenuItem sx={{ fontSize: "1rem" }} value={"bop"}>
                    BOP
                  </MenuItem>
                  <MenuItem sx={{ fontSize: "1rem" }} value={"dc"}>
                    DC
                  </MenuItem>
                </Select>
              </FormControl>
            )}
            {!scenarioId && (
              <QrCodeScannerIcon
                titleAccess="Scan QR"
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  history.push(`/costingscanner/${projectId}/${projectName}`)
                }
              />
            )}

            {AUTH.isSuperUser && scenarioId == 0 && (
              <DeleteIcon
                titleAccess="Delete Costing"
                sx={{ fontSize: "2rem", cursor: "pointer" }}
                onClick={() => {
                  swal({
                    title: `Delete Costing`,
                    text: `Are you sure?`,
                    icon: "error",
                    //@ts-ignore
                    buttons: {
                      cancel: "Cancel",
                      permanent: {
                        text: `Delete Costing`,
                        // value: `deleteParameterLibrary`,
                      },
                    },
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    className: "delete-swal",
                  }).then(async (type: string) => {
                    if (type === "permanent") {
                      API.delete(
                        `/cost/update_estimation/${costData?.cost_estimation}/`
                      )
                        .then((res: any) => {
                          setCount((prev: any) => prev + 1);
                        })
                        .catch((err: any) => {
                          const { data }: any = err?.response;
                          Swal.fire({
                            icon: "error",
                            html: `<div>
                                      <br />
                                      <p style="color:"red">${data[0]}</p>   
                                    </div>`,
                          });
                        });
                    } else if (type == "Cancel") {
                      // @ts-ignore
                      swal.close();
                    }
                  });
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-start", width: "100%" }}>
        <Box className={styles.expandIconBox}>
          {scenarioId ? (
            <Box></Box>
          ) : (
            <div className={styles.iconSec}>
              {(["left"] as const).map((anchor) => (
                <React.Fragment key={anchor}>
                  <IconButton
                    size="medium"
                    sx={{
                      backgroundColor: "primary.light",
                      color: "primary.main",
                    }}
                    onClick={toggleDrawer(anchor, true)}
                  >
                    <AddIcon sx={{ fontSize: "1.7rem" }} />
                  </IconButton>
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    className={styles.drawerContainer}
                    PaperProps={{
                      sx: {
                        backgroundColor: "aliceblue",
                      },
                    }}
                  >
                    <Box
                      sx={{ padding: "0.5rem 0", position: "sticky", top: "0" }}
                      adl-scrollbar="true"
                      adl-scrollbar-width="0.3"
                    >
                      <Typography
                        style={{
                          width: "100%",
                          textAlign: "center",
                          fontSize: "1rem",
                        }}
                      >
                        Select Part
                      </Typography>
                      <Divider sx={{ margin: "0.2rem" }} />
                      {list(anchor)}
                    </Box>
                  </Drawer>
                  <Typography
                    sx={{ fontSize: "1rem", color: "primary.main" }}
                    onClick={toggleDrawer(anchor, true)}
                    className={styles.sideTitle}
                  >
                    Selection Options
                  </Typography>
                </React.Fragment>
              ))}
            </div>
          )}
        </Box>
        <Box
          sx={{
            width: { xs: "80vw", sm: "96%", md: "96%", lg: "96%", xl: "97%" },
            height: { xs: "87vh", lg: "86vh", xl: "90vh" },
            paddingRight: "0.5rem",
            overflow: { xs: "auto", lg: "visible", xl: "visible" },
          }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          {vault != 0 && (
            <CreateCostingPart
              setCostData={setCostData}
              count={count}
              Cost_status={costData?.costing_status}
              getHierarchyData={getHierarchyData}
              treeCount={treeCount}
            />
          )}
        </Box>
      </Box>
    </div>
  );
}
