import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";

import { Box, Button, Divider, Skeleton, TextField, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Table from "@mui/material/Table";
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { API } from "../../api-services";
import React, { memo, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { LoadingButton } from "@mui/lab";

import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { debounce } from "lodash";
const popstyled = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: '40vw', xl: '30vw' },
  maxHeight: { lg: "90vh", xl: "80vh" },
  bgcolor: "background.paper",
  borderRadius: "10px",
  // border: '2px solid #000',
  // boxShadow: 24,
  p: 1,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// @ts-ignore

const useStyles = makeStyles((theme) => ({
  /** Changed modalStyle */
  ModalRoot: {
    backgroundColor: "rgba(111, 126, 140, 0.2) !important",
    backdropFilter: "blur(1px) !important",
  },
}));


export interface AddParameterModalProps {
  isOpen: any;
  onCloseModal: any;
  setCount: any;
  setParentCount: any;
  setValidationdata: any;
}

const FeatureSquenceModal = (props: AddParameterModalProps) => {
  const { isOpen, onCloseModal, setCount, setParentCount, setValidationdata } = props;
  const {
    projectId,
    projectname,
    categoryId,
    productId,
    datatype,
    validationId,
  } = useRouteParams<any>();
  const classes = useStyles();


  const [rowDatalist, setrowDatalist] = useState<any>([])

  const [IsLoading, setIsLoading] = useState<any>(false)

  const [Counter, setCounter] = useState<any>(0)
  useEffect(() => {

    setIsLoading(true);
    setrowDatalist([]);
    API.get("/bom/parameter_mapping/", {
      project: projectId,
      module: 17,
      category: categoryId,
    })
      .then((res: any) => {
        setIsLoading(false);

        setrowDatalist(res?.data);
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  }, [categoryId, Counter])










  return (
    <div>
      <Modal
        open={isOpen}
        className={classes.ModalRoot}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={popstyled}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}>
              Edit Sequence
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box

            sx={{ height: { lg: "95%", xl: "89%" }, width: "100%" }}
            // sx={{ height: { lg: "85%", xl: "89%" }, width: "100%" }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >

            {IsLoading ?
              <>
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
              </>
              :

              <Table>
                <TableHead
                  sx={{
                    backgroundColor: "primary.light",
                    position: "sticky",
                    top: "0",
                    zIndex: "1",
                  }}
                >
                  <TableRow>
                    <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>Sequence</TableCell>
                    <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>Parameter Name</TableCell>
                    <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>Data Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowDatalist.map((item: any, index: any) => {
                    return (<>
                      <TableRow>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Rowrender isOpen={isOpen} setParentCount={setParentCount} setCounter={setCounter} data={item} setCount={setCount}
                            setValidationdata={setValidationdata} />
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>{item?.parameter?.name}</TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          {item?.data_type}
                        </TableCell>
                      </TableRow>
                    </>)
                  })}
                </TableBody>

              </Table>
            }
          </Box>
        </Box>
      </Modal>
    </div>
  );
};



export default FeatureSquenceModal;

export interface renderRowProps {
  data: any;
  setCount: any;
  setCounter: any;
  setParentCount: any;

  isOpen: any;
  setValidationdata: any;

}
export const Rowrender = (props: renderRowProps) => {
  const {
    projectId,
    projectname,
    categoryId,
    productId,
    datatype,
    validationId,
    categoryname,
    mainId
  } = useRouteParams<any>();
  const { data, setCount,
    setCounter, setParentCount, isOpen, setValidationdata } = props;
  const [sequence, setsequence] = useState<any>(null)


  const ExactDebounce = React.useRef(
    debounce(async (criteria: any, Id: any, projectId: any) => {
      await API.put(`/bom/parameter_mapping/${Id}/`, { sequence: parseInt(criteria), project: projectId }).then(
        (res: any) => {
          //  setSequence(res.data?.sequence );

          API.patch("/config/feature_config/", {
            id: mainId,
            validated: false,
          })
            .then((res: any) => {
              setValidationdata(res?.data?.validated);
              setParentCount((prev: any) => prev + 1)
            })
            .catch((err: any) => {
              const { data } = err?.response;
              setValidationdata(false);
            });
          setCount((prev: any) => prev + 1)
          setCounter((prev: any) => prev + 1)
        }
      );
    }, 800)
  ).current;

  const handlechnage = (e: any) => {
    setsequence(e?.target?.value)
    if (e?.target?.value?.length !== 0 || e?.target?.value !== null) {
      ExactDebounce(e.target.value, data?.id, projectId)
    }

  }

  useEffect(() => {
    setsequence(data?.sequence)
  }, [data])

  return (<>
    <TextField
      variant="standard"
      value={sequence}
      type="number"
      inputProps={{
        step: 'any',
        min: 1,
        // style: { fontSize: '1rem', marginTop: '0.4rem' }
      }}
      onChange={(e: any) => handlechnage(e)}
      sx={{
        width: '4rem',
        ".MuiInputBase-root.MuiInput-root": {
          fontSize: '1rem !important'
        },
        '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
        '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
      }}
    />


  </>)
}