import React, { useEffect, useState } from "react";
import {
  Box,
  Modal,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { API } from "../../../api-services";
import AddPricemapTable from "./AddPriceMaptable";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",

  height: "55vh",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

const sellers = [
  { id: 1, name: "Seller One with a Very Long Name That Should Be Shortened" },
  { id: 2, name: "Seller Two" },
  { id: 3, name: "Seller Three" },
];

function AddMspModal({
  isOpen,
  onCloseModal,
  vault,
  setShowMSPCounter,
  showMSPCounter,
}: any) {
  const [loading, setLoading] = useState(false);
  const [suppliers, setSuppliers] = useState<any>([]);

  const [selectedSeller, setSelectedSeller] = useState<any>(null);

  const handleSellerClick = (seller: any) => {
    setSelectedSeller(seller);
  };

  const getSuppliers = () => {
    setLoading(true);
    API.get(`/bom/all_price_map_table/`, { vault: vault }, 0)
      .then((res: any) => {
        if (res.data) {
          setSuppliers(res.data?.data);
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  useEffect(() => {
    getSuppliers();
  }, []);

  useEffect(() => {
    if (suppliers?.length > 0) {
      setSelectedSeller(suppliers?.[0]);
    }
  }, [suppliers]);

  return (
    <Modal open={isOpen} onClose={onCloseModal}>
      <Box sx={style}>
        <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: "-2rem",
            }}
          >
            Add MSP
          </Typography>
          <CancelIcon
            titleAccess="Close"
            sx={{ cursor: "pointer", margin: "0.5rem" }}
            onClick={onCloseModal}
          />
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
            marginBottom: "1rem",
          }}
        />
        <Box sx={{ display: "flex", height: "90%" }}>
          <Box
            sx={{
              width: "25%",
              borderRight: "1px solid #ccc",
              overflow: "auto",
              height: "100%",
            }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            <List sx={{ paddingTop: "0" }}>
              {suppliers?.map((seller: any) => (
                <ListItem
                  button
                  key={seller?.supplier_id}
                  onClick={() => handleSellerClick(seller)}
                  sx={{
                    borderBottom: "1px solid #ccc",
                    // borderRadius: "4px",
                    // marginBottom: "0.5rem",
                    backgroundColor:
                      selectedSeller?.supplier_id === seller?.supplier_id
                        ? "#008080"
                        : "transparent",
                    color:
                      selectedSeller?.supplier_id === seller?.supplier_id
                        ? "#fff"
                        : "inherit",
                    "&:hover": {
                      backgroundColor:
                        selectedSeller?.supplier_id === seller?.supplier_id
                          ? "#008080"
                          : "#f0f0f0",
                    },
                  }}
                >
                  <ListItemText
                    primary={
                      seller?.supplier?.length > 20
                        ? `${seller?.supplier.slice(0, 20)}...`
                        : seller?.supplier
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Box>
          <Box
            sx={{ width: "75%", p: 2, height: "100%", paddingTop: "0" }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            {selectedSeller ? (
              <AddPricemapTable
                supplier={selectedSeller}
                suppliers={suppliers}
                vault={vault}
                setShowMSPCounter={setShowMSPCounter}
                onCloseModal={onCloseModal}
                showMSPCounter={showMSPCounter}
                setSelectedSeller={setSelectedSeller}
              />
            ) : (
              <Box
                sx={{ display: "grid", placeItems: "center", height: "90%" }}
              >
                <Typography variant="body1">
                  Select a seller to see details.
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default AddMspModal;
