import * as React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Divider, FormControl, Modal, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { QRCode } from 'react-qrcode-logo';
import { API } from '../../../api-services';
import { useRouteParams } from '../../../ui-reusable-component/useRouteParams';
import IFrame from './ImageIFrame/ImageIFrame';
import { fetchS3Url } from '../../../CustomHook/s3-uri.service';
import ErrorModalFilesMail from '../../ProjectMailModule/ErrorModalFilesMail';
import { useBaseModal } from '../../SearchPage/useBaseModal';
import { LoadingButton } from '@mui/lab';
import { useReactToPrint } from 'react-to-print';
import { useStage } from 'spritespin';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';
import { ADMIN } from '../../../Redux/Services/admin.service';
import Swal from 'sweetalert2';
import { DatabaseInputBox } from './DatabaseInputBox';
import { BooleanInputBox } from './BooleanInputBox';
import { DateInputBox } from './DateInputBox';

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	minWidth: { lg: '35vw', xl: '30vw' },
	maxHeight: '90vh',
	bgcolor: 'background.paper',
	borderRadius: '10px',
	p: 1,
};

export interface IBOMEntryAddBOMPartModalProps {
	onCloseModal: any;
	isOpen: any;
	getData: any;
	setParentsCounter: any;
	AssemblyData: any;
	SucessAndErrorMsgVault: any;
	copypartmsg: any;
}

export default function BOMEntryAddBOMPartModal(
	props: IBOMEntryAddBOMPartModalProps
) {
	const {
		onCloseModal,
		isOpen,
		getData,
		setParentsCounter,
		AssemblyData,
		SucessAndErrorMsgVault,
		copypartmsg,
	} = props;
	const {
		vehCateId,
		vehCateName,
		partId,
		topVault,
		vault,
		treeabbr,
		workstation,
		isLeaf,
		copyStatus,
	} = useRouteParams<any>();
	const imageIframeRef = React.useRef<any>(null);

	const [PartAssemblyData, setPartAssemblyData] = React.useState<any>([]);
	const [contentRef, setContentRef] = React.useState<any>();
	// const [print, setPrint] = React.useState<any>(0);
	const [quantity, setquantity] = React.useState<any>(1);

	// const [SelectedPart, setSelectedPart] = React.useState<any>({});
	const [subPartName, setSubPartName] = React.useState<any>('');
	const [UserManualEamil, setUserManualEamil] = React.useState<any>({});
	const [Loader, setLoader] = React.useState<any>(false);
	const ErrorModalFiles = useBaseModal();
	const [InfoData, setInfodata] = React.useState<any>([]);
	const [ErrorModalFileMsg, setErrorModalFileMsg] = React.useState<any>();
	const [newVaultID, setNewVaultID] = React.useState<any>();
	const [PartError, SetPartError] = React.useState<any>(false);
	const [CategoryError, SetCategoryError] = React.useState<any>(false);
	const [SelectedCategory, setSelectedCategory] = React.useState<any>({});
	const [categoryList, setcategoryList] = React.useState<any>([]);
	const history = useHistory<any>();
	// const [WorkStationList, setWorkStationList] = React.useState<any>([]);
	const [BOMparameters, setBOMparameters] = React.useState<any>([]);
	const [details, setDetails] = React.useState<any>({});
	const [allValues, setAllValues] = React.useState<any>({});
	const [loadingWeightLock, setLoadingWeightLock] = React.useState<any>(false);
	const [isBOMparametersLoading, setIsBOMparametersLoading] =
		React.useState<boolean>(false);
	// const handleSelectChangeAddBOMPart = (newSystemValue: any) => {
	// 	setSelectedPart(newSystemValue);
	// 	SetPartError(false);
	// };

	const handleChange = (e: any) => {
		setquantity(e?.target?.value);
	};
	const handleSelectChangecategory = (event: any, newSystemValue: any) => {
		setSelectedCategory(newSystemValue);
		SetCategoryError(false);
	};
	const handlePrint = useReactToPrint({
		content: () => imageIframeRef.current,
	});

	const getQRHandler = () => {
		// if (
		// 	Object?.keys(SelectedCategory)?.length > 0 &&
		// 	Object.keys(SelectedPart)?.length > 0
		// ) {
			setLoader(true);
		API.post(
			`bom/vault_info/`,
			{
				name: subPartName,
				category: SelectedCategory?.category,
				quantity: +quantity,
				parent: getData?.id,
				vehicle_category: vehCateId,
				// top_vault: InfoData[0]?.top_vault,
			},
			0
		)
			.then((res: any) => {				
				setLoader(false);

				setNewVaultID(res?.data?.id);
				// onCloseModal();

				setContentRef(res?.data?.qr_link);
				//  fetchS3Url().then(setContentRef)

				// API.get(
				//   "/auth/workstation/",
				//   {
				//     top_vault: topVault,
				//     vault:  res?.data?.id ,
				//     // workstation_list: true,
				//   },
				//   0
				// )
				//   .then((res1: any) => {
				// let CreateSation= res1?.data && res1?.data.filter((item:any,index:any)=>{return item?.name=="CS"})
				// if(CreateSation && CreateSation?.length==0 ){
				if (SelectedCategory?.category_leaf == false) {
					var data = [...InfoData[0]?.ancestors, newVaultID];

					history.push(
						`/bomentry/${vehCateId}/${vehCateName}/0/${
							res?.data?.id == undefined ? vault : res?.data?.id
						}/${treeabbr}/0/${isLeaf}/${copyStatus}`
					);

					setContentRef(null);
					onCloseModal();
					sessionStorage.setItem('BOMCreateNode', JSON.stringify(data));
					sessionStorage.setItem('newvault', res?.data?.id);

					setParentsCounter((prev: any) => prev + 1);
					if (getData?.is_copy == true) {
						copypartmsg();
					} else {
						SucessAndErrorMsgVault();
					}
				}

				if (SelectedCategory?.category_leaf == true) {
					handlePrint();
				}
				// }

				//   })
				//   .catch((err: any) => {

				//   });

				setContentRef(res?.data?.qr_link);
			})
			.catch((err: any) => {
				setLoader(false);
				const { data } = err?.response;
				ErrorModalFiles?.open();
				setErrorModalFileMsg(data[0]);
			});
		// }
		if (
			Object?.keys(SelectedCategory)?.length == 0 ||
			Object?.keys(SelectedCategory)?.length == null
		) {
			SetCategoryError(true);
		}
		if (
			Object?.keys(subPartName)?.length == 0 ||
			Object?.keys(subPartName)?.length == null
		) {
			SetPartError(true);
		}
	};

	const Proceedhandler = () => {};
	const ExactDebounce = React.useRef(
		debounce(async (criteria: any) => {
			await API.get(
				'/db/part_name/',
				{ search_key: criteria, vault: getData?.id },
				0
			).then((res: any) => {
				setPartAssemblyData(res.data);
			});
		}, 700)
	).current;
	const PartNameHandler = (e: any, item: any) => {
		setUserManualEamil(item);

		if (e?.type == 'change') {
			if (item !== 'False' && item?.length > 0) {
				ExactDebounce(item);
			}
		}
	};

	const ManualEmailHandler = (e: any) => {
		var filterdata =
			PartAssemblyData &&
			PartAssemblyData.filter(
				(item: any, index: any) => item?.name == UserManualEamil
			);

		if (filterdata?.length == 0) {
			if (e.key == 'Enter') {
				{
					API.post(
						`/db/part_name/`,
						{
							name: UserManualEamil,

							vault: getData?.id,
						},
						{},
						0
					)
						.then((res1: any) => {
							API.get(
								`/db/part_name/`,
								{ search_key: UserManualEamil, vault: getData?.id },
								0
							)
								.then((res: any) => {
									setPartAssemblyData(res?.data);
								})
								.catch((err: any) => {
									console.log('Server Error');
								});
						})
						.catch((err: any) => {
							const { data } = err?.response;
							ErrorModalFiles?.open();
							setErrorModalFileMsg(data);
						});
				}
			}
		}
	};

	const PasteEmailHandler = (data: any) => {
		var filterdata =
			PartAssemblyData &&
			PartAssemblyData.filter((item: any, index: any) => item?.name == data);

		if (filterdata?.length == 0) {
			API.post(
				`/db/part_name/`,
				{
					name: data,

					vault: getData?.id,
				},
				{},
				0
			)
				.then((res1: any) => {
					API.get(`/db/part_name/`, { vault: getData?.id }, 0)
						.then((res: any) => {
							setPartAssemblyData(res?.data);
						})
						.catch((err: any) => {
							console.log('Server Error');
						});
				})
				.catch((err: any) => {
					const { data } = err?.response;
					ErrorModalFiles?.open();
					setErrorModalFileMsg(data);
				});
		}
	};

	React.useEffect(() => {
		setLoader(true);
		API.get('/bom/vault_info/', { vault: getData?.id })
			.then((res1: any) => {
				setInfodata(res1?.data);
				setLoader(false);
			})
			.catch((err: any) => {
				setLoader(false);
				console.log('Server Error');
			});
	}, [getData]);

	React.useEffect(() => {
		if (InfoData.length > 0) {
			setLoader(true);
			API.get('/bom/template_config/', { vehicle_category: vehCateId }, 0)
				.then((res: any) => {
					setcategoryList(res?.data);
					setLoader(false);
				})
				.catch((err: any) => {
					setLoader(false);
					console.log('Server Error');
				});
		}
	}, [InfoData]);

	React.useEffect(() => {
		setPartAssemblyData(AssemblyData);
	}, [AssemblyData]);

	// console.log(newVaultID,"newVaultIDnewVaultID")
	React.useEffect(() => {
		if (newVaultID !== undefined) {
			getBOMParametersData();
		}
	}, [vault, vehCateId, newVaultID]);

	// let CreateSation= WorkStationList && WorkStationList?.filter((item:any,index:any)=>{return item?.name=="CS"})
	//  console.log(WorkStationList && WorkStationList[0]?.id ,"WorkStationListWorkStationList",CreateSation)

	const getBOMParametersData = () => {
		setIsBOMparametersLoading(true);
		API.get(
			'/bom/bom_create_view/',
			{
				...obj(topVault, newVaultID),
				part_details: true,
			},
			0
		)
			.then((res: any) => {
				setIsBOMparametersLoading(false);
				setBOMparameters(res.data);
			})
			.catch((err: any) => {
				setIsBOMparametersLoading(false);
			});
	};

	const handleClose = () => {
		if (
			Object?.keys(SelectedCategory)?.length > 0 &&
			Object.keys(subPartName)?.length > 0
		) {
			var data = [...InfoData[0]?.ancestors, newVaultID];

			if (getData?.is_copy == true) {
				copypartmsg();
			}
			// @ts-ignore
			history.push(
				`/bomentry/${vehCateId}/${vehCateName}/0/${
					newVaultID == undefined ? vault : newVaultID
				}/${treeabbr}/0/${isLeaf}/${copyStatus}`
			);

			setContentRef(null);
			onCloseModal();
			sessionStorage.setItem('BOMCreateNode', JSON.stringify(data));
			sessionStorage.setItem('newvault', newVaultID);

			setParentsCounter((prev: any) => prev + 1);
			document.getElementById(newVaultID)?.scrollIntoView({
				block: 'nearest',
				inline: 'start',
				behavior: 'smooth',
			});
		} else {
			setContentRef(null);
			onCloseModal();
		}
	};
	// React.useEffect(()=>{
	// if(newVaultID!==undefined){
	//   API.get(
	//     "/auth/workstation/",
	//     {
	//       top_vault: partId,
	//       vault: newVaultID != 0 ? newVaultID : undefined,
	//       workstation_list: true,
	//     },
	//     0
	//   )
	//     .then((res: any) => {

	//       setWorkStationList(res.data);

	//     })
	//     .catch((err: any) => {

	//     });
	// }

	// },[newVaultID])

	// console.log(getData,"xsbcbsjcbjsbcjsjhcb")

	const obj = (tv: any, vlt: any) => {
		if (vlt == 0) {
			return { vehicle_category: tv };
		} else {
			return { vault: vlt };
		}
	};

	const getobj = (v: any, tv: any) => {
		if (v == 0) {
			return { vehicle_category: tv };
		} else {
			return { vault: v };
		}
	};

	const getVaultDetails = () => {
		setLoadingWeightLock(true);
		API.get(
			'/bom/vault_info/',
			{
				...obj(topVault, newVaultID),
				module: 9,
				current_top_vault: vehCateId,
			},
			0
		)
			.then((res: any) => {
				setDetails(res.data[0]);
			})
			.catch((err: any) => {});
	};

	const getAllValues = () => {
		API.get('/bom/component_details/', { ...obj(topVault, newVaultID) }, 0)
			.then((res: any) => {
				setAllValues(res.data[0]?.details);
			})
			.catch((err: any) => {});
	};

	React.useEffect(() => {
		if (newVaultID !== undefined) {
			getAllValues();
		}
	}, [vault, newVaultID]);
	React.useEffect(() => {
		if (newVaultID !== undefined) {
			getVaultDetails();
		}
	}, [vault, newVaultID]);

	const BOMValidationStatus = () => {
		API.get(
			'/bom/bom_hirearchy_function/',
			{
				bom_status: true,
				...obj(topVault, newVaultID),
			},
			0
		)
			.then((res: any) => {
				// setBOMStatus(res.data?.bom_status);
			})
			.catch((err: any) => {});
	};

	const ExactDebounceParameters = React.useRef(
		debounce(async (value: any, name: any, idd: any, tv) => {
			await updateDataVaultParams(name, value, idd, tv);
		}, 1000)
	).current;

	React.useEffect(() => {
		return () => {
			ExactDebounce.cancel(); // Cancel the debounced function
			ExactDebounceParameters.cancel(); // Cancel the debounced function
		};
	}, []);

	// console.log(BOMparameters, 'BOMparametersBOMparameters');

	const updateDataVaultParams = async (
		name: any,
		value: any,
		vlt: any,
		tv: any
	) => {
		API.put(
			`/bom/component_details/74699/`,
			{
				[name]: value,
			},
			{
				...getobj(vlt, tv),
			},
			0
		)
			.then((res: any) => {
				// setSyncColor("red");
				// getBOMParametersData();
				BOMValidationStatus();
				ADMIN.toast.info('Updated Successfully');
			})
			.catch((err: any) => {
				const { data } = err.response;
				Swal.fire({
					title: 'Error!',
					text: data[0],
					icon: 'error',
					confirmButtonText: 'Ok',
				});
			});
	};

	const handleSelectSystems = (
		event: any,
		newSystemValue: any,
		key: any,
		v: any,
		tv: any
	) => {
		ExactDebounceParameters(newSystemValue, key, v, tv);
	};

	return (
		<div>
			<Modal
				open={isOpen}
				// onClose={onCloseModal}
				disableEnforceFocus
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'>
				<Box sx={style}>
					<ErrorModalFilesMail
						isOpen={ErrorModalFiles.isOpen}
						onCloseModal={ErrorModalFiles.close}
						ErrorMsg={ErrorModalFileMsg}
					/>
					<Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
						<Typography
							style={{
								fontSize: '1rem',
								fontWeight: '600',
								width: '100%',
								textAlign: 'center',
								whiteSpace: 'nowrap',
								// marginRight: "-2rem",
							}}>
							Add Part/Assembly to {getData?.name} ({getData?.part_no})
						</Typography>
						<CancelIcon
							titleAccess='Close'
							sx={{ cursor: 'pointer', margin: '0.5rem' }}
							onClick={() => handleClose()}
						/>
					</Box>
					<Divider
						sx={{
							borderColor: 'primary.light',
							margin: '0 1rem',
							marginBottom: '1rem',
						}}
					/>
					<form onSubmit={getQRHandler}>
						<Box sx={{ padding: '0 1rem 1rem' }}>
							<Box
								sx={{
									display: 'grid',
									gridTemplateColumns: '1fr 1fr',
									columnGap: '1rem',
									width: '100%',
									marginTop: '1rem',
								}}>
								<FormControl fullWidth>
									<TextField
										// value={SelectedPart}
										// onChange={(e: any) => handleSelectChangeAddBOMPart(e)}
										value={subPartName}
										onChange={(e) => setSubPartName(e.target.value)}
										variant='standard'
										placeholder='Part Name / Assembly'
										sx={{
											'.MuiInputBase-root.MuiInput-root': {
												fontSize: '1rem !important',
											},
											'& .MuiInput-underline:before': {
												borderBottomColor: '#00887a',
											},
											'& .MuiInput-underline:after': {
												borderBottomColor: '#00887a',
											},
											'.MuiSvgIcon-root': { color: 'primary.main' },
										}}
									/>

									{PartError ? (
										<span style={{ color: 'red' }}>
											*Please Enter Part/Assembly Name
										</span>
									) : (
										''
									)}
								</FormControl>
							</Box>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'flex-end',
									columnGap: '1rem',
									width: '100%',
									marginTop: '1rem',
								}}>
								<FormControl fullWidth variant='standard'>
									<Autocomplete
										id='tags-standard'
										// disableClearable
										// disableCloseOnSelect
										sx={{
											'.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator':
												{
													color: 'primary.main',
												},
											'.MuiButtonBase-root.MuiChip-root': {
												backgroundColor: 'primary.light',
												height: '26px',
											},
										}}
										options={categoryList}
										getOptionLabel={(option) =>
											// @ts-ignore
											option?.commodity_name == undefined
												? ''
												: option?.commodity_name
										}
										value={SelectedCategory}
										onChange={(event: any, newSystemValue: any) => {
											handleSelectChangecategory(event, newSystemValue);
										}}
										// getOptionDisabled={getOptionDisabled}
										renderOption={(props, option) => (
											<li {...props} style={{ fontSize: '1rem' }}>
												{option?.commodity_name}
											</li>
										)}
										renderInput={(params) => (
											<TextField
												{...params}
												variant='standard'
												placeholder='Select Category'
												sx={{
													'& .MuiInput-underline:before': {
														borderBottomColor: 'primary.main',
													},
													'& .MuiInput-underline:after': {
														borderBottomColor: 'primary.main',
													},
													'.MuiInputBase-root.MuiInput-root': {
														fontSize: '1rem !important',
													},
													'.MuiSvgIcon-root': { color: 'primary.main' },
												}}
											/>
										)}
									/>

									{CategoryError ? (
										<span style={{ color: 'red' }}>
											{' '}
											*Please Select Category
										</span>
									) : (
										''
									)}
								</FormControl>
								<FormControl fullWidth>
									<TextField
										value={quantity}
										onChange={(e: any) => handleChange(e)}
										variant='standard'
										type='number'
										inputProps={{
											min: 1,
										}}
										InputLabelProps={{
											shrink: true,
											style: {
												color: '#00887a',
											},
										}}
										label='Quantity'
										placeholder='Quantity'
										sx={{
											'.MuiInputBase-root.MuiInput-root': {
												fontSize: '1rem !important',
											},
											'& .MuiInput-underline:before': {
												borderBottomColor: 'primary.main',
											},
											'& .MuiInput-underline:after': {
												borderBottomColor: 'primary.main',
											},
										}}
									/>
								</FormControl>
							</Box>
							<Box
								sx={{ maxHeight: '60vh' }}
								adl-scrollbar='true'
								adl-scrollbar-width='0.3'>
								{contentRef && (
									<Box
										sx={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											columnGap: '1rem',
											width: '100%',

											marginTop: '1rem',
										}}>
										<Box>
											{contentRef && (
												<>
													<div
														ref={imageIframeRef}
														style={{ textAlign: 'center' }}>
														<img src={contentRef} height={200} width={200} />
													</div>
												</>
											)}
										</Box>
									</Box>
								)}
								{contentRef && (
									<Box sx={{ display: 'flex', justifyContent: 'center' }}>
										<LoadingButton
											loading={Loader}
											size='small'
											variant='contained'
											onClick={() => handlePrint()}
											sx={{
												width: '10rem',
												'&:hover': {
													transform: 'Scale(1.05)',
													transition: 'transform 0.5s ease',
												},
											}}>
											Print QR
										</LoadingButton>
									</Box>
								)}
							</Box>
							<Box
								sx={{
									display: 'flex',
									marginTop: '1rem',
									justifyContent: 'end',
								}}>
								{SelectedCategory &&
									Object?.keys(SelectedCategory)?.length > 0 && (
										<>
											{contentRef == null ? (
												<LoadingButton
													loading={Loader}
													size='small'
													variant='contained'
													type='submit'
													sx={{
														width: '8rem',
														'&:hover': {
															transform: 'Scale(1.05)',
															transition: 'transform 0.5s ease',
														},
														'.MuiCircularProgress-root': {
															color: 'primary.main',
														},
													}}>
													{SelectedCategory?.category_leaf == true
														? 'Get QR'
														: 'ADD'}
												</LoadingButton>
											) : (
												<LoadingButton
													size='small'
													variant='contained'
													onClick={() => handleClose()}
													sx={{
														width: '8rem',
														'&:hover': {
															transform: 'Scale(1.05)',
															transition: 'transform 0.5s ease',
														},
														'.MuiCircularProgress-root': {
															color: 'primary.main',
														},
													}}>
													Proceed
												</LoadingButton>
											)}
										</>
									)}
							</Box>
						</Box>
					</form>
				</Box>
			</Modal>
		</div>
	);
}
