import axios, { AxiosError, AxiosResponse } from "axios";
import { API } from "../api-services";
import { MODULES } from "../Constants/modules.constants";
import { s3Url } from "../utlis/s3url.utils";
import Swal from "sweetalert2";
import successIcon from "../../Components/Assets/images/success.png";
import warningicon from "../Assets/images/Warning.png"

const S3WeightImage = (fileList: any, itemId: any, weight: any, setCounter: any, setLoading: any, onCloseModal: any) => {
  var random: any = Math.floor(Math.random() * 1000000 + 1);
  API.get("cart/get_signed_policy/", {
    module: MODULES.CAD,
  }).then((res) => {
    const data = Array.from(fileList).forEach((file: any, index: any) => {
      const formData = new FormData();
      formData.append("key", `weightCheck/${itemId ? itemId : 0}/${random}/${file.name}`);
      formData.append("Content-Type", file?.type);
      formData.append("x-amz-server-side-encryption", "AES256");
      formData.append("X-Amz-Credential", res.data.data.x_amz_credential);
      formData.append("X-Amz-Algorithm", "AWS4-HMAC-SHA256");
      formData.append("X-Amz-Date", res.data.data.x_amz_date);
      formData.append("X-Amz-Signature", res.data.data.aws_policy_signature);
      formData.append("Policy", res.data.data.aws_policy);
      formData.append("file", file, file?.name);
      axios
        .post(s3Url, formData, {
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
          },
        })
        .then(async (res: any) => {
          API.post(
            "/xcpep/dispatch/",
            {
              weight_verification_image: `/weightCheck/${itemId ? itemId : 0}/${random}/${file.name}`,
              verification_weight: weight,
              id: itemId,
            },
            {},
            0
          )
            .then((res: any) => {
              setLoading(false);
              Swal.fire({
                html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
            
              <br />
            
              <p style="color:#007fff;">Part verified successfully
            
              </p>   
            
               </div>`,
              });
              onCloseModal();
              setCounter((prev: any) => prev + 1);
            })
            .catch((err: any) => {
              // err.response?.data[0]
              Swal.fire({
                html: `<div><img src="${warningicon}" alt="Custom Image" style="width: 7rem; height: auto;" />
            
              <br />
            
              <p style="color:#007fff;">${err.response?.data[0]}
            
              </p>   
            
               </div>`,
              });
              onCloseModal();
              setLoading(false);
            });
        });
    });
  });
};
// ----------------------------------------------------------------------------------
export default S3WeightImage;
