import Chip from '@mui/material/Chip'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styles from "./ProjectDirectorylist.module.scss"
import ProductCategory from './ProductCategory';
// import OEMlist from './OEMlist';
import { IconButton, Typography } from '@mui/material';


interface ProjectDirectorylistProps { }

const ProjectDirectorylist = (props: ProjectDirectorylistProps) => {


  const { projectId, type } = useParams<any>()
  const history = useHistory<any>()


  return (
    <div>
      <div className={styles.top_container}>
        <div className={styles.arrowbtn}>
          <IconButton>
            <ArrowBackIcon sx={{ cursor: "pointer", color: 'primary.main' }} onClick={() => history.push("/project_directory")} />
          </IconButton>

          {/* {type=="OEM" ?
          <Typography sx={{ fontSize: '1rem' }}>Project Directory(OEM's List)</Typography>: */}
          
          <Typography sx={{ fontSize: '1rem' }}>Project Directory(Product Categories)</Typography>          
          {/* } */}
        </div>
        <div>

          {/* type == "OEM" && */} 
          {/* {type == "Categories"} */}
          {/* { <Chip size="small" label="Product Categories" sx={{
            backgroundColor: type == "Categories"?'primary.main':'primary.light', color: type == "Categories"? 'white': 'primary.main',  cursor: 'pointer',marginRight:'1rem',
            "&:hover": {
              backgroundColor: 'primary.main',
              color:'white',
            },
          }}
           onClick={() => history.push("/Project-Directory-list/Categories/0")} />} */}
          {/* {  <Chip size="small" label="OEM's List" sx={{
            backgroundColor:type == "OEM"? 'primary.main':'primary.light', color: type == "OEM"? 'white': 'primary.main', cursor: 'pointer',
            "&:hover": {
              backgroundColor: 'primary.main',
              color:'white',
            },
          }} onClick={() => history.push("/Project-Directory-list/OEM/0")} />} */}
        </div>
      </div>
      <div>
        {type == "Categories" && <ProductCategory />}
        {/* {type == "OEM" && <OEMlist />} */}
      </div>
      <div>


      </div>




    </div>
  )
}

export default ProjectDirectorylist