import React, { useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputBase,
  Link,
  Modal,
  Pagination,
  Paper,
  Skeleton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import CDN_URL from "../../utlis/CDN_URL";
import UploadIcon from "@mui/icons-material/Upload";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import OwnPartList from "./OwnPartList";
import ImageUpload from "./useImageUpload";
import CallIcon from "@mui/icons-material/Call";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EmailIcon from "@mui/icons-material/Email";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CheckCircle, Delete, Edit } from "@mui/icons-material";
import { API } from "../../api-services";
import Swal from "sweetalert2";
import BusinessOwnerDetailsModal from "./BusinessOwnerDetailsModal";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import PartListModal from "./PartListModal";
import SupplierFinancialData from "./SupplierFinancialData";
import SupplierContact from "./SupplierContact";
import SupplierBusinessInfo from "./SupplierBusinessInfo";
import SupplierDirectors from "./SupplierDirectors";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import SupplierDeleteRestrictionModal from "./SupplierDeleteRestrictionModal";
import moment from "moment";
import EmailLogs from "./EmailLogs";
import SupplierLocations from "./SupplierLocations";

function SupplierUI({
  supplierDetails,
  supplierId,
  editSupplier,
  getSupplierDetails,
  filterOption,
  setFilterOption,
  getTrashCount,
}: any) {
  const { supplier } = useRouteParams<any>();
  const userDetails: any = {
    name: "John Doe",
    email: "john.doe@example.com",
    phone: "123-456-7890",
    subscriptions: [
      { date: "2024-01-01", type: "Monthly" },
      { date: "2024-06-01", type: "Yearly" },
    ],
  };
  const PartsModal = useBaseParamsModal();
  const deleteSupplier = useBaseParamsModal();
  const [openModal, setOpenModal] = useState(false);
  const [partList, setPartList] = useState(false);
  const [value, setValue] = React.useState(0);
  const [partCount, setPartCount] = React.useState<any>({
    known: 0,
    potential: 0,
  });
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const MAX_TOTAL_FILE_SIZE_MB_LOGO = 25;
  const documentRef = React.useRef<any>(null);
  const [expandedItems, setExpandedItems] = React.useState<{
    [key: number]: boolean;
  }>({});

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const renderDetails = (key: any, val: any) => {
    return (
      <Box sx={{ display: "flex", gap: "1.5rem" }}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "1r.1em",
            width: "25%",
          }}
        >
          {key}
        </Typography>
        :
        {key === "Website" ? (
          <>
            {val ? (
              <Link target="_blank" rel="noopener noreferrer" href={val}>
                {val && val !== null ? val : "--"}
              </Link>
            ) : (
              <Typography
                sx={{
                  fontWeight: key === "Address" ? "regular" : "bold",
                  fontSize: "1r.1em",
                }}
              >
                {val && val !== null ? val : "--"}
              </Typography>
            )}
          </>
        ) : (
          <Typography
            sx={{
              fontWeight: key === "Address" ? "regular" : "bold",
              fontSize: "1r.1em",
            }}
          >
            {val && val !== null ? val : "--"}
          </Typography>
        )}
      </Box>
    );
  };

  const handleCallClick = (contactNo: any) => {
    window.location.href = `tel:${contactNo}`;
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        console.log("Copied to clipboard");
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };
  const handleEmailClick = (emailId: any) => {
    window.location.href = `mailto:${emailId}`;
  };

  const handleToggle = (index: number) => {
    setExpandedItems((prev: any) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const browselogoFiles = (event: any) => {
    event.preventDefault();
    return documentRef.current?.click?.();
  };

  const handleAPIWithKey = (key: any) => {
    // API.post("",{},{}).then((res:any)=>{
    //   getSupplierDetailsByID();
    // }).catch((err:any)=>{
    // })
  };

  const handleInputClickfile = (e: any) => {
    // clear the prev value on click so that same image can be uploaded again
    (e.target as HTMLInputElement).value = "";
  };

  function mergeArrays(...arrays: any) {
    return arrays.reduce((merged: any, arr: any) => {
      if (Array.isArray(arr)) {
        merged.push(...arr);
      }
      return merged;
    }, []);
  }

  function mergeDocs(supplierDetails: any) {
    const proofOfOwnershipDocs = Array.isArray(
      supplierDetails?.proof_of_ownership_docs
    )
      ? supplierDetails.proof_of_ownership_docs
      : [];
    const addVerificationDocs = Array.isArray(
      supplierDetails?.add_verification_docs
    )
      ? supplierDetails.add_verification_docs
      : [];
    const authorizedSignatoryDocs = Array.isArray(
      supplierDetails?.authorized_signatory_docs
    )
      ? supplierDetails.authorized_signatory_docs
      : [];

    return mergeArrays(
      proofOfOwnershipDocs,
      addVerificationDocs,
      authorizedSignatoryDocs,
      addVerificationDocs // Not sure if this is intentional, but added as per your example
    );
  }

  const mergedDocs = mergeDocs(supplierDetails);

  const verifySupplier = () => {
    API.put(`supplier/supplier_db/${supplierId}/`, {
      verified: true,
    })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Supplier verified successfully",
        }).then(() => {
          // This code will execute after the user clicks the "OK" button
          getSupplierDetails(supplierId);
        });
      })

      .catch((err: any) => {
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
        });
      });
  };

  const fetchPartsData = async () => {
    try {
      const result = await API.get(`/supplier/supplier_db/`, {
        parts_info: true,
        page: 1,
        id: supplier,
      });

      const knownParts: any =
        Array.isArray(result.data) &&
        result.data?.filter((item: any, index: number) => {
          if (item?.potential_supplier === false) {
            return item;
          }
        });

      const potentialParts: any =
        Array.isArray(result.data) &&
        result.data?.filter((item: any, index: number) => {
          if (item?.potential_supplier === true) {
            return item;
          }
        });

      setPartCount({
        known: knownParts?.length || 0,
        potential: potentialParts?.length || 0,
      });

      setPartList(result.data);

      return;
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    } finally {
    }
  };

  useEffect(() => {
    fetchPartsData();
  }, [supplierDetails]);

  const handleDeleteSupplier = (supplierId: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this supplier?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete!",
    }).then((result) => {
      if (result.isConfirmed) {
        API.delete(`/supplier/supplier_db/${supplierDetails?.id}/`, {}, 0)
          .then((res) => {
            // Show success message
            getSupplierDetails(supplierId);
            setTimeout(() => {
              setFilterOption({
                ...filterOption,
                part: filterOption.part === true ? null : true,
              });
            }, 500);

            setTimeout(() => {
              getTrashCount();
            }, 800);

            Swal.fire({
              icon: "success",
              title: "Supplier deleted successfully",
              showConfirmButton: false,
              timer: 500,
            });
            // Optionally, refresh the supplier list or handle state updates
          })
          .catch((err) => {
            console.log(err, "Unable to delete supplier. Server Error");
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          });
      }
    });
  };

  return (
    <Box
      sx={{ width: "75.5%", padding: "0.5rem", height: "87vh" }}
      adl-scrollbar="true"
      adl-scrollbar-width="0.3"
    >
      {deleteSupplier.isOpen && (
        <SupplierDeleteRestrictionModal
          openModal={deleteSupplier.isOpen}
          handleCloseModal={() => deleteSupplier.close()}
          supplierId={supplierDetails?.id}
          getSupplierDetails={getSupplierDetails}
          partList={partList}
        />
      )}
      {PartsModal.isOpen && (
        <PartListModal
          openModal={PartsModal.isOpen}
          handleCloseModal={() => PartsModal.close()}
        />
      )}
      <Box
        sx={{
          display: "flex",
          backgroundColor: "background.paper",
          marginBottom: "0.5rem",
          alignItems: "flex-end",
        }}
      >
        <Box sx={{ width: "auto", backgroundColor: "white" }}>
          <Tabs
            //   orientation="vertical"
            value={value}
            onChange={handleChange}
            aria-label="Tabs example"
            sx={{ borderRight: 1, borderColor: "divider", height: "100%" }}
          >
            <Tab label="Information" />
            <Tab label={`Known Parts (${partCount?.known})`} />
            <Tab label={`Potential Parts (${partCount?.potential})`} />
            <Tab label={`Email Logs (0)`} />
          </Tabs>
        </Box>

        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            {supplierDetails && supplierDetails?.business_name !== "" && (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  fontSize: "0.8rem",
                  borderRadius: "0.5rem",
                  "&.Mui-disabled": {
                    background: "transparent",
                    color: "#00887a",
                    cursor: "context-menu",
                    border: "1px solid #00887a",
                  },
                }}
                onClick={verifySupplier}
                disabled={supplierDetails?.verified}
                startIcon={<CheckCircle />}
              >
                {supplierDetails?.verified ? "Verified" : "Verify"}
              </Button>
            )}

            <Button
              variant="contained"
              // color="error"
              startIcon={<Edit />}
              sx={{ ml: 2 }}
              onClick={() => editSupplier(supplierDetails?.id)}
            >
              Edit
            </Button>
            <Button
              variant="contained"
              color="error"
              startIcon={<Delete />}
              sx={{ ml: 2 }}
              onClick={() => {
                if (supplierDetails?.can_be_deleted) {
                  handleDeleteSupplier(supplierDetails?.id);
                } else {
                  deleteSupplier.open(supplierDetails?.id);
                }
              }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Box>

      {value === 0 && (
        <>
          <Grid container spacing={2} sx={{ p: 2 }}>
            {/* Logo Box */}
            <Grid item xs={12} md={2.6}>
              <Box
                sx={{
                  border: "1px solid #ebeff5",
                  borderRadius: 1,
                  p: 2,
                  mb: 2,
                  height: "95%",
                  width: "100%",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <Avatar
                  sx={{
                    width: "10rem",
                    height: "10rem",
                    bgcolor: "#ebeff5",
                    color: "#000",
                    borderRadius: 0,
                    mb: 1,
                  }}
                  src={`${CDN_URL}${supplierDetails?.logo}`}
                >
                  {!supplierDetails?.logo && <PersonIcon />}
                </Avatar>
              </Box>
            </Grid>

            {/* Left Side */}
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  border: "1px solid #ebeff5",
                  borderRadius: 1,
                  p: 2,
                  mb: 2,
                  height: "95%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "1.2rem",
                      fontWeight: "600",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {supplierDetails?.business_name}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ cursor: "pointer", width: "100%" }}
                  >
                    <Box
                      component="span"
                      sx={{
                        width: "160px",
                        display: "inline-block",
                        fontWeight: "bold",
                        verticalAlign: "top",
                      }}
                    >
                      Claimed By:
                    </Box>
                    <Box
                      component="span"
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        display: "inline-block",
                        maxWidth: "calc(100% - 160px)", // Adjusts width based on the label's width
                      }}
                      onClick={handleOpenModal}
                    >
                      {supplierDetails?.claimed_by?.name ? (
                        <span
                          style={{
                            color: "#008080",
                            textDecoration: "underline",
                          }}
                        >
                          {supplierDetails?.claimed_by?.name}
                        </span>
                      ) : (
                        <span>--</span>
                      )}
                    </Box>
                  </Typography>
                  <Typography variant="body1">
                    <Box
                      component="span"
                      sx={{
                        width: "160px",
                        display: "inline-block",
                        fontWeight: "bold",
                      }}
                    >
                      Claimed On:
                    </Box>
                    <Box
                      component="span"
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {supplierDetails?.claimed_on
                        ? moment(supplierDetails?.claimed_on)?.format(
                            "DD-MM-YYYY"
                          )
                        : "--"}
                    </Box>
                  </Typography>
                  <Typography variant="body1">
                    <Box
                      component="span"
                      sx={{
                        width: "160px",
                        display: "inline-block",
                        fontWeight: "bold",
                      }}
                    >
                      Verified On:
                    </Box>
                    <Box
                      component="span"
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {supplierDetails?.verified_at
                        ? moment(supplierDetails?.verified_at)?.format(
                            "DD-MM-YYYY"
                          )
                        : "--"}
                    </Box>
                  </Typography>
                  <Typography variant="body1" sx={{ width: "100%" }}>
                    <Box
                      component="span"
                      sx={{
                        width: "160px",
                        display: "inline-block",
                        fontWeight: "bold",
                        verticalAlign: "top",
                      }}
                    >
                      Applications:
                    </Box>
                    <Tooltip
                      title={supplierDetails?.vehicle_category
                        ?.map((category: any) => category.name)
                        .join(", ")}
                    >
                      <Box
                        component="span"
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          display: "inline-block",
                          maxWidth: "calc(100% - 160px)", // Adjusts width based on the label's width
                        }}
                      >
                        {supplierDetails?.vehicle_category
                          ?.map((category: any) => category.name)
                          .join(", ")}
                      </Box>
                    </Tooltip>
                  </Typography>

                  <Typography variant="body1" sx={{ width: "100%" }}>
                    <Box
                      component="span"
                      sx={{
                        width: "160px",
                        display: "inline-block",
                        fontWeight: "bold",
                        verticalAlign: "top",
                      }}
                    >
                      Part Type:
                    </Box>

                    <Tooltip
                      title={supplierDetails?.bom_category
                        ?.map((category: any) => category.name)
                        .join(", ")}
                    >
                      <Box
                        component="span"
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          display: "inline-block",
                          maxWidth: "calc(100% - 160px)", // Adjusts width based on the label's width
                        }}
                      >
                        {supplierDetails?.bom_category
                          ?.map((category: any) => category.name)
                          .join(", ")}
                      </Box>
                    </Tooltip>
                  </Typography>
                </Box>
              </Box>
            </Grid>

            {/* Right Side */}
            <Grid item xs={12} md={6.4}>
              <Box
                sx={{
                  border: "1px solid #ebeff5",
                  borderRadius: 1,
                  p: 2,
                  height: "95%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                  <Typography variant="body1" sx={{ width: "49%" }}>
                    <Box
                      component="span"
                      sx={{
                        width: "80%",
                        display: "inline-block",
                        fontWeight: "bold",
                      }}
                    >
                      Total No. of Parts:
                    </Box>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => PartsModal.open()}
                    >
                      {supplierDetails?.total_parts || `0`}
                    </span>
                  </Typography>
                  <Typography variant="body1" sx={{ width: "49%" }}>
                    <Box
                      component="span"
                      sx={{
                        width: "80%",
                        display: "inline-block",
                        fontWeight: "bold",
                      }}
                    >
                      Parts Added by xcPROC:
                    </Box>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => PartsModal.open()}
                    >
                      {supplierDetails?.parts_added_by_asi || `0`}
                    </span>
                  </Typography>
                  <Typography variant="body1" sx={{ width: "49%" }}>
                    <Box
                      component="span"
                      sx={{
                        width: "80%",
                        display: "inline-block",
                        fontWeight: "bold",
                      }}
                    >
                      Parts Added by Seller:
                    </Box>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => PartsModal.open()}
                    >
                      {supplierDetails?.parts_added_by_seller || `0`}
                    </span>
                  </Typography>

                  <Typography variant="body1" sx={{ width: "49%" }}>
                    <Box
                      component="span"
                      sx={{
                        width: "80%",
                        display: "inline-block",
                        fontWeight: "bold",
                      }}
                    >
                      Parts Without MSP:
                    </Box>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => PartsModal.open()}
                    >
                      {supplierDetails?.parts_without_msp || `0`}
                    </span>
                  </Typography>

                  <Typography variant="body1" sx={{ width: "49%" }}>
                    <Box
                      component="span"
                      sx={{
                        width: "80%",
                        display: "inline-block",
                        fontWeight: "bold",
                      }}
                    >
                      Add Part Requests:
                    </Box>
                    <span
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={() => {
                        const url = `/#/requestPage/4/0/0`;
                        window.open(url, "_blank");
                      }}
                    >
                      {supplierDetails?.add_part_requests || `0`}
                    </span>
                  </Typography>

                  <Typography variant="body1" sx={{ width: "49%" }}>
                    <Box
                      component="span"
                      sx={{
                        width: "80%",
                        display: "inline-block",
                        fontWeight: "bold",
                      }}
                    >
                      Edit Part Requests:
                    </Box>
                    <span
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={() => {
                        const url = `/#/requestPage/4/0/0`;
                        window.open(url, "_blank");
                      }}
                    >
                      {supplierDetails?.edit_part_requests || `0`}
                    </span>
                  </Typography>

                  <Typography variant="body1" sx={{ width: "49%" }}>
                    <Box
                      component="span"
                      sx={{
                        width: "80%",
                        display: "inline-block",
                        fontWeight: "bold",
                      }}
                    >
                      Parts with Main Costing Validated:
                    </Box>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => PartsModal.open()}
                    >
                      {supplierDetails?.known_parts_with_main_costing_validated ||
                        `0`}
                    </span>
                  </Typography>

                  <Typography variant="body1" sx={{ width: "49%" }}>
                    <Box
                      component="span"
                      sx={{
                        width: "80%",
                        display: "inline-block",
                        fontWeight: "bold",
                      }}
                    >
                      Parts with Scenario Costing:
                    </Box>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => PartsModal.open()}
                    >
                      {supplierDetails?.known_parts_with_scenario_costing_done ||
                        `0`}
                    </span>
                  </Typography>

                  <Typography variant="body1" sx={{ width: "49%" }}>
                    <Box
                      component="span"
                      sx={{
                        width: "80%",
                        display: "inline-block",
                        fontWeight: "bold",
                      }}
                    >
                      Parts without Main Costing Validated:
                    </Box>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => PartsModal.open()}
                    >
                      {supplierDetails?.known_parts_without_main_costing_validated ||
                        `0`}
                    </span>
                  </Typography>

                  <Typography variant="body1" sx={{ width: "49%" }}>
                    <Box
                      component="span"
                      sx={{
                        width: "80%",
                        display: "inline-block",
                        fontWeight: "bold",
                      }}
                    >
                      Parts without Scenario costing:
                    </Box>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => PartsModal.open()}
                    >
                      {supplierDetails?.known_parts_without_scenario_costing ||
                        `0`}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Grid>

            {/* Modal */}
            <BusinessOwnerDetailsModal
              openModal={openModal}
              handleCloseModal={handleCloseModal}
              userId={39}
              userDetails={supplierDetails?.claimed_by}
            />
          </Grid>

          <SupplierContact
            spoc_information={supplierDetails?.spoc_information}
          />

          <SupplierBusinessInfo supplierDetails={supplierDetails} />

          <SupplierFinancialData supplierDetails={supplierDetails} />

          <SupplierDirectors supplierDetails={supplierDetails} />
          <SupplierLocations supplierDetails={supplierDetails} />
        </>
      )}

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "1rem",
        }}
      ></Box>

      {value === 1 && (
        <OwnPartList
          setPartCount={setPartCount}
          partCount={partCount}
          value={value}
          supplierDetails={supplierDetails}
          getSupplierDetailsByID={getSupplierDetails}
          show_status={"Full"}
        />
      )}

      {value === 2 && (
        <OwnPartList
          setPartCount={setPartCount}
          value={value}
          partCount={partCount}
          supplierDetails={supplierDetails}
          getSupplierDetailsByID={getSupplierDetails}
          show_status={"Full"}
        />
      )}

      {value === 3 && <EmailLogs />}
    </Box>
  );
}

export default SupplierUI;
