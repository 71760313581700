import TableContainer from "@mui/material/TableContainer";
import { Card, CardMedia,Modal, Box, Divider, Table, TableHead, TableBody, TableRow, TableCell, ButtonProps, Skeleton, } from "@mui/material";
import Typography from "@mui/material/Typography";
import CancelIcon from "@mui/icons-material/Cancel";
import RViewerJS from "viewerjs-react";
import { useEffect, useState } from "react";
import { API } from "../../api-services";
import styles from './ViewBOMImagesModal.module.scss';
import { useParams } from "react-router-dom";
import NoImgLoader from '../../Assets/images/loading-screen.gif';
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";


export interface IAssignCostModeltoAddedMaterialsModalProps {
  onCloseModal: any;
  isOpen: any;
  vault: any;
  treeabbr: any;
  topVault: any;
  dataAll: any;
 
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "96vw",
  heigth: "80vh",
  bgcolor: "background.paper",
  // backgroundColor: "primary.light",
  borderRadius: "10px",
  p: 1,
};

export function ViewBOMImagesModal(
  props: IAssignCostModeltoAddedMaterialsModalProps
) {
  const { onCloseModal, isOpen, topVault, vault, treeabbr,dataAll  } =
    props;
    const [LoaderImages, setLoaderImages] = useState<any>(false);

    const [BomImages, setBomImages] = useState<any>(null);
    const [srcImages, setSrcImages] = useState<any>();
    const { height, width } = useWindowDimensions();

    const { projectId,leafNode } = useParams<any>();

    var NoImagesData = () => {

      var valuedata = BomImages && BomImages["three_sixity"] && BomImages["three_sixity"][0];
  
      var lengthObj = valuedata && Object?.keys(valuedata)?.length
      return lengthObj;
    }
   
    const toggleTopvault = () => {
      if (dataAll?.abbreviation == "product") {
        return { top_vault: dataAll?.id };
      } else {
        return { vault: dataAll?.id };
      }
    };

    
    useEffect(() => {

      setLoaderImages(true)
      setBomImages(null);
      API.get("/cost/Bom_view_data/", {
        ...toggleTopvault(),
      }, 0)
        .then((res: any) => {
          setLoaderImages(false)
          // console.log(res.data, "Bom_view_data");
          setBomImages(res?.data);
  
        })
        .catch((err: any) => {
          // ADMIN.toast.throwError(err, "Server error in Bom module");
          setBomImages(null);
  
          setLoaderImages(false)
        });
  
    }, [dataAll])


    const BomImagesData = (ImageItem: any) => {
      let entries = BomImages && Object.entries(ImageItem);
      // height: 17.9rem;
      let data =
        BomImages &&
        entries.map(([key, val]: any) => {
          return (
            <Card sx={{ boxShadow: 'none' }}
              className={styles.bomImageCardImage}
            // sx={{ width: "100%", height: NoImagesData() == 0 ? "16.9rem" : NoducmentData() != 0 ? "115px" : "115px" }}
            >
              {/* <CardMedia
                component="img"
                height="100%"
                src={val}
                alt={key}
                title={key}
                sx={{ objectFit: 'contain', cursor: "pointer" }}
              /> */}
             {/* <img src={val} style={{ objectFit: 'contain', cursor: "pointer" }}/> */}
              <img src={val} placeholder={NoImgLoader} alt={key} title={key} style={{ objectFit: 'contain', cursor: "pointer", height: width < 1370 ? "100px" : '140px' }} />
            </Card>
          );
        });
      return data;
    };

 

  return (
    <div>
      

      <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                // textAlign: "center",
                marginLeft: "1rem",
              }}
            >
              BOM Images
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
              // onClick={() => {
              //   API.post(
              //     "/cost/cost_calculator/",
              //     {
              //       material: true,
              //       cost_estimation: costDetailsData?.cost_estimation,
              //     },
              //     {},
              //     0
              //   )
              //     .then((res: any) => { })
              //     .catch((err: any) => { });
              //   onCloseModal();
              // }}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "0.5rem",
            }}
          />
          <Box sx={{ padding: "0 1rem" }}>
            <Box className={styles.BOMImageModal}>

            {LoaderImages ? <>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          //  height: "20rem",
                        }}>

                        <Box
                          sx={{
                            display: "flex",
                            columnGap: "3rem",
                            flexDirection: "row",
                            height: "8rem",
                            marginTop: '0rem',
                            padding: '0 0.5rem'
                          }}
                        >
                          <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                          <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                          <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            columnGap: "3rem",
                            flexDirection: "row",
                            height: "8rem",
                            marginTop: '0rem',
                            padding: '0 0.5rem'
                          }}
                        >
                          <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                          <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                          <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            columnGap: "3rem",
                            flexDirection: "row",
                            height: "8rem",
                            marginTop: '0rem',
                            padding: '0 0.5rem'
                          }}
                        >
                          <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                          <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                          <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            columnGap: "3rem",
                            flexDirection: "row",
                            height: "8rem",
                            marginTop: '0rem',
                            padding: '0 0.5rem'
                          }}
                        >
                          <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                          <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                          <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                        </Box></Box>
                    </> :
                      <>
                        <Box sx={{ width: '100%' }}>
                          {/* @ts-ignore */}
                          <RViewerJS>
                            {BomImages &&
                              BomImages["image"].map((BomImages: any, indx: any) => {
                                return (
                                  <div
                                    // className={NoImagesData() == 0 ? styles.BomImageNoImage : NoducmentData() != 0 ? styles.BomImage : styles.BomImageNoDoc}
                                    className={styles.BomImage}
                                    adl-scrollbar="true"
                                    adl-scrollbar-width="0.3"
                                  >
                                    {BomImagesData(BomImages)}
                                  </div>
                                );
                              })}
                          </RViewerJS>
                          {/* <Divider sx={{ margin: "1rem", display: NoImagesData() !== 0 ? "block" : NoImagesData() != 0 ? "block" : "none" }} />
                          <div
                            style={{ display: BomImages && BomImages["three_sixity"]?.map((images: any, index: any) => Object?.keys(images)?.length > 0 ? "block" : "none") }}
                          >

                            {BomImages &&
                              BomImages["three_sixity"]?.map((images: any, index: any) => {
                                return (
                                  <>
                                    <div
                                      className={NoducmentData() == 0 ? styles.NoDocmentThreeSixty : styles.BomImagethreeSixty}
                                      adl-scrollbar="true"
                                      adl-scrollbar-width="0.3"
                                    >
                                    
                                      {ThreeSixityCard(images)}
                                    </div>
                                  </>
                                );
                              })}
                          </div> */}

                          
                          {/* <Divider sx={{ margin: "1rem", display: NoducmentData() !== 0 ? "block" : NoducmentData() != 0 ? "block" : "none" }} />

                          {BomImages && BomImages["document"] && NoducmentData() != 0 &&
                            <div className={styles.documentSec}>
                              <TableContainer>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell sx={{ padding: '0.2rem 0.5rem' }}>Parameter</TableCell>
                                      <TableCell sx={{ padding: '0.2rem 0.5rem', width: '20rem' }}>Document</TableCell>
                                      <TableCell sx={{ padding: '0.2rem 0.5rem', width: '5rem' }}>Type</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {ReportRow()}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>} */}
                        </Box>



                      </>
                    }
              
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
