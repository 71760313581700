// react

// vendors
// services    "immutability-helper": "~3.0.1",
// utils
// constants
// factories
// core
// redux
// ui-components
// others
// styles
import styles from "../../../ComponentUI/IdeaCreate/IdeaView/IdeaCalculatorValueInputModal.module.scss";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import Box from '@mui/material/Box/Box';
import { Button, Divider, Modal, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { CALCULATOR_INPUT_CONTENT_TYPE, CALCULATOR_INPUT_TYPE, InputContentType } from "../../../Configuration/Costing/calculator.constant";
import { ID } from "../../../utlis/commonInterfaces";
import { bomcalculatorInput } from "../../../Redux/Reducers/bomCalculatorInput.reducer";
import { debounce, isNull, isNumber } from "lodash";
import { useCollection } from "../../../CustomHook/useCollection";
import { useEffect, useState } from "react";
import { UserInput, userInputSelectors } from "../../../Redux/Reducers/userInput.reducer";
import { useDispatch, useSelector } from "react-redux";

import { triangulationSelectors } from "../../../Redux/Reducers/triagulation.reducer";
import { API } from "../../../api-services";
import update from "immutability-helper";
import React from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import CalculatorInputRow from "../../CostingCreateModule/CostingTriangulation/CalculatorInputRow";
import { useQueryDispatch } from "../../../CustomHook/useQueryDispatch";
import { ADMIN } from "../../../Redux/Services/admin.service";
import { BOMuserInputSelectors } from "../../../Redux/Reducers/BOMuserInput.reducer";
import { getUserInputsTriangulation, resetUserInputs, updateUserInputTriangulation } from "../../../Redux/Actions/BOMuserInput.actions";
import BOMCalculatorInputRow from "./BOMCalculatorInputRow";
// interfaces and types
// lazy

// ----------------------------------------------------------------------------------
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "60rem",
    bgcolor: 'background.paper',
    borderRadius: '10px',
    p: 1,
};
interface CalculatorValueInputModalProps {
    isOpen: any;
    onClose: any;
    fetchCalculator: any;
    calculatorData: any;
    CalculatorItem: any;
    setCalcData?: any;
    count?: any;
    setCount?: any;
    calcName?: any;
    BOMValidationStatus?: any;

}

interface BOMCalculatorInputFromApi {
    id: ID;
    content_type: InputContentType;
    name: string;
    /** string used to refere the input in the formula */
    alias: string;

    parameter?: ID;
    table_element?: ID;

    attribute?: string;

    formula?: string;
    constant?: number;
    user_input?: string;
    calculator_category?: number;
    volume?: number;
    calculator: ID;
    image_url?: string;
    test_input?: number;
    result?: number;
}



export const transformCalculatorInputFromApi = (input: BOMCalculatorInputFromApi): bomcalculatorInput => {
    const { content_type, ...rest } = input;
    const obj: any = {};
    if (content_type) {
        if (rest.parameter) {
            if (content_type === CALCULATOR_INPUT_CONTENT_TYPE.BOM_PARAMETER) obj.type = CALCULATOR_INPUT_TYPE.BOM_PARAMETER;
            if (content_type === CALCULATOR_INPUT_CONTENT_TYPE.BOM_TABLE_CALCULATOR) obj.type = CALCULATOR_INPUT_TYPE.BOM_TABLE_CALCULATOR;
            if (content_type === CALCULATOR_INPUT_CONTENT_TYPE.COMMODITY_PARAMETER) obj.type = CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER;
        }
        else if (rest.attribute) {
            if (content_type === CALCULATOR_INPUT_CONTENT_TYPE.COST_INPUT) {
                obj.type = CALCULATOR_INPUT_TYPE.COST_INPUT;
            }
            else if (content_type === CALCULATOR_INPUT_CONTENT_TYPE.CALCULATOR_RESULT) {
                obj.type = CALCULATOR_INPUT_TYPE.CALCULATOR_RESULT;
            }
        }
    }
    if (rest.formula) obj.type = CALCULATOR_INPUT_TYPE.FORMULA;
    else if (!isNull(rest.user_input)) obj.type = CALCULATOR_INPUT_TYPE.USER_INPUT;
    if (rest.constant) obj.type = CALCULATOR_INPUT_TYPE.CONSTANT;
    if (rest.volume) obj.type = CALCULATOR_INPUT_TYPE.VOLUME;

    return Object.assign({}, rest, obj);
};

const BOMCalculatorValueInputModal: React.FC<CalculatorValueInputModalProps> = (props) => {

    const { fetchCalculator, calculatorData, isOpen, onClose, setCalcData, count, CalculatorItem, setCount, calcName, BOMValidationStatus } = props;

    const inputs = useCollection<bomcalculatorInput>();
    const [fetchCalculatorData, setFetchCalculatorData] = useState<any>(calculatorData);

    const [data, setData] = useState<UserInput>();
    const { workstation, topVault, vault, projectId } = useRouteParams<any>();
    const dispatch = useDispatch();


    // React.useEffect(() => {
    //     BOMValidationStatus()
    // }, [fetchCalculatorData]);


    // useEffect(() => {
    //     if (fetchCalculator == "TRUE") {
    //         inputs.setAll(calculatorData && calculatorData[0]?.calculator_fields.map(transformCalculatorInputFromApi).sort((a: any, b: any) => a.sequence - b.sequence));
    //     }
    // }, [fetchCalculator]);



    const userInputsFetched = useQueryDispatch({
        query: { action: getUserInputsTriangulation, arg: { cardCostId: calculatorData && calculatorData[0]?.id }, condition: fetchCalculator === "TRUE" },
        cleanup: { action: resetUserInputs, args: [] },
        dependency: [fetchCalculator == "TRUE", isOpen],
    });


    const userInput:any = useSelector(BOMuserInputSelectors.selectAll);


    useEffect(() => {

        if (userInput) setData(userInput[0]);
    }, [userInput]);


    const handleUserInputValueChange = (inputId:ID,value:any) => {

        let calcUserInput = calculatorData?.map((item: any) => {
            if (item.content_type == null && item.constant == null && item.formula == null ) {
                if (item.id == inputId) {
                    return { [item?.id]: value }
                }
                else {
                    return { [item.id]: item.value }
                }
            }
        });
        calcUserInput = calcUserInput.filter((item: any) => item != undefined)
        calcUserInput = Object.assign({}, ...calcUserInput);
        const updatedValue = {
            user_input: {
                ...calcUserInput
            }
        }

        // if (!data) return;
        // const updated = update(calculatorData, {
        //     user_input: {
        //         [inputId]: { $set: value }
        //     }
        // });
        ExactDebounce(updatedValue, calculatorData)
        // setData(updated);
    };

    const getObjKeyName = () => {
        if (vault == 0) {
            return { top_vault: topVault };
        } else {
            return { vault: vault };
        }
    };



    const ExactDebounce = React.useRef(
        debounce(async (values: any, cal: any) => {
            if (!values) return;
            // const { id } = values;
            API.put(`/bom/bom_entry_calculator/${CalculatorItem}/`, { ...values }, { ...getObjKeyName }, 0)
                .then((res: any) => {
                    // setCalcData(res.data);
                    
                    API.get(`/bom/bom_entry_calculator/`,
                        {
                            calculation_id: CalculatorItem,
                            ...getObjKeyName(),
                        },
                        0)
                        .then((res: any) => {
                            setCalcData(res.data);
                            setFetchCalculatorData(res.data);
                            ADMIN.toast.success("User Input Updated Successfully");
                            
                        })
                        .catch((err: any) => {
                            console.log(err);
                        });
                        // fetchCalculator(CalculatorItem)
                    // setCount((prev: any) => prev + 1);
                })
                .catch((err: any) => {
                    console.error(err);
                });
            // const action: any = await dispatch(updateUserInputTriangulation({ id: values?.id, calculator: cal && cal[0].id, updates: values }));
            // 
            // if (updateUserInputTriangulation.fulfilled.match(action)) {
            //     setCount((prev: any) => prev + 1);
            // }
        }, 500)
    ).current;

    const renderRow = (inputCalculationId: any) => {
        // NOTE: inputCalculationId & inputId are same
        return (
            <BOMCalculatorInputRow
                inputCalculationData={inputCalculationId}
                userInputValue={data?.user_input[inputCalculationId.id]}
                onValueChange={handleUserInputValueChange}
                resultValue={data?.result[inputCalculationId.alias]}
                inputId={inputCalculationId.id}
            />
        );
    };


    const handleSave = async () => {
        if (!data) return;
        const { id } = data;
        const action: any = await dispatch(updateUserInputTriangulation({ id: id, calculator: calculatorData && calculatorData[0].id, updates: data }));
        // 
        // if (updateUserInputTriangulation.fulfilled.match(action)) {
        //     setCount(count + 1);
        // }

    };

    const closeHandler = () => {


        setCount((prev: any) => prev + 1)
        // BOMValidationStatus();
        onClose()


    }

    return (
        <div
            data-testid="calculator-value-input-modal"
            className={styles.container}
        >
            <Modal
                open={isOpen}
                onClose={onClose}

                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
                        <Typography
                            style={{
                                fontSize: "1rem",
                                fontWeight: "600",
                                width: "100%",
                                textAlign: "center",
                                marginRight: '-2rem'
                            }}
                        >
                            {calcName} - Calculation
                        </Typography>
                        <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={() => closeHandler()} />
                    </Box>
                    <Divider
                        sx={{
                            borderColor: "primary.light",
                            margin: "0 1rem",
                            marginBottom: "1rem",
                        }}
                    />
                    <Box sx={{ maxHeight: '80vh' }}
                        adl-scrollbar="true"
                        adl-scrollbar-width="0.3">
                        {fetchCalculator == "FETCHING" ? <><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton></>
                            :
                            <Table stickyHeader aria-label="sticky table" className={styles.tableStyle}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', backgroundColor: "primary.light" }}>Input</TableCell>
                                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', backgroundColor: "primary.light" }}>Value</TableCell>
                                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', backgroundColor: "primary.light", textAlign: 'center', }} >Unit</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {fetchCalculatorData.map(renderRow)}
                                </TableBody>
                                
                            </Table>
                        }
                    </Box>
                </Box>
            </Modal>

        </div>
    );
};

// ----------------------------------------------------------------------------------

export default BOMCalculatorValueInputModal;