import React from 'react';
import { Box, Divider, Modal } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, {
	stepConnectorClasses,
} from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import PersonIcon from '@mui/icons-material/Person';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import TaskRoundedIcon from '@mui/icons-material/TaskRounded';
import ModelTrainingRoundedIcon from '@mui/icons-material/ModelTrainingRounded';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

export interface IScenarioRequestStatusModalProps {
	isOpen: boolean;
	onCloseModal: () => void;
	status: any; // Assuming status contains information about the current stage
	// currentType: any;
	reOpen:any;
	rowData: any;
}

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: { lg: '95vw', xl: '90vw' },
	height: 'auto',
	bgcolor: 'background.default',
	borderRadius: '1rem',
	p: 1,
	focusVisible: 'none !important',
};

const ScenarioRequestStatusModal: React.FC<IScenarioRequestStatusModalProps> = (
	props
) => {
	const { isOpen, onCloseModal, status, reOpen,rowData } = props;
	
	const [activeStep, setActiveStep] = React.useState(0);

	const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
		[`&.${stepConnectorClasses.alternativeLabel}`]: {
			top: 22,
		},
		[`&.${stepConnectorClasses.active}`]: {
			[`& .${stepConnectorClasses.line}`]: {
				backgroundColor: '#00887a',
				// backgroundImage:
				// 	'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
			},
		},
		[`&.${stepConnectorClasses.completed}`]: {
			[`& .${stepConnectorClasses.line}`]: {
				backgroundColor: '#00887a',
				// backgroundImage:
				// 	'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
			},
		},
		[`& .${stepConnectorClasses.line}`]: {
			height: 3,
			border: 0,
			backgroundColor:
				theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
			borderRadius: 1,
		},
	}));

	const ColorlibStepIconRoot = styled('div')<{
		ownerState: { completed?: boolean; active?: boolean };
	}>(({ theme, ownerState }) => ({
		backgroundColor:
			theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
		zIndex: 1,
		color: '#fff',
		width: 50,
		height: 50,
		display: 'flex',
		borderRadius: '50%',
		justifyContent: 'center',
		alignItems: 'center',
		...(ownerState.active && {
			backgroundColor: '#00887a',
			// backgroundImage:
			// 	'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
			boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
		}),
		...(ownerState.completed && {
			backgroundColor: '#00887a',
			// backgroundImage:
			// 	'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
		}),
	}));

	function ColorlibStepIcon(props: StepIconProps) {
		const { active, completed, className } = props;

		const icons: { [index: string]: React.ReactElement } = {
			1: <PersonIcon />,
			2: <AttachMoneyRoundedIcon />,
			3: <AutoModeIcon />,
			4: <CheckCircleIcon />,			
		};

		return (
			<ColorlibStepIconRoot
				ownerState={{ completed, active }}
				className={className}>
				{icons[String(props.icon)]}
			</ColorlibStepIconRoot>
		);
	}

	function ColorlibStepIconRejected(props: StepIconProps) {
		const { active, completed, className } = props;

		const icons: { [index: string]: React.ReactElement } = {
			1: <PersonIcon />,
			2: <RequestQuoteIcon />,
			3: <DoNotDisturbIcon />,
		};
		return (
			<ColorlibStepIconRoot
				ownerState={{ completed, active }}
				className={className}>
				{icons[String(props.icon)]}
			</ColorlibStepIconRoot>
		);
	}

	let steps = [];
	if (status === 'Rejected') {
		steps = ['Request Received', 'Quote Sent', 'Rejected'];
	} else {
		steps = [
			'Request Received',		
			'Payment Received',
			'In Progress',
			'Published',			
		];
	}	



	React.useEffect(() => {
		// Update the active step based on the status
		if (status === 'Rejected' && reOpen == false) {
			// Update the active step based on the status
			if (status === 'Rejected') {
				setActiveStep(steps.length);
			} else if (status === 'Quote Sent') {
				setActiveStep(2);
			} else if (status === 'Request Received') {
				setActiveStep(1);
			} else {
				setActiveStep(0);
			}

		} else {
			if (status === 'Published') {
				setActiveStep(steps.length);				
			} else if (status === 'In Progress') {
				setActiveStep(2);
			} else if (status === 'Payment Received') {
				setActiveStep(1);
			} else if (status === 'Request Received') {
				setActiveStep(0);
			}
		}
		
	}, [status]);

	const getRejectedLabel = (label: string) => {
		if (label === 'Request Received') {
			return 'Costing Request Received - Quotation In Progress';
		} else if (label === 'Quote Sent') {
			return 'Quotation Sent - Payment In Progress';
		} else if (label === 'Rejected') {
			return 'Payment Not Done - Request Rejected by ASI';
		}
	};

	const getLabel = (label: string) => {
		if (label === 'Request Received') {
			return 'Costing Request Received - Yet To Pay';		
		} else if (label === 'Payment Received') {
			return  <>{rowData?.reOpen ? <Typography sx={{fontSize:'0.87rem'}}>Payment Received - Yet to Start Costing <br/ ><span style={{color:'red'}}>Old Costing Deleted</span></Typography> : 'Payment Received - Yet to Start Costing'} </>;
		} else if (label === 'Costing Inprogress') {
			return 'Costing In Progress';		
		} else if (label === 'Published') {
			return 'Costing Completed & Published';
		}
	};

	return (
		<>
			<Modal
				open={isOpen}
				onClose={onCloseModal}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'>
				<Box sx={style}>
					<Box sx={{ width: '100%' }}>
						<Box
							sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
							<Typography
								style={{
									fontSize: '1rem',
									fontWeight: '600',
									width: '100%',
									textAlign: 'center',
								}}>
								Costing Request Status 
							</Typography>
							<CancelIcon
								sx={{
									cursor: 'pointer',
									marginLeft: '-2rem',
								}}
								onClick={onCloseModal}
							/>
						</Box>
						<Divider
							sx={{
								borderColor: 'primary.light',
								margin: '0 1rem',
								marginBottom: '1rem',
							}}
						/>
						{status != 'Rejected' ? (
							<Stack sx={{ width: '100%' }} spacing={4}>
								<Stepper
									alternativeLabel
									activeStep={activeStep}
									connector={<ColorlibConnector />}>
									{steps.map((label) => (
										<Step
											key={label}
											sx={{
												'span.MuiStepLabel-label.Mui-disabled': {
													color: '#ccc',
												},
												'span.MuiStepLabel-label.Mui-active.Mui-completed': {
													color: 'primary.main',
												},
											}}>
											<StepLabel StepIconComponent={ColorlibStepIcon}>
												{/* {label} */}
												{getLabel(label)}
											</StepLabel>
										</Step>
									))}
								</Stepper>
							</Stack>
						) : (
							<Stack sx={{ width: '100%' }} spacing={4}>
								<Stepper
									alternativeLabel
									activeStep={activeStep}
									connector={<ColorlibConnector />}>
									{steps.map((label) => (
										<Step
											key={label}
											sx={{
												'span.MuiStepLabel-label.Mui-disabled': {
													color: '#ccc',
												},
												'span.MuiStepLabel-label.Mui-active.Mui-completed': {
													color: 'primary.main',
												},
											}}>
											<StepLabel StepIconComponent={ColorlibStepIconRejected}>
												{getRejectedLabel(label)}
											</StepLabel>
										</Step>
									))}
								</Stepper>
							</Stack>
						)}
					</Box>
				</Box>
			</Modal>
		</>
	);
};

export default ScenarioRequestStatusModal;
