import { Box, CircularProgress, TextField, debounce } from '@mui/material';
import * as React from 'react';
import { API } from '../../../api-services';
import { ADMIN } from '../../../Redux/Services/admin.service';
import Swal from 'sweetalert2';
import { useRouteParams } from '../../../ui-reusable-component/useRouteParams';
import { useSelector } from 'react-redux';
import { customValidationSelectors } from '../../../Redux/Reducers/customValidation.reducer';
import { parameterConfigSelectors } from '../../../Redux/Reducers/parameterConfig.reducer';
import { workstationSelectors } from '../../../Redux/Reducers/workstation.reducer';
import { RootState } from '../../../Redux/Store/storeConfigurations';
import validateValues from '../validateValues';
import { ID } from '../../../utlis/commonInterfaces';
import { customValidate } from '../../../utlis/CustomValidate';
import FormError from '../../../Core/FromError';
import { userPermissionAction } from './BOMEntryLandingPage';

export interface INumberInputBoxProps {
  item: any,
  details: any,
  BOMValidationStatus: any;
  allValues: any,
  getAllValues: any
  modalType?:any;
  vaultpropId?:any;
}


export function TextInputBox(props: INumberInputBoxProps) {
  const { item, details, BOMValidationStatus,allValues,getAllValues,modalType ,vaultpropId } = props;
  const { vault, workstation, topVault, copyStatus } = useRouteParams<any>();
  const userAction = React.useContext(userPermissionAction)
  let UserAction = userAction && userAction?.action
  const [value, setValue] = React.useState<any>('');
  const [oldValue,setOldValue] = React.useState<any>('');
  const [loading, setLoading] = React.useState<any>(false)
  const [fieldError, setFieldError] = React.useState<any>(undefined);
  const customValidations = useSelector(customValidationSelectors.selectAll);
  const parameterConfigs = useSelector(
    parameterConfigSelectors.selectNonImageNonTableChildernConfigs
  );

  const workstationList = useSelector((state: RootState) => {
    return workstationSelectors.selectById(state, +workstation || 0);
  });


  const getObj = (vlt: any) => {
    // if (vlt == 0) {
    //   return { top_vault: topVault }
    // } else {
      return { vault: vlt }
    // }
  }
 


  const configToDbMap = parameterConfigs.reduce((result, config) => {
    result[config.id] = config.parameter_id;
    return result;
  }, {} as Record<string, ID>)

  const configToDataTypeMap = parameterConfigs.reduce((result, config) => {
    result[config.id] = config.data_type;
    return result;
  }, {} as Record<string, string>);
  const categoryIncludedInWorkstion = workstationList
    ? workstationList.categories.includes(1)
    : false;

  const allInputData = parameterConfigs.filter(data => data.data_type !== 'table').filter(data => data.data_type !== 'document');
  const allowedParameters = categoryIncludedInWorkstion
    ? workstationList?.allowed_parameters
    : undefined;

  var errors = validateValues(
    allInputData,
    { [item?.key]: value },
    allowedParameters,
    "parameter_id"
  );
  React.useEffect(() => {
    setValue(item?.value)
    setOldValue(item?.value)
  }, [item])

  const validateField = (parameter_id: ID, value?: any) => {
    const val = value ?? { [item?.key]: value }[parameter_id];
    let error: string | undefined;
    const config: any = parameterConfigs.find(
      config => config.parameter_id === parameter_id
    );

    if (config)
      error = validateValues([config], { [parameter_id]: val })[
        parameter_id
      ];

    // custom validations
    const relation = customValidations?.find(
      relation => relation.parameter_id === parameter_id
    );

    allValues[item?.key] = value

    if (relation)
      error = customValidate(
        relation.parameter_id,
        configToDataTypeMap,
        relation.validation,
        undefined,
        allValues,
        details,
        configToDbMap
      );
    setFieldError(error!);
  };

  const updateDataVaultParams = async (name: any, val: any, vlt: any) => {
    setLoading(true)
    API.put(`/bom/component_details/74699/`, {
      [name]: val,
    }, {
      ...getObj(vlt),
    }, 0).then((res: any) => {
      setOldValue(val)
      setLoading(false)
      BOMValidationStatus();
      ADMIN.toast.info("Updated Successfully")
    }).catch((err: any) => {
      const { data } = err.response;
      setLoading(false)
      Swal.fire({
        title: "Error!",
        text: data[0],
        icon: "error",
        confirmButtonText: "Ok",
      });
    })
  }

  const ExactDebounceParameters = React.useRef(
    debounce(async (value: any, name: any, idd: any) => {
      await updateDataVaultParams(name, value, idd);
    }, 1000)
  ).current;

  const handleChangeNumber = (event: any) => {
    const { name, value } = event.target;
    validateField(item?.key, value);
    // ExactDebounceParameters(value, name, vault);
    setValue(value);

  }
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <TextField
          variant="standard"
          name={item?.key}
          disabled={((copyStatus == false || copyStatus == "false") && (UserAction?.includes("U") || UserAction?.includes("C"))) ? false : true}
          type="text"
          onChange={handleChangeNumber}
          value={value}
          placeholder="Enter Value"
          sx={{
            width:'100%',
            ".MuiInputBase-root.MuiInput-root": {
              fontSize: "12px",
              backgroundColor:'rgba(255,255,255,0.3)',
              paddingLeft:'0.3rem' ,
            },
            "& .MuiInput-underline:before": {
              borderBottomColor: "transparent",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "primary.light",
            },
          }}
          onBlur={(event: any) => {
            if(modalType=="modalform"){
              if(event.target.value !== oldValue){
                ExactDebounceParameters(event.target.value, event.target.name, vaultpropId)
                }
            }else{

              if(event.target.value !== oldValue){
                ExactDebounceParameters(event.target.value, event.target.name, vault)
                }
            }
           
          }}
        />
        {loading && <CircularProgress size={20} sx={{ margin: '5px' }} />}
      </Box>
      {fieldError && (
        <FormError error={fieldError} align="center" />
      )}
    </>
  );
}
