import { Box, Button, ButtonProps, Table, TableBody, TableCell, TableHead, TableRow, Typography, IconButton } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { API } from '../../api-services';
import Plotly from 'plotly.js';

import createPlotlyComponent from 'react-plotly.js/factory';
import useWindowDimensions from '../../utlis/useWindowDimensions';
import BouncingLoader from '../../ui-reusable-component/BouncingLoader';

import { DataGrid, GridColDef, GridCsvExportMenuItem, GridCsvExportOptions, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExportContainer, GridValueGetterParams } from "@mui/x-data-grid";


import RViewerJS from "viewerjs-react";
import Skeleton from '@mui/material/Skeleton';
import Image from "../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import { BomImageViewer } from "../BomImageViewer";
import BomCompareImg from "./BomCompareImg";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ReplyIcon from '@mui/icons-material/Reply';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Label,
  LabelList,
  CartesianGrid
} from "recharts";
import { numberFormat } from '../../utlis/valueFormat';
import styles from "./VehiclelevelCostCompare.module.scss";
import { useBaseModal } from '../../ComponentUI/SearchPage/useBaseModal';
import RidirectionModal from './RidirectionModal';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import CompareCostingGraphmodal from './CompareCostingGraphmodal';

const renderCustomizedLabel = (props: any) => {
  const { content, ...rest } = props;

  return <Label {...rest} fontSize="12" fill="#FFFFFF" fontWeight="Bold" />;
};




const csvOptions: GridCsvExportOptions = { fileName: 'Drive Table In CSV', delimiter: ",", utf8WithBom: true };

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: '2rem' }}>
    <span style={{ position: 'absolute', right: '0rem' }}>
      <GridToolbarColumnsButton />
      {/* <GridToolbarDensitySelector /> */}
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
)
type Props = {
  counts?:any
}
const Plot = createPlotlyComponent(Plotly);
export default function VehiclelevelCostCompare({ counts}: Props) {


  const { projectId, moduleType, topVaultId, abbr, NodeId, Count, TopId, Partname, Nodeleaf, depthNode, pathname } = useParams<any>();

  const { height, width } = useWindowDimensions();
  const [AllProductData, setAllProductData] = useState([]);
  const [CostingData, setCostingData] = useState([]);
  const [CostingLoader, setCostingLoader] = useState(false);
  const [CostingGraphData, setCostingGraphData] = useState<any>()
  const [LoaderImagePaagination, setLoaderImagePaagination] = useState(false)
  const [loaderImage, setLoaderImage] = useState(false);
  const [Imageslength, setImageslength] = useState()
  const [parameterName, setParameterName] = useState<any>([])

  const [CostingGraphLoader, setCostingGraphLoader] = useState<any>(false)
  const [PageCount, setPageCount] = useState<any>(1)
  const [imagesData, setImagesData] = useState<any>();
  const ridirectionopenVehicle = useBaseModal()
  const graphModal = useBaseModal();

  const topVaultsParam: any =
    topVaultId && typeof topVaultId == "string"
      ? topVaultId?.split(",")?.map(Number)
      : [topVaultId];

  var filterDataIds = topVaultsParam?.sort(function (a: any, b: any) { return a - b });
  // console.log(filterDataIds, "filterDataIds")



  React.useEffect(() => {
    if (projectId) {
      // setCostingLoader(true)
      // setLoaderProduct(true);
      API.get("/cost/compare_module/", {
        project: projectId,
        product_list: true,
        module: moduleType,
        depth: depthNode, path: pathname
      })
        .then((res: any) => {
          setAllProductData(res.data);
          // setCostingLoader(false)
          // setSelectedTopVaultIds([])
          // setLoaderProduct(false);
        })
        .catch((err: any) => {
          // setCostingLoader(false)
          // setLoaderProduct(false);
          setAllProductData([])
          // console.log("compare error");
        });
    }
  }, [projectId, Count,counts]);


  var selectedTopVaultData = AllProductData && AllProductData.filter((item: any, index: number) => topVaultsParam.includes(item?.id))

  let OrderData = _.orderBy(selectedTopVaultData, ['id'],
    ['asc', 'desc']);



  let OrderTopvaults = _.orderBy(topVaultsParam, ['asc', 'desc']);


  // console.log(OrderTopvaults, "OrderTopvaults")


  const togglePartName = (name: any) => {
    if (abbr == "product") {
      return { part_name: "product" }
    } else {
      return { part_name: name }
    }
  }



  useEffect(() => {
    setCostingLoader(true)
    API.get("/cost/compare_product_costing/", {


      vehicle_ids: filterDataIds.toString(),
      //   ...togglePartName(Partname),
      //   module: moduleType,
      //   is_leaf: Nodeleaf,
      //   depth: depthNode,path:pathname

    }).then((res: any) => {
      setCostingData(res?.data)
      setCostingLoader(false)
      // console.log(res.data, "resdata")
    }).catch((err: any) => {
      setCostingData([])
      setCostingLoader(false)
    })


  }, [Nodeleaf, NodeId, Count,counts])




  useEffect(() => {

    API.get("/cost/vehicle_level_activity/", {


      top_vault: filterDataIds.toString(),

      part_name: Partname

    })
  }, [])



  const TableRowCost = (rowitem: any) => {

    var FilterData = rowitem && rowitem?.map((item: any, id: any) => ({
      ...item,
      id: id + 1,
    }));

    return FilterData;
  };

  const topVaultColor: any = [
    "#075482",
    "#e14958",
    "#fe932b",
    "#64d0dc",
    "#33b1e4",
    "#ffcc67",
    "#8dc73f",
    "#8945aa",
    "#9fd3c7",
    "#385170",
  ];

  const OpenGraphModal = (item: any) => {

    graphModal.open()


    setCostingGraphLoader(true)
    API.get("/cost/compare_module/", {


      top_vaults: filterDataIds.toString(),
      ...togglePartName(Partname),
      module: moduleType,
      is_leaf: Nodeleaf,
      depth: depthNode,
      costing_graph: true, path: pathname,
      key: item,
      colors: topVaultColor
        ?.slice(0, topVaultId && topVaultId.length > 1 ? topVaultId?.split(",").length : filterDataIds.length)
        ?.toString(),

    }).then((res: any) => {
      setCostingGraphData(res?.data)
      setCostingGraphLoader(false)
      // console.log(res.data, "resdata").
    }).catch((err: any) => {

      setCostingGraphLoader(false)
    })
    setParameterName(item)

  }

  //   console.log(TableRow() ,"TableRow")

  const TableHeaderCost = (HedaerItem: any) => {



    var dataHeader: any = HedaerItem && Object.keys(HedaerItem)
    dataHeader.pop();

    dataHeader.unshift("Subsytem Name");
    console.log(dataHeader)
    let totalCost = 0;

    var valuedata = dataHeader &&
      dataHeader?.map((key: any, value: any) => {
        // console.log(key,"checkKey")
        return {
          field: key,
          headerName: key,
          maxWidth: 600,
          minWidth: 80,
          width: 300,
          flex: 1,
          headerAlign: key == "Subsytem Name" ? 'left' : 'right',
          align: key == "Subsytem Name" ? 'left' : 'right',
          renderCell: (cellValues: any) => {

            if (key === "Subsytem Name") {
              return (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                  <span style={{ textTransform: "capitalize" }}>{cellValues.row[key]}</span>
                  <EqualizerIcon titleAccess='Graph' style={{ cursor: "pointer" }} onClick={() => OpenGraphModal(cellValues.row[key])} />
                </Box>
              )
            }
            else {
              return <span>{cellValues.row[key]}</span>;
            }

          },
        };
      });
    return valuedata;
  };



  useEffect(() => {
    setLoaderImage(true)
    API.get("/cost/compare_module/", {
      top_vaults: filterDataIds.toString(),
      ...togglePartName(Partname),
      module: 1,
      is_leaf: Nodeleaf,
      depth: depthNode,
      image_length: true,
      path: pathname
    })
      .then((res: any) => {
        setImageslength(res?.data?.image_length);
        setLoaderImage(false)
        // setSelectedTopVaultIds([])
        // setLoaderProduct(false);
      })
      .catch((err: any) => {
        // setLoaderProduct(false);
        // console.log("compare error");
        setLoaderImage(false)
      });



  }, [Nodeleaf, NodeId, Count, topVaultId])


  useEffect(() => {
    setLoaderImagePaagination(true)
    API.get("/cost/compare_module/", {
      top_vaults: filterDataIds.toString(),
      ...togglePartName(Partname),
      module: 1,
      is_leaf: Nodeleaf,
      depth: depthNode,
      page: PageCount, path: pathname
    })
      .then((res: any) => {
        setImagesData(res?.data);
        setLoaderImagePaagination(false)

      })
      .catch((err: any) => {
        setImagesData(null)
        // console.log("compare error");
        setLoaderImagePaagination(false)
      });



  }, [Nodeleaf, NodeId, PageCount, topVaultId])



  // console.log(CostingGraphData, "CostingGraphData")

  var ImageLengthData = Imageslength && Imageslength;
  const ImgPaginationHandler = (btnType: any) => {

    if (btnType == "next") {
      setPageCount(PageCount + 1)
    }

    if (btnType == "prev") {
      setPageCount(PageCount - 1)
    }
  }


  const Redirection = (item: any) => {


    API.get("/cost/compare_redirect/", { top_vaults: item, part_name: Partname, is_leaf: Nodeleaf, depth: depthNode, path: pathname }).then((res: any) => {
      // sessionStorage.setItem("redirect_url",);
      sessionStorage.setItem("node", JSON.stringify(res.data?.root_nodes_id));
      if (Object.keys(res?.data)?.length > 0) {

        window.open(
          `/#/view/${res.data?.project}/${res.data?.top_vault}/${res.data?.vault}/${res.data?.abbreviation}/costing`,
          "_blank",
          " noopener"
        );
      }
      else {
        // riderictionPopup.current=true



        ridirectionopenVehicle.open()



      }
      // history.push(`/view/${res.data?.project}/${res.data?.top_vault}/${res.data?.vault}/${res.data?.abbreviation}/true`)
    })


  }



  return (
    <Box sx={{ height: "87vh", }}>

      <RidirectionModal
        isOpen={ridirectionopenVehicle.isOpen}
        onCloseModal={ridirectionopenVehicle.close}


      />
      <CompareCostingGraphmodal
        Open={graphModal.isOpen}
        Close={graphModal.close}
        parameterGraphData={CostingGraphData && CostingGraphData}
        ParameterNames={parameterName && parameterName}
        CostingGraphLoader={CostingGraphLoader}
      />

      {LoaderImagePaagination ?
        <Box sx={{ width: '100%', padding: '0 0.5rem' }}>
          <Skeleton sx={{ height: "12rem", width: "100%" }} />
        </Box>
        :
        <Table>
          <TableHead sx={{ backgroundColor: 'primary.light', padding: '0 1rem' }} >
            <TableRow>
              <TableCell sx={{ padding: "0.2rem 0.5rem", paddingLeft: "2rem", width: OrderData?.length == 5 ? '16vw' : OrderData?.length == 4 ? '15vw' : OrderData?.length == 3 ? '20vw' : OrderData?.length == 2 ? "25vw" : OrderData?.length == 1 ? '25vw' : '30vw', borderBottom: 'none', }}>Costing Comparison</TableCell>
              <TableCell sx={{ padding: "0.2rem 0.5rem", width: '3rem', borderBottom: 'none', }}></TableCell>
              {OrderData && OrderData?.map((item: any, index: any) => {
                return (<>
                  <TableCell sx={{ padding: "0.2rem 0.5rem", width: OrderData?.length == 5 ? '16vw' : OrderData?.length == 4 ? '15vw' : OrderData?.length == 3 ? '20vw' : OrderData?.length == 2 ? "25vw" : OrderData?.length == 1 ? '25vw' : '30vw', borderBottom: 'none', }} align="center">
                    <Box sx={{ display: 'flex', alignItems: "center", }}>
                      <Typography style={{ fontSize: '1rem', width: '100%', textAlign: 'center' }}> {item?.name} ({item?.product_id})</Typography>
                      <ReplyIcon
                        sx={{ fontSize: "1rem", transform: "scaleX(-1)", cursor: 'pointer' }} onClick={() => Redirection(item?.id)} titleAccess="Go to Costing" />
                    </Box>
                  </TableCell>
                </>)
              })}

              <TableCell sx={{ padding: "0.2rem 0.5rem", width: '2rem', borderBottom: 'none', }}>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell colSpan={OrderData?.length + 2} sx={{ padding: "0.2rem 0.5rem", borderBottom: 'none', width: '100%' }} align="right">
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <IconButton style={{ cursor: "pointer" }} disabled={PageCount > 1 ? false : true} onClick={() => ImgPaginationHandler("prev")}>
                    <ArrowBackIosIcon />
                  </IconButton>
                  {/* @ts-ignore */}
                  <RViewerJS>
                    {/* @ts-ignore */}
                    <span title={imagesData && imagesData?.parameter}>
                      {/* @ts-ignore */}
                      {imagesData && imagesData?.data?.map((item: any, index: any) => {
                        {/* @ts-ignore */ }
                        return (<><BomCompareImg ImgItem={item} topVaultIds={OrderData?.length} viweimg={imagesData && imagesData?.parameter} />              </>)
                      })}
                    </span>
                  </RViewerJS>
                  <IconButton style={{ cursor: "pointer" }}
                    // @ts-ignore
                    disabled={PageCount <= ImageLengthData - 1 ? false : true}
                    onClick={() => ImgPaginationHandler("next")}>
                    <ArrowForwardIosIcon style={{ cursor: "pointer" }} />
                  </IconButton>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      }
      <Box >
        {/* @ts-ignore */}
        {CostingLoader ?

          <Box sx={{ width: '100%', padding: '0 1rem' }}>
            <Skeleton sx={{ height: "3rem", width: "100%" }} />
            <Skeleton sx={{ height: "3rem", width: "100%" }} />
            <Skeleton sx={{ height: "3rem", width: "100%" }} />
            <Skeleton sx={{ height: "3rem", width: "100%" }} />
            <Skeleton sx={{ height: "3rem", width: "100%" }} />
          </Box>
          :
          <Box sx={{
            width: "100%", padding: '0 1rem', height: { lg: "64vh", xl: '65.5vh' },
            "& .MuiDataGrid-columnHeaderTitle": {
              color: '#fff !important',
            },
            // "& .MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
            //   minHeight: '24px !important',
            //   maxHeight: '24px !important',
            // },
            // "& .MuiDataGrid-root .MuiDataGrid-columnHeader--sortable": {
            //   backgroundColor: "primary.main",
            //   minHeight: '24px !important',
            //   maxHeight: '24px !important',
            // },
            // "& .MuiDataGrid-root .MuiDataGrid-row": {
            //   minHeight: '24px !important',
            //   maxHeight: '24px !important',
            // },
            "& .MuiDataGrid-columnHeaders.MuiDataGrid-columnHeaders": {
              backgroundColor: "primary.main",
              minHeight: '34px !important',
              maxHeight: '34px !important',
              lineHeight: '34px !important'
            }
          }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.6">
            {CostingData && CostingData?.length > 0 &&
              <DataGrid
                headerHeight={48}
                rowHeight={36}
                rows={TableRowCost(CostingData && CostingData)}
                columns={TableHeaderCost(CostingData && CostingData[0])}
                components={{ Toolbar: () => { return CustomToolbar() } }}
                density="compact"
                autoHeight={true}
                sx={{
                  textAlign: "right",
                  '&.MuiDataGrid-columnHeaders.MuiDataGrid-columnHeaders': {
                    position: 'sticky',
                    top: '0',
                    height: '50px',
                  },
                  " .& ::-webkit-scrollbar-track": {
                    // border: 1px solid black;
                    backgroundColor: "#f5f5f5",
                    borderRadius: "5%",
                  },
                  ".& ::-webkit-scrollbar": {
                    width: "5px",
                    backgroundColor: "#18aedb",
                    borderRadius: "5%",
                  },
                  ".& ::-webkit-scrollbar-thumb": {
                    backgroundImage: "linear-gradient(#09c6f9, #045de9)",
                    borderRadius: "5%",
                  },
                }}
                disableSelectionOnClick
                hideFooterPagination={true}
                hideFooter={true}

              />
            }
          </Box>}
        {/* @ts-ignore */}
      </Box>
    </Box>
  )
}