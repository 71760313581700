import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  Container,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VerifiedIcon from "@mui/icons-material/Verified";
import { API } from "../../api-services";
import { read } from "fs";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import Swal from "sweetalert2";
import contacts from "material-ui/svg-icons/communication/contacts";
import { ADMIN } from "../../Redux/Services/admin.service";
import CDN_URL from "../../utlis/CDN_URL";
import OtherLocationsModal from "./OtherLocationsModal";
import { a } from "@react-spring/web";
import DirectorsSignatoriesModal from "./DirectorsModal";
import ChargesModal from "./ChargesModal";

const validationSchema = Yup.object({
  business_name: Yup.string().required("Business Name is required"),
  incorporated_date: Yup.string().required(
    "Business incorporate date is required"
  ),
  cin_number: Yup.string().required("CIN is required"),
  type: Yup.string().required("Type is required"),


  address_line: Yup.string().required("Address line is required"),
  address_city: Yup.string().required("City is required"),
  address_district: Yup.string().required("District is required"),
  address_state: Yup.string().required("State is required"),
  address_country: Yup.string().required("Country is required"),
  address_pincode: Yup.string().required("Pincode is required"),
  pan_number: Yup.string().required("PAN is required"),
  gross_turnover: Yup.string().required("Gross turnover is required"),
  export_turnover: Yup.string().required("Export turnover is required"),
  records: Yup.object().test(
    "has-at-least-one-item",
    "Must have at least one location",
    (value) => {
      return Object.keys(value || {}).length > 0;
    }
  ),
  directors_signatories: Yup.object().test(
    "has-at-least-one-item",
    "Must have at least one executive",
    (value) => {
      return Object.keys(value || {}).length > 0;
    }
  ),
  charges: Yup.object(),

  // ---------------------------------------

  // vehicle_category: Yup.array().required("Product Category is required"),
  // bom_category: Yup.array().required("BOM Category is required"),

  // logo: Yup.string().required("Logo is required"),
  // business_email: Yup.string()
  //   .email("Invalid email address")
  //   .required("Business Email is required"),
  // business_phone_no: Yup.string()
  //   .matches(/^\+(?:[0-9] ?){6,14}[0-9]$/, "Invalid phone number")
  //   .required("Business Phone is required"),
  // business_address: Yup.string().required("Business Address is required"),
  // business_pincode: Yup.string().required("Picode is required"),
  // business_website: Yup.string().url("Invalid URL"),
  // annual_revenue: Yup.string().required("Please Enter Annual Revenue"),
  // year_of_establishment: Yup.string().required(
  //   "Please Enter Year of Establishment"
  // ),
  // legal_status_of_business: Yup.string().required(
  //   "Please Enter Legal Status of the Firm"
  // ),
  // business_registration_no: Yup.string().required(
  //   "Please Enter Business Registration Number"
  // ),
  // executives: Yup.object()
  //   .test(
  //     "has-at-least-one-item",
  //     "Must have at least one executive",
  //     (value) => {
  //       return Object.keys(value || {}).length > 0;
  //     }
  //   )
  //   .required("Please Enter Name of the Director / Founder"),
  // about_business: Yup.string().required("About Business is required"),
});

const VerificationStepEdit1 = ({
  prefetchedDataId,
  handleNext,
  activeStep,
  setActiveStep,
  handleBack,
  counts,
  setCounts,
  request_type,
  steps,
}: any) => {
  const foundersModal = useBaseParamsModal();
  const directorsModal = useBaseParamsModal();
  const chargesModal = useBaseParamsModal();
  const [open, setOpen] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [founders, setFounders] = useState<any>([]);
  const [prefetchedData, setPrefetchedData] = useState<any>(null);
  const [verificationStatus, setVerificationStatus] = useState<any>({
    business_name: { verified: false, rejected: true },
    incorporated_date: { verified: false, rejected: true },
    cin_number: { verified: false, rejected: true },
    type: { verified: false, rejected: true },

   
    address_line: { verified: false, rejected: true },
    address_city: { verified: false, rejected: true },
    address_district: { verified: false, rejected: true },
    address_state: { verified: false, rejected: true },
    address_country: { verified: false, rejected: true },
    address_pincode: { verified: false, rejected: true },
    pan_number: { verified: false, rejected: true },
    gross_turnover: { verified: false, rejected: true },
    export_turnover: { verified: false, rejected: true },
    records: { verified: false, rejected: true },
    directors_signatories: { verified: false, rejected: true },
    charges: { verified: false, rejected: true },

    // ------------------------------------------------------

  });

  const getPreFetchedData = () => {
    API.get("/cart/get_all_claim_requests/", { id: prefetchedDataId }, 0)
      .then((res: any) => {
        setPrefetchedData(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    if (prefetchedDataId) {
      getPreFetchedData();
    }
  }, [prefetchedDataId]);

  const handleVerificationToggle = (fieldName: any) => {
    if (!loaded) {
      setLoaded(true);
    }
    setVerificationStatus((prevStatus: any) => ({
      ...prevStatus,
      [fieldName]: {
        verified: !prevStatus[fieldName].verified,
        rejected: prevStatus[fieldName].verified ? true : false, // Toggle rejected based on the new verified state
      },
    }));
    ADMIN.toast.info("Verification Successful");
  };

  const handleToggleVerification = () => {
    if (!loaded) {
      setLoaded(true);
    }
    ADMIN.toast.info("Verification Successful");
    const allVerified = Object.keys(verificationStatus).every(
      (key) => verificationStatus[key].verified === true
    );

    const updatedStatus = Object.keys(verificationStatus).reduce(
      (acc: any, key: any) => {
        acc[key] = { ...verificationStatus[key], verified: !allVerified };
        return acc;
      },
      {}
    );

    setVerificationStatus(updatedStatus);
  };

  useEffect(() => {
  
    setVerificationStatus((prevStatus: any) => ({
      ...prevStatus,

      business_name: {
        ...prevStatus.business_name,
        verified: prefetchedData?.verification_status?.business_name || false,
      },
      incorporated_date: {
        ...prevStatus.incorporated_date,
        verified: prefetchedData?.verification_status?.incorporated_date || false,
      },
      cin_number: {
        ...prevStatus.cin_number,
        verified: prefetchedData?.verification_status?.cin_number || false,
      },
      type: {
        ...prevStatus.type,
        verified: prefetchedData?.verification_status?.type || false,
      },
  
     
      address_line: {
        ...prevStatus.address_line,
        verified: prefetchedData?.verification_status?.address_line || false,
      },
      address_city: {
        ...prevStatus.address_city,
        verified: prefetchedData?.verification_status?.address_city || false,
      },
      address_district: {
        ...prevStatus.address_district,
        verified: prefetchedData?.verification_status?.address_district || false,
      },
      address_state: {
        ...prevStatus.address_state,
        verified: prefetchedData?.verification_status?.address_state || false,
      },
      address_country: {
        ...prevStatus.address_country,
        verified: prefetchedData?.verification_status?.address_country || false,
      },
      address_pincode: {
        ...prevStatus.address_pincode,
        verified: prefetchedData?.verification_status?.address_pincode || false,
      },
      pan_number: {
        ...prevStatus.pan_number,
        verified: prefetchedData?.verification_status?.pan_number || false,
      },
      gross_turnover: {
        ...prevStatus.gross_turnover,
        verified: prefetchedData?.verification_status?.gross_turnover || false,
      },
      export_turnover: {
        ...prevStatus.export_turnover,
        verified: prefetchedData?.verification_status?.export_turnover || false,
      },
      records: {
        ...prevStatus.records,
        verified: prefetchedData?.verification_status?.records || false,
      },
      directors_signatories: {
        ...prevStatus.directors_signatories,
        verified: prefetchedData?.verification_status?.directors_signatories || false,
      },
      charges: {
        ...prevStatus.charges,
        verified: prefetchedData?.verification_status?.charges || false,
      },

    }));
  }, [prefetchedData?.verification_status]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openContact = Boolean(anchorEl);

  useEffect(() => {
    if (prefetchedData && prefetchedData.executives) {
      
      // Convert the object to an array of items with { name, designation }
      const executivesArray = Object.entries(prefetchedData.executives)?.map(
        ([key, value]) => ({
          name: key,
          designation: value,
        })
      );
      // Set the state with the array of executives
      setFounders(executivesArray);
    }
  }, [prefetchedData?.executives]);

  const handleSubmit = () => {
    const simplifiedStatus: any = {};
    Object.keys(verificationStatus).forEach((key: any) => {
      simplifiedStatus[key] = verificationStatus[key].verified;
    });

    API.put(`cart/get_all_claim_requests/${prefetchedDataId}/`, {
      ...simplifiedStatus,
      [request_type === "Create"
        ? "validate_create_business"
        : "validate_claim_business"]: true,
    })
      .then((res) => {
        getPreFetchedData();

        // setClaimedData(res.data?.data)
        // Swal.fire({
        //   icon: "success",
        //   title: "Informations submitted successfully",
        // });
      })

      .catch((err: any) => {
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
        });
      });
  };

  useEffect(() => {
    if (loaded) {
      handleSubmit();
      setLoaded(false);
    }
  }, [verificationStatus, loaded]);

  const truncateString = (str: any, maxLength: any) => {
    if (str.length <= maxLength) {
      return str; // Return the full string if it's less than or equal to maxLength
    } else {
      return str.slice(0, maxLength - 3) + "..."; // Truncate and add "..." for longer strings
    }
  };

  const verifiedCount = Object.keys(verificationStatus).filter(
    (key) => verificationStatus[key].verified === true
  )?.length;
  useEffect(() => {
    setCounts({ ...counts, v1: verifiedCount });
  }, [verifiedCount]);


  return (
    <>

    {foundersModal.isOpen && (
      <OtherLocationsModal isOpen={foundersModal.isOpen} onCloseModal={()=> foundersModal?.close()} records={foundersModal?.propsId} />
    )}

    {directorsModal?.isOpen && (
      <DirectorsSignatoriesModal isOpen={directorsModal.isOpen} onCloseModal={()=> directorsModal?.close()} directors={directorsModal?.propsId} />
    )}

{chargesModal?.isOpen && (
      <ChargesModal isOpen={chargesModal.isOpen} onCloseModal={()=> chargesModal?.close()} charges={chargesModal?.propsId} />
    )}

<Box sx={{ padding: "1rem " }}>
      <Box
        sx={{
          marginTop: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Formik
          initialValues={{
            business_name: prefetchedData ? prefetchedData.business_name : "",
            business_pincode: prefetchedData
              ? prefetchedData.business_pincode
              : "",
            bom_category: prefetchedData ? prefetchedData.bom_category : [],
            vehicle_category: prefetchedData
              ? prefetchedData.vehicle_category
              : [],
            logo: prefetchedData ? prefetchedData.logo : "",
            business_email: prefetchedData ? prefetchedData.business_email : "",
            business_phone_no: prefetchedData
              ? prefetchedData.business_phone_no
              : "",
            business_address: prefetchedData
              ? prefetchedData.business_address
              : "",
            business_website: prefetchedData
              ? prefetchedData.business_website
              : "",
            annual_revenue: prefetchedData ? prefetchedData.annual_revenue : "",
            year_of_establishment: prefetchedData
              ? prefetchedData.year_of_establishment
              : "",
            legal_status_of_business: prefetchedData
              ? prefetchedData.legal_status_of_business
              : "",
       
            executives: prefetchedData ? prefetchedData.executives : {},
            about_business: prefetchedData ? prefetchedData.about_business : "",
          }}
          // validationSchema={validationSchema}
          onSubmit={(values: any) => {
            handleNext();
          }}
          enableReinitialize={true} // Enable reinitialization when initialValues change
        >
          {({ errors, touched }: any) => (
            <Form>
              <Box sx={{ width: "100%" }}>
               <Box
                  sx={{ display: "flex", width: "100%", background: "#00887a" }}
                >
                  <Box
                    sx={{
                      padding: "1rem",
                      width: "18vw",
                      color: "#fff",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Field
                  </Box>
                  <Box
                    sx={{
                      padding: "1rem",
                      width: "18vw",
                      color: "#fff",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Old Value
                  </Box>
                  <Box
                    sx={{
                      padding: "1rem",
                      width: "18vw",
                      color: "#fff",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    New Value
                  </Box>
                  <Box
                    sx={{
                      padding: "1rem",
                      width: "18vw",
                      color: "#fff",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Verify{" "}
                    <span
                      style={{ cursor: "pointer", marginLeft: "1rem" }}
                      onClick={handleToggleVerification}
                    >
                      {`(Verify All)`}
                    </span>
                  </Box>
                </Box>

                {Object.keys(validationSchema?.fields).map((fieldName: any, index: any) => {
                  return (
                    <Box
                          key={index}
                          // sx={{
                          //   padding: "1rem",
                          //   width: "25vw",
                          //   color: "#fff",
                          //   borderBottomColor: "primary.light",
                          // }}
                          sx={{
                            border: "0.1px solid primary.light",
                            display: "flex",
                          }}
                        >
                          <Box
                            key={index}
                            sx={{
                              width: "18vw",
                              padding: "1rem",
                              border: "0.1px solid #dee0e0",
                              display: "flex",
                            }}
                          >
                            {fieldName === "vehicle_category" ? "Product Category" :   fieldName
                              ?.replace(/_/g, " ")
                              ?.replace(/\b\w/g, (char: any) =>
                                char.toUpperCase()
                              )}
                          </Box>
                          <Box
                            key={index}
                            sx={{
                              width: "18vw",
                              padding: "1rem",
                              border: "0.1px solid #dee0e0",
                              display: "flex",
                            }}
                          >
                            {prefetchedData?.old_data
                              ? fieldName === "about_business"
                                ? truncateString(prefetchedData?.old_data?.[fieldName], 150)
                                : (fieldName === "records" || fieldName === "charges" || fieldName === "directors_signatories")
                                ? (
                                  <VisibilityIcon sx={{color:"#00887a", cursor:"pointer"}} onClick={()=> {
                                    if(fieldName === "records"){
                                      foundersModal.open(prefetchedData?.old_data?.[fieldName])
                                    }
                                    if(fieldName === "directors_signatories"){
                                      directorsModal.open(prefetchedData?.old_data?.[fieldName])
                                    }
                                    if(fieldName === "charges"){
                                      chargesModal.open(prefetchedData?.old_data?.[fieldName])
                                    }
                                  }} />
                                  // prefetchedData[fieldName]?.length > 0 ? prefetchedData[fieldName].map((item: any) => item.name).join(", ") : "-"

                                )
                                
                                :prefetchedData?.[fieldName]
                              : ""}
                          </Box>
                          <Box
                            key={index}
                            sx={{
                              width: "18vw",
                              padding: "1rem",
                              border: "0.1px solid #dee0e0",
                              display: "flex",
                            }}
                          >
                            {prefetchedData
                              ? fieldName === "about_business"
                                ? truncateString(prefetchedData[fieldName], 150)
                                : (fieldName === "records" || fieldName === "charges" || fieldName === "directors_signatories")
                                ? (
                                  <VisibilityIcon sx={{color:"#00887a", cursor:"pointer"}} onClick={()=> {
                                    if(fieldName === "records"){
                                      foundersModal.open(prefetchedData?.[fieldName])
                                    }
                                    if(fieldName === "directors_signatories"){
                                      directorsModal.open(prefetchedData?.[fieldName])
                                    }
                                    if(fieldName === "charges"){
                                      chargesModal.open(prefetchedData?.[fieldName])
                                    }
                                  }} />
                                  // prefetchedData[fieldName]?.length > 0 ? prefetchedData[fieldName].map((item: any) => item.name).join(", ") : "-"

                                )
                                
                                :prefetchedData[fieldName]
                              : ""}
                          </Box>

                          <Box
                            key={index}
                            sx={{
                              width: "18vw",
                              padding: "1rem",
                              border: "0.1px solid #dee0e0",
                              display: "flex",
                              // justifyContent: "center"
                            }}
                          >
                            {verificationStatus?.[fieldName]?.verified ? (
                              <VerifiedIcon
                                sx={{ cursor: "pointer", color: "green" }}
                                titleAccess="Verified"
                                onClick={() =>
                                  handleVerificationToggle(fieldName)
                                }
                              />
                            ) : (
                              <VerifiedIcon
                                sx={{ cursor: "pointer", color: "#ffc800" }}
                                titleAccess="Not Verified"
                                onClick={() =>
                                  handleVerificationToggle(fieldName)
                                }
                              />
                            )}
                          </Box>
                        </Box>
                  )
                })}

<Grid
                  container
                  justifyContent="space-between"
                  spacing={2}
                  sx={{ mt: 2 }}
                >
                  <Grid item>
                    <Button disabled={activeStep === 0} onClick={handleBack}>
                      Previous
                    </Button>
                  </Grid>

                  <Grid item>
                  <Box sx={{display:"flex", gap:"1rem"}}>
                  {/* <Button
                        size="small"
                        type="button"
                        variant="contained"
                        sx={{
                          background:"#d65151",
                          "&:hover": {
                            background:"#f21313",
                            transform: "Scale(1.05)",
                            transition: "transform 0.5s ease",
                          },
                        }}
                      >
                        Reject
                      </Button> */}
                    <Button variant="contained" color="primary" type="submit">
                      Next
                    </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>

       
      </Box>
    </Box>
    
    </>
 
  );
};

export default VerificationStepEdit1;
