import React from "react";
import {
  Modal,
  Box,
  TextField,
  Button,
  Typography,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Field, Formik } from "formik";
import CancelIcon from "@mui/icons-material/Cancel";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50rem",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

const AddLocationModal: React.FC<any> = ({ isOpen, onCloseModal, onSave }) => {
  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: "-2rem",
            }}
          >
            Add Location Information
          </Typography>
          <CancelIcon
            titleAccess="Close"
            sx={{ cursor: "pointer", margin: "0.5rem" }}
            onClick={onCloseModal}
          />
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
            marginBottom: "1rem",
          }}
        />
        <Box sx={{ padding: 2, backgroundColor: "white", borderRadius: 1 }}>
          <Formik
            initialValues={{
              gstin: "",
              legalName: "",
              pan: "",
              primaryAddress: { district: "", street: "" },
              status: "",
              registered: "",
              active: true,
            }}
            onSubmit={(values) => {
              onSave(values);
              onCloseModal();
            }}
          >
            {({ values, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  label="GSTIN"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  name="gstin"
                  value={values.gstin}
                  onChange={handleChange}
                  required
                />

                <Field name="active">
                  {({ field, form }: any) => (
                    <FormControl fullWidth variant="outlined" margin="normal">
                      <InputLabel id="active-label">Active</InputLabel>
                      <Select
                        labelId="active-label"
                        label="Active"
                        value={field.value ? "true" : "false"} // Convert boolean to string for Select
                        onChange={(event) => {
                          const value = event.target.value === "true"; // Convert string back to boolean
                          form.setFieldValue(field.name, value); // Update Formik state
                        }}
                      >
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                      </Select>
                      {form.touched.active && form.errors.active && (
                        <Typography color="error">
                          {form.errors.active}
                        </Typography>
                      )}
                    </FormControl>
                  )}
                </Field>

                <TextField
                  label="Legal Name"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  name="legalName"
                  value={values.legalName}
                  onChange={handleChange}
                  required
                />
                <TextField
                  label="PAN"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  name="pan"
                  value={values.pan}
                  onChange={handleChange}
                  required
                />
                <TextField
                  label="District"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  name="primaryAddress.district"
                  value={values.primaryAddress.district}
                  onChange={handleChange}
                  required
                />
                <TextField
                  label="Street"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  name="primaryAddress.street"
                  value={values.primaryAddress.street}
                  onChange={handleChange}
                  required
                />
                <Button type="submit" variant="contained" color="primary">
                  Add Location
                </Button>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddLocationModal;
