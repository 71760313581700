import React, { useState } from "react";
import { Box, Button, Paper, Skeleton, Stack, Typography } from "@mui/material";
import styles from "./Landing.module.scss";
import { useHistory } from "react-router-dom";
import analysis from "../../Assets/images/analysis.svg";
import noImage from "../../Assets/images/noImage.png";
import Idea from "../../Assets/images/Idea.png";
import ImageRoundedIcon from "@mui/icons-material/ImageRounded";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import Image from "../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import NoImgLoader from "../../Assets/images/loading-screen-landing.gif";
import Grid from "@mui/material/Grid";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import PushPinIcon from "@mui/icons-material/PushPin";
import UnpinImg from "../../Assets/images/Unpin.svg";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import Modal from "@mui/material/Modal";
import ProductProgress from "./ProductProgress";
import { API } from "../../api-services";
import { useBaseModal } from "../SearchPage/useBaseModal";
import RidirectionModal from "../../ViewModule/Comparefolder/RidirectionModal";
import StarIcon from "@mui/icons-material/Star";
import ChangeHistoryRoundedIcon from "@mui/icons-material/ChangeHistoryRounded";
import MonitorRoundedIcon from "@mui/icons-material/MonitorRounded";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";

//SLick carousel
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface IProductProgress {}
interface ILandingCountGet {
  SPOC: null | string;
  architecture_count: number;
  bom_part_count: number;
  completed_status: number;
  idea_count: number;
  idea_value: string;
  media_count: number;
  project_description: string;
  project_id: number;
  project_name: string;
  product_data: any;
}
interface ILandingPageBodyRowsProps {
  item: any;
  index: number;
  calculationData?: any;
  calcLoading?: any;
  setApiCallCount?: any;
}

function LandingPageBodyRows(props: ILandingPageBodyRowsProps) {
  const history = useHistory();
  const { item, index, calculationData, calcLoading, setApiCallCount } = props;
  const [apiCallProduct, setApiCallProduct] = useState<boolean>(false);
  const [productList, setProductList] = useState<any>([]);
  const [apiCallAnalysisProduct, setApiCallAnalysisProduct] =
    useState<boolean>(false);
  const [productAnalysisList, setProductAnalysisList] = useState<any>();
  const validation = useBaseModal();

  const { height, width } = useWindowDimensions();

  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 1000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const PinedClicked = (projectId: any, pinnedStatus: any) => {
    API.put(
      `/cost/dashboard/${projectId}/`,
      { pin_project: pinnedStatus ? false : true },
      {},
      0
    )
      .then((res: any) => {
        setApiCallCount((prev: any) => prev + 1);
      })
      .catch(() => {});
  };

  const redirectViewData = (Project_Id: any, FirstTopVoult: any) => {
    // @ts-ignore
    sessionStorage.removeItem("node");
    sessionStorage.removeItem("bomModule");
    history.push(`/view/${Project_Id}/${FirstTopVoult}/${0}/product/false`);
  };

  const redirectComparePage = (Project_Id: any, allTopVouts: any) => {
    var FiterTovoults =
      allTopVouts &&
      allTopVouts.map((item: any, index: any) => {
        return item?.top_vault_id;
      });

    API.get("customer/compare_landing/", { project: Project_Id }).then(
      (res: any) => {
        if (Object.keys(res?.data)?.length > 0) {
          var sliceTopvaoults =
            res?.data?.top_vault && res?.data?.top_vault.slice(0, 4);

          var modulepression = res?.data?.module == "BOM" ? 1 : 2;
          history.push(
            `/newcompare/${Project_Id}/${modulepression}/${sliceTopvaoults?.toString()}/product/0/Vehicle/0/vehicle/false/-1/null`
          );
        } else {
          validation.open();
        }
        // validation
      }
    );
  };
  const mouseHoverHandler = async (id: any) => {
    setApiCallProduct(false);
    API.get(
      `/cost/dashboard/`,
      {
        get_idea_topvault: true,
        project: id,
      },
      0
    ).then((res: any) => {
      setApiCallProduct(true);
      setProductList(res?.data);
    });
  };

  const mouseHoverAnalysisHandler = async (id: any) => {
    setApiCallAnalysisProduct(false);
    API.get(
      `/cost/analysis/`,
      {
        get_drawer_values: "analysis_module",
        project: id,
      },
      0
    ).then((res: any) => {
      setApiCallAnalysisProduct(true);
      setProductAnalysisList(res.data);
    });
  };

  const redirectBannerPage = (Project_Id: any, Project_name: any) => {
    history.push(`/banner/${Project_Id}/${Project_name}/0/0/0`);
  };

  const analysisHandler: any = () => {
    if (apiCallAnalysisProduct && productAnalysisList?.length > 0) {
      let filteredBOM: any =
        productAnalysisList &&
        productAnalysisList?.filter((item: any) => {
          return item?.module == "BOM";
        });
      if (filteredBOM?.length > 0) {
        history.push(
          `/analysis/${filteredBOM[0]?.project}/${filteredBOM
            ?.map((itemId: any) => {
              return itemId?.top_vault_id;
            })
            ?.toString()}`
        );
      } else {
        let filteredCosting: any =
          productAnalysisList &&
          productAnalysisList?.filter((item: any) => {
            return item?.module == "Costing";
          });
        history.push(
          `/analysis_costing/${filteredCosting[0]?.project}/${filteredCosting
            ?.map((itemId: any) => {
              return itemId?.top_vault_id;
            })
            ?.toString()}`
        );
      }
    }
  };

  return (
    <Box>
      <RidirectionModal
        isOpen={validation.isOpen}
        onCloseModal={validation.close}
        validationText={"permission"}
      />
      <Grid
        container
        spacing={2}
        columnSpacing={4}
        className={styles.projectTitlesec}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={3.2}
          lg={2.8}
          xl={2.8}
          sx={{ paddingTop: "0 !important" }}
          className={styles.projectDetails}
        >
          <Box>
            <Box
              sx={{
                display: {
                  xs: "block",
                  sm: "block",
                  md: "block",
                  lg: "none",
                  xl: "none",
                },
                position: "relative",
                marginBottom: "-2rem",
                zIndex: "1",
              }}
            >
              <Box sx={{ textAlign: "right" }}>
                {!item?.is_pinned && (
                  <PushPinOutlinedIcon
                    sx={{
                      fontSize: "1.5rem",
                      cursor: "pointer",
                      ":hover": {
                        transform: "scale(1.1)",
                        transition: "transform 0.5s ease",
                      },
                    }}
                    titleAccess="Pin project on top"
                    onClick={() => {
                      PinedClicked(item?.project_id, item?.is_pinned);
                    }}
                  />
                )}
                {item?.is_pinned && (
                  <PushPinIcon
                    titleAccess="Unpin"
                    onClick={() => {
                      PinedClicked(item?.project_id, item?.is_pinned);
                    }}
                    sx={{
                      color: "green",
                      fontSize: "1.5rem",
                      cursor: "pointer",
                      ":hover": {
                        transform: "scale(1.1)",
                        transition: "transform 0.5s ease",
                      },
                    }}
                  />
                )}
              </Box>
            </Box>
            <Box>
              <Typography className={styles.project_name}>
                {item?.project_name}
                <span
                  className={styles.project_description}
                >{` [ ${item?.project_description} ]`}</span>
              </Typography>
              <div className={styles.innerSection}>
                <Grid container className={styles.btnLink}>
                  <Grid item>
                    {!calcLoading ? (
                      <div
                        className={styles.progresbar}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          history.push(
                            `/dashboard/${item?.project_id}/AllProduct`
                          )
                        }
                      >
                        <ProductProgress
                          progressData={
                            calculationData &&
                            calculationData?.overall_progress &&
                            calculationData?.overall_progress[0]
                          }
                        />
                      </div>
                    ) : (
                      <Skeleton animation="wave" />
                    )}
                    <Box
                      sx={{
                        height: { lg: "6.7rem", xl: "8.5rem" },
                        marginTop: "0.5rem",
                      }}
                    >
                      {!calcLoading ? (
                        <div className={styles.moduleDetails}>
                          <Stack
                            direction="row"
                            alignItems="flex-start"
                            columnGap={1}
                          >
                            {item?.has_ideas && (
                              <TipsAndUpdatesIcon
                                color="primary"
                                sx={{ fontSize: "1.5rem" }}
                              />
                            )}
                            {item?.has_ideas && (
                              <Typography className={styles.project_module}>
                                {`${calculationData?.idea_count?.idea_count} Ideas Worth `}

                                {`${calculationData?.idea_count?.total_cost} INR`}
                              </Typography>
                            )}
                          </Stack>
                          {calculationData?.has_media == true &&
                            calculationData?.has_architecture == true && (
                              <Stack
                                direction="row"
                                alignItems="center"
                                columnGap={1}
                              >
                                <ImageRoundedIcon
                                  color="primary"
                                  sx={{ fontSize: "1.5rem" }}
                                />
                                <Typography className={styles.project_module}>
                                  {`${calculationData?.total_media} Images & `}
                                  {`${calculationData?.architecture_count} Architecture Diagram`}
                                </Typography>
                              </Stack>
                            )}

                          {calculationData?.has_media == true &&
                            calculationData?.has_architecture == false && (
                              <Stack
                                direction="row"
                                alignItems="center"
                                columnGap={1}
                              >
                                <ImageRoundedIcon
                                  color="primary"
                                  sx={{ fontSize: "1.5rem" }}
                                />
                                <Typography className={styles.project_module}>
                                  {`${calculationData?.total_media} Images & `}
                                  {`${calculationData?.architecture_count} Architecture Diagram`}
                                </Typography>
                              </Stack>
                            )}

                          {calculationData?.has_media == false &&
                            calculationData?.has_architecture == true && (
                              <Stack
                                direction="row"
                                alignItems="center"
                                columnGap={1}
                              >
                                <ImageRoundedIcon
                                  color="primary"
                                  sx={{ fontSize: "1.5rem" }}
                                />
                                <Typography className={styles.project_module}>
                                  {`${calculationData?.architecture_count} Architecture Diagram`}
                                </Typography>
                              </Stack>
                            )}

                          <Stack
                            direction="row"
                            alignItems="center"
                            columnGap={1}
                          >
                            <AccountTreeIcon
                              color="primary"
                              sx={{ fontSize: "1.5rem" }}
                            />
                            <Typography className={styles.project_module}>
                              {item?.costing_status == false
                                ? `${calculationData?.bom_count} BOM Parts`
                                : `${calculationData?.bom_count}  Parts BOM & Zero-based costing`}
                            </Typography>
                          </Stack>

                          {calculationData?.has_features == true &&
                            calculationData?.feature_count != 0 && (
                              <Stack
                                direction="row"
                                alignItems="center"
                                columnGap={1}
                              >
                                <StarIcon
                                  color="primary"
                                  sx={{ fontSize: "1.5rem" }}
                                />
                                <Typography className={styles.project_module}>
                                  {`${calculationData?.feature_count} Features`}
                                </Typography>
                              </Stack>
                            )}
                        </div>
                      ) : (
                        <Box>
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                        </Box>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
                        columnGap: "10px",
                        margin: "0.5rem 0 0",
                      }}
                    >
                      <MonitorRoundedIcon
                        className={styles.iconLink}
                        titleAccess="View Data"
                        sx={{
                          cursor: "pointer",
                          filter: "drop-shadow(3px 4px 2px rgba(0, 0, 0, 0.4))",
                          fontSize: "2.5rem",
                          display: "flex",
                          alignItems: "center",
                          margin: "0 auto",
                        }}
                        onClick={() =>
                          redirectViewData(
                            item?.project_id,
                            item?.top_vault_details &&
                              item?.top_vault_details[0]?.top_vault_id
                          )
                        }
                      />

                      {item && item?.top_vault_details.length >= 2 && (
                        <ChangeHistoryRoundedIcon
                          titleAccess="Compare"
                          className={styles.iconLink}
                          sx={{
                            cursor: "pointer",
                            filter:
                              "drop-shadow(3px 4px 2px rgba(0, 0, 0, 0.4))",
                            fontSize: "2.9rem",
                            display: "flex",
                            alignItems: "center",
                            margin: "0 auto",
                            marginTop: "-0.3rem",
                          }}
                          onClick={() =>
                            redirectComparePage(
                              item?.project_id,
                              item && item && item?.top_vault_details
                            )
                          }
                        />
                      )}

                      {item?.has_analysis && (
                        <img
                          src={analysis}
                          className={styles.iconLink}
                          style={{
                            width: "2.5rem",
                            height: "2rem",
                            // fontSize: "3.5rem",
                            margin: "0 auto",
                            marginTop: "3px",
                            alignItems: "center",
                            display: "flex",
                            cursor: "pointer",
                            filter:
                              "drop-shadow(3px 4px 2px rgba(0, 0, 0, 0.4))",
                          }}
                          title="Analysis"
                          onClick={() =>
                            productAnalysisList?.length > 0 && analysisHandler()
                          }
                          onMouseEnter={() =>
                            mouseHoverAnalysisHandler(item?.project_id)
                          }
                        />
                      )}

                      {item?.has_ideas && (
                        <img
                          src={Idea}
                          title="Evaluate Idea"
                          className={styles.iconLink}
                          style={{
                            height: "2rem",
                            margin: "0 auto",
                            marginTop: "3px",
                            alignItems: "center",
                            display: "flex",
                            cursor: "pointer",
                            filter:
                              "drop-shadow(3px 4px 2px rgba(0, 0, 0, 0.4))",
                          }}
                          onClick={() => {
                            return (
                              productList?.length > 0 &&
                              apiCallProduct &&
                              history.push(
                                `/idea_dashboard/${item?.project_id}/${productList[0]?.topvault_id}`
                              )
                            );
                          }}
                          onMouseEnter={() =>
                            mouseHoverHandler(item?.project_id)
                          }
                        />
                      )}
                      {item?.banner && (
                        <BookmarkBorderIcon
                          titleAccess="Banner"
                          className={styles.iconLink}
                          sx={{
                            cursor: "pointer",
                            filter:
                              "drop-shadow(3px 4px 2px rgba(0, 0, 0, 0.4))",
                            fontSize: "2.5rem",
                            display: "flex",
                            alignItems: "center",
                            margin: "0 auto",
                          }}
                          onClick={() =>
                            redirectBannerPage(
                              item?.project_id,
                              item?.project_name
                            )
                          }
                        />
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={8.8}
          lg={9.2}
          xl={9.2}
          sx={{
            paddingTop: "0 !important",
            display: {
              xs: "none",
              sm: "none",
              md: "none",
              lg: "block",
              xl: "block",
            },
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 2rem",
              width: "100%",
            }}
          >
            <div className={styles.carouselDiv}>
              {item?.top_vault_details?.length > 4 ? (
                <div
                  className={styles.projectImagesCoursel}
                  // adl-scrollbar="true"
                  // adl-scrollbar-width="0.3"
                >
                  <Slider {...settings}>
                    {item?.top_vault_details?.map((imageInner: any) => {
                      return (
                        <Paper
                          sx={{
                            boxShadow: "none",
                            backgroundColor: "transparent",
                          }}
                          className={styles.projImage}
                          title={imageInner?.top_vault_name}
                          onClick={() =>
                            history.push(
                              `/view/${item?.project_id}/${
                                imageInner?.top_vault_id
                              }/${0}/product/false`
                            )
                          }
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography className={styles.productTitle}>
                              {imageInner?.top_vault_name}
                            </Typography>
                          </Box>
                          <div>
                            {imageInner?.original_image ? (
                              <Image
                                src={imageInner?.original_image}
                                placeholderImg={NoImgLoader}
                                loading="lazy"
                                className={styles.proimg}
                                alt={imageInner?.top_vault_name}
                              />
                            ) : (
                              <Image
                                src={noImage}
                                placeholderImg={NoImgLoader}
                                loading="lazy"
                                className={styles.proimg}
                                alt={imageInner?.top_vault_name}
                              />
                            )}
                          </div>
                        </Paper>
                      );
                    })}
                  </Slider>
                </div>
              ) : item?.top_vault_details?.length < 5 || width > 750 ? (
                <div
                  className={styles.projectImages}
                  // adl-scrollbar="true"
                  // adl-scrollbar-width="0.3"
                >
                  {item?.top_vault_details?.map((imageInner: any) => {
                    return (
                      <Paper
                        sx={{
                          boxShadow: "none",
                          backgroundColor: "transparent",
                        }}
                        className={styles.projImage}
                        title={imageInner?.top_vault_name}
                        onClick={() =>
                          history.push(
                            `/view/${item?.project_id}/${
                              imageInner?.top_vault_id
                            }/${0}/product/false`
                          )
                        }
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography className={styles.productTitle}>
                            {imageInner?.top_vault_name}
                          </Typography>
                        </Box>
                        <div>
                          {imageInner?.original_image ? (
                            <Image
                              src={imageInner?.original_image}
                              placeholderImg={NoImgLoader}
                              loading="lazy"
                              className={styles.proimg}
                              alt={imageInner?.top_vault_name}
                            />
                          ) : (
                            <Image
                              src={noImage}
                              placeholderImg={NoImgLoader}
                              loading="lazy"
                              className={styles.proimg}
                              alt={imageInner?.top_vault_name}
                            />
                          )}
                        </div>
                      </Paper>
                    );
                  })}
                </div>
              ) : (
                <div
                  className={styles.projectImages}
                  adl-scrollbar="true"
                  adl-scrollbar-width="0.3"
                >
                  <Slider {...settings}>
                    {item?.top_vault_details?.map((imageInner: any) => {
                      return (
                        <Paper
                          sx={{
                            boxShadow: "none",
                            backgroundColor: "transparent",
                          }}
                          className={styles.projImage}
                          title={imageInner?.top_vault_name}
                          onClick={() =>
                            history.push(
                              `/view/${item?.project_id}/${
                                imageInner?.top_vault_id
                              }/${0}/product/false`
                            )
                          }
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography className={styles.productTitle}>
                              {imageInner?.top_vault_name}
                            </Typography>
                          </Box>
                          <div>
                            {imageInner?.original_image ? (
                              <Image
                                src={imageInner?.original_image}
                                placeholderImg={NoImgLoader}
                                loading="lazy"
                                className={styles.proimg}
                                alt={imageInner?.top_vault_name}
                              />
                            ) : (
                              <Image
                                src={noImage}
                                placeholderImg={NoImgLoader}
                                loading="lazy"
                                className={styles.proimg}
                                alt={imageInner?.top_vault_name}
                              />
                            )}
                          </div>
                        </Paper>
                      );
                    })}
                  </Slider>
                </div>
              )}
            </div>
            <Box sx={{ textAlign: "right" }}>
              {!item?.is_pinned && (
                <PushPinOutlinedIcon
                  sx={{
                    fontSize: "1.5rem",
                    cursor: "pointer",
                    ":hover": {
                      transform: "scale(1.1)",
                      transition: "transform 0.5s ease",
                    },
                  }}
                  titleAccess="Pin project on top"
                  onClick={() => {
                    PinedClicked(item?.project_id, item?.is_pinned);
                  }}
                />
              )}
              {item?.is_pinned && (
                <PushPinIcon
                  titleAccess="Unpin"
                  onClick={() => {
                    PinedClicked(item?.project_id, item?.is_pinned);
                  }}
                  sx={{
                    color: "green",
                    fontSize: "1.5rem",
                    cursor: "pointer",
                    ":hover": {
                      transform: "scale(1.1)",
                      transition: "transform 0.5s ease",
                    },
                  }}
                />
              )}
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={8.8}
          lg={9.2}
          xl={9.2}
          padding={1}
          sx={{
            display: {
              xs: "block",
              sm: "block",
              md: "block",
              lg: "none",
              xl: "none",
            },
          }}
        >
          <div className={styles.carouselDiv}>
            {width > 600 ? (
              <>
                {item?.top_vault_details?.length > 3 ? (
                  <div className={styles.projectImagesCoursel}>
                    <Slider {...settings}>
                      {item?.top_vault_details?.map((imageInner: any) => {
                        return (
                          <Paper
                            sx={{
                              boxShadow: "none",
                              backgroundColor: "transparent",
                            }}
                            className={styles.projImage}
                            title={imageInner?.top_vault_name}
                            onClick={() =>
                              history.push(
                                `/view/${item?.project_id}/${
                                  imageInner?.top_vault_id
                                }/${0}/product/false`
                              )
                            }
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography className={styles.productTitle}>
                                {imageInner?.top_vault_name}
                              </Typography>
                            </Box>
                            <div>
                              {imageInner?.original_image ? (
                                <Image
                                  src={imageInner?.original_image}
                                  placeholderImg={NoImgLoader}
                                  loading="lazy"
                                  className={styles.proimg}
                                  alt={imageInner?.top_vault_name}
                                />
                              ) : (
                                <Image
                                  src={noImage}
                                  placeholderImg={NoImgLoader}
                                  loading="lazy"
                                  className={styles.proimg}
                                  alt={imageInner?.top_vault_name}
                                />
                              )}
                            </div>
                          </Paper>
                        );
                      })}
                    </Slider>
                  </div>
                ) : item?.top_vault_details?.length < 4 ? (
                  <div className={styles.projectImages}>
                    {item?.top_vault_details?.map((imageInner: any) => {
                      return (
                        <Paper
                          sx={{
                            boxShadow: "none",
                            backgroundColor: "transparent",
                          }}
                          className={styles.projImage}
                          title={imageInner?.top_vault_name}
                          onClick={() =>
                            history.push(
                              `/view/${item?.project_id}/${
                                imageInner?.top_vault_id
                              }/${0}/product/false`
                            )
                          }
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography className={styles.productTitle}>
                              {imageInner?.top_vault_name}
                            </Typography>
                          </Box>
                          <div>
                            {imageInner?.original_image ? (
                              <Image
                                src={imageInner?.original_image}
                                placeholderImg={NoImgLoader}
                                loading="lazy"
                                className={styles.proimg}
                                alt={imageInner?.top_vault_name}
                              />
                            ) : (
                              <Image
                                src={noImage}
                                placeholderImg={NoImgLoader}
                                loading="lazy"
                                className={styles.proimg}
                                alt={imageInner?.top_vault_name}
                              />
                            )}
                          </div>
                        </Paper>
                      );
                    })}
                  </div>
                ) : (
                  <div
                    className={styles.projectImages}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                  >
                    <Slider {...settings}>
                      {item?.top_vault_details?.map((imageInner: any) => {
                        return (
                          <Paper
                            sx={{
                              boxShadow: "none",
                              backgroundColor: "transparent",
                            }}
                            className={styles.projImage}
                            title={imageInner?.top_vault_name}
                            onClick={() =>
                              history.push(
                                `/view/${item?.project_id}/${
                                  imageInner?.top_vault_id
                                }/${0}/product/false`
                              )
                            }
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography className={styles.productTitle}>
                                {imageInner?.top_vault_name}
                              </Typography>
                            </Box>
                            <div>
                              {imageInner?.original_image ? (
                                <Image
                                  src={imageInner?.original_image}
                                  placeholderImg={NoImgLoader}
                                  loading="lazy"
                                  className={styles.proimg}
                                  alt={imageInner?.top_vault_name}
                                />
                              ) : (
                                <Image
                                  src={noImage}
                                  placeholderImg={NoImgLoader}
                                  loading="lazy"
                                  className={styles.proimg}
                                  alt={imageInner?.top_vault_name}
                                />
                              )}
                            </div>
                          </Paper>
                        );
                      })}
                    </Slider>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className={styles.projectImagesCoursel}>
                  <Slider {...settings}>
                    {item?.top_vault_details?.map((imageInner: any) => {
                      return (
                        <Paper
                          sx={{
                            boxShadow: "none",
                            backgroundColor: "transparent",
                          }}
                          className={styles.projImage}
                          title={imageInner?.top_vault_name}
                          onClick={() =>
                            history.push(
                              `/view/${item?.project_id}/${
                                imageInner?.top_vault_id
                              }/${0}/product/false`
                            )
                          }
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography className={styles.productTitle}>
                              {imageInner?.top_vault_name}
                            </Typography>
                          </Box>
                          <div>
                            {imageInner?.original_image ? (
                              <Image
                                src={imageInner?.original_image}
                                placeholderImg={NoImgLoader}
                                loading="lazy"
                                className={styles.proimg}
                                alt={imageInner?.top_vault_name}
                              />
                            ) : (
                              <Image
                                src={noImage}
                                placeholderImg={NoImgLoader}
                                loading="lazy"
                                className={styles.proimg}
                                alt={imageInner?.top_vault_name}
                              />
                            )}
                          </div>
                        </Paper>
                      );
                    })}
                  </Slider>
                </div>
              </>
            )}
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}
export default LandingPageBodyRows;
