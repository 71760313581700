import * as React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Box,
  Button,
  ButtonProps,
  Divider,
  FormControl,
  InputLabel,
  Modal,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { API } from "../../../api-services";
import { useScroll } from "@react-spring/web";
import { LocalDining } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useHistory } from "react-router-dom";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { DataGrid } from "@mui/x-data-grid";
import {
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExportContainer,
} from "@mui/x-data-grid";
import useWindowDimensions from "../../../ui-reusable-component/useWindowDimensions";
import validated from "../../../Assets/images/Validated.svg";
import notValid from "../../../Assets/images/NotValid.svg";


export interface AgentModalProps {
  onCloseModal: any;
  isOpen: any;
  getData: any;
  isProduct: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "87vw", xl: "72vw" },
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

export default function AgentModal(props: AgentModalProps) {
  const { onCloseModal, isOpen, getData, isProduct } = props;
  const {
    projectId,
    projectName,
    topVault,
    vault,
    treeabbr,
    workstation,
    isLeaf,
    copyStatus,
    vehCateId,
    vehCateName
  } = useRouteParams<any>();
  const [AgentData, setAgentData] = React.useState([]);
  const [loader, setLoader] = React.useState<any>(false);
  const { height, width } = useWindowDimensions();
  const history = useHistory<any>();
  
  const partrounting = (data: any) => {
    window.open(`/#/bomentry/${vehCateId}/${vehCateName}/${topVault}/${data?.id}/${data?.abbreviation==null?"none":data?.abbreviation}/${workstation}/${isLeaf}/${copyStatus}`)
    // history.push(
    //   `/#/bomentry/${projectId}/${projectName}/${topVault}/${data?.id}/${treeabbr}/${workstation}/${isLeaf}/${copyStatus}`
    // );
    onCloseModal();
    sessionStorage.setItem("BOMCreateNode", JSON.stringify(data?.ansecotrs));
  };

  React.useEffect(() => {
    setLoader(true);
    if(isProduct){
      API.get(`bom/product_agent/`, { product_category: topVault }, 0)
      .then((res: any) => {
        setAgentData(res?.data);
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
        console.log("SErver Error");
      });
    }
    else{

    API.get(`bom/fetch_details/`, { vault: getData?.id }, 0)
      .then((res: any) => {
        setAgentData(res?.data);
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
        console.log("SErver Error");
      });
  }}, [getData]);

  const [checked, setChecked] = React.useState(false);

  // @ts-ignore
  const totalBlankCount = AgentData?.blank_part_list?.reduce((sum: number, item: any) => sum + item?.blank_count, 0);
// @ts-ignore
  const allTrue = AgentData?.non_validated?.every((item: any) => item?.status === 3);
  // @ts-ignore
  const NAData= AgentData && AgentData?.na_avg_percent
// @ts-ignore

// console.log(AgentData?.delta_data?.delta_weight_percentage, AgentData?.delta_data?.error_percentage * 100, AgentData?.delta_data?.delta_weight_percentage,  -AgentData?.delta_data?.error_percentage * 100,AgentData?.delta_data?.delta_weight_percentage >= -AgentData?.delta_data?.error_percentage * 100, "Valueee")
  return (
    <div>
      <Modal
        open={isOpen}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}>
              Reason for Validation Error
            </Typography>
            <CancelIcon
              titleAccess='Close'
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />

          {loader ? (
            <>
              <Box
                sx={{
                  padding: "0 1rem",
                  display: "flex",
                  alignItems: "flex-start",
                  columnGap: "1rem",
                  width: "100%",
                  height: "65vh",
                }}>
                <Skeleton
                  variant='rectangular'
                  height='100%'
                  width='100%'
                  sx={{ marginBottom: "1rem" }}
                />
                <Skeleton
                  variant='rectangular'
                  height='100%'
                  width='100%'
                  sx={{ marginBottom: "1rem" }}
                />
                <Skeleton
                  variant='rectangular'
                  height='100%'
                  width='100%'
                  sx={{ marginBottom: "1rem" }}
                />
                <Skeleton
                  variant='rectangular'
                  height='100%'
                  width='100%'
                  sx={{ marginBottom: "1rem" }}
                />
              </Box>
            </>
          ) : (
            <Box
              sx={{
                height: "70vh",
                width: "100%",
                padding: "0 1rem",
                display: "flex",
                flexDirection: "row",
                columnGap: "1rem",
              }}>
              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{
                    backgroundColor: "#007fff",
                    color: "white",
                    padding: "0.2rem 0.5rem",
                    borderRadius: "3px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "none",
                  }}>
                  <Typography sx={{ fontSize: "1rem", width: "100%" }}>
                    {/* @ts-ignore */}
                    Non-Validated Part {AgentData?.non_validated?.length > 0 ? <>({AgentData?.non_validated?.length})</> : ''}
                  </Typography>
                  {/* @ts-ignore */}
                  {AgentData?.non_validated?.length > 0 ? 
                   <img
                   title="Not Validated"
                   src={notValid}
                   style={{ height: "1.5rem", margin: "0.1rem" }}
                 /> : <img
                 title="Validated"
                 src={validated}
                 style={{ height: "1.5rem", margin: "0.1rem" }}
               /> 
                 }
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",                    
                    gap: " 0.5rem",
                    height: "65vh",
                  }}
                  adl-scrollbar='true'
                  adl-scrollbar-width='0.3'>
                  <Table sx={{ width: "100%" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell 
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}>
                        Part
                        </TableCell>
                        <TableCell 
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}>
                          Status
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {/* @ts-ignore */}
                    {AgentData && AgentData?.non_validated?.map((item: any, index: any) => {
                        return (
                          <>
                            <TableRow>
                              <TableCell
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                  width: "100%",
                                }}>
                                <span
                                  onClick={() => partrounting(item)}
                                  style={{
                                    borderBottom: "1px solid",
                                    cursor: "pointer",
                                  }}>
                                  {item?.part_no}
                                </span>
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                  display:'flex',
                                  alignItems:'center',
                                  justifyContent:'center',
                                }}>
                                {item?.status !== 3 ? 
                                  <img
                                  title="Not Validated"
                                  src={notValid}
                                  style={{ height: "1.5rem", margin: "0.1rem" }}
                                /> : <img
                                title="Validated"
                                src={validated}
                                style={{ height: "1.5rem", margin: "0.1rem" }}
                              /> 
                                  
                                }
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                  </Table>
                </Box>
              </Box>

              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{
                    backgroundColor: "#007fff",
                    color: "white",
                    padding: "0.2rem 0.5rem",
                    borderRadius: "3px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "none",
                  }}>
                  <Typography sx={{ fontSize: "1rem", width: "100%" }}>
                    {/* @ts-ignore */}
                    Delta Value {AgentData?.delta_data?.delta_weight_percentage > 0 ? <>({/* @ts-ignore */}
                    {AgentData?.delta_data?.delta_weight_percentage}%)</> : ''}
                  </Typography>  
                  {/* @ts-ignore */}
                    {AgentData?.delta_data?.delta_weight_percentage <= AgentData?.delta_data?.error_percentage * 100 && AgentData?.delta_data?.delta_weight_percentage >= -AgentData?.delta_data?.error_percentage * 100 ?
                    <img
                    title="Validated"
                    src={validated}
                    style={{ height: "1.5rem", margin: "0.1rem" }}
                    /> : <img
                    title="Not Validated"
                    src={notValid}
                    style={{ height: "1.5rem", margin: "0.1rem" }}
                  /> 
                  }

                </Box>
                <Box>
                  <Box>
                    <Table sx={{ width: "100%", marginTop: "0.4rem" }}>
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}>
                          Assembly Weight
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}>
                          : {/* @ts-ignore */}
                          {AgentData?.delta_data?.assembly_weight?.toFixed(2)} g
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}>
                          Child Parts Weight
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}>
                          : {/* @ts-ignore */}
                          {AgentData?.delta_data?.children_weight?.toFixed(2)} g
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                            // @ts-ignore
                            color: AgentData?.delta_data?.delta_weight_percentage <= AgentData?.delta_data?.error_percentage * 100 && AgentData?.delta_data?.delta_weight_percentage >= -AgentData?.delta_data?.error_percentage * 100 ? "green" : "red", 
                          }}>
                          Delta Weight
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                            display: "flex",
                            alignItems: "center",
                            columnGap: "0.5rem",
                            // @ts-ignore
                            color: AgentData?.delta_data?.delta_weight_percentage <= AgentData?.delta_data?.error_percentage * 100 && AgentData?.delta_data?.delta_weight_percentage >= -AgentData?.delta_data?.error_percentage * 100 ? "green" : "red", 
                          }}>
                          :
                          <Typography sx={{ fontSize: "1rem" }}>
                            {/* @ts-ignore */}
                            {AgentData?.delta_data?.delta} g <span style={{ color: AgentData?.delta_data?.delta_weight_percentage <= AgentData?.delta_data?.error_percentage * 100 && AgentData?.delta_data?.delta_weight_percentage >= -AgentData?.delta_data?.error_percentage * 100 ? "green" : "red",  }}>
                              {/* @ts-ignore */}
                              ({AgentData?.delta_data?.delta_weight_percentage}% {AgentData?.delta_data?.status} Limit)
                            </span>
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}>
                          Limit
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}>
                          : {/* @ts-ignore */}
                          {AgentData?.delta_data?.limit?.toFixed(2)} g ({/* @ts-ignore */}
                          {AgentData?.delta_data?.error_percentage * 100}%)
                        </TableCell>
                      </TableRow>
                    </Table>
                    <Typography sx={{ fontSize: "1rem", marginTop: "1rem" }}>
                      <b>Note:</b>
                      <br />
                     
                      Assembly weight should be within&nbsp;
                      {/* @ts-ignore */}
                      <b><span>&#177;</span>{AgentData?.delta_data?.error_percentage * 100}%</b> of
                      the sum of child parts weight
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{
                    backgroundColor: "#007fff",
                    color: "white",
                    padding: "0.2rem 0.5rem",
                    borderRadius: "3px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "none",
                  }}>
                  <Typography sx={{ fontSize: "1rem", width: "100%" }}>                    
                    Blank {totalBlankCount > 0 ? <>({totalBlankCount})</> : ''}
                  </Typography>
                  {/* @ts-ignore */}
                  {totalBlankCount > 0 ? 
                    <img title="Not Validated"
                    src={notValid}
                    style={{ height: "1.5rem", margin: "0.1rem" }}
                  /> : <img
                    src={validated}
                    style={{ height: "1.5rem", margin: "0.1rem" }}
                    />
                    
                 }
                </Box>

                {/* @ts-ignore */}
                {AgentData && AgentData?.blank_part_list && AgentData?.blank_part_list.length > 0 ? 
                  <Box
                    sx={{ height: "65vh" }}
                    adl-scrollbar='true'
                    adl-scrollbar-width='0.3'>
                    <Table stickyHeader aria-label='sticky table'>
                      <TableHead>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}>
                          Part No.
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                            textAlign:'center',
                          }}>
                          Count
                        </TableCell>
                      </TableHead>

                      <TableBody>
                        {/* @ts-ignore */}
                        {AgentData && AgentData?.blank_part_list?.map((item: any, index: any) => {
                              return (
                                <>
                                  <TableRow>
                                    <TableCell
                                      sx={{
                                        padding: "0.2rem 0.5rem",
                                        borderBottomColor: "primary.light",
                                      }}>
                                      <span
                                        onClick={() => partrounting(item)}
                                        style={{
                                          borderBottom: "1px solid",
                                          cursor: "pointer",
                                        }}>
                                        {item?.part_no}
                                      </span>
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        padding: "0.2rem 0.5rem",
                                        borderBottomColor: "primary.light",
                                        textAlign:'center',
                                      }}>
                                        <span title={item?.unfill_params}>
                                      {item?.blank_count}
                                      </span>
                                    </TableCell>
                                  </TableRow>
                                </>
                              );
                            }
                          )}
                      </TableBody>
                    </Table>
                  </Box> :
                  <Typography sx={{ padding: "0.5rem 0.5rem",
                  borderBottomColor: "primary.light",
                  textAlign:'center' }}>
                    No Data Available
                  </Typography>}
              </Box>

              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{
                    backgroundColor: "#007fff",
                    color: "white",
                    padding: "0.2rem 0.5rem",
                    borderRadius: "3px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "none",
                  }}>
                  <Typography sx={{ fontSize: "1rem", width: "100%" }}>
                    {/* @ts-ignore */}
                    NA {AgentData && AgentData?.na_count_list?.length > 0 ? <>({AgentData && AgentData?.na_count_list?.length})</> : ''}
                  </Typography>
                    {/* @ts-ignore */}
                    
                  {NAData>20  ? 
                  <img title="Not Validated"
                  src={notValid}
                  style={{ height: "1.5rem", margin: "0.1rem" }}
                />  
                   : 
                   <img
                  src={validated}
                  style={{ height: "1.5rem", margin: "0.1rem" }}
                  />                  
                 }
                </Box>

                {/* @ts-ignore */}
                {AgentData &&  AgentData?.na_count_list && AgentData?.na_count_list.length > 0 ? 
                  <Box
                  sx={{ height: "65vh" }}
                  adl-scrollbar='true'
                  adl-scrollbar-width='0.3'>
                  <Table stickyHeader aria-label='sticky table'>
                    <TableHead>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}>
                        Part No.
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          textAlign:'right',
                        }}>
                        Percentage
                      </TableCell>
                      {/* <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          textAlign:'center',
                        }}>
                        Status
                      </TableCell> */}
                    </TableHead>

                    <TableBody>
                      {/* @ts-ignore */}
                      {AgentData &&  AgentData?.na_count_list?.map((item: any, index: any) => {
                            return (
                              <>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}>
                                    <span
                                      onClick={() => partrounting(item)}
                                      style={{
                                        borderBottom: "1px solid",
                                        cursor: "pointer",
                                      }}>
                                      {item?.part_no}
                                    </span>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                      textAlign:'right',
                                    }}>
                                    {item?.na_percent}
                                  </TableCell>
                                  {/* <TableCell
                                     sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                      display:'flex',
                                      alignItems:'center',
                                      justifyContent:'center',
                                    }}>
                                    {item?.na_percent > 20 ?
                                    <img
                                      title="Not Validated"
                                      src={notValid}
                                      style={{ height: "1.5rem", margin: "0.1rem" }}
                                      /> : <img
                                      title="Validated"
                                      src={validated}
                                      style={{ height: "1.5rem", margin: "0.1rem" }}
                                    /> }
                                  </TableCell> */}
                                </TableRow>
                              </>
                            );
                          }
                        )}
                    </TableBody>
                  </Table>
                  </Box> :
                  <Typography sx={{ padding: "0.5rem 0.5rem",
                  borderBottomColor: "primary.light",
                  textAlign:'center' }}>
                    No Data Available
                  </Typography>
                }
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
}
