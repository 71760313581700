// react
import React, { useRef, useState } from "react";

// vendors

import { useSelector } from "react-redux";

// services

// styles
import styles from "../../../ComponentUI/IdeaCreate/IdeaView/IdeaCalculatorInputRow.module.scss";

// interfaces and types


import { triangulationSelectors } from "../../../Redux/Reducers/triagulation.reducer";
import {
  calculatorInputBackgroundColorMap,
} from "../../../Configuration/Costing/calculator.constant";
import { TableCell, TableRow, TextField } from "@mui/material";
import { useAppDispatch } from "../../../../AppRouter";
import { toExponential } from "../../../utlis/toExponential";
// import { rejectedStatus } from "./IdeaViewpage";
import { UserContext } from "../../../../Components/Configuration/BOM/BomConfigurationLanding";

import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";

// import FlagFileUpload from ../Components/Core/CustomHooks/FlagFileUpload.tsx

// lazy

// ----------------------------------------------------------------------------------

interface CalculatorInputRowProps {
  inputCalculationData: any;
  onValueChange: (id:any,value: any) => void;
  userInputValue: any;
  resultValue?: any;
  inputId?:any
}

const BOMCalculatorInputRow: React.FC<CalculatorInputRowProps> = (props) => {
  const {
    inputCalculationData,
    onValueChange,
    userInputValue,
    resultValue,
    inputId
  } = props;
//   const rejectedAction = React.useContext<any>(rejectedStatus);
//   let rejectedStatusActionParams = rejectedAction && rejectedAction;
  const [flag, setflag] = useState();
  const [FlagLoader, setFlagLoader] = useState(false);
  const { copyStatus } = useRouteParams<any>();

  const [file, setFile] = useState();

  const [loading, setLoading] = useState(false);
  const [userInput, setUserInput] = useState(inputCalculationData?.value);

  const inputElRef = useRef<any>(null);

  const dispatch = useAppDispatch();
  const useraction = React.useContext<any>(UserContext);

  React.useEffect(() => {
    if (inputCalculationData) {
      setflag(inputCalculationData.flag);
      setFile(inputCalculationData.image_url);
    }
  }, [inputCalculationData.flag]);

  // ---------------------------------------------------------------------------------
  // selector

  // ----------------------------------------------------------------------------------
  //refs
  const imageViewerElRef = useRef<any>();
  //-----------------------------------------------------------------------------------
  // handlers

  const triangulationData: any = useSelector(triangulationSelectors.selectAll);

  const permisson = triangulationData[0]
    ? !triangulationData[0].allowed_action.includes("C" || "U")
    : false;

  const handleInputChange = (e: React.ChangeEvent) => {
    // @ts-ignore
    setUserInput(Number(e?.target?.value));
    // @ts-ignore
    onValueChange(inputId,Number(e?.target?.value));
  };

  // console.log(window.location.href, "pathname");


  // ----------------------------------------------------------------------------------
  // jsx abstractions

  const renderValue = () => {
    if (!inputCalculationData) return null;

    else if( inputCalculationData?.formula != null && inputCalculationData?.formula != "" ){
        return <p>{inputCalculationData?.value}</p>;
    }
    else if(!window.location.href.includes("view") && inputCalculationData?.content_type == null && inputCalculationData?.constant == null && inputCalculationData?.formula == null && inputCalculationData?.user_input != null && copyStatus == "false"){
        return (
            <TextField
              type="number"
              variant="standard"
              name={inputCalculationData.id}
              value={userInput}
            //   disabled={
            //       ( 
            //           useraction &&
            //           (useraction?.action?.includes("U")) ||
            //         (useraction && useraction?.action?.includes("C")))
            //       ? false
            //       : true
            //   }
              onChange={handleInputChange}
              sx={{
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
                "& .MuiInput-underline:before": { borderBottomColor: "white" },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
              }}
            />
          );
    }
    else{
        return toExponential(inputCalculationData.value || 0);
    }


    // switch (inputCalculationData.content_type) {
    //   case CALCULATOR_INPUT_TYPE.USER_INPUT:
    //     return (
    //       <TextField
    //         type="number"
    //         variant="standard"
    //         name={inputCalculationData.id}
    //         value={userInputValue}
    //         // disabled={
    //         //    IdeaType == "Client"
    //         //     ? true
    //         //     : !rejectedStatusActionParams && ( 
    //         //         useraction &&
    //         //         (useraction?.action?.includes("U")) ||
    //         //       (useraction && useraction?.action?.includes("C")))
    //         //     ? false
    //         //     : true
    //         // }
    //         onChange={handleInputChange}
    //         sx={{
    //           ".MuiInputBase-root.MuiInput-root": {
    //             fontSize: "1rem !important",
    //           },
    //           "& .MuiInput-underline:before": { borderBottomColor: "white" },
    //           "& .MuiInput-underline:after": {
    //             borderBottomColor: "primary.main",
    //           },
    //         }}
    //       />
    //     );

    //   case CALCULATOR_INPUT_TYPE.FORMULA:
    //     return <p>{resultValue}</p>;
    //   default:
    //     return toExponential(inputCalculationData.value || 0);
    // }
  };

  

  // ----------------------------------------------------------------------------------
  // jsx

  if (!inputCalculationData) return null;
  return (
    <TableRow data-testid="calculator-row" className={styles.container}>
      <TableCell
        sx={{
          padding: "0.2rem 0.5rem",
          borderBottomColor: "primary.light",
          backgroundColor:
            calculatorInputBackgroundColorMap[inputCalculationData.type],
        }}
      >
        {inputCalculationData.name}
      </TableCell>
      <TableCell
        sx={{
          padding: "0.2rem 0.5rem",
          borderBottomColor: "primary.light",
        }}
      >
        <span
          style={{ display: "flex", alignItems: "center" }}
          className={styles.popupValue}
        >
          <span>{renderValue()}</span>
          
        </span>
      </TableCell>
      <TableCell
        sx={{
          padding: "0.2rem 0.5rem",
          borderBottomColor: "primary.light",
          textAlign: "center",
        }}
      >
        {inputCalculationData.unit}
      </TableCell>
      {/* <div style={ { display: "none" } }>
                <RViewerJS>
                    <img src={ inputCalculationData?.image_url } alt="image" ref={ imageViewerElRef } />
                </RViewerJS>
            </div> */}
    </TableRow>
  );
};

// ----------------------------------------------------------------------------------

export default BOMCalculatorInputRow;
