import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Typography,
  Skeleton,
  Box,
  Pagination,
  TextField,
  InputAdornment,
  Popover,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { API } from "../../api-services";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useHistory } from "react-router-dom";
import { debounce } from "lodash";
import { LoadingButton } from "@mui/lab";
import { ADMIN } from "../../Redux/Services/admin.service";
import { useBaseModal } from "../SearchPage/useBaseModal";
import ActivityFilterModal from "../ActivityLogs/ActivityFilterModal";

interface RowData {
  id: number;
  title: string;
  query: string | null;
  activity_type: string;
  content_type: number | null;
  object_id: number | null;
  action_time: string;
  is_leaf: boolean;
  user_name: string | any;
  response_status: string | any;
  data: RowData[];
  activity_group: string | any;
}


const ActivityLogsClient = (props: any) => {

  function parseLog(input: string | null | undefined): { action: string; duration?: string; count?: number } | null {
    if (!input) {
        return {action:""}; // Handle null or undefined input
    }

    const regex = /^(.*?)(?: \| Duration: (\d+:\d+:\d+))?(?: \| Count: (\d+))?$/;
    const match = input.match(regex);

    if (match) {
        const [, action, duration, count] = match;
        return {
            action: action.trim(),
            duration: duration ? duration.trim() : undefined,
            count: count ? parseInt(count, 10) : undefined
        };
    }

    return null; // Handle invalid format or no matches
}

const getEmptyDataTitle = (type: any) => {
  switch (type) {
    case "session":
      return "Session";
    case "status":
      return "Success";
    case "group":
      return "Session";
      case "action":
        return "No title";
    default:
      return "No data";
  }
}

function truncateString(str?: string | null,lengthStr?:any,type?:any) {
  // Check if the string is null or undefined, return a placeholder or empty string
  if (!str) {
    return getEmptyDataTitle(type) // You can change "No data" to an empty string "" if you prefer
  }

  // Check if the string is longer than 8 characters
  if (str.length > lengthStr) {
    return `${str.slice(0, lengthStr)}..`;
  }

  // Return the original string if it's 8 characters or less
  return str;
}



  const { userId, activityType, subscriptionId, filterType } =
    useRouteParams<any>();
  const history = useHistory();
  const activityFilterModalopen = useBaseModal();
  const { activityTypeProps } = props;
  const today = new Date().toISOString().split("T")[0];
  const [data, setData] = useState<RowData[]>([]);
  const [query, setQuery] = useState<string | null | undefined>("");
  const [searchKeys, setSearchKeys] = useState<string | null | undefined>("");
  const [expanded, setExpanded] = useState<{ [key: number]: boolean }>({});
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [customEndDate, setCustomEndDate] = useState<string>("");
  const [customStartDate, setCustomStartDate] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dateRangeChild, setDateRangeChild] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [dateRange, setdateRange] = useState<any>({});
  const [loadingApply, setLoadingApply] = useState<boolean>(false);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [allDataCount, setdataCount] = useState<any>({});
  const getPayload = () => {
    let payload = {};
    // if (optionn?.subscription) {
    //   payload = { user_id: userId };
    // } else if (optionn?.cookies__uuid) {
    //   payload = { uuid: optionn.cookies__uuid };
    // }
    if (activityType !== "preloggedin") {
      payload = { user_id: userId };
    } else {
      payload = { uuid: userId };
    }
    return payload;
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApply = () => {
    setLoadingApply(true);
    API.get("/auth/export_activity_log/", { from: startDate, to: endDate }, 0)
      .then((res: any) => {
        window.open(res.data);
        setLoadingApply(false);
        handleClose();
        // const url = window.URL.createObjectURL(new Blob([res.data]));
        // const link = document.createElement('a');
        // link.href = url;
        // link.setAttribute('download', 'activity_logs.csv');
        // document.body.appendChild(link);
        // link.click();
      })
      .catch(() => {
        setLoadingApply(false);
        ADMIN.toast.error("Error in downloading file");
      });
  };

  React.useEffect(() => {
    setCurrentPage(1);
  }, [activityType]);

  const getAllCount = () => {
    API.get(
      "/auth/client_activity_log/",
      {
        get_logs_count: true,
        ...getPayload(),
        page: currentPage,
      },
      0
    )
      .then((res: any) => {
        setdataCount(res.data);
      })
      .catch(() => {});
  };

  React.useEffect(() => {
    getAllCount();
  }, [activityType, userId]);

  const fetchData = async (
    type: any,
    id: number | null
  ): Promise<RowData[]> => {
    const response = await API.get(
      "/auth/client_activity_log/",
      {
        ...getPayload(),
        page: currentPage,
        log_filter: typeof id === "string" ? type : filterType,
        search_key: typeof id === "string" ? id : "",
        ...dateRange,
      },
      0
    );
    setTotalPage(response.data?.total_page);
    return response.data?.data;
  };

  const handleExpandClick = async (row: RowData) => {
    setExpanded((prev) => ({ ...prev, [row.id]: !prev[row.id] }));

    if (!expanded[row.id] && !row.data.length) {
      const childData = await fetchData(activityTypeProps, row.id);
      setData((prevData) =>
        prevData.map((r) => (r.id === row.id ? { ...r, data: childData } : r))
      );
    }
  };
  const debouncedSearch = useCallback(
    debounce((value: any, type: any) => {
      setSearchKeys(value);
      // onSearch(value);
      const loadInitialData = async () => {
        setLoading(true);
        let initialData: any = [];
        if (userId != 0) {
          initialData = await fetchData(type, value);
        }
        setData(initialData);
        setLoading(false);
      };

      loadInitialData();
    }, 500), // Adjust debounce delay as needed
    []
  );

  const onSearchChange = (event: any) => {
    const value = event.target.value;
    setQuery(value);
    debouncedSearch(value, filterType);
  };
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    if (activityType === "preloggedin") {
      if (newValue === 0) {
        history.push(
          `/activitylogs/${activityTypeProps}/${userId}/${subscriptionId}/General`
        );
      } else if (newValue === 1) {
        history.push(
          `/activitylogs/${activityTypeProps}/${userId}/${subscriptionId}/Search`
        );
      } else if (newValue === 2) {
        history.push(
          `/activitylogs/${activityTypeProps}/${userId}/${subscriptionId}/Direct-Access`
        );
      } else {
        history.push(
          `/activitylogs/${activityTypeProps}/${userId}/${subscriptionId}/General`
        );
      }
    } else {
      if (newValue === 0) {
        history.push(
          `/activitylogs/${activityTypeProps}/${userId}/${subscriptionId}/General`
        );
      } else if (newValue === 1) {
        history.push(
          `/activitylogs/${activityTypeProps}/${userId}/${subscriptionId}/Search`
        );
      } else if (newValue === 2) {
        history.push(
          `/activitylogs/${activityTypeProps}/${userId}/${subscriptionId}/Payment`
        );
      } else if (newValue === 3) {
        history.push(
          `/activitylogs/${activityTypeProps}/${userId}/${subscriptionId}/Direct-Access`
        );
      } else {
        history.push(
          `/activitylogs/${activityTypeProps}/${userId}/${subscriptionId}/General`
        );
      }
    }

    // "/activitylogs/:activityTypeProps/:userId/:subscriptionId"
    setCurrentTab(newValue);
  };

  useEffect(() => {
    const loadInitialData = async () => {
      setLoading(true);
      let initialData: any = [];

      initialData = await fetchData(activityTypeProps, null);
      setData(initialData);
      setLoading(false);
    };

    loadInitialData();
  }, [
    activityTypeProps,
    userId,
    currentPage,
    filterType,
    subscriptionId,
    dateRange,
  ]);

  const renderRows = (rows: RowData[], depth = 0): React.ReactNode => {
    return (
      Array.isArray(rows) &&
      rows.map((row) => (
        <React.Fragment key={row.id}>
          <TableRow>
          <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                width:"12rem !important"
                // borderBottom: "1px solid #ddd",
              }}
            >
              {row.action_time}
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                // borderBottom: "1px solid",
                // borderColor: "#ddd",
                position: "relative",
                textAlign:'left',
                width: "1rem !important",
              }}
            >
              {!row.is_leaf ? (
                <Tooltip title={expanded[row.id] ? "" : ""} arrow>
                  <IconButton
                    onClick={() => handleExpandClick(row)}
                    aria-label="expand row"
                    size="small"
                    style={{
                      color: depth === 0 ? "green" : "#009688",
                      padding: "4px",
                      border: "none",
                    }}
                  >
                    {expanded[row.id] ? (
                      <RemoveCircleIcon fontSize="small" />
                    ) : (
                      <AddCircleIcon fontSize="small" />
                    )}
                  </IconButton>
                </Tooltip>
              ) : (
                depth == 0 && (
                  <IconButton
                    style={{ padding: "4px", border: "none" }}
                    size="small"
                    disabled
                  >
                    <AccessTimeIcon fontSize="small" />
                  </IconButton>
                )
              )}
              {/* Connector line */}
              {!row.is_leaf && (
                <>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: 0,
                      width: "100%",
                      height: "1px",
                      backgroundColor: getLineColor(depth),
                      zIndex: -1,
                      borderBottom: "1px solid #ddd",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "50%",
                      width: "1px",
                      height: "calc(100% + 1rem)",
                      backgroundColor: getLineColor(depth),
                      zIndex: -1,
                      borderLeft: "1px solid #ddd",
                    }}
                  />
                </>
              )}
            </TableCell>
            <TableCell
              style={{
                padding: "0.2rem 0.5rem",
                // borderBottom: "1px solid #ddd",
              }}
              title={parseLog(row.title)?.action}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: depth * 1,
                  columnGap: "0.5rem",
                }}
              >
                {row?.is_leaf && !expanded[row.id] && depth != 0 && (
                  <FiberManualRecordIcon sx={{ fontSize: "10px" }} />
                )}
                <Typography>{truncateString(parseLog(row.title)?.action,110,"action")}</Typography>
              </Box>
            </TableCell>
            <TableCell
              style={{
                padding: "0.2rem 0.5rem",
                // borderBottom: "1px solid #ddd",
              }}
            >
              {`${parseLog(row.title)?.duration || ""}`}
            </TableCell>
            <TableCell
              style={{
                padding: "0.2rem 0.5rem",
                // borderBottom: "1px solid #ddd",
              }}
            >
              {parseLog(row.title)?.count || ``}
            </TableCell>
            <TableCell
              style={{
                padding: "0.2rem 0.5rem",
                // borderBottom: "1px solid #ddd",
              }}
              title={row.activity_type}
            >
              {truncateString(row.activity_type,9,"session") || "Session wise"}
            </TableCell>
            <TableCell
              style={{
                padding: "0.2rem 0.5rem",
                // borderBottom: "1px solid #ddd",
              }}
              title={row.response_status}
            >
              {truncateString(row.response_status,9,"status") || `Success`}
            </TableCell>
            <TableCell
              style={{
                padding: "0.2rem 0.5rem",
                // borderBottom: "1px solid #ddd",
              }}
              title={row.activity_group}
            >
              {truncateString(row.activity_group,7,"group") || "Session"}
            </TableCell>
           
          </TableRow>
          {expanded[row.id] &&
            row.data.length > 0 &&
            renderRows(row.data, depth + 1)}
        </React.Fragment>
      ))
    );
  };

  const renderSkeletonRows = () => {
    return Array.from({ length: 5 }).map((_, index) => (
      <TableRow key={index}>
        <TableCell style={{ width: "2rem" }}>
          {/* @ts-ignore */}
          <Skeleton variant="circle" width={24} height={24} />
        </TableCell>
        <TableCell style={{ width: "60rem" }}>
          <Skeleton variant="text" width="80%" />
        </TableCell>
        <TableCell style={{}}>
          <Skeleton variant="text" width="60%" />
        </TableCell>
        <TableCell style={{}}>
          <Skeleton variant="text" width="40%" />
        </TableCell>
        <TableCell style={{}}>
          <Skeleton variant="text" width="50%" />
        </TableCell>
      </TableRow>
    ));
  };

  const getLineColor = (depth: number) => {
    switch (depth) {
      case 0:
        return "#00796b"; // Teal for level 0
      case 1:
        return "#004d40"; // Darker teal for level 1
      case 2:
        return "#a5d6a7"; // Light green for level 2
      default:
        return "#c8e6c9"; // Even lighter green for deeper levels
    }
  };

  return (
    <TableContainer
      component={Paper}
      sx={{ height: "84vh", width: "100%", marginTop: "2.1rem" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: "0.5rem",
        }}
      >
        {/* <Tabs
          sx={{
            height: "38px",
            backgroundColor: "#efefef",
            ".MuiButtonBase-root .MuiTab-root .Mui-selected": {
              backgroundColor: "primary.main ",
              color: "text.ContrastText",
            },
          }}
          value={currentTab}
          onChange={handleTabChange}
          aria-label="tabs"
        >
          <Tab
            sx={{
              backgroundColor: "#efefef",
              "& .MuiButtonBase-root .MuiTab-root .Mui-selected": {
                backgroundColor: "primary.main !important",
                color: "text.ContrastText !imporaant",
              },
            }}
            label={`General ( ${allDataCount?.general_logs_count || 0} )`}
          />
          <Tab
            sx={{
              backgroundColor: "#efefef",
              ".MuiButtonBase-root .MuiTab-root .Mui-selected": {
                backgroundColor: "primary.main",
                color: "text.ContrastText",
              },
            }}
            label={`Search-Based ( ${allDataCount?.search_logs_count || 0} )`}
          />
          {activityType !== "preloggedin" && (
            <Tab
              sx={{
                backgroundColor: "#efefef",
                ".MuiButtonBase-root .MuiTab-root .Mui-selected": {
                  backgroundColor: "primary.main",
                  color: "text.ContrastText",
                },
              }}
              label={`Payment Related ( ${
                allDataCount?.payment_logs_count || 0
              } )`}
            />
          )}
          {
            <Tab
              sx={{
                backgroundColor: "#efefef",
                ".MuiButtonBase-root .MuiTab-root .Mui-selected": {
                  backgroundColor: "primary.main",
                  color: "text.ContrastText",
                },
              }}
              label={`Access Via External website ( ${
                allDataCount?.direct_logs_count || 0
              } )`}
            />
          }
        </Tabs> */}
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            columnGap: "1rem",
          }}
        >
          <TextField
            label="Search"
            variant="outlined"
            value={query}
            onChange={onSearchChange}
            size="small"
            sx={{
              borderRadius: "8px",
              marginRight: "1rem",
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: "#00887A" }} />
                </InputAdornment>
              ),
            }}
          />
          {/* <LoadingButton
            variant="contained"
            onClick={(event: any) => setAnchorEl(event.currentTarget)}
          >
            Download
          </LoadingButton> */}
          <FilterAltIcon
          
            sx={{ color: "primary.main", cursor: "pointer",fontSize:"2rem" }}
            onClick={() => activityFilterModalopen.open()}
          />
          {activityFilterModalopen.isOpen && (
            <ActivityFilterModal
              Open={activityFilterModalopen.isOpen}
              Close={activityFilterModalopen.close}
              setdateRange={setdateRange}
              dateRangeChild={dateRangeChild}
              setDateRangeChild={setDateRangeChild}
              customEndDate={customEndDate}
              setCustomEndDate={setCustomEndDate}
              customStartDate={customStartDate}
              setCustomStartDate={setCustomStartDate}
            />
          )}
         
         
          {totalPage > 0 && (
            <Pagination
              count={totalPage}
              page={currentPage}
              size="small"
              onChange={(e, page) => setCurrentPage(page)}
              variant="outlined"
              sx={{ marginLeft: 2 }}
            />
          )}
        </Box>
      </Box>
      <div
        adl-scrollbar="true"
        adl-scrollbar-width="0.3"
        style={{ height: "84vh" }}
      >
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: "#009688" }}>
            <TableCell
                style={{
                  padding: "0.2rem 0.5rem",
                  color: "white",
                  
                  // borderBottom: "1px solid #ddd",
                }}
              >
              Date & Time
              </TableCell>
              <TableCell
                style={{
                  color: "white",
                  
                  // borderBottom: "1px solid #ddd",
                }}
              />
              <TableCell
                style={{
                  padding: "0.2rem 0.5rem",
                  color: "white",
                  // borderBottom: "1px solid #ddd",
                  width: "60rem",
                }}
              >
                Activity
              </TableCell>
              <TableCell
                style={{
                  padding: "0.2rem 0.5rem",
                  color: "white",
                  // borderBottom: "1px solid #ddd",
                }}
              >
                Duration
              </TableCell>
              <TableCell
                style={{
                  padding: "0.2rem 0.5rem",
                  color: "white",
                  // borderBottom: "1px solid #ddd",
                }}
              >
                Counts
              </TableCell>
 
              <TableCell
                style={{
                  padding: "0.2rem 0.5rem",
                  color: "white",
                  // borderBottom: "1px solid #ddd",
                }}
              >
                Activity Type
              </TableCell>
              <TableCell
                style={{
                  padding: "0.2rem 0.5rem",
                  color: "white",
                  // borderBottom: "1px solid #ddd",
                }}
              >
                Status
              </TableCell>
              <TableCell
                style={{
                  padding: "0.2rem 0.5rem",
                  color: "white",
                  // borderBottom: "1px solid #ddd",
                }}
              >
                Group
              </TableCell>
              {/* <TableCell
                style={{
                  padding: "0.2rem 0.5rem",
                  color: "white",
                  // borderBottom: "1px solid #ddd",
                }}
              >
                Action Date & Time
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              renderSkeletonRows()
            ) : data?.length > 0 ? (
              renderRows(data)
            ) : (
              <TableRow>
                <TableCell colSpan={5} style={{ textAlign: "center" }}>
                  No Data
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box p={2} className="date-range-picker">
            <Typography
              textAlign="center"
              variant="subtitle2"
              sx={{ marginBottom: "1rem", color: "primary.main" }}
            >
              Select Date Range
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              className="date-range-form"
            >
              <TextField
                label="Start Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="date-input"
                inputProps={{ max: today }}
              />
              <TextField
                label="End Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="date-input"
                inputProps={{ max: today }}
              />
              <LoadingButton
                onClick={handleApply}
                loading={loadingApply}
                variant="contained"
                className="apply-button"
              >
                Apply
              </LoadingButton>
            </Box>
          </Box>
        </Popover>
      </div>
    </TableContainer>
  );
};

export default ActivityLogsClient;
