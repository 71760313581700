import { Box, Button } from '@mui/material'
import React from 'react'

function EmailLogs() {
  return (
    <Box sx={{display:"flex", justifyContent:"center"}}>
        
        <Button variant='contained' sx={{marginTop:"2rem"}}>Send Email</Button>

   
    </Box>
  )
}

export default EmailLogs