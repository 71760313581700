import {
  Box,
  Button,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Skeleton,
  Tooltip,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import MessageIcon from "@mui/icons-material/Message";
import { useBaseParamsModal } from "../CustomHook/useBaseParamsModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { API } from "../api-services";
import PartVerifyandApproveModel from "./RequestedPartSection/PartVerifyandApproveModel";
import Popover from "@mui/material/Popover";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import SkeletonLoader from "../ComponentUI/SkeletonLoader/SkeletonLoader";
import { useHistory, useParams } from "react-router-dom";
import PartImageModel from "./RequestedPartSection/PartImageModel";
import LoadingButton from "@mui/lab/LoadingButton";
import bomIcon from "../Assets/images/hierarchy.png";
import PartRejctReasonModel from "./RequestedPartSection/PartRejctReasonModel";
import successIcon from "../Assets/images/success.png";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import LaunchIcon from "@mui/icons-material/Launch";
import Radio from "@mui/material/Radio";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import RadioGroup from "@mui/material/RadioGroup";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CDN_URL from "../utlis/CDN_URL";
import InfoIcon from "@mui/icons-material/Info";
import SupplierPriceDetailsModal from "../ui-reusable-component/SupplierPriceDetailsModal";
import CreateOtherCategoryModal from "./RequestedPartSection/CreateOtherCategoryModal";
import UpdateIcon from "@mui/icons-material/Update";
import { ADMIN } from "../Redux/Services/admin.service";
import ShowDescriptionModal from "./RequestedPartSection/ShowDescriptionModal";
import AddConflictPart from "./RequestedPartSection/AddConflictPart";
import ConflictViewModal from "./RequestedPartSection/ConflictViewModal";
import SingleChat from "../chat/SingleChat";

interface ICreatePartTableProps {}

interface Params {
  requestPage: any;
  requestType: any;
  supplierId: any;
  search_key: any;
  privateStatus: any;
}
export function CreatePartTable(props: ICreatePartTableProps) {
  const { requestPage, requestType, supplierId, search_key,privateStatus } =
    useParams<Params>();
  const addCategoryOpenModal = useBaseParamsModal();
  const addConflictpart = useBaseParamsModal();
  const showDescription = useBaseParamsModal();
  const showComments = useBaseParamsModal();
  const [loader, setLoader] = React.useState<boolean>(false);
  const [partData, setPartData] = React.useState<any>([]);
  const partImgModel = useBaseParamsModal();
  const supplierDetailsOpen = useBaseParamsModal();
  const partRejectModel = useBaseParamsModal();
  const partVerifyAndApproveModel = useBaseParamsModal();
  const partVerifyAndApproveModelConflict = useBaseParamsModal();
  const [approvePart, setApprovePart] = React.useState<any>();
  const [supplierData, setSupplierData] = useState<any>();
  const [supplierloader, setSupplierLoader] = useState<boolean>(false);
  const history = useHistory();
  const [comment, setComment] = useState<string>("");
  const [currentpart, setCurrentPart] = useState<any>();
  const [rejectLoader, setRejectLoader] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [anchorElSupplier, setAnchorElSupplier] =
    React.useState<HTMLElement | null>(null);
  const [rowData, setRowData] = React.useState<any>([]);
  const [rowDataSupplier, setRowDataSupplier] = React.useState<any>([]);
  const [deleteLoader, setDeleteLoader] = useState<boolean>(false);
  const [activeSupplier, setActiveSupplier] = useState<any>(
    sessionStorage.getItem("suppliername") || ""
  );

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement>,
    rowId: any
  ) => {
    setRowData(rowId);
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverOpenSupplier = (
    event: React.MouseEvent<HTMLElement>,
    rowId: any
  ) => {
    setRowDataSupplier(rowId);
    setAnchorElSupplier(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const handlePopoverCloseSupplier = () => {
    setAnchorElSupplier(null);
  };
  const open = Boolean(anchorEl);
  const openSupplier = Boolean(anchorElSupplier);

  // Pagination & search
  const [totalPagecount, settotalPagecount] = React.useState<any>();
  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    setLoader(true);
    API.get("cart/get_all_part_requests_for_internal/", {
      supplier_id: `${supplierId}`,
      total_page: true,
    })
      .then((res: any) => {
        settotalPagecount(res.data?.page_count);
        setLoader(false);
      })
      .catch((err: any) => {
        const { data } = err?.response;
        Swal.fire({
          icon: "error",
          html: `<div>
					<br />
					<p style="color:"red">${data.detail}</p>   
					</div>`,
        });
        setLoader(false);
      });
  }, []);

  const getRequestTypeString = (requestType: any) => {
    switch (requestType) {
      case "Add":
        return "Add new part";
      case "Edit":
        return "Edit existing part";
      case "Delete":
        return "Delete Part";
      default:
        return "";
    }
  };

  const [searchPartValue, setSearchPartValue] = useState<any>("");
  useEffect(() => {
    setSearchPartValue(searchPartValue == "0" ? "" : searchPartValue);
  }, [searchPartValue]);

  const searchPartAPICall = (event: any) => {
    if (event.key === "Enter") {
      handlePartSearchIcon();
    }
  };
  const handleOnChangePart = (event: any) => {
    setSearchPartValue(event.target.value);
  };

  const handleClearPartSearch = () => {
    setSearchPartValue("");
    supplierPartData();
  };

  const handlePartSearchIcon = () => {
    setLoader(true);
    API.get("cart/get_all_part_requests_for_internal/", {
      supplier_id: `${supplierId}`,
      filter: "",
      search_key: searchPartValue,
    })
      .then((res) => {
        setPartData(res.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        const { data } = err?.response;
        Swal.fire({
          icon: "error",
          html: `<div>
				<br />
				<p style="color:"red">${data[0]}</p>   
				</div>`,
        });
      });
  };

  const [selectFilter, setSelectFilter] = React.useState("All");
  const filterAddPartRequest = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectFilter(event.target.value);
  };

  const supplierPartData = () => {
    if (selectFilter == "All") {
      setLoader(true);
      API.get(
        "cart/get_all_part_requests_for_internal/",
        { supplier_id: `${supplierId}`, page_no: page },
        0
      )
        .then((res: any) => {
          setPartData(res.data);
          setLoader(false);
        })
        .catch((err: any) => {
          const { data } = err?.response;
          Swal.fire({
            icon: "error",
            html: `<div>
						<br />
						<p style="color:"red">${data.detail}</p>   
						</div>`,
          });
          setLoader(false);
        });
    } else {
      setLoader(true);
      API.get(
        "cart/get_all_part_requests_for_internal/",
        {
          supplier_id: `${supplierId}`,
          page_no: page,
          filter: selectFilter,
        },
        0
      )
        .then((res: any) => {
          let dataList = res?.data?.map((item: any, index: any) => {
            return { ...item, ids: (page - 1) * res.data?.length + index + 1 };
          });
          setPartData(dataList);
          setLoader(false);
        })
        .catch((err: any) => {
          const { data } = err?.response;
          Swal.fire({
            icon: "error",
            html: `<div>
								<br />
								<p style="color:"red">${data.detail}</p>   
								</div>`,
          });
          setLoader(false);
        });
    }
  };

  React.useEffect(() => {
    supplierPartData();
  }, [supplierId, page, selectFilter]);

  // const supplierPartData =()=> {
  // 	setLoader(true);
  // 	API.get('cart/get_all_part_requests_for_internal/', { supplier_id: `${supplierId}`, page_no: page}, 0)
  // 		.then((res: any) => {
  // 			setPartData(res.data);
  // 			setLoader(false);
  // 		})
  // 		.catch((err: any) => {
  // 			const { data } = err?.response;
  // 			Swal.fire({
  // 				icon: 'error',
  // 				html: `<div>
  // 				<br />
  // 				<p style="color:"red">${data.detail}</p>
  // 				</div>`,
  // 			});
  // 			setLoader(false);
  // 		});
  // 	};
  // React.useEffect(() => {
  // 	supplierPartData()
  // }, [supplierId]);

  const supplierPartFilterData = (supplier: any) => {
    sessionStorage.removeItem("supplierId");
    sessionStorage.setItem("supplierId", supplier.id);
    setActiveSupplier(supplier.business_name);
    sessionStorage.setItem("suppliername", supplier.business_name);
    history.push(`/requestPage/4/${supplier.id}/0`);
  };

  const columns: GridColDef[] = [
    {
      field: "actions",
      headerName: "Actions",
      width: 250,
      renderCell: (cellValues: any) => {
        return (
          <Box sx={{display:"flex", gap:"0.5rem", alignItems:"center"}}>
          {cellValues?.row?.claim_request_pending?  <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "1rem",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "red",
                width: "100%",
                "&:hover": {
                  backgroundColor: "red",
                },
              }}
              onClick={()=>history.push("/requestPage/3/0/0")}
              color="primary"
              size="small"
              title="Claim Request Pending"
            >
              Approve Business Request 
            </Button>
          </Box>:
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "1rem",
              width: "100%",
            }}
          >
            {cellValues.row.status !== "Rejected" &&
            !cellValues.row.conflicted &&
            cellValues.row.request_type !== "Delete" &&
            cellValues.row.show_approve ? (
              <Button
                variant="contained"
                disabled={cellValues.row.status === "Accepted" && true}
                sx={{
                  backgroundColor:
                    cellValues.row.status === "Accepted"
                      ? "green"
                      : cellValues.row.status === "In Progress"
                      ? "#ffc800"
                      : "primary.main",
                  "&:hover": {
                    backgroundColor:
                      cellValues.row.status === "Accepted"
                        ? "green"
                        : cellValues.row.status === "In Progress"
                        ? "#ffc800"
                        : "primary.main",
                  },
                  width: cellValues.row.status === "Accepted" ? "100%" : "7rem",
                }}
                onClick={() => openPartVerify(cellValues.row)}
                color="primary"
                size="small"
                title="Publish part to xcPROC"
              >
                {cellValues.row.status === "Accepted"
                  ? (cellValues.row.conflicted?"Approved Conflict":"Approved")
                  : cellValues.row.status === "In Progress"
                  ? "In Progress"
                  : "Approve"}
              </Button>
            ) : (
              ""
            )}
            {cellValues.row.status !== "Accepted" &&
              !cellValues.row.conflicted &&
              !cellValues.row.show_approve &&
              cellValues.row.status !== "Rejected" && (
                <LoadingButton
                  loading={cellValues.row.part === currentpart && rejectLoader}
                  variant="contained"
                  sx={{
                    backgroundColor: "primary.main",
                    width:
                      cellValues.row.status === "Rejected" ? "100%" : "6rem",
                    "&:hover": {
                      backgroundColor: "primary.main",
                    },
                    ".MuiCircularProgress-root": {
                      color: "primary.main",
                    },
                  }}
                  onClick={() => addCategoryOpenModal.open(cellValues.row)}
                  color="primary"
                  size="small"
                  title="Add other category"
                >
                  Category+
                </LoadingButton>
              )}

            {cellValues.row.status !== "Accepted" &&
              cellValues.row.conflicted &&
              cellValues.row.request_type !== "Delete" &&
              cellValues.row.status !== "Rejected" && (
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <Button
                    title="View Conflicted Request"
                    size="small"
                    sx={{ padding: "0.2rem 0.3rem" }}
                    variant="contained"
                    color="warning"
                    endIcon={<VisibilityIcon />}
                    onClick={() =>
                      partVerifyAndApproveModel.open(cellValues.row)
                    }
                  >
                    Conflict
                  </Button>
                  {/* <AddCircleIcon
                    sx={{ color: "primary.main", cursor: "pointer" }}
                    titleAccess="Add New Part"
                    onClick={()=>{
                      addConflictpart.open(cellValues.row)
                    }}
                  /> */}
                </Box>
              )}

            {cellValues.row.status !== "Accepted" &&
            cellValues.row.status !== "Rejected" &&
            cellValues.row.request_type !== "Delete" ? (
              <LoadingButton
                loading={cellValues.row.part === currentpart && rejectLoader}
                variant="contained"
                disabled={cellValues.row.status === "Rejected" && true}
                sx={{
                  backgroundColor:
                    cellValues.row.status === "Rejected" ? "#c5c5c5" : "red",
                  width: cellValues.row.status === "Rejected" ? "100%" : "6rem",
                  "&:hover": {
                    backgroundColor:
                      cellValues.row.status === "Rejected" ? "#c5c5c5" : "red",
                  },
                  ".MuiCircularProgress-root": {
                    color: "primary.main",
                  },
                }}
                onClick={() => rejectPartHandler(cellValues.row)}
                color="primary"
                size="small"
                title="Reject Part"
              >
                {cellValues.row.status === "Rejected" ? "Rejected" : "Reject"}
              </LoadingButton>
            ) : (
              cellValues.row.status !== "Accepted" &&
              cellValues.row.request_type !== "Delete" && (
                <LoadingButton
                  loading={cellValues.row.part === currentpart && rejectLoader}
                  variant="contained"
                  sx={{
                    backgroundColor: "primary.main",
                    width:
                      cellValues.row.status === "Rejected" ? "100%" : "6rem",
                    "&:hover": {
                      backgroundColor: "primary.main",
                    },
                    ".MuiCircularProgress-root": {
                      color: "primary.main",
                    },
                  }}
                  onClick={() => revertHandler(cellValues.row)}
                  endIcon={<UpdateIcon />}
                  color="primary"
                  size="small"
                  title="Reject Part"
                >
                  {"Revert"}
                </LoadingButton>
              )
            )}
            {cellValues.row.request_type === "Delete" ? (
              <LoadingButton
                loading={cellValues.row.part === currentpart && deleteLoader}
                variant="contained"
                disabled={cellValues.row.status === "Rejected" && true}
                sx={{
                  backgroundColor: "red",
                  width: "100%",
                  ".MuiCircularProgress-root": {
                    color: "primary.main",
                  },
                }}
                onClick={() => DeleteParthandler(cellValues.row)}
                color="primary"
                size="small"
                title="Delete Part"
              >
                Delete Part
              </LoadingButton>
            ) : (
              ""
            )}
          </Box>}


          <Tooltip title="Chat with requested user">
              <MessageIcon
                  sx={{
                    color: "#00887a",
                    fontSize: "1.5rem",
                    cursor: "pointer",
                    display: "grid",
                    placeItems: "center",
                  }}
                  onClick={()=> {
                    handleClickk();
                    setSelectedRequest(cellValues.row);
                  }}
                />
                </Tooltip>
          </Box>
        );
      },
    },
    {
      field: "action",
      headerName: "BOM",
      width: 80,
      headerAlign: "center",
      align: "center",
      renderCell(cellValues) {
        return (
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: "1rem" }}
          >
            {(cellValues.row.status === "Accepted" || cellValues?.row.vault_id) ? (
              <IconButton
                size="small"
                title="GO to BOM"
                onClick={() => {
                  sessionStorage.removeItem("BOMCreateNode");
                  sessionStorage.setItem(
                    "BOMCreateNode",
                    JSON.stringify(cellValues.row?.ancestors)
                  );
                  window.open(
                    `#/bomentry/${cellValues.row.vehicle_id}/${
                      cellValues.row.vehicle_category
                    }/0/${
                      cellValues.row.vault_id || cellValues.row.ancestors
                    }/${cellValues.row.abbreviation}/0/${
                      cellValues.row.is_leaf
                    }/false/${privateStatus}`
                  );
                }}
              >
                <img
                  src={bomIcon}
                  style={{
                    width: "1.5rem",
                    height: "auto",
                    margin: "0 auto",
                  }}
                />
              </IconButton>
            ) : (
              <Typography sx={{ width: "3rem" }}>-</Typography>
            )}
          </Box>
        );
      },
    },
    { field: "id", headerName: "S.No.", width: 50 },
    {
      field: "name",
      headerName: "Part Name",
      width: 160,
    },
    {
      field: "request_by",
      headerName: "Request By",
      width: 80,
      renderCell: (cellValues: any) => {
        return (
          <>
            {cellValues.row.quantity_pricing !== null &&
            cellValues.row.quantity_pricing !== undefined ? (
              <IconButton
                aria-owns={openSupplier ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={(event) =>
                  handlePopoverOpenSupplier(
                    event,
                    cellValues.row.quantity_pricing
                  )
                }
                onMouseLeave={handlePopoverCloseSupplier}
              >
                <VisibilityIcon
                  sx={{ cursor: "pointer", color: "text.primary" }}
                />
              </IconButton>
            ) : (
              <Typography sx={{ padding: "0 0.5rem" }}>-</Typography>
            )}
            <Popover
              elevation={1}
              id="mouse-over-popover"
              sx={{
                pointerEvents: "none",
              }}
              open={openSupplier}
              anchorEl={anchorElSupplier}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverCloseSupplier}
              disableRestoreFocus
            >
              <Table sx={{ width: "15rem" }}>
                {/* <TableHead sx={{ backgroundColor: "primary.light" }}>
                  <TableRow>
                    <TableCell sx={{ padding: "0.2rem 0.5rem" }}>
                      Quantity
                    </TableCell>
                    <TableCell sx={{ padding: "0.2rem 0.5rem" }}>
                      Price
                    </TableCell>
                  </TableRow>
                </TableHead> */}
                <TableBody>
                  {/* requested_by */}

                  <TableRow>
                    <TableCell sx={{ padding: "0.2rem 0.5rem" }}>
                      Name
                    </TableCell>
                    <TableCell sx={{ padding: "0.2rem 0.5rem" }}>
                      {cellValues?.row?.requested_by?.business_name || "--"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "0.2rem 0.5rem" }}>
                      Business type:
                    </TableCell>
                    <TableCell sx={{ padding: "0.2rem 0.5rem" }}>
                      {cellValues?.row?.requested_by?.business_type || "--"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "0.2rem 0.5rem" }}>
                      Business website:
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        textDecoration: "underline",
                      }}
                      onClick={() =>
                        window.open(
                          cellValues?.row?.requested_by?.business_website
                        )
                      }
                    >
                      {cellValues?.row?.requested_by?.business_website || "--"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "0.2rem 0.5rem" }}>
                      Business email:
                    </TableCell>
                    <TableCell sx={{ padding: "0.2rem 0.5rem" }}>
                      {cellValues?.row?.requested_by?.email || "--"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Popover>
          </>
        );
      },
    },
    {
      field: "description",
      headerName: "Part Description",
      width: 100,
      renderCell: (cellValues: any) => {
        return (
          <>
            <VisibilityIcon
              sx={{ cursor: "pointer" }}
              titleAccess={cellValues?.row?.description}
              onClick={() => showDescription.open(cellValues?.row)}
            />
          </>
        );
      },
    },
    {
      field: "request_type",
      headerName: "Request Type",
      width: 130,
      renderCell: (cellValues: any) => {
        return getRequestTypeString(cellValues.row.request_type);
      },
    },
    // {
    // 	field: 'supplier_name',
    // 	headerName: 'Supplier name',
    // 	width: 180,
    // },
    {
      field: "part_image",
      headerName: "Part Images",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
            <VisibilityIcon
              titleAccess="View Images"
              sx={{
                cursor: "pointer",
                opacity: cellValues.row.part_image?.length !== 0 ? 1 : 0.5,
              }}
              onClick={() => {
                if (cellValues.row.part_image?.length !== 0) {
                  openPartImages(cellValues.row);
                } else {
                }
              }}
            />
          </>
        );
      },
    },

    {
      field: "drawings",
      headerName: "Part Drawings",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
            <VisibilityIcon
              titleAccess={
                cellValues.row.drawings[0]?.url?.length !== 0
                  ? "View Drawings"
                  : "No Drawings"
              }
              sx={{
                cursor: "pointer",
                opacity:
                  cellValues.row.drawings[0]?.url?.length !== 0 ? 1 : 0.5,
              }}
              onClick={() => {
                if (cellValues.row.drawings[0]?.url?.length !== 0) {
                  openPartDrawings(cellValues.row.drawings);
                }
              }}
            />
          </>
        );
      },
    },
    {
      field: "cadDocument",
      headerName: "CAD File",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
            <VisibilityIcon
              titleAccess={
                cellValues.row.cadDocument[0]?.url?.length !== 0
                  ? "View CAD Documents"
                  : "No CAD Documents"
              }
              sx={{
                cursor: "pointer",
                opacity:
                  cellValues.row.cadDocument[0]?.url?.length !== 0 ? 1 : 0.5,
              }}
              onClick={() => {
                if (cellValues.row.cadDocument[0]?.url?.length !== 0) {
                  openPartCADDoc(cellValues.row.cadDocument);
                }
              }}
            />
          </>
        );
      },
    },
    {
      field: "quantity_pricing",
      headerName: "Pricing",
      width: 80,
      headerAlign: "center",
      align: "center",
      renderCell: (cellValues: any) => {
        return (
          <>
            {cellValues.row.quantity_pricing !== null &&
            cellValues.row.quantity_pricing !== undefined ? (
              <IconButton
                aria-owns={open ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={(event) =>
                  handlePopoverOpen(event, cellValues.row.quantity_pricing)
                }
                onClick={() => openSupplierDetails(cellValues.row)}
                onMouseLeave={handlePopoverClose}
              >
                <VisibilityIcon
                  sx={{ cursor: "pointer", color: "text.primary" }}
                />
              </IconButton>
            ) : (
              <Typography sx={{ padding: "0 0.5rem" }}>-</Typography>
            )}
            <Popover
              elevation={1}
              id="mouse-over-popover"
              sx={{
                pointerEvents: "none",
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Table sx={{ width: "15rem" }}>
                <TableHead sx={{ backgroundColor: "primary.light" }}>
                  <TableRow>
                    <TableCell sx={{ padding: "0.2rem 0.5rem" }}>
                      Quantity
                    </TableCell>
                    <TableCell sx={{ padding: "0.2rem 0.5rem" }}>
                      Price
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowData &&
                    rowData.map((item: any, index: number) => {
                      return (
                        <TableRow key={index}>
                          <TableCell sx={{ padding: "0.2rem 0.5rem" }}>
                            {item.volume}
                          </TableCell>
                          <TableCell sx={{ padding: "0.2rem 0.5rem" }}>
                            {item?.currency}{" "}
                            {!isNaN(item.price) ? (+item.price)?.toFixed(2) : 0}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Popover>
          </>
        );
      },
    },
    {
      field: "weight",
      headerName: "Weight (g)",
      type: "number",
      width: 80,
    },

    {
      field: "comments",
      headerName: "Comments",
      width: 100,
      renderCell: (cellValues: any) => {
        return (
          <>
            <VisibilityIcon
              sx={{
                cursor: "pointer",
                opacity: cellValues?.row?.comments?.length > 0 ? 1 : 0.5,
              }}
              titleAccess={cellValues?.row?.comments}
              onClick={() => {
                if (cellValues?.row?.comments?.length > 0) {
                  showComments.open(cellValues?.row);
                }
              }}
            />
          </>
        );
      },
    },
    {
      field: "verifiedby",
      headerName: "Reviewed By",
      width: 150,
    },
 

    
  ];

  const rows =
    partData &&
    partData?.map((item: any, index: number) => {
      return {
        idd: index + 1,
        id: item?.id,
        user_logo: item?.user_logo,
        room_id: item?.room_id,
        ID: item.id,
        name: item.name,
        supplier_name: item.supplier_name,
        email: item.supplier_email,
        phone_no: item.phone_no,
        company_name: item.company_name,
        status: item.status,
        createdby: item.created_by,
        description: item.description,
        quantity_pricing: item.quantity_pricing,
        weight: item.weight,
        componentsDetails: item.component_details,
        documents: item.docs,
        drawings: item.drawing,
        cadDocument: item.cad_document,
        comments: item.comments,
        verified: item.verified,
        verifiedby: item.verified_by,
        part_image: item.part_image,
        request_type: item.request_type,
        vehicle_id: item.vehicle_id,
        vehicle_category: item.vehicle_category,
        vault_id: item.vault_id,
        ancestors: item.ancestors,
        abbreviation: item.abbreviation,
        is_leaf: item.is_leaf,
        request_stage: item.request_stage,
        color_code: item.color_code,
        other_vehicle_category: item?.other_vehicle_category,
        other_part_category: item?.other_part_category,
        other_vehicle_or_part_category_name:
          item?.other_vehicle_or_part_category_name,
        show_approve: item?.show_approve,
        supplier: item?.supplier,
        other_name: item?.other_name,
        other_part_name: item?.other_part_name,
        vehicle_category_id: item?.vehicle_category_id,
        requested_by: item?.requested_by,
        conflicted: item?.conflicted,
        category_id: item?.category_id,
        known_sellers: item?.known_sellers,
        potential_sellers: item?.potential_sellers,
        claim_request_pending: item?.claim_request_pending
      };
    });

  const openPartImages = (colpart: any) => {
    partImgModel.open({
      images: colpart.part_image,
      supplier_name: colpart.supplier_name,
    });
  };
  const openSupplierDetails = (colpart: any) => {
    supplierDetailsOpen.open(colpart);
  };

  const openPartDrawings = (partdrawing: any) => {
    window.open(`${CDN_URL}.${partdrawing[0].url}`);
  };

  const openPartCADDoc = (partcad: any) => {
    window.open(`${CDN_URL}.${partcad[0].url}`);
  };

  const openPartVerify = (colpart: any) => {
    setApprovePart(colpart);
    if(colpart.conflicted){
    partVerifyAndApproveModel.open(colpart);
    }else{
      partVerifyAndApproveModelConflict.open(colpart);
    }
  };

  const [searchValue, setSearchValue] = useState<any>("");
  useEffect(() => {
    setSearchValue(searchValue == "0" ? "" : searchValue);
  }, [searchValue]);

  const searchAPICall = (event: any) => {
    if (event.key === "Enter") {
      handleSearchIcon();
    }
  };
  const handleOnChange = (event: any) => {
    setSearchValue(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchValue("");
    getSupplierData();
  };

  const handleSearchIcon = () => {
    setSupplierLoader(true);
    API.get("supplier/supplier_with_part/", { search_key: searchValue })
      .then((res) => {
        setSupplierData(res.data);
        setSupplierLoader(false);
      })
      .catch((err) => {
        setSupplierLoader(false);
        const { data } = err?.response;
        Swal.fire({
          icon: "error",
          html: `<div>
				<br />
				<p style="color:"red">${data[0]}</p>   
				</div>`,
        });
      });
  };

  const getSupplierData = () => {
    setSupplierLoader(true);
    API.get("supplier/supplier_with_part/", {}, 0)
      .then((res) => {
        setSupplierData(res.data);
        setSupplierLoader(false);
        let supplierId = sessionStorage.getItem("supplierId");
        if (supplierId == null && supplierData !== 0) {
          setActiveSupplier(res.data[0]?.business_name);
          sessionStorage.setItem("suppliername", res.data[0]?.business_name);
          history.push(`/requestPage/4/${res?.data[0]?.id}/0`);
        } else {
          history.push(`/requestPage/4/${supplierId}/0`);
        }
      })
      .catch((err) => {
        setSupplierLoader(false);
        const { data } = err?.response;
        Swal.fire({
          icon: "error",
          html: `<div>
			<br />
			<p style="color:"red">${data[0]}</p>   
			</div>`,
        });
      });
  };

  React.useEffect(() => {
    getSupplierData();
  }, []);

  const rejectPartHandler = (part: any) => {
    partRejectModel.open(part);
    setCurrentPart(part);
  };
  const revertHandler = (rowData: any) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "Do you want to revert this part?",
      customClass: {
        container: "swal2Container",
      },
      confirmButtonText: "Yes, Revert",
      showCancelButton: true,
    }).then((result: any) => {
      if (result.isConfirmed) {
        setLoader(true);
        API.put(
          "cart/get_all_part_requests_for_internal/",
          { revert_back: true, part_id: rowData.ID },
          0
        )
          .then((response: any) => {
            supplierPartData();
            Swal.fire({
              customClass: {
                container: "swal2Container",
              },
              html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
          <br /><br />
          <p style="color:#00887a;">Part Reverted Successfully</p>   
          </div>`,
            });
          })
          .catch((error: any) => {
            if (error && error.response) {
              Swal.fire({
                icon: "error",
                html: `<div>
              <br />
              <p style="color:"red">${error.response.data[0]}</p>   
              </div>`,
              });
            }
          });
      }
    });
  };

  React.useEffect(() => {
    if (comment !== "") {
      let rejectcomment = comment;
      rejectWithReason(currentpart, rejectcomment);
    }
  }, [comment]);

  const rejectWithReason = (part: any, rejectComment: any) => {
    setRejectLoader(true);
    API.put(
      "cart/get_all_part_requests_for_internal/",
      { reject: true, part_id: part.ID, comment: rejectComment },
      0
    )
      .then((res: any) => {
        API.get(
          "cart/get_all_part_requests_for_internal/",
          { supplier_id: `${supplierId}` },
          0
        )
          .then((res: any) => {
            setPartData(res.data);
            setRejectLoader(false);
            Swal.fire({
              customClass: {
                container: "swal2Container",
              },
              html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
					<br /><br />
					<p style="color:#00887a;">Part Rejected Successfully</p>   
						</div>`,
            });
          })
          .catch((err: any) => {
            const { data } = err?.response;
            Swal.fire({
              icon: "error",
              html: `<div>
						<br />
						<p style="color:"red">${data.detail}</p>   
						</div>`,
            });
            setRejectLoader(false);
          });
      })
      .catch((err: any) => {
        const { data } = err?.response;
        Swal.fire({
          icon: "error",
          html: `<div>
					<br />
					<p style="color:"red">${data.detail}</p>   
					</div>`,
        });
        setLoader(false);
      });
  };

  const DeleteParthandler = (part: any) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "Do you want to delete this part? Doing so will remove all BOM, Costing, ZBC & Scenario Costing. Once deleted, you will not be able to restore this part.",
      customClass: {
        container: "swal2Container",
      },
      confirmButtonText: "Yes, Delete",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setDeleteLoader(true);
        API.delete(
          "cart/get_all_part_requests_for_internal/",
          { part_id: part.ID, delete: true },
          0
        )
          .then((response) => {
            setDeleteLoader(false);
            Swal.fire({
              customClass: {
                container: "swal2Container",
              },
              html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
					<br /><br />
					<p style="color:#00887a;">Part Deleted Successfully</p>   
					</div>`,
            });
          })
          .catch((error) => {
            setDeleteLoader(false);
            console.log(error);
          });
      }
    });
  };

  const PaginationHandler = (type: any) => {
    if (type == "Next") {
      setPage((prev: any) => prev + 1);
    } else {
      setPage((prev: any) => prev - 1);
    }
  };


        // ------------drawer-----------------
        const [selectedRequest, setSelectedRequest] = React.useState<any>(null);
        const [openChatDrawer, setOpenChatDrawer] = React.useState(false);

        const handleClickk = () => {
          setOpenChatDrawer(true);
        };
      
        const handleClosee = () => {
          setOpenChatDrawer(false);
        };
      
        // -----------------------------

  return (
    <>
           {openChatDrawer && <SingleChat open={openChatDrawer}  handleClose={()=> {
supplierPartData();
setSelectedRequest(null);
handleClosee();
       }
        
        } request={selectedRequest} page_name="supplier_part" heading={`Part request for `} />}
      {partImgModel.isOpen && (
        <PartImageModel
          isOpen={partImgModel.isOpen}
          onCloseModal={partImgModel.close}
          partdetails={partImgModel.propsId}
          docType={"new"}
        />
      )}
      {showDescription.isOpen && (
        <ShowDescriptionModal
          isOpen={showDescription.isOpen}
          onCloseModal={showDescription.close}
          partdetails={showDescription.propsId}
          type={"Description"}
        />
      )}
      {showComments.isOpen && (
        <ShowDescriptionModal
          isOpen={showComments.isOpen}
          onCloseModal={showComments.close}
          partdetails={showComments.propsId}
          type={"comments"}
        />
      )}
      {/* showComments */}
      {supplierDetailsOpen.isOpen && (
        <SupplierPriceDetailsModal
          isOpen={supplierDetailsOpen.isOpen}
          onCloseModal={supplierDetailsOpen.close}
          partdetails={supplierDetailsOpen.propsId}
          supplier_name={activeSupplier}
        />
      )}

      {partRejectModel.isOpen && (
        <PartRejctReasonModel
          isOpen={partRejectModel.isOpen}
          onCloseModal={partRejectModel.close}
          partdetails={partRejectModel.propsId}
          setComment={setComment} // Pass the function setComment, not comment
        />
      )}

      {partVerifyAndApproveModel.isOpen && (
        <ConflictViewModal
          isOpen={partVerifyAndApproveModel.isOpen}
          onCloseModal={partVerifyAndApproveModel.close}
          partdetails={partVerifyAndApproveModel.propsId}
          supplierId={supplierId}
          updateSupplierData={supplierPartData}
        />
      )}
       {partVerifyAndApproveModelConflict.isOpen && (
        <PartVerifyandApproveModel
          isOpen={partVerifyAndApproveModelConflict.isOpen}
          onCloseModal={partVerifyAndApproveModelConflict.close}
          partdetails={partVerifyAndApproveModelConflict.propsId}
          supplierId={supplierId}
          updateSupplierData={supplierPartData}
        />
      )}

      {addCategoryOpenModal.isOpen && (
        <CreateOtherCategoryModal
          isOpen={addCategoryOpenModal.isOpen}
          onCloseModal={addCategoryOpenModal.close}
          cellData={addCategoryOpenModal.propsId}
          supplierPartData={supplierPartData}
        />
      )}
      {addConflictpart.isOpen && (
        <AddConflictPart
          isOpen={addConflictpart.isOpen}
          onCloseModal={addConflictpart.close}
          partdetails={addConflictpart.propsId}
          supplierPartData={supplierPartData}
        />
      )}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "24rem 1fr",
          columnGap: "0.5rem",
          height: { lg: "50vh", xl: "63vh" },
          width: "100%",
        }}
      >
        <Box sx={{ width: "100%", borderRight: "1px solid" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid",
              width: "100%",
              padding: "0.2rem 0.5rem",
            }}
          >
            <InputBase
              sx={{ ml: 1, width: "100%" }}
              placeholder="Search Supplier"
              inputProps={{ "aria-label": "Search Supplier" }}
              onChange={handleOnChange}
              onKeyDown={searchAPICall}
              value={searchValue}
            />
            {searchValue ? (
              <IconButton
                title="Clear Search"
                size="small"
                sx={{
                  padding: "3px",
                  cursor: "pointer",
                  color: "primary.main",
                  backgroundColor: "primary.light",
                  margin: "0 0.5rem",
                }}
                aria-label="clear-search"
                onClick={handleClearSearch}
              >
                <ClearIcon sx={{ fontSize: "1rem" }} />
              </IconButton>
            ) : (
              <Box sx={{ width: "3rem" }}></Box>
            )}
            <IconButton
              title="Search Supplier"
              type="button"
              onClick={handleSearchIcon}
              sx={{
                cursor: "pointer",
                color: "primary.main",
                padding: "0.2rem",
              }}
              aria-label="Search Supplier"
            >
              <SearchIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              Width: "100%",
              height: { lg: "45vh", xl: "60vh" },
              padding: "3px 0.5rem 0 0",
              marginLeft: "0.5rem",
              borderLeft: "1px dashed",
            }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            {supplierloader ? (
              <Box sx={{ Width: "100%", padding: "0 1rem" }}>
                <SkeletonLoader />
                <SkeletonLoader />
                <SkeletonLoader />
                <SkeletonLoader />
              </Box>
            ) : (
              supplierData &&
              supplierData.map((item: any, index: any) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "0.5rem",
                    pl: "0.5rem",
                    padding: "0.2rem 0.5rem 0.2rem 0",
                    borderTopRightRadius: 5,
                    borderBottomRightRadius: 5,
                    backgroundColor:
                      item.id == supplierId ? "primary.main" : "",
                    color: item.id == supplierId ? "white" : "",
                  }}
                  key={index}
                  onClick={() => supplierPartFilterData(item)}
                >
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      cursor: "pointer",
                      whiteSpace: "nowrap",
                    }}
                  >
                    --
                  </Typography>
                  <Typography
                    title={item.business_name}
                    sx={{
                      width: "100%",
                      fontSize: "1rem",
                      cursor: "pointer",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      textTransform: "capitalize",
                    }}
                  >
                    {item.business_name}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: "0.5rem",
                      width: "9rem",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "1rem",
                        backgroundColor: "#ffc800",
                        padding: "2px 5px",
                        color: "black",
                        lineHeight: 1,
                        borderRadius: "3px",
                      }}
                    >
                      {item.pending_parts}
                    </span>{" "}
                    |{" "}
                    <span
                      style={{
                        fontSize: "1rem",
                        backgroundColor:
                          item.id == supplierId ? "#8dd1ca" : "green",
                        padding: "2px 5px",
                        color: item.id == supplierId ? "black" : "white",
                        lineHeight: 1,
                        borderRadius: "3px",
                      }}
                    >
                      {item.accepted_parts}
                    </span>{" "}
                    |{" "}
                    <span
                      style={{
                        fontSize: "1rem",
                        backgroundColor:
                          item.id == supplierId ? "#f95959" : "#f95959",
                        padding: "2px 5px",
                        color: item.id == supplierId ? "white" : "white",
                        lineHeight: 1,
                        borderRadius: "3px",
                      }}
                    >
                      {item.rejected_parts}
                    </span>
                  </Box>
                </Box>
              ))
            )}
          </Box>
        </Box>
        <Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              columnGap: "1rem",
              padding: "0 1rem",
            }}
          >
            <Box
              title={activeSupplier}
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: { lg: "33%", xl: "45%" },
                textTransform: "capitalize",
              }}
            >
              {activeSupplier}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#e5e5e5",
                borderRadius: 1,
                width: { lg: "25rem", xl: "30rem" },
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search Part Requests"
                inputProps={{ "aria-label": "Search Part Requests" }}
                onChange={handleOnChangePart}
                onKeyDown={searchPartAPICall}
                value={searchPartValue}
              />
              {searchPartValue ? (
                <IconButton
                  title="Clear Search"
                  size="small"
                  sx={{
                    cursor: "pointer",
                    color: "primary.main",
                    backgroundColor: "primary.light",
                    marginRight: "0.5rem",
                  }}
                  aria-label="clear-search"
                  onClick={handleClearPartSearch}
                >
                  {" "}
                  <ClearIcon sx={{ fontSize: "1rem" }} />
                </IconButton>
              ) : (
                <Box sx={{ width: "3rem" }}></Box>
              )}
              <IconButton
                type="button"
                onClick={handlePartSearchIcon}
                sx={{
                  cursor: "pointer",
                  color: "primary.main",
                  padding: "0.2rem",
                }}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                columnGap: "1rem",
                position: "relative",
                width: "100%",
                zIndex: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                  position: "relative",
                  zIndex: 1,
                }}
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="All"
                    name="radio-buttons-group"
                    onChange={filterAddPartRequest}
                    value={selectFilter}
                  >
                    <FormControlLabel
                      defaultChecked
                      value="All"
                      control={<Radio />}
                      label="All"
                    />
                    <FormControlLabel
                      value="Not Started"
                      control={<Radio />}
                      label="Not Started"
                    />
                    <FormControlLabel
                      value="In Progress"
                      control={<Radio />}
                      label="In Progress"
                    />
                    <FormControlLabel
                      value="Accepted"
                      control={<Radio />}
                      label="Accepted"
                    />
                    <FormControlLabel
                      value="Rejected"
                      control={<Radio />}
                      label="Rejected"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <IconButton
                sx={{ cursor: page > 1 ? "pointer" : "not-allowed" }}
                disabled={page > 1 ? false : true}
                title="Previous Page"
                color="inherit"
                onClick={() => PaginationHandler("Previous")}
              >
                <ArrowCircleLeftIcon
                  sx={{ cursor: page > 1 ? "pointer" : "not-allowed" }}
                />
              </IconButton>
              {page}
              <IconButton
                sx={{
                  cursor: page < totalPagecount ? "pointer" : "not-allowed",
                }}
                disabled={page < totalPagecount ? false : true}
                title="Next Page"
                color="inherit"
                onClick={() => PaginationHandler("Next")}
              >
                <ArrowCircleRightIcon
                  sx={{
                    cursor: page < totalPagecount ? "pointer" : "not-allowed",
                  }}
                />
              </IconButton>
            </Box>
          </Box>

          <Box
            sx={{
              height: { lg: "53vh", xl: "57vh" },
              "& .super-app-theme--not_started": {
                bgcolor: "#fff",
              },
              "& .super-app-theme--accepted": {
                bgcolor: "#99dfbb",
              },
              "& .super-app-theme--in_progress": {
                bgcolor: "#ffffa3",
              },
              "& .super-app-theme--rejected": {
                bgcolor: "secondary.main",
              },
              "& .super-app-theme--conflicted": {
                bgcolor: "#ff00003b",
                // textDecoration: "line-through",
              },
            }}
          >
            <DataGrid
              headerHeight={38}
              rowHeight={38}
              rows={rows}
              columns={columns}
              loading={loader}
              getRowClassName={(params) =>
                `super-app-theme--${params.row.color_code}`
              }
              initialState={{
                pagination: {
                  //@ts-ignore
                  paginationModel: {
                    pageSize: 50,
                  },
                },
                rowHeight: 38,
              }}
              sx={{
                "& ::-webkit-scrollbar": {
                  width: "0.3rem",
                  height: "0.3rem",
                },
                "& ::-webkit-scrollbar-thumb": {
                  // background: $thumb-color;
                  backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                  borderRadius: "10px",
                },
                "& ::- webkit-scrollbar-thumb: hover ": {
                  // width: "4px",
                  backgroundColor: "#045DE9 !important",
                },
                "::-webkit-scrollbar-track": {
                  backgroundColor: "#e1e1f3 !important",
                },
                "&.MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  color: "text.primary",
                },
                "&.MuiDataGrid-root .MuiDataGrid-cell": {
                  borderBottomColor: "primary.light",
                },
              }}
              pageSizeOptions={[50]}
              disableRowSelectionOnClick
              disableSelectionOnClick
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}
