import * as React from "react";
import useWindowDimensions from "../../utlis/useWindowDimensions";
import Plotly from "plotly.js";
import createPlotlyComponent from "react-plotly.js/factory";
import { numberFormat } from "../../utlis/valueFormat";
import { API } from "../../api-services";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { Box } from "@mui/material";
import { CostingImageSection } from "./CostingImageSection";
import { CostingTableSection } from "./CostingTableSection";
import { useRouteMatch } from "react-router-dom";

export interface ICostingProductLevelGraphProps {
  productLevelData: any;
  count: any;
}

export default function CostingProductLevelGraph(
  props: ICostingProductLevelGraphProps
) {
  const Plot = createPlotlyComponent(Plotly);
  const {url}=useRouteMatch()
  const [imageData, setgImageData] = React.useState<any>({});
  const [bomData, setBomData] = React.useState<any>({});
  const { topVault ,scenarioId} = useRouteParams<any>();
  const { height, width } = useWindowDimensions();
  const { productLevelData, count } = props;
  const getImageData = () => {
    API.get(
      "/cost/costing_module/",
      {
        bom: true,
        top_vault: topVault,
        scenario:url.includes("scenario")?scenarioId:undefined
      },
      0
    )
      .then((res: any) => {
        setgImageData(res.data?.image);
        setBomData(res.data?.numeric);
      })
      .catch(() => { });
  };

  React.useEffect(() => {
    getImageData();
  }, [topVault]);
  return (
    <Box sx={{ height: "87vh" }}
      adl-scrollbar="true"
      adl-scrollbar-width="0.3" >
      {productLevelData?.length>2 && <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '0.5rem' }}>
        <div style={{ transform: 'rotate(270deg)', color: 'black', width: '1rem', fontSize: '12px', whiteSpace: 'nowrap' }}>{`Currency (${sessionStorage.getItem("currency")})`}</div>
        <Plot
          // label={{ value: `Currency(${sessionStorage.getItem("currency")})`, angle: -90, position: 'middle',dx:-12 }}
          data={
            productLevelData &&
            productLevelData?.map((values: any) => {
              return {
                x: [values.name?.toUpperCase()],
                y: [values.cost],
                type: "bar",
                text: ` ${values.cost?.toString()}`,
                textposition: "top",
                hoverinfo: `x+y`,
                textangle: -90,
                name: values.abbreviation,               
                marker: { color: "orange", },
              };
            })
          }

          layout={{
            xaxis: {
              visible: true,
              showticklabels: true,
              tickangle: -90,
              fixedrange: true,
              rangemode: "tozero",
              // @ts-ignore
              textTransform: "uppercase"
                // title: {
                //    text: productLevelData && productLevelData?.slice(0).reverse()?.map( ( values: any ) =>values.abbreviation).toString(),
                // },
              },

              yaxis: {
                fixedrange: false,
                rangemode: "tozero",
                showticklabels: true,
                 // @ts-ignore
              textTransform: "uppercase"
                // label:{ value: `Currency(${sessionStorage.getItem("currency")})`, angle: -90, position: 'middle',dx:-12 }

              },
              //    height: 310,
              //    width: 500,
              autosize: true,
              height: height <= 768 ? 350 : 350,
              width: width <= 1366 ? 460 : 710,
              margin: {
                l: 30,
                t: 10,
                r: 10,
                b: 135,
                pad: 0,
              },
              hoverlabel: {
                bordercolor: 'transparent',
                font: { color: 'black' }
              },
              font: {
                //@ts-ignore
                size: "10",
                color: 'black',
                textTransform: 'capitalize',
              },
              showlegend: false,
              //    legend: { "orientation": "t" }
            }
          }
          config={{
            modeBarButtonsToRemove: [
              "zoom2d",
              "pan2d",
              "select2d",
              "lasso2d",
              "zoomIn2d",
              "zoomOut2d",
              "autoScale2d",
              "resetScale2d",
              "hoverClosestGl2d",
              "hoverClosestPie",
              "toggleHover",
              "resetViews",
              "sendDataToCloud",
              "hoverClosestCartesian",
              "hoverCompareCartesian",
              "toggleSpikelines",
              "togglehover",
              "zoom3d",
            ],
            displaylogo: false,
          }}
        />
      </div>}
      <Box sx={{ padding: '0 1rem' }}>
        <CostingImageSection imageData={imageData && imageData} />
        <CostingTableSection bomData={bomData && bomData} />
      </Box>
    </Box>
  );
}
