import { Box, Button, Container, Icon, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { API } from '../../api-services';
import CloseIcon from '@mui/icons-material/Close';


import { Close, Done, History } from "@mui/icons-material";
const ProgressBadge = styled("div")({
    backgroundColor: "#f44336", // Red color for expired
    color: "#fff",
    padding: "4px 8px",
    borderRadius: "12px",
    fontWeight: "bold",
    textAlign: "center",
  });

  const CustomContainer = styled(Container)`
  max-width: 100% !important;
  padding: 0 !important;
`;
  
  const ProgressBadgeCurrent = styled("div")({
    backgroundColor: "#00887A", // Color for current
    color: "#fff",
    padding: "4px 8px",
    borderRadius: "12px",
    fontWeight: "bold",
    textAlign: "center",
  });

interface Subscription {
    id: number;
    subscription: string;
    starts_at: string;
    ends_at: string;
    current: boolean;
    duration: number;
  }

  const IconContainer = styled(Box)({
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "2rem",
    height: "2rem",
  });

  const calculateRemainingDays = (endDate: string) => {
    const today = new Date();
    const end = new Date(endDate);
    const differenceInTime = end.getTime() - today.getTime();
    return Math.ceil(differenceInTime / (1000 * 3600 * 24));
  };
  

function BusinessOwnerDetailsModal({openModal, handleCloseModal, userDetails, userId}: any) {
    const [subscriptiondetails, setSubscriptionDetails] = useState<Subscription[]>(
        []
      );
    const getSubscriptionDetails = () => {
        API.get("/auth/get_user_subscription_history/", { user_id: userDetails?.user_id }, 0)
          .then((res: any) => {
            setSubscriptionDetails(res.data);
          })
          .catch((err: any) => {});
      };
    
      useEffect(() => {
        if (userDetails?.user_id) {
          getSubscriptionDetails();
        }
        console.log(userDetails?.user_id);
      }, []);

     
     
      
  return (
    <Modal
    open={openModal}
    onClose={handleCloseModal}
    aria-labelledby="user-details-modal"
    aria-describedby="user-details-description"
  >
    <Box
      sx={{
        
        width: "70vw",
        mx: "auto",
        mt: "10%",
        bgcolor: "background.paper",
        borderRadius: 2,
      }}
    >
     <Box sx={{display:"flex", justifyContent:"space-between", marginBottom:"1rem", borderBottom:"0.1px solid #d7e0e0", p: 2}}>
        <div/>
     <Typography id="user-details-modal" variant="h6" component="h2" sx={{fontWeight:"600"}}>
        User Details
      </Typography>

      <CloseIcon onClick={handleCloseModal} sx={{cursor:"pointer"}}/>
      
     </Box>
<Box >


</Box>
     <Box sx={{display:"flex", justifyContent:"space-between", marginTop:"1rem", p: 2}}>
     <Typography variant="body1" sx={{width:"32%"}}>
        <strong>Name:</strong> {userDetails.name}
      </Typography>
      <Typography variant="body1" sx={{width:"32%"}}>
        <strong>Email:</strong> {userDetails.email}
      </Typography>
      <Typography variant="body1" sx={{width:"32%"}}>
        <strong>Phone:</strong> {userDetails.phone_no}
      </Typography>
     </Box>
     
      <Box sx={{marginTop:"1rem", p: 2}}>

      <Box sx={{display:"flex", justifyContent:"space-between"}}>
      <Typography variant="h6"  sx={{ mt: 2, marginBottom:2 }}>
        Subscription History
      </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 2, mb: 2 }}
     onClick={()=> {
     
      const url = `/#/activitylogs/sellers/${userDetails?.user_id}/0/General`;
  
      // Open the URL in a new tab
      window.open(url, '_blank');
     }}
        >
          View Activity Log
        </Button>
      </Box>

     
      <CustomContainer sx={{maxWidth: "100%", padding:"0"}}>
          <TableContainer
            component={Paper}
            sx={{ maxHeight: "calc(60vh - 80px)", maxWidth: "100%", padding:"0" }}
            adl-scrollbar="true"
          adl-scrollbar-width="0.3"
          >
            <Table>
              <TableHead>
                <TableRow sx={{background:"#008080"}}>
                  <TableCell sx={{ color: 'white' }} align="center">Subscription</TableCell>
                  <TableCell sx={{ color: 'white' }} align="center">Start Date</TableCell>
                  <TableCell sx={{ color: 'white' }} align="center">End Date</TableCell>
                  <TableCell sx={{ color: 'white' }} align="center">Duration (Days)</TableCell>
                  <TableCell sx={{ color: 'white' }} align="center">Remaining Days</TableCell>
                  <TableCell sx={{ color: 'white' }} align="center">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subscriptiondetails.map((sub, index) => {
                  const remainingDays: any = sub.current
                    ? calculateRemainingDays(sub.ends_at)
                    : null;
                  const expired =
                    !sub.current ||
                    remainingDays === null ||
                    remainingDays <= 0;
                  const progressValue = sub.current
                    ? (100 * (sub.duration - remainingDays)) / sub.duration
                    : 100;

                  return (
                    <TableRow key={sub.id}>
                      <TableCell align="center">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: "0.5rem",
                            width: "100%",
                            position: "relative",
                          }}
                        >
                          <IconContainer>
                            {/* {index < subscriptiondetails.length - 1 && (
                              <ConnectorLine />
                            )} */}
                            {expired ? (
                              <Icon fontSize="small" color="error">
                                <History sx={{ fontSize: '1.3rem' }} />
                              </Icon>
                            ) : (
                              <Icon fontSize="small" color="success">
                                <Done sx={{ fontSize: '1.3rem' }} />
                              </Icon>
                            )}
                          </IconContainer>
                          {sub.subscription}
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        {new Date(sub.starts_at).toLocaleDateString()}
                      </TableCell>
                      <TableCell align="center">
                        {new Date(sub.ends_at).toLocaleDateString()}
                      </TableCell>
                      <TableCell align="center">{sub.duration}</TableCell>
                      <TableCell align="center">
                        {remainingDays !== null ? remainingDays : 'N/A'}
                      </TableCell>
                      <TableCell align="center">
                        {expired ? (
                          <ProgressBadge>Expired</ProgressBadge>
                        ) : (
                          <ProgressBadgeCurrent>Active</ProgressBadgeCurrent>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </CustomContainer>
      </Box>
     
   
     
    </Box>
  </Modal>
  )
}

export default BusinessOwnerDetailsModal