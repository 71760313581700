import { memo, useEffect, useMemo, useRef, useState } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
// web.cjs is required for IE11 support

import { TransitionProps } from '@mui/material/transitions';
import { animated, useSpring } from '@react-spring/web';
import { isNull, isUndefined, result } from 'lodash';
import openFolder from '../../../Assets/images/svgs/openfolderblue.svg';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FolderIcon from '@mui/icons-material/Folder';
import styles from '../BOMCreate/BOMEntry/BOMTreeViewCreate.module.scss'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import swal from 'sweetalert';
import { useBaseModal } from '../SearchPage/useBaseModal';
import { API } from "../../api-services";
import { useDispatch, useSelector } from 'react-redux';

import { DialogComponent } from '../../utlis/DialogComponent';

import React from 'react';
import { Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

function TransitionComponent(props: TransitionProps) {
	const style = useSpring({
		from: {
			opacity: 0,
			transform: 'translate3d(20px,0,0)',
			display: 'none',
		},
		to: {
			opacity: props.in ? 1 : 0,
			transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
			display: 'block',
		},
	});

	return (
		<animated.div style={style} className='Tempdekho'>
			<Collapse {...props} />
		</animated.div>
	);
}

const StyledTreeItem = styled((props: TreeItemProps) => (
	<TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
	[`& .${treeItemClasses.iconContainer}`]: {
		'& .close': {
			opacity: 0.3,
		},
	},
	[`& .${treeItemClasses.group}`]: {
		marginLeft: 10,
		paddingLeft: 8,
		borderLeft: `1px dashed ${theme.palette.text.primary}`,
	},
}));

interface TreeViewComponentsProps {
	HierarchyData: any;
	sourceID: any;
	checkboxvalue: any;
	setcheckboxvalue: any;
	setParentsCounter?: any;
}
const CopyPartHeirarchyCosting = (props: TreeViewComponentsProps) => {
	const {
		HierarchyData,
		sourceID,
		setParentsCounter,
		checkboxvalue,
		setcheckboxvalue,
	} = props;
	const {
		projectId,
		topVault,
		vault,
		leafNode,
		treeabbr,
		scenarioId,
		projectName,
		workstation,
		isLeaf,
	} = useParams<any>();
	const { url } = useRouteMatch();
	const openStatusContent = useBaseModal();
	const [activeId, setActiveId] = React.useState<any>(null);
	const [contentText, setContentText] = React.useState<any>('');
	const [iconText, setIconText] = React.useState<any>('');
	const [HierarchyAllData, setHierarchyAllData] = React.useState<any>(null);
	const [loader, setLoader] = React.useState<any>(false);
	const [opendailog, setOpendailog] = React.useState<any>(false);
	const [TopVaultsfetched, setTopVaultsfetch] = useState<any>([]);
	const [menuItems, setMenuItems] = React.useState<any>();
	const [validatedModules, setValidatedModules] = React.useState<any>([]);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const [ModalData, setModalData] = useState<any>(null);
	const [PartAssemblyData, setPartAssemblyData] = React.useState<any>([]);
	const [newVault, setNewvault] = useState<any>();
	const [PartLoader, setPartLoader] = React.useState<any>(false);
	const imageIframeRef = useRef<any>(null);
	const [contentRef, setContentRef] = React.useState<any>();
	const sheetview = useBaseModal();
	const AddBOMPartModal = useBaseModal();
	const [QRLoader, setQRLoader] = useState<any>(false);
	const [SystemData, setSystemData] = useState([]);
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleClick = (
		event: React.MouseEvent<HTMLButtonElement>,
		idd: any,
		is_leaf: any,
		data: any
	) => {
		event.preventDefault();
		event.stopPropagation();
		setModalData(data);
		sessionStorage.setItem('TargetIds', idd);
		setAnchorEl(event.currentTarget);
		// setAnchorPointBOMCreate({ x: event.clientX, y: event.clientY });
		// setOpenContext(true);
		// getMenuItems(event.currentTarget, idd, is_leaf, data?.abbreviation);
	};

	const getVault = (treeabbr: any, idd: any) => {
		if (treeabbr == 'product') {
			return { top_vault: idd };
		} else {
			return { vault: idd };
		}
	};

	const [expanded, setExpanded] = React.useState<any>([parseInt(topVault)]);
	const dispatch = useDispatch();
	var random: any = Math.floor(Math.random() * 1000000 + 1);
	const store: any = useSelector((state) => state);
	const [TopLevel, setTopLevel] = React.useState();
	const [titleString, setTitleString] = React.useState<any>('');
	const [selected, setSelected] = React.useState<any>(topVault);

	const history = useHistory<any>();

	// useEffect(()=>{return sessionStorage.setItem("BOMCreateNode",JSON.stringify([Number(topVault)]));},[topVault])

	const handleChange = (e: any, nodeId: any, abbreviation: any) => {
		let a: any = sessionStorage.getItem('CostingCreateNode');
		let val: any = JSON.parse(a) || [];
		if (val?.includes(nodeId)) {
			let noDuplicate: any = val?.filter((item: any) => {
				return item != nodeId;
			});
			let b: any = sessionStorage.setItem(
				'CostingCreateNode',
				JSON.stringify(noDuplicate)
			);
		} else {
			val?.push(nodeId);
			let b: any = sessionStorage.setItem('CostingCreateNode', JSON.stringify(val));
		}
		setActiveId(nodeId);

		// onSelectCallback(nodeId, abbreviation, HierarchyAllData, HierarchyAllData.id);
		const index = expanded.indexOf(nodeId);

		const copyExpanded = [...expanded];
		if (index === -1) {
			copyExpanded.push(nodeId);
		} else {
			copyExpanded.splice(index, 1);
		}
		setExpanded(copyExpanded);
		setSelected(copyExpanded);
		// if (url?.includes('false')) {
		// 	if (topVault == nodeId) {
		// 		API.get(
		// 			'customer/view_data_activity/',
		// 			{
		// 				top_vault: nodeId,
		// 			},
		// 			0
		// 		).then((res: any) => {});
		// 	} else {
		// 		API.get(
		// 			'customer/view_data_activity/',
		// 			{
		// 				vault: nodeId,
		// 			},
		// 			0
		// 		).then((res: any) => {});
		// 	}
		// }
	};
	

	useEffect(() => {
		let a: any = sessionStorage.getItem('CostingCreateNode');
		let val: any = JSON.parse(a);
		if (val != null) {
			setExpanded(val);
			setSelected(val);
		}
	}, [topVault, sessionStorage.getItem('CostingCreateNode')]);

	useEffect(() => {
		setHierarchyAllData(HierarchyData && HierarchyData);
	}, [HierarchyData]);
	let CopyId = sessionStorage?.getItem('copyId');

	// @ts-ignore
	let matchData = JSON?.parse(CopyId);
    
	useEffect(() => {
		setTimeout(
			() =>
				document.getElementById(vault)?.scrollIntoView({
					block: 'nearest',
					inline: 'start',
					behavior: 'smooth',
				}),
			1000
		);
		// @ts-ignore
	}, [vault]);

	const getColor = (status: any) => {
		switch (status) {
			case 0:
				return 'red';
			case 1:
				return '#007fff';
			case 2:
				return '#007fff';
			default:
				return 'green';
		}
	};

	const CheckboxHandler = (e: any, data: any) => {
		// setcheckboxvalue([])
		e?.stopPropagation();
        if(checkboxvalue.includes(data.id)){
            const newCheckboxValue = checkboxvalue.filter((item:any) => item !== data.id)
            setcheckboxvalue([...newCheckboxValue]);
        }
        else{
            setcheckboxvalue([...checkboxvalue, data.id]);
        }
		
	};
    
	return (
		<>
			<TreeView
				expanded={expanded}
				key={HierarchyAllData?.id}
				id={HierarchyAllData?.id}
				defaultParentIcon={
					<FolderIcon sx={{ color: HierarchyAllData?.color }} />
				}
				className={styles.treetemp}
				defaultCollapseIcon={
					HierarchyAllData &&
					HierarchyAllData?.is_leaf == false && (
						<FolderOpenIcon style={{ color: HierarchyAllData?.color }} />
					)
				}
				defaultExpandIcon={
					HierarchyAllData?.is_leaf == false && (
						<FolderIcon sx={{ color: HierarchyAllData?.color }} />
					)
				}
				defaultEndIcon={
					HierarchyAllData?.is_leaf ? (
						<InsertDriveFileIcon sx={{ color: HierarchyAllData?.color }} />
					) : (
						<FolderIcon sx={{ color: HierarchyAllData?.color }} />
					)
				}
				onNodeSelect={(e: any) =>
					handleChange(e, HierarchyAllData?.id, HierarchyAllData?.abbreviation)
				}
				selected={selected}
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup='true'
				aria-expanded={open ? 'true' : undefined}
				sx={{
					'&.MuiTreeItem-content{}.Mui-selected': {
						backgroundColor:
							HierarchyAllData?.id == vault
								? '#cee6fa !important'
								: 'white !important',
					},
				}}>
				<StyledTreeItem
					data-index={HierarchyAllData?.id}
					nodeId={HierarchyAllData?.id}
					id={HierarchyAllData?.id}
					// onMouseEnter={() =>
					//   hoverData(
					//     `${HierarchyAllData?.part_no}  ${HierarchyAllData?.name}`,
					//     HierarchyAllData.id
					//   )
					// }

					label={
						<Typography className={styles.treeViewData}>
							<Typography
								style={{
									fontSize: '12px',
									color: getColor(HierarchyAllData?.cost_status),
								}}
								className={styles.treeViewText}
								onContextMenu={(event: any) =>
									handleClick(
										event,
										HierarchyAllData.id,
										HierarchyAllData.is_leaf,
										HierarchyAllData
									)
								}>
								{HierarchyAllData?.part_no} x {HierarchyAllData?.quantity}
								<br /> {HierarchyAllData?.name}
							</Typography>
							{HierarchyAllData?.is_leaf == true &&

							HierarchyAllData?.cost_status === 0 &&
								HierarchyAllData?.abbreviation !== 'product' && 
                                sourceID !== HierarchyAllData?.id &&
                                (
									<Checkbox
										style={{ padding: '3px' }}
										checked={
											checkboxvalue?.includes(HierarchyAllData?.id)
												? true
												: false
										}
										onClick={(e: any) => CheckboxHandler(e, HierarchyAllData)}
										sx={{
											color: 'primary.main',
										}}
                                        
									/>
								)}
						</Typography>
					}>
					{HierarchyAllData &&
						HierarchyAllData?.subsystem?.map(
							(subsystemData: any, index: any) => {
								return (
									<>
										<CopyPartHeirarchyCosting
											HierarchyData={subsystemData}
											key={subsystemData?.id}
											checkboxvalue={checkboxvalue}
											setcheckboxvalue={setcheckboxvalue}
											sourceID={sourceID}
											setParentsCounter={setParentsCounter}
										/>
									</>
								);
							}
						)}
				</StyledTreeItem>
			</TreeView>
			<DialogComponent
				isOpen={openStatusContent.isOpen}
				onClose={openStatusContent.close}
				content={contentText}
				type={iconText}
			/>
		</>
	);
};

export default CopyPartHeirarchyCosting;
