
// -------------------------------------------------------------------------------------

import { API } from "../../api-services";
import { ID } from "../../utlis/commonInterfaces";

const url = "cost/assumption/";
const baseUrl = "/sell/assumption";
export interface GetAssumptionCardByIdArg {
    id: ID;
    /** indicates whether the location & revision keys has to be populated by instances or not */
    populate?: boolean;
}
// -------------------------------------------------------------------------------------

export default {
 
    getById ( arg: GetAssumptionCardByIdArg ) {
        return API.get( url, { id: arg.id, details: arg.populate || undefined } );
    },
 


  
};
