import * as React from 'react';
import { Box, Checkbox, IconButton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory, useRouteMatch } from 'react-router-dom';

import Button from '@mui/material/Button';

import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';

import JoditEditor from 'jodit-react';
import { useEffect, useRef, useState } from 'react';
import useWindowDimensions from '../../ui-reusable-component/useWindowDimensions';
import ReactDOMServer from 'react-dom/server';
import { useBaseModal } from '../../ComponentUI/SearchPage/useBaseModal';
import ShareCostingModel from './ShareCositngModel';
import WatermarkModel from './WatermarkModel';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { API } from '../../api-services';
import { AUTH } from '../../Redux/Services/auth.service';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import VerifiedIcon from '@mui/icons-material/Verified';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

// import swal from "sweetalert";
import Swal from 'sweetalert2';
import successIcon from '../../Assets/images/success.png';

import './CostingConfigurationGuidelines.scss';
import { useDocumentTitle } from '../../ui-reusable-component/useDocumentTitle';
import { ADMIN } from '../../Redux/Services/admin.service';
import { Rectangle } from 'recharts';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export interface ICostingConfigurationGuidelinesProps {}

function CostingConfigurationGuidelines(
	props: ICostingConfigurationGuidelinesProps
) {
	const history = useHistory();
	useDocumentTitle('Costing Config');
	const editorConfig = {
		style: {
			padding: '20px',
			width: '100%',
			height: '74vh',
		},
	};
	const { url } = useRouteMatch();
	const [saveCount, setSaveCount] = React.useState<any>(0);
	const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
	const [content, setContent] = useState<any>('');
	const [contentDataCount, setContentDataCount] = React.useState<any>();
	const [loading, setLoading] = React.useState<any>(false);
	const [guidelinesId, setGuidelinesId] = React.useState<any>();
	const [guidelineScequenceId, setGuidelineScequenceId] = React.useState<any>();
	const [validation, setValidation] = useState<any>(false);
	const [watermark, setWatermark] = useState<any>(false);
	const { projectId, projectName } = useRouteParams<any>();
	const [useraction, setuseraction] = useState<any>(null);
	const editor = useRef(null);
	const [state, setState] = React.useState({
		top: false,
		left: false,
		bottom: false,
		right: false,
	});
	const { height, width } = useWindowDimensions();
	React.useEffect(() => {
		API.get(
			'/auth/user_permission_module/',
			{
				action: true,
				configuration: true,
				vault: projectId,
				module: 'Costing Configuration',
			},
			0
		)
			.then((res: any) => {
				setuseraction(res?.data?.action);
				// sessionStorage.setItem("ConfigPermission", res.data.action);
			})
			.catch((err: any) => {});
	}, []);

	const handleClick = () => {
		history.goBack();
	};
	const [guideLinesData, setGuideLinesData] = React.useState<any>({});
	const [otherValidation, setOtherValidation] = React.useState<any>({});

	const getGuidelinesData = () => {
		API.get(
			'/cost/cost_guide_line/',
			{
				vault: projectId,
			},
			0
		)
			.then((res: any) => {
				setContentDataCount(res.data?.length);
				setGuidelinesId(res.data?.id);
				setContent(res.data.content);
				setGuidelineScequenceId(res.data.scequence);
				setValidation(res.data?.validation);
				setWatermark(res.data?.water_mark);
				sessionStorage.setItem(
					'validationGuideLines',
					JSON.stringify(res.data)
				);
				setGuideLinesData(res.data);
			})
			.catch((err: any) => {
				console.error('Error fetching data:', err);
			});
	};
	// const getAllResult = () => {
	//   API.get(
	//     "/const/share_costing_config/",
	//     {
	//       vault: projectId,
	//       costing_config: true,
	//     },
	//     0
	//   )
	//     .then((res: any) => {
	//       setOtherValidation(res.data[0]);
	//       sessionStorage.setItem("otherValidation", JSON.stringify(res.data[0]));
	//     })
	//     .catch((err: any) => {
	//       console.error("Error fetching data:", err);
	//     });
	// };
	React.useEffect(() => {
		getGuidelinesData();
		// getAllResult();
	}, [projectId, validation,]);

	const htmlElement = ReactDOMServer.renderToStaticMarkup(
		<>
			<div>
				<p></p>
			</div>
		</>
	);

	useEffect(() => {
		setContent(htmlElement);
	}, [htmlElement]);
	const handleDownload = () => {
		try {
			fetch(
				API.apiBase +
					`cost/generate_cost_guide_line/?vault=${projectId}&user=${AUTH.user}`,
				{
					method: 'GET',
				}
			)
				.then((resp) => resp.blob())
				.then((blob) => {
					const url = window.URL.createObjectURL(blob);
					const a = document.createElement('a');
					a.style.display = 'none';
					a.href = url;
					a.download = 'costing-guideline'; // the filename you want
					document.body.appendChild(a);
					a.click();
					window.URL.revokeObjectURL(url);
				});
		} catch (e) {}
	};

	const handleSave = () => {
		setLoading(true);
		if (contentDataCount > 0) {
			API.put(
				`/cost/cost_guide_line/${guidelinesId}/`,
				{
					content: content,
					vault: projectId,
					sequence: guidelineScequenceId,
					validation: false,
				},
				{}
			)
				.then((res: any) => {
					setLoading(false);
					getGuidelinesData();
					Swal.fire({
						html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
          <br />
          <p style="color:#007fff;">Guidelines updated Successfully!</p>   
           </div>`,
					});
				})
				.catch((err: any) => {
					setLoading(false);
				});
		} else {
			API.put(`/cost/cost_guide_line/${guidelinesId}/`, {
				content: content,
				vault: projectId,
			})
				.then((res: any) => {
					setLoading(false);
					ADMIN.toast.info('Guidelines Saved Successfully!');
				})
				.catch((err: any) => {
					Swal.fire({
						icon: 'error',
						html: `<div> 
                    <p style="color:"red">${err?.response?.data[0]}</p>   
                    </div>`,
						customClass: {
							container: 'swal2Container',
						},
					});

					setLoading(false);
				});
		}
	};

	const shareModal = useBaseModal();
	const WatermrkModel = useBaseModal();
	// const AssumptionCardModal = useBaseModal();

	return (
		<div>
			{shareModal.isOpen && (
				<ShareCostingModel
					isOpen={shareModal.isOpen}
					onCloseModal={shareModal.close}
				/>
			)}
			{WatermrkModel.isOpen && (
				<WatermarkModel
					isWMOpen={WatermrkModel.isOpen}
					onCloseModal={WatermrkModel.close}
					getGuidelinesData={getGuidelinesData}
				/>
			)}
			{/* {AssumptionCardModal.isOpen && (
        <AssumptionCard
          isASCDopen={AssumptionCardModal.isOpen}
          isASCDclose={AssumptionCardModal.close}
        />
      )} */}

			<Box sx={{ margin: '0 0.5rem' }}>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						width: '100%',
					}}>
					<Box
						sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
						<IconButton
							sx={{ cursor: 'pointer' }}
							title='Go Back'
							onClick={handleClick}>
							<ArrowBackIcon color='primary' titleAccess='Go Back' />
						</IconButton>
						<Typography
							sx={{
								fontSize: '1rem',
								whiteSpace: 'nowrap',
								fontWeight: '500',
							}}>
							Costing Guidelines-{'>'}
							{projectName}-{'>'}
							{guideLinesData.vault_name}
						</Typography>
					</Box>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							columnGap: '1.5rem',
							height: '3rem',
							width: '25rem',
						}}>
						{useraction && useraction?.includes('C') && (
							<>
								{guideLinesData?.water_mark == true ? (
									<Button
										size='small'
										title='Add Watermark'
										endIcon={<CheckCircleRoundedIcon />}
										sx={{
											backgroundColor:
												guideLinesData?.water_mark == true
													? 'primary.light'
													: '',
											color:
												guideLinesData?.water_mark == true
													? 'primary.main'
													: 'black',
										}}
										onClick={() => {
											handleSave()
											WatermrkModel.open()}}>
										Watermark
									</Button>
								) : (
									<Button
										size='small'
										title='Add Watermark'
										startIcon={
											<AddIcon
												sx={{ color: 'black', marginRight: '-0.5rem' }}
											/>
										}
										sx={{
											backgroundColor:
												guideLinesData?.water_mark == true
													? 'primary.light'
													: '',
											color:
												guideLinesData?.water_mark == true
													? 'primary.main'
													: 'black',
										}}
										onClick={() =>{
											handleSave();
											WatermrkModel.open()}}>
										Watermark
									</Button>
								)}
							</>
						)}

						{useraction && useraction?.includes('U') && (
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									columnGap: '0.3rem',
								}}>
								<Checkbox
									style={{ padding: '1px' }}
									{...label}
									// marginTop: validation === false ? '' : '-4px', marginRight: validation === false ? '' : '3px'
									checked={validation}
									disabled={contentDataCount === 0 ? true : false}
									checkedIcon={
										<VerifiedIcon
											sx={{
												color: '#39FF14',
												position: 'relative',
											}}
										/>
									}
									sx={{
										color: 'primary.main',
										'& .MuiSvgIcon-root': { fontSize: { lg: 20, xl: 22 } },
									}}
									onChange={() => {
										
										setLoading(false);
										API.put(
											`/cost/cost_guide_line/${guidelinesId}/`,
											{
												make_validation: !validation,
												vault: projectId,
											},
											{},
											0
										)
											.then(() => {
												setValidation((prev: any) => !prev);
											})
											.catch((err) => {
												Swal.fire({
													icon: 'error',
													html: `<div> 
                              <p style="color:"red">${err.response.data[0]}</p>   
                              </div>`,
													customClass: {
														container: 'swal2Container',
													},
												});
											});
									}}
								/>
								<Typography sx={{ fontSize: '0.87rem',textTransform:'uppercase',fontWeight:'500' }}>
									{validation == true ? 'Validated' : 'Validate'}
								</Typography>
							</Box>
						)}
					</Box>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-end',
							columnGap: '1rem',
							paddingRight: '1rem',
							width: '13rem',
						}}>
						{guideLinesData?.validation && (
							<Button
								variant='contained'
								title='Download Guidelines'
								size='small'
								// sx={{ backgroundColor: 'primary.light', color: 'primary.main' }}
								endIcon={<DownloadIcon />}
								onClick={handleDownload}>
								Download
							</Button>
						)}

						{useraction && useraction?.includes('C') && (
							<LoadingButton
								title='Save Configuration'
								variant='contained'
								size='small'
								loading={loading}
								sx={{
									minWidth: '3rem',
									padding: '0.2rem',
									cursor: 'pointer',
									'&:hover': {
										transform: 'Scale(1.05)',
										transition: 'transform 0.3s ease',
									},
								}}
								onClick={handleSave}>
								<SaveIcon sx={{ color: 'white', fontSize: '1.5rem' }} />
							</LoadingButton>
						)}
					</Box>
				</Box>
			</Box>
			<Box sx={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
				<Box sx={{ width: '100%', height: '89vh' }}>
					<Box sx={{ padding: '0 1rem' }}>
						<JoditEditor
							ref={editor}
							config={editorConfig}
							className='CostingConfigMailEditor'
							value={content}
							onBlur={(newContent) => setContent(newContent)}
						/>
					</Box>
				</Box>
			</Box>
		</div>
	);
}
export default CostingConfigurationGuidelines;
