import React, { useEffect, useState } from 'react';
import { useRouteParams } from '../../../ui-reusable-component/useRouteParams';
import { API } from '../../../api-services';
import GradientProgressBar from './GradientProgressBar'
import PieChartComponent from './PieChartComponent'
import styles from './LeftGraphComponent.module.scss';
import BubbleChart from '../../../Assets/images/BubbleChart.png';
import { Box, Button, Menu, MenuItem, Skeleton, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useBaseModal } from '../../SearchPage/useBaseModal';
import { DialogComponent } from '../../../utlis/DialogComponent';
import DownloadIcon from '@mui/icons-material/Download';


type Anchor = "top" | "left" | "bottom" | "right";

interface LeftGraphComponentI {
}

interface Params {
  projectId: any;
  topVaultId: any;
}

const LeftGraphComponent: React.FC<LeftGraphComponentI> = (props) => {
  const [dataLoad, setDataLoad] = useState<boolean>(true);
  const { projectId, topVaultId } = useRouteParams<Params>();
  const [graphBar, setGraphBar] = useState<Array<{ abbrevation: string, accepted_ideas: number, base: number, rejected_ideas: number, submitted_ideas: number, subsystem_name: string, total_ideas: number }> | []>([]);
  const [pieChart, setPieChart] = useState<Array<{ headers: { total_ideas: number, total_ideas_cost: number }, piechart: Array<{ name: string, value: number, count: number }> }> | any>({});
  const [ideaGraph, setIdeaGraph] = useState<boolean>(false);
  const [ideaPieChart, setIdeaPieChart] = useState<boolean>(false);
  const [contentText, setContentText] = React.useState<string>("Report download is running in background");
  const [iconText, setIconText] = React.useState<string>("success")
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const history = useHistory();
  const open = Boolean(anchorEl);
  const openDialogModal = useBaseModal();

  useEffect(() => {
    setDataLoad(true);
    API.get(
      `/cost/idea_data/?idea_dashboard=subsystem_record`,
      {
        top_vault_id: topVaultId,
      },
      0
    ).then((res: any) => {
      setDataLoad(false);
      setGraphBar(res?.data as { abbrevation: string, accepted_ideas: number, base: number, rejected_ideas: number, submitted_ideas: number, subsystem_name: string, total_ideas: number }[]);
      setIdeaGraph(true);
    });
  }, [projectId, topVaultId]);

  useEffect(() => {
    setDataLoad(true);
    API.get(
      `/cost/idea_data/`,
      {
        top_vault_id: topVaultId,
        idea_dashboard: "net_summary"
      },
      0
    ).then((res: any) => {
      setDataLoad(false);
      setPieChart(res?.data as { headers: { total_ideas: number, total_ideas_cost: number }, piechart: { name: string, value: number, count: number }[] }[]);
      setIdeaPieChart(true);
    });
  }, [projectId, topVaultId]);

  const getPercentageValue: any = (value: number, total: number) => {
    var etotal = total >= 1 ? total : 1;
    return ((value / etotal) * 100);
  };

  const handleClickDownload = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleWithoutImage = async () => {
    handleClose();
    API.get("/cost/check_consolidate_downoad/", { top_vault: topVaultId, with_image: false, consolidate_type: "Idea" }, 0).then((res: any) => {
      if (res.data.status === "Failed") {
        openDialogModal.open();
        setIconText("failed")
        setContentText(res.data.message)
      } else if (res.data.status === "First Download") {
        openDialogModal.open();
        setIconText("firstdownload")
        setContentText(res.data.message)
        API.get(
          "/cost/consolidated_report/",
          {
            top_vault: topVaultId,
            with_image: false,
            consolidate_type: "Idea"
          }, 0).then((res: any) => {
            window.open(res?.data, "_blank");
          }).catch((err: any) => {
            openDialogModal.open();
            setIconText("failed")
            setContentText(err.response.data)
          })
      } else if (res.data.status === "In Progress") {
        openDialogModal.open();
        setIconText("reportProgress")
        setContentText(res.data.message)
      } else if (res.data.status === "Completed") {
        window.open(res?.data?.url, "_blank");
      }
    })
  }

  const handleWithImage = async () => {
    handleClose();
    API.get("/cost/check_consolidate_downoad/", { top_vault: topVaultId, with_image: true, consolidate_type: "Idea" }, 0).then((res: any) => {
      if (res.data.status === "Failed") {
        openDialogModal.open();
        setIconText("failed")
        setContentText(res.data.message)
      } else if (res.data.status === "First Download") {
        openDialogModal.open();
        setIconText("firstdownload")
        setContentText(res.data.message)
        API.get(
          "/cost/consolidated_report/",
          {
            top_vault: topVaultId,
            consolidate_type: "Idea",
            with_image: true,
          }, 0).then((res: any) => {
            window.open(res?.data, "_blank");
          }).catch((err: any) => {
            openDialogModal.open();
            setIconText("failed")
            setContentText(err.response.data)
          });
      } else if (res.data.status === "In Progress") {
        openDialogModal.open();
        setIconText("reportProgress")
        setContentText(res.data.message)
      } else if (res.data.status === "Completed") {
        window.open(res?.data?.url, "_blank");
      }
    })
  }

  return (
    <div>
      <DialogComponent isOpen={openDialogModal.isOpen} onClose={openDialogModal.close} content={contentText} type={iconText} />

      {/* <LetSuspense
    loadingPlaceholder={BouncingLoader}
    condition={ideaGraph && ideaPieChart}
  > */}
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <span style={{ fontWeight: '500', padding: "0.3rem", fontSize: '1rem' }}>
          {` ${pieChart?.headers?.total_ideas} Cost Saving Ideas Worth ${pieChart?.headers?.total_ideas_cost} INR per Vehicle is Generated`}
        </span>
        <span>
          <Button
            // variant="contained"
            className="btnlogin"
            //   sx={{ fontWeight: "600" }}
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClickDownload}
            size="small"
            endIcon={<DownloadIcon />}>
            <Typography sx={{ fontSize: '1rem', fontWeight: '500', marginRight: '-0.5rem' }}>Download</Typography>
          </Button>
        </span>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            ".MuiList-root.MuiMenu-list": {
              padding: 0,
            }
          }}>
          <MenuItem sx={{ fontSize: '1rem' }} onClick={handleWithImage}>With Image Report</MenuItem>
          <MenuItem sx={{ fontSize: '1rem' }} onClick={handleWithoutImage}>
            Without Image Report
          </MenuItem>
        </Menu>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', padding: '0 1rem' }}>
        <Box>
          {!dataLoad ?
            <PieChartComponent pieChart={pieChart?.piechart} />
            : <Box sx={{ height: '12rem', marginTop: '2rem' }}>
              <Skeleton variant="circular" width={120} height={120} sx={{ marginLeft: '3rem' }} /></Box>
          }</Box>

        <Box>
          {!dataLoad ?
            <img title='Click to Expand Prioritisation Matrix' onClick={() => { return history.push(`/idea_dashboard/prioritisationMatrix/${projectId}/${topVaultId}`) }} src={BubbleChart} height={'180px'} width={'100%'} style={{ cursor: 'pointer', objectFit: 'contain' }} />
            : <Box sx={{ marginLeft: '8rem' }}>
              <Skeleton variant="rectangular" width={250} height={100} /></Box>
          }
        </Box>
      </Box>

      <div>
        {!dataLoad ?
          <div className={styles.progressBarContainer}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
            style={{ overflowX: "hidden" }}>
            {graphBar && graphBar?.map((item: { abbrevation: string, accepted_ideas: number, base: number, rejected_ideas: number, submitted_ideas: number, subsystem_name: string, total_ideas: number }) => {
              return (
                <GradientProgressBar
                  completed={getPercentageValue(item?.accepted_ideas, item?.accepted_ideas + item?.submitted_ideas + item?.rejected_ideas)}
                  notStarted={getPercentageValue(item?.submitted_ideas, item?.accepted_ideas + item?.submitted_ideas + item?.rejected_ideas)}
                  rejected={getPercentageValue(item?.rejected_ideas, item?.accepted_ideas + item?.submitted_ideas + item?.rejected_ideas)}
                  item={item}
                />
              )
            })}
          </div>
          : <Box>
            <Box sx={{ height: '100%', marginTop: '5rem' }}>
              {/* sx={{ display: "flex", rowGap: "4rem",flexDirection:"row",height:'3rem' }}> */}
              <Skeleton sx={{ height: "2.5rem", width: "100%" }} />
              <Skeleton sx={{ height: "2.5rem", width: "100%" }} />
              <Skeleton sx={{ height: "2.5rem", width: "100%" }} />
              <Skeleton sx={{ height: "2.5rem", width: "100%" }} />
              <Skeleton sx={{ height: "2.5rem", width: "100%" }} />
              <Skeleton sx={{ height: "2.5rem", width: "100%" }} />
              <Skeleton sx={{ height: "2.5rem", width: "100%" }} />
              <Skeleton sx={{ height: "2.5rem", width: "100%" }} />
            </Box>
          </Box>
        }</div>
      {/* </LetSuspense> */}
    </div>
  );
}

export default LeftGraphComponent;