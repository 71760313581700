import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

import styles from "./InwarLandingPage.module.scss";

import { Checkbox, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import InwardParameterdata from "./InwardParameterdata";
import useFetch from "../../Core/CustomHooks/useFetch";
import { USER } from "../../Redux/Services/user.service";
import { Inward } from "../../Redux/Services/inward-service";
import { useHistory, useParams } from "react-router-dom";
import { API } from "../../api-services";
import { useBaseModal } from "../SearchPage/useBaseModal";
import ValidationModal from "./ValidationModal";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DownloadIcon from "@mui/icons-material/Download";
import { DialogComponent } from "../../utlis/DialogComponent";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { userSelectors } from "../../Redux/Reducers/user.reducer";
import { useSelector } from "react-redux";
import { useDocumentTitle } from "../../ui-reusable-component/useDocumentTitle";



interface InwardlandingPageProps { }
type Anchor = "top" | "left" | "bottom" | "right";
const InwardLandingPage = (props: InwardlandingPageProps) => {
  useDocumentTitle( "Vehicle Inwarding" );
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const { projectId, projectname, productId } = useParams<any>();

  const [selectedId, setSelectedId] = useState<any>([parseInt(projectId)]);
  const [selectedProduct, setSelectedProduct] = useState<any>([
    parseInt(productId),
  ]);
  const [projectName, setProjectName] = useState<any>(projectname);

  const [ProductList, setProductList] = useState<any>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [Premissiondata,setPremissiondata]=useState<any>()
  const open = Boolean(anchorEl);
  const openDialogModal = useBaseModal();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [CheckboxdataMsg, setCheckboxdataMsg] = useState<any>();
  const { 0: fetching, 1: ProjecList } = useFetch<any>({
    method: Inward.getProject,
    args: [],
    dependencies: [],
    // condition: type !== "from",
    fallBackErrMsg: "Could not fetch values",
    // condition=type==="To"
  });

  const Validationmodal = useBaseModal();
  const history = useHistory<any>();

  const getselectedBackgroundColor = (idd: any) => {
    if (selectedId?.includes(idd)) {
      return "primary.main";
    } else {
      return "primary.light";
    }
  };
  const getSelectedTextColor = (idd: any) => {
    if (selectedId?.includes(idd)) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };
  const userProfilePic = useSelector(userSelectors.selectAll);
  var ProjectAdmin= userProfilePic && userProfilePic[0]?.is_project_admin
  
  const getProductselectedBackgroundColor = (idd: any) => {
    if (selectedProduct?.includes(idd)) {
      return "primary.main";
    } else {
      return "white";
    }
  };
  const getProductSelectedTextColor = (idd: any) => {
    if (selectedProduct?.includes(idd)) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };
  const selectAllProduct = (Ids: any, name: any) => {
    setSelectedProduct([Ids]);
    history.push(`/inward/${selectedId?.toString()}/${projectName}/${Ids}`);
  };
  const selectAllProject = (productIds: any, name: any) => {
    setProjectName(name);

    setSelectedId([productIds]);
  };

;


// useEffect(()=>{
//   API.get("/cost/module_permission/",{top_vault_id:productId,module:"Product Inward"}).then((res:any)=>{
//     setPremissiondata(res?.data)
//   }).catch((err:any)=>{
//     console.log("Server Error")
//   })
// },[productId])

useEffect(()=>{

  API.delete("/analytics/store_attachment_details/", { delete_all: true, mail_type: 1 }).then((res: any) => {

  }).catch((err: any) => {
   
    console.log("Server Error")
  })
},[])
const MailRedirectionHandler=()=>{
 window.open(`/#/Inward-mail/${projectname}/${projectId}/${0}/${false}/${productId}`,"_blank"," noopener")
}

// console.log(Premissiondata,"PremissiondataPremissiondata")

  useEffect(() => {
    API.get(
      `/vehicle_inward/inward_view/`,
      {
        product_list: true,
        project_id: selectedId?.toString(),
      },
      0
    ).then((res: any) => {
      setProductList(res?.data);
    });
  }, [projectId]);
  const toggleDrawer =
    (anchor: Anchor, open: boolean, actiontype: any) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }

        // console.log(actiontype, "actiontypeactiontype");

        if (actiontype == "open") {
          API.get(`/vehicle_inward/inward_view/`, {
            product_list: true,
            project_id: selectedId?.toString(),
          }).then((res: any) => {
            setSelectedProduct([res?.data[0]?.id]);

            history.push(
              `/inward/${selectedId?.toString()}/${projectName}/${res?.data[0]?.id
              }`
            );
            setState({ ...state, [anchor]: open });
          });
        }

        setState({ ...state, [anchor]: open });
      };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        minWidth: 300,
        width: anchor === "top" || anchor === "bottom" ? "auto" : 300,
      }}
      role="presentation"
    >
      <Box sx={{ width: "100%", padding: "0.5rem 1rem" }}>
        <Typography
          sx={{
            width: "100%",
            fontSize: "1rem",
            textAlign: "center",
          }}
        >
          Select Project
        </Typography>
        <Divider />
        {ProjecList &&
          ProjecList?.map((item: any, index: any) => {
            return (
              <>
                <Box
                  sx={{ alignItems: "center", display: "flex", margin: '0.5rem 0' }}
                >
                  <Checkbox
                    style={{ padding: "6px" }}
                    checked={selectedId?.includes(item?.id) ? true : false}
                    onClick={() => selectAllProject(item?.id, item?.name)}
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                  />
                  <Box
                    onClick={() => selectAllProject(item?.id, item?.name)}
                    key={index}
                    sx={{
                      padding: "0.5rem 0.5rem",
                      backgroundColor: getselectedBackgroundColor(item.id),
                      color: getSelectedTextColor(item.id),
                      textAlign: "left",
                      cursor: "pointer",
                      width: "100%",
                    }}
                  >
                    <Typography style={{ fontSize: "1rem", lineHeight: '1.2' }}>
                      {item?.name} ({item?.description})
                    </Typography>
                  </Box>
                </Box>
              </>
            );
          })}

        {selectedId?.length !== 1 && (
          <p
            style={{
              fontSize: "12px",
              color: "#d32f2f",
              marginTop: "0.4rem",
              marginLeft: "1.45rem",
            }}
          >
            {"*Please select  at least one project..."}
          </p>
        )}
      </Box>
      <Box style={{ textAlign: "right", marginTop: "1rem", padding: '0 1rem' }}>
        <Button
          className={styles.drawBtn}
          sx={{ marginRight: "1rem" }}
          variant="contained"
          size="small"
          onClick={toggleDrawer(anchor, false, "close")}
        >
          Close
        </Button>
        <Button
          className={styles.drawBtn}
          variant="contained"
          onClick={toggleDrawer(anchor, false, "open")}
          size="small"
        >
          Ok
        </Button>

      </Box>
    </Box>
  );

  const DownloadHandler = (filename: any) => {
    if (filename == "excel") {
      openDialogModal.open();
     
      API.post(
        "/config/vehicle_inward_download/",
        { top_vault: productId },
        {},
        0
      )
        .then((res: any) => {
          window.open(res?.data, "_blank");
        })
        .catch((err: any) => { 

          openDialogModal.close()
          const { data } = err?.response;
          Validationmodal.open();
          setCheckboxdataMsg({ msg: data[0], type: "error" });
        });
    }

    if (filename == "Images") {
      openDialogModal.open();
      API.post(
        "/config/vehicle_inward_image_download/",
        { top_vault: productId },
        {},
        0
      )
        .then((res: any) => {
          window.open(res?.data, "_blank");
        })
        .catch((err: any) => { 
          openDialogModal.close()

          const { data } = err?.response;
          Validationmodal.open();

          setCheckboxdataMsg({ msg: data[0], type: "error" });
        });
    }

    if (filename == "DOC") {
      openDialogModal.open();
      API.post(
        "/config/vehicle_inward_doc_download/",
        { top_vault: productId },
        {},
        0
      )
        .then((res: any) => {
          window.open(res?.data, "_blank");
        })
        .catch((err: any) => { 

          openDialogModal.close()
          const { data } = err?.response; 
          Validationmodal.open();
          setCheckboxdataMsg({ msg: data[0], type: "error" });
        });
    }
    // window.open(res?.data, "_blank");
  };

  const backButtonHandler: any = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    history.push("/internallanding");
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          margin: "0.5rem 1rem 0px",
        }}
      >
        
        <Box sx={{ display: "flex", alignItems: 'center',columnGap:'1rem' }}>
          <IconButton  size="small" sx={{ cursor: "pointer" }} onClick={backButtonHandler}>
            <ArrowBackIcon color="primary" titleAccess="Go Back" />
          </IconButton>
          <Typography sx={{ fontSize: '1rem', width: '18.5vw' }}>Product Inward ({projectName})</Typography>
          <Box sx={{ width: '66vw', display: 'flex', }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3">
            {ProductList &&
              ProductList?.map((item: any, index: any) => {
                return (
                  <>
                    <Box
                      onClick={() => selectAllProduct(item?.id, item?.name)}
                      key={index}
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: getProductselectedBackgroundColor(
                          item.id
                        ),
                        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
                        color: getProductSelectedTextColor(item.id),
                        textAlign: "center",
                        cursor: "pointer",
                        display: "inline-block",
                        minWidth: '10rem',
                        borderRadius: "5px",
                        margin: '0.25rem',
                        marginRight: "1rem",
                        "&:hover": {
                          transform: 'Scale(1.05)',
                          transition: 'transform 0.5s ease',
                        }
                      }}
                    >
                      <Typography sx={{ fontSize: "1rem" }}>
                        {item?.name}
                      </Typography>
                    </Box>
                  </>
                );
              })}
          </Box>
        </Box>
        <Box sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center"
        }}>

          {(Premissiondata && Premissiondata?.action[0]?.includes("C") && Premissiondata?.action[0] ?.includes("D") && Premissiondata?.action [0]?.includes("U") && Premissiondata?.action [0]?.includes("R") || ProjectAdmin==true ) &&
           <MailOutlineIcon titleAccess="Send Inward Mail" sx={{cursor:"pointer"}} onClick={()=>MailRedirectionHandler()}/>}

{(Premissiondata && Premissiondata?.action[0]?.includes("C") && Premissiondata && Premissiondata?.action[0]?.includes("D") && Premissiondata?.action[0]?.includes("U")  && Premissiondata?.action[0]?.includes("R")  || ProjectAdmin==true  )&&
          <div>

           
            <Button
              id="basic-button"
              title="Download"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              endIcon={<DownloadIcon />}
              sx={{
                "&:hover": {
                  transform: 'Scale(1.1)',
                  transition: 'transform 0.5s ease',
                }
              }}

            >
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: "500",
                  marginRight: "-0.5rem",
                }}
              >
                Download
              </Typography>
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={() => DownloadHandler("excel")}>
                Excel
              </MenuItem>
              <MenuItem onClick={() => DownloadHandler("Images")}>
                Images
              </MenuItem>
              <MenuItem onClick={() => DownloadHandler("DOC")}>DOC.</MenuItem>
            </Menu>
          </div>
}
        </Box>
      </div>
      <div className={styles.inward_container}>
        {openDialogModal.isOpen && (
          <DialogComponent
            isOpen={openDialogModal.isOpen}
            onClose={openDialogModal.close}
            content="We’ve got this report downloading faster than a cheetah running. You will also receive a notification.
                But in the meantime, keep using xcPEP like the productivity champion !"
            type={"firstdownload"}
          />
        )}
        {Validationmodal.isOpen && (
          <ValidationModal
            isOpen={Validationmodal.isOpen}
            onCloseModal={Validationmodal.close}
            CheckboxdataMsg={CheckboxdataMsg}
          />
        )}
        <div className={styles.left_container}>
          <div>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="small"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true, "no")}
                >
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>

                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false, "no")}
                >
                  {list(anchor)}
                </Drawer>
              </React.Fragment>
            ))}
          </div>
          <div onClick={toggleDrawer("left", true, "no")} style={{ height: '100%', display: 'flex', alignItems: 'center', width: '4rem' }}>
            <Typography sx={{ fontSize: '1rem' }} className={styles.drawer_title}>Project Selection</Typography>
          </div>
        </div>
        <div className={styles.right_main_container}>
          <div className={styles.right_container_bottom}>
           { Premissiondata!==undefined && <InwardParameterdata  Premissiondata={Premissiondata} />}
          </div>
        </div>
      </div>
    </>

  );
};
export default InwardLandingPage;
