import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import styles from "../AddRevisionModal.module.scss";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { useRouteMatch } from "react-router-dom";
import Swal from "sweetalert2";
import successIcon from "../../../Assets/images/success.png";

export interface IHornModulesFormProps {
  actions?: any;
  onCloseModal: any;
  getMaterialData: any;
  subCategory: any;
  target: any;
  onCloseParent: any;
}
const validationSchema = yup.object({
  name: yup.string().required("Please type Item name"),
  type: yup.string().required("Please select type"),
  rated_voltage: yup.string().required("Please type Item Related voltage"),
  current_rating: yup.mixed().required("Please type current rating"),
  power_rating: yup.mixed().required("Please type Power rating"),
  frequency: yup.mixed().required("Please enter Frequency"),
  sound_level: yup.mixed().required("Please enter Sound level"),
  reference: yup.string(),
  unit_cost: yup.mixed().required("Please enter per unit cost"),
});
const useStyles = makeStyles((theme) => ({
  select: {
    "&.MuiInputBase-root-MuiInput-root-MuiSelect-root": {
      borderBottom: "1px solid red",
    },
  },
}));
export function HornModulesFormMove(props: IHornModulesFormProps) {
  const { actions, onCloseModal, getMaterialData, subCategory, target, onCloseParent } = props;
  const { url } = useRouteMatch();
  const [isLoadingsave, setIsLoadingSave] = React.useState<any>(false);
  const { categoryName } = useRouteParams<any>();
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      name: "",
      type: "",
      rated_voltage: "",
      current_rating: "",
      power_rating: "",
      frequency: "",
      sound_level: "",
      reference: "",
      unit_cost: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsLoadingSave(true);
      API.post(
        "db/move_cost_data/",
        {
          ...values,
          ...{ target_commodity: subCategory, id: url.includes("DB") ? actions.id : actions.purchase_item, boughtout: true, module_type: target },
        },
        {},
        0
      )
        .then((res: any) => {
          Swal.fire({
            html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />

      <br />

      <p style="color:#007fff;">Moved Successfully!</p>   

       </div>`,
          });
          getMaterialData();
          onCloseModal();
          onCloseParent();
        })
        .catch((err: any) => {});
    },
  });
  React.useEffect(() => {
    if (actions !== "add") {
      formik.setFieldValue("name", actions?.name || "");
      formik.setFieldValue("type", actions?.type || "");
      formik.setFieldValue("rated_voltage", actions?.rated_voltage || "");
      formik.setFieldValue("current_rating", actions?.current_rating || "");
      formik.setFieldValue("power_rating", actions?.power_rating || "");
      formik.setFieldValue("frequency", actions?.frequency || "");
      formik.setFieldValue("sound_level", actions?.sound_level || "");
      formik.setFieldValue("reference", actions?.reference || "");
      formik.setFieldValue("unit_cost", parseFloat(actions.unit_cost?.slice(1)) || "");
    }
  }, [actions]);
  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: "flex", alignItems: "center", columnGap: "1rem", marginBottom: "0.5rem" }}>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="Item Name"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="name"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              multiline
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </FormControl>
          <FormControl
            fullWidth
            variant="standard">
            <InputLabel
              sx={{ color: "primary.main" }}
              shrink
              id="demo-simple-select-label">
              Select Type{" "}
            </InputLabel>

            <Select
              id="type"
              name="type"
              className={classes.select}
              labelId="demo-select-small"
              // id="demo-select-small"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                  fontSize: "1rem",
                },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
              error={formik.touched.type && Boolean(formik.errors.type)}
              //@ts-ignore
              helperText={formik.touched.type && formik.errors.type}
              MenuProps={{
                style: {
                  maxHeight: 400,
                  color: "007fff",
                },
              }}
              onChange={formik.handleChange}
              value={formik.values.type !== null ? formik.values.type : ""}>
              {/* <option value={""} style={{ display: "none" }}></option> */}
              {["Single Tone", "Dual Tone", "Others"].map((item: any, index: any) => {
                return (
                  <MenuItem
                    sx={{ color: "primary.main", fontSize: "1rem" }}
                    key={index}
                    value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
            <div className={styles.errormsg}>{formik.touched.type && Boolean(formik.errors.type) && <p>{formik.errors.type}</p>}</div>
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", columnGap: "1rem", marginBottom: "0.5rem" }}>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="Related Voltage"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="rated_voltage"
              name="rated_voltage"
              onChange={formik.handleChange}
              value={formik.values.rated_voltage}
              multiline
              error={formik.touched.rated_voltage && Boolean(formik.errors.rated_voltage)}
              helperText={formik.touched.rated_voltage && formik.errors.rated_voltage}
            />
          </FormControl>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="Current Rating"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="current_rating"
              name="current_rating"
              onChange={formik.handleChange}
              value={formik.values.current_rating}
              type="number"
              error={formik.touched.current_rating && Boolean(formik.errors.current_rating)}
              helperText={formik.touched.current_rating && formik.errors.current_rating}
            />
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", columnGap: "1rem", marginBottom: "0.5rem" }}>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="Power Rating"
              type="number"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="power_rating"
              name="power_rating"
              onChange={formik.handleChange}
              value={formik.values.power_rating}
              error={formik.touched.power_rating && Boolean(formik.errors.power_rating)}
              helperText={formik.touched.power_rating && formik.errors.power_rating}
            />
          </FormControl>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="Frequency"
              type="number"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="frequency"
              name="frequency"
              onChange={formik.handleChange}
              value={formik.values.frequency}
              error={formik.touched.frequency && Boolean(formik.errors.frequency)}
              helperText={formik.touched.frequency && formik.errors.frequency}
            />
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", columnGap: "1rem", marginBottom: "0.5rem" }}>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="Sound level"
              type="number"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="sound_level"
              name="sound_level"
              onChange={formik.handleChange}
              value={formik.values.sound_level}
              error={formik.touched.sound_level && Boolean(formik.errors.sound_level)}
              helperText={formik.touched.sound_level && formik.errors.sound_level}
            />
          </FormControl>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="Per unit cost (INR)"
              type="number"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="unit_cost"
              name="unit_cost"
              onChange={formik.handleChange}
              value={formik.values.unit_cost}
              error={formik.touched.unit_cost && Boolean(formik.errors.unit_cost)}
              helperText={formik.touched.unit_cost && formik.errors.unit_cost}
            />
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", columnGap: "1rem", marginBottom: "0.5rem" }}>
          <FormControl
            fullWidth
            variant="standard">
            <TextField
              variant="standard"
              label="Source"
              multiline
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="reference"
              name="reference"
              onChange={formik.handleChange}
              value={formik.values.reference}
              error={formik.touched.reference && Boolean(formik.errors.reference)}
              helperText={formik.touched.reference && formik.errors.reference}
            />
          </FormControl>
        </Box>
        <Box sx={{ width: "100%", textAlign: "right" }}>
          <LoadingButton
            size="small"
            variant="contained"
            sx={{
              padding: "0.5rem",
              lineHeight: 1,
              marginTop: "1rem",
              "&:hover": {
                transform: "Scale(1.05)",
                transition: "transform 0.5s ease",
              },
              ".MuiCircularProgress-root": {
                color: "primary.main",
              },
            }}
            loading={isLoadingsave}
            type="submit">
            Move
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
}
