import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { TreeItemProps, treeItemClasses } from "@mui/lab/TreeItem";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
// web.cjs is required for IE11 support

import { TransitionProps } from "@mui/material/transitions";
import { animated, useSpring } from "@react-spring/web";
import { API } from "../api-services";
import { isNull, isUndefined, result } from "lodash";
import openFolder from "../Assets/images/svgs/openfolderblue.svg";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FolderIcon from "@mui/icons-material/Folder";
import styles from "./ViewComponents.module.scss";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import Button from "@mui/material/Button";
import { Box, Menu, MenuItem, Typography } from "@mui/material";
// import MenuItem from "@mui/material/MenuItem";
import NestedMenuItem from "material-ui-nested-menu-item";
import { ConstructionOutlined } from "@mui/icons-material";
import { useRouteParams } from "../ui-reusable-component/useRouteParams";
import { DialogComponent } from "../utlis/DialogComponent";
import { useBaseModal } from "../ComponentUI/SearchPage/useBaseModal";
import { useDispatch, useSelector } from "react-redux";
import { setAddCompareCount, setIsCompare } from "../Redux/Actions/compareopen.action";
import swal from "sweetalert";

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
      display: "none",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
      display: "block",
    },
  });

  return (
    <animated.div style={style} className="Tempdekho">
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = styled((props: TreeItemProps) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 10,
    paddingLeft: 8,
    borderLeft: `1px dashed ${theme.palette.text.primary}`,
  },
}));

interface TreeViewComponentsProps {
  HierarchyData: any;
  onSelectCallback: any;
}
const TreeViewComponents = (props: TreeViewComponentsProps) => {
  const { HierarchyData, onSelectCallback } = props;
  const { projectId, topVault, vault, leafNode, treeabbr, scenarioId } =
    useParams<any>();
  const { url } = useRouteMatch();
  const openStatusContent = useBaseModal();
  const [activeId, setActiveId] = React.useState<any>(null);
  const [contentText, setContentText] = React.useState<any>("");
  const [iconText, setIconText] = React.useState<any>("");
  const [HierarchyAllData, setHierarchyAllData] = React.useState<any>(null);
  const [loader, setLoader] = React.useState<any>(false);
  const [opendailog, setOpendailog] = React.useState<any>(false);
  const [TopVaultsfetched, setTopVaultsfetch] = useState<any>([]);
  const [menuItems, setMenuItems] = React.useState<any>();
  const [validatedModules, setValidatedModules] = React.useState<any>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    idd: any,
    is_leaf: any,
    data?: any
  ) => {
    event.preventDefault();
    event.stopPropagation();
    getMenuItems(event.currentTarget, idd, is_leaf, data?.abbreviation);
  };

  const getVault = (treeabbr: any, idd: any) => {
    if (treeabbr == "product") {
      return { top_vault: idd };
    } else {
      return { vault: idd };
    }
  };

  const handleRedirect = (module: any, treeabbr: any, id: any) => {
    // console.log("handleRedirect",id)
    if (module === "media" || module === "architecture") {
      API.get(`/image_repository/subsystem_fetch/`, {
        project: projectId,
        abbreviation: treeabbr == "product" ? 0 : treeabbr,
        image_category: treeabbr == "product" ? 1 : 2,
        media_type: "image",
      }).then((res: any) => {
        const topVaultKey: any =
          res.data &&
          res.data?.map((tvault: any) => {
            return tvault?.id;
          });

        const topVaults: any = topVaultKey?.slice(0, 5);
        if (module === "media") {
          if (treeabbr == "product") {
            res.data &&
              history.push(
                `/media-repository/image/${projectId}/0/1/0/${topVaults.toString()}`
              );
          } else {
            res.data &&
              history.push(
                `/media-repository/image/${projectId}/${treeabbr}/2/0/${topVaults.toString()}`
              );
          }
        } else if (module === "architecture") {
          if (treeabbr == "product") {
            res.data &&
              history.push(
                `/architecture/${projectId}/0/0/${topVaults.toString()}`
              );
          } else {
            res.data &&
              history.push(
                `/architecture/${projectId}/${treeabbr}/0/${topVaults.toString()}`
              );
          }
        }
      });
    }
    if (module === "features") {
      API.get("/cost/feature_view_data/", {
        project: projectId,
        analysis_type: 19,
        subsystem_list: true,
      })
        .then((res: any) => {
          // setFeatureTopVault(res.data?.allowed_top_vault);
          // setFeatureData(res?.data?.sub_system);
          const topVaultKey: any =
            res.data?.allowed_top_vault &&
            res.data?.allowed_top_vault?.map((tvault: any) => {
              return tvault?.id;
            });
          const topVaults: any = topVaultKey?.slice(0, 5);
          const FeatureVault: any =
            res?.data?.sub_system &&
            res?.data?.sub_system.filter((item: any, ind: any) => {
              return item.abbreviation == treeabbr;
            });

          var DataFilterFeatureId =
            FeatureVault && FeatureVault[0]?.abbreviation == "product"
              ? FeatureVault && FeatureVault[0]?.top_vault_id
              : FeatureVault && FeatureVault[0]?.vault_id;
          var FilterFeatureId = FeatureVault && FeatureVault[0]?.id;

          history.push(
            `/featurelandingPage/${projectId}/${topVaults?.toString()}/${DataFilterFeatureId}/${treeabbr}/${0}/${FilterFeatureId}/${0}/${0}`
          );
        })
        .catch((err: any) => {
          console.log(err);
        });
    } else if (module === "bom") {
      history.push(`/view/${projectId}/${topVault}/${id}/${treeabbr}/true`);
    } else if (module === "costing") {
      history.push(`/view/${projectId}/${topVault}/${id}/${treeabbr}/costing`);
    } else if (module === "ideas") {
      if (id == topVault) {
        if (treeabbr == "product") {
          history.push(`/idea/${projectId}/${topVault}/0/product`);
        }
      } else {
        if (treeabbr == "null") {
          API.get(
            `/cost/dashboard/`,
            { vault_id: id, fetch_abbreviation: true },
            0
          ).then((response: any) => {
            history.push(
              `/idea/${projectId}/${topVault}/${response.data?.abbreviation}/${id}`
            );
          });
        } else {
          history.push(`/idea/${projectId}/${topVault}/${treeabbr}/${id}`);
        }
      }
    }
    if (module === "w") {
      if (url.includes("costing")) {
        API.get(
          "/cost/check_consolidate_downoad/",
          {
            ...getVault(treeabbr, id),
            with_image: true,
            consolidate_type: "Costing",
            scenario: url.includes("scenario") ? scenarioId : undefined,
          },
          0
        ).then((res: any) => {
          // console.log("resultcheck", res.data);

          if (res.data.status === "Failed") {
            setIconText("failed");
            setContentText(res.data.message);
            openStatusContent.open();
          } else if (res.data.status === "First Download") {
            setIconText("firstdownload");
            setContentText(res.data.message);
            openStatusContent.open();
            API.get(
              "customer/consolidated_report/",
              {
                ...getVault(treeabbr, id),
                with_image: true,
                consolidate_type: "Costing",
                scenario: url.includes("scenario") ? scenarioId : 0,
              },
              0
            ).then((res: any) => {
              window.open(res?.data, "_self");
            });
          } else if (res.data.status === "In Progress") {
            setIconText("reportProgress");
            setContentText(res.data.message);
            openStatusContent.open();
          } else if (res.data.status === "Completed") {
            window.open(res?.data?.url, "_self");
          }
        });
        // DialogComponent(opendailog,setOpendailog,"Report Download is running in background.","warning")
      } else {
        // openStatusContent.open();
        API.get(
          "/cost/check_consolidate_downoad/",
          {
            ...getVault(treeabbr, id),
            with_image: true,
            consolidate_type: "BOM",
          },
          0
        ).then((res: any) => {
          // console.log("resultcheck", res.data);

          if (res.data.status === "Failed") {
            setIconText("failed");
            setContentText(res.data.message);
            openStatusContent.open();
          } else if (res.data.status === "First Download") {
            setIconText("firstdownload");
            setContentText(res.data.message);
            openStatusContent.open();
            API.get(
              "customer/consolidated_report/",
              {
                ...getVault(treeabbr, id),
                with_image: true,
                consolidate_type: "BOM",
              },
              0
            ).then((res: any) => {
              window.open(res?.data, "_self");
            });
          } else if (res.data.status === "In Progress") {
            setIconText("reportProgress");
            setContentText(res.data.message);
            openStatusContent.open();
          } else if (res.data.status === "Completed") {
            //  openStatusContent.open();
            window.open(res?.data?.url, "_self");
          }
        });
        // DialogComponent(opendailog,setOpendailog,"Report Download is running in background.","warning")
      }
    } else if (module === "w/o") {
      if (url.includes("costing")) {
        API.get(
          "/cost/check_consolidate_downoad/",
          {
            ...getVault(treeabbr, id),
            with_image: false,
            consolidate_type: "Costing",
            scenario: url.includes("scenario") ? scenarioId : undefined,
          },
          0
        ).then((res: any) => {
          // console.log("resultcheck", res.data);

          if (res.data.status === "Failed") {
            setIconText("failed");
            setContentText(res.data.message);
            openStatusContent.open();
          } else if (res.data.status === "First Download") {
            setIconText("firstdownload");
            setContentText(res.data.message);
            openStatusContent.open();
            API.get(
              "customer/consolidated_report/",
              {
                ...getVault(treeabbr, id),
                with_image: false,
                scenario: url.includes("scenario") ? scenarioId : 0,
                consolidate_type: "Costing",
              },
              0
            ).then((res: any) => {
              window.open(res?.data, "_self");
            });
          } else if (res.data.status === "In Progress") {
            setIconText("reportProgress");
            setContentText(res.data.message);
            openStatusContent.open();
          } else if (res.data.status === "Completed") {
            window.open(res?.data?.url, "_self");
          }
        });
      } else {
        // openStatusContent.open();
        API.get(
          "/cost/check_consolidate_downoad/",
          {
            ...getVault(treeabbr, id),
            with_image: false,
            consolidate_type: "BOM",
          },
          0
        ).then((res: any) => {
          // console.log("resultcheck", res.data);

          if (res.data.status === "Failed") {
            setIconText("failed");
            setContentText(res.data.message);
            openStatusContent.open();
          } else if (res.data.status === "First Download") {
            setIconText("firstdownload");
            setContentText(res.data.message);
            openStatusContent.open();
            API.get(
              "customer/consolidated_report/",
              {
                ...getVault(treeabbr, id),
                with_image: false,
                consolidate_type: "BOM",
              },
              0
            ).then((res: any) => {
              window.open(res?.data, "_self");
            });
          } else if (res.data.status === "In Progress") {
            setIconText("reportProgress");
            setContentText(res.data.message);
            openStatusContent.open();
          } else if (res.data.status === "Completed") {
            //  openStatusContent.open();
            window.open(res?.data?.url, "_self");
          }
        });
      }
    } else if (module === "Excel") {
      setIconText("firstdownload");
      setContentText(
        "We’ve got this report downloading faster than a cheetah running. You will also receive a notification.But in the meantime, keep using xcPEP like the productivity champion !"
      );
      openStatusContent.open();
      API.get(
        "customer/individual_report/",
        {
          vault: id,
          individual_type: "Costing",
          scenario: url.includes("scenario") ? scenarioId : 0,
        },
        0
      ).then((res: any) => {
        window.open(res?.data, "_self");
      });
    }
    else if (module === "Detailed Excel") {
      setIconText("firstdownload");
      setContentText(
        "We’ve got this report downloading faster than a cheetah running. You will also receive a notification.But in the meantime, keep using xcPEP like the productivity champion !"
      );
      openStatusContent.open();
      API.get(
        "customer/individual_report/",
        {
          vault: id,
          individual_type: "Costing",
          scenario: url.includes("scenario") ? scenarioId : 0,
          detail_report: true,
        },
        0
      ).then((res: any) => {
        window.open(res?.data, "_self");
      });
    }
    if (module === "withbom") {
      // openStatusContent.open()
      // // DialogComponent(opendailog,setOpendailog,"Report Download is running in background.","warning")
      //  API.get("customer/consolidated_report/",{...getVault(),with_image:true,consolidate_type:"BOM"},0).then((res:any)=>{
      //   window.open(res?.data, "_self")
      //  })
    } else if (module === "withoutbom") {
      // openStatusContent.open()
      // // DialogComponent(opendailog,setOpendailog,"Report Download is running in background.","warning")
      // API.get("customer/consolidated_report/",{...getVault(),with_image:false,consolidate_type:"BOM"},0).then((res:any)=>{
      //   window.open(res?.data, "_self")
      //  })
    } else if (module === "Excelbom") {
      setIconText("firstdownload");
      setContentText(
        "We’ve got this report downloading faster than a cheetah running. You will also receive a notification.But in the meantime, keep using xcPEP like the productivity champion !"
      );
      openStatusContent.open();
      //  DialogComponent(opendailog,setOpendailog,"Report Download is running in background.","success")
      API.get(
        "customer/individual_report/",
        { vault: id, individual_type: "BOM" },
        0
      )
        .then((res: any) => {
          window.open(res?.data, "_self");
        })
        .catch((err: any) => { });
    }
  };

  const checkPresentModule = () => {
    if (url.includes("featurelandingPage")) {
      return "features";
    } else if (url.includes("true")) {
      return "bom";
    } else if (url.includes("costing")) {
      return "costing";
    }
  };

  const getModule = (module: any) => {
    // if(module !== "Quick_Compare"){
    switch (module) {
      case "media":
        return "Go to Media";
      case "bom":
        return "Go to BOM";
      case "costing":
        return "Go to Costing";
      case "features":
        return "Go to Features";
      case "architecture":
        return "Go to Architecture";
      case "ideas":
        return "Go to Ideas";
      case "without":
        return "Costing with out images download";
      case "with":
        return "Costing with images download";
      case "Excel":
        return "Part cost excel download";
      case "Detailed Excel":
        return "Post cost detailed excel download";
      case "withoutbom":
        return "Costing with out images download";
      case "withbom":
        return "Costing with images download";
      case "Excelbom":
        return "Part BOM excel download";
      case "Download Report":
        return "Download Report";


    }
    // }
  };
  const getMenuItems = (
    currentTarget: any,
    idd: any,
    is_leaf: any,
    abbreviation: any
  ) => {
    if (topVault == idd) {
      // setMenuItems((prev:any)=>[...prev,"Quick_Compare"])
      API.get(
        "/cost/view_data_module/",
        { top_vault: idd, validated: true },
        0
      )
        .then((res: any) => {
          setMenuItems(
            Object.keys(res.data).filter((item) => res.data[item] === true)
          );
          let checkedMod = Object.keys(res.data).filter(
            (item) => res.data[item] === true
          );
          if (
            url.includes("costing") &&
            checkedMod &&
            checkedMod?.includes("costing")
          ) {
            if (is_leaf === true) {
              setMenuItems((prev: any) => [...prev, ...["Excel", "Detailed Excel"]]);
            }
            if (is_leaf === false) {
              if (abbreviation == "product") {
                setMenuItems((prev: any) => [...prev, ...["Download Report"]]);
              } else {
                setMenuItems((prev: any) => [...prev, ...["Download Report"]]);
              }
            }
          }
          if (
            url.includes("true") &&
            checkedMod &&
            checkedMod?.includes("bom")
          ) {
            if (is_leaf === true) {
              setMenuItems((prev: any) => [...prev, ...["Excelbom"]]);
            }
            if (is_leaf === false) {
              if (abbreviation == "product") {
                setMenuItems((prev: any) => [...prev, ...["Download Report"]]);
              } else {
                setMenuItems((prev: any) => [...prev, ...["Download Report"]]);
              }
            }
          }

          setAnchorEl(currentTarget);
        })
        .catch((err: any) => {
          setMenuItems([]);
        });
    } else {
      API.get("/cost/view_data_module/", { vault: idd, validated: true }, 0)
        .then((res: any) => {
          setMenuItems(
            Object.keys(res.data).filter(
              (item) => res.data[item] === true && item !== checkPresentModule()
            )
          );
          // setValidatedModules(
          //   Object.keys(res.data).filter((item) => res.data[item] === true)
          // )
          let checkedMod = Object.keys(res.data).filter(
            (item) => res.data[item] === true
          );
          if (
            url.includes("costing") &&
            checkedMod &&
            checkedMod?.includes("costing")
          ) {
            if (is_leaf === true) {
              setMenuItems((prev: any) => [...prev, ...["Excel", "Detailed Excel"]]);
            }
            if (is_leaf === false) {
              if (abbreviation == "product") {
                setMenuItems((prev: any) => [...prev, ...["Download Report"]]);
              } else {
                setMenuItems((prev: any) => [...prev, ...["Download Report"]]);
              }
            }
          }
          if (
            url.includes("true") &&
            checkedMod &&
            checkedMod?.includes("bom")
          ) {
            if (is_leaf === true) {
              setMenuItems((prev: any) => [...prev, ...["Excelbom"]]);
            }
            if (is_leaf === false) {
              if (abbreviation == "product") {
                setMenuItems((prev: any) => [...prev, ...["Download Report"]]);
              } else {
                setMenuItems((prev: any) => [...prev, ...["Download Report"]]);
              }
            }
          }
          setAnchorEl(currentTarget);
        })
        .catch((err: any) => {
          setMenuItems({});
        });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [expanded, setExpanded] = React.useState<any>([parseInt(topVault)]);
  const dispatch = useDispatch();
  var random: any = Math.floor(Math.random() * 1000000 + 1);
  const store: any = useSelector((state) => state);
  const [TopLevel, setTopLevel] = React.useState();
  const [titleString, setTitleString] = React.useState<any>("");
  const [selected, setSelected] = React.useState<any>(topVault);
  const history = useHistory<any>();
  const handleCompareClick = (abbr: any, idd: any) => {
    API.post("/cost/compare_cart/", {
      vault: parseInt(idd),
      project: projectId
    }, {}, 0).then((res: any) => {
      sessionStorage.setItem("is_compare", "true")
      dispatch(setIsCompare("true"));
      dispatch(setAddCompareCount((store.is_compare?.add_count + 1)))
    }).catch((err: any) => {
      if (err.response.status === 400) {
        // swal("Selection Exceeds",'warning');  
        swal({
          // title: "Good job!",
          text: "Selection Exceeds!",
          icon: "warning",
          //@ts-ignore
          button: "OK",
        });
      }
    })
  }
  const hoverData = (title: any, id: any) => {
    if (url.includes("costing")) {
      if (id == topVault) {
        setTitleString("");
        API.get(
          "/cost/system_cost/",
          {
            top_vault: id,
            scenario: url.includes("scenario") ? scenarioId : undefined,
          },
          0
        )
          .then((res: any) => {
            setTitleString(
              (prev: any) =>
                `${prev} Total Cost: ${Number(
                  res.data[0]?.cost?.toFixed(2)
                )} Total Weight: ${Number(res.data[0]?.weight?.toFixed(2))}`
            );
          })
          .catch((err: any) => {
            setTitleString("");
          });
      } else {
        setTitleString("");
        API.get(
          "/cost/system_cost/",
          {
            vault: id,
            scenario: url.includes("scenario") ? scenarioId : undefined,
          },
          0
        )
          .then((res: any) => {
            setTitleString(
              (prev: any) =>
                `${prev} Total Cost: ${Number(
                  res.data[0]?.cost?.toFixed(2)
                )} Total Weight: ${Number(res.data[0]?.weight?.toFixed(2))}`
            );
          })
          .catch((err: any) => {
            setTitleString("");
          });
      }
    }
  };

  // useEffect(()=>{return sessionStorage.setItem("node",JSON.stringify([Number(topVault)]));},[topVault])

  const handleChange = (e: any, nodeId: any, abbreviation: any) => {
    let a: any = sessionStorage.getItem("node");
    let val: any = JSON.parse(a);
    if (val?.includes(nodeId)) {
      let noDuplicate: any = val?.filter((item: any) => {
        return item != nodeId;
      });
      let b: any = sessionStorage.setItem("node", JSON.stringify(noDuplicate));
    } else {
      val.push(nodeId);
      let b: any = sessionStorage.setItem("node", JSON.stringify(val));
    }
    setActiveId(nodeId);
    onSelectCallback(nodeId, abbreviation, topVault);
    const index = expanded.indexOf(nodeId);

    const copyExpanded = [...expanded];
    if (index === -1) {
      copyExpanded.push(nodeId);
    } else {
      copyExpanded.splice(index, 1);
    }
    setExpanded(copyExpanded);
    setSelected(copyExpanded);
    if (url?.includes("false")) {
      if (topVault == nodeId) {
        API.get(
          "customer/view_data_activity/",
          {
            top_vault: nodeId,
          },
          0
        ).then((res: any) => { });
      } else {
        API.get(
          "customer/view_data_activity/",
          {
            vault: nodeId,
          },
          0
        ).then((res: any) => { });
      }
    }
  };

  useEffect(() => {
    let a: any = sessionStorage.getItem("node");
    let val: any = JSON.parse(a);
    if (val != null) {
      setExpanded(val);
      setSelected(val);
    }
  }, [topVault, sessionStorage.getItem("node")]);

  useEffect(() => {
    setHierarchyAllData(HierarchyData && HierarchyData);
  }, [HierarchyData]);

  useEffect(() => {
    document.getElementById(vault)?.scrollIntoView({
      block: "nearest",
      inline: "start",
      behavior: "smooth",
    });
  }, [vault]);

  return (
    <>
      <TreeView
        expanded={expanded}
        key={HierarchyAllData?.id}
        id={HierarchyAllData?.id}
        defaultParentIcon={<FolderIcon fill="primary" />}
        className={styles.treetemp}
        defaultCollapseIcon={
          HierarchyAllData &&
          HierarchyAllData?.is_leaf == false && (
            <img src={openFolder} style={{ width: "18px", color: "#007fff" }} />
          )
        }
        defaultExpandIcon={
          HierarchyAllData?.is_leaf == false && <FolderIcon color="primary" />
        }
        defaultEndIcon={
          HierarchyAllData?.is_leaf ? (
            <InsertDriveFileIcon color="primary" />
          ) : (
            <FolderIcon color="primary" />
          )
        }
        onNodeSelect={(e: any) =>
          handleChange(e, HierarchyAllData?.id, HierarchyAllData?.abbreviation)
        }
        selected={selected}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        sx={{
          "&.MuiTreeItem-content{}.Mui-selected": {
            backgroundColor:
              HierarchyAllData?.id == vault
                ? "#cee6fa !important"
                : "white !important",
          },
        }}
      >
        <Paper sx={{ width: 320, maxWidth: "100%" }}>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open && open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{
              ".MuiList-root.MuiMenu-list": {
                padding: 0,
              },
            }}
          >
            {menuItems &&
              menuItems?.length > 0 &&
           

menuItems?.filter((item:any)=>item !=="scenario_exists" && item !== "scenario_created").map((item: any) => {
                return (
                  <MenuItem
                    sx={{
                      display: "flex !important",
                      flexDirection: "column !important",
                      textAlign: "left !important",
                      padding: "0.1rem 0.5rem",
                      color: "primary.main",
                    }}
                    onClick={() =>
                      handleRedirect(
                        item,
                        HierarchyAllData?.abbreviation,
                        HierarchyAllData?.id
                      )
                    }
                    style={{
                      fontSize: "0.9rem",
                      alignItems: "flex-start",
                      padding: "0.1rem 0.5rem",
                    }}
                  >
                    {item &&
                      item !== "Download Report" &&
                      menuItems.includes(item) ? (
                      `${getModule(item)}`
                    ) : (
                      //@ts-ignore
                      <NestedMenuItem
                        style={{
                          padding: "0",
                          fontSize: "0.9rem",
                          color: "primary.main",
                        }}
                        label={getModule(item) && `${getModule(item)}`}
                        parentMenuOpen={open}

                      // onClick={handleItemClick}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            padding: "0 0.5rem",
                            color: "primary.main",
                          }}
                        >
                          <MenuItem
                            sx={{
                              fontSize: "1rem",
                              color: "primary.main",
                              padding: "0.2rem 0.5rem",
                              // borderBottom:'1px solid #8080801c !important'
                            }}
                            onClick={() =>
                              handleRedirect(
                                "w",
                                HierarchyAllData?.abbreviation,
                                HierarchyAllData?.id
                              )
                            }
                          >
                            Consolidated with images
                          </MenuItem>
                          <MenuItem
                            sx={{
                              fontSize: "1rem",
                              color: "primary.main",
                              padding: "0.2rem 0.5rem",
                            }}
                            onClick={() =>
                              handleRedirect(
                                "w/o",
                                HierarchyAllData?.abbreviation,
                                HierarchyAllData?.id
                              )
                            }
                          >
                            Consolidated w/o images
                          </MenuItem>

                        </Box>
                      </NestedMenuItem>
                    )}
                  </MenuItem>
                );
              })}
            {HierarchyAllData?.abbreviation !== "product" && <MenuItem
              sx={{
                display: "flex !important",
                flexDirection: "column !important",
                textAlign: "left !important",
                padding: "0.1rem 0.5rem",
                color: "primary.main",
              }}
              onClick={() => handleCompareClick(HierarchyAllData?.abbreviation,
                HierarchyAllData?.id)}
              style={{
                fontSize: "0.9rem",
                alignItems: "flex-start",
                padding: "0.1rem 0.5rem",
              }}
            >
              Add to Quick Compare
            </MenuItem>}
          </Menu>
        </Paper>
        <StyledTreeItem
          data-index={HierarchyAllData?.id}
          nodeId={HierarchyAllData?.id}
          onMouseEnter={() =>
            hoverData(
              `${HierarchyAllData?.part_no} ${HierarchyAllData?.part_no ? "x" : ""
              } ${HierarchyAllData?.quantity} ${HierarchyAllData?.name}`,
              HierarchyAllData.id
            )
          }
          title={titleString && url.includes("costing") && titleString}
          label={
            <Typography className={styles.treeViewData}>
              <Typography
                style={{ fontSize: "12px" }}
                className={styles.treeViewText}
                onContextMenu={(event: any) =>
                  handleClick(
                    event,
                    HierarchyAllData.id,
                    HierarchyAllData.is_leaf,
                    HierarchyAllData
                  )
                }
              >
                {HierarchyAllData?.part_no} x {HierarchyAllData?.quantity}
                <br /> {HierarchyAllData?.name}
              </Typography>
            </Typography>
          }
        >
          {HierarchyAllData &&
            HierarchyAllData?.subsystem?.map(
              (subsystemData: any, index: any) => {
                return (
                  <>
                    <TreeViewComponents
                      HierarchyData={subsystemData}
                      key={subsystemData?.id}
                      onSelectCallback={onSelectCallback}
                    />
                  </>
                );
              }
            )}
        </StyledTreeItem>
      </TreeView>
      <DialogComponent
        isOpen={openStatusContent.isOpen}
        onClose={openStatusContent.close}
        content={contentText}
        type={iconText}
      />
    </>
  );
};

export default TreeViewComponents;
