import { Box, Button, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import styles from "./ReportsLandingPage.module.scss";

import useFetch from "../../Core/CustomHooks/useFetch";
import reportsbank from "../../Redux/Services/reportsBank.service";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import { API } from "../../api-services";
import { useStage } from "spritespin";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ShareIcon from "@mui/icons-material/Share";
import InfoIcon from "@mui/icons-material/Info";
import ReplyIcon from "@mui/icons-material/Reply";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import BouncingLoader from "../../ui-reusable-component/BouncingLoader";
import { useBaseModal } from "../SearchPage/useBaseModal";
import ShareReportsModal from "./ShareReportsModal";
//@ts-ignore
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import StorageIcon from "@mui/icons-material/Storage";

import swal from "sweetalert";
import LetSuspense from "../../Core/CustomHooks/LetSuspense";
import { fontSize } from "@mui/system";
import MoveToDriveModal from "./MoveToDriveModal";
import { isUndefined } from "lodash";
import { AUTH } from "../../Redux/Services/auth.service";
import { useSelector } from "react-redux";
import { userSelectors } from "../../Redux/Reducers/user.reducer";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";


type Props = {};

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>

      <Box sx={{ mt: 1 }}>You have not downloaded any files yet.</Box>
    </StyledGridOverlay>
  );
}

function CustomNoRowsOverlayShare() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>

      <Box sx={{ mt: 1 }}>There are no files shared with you .</Box>
    </StyledGridOverlay>
  );
}
const RepotsLandingPage = (props: Props) => {
  var { type, projectId, projectName, moduleId } = useParams<any>();
  const history = useHistory<any>();
  const { url } = useRouteMatch();
  const [expanded, setExpanded] = React.useState<any | false>(moduleId);
  const [DataGridData, setDataGridData] = useState<any>([]);
  const [dataLoad, setDataLoad] = useState<boolean>(true);
  const [FeatureLoader, setFeatureLoader] = useState<any>(false);
  const [BomLoader, setBomLoader] = useState<any>(false);
  const [CostingLoader, setCostingLoader] = useState<any>(false);
  const [MediaLoader, setMediaLoader] = useState<any>(false);
  const [ArchLoader, setArchLoader] = useState<any>(false);
  const [IdeaLoader, setIdeaLoader] = useState<any>(false);
  const [OtherLoader, setOtherLoader] = useState<any>(false);
  const [ShareType, setShareType] = useState<any>();
  const [FileId, setFileId] = useState<any>();
  const shareModaldata = useBaseModal();
  const moveModaldata = useBaseModal();
  const [Counters, setCounters] = useState<any>(0);
  const [UserSelectedIds, setUserSeelctedIds] = useState<any>();
  const [ModuleType, setModuleType] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [SelecteProjectId, setSelectedProjectId] = useState<any>()
  const { height, width } = useWindowDimensions();
  const [selectedRowData, SetselectedRowData] = useState<any>([])
  const { 0: loaderCount, 1: AllFileCount,2:setAllFileCount } = useFetch({
    method: reportsbank.getFileCount,
    args: [url.includes("my") ? "my" : "share"],
    fallBackErrMsg: "Could not fetch projects",
    dependencies: [type],
  });

  const userProfilePic = useSelector(userSelectors.selectAll);

  useEffect(() => {
    if (moduleId == 0)
      setExpanded(false)
  }, [moduleId])

  const handleChange =
    (panel: string, module: any) =>
      (event: React.SyntheticEvent, isExpanded: boolean) => {
        setModuleType(module);

        setExpanded(isExpanded ? module : false);
        history.push(`/reports/0/${type}/${module}`);
        setDataGridData([]);
        {
          if (module == 1) {
            setBomLoader(true);
            API.get(`/cost/report_bank_view/`, {
              // project_id: projectId,
              module_data: true,
              report_type: module,
            }, 0)
              .then((res: any) => {
                setDataGridData(res.data);
                setBomLoader(false);

              })
              .catch((err: any) => {
                setBomLoader(false);
              });
          }

          if (module == 2) {
            setMediaLoader(true);
            API.get(`/cost/report_bank_view/`, {
              project_id: projectId,
              module_data: true,
              report_type: module,
            }, 0)
              .then((res: any) => {
                setDataGridData(res.data);
                setMediaLoader(false);

              })
              .catch((err: any) => {
                setMediaLoader(false);
              });
          }

          if (module == 3) {
            setArchLoader(true);
            API.get(`/cost/report_bank_view/`, {
              // project_id: projectId,
              module_data: true,
              report_type: module,
            }, 0)
              .then((res: any) => {
                setDataGridData(res.data);
                setArchLoader(false);

              })
              .catch((err: any) => {
                setArchLoader(false);
              });
          }

          if (module == 4) {
            setOtherLoader(true);
            API.get(`/cost/report_bank_view/`, {
              // project_id: projectId,
              module_data: true,
              report_type: module,
            }, 0)
              .then((res: any) => {
                setDataGridData(res.data);
                setOtherLoader(false);
              })
              .catch((err: any) => {
                setOtherLoader(false);
              });
          }

          if (module == 5) {
            setIdeaLoader(true);
            API.get(`/cost/report_bank_view/`, {
              // project_id: projectId,
              module_data: true,
              report_type: module,
            }, 0)
              .then((res: any) => {
                setDataGridData(res.data);
                setIdeaLoader(false);
              })
              .catch((err: any) => {
                setIdeaLoader(false);
              });
          }

          if (module == 6) {
            setFeatureLoader(true);
            API.get(`/cost/report_bank_view/`, {
              // project_id: projectId,
              module_data: true,
              report_type: module,
            }, 0)
              .then((res: any) => {
                setDataGridData(res.data);
                setFeatureLoader(false);
              })
              .catch((err: any) => {
                setFeatureLoader(false);
              });
          }

          if (module == 7) {
            setCostingLoader(true);
            API.get(`/cost/report_bank_view/`, {
              // project_id: projectId,
              module_data: true,
              report_type: module,
            }, 0)
              .then((res: any) => {
                setDataGridData(res.data);
                setCostingLoader(false);

              })
              .catch((err: any) => {
                setCostingLoader(false);
              });
          }
        }
      };





  useEffect(() => {
    // setExpanded( moduleId );
    history.push(`/reports/0/${type}/${moduleId}/0`);

    if (moduleId !== "0") {
      if (type == "my") {


        API.get(`/cost/report_bank_view/`, {
          // project_id: projectId,
          module_data: true,
          report_type: moduleId,
        }, 0)
          .then((res: any) => {
            setDataGridData(res.data);
            // setBomLoader(false);

          })
          .catch((err: any) => {
            // setBomLoader(false);
          });
      } if (type == "share") {

        API.get(`/cost/report_bank_view/`, {
          // project_id: projectId,
          module_data: true,
          report_type: moduleId,
          shared_me: true,
        }, 0)
          .then((res: any) => {
            setDataGridData(res.data);
            // setBomLoader(false);

          })
          .catch((err: any) => {
            // setBomLoader(false);
          });

      }
    }
  }, [])


  const handleChangeShare =
    (panel: string, module: any) =>
      (event: React.SyntheticEvent, isExpanded: boolean) => {
        setModuleType(module);
        history.push(`/reports/0/${type}/${module}`);
        setExpanded(isExpanded ? module : false);
        setDataGridData([]);
        {
          if (module == 1) {
            setBomLoader(true);
            API.get(`/cost/report_bank_view/`, {
              // project_id: projectId,
              module_data: true,
              report_type: module,
              shared_me: true,
            }, 0)
              .then((res: any) => {
                setDataGridData(res.data);
                setBomLoader(false);
              })
              .catch((err: any) => {
                setBomLoader(false);
              });
          }

          if (module == 2) {
            setMediaLoader(true);
            API.get(`/cost/report_bank_view/`, {
              // project_id: projectId,
              module_data: true,
              report_type: module,
              shared_me: true,
            }, 0)
              .then((res: any) => {
                setDataGridData(res.data);
                setMediaLoader(false);

              })
              .catch((err: any) => {
                setMediaLoader(false);
              });
          }

          if (module == 3) {
            setArchLoader(true);
            API.get(`/cost/report_bank_view/`, {
              // project_id: projectId,
              module_data: true,
              report_type: module,
              shared_me: true,
            }, 0)
              .then((res: any) => {
                setDataGridData(res.data);
                setArchLoader(false);
                ;
              })
              .catch((err: any) => {
                setArchLoader(false);
              });
          }

          if (module == 4) {
            setOtherLoader(true);
            API.get(`/cost/report_bank_view/`, {
              // project_id: projectId,
              module_data: true,
              report_type: module,
              shared_me: true,
            }, 0)
              .then((res: any) => {
                setDataGridData(res.data);
                setOtherLoader(false);
              })
              .catch((err: any) => {
                setOtherLoader(false);
              });
          }

          if (module == 5) {
            setIdeaLoader(true);
            API.get(`/cost/report_bank_view/`, {
              // project_id: projectId,
              module_data: true,
              report_type: module,
              shared_me: true,
            }, 0)
              .then((res: any) => {
                setDataGridData(res.data);
                setIdeaLoader(false);
              })
              .catch((err: any) => {
                setIdeaLoader(false);
              });
          }

          if (module == 6) {
            setFeatureLoader(true);
            API.get(`/cost/report_bank_view/`, {
              // project_id: projectId,
              module_data: true,
              report_type: module,
              shared_me: true,
            }, 0)
              .then((res: any) => {
                setDataGridData(res.data);
                setFeatureLoader(false);
              })
              .catch((err: any) => {
                setFeatureLoader(false);
              });
          }

          if (module == 7) {
            setCostingLoader(true);
            API.get(`/cost/report_bank_view/`, {
              // project_id: projectId,
              module_data: true,
              report_type: module,
              shared_me: true,
            }, 0)
              .then((res: any) => {
                setDataGridData(res.data);
                setCostingLoader(false);

              })
              .catch((err: any) => {
                setCostingLoader(false);
              });
          }
        }
      };

  useEffect(() => {
    setDataLoad(true);
    if (moduleId !== "0") {


      if (Counters !== 0) {
        API.get(`/cost/report_bank_view/`, {
          // project_id: projectId,
          module_data: true,
          report_type: moduleId,
        }, 0)
          .then((res: any) => {
            setDataGridData(res.data);
            setDataLoad(false);
            // setBomLoader(false);

          })
          .catch((err: any) => {
            // setBomLoader(false);
          });
      }
    }
  }, [Counters]);
  const ShareFile = (Id: any, selectedId: any, projectIds: any) => {
    setFileId(Id);

    setSelectedProjectId(projectIds)
    if (expanded == 6) {
      setShareType(6);

      setUserSeelctedIds(selectedId);
      // shareModal.open
      var data = shareModaldata.open();
    } else if (expanded == 1) {
      setShareType(1);

      setUserSeelctedIds(selectedId);
      // shareModal.open
      var data = shareModaldata.open();
    } else if (expanded == 7) {
      setShareType(7);
      setUserSeelctedIds(selectedId);

      // shareModal.open
      var data = shareModaldata.open();
    } else if (expanded == 2) {
      setShareType(2);
      setUserSeelctedIds(selectedId);

      // shareModal.open
      var data = shareModaldata.open();
    } else if (expanded == 3) {
      setShareType(3);

      // shareModal.open
      var data = shareModaldata.open();
    } else if (expanded == 5) {
      setShareType(5);
      setUserSeelctedIds(selectedId);

      // shareModal.open
      var data = shareModaldata.open();
    } else if (expanded == 4) {
      setShareType(4);
      setUserSeelctedIds(selectedId);

      // shareModal.open
      var data = shareModaldata.open();
    }
  };

  const MoveFile = (Id: any, projectIds: any) => {
    setFileId(Id);
    setSelectedProjectId(projectIds)
    var filterRowData = DataGridData && DataGridData?.filter((item: any, index: any) => item?.id == Id)


    SetselectedRowData(filterRowData)



    if (expanded == 6) {
      setShareType(6);

      // setUserSeelctedIds(selectedId);

      var data = moveModaldata.open();
    } else if (expanded == 1) {
      setShareType(1);

      // setUserSeelctedIds(selectedId);

      var data = moveModaldata.open();
    } else if (expanded == 7) {
      setShareType(7);
      // setUserSeelctedIds(selectedId);


      var data = moveModaldata.open();
    } else if (expanded == 2) {
      setShareType(2);
      // setUserSeelctedIds(selectedId);


      var data = moveModaldata.open();
    } else if (expanded == 3) {
      setShareType(3);

      // shareModal.open
      var data = moveModaldata.open();
    } else if (expanded == 5) {
      setShareType(5);
      // setUserSeelctedIds(selectedId);


      var data = moveModaldata.open();
    } else if (expanded == 4) {
      setShareType(4);
      // setUserSeelctedIds(selectedId);

      // shareModal.open
      var data = moveModaldata.open();
    }
  };

  const DeleteHandler = (fileId: any) => {
    swal({
      title: "Delete",
      icon: "warning",
      text: "Are you sure!",
      buttons: ["Cancel", "Delete"],
    })
      .then((confirm) => {
        if (confirm)
          API.delete("xcpep/report_bank/stats/", { report_id: fileId }).then(
            (res: any) => {
              API.get("/cost/report_bank_view/",{


                report_count: true,report_category:url.includes("my") ? "my" : "share"
              }).then((res:any)=>{
                setAllFileCount(res?.data)

              })


              setCounters((prev: any) => prev + 1);






            }
          );
      })
      .catch((e) => { });
  };

  let columns: any =
    type == "my"
      ? [

        { field: "sno", headerName: "SNO.", minWidth: width > 1400 ? 40 : 40, flex: 1 },
        { field: "project_name", headerName: "Project Name", minWidth: width > 1400 ? 70 : 110, flex: 1 },

        { field: "project_id", headerName: "Project", hide: true },
        {
          field: "name",
          headerName: "File Description",
          minWidth: width > 1400 ? 500 : 400,
          flex: 1.5,
        },
        {
          field: "downloaded_by",
          headerName: "Downloaded By",
          // type: 'date',
          minWidth: 150,
          flex: 1,
        },

        {
          field: "downloaded_date",
          headerName: "Date",
          type: "date",
          minWidth: 100,
          flex: 1,
        },
        {
          field: "deletion_date",
          headerName: " Auto Deletion Date",
          type: "date",
          minWidth: 120,
          flex: 1,
        },

        {
          field: "status",
          headerName: " Download Status",

          minWidth: 120,
          flex: 1,
        },

        {
          field: "file_path",
          headerName: "Download",
          minWidth: 70,
          flex: 1,
          renderCell: (cellValues: any) => {
            return (
              <>
                <Button
                  color="inherit"
                  style={{ padding: "0rem", minWidth: "2rem" }}
                  disabled={cellValues?.row?.status == "Completed" ? false : true}
                >
                  <FileDownloadIcon
                    titleAccess="Download"
                    style={{ cursor: "pointer", fontSize: "1.5rem" }}
                    onClick={() => {
                      window.open(
                        cellValues?.row?.file_path,
                        "_blank",
                        " noopener"
                      );
                    }}
                  />
                </Button>
              </>
            );
          },
        },
        {
          field: "shared_with",
          headerName: "Share",
          minWidth: width > 1400 ? 80 : 60,
          flex: 1,
          renderCell: (cellValues: any) => {
            return (
              <>
                <Button
                  color="inherit"
                  style={{ padding: "0rem", minWidth: "2rem" }}
                  disabled={cellValues?.row?.status == "Completed" ? false : true}
                >
                  <ShareIcon
                    titleAccess="Share"
                    onClick={() =>
                      ShareFile(
                        cellValues?.row?.id,
                        cellValues?.row?.shared_with,
                        cellValues?.row?.project_id
                      )
                    }
                  />
                </Button>

                <Button
                  color="inherit"
                  style={{ padding: "0rem", minWidth: "2rem" }}
                  disabled={cellValues?.row?.status == "Completed" ? false : true}
                >
                  <InfoIcon
                    titleAccess={cellValues?.row?.shared_with?.title?.length == 0 ? "No user" : cellValues?.row?.shared_with?.title.toString(",")}
                    style={{ cursor: "pointer", fontSize: "1.5rem" }}
                  // onClick={(e: any) => {
                  //   handleDeleteTableData(e, cellValues?.row?.id)
                  // }}
                  />
                </Button>
                {/* <Button
                  color="inherit"
                  style={{ padding: '0rem', minWidth: '2rem' }}
                  disabled={cellValues?.row?.expiry_date < today}
                >
                  <LinkIcon
                    style={cellValues?.row?.expiry_date < today
                      ? { cursor: "not-allowed" ,fontSize:'1.5rem'}
                      : { cursor: "pointer",fontSize:'1.5rem' }}
                    onClick={() => {
                      copyPartName(window.location.href)
                    }}
                  />
                </Button> */}
              </>
            );
          },
        },
        {
          field: "Move",
          headerName: "Copy to Drive",
          minWidth: 90,
          flex: 1,
          renderCell: (cellValues: any) => {
            return (
              <Button
                color="inherit"
                style={{ padding: "0rem", minWidth: "2rem" }}
                title="Copy to Drive"
                disabled={cellValues?.row?.status == "Completed" ? false : true}
                onClick={() =>
                  MoveFile(
                    cellValues?.row?.id,
                    cellValues?.row?.project_id,

                  )
                }
              >
                <ReplyIcon sx={{ transform: "scaleX(-1)" }} />
                <StorageIcon
                  sx={{ fontSize: "1rem", margin: "-0.3rem 0 0 -0.3rem" }}
                />
              </Button>
            );
          },
        },
        {
          field: "delete",
          headerName: "Delete",
          minWidth: 60,
          flex: 1,
          renderCell: (cellValues: any) => {
            return (
              <>
                <Button
                  color="inherit"
                  style={{ padding: "0rem", minWidth: "2rem" }}
                >
                  <DeleteForeverIcon titleAccess="Delete"
                    onClick={() => DeleteHandler(cellValues?.row?.id)}
                  />
                </Button>
              </>
            );
          },
        },
      ]
      : [
        { field: "id", headerName: "ID", hide: true },

        { field: "sno", headerName: "SNO.", minWidth: width > 1400 ? 40 : 40, flex: 1 },
        { field: "project_name", headerName: "Project Name", minWidth: width > 1400 ? 70 : 130, flex: 1 },
        { field: "project_id", headerName: "Project", hide: true },
        {
          field: "name",
          headerName: "File Description",
          minWidth: 420,
          flex: 1,
        },
        {
          field: "downloaded_by",
          headerName: "Shared By",
          // type: 'date',
          minWidth: 150,
          flex: 1,
        },
        {
          field: "downloaded_date",
          headerName: "Date",
          type: "date",
          minWidth: 150,
          flex: 1,
        },
        {
          field: "deletion_date",
          headerName: " Auto Deletion Date",
          type: "date",
          minWidth: 150,
          flex: 1,
        },
        {
          field: "file_path",
          headerName: "Download",
          minWidth: 150,
          flex: 1,
          renderCell: (cellValues: any) => {
            return (
              <>
                <Button
                  color="inherit"
                  style={{ padding: "0rem", minWidth: "2rem" }}
                >
                  <FileDownloadIcon
                    style={{ cursor: "pointer", fontSize: "1.5rem" }}
                    onClick={() => {
                      window.open(
                        cellValues?.row?.file_path,
                        "_blank",
                        " noopener"
                      );
                    }}
                  />
                </Button>
              </>
            );
          },
        },
      ];
  var rows: any = [];

  const TableRow = () => {
    var valuedata =
      DataGridData &&
      DataGridData.map((item: any, id: any) => ({
        ...item,
        sno: id + 1,
      }));

    return valuedata;
  };
  let dataStructureDataGrid: any = {
    columns: columns,
    rows: TableRow() && TableRow(),
  };



  return (
    <div>
      <ShareReportsModal
        Open={shareModaldata.isOpen}
        isClose={shareModaldata.close}
        ProjectIdData={SelecteProjectId && SelecteProjectId}
        reprtsType={ShareType && ShareType}
        fileId={FileId}
        selectedUserNamesId={UserSelectedIds && UserSelectedIds}
        setCounters={setCounters}
      />
      {!isUndefined(FileId) &&
        <MoveToDriveModal
          Open={moveModaldata.isOpen}
          isClose={moveModaldata.close}
          ProjectIdData={SelecteProjectId && SelecteProjectId}
          reprtsType={ShareType && ShareType}
          fileId={FileId}
          SelectedDataValue={selectedRowData && selectedRowData}


        />
      }
      <Box className={styles.repotsLandingPage}>
        <Box
          className={
            type == "my" ? styles.reportactiveTab : styles.InactivereportTab
          }
          onClick={() => {
            setExpanded(false);

            history.push(`/reports/0/my/0`);
          }}
          style={{ fontSize: "1rem" }}
        >

          {userProfilePic && userProfilePic[0]?.is_superuser ? `${"All reports"}` : `${"My reports"}`}

        </Box>
        <Box className={type == "share" ? styles.reportactiveTab : styles.InactivereportTab}
          onClick={() => { setExpanded(false); history.push(`/reports/0/share/0`); }}
          style={{ fontSize: "1rem" }}
        >
          Reports shared with me
        </Box>
      </Box>

      <Box>
        {loaderCount == "TRUE" ? <>

          {
            (
              <Box
                sx={{ maxHeight: "85vh" }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3"
              >
                {
                  (
                    <Accordion
                      expanded={expanded == 6}
                      onChange={
                        type == "my"
                          ? handleChange("panel1", 6)
                          : handleChangeShare("panel1", 6)
                      }
                    >
                      <AccordionSummary
                        expandIcon={
                          <ArrowRightIcon sx={{ color: "primary.main" }} />
                        }
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        sx={{ minHeight: "34px" }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Typography sx={{ fontSize: "1rem", flexShrink: 0 }}>
                            Features
                          </Typography>
                          <Typography sx={{ fontSize: "1rem", flexShrink: 0 }}>
                            ( {AllFileCount && AllFileCount["Features"]} Files )
                          </Typography>
                        </Box>
                      </AccordionSummary>

                      <AccordionDetails>
                        {FeatureLoader ? (
                          <div>
                            <Skeleton />
                            <Skeleton />
                            <Skeleton /></div>
                        ) : (
                          <Box
                            sx={{
                              height: "67vh",
                              width: "100%",

                              "& .cold": {
                                cursor: "pointer",
                              },
                            }}
                          >
                            {type == "my" ? (
                              <DataGrid
                                // headerHeight={48}                                
                                // rowHeight={36}
                                {...dataStructureDataGrid}
                                components={{ NoRowsOverlay: CustomNoRowsOverlay }}
                                // loading={loading || !load}
                                // components={{ Toolbar: () => { return CustomToolbar(groupItems?.id) } }}
                                pageSize={59}
                                rowsPerPageOptions={[59]}
                                density={"compact"}
                                // onCellClick={(params, event) => {
                                //   event.defaultMuiPrevented = true;
                                //   if (params?.field == "view") {
                                //     viewFile(
                                //       params?.row?.pathDontShow,
                                //       params?.row?.type,
                                //       params?.row?.expiry_date
                                //     );
                                //   }
                                // }}
                                sx={{
                                  boxShadow: 2,
                                  border: 2,
                                  borderColor: "primary.light",
                                  "& .MuiDataGrid-cell:hover": {
                                    color: "primary.main",
                                  },
                                  overflowY: "auto",
                                  "& ::-webkit-scrollbar": {
                                    width: "0.3rem",
                                    height: "0.3rem",
                                  },
                                  "& ::-webkit-scrollbar-thumb": {
                                    // background: $thumb-color;
                                    backgroundImage:
                                      "linear-gradient(#09C6F9, #045DE9)",
                                    borderRadius: "10px",
                                  },
                                  "& ::- webkit-scrollbar-thumb: hover ": {
                                    // width: 0.4rem;
                                    backgroundColor: "#045DE9 !important",
                                  },
                                  "::-webkit-scrollbar-track": {
                                    backgroundColor: "#e1e1f3 !important",
                                  },
                                }}
                                getCellClassName={(params: any) => {
                                  return params?.field == "view" && "cold";
                                }}
                              />
                            ) : (
                              <DataGrid
                                // headerHeight={48}
                                // rowHeight={36}
                                {...dataStructureDataGrid}
                                // loading={loading || !load}
                                components={{
                                  NoRowsOverlay: CustomNoRowsOverlayShare,
                                }}
                                // components={{ Toolbar: () => { return CustomToolbar(groupItems?.id) } }}
                                pageSize={59}
                                rowsPerPageOptions={[59]}
                                density={"compact"}
                                // onCellClick={(params, event) => {
                                //   event.defaultMuiPrevented = true;
                                //   if (params?.field == "view") {
                                //     viewFile(
                                //       params?.row?.pathDontShow,
                                //       params?.row?.type,
                                //       params?.row?.expiry_date
                                //     );
                                //   }
                                // }}
                                sx={{
                                  boxShadow: 2,
                                  border: 2,
                                  borderColor: "primary.light",
                                  "& .MuiDataGrid-cell:hover": {
                                    color: "primary.main",
                                  },
                                  overflowY: "auto",
                                  "& ::-webkit-scrollbar": {
                                    width: "0.6rem",
                                    height: "0.6rem",
                                  },
                                  "& ::-webkit-scrollbar-thumb": {
                                    // background: $thumb-color;
                                    backgroundImage:
                                      "linear-gradient(#09C6F9, #045DE9)",
                                    borderRadius: "10px",
                                  },
                                  "& ::- webkit-scrollbar-thumb: hover ": {
                                    // width: 0.4rem;
                                    backgroundColor: "#045DE9 !important",
                                  },
                                  "::-webkit-scrollbar-track": {
                                    backgroundColor: "#e1e1f3 !important",
                                  },
                                }}
                                getCellClassName={(params: any) => {
                                  return params?.field == "view" && "cold";
                                }}
                              />
                            )}
                          </Box>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  )}
                {(
                  <Accordion
                    expanded={expanded == 1}
                    onChange={
                      type == "my"
                        ? handleChange("panel2", 1)
                        : handleChangeShare("panel2", 1)
                    }
                  // onChange={handleChange("panel2", 1)}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ArrowRightIcon sx={{ color: "primary.main" }} />
                      }
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                      sx={{ minHeight: "34px" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Typography sx={{ fontSize: "1rem", flexShrink: 0 }}>
                          BOM
                        </Typography>
                        <Typography sx={{ fontSize: "1rem", flexShrink: 0 }}>
                          ( {AllFileCount && AllFileCount["BOM"]} Files )
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {BomLoader ? (
                        <div>
                          <Skeleton />
                          <Skeleton />
                          <Skeleton /></div>
                      ) : (
                        <Box
                          sx={{
                            height: "67vh",
                            width: "100%",

                            "& .cold": {
                              cursor: "pointer",
                            },
                          }}
                        >
                          {type == "my" ? (
                            <DataGrid 
                            // headerHeight={48}
                            //   rowHeight={36}
                              {...dataStructureDataGrid}
                              loading={BomLoader}
                              components={{ NoRowsOverlay: CustomNoRowsOverlay }}
                              // components={{ Toolbar: () => { return CustomToolbar(groupItems?.id) } }}
                              pageSize={59}
                              rowsPerPageOptions={[59]}
                              density={"compact"}
                              // onCellClick={(params, event) => {
                              //   event.defaultMuiPrevented = true;
                              //   if (params?.field == "view") {
                              //     viewFile(
                              //       params?.row?.pathDontShow,
                              //       params?.row?.type,
                              //       params?.row?.expiry_date
                              //     );
                              //   }
                              // }}
                              sx={{
                                boxShadow: 2,
                                border: 2,
                                borderColor: "primary.light",
                                "& .MuiDataGrid-cell:hover": {
                                  color: "primary.main",
                                },
                                overflowY: "auto",
                                "& ::-webkit-scrollbar": {
                                  width: "0.6rem",
                                  height: "0.6rem",
                                },
                                "& ::-webkit-scrollbar-thumb": {
                                  // background: $thumb-color;
                                  backgroundImage:
                                    "linear-gradient(#09C6F9, #045DE9)",
                                  borderRadius: "10px",
                                },
                                "& ::- webkit-scrollbar-thumb: hover ": {
                                  // width: 0.4rem;
                                  backgroundColor: "#045DE9 !important",
                                },
                                "::-webkit-scrollbar-track": {
                                  backgroundColor: "#e1e1f3 !important",
                                },
                              }}
                              getCellClassName={(params: any) => {
                                return params?.field == "view" && "cold";
                              }}
                            />
                          ) : (
                            <DataGrid 
                            // headerHeight={48}
                            //   rowHeight={36}
                              {...dataStructureDataGrid}
                              // loading={loading || !load}
                              components={{
                                NoRowsOverlay: CustomNoRowsOverlayShare,
                              }}
                              // components={{ Toolbar: () => { return CustomToolbar(groupItems?.id) } }}
                              pageSize={59}
                              rowsPerPageOptions={[59]}
                              density={"compact"}
                              // onCellClick={(params, event) => {
                              //   event.defaultMuiPrevented = true;
                              //   if (params?.field == "view") {
                              //     viewFile(
                              //       params?.row?.pathDontShow,
                              //       params?.row?.type,
                              //       params?.row?.expiry_date
                              //     );
                              //   }
                              // }}
                              sx={{
                                boxShadow: 2,
                                border: 2,
                                borderColor: "primary.light",
                                "& .MuiDataGrid-cell:hover": {
                                  color: "primary.main",
                                },
                                overflowY: "auto",
                                "& ::-webkit-scrollbar": {
                                  width: "0.6rem",
                                  height: "0.6rem",
                                },
                                "& ::-webkit-scrollbar-thumb": {
                                  // background: $thumb-color;
                                  backgroundImage:
                                    "linear-gradient(#09C6F9, #045DE9)",
                                  borderRadius: "10px",
                                },
                                "& ::- webkit-scrollbar-thumb: hover ": {
                                  // width: 0.4rem;
                                  backgroundColor: "#045DE9 !important",
                                },
                                "::-webkit-scrollbar-track": {
                                  backgroundColor: "#e1e1f3 !important",
                                },
                              }}
                              getCellClassName={(params: any) => {
                                return params?.field == "view" && "cold";
                              }}
                            />
                          )}
                        </Box>
                      )}
                    </AccordionDetails>
                  </Accordion>
                )}

                {(
                  <Accordion
                    expanded={expanded == 7}
                    onChange={
                      type == "my"
                        ? handleChange("panel3", 7)
                        : handleChangeShare("panel3", 7)
                    }
                  // onChange={handleChange("panel3", 7)}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ArrowRightIcon sx={{ color: "primary.main" }} />
                      }
                      aria-controls="panel3bh-content"
                      id="panel3bh-header"
                      sx={{ minHeight: "34px" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Typography sx={{ fontSize: "1rem", flexShrink: 0 }}>
                          Costing
                        </Typography>
                        <Typography sx={{ fontSize: "1rem", flexShrink: 0 }}>
                          ( {AllFileCount && AllFileCount["Costing"]} Files )
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {CostingLoader ? (
                        <div>
                          <Skeleton />
                          <Skeleton />
                          <Skeleton /></div>
                      ) : (
                        <Box
                          sx={{
                            height: "67vh",
                            width: "100%",

                            "& .cold": {
                              cursor: "pointer",
                            },
                          }}
                        >
                          {type == "my" ? (
                            <DataGrid 
                            // headerHeight={48}
                            //   rowHeight={36}
                              {...dataStructureDataGrid}
                              components={{ NoRowsOverlay: CustomNoRowsOverlay }}
                              loading={CostingLoader}
                              // components={{ Toolbar: () => { return CustomToolbar(groupItems?.id) } }}
                              pageSize={59}
                              rowsPerPageOptions={[59]}
                              density={"compact"}
                              // onCellClick={(params, event) => {
                              //   event.defaultMuiPrevented = true;
                              //   if (params?.field == "view") {
                              //     viewFile(
                              //       params?.row?.pathDontShow,
                              //       params?.row?.type,
                              //       params?.row?.expiry_date
                              //     );
                              //   }
                              // }}
                              sx={{
                                boxShadow: 2,
                                border: 2,
                                borderColor: "primary.light",
                                "& .MuiDataGrid-cell:hover": {
                                  color: "primary.main",
                                },
                                overflowY: "auto",
                                "& ::-webkit-scrollbar": {
                                  width: "0.6rem",
                                  height: "0.6rem",
                                },
                                "& ::-webkit-scrollbar-thumb": {
                                  // background: $thumb-color;
                                  backgroundImage:
                                    "linear-gradient(#09C6F9, #045DE9)",
                                  borderRadius: "10px",
                                },
                                "& ::- webkit-scrollbar-thumb: hover ": {
                                  // width: 0.4rem;
                                  backgroundColor: "#045DE9 !important",
                                },
                                "::-webkit-scrollbar-track": {
                                  backgroundColor: "#e1e1f3 !important",
                                },
                              }}
                              getCellClassName={(params: any) => {
                                return params?.field == "view" && "cold";
                              }}
                            />
                          ) : (
                            <DataGrid 
                            //  headerHeight={48}
                            //   rowHeight={36}
                              {...dataStructureDataGrid}
                              components={{
                                NoRowsOverlay: CustomNoRowsOverlayShare,
                              }}
                              // loading={loading || !load}
                              // components={{ Toolbar: () => { return CustomToolbar(groupItems?.id) } }}
                              pageSize={59}
                              rowsPerPageOptions={[59]}
                              density={"compact"}
                              // onCellClick={(params, event) => {
                              //   event.defaultMuiPrevented = true;
                              //   if (params?.field == "view") {
                              //     viewFile(
                              //       params?.row?.pathDontShow,
                              //       params?.row?.type,
                              //       params?.row?.expiry_date
                              //     );
                              //   }
                              // }}
                              sx={{
                                boxShadow: 2,
                                border: 2,
                                borderColor: "primary.light",
                                "& .MuiDataGrid-cell:hover": {
                                  color: "primary.main",
                                },
                                overflowY: "auto",
                                "& ::-webkit-scrollbar": {
                                  width: "0.6rem",
                                  height: "0.6rem",
                                },
                                "& ::-webkit-scrollbar-thumb": {
                                  // background: $thumb-color;
                                  backgroundImage:
                                    "linear-gradient(#09C6F9, #045DE9)",
                                  borderRadius: "10px",
                                },
                                "& ::- webkit-scrollbar-thumb: hover ": {
                                  // width: 0.4rem;
                                  backgroundColor: "#045DE9 !important",
                                },
                                "::-webkit-scrollbar-track": {
                                  backgroundColor: "#e1e1f3 !important",
                                },
                              }}
                              getCellClassName={(params: any) => {
                                return params?.field == "view" && "cold";
                              }}
                            />
                          )}
                        </Box>
                      )}
                    </AccordionDetails>
                  </Accordion>
                )}
                {(
                  <Accordion
                    expanded={expanded == 2}
                    onChange={
                      type == "my"
                        ? handleChange("panel4", 2)
                        : handleChangeShare("panel4", 2)
                    }
                  // onChange={handleChange("panel4", 2)}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ArrowRightIcon sx={{ color: "primary.main" }} />
                      }
                      aria-controls="panel4bh-content"
                      id="panel4bh-header"
                      sx={{ minHeight: "34px" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Typography sx={{ fontSize: "1rem", flexShrink: 0 }}>
                          Media
                        </Typography>
                        <Typography sx={{ fontSize: "1rem", flexShrink: 0 }}>
                          ( {AllFileCount && AllFileCount["Media"]} Files )
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {MediaLoader ? (
                        <div>
                          <Skeleton />
                          <Skeleton />
                          <Skeleton /></div>
                      ) : (
                        <Box
                          sx={{
                            height: "67vh",
                            width: "100%",

                            "& .cold": {
                              cursor: "pointer",
                            },
                          }}
                        >
                          {type == "my" ? (
                            <DataGrid 
                            // headerHeight={48}
                            //   rowHeight={36}
                              {...dataStructureDataGrid}
                              components={{ NoRowsOverlay: CustomNoRowsOverlay }}
                              // loading={loading || !load}
                              // components={{ Toolbar: () => { return CustomToolbar(groupItems?.id) } }}
                              pageSize={59}
                              rowsPerPageOptions={[59]}
                              density={"compact"}
                              // onCellClick={(params, event) => {
                              //   event.defaultMuiPrevented = true;
                              //   if (params?.field == "view") {
                              //     viewFile(
                              //       params?.row?.pathDontShow,
                              //       params?.row?.type,
                              //       params?.row?.expiry_date
                              //     );
                              //   }
                              // }}
                              sx={{
                                boxShadow: 2,
                                border: 2,
                                borderColor: "primary.light",
                                "& .MuiDataGrid-cell:hover": {
                                  color: "primary.main",
                                },
                                overflowY: "auto",
                                "& ::-webkit-scrollbar": {
                                  width: "0.6rem",
                                  height: "0.6rem",
                                },
                                "& ::-webkit-scrollbar-thumb": {
                                  // background: $thumb-color;
                                  backgroundImage:
                                    "linear-gradient(#09C6F9, #045DE9)",
                                  borderRadius: "10px",
                                },
                                "& ::- webkit-scrollbar-thumb: hover ": {
                                  // width: 0.4rem;
                                  backgroundColor: "#045DE9 !important",
                                },
                                "::-webkit-scrollbar-track": {
                                  backgroundColor: "#e1e1f3 !important",
                                },
                              }}
                              getCellClassName={(params: any) => {
                                return params?.field == "view" && "cold";
                              }}
                            />
                          ) : (
                            <DataGrid 
                            // headerHeight={48}
                            //   rowHeight={36}
                              {...dataStructureDataGrid}
                              components={{
                                NoRowsOverlay: CustomNoRowsOverlayShare,
                              }}
                              // loading={loading || !load}
                              // components={{ Toolbar: () => { return CustomToolbar(groupItems?.id) } }}
                              pageSize={59}
                              rowsPerPageOptions={[59]}
                              density={"compact"}
                              // onCellClick={(params, event) => {
                              //   event.defaultMuiPrevented = true;
                              //   if (params?.field == "view") {
                              //     viewFile(
                              //       params?.row?.pathDontShow,
                              //       params?.row?.type,
                              //       params?.row?.expiry_date
                              //     );
                              //   }
                              // }}
                              sx={{
                                boxShadow: 2,
                                border: 2,
                                borderColor: "primary.light",
                                "& .MuiDataGrid-cell:hover": {
                                  color: "primary.main",
                                },
                                overflowY: "auto",
                                "& ::-webkit-scrollbar": {
                                  width: "0.6rem",
                                  height: "0.6rem",
                                },
                                "& ::-webkit-scrollbar-thumb": {
                                  // background: $thumb-color;
                                  backgroundImage:
                                    "linear-gradient(#09C6F9, #045DE9)",
                                  borderRadius: "10px",
                                },
                                "& ::- webkit-scrollbar-thumb: hover ": {
                                  // width: 0.4rem;
                                  backgroundColor: "#045DE9 !important",
                                },
                                "::-webkit-scrollbar-track": {
                                  backgroundColor: "#e1e1f3 !important",
                                },
                              }}
                              getCellClassName={(params: any) => {
                                return params?.field == "view" && "cold";
                              }}
                            />
                          )}
                        </Box>
                      )}
                    </AccordionDetails>
                  </Accordion>
                )}

                {(
                  <Accordion
                    expanded={expanded == 3}
                    onChange={
                      type == "my"
                        ? handleChange("panel5", 3)
                        : handleChangeShare("panel5", 3)
                    }
                  // onChange={handleChange("panel5", 3)}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ArrowRightIcon sx={{ color: "primary.main" }} />
                      }
                      aria-controls="panel4bh-content"
                      id="panel4bh-header"
                      sx={{ minHeight: "34px" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Typography sx={{ fontSize: "1rem", flexShrink: 0 }}>
                          Architecture
                        </Typography>
                        <Typography sx={{ fontSize: "1rem", flexShrink: 0 }}>
                          ( {AllFileCount && AllFileCount["Architecture"]} Files )
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {ArchLoader ? (
                        <div>
                          <Skeleton />
                          <Skeleton />
                          <Skeleton /></div>
                      ) : (
                        <Box
                          sx={{
                            height: "67vh",
                            width: "100%",

                            "& .cold": {
                              cursor: "pointer",
                            },
                          }}
                        >
                          {type == "my" ? (
                            <DataGrid 
                            // headerHeight={48}
                            //   rowHeight={36}
                              {...dataStructureDataGrid}
                              components={{ NoRowsOverlay: CustomNoRowsOverlay }}
                              // loading={loading || !load}
                              // components={{ Toolbar: () => { return CustomToolbar(groupItems?.id) } }}
                              pageSize={59}
                              rowsPerPageOptions={[59]}
                              density={"compact"}
                              // onCellClick={(params, event) => {
                              //   event.defaultMuiPrevented = true;
                              //   if (params?.field == "view") {
                              //     viewFile(
                              //       params?.row?.pathDontShow,
                              //       params?.row?.type,
                              //       params?.row?.expiry_date
                              //     );
                              //   }
                              // }}
                              sx={{
                                boxShadow: 2,
                                border: 2,
                                borderColor: "primary.light",
                                "& .MuiDataGrid-cell:hover": {
                                  color: "primary.main",
                                },
                                overflowY: "auto",
                                "& ::-webkit-scrollbar": {
                                  width: "0.6rem",
                                  height: "0.6rem",
                                },
                                "& ::-webkit-scrollbar-thumb": {
                                  // background: $thumb-color;
                                  backgroundImage:
                                    "linear-gradient(#09C6F9, #045DE9)",
                                  borderRadius: "10px",
                                },
                                "& ::- webkit-scrollbar-thumb: hover ": {
                                  // width: 0.4rem;
                                  backgroundColor: "#045DE9 !important",
                                },
                                "::-webkit-scrollbar-track": {
                                  backgroundColor: "#e1e1f3 !important",
                                },
                              }}
                              getCellClassName={(params: any) => {
                                return params?.field == "view" && "cold";
                              }}
                            />
                          ) : (
                            <DataGrid 
                            // headerHeight={48}
                            //   rowHeight={36}
                              {...dataStructureDataGrid}
                              components={{
                                NoRowsOverlay: CustomNoRowsOverlayShare,
                              }}
                              // loading={loading || !load}
                              // components={{ Toolbar: () => { return CustomToolbar(groupItems?.id) } }}
                              pageSize={59}
                              rowsPerPageOptions={[59]}
                              density={"compact"}
                              // onCellClick={(params, event) => {
                              //   event.defaultMuiPrevented = true;
                              //   if (params?.field == "view") {
                              //     viewFile(
                              //       params?.row?.pathDontShow,
                              //       params?.row?.type,
                              //       params?.row?.expiry_date
                              //     );
                              //   }
                              // }}
                              sx={{
                                boxShadow: 2,
                                border: 2,
                                borderColor: "primary.light",
                                "& .MuiDataGrid-cell:hover": {
                                  color: "primary.main",
                                },
                                overflowY: "auto",
                                "& ::-webkit-scrollbar": {
                                  width: "0.6rem",
                                  height: "0.6rem",
                                },
                                "& ::-webkit-scrollbar-thumb": {
                                  // background: $thumb-color;
                                  backgroundImage:
                                    "linear-gradient(#09C6F9, #045DE9)",
                                  borderRadius: "10px",
                                },
                                "& ::- webkit-scrollbar-thumb: hover ": {
                                  // width: 0.4rem;
                                  backgroundColor: "#045DE9 !important",
                                },
                                "::-webkit-scrollbar-track": {
                                  backgroundColor: "#e1e1f3 !important",
                                },
                              }}
                              getCellClassName={(params: any) => {
                                return params?.field == "view" && "cold";
                              }}
                            />
                          )}
                        </Box>
                      )}
                    </AccordionDetails>
                  </Accordion>
                )}

                {(
                  <Accordion
                    expanded={expanded == 5}
                    onChange={
                      type == "my"
                        ? handleChange("panel6", 5)
                        : handleChangeShare("panel6", 5)
                    }
                  // onChange={handleChange("panel6", 5)}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ArrowRightIcon sx={{ color: "primary.main" }} />
                      }
                      aria-controls="panel4bh-content"
                      id="panel4bh-header"
                      sx={{ minHeight: "34px" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Typography sx={{ fontSize: "1rem", flexShrink: 0 }}>
                          Ideas
                        </Typography>
                        <Typography sx={{ fontSize: "1rem", flexShrink: 0 }}>
                          ( {AllFileCount && AllFileCount["Ideas"]} Files )
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {IdeaLoader ? (
                        <div>
                          <Skeleton />
                          <Skeleton />
                          <Skeleton /></div>
                      ) : (
                        <Box
                          sx={{
                            height: "67vh",
                            width: "100%",

                            "& .cold": {
                              cursor: "pointer",
                            },
                          }}
                        >
                          {type == "my" ? (
                            <DataGrid 
                            // headerHeight={48}
                            //   rowHeight={36}
                              {...dataStructureDataGrid}
                              components={{ NoRowsOverlay: CustomNoRowsOverlay }}
                              // loading={loading || !load}
                              // components={{ Toolbar: () => { return CustomToolbar(groupItems?.id) } }}
                              pageSize={59}
                              rowsPerPageOptions={[59]}
                              density={"compact"}
                              // onCellClick={(params, event) => {
                              //   event.defaultMuiPrevented = true;
                              //   if (params?.field == "view") {
                              //     viewFile(
                              //       params?.row?.pathDontShow,
                              //       params?.row?.type,
                              //       params?.row?.expiry_date
                              //     );
                              //   }
                              // }}
                              sx={{
                                boxShadow: 2,
                                border: 2,
                                borderColor: "primary.light",
                                "& .MuiDataGrid-cell:hover": {
                                  color: "primary.main",
                                },
                                overflowY: "auto",
                                "& ::-webkit-scrollbar": {
                                  width: "0.6rem",
                                  height: "0.6rem",
                                },
                                "& ::-webkit-scrollbar-thumb": {
                                  // background: $thumb-color;
                                  backgroundImage:
                                    "linear-gradient(#09C6F9, #045DE9)",
                                  borderRadius: "10px",
                                },
                                "& ::- webkit-scrollbar-thumb: hover ": {
                                  // width: 0.4rem;
                                  backgroundColor: "#045DE9 !important",
                                },
                                "::-webkit-scrollbar-track": {
                                  backgroundColor: "#e1e1f3 !important",
                                },
                              }}
                              getCellClassName={(params: any) => {
                                return params?.field == "view" && "cold";
                              }}
                            />
                          ) : (
                            <DataGrid
                            //  headerHeight={48}
                            //   rowHeight={36}
                              {...dataStructureDataGrid}
                              components={{
                                NoRowsOverlay: CustomNoRowsOverlayShare,
                              }}
                              // loading={loading || !load}
                              // components={{ Toolbar: () => { return CustomToolbar(groupItems?.id) } }}
                              pageSize={59}
                              rowsPerPageOptions={[59]}
                              density={"compact"}
                              // onCellClick={(params, event) => {
                              //   event.defaultMuiPrevented = true;
                              //   if (params?.field == "view") {
                              //     viewFile(
                              //       params?.row?.pathDontShow,
                              //       params?.row?.type,
                              //       params?.row?.expiry_date
                              //     );
                              //   }
                              // }}
                              sx={{
                                boxShadow: 2,
                                border: 2,
                                borderColor: "primary.light",
                                "& .MuiDataGrid-cell:hover": {
                                  color: "primary.main",
                                },
                                overflowY: "auto",
                                "& ::-webkit-scrollbar": {
                                  width: "0.6rem",
                                  height: "0.6rem",
                                },
                                "& ::-webkit-scrollbar-thumb": {
                                  // background: $thumb-color;
                                  backgroundImage:
                                    "linear-gradient(#09C6F9, #045DE9)",
                                  borderRadius: "10px",
                                },
                                "& ::- webkit-scrollbar-thumb: hover ": {
                                  // width: 0.4rem;
                                  backgroundColor: "#045DE9 !important",
                                },
                                "::-webkit-scrollbar-track": {
                                  backgroundColor: "#e1e1f3 !important",
                                },
                              }}
                              getCellClassName={(params: any) => {
                                return params?.field == "view" && "cold";
                              }}
                            />
                          )}
                        </Box>
                      )}
                    </AccordionDetails>
                  </Accordion>
                )}

                {(
                  <Accordion
                    expanded={expanded == 4}
                    onChange={
                      type == "my"
                        ? handleChange("panel7", 4)
                        : handleChangeShare("panel7", 4)
                    }
                  // onChange={handleChange("panel6", 4)}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ArrowRightIcon sx={{ color: "primary.main" }} />
                      }
                      aria-controls="panel4bh-content"
                      id="panel4bh-header"
                      sx={{ minHeight: "34px" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Typography sx={{ fontSize: "1rem", flexShrink: 0 }}>
                          Others
                        </Typography>
                        <Typography sx={{ fontSize: "1rem", flexShrink: 0 }}>
                          ( {AllFileCount && AllFileCount["Others"]} Files )
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {OtherLoader ? (
                        <div>
                          <Skeleton />
                          <Skeleton />
                          <Skeleton /></div>
                      ) : (
                        <Box
                          sx={{
                            height: "67vh",
                            width: "100%",

                            "& .cold": {
                              cursor: "pointer",
                            },
                          }}
                        >
                          {type == "my" ? (
                            <DataGrid 
                            // headerHeight={48}
                            //   rowHeight={36}
                              {...dataStructureDataGrid}
                              components={{ NoRowsOverlay: CustomNoRowsOverlay }}
                              // loading={loading || !load}
                              // components={{ Toolbar: () => { return CustomToolbar(groupItems?.id) } }}
                              pageSize={59}
                              rowsPerPageOptions={[59]}
                              density={"compact"}
                              // onCellClick={(params, event) => {
                              //   event.defaultMuiPrevented = true;
                              //   if (params?.field == "view") {
                              //     viewFile(
                              //       params?.row?.pathDontShow,
                              //       params?.row?.type,
                              //       params?.row?.expiry_date
                              //     );
                              //   }
                              // }}
                              sx={{
                                boxShadow: 2,
                                border: 2,
                                borderColor: "primary.light",
                                "& .MuiDataGrid-cell:hover": {
                                  color: "primary.main",
                                },
                                overflowY: "auto",
                                "& ::-webkit-scrollbar": {
                                  width: "0.6rem",
                                  height: "0.6rem",
                                },
                                "& ::-webkit-scrollbar-thumb": {
                                  // background: $thumb-color;
                                  backgroundImage:
                                    "linear-gradient(#09C6F9, #045DE9)",
                                  borderRadius: "10px",
                                },
                                "& ::- webkit-scrollbar-thumb: hover ": {
                                  // width: 0.4rem;
                                  backgroundColor: "#045DE9 !important",
                                },
                                "::-webkit-scrollbar-track": {
                                  backgroundColor: "#e1e1f3 !important",
                                },
                              }}
                              getCellClassName={(params: any) => {
                                return params?.field == "view" && "cold";
                              }}
                            />
                          ) : (
                            <DataGrid 
                            // headerHeight={48}
                            //   rowHeight={36}
                              {...dataStructureDataGrid}
                              components={{
                                NoRowsOverlay: CustomNoRowsOverlayShare,
                              }}
                              // loading={loading || !load}
                              // components={{ Toolbar: () => { return CustomToolbar(groupItems?.id) } }}
                              pageSize={59}
                              rowsPerPageOptions={[59]}
                              density={"compact"}
                              // onCellClick={(params, event) => {
                              //   event.defaultMuiPrevented = true;
                              //   if (params?.field == "view") {
                              //     viewFile(
                              //       params?.row?.pathDontShow,
                              //       params?.row?.type,
                              //       params?.row?.expiry_date
                              //     );
                              //   }
                              // }}
                              sx={{
                                boxShadow: 2,
                                border: 2,
                                borderColor: "primary.light",
                                "& .MuiDataGrid-cell:hover": {
                                  color: "primary.main",
                                },
                                overflowY: "auto",
                                "& ::-webkit-scrollbar": {
                                  width: "0.6rem",
                                  height: "0.6rem",
                                },
                                "& ::-webkit-scrollbar-thumb": {
                                  // background: $thumb-color;
                                  backgroundImage:
                                    "linear-gradient(#09C6F9, #045DE9)",
                                  borderRadius: "10px",
                                },
                                "& ::- webkit-scrollbar-thumb: hover ": {
                                  // width: 0.4rem;
                                  backgroundColor: "#045DE9 !important",
                                },
                                "::-webkit-scrollbar-track": {
                                  backgroundColor: "#e1e1f3 !important",
                                },
                              }}
                              getCellClassName={(params: any) => {
                                return params?.field == "view" && "cold";
                              }}
                            />
                          )}
                        </Box>
                      )}
                    </AccordionDetails>
                  </Accordion>
                )}
              </Box>
            )}  </> :
          <div>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton /></div>

        }

        {/* </LetSuspense> */}

      </Box>
    </div>
  );
};

export default RepotsLandingPage;
