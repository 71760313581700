import React, { useEffect } from "react";
import { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Skeleton,
  styled,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import ShareIcon from "@mui/icons-material/Share";
import styles from "./BomConfigurationLanding.module.scss";

import Typography from "@mui/material/Typography";
import { API } from "../../api-services";
import CostingConfigurationDrawer from "../Costing/CostingConfigurationDrawer";
import BOMCustomValidation from "./BOMCustomValidation";
import { useShareCustomValidationModal } from "./useShareCustomValidationModal";
import BOMCustomValidationShareModal from "./BOMCustomValidationShareModal";
import validated from '../../Assets/images/Validated.svg';
import notvalidated from '../../Assets/images/NotValidated.svg';
import { useDocumentTitle } from "../../ui-reusable-component/useDocumentTitle";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import swal from "sweetalert";
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import FolderIcon from '@mui/icons-material/Folder';
import openFolder from '../../Assets/images/svgs/openfolderblue.svg';
import BOMParameterAddModal from "./BOMParameterAddModal";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";



type Anchor = "top" | "left" | "bottom" | "right";

interface Params {
  projectId: any;
  vehicleCategoryId:any;
  topVaultId: any;
  categoryId: any;
  parameterId: any;
  projectname: any;
}

interface IBOMCustomValidationLandingPageProps { }

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'white',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '0px solid rgba(0, 0, 0, .125)',
}));

export function BOMCustomValidationLandingPage() {  
  const { projectId, projectname, topVaultId, categoryId, parameterId,vehicleCategoryId, } = useRouteParams<Params>();
  useDocumentTitle( "Custom Validation Config" );
  const [ProductList, setProductList] = useState<any>([]);
  const [ProductLoader, setProductLoader] = useState<any>();
  const [CategoryList, setCotegoryList] = useState<any>([]);
  const [ParentCount, setParentCount] = useState<any>(0)
  const history = useHistory();
  const [expanded, setExpanded] = React.useState<string | false>(categoryId);
  const shareCustomValidation: any = useShareCustomValidationModal();
  const parameterAdded = useBaseModal();
  const [parameterListDataLoader, setParameterListDataLoader] = useState<boolean>(true);
  const [validationShowData, setValidationShowData] = useState<any>();
  const [parameterListData, setParameterListData] = useState<any>();
  const [apiCallValidationShowData, setApiCallValidationShowData] =
    useState<boolean>(false);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

 


  useEffect(() => {
    {
      setProductLoader(true);
      API.get(`bom/custom_validation_config/`, {
        
        mui_custom_validation: true,
        vehicle_category:vehicleCategoryId,
      })
        .then((res: any) => {
          setCotegoryList(res?.data);
          setProductLoader(false);
        })
        .catch((err: any) => {
          setProductLoader(false);
          console.log("Server Icon");
        });
    }
  }, [topVaultId,parameterAdded.isOpen, ParentCount]);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
      
      
      >
      <CostingConfigurationDrawer VehiclecategoryId={vehicleCategoryId}  projectname={projectname}/>
    </Box>
  );

  const ProductHandler = (id: any) => {
    history.push(
      `/customValidation/${projectId}/${projectname}/${id}/0/0`
    );
  };

  const handleChange =
  (item: any) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? item?.category : false);
    history.push(
      `/customValidation/${vehicleCategoryId}/${projectname}/${topVaultId}/${item?.category}/0/${item?.lock}`
    );
  };


  
  // const CategoryHandler = (item: any) => {
  //   history.push(
  //     `/customValidation/${vehicleCategoryId}/${projectname}/${topVaultId}/${item?.category}/0/${item?.lock}`
  //   );
  // };

  useEffect(() => {
    setParameterListDataLoader(true);
    API.get(
      `bom/custom_validation/`,
      { category: categoryId, vehicle_category: vehicleCategoryId },
      0
    ).then((res: any) => {
      setValidationShowData(res?.data);
      setParameterListDataLoader(false);
    });
  }, [categoryId,parameterAdded.isOpen, ParentCount]);

  const DataTypeHandler = (item: any) => {  
    history.push(
      `/customValidation/${vehicleCategoryId}/${projectname}/${topVaultId}/${categoryId}/${item.id}/${item.lock}`
    )
  };

  useEffect(() => {
    API.get(
      `bom/parameter_mapping/`,
      {
        module: "6",
        category: categoryId,
        vehicle_category: vehicleCategoryId,
      },
      0
    ).then((res: any) => {
      setParameterListData(res?.data);
    });
  }, [categoryId, parameterAdded.isOpen,]);


  const goBackHandler = () => {
    history.push(`/configurationmain/${vehicleCategoryId}/${projectname}`)
  };

  const handleShareCustomValidation = (e: any, productId: any, type: any, name: any) => {
    e.stopPropagation();
    shareCustomValidation.open(productId, type, name)
  }
  const LockHandlerAction = (item: any, type: any) => {
 
    if (type==false){
      API.patch(`/bom/custom_validation_config/`, {
        lock: type,
        id:item?.id
      }).then((res: any) => {
        history.push(`/customValidation/${vehicleCategoryId}/${projectname}/${topVaultId}/${categoryId}/${parameterId}/${type}`)
        setParentCount((prev: any) => prev + 1);
      }).catch((err: any) => {
        console.log("Server error")
      })
    }
    if (type==true){
      API.patch(`/bom/custom_validation_config/`, {
        lock: type,
        id:item?.id
      }).then((res: any) => {
        history.push(`/customValidation/${vehicleCategoryId}/${projectname}/${topVaultId}/${categoryId}/${parameterId}/${type}`)
        setParentCount((prev: any) => prev + 1);
      }).catch((err: any) => {
        console.log("Server error")
      })
    }



  }

  return (
    <div>
      {parameterAdded?.isOpen && (
        <BOMParameterAddModal
          isOpen={parameterAdded.isOpen}
          onCloseModal={parameterAdded.close}
          parameterListData={parameterListData}
          apiCallValidationShowData={apiCallValidationShowData}
          setApiCallValidationShowData={setApiCallValidationShowData}
          validationShowData={validationShowData}
        />
      )}

      {shareCustomValidation.isOpen &&
        <BOMCustomValidationShareModal
          isOpen={shareCustomValidation.isOpen}
          onCloseModal={shareCustomValidation.close}
          idShare={shareCustomValidation.id}
          type={shareCustomValidation.type}
          nameHeader={shareCustomValidation.nameHeader}
        />}
      <Box sx={{ margin: "0 0.5rem" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            position: 'relative',
            zIndex: 100
          }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "1rem",
              width: { lg: "19vw", xl: "17.5vw" },
            }}>
            <IconButton
              sx={{ cursor: "pointer" }}
              title="Go Back"
              onClick={() => goBackHandler()}>
              <ArrowBackIcon color="primary" titleAccess="Go Back" />
            </IconButton>
            <Typography
              sx={{
                fontSize: "1rem",
                whiteSpace: "nowrap",
              }}>
              {projectname} (BOM Custom Validation)
            </Typography>
          </Box>

        
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}>
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}>
                  <Box
                    sx={{ padding: "1rem 0rem", paddingBottom: "0" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3">
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}>Select Configuration </Typography>
                    <Divider sx={{ margin: "0.5rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  style={{
                    transform: "rotate(270deg)",
                    whiteSpace: "nowrap",
                    marginTop: "40vh",
                    cursor: "pointer",
                    fontSize: "1rem",
                  }}
                  onClick={toggleDrawer(anchor, true)}>Select Configuration</Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        <Box
          sx={{
            width: {lg:'96%',xl:"97%"},
            display: "flex",
            alignItems: "flex-start",
            // paddingRight: "1rem",
          }}>
          <Box>
            <Box
              sx={{
                height: { lg: "85vh", xl: "89vh" },
                width: { lg: "17vw", xl: "17vw" },
                padding:  { lg: "0", xl: "0.5rem"},
              }}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3"
            >

              {/* {ProductLoader ?
                <Box sx={{ width: '100%' }}>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="6rem"></Skeleton>
                </Box> : <>
                  {CategoryList &&
                    CategoryList?.map((item: any, index: any) => {
                      return (
                        <>
                          <Box
                            onClick={() => CategoryHandler(item)}
                            sx={{
                              boxShadow:
                                "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
                              borderRadius: "10px",
                              cursor: "pointer",
                              width: "100%",
                              // height: "7rem",
                              // marginBottom: "1rem",
                            }}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "start",
                                width: "100%",
                                height: "100%",
                                borderRadius: "10px",
                                // border: item?.is_validated
                                //   ? "2px solid #47fd47"
                                //   : "2px solid yellow",
                                backgroundColor:
                                  item?.category == categoryId
                                    ? "primary.light"
                                    : "white",
                              }}>
                              <Box sx={{
                                width: "100%", display: "flex",
                                flexDirection: "column",
                                margin: "0.5rem 0rem 0rem 0.5rem"
                              }}>
                                <Typography sx={{ fontSize: "1rem" }}>
                                  {item?.commodity_name}
                                </Typography>
                                {item?.is_leaf ? (
                                  <Typography sx={{ fontSize: "1rem" }}>
                                    Leaf
                                  </Typography>
                                ) : (
                                  <Typography sx={{ fontSize: "1rem" }}>
                                    Non Leaf
                                  </Typography>
                                )}
                                <Typography sx={{ fontSize: "1rem" }}>
                                  ({item?.parameter_count}) Parameters
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  width: "2rem",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-between",
                                  height: "5.5rem",
                                  alignItems: 'center',
                                  padding: '3px',
                                }}>
                                <img src={item?.is_validated ? validated : notvalidated} style={{ width: '1.5rem' }} />
                                {(item?.lock && item?.is_validated) ? (
                                  <IconButton
                                    sx={{
                                      padding: "0",
                                      cursor: "pointer",
                                      color: "green",
                                    }}
                                    onClick={() =>
                                      {
                                        swal({
                                          title: "Unlock?",
                                          text: "Are you sure you want to unlock?",
                                          buttons: ["Cancel", "Yes"],
                                  
                                          icon: "error",
                                        }).then((confirm) => {
                                          if (confirm) {
                                            LockHandlerAction(item, false)
                                          }
                                        });
                                       
                                      }
                                    }
                                  >
                                    <LockIcon sx={{color:"green"}} />
                                  </IconButton>
                                ) : (
                                 (item?.is_validated &&  <IconButton
                                    sx={{
                                      padding: "0",
                                      cursor: "pointer",
                                      color: "color:'red",
                                    }}
                                    onClick={() =>
                                      {
                                        swal({
                                          title: "Lock?",
                                          text: "Are you sure you want to Lock?",
                                          buttons: ["Cancel", "Yes"],
                                  
                                          icon: "error",
                                        }).then((confirm) => {
                                          if (confirm) {
                                            LockHandlerAction(item, true)
                                          }
                                        });
                                       
                                      }
                                    }
                                  >
                                    <LockOpenIcon sx={{color:'red'}} />
                                  </IconButton>)
                                )}
                                {item?.is_validated && <ShareIcon titleAccess="Share Category" sx={{ fontSize: '1.5rem', cursor: 'pointer' }} onClick={(e: any) => handleShareCustomValidation(e, item?.id, 'categoryLevel', item?.commodity_name)} />}
                              </Box>
                            </Box>
                          </Box>
                        </>
                      );
                    })}
                </>} */}

                {ProductLoader ? (
																																	<>
																																		<Skeleton />
																																		<Skeleton />
																																		<Skeleton />
																																		<Skeleton />
																																		<Skeleton />
																																		<Skeleton />
																																	</>
																																) : (CategoryList &&
																																			CategoryList?.map(
																																				(
																																					item: any,
																																					indx: any
																																				) => {
																																					return (
																																						<>
																																<Accordion sx={{border: 'none',}}
																																expanded={item?.category === categoryId}
																																onChange={handleChange(item)}>
																																		<AccordionSummary
																																		expandIcon={
																																			item?.category === categoryId ? (
																																				<img
																																					src={
																																						openFolder
																																					}
																																					style={{
																																						height:
																																							'1.2rem',
																																					}}
																																				/>
																																			) : (
																																				<FolderIcon
																																					sx={{
																																						fontSize:
																																							'1.5rem',
																																						color:
																																							'primary.main',
																																					}}
																																				/>
																																			)
																																		}
																																		sx={{
																																			minHeight:
																																				'24px',
																																			padding:
																																				'0.2rem 0.5rem',
                                                                        width:'100%'
																																		}}>
                                                                      <Box sx={{marginLeft:'0.3rem', width:'100%',display:'flex',	alignItems:'center',}}>
                                                                        <Typography title={item?.commodity_name} sx={{width:'100%',lineHeight:'1.1',color:'text.primary'}}>
                                                                        {item?.commodity_name} {item?.is_leaf ? '(Leaf)' : '(Non Leaf)'}
                                                                        </Typography>
                                                                        <Typography sx={{padding: "0 2px",color:'text.primary'}}>({item?.parameter_count})</Typography>
                                                                        <Box
                                                                          sx={{
                                                                            display: "flex",                                  
                                                                            justifyContent: "flex-end",
                                                                            columnGap: "0.5rem", 
                                                                            whiteSpace:'nowrap',
                                                                            alignItems: "center",
                                                                            padding: "0 3px",
                                                                          }}
                                                                        > 
                                                                          {(item?.lock && item?.is_validated) ? (
                                                                            <IconButton
                                                                              sx={{
                                                                                padding: "0",
                                                                                cursor: "pointer",
                                                                                color: "green",
                                                                              }}
                                                                              onClick={() =>
                                                                                {
                                                                                  swal({
                                                                                    title: "Unlock?",
                                                                                    text: "Are you sure you want to unlock?",
                                                                                    buttons: ["Cancel", "Yes"],
                                                                            
                                                                                    icon: "error",
                                                                                  }).then((confirm) => {
                                                                                    if (confirm) {
                                                                                      LockHandlerAction(item, false)
                                                                                    }
                                                                                  });
                                                                                
                                                                                }
                                                                              }
                                                                            >
                                                                              <LockIcon sx={{color:"green"}} />
                                                                            </IconButton>
                                                                          ) : (
                                                                          (item?.is_validated &&  <IconButton
                                                                              sx={{
                                                                                padding: "0",
                                                                                cursor: "pointer",
                                                                                color: "color:'red",
                                                                              }}
                                                                              onClick={() =>
                                                                                {
                                                                                  swal({
                                                                                    title: "Lock?",
                                                                                    text: "Are you sure you want to Lock?",
                                                                                    buttons: ["Cancel", "Yes"],
                                                                            
                                                                                    icon: "error",
                                                                                  }).then((confirm) => {
                                                                                    if (confirm) {
                                                                                      LockHandlerAction(item, true)
                                                                                    }
                                                                                  });
                                                                                
                                                                                }
                                                                              }
                                                                            >
                                                                              <LockOpenIcon sx={{color:'red'}} />
                                                                            </IconButton>)
                                                                          )}
                                                                          {item?.is_validated && <ShareIcon titleAccess="Share Category" sx={{ fontSize: '1.5rem', cursor: 'pointer' }} onClick={(e: any) => handleShareCustomValidation(e, item?.id, 'categoryLevel', item?.commodity_name)} />}
                                                                          <img src={item?.is_validated ? validated : notvalidated} style={{ width: '1.5rem' }} />
                                                                        </Box>
                                                                        </Box>
																																		</AccordionSummary>
																																		<AccordionDetails>																																			
																																		{parameterListDataLoader ? (
																																	<>
																																		<Skeleton />
																																		<Skeleton />
																																		<Skeleton />
																																		<Skeleton />
																																		<Skeleton />
																																		<Skeleton />
																																	</>
																																) : (
																																	 <Box
																																		sx={{
																																			marginLeft:
																																				'1.3rem',
																																			borderLeft:
																																				'1px solid',
																																			borderColor:
																																				'primary.main',
																																		}}>
																																		{validationShowData.length>0 ?
																																			validationShowData?.map(
																																				(
																																					itemParam: any,
																																					indx: any
																																				) => {
																																					return (
																																						<>
																																							<Typography
																																								sx={{
                                                                                  backgroundColor:
                                                                                  itemParam?.id === parameterId
                                                                                    ? "primary.main"
                                                                                    : "",
                                                                                    color:
                                                                                    itemParam?.id === parameterId
                                                                                      ? "white"
                                                                                      : "text.primary",
																																									// backgroundColor: (categoryName === item?.parameter__data_type) ? '#e6e6fa': '',
																																									display: 'flex',
																																									alignItems:'center',
																																									fontSize:'1rem',
																																									columnGap:'2px',
																																									cursor:'pointer',
																																									marginBottom:'3px',
																																									// color: (categoryName === item?.parameter__data_type)? 'green' : 'text.primary',
																																								}}
																																								onClick={(e: any) => {DataTypeHandler(itemParam)}}
																																								>
																																								{' '}
																																								<span style={{whiteSpace:'nowrap'}}>--</span>
																																								<span style={{lineHeight:'1.2',width:'85%',textTransform:'capitalize'}}>{
																																									itemParam?.key
																																								}</span>
																																							</Typography>
																																						</>
																																					);
																																				}
																																			): <Box sx={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',padding: '0.5rem'}}>
                                                                          <Button onClick={() => {parameterAdded.open()}}> Add Validation Parameter</Button>
                                                                      </Box>}
                                                                      
																																	</Box>
																																)}	
																																		</AccordionDetails>
																																</Accordion>
																																</>
																																					);
																																				}
																																			))}
            </Box>
          </Box>
          <Box sx={{ width: { lg: '79vw', xl: "82vw", },}}>        {/* marginTop: '-3rem' */}
          {categoryId == '0' && <Box sx={{width:'90%', height:'92vh',display:'flex',justifyContent:'center',alignItems:'center',fontSize:'2rem', fontWeight:'bold'}}>
              Please select Category
          </Box>}
            {categoryId == '0' ? <></> : CategoryList?.length > 0 && <BOMCustomValidation CategoryList={CategoryList && CategoryList} setParentCount={setParentCount} />}
          </Box>
        </Box>
      </Box>
    </div>
  );
}