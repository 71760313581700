import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { API } from "../api-services";
import { mockOrgTreeList } from "../datastrucures";
import BouncingLoader from "../ui-reusable-component/BouncingLoader";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Allmodulecomponents from "./Allmodulecomponents";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
// import Footer from "./Footer";

import RecursiveTree from "./recursive_tree";
import TreeViewComponents from "./TreeViewComponents";

import styles from "./ViewComponents.module.scss";

import { isNull, isUndefined } from "lodash";
import Skeleton from "@mui/material/Skeleton";
import DownloadReportAlertModal from "./DownloadReportAlertModal"
import LetSuspense from "../LetSuspense";
import { useBaseModal } from "../ComponentUI/SearchPage/useBaseModal";
import { AUTH } from "../Redux/Services/auth.service";
import Footer from "./Footer";
import { useDocumentTitle } from "../ui-reusable-component/useDocumentTitle";
type Props = {};

const ViewComponents = (props: Props) => {
  const [selectedData, setSelectedData] = useState<any>();
  const [loader, setLoading] = useState<any>(true);
  useDocumentTitle( "Data view" );
  const [AllProjectData, setAllProjectData] = useState<any>();
  const [HierarchyData, setHierarchyData] = useState<any>();
  const [hierarchyLoader, setHierchyLoader] = useState<any>(false);
  const [HierarchyActiveId, setHierarchyActiveId] = React.useState<any>(null);
  const [TopLevel, setTopLevel] = React.useState(null);
  const [abbr, setAbbr] = useState<any>();
  const [expendbom, setExpendBom] = useState<any>(false);
  const [treeview, setTreeview] = useState<any>(false);

  //Tab active Style
  const initialActiveIndex = sessionStorage.getItem('activeIndex') || 0;
  const [activeIndex, setActiveIndex] = useState(initialActiveIndex);

  //Tree Expand
  const [isActive, setIsActive] = useState(false);

  const refState = useRef<any>(null);
  const history = useHistory<any>();

  const { projectId, topVault, vault, treeabbr, leafNode } = useParams<any>();

  useEffect(() => {
    API.get("/cost/dashboard/", { get_product_list: projectId }).then(
      (res: any) => {
        setSelectedData(null);
        setAllProjectData(res?.data[0]);
      }
    );
  }, [projectId]);
  // obj=

  const toggleTopvault: any = () => {
    if (topVault !== 0) {
      if (treeabbr == "product") {
        return { top_vault: topVault };
      } else {
        return { vault: vault };
      }
    }
  };

  const ProjectHandler = (ProductID: any, type: any, key: any) => {
    setActiveIndex(key);
    setLoading(true);
    API.get(
      "xcpep/system_hirechy_fetch/",
      {
        top_vault: ProductID,
        // hierarchy: true,
      },
      0
    )
      .then((res: any) => {
        API.get("customer/view_data_activity/", {
          top_vault: ProductID
        },
          0).then((res: any) => {
          });
        setLoading(false);
        setHierarchyData(res.data.data);
        sessionStorage.removeItem("node");
        if (topVault == 0) {
          history.push(`/view/${projectId}/${res.data.data.id}/0/product/false`);
        } else {
          history.push(
            `/view/${projectId}/${res?.data?.data?.id}/0/${res?.data?.data?.abbreviation}/false`
          );

        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    API.get(
      "xcpep/system_hirechy_fetch/",
      {
        top_vault:
          topVault == 0
            ? AllProjectData && AllProjectData?.products[0]?.vehicle_id
            : topVault,
        // hierarchy: true,
      },
      0
    )
      .then((res: any) => {
        setHierarchyData(res?.data?.data);
        setLoading(false);

        // above one if don't work use uncomment below one

        // if (topVault == 0) {
        //   setHierarchyActiveId(res.data?.data?.id);
        //   history.push(`/view/${projectId}/${res.data?.data?.id}/0/product/false`);
        // } else {
        //   setHierarchyActiveId(topVault);
        //   history.push(
        //     `/view/${projectId}/${topVault}/${vault}/${treeabbr}/false`
        //   );
        // }
      })
      .catch((err: any) => {
        setLoading(false);
      });

  }, [topVault]);

  useEffect(() => {
    if (topVault != null) {
      let a: any = sessionStorage?.getItem("node");
      let val: any = a && JSON?.parse(a);
      if (val == null) {
        sessionStorage.setItem("node", JSON.stringify([Number(topVault)]));
      }
    }
  }, [topVault])

  const CloseView = () => {
    {
      AUTH.user_category == "Internal" ? history.push(`/internallanding`) :
        history.push(`/landing`);
    }
  };

  const onSelect = (value: any, abbreviation: any, propTopvault: any) => {
    // You can put whatever here
    setSelectedData(value);
    setAbbr(abbreviation);

    if (abbreviation == "product") {
      history.push(`/view/${projectId}/${topVault}/0/${abbreviation}/false`);
    } else {
      history.push(
        `/view/${projectId}/${topVault}/${value}/${abbreviation}/false`
      );
    }
  };

  useEffect(() => {
    // Store the active index in sessionStorage
    sessionStorage.setItem('activeIndex', activeIndex.toString());
  }, [activeIndex]);



  return (
    <div>

      <div className={styles.view_container}>


        <div className={styles.tab_mainContainer}>
          <Box className={styles.icon_project}>
            <Box sx={{ width: "20rem", display: "flex", alignItems: "center" }}>
              <IconButton
                sx={{ cursor: "pointer" }}
                onClick={() => CloseView()}
              >
                <ArrowBackIcon color="primary" titleAccess="Go Back" />
              </IconButton>
              <span className={styles.productLevel}>
                <span className={styles.prodtitle}>
                  {AllProjectData?.project_name}
                </span>
                <span className={styles.proddesc}>
                  {AllProjectData?.project_description}
                </span>
              </span>
            </Box>
            <div
              className={styles.tabBox_container}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3"
            >
              {AllProjectData &&
                AllProjectData?.products?.map(
                  (projectData: any, index: any) => {
                    return (
                      <>
                        <span key={projectData?.vehicle_id}
                          className={`${styles.Project_chip} ${activeIndex == projectData?.vehicle_id ? styles.active : ''}`}
                          // className={
                          //   topVault == projectData?.vehicle_id
                          //     ? styles.Project_chip_active
                          //     : styles.Project_chip
                          // }
                          onClick={() => {
                            ProjectHandler(projectData?.vehicle_id, "onclickHandler", projectData?.vehicle_id);
                          }}
                        >
                          <span style={{ lineHeight: "1.3" }}>
                            {projectData?.prod_id} - {projectData?.vehicle_name}
                          </span>
                          {/* <span style={{ lineHeight: '1.3', }}>{projectData?.prod_id}</span> */}
                        </span>
                      </>
                    );
                  }
                )}
            </div>
          </Box>

        </div>

        <div
          className={styles.recursive_container}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >

          <>
            <div
              className={styles.left_container}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3"
            >
              {!loader ? (
                <>
                  <TreeViewComponents
                    HierarchyData={HierarchyData && HierarchyData}
                    onSelectCallback={onSelect}
                  />
                </>
              ) : (
                <>
                  <Box sx={{ width: "100%", padding: '0 0.5rem' }}>
                    <Skeleton sx={{ height: "2rem", width: "30%" }} />
                    <Skeleton sx={{ height: "2rem", width: "45%" }} />
                    <Skeleton sx={{ height: "2rem", width: "50%" }} />
                    <Skeleton sx={{ height: "2rem", width: "65%" }} />
                    <Skeleton sx={{ height: "2rem", width: "70%" }} />
                    <Skeleton sx={{ height: "2rem", width: "60%" }} />
                    <Skeleton sx={{ height: "2rem", width: "55%" }} />
                    <Skeleton sx={{ height: "2rem", width: "30%" }} />
                    <Skeleton sx={{ height: "2rem", width: "45%" }} />
                    <Skeleton sx={{ height: "2rem", width: "50%" }} />
                    <Skeleton sx={{ height: "2rem", width: "35%" }} />
                    <Skeleton sx={{ height: "2rem", width: "70%" }} />
                    <Skeleton sx={{ height: "2rem", width: "55%" }} />
                    <Skeleton sx={{ height: "2rem", width: "80%" }} />
                    <Skeleton sx={{ height: "2rem", width: "30%" }} />
                    <Skeleton sx={{ height: "2rem", width: "45%" }} />
                    <Skeleton sx={{ height: "2rem", width: "50%" }} />
                    <Skeleton sx={{ height: "2rem", width: "65%" }} />
                    <Skeleton sx={{ height: "2rem", width: "70%" }} />
                    <Skeleton sx={{ height: "2rem", width: "60%" }} />
                    <Skeleton sx={{ height: "2rem", width: "55%" }} />
                    <Skeleton sx={{ height: "2rem", width: "30%" }} />
                    <Skeleton sx={{ height: "2rem", width: "45%" }} />
                    <Skeleton sx={{ height: "2rem", width: "50%" }} />
                    <Skeleton sx={{ height: "2rem", width: "35%" }} />
                    <Skeleton sx={{ height: "2rem", width: "70%" }} />
                    <Skeleton sx={{ height: "2rem", width: "55%" }} />
                    <Skeleton sx={{ height: "2rem", width: "80%" }} />
                  </Box>
                </>
              )}
            </div>
            <>
              <div className={styles.righ_container}>
                <Allmodulecomponents />
              </div>
            </>
          </>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default React.memo(ViewComponents);
