import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Typography,
  Paper,
  Skeleton,
  Tabs,
  Tab,
  Box,
  Button,
  Popover,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { styled } from "@mui/system";
import { API } from "../../api-services";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { LoadingButton } from "@mui/lab";
import { ADMIN } from "../../Redux/Services/admin.service";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import SubscriptionModalDetails from "./SubscriptionModalDetails";
import DownloadIcon from '@mui/icons-material/Download';

interface User {
  user_id: string;
  user_name: string;
  uuid: string;
}


const StyledOptionListItem = styled(ListItem)({
  cursor: "pointer",
  backgroundColor: "#a5d9d4",
  margin: "1px 0",
  "&:hover": {
    backgroundColor: "#a5d9d4",
  },
});

const SelectedOptionListItem = styled(ListItem)({
  cursor: "pointer",
  backgroundColor: "#00887a",
  color: "#ffffff",
  margin: "1px 0",
  "&:hover": {
    backgroundColor: "#00887a",
  },
});

const ActivitylogTree = (props: any) => {
  const { setOption } = props;
  const today = new Date().toISOString().split("T")[0];
  const openHistoryModal = useBaseParamsModal();
  const { activityType, userId, subscriptionId, filterType } =
    useRouteParams<any>();
  const history = useHistory();
  const [users, setUsers] = useState<User[]>([]);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [expandedUserId, setExpandedUserId] = useState<any | null>(null);
  const [anchorElDate, setAnchorElDate] = useState<null | HTMLElement>(null);
  const [startDateDate, setStartDateDate] = useState<string>("");
  const [endDateDate, setEndDateDate] = useState<string>("");
  const [loadingApply, setLoadingApply] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<{
    [key: string]: string;
  }>({});
  const [currentTab, setCurrentTab] = useState<any>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [subscriptionInfo, setSubscriptioninfo] = useState<any>([]);
  const [userList, setUserList] = useState<any>([]);
  const [userLoading, setUserLoading] = useState<boolean>(false);
  const [preLoginUserList, setPreLoginUserList] = useState<any>([]);
  const [userListCount, setUserListCount] = useState<any>({});

  const [subscriptionLoading, setSubscriptionLoading] =
    useState<boolean>(false);
  const [flag, setFlag] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [startDate, setStartDate] = useState<string>("");
  const [currentCity, setCurrentCity] = useState<any>("");
  const [endDate, setEndDate] = useState<string>("");
  const [allLocations, setAllLocations] = useState<any>([]);
  const [selectedOption, setSelectedOption] = useState<string>("Today");
  const handleCloseDate = () => {
    setAnchorElDate(null);
  };

  const handleApplyDate = () => {
    setLoadingApply(true);
    API.get(
      "/auth/export_activity_log/",
      { from: startDateDate, to: endDateDate },
      0
    )
      .then((res: any) => {
        window.open(res.data);
        setLoadingApply(false);
        handleCloseDate();
        // const url = window.URL.createObjectURL(new Blob([res.data]));
        // const link = document.createElement('a');
        // link.href = url;
        // link.setAttribute('download', 'activity_logs.csv');
        // document.body.appendChild(link);
        // link.click();
      })
      .catch(() => {
        setLoadingApply(false);
        ADMIN.toast.error("Error in downloading file");
      });
  };

  const getDateRange = (option: any) => {
    switch (option) {
      case "Today":
        return `${moment().startOf("day").format("DD-MM-YYYY")}`;
      case "Yesterday":
        return `${moment()
          .subtract(1, "day")
          .startOf("day")
          .format("DD-MM-YYYY")}`;
      case "7 days":
        return `${moment()
          .subtract(7, "days")
          .startOf("day")
          .format("DD-MM-YYYY")}
          - ${moment().endOf("day").format("DD-MM-YYYY")}`;
      case "30 days":
        return `${moment()
          .subtract(30, "days")
          .startOf("day")
          .format("DD-MM-YYYY")}
          - ${moment().endOf("day").format("DD-MM-YYYY")}`;

      default:
        return `${moment().startOf("day").format("DD-MM-YYYY")}
          - ${moment().endOf("day").format("DD-MM-YYYY")}`;
    }
  };

  React.useEffect(() => {
    setCurrentCity("");
    setExpanded(false);
  }, [activityType]);
  const getAllLocations = (option: any) => {
    API.get(
      "/auth/client_activity_log/",
      {
        user_list: "pre-login",
        datetime_filter: option,
        location_group: true,
      },
      0
    ).then((res: any) => {
      setAllLocations(res.data);
      if (res.data?.length > 0) {
        // setCurrentCity(res.data[0]?.cookies__city)
      }
    });
  };
  React.useEffect(() => {
    getAllLocations("Today");
  }, []);
  const getAllLocationsCustom = () => {
    API.get(
      "/auth/client_activity_log/",
      {
        user_list: "pre-login",
        from: startDate,
        to: endDate,
        location_group: true,
      },
      0
    ).then((res: any) => {
      setAllLocations(res.data);
      if (res.data?.length > 0) {
        // setCurrentCity(res.data[0]?.cookies__city)
      }
    });
  };
  const getPayload = () => {
    switch (selectedOption) {
      case "Today":
        return { datetime_filter: "Today" };
      case "Yesterday":
        return { datetime_filter: "Yesterday" };
      case "7 days":
        return { datetime_filter: "Last 7 days" };
      case "30 days":
        return { datetime_filter: "Last 30 days" };
      case "Custom":
        return { form: startDate, to: endDate };
      default:
        return { datetime_filter: "Today" };
    }
  };
  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    option: string
  ) => {
    setSelectedOption(option);
    if (option === "Custom") {
      setAnchorEl(event.currentTarget);
    } else {
      getAllLocations(option);
      // Handle other options here (e.g., fetch or filter data based on selected option)
      setAnchorEl(null);
    }
  };
  const fetchUsers = async (): Promise<User[]> => {
    const response = await API.get(
      "/auth/client_activity_log/",
      { user_list: true, ...getPayload() },
      0
    );
    if (response.data) {
      setFlag(false);
    }
    return response.data;
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApply = () => {
    setFlag(true);
    getAllLocationsCustom();
    getUsersListLocationwise();
    handleClose();
  };

  React.useEffect(() => {
    setExpandedUserId(null);
  }, [activityType]);


  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 0) {
      history.push(`/activitylogs/${"buyers"}/${0}/${0}/${filterType}`);
    } else if (newValue === 1) {
      history.push(`/activitylogs/${"sellers"}/${0}/${0}/${filterType}`);
    } else if (newValue === 2) {
      history.push(`/activitylogs/${"preloggedin"}/${0}/${0}/${filterType}`);
    } else {
      history.push(`/activitylogs/${"buyers"}/${0}/${0}/${filterType}`);
    }
    setCurrentTab(newValue);
  };

  const getValueFromRoute = (activityType: string) => {
    switch (activityType) {
      case "buyers":
        return 0;
      case "sellers":
        return 1;
      case "preloggedin":
        return 2;
      default:
        return 0;
    }
  };

  const getOption = (option: any) => {
    switch (option) {
      case "Today":
        return "Today";
      case "Yesterday":
        return "Yesterday";
      case "7 days":
        return "7D";
      case "30 days":
        return "30D";
      case "Custom":
        return "Custom";
      default:
        return "Today";
    }
  };

  const getPayloadVal = (activityType: string) => {
    switch (activityType) {
      case "buyers":
        return "buyer";
      case "sellers":
        return "seller";
      case "preloggedin":
        return "pre-login";
      default:
        return "buyers";
    }
  };

  const getUsersList = () => {
    setUserLoading(true);
    setUserList([]);
    API.get(
      "/auth/client_activity_log/",
      { user_list: getPayloadVal(activityType) },
      0
    )
      .then((res: any) => {
        setUserList(res.data);
        setUserLoading(false);
      })
      .catch(() => {
        setUserLoading(false);
      });
  };

  const getUsersListLocationwise = () => {
    API.get(
      "/auth/client_activity_log/",
      {
        user_list: getPayloadVal(activityType),
        city: currentCity?.cookies__city,
        region: currentCity?.cookies__region,
        country_name: currentCity?.cookies__country_name,
        ...getPayload(),
      },
      0
    )
      .then((res: any) => {
        setPreLoginUserList(res.data);
      })
      .catch(() => {});
  };

  const getUserListTypesCount = () => {
    API.get("/auth/client_activity_log/", { user_summary: true }, 0)
      .then((res: any) => {
        setUserListCount(res.data);
      })
      .catch(() => {});
  };

  // const getSubScriptionInfo = (userIdd: any) => {
  //   setSubscriptionLoading(true);
  //   API.get("/auth/client_activity_log/", { user_subscription: userIdd }, 0)
  //     .then((res: any) => {
  //       setSubscriptioninfo(res.data);
  //       setSubscriptionLoading(false);
  //     })
  //     .catch((err: any) => {
  //       setSubscriptionLoading(false);
  //     });
  // };

  React.useEffect(() => {
    getUsersList();
    getUserListTypesCount();
  }, [activityType]);

  React.useEffect(() => {
    if (currentCity && expanded) {
      getUsersListLocationwise();
    }
  }, [currentCity]);

  const handleChange =
    (panel: string, city: any, data: any) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      setCurrentCity(data);
    };

  useEffect(() => {
    const loadUsers = async () => {
      if (selectedOption !== "Custom") {
        setLoading(true);
        const users = await fetchUsers();
        setUsers(users);
        setLoading(false);
      }
      if (flag === true) {
        setLoading(true);
        const users = await fetchUsers();
        setUsers(users);
        setLoading(false);
      }
    };

    loadUsers();
  }, [selectedOption, flag]);


  const handleUserClick = (userIdd: any) => {
    // getSubScriptionInfo(userIdd);
    history.push(`/activitylogs/${activityType}/${userIdd}/${0}/${filterType}`);
    setExpandedUserId((prevUserId: any) =>
      prevUserId === userIdd ? null : userIdd
    );
  };

  const handleOptionClick = (userIdd: any, option: any) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [userIdd]: option,
    }));
    sessionStorage.removeItem("activityMapped");
    history.push(
      `/activitylogs/${activityType}/${userIdd}/${userIdd}/${filterType}`
    );
    setOption(option);
  };

  const getCount = (option: any, userListCount: any) => {
    switch (option) {
      case "Today":
        return userListCount["Pre-Login"]?.["Today"] || 0;
      case "Yesterday":
        return userListCount["Pre-Login"]?.["Yesterday"] || 0;
      case "7 days":
        return userListCount["Pre-Login"]?.["7 days"] || 0;
      case "30 days":
        return userListCount["Pre-Login"]?.["30 days"] || 0;
      default:
        return 0;
    }
  };

  return (
    <Paper
      style={{
        // padding: "16px",
        margin: "16px",
        height: "85vh",
        overflowY: "auto",
        width: "100%",
        backgroundColor: "#d3e3fc45",
      }}
    >
      <Tabs
        value={getValueFromRoute(activityType)}
        onChange={handleTabChange}
        aria-label="tabs"
        sx={{
          width: "100%",
          ".MuiButtonBase-root .MuiTab-root ": {
            minWidth: "33% !important",
            maxWidth: "33% !important",
          },
        }}
      >
        <Tab
          sx={{
            ".MuiButtonBase-root .MuiTab-root ": {
              minWidth: "33% !important",
              maxWidth: "33% !important",
            },
          }}
          label={`Buyers (${
            userListCount?.Buyer >= 0 ? userListCount?.Buyer : "-"
          })`}
        />
        <Tab
          sx={{
            ".MuiButtonBase-root .MuiTab-root": {
              minWidth: "33% !important",
              maxWidth: "33% !important",
            },
          }}
          label={`Manufacturers (${
            userListCount?.Seller >= 0 ? userListCount?.Seller : "-"
          })`}
        />
        <Tab
          sx={{
            ".MuiButtonBase-root .MuiTab-root": {
              minWidth: "33% !important",
              maxWidth: "33% !important",
            },
          }}
          label={`Pre log in (${
            userListCount["Pre-Login"]?.Total >= 0
              ? userListCount["Pre-Login"]?.Total
              : "-"
          })`}
        />
        <LoadingButton
          // variant="contained"
          // sx={{ marginLeft: "1rem" }}
          onClick={(event: any) => setAnchorElDate(event.currentTarget)}
        >
          <DownloadIcon/>
        </LoadingButton>

        <Popover
          open={Boolean(anchorElDate)}
          anchorEl={anchorElDate}
          onClose={handleCloseDate}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box p={2} className="date-range-picker">
            <Typography
              textAlign="center"
              variant="subtitle2"
              sx={{ marginBottom: "1rem", color: "primary.main" }}
            >
              Select Date Range
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              className="date-range-form"
            >
              <TextField
                label="Start Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={startDateDate}
                onChange={(e) => setStartDateDate(e.target.value)}
                className="date-input"
                inputProps={{ max: today }}
              />
              <TextField
                label="End Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={endDateDate}
                onChange={(e) => setEndDateDate(e.target.value)}
                className="date-input"
                inputProps={{ max: today }}
              />
              <LoadingButton
                onClick={handleApplyDate}
                loading={loadingApply}
                variant="contained"
                className="apply-button"
              >
                Apply
              </LoadingButton>
            </Box>
          </Box>
        </Popover>
      </Tabs>
      <List>
        {activityType === "preloggedin" ? (
          <Box>
            <Box
              display="flex"
              justifyContent="space-between"
              p={2}
              className="date-options"
              sx={{ width: "100%" }}
            >
              {["Today", "Yesterday", "7 days", "30 days", "Custom"].map(
                (option) => (
                  <Button
                    key={option}
                    onClick={(event) => handleClick(event, option)}
                    variant={
                      selectedOption === option ? "contained" : "outlined"
                    }
                    className="date-option-button"
                  >
                    {getOption(option)}
                    <br />
                    {option !== "Custom" &&
                      `( ${getCount(option, userListCount)} )`}
                  </Button>
                )
              )}

              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Box p={2} className="date-range-picker">
                  <Typography
                    textAlign="center"
                    variant="subtitle2"
                    sx={{ marginBottom: "1rem", color: "primary.main" }}
                  >
                    Select Date Range
                  </Typography>
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    className="date-range-form"
                  >
                    <TextField
                      label="Start Date"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      className="date-input"
                      inputProps={{ max: today }}
                    />
                    <TextField
                      label="End Date"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      className="date-input"
                      inputProps={{ max: today }}
                    />
                    <Button
                      onClick={handleApply}
                      variant="contained"
                      className="apply-button"
                    >
                      Apply
                    </Button>
                  </Box>
                </Box>
              </Popover>
            </Box>
            <Typography
              variant="subtitle2"
              textAlign="left"
              sx={{ color: "primary.main", marginLeft: "1rem" }}
            >
              {selectedOption === "Custom"
                ? `${startDate || "--/--"} - ${endDate || "--/--"}`
                : getDateRange(selectedOption)}
            </Typography>
          </Box>
        ) : (
          <Box></Box>
        )}
        {activityType === "preloggedin" &&
          allLocations &&
          allLocations?.map((item: any, index: any) => {
            return (
              <Accordion
                key={index}
                expanded={expanded === `panel${index}`}
                onChange={handleChange(
                  `panel${index}`,
                  item?.cookies__city,
                  item
                )}
                sx={{ backgroundColor: "#a3c9ef" }}
              >
                <AccordionSummary
                  // expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <Typography>
                    {`${item.cookies__city} `},{" "}
                    {`${item?.cookies__region}, ${item?.cookies__country_name}`}{" "}
                    ({item?.uuid_count})
                    {/* {`${item.cookies__city} `},{" "}
                    {`${item?.cookies__country_name}`}{" "}
                    ({item?.uuid_count}) */}
                    {/* ${item?.region},${item?.country_name} */}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "0.2rem 0.2rem" }}>
                  {Array.isArray(preLoginUserList) &&
                    preLoginUserList?.map((user: any, index: number) => {
                      return subscriptionId == user?.uuid ? (
                        <SelectedOptionListItem
                          onClick={() => handleOptionClick(user?.uuid, user)}
                        >
                          <ListItemText
                            primary={
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <Box  sx={{
                                  display: "flex",
                                  width:'100%',
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}>
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    color: "#ffffff",
                                  }}
                                >
                                  <span title={user?.cookies__uuid}>
                                    {`User ${index + 1}, Date: ${
                                      user?.last_used_at
                                    }`}{" "}
                                    ({`${user?.total_logs_count}`})
                                  </span>
                                </Typography>
                                <InfoIcon
                                  sx={{ cursor: "pointer" }}
                                  titleAccess={`User Created on: ${
                                    user?.formattedAddress_user || ""
                                  } ${user?.uuid_generated_at} , using the ${user?.browser || "Chrome"} `}
                                />
                                </Box>
                                <Box sx={{width:'100%'}}>
                                <Typography
                                  style={{
                                    color: "#ffffff",
                                  }}
                                >
                                 <span title={user?.cookies__uuid}>
                                  OS: {user?.os || "NA"}, Resolution: {user?.screen_resolution || "NA"} {user?.device && `( ${user?.device} )`}
                                </span>
                                </Typography>
                                </Box>
                               
                              </Box>
                            }
                          />
                        </SelectedOptionListItem>
                      ) : (
                        <StyledOptionListItem
                          onClick={() => handleOptionClick(user?.uuid, user)}
                        >
                          <ListItemText
                            primary={
                              <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <Box  sx={{
                                display: "flex",
                                width:'100%',
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}>
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  color: "#00887A",
                                }}
                              >
                                <span title={user?.cookies__uuid}>
                                  {`User ${index + 1}, Date: ${
                                    user?.last_used_at
                                  }`}{" "}
                                  ({`${user?.total_logs_count}`})
                                </span>
                              </Typography>
                              <InfoIcon
                                sx={{ cursor: "pointer" }}
                                titleAccess={`User Created on: ${
                                  user?.formattedAddress_user || ""
                                } ${user?.uuid_generated_at} , using the ${user?.browser || "Chrome"} `}
                              />
                              </Box>
                              <Box sx={{width:'100%'}}>
                              <Typography
                                style={{
                                  // fontWeight: "bold",
                                  color: "black",
                                }}
                              >
                                <span title={user?.cookies__uuid}>
                                  OS: {user?.os || "NA"}, Resolution: {user?.screen_resolution || "NA"} {user?.device && `( ${user?.device} )`}
                                </span>
                              </Typography>
                              </Box>
                             
                            </Box>
                            }
                          />
                        </StyledOptionListItem>
                      );
                    })}
                </AccordionDetails>
              </Accordion>
            );
          })}
        {loading
          ? Array.from({ length: 5 }).map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                width="100%"
                height={48}
                style={{ margin: "8px 0" }}
              />
            ))
          : activityType !== "preloggedin" &&
            (!userLoading
              ? Array.isArray(userList) &&
                userList?.map((user: any) =>
                  Object.keys(user)?.includes("cookies__uuid") ? (
                    <Box></Box>
                  ) : (
                    <React.Fragment key={user?.id}>
                      {/* <StyledUserListItemButton
                    onClick={() => handleUserClick(user?.id)}
                  > */}
                      {userId == user?.id ? (
                        <SelectedOptionListItem
                          onClick={() => handleUserClick(user?.id)}
                        >
                          <ListItemText
                            primary={
                              <Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    // color: "#ffffff",
                                  }}
                                >
                                  {user.first_name} (
                                  {user?.current_subscription})
                                </Typography>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontWeight: "bold",
                                      color: "#ffffff",
                                    }}
                                  >
                                    ({user?.total_logs_count || 0})
                                  </Typography>
                                  <InfoIcon
                                    onClick={(event: any) => {
                                      event.stopPropagation();
                                      openHistoryModal.open(user?.id);
                                    }}
                                  />
                                </Box>
                              </Box>
                              <Box sx={{width:'100%'}}>
                                <Typography
                                  style={{
                                    color: "#ffffff",
                                  }}
                                >
                                 {user?.user_type === "Buyer"?
                                 <span title={user?.my_business || ""}>
                                 Company name: {user?.company_name || "No company name available"}
                               </span>
                                 :<span title={user?.my_business || ""}>
                                  Claimed Business: {user?.my_business || "No Business Claimed"}
                                </span>}
                                </Typography>
                                </Box>
                              <Box sx={{width:'100%'}}>
                                <Typography
                                  style={{
                                    color: "#ffffff",
                                  }}
                                >
                                 <span title={user?.email || ""}>
                                  Email: {user?.email || "NA"}
                                </span>
                                </Typography>
                                </Box>
                              <Box sx={{width:'100%'}}>
                                <Typography
                                  style={{
                                    color: "#ffffff",
                                  }}
                                >
                                 <span title={user?.cookies__uuid}>
                                  OS: {user?.os || "NA"}, Resolution: {user?.screen_resolution || "NA"} {user?.device && `( ${user?.device} )`}
                                </span>
                                </Typography>
                                </Box>
                              </Box>
                            }
                          />
                        </SelectedOptionListItem>
                      ) : (
                        <StyledOptionListItem
                          onClick={() => handleUserClick(user?.id)}
                        >
                          <ListItemText
                            primary={
                              <Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    color:'#00887A'
                                    // color: "#ffffff",
                                  }}
                                >
                                  {user.first_name} (
                                  {user?.current_subscription}) {" "}
                                  
                                </Typography>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontWeight: "bold",
                                      // color: "#ffffff",
                                    }}
                                  >
                                    ({user?.total_logs_count || 0})
                                  </Typography>
                                  <InfoIcon
                                    onClick={(event: any) => {
                                      event.stopPropagation();
                                      openHistoryModal.open(user?.id);
                                    }}
                                  />
                                </Box>
                              </Box>
                              <Box sx={{width:'100%'}}>
                                <Typography
                                  style={{
                                    color: "black",
                                  }}
                                >
                                 {user?.user_type === "Buyer"?
                                 <span title={user?.my_business || ""}>
                                 Company name: {user?.company_name || "No company name available"}
                               </span>
                                 :<span title={user?.my_business || ""}>
                                  Claimed Business: {user?.my_business || "No Business Claimed"}
                                </span>}
                                </Typography>
                                </Box>
                              <Box sx={{width:'100%'}}>
                                <Typography
                                  style={{
                                    color: "black",
                                  }}
                                >
                                 <span title={user?.email || ""}>
                                  Email: {user?.email || "NA"}
                                </span>
                                </Typography>
                                </Box>
                              <Box sx={{width:'100%'}}>
                                <Typography
                                  style={{
                                    color: "black",
                                  }}
                                >
                                 <span title={user?.cookies__uuid}>
                                  OS: {user?.os || "NA"}, Resolution: {user?.screen_resolution || "NA"} {user?.device && `( ${user?.device} )`}
                                </span>
                                </Typography>
                                </Box>
                                
                              </Box>
                            }
                          />
                        </StyledOptionListItem>
                      )}
                      {/* {expandedUserId === user?.id ? (
                      <ArrowForwardIosIcon
                        style={{ color: "#ffffff", transform: "rotate(90deg)" }}
                      />
                    ) : (
                      <ArrowForwardIosIcon style={{ color: "#ffffff" }} />
                    )} */}
                      {/* </StyledUserListItemButton> */}
                    </React.Fragment>
                  )
                )
              : Array.from({ length: 5 }).map((_, index) => (
                  <Skeleton
                    key={index}
                    variant="rectangular"
                    width="100%"
                    height={48}
                    style={{ margin: "8px 0" }}
                  />
                )))}
      </List>
      {openHistoryModal.isOpen && (
        <SubscriptionModalDetails
          Open={openHistoryModal.isOpen}
          Close={openHistoryModal.close}
          userId={openHistoryModal.propsId}
        />
      )}
    </Paper>
  );
};

export default ActivitylogTree;
