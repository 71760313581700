import * as React from 'react';
import { Box, Checkbox, IconButton, Typography } from '@mui/material';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import warningImg from '../../Assets/images/Warning.png';

import { useEffect } from 'react';
import { API } from '../../api-services';



interface ICostingConfigurationDrawerProps {}

interface Params {
	type: string;
}


function UserManagementDrawer(props: ICostingConfigurationDrawerProps) {
	const history = useHistory();
	const { url } = useRouteMatch();
	const { type } = useParams<Params>();
	const [selectBilling, setSelectBilling] = React.useState<any>();
	const [BillingData, setBillingData] = React.useState<any>([]);
	const [billingLoader, setBillingLoader] = React.useState<boolean>(false);
	const [billingType, setBillingType] = React.useState<string>('');
	// const [type, setType] = React.useState('subscription');
// 	const [surl, setUrl] = React.useState(""); // Assuming url state management (replace with your logic)
//   const [isChecked, setIsChecked] = React.useState(false); // State to manage checkbox selection

//   const billingHandler = () => {
//     setIsChecked(!isChecked); // Toggle checkbox state on click
//     setUrl(type); // Update url state (replace with your logic)
//     // Code for additional actions upon click (optional)
//   };
	
	const [state, setState] = React.useState({
		top: false,
		left: false,
		bottom: false,
		right: false,
	});

	// const billingHandler = (selectedOption: any) => {
	// 	setSelectBilling(selectedOption);
	// 	// history.push(`/user-management/billing-info/0/`);
	// 	console.log(selectBilling);
	// }
	// const subbillingHandler = (type: any) => {
    //     let billingType = type;
    //     setBillingLoader(true);
    //     API.get('payment/get_all_billing_detail/', {
    //         billing_type: billingType,
    //     }, 0)
    //         .then((res: any) => {
    //             setBillingData(res.data);
    //             history.push(`/user-management/billing-info/${billingType}`);
    //             setBillingLoader(false);
    //         })
    //         .catch((err: any) => {
    //             console.log(err);
    //         });
    // };

//    React.useEffect(() => {
// 		subbillingHandler(type);
//     }, [type]);

	const subbillingHandler = (type: any) => {
        let billingType = type;        
        history.push(`/user-management/billing-info/${billingType}`);            
    };

	return (
		<div>
			<Box
				sx={{ padding: '0 0.5rem' }}
				// onClick={toggleDrawer(anchor, false)}
				// onKeyDown={toggleDrawer(anchor, false)}
			>
				<Box sx={{ padding: '0 0.5rem',width:'20rem' }}>
					<Box sx={{ alignItems: 'center', display: 'flex' }}>
						<Checkbox
							style={{ padding: '3px' }}
							checked={url.includes('search-config')}
							onClick={() => history.push(`/user-management/search-config`)}
							inputProps={{ 'aria-label': 'controlled' }}
							sx={{
								'& .MuiSvgIcon-root': { fontSize: { lg: '24', xl: '30' } },
							}}
						/>
						<Box
							// key={index}
							onClick={() => history.push(`/user-management/search-config`)}
							sx={{
								padding: '0.2rem',
								margin: '0.5rem,0rem',
								backgroundColor: url.includes('search-config')
									? 'primary.main'
									: 'primary.light',
								color: url.includes('search-config') ? 'white' : 'primary.main',
								textAlign: 'left',
								cursor: 'pointer',
								display: 'inline-block',
								width: '100%',
							}}>
							<Typography
								style={{ fontSize: '0.87rem', textTransform: 'capitalize' }}>
								Search Configuration
							</Typography>
						</Box>
					</Box>
					
					<Box sx={{ alignItems: 'center', display: 'flex' }}>
						<Checkbox
							style={{ padding: '3px' }}
							checked={ url.includes('user-list')}
							onClick={() => history.push(`/user-management/user-list/all/0`)}
							inputProps={{ 'aria-label': 'controlled' }}
							sx={{
								'& .MuiSvgIcon-root': { fontSize: { lg: '24', xl: '30' } },
							}}
						/>
						<Box
							// key={index}
							onClick={() => history.push(`/user-management/user-list/all/0`)}
							sx={{
								padding: '0.2rem',
								margin: '0.5rem,0rem',
								backgroundColor: url.includes('user-list')
									? 'primary.main'
									: 'primary.light',
								color: url.includes('user-list')
									? 'white'
									: 'primary.main',
								textAlign: 'left',
								cursor: 'pointer',
								display: 'inline-block',
								width: '100%',
							}}>
							<Typography
								style={{ fontSize: '0.87rem', textTransform: 'capitalize' }}>
								User List
							</Typography>
						</Box>
					</Box>					

					<Box sx={{width:'100%',marginTop:'1rem' }}>
						<Typography sx={{fontSize:'1.5rem',fontWeight:'600'}}>Billing Info</Typography>
						{/* <Checkbox
							style={{ padding: '3px' }}
							checked={url.includes('billing-info')} // Set checked based on the state
							// onClick={billingHandler}
							inputProps={{ 'aria-label': 'controlled' }}
							sx={{
							'& .MuiSvgIcon-root': { fontSize: { lg: '24', xl: '30' } },
							cursor: 'not-allowed',
							}}
						/>
						<Box
								// onClick={billingHandler}
								// onClick={() => billingHandler('billing-info')}								
								sx={{
									padding: '0.2rem',
									margin: '0.5rem,0rem',
									backgroundColor:url.includes('billing-info')
										? 'primary.main'
										: 'primary.light',
									color:url.includes('billing-info')
										? 'white'
										: 'primary.main',
									textAlign: 'left',
									cursor: 'not-allowed',
									display: 'inline-block',
									width: '100%',
								}}>
								<Typography
									style={{ fontSize: '0.87rem', textTransform: 'capitalize' }}>
									Billing
								</Typography>
							</Box> */}
						<Box sx={{width:'100%',marginTop:'2px'}}>
							
							{/* {isChecked || url.includes('billing-info') && */}
							 <Box sx={{marginTop:'2px'}}>                
								<Box sx={{ alignItems: 'center', display: 'flex' }}>
									<Checkbox
										style={{ padding: '3px' }}
										checked={url.includes('subscription')}
										onClick={() => subbillingHandler('subscription')}
										inputProps={{ 'aria-label': 'controlled' }}
										sx={{
											'& .MuiSvgIcon-root': { fontSize: { lg: '24', xl: '30' } },
										}}
									/>
									<Box onClick={() => subbillingHandler('subscription')}
										sx={{
											padding: '0.2rem',
											margin: '0.5rem,0rem',
											backgroundColor: url.includes('subscription')
												? 'primary.main'
												: 'primary.light',
											color: url.includes('subscription')
												? 'white'
												: 'primary.main',
											textAlign: 'left',
											cursor: 'pointer',
											display: 'inline-block',
											width: '100%',
										}}>
										<Typography
											style={{ fontSize: '0.87rem', textTransform: 'capitalize' }}>
											Subscription
										</Typography>
									</Box>
								</Box>
								<Box sx={{ alignItems: 'center', display: 'flex' }}>
									<Checkbox
										style={{ padding: '3px' }}
										checked={url.includes('zbc_request')}
										onClick={() => subbillingHandler('zbc_request')}
										inputProps={{ 'aria-label': 'controlled' }}
										sx={{
											'& .MuiSvgIcon-root': { fontSize: { lg: '24', xl: '30' } },
										}}
									/>
									<Box onClick={() => subbillingHandler('zbc_request')}
										sx={{
											padding: '0.2rem',
											margin: '0.5rem,0rem',
											backgroundColor: url.includes('zbc_request')
												? 'primary.main'
												: 'primary.light',
											color: url.includes('zbc_request')
												? 'white'
												: 'primary.main',
											textAlign: 'left',
											cursor: 'pointer',
											display: 'inline-block',
											width: '100%',
										}}>
										<Typography
											style={{ fontSize: '0.87rem', textTransform: 'capitalize' }}>
											ZBC Request
										</Typography>
									</Box>
								</Box>
								<Box sx={{ alignItems: 'center', display: 'flex' }}>
									<Checkbox
										style={{ padding: '3px' }}
										checked={url.includes('scenario_request')}
										onClick={() => subbillingHandler('scenario_request')}
										inputProps={{ 'aria-label': 'controlled' }}
										sx={{
											'& .MuiSvgIcon-root': { fontSize: { lg: '24', xl: '30' } },
										}}
									/>
									<Box onClick={() => subbillingHandler('scenario_request')}
										sx={{
											padding: '0.2rem',
											margin: '0.5rem,0rem',
											backgroundColor: url.includes('scenario_request')
												? 'primary.main'
												: 'primary.light',
											color: url.includes('scenario_request')
												? 'white'
												: 'primary.main',
											textAlign: 'left',
											cursor: 'pointer',
											display: 'inline-block',
											width: '100%',
										}}>
										<Typography
											style={{ fontSize: '0.87rem', textTransform: 'capitalize' }}>
											Scenario Costing
										</Typography>
									</Box>
								</Box>
								<Box sx={{ alignItems: 'center', display: 'flex' }}>
									<Checkbox
										style={{ padding: '3px' }}
										checked={url.includes('add_part')}
										onClick={() => subbillingHandler('add_part')}
										inputProps={{ 'aria-label': 'controlled' }}
										sx={{
											'& .MuiSvgIcon-root': { fontSize: { lg: '24', xl: '30' } },
										}}
									/>
									<Box onClick={() => subbillingHandler('add_part')}
										sx={{
											padding: '0.2rem',
											margin: '0.5rem,0rem',
											backgroundColor: url.includes('add_part')
												? 'primary.main'
												: 'primary.light',
											color: url.includes('add_part')
												? 'white'
												: 'primary.main',
											textAlign: 'left',
											cursor: 'pointer',
											display: 'inline-block',
											width: '100%',
										}}>
										<Typography
											style={{ fontSize: '0.87rem', textTransform: 'capitalize' }}>
											Add Part
										</Typography>
									</Box>
								</Box>                  
							</Box>
							{/* } */}
						</Box>
					</Box>
				</Box>
			</Box>
		</div>
	);
}
export default UserManagementDrawer;
