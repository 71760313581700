import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import styles from "../AddRevisionModal.module.scss";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { API } from "../../../api-services";

export interface IIgnitionModulesFormProps {
  actions?: any;
  onCloseModal?: any;
  getMaterialData: any;
  subCategory: any;
}
const validationSchema = yup.object({
  name: yup.string().required("Please type Item name"),
  type: yup.string().required("Please select type"),
  sensing_technology: yup.string().required("Please select Sensing Technology"),
  type_of_key_fob: yup.mixed().required("Please select Type of Key Fob"),
  no_of_function: yup.mixed().required("Please type No. of Functions"),
  function: yup.mixed().required("Please enter Function"),
  range: yup.mixed().required("Please enter Range"),
  reference: yup.string(),
  unit_cost: yup.mixed().required("Please enter per unit cost"),
});
const useStyles = makeStyles((theme) => ({
  select: {
    "&.MuiInputBase-root-MuiInput-root-MuiSelect-root": {
      borderBottom: "1px solid red",
    },
  },
}));
export function RemoteKeyModulesForm(props: IIgnitionModulesFormProps) {
  const { actions, onCloseModal, getMaterialData, subCategory } = props;
  const [isLoadingsave, setIsLoadingSave] = React.useState<any>(false);
  const { categoryName, databaseName, revisionId } = useRouteParams<any>();
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      name: "",
      type: "",
      sensing_technology: "",
      type_of_key_fob: "",
      no_of_function: "",
      function: "",
      range: "",
      reference: "",
      unit_cost: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsLoadingSave(true);
      if (actions === "add") {
        API.post(
          "/db/bought_out/",
          {
            ...values,
            ...{
              organisation: 1,
              commodity_category_instance: subCategory,
              module_type: "Remote Key/Lock",
            },
          },
          {},
          0
        )
          .then((res: any) => {
            if (!databaseName?.includes("DB")) {
              API.post(
                "/db/bought_out_cost/",
                {
                  purchase_items: [res.data.id],
                  revision: revisionId,
                },
                {},
                0
              )
                .then((res: any) => {
                  setIsLoadingSave(false);
                  getMaterialData("Remote Key/Lock");
                  onCloseModal();
                })
                .catch((err: any) => {
                  setIsLoadingSave(false);
                });
            } else {
              setIsLoadingSave(false);
              getMaterialData();
              onCloseModal();
            }
          })
          .catch((err: any) => {
            setIsLoadingSave(false);
          });
      } else {
        API.put(
          `/db/bought_out/${actions?.id}/`,
          {
            ...values,
            ...{
              organisation: 1,
              commodity_category_instance: actions?.commodity_name,
              module_type: actions?.module_type,
            },
          },
          {},
          0
        )
          .then((res: any) => {
            onCloseModal();
            setIsLoadingSave(false);
            getMaterialData();
          })
          .catch((err: any) => {
            setIsLoadingSave(false);
          });
      }
    },
  });
  React.useEffect(() => {
    if (actions !== "add") {
      formik.setFieldValue("name", actions?.name || "");
      formik.setFieldValue("type", actions?.type || "");
      formik.setFieldValue(
        "sensing_technology",
        actions?.sensing_technology || ""
      );
      formik.setFieldValue("type_of_key_fob", actions?.type_of_key_fob || "");
      formik.setFieldValue("no_of_function", actions?.no_of_function || "");
      formik.setFieldValue("function", actions?.function || "");
      formik.setFieldValue("range", actions?.range || "");
      formik.setFieldValue("reference", actions?.reference || "");
      formik.setFieldValue(
        "unit_cost",
        parseFloat(actions.unit_cost?.slice(1)) || ""
      );
    }
  }, [actions]);
  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            columnGap: "1rem",
            marginBottom: "0.5rem",
          }}
        >
          <FormControl fullWidth variant="standard">
            <TextField
              variant="standard"
              label="Item Name"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="name"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              multiline
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </FormControl>
          <FormControl fullWidth variant="standard">
            <InputLabel
              sx={{ color: "primary.main" }}
              shrink
              id="demo-simple-select-label"
            >
              Select Type{" "}
            </InputLabel>

            <Select
              id="type"
              name="type"
              className={classes.select}
              labelId="demo-select-small"
              // id="demo-select-small"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                  {
                    fontSize: "1rem",
                  },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
              error={formik.touched.type && Boolean(formik.errors.type)}
              //@ts-ignore
              helperText={formik.touched.type && formik.errors.type}
              MenuProps={{
                style: {
                  maxHeight: 400,
                  color: "007fff",
                },
              }}
              onChange={formik.handleChange}
              value={formik.values.type !== null ? formik.values.type : ""}
            >
              {/* <option value={""} style={{ display: "none" }}></option> */}
              {["Smart Phone", "Smart Card", "Others"].map(
                (item: any, index: any) => {
                  return (
                    <MenuItem
                      sx={{ color: "primary.main", fontSize: "1rem" }}
                      key={index}
                      value={item}
                    >
                      {item}
                    </MenuItem>
                  );
                }
              )}
            </Select>
            <div className={styles.errormsg}>
              {formik.touched.type && Boolean(formik.errors.type) && (
                <p>{formik.errors.type}</p>
              )}
            </div>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            columnGap: "1rem",
            marginBottom: "0.5rem",
          }}
        >
          <FormControl fullWidth variant="standard">
            <InputLabel
              sx={{ color: "primary.main" }}
              shrink
              id="demo-simple-select-label"
            >
              Select Sensing Technology{" "}
            </InputLabel>

            <Select
              id="sensing_technology"
              name="sensing_technology"
              className={classes.select}
              labelId="demo-select-small"
              // id="demo-select-small"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                  {
                    fontSize: "1rem",
                  },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
              error={
                formik.touched.sensing_technology &&
                Boolean(formik.errors.sensing_technology)
              }
              //@ts-ignore
              helperText={
                formik.touched.sensing_technology &&
                formik.errors.sensing_technology
              }
              MenuProps={{
                style: {
                  maxHeight: 400,
                  color: "007fff",
                },
              }}
              onChange={formik.handleChange}
              value={
                formik.values.sensing_technology !== null
                  ? formik.values.sensing_technology
                  : ""
              }
            >
              {/* <option value={""} style={{ display: "none" }}></option> */}
              {["Blutooth", "RF", "RFID", "NFC", "Others"].map(
                (item: any, index: any) => {
                  return (
                    <MenuItem
                      sx={{ color: "primary.main", fontSize: "1rem" }}
                      key={index}
                      value={item}
                    >
                      {item}
                    </MenuItem>
                  );
                }
              )}
            </Select>
            <div className={styles.errormsg}>
              {formik.touched.sensing_technology &&
                Boolean(formik.errors.sensing_technology) && (
                  <p>{formik.errors.sensing_technology}</p>
                )}
            </div>
          </FormControl>
          <FormControl fullWidth variant="standard">
            <InputLabel
              sx={{ color: "primary.main" }}
              shrink
              id="demo-simple-select-label"
            >
              Type of Key Fob{" "}
            </InputLabel>

            <Select
              id="type_of_key_fob"
              name="type_of_key_fob"
              className={classes.select}
              labelId="demo-select-small"
              // id="demo-select-small"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                  {
                    fontSize: "1rem",
                  },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
              error={
                formik.touched.type_of_key_fob &&
                Boolean(formik.errors.type_of_key_fob)
              }
              //@ts-ignore
              helperText={
                formik.touched.type_of_key_fob && formik.errors.type_of_key_fob
              }
              MenuProps={{
                style: {
                  maxHeight: 400,
                  color: "007fff",
                },
              }}
              onChange={formik.handleChange}
              value={
                formik.values.type_of_key_fob !== null
                  ? formik.values.type_of_key_fob
                  : ""
              }
            >
              {/* <option value={""} style={{ display: "none" }}></option> */}
              {[
                "Key fob(with mechanical key)",
                "Smart key(w/o mechanical key)",
                "Others",
              ].map((item: any, index: any) => {
                return (
                  <MenuItem
                    sx={{ color: "primary.main", fontSize: "1rem" }}
                    key={index}
                    value={item}
                  >
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
            <div className={styles.errormsg}>
              {formik.touched.type_of_key_fob &&
                Boolean(formik.errors.type_of_key_fob) && (
                  <p>{formik.errors.type_of_key_fob}</p>
                )}
            </div>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            columnGap: "1rem",
            marginBottom: "0.5rem",
          }}
        >
          <FormControl fullWidth variant="standard">
            <TextField
              variant="standard"
              label="No. of Functions"
              type="number"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="no_of_function"
              name="no_of_function"
              onChange={formik.handleChange}
              value={formik.values.no_of_function}
              error={
                formik.touched.no_of_function &&
                Boolean(formik.errors.no_of_function)
              }
              helperText={
                formik.touched.no_of_function && formik.errors.no_of_function
              }
            />
          </FormControl>

          <FormControl fullWidth variant="standard">
            <TextField
              variant="standard"
              label="Functions"
              multiline
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="function"
              name="function"
              onChange={formik.handleChange}
              value={formik.values.function}
              error={formik.touched.function && Boolean(formik.errors.function)}
              helperText={formik.touched.function && formik.errors.function}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            columnGap: "1rem",
            marginBottom: "0.5rem",
          }}
        >
          <FormControl fullWidth variant="standard">
            <TextField
              variant="standard"
              label="Range"
              multiline
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="range"
              name="range"
              onChange={formik.handleChange}
              value={formik.values.range}
              error={formik.touched.range && Boolean(formik.errors.range)}
              helperText={formik.touched.range && formik.errors.range}
            />
          </FormControl>
          <FormControl fullWidth variant="standard">
            <TextField
              variant="standard"
              label="Per unit cost (INR)"
              type="number"
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="unit_cost"
              name="unit_cost"
              onChange={formik.handleChange}
              value={formik.values.unit_cost}
              error={
                formik.touched.unit_cost && Boolean(formik.errors.unit_cost)
              }
              helperText={formik.touched.unit_cost && formik.errors.unit_cost}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            columnGap: "1rem",
            marginBottom: "0.5rem",
          }}
        >
          <FormControl fullWidth variant="standard">
            <TextField
              variant="standard"
              label="Source"
              multiline
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="reference"
              name="reference"
              onChange={formik.handleChange}
              value={formik.values.reference}
              error={
                formik.touched.reference && Boolean(formik.errors.reference)
              }
              helperText={formik.touched.reference && formik.errors.reference}
            />
          </FormControl>
        </Box>
        <Box sx={{ width: "100%", textAlign: "right" }}>
          <LoadingButton
            size="small"
            variant="contained"
            sx={{
              padding: "0.5rem",
              lineHeight: 1,
              marginTop: "1rem",
              "&:hover": {
                transform: "Scale(1.05)",
                transition: "transform 0.5s ease",
              },
              ".MuiCircularProgress-root": {
                color: "primary.main",
              },
            }}
            loading={isLoadingsave}
            type="submit"
          >
            {actions === "add" ? "Add" : "Save"}
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
}
