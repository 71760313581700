import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  TextField,
  MenuItem,
  Select,
  Input,
  FormControl,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  Chip,
  Tooltip,
  Grid,
  InputLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { API } from "../../../api-services";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { ADMIN } from "../../../Redux/Services/admin.service";

function ConfigurationTable({ permissionData, counter, setCounter }: any) {
  const [claimBusiness, setClaimBusiness] = useState({
    prelogin: "No",
    buyerStarter: "No",
    buyerProfessional: "No",
    sellerStarter: "Yes",
    sellerProfessional: "Yes",
  });

  const [dropDownList, setDropDownList] = useState<any>({
    prelogin: {
      partList: [],
      supplierList: [],
      searchPageList: [],
    },
    buyer: {
      starter: {
        partList: [],
        supplierList: [],
        searchPageList: [],
      },
      professional: {
        partList: [],
        supplierList: [],
        searchPageList: [],
      },
    },
    seller: {
      starter: {
        partList: [],
        supplierList: [],
        searchPageList: [],
      },
      professional: {
        partList: [],
        supplierList: [],
        searchPageList: [],
      },
    },
  });

  const formik: any = useFormik({
    initialValues: {
      page_data_to_show: [],
      prelogin: {
        supplier_data_to_show: [
          // {id: 33, name: "Should cost", selected: true}, {id: 32, name: "Minimum Selling Price", selected: true}
        ],
        page_data_to_show: [
          // {id: 33, name: "Should cost", selected: true},{id: 32, name: "Minimum Selling Price", selected: true}
        ],
        search_data_to_show: [
          //{id: 33, name: "Should cost", selected: true}, {id: 32, name: "Minimum Selling Price", selected: true} 
        ],
        supplier_data_to_show_is_unlimited: false,
        page_data_to_show_is_unlimited: false,
        search_data_to_show_is_unlimited: false,
      },
      buyer: {
        starter: {
          supplier_data_to_show: [
            
            // {id: 33, name: "Should cost", selected: true},{id: 32, name: "Minimum Selling Price", selected: true}
          ],
          page_data_to_show: [
            // {id: 33, name: "Should cost", selected: true},{id: 32, name: "Minimum Selling Price", selected: true}
          ],
          search_data_to_show: [
            // {id: 33, name: "Should cost", selected: true},{id: 32, name: "Minimum Selling Price", selected: true}
          ],
          supplier_data_to_show_is_unlimited: false,
          page_data_to_show_is_unlimited: false,
          search_data_to_show_is_unlimited: false,
        },
        professional: {
          supplier_data_to_show: [
            // {id: 33, name: "Should cost", selected: true},{id: 32, name: "Minimum Selling Price", selected: true}
          ],
          page_data_to_show: [
            // {id: 33, name: "Should cost", selected: true},{id: 32, name: "Minimum Selling Price", selected: true}
          ],
          search_data_to_show: [
            // {id: 33, name: "Should cost", selected: true},{id: 32, name: "Minimum Selling Price", selected: true}
          ],
          supplier_data_to_show_is_unlimited: false,
          page_data_to_show_is_unlimited: false,
          search_data_to_show_is_unlimited: false,
        },
      },
      seller: {
        starter: {
          supplier_data_to_show: [
            // {id: 33, name: "Should cost", selected: true},{id: 32, name: "Minimum Selling Price", selected: true}
          ],
          page_data_to_show: [
            // {id: 33, name: "Should cost", selected: true},{id: 32, name: "Minimum Selling Price", selected: true}
          ],
          search_data_to_show: [
            // {id: 33, name: "Should cost", selected: true},{id: 32, name: "Minimum Selling Price", selected: true}
          ],
          supplier_data_to_show_is_unlimited: false,
          page_data_to_show_is_unlimited: false,
          search_data_to_show_is_unlimited: false,
        },
        professional: {
          supplier_data_to_show: [
            // {id: 33, name: "Should cost", selected: true},{id: 32, name: "Minimum Selling Price", selected: true}
          ],
          page_data_to_show: [
            // {id: 33, name: "Should cost", selected: true},{id: 32, name: "Minimum Selling Price", selected: true}
          ],
          search_data_to_show: [
            // {id: 33, name: "Should cost", selected: true},{id: 32, name: "Minimum Selling Price", selected: true}
          ],
          supplier_data_to_show_is_unlimited: false,
          page_data_to_show_is_unlimited: false,
          search_data_to_show_is_unlimited: false,
        },
      },
    },
    onSubmit: (values: any) => {},
  });

  useEffect(() => {
    if (permissionData?.Buyer) {
      const buyerStarter: any = permissionData?.Buyer?.Starter;
      const buyerProfessional: any = permissionData?.Buyer?.Professional;
      const prelogin: any = permissionData["Pre-Login"];
      const sellerStarter: any = permissionData?.Seller?.Starter;
      const sellerProfessional: any = permissionData?.Seller?.Power;

      setClaimBusiness({
        ...claimBusiness,
        prelogin: prelogin?.claim_business_limit === 0 ? "No" : "Yes",
        buyerStarter: buyerStarter?.claim_business_limit === 0 ? "No" : "Yes",
        buyerProfessional:
          buyerProfessional?.claim_business_limit === 0 ? "No" : "Yes",
        sellerStarter: sellerStarter?.claim_business_limit === 0 ? "No" : "Yes",
        sellerProfessional:
          sellerProfessional?.claim_business_limit === 0 ? "No" : "Yes",
      });

      formik.setValues({
        ...formik.values,
        buyer: {
          ...formik.values.buyer,
          starter: buyerStarter || formik.values.buyer.starter,
          professional: buyerProfessional || formik.values.buyer.professional,
        },
        seller: {
          ...formik.values.seller,
          starter: sellerStarter || formik.values.seller.starter,
          professional: sellerProfessional || formik.values.seller.professional,
        },
        prelogin: {
          ...formik.values.prelogin,
          ...prelogin, // Assuming `prelogin` is an object
        },
      });
    }
  }, [permissionData]);

  const getType = (typeIndex: any) => {
    const userType =
      typeIndex === 1
        ? "prelogin"
        : typeIndex === 2 || typeIndex === 3
        ? "buyer"
        : "seller";
    const subscriptionType =
      typeIndex === 2 || typeIndex === 4
        ? "starter"
        : typeIndex === 3 || typeIndex === 5
        ? "professional"
        : null;
    return { userType, subscriptionType };
  };

  const handleChangeInput = (
    e: any,
    typeIndex: any,
    namee: any,
    valuee: any
  ) => {
    const { name, value } = e.target;
    const { userType, subscriptionType }: any = getType(typeIndex);

    let updatedValues = { ...formik.values };

    if (userType === "buyer" && subscriptionType === "starter") {
      updatedValues = {
        ...updatedValues,
        buyer: {
          ...updatedValues.buyer,
          starter: {
            ...updatedValues.buyer.starter,
            [name]: parseInt(value), // Update the specific field (e.g., duration_in_days)
          },
        },
      };
    } else if (userType === "buyer" && subscriptionType === "professional") {
      updatedValues = {
        ...updatedValues,
        buyer: {
          ...updatedValues.buyer,
          professional: {
            ...updatedValues.buyer.professional,
            [name]: parseInt(value), // Update the specific field (e.g., duration_in_days)
          },
        },
      };
    } else if (userType === "seller" && subscriptionType === "starter") {
      updatedValues = {
        ...updatedValues,
        seller: {
          ...updatedValues.seller,
          starter: {
            ...updatedValues.seller.starter,
            [name]: parseInt(value), // Update the specific field (e.g., duration_in_days)
          },
        },
      };
    } else if (userType === "seller" && subscriptionType === "professional") {
      updatedValues = {
        ...updatedValues,
        seller: {
          ...updatedValues.seller,
          professional: {
            ...updatedValues.seller.professional,
            [name]: parseInt(value), // Update the specific field (e.g., duration_in_days)
          },
        },
      };
    } else if (
      userType === "prelogin" &&
      (subscriptionType === "null" || subscriptionType === null)
    ) {
      updatedValues = {
        ...updatedValues,
        prelogin: {
          ...updatedValues.prelogin,
          [name]: parseInt(value),
        },
      };
    }

    formik.setValues(updatedValues);
  };

  const handleUpdateConfig = (e: any, typeIndex: any) => {
    const { userType, subscriptionType } = getType(typeIndex);
    let fieldValue: any;

    if (userType === "buyer" && subscriptionType === "starter") {
      fieldValue = formik.values.buyer.starter;
    } else if (userType === "buyer" && subscriptionType === "professional") {
      fieldValue = formik.values.buyer.professional;
    } else if (userType === "seller" && subscriptionType === "starter") {
      fieldValue = formik.values.seller.starter;
    } else if (userType === "seller" && subscriptionType === "professional") {
      fieldValue = formik.values.seller.professional;
    } else if (
      userType === "prelogin" &&
      (subscriptionType === "null" || subscriptionType === null)
    ) {
      fieldValue = formik.values.prelogin;
    }

    API.put(
      `payment/search_configuration/${fieldValue?.id}/`,

      fieldValue
    )
      .then((res: any) => {
        ADMIN.toast.info("Updated Successfully");
        setCounter(counter + 1);
      })
      .catch((err: any) => {
        const { data } = err.response;
        Swal.fire({
          title: "Error!",
          text: data[0],
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  useEffect(() => {
    if (claimBusiness.prelogin === "No") {
      formik.setValues({
        ...formik.values,
        prelogin: {
          ...formik.values.prelogin,
          claim_business_limit: 0,
        },
      });
    }

    if (claimBusiness.buyerStarter === "No") {
      formik.setValues({
        ...formik.values,
        buyer: {
          ...formik.values.buyer,
          starter: {
            ...formik.values.buyer.starter,
            claim_business_limit: 0,
          },
        },
      });
    }
    if (claimBusiness.buyerProfessional === "No") {
      formik.setValues({
        ...formik.values,
        buyer: {
          ...formik.values.buyer,
          professional: {
            ...formik.values.buyer.professional,
            claim_business_limit: 0,
          },
        },
      });
    }
    if (claimBusiness.sellerStarter === "No") {
      formik.setValues({
        ...formik.values,
        seller: {
          ...formik.values.seller,
          starter: {
            ...formik.values.seller.starter,
            claim_business_limit: 0,
          },
        },
      });
    }
    if (claimBusiness.sellerProfessional === "No") {
      formik.setValues({
        ...formik.values,
        seller: {
          ...formik.values.seller,
          professional: {
            ...formik.values.seller.professional,
            claim_business_limit: 0,
          },
        },
      });
    }
  }, [claimBusiness]);

  useEffect(() => {
    setDropDownList((prevState: any) => ({
      ...prevState, // spread the previous state
      prelogin: {
        partList: permissionData?.["Pre-Login"]?.page_data_to_show || [],
        supplierList:
          permissionData?.["Pre-Login"]?.supplier_data_to_show || [],
        searchPageList:
          permissionData?.["Pre-Login"]?.search_data_to_show || [],
      },
      buyer: {
        starter: {
          partList: permissionData?.Buyer?.Starter?.page_data_to_show || [],
          supplierList:
            permissionData?.Buyer?.Starter?.supplier_data_to_show || [],
          searchPageList:
            permissionData?.Buyer?.Starter?.search_data_to_show || [],
        },
        professional: {
          partList:
            permissionData?.Buyer?.Professional?.page_data_to_show || [],
          supplierList:
            permissionData?.Buyer?.Professional?.supplier_data_to_show || [],
          searchPageList:
            permissionData?.Buyer?.Professional?.search_data_to_show || [],
        },
      },
      seller: {
        starter: {
          partList: permissionData?.Seller?.Starter?.page_data_to_show || [],
          supplierList:
            permissionData?.Seller?.Starter?.supplier_data_to_show || [],
          searchPageList:
            permissionData?.Seller?.Starter?.search_data_to_show || [],
        },
        professional: {
          partList: permissionData?.Seller?.Power?.page_data_to_show || [],
          supplierList:
            permissionData?.Seller?.Power?.supplier_data_to_show || [],
          searchPageList:
            permissionData?.Seller?.Power?.search_data_to_show || [],
        },
      },
    }));
  }, [permissionData]);

  const handleChangeSelectInput = (
    event: any,
    value: any[],
    typeIndex?: any,
    name?: any,
    isSelect?: any
  ) => {
    const { userType, subscriptionType }: any = getType(typeIndex);
    let updatedPageData: any;

    if (userType === "buyer" && subscriptionType === "starter") {
      const selectedIds = value.map((item) => item.id); // Get IDs of selected items

      const updatedSupplierData = formik.values.buyer.starter?.[name]?.map(
        (item: any) => ({
          ...item,
          selected: selectedIds.includes(item.id), // Set selected to true or false based on the selected values
        })
      );

      // Update Formik state
      formik.setFieldValue(`buyer.starter.${name}`, updatedSupplierData);
    } else if (userType === "buyer" && subscriptionType === "professional") {
      const selectedIds = value.map((item) => item.id); // Get IDs of selected items

      const updatedSupplierData = formik.values.buyer.professional?.[name]?.map(
        (item: any) => ({
          ...item,
          selected: selectedIds.includes(item.id), // Set selected to true or false based on the selected values
        })
      );

      // Update Formik state
      formik.setFieldValue(`buyer.professional.${name}`, updatedSupplierData);
    } else if (userType === "seller" && subscriptionType === "starter") {
      const selectedIds = value.map((item) => item.id); // Get IDs of selected items

      const updatedSupplierData = formik.values.seller.starter?.[name]?.map(
        (item: any) => ({
          ...item,
          selected: selectedIds.includes(item.id), // Set selected to true or false based on the selected values
        })
      );

      // Update Formik state
      formik.setFieldValue(`seller.starter.${name}`, updatedSupplierData);
    } else if (userType === "seller" && subscriptionType === "professional") {
      const selectedIds = value.map((item) => item.id); // Get IDs of selected items

      const updatedSupplierData = formik.values.seller.professional?.[
        name
      ]?.map((item: any) => ({
        ...item,
        selected: selectedIds.includes(item.id), // Set selected to true or false based on the selected values
      }));

      // Update Formik state
      formik.setFieldValue(`seller.professional.${name}`, updatedSupplierData);
    } else if (
      userType === "prelogin" &&
      (subscriptionType === "null" || subscriptionType === null)
    ) {
      updatedPageData = formik.values.prelogin?.[name]?.map((item: any) => {
        // Check if the item is selected or deselected
        const isSelected = value.some(
          (selectedItem) => selectedItem.id === item.id
        );
        return { ...item, selected: isSelected };
      });
      // Update the formik state
      formik.setFieldValue(`prelogin.${name}`, updatedPageData);
    }
  };

  const handleCheckboxInput = (e: any, typeIndex?: any, name?: any) => {
    const { value } = e.target;
    const { userType, subscriptionType } = getType(typeIndex);

    let keyPath;

    if (userType === "buyer" && subscriptionType === "starter") {
      keyPath = `buyer.starter.${name}`;
    } else if (userType === "buyer" && subscriptionType === "professional") {
      keyPath = `buyer.professional.${name}`;
    } else if (userType === "seller" && subscriptionType === "starter") {
      keyPath = `seller.starter.${name}`;
    } else if (userType === "seller" && subscriptionType === "professional") {
      keyPath = `seller.professional.${name}`;
    } else if (
      userType === "prelogin" &&
      (subscriptionType === "null" || subscriptionType === null)
    ) {
      keyPath = `prelogin.${name}`;
    }

    if (keyPath) {
      formik.setFieldValue(keyPath, value === "true");
    }
  };

  

  const handleSelectAll = (category: string, field?: any) => {
    // Split the category into parts
    const categoryParts = category?.split('.');
  
    // Dynamically access the nested value in formik
    const fieldValues = categoryParts?.reduce((acc, key) => acc?.[key], formik.values)?.[field];
  
    // Check the current status (if all are selected)
    const currentStatus = fieldValues?.every((item: any) => item?.selected);
  
    // Create the updated data by toggling the 'selected' status
    const updatedData = fieldValues?.map((item: any) => ({
      ...item,
      selected: !currentStatus, // Toggle the selection
    }));
  
    // Dynamically set the field value using formik.setFieldValue
    formik.setFieldValue(`${category}.${field}`, updatedData);
  };


  const renderSelectAllCheckbox = (category: string, field?: any, tabIndex?: any) => {
    // Split the category into parts
    const categoryParts = category?.split('.');
  
    // Dynamically access the nested value in formik
    const fieldValues = categoryParts?.reduce((acc, key) => acc?.[key], formik.values)?.[field];
  
    // Check if all items are selected
    const allSelected = fieldValues?.every((item: any) => item?.selected);
  
   
  
    return (
      <Checkbox
        checked={allSelected || false}
        onChange={() => handleSelectAll(category, field)}
        color="primary"
        onBlur={(e) => handleUpdateConfig(e, tabIndex)}
      />
    );
  };
  console.log(formik.values);
 

  return (
    <TableContainer component={Paper}>
      <Table aria-label="tale table">
        <TableHead>
          <TableRow sx={{}}>
            <TableCell
              sx={{
                width: "15vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                background: "#d0eddb",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  placeItems: "center",
                  fontWeight: "500",
                }}
              >
                Type of User
              </Box>
            </TableCell>
            <TableCell
              sx={{
                width: "15vw",
                borderBottom: "1px solid rgba(196, 196, 196, 1)",
                borderRight: "1px solid rgba(196, 196, 196, 1)",
                background: "#ddf0f6",
              }}
            >
              <Box sx={{ display: "grid", placeItems: "center" }}>
                Pre Login
              </Box>
            </TableCell>
            <TableCell
              sx={{
                width: "30vw",
                borderBottom: "1px solid rgba(196, 196, 196, 1)",
                borderRight: "1px solid rgba(196, 196, 196, 1)",
                padding: "0",
                background: "#ddf0f6",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  placeItems: "center",
                  borderBottom: "1px solid rgba(196, 196, 196, 1)",
                  padding: "0.5rem",
                }}
              >
                Buyer
              </Box>

              <Box sx={{ display: "flex", padding: "0.5rem" }}>
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.5rem",
                    borderRight: "1px solid rgba(196, 196, 196, 1)",
                  }}
                >
                  Starter
                </Box>
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.5rem",
                  }}
                >
                  Professional
                </Box>
              </Box>
            </TableCell>
            <TableCell
              sx={{
                width: "30vw",
                borderBottom: "1px solid rgba(196, 196, 196, 1)",
                borderRight: "1px solid rgba(196, 196, 196, 1)",
                padding: "0",
                background: "#ddf0f6",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  placeItems: "center",
                  borderBottom: "1px solid rgba(196, 196, 196, 1)",
                  padding: "0.5rem",
                }}
              >
                Seller
              </Box>

              <Box sx={{ display: "flex", padding: "0.5rem" }}>
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.5rem",
                    borderRight: "1px solid rgba(196, 196, 196, 1)",
                  }}
                >
                  Basic User
                </Box>{" "}
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.5rem",
                  }}
                >
                  Power User
                </Box>
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {/* Duration */}
          <TableRow>
            <TableCell
              sx={{
                width: "15vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",

                background: "#d0eddb",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  placeItems: "center",
                  textAlign: "center",
                }}
              >
                Duration ( in days)
              </Box>
            </TableCell>
            <TableCell
              sx={{
                width: "15vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
              }}
            >
              <Box sx={{ display: "grid", placeItems: "center" }}>
                <TextField
                  type="text"
                  disabled
                  name="duration_in_days"
                  onChange={(e) => handleChangeInput(e, 1, "", "")}
                  onBlur={(e) => handleUpdateConfig(e, 1)}
                  variant="standard"
                  // value={formik.values.prelogin?.duration_in_days}
                  value="Unlimited"
                  sx={{
                    padding: "0",
                    "& .MuiOutlinedInput-input": {
                      padding: "0", // Ensure other necessary paddings are applied
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#d3d3d3", // Light color for bottom border
                    },
                    "& .MuiInput-underline:hover:before": {
                      borderBottomColor: "#a9a9a9", // Color on hover (optional)
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#00887a", // Color after focus (optional)
                    },
                  }}
                  style={{ padding: "0" }}
                  inputProps={{ min: 0, style: { textAlign: "center" } }} // Ensure input cannot go below 0
                />
              </Box>
            </TableCell>
            <TableCell
              sx={{
                width: "30vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                padding: "0",
              }}
            >
              <Box sx={{ display: "flex", padding: "0.5rem" }}>
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.35rem",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  <TextField
                    type="number"
                    variant="standard"
                    name="duration_in_days"
                    onChange={(e) => handleChangeInput(e, 2, "", "")}
                    onBlur={(e) => handleUpdateConfig(e, 2)}
                    value={
                      (formik.values as any)?.buyer?.starter?.duration_in_days
                    }
                    sx={{
                      padding: "0",
                      "& .MuiOutlinedInput-input": {
                        padding: "0", // Ensure other necessary paddings are applied
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#d3d3d3", // Light color for bottom border
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "#a9a9a9", // Color on hover (optional)
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#00887a", // Color after focus (optional)
                      },
                    }}
                    style={{ padding: "0" }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }} // Ensure input cannot go below 0
                  />
                </Box>
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.25rem",
                  }}
                >
                  <TextField
                    type="text"
                    variant="standard"
                    name="duration_in_days"
                    disabled
                    onChange={(e) => handleChangeInput(e, 3, "", "")}
                    onBlur={(e) => handleUpdateConfig(e, 3)}
                    value={
                      (formik.values as any)?.buyer?.professional
                        ?.duration_in_days
                    }
                    sx={{
                      padding: "0",
                      "& .MuiOutlinedInput-input": {
                        padding: "0", // Ensure other necessary paddings are applied
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#d3d3d3", // Light color for bottom border
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "#a9a9a9", // Color on hover (optional)
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#00887a", // Color after focus (optional)
                      },
                    }}
                    style={{ padding: "0" }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }} // Ensure input cannot go below 0
                  />
                </Box>
              </Box>
            </TableCell>
            <TableCell
              sx={{
                width: "30vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                padding: "0",
              }}
            >
              <Box sx={{ display: "flex", padding: "0.5rem" }}>
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.25rem",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  <TextField
                    type="number"
                    variant="standard"
                    name="duration_in_days"
                    onChange={(e) => handleChangeInput(e, 4, "", "")}
                    onBlur={(e) => handleUpdateConfig(e, 4)}
                    value={
                      (formik.values as any)?.seller?.starter?.duration_in_days
                    }
                    sx={{
                      padding: "0",
                      "& .MuiOutlinedInput-input": {
                        padding: "0", // Ensure other necessary paddings are applied
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#d3d3d3", // Light color for bottom border
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "#a9a9a9", // Color on hover (optional)
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#00887a", // Color after focus (optional)
                      },
                    }}
                    style={{ padding: "0" }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }} // Ensure input cannot go below 0
                  />
                </Box>{" "}
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.25rem",
                  }}
                >
                  <TextField
                    type="text"
                    variant="standard"
                    name="duration_in_days"
                    disabled
                    onChange={(e) => handleChangeInput(e, 5, "", "")}
                    onBlur={(e) => handleUpdateConfig(e, 5)}
                    value={
                      (formik.values as any)?.seller?.professional
                        ?.duration_in_days
                    }
                    sx={{
                      padding: "0",
                      "& .MuiOutlinedInput-input": {
                        padding: "0", // Ensure other necessary paddings are applied
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#d3d3d3", // Light color for bottom border
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "#a9a9a9", // Color on hover (optional)
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#00887a", // Color after focus (optional)
                      },
                    }}
                    style={{ padding: "0" }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }} // Ensure input cannot go below 0
                  />
                </Box>
              </Box>
            </TableCell>
          </TableRow>

          {/* Search Result Count */}
          <TableRow>
            <TableCell
              sx={{
                width: "15vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",

                background: "#d0eddb",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  placeItems: "center",
                  textAlign: "center",
                }}
              >
                Search Results in SLP
              </Box>
            </TableCell>
            <TableCell
              sx={{
                width: "15vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
              }}
            >
              <Box sx={{display:"flex", alignItems:"center",   borderBottom: "0.1px solid rgba(224, 224, 224, 1)",}}>
              {/* Autocomplete multiselect dropdown for 'search_data_to_show_is_unlimited' */}
              <Autocomplete
                id="combo-box-demo"
                multiple
                options={dropDownList?.prelogin?.searchPageList}
                getOptionLabel={(option: any) => option?.name}
                limitTags={1}
                disableCloseOnSelect
                value={
                  formik.values.prelogin.search_data_to_show?.filter(
                    (item: any) => item.selected
                  ) || []
                }
                // Show selected items
                onChange={(e, value) =>
                  handleChangeSelectInput(
                    e,
                    value,
                    1,
                    "search_data_to_show",
                    true
                  )
                }
                onBlur={(e) => handleUpdateConfig(e, 1)}
                renderOption={(props: any, option: any) => (
                  <li {...props} style={{ fontSize: "12px" }}>
                    {option?.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Select sections to show"
                    sx={{
                      width: "100%",
                      "& .MuiInput-underline:before": {
                        borderBottom: "none", // Remove bottom border
                      },
                      "& .MuiInput-underline:after": {
                        borderBottom: "none", // Remove active bottom border
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "12px !important",
                      },
                      ".MuiSvgIcon-root": { color: "primary.main" },
                    }}
                  />
                )}
                renderTags={(value: any[], getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      // key={option.id}
                      label={option?.name}
                      {...getTagProps({ index })}
                      sx={{
                        backgroundColor: "primary.light",
                        height: "26px",
                      }}
                    />
                  ))
                }
                sx={{ marginTop: "0.1rem", flex: "1",border:"none"  }}
              />
           {renderSelectAllCheckbox('prelogin', "search_data_to_show", 1)}

              </Box>
             

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderBottom: "0.1px solid rgba(224, 224, 224, 1)",
                  marginBottom: "0.2rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "0.5rem",
                  }}
                >
                  <Select
                    variant="standard"
                    labelId="supplier-unlimited-label"
                    name="search_data_to_show_is_unlimited"
                    value={
                      formik.values?.prelogin
                        ?.search_data_to_show_is_unlimited || false
                    }
                    onChange={(e) =>
                      handleCheckboxInput(
                        e,
                        1,
                        "search_data_to_show_is_unlimited"
                      )
                    }
                    sx={{
                      width:
                        formik.values?.prelogin
                          ?.search_data_to_show_is_unlimited === false
                          ? "65%"
                          : "100%",
                      "& .MuiStandardInput-root": {
                        padding: "0",
                        "& .MuiStandardInput-notchedOutline": {
                          borderBottomColor: "#fff", // Initial bottom border color
                        },
                        "&:hover .MuiStandardInput-notchedOutline": {
                          borderBottomColor: "#a9a9a9", // Hover color
                        },
                        "&.Mui-focused .MuiStandardInput-notchedOutline": {
                          borderBottomColor: "#00887a", // Focus color
                        },
                      },
                    }}
                    onBlur={(e) => handleUpdateConfig(e, 1)}
                    color="primary"
                    label="Supplier Unlimited"
                  >
                    <MenuItem value={"false"}>Limited Access</MenuItem>
                    <MenuItem value={"true"}>Unlimited Access</MenuItem>
                  </Select>

                  {/* TextField for 'search_credit' */}
                  {formik.values?.prelogin?.search_data_to_show_is_unlimited ===
                    false && (
                    <TextField
                      type="number"
                      name="search_credit"
                      disabled={
                        formik.values?.prelogin
                          ?.search_data_to_show_is_unlimited
                      }
                      value={(formik.values as any)?.prelogin?.search_credit}
                      onChange={(e) => handleChangeInput(e, 1, "", "")}
                      onBlur={(e) => handleUpdateConfig(e, 1)}
                      variant="standard"
                      sx={{
                        width: "25%",
                        padding: "0",
                        "& .MuiOutlinedInput-input": {
                          padding: "0", // Ensure no padding for input
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "#e0e0e0", // Light color for bottom border
                        },
                        "& .MuiInput-underline:hover:before": {
                          borderBottomColor: "#a9a9a9", // Color on hover (optional)
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#00887a", // Color after focus (optional)
                        },
                      }}
                      inputProps={{ min: 0, style: { textAlign: "center" } }}
                    />
                  )}
                </Box>
              </Box>
            </TableCell>
            <TableCell
              sx={{
                width: "30vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                padding: "0",
              }}
            >
              <Box sx={{ display: "flex", padding: "0.5rem" }}>
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.35rem",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                   <Box sx={{display:"flex", alignItems:"center",  borderBottom: "0.1px solid rgba(224, 224, 224, 1)",width:"100%"}}>
                  {/* Autocomplete multiselect dropdown for 'search_data_to_show' */}
                  <Autocomplete
                    id="combo-box-demo"
                    multiple
                    options={dropDownList?.buyer?.starter?.searchPageList}
                    getOptionLabel={(option: any) => option?.name}
                    limitTags={1}
                    disableCloseOnSelect
                    value={
                      formik.values.buyer.starter.search_data_to_show?.filter(
                        (item: any) => item?.selected
                      ) || []
                    }
                    // Show selected items
                    onChange={(e, value) =>
                      handleChangeSelectInput(
                        e,
                        value,
                        2, // Change this as per your needs
                        "search_data_to_show",
                        true
                      )
                    }
                    onBlur={(e) => handleUpdateConfig(e, 2)}
                    renderOption={(props: any, option: any) => (
                      <li {...props} style={{ fontSize: "12px" }}>
                        {option?.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Select sections to show"
                        sx={{
                          width: "100%",
                          "& .MuiInput-underline:before": {
                            borderBottom: "none", // Remove bottom border
                          },
                          "& .MuiInput-underline:after": {
                            borderBottom: "none", // Remove active bottom border
                          },
                          ".MuiInputBase-root.MuiInput-root": {
                            fontSize: "12px !important",
                          },
                          ".MuiSvgIcon-root": { color: "primary.main" },
                        }}
                      />
                    )}
                    renderTags={(value: any[], getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          // key={option.id}
                          label={option?.name}
                          {...getTagProps({ index })}
                          sx={{
                            backgroundColor: "primary.light",
                            height: "26px",
                          }}
                        />
                      ))
                    }
                    sx={{ width: "100%", marginTop: "0.1rem", flex: "1" }}
                  />

{renderSelectAllCheckbox('buyer.starter', "search_data_to_show", 2)}

</Box>
                  
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      borderBottom: "0.1px solid rgba(224, 224, 224, 1)",
                      marginBottom: "0.2rem",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        marginTop: "0.5rem",
                      }}
                    >
                      <Select
                        variant="standard"
                        labelId="supplier-unlimited-label"
                        name="search_data_to_show_is_unlimited"
                        value={
                          formik.values?.buyer?.starter
                            ?.search_data_to_show_is_unlimited || false
                        }
                        onChange={(e) =>
                          handleCheckboxInput(
                            e,
                            2,
                            "search_data_to_show_is_unlimited"
                          )
                        }
                        sx={{
                          width:
                            formik.values?.buyer?.starter
                              ?.search_data_to_show_is_unlimited === false
                              ? "65%"
                              : "100%",
                          "& .MuiStandardInput-root": {
                            padding: "0",
                            "& .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#fff", // Initial bottom border color
                            },
                            "&:hover .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#a9a9a9", // Hover color
                            },
                            "&.Mui-focused .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#00887a", // Focus color
                            },
                          },
                        }}
                        onBlur={(e) => handleUpdateConfig(e, 2)}
                        color="primary"
                        label="Supplier Unlimited"
                      >
                        <MenuItem value={"false"}>Limited Access</MenuItem>
                        <MenuItem value={"true"}>Unlimited Access</MenuItem>
                      </Select>
                      {/* TextField for 'search_credit' */}
                      {formik.values?.buyer?.starter
                        ?.search_data_to_show_is_unlimited === false && (
                        <TextField
                          type="number"
                          variant="standard"
                          disabled={
                            formik.values?.buyer?.starter
                              ?.search_data_to_show_is_unlimited
                          }
                          name="search_credit"
                          value={
                            (formik.values as any)?.buyer?.starter
                              ?.search_credit
                          }
                          onChange={(e) => handleChangeInput(e, 2, "", "")}
                          onBlur={(e) => handleUpdateConfig(e, 2)}
                          sx={{
                            width: "100%",
                            padding: "0",
                            "& .MuiOutlinedInput-input": {
                              padding: "0", // Ensure no padding for input
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#fff", // Light color for bottom border
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#a9a9a9", // Color on hover (optional)
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#00887a", // Color after focus (optional)
                            },
                          }}
                          inputProps={{
                            min: 0,
                            style: { textAlign: "center" },
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.35rem",
                  }}
                >
                  {/* Autocomplete multiselect dropdown for 'search_data_to_show' */}
                  {/* <Autocomplete
                    id="combo-box-demo"
                    multiple
                    options={dropDownList?.buyer?.professional?.searchPageList}
                    getOptionLabel={(option: any) => option?.name}
                    limitTags={1}
                    disableCloseOnSelect
                    value={
                      formik.values.buyer.professional.search_data_to_show?.filter(
                        (item: any) => item?.selected
                      ) || []
                    }
                    // Show selected items
                    onChange={(e, value) =>
                      handleChangeSelectInput(
                        e,
                        value,
                        3, // Change this as per your needs
                        "search_data_to_show",
                        true
                      )
                    }
                    onBlur={(e) => handleUpdateConfig(e, 3)}
                    renderOption={(props: any, option: any) => (
                      <li {...props} style={{ fontSize: "12px" }}>
                        {option?.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Select sections to show"
                        sx={{
                      width: "100%",
                      "& .MuiInput-underline:before": {
                        borderBottom: "none", // Remove bottom border
                      },
                      "& .MuiInput-underline:after": {
                        borderBottom: "none", // Remove active bottom border
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "12px !important",
                      },
                      ".MuiSvgIcon-root": { color: "primary.main" },
                    }}
                      />
                    )}
                    renderTags={(value: any[], getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          // key={option.id}
                          label={option?.name}
                          {...getTagProps({ index })}
                          sx={{
                            backgroundColor: "primary.light",
                            height: "26px",
                          }}
                        />
                      ))
                    }
                    sx={{ width: "100%", marginTop: "0.1rem", flex: "1" }}
                  /> */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      borderBottom: "0.1px solid rgba(224, 224, 224, 1)",
                      marginBottom: "0.2rem",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Select
                        variant="standard"
                        labelId="supplier-unlimited-label"
                        name="search_data_to_show_is_unlimited"
                        value={
                          formik.values?.buyer?.professional
                            ?.search_data_to_show_is_unlimited || false
                        }
                        onChange={(e) =>
                          handleCheckboxInput(
                            e,
                            3,
                            "search_data_to_show_is_unlimited"
                          )
                        }
                        sx={{
                          width:
                            formik.values?.buyer?.professional
                              ?.search_data_to_show_is_unlimited === false
                              ? "65%"
                              : "100%",
                          "& .MuiStandardInput-root": {
                            padding: "0",
                            "& .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#fff", // Initial bottom border color
                            },
                            "&:hover .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#a9a9a9", // Hover color
                            },
                            "&.Mui-focused .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#00887a", // Focus color
                            },
                          },
                        }}
                        onBlur={(e) => handleUpdateConfig(e, 3)}
                        color="primary"
                        label="Supplier Unlimited"
                      >
                        <MenuItem value={"false"}>Limited Access</MenuItem>
                        <MenuItem value={"true"}>Unlimited Access</MenuItem>
                      </Select>
                      {/* TextField for 'search_credit' */}
                      {formik.values?.buyer?.professional
                        ?.search_data_to_show_is_unlimited === false && (
                        <TextField
                          type="number"
                          variant="standard"
                          name="search_credit"
                          value={
                            (formik.values as any)?.buyer?.professional
                              ?.search_credit
                          }
                          disabled={
                            formik.values?.buyer?.professional
                              ?.search_data_to_show_is_unlimited
                          }
                          onChange={(e) => handleChangeInput(e, 3, "", "")}
                          onBlur={(e) => handleUpdateConfig(e, 3)}
                          sx={{
                            width: "100%",
                            padding: "0",
                            "& .MuiOutlinedInput-input": {
                              padding: "0", // Ensure no padding for input
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#fff", // Light color for bottom border
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#a9a9a9", // Color on hover (optional)
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#00887a", // Color after focus (optional)
                            },
                          }}
                          inputProps={{
                            min: 0,
                            style: { textAlign: "center" },
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </TableCell>
            <TableCell
              sx={{
                width: "30vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                padding: "0",
              }}
            >
              <Box sx={{ display: "flex", padding: "0.5rem" }}>
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.35rem",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                    <Box sx={{display:"flex", alignItems:"center",  borderBottom: "0.1px solid rgba(224, 224, 224, 1)",width:"100%"}}>
                  {/* Autocomplete multiselect dropdown for 'search_data_to_show' */}
                  <Autocomplete
                    id="combo-box-demo"
                    multiple
                    options={dropDownList?.seller?.starter?.searchPageList}
                    getOptionLabel={(option: any) => option?.name}
                    limitTags={1}
                    disableCloseOnSelect
                    value={
                      formik.values.seller?.starter.search_data_to_show?.filter(
                        (item: any) => item?.selected
                      ) || []
                    }
                    // Show selected items
                    onChange={(e, value) =>
                      handleChangeSelectInput(
                        e,
                        value,
                        4, // Change this as per your needs
                        "search_data_to_show",
                        true
                      )
                    }
                    onBlur={(e) => handleUpdateConfig(e, 4)}
                    renderOption={(props: any, option: any) => (
                      <li {...props} style={{ fontSize: "12px" }}>
                        {option?.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Select sections to show"
                        sx={{
                      width: "100%",
                      "& .MuiInput-underline:before": {
                        borderBottom: "none", // Remove bottom border
                      },
                      "& .MuiInput-underline:after": {
                        borderBottom: "none", // Remove active bottom border
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "12px !important",
                      },
                      ".MuiSvgIcon-root": { color: "primary.main" },
                    }}
                      />
                    )}
                    renderTags={(value: any[], getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          // key={option.id}
                          label={option?.name}
                          {...getTagProps({ index })}
                          sx={{
                            backgroundColor: "primary.light",
                            height: "26px",
                          }}
                        />
                      ))
                    }
                    sx={{ width: "100%", marginTop: "0.1rem", flex: "1" }}
                  />
                  {renderSelectAllCheckbox('seller.starter', "search_data_to_show", 4)}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      borderBottom: "0.1px solid rgba(224, 224, 224, 1)",
                      marginBottom: "0.2rem",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Select
                        variant="standard"
                        labelId="supplier-unlimited-label"
                        name="search_data_to_show_is_unlimited"
                        value={
                          formik.values?.seller?.starter
                            ?.search_data_to_show_is_unlimited || false
                        }
                        onChange={(e) =>
                          handleCheckboxInput(
                            e,
                            4,
                            "search_data_to_show_is_unlimited"
                          )
                        }
                        sx={{
                          width:
                            formik.values?.seller?.starter
                              ?.search_data_to_show_is_unlimited === false
                              ? "65%"
                              : "100%",
                          "& .MuiStandardInput-root": {
                            padding: "0",
                            "& .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#fff", // Initial bottom border color
                            },
                            "&:hover .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#a9a9a9", // Hover color
                            },
                            "&.Mui-focused .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#00887a", // Focus color
                            },
                          },
                        }}
                        onBlur={(e) => handleUpdateConfig(e, 4)}
                        color="primary"
                        label="Supplier Unlimited"
                      >
                        <MenuItem value={"false"}>Limited Access</MenuItem>
                        <MenuItem value={"true"}>Unlimited Access</MenuItem>
                      </Select>

                      {/* TextField for 'search_credit' */}
                      {formik.values?.seller?.starter
                        ?.search_data_to_show_is_unlimited === false && (
                        <TextField
                          type="number"
                          variant="standard"
                          name="search_credit"
                          disabled={
                            formik.values?.seller?.starter
                              ?.search_data_to_show_is_unlimited
                          }
                          value={
                            (formik.values as any)?.seller?.starter
                              ?.search_credit
                          }
                          onChange={(e) => handleChangeInput(e, 4, "", "")}
                          onBlur={(e) => handleUpdateConfig(e, 4)}
                          sx={{
                            width: "100%",
                            padding: "0",
                            "& .MuiOutlinedInput-input": {
                              padding: "0", // Ensure no padding for input
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#fff", // Light color for bottom border
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#a9a9a9", // Color on hover (optional)
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#00887a", // Color after focus (optional)
                            },
                          }}
                          inputProps={{
                            min: 0,
                            style: { textAlign: "center" },
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.35rem",
                  }}
                >
                  {/* Autocomplete multiselect dropdown for 'search_data_to_show' */}
                  {/* <Autocomplete
                    id="combo-box-demo"
                    multiple
                    options={dropDownList?.seller?.professional?.searchPageList}
                    getOptionLabel={(option: any) => option?.name}
                    limitTags={1}
                    disableCloseOnSelect
                    value={
                      formik.values.seller.professional.search_data_to_show?.filter(
                        (item: any) => item?.selected
                      ) || []
                    }
                    // Show selected items
                    onChange={(e, value) =>
                      handleChangeSelectInput(
                        e,
                        value,
                        5, // Change this as per your needs
                        "search_data_to_show",
                        true
                      )
                    }
                    onBlur={(e) => handleUpdateConfig(e, 5)}
                    renderOption={(props: any, option: any) => (
                      <li {...props} style={{ fontSize: "12px" }}>
                        {option?.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Select sections to show"
                        sx={{
                      width: "100%",
                      "& .MuiInput-underline:before": {
                        borderBottom: "none", // Remove bottom border
                      },
                      "& .MuiInput-underline:after": {
                        borderBottom: "none", // Remove active bottom border
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "12px !important",
                      },
                      ".MuiSvgIcon-root": { color: "primary.main" },
                    }}
                      />
                    )}
                    renderTags={(value: any[], getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          // key={option.id}
                          label={option?.name}
                          {...getTagProps({ index })}
                          sx={{
                            backgroundColor: "primary.light",
                            height: "26px",
                          }}
                        />
                      ))
                    }
                    sx={{ width: "100%", marginTop: "0.1rem", flex: "1" }}
                  /> */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      borderBottom: "0.1px solid rgba(224, 224, 224, 1)",
                      marginBottom: "0.2rem",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Select
                        variant="standard"
                        labelId="supplier-unlimited-label"
                        name="search_data_to_show_is_unlimited"
                        value={
                          formik.values?.seller?.professional
                            ?.search_data_to_show_is_unlimited || false
                        }
                        onChange={(e) =>
                          handleCheckboxInput(
                            e,
                            5,
                            "search_data_to_show_is_unlimited"
                          )
                        }
                        sx={{
                          width:
                            formik.values?.seller?.professional
                              ?.search_data_to_show_is_unlimited === false
                              ? "65%"
                              : "100%",
                          "& .MuiStandardInput-root": {
                            padding: "0",
                            "& .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#fff", // Initial bottom border color
                            },
                            "&:hover .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#a9a9a9", // Hover color
                            },
                            "&.Mui-focused .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#00887a", // Focus color
                            },
                          },
                        }}
                        onBlur={(e) => handleUpdateConfig(e, 5)}
                        color="primary"
                        label="Supplier Unlimited"
                      >
                        <MenuItem value={"false"}>Limited Access</MenuItem>
                        <MenuItem value={"true"}>Unlimited Access</MenuItem>
                      </Select>

                      {/* TextField for 'search_credit' */}
                      {formik.values?.seller?.professional
                        ?.search_data_to_show_is_unlimited === false && (
                        <TextField
                          type="number"
                          variant="standard"
                          name="search_credit"
                          disabled={
                            formik.values?.seller?.professional
                              ?.search_data_to_show_is_unlimited
                          }
                          value={
                            (formik.values as any)?.seller?.professional
                              ?.search_credit
                          }
                          onChange={(e) => handleChangeInput(e, 5, "", "")}
                          onBlur={(e) => handleUpdateConfig(e, 5)}
                          sx={{
                            width: "100%",
                            padding: "0",
                            "& .MuiOutlinedInput-input": {
                              padding: "0", // Ensure no padding for input
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#fff", // Light color for bottom border
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#a9a9a9", // Color on hover (optional)
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#00887a", // Color after focus (optional)
                            },
                          }}
                          inputProps={{
                            min: 0,
                            style: { textAlign: "center" },
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </TableCell>
          </TableRow>

          {/* How many Supplier page visits */}
          <TableRow>
            <TableCell
              sx={{
                width: "15vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",

                background: "#d0eddb",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  placeItems: "center",
                  textAlign: "center",
                }}
              >
                Supplier page visit
              </Box>
            </TableCell>

            <TableCell
              sx={{
                width: "15vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
              }}
            >
               <Box sx={{display:"flex", alignItems:"center",   borderBottom: "0.1px solid rgba(224, 224, 224, 1)",}}>
              {/* Autocomplete multiselect dropdown for 'supplier_data_to_show' */}
              <Autocomplete
                id="combo-box-demo"
                multiple
                options={dropDownList?.prelogin?.supplierList}
                getOptionLabel={(option: any) => option?.name}
                limitTags={1}
                disableCloseOnSelect
                value={
                  formik.values.prelogin.supplier_data_to_show?.filter(
                    (item: any) => item.selected
                  ) || []
                }
                // Show selected items
                onChange={(e, value) =>
                  handleChangeSelectInput(
                    e,
                    value,
                    1,
                    "supplier_data_to_show",
                    true
                  )
                }
                onBlur={(e) => handleUpdateConfig(e, 1)}
                renderOption={(props: any, option: any) => (
                  <li {...props} style={{ fontSize: "12px" }}>
                    {option?.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Select sections to show"
                    sx={{
                      width: "100%",
                      "& .MuiInput-underline:before": {
                        borderBottom: "none", // Remove bottom border
                      },
                      "& .MuiInput-underline:after": {
                        borderBottom: "none", // Remove active bottom border
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "12px !important",
                      },
                      ".MuiSvgIcon-root": { color: "primary.main" },
                    }}
                  />
                )}
                renderTags={(value: any[], getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      // key={option.id}
                      label={option?.name}
                      {...getTagProps({ index })}
                      sx={{
                        backgroundColor: "primary.light",
                        height: "26px",
                      }}
                    />
                  ))
                }
                sx={{ marginTop: "0.1rem", flex: "1" }}
              />
              {renderSelectAllCheckbox('prelogin', "supplier_data_to_show", 1)}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderBottom: "0.1px solid rgba(224, 224, 224, 1)",
                  marginBottom: "0.2rem",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "0.5rem",
                  }}
                >
                  <Select
                    variant="standard"
                    labelId="supplier-unlimited-label"
                    name="supplier_data_to_show_is_unlimited"
                    value={
                      formik.values?.prelogin
                        ?.supplier_data_to_show_is_unlimited || false
                    }
                    onChange={(e) =>
                      handleCheckboxInput(
                        e,
                        1,
                        "supplier_data_to_show_is_unlimited"
                      )
                    }
                    sx={{
                      width:
                        formik.values?.prelogin
                          ?.supplier_data_to_show_is_unlimited === false
                          ? "65%"
                          : "100%",
                      "& .MuiStandardInput-root": {
                        padding: "0",
                        "& .MuiStandardInput-notchedOutline": {
                          borderBottomColor: "#fff", // Initial bottom border color
                        },
                        "&:hover .MuiStandardInput-notchedOutline": {
                          borderBottomColor: "#a9a9a9", // Hover color
                        },
                        "&.Mui-focused .MuiStandardInput-notchedOutline": {
                          borderBottomColor: "#00887a", // Focus color
                        },
                      },
                    }}
                    onBlur={(e) => handleUpdateConfig(e, 1)}
                    color="primary"
                    label="Supplier Unlimited"
                  >
                    <MenuItem value={"false"}>Limited Access</MenuItem>
                    <MenuItem value={"true"}>Unlimited Access</MenuItem>
                  </Select>

                  {/* TextField for 'supplier_view_credit' */}
                  {formik.values?.prelogin
                    ?.supplier_data_to_show_is_unlimited === false && (
                    <TextField
                      type="number"
                      name="supplier_view_credit"
                      value={
                        (formik.values as any)?.prelogin?.supplier_view_credit
                      }
                      disabled={
                        formik.values?.prelogin
                          ?.supplier_data_to_show_is_unlimited
                      }
                      onChange={(e) => handleChangeInput(e, 1, "", "")}
                      onBlur={(e) => handleUpdateConfig(e, 1)}
                      variant="standard"
                      sx={{
                        width: "100%",
                        padding: "0",
                        "& .MuiOutlinedInput-input": {
                          padding: "0", // Ensure no padding for input
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "#fff", // Light color for bottom border
                        },
                        "& .MuiInput-underline:hover:before": {
                          borderBottomColor: "#a9a9a9", // Color on hover (optional)
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#00887a", // Color after focus (optional)
                        },
                      }}
                      inputProps={{ min: 0, style: { textAlign: "center" } }}
                    />
                  )}
                </Box>
              </Box>
            </TableCell>
            <TableCell
              sx={{
                width: "30vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                padding: "0",
              }}
            >
              <Box sx={{ display: "flex", padding: "0.5rem" }}>
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.35rem",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  
                  <Box sx={{display:"flex", alignItems:"center",   borderBottom: "0.1px solid rgba(224, 224, 224, 1)",width:"100%"}}>
                  {/* Autocomplete multiselect dropdown for 'supplier_data_to_show' */}
                  <Autocomplete
                    id="combo-box-demo"
                    multiple
                    options={dropDownList?.buyer?.starter?.supplierList}
                    getOptionLabel={(option: any) => option?.name}
                    limitTags={1}
                    disableCloseOnSelect
                    value={
                      formik.values.buyer.starter.supplier_data_to_show?.filter(
                        (item: any) => item?.selected
                      ) || []
                    }
                    // Show selected items
                    onChange={(e, value) =>
                      handleChangeSelectInput(
                        e,
                        value,
                        2, // Change this as per your needs
                        "supplier_data_to_show",
                        true
                      )
                    }
                    onBlur={(e) => handleUpdateConfig(e, 2)}
                    renderOption={(props: any, option: any) => (
                      <li {...props} style={{ fontSize: "12px" }}>
                        {option?.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Select sections to show"
                        sx={{
                      width: "100%",
                      "& .MuiInput-underline:before": {
                        borderBottom: "none", // Remove bottom border
                      },
                      "& .MuiInput-underline:after": {
                        borderBottom: "none", // Remove active bottom border
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "12px !important",
                      },
                      ".MuiSvgIcon-root": { color: "primary.main" },
                    }}
                      />
                    )}
                    renderTags={(value: any[], getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          // key={option.id}
                          label={option?.name}
                          {...getTagProps({ index })}
                          sx={{
                            backgroundColor: "primary.light",
                            height: "26px",
                          }}
                        />
                      ))
                    }
                    sx={{  marginTop: "0.1rem", flex: "1" }}
                  />
                    {renderSelectAllCheckbox('buyer.starter', "supplier_data_to_show", 2)}
                    </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      borderBottom: "0.1px solid rgba(224, 224, 224, 1)",
                      marginBottom: "0.2rem",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        marginTop: "0.5rem",
                      }}
                    >
                      <Select
                        variant="standard"
                        labelId="supplier-unlimited-label"
                        name="supplier_data_to_show_is_unlimited"
                        value={
                          formik.values?.buyer?.starter
                            ?.supplier_data_to_show_is_unlimited || false
                        }
                        onChange={(e) =>
                          handleCheckboxInput(
                            e,
                            2,
                            "supplier_data_to_show_is_unlimited"
                          )
                        }
                        sx={{
                          width:
                            formik.values?.buyer?.starter
                              ?.supplier_data_to_show_is_unlimited === false
                              ? "65%"
                              : "100%",
                          "& .MuiStandardInput-root": {
                            padding: "0",
                            "& .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#fff", // Initial bottom border color
                            },
                            "&:hover .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#a9a9a9", // Hover color
                            },
                            "&.Mui-focused .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#00887a", // Focus color
                            },
                          },
                        }}
                        onBlur={(e) => handleUpdateConfig(e, 2)}
                        color="primary"
                        label="Supplier Unlimited"
                      >
                        <MenuItem value={"false"}>Limited Access</MenuItem>
                        <MenuItem value={"true"}>Unlimited Access</MenuItem>
                      </Select>

                      {/* TextField for 'supplier_view_credit' */}
                      {formik.values?.buyer?.starter
                        ?.supplier_data_to_show_is_unlimited === false && (
                        <TextField
                          type="number"
                          variant="standard"
                          name="supplier_view_credit"
                          value={
                            (formik.values as any)?.buyer?.starter
                              ?.supplier_view_credit
                          }
                          disabled={
                            formik.values?.buyer?.starter
                              ?.supplier_data_to_show_is_unlimited
                          }
                          onChange={(e) => handleChangeInput(e, 2, "", "")}
                          onBlur={(e) => handleUpdateConfig(e, 2)}
                          sx={{
                            width: "100%",
                            padding: "0",
                            "& .MuiOutlinedInput-input": {
                              padding: "0", // Ensure no padding for input
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#fff", // Light color for bottom border
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#a9a9a9", // Color on hover (optional)
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#00887a", // Color after focus (optional)
                            },
                          }}
                          inputProps={{
                            min: 0,
                            style: { textAlign: "center" },
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.35rem",
                  }}
                >
                  {/* Autocomplete multiselect dropdown for 'supplier_data_to_show' */}
                  {/* <Autocomplete
                    id="combo-box-demo"
                    multiple
                    options={dropDownList?.buyer?.professional?.supplierList}
                    getOptionLabel={(option: any) => option?.name}
                    limitTags={1}
                    disableCloseOnSelect
                    value={
                      formik.values.buyer.professional.supplier_data_to_show?.filter(
                        (item: any) => item?.selected
                      ) || []
                    }
                    // Show selected items
                    onChange={(e, value) =>
                      handleChangeSelectInput(
                        e,
                        value,
                        3, // Change this as per your needs
                        "supplier_data_to_show",
                        true
                      )
                    }
                    onBlur={(e) => handleUpdateConfig(e, 3)}
                    renderOption={(props: any, option: any) => (
                      <li {...props} style={{ fontSize: "12px" }}>
                        {option?.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Select sections to show"
                        sx={{
                      width: "100%",
                      "& .MuiInput-underline:before": {
                        borderBottom: "none", // Remove bottom border
                      },
                      "& .MuiInput-underline:after": {
                        borderBottom: "none", // Remove active bottom border
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "12px !important",
                      },
                      ".MuiSvgIcon-root": { color: "primary.main" },
                    }}
                      />
                    )}
                    renderTags={(value: any[], getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          // key={option.id}
                          label={option?.name}
                          {...getTagProps({ index })}
                          sx={{
                            backgroundColor: "primary.light",
                            height: "26px",
                          }}
                        />
                      ))
                    }
                    sx={{ width: "100%", marginTop: "0.1rem", flex: "1" }}
                  /> */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      borderBottom: "0.1px solid rgba(224, 224, 224, 1)",
                      marginBottom: "0.2rem",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        marginTop: "0.5rem",
                      }}
                    >
                      <Select
                        variant="standard"
                        labelId="supplier-unlimited-label"
                        name="supplier_data_to_show_is_unlimited"
                        value={
                          formik.values?.buyer?.professional
                            ?.supplier_data_to_show_is_unlimited || false
                        }
                        onChange={(e) =>
                          handleCheckboxInput(
                            e,
                            3,
                            "supplier_data_to_show_is_unlimited"
                          )
                        }
                        sx={{
                          width:
                            formik.values?.buyer?.professional
                              ?.supplier_data_to_show_is_unlimited === false
                              ? "65%"
                              : "100%",
                          "& .MuiStandardInput-root": {
                            padding: "0",
                            "& .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#fff", // Initial bottom border color
                            },
                            "&:hover .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#a9a9a9", // Hover color
                            },
                            "&.Mui-focused .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#00887a", // Focus color
                            },
                          },
                        }}
                        onBlur={(e) => handleUpdateConfig(e, 3)}
                        color="primary"
                        label="Supplier Unlimited"
                      >
                        <MenuItem value={"false"}>Limited Access</MenuItem>
                        <MenuItem value={"true"}>Unlimited Access</MenuItem>
                      </Select>

                      {/* TextField for 'supplier_view_credit' */}
                      {formik.values?.buyer?.professional
                        ?.supplier_data_to_show_is_unlimited === false && (
                        <TextField
                          type="number"
                          variant="standard"
                          name="supplier_view_credit"
                          value={
                            (formik.values as any)?.buyer?.professional
                              ?.supplier_view_credit
                          }
                          disabled={
                            formik.values?.buyer?.professional
                              ?.supplier_data_to_show_is_unlimited
                          }
                          onChange={(e) => handleChangeInput(e, 3, "", "")}
                          onBlur={(e) => handleUpdateConfig(e, 3)}
                          sx={{
                            width: "100%",
                            padding: "0",
                            "& .MuiOutlinedInput-input": {
                              padding: "0", // Ensure no padding for input
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#fff", // Light color for bottom border
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#a9a9a9", // Color on hover (optional)
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#00887a", // Color after focus (optional)
                            },
                          }}
                          inputProps={{
                            min: 0,
                            style: { textAlign: "center" },
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </TableCell>
            <TableCell
              sx={{
                width: "30vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                padding: "0",
              }}
            >
              <Box sx={{ display: "flex", padding: "0.5rem" }}>
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.35rem",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  <Box sx={{display:"flex", alignItems:"center",   borderBottom: "0.1px solid rgba(224, 224, 224, 1)",width:"100%"}}>
                  {/* Autocomplete multiselect dropdown for 'supplier_data_to_show' */}
                  <Autocomplete
                    id="combo-box-demo"
                    multiple
                    options={dropDownList?.seller?.starter?.supplierList}
                    getOptionLabel={(option: any) => option?.name}
                    limitTags={1}
                    disableCloseOnSelect
                    value={
                      formik.values.seller?.starter.supplier_data_to_show?.filter(
                        (item: any) => item?.selected
                      ) || []
                    }
                    // Show selected items
                    onChange={(e, value) =>
                      handleChangeSelectInput(
                        e,
                        value,
                        4, // Change this as per your needs
                        "supplier_data_to_show",
                        true
                      )
                    }
                    onBlur={(e) => handleUpdateConfig(e, 4)}
                    renderOption={(props: any, option: any) => (
                      <li {...props} style={{ fontSize: "12px" }}>
                        {option?.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Select sections to show"
                        sx={{
                      width: "100%",
                      "& .MuiInput-underline:before": {
                        borderBottom: "none", // Remove bottom border
                      },
                      "& .MuiInput-underline:after": {
                        borderBottom: "none", // Remove active bottom border
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "12px !important",
                      },
                      ".MuiSvgIcon-root": { color: "primary.main" },
                    }}
                      />
                    )}
                    renderTags={(value: any[], getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          // key={option.id}
                          label={option?.name}
                          {...getTagProps({ index })}
                          sx={{
                            backgroundColor: "primary.light",
                            height: "26px",
                          }}
                        />
                      ))
                    }
                    sx={{ width: "100%", marginTop: "0.1rem", flex: "1" }}
                  />
                   {renderSelectAllCheckbox('seller.starter', "supplier_data_to_show", 4)}
                   </Box>


                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      borderBottom: "0.1px solid rgba(224, 224, 224, 1)",
                      marginBottom: "0.2rem",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "0.5rem",
                        width: "100%",
                      }}
                    >
                      <Select
                        variant="standard"
                        labelId="supplier-unlimited-label"
                        name="supplier_data_to_show_is_unlimited"
                        value={
                          formik.values?.seller?.starter
                            ?.supplier_data_to_show_is_unlimited || false
                        }
                        onChange={(e) =>
                          handleCheckboxInput(
                            e,
                            4,
                            "supplier_data_to_show_is_unlimited"
                          )
                        }
                        sx={{
                          width:
                            formik.values?.seller?.starter
                              ?.supplier_data_to_show_is_unlimited === false
                              ? "65%"
                              : "100%",
                          "& .MuiStandardInput-root": {
                            padding: "0",
                            "& .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#fff", // Initial bottom border color
                            },
                            "&:hover .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#a9a9a9", // Hover color
                            },
                            "&.Mui-focused .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#00887a", // Focus color
                            },
                          },
                        }}
                        onBlur={(e) => handleUpdateConfig(e, 4)}
                        color="primary"
                        label="Supplier Unlimited"
                      >
                        <MenuItem value={"false"}>Limited Access</MenuItem>
                        <MenuItem value={"true"}>Unlimited Access</MenuItem>
                      </Select>

                      {/* TextField for 'supplier_view_credit' */}
                      {formik.values?.seller?.starter
                        ?.supplier_data_to_show_is_unlimited === false && (
                        <TextField
                          type="number"
                          variant="standard"
                          name="supplier_view_credit"
                          value={
                            (formik.values as any)?.seller?.starter
                              ?.supplier_view_credit
                          }
                          disabled={
                            formik.values?.seller?.starter
                              ?.supplier_data_to_show_is_unlimited
                          }
                          onChange={(e) => handleChangeInput(e, 4, "", "")}
                          onBlur={(e) => handleUpdateConfig(e, 4)}
                          sx={{
                            width: "100%",
                            padding: "0",
                            "& .MuiOutlinedInput-input": {
                              padding: "0", // Ensure no padding for input
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#fff", // Light color for bottom border
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#a9a9a9", // Color on hover (optional)
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#00887a", // Color after focus (optional)
                            },
                          }}
                          inputProps={{
                            min: 0,
                            style: { textAlign: "center" },
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.35rem",
                  }}
                >
                  {/* Autocomplete multiselect dropdown for 'supplier_data_to_show' */}
                  {/* <Autocomplete
                    id="combo-box-demo"
                    multiple
                    options={dropDownList?.seller?.professional?.supplierList}
                    getOptionLabel={(option: any) => option?.name}
                    limitTags={1}
                    disableCloseOnSelect
                    value={
                      formik.values.seller.professional.supplier_data_to_show?.filter(
                        (item: any) => item?.selected
                      ) || []
                    }
                    // Show selected items
                    onChange={(e, value) =>
                      handleChangeSelectInput(
                        e,
                        value,
                        5, // Change this as per your needs
                        "supplier_data_to_show",
                        true
                      )
                    }
                    onBlur={(e) => handleUpdateConfig(e, 5)}
                    renderOption={(props: any, option: any) => (
                      <li {...props} style={{ fontSize: "12px" }}>
                        {option?.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Select sections to show"
                        sx={{
                      width: "100%",
                      "& .MuiInput-underline:before": {
                        borderBottom: "none", // Remove bottom border
                      },
                      "& .MuiInput-underline:after": {
                        borderBottom: "none", // Remove active bottom border
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "12px !important",
                      },
                      ".MuiSvgIcon-root": { color: "primary.main" },
                    }}
                      />
                    )}
                    renderTags={(value: any[], getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          // key={option.id}
                          label={option?.name}
                          {...getTagProps({ index })}
                          sx={{
                            backgroundColor: "primary.light",
                            height: "26px",
                          }}
                        />
                      ))
                    }
                    sx={{ width: "100%", marginTop: "0.1rem", flex: "1" }}
                  /> */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      borderBottom: "0.1px solid rgba(224, 224, 224, 1)",
                      marginBottom: "0.2rem",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        marginTop: "0.5rem",
                      }}
                    >
                      <Select
                        variant="standard"
                        labelId="supplier-unlimited-label"
                        name="supplier_data_to_show_is_unlimited"
                        value={
                          formik.values?.seller?.professional
                            ?.supplier_data_to_show_is_unlimited || false
                        }
                        onChange={(e) =>
                          handleCheckboxInput(
                            e,
                            5,
                            "supplier_data_to_show_is_unlimited"
                          )
                        }
                        sx={{
                          width:
                            formik.values?.seller?.professional
                              ?.supplier_data_to_show_is_unlimited === false
                              ? "65%"
                              : "100%",
                          "& .MuiStandardInput-root": {
                            padding: "0",
                            "& .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#fff", // Initial bottom border color
                            },
                            "&:hover .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#a9a9a9", // Hover color
                            },
                            "&.Mui-focused .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#00887a", // Focus color
                            },
                          },
                        }}
                        onBlur={(e) => handleUpdateConfig(e, 5)}
                        color="primary"
                        label="Supplier Unlimited"
                      >
                        <MenuItem value={"false"}>Limited Access</MenuItem>
                        <MenuItem value={"true"}>Unlimited Access</MenuItem>
                      </Select>
                      {/* TextField for 'supplier_view_credit' */}
                      {formik.values?.seller?.professional
                        ?.supplier_data_to_show_is_unlimited === false && (
                        <TextField
                          type="number"
                          variant="standard"
                          name="supplier_view_credit"
                          value={
                            (formik.values as any)?.seller?.professional
                              ?.supplier_view_credit
                          }
                          disabled={
                            formik.values?.seller?.professional
                              ?.supplier_data_to_show_is_unlimited
                          }
                          onChange={(e) => handleChangeInput(e, 5, "", "")}
                          onBlur={(e) => handleUpdateConfig(e, 5)}
                          sx={{
                            width: "100%",
                            padding: "0",
                            "& .MuiOutlinedInput-input": {
                              padding: "0", // Ensure no padding for input
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#fff", // Light color for bottom border
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#a9a9a9", // Color on hover (optional)
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#00887a", // Color after focus (optional)
                            },
                          }}
                          inputProps={{
                            min: 0,
                            style: { textAlign: "center" },
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </TableCell>
          </TableRow>

          {/* How many Part page visits */}
          <TableRow>
            <TableCell
              sx={{
                width: "15vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",

                background: "#d0eddb",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  placeItems: "center",
                  textAlign: "center",
                }}
              >
                Part page visit
              </Box>
            </TableCell>

            <TableCell
              sx={{
                width: "15vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
              }}
            >
              <Box sx={{display:"flex", alignItems:"center",   borderBottom: "0.1px solid rgba(224, 224, 224, 1)",}}>
              {/* Autocomplete multiselect dropdown for 'page_data_to_show_is_unlimited' */}
              <Autocomplete
                id="combo-box-demo"
                multiple
                options={dropDownList?.prelogin?.partList}
                getOptionLabel={(option: any) => option?.name}
                limitTags={1}
                disableCloseOnSelect
                value={
                  formik.values.prelogin.page_data_to_show?.filter(
                    (item: any) => item.selected
                  ) || []
                }
                // Show selected items
                onChange={(e, value) =>
                  handleChangeSelectInput(
                    e,
                    value,
                    1,
                    "page_data_to_show",
                    true
                  )
                }
                onBlur={(e) => handleUpdateConfig(e, 1)}
                renderOption={(props: any, option: any) => (
                  <li {...props} style={{ fontSize: "12px" }}>
                    {option?.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Select sections to show"
                    sx={{
                      width: "100%",
                      "& .MuiInput-underline:before": {
                        borderBottom: "none", // Remove bottom border
                      },
                      "& .MuiInput-underline:after": {
                        borderBottom: "none", // Remove active bottom border
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "12px !important",
                      },
                      ".MuiSvgIcon-root": { color: "primary.main" },
                    }}
                  />
                )}
                renderTags={(value: any[], getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      // key={option.id}
                      label={option?.name}
                      {...getTagProps({ index })}
                      sx={{
                        backgroundColor: "primary.light",
                        height: "26px",
                      }}
                    />
                  ))
                }
                sx={{ marginTop: "0.1rem", flex: "1" }}
              />
              {renderSelectAllCheckbox('prelogin', "page_data_to_show", 1)}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderBottom: "0.1px solid rgba(224, 224, 224, 1)",
                  marginBottom: "0.2rem",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "0.5rem",
                    width: "100%",
                  }}
                >
                  <Select
                    variant="standard"
                    labelId="supplier-unlimited-label"
                    name="page_data_to_show_is_unlimited"
                    value={
                      formik.values?.prelogin?.page_data_to_show_is_unlimited ||
                      false
                    }
                    onChange={(e) =>
                      handleCheckboxInput(
                        e,
                        1,
                        "page_data_to_show_is_unlimited"
                      )
                    }
                    sx={{
                      width:
                        formik.values?.prelogin
                          ?.page_data_to_show_is_unlimited === false
                          ? "65%"
                          : "100%",
                      "& .MuiStandardInput-root": {
                        padding: "0",
                        "& .MuiStandardInput-notchedOutline": {
                          borderBottomColor: "#fff", // Initial bottom border color
                        },
                        "&:hover .MuiStandardInput-notchedOutline": {
                          borderBottomColor: "#a9a9a9", // Hover color
                        },
                        "&.Mui-focused .MuiStandardInput-notchedOutline": {
                          borderBottomColor: "#00887a", // Focus color
                        },
                      },
                    }}
                    onBlur={(e) => handleUpdateConfig(e, 1)}
                    color="primary"
                    label="Supplier Unlimited"
                  >
                    <MenuItem value={"false"}>Limited Access</MenuItem>
                    <MenuItem value={"true"}>Unlimited Access</MenuItem>
                  </Select>

                  {/* TextField for 'part_view_credit' */}
                  {formik.values?.prelogin?.page_data_to_show_is_unlimited ===
                    false && (
                    <TextField
                      type="number"
                      name="part_view_credit"
                      value={(formik.values as any)?.prelogin?.part_view_credit}
                      disabled={
                        formik.values?.prelogin?.page_data_to_show_is_unlimited
                      }
                      onChange={(e) => handleChangeInput(e, 1, "", "")}
                      onBlur={(e) => handleUpdateConfig(e, 1)}
                      variant="standard"
                      sx={{
                        width: "100%",
                        padding: "0",
                        "& .MuiOutlinedInput-input": {
                          padding: "0", // Ensure no padding for input
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "#fff", // Light color for bottom border
                        },
                        "& .MuiInput-underline:hover:before": {
                          borderBottomColor: "#a9a9a9", // Color on hover (optional)
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#00887a", // Color after focus (optional)
                        },
                      }}
                      inputProps={{ min: 0, style: { textAlign: "center" } }}
                    />
                  )}
                </Box>
              </Box>
            </TableCell>
            <TableCell
              sx={{
                width: "30vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                padding: "0",
              }}
            >
              <Box sx={{ display: "flex", padding: "0.5rem" }}>
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.35rem",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                   <Box sx={{display:"flex", alignItems:"center",   borderBottom: "0.1px solid rgba(224, 224, 224, 1)",flexWrap:"wrap", overflow:"hidden"}}>
                  {/* Autocomplete multiselect dropdown for 'page_data_to_show' */}
                  <Autocomplete
                    id="combo-box-demo"
                    multiple
                    options={dropDownList?.buyer?.starter?.partList}
                    getOptionLabel={(option: any) => option?.name}
                    limitTags={1}
                    disableCloseOnSelect
                    value={
                      formik.values.buyer.starter.page_data_to_show?.filter(
                        (item: any) => item?.selected
                      ) || []
                    }
                    // Show selected items
                    onChange={(e, value) =>
                      handleChangeSelectInput(
                        e,
                        value,
                        2, // Change this as per your needs
                        "page_data_to_show",
                        true
                      )
                    }
                    onBlur={(e) => handleUpdateConfig(e, 2)}
                    renderOption={(props: any, option: any) => (
                      <li {...props} style={{ fontSize: "12px" }}>
                        {option?.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Select sections to show"
                        sx={{
                      width: "100%",
                      "& .MuiInput-underline:before": {
                        borderBottom: "none", // Remove bottom border
                      },
                      "& .MuiInput-underline:after": {
                        borderBottom: "none", // Remove active bottom border
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "12px !important",
                      },
                      ".MuiSvgIcon-root": { color: "primary.main" },
                    }}
                      />
                    )}
                    renderTags={(value: any[], getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          // key={option.id}
                          label={option?.name}
                          {...getTagProps({ index })}
                          sx={{
                            backgroundColor: "primary.light",
                            height: "26px",
                          }}
                        />
                      ))
                    }
                    sx={{ marginTop: "0.1rem", flex: "1" }}
                  />
                   {renderSelectAllCheckbox('buyer.starter', "page_data_to_show", 2)}
                   </Box>


                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      borderBottom: "0.1px solid rgba(224, 224, 224, 1)",
                      marginBottom: "0.2rem",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "0.5rem",
                        width: "100%",
                      }}
                    >
                      <Select
                        variant="standard"
                        labelId="supplier-unlimited-label"
                        name="page_data_to_show_is_unlimited"
                        value={
                          formik.values?.buyer?.starter
                            ?.page_data_to_show_is_unlimited || false
                        }
                        onChange={(e) =>
                          handleCheckboxInput(
                            e,
                            2,
                            "page_data_to_show_is_unlimited"
                          )
                        }
                        sx={{
                          width:
                            formik.values?.buyer?.starter
                              ?.page_data_to_show_is_unlimited === false
                              ? "65%"
                              : "100%",
                          "& .MuiStandardInput-root": {
                            padding: "0",
                            "& .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#fff", // Initial bottom border color
                            },
                            "&:hover .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#a9a9a9", // Hover color
                            },
                            "&.Mui-focused .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#00887a", // Focus color
                            },
                          },
                        }}
                        onBlur={(e) => handleUpdateConfig(e, 2)}
                        color="primary"
                        label="Supplier Unlimited"
                      >
                        <MenuItem value={"false"}>Limited Access</MenuItem>
                        <MenuItem value={"true"}>Unlimited Access</MenuItem>
                      </Select>

                      {/* TextField for 'part_view_credit' */}
                      {formik.values?.buyer?.starter
                        ?.page_data_to_show_is_unlimited === false && (
                        <TextField
                          type="number"
                          variant="standard"
                          name="part_view_credit"
                          value={
                            (formik.values as any)?.buyer?.starter
                              ?.part_view_credit
                          }
                          disabled={
                            formik.values?.buyer?.starter
                              ?.page_data_to_show_is_unlimited
                          }
                          onChange={(e) => handleChangeInput(e, 2, "", "")}
                          onBlur={(e) => handleUpdateConfig(e, 2)}
                          sx={{
                            width: "100%",
                            padding: "0",
                            "& .MuiOutlinedInput-input": {
                              padding: "0", // Ensure no padding for input
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#fff", // Light color for bottom border
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#a9a9a9", // Color on hover (optional)
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#00887a", // Color after focus (optional)
                            },
                          }}
                          inputProps={{
                            min: 0,
                            style: { textAlign: "center" },
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.35rem",
                  }}
                >
                  {/* Autocomplete multiselect dropdown for 'page_data_to_show' */}
                  {/* <Autocomplete
                    id="combo-box-demo"
                    multiple
                    options={dropDownList?.buyer?.professional?.partList}
                    getOptionLabel={(option: any) => option?.name}
                    limitTags={1}
                    disableCloseOnSelect
                    value={
                      formik.values.buyer.professional.page_data_to_show?.filter(
                        (item: any) => item?.selected
                      ) || []
                    }
                    // Show selected items
                    onChange={(e, value) =>
                      handleChangeSelectInput(
                        e,
                        value,
                        3, // Change this as per your needs
                        "page_data_to_show",
                        true
                      )
                    }
                    onBlur={(e) => handleUpdateConfig(e, 3)}
                    renderOption={(props: any, option: any) => (
                      <li {...props} style={{ fontSize: "12px" }}>
                        {option?.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Select sections to show"
                        sx={{
                      width: "100%",
                      "& .MuiInput-underline:before": {
                        borderBottom: "none", // Remove bottom border
                      },
                      "& .MuiInput-underline:after": {
                        borderBottom: "none", // Remove active bottom border
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "12px !important",
                      },
                      ".MuiSvgIcon-root": { color: "primary.main" },
                    }}
                      />
                    )}
                    renderTags={(value: any[], getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          // key={option.id}
                          label={option?.name}
                          {...getTagProps({ index })}
                          sx={{
                            backgroundColor: "primary.light",
                            height: "26px",
                          }}
                        />
                      ))
                    }
                    sx={{ width: "100%", marginTop: "0.1rem", flex: "1" }}
                  /> */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      borderBottom: "0.1px solid rgba(224, 224, 224, 1)",
                      marginBottom: "0.2rem",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        marginTop: "0.5rem",
                      }}
                    >
                      <Select
                        variant="standard"
                        labelId="supplier-unlimited-label"
                        name="page_data_to_show_is_unlimited"
                        value={
                          formik.values?.buyer?.professional
                            ?.page_data_to_show_is_unlimited || false
                        }
                        onChange={(e) =>
                          handleCheckboxInput(
                            e,
                            3,
                            "page_data_to_show_is_unlimited"
                          )
                        }
                        sx={{
                          width:
                            formik.values?.buyer?.professional
                              ?.page_data_to_show_is_unlimited === false
                              ? "65%"
                              : "100%",
                          "& .MuiStandardInput-root": {
                            padding: "0",
                            "& .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#fff", // Initial bottom border color
                            },
                            "&:hover .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#a9a9a9", // Hover color
                            },
                            "&.Mui-focused .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#00887a", // Focus color
                            },
                          },
                        }}
                        onBlur={(e) => handleUpdateConfig(e, 3)}
                        color="primary"
                        label="Supplier Unlimited"
                      >
                        <MenuItem value={"false"}>Limited Access</MenuItem>
                        <MenuItem value={"true"}>Unlimited Access</MenuItem>
                      </Select>

                      {/* TextField for 'part_view_credit' */}
                      {formik.values?.buyer?.professional
                        ?.page_data_to_show_is_unlimited === false && (
                        <TextField
                          type="number"
                          variant="standard"
                          name="part_view_credit"
                          value={
                            (formik.values as any)?.buyer?.professional
                              ?.part_view_credit
                          }
                          disabled={
                            formik.values?.buyer?.professional
                              ?.page_data_to_show_is_unlimited
                          }
                          onChange={(e) => handleChangeInput(e, 3, "", "")}
                          onBlur={(e) => handleUpdateConfig(e, 3)}
                          sx={{
                            width: "100%",
                            padding: "0",
                            "& .MuiOutlinedInput-input": {
                              padding: "0", // Ensure no padding for input
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#fff", // Light color for bottom border
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#a9a9a9", // Color on hover (optional)
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#00887a", // Color after focus (optional)
                            },
                          }}
                          inputProps={{
                            min: 0,
                            style: { textAlign: "center" },
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </TableCell>
            <TableCell
              sx={{
                width: "30vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                padding: "0",
              }}
            >
              <Box sx={{ display: "flex", padding: "0.5rem" }}>
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.35rem",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                   <Box sx={{display:"flex", alignItems:"center",   borderBottom: "0.1px solid rgba(224, 224, 224, 1)",}}>

                  {/* Autocomplete multiselect dropdown for 'page_data_to_show' */}
                  <Autocomplete
                    id="combo-box-demo"
                    multiple
                    options={dropDownList?.seller?.starter?.partList}
                    getOptionLabel={(option: any) => option?.name}
                    limitTags={1}
                    disableCloseOnSelect
                    value={
                      formik.values.seller?.starter.page_data_to_show?.filter(
                        (item: any) => item?.selected
                      ) || []
                    }
                    // Show selected items
                    onChange={(e, value) =>
                      handleChangeSelectInput(
                        e,
                        value,
                        4, // Change this as per your needs
                        "page_data_to_show",
                        true
                      )
                    }
                    onBlur={(e) => handleUpdateConfig(e, 4)}
                    renderOption={(props: any, option: any) => (
                      <li {...props} style={{ fontSize: "12px" }}>
                        {option?.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Select sections to show"
                        sx={{
                      width: "100%",
                      "& .MuiInput-underline:before": {
                        borderBottom: "none", // Remove bottom border
                      },
                      "& .MuiInput-underline:after": {
                        borderBottom: "none", // Remove active bottom border
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "12px !important",
                      },
                      ".MuiSvgIcon-root": { color: "primary.main" },
                    }}
                      />
                    )}
                    renderTags={(value: any[], getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          // key={option.id}
                          label={option?.name}
                          {...getTagProps({ index })}
                          sx={{
                            backgroundColor: "primary.light",
                            height: "26px",
                          }}
                        />
                      ))
                    }
                    sx={{  marginTop: "0.1rem", flex: "1" }}
                  />
                   {renderSelectAllCheckbox('seller.starter', "page_data_to_show", 4)}

                   </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      borderBottom: "0.1px solid rgba(224, 224, 224, 1)",
                      marginBottom: "0.2rem",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        marginTop: "0.5rem",
                      }}
                    >
                      <Select
                        variant="standard"
                        labelId="supplier-unlimited-label"
                        name="page_data_to_show_is_unlimited"
                        value={
                          formik.values?.seller?.starter
                            ?.page_data_to_show_is_unlimited || false
                        }
                        onChange={(e) =>
                          handleCheckboxInput(
                            e,
                            4,
                            "page_data_to_show_is_unlimited"
                          )
                        }
                        sx={{
                          width:
                            formik.values?.seller?.starter
                              ?.page_data_to_show_is_unlimited === false
                              ? "65%"
                              : "100%",
                          "& .MuiStandardInput-root": {
                            padding: "0",
                            "& .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#fff", // Initial bottom border color
                            },
                            "&:hover .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#a9a9a9", // Hover color
                            },
                            "&.Mui-focused .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#00887a", // Focus color
                            },
                          },
                        }}
                        onBlur={(e) => handleUpdateConfig(e, 4)}
                        color="primary"
                        label="Supplier Unlimited"
                      >
                        <MenuItem value={"false"}>Limited Access</MenuItem>
                        <MenuItem value={"true"}>Unlimited Access</MenuItem>
                      </Select>

                      {/* TextField for 'part_view_credit' */}
                      {formik.values?.seller?.starter
                        ?.page_data_to_show_is_unlimited === false && (
                        <TextField
                          type="number"
                          variant="standard"
                          name="part_view_credit"
                          value={
                            (formik.values as any)?.seller?.starter
                              ?.part_view_credit
                          }
                          disabled={
                            formik.values?.seller?.starter
                              ?.page_data_to_show_is_unlimited
                          }
                          onChange={(e) => handleChangeInput(e, 4, "", "")}
                          onBlur={(e) => handleUpdateConfig(e, 4)}
                          sx={{
                            width: "100%",
                            padding: "0",
                            "& .MuiOutlinedInput-input": {
                              padding: "0", // Ensure no padding for input
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#fff", // Light color for bottom border
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#a9a9a9", // Color on hover (optional)
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#00887a", // Color after focus (optional)
                            },
                          }}
                          inputProps={{
                            min: 0,
                            style: { textAlign: "center" },
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.35rem",
                  }}
                >
                  {/* Autocomplete multiselect dropdown for 'page_data_to_show' */}
                  {/* <Autocomplete
                    id="combo-box-demo"
                    multiple
                    options={dropDownList?.seller?.professional?.partList}
                    getOptionLabel={(option: any) => option?.name}
                    limitTags={1}
                    disableCloseOnSelect
                    value={
                      formik.values.seller.professional.page_data_to_show?.filter(
                        (item: any) => item?.selected
                      ) || []
                    }
                    // Show selected items
                    onChange={(e, value) =>
                      handleChangeSelectInput(
                        e,
                        value,
                        5, // Change this as per your needs
                        "page_data_to_show",
                        true
                      )
                    }
                    onBlur={(e) => handleUpdateConfig(e, 5)}
                    renderOption={(props: any, option: any) => (
                      <li {...props} style={{ fontSize: "12px" }}>
                        {option?.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Select sections to show"
                        sx={{
                      width: "100%",
                      "& .MuiInput-underline:before": {
                        borderBottom: "none", // Remove bottom border
                      },
                      "& .MuiInput-underline:after": {
                        borderBottom: "none", // Remove active bottom border
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "12px !important",
                      },
                      ".MuiSvgIcon-root": { color: "primary.main" },
                    }}
                      />
                    )}
                    renderTags={(value: any[], getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          // key={option.id}
                          label={option?.name}
                          {...getTagProps({ index })}
                          sx={{
                            backgroundColor: "primary.light",
                            height: "26px",
                          }}
                        />
                      ))
                    }
                    sx={{ width: "100%", marginTop: "0.1rem", flex: "1" }}
                  /> */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      borderBottom: "0.1px solid rgba(224, 224, 224, 1)",
                      marginBottom: "0.2rem",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "0.5rem",
                        width: "100%",
                      }}
                    >
                      <Select
                        variant="standard"
                        labelId="supplier-unlimited-label"
                        name="page_data_to_show_is_unlimited"
                        value={
                          formik.values?.seller?.professional
                            ?.page_data_to_show_is_unlimited || false
                        }
                        onChange={(e) =>
                          handleCheckboxInput(
                            e,
                            5,
                            "page_data_to_show_is_unlimited"
                          )
                        }
                        sx={{
                          width:
                            formik.values?.seller?.professional
                              ?.page_data_to_show_is_unlimited === false
                              ? "65%"
                              : "100%",
                          "& .MuiStandardInput-root": {
                            padding: "0",
                            "& .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#fff", // Initial bottom border color
                            },
                            "&:hover .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#a9a9a9", // Hover color
                            },
                            "&.Mui-focused .MuiStandardInput-notchedOutline": {
                              borderBottomColor: "#00887a", // Focus color
                            },
                          },
                        }}
                        onBlur={(e) => handleUpdateConfig(e, 5)}
                        color="primary"
                        label="Supplier Unlimited"
                      >
                        <MenuItem value={"false"}>Limited Access</MenuItem>
                        <MenuItem value={"true"}>Unlimited Access</MenuItem>
                      </Select>

                      {/* TextField for 'part_view_credit' */}
                      {formik.values?.seller?.professional
                        ?.page_data_to_show_is_unlimited === false && (
                        <TextField
                          type="number"
                          variant="standard"
                          name="part_view_credit"
                          value={
                            (formik.values as any)?.seller?.professional
                              ?.part_view_credit
                          }
                          disabled={
                            formik.values?.seller?.professional
                              ?.page_data_to_show_is_unlimited
                          }
                          onChange={(e) => handleChangeInput(e, 5, "", "")}
                          onBlur={(e) => handleUpdateConfig(e, 5)}
                          sx={{
                            width: "100%",
                            padding: "0",
                            "& .MuiOutlinedInput-input": {
                              padding: "0", // Ensure no padding for input
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#fff", // Light color for bottom border
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#a9a9a9", // Color on hover (optional)
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#00887a", // Color after focus (optional)
                            },
                          }}
                          inputProps={{
                            min: 0,
                            style: { textAlign: "center" },
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </TableCell>
          </TableRow>

          {/* Show Seller Contact Details */}
          <TableRow>
            <TableCell
              sx={{
                width: "15vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",

                background: "#d0eddb",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  placeItems: "center",
                  textAlign: "center",
                }}
              >
                Seller Contact Details
              </Box>
            </TableCell>
            <TableCell
              sx={{
                width: "15vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
              }}
            >
              <Box sx={{ display: "grid", placeItems: "center" }}>
                <TextField
                  type="number"
                  variant="standard"
                  name="seller_contact_view_credit"
                  value={
                    (formik.values as any)?.prelogin?.seller_contact_view_credit
                  }
                  onChange={(e) => handleChangeInput(e, 1, "", "")}
                  onBlur={(e) => handleUpdateConfig(e, 1)}
                  sx={{
                    padding: "0",
                    "& .MuiOutlinedInput-input": {
                      padding: "0", // Ensure other necessary paddings are applied
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#d3d3d3", // Light color for bottom border
                    },
                    "& .MuiInput-underline:hover:before": {
                      borderBottomColor: "#a9a9a9", // Color on hover (optional)
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#00887a", // Color after focus (optional)
                    },
                  }}
                  style={{ padding: "0" }}
                  inputProps={{ min: 0, style: { textAlign: "center" } }} // Ensure input cannot go below 0
                />
              </Box>
            </TableCell>
            <TableCell
              sx={{
                width: "30vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                padding: "0",
              }}
            >
              <Box sx={{ display: "flex", padding: "0.5rem" }}>
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.35rem",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  <TextField
                    type="number"
                    name="seller_contact_view_credit"
                    value={
                      (formik.values as any)?.buyer?.starter
                        ?.seller_contact_view_credit
                    }
                    onChange={(e) => handleChangeInput(e, 2, "", "")}
                    onBlur={(e) => handleUpdateConfig(e, 2)}
                    variant="standard"
                    sx={{
                      padding: "0",
                      "& .MuiOutlinedInput-input": {
                        padding: "0", // Ensure other necessary paddings are applied
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#d3d3d3", // Light color for bottom border
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "#a9a9a9", // Color on hover (optional)
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#00887a", // Color after focus (optional)
                      },
                    }}
                    style={{ padding: "0" }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }} // Ensure input cannot go below 0
                  />
                </Box>
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.25rem",
                  }}
                >
                  <TextField
                    type="number"
                    variant="standard"
                    name="seller_contact_view_per_day"
                    value={
                      (formik.values as any)?.buyer?.professional
                        ?.seller_contact_view_per_day
                    }
                    onChange={(e) => handleChangeInput(e, 3, "", "")}
                    onBlur={(e) => handleUpdateConfig(e, 3)}
                    sx={{
                      padding: "0",
                      "& .MuiOutlinedInput-input": {
                        padding: "0", // Ensure other necessary paddings are applied
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#d3d3d3", // Light color for bottom border
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "#a9a9a9", // Color on hover (optional)
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#00887a", // Color after focus (optional)
                      },
                    }}
                    style={{ padding: "0" }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }} // Ensure input cannot go below 0
                  />
                  <span
                    style={{
                      textAlign: "center",
                      marginTop: "5px",
                      color: "grey",
                    }}
                  >
                    per day
                  </span>
                </Box>
              </Box>
            </TableCell>
            <TableCell
              sx={{
                width: "30vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                padding: "0",
              }}
            >
              <Box sx={{ display: "flex", padding: "0.5rem" }}>
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.25rem",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  <TextField
                    type="number"
                    variant="standard"
                    name="seller_contact_view_credit"
                    value={
                      (formik.values as any)?.seller?.starter
                        ?.seller_contact_view_credit
                    }
                    onChange={(e) => handleChangeInput(e, 4, "", "")}
                    onBlur={(e) => handleUpdateConfig(e, 4)}
                    sx={{
                      padding: "0",
                      "& .MuiOutlinedInput-input": {
                        padding: "0", // Ensure other necessary paddings are applied
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#d3d3d3", // Light color for bottom border
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "#a9a9a9", // Color on hover (optional)
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#00887a", // Color after focus (optional)
                      },
                    }}
                    style={{ padding: "0" }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }} // Ensure input cannot go below 0
                  />
                </Box>{" "}
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.25rem",
                  }}
                >
                  <TextField
                    type="number"
                    variant="standard"
                    name="seller_contact_view_per_day"
                    value={
                      (formik.values as any)?.seller?.professional
                        ?.seller_contact_view_per_day
                    }
                    onChange={(e) => handleChangeInput(e, 5, "", "")}
                    onBlur={(e) => handleUpdateConfig(e, 5)}
                    sx={{
                      padding: "0",
                      "& .MuiOutlinedInput-input": {
                        padding: "0", // Ensure other necessary paddings are applied
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#d3d3d3", // Light color for bottom border
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "#a9a9a9", // Color on hover (optional)
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#00887a", // Color after focus (optional)
                      },
                    }}
                    style={{ padding: "0" }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }} // Ensure input cannot go below 0
                  />
                  <span
                    style={{
                      textAlign: "center",
                      marginTop: "5px",
                      color: "grey",
                    }}
                  >
                    per day
                  </span>
                </Box>
              </Box>
            </TableCell>
          </TableRow>

          {/* Show Supplier Price */}
          <TableRow>
            <TableCell
              sx={{
                width: "15vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",

                background: "#d0eddb",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  placeItems: "center",
                  textAlign: "center",
                }}
              >
                Supplier Price
              </Box>
            </TableCell>
            <TableCell
              sx={{
                width: "15vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
              }}
            >
              <Box sx={{ display: "grid", placeItems: "center" }}>
                <TextField
                  type="number"
                  name="supplier_price_view_credit"
                  value={
                    (formik.values as any)?.prelogin?.supplier_price_view_credit
                  }
                  onChange={(e) => handleChangeInput(e, 1, "", "")}
                  onBlur={(e) => handleUpdateConfig(e, 1)}
                  variant="standard"
                  sx={{
                    padding: "0",
                    "& .MuiOutlinedInput-input": {
                      padding: "0", // Ensure other necessary paddings are applied
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#d3d3d3", // Light color for bottom border
                    },
                    "& .MuiInput-underline:hover:before": {
                      borderBottomColor: "#a9a9a9", // Color on hover (optional)
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#00887a", // Color after focus (optional)
                    },
                  }}
                  style={{ padding: "0" }}
                  inputProps={{ min: 0, style: { textAlign: "center" } }} // Ensure input cannot go below 0
                />
              </Box>
            </TableCell>
            <TableCell
              sx={{
                width: "30vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                padding: "0",
              }}
            >
              <Box sx={{ display: "flex", padding: "0.5rem" }}>
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.35rem",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  <TextField
                    type="number"
                    name="supplier_price_view_credit"
                    value={
                      (formik.values as any)?.buyer?.starter
                        ?.supplier_price_view_credit
                    }
                    onChange={(e) => handleChangeInput(e, 2, "", "")}
                    onBlur={(e) => handleUpdateConfig(e, 2)}
                    variant="standard"
                    sx={{
                      padding: "0",
                      "& .MuiOutlinedInput-input": {
                        padding: "0", // Ensure other necessary paddings are applied
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#d3d3d3", // Light color for bottom border
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "#a9a9a9", // Color on hover (optional)
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#00887a", // Color after focus (optional)
                      },
                    }}
                    style={{ padding: "0" }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }} // Ensure input cannot go below 0
                  />
                </Box>
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.25rem",
                  }}
                >
                  <TextField
                    type="number"
                    name="supplier_price_view_per_day"
                    value={
                      (formik.values as any)?.buyer?.professional
                        ?.supplier_price_view_per_day
                    }
                    onChange={(e) => handleChangeInput(e, 3, "", "")}
                    onBlur={(e) => handleUpdateConfig(e, 3)}
                    variant="standard"
                    sx={{
                      padding: "0",
                      "& .MuiOutlinedInput-input": {
                        padding: "0", // Ensure other necessary paddings are applied
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#d3d3d3", // Light color for bottom border
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "#a9a9a9", // Color on hover (optional)
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#00887a", // Color after focus (optional)
                      },
                    }}
                    style={{ padding: "0" }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }} // Ensure input cannot go below 0
                  />
                  <span
                    style={{
                      textAlign: "center",
                      marginTop: "5px",
                      color: "grey",
                    }}
                  >
                    per day
                  </span>
                </Box>
              </Box>
            </TableCell>
            <TableCell
              sx={{
                width: "30vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                padding: "0",
              }}
            >
              <Box sx={{ display: "flex", padding: "0.5rem" }}>
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.25rem",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  <TextField
                    type="number"
                    variant="standard"
                    name="supplier_price_view_credit"
                    value={
                      (formik.values as any)?.seller?.starter
                        ?.supplier_price_view_credit
                    }
                    onChange={(e) => handleChangeInput(e, 4, "", "")}
                    onBlur={(e) => handleUpdateConfig(e, 4)}
                    sx={{
                      padding: "0",
                      "& .MuiOutlinedInput-input": {
                        padding: "0", // Ensure other necessary paddings are applied
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#d3d3d3", // Light color for bottom border
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "#a9a9a9", // Color on hover (optional)
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#00887a", // Color after focus (optional)
                      },
                    }}
                    style={{ padding: "0" }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }} // Ensure input cannot go below 0
                  />
                </Box>{" "}
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.25rem",
                  }}
                >
                  <TextField
                    type="number"
                    name="supplier_price_view_per_day"
                    value={
                      (formik.values as any)?.seller?.professional
                        ?.supplier_price_view_per_day
                    }
                    onChange={(e) => handleChangeInput(e, 5, "", "")}
                    onBlur={(e) => handleUpdateConfig(e, 5)}
                    variant="standard"
                    sx={{
                      padding: "0",
                      "& .MuiOutlinedInput-input": {
                        padding: "0", // Ensure other necessary paddings are applied
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#d3d3d3", // Light color for bottom border
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "#a9a9a9", // Color on hover (optional)
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#00887a", // Color after focus (optional)
                      },
                    }}
                    style={{ padding: "0" }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }} // Ensure input cannot go below 0
                  />
                  <span
                    style={{
                      textAlign: "center",
                      marginTop: "5px",
                      color: "grey",
                    }}
                  >
                    per day
                  </span>
                </Box>
              </Box>
            </TableCell>
          </TableRow>

          {/* Show ASI's ZBC */}
          <TableRow>
            <TableCell
              sx={{
                width: "15vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",

                background: "#d0eddb",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  placeItems: "center",
                  textAlign: "center",
                }}
              >
                Zero Based Costing
              </Box>
            </TableCell>
            <TableCell
              sx={{
                width: "15vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
              }}
            >
              <Box sx={{ display: "grid", placeItems: "center" }}>
                <TextField
                  type="number"
                  name="zbc_cost_view_credit"
                  value={(formik.values as any)?.prelogin?.zbc_cost_view_credit}
                  onChange={(e) => handleChangeInput(e, 1, "", "")}
                  onBlur={(e) => handleUpdateConfig(e, 1)}
                  variant="standard"
                  sx={{
                    padding: "0",
                    "& .MuiOutlinedInput-input": {
                      padding: "0", // Ensure other necessary paddings are applied
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#d3d3d3", // Light color for bottom border
                    },
                    "& .MuiInput-underline:hover:before": {
                      borderBottomColor: "#a9a9a9", // Color on hover (optional)
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#00887a", // Color after focus (optional)
                    },
                  }}
                  style={{ padding: "0" }}
                  inputProps={{ min: 0, style: { textAlign: "center" } }} // Ensure input cannot go below 0
                />
              </Box>
            </TableCell>
            <TableCell
              sx={{
                width: "30vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                padding: "0",
              }}
            >
              <Box sx={{ display: "flex", padding: "0.5rem" }}>
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.35rem",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  <TextField
                    type="number"
                    variant="standard"
                    name="zbc_cost_view_credit"
                    value={
                      (formik.values as any)?.buyer?.starter
                        ?.zbc_cost_view_credit
                    }
                    onChange={(e) => handleChangeInput(e, 2, "", "")}
                    onBlur={(e) => handleUpdateConfig(e, 2)}
                    sx={{
                      padding: "0",
                      "& .MuiOutlinedInput-input": {
                        padding: "0", // Ensure other necessary paddings are applied
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#d3d3d3", // Light color for bottom border
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "#a9a9a9", // Color on hover (optional)
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#00887a", // Color after focus (optional)
                      },
                    }}
                    style={{ padding: "0" }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }} // Ensure input cannot go below 0
                  />
                </Box>
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.25rem",
                  }}
                >
                  <TextField
                    type="number"
                    variant="standard"
                    name="zbc_cost_view_per_day"
                    value={
                      (formik.values as any)?.buyer?.professional
                        ?.zbc_cost_view_per_day
                    }
                    onChange={(e) => handleChangeInput(e, 3, "", "")}
                    onBlur={(e) => handleUpdateConfig(e, 3)}
                    sx={{
                      padding: "0",
                      "& .MuiOutlinedInput-input": {
                        padding: "0", // Ensure other necessary paddings are applied
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#d3d3d3", // Light color for bottom border
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "#a9a9a9", // Color on hover (optional)
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#00887a", // Color after focus (optional)
                      },
                    }}
                    style={{ padding: "0" }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }} // Ensure input cannot go below 0
                  />
                  <span
                    style={{
                      textAlign: "center",
                      marginTop: "5px",
                      color: "grey",
                    }}
                  >
                    per day
                  </span>
                </Box>
              </Box>
            </TableCell>
            <TableCell
              sx={{
                width: "30vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                padding: "0",
              }}
            >
              <Box sx={{ display: "flex", padding: "0.5rem" }}>
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.25rem",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  <TextField
                    type="number"
                    variant="standard"
                    name="zbc_cost_view_credit"
                    value={
                      (formik.values as any)?.seller?.starter
                        ?.zbc_cost_view_credit
                    }
                    onChange={(e) => handleChangeInput(e, 4, "", "")}
                    onBlur={(e) => handleUpdateConfig(e, 4)}
                    sx={{
                      padding: "0",
                      "& .MuiOutlinedInput-input": {
                        padding: "0", // Ensure other necessary paddings are applied
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#d3d3d3", // Light color for bottom border
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "#a9a9a9", // Color on hover (optional)
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#00887a", // Color after focus (optional)
                      },
                    }}
                    style={{ padding: "0" }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }} // Ensure input cannot go below 0
                  />
                </Box>{" "}
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.25rem",
                  }}
                >
                  <TextField
                    type="number"
                    name="zbc_cost_view_per_day"
                    value={
                      (formik.values as any)?.seller?.professional
                        ?.zbc_cost_view_per_day
                    }
                    onChange={(e) => handleChangeInput(e, 5, "", "")}
                    onBlur={(e) => handleUpdateConfig(e, 5)}
                    variant="standard"
                    sx={{
                      padding: "0",
                      "& .MuiOutlinedInput-input": {
                        padding: "0", // Ensure other necessary paddings are applied
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#d3d3d3", // Light color for bottom border
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "#a9a9a9", // Color on hover (optional)
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#00887a", // Color after focus (optional)
                      },
                    }}
                    style={{ padding: "0" }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }} // Ensure input cannot go below 0
                  />
                  <span
                    style={{
                      textAlign: "center",
                      marginTop: "5px",
                      color: "grey",
                    }}
                  >
                    per day
                  </span>
                </Box>
              </Box>
            </TableCell>
          </TableRow>

          {/* Can Claim a Business */}
          <TableRow>
            <TableCell
              sx={{
                width: "15vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",

                background: "#d0eddb",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  placeItems: "center",
                  textAlign: "center",
                }}
              >
                Add / Claim Business (Yes / No)
              </Box>
            </TableCell>
            <TableCell
              sx={{
                width: "15vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
              }}
            >
              <Box sx={{ display: "grid", placeItems: "center" }}>
                <FormControl fullWidth>
                  <Select
                    labelId="prelogin-label"
                    id="prelogin"
                    name="prelogin"
                    variant="standard"
                    value={claimBusiness.prelogin}
                    onChange={(e) => {
                      setClaimBusiness({
                        ...claimBusiness,
                        prelogin: e.target.value,
                      });
                      // let updatedValues = { ...formik.values };
                      // updatedValues = {
                      //   ...updatedValues,
                      //   prelogin: {
                      //     ...updatedValues.prelogin,
                      //     ["claim_business_limit"]: parseInt(value),
                      //   },
                      // };
                    }}
                    sx={{
                      padding: "0",
                      textAlign: "center",
                      width: "100%",
                      "& .MuiOutlinedInput-input": {
                        padding: "0", // Ensure other necessary paddings are applied
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#d3d3d3", // Light color for bottom border
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "#a9a9a9", // Color on hover (optional)
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#00887a", // Color after focus (optional)
                      },
                    }}
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </TableCell>
            <TableCell
              sx={{
                width: "30vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                padding: "0",
              }}
            >
              <Box sx={{ display: "flex", padding: "0.5rem" }}>
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.25rem",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  <FormControl fullWidth>
                    <Select
                      labelId="prelogin-label"
                      id="buyerStarter"
                      name="buyerStarter"
                      variant="standard"
                      value={claimBusiness.buyerStarter}
                      onChange={(e) => {
                        setClaimBusiness({
                          ...claimBusiness,
                          buyerStarter: e.target.value,
                        });
                      }}
                      sx={{
                        padding: "0",
                        textAlign: "center",
                        width: "100%",
                        "& .MuiOutlinedInput-input": {
                          padding: "0", // Ensure other necessary paddings are applied
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "#d3d3d3", // Light color for bottom border
                        },
                        "& .MuiInput-underline:hover:before": {
                          borderBottomColor: "#a9a9a9", // Color on hover (optional)
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#00887a", // Color after focus (optional)
                        },
                      }}
                    >
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Select>
                  </FormControl>
                </Box>{" "}
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.25rem",
                  }}
                >
                  <FormControl fullWidth>
                    <Select
                      labelId="prelogin-label"
                      id="buyerProfessional"
                      name="buyerProfessional"
                      variant="standard"
                      value={claimBusiness.buyerProfessional}
                      onChange={(e) => {
                        setClaimBusiness({
                          ...claimBusiness,
                          buyerProfessional: e.target.value,
                        });
                      }}
                      sx={{
                        padding: "0",
                        textAlign: "center",
                        width: "100%",
                        "& .MuiOutlinedInput-input": {
                          padding: "0", // Ensure other necessary paddings are applied
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "#d3d3d3", // Light color for bottom border
                        },
                        "& .MuiInput-underline:hover:before": {
                          borderBottomColor: "#a9a9a9", // Color on hover (optional)
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#00887a", // Color after focus (optional)
                        },
                      }}
                    >
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </TableCell>
            <TableCell
              sx={{
                width: "30vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                padding: "0",
              }}
            >
              <Box sx={{ display: "flex", padding: "0.5rem" }}>
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.25rem",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  <FormControl fullWidth>
                    <Select
                      labelId="prelogin-label"
                      id="sellerStarter"
                      name="sellerStarter"
                      variant="standard"
                      value={claimBusiness.sellerStarter}
                      onChange={(e) => {
                        setClaimBusiness({
                          ...claimBusiness,
                          sellerStarter: e.target.value,
                        });
                      }}
                      sx={{
                        padding: "0",
                        textAlign: "center",
                        width: "100%",
                        "& .MuiOutlinedInput-input": {
                          padding: "0", // Ensure other necessary paddings are applied
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "#d3d3d3", // Light color for bottom border
                        },
                        "& .MuiInput-underline:hover:before": {
                          borderBottomColor: "#a9a9a9", // Color on hover (optional)
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#00887a", // Color after focus (optional)
                        },
                      }}
                    >
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Select>
                  </FormControl>
                </Box>{" "}
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.25rem",
                  }}
                >
                  <FormControl fullWidth>
                    <Select
                      labelId="prelogin-label"
                      id="sellerProfessional"
                      name="sellerProfessional"
                      variant="standard"
                      value={claimBusiness.sellerProfessional}
                      onChange={(e) => {
                        setClaimBusiness({
                          ...claimBusiness,
                          sellerProfessional: e.target.value,
                        });
                      }}
                      sx={{
                        padding: "0",
                        textAlign: "center",
                        width: "100%",
                        "& .MuiOutlinedInput-input": {
                          padding: "0", // Ensure other necessary paddings are applied
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "#d3d3d3", // Light color for bottom border
                        },
                        "& .MuiInput-underline:hover:before": {
                          borderBottomColor: "#a9a9a9", // Color on hover (optional)
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#00887a", // Color after focus (optional)
                        },
                      }}
                    >
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </TableCell>
          </TableRow>

          {/* How many businesses can be claimed */}
          <TableRow>
            <TableCell
              sx={{
                width: "15vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",

                background: "#d0eddb",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  placeItems: "center",
                  textAlign: "center",
                }}
              >
                Number of businesses can be claimed
              </Box>
            </TableCell>
            <TableCell
              sx={{
                width: "15vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
              }}
            >
              <Box sx={{ display: "grid", placeItems: "center" }}>
                <TextField
                  type="number"
                  value={(formik.values as any)?.prelogin?.claim_business_limit}
                  onChange={(e) => handleChangeInput(e, 1, "", "")}
                  onBlur={(e) => handleUpdateConfig(e, 1)}
                  name="claim_business_limit"
                  variant="standard"
                  sx={{
                    padding: "0",
                    "& .MuiOutlinedInput-input": {
                      padding: "0", // Ensure other necessary paddings are applied
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#d3d3d3", // Light color for bottom border
                    },
                    "& .MuiInput-underline:hover:before": {
                      borderBottomColor: "#a9a9a9", // Color on hover (optional)
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#00887a", // Color after focus (optional)
                    },
                  }}
                  style={{ padding: "0" }}
                  inputProps={{ min: 0, style: { textAlign: "center" } }} // Ensure input cannot go below 0
                />
              </Box>
            </TableCell>
            <TableCell
              sx={{
                width: "30vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                padding: "0",
              }}
            >
              <Box sx={{ display: "flex", padding: "0.5rem" }}>
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.25rem",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  <TextField
                    type="number"
                    value={
                      (formik.values as any)?.buyer?.starter
                        ?.claim_business_limit
                    }
                    onChange={(e) => handleChangeInput(e, 2, "", "")}
                    onBlur={(e) => handleUpdateConfig(e, 2)}
                    name="claim_business_limit"
                    variant="standard"
                    sx={{
                      padding: "0",
                      "& .MuiOutlinedInput-input": {
                        padding: "0", // Ensure other necessary paddings are applied
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#d3d3d3", // Light color for bottom border
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "#a9a9a9", // Color on hover (optional)
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#00887a", // Color after focus (optional)
                      },
                    }}
                    style={{ padding: "0" }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }} // Ensure input cannot go below 0
                  />
                </Box>{" "}
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.25rem",
                  }}
                >
                  <TextField
                    type="number"
                    value={
                      (formik.values as any)?.buyer?.professional
                        ?.claim_business_limit
                    }
                    onChange={(e) => handleChangeInput(e, 3, "", "")}
                    onBlur={(e) => handleUpdateConfig(e, 3)}
                    name="claim_business_limit"
                    variant="standard"
                    sx={{
                      padding: "0",
                      "& .MuiOutlinedInput-input": {
                        padding: "0", // Ensure other necessary paddings are applied
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#d3d3d3", // Light color for bottom border
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "#a9a9a9", // Color on hover (optional)
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#00887a", // Color after focus (optional)
                      },
                    }}
                    style={{ padding: "0" }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }} // Ensure input cannot go below 0
                  />
                </Box>
              </Box>
            </TableCell>
            <TableCell
              sx={{
                width: "30vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                padding: "0",
              }}
            >
              <Box sx={{ display: "flex", padding: "0.5rem" }}>
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.25rem",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  <TextField
                    type="number"
                    value={
                      (formik.values as any)?.seller?.starter
                        ?.claim_business_limit
                    }
                    onChange={(e) => handleChangeInput(e, 4, "", "")}
                    onBlur={(e) => handleUpdateConfig(e, 4)}
                    name="claim_business_limit"
                    variant="standard"
                    sx={{
                      padding: "0",
                      "& .MuiOutlinedInput-input": {
                        padding: "0", // Ensure other necessary paddings are applied
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#d3d3d3", // Light color for bottom border
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "#a9a9a9", // Color on hover (optional)
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#00887a", // Color after focus (optional)
                      },
                    }}
                    style={{ padding: "0" }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }} // Ensure input cannot go below 0
                  />
                </Box>{" "}
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.25rem",
                  }}
                >
                  <TextField
                    type="number"
                    value={
                      (formik.values as any)?.seller?.professional
                        ?.claim_business_limit
                    }
                    onChange={(e) => handleChangeInput(e, 5, "", "")}
                    onBlur={(e) => handleUpdateConfig(e, 5)}
                    name="claim_business_limit"
                    variant="standard"
                    sx={{
                      padding: "0",
                      "& .MuiOutlinedInput-input": {
                        padding: "0", // Ensure other necessary paddings are applied
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#d3d3d3", // Light color for bottom border
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "#a9a9a9", // Color on hover (optional)
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#00887a", // Color after focus (optional)
                      },
                    }}
                    style={{ padding: "0" }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }} // Ensure input cannot go below 0
                  />
                </Box>
              </Box>
            </TableCell>
          </TableRow>

          {/* How many parts can be added with price */}
          <TableRow>
            <TableCell
              sx={{
                width: "15vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",

                background: "#d0eddb",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  placeItems: "center",
                  textAlign: "center",
                }}
              >
                Number of parts can be added
              </Box>
            </TableCell>
            <TableCell
              sx={{
                width: "15vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
              }}
            >
              <Box sx={{ display: "grid", placeItems: "center" }}>
                <TextField
                  type="number"
                  value={
                    (formik.values as any)?.prelogin?.add_part_for_free_limit
                  }
                  name="add_part_for_free_limit"
                  onChange={(e) => handleChangeInput(e, 1, "", "")}
                  onBlur={(e) => handleUpdateConfig(e, 1)}
                  variant="standard"
                  sx={{
                    padding: "0",
                    "& .MuiOutlinedInput-input": {
                      padding: "0", // Ensure other necessary paddings are applied
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#d3d3d3", // Light color for bottom border
                    },
                    "& .MuiInput-underline:hover:before": {
                      borderBottomColor: "#a9a9a9", // Color on hover (optional)
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#00887a", // Color after focus (optional)
                    },
                  }}
                  style={{ padding: "0" }}
                  inputProps={{ min: 0, style: { textAlign: "center" } }} // Ensure input cannot go below 0
                />
              </Box>
            </TableCell>
            <TableCell
              sx={{
                width: "30vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                padding: "0",
              }}
            >
              <Box sx={{ display: "flex", padding: "0.5rem" }}>
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.25rem",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  <TextField
                    type="number"
                    value={
                      (formik.values as any)?.buyer?.starter
                        ?.add_part_for_free_limit
                    }
                    name="add_part_for_free_limit"
                    onChange={(e) => handleChangeInput(e, 2, "", "")}
                    onBlur={(e) => handleUpdateConfig(e, 2)}
                    variant="standard"
                    sx={{
                      padding: "0",
                      "& .MuiOutlinedInput-input": {
                        padding: "0", // Ensure other necessary paddings are applied
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#d3d3d3", // Light color for bottom border
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "#a9a9a9", // Color on hover (optional)
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#00887a", // Color after focus (optional)
                      },
                    }}
                    style={{ padding: "0" }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }} // Ensure input cannot go below 0
                  />
                </Box>{" "}
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.25rem",
                  }}
                >
                  <TextField
                    type="number"
                    variant="standard"
                    value={
                      (formik.values as any)?.buyer?.professional
                        ?.add_part_for_free_limit
                    }
                    name="add_part_for_free_limit"
                    onChange={(e) => handleChangeInput(e, 3, "", "")}
                    onBlur={(e) => handleUpdateConfig(e, 3)}
                    sx={{
                      padding: "0",
                      "& .MuiOutlinedInput-input": {
                        padding: "0", // Ensure other necessary paddings are applied
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#d3d3d3", // Light color for bottom border
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "#a9a9a9", // Color on hover (optional)
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#00887a", // Color after focus (optional)
                      },
                    }}
                    style={{ padding: "0" }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }} // Ensure input cannot go below 0
                  />
                </Box>
              </Box>
            </TableCell>
            <TableCell
              sx={{
                width: "30vw",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                padding: "0",
              }}
            >
              <Box sx={{ display: "flex", padding: "0.5rem" }}>
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.25rem",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  <TextField
                    type="number"
                    value={
                      (formik.values as any)?.seller?.starter
                        ?.add_part_for_free_limit
                    }
                    name="add_part_for_free_limit"
                    onChange={(e) => handleChangeInput(e, 4, "", "")}
                    onBlur={(e) => handleUpdateConfig(e, 4)}
                    variant="standard"
                    sx={{
                      padding: "0",
                      "& .MuiOutlinedInput-input": {
                        padding: "0", // Ensure other necessary paddings are applied
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#d3d3d3", // Light color for bottom border
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "#a9a9a9", // Color on hover (optional)
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#00887a", // Color after focus (optional)
                      },
                    }}
                    style={{ padding: "0" }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }} // Ensure input cannot go below 0
                  />
                </Box>{" "}
                <Box
                  sx={{
                    width: "50%",
                    display: "grid",
                    placeItems: "center",
                    padding: "0.25rem",
                  }}
                >
                  <TextField
                    type="number"
                    variant="standard"
                    value={
                      (formik.values as any)?.seller?.professional
                        ?.add_part_for_free_limit
                    }
                    name="add_part_for_free_limit"
                    onChange={(e) => handleChangeInput(e, 5, "", "")}
                    onBlur={(e) => handleUpdateConfig(e, 5)}
                    sx={{
                      padding: "0",
                      "& .MuiOutlinedInput-input": {
                        padding: "0", // Ensure other necessary paddings are applied
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#d3d3d3", // Light color for bottom border
                      },
                      "& .MuiInput-underline:hover:before": {
                        borderBottomColor: "#a9a9a9", // Color on hover (optional)
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#00887a", // Color after focus (optional)
                      },
                    }}
                    style={{ padding: "0" }}
                    inputProps={{ min: 0, style: { textAlign: "center" } }} // Ensure input cannot go below 0
                  />
                </Box>
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ConfigurationTable;
