// // react
// import React, { useMemo } from 'react';

import {
  Box,
  Divider,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CALCULATOR_DATABASES_MAP } from "../../../../../../../Configuration/Costing/calculator.constant";
import { ID } from "../../../../../../../utlis/commonInterfaces";
import { useAssumptioCard } from "../../../../../useAssumptioCard";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import { useContext, useEffect, useState } from "react";
import { API } from "../../../../../../../api-services";
import { Padding } from "@mui/icons-material";
import { useRouteParams } from "../../../../../../../ui-reusable-component/useRouteParams";
import ReplyIcon from "@mui/icons-material/Reply";
import { OtherAssumptionIdContext } from "../../../../CreateCostingPart";
import arrowRight from "../../../../../../../Assets/images/rightArrows.svg";


// ----------------------------------------------------------------------------------

interface SelectedDatabaseRecordInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  database: number;
  recordId: ID;
  userInput:any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "30vw",
  maxWidth: "80vw",
  // height: '80vh',
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,

};

const SelectedDatabaseRecordInfoModal: React.FC<
  SelectedDatabaseRecordInfoModalProps
> = (props) => {
  const { database, isOpen, onClose, recordId ,userInput} = props;

  const { projectId, vault, topVault, scenarioId } = useRouteParams<any>();
  const [DataBaseList, setDataBaseList] = useState<any>([]);
  const [currencyInfo,setCurrencyInfo] = useState<any>([]);
  const [loding, setLoading] = useState<any>(false);
  const [Loader, setLoader] = useState<any>(false);
  const [assumptionDetails, setAssumptionDetails] = useState<any>([]);
  const [useraction, setuseraction] = useState<any>(null);
  const [commodityID, setCommodityID]=useState<any>(null)
  const handleOpenLinkReference = (reference: string) => {
    window.open(reference);
  };
  const getAssumptionData = () => {
    setLoading(true);
    API.get(
      "/cost/costing_module/",
      {
        vault_id: vault,
        get_assumption_details: true,
        scenario: scenarioId ? scenarioId : undefined,
      },
      0
    )
      .then((res: any) => {
        setLoading(false);

        setAssumptionDetails(res.data);

      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const checkUserPermession = () => {
    
    API.get(
      '/auth/user_permission_module/',
      {
        action: true,
        database: true,
        module: 'DB-Costing',
      },
      0
    )
      .then((res: any) => {
        
        setuseraction(res?.data?.action);
        
      })
      .catch((err: any) => { });

  }

  const handleUserRedirection = (typeId: any, typeName: any) => {
    // console.log(assumptionDetails);
    window.open(`/#/data-base/revision-database/${assumptionDetails[0].assumption_type}/${assumptionDetails[0].tools_revision["Revised on"].split("-")[2]}/${assumptionDetails[0].tools_revision["Revised on"].split("-")[1]}/${assumption?.material_revision}/${typeId}/${typeName}/${commodityID}/false/0`) 
  }

  

  // ----------------------------------------------------------------------------------
  // assumption card info

  const AssumtionIds=useContext<any>(OtherAssumptionIdContext)

  const { 1: assumption } = useAssumptioCard();
  // console.log(assumption?.bop_revision, "gebrbubuegbiu")
  // console.log("assumptionassumptionassumption", assumption);
  const getRevisionId = () => {
    if (!assumption) return 0;

    switch (database) {
      case CALCULATOR_DATABASES_MAP.LABOUR:
        return assumption.labour_revision;
      case CALCULATOR_DATABASES_MAP.MACHINE:
        return assumption.machine_revision;
      case CALCULATOR_DATABASES_MAP.MATERIAL:
        return assumption.material_revision;
      case CALCULATOR_DATABASES_MAP.TOOL:
        return assumption.tools_revision;
      case CALCULATOR_DATABASES_MAP.STROKE_RATE:
        return assumption.operations_revision;
      case CALCULATOR_DATABASES_MAP.PURCHASEING_ITEM:
        return assumption.bop_revision;
      case CALCULATOR_DATABASES_MAP.CURRENCY:
        return assumption.currency_revision;
      default:
        return 0;
    }
  };


  const getRevisionNameId:any = () => {
    if (!assumption) return 0;

    switch (database) {
      case 2:
        return "machine_id";
       case 3:
        return "labour_id";
        case 4:
          return "tools_id";
          case 7:
            return "stroke_rate_id"

    }
  };

  const tableHeading = (datatype: any) => {
    switch (datatype) {
      case 1:
        return (
          <Typography sx={{display:'flex',alignItems:'center',columnGap:'0.5rem',justifyContent:'center', padding: "0.2rem 0.5rem", fontWeight: "500" }}>
            {" "}
            Material Info
            {((useraction && useraction?.includes('C')) ||
									(useraction && useraction?.includes('U')) ||
									(useraction && useraction?.includes('R')) ||
									(useraction && useraction?.includes('D'))) && 
                (<img src={arrowRight}
                            onClick={()=> handleUserRedirection(assumption?.material_revision, "rm")}
                              
                              title="Go to Revision Page"
                              style={{
                                fontSize: "1.5rem",
                                // transform: "scaleX(-1)",
                                width:'1.5rem',
                                justifySelf: "center",
                                alignSelf: "center",
                                cursor: "pointer",
                              }}
                            /> ) 
}
          </Typography>
        );
      case 2:
        return (
          <Typography sx={{display:'flex',alignItems:'center',columnGap:'0.5rem',justifyContent:'center', padding: "0.2rem 0.5rem", fontWeight: "500" }}>
            {" "}
            MHR Info
            {((useraction && useraction?.includes('C')) ||
									(useraction && useraction?.includes('U')) ||
									(useraction && useraction?.includes('R')) ||
									(useraction && useraction?.includes('D'))) && 
                (<img src={arrowRight}
                            onClick={()=> handleUserRedirection(assumption?.machine_revision, "machine")}
                              
                              title="Go to Revision Page"
                              style={{
                                fontSize: "1.5rem",
                                // transform: "scaleX(-1)",
                                width:'1.5rem',
                                justifySelf: "center",
                                alignSelf: "center",
                                cursor: "pointer",
                              }}
                            /> ) 
}
          </Typography>
        );
      case 3:
        return (
          <Typography sx={{display:'flex',alignItems:'center',columnGap:'0.5rem',justifyContent:'center', padding: "0.2rem 0.5rem", fontWeight: "500" }}>
            {" "}
            LHR Info
            {((useraction && useraction?.includes('C')) ||
									(useraction && useraction?.includes('U')) ||
									(useraction && useraction?.includes('R')) ||
									(useraction && useraction?.includes('D'))) && 
                (<img src={arrowRight}
                            onClick={()=> handleUserRedirection(assumption?.labour_revision, "labor")}
                              
                              title="Go to Revision Page"
                              style={{
                                fontSize: "1.5rem",
                                // transform: "scaleX(-1)",
                                width:'1.5rem',
                                justifySelf: "center",
                                alignSelf: "center",
                                cursor: "pointer",
                              }}
                            /> ) 
}
          </Typography>
        );
      case 4:
        return (
          <Typography sx={{display:'flex',alignItems:'center',columnGap:'0.5rem',justifyContent:'center', padding: "0.2rem 0.5rem", fontWeight: "500" }}>
            {" "}
            Tool Info
            {((useraction && useraction?.includes('C')) ||
									(useraction && useraction?.includes('U')) ||
									(useraction && useraction?.includes('R')) ||
									(useraction && useraction?.includes('D'))) && 
                (<img src={arrowRight}
                            onClick={()=> handleUserRedirection(assumption?.tools_revision, "tools")}
                              
                              title="Go to Revision Page"
                              style={{
                                fontSize: "1.5rem",
                                // transform: "scaleX(-1)",
                                width:'1.5rem',
                                justifySelf: "center",
                                alignSelf: "center",
                                cursor: "pointer",
                              }}
                            /> ) 
}
          </Typography>
        );
      case 5:
        return (
          <Typography sx={{display:'flex',alignItems:'center',columnGap:'0.5rem',justifyContent:'center', padding: "0.2rem 0.5rem", fontWeight: "500" }}>
            {" "}
            Fastener Info
            {((useraction && useraction?.includes('C')) ||
									(useraction && useraction?.includes('U')) ||
									(useraction && useraction?.includes('R')) ||
									(useraction && useraction?.includes('D'))) && 
                (<img src={arrowRight}
                            onClick={()=> handleUserRedirection(assumption?.bop_revision, "bop")}
                              
                              title="Go to Revision Page"
                              style={{
                                fontSize: "1.5rem",
                                // transform: "scaleX(-1)",
                                width:'1.5rem',
                                justifySelf: "center",
                                alignSelf: "center",
                                cursor: "pointer",
                              }}
                            /> ) 
}
          </Typography>
        );
      case 6:
        return (
          <Typography sx={{display:'flex',alignItems:'center',columnGap:'0.5rem',justifyContent:'center', padding: "0.2rem 0.5rem", fontWeight: "500" }}>
            {" "}
            BOP Info
            {((useraction && useraction?.includes('C')) ||
									(useraction && useraction?.includes('U')) ||
									(useraction && useraction?.includes('R')) ||
									(useraction && useraction?.includes('D'))) && 
                (<img src={arrowRight}
                            onClick={()=> handleUserRedirection(assumption?.bop_revision, "bop")}
                              
                              title="Go to Revision Page"
                              style={{
                                fontSize: "1.5rem",
                                // transform: "scaleX(-1)",
                                width:'1.5rem',
                                justifySelf: "center",
                                alignSelf: "center",
                                cursor: "pointer",
                              }}
                            /> ) 
}
          </Typography>
        );
      case 7:
        return (
          <Typography sx={{display:'flex',alignItems:'center',columnGap:'0.5rem',justifyContent:'center', padding: "0.2rem 0.5rem", fontWeight: "500" }}>
            {" "}
            Operation Info
            {((useraction && useraction?.includes('C')) ||
									(useraction && useraction?.includes('U')) ||
									(useraction && useraction?.includes('R')) ||
									(useraction && useraction?.includes('D'))) && 
                (<img src={arrowRight}
                            onClick={()=> handleUserRedirection(assumption?.operations_revision, "operations")}
                              
                              title="Go to Revision Page"
                              style={{
                                fontSize: "1.5rem",
                                // transform: "scaleX(-1)",
                                width:'1.5rem',
                                justifySelf: "center",
                                alignSelf: "center",
                                cursor: "pointer",
                              }}
                            /> ) 
}
          </Typography>
        );
      case 8:
        return (
          <Typography sx={{display:'flex',alignItems:'center',columnGap:'0.5rem',justifyContent:'center', padding: "0.2rem 0.5rem", fontWeight: "500" }}>
            {" "}
            Currency Info
            {((useraction && useraction?.includes('C')) ||
									(useraction && useraction?.includes('U')) ||
									(useraction && useraction?.includes('R')) ||
									(useraction && useraction?.includes('D'))) && 
                (<img src={arrowRight}
                            onClick={()=> handleUserRedirection(assumption?.currency_revision, "currency")}
                              
                              title="Go to Revision Page"
                              style={{
                                fontSize: "1.5rem",
                                // transform: "scaleX(-1)",
                                width:'1.5rem',
                                justifySelf: "center",
                                alignSelf: "center",
                                cursor: "pointer",
                              }}
                            /> ) 
}
          </Typography>
        );
    }
  };

  // ----------------------------------------------------------------------------------
  // fetch

  // const { 0: fetched, 1: data, 3: fetchData } = useFetch( {
  //     method: databaseService[database],
  //     args: [recordId, getRevisionId()],
  //     condition: Boolean( database ) && Boolean( recordId ) && Boolean( assumption ),
  //     transformResData: transformDatabaseRecord[database],
  //     dependencies: [recordId, database, assumption]
  // } );

  // ----------------------------------------------------------------------------------
  // memorized

  // const columns = useMemo( () => {
  //     return databaseColumns[database] || [];
  // }, [database] );

  // ----------------------------------------------------------------------------------
  // jsx helpers

  // const getDatabaseName = () => {
  //     return calculatorDatabaseOptions.find( option => option.value === database )?.text;
  // };

  // ----------------------------------------------------------------------------------
  // jsx
  const isUrl = (url:any) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  useEffect(()=>{
    getAssumptionData();
    checkUserPermession();
  }, [])
let valuelist=AssumtionIds && AssumtionIds?.map((item:any,index:any)=>{return item?.id})
  const materialQuery=()=>{
    if(AssumtionIds && AssumtionIds?.length>0){
      return { revision: getRevisionId(), material_id: recordId,calculation_id:userInput?.id,assumption_list:valuelist?.toString()}
     
    }else{
      return { revision: getRevisionId(), material_id: recordId}
    }
    
  }


  const MultipleAssumptionQuery=()=>{
    if(AssumtionIds && AssumtionIds?.length>0){
      return { revision: getRevisionId(), [getRevisionNameId()]: recordId,calculation_id:userInput?.id,assumption_list:valuelist?.toString()}
     
    }else{
      return { revision: getRevisionId(), [getRevisionNameId()]: recordId}
    }
    
  }
  
  
  const BOPQuery=()=>{
    if(AssumtionIds && AssumtionIds?.length>0){
   
      return { revision: assumption?.bop_revision, id: recordId,calculation_id:userInput?.id,assumption_list:valuelist?.toString()}
     
    }else{
      return { revision: assumption?.bop_revision, id: recordId}
    }
    
  }



  const CuurencyQuery=()=>{
    if(AssumtionIds && AssumtionIds?.length>0){
   
      return { revision: getRevisionId(), id: recordId,calculation_id:userInput?.id,assumption_list:valuelist?.toString()}
     
    }else{
      return { revision: getRevisionId(), id: recordId}
    }
    
  }
  
  useEffect(() => {
    if (getRevisionId() !== 0) {
      console.log("databasedatabasedatabase", database);
      setLoader(true);
      if (database == 1) {
        API.get(
          "/db/material_cost/",
          { ...materialQuery() },
          0
        )
          .then((res: any) => {
            setCommodityID(res?.data?.[0]?.material?.category)
            setDataBaseList(res.data);
            setLoader(false);
          })
          .catch((err: any) => {
            console.log("Server Error");
            setLoader(false);
          });
      } else if (database == 2) {
        API.get(
          "/db/machine_cost/",
          { ...MultipleAssumptionQuery() },
          0
        )
          .then((res: any) => {
            // console.log(res?.data?.[0])
            setCommodityID(res?.data?.[0]?.machine?.commodity)
            setDataBaseList(res.data);
            setLoader(false);
          })
          .catch((err: any) => {
            console.log("Server Error");
            setLoader(false);
          });
      } else if (database == 3) {
        API.get(
          "/db/labour_cost/",
          { ...MultipleAssumptionQuery()},
          0
        )
          .then((res: any) => {
            // console.log(res?.data?.[0]);
            setCommodityID(res?.data?.[0]?.labour?.commodity)
            setDataBaseList(res.data);
            setLoader(false);
          })
          .catch((err: any) => {
            setLoader(false);
            console.log("Server Error");
          });
      }
      if (database == 4) {
        // console.log(database, "hrbvbfvbfvhbfvhb")
        API.get(
          "/db/tool_cost/",
          { ...MultipleAssumptionQuery()},
          0
        )
          .then((res: any) => {
            setCommodityID(res?.data?.[0]?.tools?.commodity)
            setDataBaseList(res.data);
            setLoader(false);
          })
          .catch((err: any) => {
            console.log("Server Error");
            setLoader(false);
          });
      }
      if (database == 7) {
        API.get(
          "/db/stroke_rate_revision/",
          { ...MultipleAssumptionQuery() },
          0
        )
          .then((res: any) => {
            setDataBaseList(res.data);
            setLoader(false);
          })
          .catch((err: any) => {
            setLoader(false);
            console.log("Server Error");
          });
      }
      if (database == 8) {
        API.get(
          "/db/revision_currency/",{...CuurencyQuery()},
          // { revision: getRevisionId(), id: recordId },
          0
        )
          .then((res: any) => {
            setCurrencyInfo(res.data);
            setLoader(false);
          })
          .catch((err: any) => {
            setLoader(false);
            console.log("Server Error");
          });
      }
      //   /db/revision_currency/
    }
    setLoader(true);
    if (database == 6) {
      API.get(
        "/db/purchasing_item/",{...BOPQuery()}
       ,
        0
      )
        .then((res: any) => {
          console.log(res.data)
          setDataBaseList(res.data);
          setLoader(false);
        })
        .catch((err: any) => {
          setLoader(false);
          console.log("Server Error");
        });
    }
  }, [database, recordId, isOpen]);

  const databaseColumns1 = (type: any) => {
    switch (type) {
      case 3:
        return (
          <>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Labour Code
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList?.[0]?.labour?.labour_code}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Labour Type
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList?.[0]?.labour?.labour_type}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Minimum Wage (INR/Day)
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList?.[0]?.wage_per_day}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                MiD.A (INR/Month)
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList?.[0]?.dearness_allowance}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Perks (%)
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList?.[0]?.perks}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Minimum Wage (INR/Month)
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList?.[0]?.wage_per_month}
              </TableCell>
            </TableRow>
           
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Description
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList?.[0]?.labour?.description}{" "}
              </TableCell>
            </TableRow>


          {  AssumtionIds && AssumtionIds?.length>0
           ?
          
          
          <><TableRow>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  Labour Type
                </TableCell>

                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  {DataBaseList[0]?.labour_detail?.labour_type}
                </TableCell>
              </TableRow><TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Labour Cost
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.labour_detail?.labour_cost}
                  </TableCell>
                </TableRow></>:


<TableRow>
<TableCell
  sx={{
    padding: "0.2rem 0.5rem",
    borderBottomColor: "primary.light",
  }}
>
  LHR
</TableCell>
<TableCell
  sx={{
    padding: "0.2rem 0.5rem",
    borderBottomColor: "primary.light",
  }}
>
  {DataBaseList?.[0]?.cost_per_hour}
</TableCell>
</TableRow>
          }
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Commodity
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList?.[0]?.labour?.commodity_name}{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Reference
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {isUrl(DataBaseList?.[0]?.reference)?<span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => handleOpenLinkReference(DataBaseList?.[0]?.reference)}>
              Link
            </span>:<span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              >
              {DataBaseList?.[0]?.reference}
            </span>}
              </TableCell>
            </TableRow>
          </>
        );
      case 2:
        return (
          <>
            <>
              <TableRow>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  Machine Code
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  {DataBaseList[0]?.machine?.machine_code}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  {DataBaseList[0]?.machine?.name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  Brand Name
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  {DataBaseList[0]?.machine?.brand_name}{" "}
                </TableCell>
              </TableRow>
          



          {  AssumtionIds && AssumtionIds?.length>0
           ?
          
          
          <><TableRow>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  Machine Type
                </TableCell>

                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  {DataBaseList[0]?.machine_detail?.machine_type}
                </TableCell>
              </TableRow><TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Machine Cost
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.machine_detail?.machine_cost}
                  </TableCell>
                </TableRow></>:    <TableRow>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  Cost Per Hour(INR/Hour)
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  {" "}
                  {DataBaseList[0]?.cost_per_hour}
                </TableCell>
              </TableRow>
          }
              <TableRow>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  Commodity
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  {DataBaseList[0]?.machine?.commodity_name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  Description
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  {DataBaseList[0]?.machine?.description}
                </TableCell>
              </TableRow>
              <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Reference
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                
                {isUrl(DataBaseList?.[0]?.reference)?<span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => handleOpenLinkReference(DataBaseList?.[0]?.reference)}>
              Link
            </span>:<span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              >
              {DataBaseList?.[0]?.reference}
            </span>}
              </TableCell>
            </TableRow>
            </>
          </>
        );
      case 4:
        return (
          <>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Tool Code{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.tools?.code}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Tool Name{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.tools?.name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Specification{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.tools?.specification}{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Catogory{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.tools?.commodity_name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Supplier{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.tools?.supplier}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Material{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.tools?.material}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Other{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.tools?.other}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                No. of Shots{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.shots}
              </TableCell>
            </TableRow>
          



            {  AssumtionIds && AssumtionIds?.length>0
           ?
          
          
          <><TableRow>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  Tool Type
                </TableCell>

                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  {DataBaseList[0]?.tools_detail?.tools_type}
                </TableCell>
              </TableRow><TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                  Unit Rate (INR){" "}
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.tools_detail?.tools_cost}
                  </TableCell>
                </TableRow></>:  <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Unit Rate (INR){" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.price}
              </TableCell>
            </TableRow>
          }
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light", 
                }}
              >
                Reference{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {isUrl(DataBaseList?.[0]?.details)?<span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => handleOpenLinkReference(DataBaseList?.[0]?.details)}>
              Link
            </span>:<span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              >
              {DataBaseList?.[0]?.details}
            </span>}
              </TableCell>
            </TableRow>
          </>
        );
      case 1:
        return (
          <>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Material Code
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.material?.material_code}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Material Category
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.material?.category}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Grade
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.material?.material_grade}{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Sub Grade
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.material?.material_subgrade}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Shape
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {" "}
                {DataBaseList[0]?.material?.shape}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Density
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.material?.density}
              </TableCell>
            </TableRow>

            {
              AssumtionIds && AssumtionIds?.length>0 ?
              
              <>
              <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Material Type
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.material_detail?.material_type}
                  </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      Material Cost
                    </TableCell>

                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      {DataBaseList[0]?.material_detail?.material_cost}
                    </TableCell>
                  </TableRow>
                  
                  
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      Scrap Type
                    </TableCell>

                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      {DataBaseList[0]?.material_detail?.scrap_type}
                    </TableCell>
                  </TableRow><TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      Scrap Cost
                    </TableCell>

                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      {DataBaseList[0]?.material_detail?.scrap_cost}
                    </TableCell>
                  </TableRow>
                  <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Reference
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                  {isUrl(DataBaseList[0]?.scrap_reference)?<span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => handleOpenLinkReference(DataBaseList[0]?.scrap_reference)}>
              Link
            </span>:<span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              >
              {DataBaseList[0]?.scrap_reference}
            </span>}
              </TableCell>
            </TableRow>
                  </>
                
              
              
              :
            
            <>
            <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Material Cost
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.material_cost}
                  </TableCell>
                </TableRow>
                
                <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      Scrap Cost
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      {DataBaseList[0]?.scrap_cost}
                    </TableCell>
                  </TableRow>
                  <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Reference
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                  {isUrl(DataBaseList[0]?.material_reference)?<span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => handleOpenLinkReference(DataBaseList[0]?.material_reference)}>
              Link
            </span>:<span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              >
              {DataBaseList[0]?.material_reference}
            </span>}
              </TableCell>
            </TableRow>
                  
                  
                  </>
    }
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Remarks
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.material?.description == ""
                  ? "-"
                  : DataBaseList[0]?.material?.description}
              </TableCell>
            </TableRow>
            
            
          </>
        );
      case 6:
        return (
          <>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                BOP Item Code
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.code}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                BOP Item Name
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Item Description
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.description}{" "}
              </TableCell>
            </TableRow>
            
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Item Details
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.item_detail}{" "}
              </TableCell>
            </TableRow>
            {AssumtionIds && AssumtionIds?.length>0 ?
            
            <><TableRow>

                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  Value Type
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  {DataBaseList[0]?.purchase_detail?.bop_type}{" "}
                </TableCell>
              </TableRow><TableRow>

                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    BOP Cost
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.purchase_detail?.bop_cost }{" "}
                  </TableCell>
                </TableRow></>
          
        :
            
           
            
           
           
            <TableRow>

              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                BOP Cost{" "}
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.bop_cost}{" "}
              </TableCell>
            </TableRow>
    }
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Commodity
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.commodity_name}{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Length (mm)
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.length}{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Width (mm)
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.width}{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Height (mm)
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.height}{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Weight (kg)
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.weight}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Place of Mfg.
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.place}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Per unit Cost (INR)
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.unit_cost}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Reference
              </TableCell>
              
                <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {isUrl(DataBaseList?.[0]?.reference)?<span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => handleOpenLinkReference(DataBaseList?.[0]?.reference)}>
              Link
            </span>:<span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              >
              {DataBaseList?.[0]?.reference}
            </span>}
              </TableCell>
            </TableRow>
          </>
        );
      case 7:
        return (
          <>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Operation Code
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.operation?.code}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Category
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.operation?.category}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Remarks
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.operation?.remark}{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Unit
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.operation?.unit}{" "}
              </TableCell>
            </TableRow>
            




            {  AssumtionIds && AssumtionIds?.length>0
           ?
          
          
          <><TableRow>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  Operation Type
                </TableCell>

                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  {DataBaseList[0]?.operation_detail?.operation_type}
                </TableCell>
              </TableRow><TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Operation Cost
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {DataBaseList[0]?.operation_detail?.operation_cost}
                  </TableCell>
                </TableRow></>:<TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Rate/Unit
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.rate_per_unit}{" "}
              </TableCell>
            </TableRow>
          }
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Description
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {DataBaseList[0]?.operation?.description}{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem", 
                  borderBottomColor: "primary.light",
                }}
              >
                Reference
              </TableCell>
            <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {isUrl(DataBaseList?.[0]?.reference)?<span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => handleOpenLinkReference(DataBaseList?.[0]?.reference)}>
              Link
            </span>:<span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              >
              {DataBaseList?.[0]?.reference}
            </span>}
              </TableCell>
              </TableRow>
          </>
        );

      case 8:
        return (
          <>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Name
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {currencyInfo[0]?.name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Currency of Country
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {currencyInfo[0]?.country_name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Currency from
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {currencyInfo[0]?.currency_from}{" "}
              </TableCell>
            </TableRow>
            {
              AssumtionIds && AssumtionIds?.length>0 ?

              <><TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    Value Type
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    {currencyInfo[0]?.currency_detail?.currency_type}{" "}
                  </TableCell>
                </TableRow><TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      Exchange rate
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      {currencyInfo[0]?.currency_detail?.manual_value}{" "}
                    </TableCell>
                  </TableRow></>
            :
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                Exchange rate
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                }}
              >
                {currencyInfo[0]?.manual_value}{" "}
              </TableCell>
            </TableRow>
    }
           
          </>
        );
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={style}>
        <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: "-2rem",
            }}
          >
            {tableHeading(database)}
          </Typography>
          <CancelIcon
            titleAccess="Close"
            sx={{ cursor: "pointer", margin: "0.5rem" }}
            onClick={onClose}
          />
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
            marginBottom: "1rem",
          }}
        />
        <Box
          sx={{ maxHeight: "65vh", width: "100%" }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          {Loader ? (
            <>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </>
          ) : (
            <Box>
              <Table>
                <TableHead
                  sx={{
                    backgroundColor: "primary.light",
                    position: "sticky",
                    top: 0,
                    zIndex: 10,
                  }}
                >
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        width: "15rem",
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                    >
                      Value
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{databaseColumns1(database)}</TableBody>
              </Table>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

// ----------------------------------------------------------------------------------

export default SelectedDatabaseRecordInfoModal;
