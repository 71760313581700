import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styles from "./IdeaViewpage.module.scss";
import { Checkbox, MenuItem, TextField } from "@mui/material";
import Select from "@mui/material/Select";
import { IdeaTypesList } from "../../../utlis/Idea.constant";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { ADMIN } from "../../../Redux/Services/admin.service";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import { isBoolean } from "lodash";
import { UserAction } from "./IdeaGenerationViewLanding";
import { useSelector } from "react-redux";
import { userSelectors } from "../../../Redux/Reducers/user.reducer";

export interface IIdeadetailsProps {
  ideaAllDetails: any;
  ideaDetails: any;
  width?: any;
  savingData:any;
  setIdeaDetails:any;
  setStatusCounter:any;

}

export default function Ideadetails(props: IIdeadetailsProps) {
  const { ideaAllDetails,ideaDetails,width ,savingData,setIdeaDetails,setStatusCounter} = props;
  const ideaCloseHandler = () => {
  };
  const userProfile = useSelector(userSelectors.selectAll);
  const {vault_id} = useRouteParams<any>();
  const userAction = React.useContext<any>(UserAction);
  let UserActionParams = userAction && userAction?.action;
  const [checked, setChecked] = React.useState(true);
  const [ideaType,setIdeaType]=React.useState<any>('')
  const [currentStage,setCurrentStage]=React.useState<any>('')
  const [savingValue,setSavingValue]=React.useState<any>()
  const [oldSavingValue,setOldSavingValue]=React.useState<any>('')
  const [isCostVerified,setIsCostVerified]=React.useState<any>(false)
  const [isWeightVerified,setIsWeightVerified]=React.useState<any>(false)
  const [weightValue,setWeightValue]=React.useState<any>('')
  const [oldWeightValue,setOldWeightValue]=React.useState<any>('')
  const [easeValue,setEaseValue]=React.useState<any>('')
  const [oldEaseValue,setOldEaseValue]=React.useState<any>('')
  const [CostVerifiedBy,SetCostVerifiedBy]=React.useState<any>('');



  React.useEffect(()=>{
 
    if(ideaAllDetails && ideaAllDetails[0]?.idea_type){
      setIdeaType(ideaAllDetails[0]?.idea_type)
      setSavingValue(ideaAllDetails[0]?.cost)  
      setOldSavingValue(ideaAllDetails[0]?.cost)
      setWeightValue(ideaAllDetails[0]?.weight)
      setOldWeightValue(ideaAllDetails[0]?.weight)
      setEaseValue(ideaAllDetails[0]?.ease)
      setOldEaseValue(ideaAllDetails[0]?.ease)
    }
      
      setIsCostVerified(ideaAllDetails[0]?.cost_verified)
      setIsWeightVerified(ideaAllDetails[0]?.no_weight_saving)
      SetCostVerifiedBy(ideaAllDetails[0]?.cost_verified_by)
    if(ideaAllDetails && ideaAllDetails[0]?.stage){
      setCurrentStage(ideaAllDetails[0]?.stage)
    }
  },[ideaAllDetails,vault_id])

  const SwitchhandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const getStatus = (status: any) => {
    switch (status) {
      case 0:
        return "Initiated";
      case 1:
        return "In Review";
      case 2:
        return "Submitted";
      case 3:
        return "Accepted";

      default:
        break;
    }
  };
  function handlePaste(e:any) {
    var clipboardData, pastedData;
  
    // Stop data actually being pasted into div
    e.stopPropagation();
    e.preventDefault();
  
    // Get pasted data via clipboard API
    clipboardData = e.clipboardData || window.Clipboard;
    pastedData = clipboardData.getData('Text');
  
    // Do whatever with pasteddata
    setSavingValue(pastedData)
    
  }
  
  

  const getOrigin = (origin: any) => {
    switch (origin) {
      case 1:
        return "BOM";
      case 2:
        return "Architecture";
      case 3:
        return "Feature";
      case 4:
        return "Costing";
      case 5:
        return "Media";
      case 7:
        return "Specification";

      default:
        break;
    }
  };

  return (
    <>
  
      <Box
        sx={{
          backgroundColor: "#e5ffe5",
          border: "1px solid",
          borderColor: "primary.light",
          borderRadius: "1rem",
          width: "100%",
          padding: "0.5rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography sx={{ width: "100%", fontSize: "1rem", fontWeight: "500" }}>Idea Details</Typography>
          <CloseIcon sx={{color:'red', cursor:'pointer'}} titleAccess='Close' onClick={()=>setIdeaDetails(false)} />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            // justifyContent: "space-evenly",
            width: "100%",
          }}
        >
          <Box sx={{width:"100%"}}>
            <Box className={styles.IdeadetailsSmallscreen}>
             <Typography className={styles.title}>
                <Typography sx={{ fontSize: "0.87rem" }}>Status</Typography>
                <Typography sx={{ fontSize: "0.87rem" }}>:</Typography>
              </Typography>    
              <Typography className={styles.ideavalue}>
                {ideaAllDetails && ideaAllDetails[0]?.rejected
                  ? "Rejected"
                  : getStatus(ideaAllDetails[0]?.stage)}
              </Typography>
            </Box>
            <Box className={styles.IdeadetailsSmallscreen}>
            <Typography className={styles.title}>
                <Typography sx={{ fontSize: "0.87rem" }}>Done by </Typography>
                <Typography sx={{ fontSize: "0.87rem" }}>:</Typography>
              </Typography>  
              <Typography className={styles.ideavalue}>
                {ideaAllDetails && ideaAllDetails[0]?.done_by}
              </Typography>
            </Box>                      
          
           <Box className={styles.IdeadetailsSmallscreen}>
              <Typography className={styles.title}>
                <Typography sx={{ fontSize: "0.87rem" }}>Client Owner </Typography>
                <Typography sx={{ fontSize: "0.87rem" }}>:</Typography>
              </Typography>
              <Typography className={styles.ideavalue}>
                {ideaAllDetails && ideaAllDetails[0]?.client_owner_name}
              </Typography>
            </Box>
            <Box className={styles.IdeadetailsSmallscreen}>
              <Typography className={styles.title}>
                <Typography sx={{ fontSize: "0.87rem" }}>Origin </Typography>
                <Typography sx={{ fontSize: "0.87rem" }}>:</Typography>{" "}
              </Typography>
              <Typography className={styles.ideavalue}>
                {ideaAllDetails && getOrigin(ideaAllDetails[0]?.origin)}
              </Typography>
            </Box> 
            <Box className={styles.IdeadetailsSmallscreen}>
            <Typography className={styles.title}>
                <Typography sx={{ fontSize: "0.87rem" }}>Idea Type</Typography>
                <Typography sx={{ fontSize: "0.87rem" }}>:</Typography>
              </Typography>
              <Select
                size="small"
                id="Idea Type"
                name="Idea Type"
                labelId="demo-select-small"
                variant="standard"
                disabled={ideaAllDetails[0]?.rejected || (ideaAllDetails[0]?.stage === 2 && !UserActionParams?.includes("U"))?true:false}
                required
                value={ideaType}
                onChange={(e:any)=>{
                  const {value}=e.target
                  setIdeaType(value)
                    API.put(`/idea/pool/${vault_id}/`,{
                        idea_type:value
                    },{module:15},0).then((res:any)=>{
                       ADMIN.toast.info('Idea type updated successfully')
                    })
                }}
              
                sx={{
                  width: "13rem",
                  // marginLeft:'0.5rem',
                  "&:before": {
                    borderBottomColor: "primary.light",
                  },                  
                  "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                    {
                      fontSize: "0.87rem",
                      color:'black',
                    },
                  ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
                }}
                MenuProps={{
                  style: {
                    maxHeight: 400,
                    color: "007fff",
                  },
                }}

                // value={age}
              >
               {IdeaTypesList?.map((item:any)=>{
                  return  <MenuItem
                  sx={{ color: "primary.main", fontSize: "1rem" }}
                  value={item?.value}
                >
                  {item?.text}
                </MenuItem>
               })}
               
              </Select>
            </Box>           
          </Box>
          <Box sx={{width:"100%"}}>
          <Box className={styles.IdeadetailsSmallscreen}>
            <Typography className={styles.titleright}>
                <Typography sx={{ fontSize: "0.87rem",display:'flex',alignItems:'center',columnGap:"0.5rem" }}>Savings Value  
                {isBoolean(isCostVerified)&&(userProfile[0]?.is_superuser || userProfile[0]?.is_project_admin) &&  <Checkbox sx={{padding:'0',color:'primary.main',marginLeft:'0.25rem'}} checked={isCostVerified && isCostVerified} disabled={UserActionParams?.includes("U")?false:true} onChange={()=>{                  
                  API.put(`/idea/pool/${vault_id}/`,{
                    cost_verified:!isCostVerified,
                    current_stage:currentStage
                },{module:15},0).then((res:any)=>{
                  setStatusCounter((prev:any)=>prev+1)
                   ADMIN.toast.info('Cost verification updated successfully')
                   setIsCostVerified((prev:any)=>!prev)
                }).catch((err:any)=>{
                  if(err.response.data){
                  const {data}=err.response
                  Swal.fire({
                    text: data[0],
                    icon: "error",
                    confirmButtonText: "OK",
                  });
                  }
                })
                }} />}</Typography>
                <Typography sx={{ fontSize: "0.87rem" }}>:</Typography>
              </Typography>             
              <TextField
                size="small"
                variant="standard"
                type="number"
                disabled={ideaAllDetails[0]?.rejected || (ideaAllDetails[0]?.stage === 2 && !UserActionParams?.includes("U"))?true:false}
                InputProps={{
                  style: { color: "#007fff" },
                }}
                value={savingValue}
                onChange={(e:any)=>{
                    const {value}=e.target
                    setSavingValue(value)
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={(e:any)=>{
                  if(oldSavingValue != savingValue){
                    const {value}=e.target
                    API.put(`/idea/pool/${vault_id}/`,{
                        cost:value
                    },{module:15},0).then((res:any)=>{
                      setOldSavingValue(value)
                      setStatusCounter((prev:any)=>prev+1)
                      if(isCostVerified){
                      API.put(`/idea/pool/${vault_id}/`,{
                        cost_verified:false,
                        current_stage:currentStage
                    },{module:15},0).then((res:any)=>{
                       ADMIN.toast.info('Savings verification updated successfully')
                       setIsCostVerified((prev:any)=>!prev)
                    }).catch((err:any)=>{
                      if(err.response.data){
                      const {data}=err.response
                      Swal.fire({
                        text: data[0],
                        icon: "error",
                        confirmButtonText: "OK",
                      });
                      }
                    })}
                       ADMIN.toast.info('Saving value updated successfully')
                    })
                  }
                }}
                sx={{
                  width: "7rem",
                  // marginLeft:'0.5rem',
                  "& .Mui-error": {
                    color: "#d32f2f",
                    borderBottomColor: "#d32f2f",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "primary.light",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "primary.main",
                  },
                  "& .MuiFormLabel-root.MuiInputLabel-root ": {
                    color: "primary.main",
                  },
                  ".MuiInputBase-root.MuiInput-root": {
                    fontSize: "0.87rem !important",
                    color: 'black !important',
                  },
                }}
                id="Savings Value "
                name="Savings Value "
              />
            </Box>
            <Box className={styles.IdeadetailsSmallscreen}>
            <Typography className={styles.titleright}>
                <Typography sx={{ fontSize: "0.87rem",display:'flex',alignItems:'center',columnGap:"0.5rem" }}>Wt. Savings(Kg)  
                {isBoolean(isWeightVerified) &&  <Checkbox sx={{padding:'0',color:'primary.main'}} checked={isWeightVerified && isWeightVerified} onChange={()=>{
                  
                  API.put(`/idea/pool/${vault_id}/`,{
                    no_weight_saving:!isWeightVerified,
                    current_stage:currentStage
                },{module:15},0).then((res:any)=>{
                   ADMIN.toast.info('Cost verification updated successfully')
                   setIsWeightVerified((prev:any)=>!prev)
                }).catch((err:any)=>{
                  if(err.response.data){
                  const {data}=err.response
                  Swal.fire({
                    title: data[0],
                    icon: "error",
                    confirmButtonText: "OK",
                  });
                  }
                })
                }} />}</Typography>
                <Typography sx={{ fontSize: "0.87rem" }}>:</Typography>
              </Typography>
              <TextField
                variant="standard"
                value={weightValue}
                type="number"
                onChange={(e:any)=>{
                    const {value}=e.target
                    setWeightValue(value)
                }}
                disabled={ideaAllDetails[0]?.rejected || (ideaAllDetails[0]?.stage === 2 && !UserActionParams?.includes("U"))?true:false}
                onPaste={(e:any)=>handlePaste(e)}
                InputProps={{
                  style: { color: "#007fff" },
                }}
              
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={(e:any)=>{
                  if(oldWeightValue != weightValue){
                    const {value}=e.target
                    API.put(`/idea/pool/${vault_id}/`,{
                        weight:value
                    },{module:15},0).then((res:any)=>{
                      setOldWeightValue(value)
                       ADMIN.toast.info('Weight updated successfully')
                    })
                  }
                }}
                sx={{
                  width: "7rem",
                  // marginLeft:'0.5rem',
                  "& .Mui-error": {
                    color: "#d32f2f",
                    borderBottomColor: "#d32f2f",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "primary.light",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "primary.main",
                  },
                  "& .MuiFormLabel-root.MuiInputLabel-root ": {
                    color: "primary.main",
                  },
                  ".MuiInputBase-root.MuiInput-root": {
                    fontSize: "0.87rem !important",
                    color: 'black !important',
                  },
                }}
                id="Wt. Savings(g)"
                name="Wt. Savings(g)"
              />
            </Box>
            <Box className={styles.IdeadetailsSmallscreen}>  
            <Typography className={styles.titleright}>
                <Typography sx={{ fontSize: "0.87rem", }}>Cost Verified By</Typography>
                <Typography sx={{ fontSize: "0.87rem" }}>:</Typography>
              </Typography> 
              {isCostVerified && <Typography className={styles.ideavalue}>
                {ideaAllDetails && ideaAllDetails[0]?.cost_verifier}
                   {/* ? "Completed"
                   : "Not completed"} */}
              </Typography>}
            </Box>
            <Box className={styles.IdeadetailsSmallscreen}>              
              <Typography className={styles.titleright}>
                <Typography sx={{ fontSize: "0.87rem" }}>Duration(Days)</Typography>
                <Typography sx={{ fontSize: "0.87rem" }}>:</Typography>
              </Typography>               
              <Typography className={styles.ideavalue}>
                {ideaAllDetails && ideaAllDetails[0]?.duration}
              </Typography>
            </Box>
            <Box className={styles.IdeadetailsSmallscreen}>
              <Typography className={styles.titleright}>
                  <Typography sx={{ fontSize: "0.87rem" }}>Ease (1-10)</Typography>
                  <Typography sx={{ fontSize: "0.87rem" }}>:</Typography>
              </Typography> 
              <TextField
                variant="standard"
                InputProps={{
                  style: { color: "#007fff" },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                type="number"
                disabled={ideaAllDetails[0]?.rejected || (ideaAllDetails[0]?.stage === 2 && !UserActionParams?.includes("U"))?true:false}
                value={easeValue}
                onChange={(e:any)=>{
                    const {value}=e.target
                    setEaseValue(value)
                }}
                onBlur={(e:any)=>{
                  if(oldEaseValue != easeValue){
                    const {value}=e.target
                    API.put(`/idea/pool/${vault_id}/`,{
                        ease:value
                    },{module:15},0).then((res:any)=>{
                      setOldEaseValue(value);
                       ADMIN.toast.info('Ease updated successfully')
                    })
                  }
                }}
                sx={{
                  width: "7rem",
                  // marginLeft:'0.5rem',
                  "& .Mui-error": {
                    color: "#d32f2f",
                    borderBottomColor: "#d32f2f",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "primary.light",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "primary.main",
                  },
                  "& .MuiFormLabel-root.MuiInputLabel-root ": {
                    color: "primary.main",
                  },
                  ".MuiInputBase-root.MuiInput-root": {
                    fontSize: "0.87rem !important",
                    color: 'black !important',
                  },
                }}
                id="Ease (1-10)"
                name="Ease (1-10)"
              />
            </Box>
          </Box>
        </Box>
        
      </Box>
    </>
  );
}
