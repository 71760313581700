import { TextField } from "@mui/material";
import * as React from "react";
import { API } from "../../api-services";
import { debounce } from "lodash";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { UserContextIdea } from "./IdeaConfigurationLanding";
import { isDisabled } from "@testing-library/user-event/dist/utils";

export interface IEditSequenceProps {
  val?: any;
  paramId?: any;
  getParamsData: any;
  setCount: any;
  type?: any;
}

export function EditSequence(props: IEditSequenceProps) {
  const { val, paramId, getParamsData, setCount, type } = props;
  const { projectId } = useRouteParams<any>();
  const [sequence, setSequence] = React.useState<any>(val);
  const userPremissions = React.useContext(UserContextIdea)

  //   const debouncedAPICall = debounce((value: any) => {
  //     API.put(
  //       `/bom/parameter_mapping/${paramId}/`,
  //       {
  //         editable: value,
  //       },
  //       {},
  //       0
  //     )
  //       .then((res: any) => {})
  //       .catch((err: any) => {
  //         setSequence((prev: any) => prev);
  //       });
  //   }, 500);
  const ExactDebounce = React.useRef(
    debounce(async (value: any) => {
      if(type==="Cost Model"){
        await API.put(`/config/idea_config_cost_model/${paramId}/`,{
          sequence: parseInt(value),
        },{},0).then((res:any)=>{
            getParamsData()
            API.patch(
              `/xcpep/project/${projectId}/`,
              {
                idea_config: false,
              },
              {},
              0
            )
              .then((res: any) => {
                setCount((prev:any)=>prev+1)            })
              .catch((err: any) => {      
        
              });
              setCount((prev: any) => prev + 1)
        }).catch((err: any) => {
          setSequence((prev: any) => prev);
        });
      }
      else{
      await API.put(
        `/bom/parameter_mapping/${paramId}/`,
        {
          sequence: parseInt(value),
        },
        {},
        0
      )
        .then((res: any) => {
          getParamsData()
          API.patch(
            `/xcpep/project/${projectId}/`,
            {
              idea_config: false,
            },
            {},
            0
          )
            .then((res: any) => {
              setCount((prev: any) => prev + 1)
            })
            .catch((err: any) => {

            });
          setCount((prev: any) => prev + 1)
        })
        .catch((err: any) => {
          setSequence((prev: any) => prev);
        });
    }}, 500)
  ).current;

  React.useEffect(() => {
    return () => {
      ExactDebounce.cancel(); // Cancel the debounced function
    };
  }, []);

  return (
    <div>
      <TextField
        // id="outlined-number"
        // label="Number"
        value={sequence}
        type="number"
        variant="standard"
        inputProps={{
          step: 'any',
          min: 1,
          // style: { fontSize: '1rem', marginTop: '0.4rem' }
        }}
        disabled={userPremissions && userPremissions?.includes("U") ? false : true}
        onChange={(event: any) => {
          const { value } = event.target;
          setSequence(value);
          ExactDebounce(value);
        }}
        InputLabelProps={{
          shrink: true,
        }}
        sx={{
          width: '4rem',
          ".MuiInputBase-root.MuiInput-root":{
            fontSize:'1rem !important'
          },	
          "& .MuiInput-underline:before": {
            borderBottomColor: "primary.light",
          },
        }}
      />
    </div>
  );
}
