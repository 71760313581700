import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import styles from "./SearchModal.module.scss";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { API } from "../../api-services";
import { ADMIN } from "../../Redux/Services/admin.service";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Checkbox from "@mui/material/Checkbox";
import { debounce } from "lodash";
import { useHistory } from "react-router-dom";
import Chip from "@mui/material/Chip";
import { fontSize } from "@mui/system";
import RestoreIcon from "@mui/icons-material/Restore";
// import FastForwardIcon from "@mui/icons-material/FastForward";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
// import EditIcon from '@mui/icons-material/Edit';
import { makeStyles } from '@mui/styles';




const styled = {
  boxSize: {
    position: "absolute" as "absolute",
    top: "43%",
    left: "50%",
    transform: "translate(-50%, -50%)",

    width: "39rem",
    height: "37rem",
    borderRadius: "0.5rem",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 0,
    outline: 0
  },
  HeaderLine: {
    textAlign: "center",
    position: "relative",
  },
  SelectedHeaderLine: {
    color: "#1976d2",
  },
  nextButton: {
    position: "absolute",

    zIndex: "9999",
    left: "34rem",
    top: "1.8rem",
  },

  summery: {
    backgroundColor: "rgb(240, 247, 255)",
    borderColor: " rgb(0, 127, 255)",
    borderRadius: "10px",
    minHeight: "45px",
  },
  searchButton: {
    position: "absolute",

    // right: "37rem",
    top: "0.4rem",

    left: "-2rem",
    cursor: "default",
    color: "#1976d2",
    padding: " 0rem 0rem 0rem 2rem",
  },
  closedIcon: {
    position: "absolute",
    zIndex: "999",
    color: "#1976d2",
    right: "0.2rem",
    cursor: "pointer"
  },
  editIcon: {
    marginTop: "0.3rem",
    color: "#1976d2",
    cursor: "pointer"

  },
 


};
// @ts-ignore

const useStyles = makeStyles((theme) => ({
  /** Changed modalStyle */
  ModalRoot:
  {
    backgroundColor: "rgba(111, 126, 140, 0.2) !important",
    backdropFilter: "blur(1px) !important",
  },
}));
const label = { inputProps: { "aria-label": "Checkbox demo" } };

export interface SearchModalProps {
  isOpen: any;
  onCloseModal: any;
  type?: any;
  ProductData?: any;
}
const SearchModal = (props: SearchModalProps) => {
  const classes = useStyles();

  const { isOpen, onCloseModal, type, ProductData } = props;
  const [checkboxValue, setCheckboxValue] = useState<any>([]);
  const [SearchBox, setSearchBox] = useState<any>(false);
  const [AllProject, setAllProject] = useState<any>();
  const history = useHistory<any>();
  const [selectedValue, setSelectedValue] = React.useState<any>();

  const [LableName, setLabelName] = useState<any>([]);

  const [activeIndexArray, setActiveIndexArray] = useState<any>([]);
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const [SearchLogs, setSearchLogs] = useState<any>();
  const CheckBoxRef=useRef<any>()



  useEffect(() => {
    if(isOpen){
    API.get("/cost/dashboard/", { all_vehicles: true })
      .then((res: any) => {
        setAllProject(res?.data);
      })
      .catch((err) => {
        // ADMIN.toast.throwError(err, "Server Error");
      });
    }
  }, [isOpen]);

  const CheckBoxHandler = (e: any, id: any, ind: any, name: any, type: any) => {
    var ProjectId =
      AllProject &&
      AllProject.map((project: any, index: any) => {
        return project.id;
      });

     
   

    if (type == "checkbox") {
      if (e.target.checked == true) {
        activeIndexArray.push(ind);
        // setActiveIndexArray( ind );
        // var datavalue:any={}
        // datavalue["id"]=id,
        // datavalue["index"]=ind,
        // datavalue["lable"]=name,
        setLabelName((prev: any) => [
          ...LableName,
          { name: name, index: ind, id: id },
        ]);
        setCheckboxValue((prev: any) => [...checkboxValue, id]);
      }

      if (e.target.checked == false) {



        setLabelName((prev: any) =>
          prev.filter((item: any) => item.index !== ind)
        );
        setCheckboxValue((prev: any) =>
          prev.filter((item: any) => item !== id)
        );
        // setActiveIndexArray((prev: any) =>
        //   prev.filter((item: any) => item !== ind)
        // );
      }
    }
    else if (type == "delete") {
      if (e === false) {
        // const filterdata: any = productDefaultValue.filter( ( data: any ) => !datavalue.includes( data ) );
        setLabelName((prev: any) =>
          prev.filter((item: any) => item.index !== ind)
        );
        setCheckboxValue((prev: any) =>
          prev.filter((item: any) => item !== id)
        );
        // setActiveIndexArray((prev: any) =>
        //   prev.filter((item: any) => item !== ind)
        // );
      }
    }

    // if(e.target.checked===true){
    //     setCheckboxValue([...selectedValue,id])
    // }
    // else if(e.target.checked===false)

    // {
    //     var filterData=AllProject.filter((item:any,ind:any)=>item?.id==id)
    //     setCheckboxValue([filterData])
    // }
  };



  // const handleOnChange = async (event: any) => {
  //   setSelectedValue(event.target.value);

  //   if (event.key == "Enter") {
  //     console.log("ProjectIdCurrent",checkboxValue)
  //     debouncedSearch(event.target.value, checkboxValue);

  //   }
  // };



  // const debouncedSearch = (criteria?: any, selectedIds?: any, recentId?: any) => {
  //   sessionStorage.setItem("ProjectId", selectedIds);
  //   // sessionStorage.setItem("SearchKey", criteria);
  //   // sessionStorage.setItem("ActiveIndex" ,activeIndexArray)
  //   sessionStorage.setItem("LableName",JSON.stringify(LableName))
  //   // console.log(recentId,"recentIdrecentId")
  //   // sessionStorage.setItem("recentKey", recentId);
  //   // console.log(recentId,"recentIdrecentId")
  //   //  console.log("searchDatat")
  //   history.push(`/search`);
  //   onCloseModal();
  //   setSearchBox(false);
  // };

  // useEffect(() => {
  //   API.get("/cost/search_logs/").then((res: any) => {
  //     setSearchLogs(res.data);
  //   });
  // }, []);

  // const debouncedSearch = React.useRef(
  //   debounce(async (criteria) => {
  //     if (criteria === "") {
  //       sessionStorage.removeItem("SearchKey");
  //       history.goBack();
  //     }
  //     sessionStorage.setItem("SearchKey", criteria);
  //     history.push(`/search`);
  //   })
  // ).current;

  // var data=sessionStorage.getItem("editkey");
  // useEffect(()=>{
  //   setSearchBox(data)
  //   con
  // },[data])


  const ProjectId: any = sessionStorage.getItem("ProjectId")
  const ProjectIdCurrent = ProjectId?.replaceAll('"', '')
  const SearchKey = sessionStorage.getItem("SearchKey");
  const ActiveIndex: any = sessionStorage.getItem("ActiveIndex");

  const LableNamedata: any = sessionStorage.getItem("LableName");
  const LableNamecurrent = JSON.parse(LableNamedata)

  useEffect(() => {
    if (type == "editModal") {
      setSelectedValue(SearchKey)
      // @ts-ignore
      const saveProjectId: any = ProjectId && ProjectId?.split(',')?.map(Number);

      if (ProductData !== undefined) {
        setCheckboxValue(ProductData)
      }
      // if(saveProjectId!==null){
      //   setCheckboxValue(saveProjectId)
      // }

      const saveArrayVal: any = ActiveIndex && ActiveIndex?.split(',')?.map(Number);
      if (saveArrayVal != null) { setActiveIndexArray(saveArrayVal) }
      setLabelName(LableNamecurrent)
    }
  }, [type, SearchKey, ProjectId, LableNamedata, ProductData])







  // const RecentHandler = (e: any, id: any,searchKey:any) => {
  //   debouncedSearch(searchKey,null,id);

  //   // if(event.key=="Enter){
  //   //   debouncedSearch(event.target.value,checkboxValue)
  //   //   setSelectedValue("")

  //   //     }
  // };

  const ClosedModal = () => {
    if (type !== "editModal") {
      setActiveIndexArray([])
      setSelectedValue("");
      setLabelName([])
      onCloseModal()
      sessionStorage.removedItem("ProjectId");
    }
    onCloseModal()
  }



  useEffect(() => {
    if (activeIndexArray.length == 0) {
      GobackHandler()
    }
  }, [activeIndexArray])

  const GobackHandler = () => {
    setSearchBox(false)
  }


  const debouncedSearchNext = (selectedIds: any) => {
    sessionStorage.setItem("ProjectId", selectedIds);
    // sessionStorage.setItem("SearchKey", criteria);
    // sessionStorage.setItem("ActiveIndex" ,activeIndexArray)
    sessionStorage.setItem("LableName", JSON.stringify(LableName))
    // console.log(recentId,"recentIdrecentId")
    // sessionStorage.setItem("recentKey", recentId);
    // console.log(recentId,"recentIdrecentId")
    //  console.log("searchDatat")
    history.push(`/search`);
    onCloseModal();
    setSearchBox(false);
  };


  const ProjectSelectionHandler = () => {
   
    if (checkboxValue.length > 0) {
      sessionStorage.setItem("ProjectId", checkboxValue);
      debouncedSearchNext(checkboxValue)
    }
  };





  const handleDelete = (e: any, id: any, ind: any, name: any, type: any) => {
    CheckBoxHandler(e, id, ind, name, type);

  };
  var AllVehicleIds=  AllProject && AllProject.map((item:any,index:any)=>item.vehicle_id)
  const handleSelectedAll=(type:any)=>{
   var value= CheckBoxRef.current==AllVehicleIds
    if(type=="selcetedAll"){
     
       setCheckboxValue(AllVehicleIds)
      //  sessionStorage.setItem("ProjectId", AllVehicleIds);
    }else if(type=="clearAll"){
      setCheckboxValue([])
    }

  }


  console.log(CheckBoxRef,"checkboxValuecheckboxValue")


  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}

      <Modal // autoFocusdal
        open={isOpen}
        className={classes.ModalRoot}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styled.boxSize}>
          <div title="Close" className={styles.closeIcon}>
            <CloseIcon sx={styled.closedIcon} onClick={() => ClosedModal()} />
          </div>
          {<Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className={styles.btnsearch}>
              <Button
                className={styles.nextbtn}
                onClick={() => ProjectSelectionHandler()}
                sx={styled.nextButton}
              >
                <ArrowForwardIosIcon />
              </Button>
            </div>
            <Accordion expanded={expanded === "panel1"}>
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={styled.summery}
              >
                <Typography sx={styled.SelectedHeaderLine}>
                  Select Product For Search
                </Typography>
                <div className={styles.BtnClearSelect}>
                  <Button variant="contained" className={styles.searchbtn} onClick={()=>{handleSelectedAll("selcetedAll")}} title="Select All Product"> select All</Button>              
                  <Button variant="contained" className={styles.searchbtn} onClick={()=>{handleSelectedAll("clearAll")}} title="Clear All Product"> Clear All</Button>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className={styles.searchConatiner}>
                  {AllProject &&
                    AllProject?.map((item: any, index: any) => {
                      return (
                        <>
                          <div>
                            <Checkbox
                              onChange={(e: any) =>
                                CheckBoxHandler(
                                  e,
                                  item?.vehicle_id,
                                  index,
                                  item?.vehicle_name,
                                  "checkbox"
                                )
                              }
                            
                              checked={
                                checkboxValue?.includes(item?.vehicle_id)
                                  ? true
                                  : false
                              }
                              {...label}
                            
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 25 }, color: "#007fff" }}
                            />
                            <span style={{ color: "#007fff" }}>{item?.vehicle_name}</span>
                          </div>
                        </>
                      );
                    })}
                </div>
              </AccordionDetails>
            </Accordion>
          </Typography>}

          {/* {SearchBox=="true" || SearchBox ? (
            <div className={styles.serach_container}>

              <div className={styles.chipContainer}>
                <div className={styles.selectedData}>
                {LableName.map((itemData: any, index: any) => {
                  return (
                    <>
                      <Chip
                        sx={{ marginLeft: "0.3rem", marginBottom: "0.3rem" }}
                        label={itemData.name}
                        color="primary"
                        title="Delete"
                        variant="outlined"
                        onDelete={() =>
                          handleDelete(
                            false,
                            itemData.id,
                            itemData.index,
                            itemData.name,
                            "delete"
                          )
                        }
                        // onClick={handleClick}
                      />
                    </>
                  );
                })}

<span title="Update Project Selection">
                <EditIcon sx={styled.editIcon} color="primary"  onClick={()=>GobackHandler()}/>
                </span>
                </div>
               
              </div>


              <div>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  sx={styled.HeaderLine}
                >
                  <input
                    // type="text"

                    placeholder="Search Part no or Part Name..."
                    // value={selectedValue && selectedValue}
                    className={styles.InputSearchData}
                    //   style={{cursor:"pointer"}}
                    onKeyPress={(event: any) => handleOnChange(event)}
                    value={selectedValue && selectedValue}
                    onChange={(event: any) => handleOnChange(event)}
                    autoFocus
                  />

                  <IconButton
                    className={styles.searchIcon}
                    type="button"
                    sx={styled.searchButton}
                    aria-label="search"
                  >
                    <SearchIcon sx={{ fontSize: "1.7rem" }} />
                  </IconButton>
                </Typography>
              </div>

              <div className={styles.serachlogs}>
                <span className={styles.searchName}>Recent Search</span>
                <div className={styles.searchlogdata}>

              
                  {SearchLogs &&
                    SearchLogs.map((logs: any, index: any) => {
                      return (
                        <>
                          <div className={styles.recentSearch}>
                            <span className={styles.insideData}  onClick={(e: any) => RecentHandler(e, logs?.id,logs?.search_key)}>
                              <span
                                style={{
                                  marginRight: "1rem",
                                  marginBottom: "0.2rem",
                                }}
                              >
                                <RestoreIcon />
                              </span>
                              <span
                               
                              >
                             
                                {logs.search_key}
                              </span>
                            </span>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          ) : (
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div className={styles.btnsearch}>
                <Button
                  onClick={() => ProjectSelectionHandler()}
                  sx={styled.nextButton}
                >
                  <FastForwardIcon />
                </Button>
              </div>
              <Accordion expanded={expanded === "panel1"}>
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  sx={styled.summery}
                >
                  <Typography sx={styled.SelectedHeaderLine}>
                    Select Project
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={styles.searchConatiner}>
                    {AllProject &&
                      AllProject?.map((item: any, index: any) => {
                        return (
                          <>
                            <div>
                              <Checkbox
                                onChange={(e: any) =>
                                  CheckBoxHandler(
                                    e,
                                    item?.id,
                                    index,
                                    item?.name,
                                    "checkbox"
                                  )
                                }
                                checked={
                                  checkboxValue?.includes(item?.id)
                                    ? true
                                    : false
                                }
                                {...label}
                                defaultChecked
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                              />
                              <span>{item?.name}</span>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </AccordionDetails>
              </Accordion>
            </Typography>
          )} */}



        </Box>
      </Modal>
    </div>
  );
};
export default SearchModal;
