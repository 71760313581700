import { Box, IconButton, Modal, Tooltip, Typography } from '@mui/material';
import React from 'react'
import CancelIcon from '@mui/icons-material/Cancel';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ReplyIcon from '@mui/icons-material/Reply';
import LinkIcon from '@mui/icons-material/Link';
import OpenInNew from '@mui/icons-material/OpenInNew';


const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: " 50vw",
    height: "fit-content",
    bgcolor: "background.paper",
    borderRadius: "10px",
    p: 1,
  };

function SupplierDeleteRestrictionModal({openModal, handleCloseModal, supplierId, partList}:any) {





  // const fetchPartsData = async () => {
  //   setLoading(true);
  //   try {
  //     const result = await API.get(`db/check_variant/`, {
  //       parts_info: true,
  //       page: page,
  //       id: supplier,
  //     });

  //     setPartList(result.data);

  //     if (result.data?.count / 100 < page) {
  //       setHasMore(false);
  //     }

  //     return;
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     return null;
  //   } finally {
  //     setLoading(false);
  //   }
  // };

    // Dummy data
    const dummyData: any[] = [
      { id: 1, name: 'Part A', bomUrl: 'http://example.com/bom/1', costingUrl: 'http://example.com/costing/1', msp: 100 },
      { id: 2, name: 'Part B', bomUrl: 'http://example.com/bom/2', costingUrl: 'http://example.com/costing/2', msp: 120 },
      { id: 3, name: 'Part C', bomUrl: 'http://example.com/bom/3', costingUrl: 'http://example.com/costing/3', msp: 150 },
    ];
  
    // Define columns with icons for URLs
    const columns: GridColDef[] = [
      { field: 'id', headerName: 'ID', width: 90 },
      { field: 'name', headerName: 'Name', width: 150 },
     
      {
        field: 'drawing',
        headerName: 'BOM URL',
        width: 150,
        renderCell: (params) => (
          <Tooltip title="BOM URL">
            <IconButton
              component="a"
              href={params.value}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="BOM URL"
              onClick={()=> {

                const url = `/#/bomentry/${params?.row?.category?.id}/${params?.row?.category?.name}/0/${params?.row?.id}/${params?.row?.subSystem || "none"}/0/false/false/false/false`;
                window.open(url, '_blank');
            }} 
            >
              <OpenInNew />
            </IconButton>
          </Tooltip>
        ),
      },
      {
        field: 'cad_document',
        headerName: 'Costing URL',
        width: 150,
        renderCell: (params) => (
          <Tooltip title="Costing URL">
            <IconButton
              component="a"
              href={params.value}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Costing URL"
              onClick={()=> {
                //const url = `/#/bomentry/${cellValues?.row?.category?.id}/${cellValues?.row?.category?.name}/0/${cellValues?.row?.id}/${cellValues?.row?.category?.subSystem || "none"}/0/false/false/false/false`;
                const url = `/#/createcostingpart/${params?.row?.category?.id}/${params?.row?.category?.name}/${params?.row?.name}/0/${params?.row?.id}/none/0/0/0/0/0/0/0/0/0`;
                window.open(url, '_blank');
            }}
            >
              <OpenInNew />
            </IconButton>
          </Tooltip>
        ),
      },
      {
        field: 'category',
        headerName: 'Category',
        width: 150,
        renderCell: (params) => (
          
           <Typography>
              {params?.row?.category?.name || "--"}
           </Typography>
       
        ),
      },
    ];


    const rows = partList?.map((item: any, index: number) => {
      return { ...item, Id: index + 1 };
    });
 
    
  return (
    <Modal
    open={openModal}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style}> 
    <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Delete Supplier
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={handleCloseModal} />
          </Box>


          <Box sx={{}}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "400",
                width: "100%",
                textAlign: "center",
                marginTop: '1rem',
                color: 'red'
              }}
            >
              This seller cannot be deleted because the following parts are associated with it.
            </Typography>


            <Box sx={{ height: '27rem', width: '100%', marginTop:"1rem", }}  adl-scrollbar="true"
              adl-scrollbar-width="0.3"> {/* Adjusted height for DataGrid */}
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
          />
        </Box>
          </Box>

    </Box>
  
    </Modal>
  )
}

export default SupplierDeleteRestrictionModal