import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { API } from "../../api-services";
import CancelIcon from '@mui/icons-material/Cancel';
import success from "../../Assets/images/success.png";

export interface ProjectDirectoryModalProps {
  isOpen: any;
  onCloseModal: any;
  type: any;
  setCounter: any;
  projectIds: any
  FormType: any;
  ProjectData: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: '40%', xl: "35%" },
  bgcolor: "background.paper",
  borderRadius: '10px',
  // border: '2px solid #000',
  // boxShadow: 24,
  p: 1,
};

const ProjectDirectoryModal = (props: ProjectDirectoryModalProps) => {
  const { isOpen, onCloseModal, type, setCounter, projectIds, FormType, ProjectData } = props;

  const [Name, setName] = useState<any>("");

  const [Description, setDescription] = useState<any>("");
  const [namemsg, setnamemsg] = useState<any>(false);
  const [desmsg, setdesmsg] = useState<any>(false);

  const [Successmsg, setSuccessmsg] = useState<any>(false);

  const handelChange = (e: any, type: any) => {
    if (type == "name") {
      setnamemsg(false);
      setName(e.target.value);
    } else if (type == "discription") {
      setdesmsg(false);
      setDescription(e.target.value);
    }
  };


  // console.log(ProjectData, Name, Description, "ProjectDataProjectData")

  useEffect(() => {

    if (FormType == "Add") {


      setDescription("");
      setName("");
      setSuccessmsg(false);
    }

    if (FormType == "Edit") {
      setDescription(ProjectData && ProjectData?.description);
      setName(ProjectData && ProjectData?.name);
      setSuccessmsg(false);
    }
  }, [FormType, ProjectData]);


  const ClosedHandler = () => {
    if (FormType == "Add") {
      onCloseModal();
      setSuccessmsg(false);
      setDescription("");
      setName("");
    } else {
      onCloseModal();
      setSuccessmsg(false);

    }
    // if(FormType=="Edit"){
    //   setDescription(ProjectData && ProjectData?.description);
    //   setName(ProjectData && ProjectData?.name);
    //   setSuccessmsg(false);
    //   onCloseModal();
    //  }

  };


  // console.log(FormType, "FormTypeFormType")
  const savehandler = () => {


    // console.log(Name, Description, "hicscscihdiivhidvidvhi")

    {


      if (Name !== null && Description !== null && Name?.length > 0 && Description?.length > 0) {
        if (FormType == "Add") {


          API.post("/xcpep/project/", {
            name: Name,

            type: projectIds,
            description: Description,
          }).then((res: any) => {
            setSuccessmsg(true);

            setDescription(null);
            setName(null);
            setCounter((prev: any) => prev + 1);
          });
        }



        if (FormType == "Edit") {
          API.put(`/xcpep/project/${projectIds}/`, {
            name: Name,


            description: Description,
          }).then((res: any) => {
            setSuccessmsg(true);

            setDescription(null);
            setName(null);
            setCounter((prev: any) => prev + 1);
          })
        }
      }
    }

    if (Name == null || Name?.length == 0) {
      setnamemsg(true);
    }

    if (Description == null || Description?.length == 0) {
      setdesmsg(true);
    }



  };

  return (
    <div>
      <Modal
        keepMounted
        open={isOpen}
        // onClose={ClosedHandler}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >


        <Box sx={style}>          
          {!Successmsg ? <>
            <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "center",
                  marginRight: '-2rem'
                }}
              >

                {FormType == "Edit" ? "Edit Product" : "Add Product"}

              </Typography>
              <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={ClosedHandler} />
            </Box>
            <Divider
              sx={{
                borderColor: "primary.light",
                margin: "0 1rem",
                marginBottom: "1rem",
              }}
            />
          </>
            : ""}

          {Successmsg ? (
            <div>
              <Box sx={{ textAlign: 'center' }}>
                <img src={success} style={{ width: '7rem' }} />
              </Box>
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  // width: "100%",
                  textAlign: "center",
                  margin: "1rem",
                  color: "green",
                }}
              >

                {FormType == "Edit" ? "Product Updated Successfully!" : "Product Added Successfully!"}

                {/* Feedback for View Page */}
              </Typography>
              <Box sx={{ width: '100%', textAlign: 'center' }}>
                <Button size="small" sx={{
                  "&:hover": {
                    transform: 'Scale(1.1)',
                    transition: 'transform 0.5s ease',
                  }
                }} variant="contained" onClick={() => ClosedHandler()}>Ok</Button>
              </Box>
            </div>
          ) : (
            <div>
              <Box sx={{ display: "flex", justifyContent: "space-evenly", columnGap: '1rem', padding: '0 1rem' }}>
                <TextField
                  value={Name}
                  error={namemsg}
                  focused
                  onChange={(e: any) => handelChange(e, "name")}
                  //   sx={{color:"red !important"}}

                  sx={{
                    width: '50%',
                    input: {
                      //    color: 'red',
                      "&::placeholder": {
                        // <----- Add this.
                        opacity: 1,
                      },
                    },
                    // '& .MuiInput-underline:before': { borderBottomColor: 'primary.main' },
                    // '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                  }}
                  id="standard-size-normal"
                  label="Name *"
                  variant="standard"
                // helperText={namemsg ? "" : "Incorrect entry."}

                //   placeholder="Name"
                />


                <TextField
                  error={desmsg}
                  value={Description}
                  onChange={(e: any) => handelChange(e, "discription")}
                  //   sx={{color:"red !important"}}
                  sx={{
                    width: '100%',
                    input: {
                      //    color: 'red',
                      "&::placeholder": {
                        // <----- Add this.
                        opacity: 0,
                      },
                    },
                    // '& .MuiInput-underline:before': { borderBottomColor: 'primary.main' },
                    // '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                  }}
                  label="Description *"
                  variant="standard"

                  focused
                  id="standard-size-normal"
                // helperText={desmsg ? "" : "Incorrect entry."}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "right",
                  marginTop: "2rem",
                  padding: '0 1rem'
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => savehandler()}
                >
                  {FormType == "Edit" ? "Update" : "OK"}
                </Button>
              </Box>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default ProjectDirectoryModal;
