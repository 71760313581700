import * as React from "react";
import Modal from "@mui/material/Modal";
import {
  Box,
  Divider,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CDN_URL from "../../utlis/CDN_URL";
import { useState } from "react";

interface IPartImageModelProps {
  onCloseModal: any;
  isOpen: any;
  partdetails: any;
  type: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "60vw", xl: "55vw" },
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

export default function ShowDescriptionModal(props: IPartImageModelProps) {
  const { onCloseModal, isOpen, partdetails, type } = props;

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "1rem",
              marginBottom: "0.5rem",
            }}
          >
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Part {type === "comments" ? "Comments" : "Descriptions"} by{" "}
              {partdetails?.supplier_name}
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box
            sx={{ maxHeight: "80vh" }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            <TextField
              value={
                type === "comments"
                  ? partdetails?.comments
                  : partdetails?.description
              }
              fullWidth
              multiline
              minRows={4} // Adjust this number to fit the paragraph as needed
              InputProps={{
                readOnly: true,
              }}
              variant="outlined" // You can choose the variant that fits your design: outlined, filled, standard
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
