import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import CancelIcon from '@mui/icons-material/Cancel';
import {
	Divider,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Skeleton,
	Input,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import { API } from '../../api-services';
import { ScenarioColors } from './ScenarioColors.constants';
import { tab } from '@testing-library/user-event/dist/tab';
import { EditNewAssumptionDropdown } from './EditNewAssumptionDropdown';
import PartCountDetailsModal from './PartCountDetailsModal';
import { useBaseModal } from '../SearchPage/useBaseModal';
import { useBaseParamsModal } from '../../CustomHook/useBaseParamsModal';
import Swal from 'sweetalert2';
import { useState } from 'react';

export interface IEditScenarioModalProps {
	isOpen: any;
	onCloseModal: any;
	exsistingData?: any;
	getTableData?: any;
	getCompareGraphData?: any;
	getTotalCompareGraphData?: any;
}
const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: { xs: '86vw', sm: '35vw' },
	height: 'fit-content',
	bgcolor: 'background.paper',
	borderRadius: '10px',
	p: 0,
};

export function EditScenarioModal(props: IEditScenarioModalProps) {
	const {
		isOpen,
		onCloseModal,
		exsistingData,
		getTableData,
		getCompareGraphData,
		getTotalCompareGraphData,
	} = props;
	const partCountDetails = useBaseParamsModal();
	const [selectedScenario, setSelectedScenario] = React.useState<any>({});
	const [loader, setLoader] = React.useState<any>(false);
	const [tableData, setTableData] = React.useState<any>([]);
	const [assumptionsList, setAssumptionsList] = React.useState<any>([]);
	const [selectedAssumption, setSelectedAssumption] = React.useState<any>({});
	const [selectedColor, setSelectedColor] = React.useState<any>('');
	const [assumptionTableData, setAssumptionTableData] = React.useState<any>([]);
	const [savedScenarioId, setSavedScenarioId] = React.useState<any>('');
	const [colorList, setColorList] = React.useState<any>([]);
	const [assumptionTableDataLoading, setAssumptionTableDataLoading] =
		React.useState<any>(false);

	const [isShowTable, setIsShowTable] = React.useState<boolean>(false);
	const [scenarioName, setScenarioName] = React.useState<any>('');

	//   const handleChange = (event: SelectChangeEvent) => {
	//     setAge(event.target.value as string);
	//   };

	// color set
	const [colorSelect, setColorSelect] = useState<any>();
	const [error, setError] = useState<boolean>(false);
	const handleColorSelect: any = (e: any) => {
		setColorSelect(e.target.value);
	};

	React.useEffect(() => {
		if (Object.keys(exsistingData).length > 0 && tableData.length > 0) {
			setSelectedScenario(
				tableData.find((item: any) => item.id === exsistingData?.created_from)
			);
			setScenarioName(exsistingData?.name);
			setColorSelect(exsistingData?.color);
			setSavedScenarioId(exsistingData?.id);
			getAssumptionDataTableUpdate(
				exsistingData?.id,
				tableData.find((item: any) => item.id === exsistingData?.created_from)
			);
		}
	}, [exsistingData, tableData]);

	const [assumptionLoader, setAssumptionLoader] = React.useState<any>();
	const { projectId, projectName, topVault, vault, scenarioId, treeabbr } =
		useRouteParams<any>();
	const handleChange = (event: SelectChangeEvent, itemMain?: any) => {
		const { name, value } = event.target;
		if (name === 'scenario') {
			setSelectedScenario(value);
		} else if (name === 'assumption') {
			setSelectedAssumption(value);
			if (itemMain?.id) {
				API.put(
					`/cost/scenario_assumption/${itemMain?.id}/`,
					{
						target_assumption: value,
					},
					{},
					0
				)
					.then((res: any) => {
						getAssumptionDataTable(savedScenarioId);
					})
					.catch((err: any) => {});
			} else {
				API.post(
					'/cost/scenario_assumption/',
					{
						target_assumption: value,
						base_assumption: itemMain?.base_assumption,
						cost_scenario: savedScenarioId,
					},
					{},
					0
				)
					.then((res: any) => {
						getAssumptionDataTable(savedScenarioId);
					})
					.catch((err: any) => {});
			}
		} else if (name === 'color') {
			setColorSelect(value);
		} else if (name === 'scenarioname') {
			setScenarioName(value);
		}
	};

	const getObj = () => {
		if (vault == 0) {
			return { vehicle_category: projectId };
		} else {
			return { vault: vault };
		}
	};

	// const getColor = () => {
	//   API.get(
	//     "/cost/scenario_assumption/",
	//     {
	//       get_scenario_color: true,
	//       ...getObj(),
	//     },
	//     0
	//   )
	//     .then((res: any) => {
	//       setColorSelect(res?.data);
	//     })
	//     .catch((err: any) => {});
	// };

	const getDropDownOptions = () => {
		setLoader(true);
		API.get('/cost/scenario_table/', { ...getObj() })
			.then((res: any) => {
				setTableData(res?.data);
				setLoader(false);
			})
			.catch((err: any) => {
				console.log('Sever Error');
				setLoader(false);
			});
	};

	const getAssumptionsDropdownList = () => {
		setAssumptionLoader(true);
		API.get('/cost/assumption_card/', {
			vehicle_category: projectId,
			assumption_type: 2,
		})
			.then((res: any) => {
				setAssumptionsList(res?.data);
				setAssumptionLoader(false);
			})
			.catch((err: any) => {
				console.log('Sever Error');
				setAssumptionLoader(false);
			});
	};

	const getAssumptionDataTable = (iddd: any) => {
		setAssumptionTableDataLoading(true);
		API.get('/cost/scenario_assumption/', {
			...getObj(),
			selected_costing:
				selectedScenario?.scenario_creation === 'Main'
					? 0
					: selectedScenario?.id,
			scenario: iddd,
		})
			.then((res: any) => {
				setAssumptionTableData(res?.data);
				setAssumptionTableDataLoading(false);
			})
			.catch((err: any) => {
				console.log('Sever Error');
				setAssumptionTableDataLoading(false);
			});
	};

	const getAssumptionDataTableUpdate = (iddd: any, scenario: any) => {
		API.get('/cost/scenario_assumption/', {
			...getObj(),
			selected_costing:
				scenario?.scenario_creation === 'Main' ? 0 : scenario?.id,
			scenario: iddd,
		})
			.then((res: any) => {
				setAssumptionTableData(res?.data);
				setAssumptionTableDataLoading(false);
			})
			.catch((err: any) => {
				console.log('Sever Error');
				setAssumptionTableDataLoading(false);
			});
	};

	//   React.useEffect(() => {
	//     if (Object.keys(selectedScenario).length > 0) {
	//       getAssumptionDataTable();
	//     }
	//   }, [selectedScenario]);

	React.useEffect(() => {
		getDropDownOptions();
		getAssumptionsDropdownList();
		// getColor();
	}, [isOpen]);

	//   const [disableStatus,setDisableStatus]=React.useState<boolean>(true);
	const getLoadingStatus = () => {
		let status = assumptionTableData.every((object: any) => {
			return (
				object.hasOwnProperty('target_assumption') &&
				object.target_assumption !== null
			);
		});
		return status;
	};

	return (
		<div>
			<Modal
				open={isOpen}
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'>
				<Box sx={style}>
					<Box sx={{ display: 'flex', alignItems: 'center', height: '3.5rem' }}>
						<Typography
							style={{
								fontSize: '1rem',
								fontWeight: '600',
								width: '100%',
								textAlign: 'center',
								marginRight: '-2rem',
							}}>
							{exsistingData?.name} <br />
							{exsistingData?.product_name}({exsistingData?.parent_abbreviation}
							)
						</Typography>
						<CancelIcon
							titleAccess='Close'
							sx={{ cursor: 'pointer', margin: '0.5rem' }}
							onClick={onCloseModal}
						/>
					</Box>
					<Divider
						sx={{
							borderColor: 'primary.light',
							margin: '0 1rem',
							marginBottom: '1rem',
						}}
					/>

					<Box sx={{ padding: '0 1rem 1rem' }}>
						<Box sx={{ marginBottom: '2rem' }}>
							<FormControl variant='standard' fullWidth>
								<InputLabel
									shrink
									sx={{ color: 'primary.main' }}
									id='demo-simple-select-label'>
									Selected Base Scenario
								</InputLabel>
								<Select
									size='small'
									labelId='demo-simple-select-label'
									id='demo-simple-select'
									name='scenario'
									value={selectedScenario && selectedScenario}
									onChange={(ed: any) => handleChange(ed, {})}
									disabled={true}
									sx={{
										width: '100%',
										'&:before': {
											borderBottomColor: 'primary.main',
										},
										'& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select':
											{
												fontSize: '1rem',
												paddingLeft: '0.5rem',
												paddingBottom: '0rem',
												paddingTop: '0.2rem',
											},
										'.MuiSvgIcon-root.MuiSelect-icon': {
											color: 'primary.main',
										},
									}}>
									{!loader && tableData ? (
										tableData?.map((item: any, index: any) => {
											return (
												<MenuItem
													sx={{ fontSize: '1rem' }}
													key={index}
													value={item}>
													{item?.name}
												</MenuItem>
											);
										})
									) : (
										<Skeleton animation='wave' />
									)}
								</Select>
							</FormControl>
							<Typography
								sx={{ fontSize: '1rem', width: '100%', textAlign: 'right' }}>
								{selectedScenario?.cost}
							</Typography>
						</Box>

						<Box
							sx={{
								display: 'flex',
								alignItems: 'flex-end',
								columnGap: '1rem',
								//   justifyContent: "",
								//   height: "2.5rem",
							}}>
							<FormControl variant='standard' fullWidth>
								<InputLabel
									shrink
									sx={{ color: '#007fff' }}
									id='demo-simple-select-label'>
									Enter Scenario Name
								</InputLabel>
								<TextField
									//   label="Enter Scenario Name"
									// defaultValue="Enter Scenario Name"
									//   placeholder="Enter Scenario Name"
									variant='standard'
									name='scenarioname'
									fullWidth
									size='small'
									value={scenarioName}
									onChange={(ed: any) => handleChange(ed, {})}
									sx={{
										marginTop: '1rem',
										'.MuiInputBase-root.MuiInput-root': {
											fontSize: '1rem !important',
										},
										'& .MuiInput-underline:before': {
											borderBottomColor: 'primary.main',
										},
										'& .MuiInput-underline:after': {
											borderBottomColor: 'primary.main',
										},
									}}
								/>
							</FormControl>

							{/* <FormControl variant="standard" fullWidth>
                <InputLabel
                  shrink
                  sx={{ color: "#007fff" }}
                  id="demo-simple-select-label"
                >
                  Select Scenario Color
                </InputLabel>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="color"
                  value={selectedColor}
                  label="Color"
                  onChange={(ed: any) => handleChange(ed, {})}
                  sx={{
                    width: "100%",
                    "&:before": {
                      borderBottomColor: "primary.main",
                    },
                    "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                      {
                        fontSize: "1rem",
                        paddingLeft: "0.5rem",
                        paddingBottom: "0rem",
                        paddingTop: "0.2rem",
                      },
                    ".MuiSvgIcon-root.MuiSelect-icon": {
                      color: "primary.main",
                    },
                  }}
                >
                  {[...colorList, exsistingData?.color]?.map(
                    (item: any, index: any) => {
                      return (
                        <MenuItem
                          value={item}
                          sx={{ padding: "0.1rem 0.5rem" }}
                          key={index}
                          disabled={item === exsistingData?.color}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              height: "1rem",
                              backgroundColor: item,
                            }}
                          ></Box>
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              </FormControl> */}
							<FormControl fullWidth sx={{ marginTop: '0.5rem' }}>
								<InputLabel
									id='demo-simple-select-label-Activity'
									sx={{
										color: 'primary.main',
										fontSize: '1.13rem',
										marginLeft: '-1rem',
									}}>
									Color *
								</InputLabel>
								<Input
									type='color'
									onChange={handleColorSelect}
									value={colorSelect}
									sx={{
										'&.MuiInputBase-root.MuiInput-root:before': {
											borderBottomColor: 'primary.light',
										},
									}}
								/>
								{error && colorSelect == undefined ? (
									<p
										style={{
											fontSize: '0.75rem',
											color: '#d32f2f',
											marginTop: '0.4rem',
										}}>
										*Please select color
									</p>
								) : (
									<></>
								)}
							</FormControl>
						</Box>

						<Box sx={{ width: '100%', textAlign: 'right' }}>
							{/* <Button onClick={()=>FeatureCreateUploadModal.open()}>Open create</Button> */}
							{/* <LoadingButton
                size="small"
                // loading={isLoadingsave}
                variant="contained"
                disabled={isShowTable}
                type="submit"
                sx={{ marginTop: "1rem" }}
                onClick={() => {
                  API.post(
                    "cost/scenario_assumption/",
                    {
                      create_new_scenario: true,
                      ...getObj(),
                      name: scenarioName,
                      color: selectedColor,
                      scenario:
                        selectedScenario?.scenario_creation === "Main"
                          ? 0
                          : selectedScenario?.id,
                    },
                    {},
                    0
                  )
                    .then((res: any) => {
                      setIsShowTable(true);
                      setSavedScenarioId(res?.data?.id);
                      getAssumptionsDropdownList();
                      getAssumptionDataTable(res.data?.id);
                    })
                    .catch((err: any) => {});
                }}
              >
                Add Scenario
              </LoadingButton> */}
						</Box>

						<Box
							sx={{ maxHeight: '40vh', marginTop: '3rem' }}
							adl-scrollbar='true'
							adl-scrollbar-width='0.3'>
							{partCountDetails.isOpen && (
								<PartCountDetailsModal
									isOpen={partCountDetails.isOpen}
									onCloseModal={partCountDetails.close}
									assumption={partCountDetails.propsId}
								/>
							)}

							<Table>
								<TableHead sx={{ backgroundColor: 'primary.light' }}>
									<TableRow>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												borderBottomColor: 'primary.light',
											}}>
											Used Revision
										</TableCell>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												borderBottomColor: 'primary.light',
											}}>
											Parts
										</TableCell>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												borderBottomColor: 'primary.light',
											}}>
											New Revision
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{!assumptionTableDataLoading && assumptionTableData ? (
										assumptionTableData?.map((itemMain: any, index: any) => {
											return (
												<TableRow>
													<TableCell
														sx={{
															padding: '0.2rem 0.5rem',
															borderBottomColor: 'primary.light',
														}}>
														{' '}
														{itemMain?.base_assumption_name}
													</TableCell>
													<TableCell
														sx={{
															padding: '0.2rem 0.5rem',
															borderBottomColor: 'primary.light',
															textDecoration: 'underline',
															cursor: 'pointer',
														}}
														onClick={() => {
															if (itemMain?.part_count > 0) {
																partCountDetails.open({
																	id: itemMain?.base_assumption,
																	scenario: exsistingData?.id,
																});
															}
														}}>
														{' '}
														{itemMain?.part_count}
													</TableCell>
													<TableCell
														sx={{
															padding: '0.2rem 0.5rem',
															borderBottomColor: 'primary.light',
														}}>
														<EditNewAssumptionDropdown
															assumptionLoader={assumptionLoader}
															assumptionsList={
																assumptionsList && assumptionsList
															}
															handleChange={handleChange}
															itemMain={itemMain}
															selectedAssumption={selectedAssumption}
															setSelectedAssumption={setSelectedAssumption}
															key={index}
														/>
													</TableCell>
												</TableRow>
											);
										})
									) : (
										<TableRow>
											<TableCell>
												<Skeleton animation='wave' />
											</TableCell>
											<TableCell>
												<Skeleton animation='wave' />
											</TableCell>
											<TableCell>
												<Skeleton animation='wave' />
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</Box>
						<Box sx={{ width: '100%', textAlign: 'right' }}>
							{/* <Button onClick={()=>FeatureCreateUploadModal.open()}>Open create</Button> */}
							<LoadingButton
								size='small'
								variant='contained'
								type='submit'
								sx={{
									marginTop: '1rem',
									'&:hover': {
										transform: 'Scale(1.05)',
										transition: 'transform 0.5s ease',
									},
								}}
								onClick={() => {
									API.put(
										`cost/scenario/${savedScenarioId}/`,
										{
											name: scenarioName,
											color: colorSelect,
										},
										{},
										0
									)
										.then((res: any) => {
											getTableData();
											getCompareGraphData();
											getTotalCompareGraphData();
											setIsShowTable(true);
											setSavedScenarioId(res?.data?.id);
											getAssumptionsDropdownList();
											//   setCount((prev: any) => prev + 1);
											onCloseModal();
										})
										.catch((err: any) => {
											const { data }: any = err?.response;
											Swal.fire({
												icon: 'error',
												html: `<div>
                                <br />
                                <p style="color:"red">${data[0]}</p>   
                              </div>`,
											});
										});
								}}>
								Save
							</LoadingButton>
						</Box>
					</Box>
				</Box>
			</Modal>
		</div>
	);
}
