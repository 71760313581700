import * as React from "react";
import {
  Modal,
  Divider,
  Box,
  TableCell,
  Table,
  TableBody,
  TableRow,
  TableHead,
  Button,
  FormLabel,
  Skeleton,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CancelIcon from "@mui/icons-material/Cancel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import styles from "./AddCostModal.module.scss";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// import { CostingTreeComponent } from "./CostingTreeComponent";
// import { API } from "../../../api-services";
// import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
// import CalculatorListTree from "./CalculatorListTree";
// import { useDispatch, useSelector } from "react-redux";
// import { selectedCalculators } from "../../../Redux/Actions/compareopen.action";
// import { BorderRight, Height } from "@mui/icons-material";
// import CostingCostCalculator from "../../../Configuration/Costing/CostingCreateCalculator";
import { LoadingButton } from "@mui/lab";

import { useHistory } from "react-router-dom";
import { API } from "../../api-services";
import { Item } from "devextreme-react/data-grid";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import CopyCalculatorTree from "./CopyCalculatorTree";

export interface ICalculatorPasteModalModalProps {
  onCloseModal: any;
  isOpen: any;
  HierarchyAllData: any;
  setCounterApiCall: any;

}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "36vw",
  minHeight: "20vh",
  bgcolor: "background.paper",
  // backgroundColor: "primary.light",
  borderRadius: "10px",
  p: 1,
};

export function CalculatorPasteModal(
  props: ICalculatorPasteModalModalProps
) {
  const {
    onCloseModal,
    isOpen,
    HierarchyAllData,
    setCounterApiCall
  } = props;
  const { vehCateId } = useRouteParams<any>();
  const [projectList, setProjectList] = React.useState<any>([]);
  const [selectedTreeData, setSelectedTreeData] = React.useState<any>([]);
  const [HierarchyData, setHierarchyData] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [selectedProject, setSelectedProject] = React.useState<any>(null);
  const getProjectList = () => {
    API.get("/cost/cost_calculator_copy/", { vehicle_category_list: true }, 0).then((res: any) => {
      setProjectList(res.data);
      console.log("dabdkjsab", res.data)
    }).catch((err: any) => { });
  }
  React.useEffect(() => {
    getProjectList();
  }, []);
  const handleChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setSelectedProject(+value);
  };
  const getHierarchyData = () => {
    setIsLoading(true);
    API.get("/cost/costing_configuration/", {
      costing_config_hier: true,
      vehicle_category: selectedProject
    }, 0).then((res: any) => {
      setIsLoading(false);
      setHierarchyData(res.data[0].data);
    }).catch((err: any) => {
      setIsLoading(false);
    });
  }
  React.useEffect(() => {
    setSelectedProject(+vehCateId)
    getHierarchyData();
  }, [vehCateId]);
  React.useEffect(() => {
    if (selectedProject) {
      getHierarchyData();
    }
  }, [selectedProject]);

  const onSelect = (
    nodeId: any,
    id: any,
    HierarchyAllData: any,
    itemId: any
  ) => {
    // history.push(`/costingconfiguration/${projectId}/${projectname}/${itemId}/${HierarchyAllData.type}/`);
  };


  return (
    <div>
      <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                // textAlign: "center",
                marginLeft: "1rem",
              }}
            >
              Copied Cost Models : {HierarchyAllData?.name}
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={() => {
                onCloseModal();
              }}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "0.5rem",
            }}
          />
          <Box>
            <FormControl fullWidth>
              <InputLabel sx={{ color: "primary.main", marginLeft: '-1rem' }} shrink>
                Select Part
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant='standard'
                // size="small"
                value={selectedProject}
                onChange={handleChange}
                label="Calibration Required"
                sx={{
                  width: '100%',
                  fontSize: '1rem',
                  "&:before": {
                    borderBottomColor: "primary.light",
                  },
                  "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                    fontSize: '1rem'
                  },
                  '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
                }}
              >
                {projectList && projectList?.map((item: any) => {
                  return <MenuItem sx={{ fontSize: '1rem', }} value={item.id}>{item.name}</MenuItem>
                })}
              </Select>
            </FormControl>
            <Box
              sx={{
                height: { lg: "60vh", xl: "60vh" },
                borderRight: "1px solid",
                borderColor: "primary.light",
              }}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3"
            >
              <Typography sx={{ fontSize: '1rem', fontWeight: '500', padding: '0.2rem 0.5rem', backgroundColor: 'primary.light' }}>Paste to:</Typography>
              {!isLoading && HierarchyData ? HierarchyData?.map((item: any) => {
                return <CopyCalculatorTree
                  HierarchyData={item && item}
                  setSelectedTreeData={setSelectedTreeData}
                  onSelectCallback={onSelect}
                  setCounterApiCall={setCounterApiCall}
                  sourceCalc={HierarchyAllData && HierarchyAllData}
                  parentCloseModal={onCloseModal}
                />
              }) :
                <Box sx={{ width: "100%", padding: "0 0.5rem" }}>
                  <Skeleton sx={{ height: "2rem", width: "30%" }} />
                  <Skeleton sx={{ height: "2rem", width: "45%" }} />
                  <Skeleton sx={{ height: "2rem", width: "50%" }} />
                  <Skeleton sx={{ height: "2rem", width: "65%" }} />
                  <Skeleton sx={{ height: "2rem", width: "70%" }} />
                  <Skeleton sx={{ height: "2rem", width: "60%" }} />
                  <Skeleton sx={{ height: "2rem", width: "55%" }} />
                  <Skeleton sx={{ height: "2rem", width: "30%" }} />
                  <Skeleton sx={{ height: "2rem", width: "45%" }} />
                  <Skeleton sx={{ height: "2rem", width: "50%" }} />
                  <Skeleton sx={{ height: "2rem", width: "35%" }} />
                  <Skeleton sx={{ height: "2rem", width: "70%" }} />
                  <Skeleton sx={{ height: "2rem", width: "55%" }} />
                  <Skeleton sx={{ height: "2rem", width: "80%" }} />
                  <Skeleton sx={{ height: "2rem", width: "30%" }} />
                  <Skeleton sx={{ height: "2rem", width: "45%" }} />
                  <Skeleton sx={{ height: "2rem", width: "50%" }} />
                  <Skeleton sx={{ height: "2rem", width: "65%" }} />
                  <Skeleton sx={{ height: "2rem", width: "70%" }} />
                  <Skeleton sx={{ height: "2rem", width: "60%" }} />
                  <Skeleton sx={{ height: "2rem", width: "55%" }} />
                  <Skeleton sx={{ height: "2rem", width: "30%" }} />
                  <Skeleton sx={{ height: "2rem", width: "45%" }} />
                  <Skeleton sx={{ height: "2rem", width: "50%" }} />
                  <Skeleton sx={{ height: "2rem", width: "35%" }} />
                  <Skeleton sx={{ height: "2rem", width: "70%" }} />
                  <Skeleton sx={{ height: "2rem", width: "55%" }} />
                  <Skeleton sx={{ height: "2rem", width: "80%" }} />
                </Box>}

            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
