// react
import axios from "axios";
import moment from "moment";
import { API } from "../../api-services";
import { MODULES } from "../../Constants/modules.constants";
import { s3Url } from "../../utlis/s3url.utils";

const WorkshopEIRS3Upload = async (
   fileList: any,
   setFileListLoader:any,
   type:any,
   projectId?:any,
   apiCallWorkstationData?:any, 
   setApiCallWorkstationData?:any,
   onCloseModal?:any,
   abbreviation?:any
) => {
   // const file_name = file?.name;
   var random: any = Math.floor( Math.random() * 1000000 + 1 );

   if ( typeof ( fileList[0] ) !== "undefined" ) {
      API.get( "cart/get_signed_policy/", {
         module: MODULES.CAD,
      } ).then( res => {

         const data = Array.from( fileList ).forEach( ( file: any, index: any ) => {
            const formData = new FormData();
            formData.append(
               "key",
               `WorkstationEIR/${random}/${file.name}`
            );
            formData.append( "Content-Type", file?.type );
            formData.append( "x-amz-server-side-encryption", "AES256" );
            formData.append( "X-Amz-Credential", res.data.data.x_amz_credential );
            formData.append( "X-Amz-Algorithm", "AWS4-HMAC-SHA256" );
            formData.append( "X-Amz-Date", res.data.data.x_amz_date );
            formData.append(
               "X-Amz-Signature",
               res.data.data.aws_policy_signature
            );
            formData.append( "Policy", res.data.data.aws_policy );
            formData.append( "file", file, file?.name );

            axios
               .post( s3Url, formData, {
                  onUploadProgress: data => {
                     //Set the progress value to show the progress bar
                  },
               } )
               .then( async ( res: any ) => {
                  if ( res.status === 204 ) {
                    if(type=="workshopUpload"){
                    API.post('workshop/banner_fields/',{
                     update_workshop_file:true,
                     project:projectId,
                     file_path:`WorkstationEIR/${random}/${file.name}`
                 },0).then((res:any)=>{
                     setFileListLoader(false);
                     setApiCallWorkstationData(!apiCallWorkstationData)
                     onCloseModal();
                 })
                    }
                    else{
                        API.post('workshop/banner_fields/',{
                            upload_banner_file:true,
                            subsystem_name:abbreviation,
                            project:projectId,
                            file_path:`WorkstationEIR/${random}/${file.name}`
                        },0).then((res:any)=>{
                            setFileListLoader(false);
                            setApiCallWorkstationData(!apiCallWorkstationData)
                            onCloseModal();
                        })
                    }
               }} );
         } );
      } );
   } 

};

// ----------------------------------------------------------------------------------

export default WorkshopEIRS3Upload;
