import {
  Box,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import * as React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import styles from "./CreateCostingPart.module.scss";
import { CostEstimation } from "./CostEstimation";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InfoIcon from "@mui/icons-material/Info";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import Image from "../../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import LoadingImage from "../../../Assets/images/loading-screen.gif";
import RViewerJS from "viewerjs-react";
import { AssumptionModal } from "../../../ViewModule/CostingView/AssumptionModal";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import { useHistory } from "react-router-dom";
import { debounce } from "lodash";
import { BOMDrawerTable } from "./BOMDrawerTable";
import { CostigngCalculatorSection } from "./CostigngCalculatorSection";
import Draggable from "react-draggable";
import { ID } from "../../../utlis/commonInterfaces";
import Swal from "sweetalert2";
import { useEffect } from "react";
import OtherAssumptionModal from "./OtherAssumptionModal";
import { ADMIN } from "../../../Redux/Services/admin.service";
import { UserContext } from "../../../Configuration/BOM/BomConfigurationLanding";

export interface ICreateCostingPartProps {
  setCostData: any;
  count?: any;
  Cost_status?: any;
  getHierarchyData?: any;
  treeCount?: any;
}

export const AssumptionIdContext = React.createContext<ID>(0);
export const OtherAssumptionIdContext = React.createContext<any>([]);
export const AssumptionIdSelectedContext = React.createContext<any>([]);

export function CreateCostingPart(props: ICreateCostingPartProps) {
  const { setCostData, count, Cost_status, getHierarchyData, treeCount } =
    props;
  const {
    vault,
    topVault,
    projectId,
    projectName,
    productId,
    abbrebation,
    cardtype,
    tableId,
    condition,
    calctype,
    calcultorId,
    tableCounter,
    scenarioId,
    requestId,
  } = useRouteParams<any>();
  const assumptionModal = useBaseModal();
  const history = useHistory();
  const [costDetailsData, setCostDetailsData] = React.useState<any>({});
  const [costDetailsDataLoading, setCostDetailsDataLoading] =
    React.useState<any>(false);
  const [assumptionList, setAssumptionList] = React.useState<any>([]);
  const [selectedAssumption, setSelectedAssumption] = React.useState<any>("");
  const [commodityList, setCommodityList] = React.useState<any>([]);
  const [commodityListLoader, setCommodityListLoader] =
    React.useState<any>(false);
  const [selectedCommodity, setSelectedCommodity] = React.useState<any>([]);
  const [Tabstatus, setTabstatus] = React.useState<any>("Parameters");
  const [passingObj, setPassingObj] = React.useState<any>({});
  const [updateCount, setUpdateCount] = React.useState<any>(1);
  const [SelectedmulIds, setSelectedmulIds] = React.useState<any>([]);
  const [Selectedmulassumption, setselectedmulassumption] = React.useState<any>(
    []
  );
  const [MultipleAssumptionList, setMultipleAssumptionList] =
    React.useState<any>([]);
  const [counter, setCounter] = React.useState<any>(0);
  const otherassumptionModal = useBaseModal();
  const handleChange = (event: SelectChangeEvent) => {
    setSelectedAssumption(event.target.value);
    API.put(
      `/cost/update_estimation/${costDetailsData?.cost_estimation}/`,
      {
        assumption: event.target.value,
      },
      {},
      0
    )
      .then((res: any) => {
        getHierarchyData();
        getCostDetailsData();
        getPreSelectedCommodity();
      })
      .catch((err: any) => {});
  };
  const getCommodityList = () => {
    setCommodityListLoader(true);
    API.get("/db/commodity/", {}, 0)
      .then((res: any) => {
        setCommodityList(
          res.data?.map((item: any) => ({
            id: item.id,
            name: item.name,
          }))
        );
        setCommodityListLoader(false);
      })
      .catch((err: any) => {
        setCommodityListLoader(false);
      });
  };

  const getAssumptionList = () => {
    API.get(
      "/cost/assumption/",
      {
        top_vault: topVault,
        vault: vault,
        details: true,
        scenario: true,
        assumption_type: scenarioId ? 2 : 1,
      },
      0
    )
      .then((res: any) => {
        setAssumptionList(res.data);
        setMultipleAssumptionList(res.data);
      })
      .catch((err: any) => {});
  };
  const getObj = () => {
    if (vault == 0) {
      return { top_vault: topVault };
    } else {
      return { vault: vault };
    }
  };
  React.useEffect(() => {
    getCommodityList();
    getAssumptionList();
  }, [vault]);

  React.useEffect(() => {
    if (costDetailsData?.cost_estimation && requestId != 0) {
      API.put(
        `/cost/costing_view/${costDetailsData?.cost_estimation}/`,
        {},
        {
          main_cost_client_request: requestId,
        },
        0
      )
        .then((res: any) => {})
        .catch((err: any) => {});
    }
  }, [costDetailsData]);

  const getCostDetailsData = () => {
    setCostDetailsDataLoading(true);
    setCostDetailsData({});

    API.get(
      "/cost/costing_view/",
      {
        ...getObj(),
        level: "cost_detail",
        scenario: scenarioId ? scenarioId : undefined,
      },
      0
    )
      .then((res: any) => {
        setCostDetailsData(res.data[0]);

        setCostData(res.data[0]);
        setSelectedAssumption(res.data[0]?.assumption);
        setCostDetailsDataLoading(false);
      })
      .catch((err: any) => {
        setCostDetailsDataLoading(false);
      });
  };

  const getCostDetailsDataWOL = () => {
    // setCostDetailsDataLoading(true);
    setCostDetailsData({});

    API.get(
      "/cost/costing_view/",
      {
        ...getObj(),
        level: "cost_detail",
        scenario: scenarioId ? scenarioId : undefined,
      },
      0
    )
      .then((res: any) => {
        setCostDetailsData(res.data[0]);
        setCostData(res.data[0]);
        setSelectedAssumption(res.data[0]?.assumption);
        setCostDetailsDataLoading(false);
      })
      .catch((err: any) => {
        setCostDetailsDataLoading(false);
      });
  };

  const getPreSelectedCommodity = () => {
    API.get(
      "/cost/costing_commodity/",
      {
        vault: vault,
      },
      0
    )
      .then((res: any) => {
        setSelectedCommodity(res.data[0]?.commodity_data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  // React.useEffect(() => {
  //   if (count) {
  //     getCostDetailsData();
  //   }
  // }, [count, condition, tableCounter]);

  // console.log(selectedCommodity, "selectedCommodity");
  React.useEffect(() => {
    if (treeCount != 0) {
      getCostDetailsDataWOL();
    }
  }, [treeCount]);

  React.useEffect(() => {
    // setTimeout(() => {
    //   getCostDetailsData();
    // }, 1000);

    getPreSelectedCommodity();
    getCostDetailsData();
  }, [vault, , updateCount, count]);

  React.useEffect(() => {
    if (condition != 0) {
      getCostDetailsData();
    }
  }, [condition, tableCounter]);

  useEffect(() => {
    if (MultipleAssumptionList?.length)
      API.get(`/cost/costing_assumption/`, {
        vault: vault,
        scenario: scenarioId,
      }).then((res: any) => {
        if (res.data && Array.isArray(res?.data?.assumption_list)) {
          let assumptionIds = MultipleAssumptionList?.filter((item: any) =>
            res?.data?.assumption_list?.includes(item?.id)
          );
          setSelectedmulIds(res?.data?.assumption_list);
          setselectedmulassumption(assumptionIds);
        }
      });
  }, [vault, counter, MultipleAssumptionList]);

  const ExactDebounceCommodity = React.useRef(
    debounce(async (value: any, vaultId: any) => {
      API.put(
        `/cost/costing_commodity/${vaultId}/`,
        {
          commodity: value,
        },
        {},
        0
      )
        .then((res: any) => {
          setSelectedCommodity(value);
          getHierarchyData();
          API.get(
            "/cost/costing_commodity/",
            {
              vault: vault,
            },
            0
          )
            .then((res: any) => {
              getPreSelectedCommodity();
            })
            .catch((err: any) => {
              console.log(err);
            });

          setCostDetailsData({});

          API.get(
            "/cost/costing_view/",
            {
              ...getObj(),
              level: "cost_detail",
              scenario: scenarioId ? scenarioId : undefined,
            },
            0
          )
            .then((res: any) => {
              setCostDetailsData(res.data[0]);
              setCostData(res.data[0]);
              setSelectedAssumption(res.data[0]?.assumption);
              setCostDetailsDataLoading(false);
            })
            .catch((err: any) => {
              setCostDetailsDataLoading(false);
            });

          // setUpdateCount((prev: any) => prev + 1);
        })
        .catch((err: any) => {
          const { data } = err?.response;
          Swal.fire({
            icon: "error",
            html: `<div>
          <br />
          <p style="color:"red">${data[0]}</p>   
           </div>`,
          });
        });
    }, 1000)
  ).current;

  React.useEffect(() => {
    return () => {
      //@ts-ignore
      ExactDebounceCommodity.cancel(); // Cancel the debounced function
    };
  }, []);

  const handleSelectChangParams = (event: any, newSystemValue: any) => {
    setSelectedCommodity(newSystemValue);
  };

  const getOptionDisabled = (option: any) => {
    return selectedCommodity.some(
      (selectedOption: any) => selectedOption.id === option.id
    );
  };

  const [isActive, setIsActive] = React.useState(false);
  const BomdataDrawer = () => {
    // toggle
    setIsActive((current) => !current);

    // or set to true
    // setIsActive(true);
  };

  const [isExpand, setIsExpand] = React.useState(true);
  const partexpandHandler = () => {
    // toggle
    setIsExpand((current) => !current);

    // or set to true
    // setIsActive(true);
  };

  const TabHandler = (tab: any) => {
    if (tab == "Parameters") {
      setTabstatus("Parameters");
    } else {
      setTabstatus("Table");
    }
  };
  const calculateTextColor = (backgroundColor: any) => {
    // Calculate the text color based on the brightness of the background color.
    const hex = backgroundColor?.replace(/^#/, "");
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness < 200 ? "#ffffff" : "text.primary"; // Adjust the threshold as needed
  };

  let multipleDataSet =
    MultipleAssumptionList &&
    MultipleAssumptionList?.filter((item: any) => {
      return item?.id !== selectedAssumption;
    });
  let selectedIds =
    MultipleAssumptionList &&
    MultipleAssumptionList?.filter((item: any) => {
      return item?.id == selectedAssumption;
    });
  // console.log(selectedAssumption,"vdgiudgiuvgudivgiudvgiudvgiu")
  const OpenHandlerAssumption = () => {
    otherassumptionModal.open();
  };

  const handleSelectChangAssumtionParams = (
    event: any,
    newSystemValue: any
  ) => {
    setselectedmulassumption(newSystemValue);

    ExactDebounceMultipleAssumption(
      newSystemValue?.map((item: any) => item.id),
      vault
    );
  };
  const ExactDebounceMultipleAssumption = React.useRef(
    debounce(async (value: any, vaultId: any) => {
      // console.log(value,"vbivfbiuvbufvbfbiu")
      API.post(
        `/cost/costing_assumption/`,
        {
          assumption_list: value,
          vault: vaultId,
          scenario: scenarioId,
        },
        {},
        0
      )
        .then((res: any) => {
          setCounter((prev: any) => prev + 1);
          ADMIN.toast.info("Assumptions updated successfully");

          // setUpdateCount((prev: any) => prev + 1);
        })
        .catch((err: any) => {
          const { data } = err?.response;
          Swal.fire({
            icon: "error",
            html: `<div>
          <br />
          <p style="color:"red">${data[0]}</p>   
           </div>`,
          });
        });
    }, 1000)
  ).current;
  return (
    <div>
      {otherassumptionModal.isOpen && (
        <OtherAssumptionModal
          onCloseModal={otherassumptionModal.close}
          isOpen={otherassumptionModal.isOpen}
          Item={vault}
          scenario={scenarioId}
          costDetailsData={costDetailsData}
          setselectedmulassumption={setselectedmulassumption}
          assumptionList={multipleDataSet}
          setCounter={setCounter}
          Selectedmulassumption={Selectedmulassumption}
        />
      )}
      <AssumptionIdContext.Provider value={selectedAssumption || 0}>
        <AssumptionIdSelectedContext.Provider value={selectedIds || []}>
          <OtherAssumptionIdContext.Provider
            value={Selectedmulassumption || []}
          >
            {!costDetailsDataLoading ? (
              <Box className={styles.containerWrapper}>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      maxWidth: { lg: "40vw", xl: "35vw" },
                    }}
                  >
                    {
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "0.2rem 0.5rem",
                          width: "100%",
                          marginBottom: "0.2rem",
                          backgroundColor: costDetailsData?.scenario_color,
                          color: costDetailsData?.scenario_color
                            ? calculateTextColor(
                                costDetailsData?.scenario_color
                              )
                            : "",
                          borderRadius: "5px",
                        }}
                      >
                        <IconButton
                          title="Previous"
                          sx={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            // color: "primary.main",
                            // backgroundColor: "primary.light",
                            borderRadius: "50%",
                            width: "1.7rem",
                            height: "1.7rem",
                          }}
                          disabled={costDetailsData?.prev_vault ? false : true}
                          onClick={() =>
                            history.push(
                              `/createcostingpart/${projectId}/${projectName}/${productId}/${topVault}/${costDetailsData?.prev_vault}/${abbrebation}/0/0/0/0/0/0/0/${scenarioId}/0`
                            )
                          }
                        >
                          <ChevronLeftIcon
                            titleAccess="Previous"
                            sx={{
                              fontSize: "1.8rem",
                              color: costDetailsData?.scenario_color
                                ? calculateTextColor(
                                    costDetailsData?.scenario_color
                                  )
                                : "",
                            }}
                          />
                        </IconButton>
                        <Typography sx={{ fontSize: "1rem" }}>
                          {costDetailsData?.part_no} ( {costDetailsData?.name} )
                        </Typography>
                        <IconButton
                          title="Next"
                          sx={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            // color: "primary.main",
                            // backgroundColor: "primary.light",
                            borderRadius: "50%",
                            width: "1.7rem",
                            height: "1.7rem",
                          }}
                          disabled={costDetailsData?.next_vault ? false : true}
                          onClick={() =>
                            history.push(
                              `/createcostingpart/${projectId}/${projectName}/${productId}/${topVault}/${costDetailsData?.next_vault}/${abbrebation}/0/0/0/0/0/0/0/${scenarioId}/0`
                            )
                          }
                        >
                          <ChevronRightIcon
                            titleAccess="Next"
                            sx={{
                              fontSize: "1.8rem",
                              color: costDetailsData?.scenario_color
                                ? calculateTextColor(
                                    costDetailsData?.scenario_color
                                  )
                                : "",
                            }}
                          />
                        </IconButton>
                      </Box>
                    }
                    {/* <Box sx={{ width: "10rem", textAlign: "center" }}>
                  <Button size="small" onClick={() => partexpandHandler()}>
                    {isExpand ? "Collapse" : "Expand"}
                  </Button>
                </Box> */}
                  </Box>
                  <Box
                    className={
                      isExpand
                        ? styles.CostPartDetails
                        : styles.CostPartDetailsCollapse
                    }
                  >
                    <Box>
                      <Table
                        sx={{
                          border: "1px solid",
                          borderColor: "primary.light",
                        }}
                      >
                        <TableBody>
                          <TableRow>
                            <TableCell className={styles.partInfoTitle}>
                              Qty
                            </TableCell>
                            <TableCell className={styles.partInfo}>
                              {costDetailsData?.quantity}
                            </TableCell>
                            <TableCell className={styles.partInfoTitle}>
                              Level
                            </TableCell>
                            <TableCell className={styles.partInfo}>
                              {costDetailsData?.level}
                            </TableCell>
                            <TableCell className={styles.partInfoTitle}>
                              Weight
                            </TableCell>
                            <TableCell className={styles.partInfo}>
                              {costDetailsData?.weight?.toFixed(2)}g
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      <Table
                        sx={{
                          border: "1px solid",
                          borderColor: "primary.light",
                        }}
                      >
                        <TableBody>
                          <TableRow>
                            <TableCell className={styles.partlabel}>
                              Total Cost
                            </TableCell>
                            <TableCell className={styles.partdetails}>
                              ₹ {costDetailsData?.total_cost?.toFixed(2)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={styles.partlabel}>
                              Cost/Kg
                            </TableCell>
                            <TableCell className={styles.partdetails}>
                              ₹ {costDetailsData?.cost_per_kg?.toFixed(2)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={styles.partlabel}>
                              % Share in BOM
                            </TableCell>
                            <TableCell className={styles.partdetails}>
                              {costDetailsData?.bom_share?.toFixed(2)}%
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ backgroundColor: "#c4efb3" }}>
                            <TableCell className={styles.partlabel}>
                              Material (
                              {costDetailsData?.material_share?.toFixed(2)}%)
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "none",
                                padding: "0.2rem 0.5rem",
                              }}
                              align="right"
                            >
                              ₹ {costDetailsData?.raw_material?.toFixed(2)}
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ backgroundColor: "#fffb90" }}>
                            <TableCell className={styles.partlabel}>
                              Process ({costDetailsData?.process_share}%)
                            </TableCell>

                            <TableCell
                              sx={{
                                borderBottom: "none",
                                padding: "0.2rem 0.5rem",
                              }}
                              align="right"
                            >
                              ₹ {costDetailsData?.process?.toFixed(2)}
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ backgroundColor: "#f7e1f4" }}>
                            <TableCell className={styles.partlabel}>
                              Overheads ({costDetailsData?.overhead_share}%)
                            </TableCell>

                            <TableCell
                              sx={{
                                borderBottom: "none",
                                padding: "0.2rem 0.5rem",
                              }}
                              align="right"
                            >
                              ₹ {costDetailsData?.overhead?.toFixed(2)}
                            </TableCell>
                          </TableRow>
                          {!costDetailsData?.is_leaf && (
                            <TableRow sx={{ backgroundColor: "#d6f1f4" }}>
                              <TableCell className={styles.partlabel}>
                                Child Parts (
                                {costDetailsData?.child_share?.toFixed(2)}%)
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderBottom: "none",
                                  padding: "0.2rem 0.5rem",
                                }}
                                align="right"
                              >
                                ₹ {costDetailsData?.child_cost?.toFixed(2)}
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </Box>

                    <Box sx={{ display: "flex", padding: "0 0.5rem" }}>
                      <Box className={styles.prodImage}>
                        {/* <img src={dummy} style={{ height: "8rem", opacity: 0.3 }} /> */}
                        {/* @ts-ignore */}
                        <RViewerJS>
                          {costDetailsData?.images?.map(
                            (item: any, index: number) => {
                              return (
                                <Image
                                  placeholderImg={LoadingImage}
                                  src={item?.image}
                                  style={
                                    index === 0
                                      ? {
                                          height: "100%",
                                          width: "100%",
                                          maxHeight: "15.3rem",
                                          maxWidth: "23rem",
                                          objectFit: "contain",
                                          cursor: "pointer",
                                          borderRadius: "5px",
                                        }
                                      : {
                                          height: "100%",
                                          width: "100%",
                                          maxHeight: "15.3rem",
                                          maxWidth: "23rem",
                                          objectFit: "contain",
                                          cursor: "pointer",
                                          display: "none",
                                          borderRadius: "5px",
                                        }
                                  }
                                />
                              );
                            }
                          )}
                        </RViewerJS>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    className={styles.collapser}
                    onClick={() => partexpandHandler()}
                  >
                    {isExpand ? <span className={styles.arrowup}></span> : ""}
                    {isExpand ? "Collapse" : "Expand"} Cost Summary
                    {isExpand ? "" : <span className={styles.arrowdown}></span>}
                  </Box>
                  <Box sx={{ width: { lg: "40vw", xl: "35vw" } }}>
                    <Box
                      sx={{
                        display: "flex",
                        columnGap: "1rem",
                        alignItems: "center",
                        marginBottom: "3px",
                        width: "100%",
                      }}
                    >
                      {" "}
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          marginLeft: "0.5rem",
                          fontSize: "0.75rem",
                          width: "100%",
                        }}
                      >
                        Select Main Revision :
                      </span>
                      {selectedAssumption !== null && (
                        <span
                          style={{
                            whiteSpace: "nowrap",
                            marginLeft: "2rem",
                            fontSize: "0.75rem",
                            width: "100%",
                          }}
                        >
                          Select Commodity :
                        </span>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        columnGap: "1rem",
                        alignItems: "flex-end",
                        marginBottom: "3px",
                        width: "100%",
                        marginTop: "-0.5rem",
                      }}
                    >
                      <FormControl fullWidth>
                        <Select
                          variant="standard"
                          size="small"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          placeholder="Select Revision "
                          value={selectedAssumption}
                          displayEmpty
                          renderValue={(selectedAssumption) => {
                            if (!selectedAssumption) {
                              return (
                                <Typography
                                  sx={{
                                    fontSize: "1rem",
                                    opacity: "0.5",
                                    marginTop: "0.5rem",
                                  }}
                                >
                                  Select Revision
                                </Typography>
                              );
                            } else {
                              return (
                                assumptionList &&
                                assumptionList?.filter(
                                  (item: any) => item.id == selectedAssumption
                                )[0]?.name
                              );
                            }
                          }}
                          label="Select Revision "
                          disabled={
                            costDetailsData &&
                            costDetailsData?.permission?.includes("U")
                              ? false
                              : true
                          }
                          onChange={handleChange}
                          sx={{
                            width: "100%",
                            "&:before": {
                              borderBottomColor: "primary.main",
                            },
                            "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                              {
                                fontSize: "1rem",
                                paddingLeft: "0.5rem",
                              },
                            ".MuiSvgIcon-root.MuiSelect-icon": {
                              color: "primary.main",
                            },
                          }}
                        >
                          {assumptionList &&
                            assumptionList?.map((item: any) => {
                              return (
                                <MenuItem
                                  sx={{
                                    fontSize: "1rem",
                                    backgroundColor:
                                      assumptionList?.is_mapped === true
                                        ? "#b3f7b3"
                                        : "white",
                                    color:
                                      assumptionList?.is_mapped === true
                                        ? "green"
                                        : "primary.main",
                                  }}
                                  value={item?.id}
                                >
                                  {item?.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>

                      {selectedAssumption !== null && (
                        <>
                          {" "}
                          <InfoIcon
                            sx={{ cursor: "pointer" }}
                            onClick={() => assumptionModal.open()}
                          />
                        </>
                      )}
                      {selectedAssumption !== null && (
                        <>
                          {commodityList && (
                            <FormControl fullWidth>
                              <Autocomplete
                                multiple
                                disableClearable
                                disabled={
                                  costDetailsData &&
                                  costDetailsData?.permission?.includes("U")
                                    ? false
                                    : true
                                }
                                disableCloseOnSelect
                                onChange={(event: any, newSystemValue: any) => {
                                  handleSelectChangParams(
                                    event,
                                    newSystemValue
                                  );
                                }}
                                id="tags-standard"
                                limitTags={1}
                                options={commodityList}
                                getOptionDisabled={getOptionDisabled}
                                value={selectedCommodity && selectedCommodity}
                                getOptionLabel={(option: any) => option.name}
                                // defaultValue={[top100Films[3]]}
                                sx={{
                                  ".MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator":
                                    {
                                      color: "primary.main",
                                    },
                                  ".MuiButtonBase-root.MuiChip-root": {
                                    // background-color: #ecffec;
                                    // color: selectedCommodity?.is_mapped == "true" ? "green" : 'primary.main',
                                    height: "26px",
                                    // maxWidth:{lg:'6rem',xl:'10rem'},
                                  },
                                }}
                                onBlur={() => {
                                  if (selectedCommodity) {
                                    ExactDebounceCommodity(
                                      selectedCommodity?.map(
                                        (item: any) => item.id
                                      ),
                                      vault
                                    );
                                  }
                                }}
                                renderTags={(value: any[], getTagProps: any) =>
                                  value.map((option, index) => {
                                    // console.log("jnsdjnfdjskn",option)
                                    return (
                                      <Chip
                                        key={index}
                                        label={option.name}
                                        {...getTagProps({ index })}
                                        title={option.name} // Set title attribute with full text content
                                        sx={{
                                          backgroundColor:
                                            option?.is_mapped === true
                                              ? "#b3f7b3"
                                              : "primary.light",
                                          color:
                                            option?.is_mapped === true
                                              ? "green"
                                              : "primary.main",
                                        }} // Update chip styles as needed
                                      />
                                    );
                                  })
                                }
                                renderOption={(props, option) => (
                                  <li {...props} style={{ fontSize: "1rem" }}>
                                    {option.name}
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder="Select Commodity"
                                    sx={{
                                      ".MuiInputBase-root.MuiInput-root": {
                                        fontSize: "1rem !important",
                                      },
                                      "& .MuiInput-underline:before": {
                                        borderBottomColor: "primary.main",
                                      },
                                      "& .MuiInput-underline:after": {
                                        borderBottomColor: "primary.main",
                                      },
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                          )}
                        </>
                      )}
                    </Box>
                    {assumptionList && assumptionList.length > 1 && (
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          {/* <span style={{ whiteSpace: "nowrap",marginLeft:'0.5rem',fontSize:'1rem' }}>Select Other Assumptions :</span> */}

                          <div
                            style={{
                              display: "flex",
                              marginLeft: "0.5rem",
                              gap: "0.3rem",
                              width: "100%",
                              /* white-space: noWrap; */
                            }}
                          >
                            {
                              <FormControl fullWidth>
                                <Autocomplete
                                  multiple
                                  disableClearable
                                  disabled={
                                    costDetailsData &&
                                    costDetailsData?.permission?.includes("U")
                                      ? false
                                      : true
                                  }
                                  filterSelectedOptions
                                  disableCloseOnSelect
                                  onChange={(
                                    event: any,
                                    newSystemValue: any
                                  ) => {
                                    handleSelectChangAssumtionParams(
                                      event,
                                      newSystemValue
                                    );
                                  }}
                                  id="tags-standard"
                                  limitTags={1}
                                  options={multipleDataSet && multipleDataSet}
                                  // getOptionDisabled={getOptionDisabled}/
                                  value={
                                    Selectedmulassumption &&
                                    Selectedmulassumption
                                  }
                                  getOptionLabel={(option: any) => option.name}
                                  // defaultValue={[top100Films[3]]}
                                  sx={{
                                    ".MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator":
                                      {
                                        color: "primary.main",
                                      },
                                    ".MuiButtonBase-root.MuiChip-root": {
                                      // backgroundColor: "primary.light",
                                      height: "26px",
                                    },
                                  }}
                                  // onBlur={() => {
                                  //   if (Selectedmulassumption) {
                                  //     ExactDebounceMultipleAssumption(
                                  //       Selectedmulassumption?.map(
                                  //         (item: any) => item.id
                                  //       ),
                                  //       vault

                                  //     );
                                  //   }
                                  // }}
                                  renderOption={(props, option) => (
                                    <li
                                      {...props}
                                      style={{
                                        fontSize: "1rem",
                                        backgroundColor:
                                          option?.is_mapped === true
                                            ? "#b3f7b3"
                                            : "primary.light",
                                        color:
                                          option?.is_mapped === true
                                            ? "green"
                                            : "primary.main",
                                      }}
                                    >
                                      {option.name}
                                    </li>
                                  )}
                                  renderTags={(
                                    value: any[],
                                    getTagProps: any
                                  ) =>
                                    value.map((option, index) => {
                                      // console.log("jnsdjnfdjskn",option)
                                      return (
                                        <Chip
                                          key={index}
                                          label={option.name}
                                          {...getTagProps({ index })}
                                          title={option.name} // Set title attribute with full text content
                                          sx={{
                                            backgroundColor:
                                              option?.is_mapped === true
                                                ? "#b3f7b3"
                                                : "primary.light",
                                            color:
                                              option?.is_mapped === true
                                                ? "green"
                                                : "primary.main",
                                          }} // Update chip styles as needed
                                        />
                                      );
                                    })
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="standard"
                                      placeholder="Select Other Revision"
                                      sx={{
                                        ".MuiInputBase-root.MuiInput-root": {
                                          fontSize: "1rem !important",
                                        },
                                        "& .MuiInput-underline:before": {
                                          borderBottomColor: "primary.main",
                                        },
                                        "& .MuiInput-underline:after": {
                                          borderBottomColor: "primary.main",
                                        },
                                      }}
                                    />
                                  )}
                                />
                              </FormControl>
                            }

                            {/* {

                        SelectedmulIds.length > 0 && <>

                          {
                            Selectedmulassumption?.length > 0 && Selectedmulassumption.map((item: any, index: any) => {
                              return (<>
                                <div style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis"

                                }}>
                                  <Tooltip title={item?.name}>
                                    <>
                                      <span title={item?.name}>{item?.name},
                                      </span>

                                    </>
                                  </Tooltip>
                                </div>

                              </>)
                            })
                          }
                        </>
                      } */}
                          </div>
                        </Box>
                        {/* {
                    Selectedmulassumption?.length > 0 ? <EditIcon fontSize="small" titleAccess={"Edit Assumption"} sx={{ cursor: "pointer !important" }} onClick={() => OpenHandlerAssumption()} /> :

                      <AddIcon fontSize="small" titleAccess={"Add Assumption"} sx={{ cursor: "pointer" }} onClick={() => OpenHandlerAssumption()} />} */}
                      </Box>
                    )}
                    {selectedAssumption !== null &&
                      selectedCommodity.length > 0 && (
                        <CostEstimation
                          costDetailsData={costDetailsData && costDetailsData}
                          isExpand={isExpand}
                          setPassingObj={setPassingObj}
                          SelectedmulIds={SelectedmulIds}
                          getCostDetailsData={getCostDetailsData}
                          getHierarchyData={getHierarchyData}
                        />
                      )}
                  </Box>
                </Box>
                {Cost_status !== "Not Started" && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      width: { lg: "55vw", xl: "61vw" },
                    }}
                  >
                    <Box
                      sx={{
                        width: { lg: "55vw", xl: "61vw" },
                        zIndex: "0",
                        position: "relative",
                      }}
                    >
                      {tableId !== 0 && (
                        <CostigngCalculatorSection passingObj={passingObj} />
                      )}
                    </Box>
                    <Box className={styles.BomDrawer}>
                      <Box className={styles.drabtnSec}>
                        <Draggable
                          axis="y"
                          // defaultPosition={{x: 200, y: 0}}
                          // bounds={{left: 0, right: width > 1400 ? 1200 : 800,}}
                        >
                          <Box
                            className={styles.bombutton}
                            onClick={BomdataDrawer}
                          >
                            <Typography
                              className={styles.text}
                              sx={{
                                fontSize: "1rem",
                                color: "white",
                                padding: "0.5rem 0.2rem",
                              }}
                            >
                              BOM Data
                            </Typography>
                          </Box>
                        </Draggable>
                      </Box>
                      <Box
                        className={
                          isActive
                            ? styles.bomTableActive
                            : styles.bomTableInactive
                        }
                      >
                        <Box className={styles.bomBodySection}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              columnGap: "1px",
                            }}
                          >
                            <Box
                              onClick={() => TabHandler("Parameters")}
                              className={
                                Tabstatus == "Parameters"
                                  ? styles.bomactiveTab
                                  : styles.bomTab
                              }
                            >
                              Parameters
                            </Box>
                            <Box
                              onClick={() => TabHandler("Table")}
                              className={
                                Tabstatus == "Table"
                                  ? styles.bomactiveTab
                                  : styles.bomTab
                              }
                            >
                              Table
                            </Box>
                          </Box>
                          <Box>
                            <BOMDrawerTable TabStatus={Tabstatus} />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "flex-start",
                  columnGap: "1rem",
                }}
              >
                <Box sx={{ width: { lg: "40%", xl: "35%" } }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      columnGap: "1rem",
                      padding: "0 0.5rem",
                    }}
                  >
                    <Skeleton
                      variant="rectangular"
                      height="18rem"
                      width="100%"
                      sx={{ marginBottom: "1rem" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      height="18rem"
                      width="100%"
                      sx={{ marginBottom: "1rem" }}
                    />
                  </Box>
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                </Box>
                <Box
                  sx={{ width: { lg: "60%", xl: "65%" }, padding: "0 1rem" }}
                >
                  <Skeleton
                    variant="rectangular"
                    height="89vh"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                </Box>
              </Box>
            )}
            {assumptionModal.isOpen && (
              <AssumptionModal
                isOpen={assumptionModal.isOpen}
                onClose={assumptionModal.close}
              />
            )}
          </OtherAssumptionIdContext.Provider>
        </AssumptionIdSelectedContext.Provider>
      </AssumptionIdContext.Provider>
    </div>
  );
}
