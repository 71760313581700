import {
  Box,
  Button,
  Popover,
  Typography,
  IconButton,
  Skeleton,
  CircularProgress,
  TableCell,
  Table,
  TableRow,
  Tooltip,
} from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import { useBaseParamsModal } from "../CustomHook/useBaseParamsModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import React, { useEffect, useState } from "react";
import { API } from "../api-services";
import ScenarioRequestStatusModal from "./ScenarioRequestStatusModal";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import InfoIcon from "@mui/icons-material/Info";
import DownloadIcon from "@mui/icons-material/Download";
import LoadingButton from "@mui/lab/LoadingButton";
import ScenarioAssignModal from "./ScenarioAssignModal";
import AccountBoxRoundedIcon from "@mui/icons-material/AccountBoxRounded";
import LockIcon from "@mui/icons-material/Lock";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import ClearIcon from "@mui/icons-material/Clear";
import LaunchIcon from "@mui/icons-material/Launch";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SingleChat from "../chat/SingleChat";

export interface IRequestScenarioTableProps {}

export function RequestScenarioTable(props: IRequestScenarioTableProps) {
  const [selectedRequest, setSelectedRequest] = React.useState<any>(null);
  const assignScenario = useBaseParamsModal();
  const history = useHistory();
  const [scenarioData, setScenarioData] = React.useState<any>([]);
  const [counter, setCouner] = React.useState<any>(0);
  const [loader, setLoader] = React.useState<boolean>(false);
  const [currentStatus, setCurrentStatus] = React.useState<any>();
  const [publishloader, setPublishLoader] = React.useState<boolean>(false);
  const [currentRowId, setcurrentRowId] = React.useState<any>();
  // const [currentType, setCurrentType] = React.useState<any>();
  const senarioStausModal = useBaseParamsModal();
  // const [invoiceData, setinvoiceData] = React.useState<any>({});
  // Action Info Popover
  const [rowData, setRowData] = React.useState<any>({});
  const contentRef = React.useRef<any>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClick = (cellValues: any, event: any) => {
    setRowData(cellValues);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setRowData({});
  };
  // end of Action Info Popover

  // Pagination & search
  const [totalPagecount, settotalPagecount] = React.useState<any>();
  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    setLoader(true);
    API.get("cart/get_all_scenario_requests/", { get_page_count: true })
      .then((res: any) => {
        settotalPagecount(res.data?.page_count);
        setLoader(false);
      })
      .catch((err: any) => {
        const { data } = err?.response;
        Swal.fire({
          icon: "error",
          html: `<div>
					<br />
					<p style="color:"red">${data.detail}</p>   
					</div>`,
        });
        setLoader(false);
      });
  }, []);

  const [selectFilter, setSelectFilter] = React.useState("All");
  const filterZBCRequest = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectFilter(event.target.value);
  };

  const getScenarioData = () => {
    if (selectFilter == "All") {
      setLoader(true);
      API.get("cart/get_all_scenario_requests/", { page: page })
        .then((res: any) => {
          let dataList = res?.data?.map((item: any, index: any) => {
            return { ...item, ids: (page - 1) * res.data?.length + index + 1 };
          });
          setScenarioData(dataList);
          setLoader(false);
        })
        .catch((err: any) => {
          const { data } = err?.response;
          Swal.fire({
            icon: "error",
            html: `<div>
							<br />
							<p style="color:"red">${data.detail}</p>   
							</div>`,
          });
          setLoader(false);
        });
    } else {
      setLoader(true);
      API.get("cart/get_all_scenario_requests/", {
        page: page,
        filter: selectFilter,
      })
        .then((res: any) => {
          let dataList = res?.data?.map((item: any, index: any) => {
            return { ...item, ids: (page - 1) * res.data?.length + index + 1 };
          });
          setScenarioData(dataList);
          setLoader(false);
        })
        .catch((err: any) => {
          const { data } = err?.response;
          Swal.fire({
            icon: "error",
            html: `<div>
								<br />
								<p style="color:"red">${data.detail}</p>   
								</div>`,
          });
          setLoader(false);
        });
    }
  };

  React.useEffect(() => {
    getScenarioData();
  }, [page, selectFilter]);

  const PaginationHandler = (type: any) => {
    if (type == "Next") {
      setPage((prev: any) => prev + 1);
    } else {
      setPage((prev: any) => prev - 1);
    }
  };

  const publishClicked = (row: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to lock and publish this scenario to xcPROC, which will make it visible to the client?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "primary.main",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setcurrentRowId(row.scenario_id);
        setPublishLoader(true);
        API.put(
          `/cost/scenario/${row.scenario_id}/`,
          {
            lock: true,
          },
          {},
          0
        )
          .then((res: any) => {
            getScenarioData();
            setPublishLoader(false);
            Swal.fire({
              title: "Success",
              text: "Scenario has been locked and Published to xcPROC successfully",
              icon: "success",
              confirmButtonColor: "primary.main",
            });
          })
          .catch((err: any) => {
            const { data } = err.response;
            setPublishLoader(false);
            Swal.fire({
              title: "Error",
              text: data[0],
              icon: "error",
              confirmButtonColor: "primary.main",
            });
          });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };
  const [reopen, setReopen] = React.useState(false);

  const [searchValue, setSearchValue] = useState<any>("");
  useEffect(() => {
    setSearchValue(searchValue == "0" ? "" : searchValue);
  }, [searchValue]);

  const searchAPICall = (event: any) => {
    if (event.key === "Enter") {
      handleSearchIcon();
    }
  };
  const handleOnChange = (event: any) => {
    setSearchValue(event.target.value);
  };

  const handleSearchIcon = () => {
    setLoader(true);
    API.get("cart/get_all_scenario_requests/", { search_key: searchValue })
      .then((res) => {
        setScenarioData(res.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        const { data } = err?.response;
        Swal.fire({
          icon: "error",
          html: `<div>
				<br />
				<p style="color:"red">${data[0]}</p>   
				</div>`,
        });
      });
  };
  const handleClearSearch = () => {
    setSearchValue("");
    getScenarioData();
  };

  const handleOpenSceanrio = (cellValues: any) => {
    const url = `/#/costingscenario/${cellValues.row?.vehicle_category_id}/${cellValues?.row?.vehicle_category}/0/${cellValues.row.vault}/1/${cellValues.row?.vehicle_category_abbreviation}/0/${cellValues.row?.ID}/1`;
    window.open(url);
  };

  React.useEffect(() => {
    getScenarioData();
  }, [counter]);

  const onInvoiceDownloadClick = async (invoice: any) => {
    try {
      // Fetch HTML content from the API endpoint
      const response = await API.get(
        `payment/get_invoice_from_payment_id/${invoice}/`
      );
      const data = await response.data;
      window.open(data?.invoice);
    } catch (error: any) {
      console.error("Error:", error); // Log the entire error object
      if (error?.response) {
        // The error object contains a response property
        const { data } = error?.response;
        Swal.fire({
          icon: "error",
          title: "Error!",
          html: `<div>
					<p style="color:"red">${data[0]}</p>
					</div>`,
        });
        console.error("Server Error:", error?.response);
      } else if (error?.message) {
        // The error object contains a message property
        console.error("Error Message:", error?.message);
        // Handle the error message
      } else {
        // The error object does not contain response or message properties
        console.error("Unknown Error:", error);
        // Handle the unknown error
      }
    }
  };

  const columns: GridColDef[] = [
    // { field: 'id', headerName: 'ID', width: 50 },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (cellValues: any) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "0.7rem",
            }}
          >
            
           <Box sx={{    display: "flex",
              alignItems: "center",
              columnGap: "0.7rem", width:"10rem"}}>
           <InfoIcon
              titleAccess="Scenario Request Info"
              onClick={(event) => handleClick(cellValues, event)}
            />
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              elevation={1}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              {rowData && Object.keys(rowData)?.length > 0 && (
                <Box
                  key={rowData.id}
                  sx={{
                    width: "fit-content",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      textAlign: "center",
                      width: "100%",
                      backgroundColor: "primary.light",
                    }}
                  >
                    Scenario Request Info
                  </Typography>
                  <Table>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Order ID
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {rowData.row.order_id}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Company Name
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {rowData.row.company_name}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Email ID
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {rowData.row.email}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Phone Number
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {rowData.row.phone_no}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Other Instructions
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {rowData.row.description}
                      </TableCell>
                    </TableRow>
                  </Table>
                </Box>
              )}
            </Popover>
            {cellValues.row.request_stage === "Payment Received" && (
              <AccountBoxRoundedIcon
                titleAccess="Assign Representative and TAT"
                sx={{
                  // marginRight: '1rem',
                  cursor: "pointer",
                  color: cellValues.row.assignees.length > 0 ? "green" : "red",
                }}
                onClick={() => assignScenario.open(cellValues.row)}
              />
            )}
            {cellValues.row.allow_publish && (
              <LoadingButton
                loading={
                  cellValues.row.scenario_id == currentRowId
                    ? publishloader
                    : false
                }
                // color='primary'
                sx={{
                  backgroundColor: cellValues.row?.is_published
                    ? "#c5c5c5"
                    : cellValues.row?.request_stage === "In Progress"
                    ? "primary.main"
                    : "secondary.main",
                  color:
                    cellValues.row?.request_stage === "In Progress"
                      ? "white"
                      : "",
                }}
                size="small"
                disabled={cellValues.row?.is_published}
                title="Publish new scenario costing to xcPROC"
                onClick={() => publishClicked(cellValues.row)}
              >
                {cellValues.row?.is_published ? (
                  "Published"
                ) : (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <LockIcon /> <ArrowRightAltIcon />
                    <PublishedWithChangesIcon />
                  </Box>
                )}
              </LoadingButton>
            )}
           </Box>
           <Tooltip title="Chat with requested user">
<MessageIcon
                  sx={{
                    color: "#00887a",
                    fontSize: "1.5rem",
                    cursor: "pointer",
                    display: "grid",
                    placeItems: "center",
                  }}
                  onClick={()=> {
                    handleClickk();
                    setSelectedRequest(cellValues.row);
                  }}
                />
                </Tooltip>
          </Box>
        );
      },
    },
    {
      field: "actions_button",
      headerName: "",
      width: 50,
      renderCell: (cellValues: any) => {
        return (
          <>
            {cellValues.row.assignees.length > 0 &&
            cellValues.row.request_id != null &&
            cellValues.row?.request_stage == "Payment Received" ? (
              <IconButton
                size="small"	
                disabled={cellValues.row?.amount == null}
                sx={{ padding: "3px", color: "primary.main" }}
                onClick={() => {
                  if (cellValues.row?.costing_type == "Main-Costing") {
                    window.open(
                      `/#/createcostingpart/${cellValues.row?.vehicle_category_id}/${cellValues?.row?.vehicle_category}/0/${cellValues.row?.vehicle_category_id}/${cellValues.row.vault}/${cellValues.row?.vehicle_category_abbreviation}/0/0/0/0/0/0/0/0/${cellValues?.row?.ID}`
                    );
                  } else {
                    window.open(
                      `/#/costingscenario/${cellValues.row?.vehicle_category_id}/${cellValues?.row?.vehicle_category}/0/${cellValues.row.vault}/1/${cellValues.row?.vehicle_category_abbreviation}/0/${cellValues.row?.ID}/2`
                    );
                  }
                }}
                title="Create Scenario"
              >
                <AddCircleOutlineIcon />
              </IconButton>
            ) : (
              cellValues.row?.request_stage == "In Progress" && (
                <IconButton
                  title="Go to Scenario Page"
                  size="small"
                  sx={{ padding: "3px", color: "primary.main" }}
                  onClick={() => {
                    if (cellValues.row?.costing_type == "Main-Costing") {
                      window.open(
                        `/#/createcostingpart/${cellValues.row?.vehicle_category_id}/${cellValues?.row?.vehicle_category}/0/${cellValues.row?.vehicle_category_id}/${cellValues.row.vault}/${cellValues.row?.vehicle_category_abbreviation}/0/0/0/0/0/0/0/0/${cellValues?.row?.ID}`
                      );
                    } else {
                      window.open(
                        `/#/costingscenario/${cellValues.row?.vehicle_category_id}/${cellValues?.row?.vehicle_category}/0/${cellValues.row.vault}/1/${cellValues.row?.vehicle_category_abbreviation}/0/${cellValues.row?.ID}/2`
                      );
                    }
                  }}
                >
                  <LaunchIcon />
                </IconButton>
              )
            )}
          </>
        );
      },
    },
    {
      field: "invoice",
      headerName: "Invoice",
      width: 60,
      editable: false,
      renderCell: (cellValues: any) => {
        return (
          <>
            {cellValues.row.invoice !== null && (
              <LoadingButton
                size="small"
                sx={{
                  padding: "0.5rem",
                  minWidth: "3rem",
                  lineHeight: 1,
                  "&:hover": {
                    transform: "Scale(1.05)",
                    transition: "transform 0.5s ease",
                  },
                  ".MuiCircularProgress-root": {
                    color: "primary.main",
                  },
                }}
                title="Download Invoice"
                startIcon={<DownloadIcon sx={{ marginRight: "-0.5rem" }} />}
                onClick={() => onInvoiceDownloadClick(cellValues.row.invoice)}
              />
            )}
          </>
        );
      },
    },
    {
      field: "request_stage",
      headerName: "Status",
      width: 160,
      editable: false,
      renderCell(params) {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "0.5rem",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => senarioStausHandler(params)}
          >
            {params.row.request_stage}
          </Box>
        );
      },
    },
    {
      field: "request_id",
      headerName: "Request ID",
      width: 170,
      editable: false,
    },
    {
      field: "username",
      headerName: "Requested By",
      width: 170,
      editable: false,
    },
    {
      field: "part",
      headerName: "Requested Part Name",
      width: 140,
      editable: false,
    },
    {
      field: "costing_type",
      headerName: "Costing Type",
      width: 140,
      editable: false,
    },
    {
      field: "annual_volume",
      headerName: "Annual Volume",
      type: "number",
      width: 110,
      editable: false,
    },
    {
      field: "combined_revision",
      headerName: "Revised On",
      width: 150,
      editable: false,
      renderCell: (cellValues: any) => {
        return (
          <>
            {Array.isArray(cellValues.row?.revision_month) &&
              cellValues.row?.revision_month?.join(", ")}
          </>
        );
      },
    },
    {
      field: "mfg_location",
      headerName: "MFG Location",
      width: 150,
      editable: false,
    },
    {
      field: "paidamount",
      headerName: "Paid Amount",
      width: 170,
      align: "right",
      headerAlign: "right",
      renderCell: (cellValues: any) => {
        return (
          <>
            {cellValues.row.paid_currency} {cellValues.row.paid_amount}
          </>
        );
      },
    },
    {
      field: "tat",
      headerName: "TAT",
      width: 120,
      editable: false,
    },
    {
      field: "vehicle_category",
      headerName: "Product Category",
      width: 150,
      editable: false,
    },
    {
      field: "costing_guidelines",
      headerName: "Guideline Documents",
      width: 100,
      renderCell: (cellValues: any) => {
        return (
          <>
            <IconButton
              size="small"
              sx={{ cursor: "pointer", color: "primary.main" }}
            >
              <VisibilityIcon
                titleAccess="Publish new scenario costing"
                sx={{ cursor: "pointer" }}
                onClick={() => window.open(cellValues.row.costing_guidelines)}
              />
            </IconButton>
          </>
        );
      },
    },
  ];

  const rows =
    scenarioData &&
    scenarioData?.map((item: any, index: number) => {
      return {
        idd:index+1,
        id: item.id,
        user_logo: item?.user_logo,
        room_id: item?.room_id,
        ID: item.id,
        username: item.username,
        email: item.email,
        phone_no: item.phone_no,
        company_name: item.company_name,
        name: item.name,
        annual_volume: item.annual_volume,
        mfg_location: item.mfg_location,
        costing_guidelines: item.costing_guidelines,
        revision_month: item.revision_month,
        revision_year: item.revision_year,
        description: item.description,
        amount: item.amount,
        request_stage: item.internal_request_stage,
        actions: "",
        part: item?.part,
        request_id: item?.request_id,
        order_id: item?.order_id,
        allow_publish: item?.allow_publish,
        reOpen: item.reopen,
        vault: item?.vault,
        vehicle_category: item?.vehicle_category,
        vehicle_category_id: item?.vehicle_category_id,
        vehicle_category_abbreviation: item?.vehicle_category_abbreviation,
        is_published: item?.is_published,
        scenario_id: item?.scenario_id,
        scenario_name: item?.scenario_name,
        invoice: item?.invoice,
        tat: item?.tat,
        color_code: item?.color_code,
        assignees: item?.assignees,
        paid_currency: item?.paid_currency,
        paid_amount: item?.paid_amount,
        costing_type: item?.costing_type,
        part_id: item?.part_id,
      };
    });

  const senarioStausHandler = (allrowparams: any) => {
    // setCurrentStatus(curstatus);
    // setCurrentType(curtype);
    if (allrowparams && allrowparams.row) {
      const allparams = allrowparams.row;

      if (allparams) {
        let requestStage = allparams.request_stage;
        // let requestType = allparams.zbc_type;
        let reOpen = allparams.reOpen;
        // If allparams exists, set the values
        setCurrentStatus(requestStage);
        // setCurrentType(allparams.zbc_type);
        setReopen(allparams.reOpen);
        // Open the modal
        senarioStausModal.open(allrowparams.row);
      } else {
        // Handle the case when allparams is undefined or null
        console.log("No data found");
      }
    }
  };

        // ------------drawer-----------------
        const [openChatDrawer, setOpenChatDrawer] = React.useState(false);

        const handleClickk = () => {
          setOpenChatDrawer(true);
        };
      
        const handleClosee = () => {
          setOpenChatDrawer(false);
        };
      
        // -----------------------------


  return (
    <Box sx={{ height: { lg: "44vh", xl: "58vh" }, width: "100%" }}>


 {openChatDrawer && <SingleChat open={openChatDrawer}  handleClose={ () => {
  getScenarioData();
  setSelectedRequest(null);
  handleClosee();
 }
  
  
  } request={selectedRequest} page_name="existing_sc_request" heading={`SC request for existing part`} />}
     


      
      {assignScenario.isOpen && (
        <ScenarioAssignModal
          isOpen={assignScenario.isOpen}
          onCloseModal={assignScenario.close}
          item={assignScenario.propsId}
          getScenarioData={() => console.log("")}
          setCouner={setCouner}
        />
      )}

      {senarioStausModal.isOpen && (
        <ScenarioRequestStatusModal
          isOpen={senarioStausModal.isOpen}
          onCloseModal={senarioStausModal.close}
          status={currentStatus}
          reOpen={reopen}
          rowData={senarioStausModal.propsId}
        />
      )}
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          columnGap: "1rem",
          padding: "0 1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#e5e5e5",
            borderRadius: 1,
            width: "18rem",
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search other ZBC requests"
            inputProps={{ "aria-label": "Search other ZBC requests" }}
            onChange={handleOnChange}
            onKeyDown={searchAPICall}
            value={searchValue}
          />
          {searchValue ? (
            <IconButton
              title="Clear Search"
              size="small"
              sx={{
                cursor: "pointer",
                color: "primary.main",
                backgroundColor: "primary.light",
                marginRight: "0.5rem",
              }}
              aria-label="clear-search"
              onClick={handleClearSearch}
            >
              {" "}
              <ClearIcon sx={{ fontSize: "1rem" }} />
            </IconButton>
          ) : (
            <Box sx={{ width: "3rem" }}></Box>
          )}
          <IconButton
            type="button"
            onClick={handleSearchIcon}
            sx={{ cursor: "pointer", color: "primary.main", padding: "0.2rem" }}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
        </Box>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "1rem",
            position: "relative",
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "1rem",
              position: "relative",
              zIndex: 1,
            }}
          >
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="All"
                name="radio-buttons-group"
                onChange={filterZBCRequest}
                value={selectFilter}
              >
                <FormControlLabel
                  defaultChecked
                  value="All"
                  control={<Radio />}
                  label="All"
                />
                <FormControlLabel
                  value="Completed"
                  control={<Radio />}
                  label="Completed"
                />
                <FormControlLabel
                  value="In Progress"
                  control={<Radio />}
                  label="In Progress"
                />
                <FormControlLabel
                  value="Not Started"
                  control={<Radio />}
                  label="Not Started"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <IconButton
            sx={{ cursor: page > 1 ? "pointer" : "not-allowed" }}
            disabled={page > 1 ? false : true}
            title="Previous Page"
            color="inherit"
            onClick={() => PaginationHandler("Previous")}
          >
            <ArrowCircleLeftIcon
              sx={{ cursor: page > 1 ? "pointer" : "not-allowed" }}
            />
          </IconButton>
          {page}
          <IconButton
            sx={{ cursor: page < totalPagecount ? "pointer" : "not-allowed" }}
            disabled={page < totalPagecount ? false : true}
            title="Next Page"
            color="inherit"
            onClick={() => PaginationHandler("Next")}
          >
            <ArrowCircleRightIcon
              sx={{ cursor: page < totalPagecount ? "pointer" : "not-allowed" }}
            />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          height: { lg: "45vh", xl: "58vh" },
          width: "100%",
          "& .super-app-theme--not_started": {
            bgcolor: "secondary.main",
            // color: "#4646d3",
          },
          "& .super-app-theme--completed": {
            bgcolor: "#99dfbb",
            // color: "#4646d3",
          },
          "& .super-app-theme--in_progress": {
            bgcolor: "#ffffa3",
            // color: "#4646d3",
          },
        }}
      >
        <DataGrid
          headerHeight={38}
          rowHeight={38}
          rows={rows}
          columns={columns}
          getRowClassName={(params) =>
            `super-app-theme--${params.row.color_code}`
          }
          sx={{
            "& ::-webkit-scrollbar": {
              width: "0.3rem",
              height: "0.3rem",
            },
            "& ::-webkit-scrollbar-thumb": {
              // background: $thumb-color;
              backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
              borderRadius: "10px",
            },
            "& ::- webkit-scrollbar-thumb: hover ": {
              // width: "4px",
              backgroundColor: "#045DE9 !important",
            },
            "::-webkit-scrollbar-track": {
              backgroundColor: "#e1e1f3 !important",
            },
            "&.MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              color: "text.primary",
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell": {
              borderBottomColor: "primary.light",
            },
          }}
          loading={loader}
          hideFooter
          disableSelectionOnClick
        />
      </Box>
    </Box>
  );
}
