import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import CancelIcon from "@mui/icons-material/Cancel";
import TextField from "@mui/material/TextField";
import { Button, Divider } from "@mui/material";
import { alpha } from "@mui/material/styles";
import moment from "moment";

interface GraphShowModalProps {
  Open: boolean;
  Close: () => void;
  setdateRange: any;
  dateRangeChild: any;
  setDateRangeChild: any;
  customEndDate: any;
  setCustomEndDate: any;
  customStartDate: any;
  setCustomStartDate: any;
}
const ActivityFilterModal = (props: GraphShowModalProps) => {
  const {
    Open,
    Close,
    setdateRange,
    setDateRangeChild,
    dateRangeChild,
    customEndDate,
    setCustomEndDate,
    customStartDate,
    setCustomStartDate,
  } = props;

  const getDateRange = (option: string) => {
    switch (option) {
      case "today":
        return `${moment().format("DD-MM-YYYY")}`;
      case "yesterday":
        return `${moment().subtract(1, "day").format("DD-MM-YYYY")}`;
      case "last7days":
        return `${moment()
          .subtract(7, "days")
          .format("DD-MM-YYYY")} - ${moment().format("DD-MM-YYYY")}`;
      case "last30days":
        return `${moment()
          .subtract(30, "days")
          .format("DD-MM-YYYY")} - ${moment().format("DD-MM-YYYY")}`;
      case "custom":
        return `${moment(customStartDate).format("DD-MM-YYYY")} - ${moment(
          customEndDate
        ).format("DD-MM-YYYY")}`;
      default:
        return `${moment().format("DD-MM-YYYY")}`;
    }
  };
  const getPayload = (option: string) => {
    switch (option) {
      case "today":
        return {
          log_from_range: `${moment().format("YYYY-MM-DD")}`,
          log_to_range: `${moment().format("YYYY-MM-DD")}`,
        };
      case "yesterday":
        return {
          log_from_range: `${moment().subtract(1, "day").format("YYYY-MM-DD")}`,
          log_to_range: `${moment().subtract(1, "day").format("YYYY-MM-DD")}`,
        };
      case "last7days":
        return {
          log_from_range: `${moment()
            .subtract(7, "days")
            .format("YYYY-MM-DD")}`,
          log_to_range: `${moment().format("YYYY-MM-DD")}`,
        };
      case "last30days":
        return {
          log_from_range: `${moment()
            .subtract(30, "days")
            .format("YYYY-MM-DD")}`,
          log_to_range: `${moment().format("YYYY-MM-DD")}`,
        };
      case "custom":
        return {
          log_from_range: `${moment(customStartDate).format("YYYY-MM-DD")}`,
          log_to_range: `${moment(customEndDate).format("YYYY-MM-DD")}`,
        };
      default:
        return { log_from_range: `${moment().format("YYYY-MM-DD")}` };
    }
  };

  const handleDateRangeChange = (range: string) => {
    setDateRangeChild(range);
    if (range !== "custom") {
      setCustomStartDate("");
      setCustomEndDate("");
    }
  };

  const handleApplyFilter = () => {
    setdateRange(getPayload(dateRangeChild));
    Close();
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "20vw",
    height: "60vh",
    bgcolor: "background.paper",
    borderRadius: "12px",
    boxShadow: 24,
    p: 3,
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  };

  const buttonStyle = (selected: boolean) => ({
    variant: "contained",
    fullWidth: true,
    sx: {
      backgroundColor: selected ? "primary.main" : "transparent",
      color: selected ? "common.white" : "text.primary",
      borderColor: selected ? "primary.main" : "divider",
      borderWidth: 1,
      borderStyle: "solid",
      "&:hover": {
        backgroundColor: selected
          ? alpha("#1976d2", 0.8)
          : alpha("#1976d2", 0.1),
        borderColor: "primary.main",
      },
    },
  });

  return (
    <Modal
      open={Open}
      onClose={Close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h6"
            component="h2"
            style={{
              fontWeight: 600,
              flexGrow: 1,
              textAlign: "center",
            }}
          >
            Day Wise Filter
          </Typography>
          <CancelIcon
            titleAccess="Close"
            sx={{ cursor: "pointer", color: "text.secondary" }}
            onClick={Close}
          />
        </Box>
        <Divider sx={{backgroundColor:'primary.light'}}/>

        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          {/* @ts-ignore */}
          <Button
            {...buttonStyle(dateRangeChild === "today")}
            onClick={() => handleDateRangeChange("today")}
          >
            Today
          </Button>
          {dateRangeChild === "today" && (
            <Typography
              variant="body2"
              sx={{ color: "#00887A", textAlign: "center" }}
            >
              Date: {getDateRange("today")}
            </Typography>
          )}
          {/* @ts-ignore */}
          <Button
            {...buttonStyle(dateRangeChild === "yesterday")}
            onClick={() => handleDateRangeChange("yesterday")}
          >
            Yesterday
          </Button>
          {dateRangeChild === "yesterday" && (
            <Typography
              variant="body2"
              sx={{ color: "#00887A", textAlign: "center" }}
            >
              Date: {getDateRange("yesterday")}
            </Typography>
          )}
          {/* @ts-ignore */}
          <Button
            {...buttonStyle(dateRangeChild === "last7days")}
            onClick={() => handleDateRangeChange("last7days")}
          >
            Last 7 Days
          </Button>
          {dateRangeChild === "last7days" && (
            <Typography
              variant="body2"
              sx={{ color: "#00887A", textAlign: "center" }}
            >
              Range: {getDateRange("last7days")}
            </Typography>
          )}
          {/* @ts-ignore */}
          <Button
            {...buttonStyle(dateRangeChild === "last30days")}
            onClick={() => handleDateRangeChange("last30days")}
          >
            Last 30 Days
          </Button>
          {dateRangeChild === "last30days" && (
            <Typography
              variant="body2"
              sx={{ color: "#00887A", textAlign: "center" }}
            >
              Range: {getDateRange("last30days")}
            </Typography>
          )}
          {/* @ts-ignore */}
          <Button
            {...buttonStyle(dateRangeChild === "custom")}
            onClick={() => handleDateRangeChange("custom")}
          >
            Custom Date Range
          </Button>
          {dateRangeChild === "custom" && (
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              <TextField
                label="Start Date"
                type="date"
                value={customStartDate}
                onChange={(e) => setCustomStartDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
              <TextField
                label="End Date"
                type="date"
                value={customEndDate}
                onChange={(e) => setCustomEndDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
              <Typography
                variant="body2"
                sx={{ color: "#00887A", textAlign: "center" }}
              >
                {getDateRange("custom")}
              </Typography>
            </Box>
          )}
        </Box>

        <Button
          variant="contained"
          color="primary"
          onClick={handleApplyFilter}
          fullWidth
        >
          Apply Filter
        </Button>
      </Box>
    </Modal>
  );
};

export default ActivityFilterModal;
