import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { API } from "../../api-services";
import { useHistory } from "react-router-dom";

interface SupplierDrawerProps {
  onClose: any;
  getIds: any;
  fetchSuppliers: any;
  setSupplierList: any;
}

const SuppliersDrawer = (props: SupplierDrawerProps) => {
  const { onClose, getIds,fetchSuppliers,setSupplierList } = props;
  const history = useHistory();
  
  const [selectedBOM, setSelectedBOM] = useState<any>(null);
  const [selectedVehicle, setSelectedVehicle] = useState<any>(null);
  const [vehiclecategoryList, setVehicleCategoryList] = useState([]);
  const [bomcategoryList, setBomCategoryList] = useState([]);
  const [levels, setLevels] = useState<any>({
    level1: null,
    level2: null,
    level3: null,
  });

  useEffect(() => {
    API.get("cart/get_all_vehiclecategory_list/", {}, 0).then((res: any) => {
      setVehicleCategoryList(res?.data);
    });
  }, []);

  useEffect(() => {
    // Define the function
    const getBOMCategoryList = () => {
      API.get(`db/get_supplier_db/`, {
        get_bom_category: true,
        product_category: levels.level1,
      }).then((res: any) => {
        setBomCategoryList(res.data);
      });
    };

    // Call the function
    levels.level1 !== null && getBOMCategoryList();

    // Clean up the function
  }, [levels]); // Empty dependency array to run once when component mounts

  useEffect(() => {
    if (levels.level1 !== null && levels.level2 !== null) {
      getIds(levels?.level1, levels?.level2);
      onClose();
    }
  }, [levels]);

  useEffect(() => {
    let hash = window.location.hash;
    let parts = hash.split("/");

    // Update state based on the current state
    setLevels((prevLevels: any) => ({
      ...prevLevels,
      level1: parts[4] !== "0" ? parseInt(parts[4]) : prevLevels.level1,
      level2: parts[5] !== "0" ? parseInt(parts[5]) : prevLevels.level2,
    }));
  }, [window.location.href]);

  useEffect(() => {
    if (vehiclecategoryList?.length > 0 && levels?.level1 !== 0) {
      setSelectedVehicle(vehiclecategoryList[levels?.level1]);
    }
    if (bomcategoryList?.length > 0 && levels?.level2 !== 0) {
      // setSelectedBOM(bomcategoryList[levels?.level2]);
    }
  }, [vehiclecategoryList, bomcategoryList]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          gap:'5px'
          // padding: "0rem 0.1rem 0.5rem 0.1rem",
        }}
      >
        <Button sx={{width:'19%'}} size="medium" variant="contained" onClick={async ()=>{
          // const suppiers: any = await fetchSuppliers(0, 0);
          // if (Array.isArray(suppiers)) {
          //   setSupplierList((prevData: any) => [...prevData, ...suppiers]);
          // }
          // history.push("/data-base/supplier-database/1/0/0/0")
          
           fetchSuppliers();
          }}>All</Button>
        <Autocomplete
          options={vehiclecategoryList}
          getOptionLabel={(vcategort: any) => vcategort?.name}
          onChange={(e, vcategort) => {
            let hash = window.location.hash;
            let parts = hash.split("/");
            
            parts[4] = vcategort?.id ? vcategort?.id : 0;
            parts[5] = "0"
          
            
           
            let newHash = parts.join("/");
            window.location.hash = newHash;

            setLevels({
              level1: vcategort?.id as number,
              level2: null,
              level3: null,
            });
            setSelectedBOM("");
          }}
          renderInput={(params) => (
            <TextField {...params} label="Product Category" />
          )}
          style={{ width: "42.5%" }}
        />

        <Autocomplete
          options={bomcategoryList}
          disabled={!levels?.level1}
          getOptionLabel={(bomCategory: any) => bomCategory?.name}
          onChange={(e, bomCategory: any) => {
            let hash = window.location.hash;
            let parts = hash.split("/");

            parts[5] = bomCategory?.id ? bomCategory?.id : 0;
            let newHash = parts.join("/");
            window.location.hash = newHash;
            setLevels({ ...levels, level2: bomCategory?.id });
          }}
          style={{ width: "42.5%" }}
          renderInput={(params) => (
            <TextField {...params} label="BOM Category" />
          )}
        />
      </Box>
    </>
  );
};

export default SuppliersDrawer;
