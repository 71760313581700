import { Box, Skeleton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteIcon from "@mui/icons-material/Delete";
import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
import StraightenIcon from "@mui/icons-material/Straighten";
import styles from "./BOMCreateImagesSection.module.scss";
import RViewerJS from "viewerjs-react";
import swal from "sweetalert";
import axios, { AxiosError, AxiosResponse } from "axios";
import { LoadingButton } from "@mui/lab";
import { API } from "../../../../api-services";
import { useRouteParams } from "../../../../ui-reusable-component/useRouteParams";
// import BOMImageSingleUpload from '../../BOMImageSingleUpload';
import { useBaseModal } from "../../../SearchPage/useBaseModal";
import ArchImageEditModal from "../../../ArchitectureCreate/ArchImageEditModal";
import { BOMCreateImageFetchModel } from "./BOMCreateImageFetchModel";
import BOMImageSingleUploadEdit from "../../BOMSingleImageSectionEdit";
import Image from "../../../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import NoImage from "../../../../Assets/images/loading-screen.gif";
import { MODULES } from "../../../../Constants/modules.constants";
import { s3Url } from "../../../../utlis/s3url.utils";
import { getVideoCover } from "../../../../CustomHook/CreateVideoThumbnail";
import Swal from "sweetalert2";
import { ADMIN } from "../../../../Redux/Services/admin.service";
import { useHistory } from "react-router-dom";

interface Params {
  vehCateId: any;
  vehCateName: any;
  topVault: any;
  vault: any;
  treeabbr: any;
  workstation: any;
  isLeaf: any;
  copyStatus: any;
  privateStatus: any;
}
const BOMImageSingleUpload = (
  fileList: any,
  component_details: any,
  parameter_id: any,
  vault: any,
  // top_vault:any,
  vehCateId: any,
  setApiCallVaultData: any,
  apiCallVaultData: any,
  nextValExist: any,
  setIsUpdating: any,
  callAPIOfNextImage?: any,
  type?: any,
  changeRoute?: any,
  privateStatus?: any
  // setImageUrl?:any
) => {
  // const file_name = file?.name;
  var random: any = Math.floor(Math.random() * 1000000 + 1);
  var editedImageName: any = `EditedImage${random}`;
  var obj: Array<any> = [];
  // const dispatch = useAppDispatch();
  // + "-" + uuidv4();
  API.get("cart/get_signed_policy/", {
    module: MODULES.CAD,
    // product: top_vault == 0 ? vault : top_vault,
    product: vault,
  }).then((res) => {
    const data = Array.from(fileList).forEach((file: any, index: any) => {
      const formData = new FormData();
      formData.append(
        "key",
        `BOMEntryImage/${vehCateId}/${vault ? vault : "Vehicle"}/${random}/${
          file.name || editedImageName
        }`
      );
      formData.append("Content-Type", file?.type);
      formData.append("x-amz-server-side-encryption", "AES256");
      formData.append("X-Amz-Credential", res.data.data.x_amz_credential);
      formData.append("X-Amz-Algorithm", "AWS4-HMAC-SHA256");
      formData.append("X-Amz-Date", res.data.data.x_amz_date);
      formData.append("X-Amz-Signature", res.data.data.aws_policy_signature);
      formData.append("Policy", res.data.data.aws_policy);
      formData.append("file", file, file?.name);

      axios
        .post(s3Url, formData, {
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
          },
        })
        .then(async (res: any) => {
          if (res.status === 204) {
            if (type == "video") {
              const data = new FormData();
              const dataThumnail = new FormData();
              // get the frame at 1.5 seconds of the video file
              const cover = await getVideoCover(file, 1.5);
              //  @ts-ignore
              dataThumnail.append(parameter_id, cover);
              data.append(
                parameter_id,
                `/BOMEntryImage/${vehCateId}/${
                  vault ? vault : "Vehicle"
                }/${random}/${file.name || editedImageName}`
              );
              API.put(
                `/bom/component_details/${component_details}/`,
                data,
                {
                  vault: vault == "0" ? null : vault,
                  vehCateId: vault == "0" ? vehCateId : null,
                  video: true,
                },
                0
              )
                .then((res: AxiosResponse) => {
                  API.put(
                    `/bom/component_details/${component_details}/`,
                    dataThumnail,
                    {
                      video_thumb: true,
                      vault: vault == "0" ? null : vault,
                      vehCateId: vault == "0" ? vehCateId : null,
                      video: true,
                    },
                    0
                  )
                    .then((res: AxiosResponse) => {
                      setApiCallVaultData(!apiCallVaultData);
                      // setImageUrl(CDN_URL + `/BOMEntryImage/${vehCateId}/${vault ? vault : "Vehicle"}/${random}/${file.name || editedImageName}`);
                      setIsUpdating(false);
                      changeRoute();
                    })
                    .catch((err: AxiosError) => {});
                })
                .catch((err: AxiosError) => {
                  setIsUpdating(false);
                  //@ts-ignore
                  const { data } = err.response;
                  Swal.fire({
                    title: "Error!",
                    text: data[0],
                    icon: "error",
                    confirmButtonText: "Ok",
                  });
                });
            } else {
              const data = new FormData();
              data.append(
                parameter_id,
                `/BOMEntryImage/${vehCateId}/${
                  vault ? vault : "Vehicle"
                }/${random}/${file.name || editedImageName}`
              );
              API.put(
                `/bom/component_details/${component_details}/`,
                data,
                {
                  vault: vault == "0" ? null : vault,
                  vehCateId: vault == "0" ? vehCateId : null,
                  image: true,
                  rgb: false,
                },
                0
              )
                .then((res: AxiosResponse) => {
                  // setImageUrl(CDN_URL + `/BOMEntryImage/${vehCateId}/${vault ? vault : "Vehicle"}/${random}/${file.name || editedImageName}`);
                  setIsUpdating(false);
                  changeRoute();
                  // nextValExist ? callAPIOfNextImage('next') : setApiCallVaultData(!apiCallVaultData);
                })
                .catch((err: AxiosError) => {
                  setIsUpdating(false);
                  if (err.response) {
                    //@ts-ignore
                    const { data } = err.response;
                    Swal.fire({
                      title: "Error!",
                      text: data[0],
                      icon: "error",
                      confirmButtonText: "Ok",
                    });
                  }
                });
            }
          }
        })
        .catch((err) => {
          return ADMIN?.toast?.error(`CDN access denied`);
        });
    });
  });
};
interface IBOMCreateImagesSectionProps {
  itemIncoming: any;
  apiCallVaultData: any;
  setApiCallVaultData: any;
  actions: any;
  setParentsCounter?: any;
}

const BOMCreateImagesSection: React.FC<IBOMCreateImagesSectionProps> = (
  props
) => {
  const {
    itemIncoming,
    apiCallVaultData,
    setApiCallVaultData,
    actions,
    setParentsCounter,
  } = props;
  const {
    vehCateId,
    vehCateName,
    vault,
    topVault,
    treeabbr,
    workstation,
    isLeaf,
    copyStatus,
    privateStatus
  } = useRouteParams<Params>();
  const history = useHistory();
  const [deleteLoading, setDeleteLoading] = React.useState<any>(false);
  const [id, setIsID] = React.useState<number>(0);
  const [imageUrl, setImageUrl] = React.useState<any>("");
  const [isUpdating, setIsUpdating] = React.useState<any>(false);
  const [isUpdatingIconName, setIsUpdatingIconName] = React.useState<any>("");
  const [loaderForFetch, setLoaderForFetch] = React.useState<any>(false);
  const ImageEditor = useBaseModal();
  const [BOMStatus, setBOMStatus] = React.useState<any>("");

  React.useEffect(() => {
    if (Object.keys(itemIncoming).length > 0) {
      setImageUrl(itemIncoming?.cdn + itemIncoming?.value);
    }
  }, [itemIncoming]);

  // const createBOMFetch = useBaseModal();

  const inputElRef = useRef<any>();
  const inputElRefPhone = useRef<any>();
  const changeRoute = () => {
    var random: any = Math.floor(Math.random() * 1000000 + 1);
    history.push(
      `/bomentry/${vehCateId}/${vehCateName}/${topVault}/${vault}/${treeabbr}/${random}/${isLeaf}/${copyStatus}/${privateStatus}/${privateStatus}`
    );
  };
  //-------------------image upload--------------------------------------------------------

  const saveImage = async (file: File, id: number) => {
    setIsUpdating(true);
    if (id == 1) {
      BOMImageSingleUpload(
        [file],
        itemIncoming?.component_id,
        itemIncoming?.key,
        vault,
        // topVault,
        vehCateId,
        setApiCallVaultData,
        apiCallVaultData,
        null,
        setIsUpdating,
        setParentsCounter,
        null,
        changeRoute,
        privateStatus
      );
    } else {
      const data = new FormData();
      data.append(itemIncoming?.key, file);
      API.put(
        `/bom/component_details/${itemIncoming?.component_id}/`,
        data,
        {
          vault: vault == "0" ? null : vault,
          vehCateId: vault == "0" ? vehCateId : null,
          image: true,
          rgb: true,
        },
        0
      )
        .then((res: AxiosResponse) => {
          setApiCallVaultData(!apiCallVaultData);
          setIsUpdating(false);
          BOMValidationStatus();
          setParentsCounter((prev: any) => prev + 1);
        })
        .catch((err: AxiosError) => {});
    }
  };
  const saveEditImage = async (file: File, id: number) => {
    setIsUpdating(true);
    if (id == 1) {
      BOMImageSingleUploadEdit(
        [file],
        itemIncoming?.component_id,
        itemIncoming?.key,
        vault,
        // topVault,
        vehCateId,
        setApiCallVaultData,
        apiCallVaultData,
        null,
        setIsUpdating
      );
    } else {
      const data = new FormData();
      data.append(itemIncoming?.key, file);
      API.put(
        `/bom/component_details/${itemIncoming?.component_id}/`,
        data,
        {
          vault: vault == "0" ? null : vault,
          vehCateId: vault == "0" ? vehCateId : null,
          image: true,
          rgb: true,
        },
        0
      )
        .then((res: AxiosResponse) => {
          setApiCallVaultData(!apiCallVaultData);
          setIsUpdating(false);
          BOMValidationStatus();
        })
        .catch((err: AxiosError) => {});
    }
  };

  // --------------------------------- image editor -----------------------------------------------------
  const imageForApp = itemIncoming?.["s3_url"]?.replaceAll(
    "prod.xcpep.com/",
    ""
  );
  const relImageURL =
    itemIncoming?.["s3_url"]?.includes("prod.xcpep.com/") === false
      ? itemIncoming?.["s3_url"]
          ?.split("?")[0]
          ?.split(".com")[1]
          ?.replaceAll("%20", " ")
          ?.replaceAll("%28", "(")
          ?.replaceAll("%29", ")")
      : imageForApp
          ?.split("?")[0]
          ?.split(".com")[1]
          ?.replaceAll("%20", " ")
          ?.replaceAll("%28", "(")
          ?.replaceAll("%29", ")");

  const updateEditedImage = async (file: File) => {
    saveEditImage(file, 1);
    // BOMImageSingleUploadEdit
  };

  const imageEditorHandler = () => {
    // setApiCallVaultData(!apiCallVaultData);
    ImageEditor.open();
  };

  // -----------------------------------------------------------------------------------------------------

  const browseFilesPhone = (idGet: any, iconName: string) => {
    setIsID(idGet);
    setIsUpdatingIconName(iconName);
    return inputElRefPhone.current?.click?.();
  };

  const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files?.[0];
    saveImage(file, id);
  };

  const handleInputClickPhone = (e: React.MouseEvent) => {
    // clear the prev value on click so that same image can be uploaded again
    (e.target as HTMLInputElement).value = "";
  };

  // -------------------------------------------------------------------------------------------------------

  const browseFiles = (idGet: any, iconName: string) => {
    setIsID(idGet);
    setIsUpdatingIconName(iconName);
    return inputElRef.current?.click?.();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files?.[0];
    setImageUrl(URL.createObjectURL(file));
    saveImage(file, id);
    // setDisable( false );
  };

  const handleInputClick = (e: React.MouseEvent) => {
    // clear the prev value on click so that same image can be uploaded again
    (e.target as HTMLInputElement).value = "";
  };

  //----------------------------------------------------------------------------------------------------------------
  const obj = (tv: any, vlt: any) => {
    if (vlt == 0) {
      return { vehicle_category: tv };
    } else {
      return { vault: vlt };
    }
  };

  const BOMValidationStatus = () => {
    API.get(
      "/bom/bom_hirearchy_function/",
      {
        bom_status: true,
        ...obj(vehCateId, vault),
      },
      0
    )
      .then((res: any) => {
        setBOMStatus(res.data?.bom_status);
      })
      .catch((err: any) => {});
  };

  const handleDeleteSelectedImages = async (e: React.MouseEvent) => {
    e.stopPropagation();
    swal({
      title: `Delete Image Parameter`,
      text: `Are you sure?`,
      icon: "error",
      //@ts-ignore
      buttons: {
        cancel: "Cancel",
        permanent: {
          text: `Delete Image`,
          value: `deleteImages`,
        },
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
      className: "delete-swal",
    }).then(async (type: string) => {
      switch (type) {
        case "deleteImages":
          setDeleteLoading(true);
          API.put(
            `/bom/component_details/${itemIncoming?.component_id}/`,
            { [itemIncoming?.key]: null },
            {
              vault: vault == "0" ? null : vault,
              vehCateId: vault == "0" ? vehCateId : null,
              image: true,
              rgb: true,
            },
            0
          )
            .then((res: AxiosResponse) => {
              setApiCallVaultData(!apiCallVaultData);
              setDeleteLoading(false);
              BOMValidationStatus();
            })
            .catch((err: AxiosError) => {});
          break;
        default:
          swal(`Image saved from deleting`, { icon: "error" });
      }
    });
  };

  const handleFetchImage = (urlFetchedImage: any) => {
    setLoaderForFetch(true);
    let abc = 0;
    const data = new FormData();
    data.append(
      itemIncoming?.key,
      urlFetchedImage?.current[abc]?.substring(
        urlFetchedImage?.current[abc]?.indexOf(".net") + 4
      )
    );
    API.put(
      `/bom/component_details/${itemIncoming?.component_id}/`,
      data,
      {
        vault: vault == "0" ? null : vault,
        vehCateId: vault == "0" ? vehCateId : null,
        image: true,
        rgb: true,
      },
      0
    )
      .then((res: AxiosResponse) => {
        setLoaderForFetch(false);
        setApiCallVaultData(!apiCallVaultData);
        BOMValidationStatus();
      })
      .catch((err: AxiosError) => {});
  };

  return (
    <>
      {/* {createBOMFetch.isOpen && (
				<BOMCreateImageFetchModel
					onCloseModal={createBOMFetch.close}
					isOpen={createBOMFetch.isOpen}
					handleFetchImage={handleFetchImage}
					loaderForFetch={loaderForFetch}
				/>
			)} */}

      {ImageEditor?.isOpen && (
        <ArchImageEditModal
          Loader={isUpdating}
          open={ImageEditor.isOpen}
          imageURI={relImageURL}
          closeModal={ImageEditor.close}
          updateImage={updateEditedImage}
          setImageUrl={setImageUrl}
        />
      )}

      <Box className={styles.bomgridContainer}>
        {itemIncoming?.value == "" && imageUrl?.length == 0 ? (
          <div className={styles.bomDivNoImage}>
            {isUpdating ? (
              <Skeleton sx={{ height: "100%", width: "100%" }} />
            ) : (
              <span
                onClick={() =>
                  actions?.includes("C") &&
                  browseFiles(1, "OriginalWithBackgroudGallery")
                }
              >
                {itemIncoming?.paramter_name}
              </span>
            )}
          </div>
        ) : isUpdating ? (
          <Skeleton sx={{ height: "100%", width: "100%" }} />
        ) : (
          <Image
            title={itemIncoming?.paramter_name}
            src={`${imageUrl || itemIncoming?.cdn + itemIncoming?.value}`}
            placeholderImg={NoImage}
            className={styles.bomgridImages}
            alt={itemIncoming?.paramter_name}
          />
        )}
        <Box className={styles.imageOptions} sx={{ margin: "1rem 0" }}>
          {(copyStatus == false || copyStatus == "false") && (
            <Box className={styles.optionDiv}>
              {actions?.includes("U") && (
                <LoadingButton
                  style={{ padding: 0, minWidth: "2rem" }}
                  onClick={imageEditorHandler}
                >
                  <EditIcon
                    titleAccess="Edit Image"
                    sx={{ cursor: "pointer" }}
                  />
                </LoadingButton>
              )}
              {(itemIncoming?.value != ""
                ? actions?.includes("U")
                : actions?.includes("C")) && (
                <LoadingButton
                  style={{ padding: 0, minWidth: "2rem" }}
                  onClick={() => browseFiles(1, "OriginalWithBackgroudGallery")}
                  loading={
                    isUpdatingIconName == "OriginalWithBackgroudGallery" &&
                    isUpdating
                  }
                >
                  <UploadIcon
                    titleAccess="Upload Original Background"
                    sx={{ cursor: "pointer" }}
                  />
                </LoadingButton>
              )}
              {(itemIncoming?.value != ""
                ? actions?.includes("U")
                : actions?.includes("C")) && (
                <LoadingButton
                  style={{ padding: 0, minWidth: "2rem" }}
                  onClick={() =>
                    browseFiles(2, "OriginalWithoutBackgroudGallery")
                  }
                  loading={
                    isUpdatingIconName == "OriginalWithoutBackgroudGallery" &&
                    isUpdating
                  }
                >
                  <UploadIcon
                    titleAccess="Upload without Background"
                    sx={{ cursor: "pointer", color: "#999191" }}
                  />
                </LoadingButton>
              )}
              {itemIncoming?.value != "" &&
                !itemIncoming?.required &&
                actions?.includes("D") && (
                  <LoadingButton
                    style={{ padding: 0, minWidth: "2rem" }}
                    onClick={handleDeleteSelectedImages}
                    loading={deleteLoading}
                  >
                    <DeleteIcon
                      titleAccess="Delete"
                      sx={{ cursor: "pointer" }}
                    />
                  </LoadingButton>
                )}
              {itemIncoming?.value != "" && (
                <LoadingButton
                  style={{ padding: 0, minWidth: "2rem" }}
                  onClick={() =>
                    window.open(
                      `/#/bom-image-measurement/${vehCateId}/${vehCateName}/0/${vault}/${itemIncoming?.key}`
                    )
                  }
                >
                  <StraightenIcon
                    titleAccess="Measurement"
                    sx={{ cursor: "pointer" }}
                  />
                </LoadingButton>
              )}
              {/* {(itemIncoming?.value != ''
								? actions?.includes('U')
								: actions?.includes('C')) && (
								<LoadingButton
									style={{ padding: 0, minWidth: '2rem' }}
									onClick={() => createBOMFetch.open()}>
									Fetch
								</LoadingButton>
							)} */}
            </Box>
          )}
        </Box>
        <input
          type="file"
          style={{ display: "none" }}
          // required
          accept="image/*"
          id="file"
          name="file"
          ref={inputElRefPhone}
          onChange={handleChangePhone}
          onClick={handleInputClickPhone}
          capture="user"
        />

        <input
          type="file"
          style={{ display: "none" }}
          // required
          accept="image/*"
          id="file"
          name="file"
          ref={inputElRef}
          onChange={handleChange}
          onClick={handleInputClick}
        />
      </Box>
    </>
  );
};

export default BOMCreateImagesSection;
