import { Box, Button, DialogActions, DialogContent, Divider, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import { Formik, Field, Form } from 'formik'; // Use Formik's Form component
import CancelIcon from '@mui/icons-material/Cancel';
import React from 'react'

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50rem",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

function ManualDirectorModal({ isOpen, onCloseModal, director, onSave }: any) {
  const parseDate = (dateString:any) => {
    const parts = dateString.split(/[-/]/); // Split by '-' or '/'
    if (parts.length === 3) {
      const [day, month, year] = parts;
      return `${year}-${month}-${day}`; // Return as "YYYY-MM-DD"
    }
    return dateString; // Return original if format is unexpected
  };

  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: '-2rem'
            }}
          >
            Edit Director/Signatory
          </Typography>
          <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
            marginBottom: "1rem",
          }}
        />


        <Formik
          initialValues={director}
          onSubmit={(values) => {
            onSave(values); // Save updated director data
            onCloseModal(); // Close modal after saving
          }}
        >
          {({ touched, errors, dirty }) => (
            <Form>
              <DialogContent>
                <Field name="din">
                  {({ field }: any) => (
                    <TextField
                      {...field}
                      label="DIN"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      error={!!(touched.din && errors.din)}
                      helperText={touched.din && errors.din}
                    />
                  )}
                </Field>
                <Field name="firstName">
                  {({ field }: any) => (
                    <TextField
                      {...field}
                      label="First Name"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      error={!!(touched.firstName && errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                    />
                  )}
                </Field>
                <Field name="middleName">
                  {({ field }: any) => (
                    <TextField
                      {...field}
                      label="Middle Name"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      error={!!(touched.middleName && errors.middleName)}
                      helperText={touched.middleName && errors.middleName}
                    />
                  )}
                </Field>
                <Field name="lastName">
                  {({ field }: any) => (
                    <TextField
                      {...field}
                      label="Last Name"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      error={!!(touched.lastName && errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                    />
                  )}
                </Field>
                

                <Field name="appointmentDate">
                    {({ field }: any) => (
                      <TextField
                        {...field}
                        label="Appointment Date"
                        variant="outlined"
                        fullWidth
                        sx={{fontSize:'1rem',}}
                        error={touched.appointmentDate && !!errors.appointmentDate}
                        helperText={touched.appointmentDate && errors.appointmentDate}
                      />
                    )}
                  </Field>
               

                <Field name="designation">
                  {({ field }: any) => (
                    <TextField
                      {...field}
                      label="Designation"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      error={!!(touched.designation && errors.designation)}
                      helperText={touched.designation && errors.designation}
                    />
                  )}
                </Field>

                <Field name="isCurrentSignatory">
                  {({ field, form }: any) => (
                    <FormControl fullWidth variant="outlined" margin="normal">
                      <InputLabel id="isCurrentSignatory-label">Current Signatory</InputLabel>
                      <Select
                        labelId="isCurrentSignatory-label"
                        label="Current Signatory"
                        value={field.value ? String(field.value) : ''} // Ensure value is a string for Select
                        onChange={(event) => {
                          const value = event.target.value === "true"; // Convert string to boolean
                          form.setFieldValue(field.name, value); // Update Formik state
                        }}
                      >
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                      </Select>
                      {/* {touched.isCurrentSignatory && errors.isCurrentSignatory && (
                        <Typography color="error">{errors.isCurrentSignatory}</Typography>
                      )} */}
                    </FormControl>
                  )}
                </Field>



              </DialogContent>
              <DialogActions>
                <Button onClick={onCloseModal}>Cancel</Button>
                <Button type="submit" variant="contained" color="primary" disabled={!dirty}>
                  Save Changes
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>

      </Box>
    </Modal>
  );
}

export default ManualDirectorModal;