import * as React from "react";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { API } from "../../../api-services";
import CreateEstimationTree from "./CreateEstimationTree";
import { useHistory } from "react-router-dom";
import { Box, Skeleton } from "@mui/material";
import Swal from "sweetalert2";
import CostingTreeCloseAlertModal from "./CostingTreeCloseAlertModal";
import { useBaseParamsModal } from "../../../CustomHook/useBaseParamsModal";

export interface ICreateCostingDrawerProps {
  hierarchyData: any;
  hierarchyDataLoading: any;
}

export function CreateCostingDrawer(props: ICreateCostingDrawerProps) {
  const { hierarchyData, hierarchyDataLoading } = props;
  const { projectId, projectName, productId, topVault, vault, abbrebation,scenarioId } =
    useRouteParams<any>();
  const history = useHistory();
  const [flag, setFlag] = React.useState<any>(false);

  const onSelect = (value: any, abbreviation: any, topv: any, status: any) => {
    // console.log("statussss", status);
    // You can put whatever here
    //setSelectedData(value);
    //setAbbr(abbreviation);
    //setLoading(true);
    if (status === 3) {
      if (abbreviation == "PRODUCT") {
        //setLoading(false);
        //history.push(`/view/${projectId}/${topVault}/0/product/true`);
        history.push(
          `/createcostingpart/${projectId}/${projectName}/${productId}/${topVault}/0/product/0/0/0/0/0/0/0/${scenarioId}/0`
        );
      } else {
        //setLoading(false);
        //history.push(`/view/${projectId}/${topVault}/${value}/${abbreviation}/true`);
        history.push(
          `/createcostingpart/${projectId}/${projectName}/${productId}/${topVault}/${value}/${
            abbreviation ? abbreviation : "none"
          }/0/0/0/0/0/0/0/${scenarioId}/0/0`
        );
      }
    } else {

      CancelCostModal.open();
      
      // Swal.fire({
      //   icon: "error",

      //   html: `<div>

      // <br />

      // <p style="color:"red">Please validate BOM first to redirect to the costing page.</p>   

      //  </div>`,
      // });
    }
  };

  const CancelCostModal = useBaseParamsModal();
  // const cancelCostModelHndler = () => {
  //   CancelCostModal.open();
  //   getRMData();
  //   // getRMData();
  //   // parentClose();
  //   // onCloseModal();
  //   // / :projectName/:productId/:topVault/:vault/:abbrebation/:cardtype/:tableId/:costEstimationId/:tableCounter/:condition/:calctype/:calcultorId
  //   history.push(`/createcostingpart/${projectId}/${projectName}/${productId}/${topVault}/${vault}/${abbrebation}/${cardtype}/${materialItemsData[0]?.id}/${costDetailsData?.cost_estimation}/${0}/${0}/${1}/0`)


  // }
  // const closeHandlerParent = () => {
  //   API.post(
  //     "/cost/cost_calculator/",
  //     {
  //       material: true,
  //       cost_estimation: costDetailsData?.cost_estimation,
  //     },
  //     {},
  //     0
  //   )
  //     .then((res: any) => {
  //       onCloseModal();
  //       parentClose();
  //     })
  //     .catch((err: any) => { });
  // }

  return (
    <div>
      {CancelCostModal.isOpen && (
        <CostingTreeCloseAlertModal
          isOpen={CancelCostModal.open}
          onCloseModal={CancelCostModal.close}
          // cancelHandler={closeHandlerParent}
          // parentClose={parentClose}
        />
      )}

      {!hierarchyDataLoading ? (
        <CreateEstimationTree
          HierarchyData={hierarchyData && hierarchyData}
          onSelectCallback={onSelect}
        />
      ) : (
        <>
          <Box sx={{ width: "100%", padding: "0 0.5rem" }}>
            <Skeleton sx={{ height: "2rem", width: "30%" }} />
            <Skeleton sx={{ height: "2rem", width: "45%" }} />
            <Skeleton sx={{ height: "2rem", width: "50%" }} />
            <Skeleton sx={{ height: "2rem", width: "65%" }} />
            <Skeleton sx={{ height: "2rem", width: "70%" }} />
            <Skeleton sx={{ height: "2rem", width: "60%" }} />
            <Skeleton sx={{ height: "2rem", width: "55%" }} />
            <Skeleton sx={{ height: "2rem", width: "30%" }} />
            <Skeleton sx={{ height: "2rem", width: "45%" }} />
            <Skeleton sx={{ height: "2rem", width: "50%" }} />
            <Skeleton sx={{ height: "2rem", width: "35%" }} />
            <Skeleton sx={{ height: "2rem", width: "70%" }} />
            <Skeleton sx={{ height: "2rem", width: "55%" }} />
            <Skeleton sx={{ height: "2rem", width: "80%" }} />
            <Skeleton sx={{ height: "2rem", width: "30%" }} />
            <Skeleton sx={{ height: "2rem", width: "45%" }} />
            <Skeleton sx={{ height: "2rem", width: "50%" }} />
            <Skeleton sx={{ height: "2rem", width: "65%" }} />
            <Skeleton sx={{ height: "2rem", width: "70%" }} />
            <Skeleton sx={{ height: "2rem", width: "60%" }} />
            <Skeleton sx={{ height: "2rem", width: "55%" }} />
            <Skeleton sx={{ height: "2rem", width: "30%" }} />
            <Skeleton sx={{ height: "2rem", width: "45%" }} />
            <Skeleton sx={{ height: "2rem", width: "50%" }} />
            <Skeleton sx={{ height: "2rem", width: "35%" }} />
            <Skeleton sx={{ height: "2rem", width: "70%" }} />
            <Skeleton sx={{ height: "2rem", width: "55%" }} />
            <Skeleton sx={{ height: "2rem", width: "80%" }} />
          </Box>
        </>
      )}
    </div>
  );
}
