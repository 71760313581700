import React, { useEffect } from "react";
import {
  Autocomplete,
  Chip,
  IconButton,
  OutlinedInput,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { API } from "../../api-services";
import { AxiosResponse } from "axios";
import swal from "sweetalert";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import { isArray } from "lodash";

const stringTypes = [
  "textarea",
  "string",
  "longtext",
  "singleselect",
  "multiselect",
];
const numberTypes = [
  "float",
  "nonzero-float",
  "positive-float",
  "nonzero-positive-float",
  "negative-float",
  "nonzero-negative-float",
  "integer",
  "nonzero-integer",
  "positive-int",
  "nonzero-positive-int",
  "negative-int",
  "nonzero-negative-int",
  "percentage",
];

const stringOperations = {
  matches: "Matches",
  noMatch: "Not Match",
  includes: "Includes",
  in: "In",
  oneOf: "One Of",
  notOneOf: "Not One Of",
};
const numberOperations = {
  lessThan: "Less Than",
  greaterThan: "Greater Than",
  lessThanOrEqual: "Less Than or Equal",
  greaterThanOrEqual: "Greater Than or Equal",
  equality: "Equals",
  unEquality: "Not Equals",
  oneOf: "One Of",
  notOneOf: "Not One Of",
};
const booleanOperations = {
  equality: "Equals",
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface BOMCustomValidationRowsI {
  validationuuid: any;
  addedRowSize: any;
  itemInner: any;
  expressionRepeat: any;
  indexIncoming: number;
  parameterListData: any;
  parameterID: any;
  relationID: any;
  apiCallValidationShowData: any;
  setApiCallValidationShowData: any;
  handleClickSnackbar: any;
  deleteModelShow: any;
  setErrorMessage: any;
  expressionIncoming: any;
  setExpressionIncoming: any;
  messageIncoming: any;
  setMessageIncoming: any;
  saveLogicIncoming: any;
  setSaveLogicIncoming: any;
  itemData?: any;
}

const BOMCustomValidationRows: React.FC<BOMCustomValidationRowsI> = (props) => {
  const {
    validationuuid,
    addedRowSize,
    itemInner,
    expressionRepeat,
    indexIncoming,
    parameterListData,
    parameterID,
    relationID,
    apiCallValidationShowData,
    setApiCallValidationShowData,
    handleClickSnackbar,
    deleteModelShow,
    setErrorMessage,
    expressionIncoming,
    setExpressionIncoming,
    messageIncoming,
    setMessageIncoming,
    saveLogicIncoming,
    setSaveLogicIncoming,
    itemData
  } = props;
  const [parameter, setParameter] = React.useState<any>(expressionRepeat?.lhs);
  const [parameterType, setParameterType] = React.useState<any>(
    expressionRepeat?.type
  );
  const [expression, setExpression] = React.useState<any>(expressionIncoming);
  const [message, setMessage] = React.useState<any>(itemInner?.message);
  const [operator, setOperator] = React.useState<any>(
    expressionRepeat?.operation
  );
  const [optionsOperator, setOptionsOperator] = React.useState<any>([]);
  const [value, setValue] = React.useState<any>(
    expressionRepeat?.type === "p-v" &&
      ["singleselect", "multiselect"].includes(
        parameterListData &&
          parameterListData?.find((item: any) => {
            return item?.id === parameter;
          })?.parameter?.data_type
      )
      ? ""
      : expressionRepeat?.rhs
  );
  const [multiValue, setMultiValue] = React.useState<string[]>(
    expressionRepeat?.type === "p-v" &&
      ["singleselect", "multiselect"].includes(
        parameterListData &&
          parameterListData?.find((item: any) => {
            return item?.id === parameter;
          })?.parameter?.data_type
      )
      ? isArray(expressionRepeat?.rhs) && expressionRepeat?.rhs?.length > 0
        ? expressionRepeat?.rhs?.map((item: any) => {
            return { key: item, value: item, name: item };
          })
        : []
      : []
  );
  const [saveExpression, setSaveExpression] = React.useState<boolean>(false);
  const [saveLogic, setSaveLogic] = React.useState<boolean>(false);
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    setParameter(undefined);
    setParameterType(undefined);
    setOperator(undefined);
    setParameter(expressionRepeat?.lhs);
    setParameterType(expressionRepeat?.type);
    setOperator(expressionRepeat?.operation);
    setOptionsOperator([]);
    setValue(
      expressionRepeat?.type === "p-v" &&
        ["singleselect", "multiselect"].includes(
          parameterListData &&
            parameterListData?.find((item: any) => {
              return item?.id === parameter;
            })?.parameter?.data_type
        )
        ? ""
        : expressionRepeat?.rhs
    );
  }, [expressionRepeat]);

  useEffect(() => {
    setMultiValue([]);
    parameterListData &&
      setMultiValue(
        expressionRepeat?.type === "p-v" &&
          ["singleselect", "multiselect"].includes(
            parameterListData &&
              parameterListData?.find((item: any) => {
                return item?.id === parameter;
              })?.parameter?.data_type
          )
          ? isArray(expressionRepeat?.rhs) && expressionRepeat?.rhs?.length > 0
            ? expressionRepeat?.rhs?.map((item: any) => {
                return { key: item, value: item, name: item };
              })
            : [
                {
                  key: expressionRepeat?.rhs,
                  value: expressionRepeat?.rhs,
                  name: expressionRepeat?.rhs,
                },
              ]
          : []
      );
  }, [parameterListData]);

  useEffect(() => {
    setExpression(expressionIncoming);
  }, [expressionIncoming]);

  useEffect(() => {
    setMessage(messageIncoming);
  }, [messageIncoming]);

  useEffect(() => {
    setSaveLogic(saveLogicIncoming);
  }, [saveLogicIncoming]);

  const parameterDropdown =
    parameterListData &&
    parameterListData?.map((item: any) => {
      return { id: item?.id, name: item?.parameter?.name, value: item?.id };
    });

  useEffect(() => {
    let abc: any =
      parameterListData &&
      parameterListData?.find((item: any) => {
        return item?.id === expressionRepeat?.lhs;
      })?.parameter?.data_type;
    setOptionsOperator(
      stringTypes.includes(abc)
        ? Object.entries(stringOperations).map(([key, value]) => ({
            key: key,
            value: key,
            text: value,
          }))
        : numberTypes.includes(abc)
        ? Object.entries(numberOperations).map(([key, value]) => ({
            key: key,
            value: key,
            text: value,
          }))
        : Object.entries(booleanOperations).map(([key, value]) => ({
            key: key,
            value: key,
            text: value,
          }))
    );
  }, [expressionRepeat, parameterListData]);

  const handleChangeParameterType = (event: SelectChangeEvent) => {
    expressionRepeat?.type != event.target.value
      ? setSaveExpression(true)
      : setSaveExpression(false);
    setParameterType(event.target.value);
    expressionRepeat?.type != event.target.value
      ? setValue(undefined)
      : setValue(expressionRepeat?.rhs);
    setParameter(undefined);
    setOperator(undefined);
    setValue(undefined);
  };

  const handleChangeParameter = (event: SelectChangeEvent) => {
    expressionRepeat?.lhs != event.target.value
      ? setSaveExpression(true)
      : setSaveExpression(false);
    setParameter(event.target.value);
    expressionRepeat?.lhs != event.target.value
      ? setOperator(undefined)
      : setOperator(expressionRepeat?.operation);
    let abc: any =
      parameterListData &&
      parameterListData?.find((item: any) => {
        return item?.id === event.target.value;
      })?.parameter?.data_type;
    setOptionsOperator(
      stringTypes.includes(abc)
        ? Object.entries(stringOperations).map(([key, value]) => ({
            key: key,
            value: key,
            text: value,
          }))
        : numberTypes.includes(abc)
        ? Object.entries(numberOperations).map(([key, value]) => ({
            key: key,
            value: key,
            text: value,
          }))
        : Object.entries(booleanOperations).map(([key, value]) => ({
            key: key,
            value: key,
            text: value,
          }))
    );

    setOperator(undefined);
    setValue(undefined);
  };

  const handleChangeOperator = (event: any) => {
    expressionRepeat?.operation != event.target.value
      ? setSaveExpression(true)
      : setSaveExpression(false);
    setOperator(event.target.value);
    setValue(undefined);
    setMultiValue([]);
  };

  const handleChangeValue = (event: any) => {
    expressionRepeat?.rhs != event.target.value
      ? setSaveExpression(true)
      : setSaveExpression(false);
    setValue(event.target.value);
  };

  const handleChangeExpression = (event: any) => {
    expressionRepeat?.finalExpression != event.target.value
      ? setSaveLogicIncoming(true)
      : setSaveLogicIncoming(false);
    setExpressionIncoming(event.target.value);
  };

  const handleChangeMessage = (event: any) => {
    expressionRepeat?.message != event.target.value
      ? setSaveLogicIncoming(true)
      : setSaveLogicIncoming(false);
    setMessageIncoming(event.target.value);
  };

  const handleSaveExpressionValue: any = () => {
    API.put(
      `bom/custom_validation/${parameterID}/`,
      {
        relation_id: relationID,
        main_uid: validationuuid,
        sub_uid: expressionRepeat?.uid,
        type: parameterType,
        lhs: parameter,
        operation: operator,
        rhs:
          parameterType === "p-v" &&
          ["singleselect", "multiselect"].includes(
            parameterListData &&
              parameterListData?.find((item: any) => {
                return item?.id === parameter;
              })?.parameter?.data_type
          )
            ? multiValue?.map((item: any) => item.value)
            : value,
      },
      0
    )
      .then((res: AxiosResponse) => {
        setSaveExpression(false);
      })
      .catch((err: any) => {
        const { data } = err.response;
        setErrorMessage(data?.[0]);
        deleteModelShow.open();
      });
  };

  const handleSaveExpressionLogic: any = () => {
    if (expression?.includes("]exp")) {
      setErrorMessage("Please check the expression");
      deleteModelShow.open();
    } else {
      API.put(
        `bom/custom_validation/${parameterID}/`,
        {
          relation_id: relationID,
          main_uid: validationuuid,
          message: message,
          finalExpression: expression,
        },
        0
      )
        .then((res: AxiosResponse) => {
          setApiCallValidationShowData(!apiCallValidationShowData);
          setSaveLogicIncoming(false);
        })
        .catch((err: any) => {
          const { data } = err.response;
          setErrorMessage(data?.[0]);
          deleteModelShow.open();
        });
    }
  };

  const handleDeleteValidationValue = async (
    e: React.MouseEvent,
    id: number
  ) => {
    e.stopPropagation();
    swal({
      title: `Delete Validation Expression Values`,
      text: `Are you sure?`,
      icon: "error",
      //@ts-ignore
      buttons: {
        cancel: "Cancel",
        permanent: {
          text: `Delete`,
          value: `deleteValidationExpressionValues`,
        },
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
      className: "delete-swal",
    }).then(async (type: string) => {
      switch (type) {
        case "deleteValidationExpressionValues":
          API.delete(
            "bom/custom_validation/" + id + "/",
            {
              relation_id: relationID,
              main_uid: validationuuid,
              sub_uid: expressionRepeat?.uid,
              expression_id: `exp['${indexIncoming}']`,
            },
            0
          )
            .then((res: any) => {
              setApiCallValidationShowData(!apiCallValidationShowData);
            })
            .catch((err: any) => {
              setErrorMessage(err.response.data);
              deleteModelShow.open();
              console.log(err, "Unable to upload. Server Error");
            });
          break;
        default:
          swal(`You Canceled Validation Expression Values from deleting`, {
            icon: "error",
          });
      }
    });
  };

  // const handleDeleteValidationLogic = async (e: React.MouseEvent, id: number) => {
  //   e.stopPropagation();
  //   swal({
  //     title: `Delete Validation Final Expression`,
  //     text: `Are you sure?`,
  //     icon: "error",
  //     //@ts-ignore
  //     buttons: {
  //       cancel: "Cancel",
  //       permanent: {
  //         text: `Delete`,
  //         value: `deleteValidationFinalExpression`,
  //       },
  //     },
  //     closeOnClickOutside: false,
  //     closeOnEsc: false,
  //     className: "delete-swal",
  //   }).then(async (type: string) => {
  //     switch (type) {
  //       case "deleteValidationFinalExpression":
  //         API.delete("/config/template_validation/" + id + "/",{relation_id:relationID,main_uid:validationuuid,delete_validation:true},0)
  //           .then((res: any) => {
  //             setApiCallValidationShowData(!apiCallValidationShowData)
  //           })
  //           .catch((err: any) => {
  //             setErrorMessage(err.response.data);
  //             deleteModelShow.open()
  //             console.log(err, "Unable to upload. Server Error");
  //           });
  //         break;
  //       default:
  //         swal(`Validation Final Expression saved from deleting`, { icon: "error" });
  //     }
  //   });
  // };

  const checkDisabledButtonExpressionLogic = () => {
    if (message) {
      return false;
    }
    return true;
  };

  const copyContentExpressionID: any = (content: any) => {
    handleClickSnackbar();
    setExpressionIncoming(expressionIncoming + content);
    setSaveLogicIncoming(true);
    navigator.clipboard.writeText(content);
  };

  const handleSelectChangeProduct = (Productitem: any, situation: any) => {
    setSaveExpression(true);
    setMultiValue([...Productitem]);
  };

  return (
    <TableRow>
      <TableCell
        sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
      >
        {indexIncoming}
      </TableCell>
      <TableCell
        sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
      >
        <div style={{ display: "flex" }}>
          <span
            style={
              expression?.includes(`exp['${indexIncoming}']`)
                ? { marginRight: "0.5rem", color: "green" }
                : { marginRight: "0.5rem" }
            }
          >
            exp['{indexIncoming}']{" "}
          </span>
          <ContentCopyIcon
            sx={{ cursor: "pointer" }}
            onClick={() => copyContentExpressionID(`exp['${indexIncoming}']`)}
          />
        </div>
      </TableCell>
      <TableCell
        sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
      >
        <FormControl
          variant="standard"
          sx={{ m: 1, width: width > 1400 ? 170 : 80 }}
          fullWidth
        >
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={parameterType}
            disabled={itemData?.lock}
            onChange={handleChangeParameterType}
            sx={{
              "&:before": {
                borderBottomColor: "primary.light",
              },
              "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                {
                  fontSize: "1rem",
                },
              ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
            }}
          >
            <MenuItem sx={{ fontSize: "1rem" }} value={"p-p"}>
              Parameter to Parameter
            </MenuItem>
            <MenuItem sx={{ fontSize: "1rem" }} value={"p-v"}>
              Parameter to Value
            </MenuItem>
            <MenuItem sx={{ fontSize: "1rem" }} value={"p-m"}>
              Parameter to Meta
            </MenuItem>
          </Select>
        </FormControl>
      </TableCell>
      <TableCell
        sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
      >
        <FormControl
          variant="standard"
          sx={{ m: 1, width: width > 1400 ? 170 : 80 }}
          fullWidth
        >
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={parameter}
            disabled={itemData?.lock}
            onChange={handleChangeParameter}
            sx={{
              "&:before": {
                borderBottomColor: "primary.light",
              },
              "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                {
                  fontSize: "1rem",
                },
              ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
            }}
          >
            {parameterDropdown?.map((item: any) => {
              return (
                <MenuItem
                  sx={{ fontSize: "1rem" }}
                  key={item?.id}
                  value={item?.value}
                >
                  {item?.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell
        sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
      >
        <FormControl
          variant="standard"
          sx={{ m: 1, width: width > 1400 ? 120 : 80 }}
          fullWidth
        >
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={operator}
            disabled={itemData?.lock}
            onChange={handleChangeOperator}
            sx={{
              "&:before": {
                borderBottomColor: "primary.light",
              },
              "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                {
                  fontSize: "1rem",
                },
              ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
            }}
          >
            {optionsOperator &&
              optionsOperator?.map((item: any) => {
                return (
                  <MenuItem
                    sx={{ fontSize: "1rem" }}
                    key={item?.key}
                    value={item?.value}
                  >
                    {item?.text}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell
        sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
      >
        <FormControl
          variant="standard"
          sx={{ m: 1, width: width > 1400 ? 300 : 150 }}
          fullWidth
        >
          {parameterType === "p-p" ? (
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={value}
              disabled={itemData?.lock}
              onChange={handleChangeValue}
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                  {
                    fontSize: "1rem",
                  },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
            >
              {parameterDropdown?.map((item: any) => {
                return (
                  <MenuItem
                    sx={{ fontSize: "1rem" }}
                    key={item?.id}
                    value={item?.value}
                  >
                    {item?.name}
                  </MenuItem>
                );
              })}
            </Select>
          ) : parameterType === "p-m" ? (
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={value}
              disabled={itemData?.lock}
              onChange={handleChangeValue}
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                  {
                    fontSize: "1rem",
                  },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
            >
              <MenuItem sx={{ fontSize: "1rem" }} value={"name"}>
                Vault Name
              </MenuItem>
              <MenuItem sx={{ fontSize: "1rem" }} value={"part_no"}>
                Part No
              </MenuItem>
            </Select>
          ) : ["singleselect", "multiselect"].includes(
              parameterListData &&
                parameterListData?.find((item: any) => {
                  return item?.id === parameter;
                })?.parameter?.data_type
            ) ? (
            <Autocomplete
              multiple
              disableClearable
              disabled={itemData?.lock}
              id="multiple-limit-tags"
              limitTags={width > 1400 ? 2 : 1}
              disableCloseOnSelect
              sx={{
                ".MuiSvgIcon-root": { color: "primary.main" },
              }}
              value={multiValue}
              onChange={(event, newValue, situation) =>
                handleSelectChangeProduct(newValue, situation)
              }
              options={
                (
                  parameterListData &&
                  parameterListData
                    ?.find((item: any) => {
                      return item?.id === parameter;
                    })
                    ?.parameter?.allowed_values?.map((av: string) => ({
                      key: av,
                      value: av,
                      name: av,
                    }))
                )?.filter((item: any) => {
                  const multiValueMap = multiValue?.map(
                    (item: any) => item?.name
                  );
                  return !multiValueMap?.includes(item.name);
                }) ?? []
              }
              // @ts-ignore
              getOptionLabel={(option) => option?.name}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    size="small"
                    sx={{ background: "primary.light", fontSize: "1rem" }}
                    //@ts-ignore
                    label={option?.name}
                    {...getTagProps({ index })}
                    // disabled={fixedOptions.indexOf(option) !== -1}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  disabled={itemData?.lock}
                  variant="standard"
                  sx={{
                    width: "100%",
                    "& .MuiFormLabel-root": {
                      color: "primary.main",
                    },
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "primary.main",
                    },
                    "& .Mui-focused": {
                      color: "primary.main",
                    },
                    "& .Mui-error": {
                      color: "#d32f2f",
                      borderBottomColor: "#d32f2f",
                    },
                    ".MuiInputBase-root.MuiInput-root": {
                      fontSize: "1rem !important",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                  }}
                />
              )}
            />
          ) : (
            <TextField
              fullWidth
              InputProps={{
                style: { color: "#000", fontSize: 16 },
              }}
              disabled={itemData?.lock}
              InputLabelProps={{ style: { fontSize: 16 } }}
              sx={{
                width: "100%",
                "& .MuiFormLabel-root": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .Mui-focused": {
                  color: "primary.main",
                },
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.light",
                },
              }}
              variant="standard"
              size="small"
              value={value}
              onChange={handleChangeValue}
            />
          )}
        </FormControl>
      </TableCell>
      <TableCell
        sx={{
          padding: "0.2rem 0.5rem",
          borderBottomColor: "primary.light",
          borderRight: "0.5rem solid white",
        }}
      >
        <IconButton
          title="Save"
          sx={
            saveExpression
              ? { color: "red", padding: "0rem",opacity:itemData?.lock===true?"0.5":"1" }
              : { color: "#000", padding: "0rem",opacity:itemData?.lock===true?"0.5":"1"}
          }
          onClick={()=>{
            if(itemData?.lock===false){
              handleSaveExpressionValue()
            }
          }}
        >
          <SaveIcon />
        </IconButton>
        <IconButton
          title="Delete"
          sx={{marginLeft:'0.5rem', color: "#000", padding: "0rem" ,opacity:itemData?.lock===true?"0.5":"1"}}
          onClick={(e: any) => {
            if(itemData?.lock){
            handleDeleteValidationValue(e, parameterID)
            }

          }}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
      {indexIncoming == 1 && (
        <>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
            rowSpan={addedRowSize}
          >
            <TextField
              fullWidth
              disabled={itemData?.lock}
              InputProps={{
                style: {
                  color: "#000000",
                  fontSize: 16,
                  textOverflow: "ellipsis",
                },
              }}
              sx={{
                width: "100%",
                "& .MuiFormLabel-root": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .Mui-focused": {
                  color: "primary.main",
                },
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.light",
                },
              }}
              variant="standard"
              size="small"
              value={expression}
              onChange={handleChangeExpression}
            />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
            rowSpan={addedRowSize}
          >
            <TextField
              fullWidth
              disabled={itemData?.lock}
              InputProps={{
                style: { color: "#000000", fontSize: 16 },
              }}
              InputLabelProps={{ style: { fontSize: 16 } }}
              sx={{
                width: "100%",
                "& .MuiFormLabel-root": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .Mui-focused": {
                  color: "primary.main",
                },
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.light",
                },
              }}
              variant="standard"
              size="small"
              value={message}
              onChange={handleChangeMessage}
            />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "center",
            }}
            rowSpan={addedRowSize}
          >
            <IconButton
              title="Save"
              sx={
                checkDisabledButtonExpressionLogic()
                  ? { padding: "0rem",opacity:itemData?.lock===true?"0.5":"1" }
                  : saveLogic
                  ? { color: "red", padding: "0rem" ,opacity:itemData?.lock===true?"0.5":"1"}
                  : { color: "text.primary", padding: "0rem",opacity:itemData?.lock===true?"0.5":"1" }
              }
              disabled={checkDisabledButtonExpressionLogic() || itemData?.lock}
              onClick={()=>{
                if(itemData?.lock!=true){
                handleSaveExpressionLogic()
                }
              }}
            >
              <SaveIcon />
            </IconButton>
            {/* {expression && message && <IconButton title='Delete' sx={{ color: '#007fff', padding: '0rem' }} onClick={(e:any)=>handleDeleteValidationLogic(e,parameterID)}><DeleteIcon/></IconButton>} */}
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

export default BOMCustomValidationRows;
