import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  MenuItem,
  Select,
  Tooltip,
  Button,
  Card,
} from "@mui/material";
import styles from "./SupplierViewPageNew.module.scss"; // Importing styles from the SupplierViewPageNew module
interface Charge {
  serialNo: number;
  chargeHolder: string;
  status: string;
  amount: string;
}

interface SupplierDetails {
  financial_year: string;
  gross_turnover: string;
  export_turnover: string;
  charges?: Charge[];
}

interface Props {
  supplierDetails: any;
}




const SupplierFinancialData: React.FC<Props> = ({ supplierDetails }) => {
  
  const [filteredCharges, setFilteredCharges] = useState<Charge[]>([]);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [showAll, setShowAll] = useState(false);
  const openCharges: any =
    Array.isArray(filteredCharges) &&
    filteredCharges?.filter((itm: any) => itm?.chargeStatus === "Open");

  const visibleCharges = showAll
    ? filteredCharges
    : filteredCharges?.slice(0, 3);
  const handleShowAllDirectorsClick = () => {
    setShowAll(!showAll);
  };






  useEffect(() => {
    // Check if supplierDetails and charges exist
    if (supplierDetails?.charges) {
      // Filter charges where chargeStatus is "Open"
      const openCharges =
        Array.isArray(supplierDetails?.charges) &&
        supplierDetails?.charges.filter(
          (itm: any) => itm?.chargeStatus === "Open"
        );
      // Set the filtered charges in the state
      setFilteredCharges(openCharges);
    } else {
      // If no charges, set filteredCharges to an empty array
      setFilteredCharges([]);
    }
  }, [supplierDetails?.charges]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase();
    const filtered =
      supplierDetails?.charges?.filter((charge: Charge) =>
        charge?.chargeHolder?.toLowerCase().includes(value)
      ) || [];
    setFilteredCharges(filtered);
  };

  // const handleSortChange = (event: React.ChangeEvent<{ value: unknown }>) => {
  //   const value = event.target.value as 'asc' | 'desc';
  //   setSortOrder(value);
  //   const sortedCharges = [...filteredCharges].sort((a, b) => {
  //     const amountA = parseInt(a?.amount?.replace(/,/g, ''), 10);
  //     const amountB = parseInt(b?.amount?.replace(/,/g, ''), 10);
  //     return value === 'asc' ? amountA - amountB : amountB - amountA;
  //   });
  //   setFilteredCharges(sortedCharges);
  // };

  const totalOpenCharges = filteredCharges
    .reduce(
      (acc, curr) => acc + parseInt(curr?.amount?.toString()?.replace(/,/g, ""), 10),
      0
    )
    .toLocaleString("en-IN");

  const formatTurnoverToCrores = (turnover: string) => {
    // Remove any non-numeric characters except the decimal point
    const num = parseFloat(turnover?.toString().replace(/[^0-9.]/g, ""));

    if (isNaN(num)) {
      return { display: turnover, full: turnover }; // Return the original string if it can't be parsed
    }

    // Convert the amount to crores (1 crore = 10 million)
    const crores = num / 1e7;

    // Format the crores value with up to two decimal places
    let formattedCrores = crores.toFixed(2);

    // Remove trailing zeros if the decimal part is 00
    if (formattedCrores.endsWith(".00")) {
      formattedCrores = formattedCrores.slice(0, -3);
    } else if (formattedCrores.endsWith("0")) {
      formattedCrores = formattedCrores.slice(0, -1);
    }

    return { display: `${formattedCrores} Crores`, full: turnover };
  };

  const grossTurnover = formatTurnoverToCrores(
    supplierDetails?.gross_turnover || ""
  );
  const exportTurnover = formatTurnoverToCrores(
    supplierDetails?.export_turnover || ""
  );
  const totalCharge = formatTurnoverToCrores(totalOpenCharges || "");



  return (
    <Box sx={{ padding: 2 }} className={styles.mainContainer}>
        {(supplierDetails?.financial_year && grossTurnover?.display && exportTurnover?.display) && <Typography
            sx={{
            fontSize: "1.1rem",
            fontWeight: "600",
            marginBottom: "0.5rem",
            }}>
                Seller Financial Summary
            </Typography>}
      <Box sx={{ display: "grid", gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' }, justifyContent: "space-between", gap: '1rem' }}>
        {(supplierDetails?.financial_year && grossTurnover?.display && exportTurnover?.display) && <Card elevation={1} sx={{
          width: '100%', padding: " 0.5rem 1rem", height: "12.3rem",
        }}>
          {supplierDetails?.financial_year && <Typography
            sx={{ padding: "0.5rem", display: "flex", alignItems: "center", paddingLeft: "0" }}
          >
            <Tooltip title={"Financial Year"}>
              <span
                style={{
                  // fontWeight: "600",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "40%",
                }}
              >
                Financial Year
              </span>
            </Tooltip>
            <Tooltip title={supplierDetails?.financial_year || ""}>
              <span>: {supplierDetails?.financial_year}</span>
            </Tooltip>
          </Typography>}
          {grossTurnover?.display && <Typography
            sx={{ padding: "0.5rem", display: "flex", alignItems: "center", paddingLeft: "0" }}
          >
            <Tooltip
              title={`Gross Turnover of ${supplierDetails?.business_name}`}
            >
              <span
                style={{
                  // fontWeight: "600",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "40%",
                }}
              >
                Gross Turnover 
              </span>
            </Tooltip>
            <Tooltip title={grossTurnover?.full || ""}>
              <span>: ₹ {grossTurnover?.display || ""}</span>
            </Tooltip>
          </Typography>}

          {exportTurnover?.display && <Typography
            sx={{ padding: "0.5rem", display: "flex", alignItems: "center", paddingLeft: "0" }}
          >
            <Tooltip
              title={`Export Turnover of ${supplierDetails?.business_name}`}
            >
              <span
                style={{
                  // fontWeight: "600",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "40%",
                }}
              >
                Export Turnover 
              </span>
            </Tooltip>
            <Tooltip title={exportTurnover?.full || ""}>
              <span>: ₹ {exportTurnover?.display || ""}</span>
            </Tooltip>
          </Typography>}
        </Card>}

        <Box sx={{ width: "100%", height: "fit-content", marginTop: { xs: '0', md: "-2rem", } }}>
          { <Box>
            <Typography
              sx={{

                paddingBottom: "0.2rem",
                display: "flex",
                alignItems: "center",
                marginBottom: "0.5rem",
              }}
              className={styles.financialbasicData}
            >
              <Tooltip
                title={`Total Open Charges against ${supplierDetails?.business_name}`}
              >
                <span
                  style={{
                    fontWeight: "600",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "40%",
                  }}
                >
                  Total Open Charges 
                </span>
              </Tooltip>
              <Tooltip title={totalCharge?.full || ""}>
                <span>: ₹ {totalCharge?.display || ""}</span>
              </Tooltip>
            </Typography>
          </Box>}
          <Card elevation={1}>
            {Array.isArray(openCharges) && openCharges?.length > 0 && (
              <TableContainer>
                <Table>
                  <TableHead sx={{background:"#008080", }}>
                    <TableRow>
                      <TableCell sx={{ padding: "0.5rem", color:"#fff" }}>Serial No</TableCell>
                      <TableCell sx={{ padding: "0.5rem", color:"#fff" }}>
                        Charge holder name
                      </TableCell>
                      <TableCell sx={{ padding: "0.5rem", color:"#fff" }}>Status</TableCell>
                      <TableCell sx={{ padding: "0.5rem", color:"#fff" }}>Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {visibleCharges
                      ?.filter((itm: any) => itm?.chargeStatus === "Open")
                      ?.map((charge: any, index: any) => (
                        <TableRow key={charge?.serialNo}>
                          <TableCell
                            sx={{
                              borderBottom: 'none',
                              padding: "1.5rem",
                              "&.MuiTableCell-root": { padding: "0.5rem 1rem" },
                            }}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: 'none',
                              padding: "1.5rem",
                              "&.MuiTableCell-root": { padding: "0.5rem 1rem" },
                            }}
                          >
                            {charge?.chargeHolder}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: 'none',
                              padding: "1.5rem",
                              "&.MuiTableCell-root": { padding: "0.5rem 1rem" },
                            }}
                          >
                            {charge?.chargeStatus}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: 'none',
                              padding: "1.5rem",
                              "&.MuiTableCell-root": { padding: "0.5rem 1rem" },
                            }}
                            title={`₹${formatTurnoverToCrores(charge?.amount)?.full
                              }`}
                          >{`₹${formatTurnoverToCrores(charge?.amount)?.display
                            }`}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}


            {filteredCharges?.length > 3 && (
              <Box sx={{ textAlign: "center", margin: "0.25rem 0" }}>
                <Button variant="outlined" onClick={handleShowAllDirectorsClick}>
                  {!showAll ? "Show All" : "Show Less"}
                </Button>
              </Box>
            )}
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default SupplierFinancialData;
