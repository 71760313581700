// vendors
import { createEntityAdapter, createReducer, EntityState } from "@reduxjs/toolkit";
import { CalculatorInput } from "./calculatorInput.reducer";
import { getInputCalculations, resetInputCalculations, updateInputCalculations } from "../Actions/inputCalculation.actions";
import { RootState } from "../Store/storeConfigurations";

// redux


// -----------------------------------------------------------------------------------
// entity adaptor

export interface InputCalculation extends CalculatorInput {
    value: number;
    unit: string | null;
    db_file:any;
    content_type:any
}

const adaptor = createEntityAdapter<InputCalculation>( {
    selectId: ( inputCalculation ) => inputCalculation.id,
    sortComparer: ( a, b ) => Number( a.sequence ) - Number( b.sequence )
} );

// -----------------------------------------------------------------------------------
// interfaces & initialState

type AdditionalFields = {};

type IInputCalculationReducer = EntityState<InputCalculation> & AdditionalFields;

const additionalFields: AdditionalFields = {};

const initialState: IInputCalculationReducer = Object.assign( {}, adaptor.getInitialState(), additionalFields );

// -----------------------------------------------------------------------------------
// reducer

const InputCalculationReducer = createReducer( initialState, builder => {
    // get
    builder
        .addCase( getInputCalculations.fulfilled, ( state, action: any ) => {
            adaptor.setAll( state, action.payload.calculations );
        } )
        .addCase( getInputCalculations.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not fetch input calculations" );
        } );

    builder
        .addCase( updateInputCalculations.fulfilled, ( state, action: any ) => {
            adaptor.setAll( state, action.payload.calculations );
            // ADMIN.toast.success( 'Calculation Updated' );
        } )
        .addCase( updateInputCalculations.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not update input calculations" );
        } );

    // reset
    builder.addCase( resetInputCalculations, () => initialState );
} );

// -----------------------------------------------------------------------------------
// selectors

const inputCalculationSelectors = adaptor.getSelectors<RootState>( ( state ) => state.inputCalculations );

// -----------------------------------------------------------------------------------
// exports

export {
    InputCalculationReducer,
    initialState as InputCalculationReducerInit,
    inputCalculationSelectors
};