import * as React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import {
	Box,
	Button,
	Divider,
	Modal,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { API } from '../../../api-services';
import Swal from 'sweetalert2';

export interface IBOMDeletePartUsedDetailsModalProps {
	onCloseModal: any;
	isOpen: any;
	vault: any;
	ConfirmDeleteHandler: any;
}

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: { lg: '45vw', xl: '35vw' },
	bgcolor: 'background.paper',
	borderRadius: '10px',
	p: 1,
};

export default function BOMDeletePartUsedDetailsModal(
	props: IBOMDeletePartUsedDetailsModalProps
) {
	const { onCloseModal, isOpen, vault, ConfirmDeleteHandler } = props;

	const [partUsedInfo, setPartUsedInfo] = React.useState<any>([]);
	const [partusedinfoLoader, setPartusedinfoLoader] =
		React.useState<any>(false);

	React.useEffect(() => {
		setPartusedinfoLoader(true);
		API.get(`bom/bom_delete_confirmation/`, { vault_id: vault })
			.then((res: any) => {
				setPartUsedInfo(res.data);
				setPartusedinfoLoader(false);
			})
			.catch((err: any) => {
				setPartusedinfoLoader(false);
				const { data } = err?.response;
				Swal.fire({
					icon: 'error',
					html: `<div>                           
                            <p style="color:"red">${data[0]}</p>   
                            </div>`,
				});
			});
	}, [vault]);

	console.log(partUsedInfo.main_costing_response, 'partUsedInfo');
	const confirmDelete = () => {
        onCloseModal();
		ConfirmDeleteHandler();
	};

	return (
		<div>
			<Modal
				open={isOpen}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'>
				<Box sx={style}>
					<Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
						<Typography
							style={{
								fontSize: '1rem',
								fontWeight: '600',
								width: '100%',
								textAlign: 'center',
								marginRight: '-2rem',
							}}>
							This part is used in the following places
						</Typography>
						{/* <CancelIcon
							titleAccess='Close'
							sx={{ cursor: 'pointer', margin: '0.5rem' }}
							onClick={onCloseModal}
						/> */}
					</Box>
					<Divider
						sx={{
							borderColor: 'primary.light',
							margin: '0 1rem',
							marginBottom: '1rem',
						}}
					/>
					<Box sx={{ display: 'flex', alignItems: 'start', columnGap: '1rem' }}>
						{partUsedInfo.main_costing_response && <Box sx={{ width: '100%' }}>
							{!partusedinfoLoader ? (
								<Table>
									<TableHead>
										<TableRow>
											<TableCell
												sx={{
													padding: '0.2rem 0.5rem',
													backgroundColor: 'primary.light',
												}}
												colSpan={5}>
												Main Costing
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow>
											<TableCell
												sx={{
													padding: '0.2rem 0.5rem',
													borderBottomColor: 'primary.light',
												}}>
												Total Cost
											</TableCell>
											<TableCell
												sx={{
													padding: '0.2rem 0.5rem',
													borderBottomColor: 'primary.light',
													textAlign: 'right',
												}}>
												{partUsedInfo.main_costing_response?.cost !== null ||
												undefined
													? partUsedInfo.main_costing_response?.cost.toFixed(2)
													: partUsedInfo.main_costing_response?.cost}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell
												sx={{
													padding: '0.2rem 0.5rem',
													borderBottomColor: 'primary.light',
												}}>
												Material Cost
											</TableCell>
											<TableCell
												sx={{
													padding: '0.2rem 0.5rem',
													borderBottomColor: 'primary.light',
													textAlign: 'right',
												}}>
												{partUsedInfo.main_costing_response
													?.raw_material_cost !== null || undefined
													? partUsedInfo.main_costing_response?.raw_material_cost.toFixed(
															2
													  )
													: partUsedInfo.main_costing_response
															?.raw_material_cost}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell
												sx={{
													padding: '0.2rem 0.5rem',
													borderBottomColor: 'primary.light',
												}}>
												Process Cost
											</TableCell>
											<TableCell
												sx={{
													padding: '0.2rem 0.5rem',
													borderBottomColor: 'primary.light',
													textAlign: 'right',
												}}>
												{partUsedInfo.main_costing_response
													?.total_process_cost !== null || undefined
													? partUsedInfo.main_costing_response?.total_process_cost.toFixed(
															2
													  )
													: partUsedInfo.main_costing_response
															?.total_process_cost}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell
												sx={{
													padding: '0.2rem 0.5rem',
													borderBottomColor: 'primary.light',
												}}>
												Overhead Cost
											</TableCell>
											<TableCell
												sx={{
													padding: '0.2rem 0.5rem',
													borderBottomColor: 'primary.light',
													textAlign: 'right',
												}}>
												{partUsedInfo.main_costing_response?.overhead_cost !==
													null || undefined
													? partUsedInfo.main_costing_response?.overhead_cost.toFixed(
															2
													  )
													: partUsedInfo.main_costing_response?.overhead_cost}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell
												sx={{
													padding: '0.2rem 0.5rem',
													borderBottomColor: 'primary.light',
												}}>
												Child Cost
											</TableCell>
											<TableCell
												sx={{
													padding: '0.2rem 0.5rem',
													borderBottomColor: 'primary.light',
													textAlign: 'right',
												}}>
												{partUsedInfo.main_costing_response?.child_cost !==
													null || undefined
													? partUsedInfo.main_costing_response?.child_cost.toFixed(
															2
													  )
													: partUsedInfo.main_costing_response?.child_cost}
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							) : (
								<Box sx={{ margin: '0.5 rem', padding: '0 1rem' }}>
									<Skeleton sx={{ height: '5rem', width: '100%' }} />
									<Skeleton />
									<Skeleton />
									<Skeleton />
									<Skeleton />
								</Box>
							)}
						</Box>}
						{partUsedInfo.zbc_req_response && <Box sx={{ width: '100%' }}>
							{!partusedinfoLoader ? (
								<Table>
									<TableHead>
										<TableRow>
											<TableCell
												sx={{
													padding: '0.2rem 0.5rem',
													backgroundColor: 'primary.light',
												}}
												colSpan={5}>
												ZBC Request Details
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow>
											<TableCell
												sx={{
													padding: '0.2rem 0.5rem',
													borderBottomColor: 'primary.light',
												}}>
												Request ID
											</TableCell>
											<TableCell
												sx={{
													padding: '0.2rem 0.5rem',
													borderBottomColor: 'primary.light',
												}}>
												{partUsedInfo.zbc_req_response?.request_id}
											</TableCell>
										</TableRow>{' '}
										<TableRow>
											<TableCell
												sx={{
													padding: '0.2rem 0.5rem',
													borderBottomColor: 'primary.light',
												}}>
												Requested By
											</TableCell>
											<TableCell
												sx={{
													padding: '0.2rem 0.5rem',
													borderBottomColor: 'primary.light',
												}}>
												{partUsedInfo.zbc_req_response?.requester_name}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell
												sx={{
													padding: '0.2rem 0.5rem',
													borderBottomColor: 'primary.light',
												}}>
												Amount Paid (Excluding Taxes)
											</TableCell>
											<TableCell
												sx={{
													padding: '0.2rem 0.5rem',
													borderBottomColor: 'primary.light',
												}}>
												{partUsedInfo.zbc_req_response?.amount_paid_currency}{' '}
												{partUsedInfo.zbc_req_response?.amount_paid}
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							) : (
								<Box sx={{ margin: '0.5 rem', padding: '0 1rem' }}>
									<Skeleton sx={{ height: '5rem', width: '100%' }} />
									<Skeleton />
									<Skeleton />
									<Skeleton />
									<Skeleton />
								</Box>
							)}
						</Box>}
					</Box>
					{partUsedInfo.scenario_costing_response && <Box sx={{ width: '100%', marginTop: '1rem' }}>
						{!partusedinfoLoader ? (
							<Table>
								<TableHead>
									<TableRow>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												backgroundColor: 'primary.light',
											}}>
											Name
										</TableCell>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												backgroundColor: 'primary.light',
												textAlign: 'right',
											}}>
											Total Cost
										</TableCell>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												backgroundColor: 'primary.light',
												textAlign: 'right',
											}}>
											Material Cost
										</TableCell>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												backgroundColor: 'primary.light',
												textAlign: 'right',
											}}>
											Process Cost
										</TableCell>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												backgroundColor: 'primary.light',
												textAlign: 'right',
											}}>
											Overhead Cost
										</TableCell>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												backgroundColor: 'primary.light',
												textAlign: 'right',
											}}>
											Child Cost
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{partUsedInfo.scenario_costing_response &&
										partUsedInfo.scenario_costing_response.map(
											(item: any, index: any) => (
												<TableRow key={index}>
													<TableCell
														sx={{
															padding: '0.2rem 0.5rem',
															borderBottomColor: 'primary.light',
														}}>
														{item?.name}
													</TableCell>
													<TableCell
														sx={{
															padding: '0.2rem 0.5rem',
															borderBottomColor: 'primary.light',
															textAlign: 'right',
														}}>
														{item?.cost !== null || undefined
															? item?.cost.toFixed(2)
															: item?.cost}
													</TableCell>
													<TableCell
														sx={{
															padding: '0.2rem 0.5rem',
															borderBottomColor: 'primary.light',
															textAlign: 'right',
														}}>
														{item?.raw_material_cost !== null || undefined
															? item?.raw_material_cost.toFixed(2)
															: item?.raw_material_cost}
													</TableCell>
													<TableCell
														sx={{
															padding: '0.2rem 0.5rem',
															borderBottomColor: 'primary.light',
															textAlign: 'right',
														}}>
														{item?.total_process_cost !== null || undefined
															? item?.total_process_cost.toFixed(2)
															: item?.total_process_cost}
													</TableCell>
													<TableCell
														sx={{
															padding: '0.2rem 0.5rem',
															borderBottomColor: 'primary.light',
															textAlign: 'right',
														}}>
														{item?.overhead_cost !== null || undefined
															? item?.overhead_cost.toFixed(2)
															: item?.overhead_cost}
													</TableCell>
													<TableCell
														sx={{
															padding: '0.2rem 0.5rem',
															borderBottomColor: 'primary.light',
															textAlign: 'right',
														}}>
														{item?.child_cost !== null || undefined
															? item?.child_cost.toFixed(2)
															: item?.child_cost}
													</TableCell>
												</TableRow>
											)
										)}
								</TableBody>
							</Table>
						) : (
							<Box sx={{ margin: '0.5 rem', padding: '0 1rem' }}>
								<Skeleton sx={{ height: '5rem', width: '100%' }} />
								<Skeleton />
								<Skeleton />
								<Skeleton />
								<Skeleton />
							</Box>
						)}
					</Box>}
					<Box sx={{ marginTop: '1rem',width:'100%',textAlign:'center' }}>
						<Typography sx={{color:'red'}}>Are you sure you still want to delete this part?</Typography>
					</Box>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							columnGap: '1rem',
							marginTop: '1rem',
							width: '100%',
						}}>
						<Button
							size='small'
							variant='contained'
							color='primary'
							onClick={onCloseModal}>
							No Cancel
						</Button>
						<Button
							size='small'
							variant='contained'
							color='primary'
							onClick={confirmDelete}>
							Yes, Delete
						</Button>
					</Box>
				</Box>
			</Modal>
		</div>
	);
}
