import * as React from "react";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import { useHistory } from "react-router-dom";
import {
    Backdrop,
    Chip,
    Divider,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
    makeStyles
} from "@mui/material";
import { API } from "../../api-services";
import { LoadingButton } from "@mui/lab";
import CancelIcon from "@mui/icons-material/Cancel";
import Swal from "sweetalert2";
import successIcon from "../../Assets/images/success.png";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import AddRevisionModal from "../../DataBaseModule/Costing/AddRevisionModal";
import SearchIcon from '@mui/icons-material/Search';
import styles from "../../DataBaseModule/Costing/RevisionAndDataBase.module.scss";
import ReplyIcon from '@mui/icons-material/Reply';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip, {TooltipProps, tooltipClasses} from "@mui/material/Tooltip";
import { debounce, set } from "lodash";
import { ADMIN } from "../../Redux/Services/admin.service";


const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "20vw",
    height: "fit-content",
    bgcolor: "background.paper",
    borderRadius: "10px",
    p: 0,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface IWatermarkModelProps {
    isOpen: any;
    onCloseModal: any;
    // setCounter: any;
}

function RevisionListModal(props: IWatermarkModelProps) {
    const { isOpen, onCloseModal } = props;
    const history = useHistory();
    const { topVault, projectId, vault } = useRouteParams<any>();
    const openRevisionModal = useBaseParamsModal();
    const [counter, setCounter] = React.useState<any>(0);
    const [curdaction, setCurdaction] = React.useState<any>(null);
    const [selectedValue, setSelectedValue] = React.useState<any>(null);
    const [scenarioList, setScenarioList] = React.useState<any>([]);
    const descRef = React.useRef<any>();

    const OuterRef = React.useRef<any>();
    const inputRef = React.useRef<any>(null);

    const checkPermession = () => {
        API.get(
            '/auth/user_permission_module/',
            {
                action: true,
                database: true,
                alldb: true,
            },
            0
        )
            .then((res) => {
                setCurdaction(res.data);
                API.get(
                    '/db/location_revision/',
                    {
                        scenario: true,
                        vehicle_category: projectId
                    },
                    0
                )
                    .then((res) => {
                        console.log(res?.data);
                        setScenarioList(res?.data);

                    })
                    .catch((error: any) => {
                        console.log(error);

                    })
            })
            .catch((error) => {
                console.log(error);
            });
    }

    React.useEffect(() => {
        checkPermession();
    }, [counter]);

    const ExactDebounce = React.useRef(

        debounce(async (search_keyword: any) => {
          
            API.get(
                '/db/location_revision/',
                {
                    scenario: true,
                    vehicle_category: projectId,
                    search_name: search_keyword
                },
                0
            )
          .then((res) => {
              setScenarioList(res.data);
          }).catch((err: any) => {
            setScenarioList([]);
            const { data }: any = err?.response;
            
            ADMIN.toast.error(data[0])
          })
        }, 500)
      ).current;

    const handleOnChange = (event: any) => {
        console.log(event.target.value);
        setSelectedValue(event.target.value);
        ExactDebounce(event.target.value);

    }

    console.log(scenarioList);












    return (
        <div>
            <Modal
                open={isOpen}
                onClose={onCloseModal}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Box sx={style}>
                    <Box
                        sx={{ display: "flex", alignItems: "center", height: "3.5rem" }}
                    >
                        <Typography
                            style={{
                                fontSize: "1rem",
                                fontWeight: "600",
                                width: "100%",
                                textAlign: "center",
                                marginRight: "-2rem",
                            }}
                        >
                            Scenario Revisions <br />
                        </Typography>
                        <CancelIcon
                            titleAccess="Close"
                            sx={{ cursor: "pointer", margin: "0.5rem" }}
                            onClick={onCloseModal}
                        />
                    </Box>
                    <Divider
                        sx={{
                            borderColor: "primary.light",
                            margin: "0 1rem",
                            marginBottom: "1rem",
                        }}
                    />
                    <Box
                        sx={{
                            margin: "1rem",
                        }}
                    >   <Box sx={{ display: "flex" }}>
                            <div className={styles.inputbox} ref={OuterRef}>
                                {
                                    <input
                                        
                                        type='search'
                                        placeholder='Search Scenario Revision Name'
                                        value={selectedValue}
                                        className={styles.searchInput}
                                        autoFocus={false}
                                        style={{ color: '#00887a' }}
                                        // @ts-expect-
                                        ref={descRef}
                                        // onKeyPress={(event: any) => handleOnChange(event)}
                                        onChange={(event: any) => handleOnChange(event)}
                                    />
                                }

                                
                            </div>
                            {(curdaction?.scenario_action ||
                                curdaction?.main_action ||
                                curdaction?.scenario_action?.includes('C') ||
                                curdaction?.main_action?.includes('C')) && (
                                    <Box
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                            backgroundColor: '#faebd7',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            padding:"0.5rem",
                                        }}>
                                        <AddCircleOutlineOutlinedIcon
                                            sx={{ cursor: 'pointer' }}
                                            titleAccess='ADD Revision Main Costing'
                                            onClick={() => openRevisionModal.open('add')}
                                        />

                                    </Box>
                                )}
                        </Box>
                        <Box sx={{marginTop:"1rem"}}>
                            {
                                scenarioList && scenarioList.map((item: any) => {
                                    return (
                                        <Box sx={{ display: "flex", justifyContent: "space-between", padding: "0.5rem 0", borderBottom: "2px solid #f0f0f0" }}>
                                            <Box>
                                                <Typography>{item?.item_name}</Typography>
                                            </Box>
                                            <Box>
                                                <ReplyIcon
                                                    onClick={() => window.open(`#/data-base/revision-database/2/${item?.year}/${item?.month}/${item?.id}/0/0/0/false/0`)}
                                                    titleAccess="Go to Revision"
                                                    sx={{
                                                        fontSize: "1.5rem",
                                                        transform: "scaleX(-1)",
                                                        cursor: "pointer",
                                                        marginRight: "1rem"
                                                    }}
                                                />
                                                <Tooltip title={
                                                    <React.Fragment>
                                                        <Box>
                                                            <Typography>Revised On: {item?.revised_on}</Typography>
                                                            <Typography>Volume: {item?.volume}</Typography>
                                                            <Typography>Place: {item?.city}, {item?.country}</Typography>
                                                        </Box>
                                                    </React.Fragment>
                                                
                                                } sx={{cursor:"pointer"}} placement="top-end"><InfoIcon /></Tooltip>
                                            </Box>
                                        </Box>
                                    )

                                })
                            }
                        </Box>
                    </Box>
                </Box>

            </Modal>
            {openRevisionModal.isOpen && (
                <AddRevisionModal
                    Curdaction={curdaction}
                    isOpen={openRevisionModal.isOpen}
                    onCloseModal={openRevisionModal.close}
                    actions={openRevisionModal.propsId}
                    setCounter={setCounter}
                    getRevisionData={checkPermession}
                    keyType={2}
                />
            )}

        </div>
    );
}
export default RevisionListModal;
