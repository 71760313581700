import React from "react";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import CardMedia from "@mui/material/CardMedia";
import Card from "@mui/material/Card";
import RViewerJS from "viewerjs-react";
import Image from "../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import LoadingImage from "../Assets/images/loading-screen.gif";
import * as yup from "yup";
import {
  Autocomplete,
  Divider,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import useWindowDimensions from "../ui-reusable-component/useWindowDimensions";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import DescriptionIcon from "@mui/icons-material/Description";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import VerifiedIcon from "@mui/icons-material/Verified";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Text } from "devextreme-react/circular-gauge";
import { API } from "../api-services";
import { ADMIN } from "../Redux/Services/admin.service";

interface InfoModalProps {
  onCloseModal: any;
  isOpen: any;
  item: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  padding: "1rem",
};

const csvOptions: GridCsvExportOptions = {
  fileName: "Table In CSV",
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: "1.5rem", marginBottom: "0.5rem" }}>
    <span style={{ position: "absolute", right: "0rem" }}>
      {/* <GridToolbarColumnsButton /> */}
      {/* <GridToolbarDensitySelector /> */}
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);
const SupplierCliamerDocsModal = (props: InfoModalProps) => {
  const { height, width } = useWindowDimensions();
  const history = useHistory();
  const { onCloseModal, isOpen, item } = props;
  const [docData, setDocData] = React.useState<any>([]);

  const getDocData = () => {
    API.get(
      "/cart/get_all_business_docs/",
      {
        id: item,
      },
      0
    )
      .then((res: any) => {
        setDocData(res.data);
      })
      .catch((err: any) => {
        console.log("err", err);
      });
  };

  React.useEffect(() => {
    if (item) {
      getDocData();
    }
  }, [item]);

  const handleVerified = (id: any) => {
    API.post(
      `/cart/get_all_business_docs/`,
      {
        id: id,
        approve: true,
        comment: "verified",
      },
      {},
      0
    )
      .then((res) => {
        getDocData();
        ADMIN.toast.info("Document Verified Successfully");
        console.log("res", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <div>
      <Modal open={isOpen} onClose={onCloseModal}>
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              columnGap: "1rem",
              marginBottom: "0.5rem",
              padding: "1rem",
            }}
          >
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Supplier Claimer Documents
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box
            sx={{
              margin: "1rem",
            }}
          >
            {/* Here it will come all docs of each sesction */}

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "1rem",
              }}
            >
              {docData &&
                docData?.map((doc: any, index: number) => {
                  return (
                    <Box>
                      <Typography
                        variant="h5"
                        sx={{ textTransform: "capitalize" }}
                      >
                        {doc?.name}
                      </Typography>
                      <Box>
                        {doc?.data &&
                          doc?.data.map((proof: any, index: number) => {
                            return (
                              <Box sx={{ display: "flex", gap: "1rem" }}>
                                {proof?.document_url &&
                                  proof?.document_url !== "" &&
                                  proof?.document_url
                                    ?.split("/")?.pop()?.split(".")?.pop() === "pdf" && (
                                    <Box
                                      onClick={() =>
                                        window.open(proof?.document_url)
                                      }
                                    >
                                      {proof?.document_url?.split("/").pop()}
                                    </Box>
                                  )}
                                {proof?.document_url &&
                                  proof?.document_url !== "" &&
                                  [
                                    'png', 'jpg', 'jpeg', 'gif', 'bmp', 
                                    'webp', 'svg', 'tiff', 'ico', 'heic', 
                                    'heif'
                                  ]?.includes(
                                    proof?.document_url
                                      ?.split("/")?.pop()?.split(".")?.pop()
                                  ) && (
                                    // @ts-ignore

                                    <RViewerJS>
                                      <div>
                                        <Image
                                          src={proof?.document_url}
                                          placeholderImg={LoadingImage}
                                          title={proof?.document_url
                                            ?.split("/")
                                            .pop()}
                                          style={{
                                            width: "5rem%",
                                            height: "5rem",
                                            objectFit: "contain",
                                            marginLeft: "1rem",
                                          }}
                                          alt={proof?.document_url
                                            ?.split("/")
                                            .pop()}
                                          // key={photo.id}
                                        />
                                      </div>
                                    </RViewerJS>
                                  )}
                                <VerifiedIcon
                                sx={{cursor:'pointer'}}
                                  color={
                                    proof?.status == "Accepted"
                                      ? "success"
                                      : "error"
                                  }
                                  onClick={() => handleVerified(proof?.id)}
                                />
                                <TextField
                                  label="Comment"
                                  value={proof?.comment}
                                  disabled={proof?.verified}
                                />
                              </Box>
                            );
                          })}
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default SupplierCliamerDocsModal;
