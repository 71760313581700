import React from 'react';
import { Box, Divider, Modal } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, {
    stepConnectorClasses,
} from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import PersonIcon from '@mui/icons-material/Person';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import TaskRoundedIcon from '@mui/icons-material/TaskRounded';
import ModelTrainingRoundedIcon from '@mui/icons-material/ModelTrainingRounded';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

export interface IZBCRequestStatusModalProps {
    isOpen: boolean;
    onCloseModal: () => void;
    status: any; // Assuming status contains information about the current stage
    currentType: any;
    reOpen: any;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { lg: '95vw', xl: '90vw' },
    height: 'auto',
    bgcolor: 'background.default',
    borderRadius: '1rem',
    p: 1,
    focusVisible: 'none !important',
};

// "Request Received", "Quotation Sent", Rejected", "Cart", "Payment", In Progress","Completed"
// "Request Received", "Quotation Sent", Accepted", "Payment", Assign to Person", BOM In Progress, Costing In Progress, Submitted to ASI, Approved By ASI, Submiited to Client, Customer Feedback, Revise Request, Finished Request.

const ZBCRequestStatusModal: React.FC<IZBCRequestStatusModalProps> = (
    props
) => {
    const { isOpen, onCloseModal, status, currentType, reOpen } = props;

    const [activeStep, setActiveStep] = React.useState(0);

    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 22,
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundColor: '#00887a',
                // backgroundImage:
                //  'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundColor: '#00887a',
                // backgroundImage:
                //  'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            height: 3,
            border: 0,
            backgroundColor:
                theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
            borderRadius: 1,
        },
    }));
    // console.log('status', currentType, status);
    const ColorlibStepIconRoot = styled('div')<{
        ownerState: { completed?: boolean; active?: boolean };
    }>(({ theme, ownerState }) => ({
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && {
            backgroundColor: theme.palette.primary.main,
            // backgroundImage:
            //  'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
            boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        }),
        ...(ownerState.completed && {
            backgroundColor: theme.palette.primary.main,
            // backgroundImage:
            //  'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        }),
    }));

    function ColorlibStepIcon(props: StepIconProps) {
        const { active, completed, className } = props;

        const icons: { [index: string]: React.ReactElement } = {
            1: <PersonIcon />,
            2: <RequestQuoteIcon />,
            3: <DoNotDisturbIcon />,
        };
        return (
            <ColorlibStepIconRoot
                ownerState={{ completed, active }}
                className={className}>
                {icons[String(props.icon)]}
            </ColorlibStepIconRoot>
        );
    }

    function ColorlibStepIconDrawings(props: StepIconProps) {
        const { active, completed, className } = props;

        const icons: { [index: string]: React.ReactElement } = {
            1: <PersonIcon />,
            2: <RequestQuoteIcon />,
            3: <AttachMoneyRoundedIcon />,
            // 4: <PersonPinIcon />,
            4: <AddCircleOutlineIcon />,
            // 6: <EngineeringIcon />,
            5: <AccountTreeIcon />,
            // 8: <EngineeringIcon />,
            6: <MonetizationOnIcon />,
            // 10: <TaskRoundedIcon />,
            7: <TaskAltIcon />,
            // 12: <AutoModeIcon />,
            // 13: <ModelTrainingRoundedIcon />,
            // 14: <TaskRoundedIcon />,
            // 15: <TaskAltIcon />,
            // 12: <CheckCircleIcon />,
        };
        return (
            <ColorlibStepIconRoot
                ownerState={{ completed, active }}
                className={className}>
                {icons[String(props.icon)]}
            </ColorlibStepIconRoot>
        );
    }

    function ColorlibStepIconPhysical(props: StepIconProps) {
        const { active, completed, className } = props;

        const icons: { [index: string]: React.ReactElement } = {
			1: <PersonIcon />,
            2: <RequestQuoteIcon />,
            3: <AttachMoneyRoundedIcon />,
            // 4: <PersonPinIcon />,
            4: <AddCircleOutlineIcon />,
            // 6: <EngineeringIcon />,
            5: <AccountTreeIcon />,
            // 8: <EngineeringIcon />,
            6: <MonetizationOnIcon />,
            // 10: <TaskRoundedIcon />,
            7: <TaskAltIcon />,
            // 12: <AutoModeIcon />,
            // 13: <ModelTrainingRoundedIcon />,
            // 14: <TaskRoundedIcon />,
            // 15: <TaskAltIcon />,
            // 12: <CheckCircleIcon />,
        };


        return (
            <ColorlibStepIconRoot
                ownerState={{ completed, active }}
                className={className}>
                {icons[String(props.icon)]}
            </ColorlibStepIconRoot>
        );
    }

    function ColorlibStepIconRevision(props: StepIconProps) {
        const { active, completed, className } = props;

        const icons: { [index: string]: React.ReactElement } = {
            1: <AutoModeIcon />,
            2: <ModelTrainingRoundedIcon />,
            3: <TaskRoundedIcon />,
            4: <TaskAltIcon />,
            5: <CheckCircleIcon />,
        };
        return (
            <ColorlibStepIconRoot
                ownerState={{ completed, active }}
                className={className}>
                {icons[String(props.icon)]}
            </ColorlibStepIconRoot>
        );
    }

    React.useEffect(() => {
        // Request Raised', 'Quote Received', 'Rejected
        if (status === 'Rejected') {
            // Update the active step based on the status
            if (status === 'Rejected') {
                setActiveStep(steps.length);
            } else if (status === 'Quote Sent') {
                setActiveStep(2);
            } else if (status === 'Request Received') {
                setActiveStep(1);
            } else {
                setActiveStep(0);
            }
        } else {            
            if (status === 'Published') {
                setActiveStep(6);
            }
            else if (status === 'Costing Completed') {
                setActiveStep(5);
            }             
            else if (status === 'BOM Completed') {
                setActiveStep(4);
            }            
            else if (status === 'Part Created') {
                setActiveStep(3);
            }             
            else if (status === 'Payment Received') {
                setActiveStep(2);
            } else if (status === 'Quote Sent') {
                setActiveStep(1);
            } else if (status === 'Request Received') {
                setActiveStep(0);
            }
        }
            
    }, [status]);

    let steps = [];
    // "Request Raised", "Quote Received", Rejected", "Cart", "Payment", In Progress","Completed"
    if (status === 'Rejected') {
        steps = ['Request Received', 'Quote Sent', 'Rejected'];
    } else {
        // if (currentType === 'From Drawings') {
        steps = [
            'Request Received',
            'Quote Sent',
            'Payment Received',
            // 'Assigned',
            'Part Created',
            // 'BOM Configuration',
            'BOM Completed',
            // 'Costing Configuration',
            'Costing Completed',
            // 'Validated',
            'Published',
            // 'Revise Request',
            // 'Revise BOM & Costing',
            // 'Validate Revised BOM & Costing',
            // 'Revised Vault Published',
            // 'Completed',
        ];
        // } else {
        //  steps = [
        //      'Request Received',
        //      'Quote Sent',
        //      'Payment Received',
        //      'Part Received',
        //      'Assigned',
        //      'Part Created',
        //      'BOM Configuration',
        //      'BOM Completed',
        //      'Costing Configuration',
        //      'Costing Completed',
        //      'Validated',
        //      'Published',
        //      // 'Revise Request',
        //      // 'Revise BOM & Costing',
        //      // 'Validate Revised BOM & Costing',
        //      // 'Revised Vault Published',
        //      'Completed',
        //  ];
        // }
    }
    // if (reOpen == true) {
    //     steps = [
    //         'Revise Request',
    //         'Revise BOM & Costing',
    //         'Validate Revised BOM & Costing',
    //         'Revised Vault Published',
    //         'Completed',
    //     ];
    // } 
    const getLabel = (label: string) => {
        if (label === 'Request Received') {
            return 'ZBC Request Received - Quotation In Progress';
        } else if (label === 'Quote Sent') {
            return 'Quotation Sent - Payment In Progress';
        } else if (label === 'Rejected') {
            return 'Payment Not Done - Request Rejected by ASI';
        }
    };

    const getDrawingsLabel = (label: string) => {
        if (label === 'Request Received') {
            return 'ZBC Request Received - Quotation Pending';
        } else if (label === 'Quote Sent') {
            return 'Quotation Sent - Payment Pending from Client';
        } else if (label === 'Payment Received') {
            return <>{reOpen == true ? <Typography sx={{fontSize:'0.87rem'}}>Payment Received - Yet to Create Part <br/ ><span style={{color:'red'}}>Old ZBC Deleted</span></Typography> :'Payment Received - Yet to Create Part'} </>;
        }         
        else if (label === 'Part Created') {
            return 'Part Created - BOM In Progress';
        }         
        else if (label === 'BOM Completed') {
            return 'BOM Completed - Zero-based Costing In Progress';
        }        
        else if (label === 'Costing Completed') {
            return 'Costing Completed - ZBC Yet to be Locked & Published';
        }         
        else if (label === 'Published') {
            return 'Zero-based Costing Published';
        }         
    };

    const getPhysicialLabel = (label: string) => {
        if (label === 'Request Received') {
            return 'ZBC Request Received - Quotation Pending';
        } else if (label === 'Quote Sent') {
            return 'Quotation Sent - Payment Pending from Client';
        } else if (label === 'Payment Received') {
            return <>{reOpen == true ? <Typography sx={{fontSize:'0.87rem'}}>Payment Received - Yet to Create Part <br/ ><span style={{color:'red'}}>Old ZBC Deleted</span></Typography> :'Payment Received - Yet to Create Part'} </>;
        }
        else if (label === 'Part Created') {
            return 'Part Created - BOM In Progress';
        } 
        else if (label === 'BOM Completed') {
            return 'BOM Completed - Zero-based Costing In Progress';
        }
        else if (label === 'Costing Completed') {
            return 'Costing Completed - ZBC Yet to be Locked & Published';
        } 
        else if (label === 'Published') {
            return 'Zero-based Costing Published';
        } 
    };

    const getRevisionLabel = (label: string) => {
        if (label === 'Revise Request') {
            return 'ZBC Revise Requested - Revision In Progress';
        } else if (label === 'Revise BOM & Costing') {
            return 'Revision Completed - Yet to Validate';
        } else if (label === 'Validate Revised BOM & Costing') {
            return 'Validation Completed - Yet to Publish';
        } else if (label === 'Revised Vault Published') {
            return 'Revision Published - Yet To close';
        } else if (label === 'Completed') {
            return 'ZBC Completed Download Report';
        }
    };

    return (
        <>
            <Modal
                open={isOpen}
                onClose={onCloseModal}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'>
                <Box sx={style}>
                    <Box sx={{ width: '100%' }}>
                        <Box
                            sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
                            <Typography
                                style={{
                                    fontSize: '1rem',
                                    fontWeight: '600',
                                    width: '100%',
                                    textAlign: 'center',
                                }}>
                                Zero-based Costing Request Status ({currentType})
                            </Typography>
                            <CancelIcon
                                sx={{
                                    cursor: 'pointer',
                                    marginLeft: '-2rem',
                                }}
                                onClick={onCloseModal}
                            />
                        </Box>
                        <Divider
                            sx={{
                                borderColor: 'primary.light',
                                margin: '0 1rem',
                                marginBottom: '1rem',
                            }}
                        />

                        {status != 'Rejected' && currentType === 'From Drawings' ? (
                            <Stack sx={{ width: '100%' }} spacing={4}>
                                <Stepper
                                    alternativeLabel
                                    activeStep={activeStep}
                                    connector={<ColorlibConnector />}>
                                    {steps.map((label) => (
                                        <Step
                                            key={label}
                                            sx={{
                                                'span.MuiStepLabel-label.Mui-disabled': {
                                                    color: '#ccc',
                                                },
                                                'span.MuiStepLabel-label.Mui-active.Mui-completed': {
                                                    color: 'primary.main',
                                                },
                                            }}>
                                            <StepLabel StepIconComponent={ColorlibStepIconDrawings}>
                                                {getDrawingsLabel(label)}
                                            </StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Stack>
                        ) : status != 'Rejected' && currentType === 'From Physical Part' ? (
                            <Stack sx={{ width: '100%' }} spacing={4}>
                                <Stepper
                                    alternativeLabel
                                    activeStep={activeStep}
                                    connector={<ColorlibConnector />}>
                                    {steps.map((label) => (
                                        <Step
                                            key={label}
                                            sx={{
                                                'span.MuiStepLabel-label.Mui-disabled': {
                                                    color: '#ccc',
                                                },
                                                'span.MuiStepLabel-label.Mui-active.Mui-completed': {
                                                    color: 'primary.main',
                                                },
                                            }}>
                                            <StepLabel StepIconComponent={ColorlibStepIconPhysical}>
                                                {getPhysicialLabel(label)}
                                            </StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Stack>
                        ) : (
                            <Stack sx={{ width: '100%' }} spacing={4}>
                                <Stepper
                                    alternativeLabel
                                    activeStep={activeStep}
                                    connector={<ColorlibConnector />}>
                                    {steps.map((label) => (
                                        <Step
                                            key={label}
                                            sx={{
                                                'span.MuiStepLabel-label.Mui-disabled': {
                                                    color: '#ccc',
                                                },
                                                'span.MuiStepLabel-label.Mui-active.Mui-completed': {
                                                    color: 'primary.main',
                                                },
                                            }}>
                                            <StepLabel StepIconComponent={ColorlibStepIcon}>
                                                {getLabel(label)}
                                            </StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Stack>
                        )}
                        
                        
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default ZBCRequestStatusModal;