import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// FeatureNoImg.png
import FeatureImg from "../../Assets/images/FeatureNoImg.png";
import { useHistory, useParams } from "react-router-dom";
import { useStage } from "spritespin";
import { API } from "../../api-services";
import {
  Autocomplete,
  Checkbox,
  Chip,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
  TableHead,
  TableBody,
  Skeleton,
  Paper,
  Tooltip,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import styles from "./FeatureLandingPage.module.scss";
import { ID } from "../../utlis/commonInterfaces";
import { ADMIN } from "../../Redux/Services/admin.service";
import { DataGrid } from "@mui/x-data-grid";
import RViewerJS from "viewerjs-react";
import VideoModal from "../../ComponentUI/MediaModule/VideoModal/VideoModal";
import FeatureDetailsView from "./FeatureDetailsView";
import ClearIcon from "@mui/icons-material/Clear";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { style } from "@mui/system";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import NoDataImg from "../../Assets/images/NoDataImg.png";
import BouncingLoader from "../../ui-reusable-component/BouncingLoader";
import Image from "../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import NoImgLoader from "../../Assets/images/loading-screen.gif";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import { DialogComponent } from "../../utlis/DialogComponent";
import excelIcon from "../../Assets/images/excel.png";
import DownloadIcon from "@mui/icons-material/Download";
import { Padding } from "@mui/icons-material";
import { useDocumentTitle } from "../../ui-reusable-component/useDocumentTitle";

const icon = <CheckBoxOutlineBlankIcon fontSize="medium" />;
const checkedIcon = <CheckBoxIcon fontSize="medium" />;
export interface FeatureLandingPage {}
type Anchor = "top" | "left" | "bottom" | "right";
const FeatureLandingPage = (props: FeatureLandingPage) => {
  useDocumentTitle("Feature View");
  const [selectedTopVaultIds, setSelectedTopVaultIds] = useState<any>([]);
  const [selectedProducts, setSelectedProducts] = useState<any>([]);
  const history = useHistory<any>();
  // const {projectId}=useParams<any>()
  const {
    projectId,
    topVault,
    vault,
    abbr,
    FeatureType,
    FeatureId,
    GroupId,
    FeatureName,
  } = useParams<any>();
  const [ProjectData, setProjectData] = useState<any>([]);
  const [SubsystemData, setSubsystemData] = useState<any>([]);
  const [productSubsystems, setProductSubsystems] = useState<any>([]);
  const [categoryChange, setCategoryChange] = useState<any>([]);
  const [ProductID, setProductId] = useState<any>();
  const [ProductTopVaultData, setProductTopVaultData] = useState<any>();
  const [state, setState] = React.useState({
    left: false,
  });
  useEffect(() => {
    sessionStorage.setItem("historyProject", projectId);
  }, [projectId]);
  // const [isActive, setIsActive] = useState(false);
  // const [pinStatus, setPinStatus] = useState<any>(false);
  const [FeatureData, setFeatureData] = useState<any>();
  const [FeatureGroupId, setFeatureGroupId] = useState<any>();
  const [FeatureSelectedId, setFeatureSelectedId] = useState<any>();
  const [FeatureNameData, setFeatureNameData] = useState<any>();
  const [Loader, setLoader] = useState<any>(false);
  const VideoModalRef = useRef<any>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [ReportType, setReportType] = useState<any>();
  const open = Boolean(anchorEl);
  const [contentText, setContentText] = React.useState<any>();
  const [iconText, setIconText] = React.useState<any>("success");
  const [FeatureTypeName, setFeatureTypeName] = React.useState([]);
  const [SystemAbbr, setSystemAbbr] = React.useState();
  const [FeatureConditiondata, setFeatureConditiondata] = useState<any>([]);

  const openDialogModal = useBaseModal();
  const OptionData = () => {
    var options =
      SubsystemData &&
      SubsystemData?.sub_system &&
      SubsystemData?.sub_system.map((item: any, ind: any) => {
        return {
          title: item?.name == "MAIN PRODUCT" ? "Product" : item?.name,
          value:
            item?.name == "MAIN PRODUCT" ? item?.top_vault_id : item?.vault_id,
          text: item?.name == "MAIN PRODUCT" ? "product" : item.abbreviation,
          vaultId: item?.id,
        };
      });
    return options;
  };
  // console.log(categoryChange,"ebebfbejfbejedecb")
  // const handleCollapseClick = () => {
  //   // 👇️ toggle isActive state on click
  //   setPinStatus(!pinStatus);
  //   setIsActive(!isActive);
  // };

  const formInputField = (e: any) => {
    if (e == 1) {
      setCategoryChange([1]);
      setFeatureGroupId(0);
      setFeatureNameData(0);
      API.get("/cost/feature_view_data/", {
        project: projectId,
        abbreviation: productSubsystems,
        feature_type: true,
      })
        .then((res: any) => {
          setFeatureTypeName(res?.data);
          var filterdata = res?.data?.filter((item: any, index: any) => {
            return item?.type == e;
          });
          setFeatureConditiondata(filterdata);
        })
        .catch((err: any) => {
          console.log("Server error");
        });
    } else if (e == 0) {
      setCategoryChange([0]);
      setFeatureGroupId(0);
      setFeatureNameData(0);
      API.get("/cost/feature_view_data/", {
        project: projectId,
        abbreviation: productSubsystems,
        feature_type: true,
      })
        .then((res: any) => {
          var filterdata = res?.data?.filter((item: any, index: any) => {
            return item?.type == e;
          });
          setFeatureConditiondata(filterdata);
        })
        .catch((err: any) => {
          console.log("Server error");
        });
    } else if (e == 2) {
      API.get("/cost/feature_view_data/", {
        project: projectId,
        abbreviation: productSubsystems,
        feature_type: true,
      })
        .then((res: any) => {
          var filterdata = res?.data?.filter((item: any, index: any) => {
            return item?.type == e;
          });
          setFeatureConditiondata(filterdata);
        })
        .catch((err: any) => {
          console.log("Server error");
        });
      setCategoryChange([2]);
      setFeatureGroupId(0);
      setFeatureNameData(0);
    }
  };

  const SubSystemOnchangeHandler = (selectedValue: any) => {
    setProductSubsystems(selectedValue?.text);
    setFeatureSelectedId(selectedValue?.vaultId);
    setProductId(selectedValue?.value);
    setFeatureNameData(0);
    setFeatureGroupId(0);
  };
  const selectAllProduct = (productIds: any, item: any) => {
    if (selectedTopVaultIds?.includes(productIds)) {
      setSelectedTopVaultIds((prev: any) =>
        prev?.filter((item: any) => item !== productIds)
      );
    } else {
      setSelectedTopVaultIds((prev: any) => [...prev, productIds]);
    }
  };

  const selectAllProductSubsystem = (
    system: any,
    name: any,
    topVaultIddata: any,
    vaultIds: any,
    Id: any
  ) => {
    var topVaultName = name == "MAIN PRODUCT" ? topVaultIddata : vaultIds;
    setProductSubsystems(system);

    setFeatureSelectedId(Id);
    setProductId(topVaultName);

    setFeatureNameData(0);
    setFeatureGroupId(0);
  };

  const SearchViewHandler = () => {
    // sub_system: 66511
    // top_vault: false
    // category: 1
    // new_ui: true
    API.get("/analytics/fetch_features/", {
      sub_system: ProductID,
      top_vault: productSubsystems == "product" ? true : false,
      new_ui: true,
      get_first_feature: true,
      category: categoryChange.toString(),
    }).then((res: any) => {
      history.push(
        `/featurelandingPage/${projectId}/${selectedTopVaultIds.toString()}/${ProductID}/${productSubsystems}/${categoryChange}/${FeatureSelectedId}/${
          res?.data?.id
        }/${res?.data?.feature_name}`
      );
    });
  };
  const ToggleTopVault = () => {
    if (abbr == "product") {
      return { top_vault: true };
    } else {
      return { top_vault: false };
    }
  };

  const ToggleTopVaultdata = () => {
    if (abbr == "product") {
      return { top_vault: vault };
    } else {
      return { vault: vault };
    }
  };
  useEffect(() => {
    setLoader(true);
    API.get("/analytics/fetch_features/", {
      sub_system: vault,
      ...ToggleTopVault(),
      category: FeatureType,
      new_ui: true,
    })
      .then((res: any) => {
        setLoader(false);
        setFeatureData(res?.data);
      })
      .catch((err: any) => {
        setLoader(false);

        // ADMIN.toast.throwError(err, "Server Error")
      });
  }, [topVault, FeatureType, vault]);

  useEffect(() => {
    setProductSubsystems(abbr);
    setCategoryChange(FeatureType);
    setFeatureSelectedId(FeatureId);
    setProductId(vault);
  }, [abbr, FeatureType]);

  const getselectedBackgroundColor = (idd: any) => {
    if (selectedTopVaultIds?.includes(idd)) {
      return "primary.main";
    } else {
      return "primary.light";
    }
  };
  const getSelectedTextColor = (idd: any) => {
    if (selectedTopVaultIds?.includes(idd)) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };

  const getselectedProjectBackgroundColor = (idd: any) => {
    if (productSubsystems?.includes(idd)) {
      return "primary.main";
    } else {
      return "primary.light";
    }
  };
  const getSelectedProjectTextColor = (idd: any) => {
    if (productSubsystems?.includes(idd)) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };

  const getselectedTypeBackgroundColor = (idd: any) => {
    if (categoryChange?.includes(idd)) {
      return "primary.main";
    } else {
      return "primary.light";
    }
  };
  const getSelectedTypeTextColor = (idd: any) => {
    if (categoryChange?.includes(idd)) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };
  const topVaultsParam: any =
    topVault && typeof topVault == "string"
      ? topVault?.split(",")?.map(Number)
      : [topVault];

  useEffect(() => {
    setSelectedTopVaultIds(topVaultsParam);
  }, [topVault, projectId, GroupId, state]);

  useEffect(() => {
    setFeatureNameData(FeatureName);
    setFeatureGroupId(GroupId);
  }, [GroupId]);
  useEffect(() => {
    var value = SubsystemData?.allowed_top_vault?.filter(
      (item: any, inde: any) => topVaultsParam.includes(item?.id)
    );
    setProductTopVaultData(value);
  }, [topVault, SubsystemData, selectedTopVaultIds]);
  useEffect(() => {
    API.get("/cost/dashboard/", { get_product_list: projectId }).then(
      (res: any) => {
        setProjectData(res?.data[0]);
      }
    );
  }, [projectId]);

  const handleClear = (Id: any) => {
    if (selectedTopVaultIds?.includes(Id)) {
      var data = [...selectedTopVaultIds];
      var filterData = data.filter((item: any, ind: any) => item !== Id);
      setSelectedTopVaultIds(filterData);
      history.push(
        `/featurelandingPage/${projectId}/${filterData.toString()}/${ProductID}/${productSubsystems}/${categoryChange}/${FeatureSelectedId}/${FeatureGroupId}/${FeatureNameData}`
      );
    }
    var value = SubsystemData?.allowed_top_vault?.filter(
      (item: any, inde: any) => selectedTopVaultIds?.includes(item?.id)
    );
    setProductTopVaultData(value);
  };
  const FeatureGroupHandler = (Id: any, nameFeature: any) => {
    console.log("dbjcdkbjdkbjvbvbfvbj", categoryChange);
    setFeatureGroupId(Id);
    setFeatureNameData(nameFeature);
    history.push(
      `/featurelandingPage/${projectId}/${selectedTopVaultIds.toString()}/${ProductID}/${productSubsystems}/${categoryChange}/${FeatureSelectedId}/${Id}/${nameFeature}`
    );
  };
  useEffect(() => {
    API.get("/cost/feature_view_data/", {
      project: projectId,
      analysis_type: 19,
      subsystem_list: true,
    })
      .then((res: any) => {
        setSubsystemData(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, [projectId.topVault]);

  useEffect(() => {
    if (productSubsystems !== undefined || !abbr) {
      API.get("/cost/feature_view_data/", {
        project: projectId,
        abbreviation: productSubsystems == undefined ? abbr : productSubsystems,
        feature_type: true,
      })
        .then((res: any) => {
          setFeatureTypeName(res?.data);
          var filterdata = res?.data?.filter((item: any, index: any) => {
            return (
              item?.type == categoryChange || item?.type == categoryChange[0]
            );
          });
          setFeatureConditiondata(filterdata);
        })
        .catch((err: any) => {
          console.log("Server error");
        });
    }
  }, [productSubsystems, vault]);

  // console.log(SystemAbbr,"vdvbjdkbjvbdvbj",productSubsystems)

  const FeatureHierarchy = (keyData: any) => {
    var featureValue = FeatureData && FeatureData[keyData];
    var groupArry =
      featureValue &&
      featureValue?.map((group: any, index: any) => {
        return (
          <div
            onClick={() => FeatureGroupHandler(group?.id, group?.feature_name)}

            // aria-expanded={open ? "true" : undefined}
          >
            <Box
              sx={{
                display: "flex",
                columnGap: "0.5rem",
                alignItems: "center",
              }}
            >              
              <span title={group.name}
                className={
                  FeatureName == group?.feature_name
                    ? styles.active_feature
                    : styles.inactive_Feature
                }
              >
                {group.name}
              </span>
            </Box>
          </div>
        );
      });
    return groupArry;
  };

  //   const FeatureTypeName: any = [
  //     { name: "Feature", id: 0 },
  //     { name: "Specification", id: 1 },
  //     { name: "Information", id: 2 }

  //   ]

  // useEffect(()=>{

  // },[])

  var categorydata = [0, 1, 2];
  const DownLoadHandler = () => {
    API.get(`/analytics/run_analysis/`, {
      id: FeatureId,
      sub_system: GroupId,
      top_vault: true,
      category: categorydata.toString(),
      download: true,
    })
      .then((res: any) => {
        // ADMIN.toast.success(res.data);
      })
      .catch((err: any) => {
        // ADMIN.toast.throwError(err, "Server Error");
      });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const downLoadReport = (reportTypes: any) => {
    API.get("/cost/check_consolidate_downoad/", {
      id: FeatureId,
      ...ToggleTopVaultdata(),
      category: categorydata.toString(),
      top_vault_list: topVault,
      download: true,
      with_image: reportTypes,
      consolidate_type: "Features",
    }).then((res: any) => {
      if (res?.data?.status == "First Download") {
        setIconText("firstdownload");

        openDialogModal.open();

        setContentText(res.data.message);

        API.get(
          "/cost/consolidated_report/",

          {
            id: FeatureId,
            ...ToggleTopVaultdata(),

            category: categorydata.toString(),
            top_vault_list: topVault,
            download: true,
            with_image: reportTypes,
            consolidate_type: "Features",
          },
          0
        ).then((res: any) => {
          window.open(res?.data, "_blank");
        });
      }

      if (res?.data.status == "Completed") {
        window.open(res?.data?.url, "_blank");
      }
      if (res?.data.status == "In Progress") {
        setIconText("reportProgress");
        openDialogModal.open();
        setContentText(res.data.message);
      }
      if (res?.data.status == "Failed") {
        openDialogModal.open();
        setIconText("failed");
        setContentText(res.data.message);
      }
    });
  };

  const backButtonHandler: any = () => {
    let a: any = sessionStorage.getItem("feature");

    if (a == "Feature") {
      history.push(
        `/view/${projectId}/${
          topVaultsParam && topVaultsParam[0]
        }/0/product/false`
      );
    } else {
      window.location.href = a;
    }
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean, textType: any) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      if (textType == "cancle") {
        const topVaultsParam: any =
          topVault && typeof topVault == "string"
            ? topVault?.split(",")?.map(Number)
            : [topVault];
        setSelectedTopVaultIds(topVaultsParam);
        setProductSubsystems(abbr);
        setCategoryChange(FeatureType);
        setProductId(vault);
        setFeatureSelectedId([FeatureId]);
        setFeatureGroupId(GroupId);
        setState({ ...state, [anchor]: open });
        setFeatureNameData(FeatureName);
      }
      setSelectedTopVaultIds(selectedTopVaultIds);
      setProductSubsystems(abbr);
      setCategoryChange(FeatureType);
      setProductId(vault);
      setFeatureSelectedId(FeatureId);
      setFeatureNameData(FeatureName);
      setFeatureGroupId(GroupId);
      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        padding: "0 0.5rem",
        minWidth: { lg: 200, xl: 300 },
        maxWidth: "30vw",
        width: anchor === "top" || anchor === "bottom" ? "auto" : "auto",
      }}
      role="presentation"
    >
      <Box sx={{ width: "100%" }}>
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              width: "100%",
              fontSize: "0.75rem",
              margin: "0.5rem 0 0rem",
            }}
          >
            Product/Subsystem *
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              columnGap: "5px",
            }}
          >
            {SubsystemData &&
              SubsystemData?.sub_system &&
              SubsystemData?.sub_system?.map((item: any, index: any) => {
                return (
                  <>
                    <Box sx={{ alignItems: "center", display: "flex" }}>
                      <Checkbox
                        style={{ padding: "3px" }}
                        checked={
                          productSubsystems?.includes(item?.abbreviation)
                            ? true
                            : false
                        }
                        onClick={() =>
                          selectAllProductSubsystem(
                            item?.abbreviation,
                            item?.name,
                            item?.top_vault_id,
                            item?.vault_id,
                            item?.id
                          )
                        }
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: { lg: 24, xl: 30 },
                          },
                        }}
                      />
                      <Box
                        onClick={() =>
                          selectAllProductSubsystem(
                            item?.abbreviation,
                            item?.name,
                            item?.top_vault_id,
                            item?.vault_id,
                            item?.id
                          )
                        }
                        key={index}
                        // className={styles.selectVehBtn}
                        sx={{
                          // margin: "0rem 2rem",
                          padding: "0.2rem 0.5rem",
                          backgroundColor: getselectedProjectBackgroundColor(
                            item?.abbreviation
                          ),
                          color: getSelectedProjectTextColor(
                            item?.abbreviation
                          ),
                          textAlign: "left",
                          cursor: "pointer",
                          display: "inline-block",
                          width: "100%",
                        }}
                      >
                        {item?.name == "MAIN PRODUCT" ? (
                          <Typography sx={{ fontSize: "0.87rem" }}>
                            {"Product"}
                          </Typography>
                        ) : (
                          <Typography sx={{ fontSize: "0.87rem" }}>
                            {item?.name}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </>
                );
              })}
          </Box>
          <Box sx={{ width: "100%", marginTop: "0.5rem" }}>
            <Typography
              sx={{
                width: "100%",
                fontSize: "0.75rem",
              }}
            >
              Type *
            </Typography>
            {FeatureTypeName &&
              FeatureTypeName?.map((item: any, index: any) => {
                return (
                  <>
                    <Box sx={{ alignItems: "center", display: "flex" }}>
                      <Checkbox
                        style={{ padding: "3px" }}
                        checked={
                          categoryChange?.includes(item?.type) ? true : false
                        }
                        onClick={() => formInputField(item?.type)}
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: { lg: 24, xl: 30 },
                          },
                        }}
                      />
                      <Box
                        onClick={() => formInputField(item?.type)}
                        key={index}
                        // className={styles.selectVehBtn}
                        sx={{
                          // margin: "0rem 2rem",
                          padding: "0.2rem 0.5rem",
                          backgroundColor: getselectedTypeBackgroundColor(
                            item?.type
                          ),
                          color: getSelectedTypeTextColor(item?.type),
                          textAlign: "left",
                          cursor: "pointer",
                          display: "inline-block",
                          width: "100%",
                        }}
                      >
                        <Typography sx={{ fontSize: "0.87rem" }}>
                          {item?.name}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                );
              })}
          </Box>
          <Box sx={{ width: "100%" }}>
            <Typography
              sx={{
                width: "100%",
                fontSize: "0.75rem",
              }}
            >
              Select Products*
            </Typography>
            {SubsystemData &&
              SubsystemData?.allowed_top_vault &&
              SubsystemData?.allowed_top_vault?.map((item: any, index: any) => {
                return (
                  <>
                    <Box sx={{ alignItems: "center", display: "flex" }}>
                      <Checkbox
                        style={{ padding: "3px" }}
                        checked={
                          selectedTopVaultIds?.includes(item?.id) ? true : false
                        }
                        onClick={() => selectAllProduct(item?.id, item)}
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                      />
                      <Box
                        onClick={() => selectAllProduct(item?.id, item)}
                        key={index}
                        // className={styles.selectVehBtn}
                        sx={{
                          // margin: "0rem 2rem",
                          padding: "0.2rem 0.5rem",
                          backgroundColor: getselectedBackgroundColor(item.id),
                          color: getSelectedTextColor(item.id),
                          textAlign: "left",
                          cursor: "pointer",
                          display: "inline-block",
                          width: "100%",
                        }}
                      >
                        <Typography sx={{ fontSize: "0.87rem" }}>
                          {item?.name}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                );
              })}
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "1rem",
            position: "sticky",
            bottom: "0",
            backgroundColor: "aliceblue",
          }}
        >
          {selectedTopVaultIds.length < 1 && (
            <Typography
              sx={{
                fontSize: "0.87rem",
                color: "#d32f2f",
                marginTop: "0.4rem",
                marginLeft: "1.45rem",
              }}
            >
              {"*Please select atleast 1 Product"}
            </Typography>
          )}
          {selectedTopVaultIds.length > 5 && (
            <Typography
              sx={{
                fontSize: "0.87rem",
                color: "#d32f2f",
                marginTop: "0.4rem",
                marginLeft: "1.45rem",
              }}
            >
              {"*Please select less then 5 Products"}
            </Typography>
          )}

          <Box sx={{ textAlign: "right" }}>
            <Button
              className={styles.drawBtn}
              sx={{ marginRight: "1rem", fontSize: "0.87rem" }}
              variant="contained"
              size="small"
              onClick={toggleDrawer(anchor, false, "cancle")}
            >
              Close
            </Button>
            <Button
              onClick={SearchViewHandler}
              size="small"
              disabled={
                selectedTopVaultIds?.length < 1 ||
                selectedTopVaultIds?.length > 5
              }
            >
              {FeatureConditiondata?.length > 0 ? (
                <Button
                  className={styles.drawBtn}
                  disabled={
                    selectedTopVaultIds?.length < 1 ||
                    selectedTopVaultIds?.length > 5
                  }
                  onClick={toggleDrawer(anchor, false, "okClick")}
                  variant="contained"
                  size="small"
                  sx={{ fontSize: "0.87rem" }}
                >
                  Ok
                </Button>
              ) : (
                <Typography sx={{ color: "red !important" }}>
                  *Please select Type
                </Typography>
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
  return (
    <>
      <div className={styles.main_FeatureLandingPage}>
        <DialogComponent
          isOpen={openDialogModal.isOpen}
          onClose={openDialogModal.close}
          content={contentText}
          type={iconText}
        />
        <Box>
          <Box className={styles.goBack}>
            <IconButton sx={{ cursor: "pointer" }} onClick={backButtonHandler}>
              <ArrowBackIcon color="primary" titleAccess="Go Back" />
            </IconButton>
          </Box>
          <Box className={styles.expandIconBox}>
            <div className={styles.iconSec}>
              {(["left"] as const).map((anchor) => (
                <React.Fragment key={anchor}>
                  <IconButton
                    size="medium"
                    sx={{
                      backgroundColor: "primary.light",
                      color: "primary.main",
                    }}
                    onClick={toggleDrawer(anchor, true, "")}
                  >
                    <AddIcon sx={{ fontSize: "1.7rem" }} />
                  </IconButton>
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    // onClick={toggleDrawer(anchor, false, "cancle")}styles
                    onClose={toggleDrawer(anchor, false, "cancle")}
                    className={styles.drawerContainer}
                    PaperProps={{
                      sx: {
                        backgroundColor: "aliceblue",
                      },
                    }}
                  >
                    <Box
                      sx={{ padding: "0rem 1rem" }}
                      adl-scrollbar="true"
                      adl-scrollbar-width="0.3"
                    >
                      <Box
                        sx={{
                          position: "sticky",
                          top: "0",
                          backgroundColor: "aliceblue",
                          padding: "0.5rem 0 0",
                          zIndex: "1",
                        }}
                      >
                        <Typography
                          sx={{
                            width: "100%",
                            textAlign: "center",
                            fontSize: "1rem",
                          }}
                        >
                          Feature Selection
                        </Typography>
                        <Divider sx={{ margin: "0.2rem" }} />
                      </Box>
                      {list(anchor)}
                    </Box>
                  </Drawer>
                </React.Fragment>
              ))}
            </div>
            <Typography
              className={styles.sideTitle}
              onClick={toggleDrawer("left", true, "")}
            >
              Select Product/Subsystem & Type
            </Typography>
          </Box>
        </Box>
        <Box className={styles.featureContainer}>
          <div className={styles.chipSection}>
            <Box sx={{ width: "24vw" }}>
              <Typography
                sx={{
                  lineHeight: "1.2",
                  fontSize: "1rem",
                  whiteSpace: "nowrap",
                }}
              >
                {ProjectData && ProjectData?.project_name} (
                {ProjectData && ProjectData?.project_description})
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                columnGap: "0.5rem",
                width: { sm: "70%", md: "80%", lg: "90%", xl: "90%" },
                alignItems: "center",
              }}
            >
              {ProductTopVaultData && ProductTopVaultData.length > 1
                ? ProductTopVaultData?.map((item: any, index: any) => {
                    return (
                      <Box>
                        <Chip
                          label={item?.name}
                          variant="outlined"
                          color="primary"
                          size="small"
                          sx={{ fontSize: "0.87rem" }}
                          // ProductTopVaultData
                          // deleteIcon={ ProductTopVaultData.length>1? <ClearIcon/>:"none"}
                          onDelete={() => handleClear(item?.id)}
                        />
                      </Box>
                    );
                  })
                : ProductTopVaultData?.map((item: any, index: any) => {
                    return (
                      <Box className={styles.chipCont}>
                        <Chip
                          label={item?.name}
                          variant="outlined"
                          color="primary"
                          size="small"
                          sx={{ fontSize: "0.87rem" }}
                        />
                      </Box>
                    );
                  })}
            </Box>
            <Box className={styles.downlBtn} sx={{ textAlign: "right" }}>
              <Button
                // variant="contained"
                size="small"
                title="Download Report"
                // onClick={() => DownLoadHandler()}
                className={styles.downloadreportFeature}
                sx={{ cursor: "pointer", alignItems: "center" }}
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                // aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                endIcon={<DownloadIcon sx={{ marginLeft: "-0.5rem" }} />}
              >
                Download
              </Button>

              {/* @ts-ignore */}

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                sx={{
                  "&.MuiList-root.MuiMenu-list": {
                    Padding: "0 !important",
                  },
                }}
              >
                <MenuItem
                  sx={{
                    width: "auto",
                    fontSize: "1rem",
                    padding: "0.4rem 0.5rem",
                  }}
                  onClick={() => downLoadReport(true)}
                >
                  Consolidated with Images
                </MenuItem>
                <MenuItem
                  sx={{
                    width: "auto",
                    fontSize: "1rem",
                    padding: "0.4rem 0.5rem",
                  }}
                  onClick={() => downLoadReport(false)}
                >
                  Consolidated w/o Images
                </MenuItem>
              </Menu>
            </Box>
          </div>
          <div className={styles.featureMainDiv}>
            <>
              {FeatureData && Object.keys(FeatureData)?.length > 0 ? (
                <>
                  <div className={styles.feature_left}
                   adl-scrollbar="true"
                   adl-scrollbar-width="0.3">
                    {FeatureType == 0 ? (
                      <Box
                        className={styles.headerSect}
                        sx={{
                          color: "primary.main",
                          backgroundColor: "primary.light",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ fontSize: "1rem" }}>
                          Feature
                        </Typography>
                      </Box>
                    ) : FeatureType == 1 ? (
                      <Box
                        className={styles.headerSect}
                        sx={{
                          color: "primary.main",
                          backgroundColor: "primary.light",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ fontSize: "1rem" }}>
                          Specification
                        </Typography>
                      </Box>
                    ) : (
                      <Box
                        className={styles.headerSect}
                        sx={{
                          color: "primary.main",
                          backgroundColor: "primary.light",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ fontSize: "1rem" }}>
                          Information
                        </Typography>
                      </Box>
                    )}
                    {FeatureData &&
                      Object.keys(FeatureData).map((Feature: any, ind: any) => {
                        return (
                          <>
                            <Box sx={{ display: "block", padding: "0 0.5rem 0 1rem",width:'100%' }}>
                              <Typography
                                sx={{
                                  fontWeight: "700",
                                  marginTop: "0.5rem",
                                  fontSize: "1rem",
                                }}
                              >
                                {Feature}
                              </Typography>
                              <Typography sx={{fontSize:'1rem', fontWeight: "400"}}>
                                {FeatureHierarchy(Feature)}
                              </Typography>
                            </Box>
                          </>
                        );
                      })}
                  </div>
                  <div className={styles.feature_right}>
                    {GroupId == "0" ? (
                      <div className={styles.featureNoData}>
                        <img src={FeatureImg} style={{ width: " 16rem" }} />
                      </div>
                    ) : (
                      <FeatureDetailsView />
                    )}
                  </div>
                </>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    alignItems: "flex-start",
                  }}
                >
                  <Box
                    sx={{
                      width: "20vw",
                      padding: "0 0.5rem",
                      marginTop: "0rem",
                    }}
                  >
                    <Skeleton sx={{ height: "2rem", width: "30%" }} />
                    <Skeleton sx={{ height: "2rem", width: "45%" }} />
                    <Skeleton sx={{ height: "2rem", width: "50%" }} />
                    <Skeleton sx={{ height: "2rem", width: "65%" }} />
                    <Skeleton sx={{ height: "2rem", width: "70%" }} />
                    <Skeleton sx={{ height: "2rem", width: "60%" }} />
                    <Skeleton sx={{ height: "2rem", width: "55%" }} />
                    <Skeleton sx={{ height: "2rem", width: "30%" }} />
                    <Skeleton sx={{ height: "2rem", width: "45%" }} />
                    <Skeleton sx={{ height: "2rem", width: "50%" }} />
                    <Skeleton sx={{ height: "2rem", width: "35%" }} />
                    <Skeleton sx={{ height: "2rem", width: "70%" }} />
                    <Skeleton sx={{ height: "2rem", width: "55%" }} />
                    <Skeleton sx={{ height: "2rem", width: "80%" }} />
                    <Skeleton sx={{ height: "2rem", width: "30%" }} />
                    <Skeleton sx={{ height: "2rem", width: "45%" }} />
                    <Skeleton sx={{ height: "2rem", width: "50%" }} />
                    <Skeleton sx={{ height: "2rem", width: "65%" }} />
                    <Skeleton sx={{ height: "2rem", width: "70%" }} />
                    <Skeleton sx={{ height: "2rem", width: "60%" }} />
                    <Skeleton sx={{ height: "2rem", width: "55%" }} />
                    <Skeleton sx={{ height: "2rem", width: "30%" }} />
                    <Skeleton sx={{ height: "2rem", width: "45%" }} />
                    <Skeleton sx={{ height: "2rem", width: "50%" }} />
                    <Skeleton sx={{ height: "2rem", width: "35%" }} />
                    <Skeleton sx={{ height: "2rem", width: "70%" }} />
                    <Skeleton sx={{ height: "2rem", width: "55%" }} />
                    <Skeleton sx={{ height: "2rem", width: "80%" }} />
                  </Box>
                  <Box sx={{ padding: "0 2rem", width: "80vw" }}>
                    <Skeleton width="100%" />
                    <Skeleton width="100%" height={150} />
                    <Skeleton width="100%" height={55} />
                    <Skeleton width="100%" height={45} />
                    <Skeleton width="100%" height={150} />
                    <Skeleton width="100%" height={150} />
                  </Box>
                </Box>
              )}
            </>
            {/* )} */}
          </div>
        </Box>
      </div>
    </>
  );
};
export default FeatureLandingPage;
