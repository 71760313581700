import {
	Checkbox,
	Divider,
	FormControlLabel,
	Modal,
	Skeleton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CopyHierarchyBOM from './CopyHierarchyBOM';
import { API } from '../../../api-services';

import CancelIcon from '@mui/icons-material/Cancel';
import { useBaseModal } from '../../SearchPage/useBaseModal';
import ErrorModalFilesMail from '../../ProjectMailModule/ErrorModalFilesMail';
import SearchSupplier from './SearchSupplier';
import { LoadingButton } from '@mui/lab';
import { useRouteParams } from '../../../ui-reusable-component/useRouteParams';
import AddSupplierDB from './AddSupplierDB';
const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: { lg: '40vw', xl: '30vw' },
	bgcolor: 'background.paper',
	borderRadius: '10px',
	p: 1,
};

interface CopyHierarchyBOMModalProps {
	onCloseModal: any;
	isOpen: any;
}

export default function SupplierListMoreModal(
	props: CopyHierarchyBOMModalProps
) {
	const { onCloseModal, isOpen } = props;
	const { vault, topVault } = useRouteParams<any>();
	const [checkedDB, setCheckedDB] = React.useState<any>(true);
	const [checkedFastner, setCheckedFastner] = React.useState<any>(false);
	const [selectedSupplier, setSelectedSupplier] = useState<any>('');
	const [tab, setTab] = useState<any>(0);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCheckedDB(event.target.checked);
	};
	const handleChangeFastner = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCheckedFastner(event.target.checked);
	};

	const getObj = (vlt: any) => {
		if (vlt == 0) {
			return { top_vault: topVault };
		} else {
			return { vault: vlt };
		}
	};

	return (
		<Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
			<Modal
				open={isOpen}
				onClose={onCloseModal}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'>
				<Box sx={style}>
					<Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
						<Typography
							style={{
								fontSize: '1rem',
								fontWeight: '500',
								width: '100%',
								textAlign: 'center',
								padding: '0.1rem',
								cursor: 'pointer',
								border: '1px solid #007fff',
								backgroundColor: tab == 0 ? '#007FFF' : 'white',
								color: tab == 0 ? 'white' : '#007FFF',
							}}
							onClick={() => setTab(0)}>
							Fetch Supplier List
						</Typography>
						<Typography
							style={{
								fontSize: '1rem',
								fontWeight: '500',
								width: '100%',
								textAlign: 'center',
								padding: '0.1rem',
								cursor: 'pointer',
								border: '1px solid #007fff',
								backgroundColor: tab == 1 ? '#007FFF' : 'white',
								color: tab == 1 ? 'white' : '#007FFF',
							}}
							onClick={() => setTab(1)}>
							Add new Supplier
						</Typography>
						<CancelIcon
							titleAccess='Close'
							sx={{ cursor: 'pointer', margin: '0.5rem' }}
							onClick={onCloseModal}
						/>
					</Box>
					{/* <Divider
                        sx={{
                            borderColor: "primary.light",
                            margin: "0 1rem",                            
                        }}
                    /> */}
					{tab === 0 ? (
						<Box>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									columnGap: '1rem',
									alignItems: 'center',
									height: '5rem',
									padding: '0 1rem',
								}}>
								<Box>
									<SearchSupplier setSelectedSupplier={setSelectedSupplier} />
								</Box>
								<Box>
									<FormControlLabel
										sx={{
											'.MuiFormControlLabel-root .MuiFormControlLabel-label': {
												fontSize: '1rem',
											},
										}}
										control={
											<Checkbox
												checked={checkedDB}
												sx={{ fontSize: '1rem' }}
												onChange={handleChange}
												inputProps={{ 'aria-label': 'controlled' }}
											/>
										}
										label='Map to Subsystem'
									/>

									{/* <FormControlLabel control={<Checkbox checked={checkedFastner}
                                onChange={handleChangeFastner}
                                inputProps={{ 'aria-label': 'controlled' }} />} label="Map to Fastner" /> */}
								</Box>
							</Box>
							<Box
								sx={{
									width: '100%',
									padding: '0 1rem',
									textAlign: 'right',
									marginBottom: '1rem',
								}}>
								<LoadingButton
									onClick={() => {
										API.put(
											`/supplier/supplier/${selectedSupplier}/`,
											{
												...getObj(vault),
											},
											{},
											0
										)
											.then((res: any) => {
												onCloseModal();
											})
											.catch((err: any) => {});
									}}
									variant='contained'
									sx={{
										padding: '0.5rem',
										lineHeight: 1,
										'&:hover': {
											transform: 'Scale(1.05)',
											transition: 'transform 0.5s ease',
										},
										'.MuiCircularProgress-root': {
											color: 'primary.main',
										},
									}}>
									Add
								</LoadingButton>
							</Box>
						</Box>
					) : (
						<AddSupplierDB onCloseModalParent={onCloseModal} tab={tab} />
					)}
				</Box>
			</Modal>
		</Box>
	);
}
