const handleOpenLinkReference = (reference: string) => {
  window.open(reference);
};

const BulbColumns: any = [
  { field: "sno", headerName: "S.No", width: 50 },
  {
    field: "name",
    headerName: "Item Name",
    minWidth: 130,
    flex: 1,
  },
  {
    field: "type",
    headerName: "Type",
    minWidth: 100,
    flex: 1,
  },
  {
    field: "rated_voltage",
    headerName: "Rated Voltage",
    type: "number",
    minWidth: 100,
    flex: 1,
  },
  {
    field: "base_code",
    headerName: "Base Code",
    type: "number",
    minWidth: 120,
    flex: 1,
  },
  {
    field: "no_of_filament",
    headerName: "No. of filaments",
    type: "number",
    minWidth: 80,
    flex: 1,
  },
  {
    field: "watttage",
    headerName: " Filament Wattage",
    type: "number",
    minWidth: 140,
    flex: 1,
  },

  {
    field: "reference",
    headerName: "Reference",
    sortable: false,
    minWidth: 100,
    flex: 1,
    renderCell: (cellValues: any) => {
      return (
        <>
          <span
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => handleOpenLinkReference(cellValues?.row?.reference)}>
            Link
          </span>
        </>
      );
    },
  },
  {
    field: "unit_cost",
    headerName: "Cost",
    cellClassName: "super-app-theme--cell",
    sortable: false,
    minWidth: 90,
    flex: 1,
    align: 'right',
    renderCell : (cellValues: any) => {      
      const unitCost = Number(cellValues?.row?.unit_cost?.split(" ")[1]).toFixed(3);
      return (
        <>
        ₹ {unitCost}
        </>
      )
    }
  },


];

const HornModules: any = [
  { field: "sno", headerName: "S.No", width: 60 },
  {
    field: "name",
    headerName: "Item Name",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "type",
    headerName: "Type",
    minWidth: 120,
    flex: 1,
  },
  {
    field: "rated_voltage",
    headerName: "Rated Voltage",
    type: "number",
    minWidth: 120,
    flex: 1,
  },
  {
    field: "current_rating",
    headerName: "Current Rating",
    type: "number",
    minWidth: 120,
    flex: 1,
  },
  {
    field: "power_rating",
    headerName: "Power Rating",
    type: "number",
    minWidth: 100,
    flex: 1,
  },
  {
    field: "frequency",
    headerName: "Frequency",
    type: "number",
    minWidth: 100,
    flex: 1,
  },
  {
    field: "sound_level",
    headerName: "Sound Level",
    type: "number",
    minWidth: 100,
    flex: 1,
  },



  {
    field: "reference",
    headerName: "Reference",
    sortable: false,
    minWidth: 100,
    flex: 1,
    renderCell: (cellValues: any) => {
      return (
        <>
          <span
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => handleOpenLinkReference(cellValues?.row?.reference)}>
            Link
          </span>
        </>
      );
    },
  },
  {
    field: "unit_cost",
    headerName: "Cost",
    cellClassName: "super-app-theme--cell",
    sortable: false,
    minWidth: 90,
    flex: 1,
    align: 'right',
    renderCell : (cellValues: any) => {
      
      const unitCost = Number(cellValues?.row?.unit_cost?.split(" ")[1]).toFixed(3);
      return (
        <>
        ₹ {unitCost}
        </>
      )
    }
  },


];

const FlasherModules: any = [
  { field: "sno", headerName: "S.No", width: 60 },
  {
    field: "name",
    headerName: "Item Name",
    minWidth: 140,
    flex: 1,
  },
  {
    field: "type",
    headerName: "Type",
    minWidth: 120,
    flex: 1,
  },
  {
    field: "rated_voltage",
    headerName: "Rated Voltage",
    type: "number",
    minWidth: 120,
    flex: 1,
  },
  {
    field: "frequency",
    headerName: "Flasher Frequency",
    type: "number",
    minWidth: 120,
    flex: 1,
  },
  {
    field: "no_of_terminal",
    headerName: "No. of Terminals",
    type: "number",
    minWidth: 100,
    flex: 1,
  },
  {
    field: "watttage",
    headerName: "Wattage",
    type: "number",
    minWidth: 120,
    flex: 1,
  },
  {
    field: "adjustable",
    headerName: "Adjustable (Y/N)",
    type: "number",
    minWidth: 120,
    flex: 1,
  },



  {
    field: "reference",
    headerName: "Reference",

    sortable: false,
    minWidth: 100,
    flex: 1,
    renderCell: (cellValues: any) => {
      return (
        <>
          <span
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => handleOpenLinkReference(cellValues?.row?.reference)}>
            Link
          </span>
        </>
      );
    },
  },
  {
    field: "unit_cost",
    headerName: "Cost",
    cellClassName: "super-app-theme--cell",
    sortable: false,
    minWidth: 100,
    flex: 1,
    align: 'right',
    renderCell : (cellValues: any) => {
      
      const unitCost = Number(cellValues?.row?.unit_cost?.split(" ")[1]).toFixed(3);
      return (
        <>
        ₹ {unitCost}
        </>
      )
    }
  },


];

const IgnitionMOdules: any = [
  { field: "sno", headerName: "S.No", width: 60 },
  {
    field: "name",
    headerName: "Item Name",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "type",
    headerName: "Type",
    minWidth: 130,
    flex: 1,
  },
  {
    field: "rated_voltage",
    headerName: "Rated Voltage",
    type: "number",
    minWidth: 120,
    flex: 1,
  },
  {
    field: "no_of_position",
    headerName: "No. Of Positions",
    type: "number",
    minWidth: 120,
    flex: 1,
  },
  {
    field: "no_of_terminal",
    headerName: "No. of Terminals",
    type: "number",
    minWidth: 130,
    flex: 1,
  },
  {
    field: "with_handle_lock",
    headerName: "With handle lock (Y/N)",
    type: "number",
    minWidth: 160,
    flex: 1,
  },
  // {
  //   field: "watttage",
  //   headerName: "Adjustable (Y/N)",
  //   type: "number",
  //   minWidth: 120,
  // },



  {
    field: "reference",
    headerName: "Reference",
    sortable: false,
    minWidth: 100,
    flex: 1,
    renderCell: (cellValues: any) => {
      return (
        <>
          <span
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => handleOpenLinkReference(cellValues?.row?.reference)}>
            Link
          </span>
        </>
      );
    },
  },
  {
    field: "unit_cost",
    headerName: "Cost",
    cellClassName: "super-app-theme--cell",
    sortable: false,
    minWidth: 90,
    flex: 1,
    align: 'right',
    renderCell : (cellValues: any) => {
      
      const unitCost = Number(cellValues?.row?.unit_cost?.split(" ")[1]).toFixed(3);
      return (
        <>
        ₹ {unitCost}
        </>
      )
    }
  },


];

const RemoteKeys: any = [
  { field: "sno", headerName: "S.No", width: 50 },
  {
    field: "name",
    headerName: "Item Name",
    minWidth: 140,
    flex: 1,
  },
  {
    field: "type",
    headerName: "Type",
    minWidth: 120,
    flex: 1,
  },
  {
    field: "sensing_technology",
    headerName: "Sensing Technology",
    minWidth: 150,
  },
  {
    field: "type_of_key_fob",
    headerName: "Type of Key Fob",
    // type: "number",
    minWidth: 120,
    flex: 1,
  },
  {
    field: "no_of_function",
    headerName: "No. of Functions",
    minWidth: 120,
  },
  {
    field: "function",
    headerName: "Functions",
    type: "number",
    minWidth: 120,
    flex: 1,
  },
  {
    field: "range",
    headerName: "Range",
    type: "number",
    minWidth: 120,
  },
  {
    field: "reference",
    headerName: "Reference",
    sortable: false,
    minWidth: 100,
    renderCell: (cellValues: any) => {
      return (
        <>
          <span
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => handleOpenLinkReference(cellValues?.row?.reference)}>
            Link
          </span>
        </>
      );
    },
  },
  {
    field: "unit_cost",
    headerName: "Cost",
    cellClassName: "super-app-theme--cell",
    sortable: false,
    minWidth: 90,
    align: 'right',
    renderCell : (cellValues: any) => {
      
      const unitCost = Number(cellValues?.row?.unit_cost?.split(" ")[1]).toFixed(3);
      return (
        <>
        ₹ {unitCost}
        </>
      )
    }
  },


];
export { BulbColumns, HornModules, FlasherModules, IgnitionMOdules, RemoteKeys }