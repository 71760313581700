import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  Modal,
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepIconProps,
  Grid,
  Paper,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CircleIcon from "@mui/icons-material/Circle";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import VerificationStep1 from "./VerificationStep1";
import VerificationStep4 from "./VerificationStep4";
import VerificationStep2 from "./VerificationStep2";
import VerificationStep3 from "./VerificationStep3";
import VerificationStep5 from "./VerificationStep5";
import VerificationStep6 from "./VerificationStep6";
import Swal from "sweetalert2";
import { API } from "../../api-services";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

// const steps = [
//   "Business Information",
//   "Ownership Information",
//   "Business Registration Details",
//   "Proof of Ownership Documents",
//   "Tax Information",
//   "Contact and Additional Information",
// ];

const steps = [
  "Business Information",
  "Other Information",
  "Documents",

];

const StepIconRoot = styled("div")<{
  ownerState: { active?: boolean; completed?: boolean };
}>(({ theme, ownerState }) => ({
  color: ownerState.active
    ? theme.palette.primary.main
    : theme.palette.grey[400],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& .completed": {
    color: theme.palette.success.main,
  },
  "& .active": {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: "50%",
  },
}));

const StepIcon: React.FC<StepIconProps> = (props) => {
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <RadioButtonUncheckedIcon />,
    2: <RadioButtonUncheckedIcon />,
    3: <RadioButtonUncheckedIcon />,
    4: <RadioButtonUncheckedIcon />,
    5: <RadioButtonUncheckedIcon />,
    6: <RadioButtonUncheckedIcon />,
  };

  return (
    <StepIconRoot ownerState={{ active, completed }}>
      {completed ? (
        <CheckCircleIcon className="completed" />
      ) : active ? (
        <CircleIcon className="active" />
      ) : (
        icons[String(props.icon)]
      )}
    </StepIconRoot>
  );
};

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.success.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.grey[400],
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const SupplierAddRequestModal: React.FC<{
  isOpen: boolean;
  onCloseModal: () => void;
  allData: any;
  getAllClaimBusinessReq: any;
}> = ({ isOpen, onCloseModal, allData, getAllClaimBusinessReq }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [prefetchCount, setPrefetchCount] = useState<any>(null);
  const [completed, setCompleted] = useState<{ [k: number]: boolean }>({});
  const [counts, setCounts] = useState({
    v1: 0,
    v2: 0,
    v3: 0,

  });
  const [total, setTotal] = useState({
    v1: 16,
    v2: 7,
    v3: 3,

  });
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };
  const handleSubmit = () => {
    API.post(
      `/cart/get_all_claim_requests/`,
      {
        id: allData?.id,
        approve: true,
      },
      {},
      0
    )
      .then((res: any) => {
        getAllClaimBusinessReq();
        Swal.fire({
          icon: "success",
          title: "Your Request has been Approved Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        onCloseModal();
      })
      .catch((err: any) => {
        const { data } = err.response;
        if (err && err.response && data) {
          Swal.fire({
            icon: "error",
            html: `<div>
<br />
<p style="color:"red">${data[0]}</p>   
</div>`,
          });
        }
      });
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <VerificationStep1
            request_type={allData && allData?.request_type}
            prefetchedDataId={allData && allData?.id}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            handleNext={handleNext}
            steps={steps}
            handleBack={handleBack}
            counts={counts}
            setCounts={setCounts}
          />
        );
      case 1:
        return (
     

          <VerificationStep2
          request_type={allData && allData?.request_type}
            prefetchedDataId={allData && allData?.id}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            handleNext={handleNext}
            steps={steps}
            handleBack={handleBack}
            counts={counts}
            setCounts={setCounts}  
          />
        );
      case 2:
        return (
          <VerificationStep3
            request_type={allData && allData?.request_type}
            prefetchedDataId={allData && allData?.id}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            handleNext={handleNext}
            steps={steps}
            handleBack={handleBack}
            counts={counts}
            setCounts={setCounts}
            onCloseModal={onCloseModal}
            getAllClaimBusinessReq={getAllClaimBusinessReq}
          />
        );
      // case 3:
      //   return (
      //     <VerificationStep4
      //       request_type={allData && allData?.request_type}
      //       prefetchedDataId={allData && allData?.id}
      //       activeStep={activeStep}
      //       setActiveStep={setActiveStep}
      //       handleNext={handleNext}
      //       steps={steps}
      //       handleBack={handleBack}
      //       counts={counts}
      //       setCounts={setCounts}
      //     />
      //   );
      // case 4:
      //   return (
      //     <VerificationStep5
      //       request_type={allData && allData?.request_type}
      //       prefetchedDataId={allData && allData?.id}
      //       activeStep={activeStep}
      //       setActiveStep={setActiveStep}
      //       handleNext={handleNext}
      //       steps={steps}
      //       handleBack={handleBack}
      //       counts={counts}
      //       setCounts={setCounts}
      //     />
      //   );
      // case 5:
      //   return (
      //     <VerificationStep6
      //       request_type={allData && allData?.request_type}
      //       prefetchedDataId={allData && allData?.id}
      //       activeStep={activeStep}
      //       setActiveStep={setActiveStep}
      //       handleNext={handleNext}
      //       steps={steps}
      //       handleBack={handleBack}
      //       counts={counts}
      //       setCounts={setCounts}
      //       onCloseModal={onCloseModal}
      //       getAllClaimBusinessReq={getAllClaimBusinessReq}
      //     />
      //   );
      default:
        return <Box>Invalid Selection</Box>;
    }
  };

  const countsArray = Object.entries(counts).map(([key, value]) => value);
  const TotalArray = Object.entries(total).map(([key, value]) => value);

  // const verified1Count = Object.keys(verificationStatus).filter(
  //   (key) => verificationStatus[key].verified === true
  // )?.length;

  const getPreFetchedData = () => {
    API.get("/cart/get_all_claim_requests/", { id: allData?.id }, 0)
      .then((res: any) => {
        setPrefetchCount(res.data?.verification_status);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getPreFetchedData();
  }, [allData?.id]);

  const countTrueValues = (arr: any) => {
    if (!prefetchCount) return 0; // Return 0 if prefetchCount is null or undefined

    let count = 0;
    arr.forEach((field: any) => {
      if (prefetchCount[field]) {
        count++;
      }
    });
    return count;
  };

  useEffect(() => {
    // Calculate counts for each group and update state
    const countV1 = countTrueValues([
      "business_name",
      "business_email",
      "business_phone_no",
      "business_address",
      "business_website",
      "annual_revenue",
      "year_of_establishment",
      "legal_status_of_business",
      "business_registration_no",
      "executives",
      "about_business",
    ]);

    const countV2 = countTrueValues([
      "cin_certificate",
      "gst_certificate",
      "enterprise_type",
      "pan_document",
      "tan_certificate",
      "udyam_certificate",
      "quality_certificate",
      "iec_certificate",
    ]);
    const countV3 = countTrueValues([
      "annual_report",
      "profit_loss_sheet",
      "balance_sheet",
    ]);
    // const countV4 = countTrueValues([
    //   "client_information",

    // ]);
    // const countV5 = countTrueValues([
    //   "awards_recognition_docs",
    //   "product_catalogue",
    //   "company_brochure",
    //   "additional_information",
    // ]);
    // const countV6 = countTrueValues(["spoc_information"]);

    setCounts({
      v1: countV1,
      v2: countV2,
      v3: countV3,
      // v4: countV4,
      // v5: countV5,
      // v6: countV6,
    });
  }, [prefetchCount]);

  
  
  return (
    <div>
      <Modal
        open={isOpen}

        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style}>
          {/* <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center", mb: 3 }}
          >
            Verify & Approve Add and Claim Business Request
          </Typography> */}
        

          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ textAlign: "center", mb: 3 , width:"100%"}}
            >
              Verify & Approve Add and Claim Business Request
            </Typography>

            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", marginTop:"-2rem" }}
              onClick={onCloseModal}
            />
          </Box>

          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: { optional?: React.ReactNode } = {};
              return (
                <Step key={label} {...stepProps} >
                  <StepLabel {...labelProps} onClick={()=>setActiveStep(index)} sx={{cursor:"pointer"}}>
                    <div>{label}</div>
                    <div style={{ cursor:"pointer",fontSize: "1.3rem", marginTop: "0.5rem", background: index === activeStep ? "#d5e8db" : "#e6e6e6", padding:"0.5rem 0.2rem", borderRadius:"0.75rem" }}>
                      {countsArray[index]} / {TotalArray?.[index]}
                    </div>
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Paper
            elevation={3}
            sx={{ mt: 3, p: 2, borderRadius: 2, maxHeight: "80vh" }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            {getStepContent(activeStep)}
            {/* <Grid
              container
              justifyContent="flex-end"
              spacing={2}
              sx={{ mt: 2 }}
            >
              <Grid item>
                <Button disabled={activeStep === 0} onClick={handleBack}>
                  Back
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={
                    activeStep === steps.length - 1
                      ? handleSubmit
                      : handleComplete
                  }
                >
                  {activeStep === steps.length - 1 ? "Approve" : "Next"}
                </Button>
              </Grid>
              {activeStep === steps.length - 1 && (
                <Grid item>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "red",
                      color: "white",
                      "&:hover": { backgroundColor: "red" },
                    }}
                    onClick={
                      activeStep === steps.length - 1
                        ? onCloseModal
                        : handleComplete
                    }
                  >
                    {activeStep === steps.length - 1 && "Reject"}
                  </Button>
                </Grid>
              )}
            </Grid> */}
          </Paper>
        </Box>
      </Modal>
    </div>
  );
};

export default SupplierAddRequestModal;
