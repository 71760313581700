import React, { useEffect, useState } from "react";
import { API } from "../../api-services";
import PieChartIdea from "./PieChartIdea";
import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";

interface PieChartActivityAnalysisI {}

const PieChartActivityAnalysis: React.FC<PieChartActivityAnalysisI> = (
  props
) => {
  const [PieChartOneData, setPieChartOneData] = useState<any>([]);
  const [PieChartTwoData, setPieChartTwoData] = useState<any>([]);
  const [dataOneLoad, setDataOneLoad] = useState<boolean>(true);
  const [dataTwoLoad, setDataTwoLoad] = useState<boolean>(true);
  const [pieChartOneLogType, setPieChartOneLogType] =
    useState<any>("Total Activity");
  const [pieChartTwoLogType, setPieChartTwoLogType] =
    useState<any>("No of Sessions");
  const [pieChartOneLogDuration, setPieChartOneLogDuration] =
    useState<any>("365");

  const pieChartValue:any = [{id:1,name:'Total Logins',value:"Total Logins"},{id:2,name:'Total Users',value:"Total Users"},{id:3,name:'Total Activity',value:"Total Activity"},{id:4,name:'No of Sessions',value:"No of Sessions"},{id:5,name:'Total Time Spent',value:"Total Time Spent"}]

  const handleChangeOneLogType = (event: SelectChangeEvent) => {
    setPieChartOneLogType(event.target.value as string);
    setDataOneLoad(true);
    API.get(
      `/cost/user_activity_graph/`,
      {
        log_type: event.target.value,
        duration: pieChartOneLogDuration,
      },
      0
    )
      .then((res: any) => {
        setPieChartOneData(res?.data);
        setDataOneLoad(false);
      })
      .catch((err) => {
        console.log(err, "Server Error");
        setDataOneLoad(false);
      });
  };

  const handleChangeTwoLogType = (event: SelectChangeEvent) => {
    setPieChartTwoLogType(event.target.value as string);
    setDataTwoLoad(true);
    API.get(
      `/cost/user_activity_graph/`,
      {
        log_type: event.target.value,
        duration: pieChartOneLogDuration,
      },
      0
    )
      .then((res: any) => {
        setPieChartTwoData(res?.data);
        setDataTwoLoad(false);
      })
      .catch((err) => {
        console.log(err, "Server Error");
        setDataTwoLoad(false);
      });
  };

  const handleChangeOneLogDuration = (event: SelectChangeEvent) => {
    setPieChartOneLogDuration(event.target.value as string);
    setDataOneLoad(true);
    setDataTwoLoad(true);
    API.get(
      `/cost/user_activity_graph/`,
      {
        log_type: pieChartOneLogType,
        duration: event.target.value,
      },
      0
    )
      .then((res: any) => {
        setPieChartOneData(res?.data);
        setDataOneLoad(false);
      })
      .catch((err) => {
        console.log(err, "Server Error");
        setDataOneLoad(false);
      });
      API.get(
        `/cost/user_activity_graph/`,
        {
          log_type: pieChartTwoLogType,
          duration: event.target.value,
        },
        0
      )
        .then((res: any) => {
          setPieChartTwoData(res?.data);
          setDataTwoLoad(false);
        })
        .catch((err) => {
          console.log(err, "Server Error");
          setDataTwoLoad(false);
        });
  };

  useEffect(() => {
    setDataOneLoad(true);
    setDataTwoLoad(true);
    API.get(
      `/cost/user_activity_graph/`,
      {
        log_type: "Total Activity",
        duration: "365",
      },
      0
    )
      .then((res: any) => {
        setDataOneLoad(false);
        setPieChartOneData(res?.data);
      })
      .catch((err) => {
        console.log(err, "Server Error");
        setDataOneLoad(false);
      });
    API.get(
      `/cost/user_activity_graph/`,
      {
        log_type: "No of Sessions",
        duration: "365",
      },
      0
    )
      .then((res: any) => {
        setDataTwoLoad(false);
        setPieChartTwoData(res?.data);
      })
      .catch((err) => {
        console.log(err, "Server Error");
        setDataTwoLoad(false);
      });
  }, []);

  return (
    <>
      <div style={{display:'flex',flexDirection:'row',justifyContent:'center',marginLeft:'1.5rem'}}>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 80 }}>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={pieChartOneLogDuration}
            onChange={handleChangeOneLogDuration}
            sx={{ fontSize: "1rem" }}
          >
            <MenuItem sx={{ fontSize: "1rem" }} value={"1"}>
              Today
            </MenuItem>
            <MenuItem sx={{ fontSize: "1rem" }} value={"7"}>
              7 Days
            </MenuItem>
            <MenuItem sx={{ fontSize: "1rem" }} value={"30"}>
              30 Days
            </MenuItem>
            <MenuItem sx={{ fontSize: "1rem" }} value={"365"}>
              365 Days
            </MenuItem>
            <MenuItem sx={{ fontSize: "1rem" }} value={"all"}>
              All Data
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <Box sx={{ textAlign:'center'}}>
          {!dataOneLoad ? (
            <PieChartIdea pieChart={PieChartOneData} />
          ) : (
            <Box sx={{height:{lg:"13rem",xl:'15rem'}}}>
            <Skeleton
              variant="circular"             
              sx={{ margin: "1.1rem auto",marginLeft:{lg:'4rem',xl:'6rem'},width:{lg:'13rem',xl:"15rem"},height:{lg:'13rem',xl:"15rem"} }}
            /></Box>
          )}
          <div style={{justifyContent:'center',}}>
            <div>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120,paddingRight:'6rem' }}>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={pieChartOneLogType}
                  onChange={handleChangeOneLogType}
                  sx={{ fontSize: "1rem" }}
                >
                  {pieChartValue?.filter((item:any)=>{return item?.value!=pieChartTwoLogType})?.map((item:any)=>{
                    return (<MenuItem sx={{ fontSize: "1rem" }} value={`${item?.value}`}>
                        {`${item?.name}`}
                      </MenuItem>)
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
        </Box>
        <Box sx={{ textAlign:'center'}}>
          {!dataTwoLoad ? (
            <PieChartIdea pieChart={PieChartTwoData} />
          ) : (
            <Box sx={{height:{lg:"13rem",xl:'15rem'}}}>
              <Skeleton variant="circular" sx={{margin: "1.1rem auto",marginLeft:{lg:'4rem',xl:'6rem'},width:{lg:'13rem',xl:"15rem"},height:{lg:'13rem',xl:"15rem"}}}/>
            </Box>
          )}
          <div style={{justifyContent:'center',}}>
            <div>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120,paddingRight:'6rem' }}>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={pieChartTwoLogType}
                  onChange={handleChangeTwoLogType}
                  sx={{ fontSize: "1rem" }}
                >
                  {pieChartValue?.filter((item:any)=>{return item?.value!=pieChartOneLogType})?.map((item:any)=>{
                    return (<MenuItem sx={{ fontSize: "1rem" }} value={`${item?.value}`}>
                        {`${item?.name}`}
                      </MenuItem>)
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default PieChartActivityAnalysis;
