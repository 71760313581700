import { Box, IconButton } from '@mui/material';
import * as React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import LaunchIcon from '@mui/icons-material/Launch';
import FlagIcon from '@mui/icons-material/Flag';
import move from '../../../Assets/images/right move.svg';
import EqualizerIcon from '@mui/icons-material/Equalizer';

export interface IMachineHourRateActionComponentsProps {
	cellValues?: any;
	rowId?: any;
	InfoHandler?: any;
	DeleteHandler?: any;
	GraphHandler?: any;
	redirectionHanlder?: any;
	handleShareWith?: any;
	useraction?: any;
	setAnchorEl?: any;
}

export default function MachineHourRateActionComponents(
	props: IMachineHourRateActionComponentsProps
) {
	const { cellValues, rowId, InfoHandler, DeleteHandler, GraphHandler, redirectionHanlder, handleShareWith, useraction, setAnchorEl } = props;

	// console.log('rowId', rowId);


	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				width: '4rem',
			}}>
			{rowId.status ? (
				<IconButton
					title='Machine Info'
					sx={{ cursor: 'pointer', color: 'primary.main', padding: '0.5rem' }}
					onClick={() => InfoHandler(rowId)}>
					<InfoIcon />
				</IconButton>
			) : (
				(useraction &&
				useraction?.includes('D') && rowId?.lock_status !=true) && (
					<IconButton
						title='Delete'
						sx={{ cursor: 'pointer', color: 'primary.main', padding: '0.5rem',opacity:rowId?.lock_status?0.5:1 }}
						onClick={() => {if(rowId?.lock_status !=true){DeleteHandler(rowId)}}}>
						<DeleteIcon />
					</IconButton>
				)
			)}
			<IconButton
				title='Graph'
				sx={{ cursor: 'pointer', color: 'primary.main', padding: '0.5rem' }}
				onClick={() => GraphHandler(rowId)}>
				<EqualizerIcon />
			</IconButton>
			<IconButton
				title='Redirect'
				sx={{ cursor: 'pointer', color: 'primary.main', padding: '0.5rem' }}
				onClick={() => redirectionHanlder(rowId)}>
				<LaunchIcon />{' '}
			</IconButton>
			{(rowId.flag !== null && rowId?.lock_status !=true) && (
				<IconButton title='flag' sx={{ color: 'primary.main', padding: '0.5rem',opacity:rowId?.lock_status?0.5:1 }}>
					<FlagIcon
						onClick={() => {
							if(rowId?.lock_status !=true)
								{window.open(rowId?.flag, '_blank')}}}
						sx={{ cursor: 'pointer', color: 'green' }}
					/>
				</IconButton>
			)}

			{(useraction && useraction.includes('U') && rowId?.lock_status !=true) && (
				<IconButton
					title='Move'
					sx={{ color: 'primary.main', padding: '0.5rem', cursor: 'pointer',opacity:rowId?.lock_status?0.5:1  }}
					onClick={() => {if(rowId?.lock_status !=true){handleShareWith(rowId)}}}>					
					<img
						src={move}
						style={{ height: '1.7rem', cursor: 'pointer', padding: '0.2rem',opacity:rowId?.lock_status?0.5:1 }}
					/>
				</IconButton>
			)}
		</Box>
	);
}
