import * as React from "react";
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Skeleton,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory, useRouteMatch } from "react-router-dom";
import styles from "./FeatureConfigurationLanding.module.scss";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import ShareIcon from "@mui/icons-material/Share";
import FeatureConfiguration from "./FeatureConfiguration";
import CancelIcon from "@mui/icons-material/Cancel";
import { API } from "../../api-services";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import AddCategoryModal from "./AddCategoryModal";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import InfoIcon from "@mui/icons-material/Info";
import { useEffect, useState } from "react";
import ShareCategoryModal from "./ShareCategoryModal";
import CostingConfigurationDrawer from "../Costing/CostingConfigurationDrawer";
import ShareParameterModal from "./ShareParameterModal";
import ErrorModalFilesMail from "../../ComponentUI/ProjectMailModule/ErrorModalFilesMail";
import swal from "sweetalert";
import AddFeatureParameterModal from "./AddFeatureParameterModal";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import validated from "../../Assets/images/Validated.svg";
import notvalidated from "../../Assets/images/NotValidated.svg";
import { useDocumentTitle } from "../../ui-reusable-component/useDocumentTitle";

type Anchor = "top" | "left" | "bottom" | "right";
export const UserContextFeature = React.createContext<any>(null);
export interface IIdeaConfigurationLandingProps {}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export function FeatureConfigurationLanding(
  props: IIdeaConfigurationLandingProps
) {
  const history = useHistory();
  const {
    projectId,
    projectname,
    categoryId,
    productId,
    datatype,
    validationId,
    categoryname,
    mainId,
  } = useRouteParams<any>();
  const { url } = useRouteMatch();
  useDocumentTitle( "Feature Config" );
  const theme = useTheme();
  const ParameterModals = useBaseModal();
  const addcategoryUpop = useBaseModal();
  const sharepopup = useBaseModal();
  const ErrorModals = useBaseModal();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [categoryIds, setCategoryIds] = useState<any>(null);
  const [ErrorModalFileMsg, setErrorModalFileMsg] = useState<any>();

  const [validationdata, setValidationdata] = useState<any>();
  const [CategoryCount, setcategoryCount] = useState<any>(0);
  const ParametershareModals = useBaseModal();
  const [CategoryData, setcategoryData] = useState<any>([]);
  const [loader, setLoader] = useState<any>(false);
  const [Premisssionaction, setPremissionaction] = useState<any>(null);
  const [FeatureCounter, setFeatureCounter] = useState<any>(0);
  const [DownloadLoader, setDownloadLoader] = useState<any>(false);
  React.useEffect(() => {
    API.get(
      "/auth/user_permission_module/",
      {
        action: true,
        configuration: true,
        project: projectId,
        module: "Feature Configuration",
      },
      0
    )
      .then((res: any) => {
        setPremissionaction(res.data.action);
      })
      .catch((err: any) => {});
  }, [projectId]);

  // console.log(
  //   Premisssionaction && Premisssionaction?.includes("C"),
  //   "Premisssionaction"
  // );
  useEffect(() => {
    if (FeatureCounter > 0) {
      setLoader(true);
      API.get("/config/feature_config/", { module: 17, project: projectId })
        .then((res: any) => {
          setcategoryData(res?.data);
          setLoader(false);
        })
        .catch((err: any) => {
          setLoader(false);
          console.log("Srver Error");
        });
    } else {
      {
        setLoader(true);
        API.get("/config/feature_config/", { module: 17, project: projectId })
          .then((res: any) => {
            setcategoryData(res?.data);
            setLoader(false);
          })
          .catch((err: any) => {
            setLoader(false);
            console.log("Srver Error");
          });
      }
    }
  }, [projectId, CategoryCount, FeatureCounter]);
  const Parameterhandler = (categoryId?: any, sqe?: any) => {
    ParameterModals.open();
    setCategoryIds(categoryId);
  };
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      {/* <CostingConfigurationDrawer VehiclecategoryId={undefined} /> */}
    </Box>
  );

  const handleClick = () => {
    history.goBack();
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const ShareHandler = () => {
    sharepopup.open();
  };
  const CategoryHandler = (item: any) => {
    history.push(
      `/feature-configuration/${projectId}/${projectname}/${item?.id}/${item?.category}/${item?.name}/0/0`
    );
  };

  const handleChangeCheckBox = (type: any, Id: any) => {
    if (type == "check") {
      const checkboxvalue =
        CategoryData &&
        CategoryData?.filter((item: any, index: any) => {
          return item?.category == categoryId;
        })[0];
      API.patch("/config/feature_config/", {
        id: mainId,
        validated: false,
      })
        .then((res: any) => {
          setValidationdata(res?.data?.validated);
        })
        .catch((err: any) => {
          const { data } = err?.response;
          setValidationdata(false);
        });
    }
    if (type == "Uncheck") {
      const checkboxvalue =
        CategoryData &&
        CategoryData?.filter((item: any, index: any) => {
          return item?.category == categoryId;
        })[0];

      API.patch("/config/feature_config/", {
        id: mainId,
        validated: false,
      })
        .then((res: any) => {
          setValidationdata(res?.data?.validated);
          setcategoryCount((prev: any) => prev + 1);
        })
        .catch((err: any) => {
          const { data } = err?.response;
          setValidationdata(false);
        });
    }
    if (type == "main") {
      const checkboxvalue =
        CategoryData &&
        CategoryData?.filter((item: any, index: any) => {
          return item?.category == categoryId;
        })[0];
      API.patch("/config/feature_config/", {
        id: mainId,
        validated: !validationdata,
      })
        .then((res: any) => {
          setValidationdata(res?.data?.validated);
          setcategoryCount((prev: any) => prev + 1);
        })
        .catch((err: any) => {
          const { data } = err?.response;
          setValidationdata(false);

          Swal.fire({
            icon: "error",
            html: `<div>
      <br />
      <p style="color:red;">${data[0]}</p>   
       </div>`,
          });
        });
    }
  };
  const ParameterShareHandler = (id: any) => {
    ParametershareModals.open();
    setCategoryIds(id);
  };
  const Validationhandlerparameter = () => {
    if (validationdata && validationdata?.includes(categoryId)) {
      setValidationdata((prev: any) =>
        prev.filter((item: any) => item !== categoryId)
      );
    }
    API.patch("/config/feature_config/", {
      id: mainId,
      validated: false,
    })
      .then((res1: any) => {
        setcategoryCount((prev: any) => prev + 1);
      })
      .catch((err: any) => {});
  };

  useEffect(() => {
    const checkboxvalue =
      CategoryData &&
      CategoryData?.filter((item: any, index: any) => {
        return item?.category == categoryId;
      })[0];

    setValidationdata(checkboxvalue?.is_validated);
  }, [CategoryData, categoryId]);
  const CategoryDeleteHandler = (id: any) => {
    var checkedData =
      CategoryData &&
      CategoryData?.filter((item: any, indx: any) => {
        return item?.id !== id;
      });

    var collectIds =
      checkedData &&
      checkedData?.map((item: any, index: any) => {
        return item?.id;
      });

    // console.log(collectIds, "collectIdscollectIds")
    swal({
      title: `Delete Parameter`,
      text: `Are you sure?`,
      icon: "warning",

      buttons: ["Cancel", "Ok"],
    })
      .then((confirm) => {
        if (confirm) {
          API.delete(`/config/feature_config/`, { id: id }).then((res: any) => {
            API.get("/config/feature_config/", {
              module: 17,
              project: projectId,
            })
              .then((res: any) => {
                setcategoryData(res?.data);
              })
              .catch((err: any) => {
                console.log("Srver Error");
              });
          });
        }
      })
      .catch((e: any) => {});
  };

  const downloadhandler = () => {
    setDownloadLoader(true);
    API.get(
      "/config/parameter_mapping_download/",
      { project: projectId, module: 17 },
      0
    )
      .then((res: any) => {
        window.open(res?.data);
        setDownloadLoader(false);
      })
      .catch((err: any) => {
        setDownloadLoader(false);
        console.log("Sever Error");
      });
  };
  return (
    <div>
      {addcategoryUpop.isOpen && (
        <AddCategoryModal
          projectId={projectId}
          isOpen={addcategoryUpop.isOpen}
          onCloseModal={addcategoryUpop.close}
          selectedData={CategoryData}
          setcategoryCount={setcategoryCount}
        />
      )}

      {ParametershareModals.isOpen && (
        <ShareParameterModal
          isOpen={ParametershareModals.isOpen}
          ClosedHandler={ParametershareModals.close}
          CotegoryIds={categoryIds}
          CategoryDatalist={CategoryData}
          projectIds={projectId}
          setcategoryCount={setcategoryCount}
          setcategoryData={setcategoryData}
        />
      )}
      {ParameterModals.isOpen && (
        <AddFeatureParameterModal
          setcategoryCount={setcategoryCount}
          isOpen={ParameterModals.isOpen}
          onCloseModal={ParameterModals.close}
          parameterValidation={handleChangeCheckBox}
        />
      )}
      {ErrorModals.isOpen && (
        <ErrorModalFilesMail
          isOpen={ErrorModals.isOpen}
          onCloseModal={ErrorModals.close}
          ErrorMsg={ErrorModalFileMsg}
        />
      )}
      {sharepopup.isOpen && (
        <ShareCategoryModal
          isOpen={sharepopup.isOpen}
          ClosedHandler={sharepopup.close}
          CategoryDatalist={CategoryData}
          projectIds={projectId}
        />
      )}

      <Box sx={{ margin: "0 0.5rem" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: "1rem" }}
          >
            <IconButton
              sx={{ cursor: "pointer" }}
              title="Go Back"
              onClick={handleClick}
            >
              <ArrowBackIcon color="primary" titleAccess="Go Back" />
            </IconButton>
            <Typography
              sx={{
                fontSize: "1rem",
                whiteSpace: "nowrap",
              }}
            >
              {projectname}-Feature Configuration-Parameter Selection
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "1rem",
              paddingRight: "1rem",
            }}
          >
            {Premisssionaction && Premisssionaction?.includes("C") ? (
              <PlaylistAddIcon
                titleAccess="Add Feature Parameter"
                sx={{ cursor: "pointer", fontSize: "1.8rem" }}
                onClick={() => Parameterhandler()}
              />
            ) : (
              <PlaylistAddIcon sx={{ opacity: "0.3", fontSize: "1.8rem" }} />
            )}
            {Premisssionaction && Premisssionaction?.includes("C") ? (
              <ShareIcon
                titleAccess="Share Feature"
                onClick={() => ShareHandler()}
                sx={{ fontSize: "1.5rem", cursor: "pointer" }}
              />
            ) : (
              <ShareIcon sx={{ opacity: 0.3, fontSize: "1.5rem" }} />
            )}
            <LoadingButton
              onClick={() => downloadhandler()}
              title="Download"
              sx={{
                minWidth: "1rem",
                cursor: "pointer",
                padding: "0.1rem 0.5rem",
                ".MuiCircularProgress-root": {
                  color: "primary.main",
                },
              }}
              loading={DownloadLoader}
              startIcon={
                <FileDownloadIcon
                  sx={{ marginRight: "-1rem", fontSize: "1.7rem !important" }}
                />
              }
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}
                >
                  <Box
                    sx={{ padding: "1rem 0rem", paddingBottom: "0" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                  >
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}
                    >
                      Select Configuration Module
                    </Typography>
                    <Divider sx={{ margin: "0.5rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  style={{
                    transform: "rotate(270deg)",
                    whiteSpace: "nowrap",
                    marginTop: "40vh",
                    cursor: "pointer",
                    fontSize: "1rem",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  Select Configuration Module
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        <Box
          sx={{
            width: "97%",
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
              paddingRight: "1rem",
            }}
          >
            <Box sx={{borderRight:'1px solid',borderColor:'primary.light'}}>
              <Box sx={{ width: "100%", padding: "0 1rem" }}>
                {Premisssionaction && Premisssionaction?.includes("C") ? (
                  <Button
                    size="small"
                    variant="contained"
                    startIcon={<AddIcon sx={{ marginRight: "-0.5rem" }} />}
                    sx={{
                      width: "100%",
                      cursor: "pointer",
                      borderRadius: "3rem",
                    }}
                    onClick={() => addcategoryUpop.open()}
                  >
                    Add Category
                  </Button>
                ) : (
                  <Button
                    size="small"
                    variant="contained"
                    disabled={true}
                    startIcon={<AddIcon sx={{ marginRight: "-0.5rem" }} />}
                    sx={{ width: "100%", borderRadius: "3rem" }}
                  >
                    Add Category
                  </Button>
                )}
              </Box>
              <Box
                sx={{
                  height: { lg: "82vh", xl: "86vh" },
                  width: { lg: "15vw", xl: "15vw" },
                  padding: "0.5rem",
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "column",
                  rowGap: "1rem",
                }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3"
              >
                {loader ? (
                  <Box sx={{ width: "100%" }}>
                    <Skeleton
                      sx={{ marginBottom: "1rem" }}
                      variant="rounded"
                      width="100%"
                      height="6rem"
                    ></Skeleton>
                    <Skeleton
                      sx={{ marginBottom: "1rem" }}
                      variant="rounded"
                      width="100%"
                      height="6rem"
                    ></Skeleton>
                    <Skeleton
                      sx={{ marginBottom: "1rem" }}
                      variant="rounded"
                      width="100%"
                      height="6rem"
                    ></Skeleton>
                    <Skeleton
                      sx={{ marginBottom: "1rem" }}
                      variant="rounded"
                      width="100%"
                      height="6rem"
                    ></Skeleton>
                    <Skeleton
                      sx={{ marginBottom: "1rem" }}
                      variant="rounded"
                      width="100%"
                      height="6rem"
                    ></Skeleton>
                    <Skeleton
                      sx={{ marginBottom: "1rem" }}
                      variant="rounded"
                      width="100%"
                      height="6rem"
                    ></Skeleton>
                    <Skeleton
                      sx={{ marginBottom: "1rem" }}
                      variant="rounded"
                      width="100%"
                      height="6rem"
                    ></Skeleton>
                    <Skeleton
                      sx={{ marginBottom: "1rem" }}
                      variant="rounded"
                      width="100%"
                      height="6rem"
                    ></Skeleton>
                    <Skeleton
                      sx={{ marginBottom: "1rem" }}
                      variant="rounded"
                      width="100%"
                      height="6rem"
                    ></Skeleton>
                    <Skeleton
                      sx={{ marginBottom: "1rem" }}
                      variant="rounded"
                      width="100%"
                      height="6rem"
                    ></Skeleton>
                    <Skeleton
                      sx={{ marginBottom: "1rem" }}
                      variant="rounded"
                      width="100%"
                      height="6rem"
                    ></Skeleton>
                  </Box>
                ) : (
                  <>
                    <>
                      {CategoryData &&
                        CategoryData?.map((item: any, index: any) => {
                          return (
                            <>
                              <Box
                                onClick={() => CategoryHandler(item)}
                                sx={{
                                  boxShadow:
                                    "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
                                  borderRadius: "10px",
                                  cursor: "pointer",
                                  width: "100%",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "start",
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "10px",
                                    // border: item?.is_validated
                                    //   ? "2px solid #47fd47"
                                    //   : "2px solid yellow",
                                    backgroundColor:
                                      item?.category == categoryId
                                        ? "primary.light"
                                        : "white",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: "100%",
                                      display: "flex",
                                      flexDirection: "column",
                                      margin: "0.5rem 0rem 0rem 0.5rem",
                                    }}
                                  >
                                    <Typography sx={{ fontSize: "1rem" }}>
                                      {item?.name}
                                    </Typography>
                                    {item?.is_leaf ? (
                                      <Typography sx={{ fontSize: "1rem" }}>
                                        Leaf
                                      </Typography>
                                    ) : (
                                      <Typography sx={{ fontSize: "1rem" }}>
                                        Non Leaf
                                      </Typography>
                                    )}
                                    <Typography sx={{ fontSize: "1rem" }}>
                                      ({item?.parameter_count}) Parameters
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      width: "2rem",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "space-between",
                                      height: "5.5rem",
                                      alignItems: "center",
                                      padding: "3px",
                                    }}
                                  >
                                    <img
                                      src={
                                        item?.is_validated
                                          ? validated
                                          : notvalidated
                                      }
                                      style={{ width: "1.5rem" }}
                                    />

                                    {item?.is_validated && (
                                      <>
                                        {Premisssionaction &&
                                        Premisssionaction?.includes("C") ? (
                                          <ShareIcon
                                            sx={{ fontSize: "1.5rem" }}
                                            onClick={() =>
                                              ParameterShareHandler(item?.id)
                                            }
                                          />
                                        ) : (
                                          <ShareIcon
                                            sx={{
                                              fontSize: "1.5rem",
                                              opacity: "0.3",
                                            }}
                                          />
                                        )}
                                      </>
                                    )}
                                    {item?.parameter_count == 0 && (
                                      <>
                                        {Premisssionaction &&
                                        Premisssionaction?.includes("D") ? (
                                          <DeleteIcon
                                            sx={{
                                              fontSize: "1.5rem",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              CategoryDeleteHandler(item.id)
                                            }
                                          />
                                        ) : (
                                          <DeleteIcon
                                            sx={{
                                              fontSize: "1.5rem",
                                              opacity: "0.3",
                                            }}
                                          />
                                        )}
                                      </>
                                    )}
                                  </Box>
                                </Box>
                              </Box>
                            </>
                          );
                        })}
                    </>
                  </>
                )}
              </Box>
            </Box>

            <Box sx={{ width: "82vw" }}>
             
              <UserContextFeature.Provider value={Premisssionaction}>
                <FeatureConfiguration
                  projectId={projectId}
                  CategoryCount={CategoryCount}
                  handleChangeCheckBox={handleChangeCheckBox}
                  setParentsCounter={setFeatureCounter}
                  CategoryDatalist={CategoryData}
                  setcategoryCount={setcategoryCount}
                />
              </UserContextFeature.Provider>
            </Box>
          </Box>
          {/* )} */}
        </Box>
      </Box>
    </div>
  );
}
