"use client";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DescriptionIcon from "@mui/icons-material/Description";
import * as React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import OpenInNew from "@mui/icons-material/OpenInNew";
import ReplyIcon from '@mui/icons-material/Reply';
// import CDN_URL from "@/app/utils/CDN_URL";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import useDebounce from "./useDebounce";
import HistoryIcon from "@mui/icons-material/History";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import CDN_URL from "../../utlis/CDN_URL";
import PriceTableViewModal from "./PriceTableViewModal";
import { API } from "../../api-services";

import CreatePriceMapTable from "./CreatePriceMapTable";
// import PriceTableViewModal from "@/app/utils/PriceTableViewModal";

export interface ISupplierPlocationProps {
  getSupplierDetailsByID: any;
  supplierDetails: any;
  show_status?: any;
  setPartCount?: any;
  partCount?: any;
  value?: any;
}

export default function OwnPartList(props: ISupplierPlocationProps) {
  const { show_status, getSupplierDetailsByID, supplierDetails,  value} = props;
  const editPriceTable = useBaseParamsModal();
  const { supplier } = useRouteParams<any>();
  const documentRef = React.useRef<any>(null);
  const contactEditModal = useBaseParamsModal();
  const [counter, serCounter] = React.useState<any>(0);
  const [partId, setPartId] = React.useState<any>(null);

  const [partImages, setPartImages] = React.useState<any>([]);
  const Addpartmodal = useBaseModal();
  const Editpartmodal = useBaseModal();
  const editContactInfo = useBaseModal();
  const ShowpartImagesmodal = useBaseModal();
  const editSupplierProfileImage = useBaseParamsModal();
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [selectedTab, setSelectedTab] = React.useState<string>("all");
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [partList, setPartList] = React.useState<any>([]);
  const [hasMore, setHasMore] = React.useState<any>(true);
  const [certificates, setCertificates] = React.useState<any>([]);
  const debouncedVal = useDebounce(searchTerm);
  const [page, setPage] = React.useState<number>(1);
  const [Counter, setCounter] = React.useState<any>(0);
  // const [expandedItems, setExpandedItems] = React.useState<{
  //   [key: number]: boolean;
  // }>({});

  const handleTabChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm("");

    setSelectedTab(event.target.value);

    setPage(1);
    setHasMore(true);
  };

  const removeHtmlTags = (htmlString: string): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  };

  const errorModal = useBaseModal();
  const editClaimbusiness = useBaseModal();
  const certificateModal = useBaseModal();
  const addSupplierDocument = useBaseModal();
  const claimBusinessModal = useBaseModal();
  const SupplierInfoView = useBaseModal();
  const costView = useBaseParamsModal();

  // const getSupplierDetailsByID = () => {
  //   API.get(`supplier/supplier/${supplier_id}/`, {}, 0)
  //     .then((res: any) => {
  //       setSupplierDetails(res.data?.data);
  //       setIsPinned(res.data.is_pinned);
  //       if (AUTH && AUTH.token && AUTH.token?.length > 0) {
  //         // @ts-ignore
  //         dispatch(getUserProfilePic(2));
  //       }
  //     })
  //     .catch((err: any) => {
  //       if (err?.response?.data) {
  //         if (err?.response?.data) {
  //           setError(err?.response?.data[0]);
  //           // errorModal.open();
  //         }

  //         // Swal.fire({
  //         //  icon: 'error',
  //         //  html: `<div>
  //         //    <br />
  //         //    <p style="color:"red">${err?.response?.data[0]}</p>
  //         //     </div>`,
  //         // });
  //       }
  //     });
  // };

  const truncateString = (str: any, maxLength: any) => {
    if (str?.length <= maxLength) {
      return str; // Return the full string if it's less than or equal to maxLength
    } else {
      return str?.slice(0, maxLength - 3) + "..."; // Truncate and add "..." for longer strings
    }
  };

  const handleMouseEnter = (event: any, text: any) => {
    event.target.title = text; // Set the title attribute to the full text
  };

  const columns: GridColDef[] = [
    {
      field: "Id",
      headerName: "Sl No",
      headerClassName: "super-app-theme--header",
      width: 90,
    },
    {
      field: "name",
      headerName: "Part Name",
      headerClassName: "super-app-theme--header",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 250,
      renderCell: (cellValues: any) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "0.5rem",
              textDecoration:
                cellValues?.row?.status == "Accepted" ? "underline" : "none",
            }}
            onClick={() => {
              if (cellValues?.row?.status == "Accepted") {
                window.open(
                  `/partdetails/${cellValues?.row?.route_name}/${cellValues?.row?.vault_id}/?type=${cellValues?.row?.name}&part_id=${cellValues?.row?.vault_id}`
                );
              }
            }}
          >
            {<span style={{ cursor: "pointer" }}>{cellValues?.row?.name}</span>}
          </Box>
        );
      },
    },
    {
      field: "price_per_quantity",
      headerName: "Price Per Unit",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 150,
      renderCell: (cellValues: any) => {
        return (
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}
          >
            {
              <span style={{ cursor: "pointer" }}>
                {cellValues?.row?.currency?.symbol}
                {cellValues?.row?.single_quantity_price}
              </span>
            }
          </Box>
        );
      },
    },
    // {
    //   field: "rejection_reason",
    //   headerName: "Rejected Comment",
    //   headerClassName: "super-app-theme--header",
    //   sortable: false,
    //   width: 150,
    //   renderCell: (cellValues: any) => {
    //     return (
    //       <Box
    //         sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}
    //       >
    //         {
    //           <span
    //             style={{ cursor: "pointer" }}
    //             onMouseEnter={(e: any) =>
    //               handleMouseEnter(e, cellValues?.row?.rejection_reason)
    //             }
    //           >
    //             {truncateString(cellValues?.row?.rejection_reason, 85)}
    //           </span>
    //         }
    //       </Box>
    //     );
    //   },
    // },
    {
      field: "cost",
      headerName: "Pricing Details",
      headerClassName: "super-app-theme--header",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 180,
      renderCell: (cellValues: any) => {
        return (
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}
          >
           

            <VisibilityIcon
              titleAccess="Part Prices"
              sx={{ cursor: "pointer" }}
              style={{ color: "#569479" }}
              onClick={() => {
                costView.open(cellValues.row?.quantity_pricing);
              }}
            />
            <EditIcon
              titleAccess="Edit price"
              sx={{ cursor: "pointer" }}
              style={{ color: "#569479" }}
              onClick={() => {
                editPriceTable.open(cellValues.row);
              }}
            />
            {editPriceTable.isOpen && (
              <CreatePriceMapTable
              fetchPartsData={fetchPartsData}
                isOpen={editPriceTable.isOpen}
                onCloseModal={editPriceTable.close}
                partdetails={editPriceTable.propsId}
              />
            )}
          </Box>
        );
      },
    },

    {
      field: "drawing",
      headerName: "BOM URL",
      headerClassName: "super-app-theme--header",
      // description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 100,

      renderCell: (cellValues: any) => {
        
        return (
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}
          >
            <OpenInNew style={{color:"#00887a", cursor: "pointer",}} onClick={()=> {

                const url = `/#/bomentry/${cellValues?.row?.category?.id}/${cellValues?.row?.category?.name}/0/${cellValues?.row?.id}/${cellValues?.row?.subSystem || "none"}/0/false/false/false/false`;
                window.open(url, '_blank');
            }} />
            {/* <DescriptionIcon
              style={{
                color: "#569479",
                cursor: "pointer",
                opacity: cellValues?.row?.drawing?.[0]?.url ? 1 : 0.5,
                pointerEvents: cellValues?.row?.drawing?.[0]?.url
                  ? "auto"
                  : "none",
              }}
              onClick={() => {
                if (cellValues?.row?.drawing?.[0]?.url) {
                  window.open(
                    `${CDN_URL}${cellValues?.row?.drawing?.[0]?.url}`
                  );
                }
              }}
            /> */}
          </Box>
        );
      },
    },
    {
      field: "cad_document",
      headerName: "Costing URL",
      headerClassName: "super-app-theme--header",
      // description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 100,

      renderCell: (cellValues: any) => {
        return (
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}
          >
             <OpenInNew style={{color:"#00887a", cursor: "pointer",}} onClick={()=> {
                //const url = `/#/bomentry/${cellValues?.row?.category?.id}/${cellValues?.row?.category?.name}/0/${cellValues?.row?.id}/${cellValues?.row?.category?.subSystem || "none"}/0/false/false/false/false`;
                const url = `/#/createcostingpart/${cellValues?.row?.category?.id}/${cellValues?.row?.category?.name}/${cellValues?.row?.name}/0/${cellValues?.row?.id}/none/0/0/0/0/0/0/0/0/0`;
                window.open(url, '_blank');
            }} />
            {/* <DescriptionIcon
              style={{
                color: "#569479",
                cursor: "pointer",
                opacity: cellValues?.row?.cad_document?.[0]?.url ? 1 : 0.5,
                pointerEvents: cellValues?.row?.cad_document?.[0]?.url
                  ? "auto"
                  : "none",
              }}
              onClick={() => {
                if (cellValues?.row?.cad_document?.[0]?.url) {
                  window.open(
                    `${CDN_URL}${cellValues?.row?.cad_document?.[0]?.url}`
                  );
                }
              }}
            /> */}
          </Box>
        );
      },
    },

    {
      field: "weight",
      headerName: "Weight(g)",
      headerClassName: "super-app-theme--header",
      width: 80,
      editable: true,
    },

    {
      field: "main_costing_done",
      headerName: "Main Costing Validated",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 100,
      renderCell: (cellValues: any) => {
        return (
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}
          >
            {cellValues?.row?.main_costing_done ?
              <span style={{ cursor: "pointer" }}>
               
                Yes
              </span> : "No"
            }
          </Box>
        );
      },
      
    },

    // {
    //   field: "part_image",
    //   headerName: "Part Images",
    //   headerClassName: "super-app-theme--header",
    //   description: "This column has a value getter and is not sortable.",
    //   sortable: false,
    //   width: 100,
    //   renderCell: (cellValues: any) => {
    //     return (
    //       <Box
    //         sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}
    //       >
    //         <VisibilityIcon
    //           titleAccess="Part Images"
    //           sx={{ cursor: "pointer" }}
    //           style={{ color: "#569479" }}
    //           onClick={() => {
    //             ShowpartImagesmodal.open();
    //             setPartImages(cellValues?.row?.part_image);
    //           }}
    //         />
    //       </Box>
    //     );
    //   },
    // },
    // {
    //   field: "status",
    //   headerName: "Verification Status",
    //   headerClassName: "super-app-theme--header",
    //   description: "This column has a value getter and is not sortable.",
    //   sortable: false,
    //   width: 200,
    //   renderCell: (cellValues: any) => {
    //     return (
    //       <Box
    //         sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}
    //       >
    //         {
    //           <span
    //             style={{
    //               cursor: "pointer",
    //               // color: cellValues?.row?.status === "Not Started" ? 'secondary.main' :
    //               //        cellValues?.row?.status === "In Progress" ? "#ffffa3" :
    //               //        cellValues?.row?.status === "Accepted" ? "#99dfbb" :
    //               //        'secondary.main' // Default color if none of the conditions match
    //             }}
    //           >
    //             {cellValues?.row?.status}
    //           </span>
    //         }
    //       </Box>
    //     );
    //   },
    // },
    {
      field: "main_costing_amount",
      headerName: "Main Costing Amount",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 100,
      renderCell: (cellValues: any) => {
        return (
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}
          >
            {cellValues?.row?.main_costing_amount ?
              <span style={{ cursor: "pointer" }}>
                {cellValues?.row?.currency?.symbol}
                {cellValues?.row?.main_costing_amount}
              </span> : "--"
            }
          </Box>
        );
      },
      
    },

    {
      field: "scenario_amount",
      headerName: "Scenario Costing Amount",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 100,
      renderCell: (cellValues: any) => {
        return (
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}
          >
            {cellValues?.row?.scenario_amount ?
              <span style={{ cursor: "pointer" }}>
                {cellValues?.row?.currency?.symbol}
                {cellValues?.row?.scenario_amount}
              </span> : "--"
            }
          </Box>
        );
      },
      
    },

    {
      field: "main_costing_turn_around_time_in_days",
      headerName: "Main Costing Turn Around Time (Days)",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 100,
      renderCell: (cellValues: any) => {
        return (
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}
          >
            {cellValues?.row?.main_costing_turn_around_time_in_days ?
              <span style={{ cursor: "pointer" }}>
               
                {cellValues?.row?.main_costing_turn_around_time_in_days}
              </span> : "--"
            }
          </Box>
        );
      },
      
    },

    

    {
      field: "scenario_turn_around_time_in_days",
      headerName: "Scenario Costing Turn Around Time (Days)",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 100,
      renderCell: (cellValues: any) => {
        return (
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}
          >
            {cellValues?.row?.scenario_turn_around_time_in_days ?
              <span style={{ cursor: "pointer" }}>
               
                {cellValues?.row?.scenario_turn_around_time_in_days}
              </span> : "--"
            }
          </Box>
        );
      },
      
    },


    {
      field: "zbc_available",
      headerName: "ZBC Available",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 100,
      renderCell: (cellValues: any) => {
        return (
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}
          >
            {cellValues?.row?.zbc_available === true ?
              <span style={{ cursor: "pointer" }}>
               
              Yes
              </span> : cellValues?.row?.zbc_available === false ?
              <span style={{ cursor: "pointer" }}>
               
              No
              </span> : "--"
            }
          </Box>
        );
      },
      
    },

    // {
    //   field: "action",
    //   headerName: "Action",
    //   headerClassName: "super-app-theme--header",
    //   width: 100,
    //   renderCell: (cellValues: any) => {
    //     return (
    //       <Box
    //         sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}
    //       >
    //         {/* {cellValues?.row?.editable ? ( */}
    //         {cellValues?.row?.request_type != "Delete" && (
    //           <EditIcon
    //             onClick={() =>
    //               // window.open(cellValues?.row?.drawing?.[0]?.url)
    //               {
    //                 setPartId({
    //                   id: cellValues?.row?.id,
    //                   status: cellValues?.row?.status,
    //                 });
    //               }
    //             }
    //             style={{
    //               color: "#00887a",
    //               cursor: "pointer",
    //               opacity:
    //                 cellValues?.row?.color_code === "in_progress" ? 0.5 : 1,
    //               pointerEvents:
    //                 cellValues?.row?.color_code === "in_progress"
    //                   ? "none"
    //                   : "auto",
    //             }}
    //             titleAccess="Edit Part Information"
    //             sx={{ cursor: "pointer", color: "primary.main" }}
    //           />
    //         )}
    //         {cellValues?.row?.status === "Accepted" && (
    //           <DeleteIcon
    //             sx={{ color: "primary.main", cursor: "pointer" }}
    //             style={{ color: "#d32f2f" }}
    //             onClick={() => {
    //               Swal.fire({
    //                 title: "Are you sure?",
    //                 text: "You want to delete this Part!",
    //                 icon: "warning",
    //                 showCancelButton: true,
    //                 confirmButtonColor: "#3085d6",
    //                 cancelButtonColor: "#d33",
    //                 confirmButtonText: "Yes, delete it!",
    //               }).then((result) => {
    //                 if (result.isConfirmed) {
    //                   API.delete(
    //                     `/supplier/get_all_part_request_for_client/${cellValues?.row?.id}/`
    //                   )
    //                     .then(() => {
    //                       Swal.fire(
    //                         "Success!",
    //                         "Your delete request has been submitted",
    //                         "success"
    //                       );
    //                       fetchPartsData();
    //                     })
    //                     .catch(() => {
    //                       Swal.fire("Error!", "Something went wrong.", "error");
    //                     });
    //                 }
    //               });
    //             }}
    //           />
    //         )}
    //         {cellValues?.row?.request_type == "Delete" && (
    //           <HistoryIcon
    //             sx={{ color: "primary.main", cursor: "pointer" }}
    //             style={{ color: "green" }}
    //             titleAccess="Revert your delete request"
    //             onClick={() => {
    //               Swal.fire({
    //                 title: "Are you sure?",
    //                 text: "You want to revert this delete request!",
    //                 icon: "warning",
    //                 showCancelButton: true,
    //                 confirmButtonColor: "#3085d6",
    //                 cancelButtonColor: "#d33",
    //                 confirmButtonText: "Yes, revert it!",
    //               }).then((result) => {
    //                 if (result.isConfirmed) {
    //                   API.put(
    //                     `/supplier/get_all_part_request_for_client/`,
    //                     {},
    //                     {
    //                       part_id: cellValues?.row?.id,
    //                       tab_name: "edit_requests",
    //                       revert_back: true,
    //                     },
    //                     0
    //                   )
    //                     .then(() => {
    //                       Swal.fire(
    //                         "Success!",
    //                         "Request reverted successfully.",
    //                         "success"
    //                       );
    //                       fetchPartsData();
    //                     })
    //                     .catch(() => {
    //                       Swal.fire("Error!", "Something went wrong.", "error");
    //                     });
    //                 }
    //               });
    //             }}
    //           />
    //         )}
    //         {/* ) : (
    //           <ExitToAppIcon
    //             style={{
    //               color: "#569479",
    //               cursor: "pointer",
    //             }}
    //             onClick={() => {

    //               setSelectedTab("edit_requests");
    //               setSearchTerm(cellValues?.row?.name);

    //             }}
    //           />
    //        )} */}
    //       </Box>
    //     );
    //   },
    // },
  ];
  React.useEffect(() => {
    if (
      supplier !== null &&
      supplier !== undefined &&
      supplier !== "" &&
      counter > 0
    ) {
      getSupplierDetailsByID();
    }
  }, [Counter]);
  const rows = partList?.map((item: any, index: number) => {
    return { ...item, Id: index + 1 };
  });

  const knownPartrows = partList?.filter((item: any, index: number) => {
    if (item?.potential_supplier === false) {
      return { ...item, Id: index + 1 };
    }
  });

  const potentialPartrows = partList?.filter((item: any, index: number) => {
    if (item?.potential_supplier === true) {
      return { ...item, Id: index + 1 };
    }
  });

  const fetchPartsData = async () => {
    setLoading(true);
    try {
      const result = await API.get(`/supplier/supplier_db/`, {
        parts_info: true,
        page: page,
        id: supplier,
      });

      setPartList(result.data);

      if (result.data?.count / 100 < page) {
        setHasMore(false);
      }

      return;
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const AddPartHandler = () => {
    Addpartmodal.open();
  };

  const handleOnChange = async (event: any) => {
    setSearchTerm(event.target.value);
  };

  React.useEffect(() => {
    if (partId) {
      Editpartmodal.open();
    }
  }, [partId]);

  React.useEffect(() => {
    fetchPartsData();
  }, [page, selectedTab, counter,supplier]);
  const getRequestTypeString = (requestType: any) => {
    switch (requestType) {
      case "Add":
        return "Add new part";
      case "Edit":
        return "Edit existing part";
      case "Delete":
        return "Delete Part";
      default:
        return "";
    }
  };

  React.useEffect(() => {
    setPage(1);
    setHasMore(true);
    fetchPartsData();
  }, [debouncedVal]);

  if (selectedTab === "edit_requests") {
    columns.splice(9, 0, {
      field: "request_type",
      headerName: "Request Type",
      headerClassName: "super-app-theme--header",
      // description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 130,
      renderCell: (cellValues: any) => {
        return (
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}
          >
            {
              <span style={{ cursor: "pointer" }}>
                {getRequestTypeString(cellValues?.row?.request_type)}
              </span>
            }
          </Box>
        );
      },
    });
  }

  return (
    <>
      {/* {addSupplierDocument.isOpen && (
        <AddSupplierDocumentModal
          isOpen={addSupplierDocument.isOpen}
          onCloseModal={addSupplierDocument.close}
          supplierDetails={supplier_id}
        />
      )}
      {certificateModal.isOpen && (
        <PDFViewer
          isOpen={certificateModal.isOpen}
          onCloseModal={certificateModal.close}
          certificates={certificates}
        />
      )}
      {ShowpartImagesmodal.isOpen && (
        <PartImageModel
          isOpen={ShowpartImagesmodal.isOpen}
          onCloseModal={ShowpartImagesmodal.close}
          partdetails={partImages}
        />
      )}

      {costView?.isOpen && (
        <PriceTableViewModal
          isOpen={costView.isOpen}
          onCloseModal={costView.close}
          partdetails={costView.propsId}
        />
      )}


      {SupplierInfoView.isOpen && (
        <SupplierInfoViewModal
          isOpen={SupplierInfoView.isOpen}
          onCloseModal={SupplierInfoView.close}
          supplierDetails = {supplierDetails}
          getSupplierDetailsByID={getSupplierDetailsByID}
        />
      )}

      {errorModal.isOpen && (
        <SearchLimitModal
          isOpen={errorModal.isOpen}
          errormsg={error}
          onCloseModal={errorModal.close}
        />
      )}

      {claimBusinessModal.isOpen && (
        <ClaimBusinessForm
          isOpen={claimBusinessModal.isOpen}
          onCloseModal={claimBusinessModal.close}
          supplierDetails={null}
        />
      )}

      {Addpartmodal.isOpen && (
        <CreatePartFormNew
          onCloseModal={Addpartmodal.close}
          isOpen={Addpartmodal.isOpen}
          setCounter={setCounter}
          SupllierId={supplier_id}
          partId={null}
          tab_name={selectedTab}
          addedPart={supplierDetails?.added_parts}
          fetchPartsData={fetchPartsData}
        />
      )}

      {Editpartmodal.isOpen && (
        <CreatePartFormNew
          onCloseModal={Editpartmodal.close}
          isOpen={Editpartmodal.isOpen}
          setCounter={setCounter}
          SupllierId={supplier_id}
          partId={partId}
          tab_name={selectedTab}
          addedPart={supplierDetails?.added_parts}
          fetchPartsData={fetchPartsData}
        />
      )}

      {editSupplierProfileImage.isOpen && (
        <SupplierImageEditModal
          onCloseModal={editSupplierProfileImage.close}
          isOpen={editSupplierProfileImage.isOpen}
          profilePic={editSupplierProfileImage.propsId}
          getSupplierDetailsByID={getSupplierDetailsByID}
        />
      )}
      {editClaimbusiness.isOpen && (
        <EditBusinessInfo
          isOpen={editClaimbusiness.isOpen}
          onCloseModal={editClaimbusiness.close}
          supplierDetails={supplierDetails}
          serCounter={serCounter}
        />
      )}
      {editContactInfo?.isOpen && (
        <EditSPOCInfo
          isOpen={editContactInfo.isOpen}
          onCloseModal={editContactInfo.close}
          supplierDetails={supplierDetails}
          serCounter={serCounter}
        />
      )}
      {contactEditModal.isOpen && (
        <SupplierContactEditModal
          isOpen={contactEditModal.isOpen}
          onCloseModal={contactEditModal.close}
          contactDetails={contactEditModal.propsId}
        />
      )} */}

      <Box>
        <Grid
          container
          spacing={2}
          sx={{ padding: "0rem 0rem 0rem 2rem", height: "100%" }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box
              sx={{
                boxShadow: "0 0 10px 0 rgba(0,0,0,0.1)",
                borderRadius: "1rem",
                overflow: "hidden",
                //   height: { lg: "39vh", xl: "87vh" },
                width: "100%",
                padding: "1rem",
                paddingBottom: "2rem",
              }}
            >
              <Box
                sx={{
                  height: { lg: "60vh", xl: "70vh" },
                  overflowY:'scroll',
                  "& .super-app-theme--not_started": {
                    bgcolor: "#fff",
                  },
                  "& .super-app-theme--accepted": {
                    bgcolor: "#99dfbb",
                  },
                  "& .super-app-theme--in_progress": {
                    bgcolor: "#ffffa3",
                  },
                  "& .super-app-theme--rejected": {
                    bgcolor: "secondary.main",
                  },
                }}
                style={
                  show_status === "partial" &&
                  supplierDetails?.partList?.length === 0
                    ? { filter: "blur(10px)", WebkitFilter: "blur(2.5px)" }
                    : {}
                }
              >
                <DataGrid
                  getRowClassName={(params) =>
                    `super-app-theme--${params.row.color_code}`
                  }
                  //@ts-ignore
                  columnHeaderHeight={35}
                  rowHeight={35}
                  rows={(value === 1 ? knownPartrows : potentialPartrows) || []}
                  columns={columns}
                  hideFooter
                  // initialState={{
                  // 	pagination: {
                  // 		paginationModel: {
                  // 			pageSize: 20,
                  // 		},
                  // 	},
                  // }}
                  // pageSizeOptions={[20]}
                  // checkboxSelection

                  disableRowSelectionOnClick
                  sx={{
                    "& ::-webkit-scrollbar": {
                      width: "0.3rem",
                      height: "0.5rem",
                    },
                    "& ::-webkit-scrollbar-thumb": {
                      // background: $thumb-color;
                      backgroundImage: "linear-gradient(#72e5d9, #00887a)",
                      borderRadius: "10px",
                    },
                    "& ::- webkit-scrollbar-thumb: hover ": {
                      // width: "4px",
                      backgroundColor: "#045DE9 !important",
                    },
                    "::-webkit-scrollbar-track": {
                      backgroundColor: "#e1e1f3 !important",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                      backgroundColor: "primary.main",
                      color: "primary.contrastText",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "primary.contrastText",
                    },
                    "& .MuiDataGrid-footerContainer": {
                      minHeight: "40px",
                    },
                  }}
                />
              </Box>
            </Box>
            {costView?.isOpen && (
              <PriceTableViewModal
                isOpen={costView.isOpen}
                onCloseModal={costView.close}
                partdetails={costView.propsId}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
