import * as React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Divider, Modal, Typography } from "@mui/material";
import PCBTableModal from "./BOMEntry/PCBTableModal";
import Swal from "sweetalert2";

export interface IPCBExpandPopupModalProps {
  onCloseModal: any;
  isOpen: any;
  expandStatus: any;
  tableName: any;
  idddd: any;
  getTableData: any;
  dataForUpdate: any;
  tableData: any;
  setTableData: any;
  dataForCheck: any;
  setSelectedOptionsTable: any;
  setColorButton: any;
  colorButton: any;
  setSelectedTableId: any;
  selectedTableId: any;
  setShowButton: any;
  BOMValidationStatus?: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  height:'90vh',
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

export default function PCBExpandPopupModal(props: IPCBExpandPopupModalProps) {
  const {
    onCloseModal,
    isOpen,
    tableName,
    expandStatus,
    idddd,
    getTableData,
    BOMValidationStatus,
  } = props;

  const handleCloseModal = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This action will discard all of your unsaved data. Please make sure that everything has been saved before proceeding.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: {
        container: "swal2Container",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        onCloseModal();
        getTableData();
      }
    });
  };

  return (
    <div>
      <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEnforceFocus
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
              }}
            >
              {tableName}
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={() => {
                handleCloseModal();
              }}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box
            sx={{ padding: "0 1rem 1rem", maxHeight: "85vh" }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            <PCBTableModal
              expandStatus={expandStatus}
              idddd={idddd}
              getTableDataaa={getTableData}
              BOMValidationStatus={BOMValidationStatus}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
