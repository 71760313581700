import React from "react";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";

import {
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { API } from "../../api-services";
import { ADMIN } from "../../Redux/Services/admin.service";
interface InfoModalProps {
  onCloseModal: any;
  isOpen: any;
  cellData: any;
  supplierPartData: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "60vw",
  maxWidth: "80vw",
  height: "40vh",
  // height: '80vh',
  bgcolor: "background.paper",
  borderRa3ius: "10px",
  p: 1,
};

const CreateOtherCategoryModal = (props: InfoModalProps) => {
  const { onCloseModal, isOpen, cellData, supplierPartData } = props;
  const [productCategoryList, setProductCategoryList] = React.useState<any>([]);
  const [BOMCategoryList, setBOMCategoryList] = React.useState<any>([]);
  const [selectedBOMcat, setSelectedBOMcat] = React.useState<any>();
  const [selectedPartCat, setSelectedPartCat] = React.useState<any>();
  const handleCopyClick = (textToCopy: any) => {
    if (textToCopy) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          console.log("Text copied successfully:", textToCopy);
          // Optionally, change icon color to green or show success message
        })
        .catch((err) => {
          console.error("Unable to copy text:", err);
        });
    }
    ADMIN.toast.info("Copied Successfully");
    // Optionally, change icon color to green after copying
  };
  const getProductCategoryList = () => {
    API.get("/cart/get_all_vehiclecategory_list/", {}, 0)
      .then((res: any) => {
        setProductCategoryList(res.data);
      })
      .catch(() => {});
  };
  const getProductCategoryListFetch = () => {
    API.get("/cart/get_all_vehiclecategory_list/", {}, 0)
      .then((res: any) => {
        ADMIN.toast.info(
          "Dropdown data fetched successfully, Please select your added category"
        );
        setProductCategoryList(res.data);
      })
      .catch(() => {});
  };

  const getBOMCategoryList = (veh_cat_id: any) => {
    API.get(
      "/cart/get_all_bomcategory_list/",
      {
        all_type_bom_category_for_client: true,
        internal: false,
        vehicle_category: veh_cat_id,
      },
      0
    )
      .then((res: any) => {
        setBOMCategoryList(res.data);
      })
      .catch((err: any) => {});
  };

  const getBOMCategoryListFetch = (veh_cat_id: any) => {
    API.get(
      "/cart/get_all_bomcategory_list/",
      {
        all_type_bom_category_for_client: true,
        internal: false,
        vehicle_category: veh_cat_id,
      },
      0
    )
      .then((res: any) => {
        ADMIN.toast.info(
          "Dropdown data fetched successfully, Please select your added category"
        );
        setBOMCategoryList(res.data);
      })
      .catch((err: any) => {});
  };

  React.useEffect(() => {
    getProductCategoryList();
  }, []);

  React.useEffect(() => {
    if (cellData?.vehicle_category_id) {
      setSelectedBOMcat(cellData?.vehicle_category_id);
      getBOMCategoryList(cellData?.vehicle_category_id);
    }
  }, [cellData]);
  console.log("cellDatacellData", cellData);

  const handleChange = (e: any) => {
    setSelectedBOMcat(e.target.value);
    getBOMCategoryList(e.target.value);
  };
  const handleChangePart = (e: any) => {
    setSelectedPartCat(e.target.value);
  };

  return (
    <div>
      <Modal open={isOpen}>
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "1rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                // marginRight: '-2rem',
              }}
            >
              Add or Fetch Category
            </Typography>

            {/* <Button  onClick={()=>window.open("/#/data-base/bomCategories")} >Add Part Category</Button> */}

            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
              marginTop: "2rem",
            }}
          />
          <Box sx={{ margin: "1.5rem 1.5rem" }}>
            {cellData && cellData?.vehicle_category_id ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "auto auto",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                Product Category : {cellData && cellData?.vehicle_category}
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "auto auto",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <FormControl fullWidth sx={{ width: "50%" }}>
                  <InputLabel
                    id="demo-simple-select-label-Activity"
                    shrink
                    sx={{ color: "primary.main", marginLeft: "-1rem" }}
                  >
                    Select Product Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    variant="standard"
                    value={selectedBOMcat && selectedBOMcat}
                    disabled={cellData?.vehicle_category_id}
                    name="product"
                    onChange={handleChange}
                    label="Calibration Required"
                    sx={{
                      width: "100%",
                      marginBottom: "2rem",
                      fontSize: "1rem",
                      "&:before": {
                        borderBottomColor: "primary.light",
                      },
                      ".MuiSvgIcon-root.MuiSelect-icon": {
                        color: "primary.main",
                      },
                    }}
                  >
                    {productCategoryList &&
                      productCategoryList?.map((item: any) => {
                        return (
                          <MenuItem sx={{ fontSize: "1rem" }} value={item?.id}>
                            {item?.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                <Typography
                  onClick={() => window.open("/#/project_directory")}
                  sx={{
                    textDecoration: "underline",
                    color: "primary.main",
                    width: "20%",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  Product Category
                </Typography>
                {
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "20%",
                    }}
                  >
                    <span style={{ marginRight: "8px" }}>
                      {cellData?.other_name || "--"}
                    </span>
                    <Tooltip title="Copy" arrow>
                      <IconButton
                        sx={{
                          color: "primary.main",
                          opacity:
                            cellData?.other_name &&
                            cellData?.other_name?.length > 0
                              ? 1
                              : 0.5,
                        }}
                        onClick={() => {
                          if (
                            cellData?.other_name &&
                            cellData?.other_name?.length > 0
                          ) {
                            handleCopyClick(cellData?.other_name);
                          }
                        }}
                        disabled={
                          cellData?.other_name &&
                          cellData?.other_name?.length < 1
                        }
                      >
                        <FileCopyIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                }
                <Button
                  variant="contained"
                  onClick={() => getProductCategoryListFetch()}
                  sx={{
                    color: "primary.light",
                    width: "10%",
                    //   textAlign: "center",
                  }}
                >
                  Fetch
                </Button>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                margin: "auto auto",
                alignItems: "center",
                width: "100%",
              }}
            >
              <FormControl fullWidth sx={{ width: "50%" }}>
                <InputLabel
                  id="demo-simple-select-label-Activity"
                  shrink
                  sx={{ color: "primary.main", marginLeft: "-1rem" }}
                >
                  Part Category
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant="standard"
                  name="bom"
                  value={selectedPartCat}
                  onChange={handleChangePart}
                  label="Calibration Required"
                  sx={{
                    width: "100%",
                    fontSize: "1rem",
                    "&:before": {
                      borderBottomColor: "primary.light",
                    },
                    ".MuiSvgIcon-root.MuiSelect-icon": {
                      color: "primary.main",
                    },
                  }}
                >
                  {BOMCategoryList &&
                    BOMCategoryList?.map((item: any) => {
                      return (
                        <MenuItem
                          sx={{ fontSize: "1rem" }}
                          value={item?.cat_id}
                        >
                          {item?.cat_name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              <Typography
                onClick={() => {
                  if (selectedBOMcat)
                    window.open(
                      `/#/bomconfiguration/${selectedBOMcat}/${
                        productCategoryList &&
                        productCategoryList?.filter(
                          (item: any) => item?.id == selectedBOMcat
                        )[0]?.name
                      }/0/0/0/0/0`
                    );
                }}
                sx={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: "primary.main",
                  width: "20%",
                  textAlign: "center",
                  opacity: selectedBOMcat ? 1 : 0.5,
                }}
              >
                Part Category
              </Typography>
              <Box
                style={{ display: "flex", alignItems: "center", width: "20%" }}
              >
                <span style={{ marginRight: "8px" }}>
                  {cellData?.other_part_name || "--"}
                </span>
                <Tooltip title="Copy" arrow>
                  <IconButton
                    sx={{
                      color: "primary.main",
                      opacity:
                        cellData?.other_part_name &&
                        cellData?.other_part_name?.length > 0
                          ? 1
                          : 0.5,
                    }}
                    onClick={() => {
                      if (
                        cellData?.other_part_name &&
                        cellData?.other_part_name?.length > 0
                      ) {
                        handleCopyClick(cellData?.other_part_name);
                      }
                    }}
                    disabled={
                      cellData?.other_part_name &&
                      cellData?.other_part_name?.length < 1
                    }
                  >
                    <FileCopyIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Button
                variant="contained"
                onClick={() =>
                  getBOMCategoryListFetch(
                    selectedBOMcat || cellData?.vehicle_category_id
                  )
                }
                sx={{
                  color: "primary.light",
                  width: "10%",
                  //   textAlign: "center",
                }}
              >
                Fetch
              </Button>
            </Box>
            <Button
              sx={{
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "5rem",
                width: "100%",
              }}
              variant="contained"
              onClick={() => {
                API.put(
                  "/cart/get_all_part_requests_for_internal/",
                  {
                    part_id: cellData?.ID,
                    vehicle_category: selectedBOMcat,
                    supplier: cellData?.supplier,
                    part_category: selectedPartCat,
                  },
                  {},
                  0
                )
                  .then((res: any) => {
                    supplierPartData();
                    onCloseModal();
                  })
                  .catch((err: any) => {});
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default CreateOtherCategoryModal;
