const projectSpecificModules = [
  "Planner",
  "User Permission",
  "Minutes Of Meeting",
  "Project Delivery",
  // "Configuration",
  "BOM Configuration",
  "Features Configuration",
  "Costing Configuration",
  
  "Ideas Configuration",
  "Companion Configuration",
  "Recycle Bin",
  "Engineering Insights Report",
  // "Functions & Filters",
];


const RoleList = [
  // { text: "C", value: "C" },
  { text: "R", value: "R" },
  // { text: "U", value: "U" },
  // { text: "D", value: "D" },
];
const RoleListAll = [
  { text: "C", value: "C" },
  { text: "R", value: "R" },
  { text: "U", value: "U" },
  { text: "D", value: "D" },
];

const RoleListScenario = [
  { text: "R", value: "R" },
  { text: "U", value: "U" },
  { text: "D", value: "D" },
]
const RoleListScenarioLock = [
  { text: "R", value: "R" },
 
]
const RoleListIdea = [
  { text: "R", value: "R" },
  { text: "U", value: "U" },
];

const GeneralModules = [
  "DB-Parameter Library", "DB-Systems & Names", "DB-Supplier Library", "DB-BOM Categories", "DB-Revision Scenario", "DB-Revision Main", "DB-Costing","DB-Cost commodity and process",
  "Guest User-2",
  "Guest User-5",
  "Guest User-10",
  "Calibration"
];

export { projectSpecificModules, RoleList ,GeneralModules,RoleListAll,RoleListIdea,RoleListScenario,RoleListScenarioLock};
