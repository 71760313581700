import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { API } from "../../api-services";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Icon,
} from "@mui/material";
import { Done, History } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import { styled } from "@mui/material/styles";

interface Subscription {
  id: number;
  subscription: string;
  starts_at: string;
  ends_at: string;
  current: boolean;
  duration: number;
}

const TableHeader = styled(TableHead)({
  backgroundColor: "#00887A",
  color: "#fff",
});

const ProgressBadge = styled("div")({
  backgroundColor: "#f44336", // Red color for expired
  color: "#fff",
  padding: "4px 8px",
  borderRadius: "12px",
  fontWeight: "bold",
  textAlign: "center",
});

const ProgressBadgeCurrent = styled("div")({
  backgroundColor: "#00887A", // Color for current
  color: "#fff",
  padding: "4px 8px",
  borderRadius: "12px",
  fontWeight: "bold",
  textAlign: "center",
});

const IconContainer = styled(Box)({
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "2rem",
  height: "2rem",
});

const ConnectorLine = styled("div")({
  position: "absolute",
  top: "50%",
  left: "100%",
  width: "1px",
  height: "calc(100% - 0.5rem)", // Adjust to fit between icons
  backgroundColor: "#00887A",
  zIndex: -1,
});

const SubscriptionModalDetails = (props: any) => {
  const { Open, Close, userId } = props;

  const [subscriptiondetails, setSubscriptionDetails] = useState<Subscription[]>(
    []
  );

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70vw", // Adjusted width
    height: "60vh", // Adjusted height
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    overflowY: "auto",
  };

  const getSubscriptionDetails = () => {
    API.get("/auth/get_user_subscription_history/", { user_id: userId }, 0)
      .then((res: any) => {
        setSubscriptionDetails(res.data);
      })
      .catch((err: any) => {});
  };

  useEffect(() => {
    if (userId) {
      getSubscriptionDetails();
    }
  }, [userId]);

  const calculateRemainingDays = (endDate: string) => {
    const today = new Date();
    const end = new Date(endDate);
    const differenceInTime = end.getTime() - today.getTime();
    return Math.ceil(differenceInTime / (1000 * 3600 * 24));
  };

  return (
    <Modal
      open={Open}
      onClose={Close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: "-2rem",
              marginTop: "0.5rem",
            }}
          >
            Subscription History
          </Typography>
          <CancelIcon
            titleAccess="Close"
            sx={{ cursor: "pointer", margin: "0.5rem" }}
            onClick={Close}
          />
        </Box>
        <Container>
          <TableContainer
            component={Paper}
            sx={{ maxHeight: "calc(60vh - 80px)" }}
          >
            <Table>
              <TableHeader>
                <TableRow>
                  <TableCell sx={{ color: 'white' }} align="center">Subscription</TableCell>
                  <TableCell sx={{ color: 'white' }} align="center">Start Date</TableCell>
                  <TableCell sx={{ color: 'white' }} align="center">End Date</TableCell>
                  <TableCell sx={{ color: 'white' }} align="center">Duration (Days)</TableCell>
                  <TableCell sx={{ color: 'white' }} align="center">Remaining Days</TableCell>
                  <TableCell sx={{ color: 'white' }} align="center">Status</TableCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {subscriptiondetails.map((sub, index) => {
                  const remainingDays: any = sub.current
                    ? calculateRemainingDays(sub.ends_at)
                    : null;
                  const expired =
                    !sub.current ||
                    remainingDays === null ||
                    remainingDays <= 0;
                  const progressValue = sub.current
                    ? (100 * (sub.duration - remainingDays)) / sub.duration
                    : 100;

                  return (
                    <TableRow key={sub.id}>
                      <TableCell align="center">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: "0.5rem",
                            width: "100%",
                            position: "relative",
                          }}
                        >
                          <IconContainer>
                            {/* {index < subscriptiondetails.length - 1 && (
                              <ConnectorLine />
                            )} */}
                            {expired ? (
                              <Icon fontSize="small" color="error">
                                <History sx={{ fontSize: '1.3rem' }} />
                              </Icon>
                            ) : (
                              <Icon fontSize="small" color="success">
                                <Done sx={{ fontSize: '1.3rem' }} />
                              </Icon>
                            )}
                          </IconContainer>
                          {sub.subscription}
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        {new Date(sub.starts_at).toLocaleDateString()}
                      </TableCell>
                      <TableCell align="center">
                        {new Date(sub.ends_at).toLocaleDateString()}
                      </TableCell>
                      <TableCell align="center">{sub.duration}</TableCell>
                      <TableCell align="center">
                        {remainingDays !== null ? remainingDays : 'N/A'}
                      </TableCell>
                      <TableCell align="center">
                        {expired ? (
                          <ProgressBadge>Expired</ProgressBadge>
                        ) : (
                          <ProgressBadgeCurrent>Active</ProgressBadgeCurrent>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Box>
    </Modal>
  );
};

export default SubscriptionModalDetails;
