import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from "@mui/material";


const modalStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    maxWidth: "900px",
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 3,
};

interface SpocInformationModalProps {
    isOpen: boolean;
    onCloseModal: () => void;
    spocInformation: [];
}

function  SpocInformationModal ({ isOpen, onCloseModal, spocInformation }:any){
    return (
        <Modal
            open={isOpen}
            onClose={onCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={modalStyle}>
                <Typography variant="h6" component="h2" gutterBottom>
                    SPOC Information
                </Typography>
                <TableContainer component={Paper} sx={{ maxHeight: "70vh", overflow: 'auto' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: "#fff", backgroundColor: "#00887a" }}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Name</Typography>
                                </TableCell>
                                <TableCell sx={{ color: "#fff", backgroundColor: "#00887a" }}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Department</Typography>
                                </TableCell>
                                <TableCell sx={{ color: "#fff", backgroundColor: "#00887a" }}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Designation</Typography>
                                </TableCell>
                                <TableCell sx={{ color: "#fff", backgroundColor: "#00887a" }}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Dial Code</Typography>
                                </TableCell>
                                <TableCell sx={{ color: "#fff", backgroundColor: "#00887a" }}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Contact Number</Typography>
                                </TableCell>
                                <TableCell sx={{ color: "#fff", backgroundColor: "#00887a" }}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Email</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {spocInformation.map((spoc:any, index:any) => (
                                <TableRow key={index}>
                                    <TableCell>{spoc?.spoc_name ?? 'N/A'}</TableCell>
                                    <TableCell>{spoc?.spoc_department ?? 'N/A'}</TableCell>
                                    <TableCell>{spoc?.spoc_designation ?? 'N/A'}</TableCell>
                                    <TableCell>{spoc?.spoc_dial_code ?? 'N/A'}</TableCell>
                                    <TableCell>{spoc?.spoc_contact_number ?? 'N/A'}</TableCell>
                                    <TableCell>{spoc?.spoc_email ?? 'N/A'}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Modal>
    );
};

export default SpocInformationModal;