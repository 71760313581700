// react
import axios from "axios";
import { API } from "../../api-services";
import { MODULES } from "../../Constants/modules.constants";
import { s3Url } from "../../utlis/s3url.utils";
import { getArchImages } from "../../Redux/Actions/architecture.action";

const ArchitectureMultipleUploadS3 = async (
    fileList: any,
    project: any,
    top_vault: any,
    vault: any,
    group: any,
    abbr: any, dispatch: any,
    setLoader:any,
    pageNumber:any,
    productpage:any,
    setLoading:any,
    setSequenceCount:any,
    setStatus:any,
    handleClose:any,


) => {
    // const file_name = file?.name;
    var random: any = Math.floor(Math.random() * 1000000 + 1);

    var obj: Array<any> = [];
    const sortAlphaNum = (a: any, b: any) => a.localeCompare(b, 'en', { numeric: true });
    var img: any = "";
    // const dispatch = useAppDispatch();
    // + "-" + uuidv4();
    setLoading(true)
    // setImageLoader(true)
    API.get("cart/get_signed_policy/", {
        module: MODULES.CAD,
        product: abbr == 0 ? top_vault : vault
    }).then((res) => {
        const data = Array.from(fileList).forEach((file: any, index: any) => {
            const formData = new FormData();
            formData.append(
                "key",`${group}/${top_vault}/${vault}/${project}/${random}/${file.name}`
            );
            formData.append("Content-Type", file?.type);
            formData.append("x-amz-server-side-encryption", "AES256");
            formData.append("X-Amz-Credential", res.data.data.x_amz_credential);
            formData.append("X-Amz-Algorithm", "AWS4-HMAC-SHA256");
            formData.append("X-Amz-Date", res.data.data.x_amz_date);
            formData.append(
                "X-Amz-Signature",
                res.data.data.aws_policy_signature
            );
            formData.append("Policy", res.data.data.aws_policy);
            formData.append("file", file, file?.name);

            axios
                .post(s3Url, formData, {
                    onUploadProgress: (data) => {
                        setLoading(true)
                        //Set the progress value to show the progress bar
                    },
                })
                .then((res: any) => {

                    setLoading(true)

                    if (res.status === 204) {
                        obj.push(
                            `/${group}/${top_vault}/${vault}/${project}/${random}/${file.name}`
                        );

                        if (obj.length === fileList.length) {

                           var obj1 = obj.sort(sortAlphaNum)
                            const formData: any = new FormData();
                            Array?.from(obj1)?.forEach((image: any, index: any) => {
                                // @ts-ignore
                                formData.append(`image[${index}]`, image);
                            });

                            var data = abbr == 0 ? { top_vault: top_vault } : { vault: vault }
                            // setImageLoader(false)
                            setLoader(false)
                            API.post(
                                `/circuit_diagram/archs/`,

                                formData,

                                {
                                    ...data,


                                    categories: 3,

                                    group: group,

                                    rgb: false,


                                },
                                0
                            ).then((res: any) => {
                                setLoading(false)

                            setSequenceCount((prev:any)=>prev+1)
                                dispatch(
                                    getArchImages({
                                        project: project,
                                        abbreviation: abbr,
                                        page: pageNumber,
                                        group_id: group,
                                        col_page:productpage
                                    })
                                );

                               
                                setLoader(true)
                                setStatus(true,group)
                                handleClose()
                            }).catch((err:any)=>{
                                setLoader(true)
                                setLoading(false)
                            })

                        }
                    }
                });
        });
    });
};

// ----------------------------------------------------------------------------------

export default ArchitectureMultipleUploadS3;
