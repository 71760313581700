import { API } from "./index.service";

const urlData = "/cost/report_bank_view/";
const MoveUrl = "/cost/get_drive_groups/";
const compareUrl="/cost/compare_module/"


class reportsServices {
    // get_groupName = ( projectId: number,groupType:string) => {
    //     return API.get( urlDrive, {
    //         project: projectId,
    //         group_type:groupType
    //     } );

    // };
    getProjectRepotsBank = () => {
        return API.get( urlData );
    };
    getmoduleName = (projectId:any) => {
        return API.get( urlData,{module_list:true,project_id:projectId} );
    };
    gecomparedProjects = () => {
        return API.get( compareUrl,{project:true} );
    }

    getAllUsersReportsName = (ProjectIdData:any,reportsType:any,selecteData:any) => {
        return API.get( urlData,{share_users:true,project_id:ProjectIdData,report_type:reportsType,user_type:selecteData} );
    };
    getAllUsersMove = (ProjectIdData:any) => {
        return API.get( MoveUrl,{group_type:"created",project:ProjectIdData} );
    };
    getAllUsersDrive = ( module: any, projectId: any ) => {
        return API.get( "/auth/user_list_drive/", {
            module: module, project: projectId
        } );
    };
    getFileCount = (report_category:any) => {
        return API.get( urlData, {
            report_count: true,report_category:report_category
        } );
    }
}
const reportsbank = new reportsServices();
export default reportsbank;