import * as React from "react";
import {
  Accordion,
  Autocomplete,
  Box,
  FormControl,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { useRouteParams } from "../../../../ui-reusable-component/useRouteParams";
import { userPermissionAction } from "../../../BOMCreate/BOMEntry/BOMEntryLandingPage";
import { API } from "../../../../api-services";
import IdeaTabEntryAccordian from "./IdeaTabEntryAccordian";
import IdeaTabCheckBoxComponent from "./IdeaTabInputComponent/IdeaTabCheckBoxComponent";
import IdeaTabNumberInputBox from "./IdeaTabInputComponent/IdeaTabNumberInputBox";
import IdeaTabTextInputBox from "./IdeaTabInputComponent/IdeaTabTextInputBox";
import { TabParametershowCheckBox } from "./IdeaTabInputComponent/TabParametershowCheckBox";
import IdeaTabSingleSelectInputBox from "./IdeaTabInputComponent/IdeaTabSingleSelectInputBox";
import IdeaTabBooleanInputBox from "./IdeaTabInputComponent/IdeaTabBooleanInputBox";
import IdeaTabDatabaseInputBox from "./IdeaTabInputComponent/IdeaTabDatabaseInputBox";
import Swal from "sweetalert2";
import { ADMIN } from "../../../../Redux/Services/admin.service";
import IdeaTabDescriptionInputBox from "./IdeaTabInputComponent/IdeaTabDescriptionInputBox";
import IdeaTabDateInputBox from "./IdeaTabInputComponent/IdeaTabDateInputBox";
import { debounce } from "lodash";
import { IdeaViewCostModalComponents } from "../IdeaViewCostModalComponents";
import { UserAction } from "../IdeaGenerationViewLanding";
import { rejectedStatus } from "../IdeaViewpage";

export interface IViewTabDataProps {
  // tableDataGetCount: any;
  // BOMValidationStatus?:any;
  // tabParameter:any;
  vault_id: any;
}

export default function ViewTabData(props: IViewTabDataProps) {
  const { vault_id } = props;
  const { workstation, topVault, vault, projectId, subSystemId } =
    useRouteParams<any>();
    const rejectedAction = React.useContext<any>(rejectedStatus);
  let rejectedStatusActionParams = rejectedAction && rejectedAction;
  const handleSelectChangecategory = (event: any, newSystemValue: any) => {};
  const expandStatus = true;
  const [tableData, setTableData] = React.useState<any>([]);
  const [tableDataLoader, setTableDataLoader] = React.useState<any>(false);
  const [tableAccData, setTableAccData] = React.useState<any>([]);
  const [tableDataAll, setTableDataAll] = React.useState<any>([]);
  const [selectedOptions, setSelectedOptions] = React.useState<any>([]);
  const [dataForUpdate, setDataForUpdate] = React.useState<any>([]);
  const [colorButton, setColorButton] = React.useState<any>("");
  const [showButton, setShowButton] = React.useState<any>(false);
  const [selectedTableId, setSelectedTableId] = React.useState<any>("");
  const userAction = React.useContext<any>(UserAction);
  let UserActionParams = userAction && userAction?.action;
  const [tabParameterData, setTabParameterData] = React.useState<any>([]);
  const [isShowCheckbox, setIsShowCheckbox] = React.useState<any>();
  const [tabParameterDataLoader, setTabParameterDataLoader] =
    React.useState<any>(false);
  const [isIdeaparametersLoading, setIsIdeaparametersLoading] =
    React.useState<boolean>(false);
  const [Ideaparameters, setIdeaparameters] = React.useState<any>([]);
  const [BOMDetails, setBOMDetails] = React.useState<any>([]);
  const [counterApi, setCounterAPI] = React.useState<any>(0);
  const [partData, setPartData] = React.useState<any>("");
  const [partName, setPartName] = React.useState<any>("");
  const [category, setCategory] = React.useState<any>("");
  const [partQty, setPartQty] = React.useState<any>("");
  const [syncColor, setSyncColor] = React.useState<any>("red");
  const [details, setDetails] = React.useState<any>([]);
  const [allValues, setAllValues] = React.useState<any>([]);
  const [ParentsCounter, setParentsCounter] = React.useState<any>(0);
  const [ParentsCounterForAll, setParentsCounterForAll] =
    React.useState<any>(0);
  const [ParentsCounterForAllCopy, setParentsCounterForAllCopy] =
    React.useState<any>(0);
  const [copyStatus, setCopyStatus] = React.useState<any>(false);
  const [copyStatusForAll, setCopyStatusForAll] = React.useState<any>(false);
  const [copyStatusForAllCopy, setCopyStatusForAllCopy] =
    React.useState<any>(false);
  const [isAdding, setIsAdding] = React.useState<any>(false);

  const obj = (tv: any, vlt: any) => {
    if (vlt == 0) {
      return { top_vault: tv };
    } else {
      return { system: vlt };
    }
  };

  const getobj = (v: any, tv: any) => {
    if (v == 0) {
      return { top_vault: tv };
    } else {
      return { system: v };
    }
  };

  const getObjKeyName = () => {
    if (vault == 0) {
      return { top_vault: topVault };
    } else {
      return { vault: subSystemId };
    }
  };
  const getTableData = () => {
    API.get(
      "/idea/idea_create_view/",
      {
        table: true,
        top_vault: topVault,
        id: vault_id,
        drop_empty: true,
      },
      0
    )
      .then((res: any) => {
        setTableData(res.data);
      })
      .catch((err: any) => {});
  };

  const getTableNameCount = () => {
    setTableDataLoader(true);
    API.get(
      "/idea/idea_create_view/",
      {
        top_vault: topVault,
        id: vault_id,
        table: true,
        basic_info: true,
      },
      0
    )
      .then((res: any) => {
        setTableAccData(res.data);
        setTableDataLoader(false);
      })
      .catch((err: any) => {
        setTableDataLoader(false);
      });
  };

  const getTableDataWOLoader = () => {
    API.get(
      "/idea/idea_create_view/",
      {
        table: true,
        top_vault: topVault,
        id: vault_id,
        drop_empty: true,
      },
      0
    )
      .then((res: any) => {
        setTableData(res.data);
      })
      .catch((err: any) => {});
  };
  const getTableDataAll = () => {
    API.get(
      "/idea/idea_create_view/",
      {
        table: true,
        top_vault: topVault,
        id: vault_id,
      },
      0
    )
      .then((res: any) => {
        setTableDataAll(res.data);
        setDataForUpdate([]);
      })
      .catch((err: any) => {});
  };

  React.useEffect(() => {
    if (vault_id != 0) {
      getTableData();
      getTableDataAll();
      getTableNameCount();
    }
  }, [vault_id]);

  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? false : false);
    };

  React.useEffect(() => {
    setTabParameterDataLoader(true);
    API.get(
      "/idea/idea_create_view/",
      {
        top_vault: topVault,
        id: vault_id,
        part_details: true,
        idea_main_create: true,
      },
      0
    )
      .then((res: any) => {
        setTabParameterData(res.data);
        setTabParameterDataLoader(false);
      })
      .catch((err: any) => {
        setTabParameterDataLoader(false);
      });
  }, [topVault, vault_id]);
  React.useEffect(() => {
    API.get(
      "/idea/idea_create_view/",
      {
        top_vault: topVault,
        id: vault_id,
        part_details: true,
        idea_main_create: true,
      },
      0
    )
      .then((res: any) => {
        setTabParameterData(res.data);
        setTabParameterDataLoader(false);
      })
      .catch((err: any) => {
        setTabParameterDataLoader(false);
      });
  }, [counterApi]);

  const ExactDebounce = React.useRef(
    debounce(async (value: any, name: any, idd: any, topvlt: any, rt: any) => {
      await updateDataVault(name, value, idd, topvlt, rt);
    }, 1000)
  ).current;

  const ExactDebounceParameters = React.useRef(
    debounce(async (value: any, name: any, idd: any, tv) => {
      await updateDataVaultParams(name, value, idd, tv);
    }, 1000)
  ).current;

  const getAllValues = () => {
    // API.get("/xcpep/component_details/", { ...obj(topVault, subSystemId) }, 0)
    //   .then((res: any) => {
    //     setAllValues(res.data[0]?.details);
    //   })
    //   .catch((err: any) => {});
  };

  // React.useEffect(() => {
  //   getAllValues();
  // }, [subSystemId, topVault]);

  const getBOMTVDetailsData = () => {
    API.get(
      "/xcpep/top_vault/",
      {
        top_vault: topVault,
        module: 9,
      },
      0
    )
      .then((res: any) => {
        setBOMDetails(res.data[0]);
        setPartData(res?.data[0]?.product_id); // const getVaultBOMDetailsData = () => {
        //   API.get(
        //     "/xcpep/vault_info/",
        //     {
        //       ...obj(topVault, subSystemId),
        //       module: 9,
        //     },
        //     0
        //   )
        //     .then((res: any) => {
        //       setPartName(res.data[0]?.name);
        //       setPartData(res?.data[0]?.part_no);
        //       setCategory(res.data[0]?.category);
        //       setPartQty(res.data[0]?.quantity);
        //       setBOMDetails(res.data[0]);
        //     })
        //     .catch((err: any) => {});
        // };
        setPartName(res.data[0]?.name);
        setCategory(res.data[0]?.category);
        setPartQty(res.data[0]?.quantity);
      })
      .catch((err: any) => {});
  };
  // const getVaultBOMDetailsData = () => {
  //   API.get(
  //     "/xcpep/vault_info/",
  //     {
  //       ...obj(topVault, subSystemId),
  //       module: 9,
  //     },
  //     0
  //   )
  //     .then((res: any) => {
  //       setPartName(res.data[0]?.name);
  //       setPartData(res?.data[0]?.part_no);
  //       setCategory(res.data[0]?.category);
  //       setPartQty(res.data[0]?.quantity);
  //       setBOMDetails(res.data[0]);
  //     })
  //     .catch((err: any) => {});
  // };
  // React.useEffect(() => {
  //   if (subSystemId == 0) {
  //     getBOMTVDetailsData();
  //   } else {
  //     getVaultBOMDetailsData();
  //   }
  // }, [topVault, subSystemId]);
  // React.useEffect(() => {
  //   getWorkStationsData();
  // }, [ParentsCounter, system, topVault, category]); //topvalut & valut removed here for multilple id select looping issue
  const getCheckBoxStatus = () => {
    API.get(
      "/idea/pool/",
      {
        idea_id: vault_id,
      },
      0
    ).then((res: any) => {
      setIsShowCheckbox(res?.data?.is_text);
    });
  };

  React.useEffect(() => {
    if (vault_id != 0) {
      getCheckBoxStatus();
    }
  }, [vault_id]);
  const getUrl = (vltt: any) => {
    if (vltt == 0) {
      return "/xcpep/top_vault";
    } else {
      return "/xcpep/vault_info";
    }
  };
  const updateDataVault = async (
    name: any,
    value: any,
    vlt: any,
    topvlt: any,
    rt: any
  ) => {
    API.put(
      `${getUrl(vlt)}/${vlt != 0 ? +vlt : +topvlt}/`,
      {
        [name]: value,
        validated: false,
      },
      {},
      0
    )
      .then((res: any) => {
        setSyncColor("red");
        // BOMValidationStatus();
        // getWorkStationsData();

        ADMIN.toast.info("Updated Successfully");
      })
      .catch((err: any) => {
        const { data } = err.response;
        Swal.fire({
          title: "Error!",
          text: data[0],
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  const updateDataVaultParams = async (
    name: any,
    value: any,
    vlt: any,
    tv: any
  ) => {
    API.put(
      `/idea/pool/${vault_id}/`,
      {
        ...{ parameter_json: { [name]: value } },
      },
      {
        ...getobj(vlt, tv),
        module: 15,
      },
      0
    )
      .then((res: any) => {
        setSyncColor("red");
        // getIdeaParametersData();
        // BOMValidationStatus();
        ADMIN.toast.info("Updated Successfully");
      })
      .catch((err: any) => {
        ADMIN.toast.error("Something went wrong");
      });
  };

  const handleSelectSystems = (
    event: any,
    newSystemValue: any,
    key: any,
    v: any,
    tv: any
  ) => {
    ExactDebounceParameters(newSystemValue, key, v, tv);
  };

  const renderBooleanInputs = (item: any, index: any) => {
    switch (item?.data_type) {
      case "boolean":
        return (
          <Box
            sx={{
              backgroundColor: item?.color,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                fontSize: "0.87rem",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                width: "4rem",
              }}
            >
              {!item?.exceptions?.includes(item?.value) ? (
                <IdeaTabBooleanInputBox
                  item={item}
                  details={details && details}
                  // BOMValidationStatus={BOMValidationStatus}
                  allValues={allValues && allValues}
                  getAllValues={getAllValues}
                />
              ) : (
                item?.value
              )}
            </Box>
            <Box
              sx={{
                fontSize: "0.87rem",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                lineHeight: 1,
                width: "100%",
              }}
            >
              {item?.paramter_name}{" "}
              {item?.required && <sup style={{ color: "red" }}>*</sup>}
            </Box>
          </Box>
        );
    }
  };
  const renderInputs = (item: any, index: any) => {
    switch (item?.data_type) {
      case "number":
      case "float":
      case "nonzero-positive-int":
      case "nonzero-positive-float":
      case "positive-float":
      case "nonzero-float":
      case "percentage":
      case "positive-int":
      case "integer":
        return (
          <TableRow sx={{ backgroundColor: item?.color }}>
            <TableCell
              sx={{
                fontSize: "0.87rem",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                lineHeight: 1,
              }}
            >
              {item?.paramter_name}{" "}
              {item?.required && <sup style={{ color: "red" }}>*</sup>}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "0.87rem",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {/* {item?.exceptions?.length > 0 && (
                <IdeaTabCheckBoxComponent
                  check={item?.exceptions?.includes(item?.value)}
                  item={item}
                  getIdeaParametersData={getIdeaParametersData}
                  setCounterAPI={setCounterAPI}
                  // BOMValidationStatus={BOMValidationStatus}
                />
              )} */}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "0.87rem",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                }}
              >
                {!item?.exceptions?.includes(item?.value) ? (
                  <IdeaTabNumberInputBox
                    item={item}
                    details={details && details}
                    vaultIdd={subSystemId}
                    // BOMValidationStatus={BOMValidationStatus}
                    allValues={allValues && allValues}
                    getAllValues={getAllValues}
                  />
                ) : (
                  item?.value
                )}

                {item?.exceptions?.length > 0 && (
                  <IdeaTabCheckBoxComponent
                    check={item?.exceptions?.includes(item?.value)}
                    item={item}
                    getIdeaParametersData={getIdeaParametersData}
                    setCounterAPI={setCounterAPI}
                  />
                )}
              </Box>
            </TableCell>
          </TableRow>
        );
      case "text":
      case "string":
        return (
          <TableRow sx={{ backgroundColor: item?.color }}>
            <TableCell
              sx={{
                fontSize: "0.87rem",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                lineHeight: 1,
              }}
            >
              {item?.paramter_name}{" "}
              {item?.required && <sup style={{ color: "red" }}>*</sup>}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "0.87rem",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.exceptions?.length > 0 && (
                <IdeaTabCheckBoxComponent
                  check={item?.exceptions?.includes(item?.value)}
                  item={item}
                  getIdeaParametersData={getIdeaParametersData}
                  setCounterAPI={setCounterAPI}
                  // BOMValidationStatus={BOMValidationStatus}
                />
              )}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "0.87rem",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {
                <IdeaTabTextInputBox
                  item={item}
                  details={details}
                  // BOMValidationStatus={BOMValidationStatus}
                  allValues={allValues && allValues}
                  getAllValues={getAllValues}
                />
              }
            </TableCell>
          </TableRow>
        );

      case "textarea":
      case "longtext":
        return (
          <TableRow sx={{ backgroundColor: item?.color }}>
            <TableCell
              sx={{
                fontSize: "0.87rem",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                lineHeight: 1,
              }}
            >
              {item?.paramter_name}{" "}
              {item?.required && <sup style={{ color: "red" }}>*</sup>}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "0.87rem",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item && (isShowCheckbox == false || item?.text_parameter) && (
                <TabParametershowCheckBox
                  checkedValue={item?.text_parameter}
                  item={item}
                  type={"text"}
                  apiCallVaultData={setCounterAPI}
                  getCheckBoxStatus={getCheckBoxStatus}
                />
              )}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "0.87rem",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {
                <IdeaTabDescriptionInputBox
                  item={item}
                  details={details}
                  // BOMValidationStatus={BOMValidationStatus}
                  allValues={allValues && allValues}
                  getAllValues={getAllValues}
                />
              }
            </TableCell>
          </TableRow>
        );
      case "dropdown":
      case "singleselect":
      case "country":
        return (
          <TableRow sx={{ backgroundColor: item?.color }}>
            <TableCell
              sx={{
                fontSize: "0.87rem",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                lineHeight: 1,
              }}
            >
              {item?.paramter_name}{" "}
              {item?.required && <sup style={{ color: "red" }}>*</sup>}
            </TableCell>

            <TableCell
              sx={{
                fontSize: "0.87rem",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.exceptions?.length > 0 && (
                <IdeaTabCheckBoxComponent
                  check={item?.exceptions?.includes(item?.value)}
                  item={item}
                  getIdeaParametersData={getIdeaParametersData}
                  setCounterAPI={setCounterAPI}
                  // BOMValidationStatus={BOMValidationStatus}
                />
              )}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "0.87rem",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              <IdeaTabSingleSelectInputBox
                item={item}
                details={details}
                // BOMValidationStatus={BOMValidationStatus}
                allValues={allValues && allValues}
                getAllValues={getAllValues}
              />
            </TableCell>
          </TableRow>
        );

      case "multiselect":
        return (
          <TableRow sx={{ backgroundColor: item?.color }}>
            <TableCell
              sx={{
                fontSize: "0.87rem",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                lineHeight: 1,
              }}
            >
              {item?.paramter_name}{" "}
              {item?.required && <sup style={{ color: "red" }}>*</sup>}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "0.87rem",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.exceptions?.length > 0 && (
                <IdeaTabCheckBoxComponent
                  check={item?.exceptions?.includes(item?.value)}
                  item={item}
                  getIdeaParametersData={getIdeaParametersData}
                  setCounterAPI={setCounterAPI}
                  // BOMValidationStatus={BOMValidationStatus}
                />
              )}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "0.87rem",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  id="tags-standard"
                  // disabled={
                  //   (copyStatus == false || copyStatus == "false") &&
                  //   (UserAction?.includes("U") || UserAction?.includes("C"))
                  //     ? false
                  //     : true
                  // }
                  disabled={
                    (!rejectedStatusActionParams && UserActionParams?.includes("U") && item?.editable)
                      ? false
                      : true
                  }
                  limitTags={1}
                  // onChange={handleOnChange}
                  disableClearable
                  disableCloseOnSelect
                  sx={{
                    ".MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator":
                      {
                        color: "primary.main",
                      },
                    ".MuiButtonBase-root.MuiChip-root": {
                      backgroundColor: "primary.light",
                      height: "26px",
                    },
                  }}
                  options={item?.allowed_values}
                  getOptionLabel={(option: any) => option}
                  defaultValue={item?.value || []}
                  onChange={(event: any, newSystemValue: any) => {
                    handleSelectSystems(
                      event,
                      newSystemValue,
                      item?.key,
                      subSystemId,
                      topVault
                    );
                  }}
                  // getOptionDisabled={getOptionDisabled}
                  renderOption={(props, option) => (
                    <li {...props} style={{ fontSize: "0.87rem" }}>
                      {option}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Select Option"
                      sx={{
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "transparent",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "0.87rem !important",
                          backgroundColor: "rgba(255,255,255,0.3)",
                          paddingLeft: "0.3rem",
                        },
                        ".MuiSvgIcon-root": { color: "primary.main" },
                      }}
                    />
                  )}
                />
              </FormControl>
            </TableCell>
          </TableRow>
        );

      case "date":
        return (
          <TableRow sx={{ backgroundColor: item?.color }}>
            <TableCell
              sx={{
                fontSize: "0.87rem",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                lineHeight: 1,
              }}
            >
              {item?.paramter_name}{" "}
              {item?.required && <sup style={{ color: "red" }}>*</sup>}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "0.87rem",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.exceptions?.length > 0 && (
                <IdeaTabCheckBoxComponent
                  check={item?.exceptions?.includes(item?.value)}
                  item={item}
                  getIdeaParametersData={getIdeaParametersData}
                  setCounterAPI={setCounterAPI}
                  // BOMValidationStatus={BOMValidationStatus}
                />
              )}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "0.87rem",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {!item?.exceptions?.includes(item?.value) ? (
                <IdeaTabDateInputBox
                  item={item}
                  details={details && details}
                  // BOMValidationStatus={BOMValidationStatus}
                  allValues={allValues && allValues}
                  getAllValues={getAllValues}
                />
              ) : (
                item?.value
              )}
            </TableCell>
          </TableRow>
        );

      case "database":
        return (
          <TableRow sx={{ backgroundColor: item?.color }}>
            <TableCell
              sx={{
                fontSize: "0.87rem",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                lineHeight: 1,
              }}
            >
              {item?.paramter_name}{" "}
              {item?.required && <sup style={{ color: "red" }}>*</sup>}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "0.87rem",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {item?.exceptions?.length > 0 && (
                <IdeaTabCheckBoxComponent
                  check={item?.exceptions?.includes(item?.value)}
                  item={item}
                  getIdeaParametersData={getIdeaParametersData}
                  setCounterAPI={setCounterAPI}
                  // BOMValidationStatus={BOMValidationStatus}
                />
              )}
            </TableCell>
            <TableCell
              sx={{
                fontSize: "0.87rem",
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {!item?.exceptions?.includes(item?.value) ? (
                <IdeaTabDatabaseInputBox
                  item={item}
                  details={details && details}
                  // BOMValidationStatus={BOMValidationStatus}
                  allValues={allValues && allValues}
                  getAllValues={getAllValues}
                />
              ) : (
                "NA"
              )}
            </TableCell>
          </TableRow>
        );
    }
  };

  const getIdeaParametersData = () => {
    setIsIdeaparametersLoading(true);
    API.get(
      "/idea/idea_create_view/",
      {
        id: vault_id,
        part_details: true,
        top_vault: topVault,
      },
      0
    )
      .then((res: any) => {
        setIsIdeaparametersLoading(false);
        setIdeaparameters(res.data);
      })
      .catch((err: any) => {
        setIsIdeaparametersLoading(false);
      });
  };
  return (
    <>
      {/*Boolean Parameter  */}
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          backgroundColor: "#ffddba",
          padding: "0 0.5rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
          }}
        >
          {!tabParameterDataLoader && tabParameterData.boolean_list ? (
            tabParameterData.boolean_list?.map((item: any, index: number) => {
              return renderBooleanInputs(item, index);
            })
          ) : (
            <>
              <Box
                sx={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <Box sx={{ padding: "0.2rem 0.5rem", width: "100%" }}>
                  <Skeleton />
                </Box>
                <Box sx={{ padding: "0.2rem 0.5rem", width: "100%" }}>
                  <Skeleton />
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <Box sx={{ padding: "0.2rem 0.5rem", width: "100%" }}>
                  <Skeleton />
                </Box>
                <Box sx={{ padding: "0.2rem 0.5rem", width: "100%" }}>
                  <Skeleton />
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <Box sx={{ padding: "0.2rem 0.5rem", width: "100%" }}>
                  <Skeleton />
                </Box>
                <Box sx={{ padding: "0.2rem 0.5rem", width: "100%" }}>
                  <Skeleton />
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <Box sx={{ padding: "0.2rem 0.5rem", width: "100%" }}>
                  <Skeleton />
                </Box>
                <Box sx={{ padding: "0.2rem 0.5rem", width: "100%" }}>
                  <Skeleton />
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <Box sx={{ padding: "0.2rem 0.5rem", width: "100%" }}>
                  <Skeleton />
                </Box>
                <Box sx={{ padding: "0.2rem 0.5rem", width: "100%" }}>
                  <Skeleton />
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <Box sx={{ padding: "0.2rem 0.5rem", width: "100%" }}>
                  <Skeleton />
                </Box>
                <Box sx={{ padding: "0.2rem 0.5rem", width: "100%" }}>
                  <Skeleton />
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <Box sx={{ padding: "0.2rem 0.5rem", width: "100%" }}>
                  <Skeleton />
                </Box>
                <Box sx={{ padding: "0.2rem 0.5rem", width: "100%" }}>
                  <Skeleton />
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <Box sx={{ padding: "0.2rem 0.5rem", width: "100%" }}>
                  <Skeleton />
                </Box>
                <Box sx={{ padding: "0.2rem 0.5rem", width: "100%" }}>
                  <Skeleton />
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <Box sx={{ padding: "0.2rem 0.5rem", width: "100%" }}>
                  <Skeleton />
                </Box>
                <Box sx={{ padding: "0.2rem 0.5rem", width: "100%" }}>
                  <Skeleton />
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <Box sx={{ padding: "0.2rem 0.5rem", width: "100%" }}>
                  <Skeleton />
                </Box>
                <Box sx={{ padding: "0.2rem 0.5rem", width: "100%" }}>
                  <Skeleton />
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <Box sx={{ padding: "0.2rem 0.5rem", width: "100%" }}>
                  <Skeleton />
                </Box>
                <Box sx={{ padding: "0.2rem 0.5rem", width: "100%" }}>
                  <Skeleton />
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <Box sx={{ padding: "0.2rem 0.5rem", width: "100%" }}>
                  <Skeleton />
                </Box>
                <Box sx={{ padding: "0.2rem 0.5rem", width: "100%" }}>
                  <Skeleton />
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
      <Box>
        {/*Other Parameter  */}
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{
            width: "100%",
            border: "1px solid #e0e0e0",
            borderCollapse: "separate",
            "& th": {
              backgroundColor: "primary.light",
              border: "1px solid #ddefff",
              borderCollapse: "separate",
              padding: "0.2rem 0.5rem",
              textAlign: "left",
              fontSize: "0.87rem",
              fontWeight: "bold",
            },
            "& td": {
              // border: "1px solid #ddefff",
              borderCollapse: "separate",
              padding: "0.2rem 0.5rem",
              textAlign: "left",
              fontSize: "0.87rem",
              // width:'50%',
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ backgroundColor: "primary.light", width: "15rem" }}
              >
                Parameter
              </TableCell>
              <TableCell
                sx={{ backgroundColor: "primary.light", width: "4rem" }}
              ></TableCell>
              <TableCell sx={{ backgroundColor: "primary.light" }}>
                Value
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Numeric */}
            {!tabParameterDataLoader && tabParameterData.numerical_list ? (
              tabParameterData.numerical_list?.map(
                (item: any, index: number) => {
                  return renderInputs(item, index);
                }
              )
            ) : (
              <>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                </TableRow>
              </>
            )}
            {/* Long Text */}
            {!tabParameterDataLoader && tabParameterData.long_text_list ? (
              tabParameterData.long_text_list?.map(
                (item: any, index: number) => {
                  return renderInputs(item, index);
                }
              )
            ) : (
              <>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                </TableRow>
              </>
            )}
            {/* Other Parameter */}
            {!tabParameterDataLoader && tabParameterData.others_list ? (
              tabParameterData.others_list?.map((item: any, index: number) => {
                return renderInputs(item, index);
              })
            ) : (
              <>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                    <Skeleton />
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
        {/*Cost Model  */}
        {vault_id != 0 && <IdeaViewCostModalComponents />}
        {/* Data table */}
        {!tableDataLoader && tableAccData ? (
          tableAccData?.map((item: any, index: number) => {
            return (
              <Accordion
                // expanded={expanded === `${index}`}
                onChange={handleChange(`${index}`)}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    columnGap: "0.5rem",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: " #e2e9aa",
                      height: "2rem",
                      width: "100%",
                      paddingRight: "0.5rem",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: "0.5rem",
                      }}
                    >
                      <CircleIcon
                        sx={{ fontSize: "0.65rem", margin: "0 0.5rem" }}
                      />
                    </Typography>
                    <IdeaTabEntryAccordian
                      expandStatus={expandStatus}
                      tableName={item?.name}
                      item={item?.id}
                      getTableData={getTableData}
                      dataForUpdate={dataForUpdate}
                      tableData={tableData}
                      setTableData={setTableData}
                      dataForCheck={item?.name}
                      setSelectedOptionsTable={setSelectedOptions}
                      setColorButton={setColorButton}
                      colorButton={colorButton}
                      setSelectedTableId={setSelectedTableId}
                      selectedTableId={selectedTableId}
                      getTableDataAll={getTableDataAll}
                      tableDataLoader={tableDataLoader}
                      setShowButton={setShowButton}
                      showButton={showButton}
                      materialCount={item?.count}                      
                      tableAccData={getTableNameCount}
                    />
                  </Box>
                </Box>
              </Accordion>
            );
          })
        ) : (
          <Box sx={{ width: "100%", padding: "0.5rem", marginTop: "2rem" }}>
            <Skeleton
              animation="wave"
              variant="rectangular"
              height="2rem"
              width="100%"
              // sx={{ marginBottom: "1rem" }}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              height="2rem"
              width="100%"
              // sx={{ marginBottom: "1rem" }}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              height="2rem"
              width="100%"
              // sx={{ marginBottom: "1rem" }}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              height="2rem"
              width="100%"
              // sx={{ marginBottom: "1rem" }}
            />
          </Box>
        )}
      </Box>
    </>
  );
}
