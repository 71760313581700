import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ClearIcon from '@mui/icons-material/Clear';
import warning from '../../../Assets/images/Warning.png';

import styles from './WariningModalFilesMail.module.scss';
import {
	Divider,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { API } from '../../../api-services';
import { ADMIN } from '../../../Redux/Services/admin.service';
import ErrorModalFilesMail from '../../ProjectMailModule/ErrorModalFilesMail';
import { useBaseModal } from '../../SearchPage/useBaseModal';
import { LoadingButton } from '@mui/lab';
const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	height: '95%',
	transform: 'translate(-50%, -50%)',
	width: '97%',
	borderRadius: '0.5rem',
	bgcolor: 'background.paper',
	p: 1,
};

interface ErrorModalFilesProps {
	isOpen: any;
	onCloseModal: any;
	ErrorMsg: any;
	setCostingStatus: any;
	setTreeCount: any;
	costData: any;
	onCloseCostModal:any;
}

const WariningModalFilesMail = (props: ErrorModalFilesProps) => {
	const {
		onCloseModal,
		isOpen,
		ErrorMsg,
		setCostingStatus,
		costData,
		setTreeCount,
		onCloseCostModal
	} = props;
	const [Show, setShow] = useState<any>(false);
	const [msg, setMsg] = useState<any>();
	const ErrorModalFiles = useBaseModal();
	const [Loader,setLoader]=useState<any>(false)

	const Closedhandler = () => {
		onCloseModal();
		setShow(false);
	};

	const ValidationHandler = () => {
		setLoader(true)
		API.put(
			`/cost/update_estimation/${costData?.cost_estimation}/`,
			{ validated: true },
			{},
			0
		)
			.then((res: any) => {
				setCostingStatus('validated');
				setTreeCount((prev: any) => prev + 1);
				onCloseModal();
				setLoader(false);
				onCloseCostModal()
				ADMIN.toast.info('Status updated successfully');

				setShow(false);
				// setCount((prev: any) => prev + 1);
			})
			.catch((err: any) => {
				const { data }: any = err?.response;
				setLoader(false)
				setMsg(data[0]);
				//   Swal.fire({
				//     icon: "error",
				//     html: `<div>
				//               <br />
				//               <p style="color:"red">${data[0]}</p>
				//             </div>`,
				//   });
			});
	};

	useEffect(() => {
		setTimeout(() => {
			setShow(true);
		}, 3000);
	}, []);


	return (
		<div>
			<Modal
				open={isOpen}
				// onClose={onCloseModal}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'>
				<Box sx={style}>
					<ErrorModalFilesMail
						isOpen={ErrorModalFiles.isOpen}
						onCloseModal={ErrorModalFiles.close}
						ErrorMsg={msg}
					/>
					<Box sx={{ width: '100%', textAlign: 'center' }}>
						{/* <img src={warning} style={{ width: '7rem' }} /> */}
						<Box
							sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
							<Typography
								style={{
									fontSize: '1rem',
									fontWeight: '600',
									width: '100%',
									textAlign: 'center',
									marginRight: '-2rem',
								}}>
								{/* Catergory Info */}
							</Typography>
							<CancelIcon
								titleAccess='Close'
								sx={{ cursor: 'pointer', margin: '0.5rem' }}
								onClick={() => Closedhandler()}
							/>
						</Box>						

						<Box sx={{marginTop:'-2rem'}}>
							<img src={warning} style={{ width: '7rem' }} />
							<Typography
								sx={{ color: 'red', fontSize: '1.5rem', fontWeight: '900' }}>
								These calculators are not used in calculating the result
								formula.
							</Typography>
						</Box>
						<Box sx={{ height: {lg:'68vh',xl:'74vh'}, width: '100%' }}>
							<Box sx={{ display: 'flex', gap: '0.5rem',width:'100%' }}>
								{ErrorMsg?.Raw_Material?.length > 0 && (
									<Box sx={{width:'100%'}}>
										<Box className={styles.calTitle}>Material</Box>
										<Box
											sx={{ height: {lg:'62vh',xl:'70vh'} }}
											adl-scrollbar='true'
											adl-scrollbar-width='0.3'>
											<Table stickyHeader>
												<TableHead>
													<TableCell
														sx={{
															padding: '0.2rem 0.5rem',
															borderBottomColor: '#ddefff',
														}}>
														Name
													</TableCell>

													<TableCell
														sx={{
															padding: '0.2rem 0.5rem',
															borderBottomColor: '#ddefff',
														}}>
														{' '}
														Calculator Name
													</TableCell>
												</TableHead>

												<TableBody>
													{ErrorMsg?.Raw_Material?.map(
														(item: any, index: any) => {
															return (
																<>
																	<TableRow>
																		<TableCell
																			sx={{
																				padding: '0.2rem 0.5rem',
																				borderBottomColor: '#ddefff',
																			}}>
																			{item?.name}
																		</TableCell>
																		<TableCell
																			sx={{
																				padding: '0.2rem 0.5rem',
																				borderBottomColor: '#ddefff',
																			}}>
																			{item?.calculator_name}
																		</TableCell>
																	</TableRow>
																</>
															);
														}
													)}
												</TableBody>
											</Table>
										</Box>
									</Box>
								)}
								{ErrorMsg?.BOP?.length > 0 && (
									<Box sx={{width:'100%'}}>
										<Box className={styles.calTitle}>Bought Part</Box>
										<Box
											sx={{ height: {lg:'62vh',xl:'70vh'} }}
											adl-scrollbar='true'
											adl-scrollbar-width='0.3'>
											<Table stickyHeader>
												<TableHead>
													<TableCell
														sx={{
															padding: '0.2rem 0.5rem',
															borderBottomColor: '#ddefff',
														}}>
														Name
													</TableCell>

													<TableCell
														sx={{
															padding: '0.2rem 0.5rem',
															borderBottomColor: '#ddefff',
														}}>
														{' '}
														Calculator Name
													</TableCell>
												</TableHead>

												<TableBody>
													{ErrorMsg?.BOP?.map((item: any, index: any) => {
														return (
															<>
																<TableRow>
																	<TableCell
																		sx={{
																			padding: '0.2rem 0.5rem',
																			borderBottomColor: '#ddefff',
																		}}>
																		{item?.name}
																	</TableCell>
																	<TableCell
																		sx={{
																			padding: '0.2rem 0.5rem',
																			borderBottomColor: '#ddefff',
																		}}>
																		{item?.calculator_name}
																	</TableCell>
																</TableRow>
															</>
														);
													})}
												</TableBody>
											</Table>
										</Box>
									</Box>
								)}
								{ErrorMsg?.Labour?.length > 0 && (
									<Box sx={{width:'100%'}}>
										<Box className={styles.calTitle}>Labour</Box>
										<Box
											sx={{ height: {lg:'62vh',xl:'70vh'} }}
											adl-scrollbar='true'
											adl-scrollbar-width='0.3'>
											<Table stickyHeader>
												<TableHead
													sx={{ backgroundColor: 'primary.light !important' }}>
													<TableCell
														sx={{
															padding: '0.2rem 0.5rem',
															borderBottomColor: '#ddefff',
														}}>
														Name
													</TableCell>

													<TableCell
														sx={{
															padding: '0.2rem 0.5rem',
															borderBottomColor: '#ddefff',
														}}>
														{' '}
														Calculator Name
													</TableCell>
												</TableHead>

												<TableBody>
													{ErrorMsg?.Labour?.map((item: any, index: any) => {
														return (
															<>
																<TableRow>
																	<TableCell
																		sx={{
																			padding: '0.2rem 0.5rem',
																			borderBottomColor: '#ddefff',
																		}}>
																		{item?.name}
																	</TableCell>
																	<TableCell
																		sx={{
																			padding: '0.2rem 0.5rem',
																			borderBottomColor: '#ddefff',
																		}}>
																		{item?.calculator_name}
																	</TableCell>
																</TableRow>
															</>
														);
													})}
												</TableBody>
											</Table>
										</Box>
									</Box>
								)}

								{ErrorMsg?.Machine?.length > 0 && (
									<Box sx={{width:'100%'}}>
										<Box className={styles.calTitle}>Machine</Box>
										<Box
											sx={{ height: {lg:'62vh',xl:'70vh'} }}
											adl-scrollbar='true'
											adl-scrollbar-width='0.3'>
											<Table stickyHeader>
												<TableHead>
													<TableCell
														sx={{
															padding: '0.2rem 0.5rem',
															borderBottomColor: '#ddefff',
														}}>
														Name
													</TableCell>

													<TableCell
														sx={{
															padding: '0.2rem 0.5rem',
															borderBottomColor: '#ddefff',
														}}>
														{' '}
														Calculator Name
													</TableCell>
												</TableHead>

												<TableBody>
													{ErrorMsg?.Machine?.map((item: any, index: any) => {
														return (
															<>
																<TableRow>
																	<TableCell
																		sx={{
																			padding: '0.2rem 0.5rem',
																			borderBottomColor: '#ddefff',
																		}}>
																		{item?.name}
																	</TableCell>
																	<TableCell
																		sx={{
																			padding: '0.2rem 0.5rem',
																			borderBottomColor: '#ddefff',
																		}}>
																		{item?.calculator_name}
																	</TableCell>
																</TableRow>
															</>
														);
													})}
												</TableBody>
											</Table>
										</Box>
									</Box>
								)}
								{ErrorMsg?.Setup?.length > 0 && (
									<Box sx={{width:'100%'}}>
										<Box className={styles.calTitle}>Setup</Box>
										<Box
											sx={{ height: {lg:'62vh',xl:'70vh'} }}
											adl-scrollbar='true'
											adl-scrollbar-width='0.3'>
											<Table stickyHeader>
												<TableHead>
													<TableCell
														sx={{
															padding: '0.2rem 0.5rem',
															borderBottomColor: '#ddefff',
														}}>
														Name
													</TableCell>

													<TableCell
														sx={{
															padding: '0.2rem 0.5rem',
															borderBottomColor: '#ddefff',
														}}>
														{' '}
														Calculator Name
													</TableCell>
												</TableHead>

												<TableBody>
													{ErrorMsg?.Setup?.map((item: any, index: any) => {
														return (
															<>
																<TableRow>
																	<TableCell
																		sx={{
																			padding: '0.2rem 0.5rem',
																			borderBottomColor: '#ddefff',
																		}}>
																		{item?.name}
																	</TableCell>
																	<TableCell
																		sx={{
																			padding: '0.2rem 0.5rem',
																			borderBottomColor: '#ddefff',
																		}}>
																		{item?.calculator_name}
																	</TableCell>
																</TableRow>
															</>
														);
													})}
												</TableBody>
											</Table>
										</Box>
									</Box>
								)}
								{ErrorMsg?.Tools?.length > 0 && (
									<Box sx={{width:'100%'}}>
										<Box className={styles.calTitle}>Tools</Box>
										<Box
											sx={{ height: {lg:'62vh',xl:'70vh'} }}
											adl-scrollbar='true'
											adl-scrollbar-width='0.3'>
											<Table stickyHeader>
												<TableHead>
													<TableCell
														sx={{
															padding: '0.2rem 0.5rem',
															borderBottomColor: '#ddefff',
														}}>
														Name
													</TableCell>

													<TableCell
														sx={{
															padding: '0.2rem 0.5rem',
															borderBottomColor: '#ddefff',
														}}>
														{' '}
														Calculator Name
													</TableCell>
												</TableHead>

												<TableBody>
													{ErrorMsg?.Tools?.map((item: any, index: any) => {
														return (
															<>
																<TableRow>
																	<TableCell
																		sx={{
																			padding: '0.2rem 0.5rem',
																			borderBottomColor: '#ddefff',
																		}}>
																		{item?.name}
																	</TableCell>
																	<TableCell
																		sx={{
																			padding: '0.2rem 0.5rem',
																			borderBottomColor: '#ddefff',
																		}}>
																		{item?.calculator_name}
																	</TableCell>
																</TableRow>
															</>
														);
													})}
												</TableBody>
											</Table>
										</Box>
									</Box>
								)}
								{ErrorMsg?.Overhead?.length > 0 && (
									<Box sx={{width:'100%'}}>
										<Box className={styles.calTitle}>Overhead</Box>
										<Box
											sx={{ height: {lg:'62vh',xl:'70vh'} }}
											adl-scrollbar='true'
											adl-scrollbar-width='0.3'>
											<Table stickyHeader>
												<TableHead>
													<TableCell
														sx={{
															padding: '0.2rem 0.5rem',
															borderBottomColor: '#ddefff',
														}}>
														Name
													</TableCell>

													<TableCell
														sx={{
															padding: '0.2rem 0.5rem',
															borderBottomColor: '#ddefff',
														}}>
														{' '}
														Calculator Name
													</TableCell>
												</TableHead>

												<TableBody>
													{ErrorMsg?.Overhead?.map((item: any, index: any) => {
														return (
															<>
																<TableRow>
																	<TableCell
																		sx={{
																			padding: '0.2rem 0.5rem',
																			borderBottomColor: '#ddefff',
																		}}>
																		{item?.name}
																	</TableCell>
																	<TableCell
																		sx={{
																			padding: '0.2rem 0.5rem',
																			borderBottomColor: '#ddefff',
																		}}>
																		{item?.calculator_name}
																	</TableCell>
																</TableRow>
															</>
														);
													})}
												</TableBody>
											</Table>
										</Box>
									</Box>
								)}
							</Box>
						</Box>
						{Show && (
							<Box sx={{ textAlign: 'end', }}>
								
								<LoadingButton
									size='small'
									onClick={() => ValidationHandler()}
									sx={{
										marginTop: '1rem',
										backgroundColor: '#008000',
										'&:hover': {
											transform: 'Scale(1.05)',
											transition: 'transform 0.5s ease',
										},
									}}
                                    loading={Loader}
									variant='contained'>
									Ignore & Validate
								
								</LoadingButton>
							</Box>
						)}
					</Box>
				</Box>
			</Modal>
		</div>
	);
};

export default WariningModalFilesMail;
