import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { TreeItemProps, treeItemClasses } from "@mui/lab/TreeItem";
import Collapse from "@mui/material/Collapse";
// web.cjs is required for IE11 support

import { TransitionProps } from "@mui/material/transitions";
import { animated, useSpring } from "@react-spring/web";
import openFolder from "../../Assets/images/svgs/openfolderblue.svg";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FolderIcon from "@mui/icons-material/Folder";
import styles from "../../ViewModule/ViewComponents.module.scss";
import { useParams, useRouteMatch } from "react-router-dom";
import {
  Divider,
  MenuItem,
  Tooltip,
  TooltipProps,
  Typography,
  tooltipClasses,
} from "@mui/material";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import { useBaseModal } from "../SearchPage/useBaseModal";
import { DialogComponent } from "../../utlis/DialogComponent";
import TreeInfoModal from "../CostingCreateModule/TreeInfoModal";
// import { ControlledMenu } from "@szhsin/react-menu";
import { ViewBOMImagesModal } from "./ViewBOMImagesModal";
import WarningIcon from "@mui/icons-material/Warning";
import { API } from "../../api-services";
import Swal from "sweetalert2";
import { ADMIN } from "../../Redux/Services/admin.service";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
function TransitionComponent(props: TransitionProps) {
  /*  */
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
      display: "none",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
      display: "block",
    },
  });

  return (
    <animated.div style={style} className="Tempdekho">
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = styled((props: TreeItemProps) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 10,
    paddingLeft: 8,
    borderLeft: `1px dashed ${theme.palette.text.primary}`,
  },
}));

interface TreeViewComponentsProps {
  HierarchyData: any;
  onSelectCallback: any;
}
const CreateScenarioTree = (props: TreeViewComponentsProps) => {
  const { HierarchyData, onSelectCallback } = props;
  const { topVault, vault, projectId, scenarioType } = useParams<any>();
  const infoModal = useBaseParamsModal();
  const viewBOMImageOpen = useBaseParamsModal();
  const { url } = useRouteMatch();
  const openStatusContent = useBaseModal();
  const [activeId, setActiveId] = React.useState<any>(null);
  const [contentText, setContentText] = React.useState<any>("");
  const [iconText, setIconText] = React.useState<any>("");
  const [HierarchyAllData, setHierarchyAllData] = React.useState<any>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [selectedData, setSelectedData] = React.useState<any>({});
  const [isOpenContext, setOpenContext] = React.useState(false);
  const [warningLoader, setWarningLoader] = useState<any>(false);
  const [warningMsg, setwarningMsg] = useState<any>();
  const [anchorPointCosting, setAnchorPointCosting] = React.useState({
    x: 0,
    y: 0,
  });

  // useEffect(()=>{
  //   sessionStorage.setItem(
  //     "nodeCostingEstimate",
  //     JSON.stringify([Number(topVault)])
  //   );

  // },[])

  const [expanded, setExpanded] = React.useState<any>([parseInt(topVault)]);
  const [titleString, setTitleString] = React.useState<any>("");
  const [selected, setSelected] = React.useState<any>(parseInt(topVault));

  React.useEffect(() => {
    let a: any = sessionStorage.getItem("nodeCostingScenario");
    let val: any = JSON.parse(a) || [];
    if (!val?.includes(topVault)) {
      sessionStorage.setItem("node", JSON.stringify([parseInt(topVault)]));
    }
  }, [projectId]);

  // useEffect(()=>{return sessionStorage.setItem("node",JSON.stringify([Number(topVault)]));},[topVault])

  const handleChange = (
    e: any,
    nodeId: any,
    abbreviation: any,
    statusss: any,
    isCategory: any,
    item: any
  ) => {
    let a: any = sessionStorage.getItem("nodeCostingScenario");
    let val: any = JSON.parse(a) || [];
    if (val?.includes(nodeId)) {
      let noDuplicate: any = val?.filter((item: any) => {
        return item != nodeId;
      });
      sessionStorage.setItem(
        "nodeCostingScenario",
        JSON.stringify(noDuplicate)
      );
      sessionStorage.setItem("node", JSON.stringify(noDuplicate));
    } else {
      val?.push(nodeId);
      sessionStorage.setItem("nodeCostingScenario", JSON.stringify(val));
      sessionStorage.setItem("node", JSON.stringify(val));
    }
    setActiveId(nodeId);

    if (item?.is_delta) {
      onSelectCallback(nodeId, abbreviation, topVault, statusss, isCategory);
      Swal.fire({
        icon: "warning",
        title: "Cost Difference more than 25%.",
        // confirmButtonText: "Update",
        // showCancelButton: true,
        cancelButtonText: "Ok",
        // showConfirmButton: warningMsg?.updated === false ? true : false,
        html: `<div style="display: flex !important;
          flex-direction: column !important;
          align-items: baseline !important">
        
   
              
                      <div>
                        <Typography sx={{ fontSize: "1rem", color: "#007fff" }}>Last Scenario Name: <span style={{ color: "#007fff" }}>${
                          warningMsg?.last_scenario
                        }</span></Typography>
              
                      </div>
                      <div>
                        <Typography sx={{ fontSize: "1rem", color: "#007fff" }}>New Scenario Name: <span style={{ color: "#007fff" }}>${
                          warningMsg?.new_scenario
                        }</span></Typography>
              
                      </div>
                      <div>
                        <Typography sx={{ fontSize: "1rem", color: "#007fff" }}>Delta Value: <span style={{ color: "#007fff" }}>${warningMsg?.delta_value?.toFixed(
                          2
                        )}</span></Typography>
              
                      </div>
              
              
                     </div>`,
      }).then((result) => {
        if (result.value) {
          // API.get(
          //   "/cost/cost_calculator_update/",
          //   { vault: warningMsg?.vault },
          //   0
          // )
          //   .then((res: any) => {
          //     ADMIN.toast.info("Delta sync is in progress.");
          //   })
          //   .catch((err: any) => {});
        } else {
          // Do nothing.
        }
      });

      //        title:"Cost Difference more than 25%.",
      //        text
      //     html:

      //       //  @ts-ignore
      // //        content:(<div>

      // // <span>

      // //         <Typography sx={{ textAlign: "center", margin: "0.5rem", fontSize: "1rem", color: "#007fff" }}>Cost Difference more than 25%.</Typography>
      // //         <Divider />

      // //         <div>
      // //           <Typography sx={{ fontSize: "1rem", color: "#007fff" }}>Last Scenario Name: <span style={{ color: "#007fff" }}>${warningMsg?.last_scenario}</span></Typography>

      // //         </div>
      // //         <div>
      // //           <Typography sx={{ fontSize: "1rem", color: "#007fff" }}>New Scenario Name: <span style={{ color: "#007fff" }}>${warningMsg?.new_scenario}</span></Typography>

      // //         </div>
      // //         <div>
      // //           <Typography sx={{ fontSize: "1rem", color: "#007fff" }}>Delta Value: <span style={{ color: "#007fff" }}>${warningMsg?.delta_value?.toFixed(2)}</span></Typography>

      // //         </div>

      // //         </span>

      // //        </div>),

      //          icon: "warning",
      //         //  @ts-ignore
      //         buttons: "OK",
      //       }).then((isData: any) => {
      //         if (isData) {
      //           onSelectCallback(nodeId, abbreviation, topVault, statusss, isCategory);
      //         }
      //       }).catch((e: any) => {
      //       });
    } else {
      onSelectCallback(nodeId, abbreviation, topVault, statusss, isCategory);
    }

    sessionStorage.setItem("addScenarioStatus", statusss);
    const index = expanded.indexOf(nodeId);

    const copyExpanded = [...expanded];
    if (index === -1) {
      copyExpanded.push(nodeId);
    } else {
      copyExpanded.splice(index, 1);
    }
    setExpanded(copyExpanded);
    setSelected(copyExpanded);
  };

  useEffect(() => {
    let a: any = sessionStorage.getItem("nodeCostingScenario");
    let val: any = JSON.parse(a);
    if (val != null) {
      setExpanded(val);
      setSelected(val);
    }
  }, [topVault, sessionStorage.getItem("nodeCostingScenario")]);

  useEffect(() => {
    setHierarchyAllData(HierarchyData && HierarchyData);
  }, [HierarchyData]);

  const MoveEnterHandler = (item: any) => {
    if (item?.is_delta == true) {
      setWarningLoader(true);
      API.get(`/cost/scenario/`, { scenario_delta: true, vault: item?.id }, 0)
        .then((res: any) => {
          setWarningLoader(false);
          setwarningMsg(res?.data);
        })
        .catch((err: any) => {
          setWarningLoader(false);
        });
    }

  };

  // useEffect(() => {
  //   document.getElementById(vault)?.scrollIntoView({
  //     block: "nearest",
  //     inline: "start",
  //     behavior: "smooth",
  //   });
  // }, [vault]);

  return (
    <>
      {/* <ControlledMenu
        anchorPoint={anchorPointCosting}
        position="anchor"
        transition
        state={isOpenContext ? "open" : "closed"}
        direction="right"
        onClose={() => setOpenContext(false)}
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      >
        <MenuItem sx={{ color: "primary.main", fontSize: "1rem" }}>
          Main Costing
        </MenuItem>
        <MenuItem
          sx={{ color: "primary.main", fontSize: "1rem" }}
          onClick={() => {
            window.open(
              `/#/view/${projectId}/${topVault}/${selectedData?.id}/${selectedData?.abbreviation}/true`
            );
          }}
        >
          Go to BOM
        </MenuItem>
        <MenuItem
          sx={{ color: "primary.main", fontSize: "1rem" }}
          onClick={() => {
            viewBOMImageOpen.open(selectedData);
          }}
        >
          View images
        </MenuItem>
      </ControlledMenu> */}

      <TreeView
        expanded={expanded}
        key={HierarchyAllData?.id}
        id={HierarchyAllData?.id}
        defaultParentIcon={<FolderIcon fill="primary" />}
        className={styles.treetemp}
        defaultCollapseIcon={
          HierarchyAllData &&
          HierarchyAllData?.is_leaf == false && (
            <img src={openFolder} style={{ width: "18px", color: "#00887a" }} />
          )
        }
        defaultExpandIcon={
          HierarchyAllData?.is_leaf == false && <FolderIcon color="primary" />
        }
        defaultEndIcon={
          HierarchyAllData?.is_leaf ? (
            <InsertDriveFileIcon color="primary" />
          ) : (
            <FolderIcon color="primary" />
          )
        }
        onMouseEnter={() => MoveEnterHandler(HierarchyAllData)}
        onNodeSelect={(e: any) =>
          handleChange(
            e,
            HierarchyAllData?.id,
            HierarchyAllData?.abbreviation,
            HierarchyAllData?.cost_status,
            HierarchyAllData?.is_category,
            HierarchyAllData
          )
        }
        selected={selected}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        sx={{
          width: "100%",
          // minWidth: { lg: "18vw", xl: "20vw" },
          // maxWidth: { lg: "22rem", xl: "30rem" },
          "&.MuiTreeItem-content{}.Mui-selected": {
            backgroundColor:
              HierarchyAllData?.id == vault
                ? "#cee6fa !important"
                : "white !important",
          },
        }}
      >
        <StyledTreeItem
          data-index={HierarchyAllData?.id}
          nodeId={HierarchyAllData?.id}
          //   onMouseEnter={() =>
          //     hoverData(
          //       `${HierarchyAllData?.part_no} ${
          //         HierarchyAllData?.part_no ? "x" : ""
          //       } ${HierarchyAllData?.quantity} ${HierarchyAllData?.name}`,
          //       HierarchyAllData.id
          //     )
          //   }
          title={titleString && url.includes("costing") && titleString}
          label={
            <Typography
              className={styles.treeViewData}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                columnGap: "0.5rem",
              }}
            >
              <Typography
                style={{
                  fontSize: "12px",
                  color: HierarchyAllData?.colour,
                  lineHeight: "1.2",
                  padding: "0.2rem 0",
                }}
                className={styles.treeViewText}
                // onContextMenu={(event: any) =>
                //   handleClick(
                //     event,
                //     HierarchyAllData.id,
                //     HierarchyAllData.is_leaf,
                //     HierarchyAllData
                //   )
                // }stopPropagation
              >
                <Typography
                  sx={{
                    color:
                      HierarchyAllData?.independent > 0 ||
                      HierarchyAllData?.dependent
                        ? "#833406"
                        : "primary.main",
                    display: "flex",
                    alignItems: "center",
                    // justifyContent: "space-between",
                    width: "100%",
                    fontSize: "12px",
                  }}
                >
                  {HierarchyAllData?.part_no} x {HierarchyAllData?.quantity}
                </Typography>
                <Typography
                  sx={{
                    color:
                      HierarchyAllData?.independent > 0 ||
                      HierarchyAllData?.dependent
                        ? "#833406"
                        : "primary.main",
                    fontSize: "12px",
                    textTransform: "capitalize",
                    lineHeight: "1.2",
                  }}
                >
                  {HierarchyAllData?.name}
                </Typography>
              </Typography>

              {
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "4rem",
                    justifyContent: "space-evenly",
                  }}
                >
                  {HierarchyAllData?.is_delta == true && (
                    <Typography
                      sx={{
                        fontSize: "12px",
                        textTransform: "capitalize",
                        lineHeight: "1.2",
                        cursor: warningLoader ? "wait" : "pointer",
                      }}
                    >
                      <LightTooltip
                        title={
                          <div>
                            <Typography
                              sx={{
                                textAlign: "center",
                                margin: "0.5rem",
                                fontSize: "1rem",
                                color: "primary.main",
                              }}
                            >
                              Cost Difference more than 25%.
                            </Typography>
                            <Divider />

                            <div>
                              <Typography
                                sx={{ fontSize: "1rem", color: "primary.main" }}
                              >
                                Last Scenario Name:{" "}
                                <span style={{ color: "primary.main" }}>
                                  {warningMsg?.last_scenario}
                                </span>
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                sx={{ fontSize: "1rem", color: "primary.main" }}
                              >
                                New Scenario Name:{" "}
                                <span style={{ color: "primary.main" }}>
                                  {warningMsg?.new_scenario}
                                </span>
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                sx={{ fontSize: "1rem", color: "primary.main" }}
                              >
                                Delta Value:{" "}
                                <span style={{ color: "primary.main" }}>
                                  {warningMsg?.delta_value?.toFixed(2)}
                                </span>
                              </Typography>
                            </div>
                          </div>
                        }
                        placement="right-start"
                      >
                        <WarningIcon color="warning" />
                      </LightTooltip>
                    </Typography>
                  )}
                  {!HierarchyAllData?.is_category && (
                    <>
                      <Typography
                        sx={{
                          fontSize: "0.87rem",
                          width: "2rem",
                          display: "flex",
                          alignItems: "center",
                          whiteSpace: "nowrap",
                          fontWeight: "600",
                          justifyContent: "center",
                        }}
                      >
                        {HierarchyAllData?.independent}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "0.87rem",
                          width: "2rem",
                          display: "flex",
                          alignItems: "center",
                          whiteSpace: "nowrap",
                          fontWeight: "600",
                          justifyContent: "center",
                        }}
                      >
                        {HierarchyAllData?.dependent}
                      </Typography>
                    </>
                  )}
                </Typography>
              }
            </Typography>
          }
          onContextMenu={(e) => {
            e.stopPropagation();
            e.preventDefault();
            !HierarchyAllData?.is_category && setSelectedData(HierarchyAllData);
            !HierarchyAllData?.is_category &&
              setAnchorPointCosting({ x: e.clientX, y: e.clientY });
            !HierarchyAllData?.is_category && setOpenContext(true);
          }}
        >
          {HierarchyAllData &&
            HierarchyAllData?.subsystem?.map((subsystemData: any) => {
              return (
                <>
                  <CreateScenarioTree
                    HierarchyData={subsystemData}
                    key={subsystemData?.id}
                    onSelectCallback={onSelectCallback}
                  />
                </>
              );
            })}
        </StyledTreeItem>
      </TreeView>
      <DialogComponent
        isOpen={openStatusContent.isOpen}
        onClose={openStatusContent.close}
        content={contentText}
        type={iconText}
      />
      {infoModal.isOpen && (
        <TreeInfoModal
          isOpen={infoModal.isOpen}
          onCloseModal={infoModal.close}
          item={infoModal.propsId}
        />
      )}
      {viewBOMImageOpen.isOpen && (
        <ViewBOMImagesModal
          isOpen={viewBOMImageOpen.isOpen}
          onCloseModal={viewBOMImageOpen.close}
          vault={vault}
          topVault={topVault}
          treeabbr={selectedData?.abbreviation}
          dataAll={viewBOMImageOpen.propsId}
        />
      )}
    </>
  );
};

export default CreateScenarioTree;
