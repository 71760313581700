import { Typography, Grid, CardContent,Box, Button, styled, Card  } from '@mui/material';

import React, { useState } from 'react'


const StyledCard = styled(Card)(({ theme }) => ({
    minWidth: "200px",
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    // backgroundColor: '#e8f5e9',
    borderRadius: "8px",
    boxShadow: theme.shadows[1],
  }));



function SupplierLocations({supplierDetails}:any) {
    const [showAll, setShowAll] = useState(false);

    const handleShowAllClick = () => {
      setShowAll(!showAll);
    };
    const filteredRecords = supplierDetails?.records?.filter(
      (item: any) => item?.active === true
    );
    const visibleRecords = showAll
    ? filteredRecords
    : filteredRecords?.slice(0, 4);


    



  return (
    <div>
         {visibleRecords &&
              Array.isArray(visibleRecords) &&
              visibleRecords?.length > 0 ? (
              <>
                <Box
                  id="mfg_locations"
                  sx={{ height: "0", marginTop: "2rem" }}
                  className="inner-container"
                
                />
                <Box
                  sx={
                    {
                      // marginBottom: "1.5rem",
                      // borderTop: "0.1px solid #f0f0f0",
                      // padding: "0 1.5rem",
                    }
                  }
                >
                  <Typography
                    sx={{ fontWeight: "600" }}
                  
                    gutterBottom
                  >
                    Locations of {supplierDetails?.business_name}
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexWrap: "wrap",
                      gap: "1%",
                      width: "100%",
                    }}
                  >
                    {Array.isArray(visibleRecords) &&
                      visibleRecords?.length > 0 && (
                        <Box sx={{ width: "100%" }}>
                          <Box>
                            <Grid container spacing={2}>
                              {visibleRecords?.map(
                                (record: any, index: any) => (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    lg={3}
                                    key={index}
                                  >
                                    <StyledCard
                                      elevation={1}
                                      sx={{
                                        // backgroundColor: "#f9f9f9",
                                        // boxShadow: "none",
                                        // boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
                                        height: {
                                          xs: "fit-content",
                                          md: "10rem",
                                        },
                                        padding: "0",
                                        margin: "0",
                                      }}
                                    >
                                      <CardContent>
                                        <Typography
                                          sx={{ marginBottom: "0.75rem" }}
                                        >
                                          {record?.legalName}
                                        </Typography>

                                        {(record?.primaryAddress ||
                                          record?.address_city ||
                                          record?.address_district ||
                                          record?.address_state ||
                                          record?.address_country) && (
                                            <Typography
                                              sx={{
                                                color: "#000",
                                                marginBottom: "0.5rem",
                                              }}
                                            >
                                              Address:
                                              {`
                                  ${record?.primaryAddress?.street
                                                  ? record?.primaryAddress?.street
                                                  : record?.address_line
                                                    ? record?.address_line
                                                    : ""
                                                }
                                  ${record?.primaryAddress?.city
                                                  ? record?.primaryAddress?.city
                                                  : record?.address_city
                                                    ? record?.address_city
                                                    : ""
                                                }  
                                  ${record?.primaryAddress?.district
                                                  ? record?.primaryAddress?.district
                                                  : record?.address_district
                                                    ? record?.address_district
                                                    : ""
                                                } 
                                  ${record?.primaryAddress?.state
                                                  ? record?.primaryAddress?.state
                                                  : record?.address_state
                                                    ? record?.address_state
                                                    : ""
                                                }
                                  ${record?.primaryAddress?.country
                                                  ? record?.primaryAddress?.country
                                                  : record?.address_country
                                                    ? record?.address_country
                                                    : ""
                                                }
                                  `}
                                            </Typography>
                                          )}
                                        <Typography sx={{ color: "#555" }}>
                                          GST No:
                                          {record?.gstin || ""}
                                        </Typography>
                                      </CardContent>
                                    </StyledCard>
                                  </Grid>
                                )
                              )}
                            </Grid>
                          </Box>

                          {visibleRecords?.length > 4 && (
                            <Box sx={{ textAlign: "center" }}>
                              <Button
                                variant="outlined"
                                onClick={handleShowAllClick}
                              >
                                {!showAll ? "Show All" : "Show Less"}
                              </Button>
                            </Box>
                          )}
                        </Box>
                      )}
                    {/* <SupplierDetailsComponentMobile supplierDetails={supplierDetails} /> */}
                  </Box>
                </Box>
              </>
            ) : (
              ""
            )}
    </div>
  )
}

export default SupplierLocations