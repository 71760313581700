import * as React from "react";
import Table from "@mui/material/Table";
import {
  Box,
  FormControlLabel,
  FormGroup,
  Skeleton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import SearchBar from "../../ComponentUI/Header/SearchBar";
import CachedIcon from "@mui/icons-material/Cached";
import libraryIcon from "../../Assets/images/library.svg";
import { Checkbox, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory, useRouteMatch } from "react-router-dom";
import styles from "./CostingConfigurationLanding.module.scss";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import guidelines from "../../Assets/images/guidelines.svg";
import calculator from "../../Assets/images/calculator.svg";
import ShareIcon from "@mui/icons-material/Share";
import ShareCostingModel from "./ShareCositngModel";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import WatermarkModel from "./WatermarkModel";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import SaveIcon from "@mui/icons-material/Save";
import InfoIcon from "@mui/icons-material/Info";
import { API } from "../../api-services";
import CostingConfigurationTreeViewcomponents from "./CostingTreeViewComponents";
import { LoadingButton } from "@mui/lab";
import { EditName } from "./EditName";
import DeleteIcon from "@mui/icons-material/Delete";
import { EditDescription } from "./EditDescription";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import LibraryInfoDetailsModal from "./LibraryInfoDetailsModal";
import { deleteSwal } from "../../utlis/deleteSwal";
import ValidatedGuidelines from "../../Assets/images/guidelines_green.svg";
import ValidatedCalcImg from "../../Assets/images/calculator_green.svg";
import ValidatedGuidelineImg from "../../Assets/images/guidelines_green.svg";
import AssumptionCard from "./AssumptionCard";
import { useEffect } from "react";
import Swal from "sweetalert2";
import successIcon from "../../Assets/images/success.png";
import CostingConfigurationDrawer from "./CostingConfigurationDrawer";
import { useDocumentTitle } from "../../ui-reusable-component/useDocumentTitle";

type Anchor = "top" | "left" | "bottom" | "right";

interface ICostingConfiguarationCommodityandProcessProps {}

function CostingConfiguarationCommodityandProcess(
  props: ICostingConfiguarationCommodityandProcessProps
) {
  const history = useHistory();
  useDocumentTitle( "Costing Config" );
  const { url } = useRouteMatch();
  const { library, vehCateId, projectname, selectedIdTree, dataType } =
    useRouteParams<any>();
  const [selectedLibrary, setSelectedLibrary] = React.useState<any>(library);
  const [counterApiCall, setCounterApiCall] = React.useState<any>(0);
  const [processData, setProcessData] = React.useState<any>([]);
  const [processCreateCount, setProcessCreateCount] = React.useState<any>(0);
  const [commodityCreateCount, setCommodityCreateCount] =
    React.useState<any>(0);
  const [commodityData, setCommodityData] = React.useState<any>([]);
  const [AllHierarchy, setAllHierarchy] = React.useState<any>([]);
  const [isTreeLoading, setIsTreeLoading] = React.useState<any>(false);
  const [createName, setCreateName] = React.useState<any>("");
  const [createDes, setCreateDes] = React.useState<any>("");
  const [createNameProcess, setCreateNameProcess] = React.useState<any>("");
  const [createDesProcess, setCreateDesProcess] = React.useState<any>("");
  const [editName, setEditName] = React.useState<any>("");
  const [editDes, setEditDes] = React.useState<any>("");
  const [selectedTreeData, setSelectedTreeData] = React.useState<any>({});
  const [isLoading, setIsLoading] = React.useState<any>(false);
  const [isLoadingProcess, setIsLoadingProcess] = React.useState<any>(false);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [open, setOpen] = React.useState(false);
  const shareModal = useBaseModal();
  const WatermrkModel = useBaseModal();
  const libraryInfoModal = useBaseParamsModal();
  const AssumptionCardModal = useBaseModal();
   const [useraction,setuseraction]=React.useState<any>(null)
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

 
  React.useEffect(() => {
    API.get(
      "/auth/user_permission_module/",
      {
        action: true,
        configuration: true,
        vehicle_category: vehCateId,
        module:"Costing Configuration",
        
      },
      0

    )
      .then((res: any) => {
         setuseraction(res?.data?.action)
        sessionStorage.setItem("ConfigPermission", res.data.action);
      })
      .catch((err: any) => { });
  }, []);
  //@ts-ignore
  // const validationStatus = JSON.parse(
  //   sessionStorage.getItem("otherValidation")
  // );

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };
  const getTreeData = () => {
    setIsTreeLoading(true);
    API.get(
      "/cost/costing_configuration/",
      {
        vehicle_category: vehCateId,
        costing_config_hier: true,
      },
      0
    )
      .then((res: any) => {
        setAllHierarchy(res.data[0]?.data);
        setIsTreeLoading(false);
      })
      .catch((err: any) => {
        setIsTreeLoading(false);
      });
  };
  React.useEffect(() => {
    getTreeData();
  }, []);

  const handleChangeName = React.useMemo(()=>(e:any) => {
    const {value}=e.target;
    setCreateName(value);
  }, []);

  const handleChangeNameProcess = React.useMemo(()=>(e:any) => {
    const {value}=e.target;
    setCreateNameProcess(value);
  }, []);


  const handleChangeDescription = React.useMemo(()=>(e:any) => {
    const {value}=e.target;
    setCreateDes(value);
  }, []);

  const handleChangeDescriptionProcess = React.useMemo(()=>(e:any) => {
    const {value}=e.target;
    setCreateDesProcess(value);
  }, []);

  const onSelect = (
    nodeId: any,
    id: any,
    HierarchyAllData: any,
    itemId: any
  ) => {
    if (HierarchyAllData.type === "calculator") {
      history.push(
        `/costingconfiguration/${vehCateId}/${projectname}/${itemId}/${HierarchyAllData.type}/`
      );
    } else {
      history.push(
        `/costingconfiguration/${vehCateId}/${projectname}/${itemId}/${dataType}/${library}`
      );
    }
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
     
      <CostingConfigurationDrawer VehiclecategoryId={vehCateId}  projectname={projectname}/>
    </Box>
  );

  const handleClick = () => {
    history.push(
      `/costingconfiguration/${vehCateId}/${projectname}/${selectedIdTree}/${
        dataType || 0
      }`
    );
  };

  const comapnionHandle = () => {
    API.get("/web_companion/comapanion_activity_log", {
      //   get_recycle_bin_data_count: true
      project: vehCateId, viewed: true
    }, 0).then((res: any) => {
      // console.log(res?.data.count, "countdata")
    }).catch((err: any) => {
      // console.log("Server error")
      //   SetLoader(false)
    })
    history.push(`/companionConfig/${vehCateId}/${projectname}`);
  };


  const getCalcData = (data: any) => {  }

  const costingModel = () => {
    history.push(`/costingconfiguration/${vehCateId}/${projectname}/0/0`);
  };

  const guideHandler = () => {
    history.push(
      `/costingconfiguration/${vehCateId}/${projectname}/guidelines`
    );
  };

  const assumptionHandler = () => {
    history.push(
      `/costingconfiguration/${vehCateId}/${projectname}/assumptioncard/0/1/true/true`
    );
  };

  const getProcessData = () => {
    setIsLoadingProcess(true);
    API.get(
      "/cost/costing_configuration/",
      {
        get_library: "process_library",
      },
      0
    )
      .then((res: any) => {
        setProcessData(res.data);
        setIsLoadingProcess(false);
      })
      .catch((err: any) => {
        setIsLoadingProcess(false);
      });
  };
  const [otherValidation, setOtherValidation] = React.useState<any>({});
  const getAllResult = () => {
    API.get(
      "/cost/share_costing_config/",
      {
        project: vehCateId,
        costing_config: true,
      },
      0
    )
      .then((res: any) => {
        setOtherValidation(res.data[0]);
        sessionStorage.setItem("otherValidation", JSON.stringify(res.data[0]));
      })
      .catch((err: any) => {});
  };
  React.useEffect(() => {
    getAllResult();
  }, []);
  const getCommodityData = () => {
    setIsLoading(true);
    API.get(
      "/cost/costing_configuration/",
      {
        get_library: "commodity_library",
      },
      0
    )
      .then((res: any) => {
        setCommodityData(res.data);
        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  };
  const handleCreate = async () => {
    const response = await API.post(
      "/cost/costing_configuration/",
      {
        add_data: "commodity_library",
        name: createName,
        description: createDes,
      },
      {},
      0
    );
    if (response.data.error) {
    } else {
      setCreateName("");
      setCreateDes("");
      Swal.fire({
        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
        <br />
        <p style="color:#007fff;">Commodity Created Successfully!</p>   
         </div>`,
      });

      getCommodityData();
      getTreeData()
    }
  };
  const handleCreateProcess = async () => {
    const response = await API.post(
      "/cost/costing_configuration/",
      {
        add_data: "process_library",
        name: createNameProcess,
        description: createDesProcess,
      },
      {},
      0
    );

    if (response.data.error) {
    } else {
      setCreateNameProcess("");
      setCreateDesProcess("");
      Swal.fire({
        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
        <br />
        <p style="color:#007fff;">Process Created Successfully!</p>   
         </div>`,
      });
      getProcessData();
      getTreeData()
    }
    // .then((res: any) => {
    //   setCreateNameProcess("");
    //   setCreateDesProcess("");
    //   Swal.fire({
    //     html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
    //   <br />
    //   <p style="color:#007fff;">Process Created Successfully!</p>
    //    </div>`,
    //   });
    //   getProcessData();

    // })
    // .catch((err: any) => {});
  };
  // React.useEffect(() => {
  //   getProcessData();
  //   getTreeData();
  // }, [processCreateCount]);
  // React.useEffect(() => {
  //   getCommodityData();
  //   getTreeData();
  // }, [commodityCreateCount]);
  React.useEffect(() => {
    getProcessData();
    getCommodityData();
  }, []);

  return (
    <div>
      {shareModal.isOpen && (
        <ShareCostingModel
          isOpen={shareModal.isOpen}
          onCloseModal={shareModal.close}
        />
      )}
      {WatermrkModel.isOpen && (
        <WatermarkModel
          isWMOpen={WatermrkModel.isOpen}
          onCloseModal={WatermrkModel.close}
        />
      )}
      {/* {AssumptionCardModal.isOpen && (
        <AssumptionCard
          isASCDopen={AssumptionCardModal.isOpen}
          isASCDclose={AssumptionCardModal.close}
        />
      )} */}

      <Box sx={{ margin: "0 0.5rem" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: "1rem" }}
          >
            <IconButton
              sx={{ cursor: "pointer" }}
              title="Go Back"
              onClick={handleClick}
            >
              <ArrowBackIcon color="primary" titleAccess="Go Back" />
            </IconButton>
            <Typography
              sx={{
                fontSize: "1rem",
                // whiteSpace: "nowrap",
                fontWeight: "500",
                width: "16.5vw",
                lineHeight: "1.2",
              }}
            >
              {projectname} (Costing Configuration-Cost Model)
            </Typography>
            <Typography
              sx={{ fontSize: "1rem", whiteSpace: "nowrap", fontWeight: "500" }}
            >
              Commodity & Process Library
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: "1rem" }}
          >
            {/* {otherValidation?.guidline_config ? (
              <img
                src={ValidatedGuidelines}
                title="Guidelines"
                style={{ width: "1.5rem", cursor: "pointer" }}
              />
            ) : (
              <img
                src={guidelines}
                title="Guidelines"
                style={{ width: "1.5rem", cursor: "pointer" }}
              />
            )} */}
            {/* <img
              src={calculator}
              title="Calculator"
              style={{ width: "1.5rem", height: "auto", cursor: "pointer" }}
              onClick={() => costingModel()}
            /> */}
            {/* <CreditCardIcon
              sx={{
                fontSize: "2.5rem",
                cursor: "pointer",
                color: otherValidation?.assumption_cofig ? "green" : "",
              }}
              titleAccess="Assumption Card"
              // onClick={() => AssumptionCardModal.open()}
              onClick={() => assumptionHandler()}
            /> */}
           { 
           
          //  useraction && useraction?.includes("C") &&
           
           
           <ShareIcon
              titleAccess="Share"
              sx={{ cursor: "pointer" }}
              onClick={() => shareModal.open()}
            />}
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}
                >
                  <Box
                    sx={{ padding: "1rem 0rem", paddingBottom: "0" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                  >
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}
                    >
                      Costing Configuration
                    </Typography>
                    <Divider sx={{ margin: "0.5rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  style={{
                    transform: "rotate(270deg)",
                    whiteSpace: "nowrap",
                    marginTop: "40vh",
                    cursor: "pointer",
                    fontSize: "1rem",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  Costing Configuration
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{ width: "100%", display: "flex", alignItems: "flex-start" }}
          >
            <Box
              sx={{
                width: "20vw",               
                height: { lg: "86vh", xl: "89vh" },
                padding: "0 1rem",
              }}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3"
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  columnGap: "0.5rem",
                  justifyContent: "flex-end",
                  position: "sticky",
                  top: "0",
                  zIndex: 1,
                  paddingRight: "0.5rem",
                  backgroundColor: "white",
                }}
              >                
                <CachedIcon
                  titleAccess="Sync Calculator"
                  sx={{ cursor: "pointer" }}
                  onClick={() => getTreeData()}
                />
                <img
                  src={calculator}
                  title="Calculator"
                  style={{ width: "1.1rem", height: "auto", cursor: "pointer" }}
                  onClick={() => costingModel()}
                />
              </Box>
              {!isTreeLoading && AllHierarchy?.length>0? (
                AllHierarchy?.map((item: any) => {
                  return (
                    <CostingConfigurationTreeViewcomponents
                      HierarchyData={item && item}
                      onSelectCallback={onSelect}
                      setSelectedTreeData={getCalcData}
                       useraction={useraction}
                      setCounterApiCall={setCounterApiCall}
                    />
                  );
                })
              ) : (
                <Box sx={{ width: "100%", padding: "0 0.5rem" }}>
                  <Skeleton sx={{ height: "2rem", width: "30%" }} />
                  <Skeleton sx={{ height: "2rem", width: "45%" }} />
                  <Skeleton sx={{ height: "2rem", width: "50%" }} />
                  <Skeleton sx={{ height: "2rem", width: "65%" }} />
                  <Skeleton sx={{ height: "2rem", width: "70%" }} />
                  <Skeleton sx={{ height: "2rem", width: "60%" }} />
                  <Skeleton sx={{ height: "2rem", width: "55%" }} />
                  <Skeleton sx={{ height: "2rem", width: "30%" }} />
                  <Skeleton sx={{ height: "2rem", width: "45%" }} />
                  <Skeleton sx={{ height: "2rem", width: "50%" }} />
                  <Skeleton sx={{ height: "2rem", width: "35%" }} />
                  <Skeleton sx={{ height: "2rem", width: "70%" }} />
                  <Skeleton sx={{ height: "2rem", width: "55%" }} />
                  <Skeleton sx={{ height: "2rem", width: "80%" }} />
                  <Skeleton sx={{ height: "2rem", width: "30%" }} />
                  <Skeleton sx={{ height: "2rem", width: "45%" }} />
                  <Skeleton sx={{ height: "2rem", width: "50%" }} />
                  <Skeleton sx={{ height: "2rem", width: "65%" }} />
                  <Skeleton sx={{ height: "2rem", width: "70%" }} />
                  <Skeleton sx={{ height: "2rem", width: "60%" }} />
                  <Skeleton sx={{ height: "2rem", width: "55%" }} />
                  <Skeleton sx={{ height: "2rem", width: "30%" }} />
                  <Skeleton sx={{ height: "2rem", width: "45%" }} />
                  <Skeleton sx={{ height: "2rem", width: "50%" }} />
                  <Skeleton sx={{ height: "2rem", width: "35%" }} />
                  <Skeleton sx={{ height: "2rem", width: "70%" }} />
                  <Skeleton sx={{ height: "2rem", width: "55%" }} />
                  <Skeleton sx={{ height: "2rem", width: "80%" }} />
                </Box>
              )}
            </Box>
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  columnGap: "1rem",
                  padding: "0  0.5rem",
                }}
              >
                <Box
                  sx={{
                    height: { lg: "86.5vh", xl: "89vh" },
                    padding: "0 0.5rem",
                  }}
                  adl-scrollbar="true"
                  adl-scrollbar-width="0.3"
                >
                  <Table sx={{ padding: "0 0.5rem" }}>
                    <TableHead
                      sx={{
                        backgroundColor: "primary.main",
                        color: "white",
                        position: "sticky",
                        top: "0",
                        zIndex: 1,
                      }}
                    >
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            color: "primary.main",
                            backgroundColor: "primary.light",
                            textAlign: "center",
                          }}
                          colSpan={4}
                        >
                          Commodity
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            color: "white",
                            borderBottomColor: "primary.light",
                            width: "5vw",
                          }}
                        >
                          S.No
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            color: "white",
                            borderBottomColor: "primary.light",
                            width: "15vw",
                          }}
                        >
                          Name
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            color: "white",
                            borderBottomColor: "primary.light",
                            width: "15vw",
                          }}
                        >
                          Description
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            color: "white",
                            borderBottomColor: "primary.light",
                            width: "5vw",
                          }}
                        >
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                      useraction && useraction?.includes("C") &&
                      
                      
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        ></TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <TextField
                            placeholder="Name"
                            variant="standard"
                            size="small"
                            value={createName}
                            onChange={handleChangeName}
                            InputProps={{
                              style: { fontSize: "1rem" },
                            }}
                            sx={{
                              width: "100%",
                              ".MuiInputBase-root.MuiInput-root": {
                                fontSize: "1rem !important",
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "primary.main",
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "primary.main",
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <TextField
                            placeholder="Description"
                            variant="standard"
                            size="small"
                            value={createDes}
                            onChange={handleChangeDescription}
                            InputProps={{
                              style: { fontSize: "1rem" },
                            }}
                            sx={{
                              width: "100%",
                              ".MuiInputBase-root.MuiInput-root": {
                                fontSize: "1rem !important",
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "primary.main",
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "primary.main",
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <LoadingButton
                            disabled={
                              createName?.length > 0
                                ? false
                                : true
                            }
                            onClick={() => handleCreate()}
                          >
                            <SaveIcon sx={{ cursor: "pointer" }} />
                          </LoadingButton>

                          {/* <OpenInNewIcon /> */}
                          {/* <InfoIcon /> */}
                        </TableCell>
                      </TableRow>}
                      {commodityData && !isLoading ? ( commodityData?.length>0 && 
                        commodityData?.map((item: any, index: any) => {
                          return (
                            <TableRow key={index + 1}>
                              <TableCell
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                }}
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                }}
                              >
                                <EditName
                                  item={item && item}
                                   useraction1={useraction && useraction}
                                  editName={editName}
                                  setEditName={setEditName}
                                  key={index}
                                  type={"commodity"}
                                  getTreeData={getTreeData}
                                />
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                }}
                              >
                                <EditDescription
                                   useraction1={useraction && useraction }
                                  item={item && item}
                                  setEditDes={setEditDes}
                                  editDes={editDes}
                                  key={index}
                                  type={"commodity"}
                                />
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                }}
                              >
                                {item?.map_status && (
                                  <InfoIcon
                                    sx={{
                                      cursor: "pointer",
                                      marginLeft: "1.5rem",
                                    }}
                                    onClick={() =>
                                      libraryInfoModal.open({
                                        key: "commodity",
                                        value: item.id,
                                        name: item.name,
                                      })
                                    }
                                  />
                                )}
                                {
                                
                                 !item?.map_status && useraction && useraction?.includes("D") &&
                                
                                (
                                  <DeleteIcon
                                    sx={{
                                      cursor: "pointer",
                                      marginLeft: "1.5rem",
                                    }}
                                    onClick={async () => {
                                      const confirm: any = await deleteSwal(
                                        `Delete ${item.name}`
                                      );
                                      if (confirm) {
                                        API.delete(
                                          `/db/commodity/${item.id}/`
                                        )
                                          .then((res: any) => {
                                            getCommodityData();
                                          })
                                          .catch((err: any) => {});
                                      }
                                    }}
                                  />
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                  </Table>
                </Box>
                <Box
                  sx={{
                    height: { lg: "86.5vh", xl: "89vh" },
                    padding: "0 0.5rem",
                  }}
                  adl-scrollbar="true"
                  adl-scrollbar-width="0.3"
                >
                  <Table sx={{ padding: "0 0.5rem" }}>
                    <TableHead
                      sx={{
                        backgroundColor: "primary.main",
                        color: "white",
                        position: "sticky",
                        top: "0",
                        zIndex: 1,
                      }}
                    >
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            color: "primary.main",
                            backgroundColor: "primary.light",
                            textAlign: "center",
                          }}
                          colSpan={4}
                        >
                          Process
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            color: "white",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          S.No
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            color: "white",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          Name
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            color: "white",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          Description
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            color: "white",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                      // useraction && useraction?.includes("C") &&
                      
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        ></TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <TextField
                            placeholder="Name"
                            variant="standard"
                            size="small"
                            value={createNameProcess}
                            onChange={handleChangeNameProcess}
                            InputProps={{
                              style: { fontSize: "1rem" },
                            }}
                            sx={{
                              ".MuiInputBase-root.MuiInput-root": {
                                fontSize: "1rem !important",
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "primary.main",
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "primary.main",
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <TextField
                            placeholder="Description"
                            variant="standard"
                            size="small"
                            value={createDesProcess}
                            onChange={handleChangeDescriptionProcess}
                            InputProps={{
                              style: { fontSize: "1rem" },
                            }}
                            sx={{
                              ".MuiInputBase-root.MuiInput-root": {
                                fontSize: "1rem !important",
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "primary.main",
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "primary.main",
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <LoadingButton
                            disabled={
                              
                              createNameProcess?.length > 0
                                ? false
                                : true
                            }
                            onClick={() => handleCreateProcess()}
                          >
                            <SaveIcon sx={{ cursor: "pointer" }} />
                          </LoadingButton>

                          {/* <OpenInNewIcon /> */}
                          {/* <InfoIcon /> */}
                        </TableCell>
                      </TableRow>}
                      {processData && !isLoadingProcess ? (
                        processData?.map((item: any, index: any) => {
                          return (
                            <TableRow key={index + 1}>
                              <TableCell
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                }}
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                }}
                              >
                                <EditName
                                  item={item && item}
                                   useraction1={useraction}
                                  editName={editName}
                                  setEditName={setEditName}
                                  key={index}
                                  type={"process"}
                                  getTreeData={getTreeData}
                                />
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                }}
                              >
                                <EditDescription
                               useraction1={useraction && useraction }
                                  item={item && item}
                                  setEditDes={setEditDes}
                                  editDes={editDes}
                                  key={index}
                                  type={"process"}
                                />
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                }}
                              >
                                {/* <SaveIcon /> */}
                                {/* <OpenInNewIcon /> */}
                                {item?.map_status && (
                                  <InfoIcon
                                    sx={{
                                      cursor: "pointer",
                                      marginLeft: "1.5rem",
                                    }}
                                    onClick={() =>
                                      libraryInfoModal.open({
                                        key: "process",
                                        value: item.id,
                                        name: item.name,
                                      })
                                    }
                                  />
                                )}
                                {
                                
                                // !item?.map_status && useraction && useraction?.includes("D") &&
                                
                                (
                                  <DeleteIcon
                                    sx={{
                                      cursor: "pointer",
                                      marginLeft: "1.5rem",
                                    }}
                                    onClick={async () => {
                                      const confirm: any = await deleteSwal(
                                        `Delete ${item.name}`
                                      );
                                      if (confirm) {
                                        API.delete(`/db/process/${item.id}/`)
                                          .then((res: any) => {
                                            getProcessData();
                                          })
                                          .catch((err: any) => {});
                                      }
                                    }}
                                  />
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <Skeleton></Skeleton>
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <LibraryInfoDetailsModal
        isOpen={libraryInfoModal.isOpen}
        onCloseModal={libraryInfoModal.close}
        libraryData={libraryInfoModal.propsId}
      />
    </div>
  );
}

export default CostingConfiguarationCommodityandProcess;
