import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import * as React from "react";
import MessageIcon from "@mui/icons-material/Message";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import { useBaseModal } from "../ComponentUI/SearchPage/useBaseModal";
import QuoteModal from "./QuoteModal";
import { useBaseParamsModal } from "../CustomHook/useBaseParamsModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SupplierCliamerDocsModal from "./SupplierClaimerDocsModal";
import ClaimBusinessRequestStatusModal from "./ClaimBusinessRequestStatusModal";
import { API } from "../api-services";
import Swal from "sweetalert2";
import SupplierEditChangesModal from "./SupplierEditChangesModal";
import SupplierAddRequestModal from "../DataBaseModule/Suppliers/SupplierAddRequestModal";
import SupplierEditRequestModal from "../DataBaseModule/Suppliers/SupplierEditRequestModal";
import SingleChat from "../chat/SingleChat";

export interface ISupplierActionTableProps {}

export function SupplierActionTable(props: ISupplierActionTableProps) {
  const quoteopen = useBaseParamsModal();
  const [selectedRequest, setSelectedRequest] = React.useState<any>(null);
  const addEditRequestModal = useBaseParamsModal();
  const requestEditChangesModal = useBaseParamsModal();
  const docsViewModal = useBaseParamsModal();
  const [currentStatus, setCurrentStatus] = React.useState<any>();
  const [request_type, setRequest_type] = React.useState<any>(null);
  const claimStausModal = useBaseModal();
  const [claimBusinessList, setClaimBusinessList] = React.useState<any[]>([]);
  const [reopen, setReopen] = React.useState(false);
  const [counter, setCounter] = React.useState(0);

  const handleBusinessApprove = (id: any,request_type:any,rowData:any) => {
    if(request_type == "Edit" || request_type == "Claim"){
      requestEditChangesModal.open(rowData);
    }else{
      addEditRequestModal.open(rowData);
    }
    
  };

  const getAllClaimBusinessReq = () => {
    // API call to get all the Claim Business Request
    API.get(`/cart/get_all_claim_requests/`, {}, 0)
      .then((res: any) => {
        setClaimBusinessList(res.data);
      })
      .catch((err: any) => {
        const {data} = err.response;

        if(err && err.response && data){
        Swal.fire({

          icon: "error",
          html: `<div>
          <br />
          <p style="color:"red">${data[0]}</p>   
           </div>`,
        })
      }
      });
  };

  React.useEffect(() => {
    getAllClaimBusinessReq();
  }, []);

  const handleDocView = (itm: any) => {
    docsViewModal.open(itm?.id);
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 20 },

    {
      field: "business_name",
      headerName: "Business name",
      editable: false,
    },
    {
      field: "business_email",
      headerName: "Business Email",
      width: 190,
      editable: true,
    },
    {
      field: "business_phone_no",
      headerName: "Business Phone No",

      editable: false,
    },
    {
      field: "business_address",
      headerName: "Business Address",

      width: 230,
      editable: false,
    },
    {
      field: "website",
      headerName: "Business website",

      width: 180,
      editable: false,
    },
    {
      field: "created_by",
      headerName: "Claimer Name",

      editable: false,
    },
    // {
    //   field: "requester_designation",
    //   headerName: "Claimer Position",

    //   editable: false,
    // },
    // {
    //   field: "business_type",
    //   headerName: "Business type",

    //   editable: false,
    // },
    // {
    //   field: "is_accepted",
    //   headerName: "Status",

    //   editable: false,
    //   renderCell(params) {
    //     return (
    //       <Box
    //         sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}
    //       >
    //         {params.row.request_stage}
    //         <span
    //           style={{
    //             color:
    //               params.row.request_stage !== "Completed" ? "red" : "green",
    //           }}
    //         >
    //           <VisibilityIcon
    //             titleAccess="Publish new scenario costing"
    //             sx={{ cursor: "pointer" }}
    //             onClick={() => ClaimBusinessStausHandler(params)}
    //           />
    //         </span>
    //       </Box>
    //     );
    //   },
    // },
    {
      field: "request_type",
      headerName: "Request type",
      width: 130,
     
      renderCell: (cellValues: any) => {
        return (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            {cellValues?.row?.request_type}
          </Box>
        );
      },
    },
    {
      field: "business_registration_no",
      headerName: "Business Reg. No.",

      width: 170,
      editable: false,
    },
    // {
    //   field: "business_docs",
    //   headerName: "Proof Documents",
    //   renderCell: (cellValues: any) => {
    //     return (
    //       <>
    //         <VisibilityIcon
    //           titleAccess="View Documents"
    //           sx={{ marginRight: "1rem", cursor: "pointer" }}
    //           onClick={() => handleDocView(cellValues?.row)}
    //         />
    //       </>
    //     );
    //   },
    // },

    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (cellValues: any) => {
        return (
          <Box sx={{ display: "flex", gap: "1rem" , alignItems:"center"}}>
            {/* {cellValues?.row?.status !== "Accepted" && (
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "secondary.main",
                  color: "text.primary",
                }}
              >
                Hold
              </Button>
            )} */}
            <Box sx={{width:"6.5rem"}}>
            {cellValues?.row?.status !== "Accepted" ? (
              <Button
                variant="contained"
                sx={{ backgroundColor: "primary.main" }}
                onClick={() => handleBusinessApprove(cellValues?.row?.id,cellValues?.row?.request_type,cellValues?.row)}
                // onClick={() => handleBusinessApprove(cellValues?.row?.id,"Edit",cellValues?.row)}
              >
                Approve
              </Button>
            ) : (
              <Button sx={{ color: "green" }}>Approved</Button>
            )} 
            </Box>
           
            <Tooltip title="Chat with requested user">
<IconButton  onClick={()=> {
                    handleClickk();
                    setSelectedRequest(cellValues.row);
                  }}>
                    
<MessageIcon
                  sx={{
                    color: "#00887a",
                    fontSize: "1.5rem",
                    cursor: "pointer",
                    display: "grid",
                    placeItems: "center",
                  }}
                  
                />
</IconButton>
</Tooltip>

          </Box>
        );
      },
    },

    // {
    //   field: "fullName",
    //   headerName: "Full name",
    //   description: "This column has a value getter and is not sortable.",
    //   sortable: false,
    // //   width: 160,
    //   valueGetter: (params: GridValueGetterParams) =>
    //     `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    // },
  ];

  const ClaimBusinessStausHandler = (allrowparams: any) => {
    // console.log(curstatus, curtype);
    // setCurrentStatus(curstatus);
    // setCurrentType(curtype);
    if (allrowparams && allrowparams.row) {
      const allparams = allrowparams.row;

      if (allparams) {
        let requestStage = allparams.request_stage;
        // let requestType = allparams.zbc_type;
        let reOpen = allparams.reOpen;
        // If allparams exists, set the values
        setCurrentStatus(requestStage);
        // setCurrentType(allparams.zbc_type);
        setReopen(allparams.reOpen);
        // Open the modal
        claimStausModal.open();
      } else {
        // Handle the case when allparams is undefined or null
        console.log("allparams is not defined or null");
      }
    }
  };


          // ------------drawer-----------------
          const [openChatDrawer, setOpenChatDrawer] = React.useState(false);

          const handleClickk = () => {
            setOpenChatDrawer(true);
          };
        
          const handleClosee = () => {
            setOpenChatDrawer(false);
          };
        
          // -----------------------------

 
  

  return (
    <Box sx={{ height: { lg: "52vh", xl: "63vh" }, width: "100%" }}>
       {openChatDrawer && <SingleChat open={openChatDrawer}  handleClose={handleClosee} request={selectedRequest} page_name="create_supplier" heading={`Add business request for `} />}
      {docsViewModal.isOpen && (
        <SupplierCliamerDocsModal
          isOpen={docsViewModal.isOpen}
          onCloseModal={docsViewModal.close}
          item={docsViewModal.propsId}
        />
      )}

      {claimStausModal.isOpen && (
        <ClaimBusinessRequestStatusModal
          isOpen={claimStausModal.isOpen}
          onCloseModal={claimStausModal.close}
          status={currentStatus}
          reOpen={reopen}
        />
      )}
      <SupplierEditRequestModal
        isOpen={requestEditChangesModal.isOpen}
        onCloseModal={requestEditChangesModal.close}
        allData={requestEditChangesModal.propsId}
        getAllClaimBusinessReq={getAllClaimBusinessReq}
      />
      {
        addEditRequestModal.isOpen && (
          <SupplierAddRequestModal
            isOpen={addEditRequestModal.isOpen}
            onCloseModal={addEditRequestModal.close}
            allData={addEditRequestModal.propsId}
            getAllClaimBusinessReq={getAllClaimBusinessReq}
          />
        )
      }

      <DataGrid
        headerHeight={38}
        rowHeight={38}
        rows={claimBusinessList && claimBusinessList}
        columns={columns}
        initialState={{
          pagination: {
            //@ts-ignore
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        sx={{
          "& ::-webkit-scrollbar": {
            width: "0.3rem",
            height: "0.3rem",
          },
          "& ::-webkit-scrollbar-thumb": {
            // background: $thumb-color;
            backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
            borderRadius: "10px",
          },
          "& ::- webkit-scrollbar-thumb: hover ": {
            // width: "4px",
            backgroundColor: "#045DE9 !important",
          },
          "::-webkit-scrollbar-track": {
            backgroundColor: "#e1e1f3 !important",
          },
          "&.MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            color: "text.primary",
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell": {
            borderBottomColor: "primary.light",
          },
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
      />
    </Box>
  );
}
