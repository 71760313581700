import * as React from "react";
import { Checkbox } from "@mui/material";
import Swal from "sweetalert2";
import { useRouteParams } from "../../../../../ui-reusable-component/useRouteParams";
import { userPermissionAction } from "../../../../BOMCreate/BOMEntry/BOMEntryLandingPage";
import { API } from "../../../../../api-services";
import { ADMIN } from "../../../../../Redux/Services/admin.service";
import { UserAction } from "../../IdeaGenerationViewLanding";
import { rejectedStatus } from "../../IdeaViewpage";
// import { userPermissionAction } from '../../../BOMCreate/BOMEntry/BOMEntryLandingPage';
// import { API } from '../../../../api-services';
// import { ADMIN } from '../../../../Redux/Services/admin.service';
// import { useRouteParams } from '../../../../ui-reusable-component/useRouteParams';

export interface IIdeaTabCheckBoxComponentProps {
  item: any;
  getIdeaParametersData: any;
  // BOMValidationStatus?: any;
  check: any;
  setCounterAPI:any;
}

export default function IdeaTabCheckBoxComponent(
  props: IIdeaTabCheckBoxComponentProps
) {
  const { item, getIdeaParametersData, check,setCounterAPI } = props;
  const { vault, topVault, copyStatus, vault_id } = useRouteParams<any>();
  const [value, setValue] = React.useState<any>({});
  const [isCheck, setIsCheck] = React.useState<any>();
  const userAction = React.useContext<any>(UserAction);
  let UserActionParams = userAction && userAction?.action;
  const rejectedAction = React.useContext<any>(rejectedStatus);
  let rejectedStatusActionParams = rejectedAction && rejectedAction;

  React.useEffect(() => {
    setIsCheck(check);
  }, [check]);

  const getobj = (v: any, tv: any) => {
    if (v == 0) {
      return { top_vault: tv };
    } else {
      return { vault: v };
    }
  };

  const getChecked = (itemm: any) => {
    if (item?.exceptions?.includes(item?.value)) {
      return true;
    } else {
      return false;
    }
  };

  const updateDataVaultCheckbox = async (
    name: any,
    valuee: any,
    vlt: any,
    tv: any
  ) => {
    API.put(
      `/idea/pool/${vault_id}/`,
      {
        ...{ parameter_json: { [name]: valuee } },
      },
      {
        ...getobj(vlt, tv),
        module: 15,
      },
      0
    )
      .then((res: any) => {
        // window.location.reload();
        setCounterAPI((prev:any)=>prev+1)
        ADMIN.toast.info("Updated Successfully");
      })
      .catch((err: any) => {
        // if (err.response.data) {
        //   const { data } = err.response;
        //   Swal.fire({
        //     title: "Error!",
        //     text: data[0],
        //     icon: "error",
        //     confirmButtonText: "Ok",
        //   });
        // }
      });
  };

  return (
    <Checkbox
      checked={getChecked(item)}
      disabled={(!rejectedStatusActionParams && item?.editable && UserActionParams?.includes("U"))? false : true}
      // disabled={((copyStatus == false || copyStatus == "false") && (UserAction?.includes("U") || UserAction?.includes("C"))) ? false : true}
      sx={{
        color: "primary.main",
        padding: "3px",
        [`&.Mui-checked`]: {
          color: "green",
        },
      }}
      onClick={() => {
        if (getChecked(item)) {
          updateDataVaultCheckbox(item?.key, "", vault, topVault);
        } else {
          updateDataVaultCheckbox(
            item?.key,
            item?.exceptions[0],
            vault,
            topVault
          );
        }
      }}
    />
  );
}
