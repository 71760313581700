import React, { useEffect, useState } from 'react';
import { API } from '../../api-services';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarExportContainer,
	GridCsvExportMenuItem,
	GridCsvExportOptions,
	GridToolbarColumnsButton,
} from '@mui/x-data-grid';
import {
	Box,
	ButtonProps,
	Dialog,
	DialogContent,
	Divider,
	Typography,
	styled,
} from '@mui/material';
import useWindowDimensions from '../../ui-reusable-component/useWindowDimensions';
import BarChartIcon from '@mui/icons-material/BarChart';
import { LoadingButton } from '@mui/lab';
import {
	Bar,
	BarChart,
	CartesianGrid,
	Cell,
	Label,
	ResponsiveContainer,
	XAxis,
	YAxis,
} from 'recharts';
import Slide from '@mui/material/Slide';
import CancelIcon from '@mui/icons-material/Cancel';
import { TransitionProps } from '@mui/material/transitions';
import InfoIcon from '@mui/icons-material/Info';
import moment from 'moment';

const TriangleBar = (props: any) => {
	const { fill, x, y, width, height } = props;

	const getPath = (x: any, y: any, width: any, height: any) => {
		return `M${x},${y + height}C${x + width / 3},${y + height} ${
			x + width / 2
		},${y + height / 3}
        ${x + width / 2}, ${y}
        C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${
			y + height
		} ${x + width}, ${y + height}
        Z`;
	};

	return <path d={getPath(x, y, width, height)} stroke='none' fill={fill} />;
};

const colorsGet = ['#0088FE', '#00C49F'];

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

const StyledGridOverlay = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	height: '100%',
	'& .ant-empty-img-1': {
		fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
	},
	'& .ant-empty-img-2': {
		fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
	},
	'& .ant-empty-img-3': {
		fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
	},
	'& .ant-empty-img-4': {
		fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
	},
	'& .ant-empty-img-5': {
		fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
		fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
	},
}));

function CustomNoRowsOverlay() {
	return (
		<StyledGridOverlay>
			<svg
				width='120'
				height='100'
				viewBox='0 0 184 152'
				aria-hidden
				focusable='false'>
				<g fill='none' fillRule='evenodd'>
					<g transform='translate(24 31.67)'>
						<ellipse
							className='ant-empty-img-5'
							cx='67.797'
							cy='106.89'
							rx='67.797'
							ry='12.668'
						/>
						<path
							className='ant-empty-img-1'
							d='M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z'
						/>
						<path
							className='ant-empty-img-2'
							d='M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z'
						/>
						<path
							className='ant-empty-img-3'
							d='M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z'
						/>
					</g>
					<path
						className='ant-empty-img-3'
						d='M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z'
					/>
					<g className='ant-empty-img-4' transform='translate(149.65 15.383)'>
						<ellipse cx='20.654' cy='3.167' rx='2.849' ry='2.815' />
						<path d='M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z' />
					</g>
				</g>
			</svg>
			<Box sx={{ mt: 1 }}>No Rows</Box>
		</StyledGridOverlay>
	);
}

const csvOptions: GridCsvExportOptions = {
	fileName: 'Category Scenario Dashboard Data In CSV',
	delimiter: ',',
	utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
	<GridToolbarExportContainer {...props}>
		<GridCsvExportMenuItem options={csvOptions} />
	</GridToolbarExportContainer>
);

const CustomToolbar = () => (
	<GridToolbarContainer style={{ height: '2rem' }}>
		<span
			style={{
				position: 'absolute',
				right: '0rem',
				fontSize: '1rem',
				paddingRight: '1rem',
			}}>
			<GridToolbarColumnsButton />
			<CustomExportButton />
		</span>
	</GridToolbarContainer>
);

interface ICostingScenarioCategoryDashboardProps {}

const CostingScenarioCategoryDashboard: React.FC<
	ICostingScenarioCategoryDashboardProps
> = (props) => {
	const {
		projectId,
		projectName,
		topVault,
		vault,
		scenarioId,
		treeabbr,
		scenarioType,
	} = useRouteParams<any>();

	const [tableDashboard, setTableDashboard] = useState<any>();
	const [tableDashboardLoader, setTableDashboardLoader] =
		useState<boolean>(false);
	const [openScenarioGraphModel, setOpenScenarioGraphModel] =
		React.useState(false);
	const [openScenarioGraphModelLoader, setOpenScenarioGraphModelLoader] =
		React.useState(false);
	const [openScenarioGraphModelData, setOpenScenarioGraphModelData] =
		React.useState<any>();
	const [openInfoScenarioModel, setOpenInfoScenarioModel] =
		React.useState(false);
	const [openInfoScenarioModelLoader, setOpenInfoScenarioModelLoader] =
		React.useState(false);
	const [openInfoScenarioModelData, setOpenInfoScenarioModelData] =
		React.useState<any>([]);
	const [openScenarioVaultId, setOpenScenarioVaultId] = React.useState<any>();

	const { width } = useWindowDimensions();

	const handleScenarioGraphOpen = (incomingId: any) => {
		setOpenScenarioGraphModelLoader(true);
		API.get('/cost/scenario_category_table/', { vault: incomingId }, 0).then(
			(res: any) => {
				setOpenScenarioGraphModelData(res?.data);
				setOpenScenarioGraphModelLoader(false);
				setOpenScenarioGraphModel(true);
				setOpenScenarioVaultId(incomingId);
			}
		);
	};

	const handleScenarioGraphClose = () => {
		setOpenScenarioGraphModel(false);
		setOpenScenarioVaultId(undefined);
	};
	console.log(openInfoScenarioModelData, 'bfhvhfvfhvfvfkvjfkj');

	const handleInfoScenarioOpen = (incomingId: any, vaultIdIncoming: any) => {
		setOpenInfoScenarioModelLoader(true);
		API.get(
			'/cost/scenario_category_table/',
			{ vault: vaultIdIncoming, scenario_id: incomingId },
			0
		).then((res: any) => {
			setOpenInfoScenarioModelData(res?.data);
			setOpenInfoScenarioModelLoader(false);
			setOpenInfoScenarioModel(true);
			setOpenScenarioVaultId(incomingId);
		});
	};

	const handleInfoScenarioClose = () => {
		setOpenInfoScenarioModel(false);
		setOpenScenarioVaultId(undefined);
	};

	useEffect(() => {
		setTableDashboardLoader(true);
		setTableDashboard(undefined);
		if (scenarioType == '1') {
			API.get(
				`/cost/scenario_category_table/`,
				{
					// top_vault: topVault,
					vehicle_category: projectId,
					abbreviation: treeabbr,
				},
				0
			).then((res: any) => {
				setTableDashboard(res?.data);
				setTableDashboardLoader(false);
			});
		} else {
			API.get(
				`/cost/scenario_category_table/`,
				{
					// top_vault: topVault,
					vehicle_category: projectId,
					//  @ts-ignore
					category: JSON.parse(sessionStorage.getItem('isCategory'))?.[2],
				},
				0
			).then((res: any) => {
				setTableDashboard(res?.data);
				setTableDashboardLoader(false);
			});
		}
	}, [sessionStorage.getItem('isCategory')]);

	// columns is a variable where column data is stored. This columns is then used as a prop in dataGrid.

	let columns: any = [
		{
			field: 'sno',
			headerName: 'SNo.',
			width: width > 1370 ? 50 : width < 900 ? 50 : 50,
		},
		{
			field: 'part_no',
			headerName: 'Part No.',
			minWidth: width > 1370 ? 120 : 80,
			flex: 1,
		},
		{
			field: 'name',
			headerName: 'Name',
			minWidth: width > 1370 ? 300 : 200,
			flex: 1,
		},
		{
			field: 'part_count',
			headerName: 'Part Qty',
			minWidth: width > 1370 ? 80 : width < 1200 ? 70 : 80,
		},
		{
			field: 'last_scenario_name',
			headerName: 'Last Scenario Name',
			minWidth: width > 1370 ? 150 : 120,
			flex: 1,
			renderCell: (cellValues: any) => {
				return (
					<>
						<Typography sx={{ marginRight: '2rem' }}>
							{cellValues?.row?.last_scenario_name}
						</Typography>
						<LoadingButton
							loading={
								openScenarioVaultId == cellValues?.row?.last_scenario_id &&
								openInfoScenarioModelLoader
							}
							sx={{ minWidth: '0.5rem' }}
							onClick={() =>
								handleInfoScenarioOpen(
									cellValues?.row?.last_scenario_id,
									cellValues?.row?.id
								)
							}>
							<InfoIcon />
						</LoadingButton>
					</>
				);
			},
		},
		{
			field: 'last_scenario_cost',
			headerName: 'Last Scenario Cost',
			minWidth: width > 1370 ? 130 : 110,
			flex: 1,
		},
		{
			field: 'new_scenario_name',
			headerName: 'New Scenario Name',
			minWidth: width > 1370 ? 150 : width < 1200 ? 140 : 120,
			flex: 1,
			renderCell: (cellValues: any) => {
				return (
					<>
						<Typography sx={{ marginRight: '2rem' }}>
							{cellValues?.row?.new_scenario_name}
						</Typography>
						<LoadingButton
							loading={
								openScenarioVaultId == cellValues?.row?.new_scenario_id &&
								openInfoScenarioModelLoader
							}
							sx={{ minWidth: '0.5rem' }}
							onClick={() =>
								handleInfoScenarioOpen(
									cellValues?.row?.new_scenario_id,
									cellValues?.row?.id
								)
							}>
							<InfoIcon />
						</LoadingButton>
					</>
				);
			},
		},
		{
			field: 'new_scenario_cost',
			headerName: 'New Scenario Cost',
			width: width > 1370 ? 90 : width < 1200 ? 130 : 110,
			flex: 1,
		},
		{
			field: 'delta_value',
			headerName: 'Delta Value',
			width: width > 1370 ? 100 : width < 1200 ? 100 : 90,
		},
		{
			field: 'graph',
			headerName: 'Graph',
			width: width > 1370 ? 65 : width < 1200 ? 60 : 60,
			renderCell: (cellValues: any) => {
				return (
					<LoadingButton
						loading={
							openScenarioVaultId == cellValues?.row?.new_scenario_id &&
							openScenarioGraphModelLoader
						}
						sx={{ minWidth: '0.5rem' }}
						onClick={() => handleScenarioGraphOpen(cellValues?.row?.id)}>
						<BarChartIcon />
					</LoadingButton>
				);
			},
		},
	];

	// In rows which is a array, we are pushing data which is structured in frontEnd so, that we can pass it to dataGrid

	let rows: any = [];

	let dataGridDataGenerator: any =
		tableDashboard &&
		tableDashboard?.map((ideaItem: any, index: any) => {
			let objShared: any = {
				id: ideaItem?.id,
				sno: index + 1,
				name: ideaItem?.name,
				last_scenario_id: ideaItem?.last_scenario_id,
				last_scenario_cost: ideaItem?.last_scenario_cost?.toFixed(2),
				last_scenario_name: ideaItem?.last_scenario_name,
				new_scenario_id: ideaItem?.new_scenario_id,
				new_scenario_cost: ideaItem?.new_scenario_cost?.toFixed(2),
				new_scenario_name: ideaItem?.new_scenario_name,
				part_no: ideaItem?.part_no,
				delta_value: (
					ideaItem?.new_scenario_cost?.toFixed(2) -
					ideaItem?.last_scenario_cost?.toFixed(2)
				)?.toFixed(2),
				part_count: ideaItem?.part_count,
			};
			rows.push(objShared);
		});

	// dataStructureDataGrid is the total data format which is ready to pass to the dataGrid Props

	let dataStructureDataGrid: any = {
		columns: columns,
		rows: rows,
	};
	// console.log(tableDashboard, 'Cscsbjcbjscbjbjcbhjcs');

	return (
		<>
			{openScenarioGraphModel && (
				<Dialog
					open={openScenarioGraphModel}
					TransitionComponent={Transition}
					keepMounted
					aria-describedby='alert-dialog-slide-description'
					sx={{
						'.MuiPaper-root.MuiDialog-paper': {
							maxWidth: '50vw',
							borderRadius: '10px',
							maxHeight: '58vh',
						},
					}}>
					<DialogContent
						sx={{
							padding: '0rem',
							width: '50vw',
							maxHeight: '58vh',
							overflow: 'hidden',
						}}>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								height: '2.5rem',
								position: 'sticky',
								top: 0,
								zIndex: 2,
								backgroundColor: 'white',
							}}>
							<Typography
								sx={{
									fontSize: '1rem',
									fontWeight: '600',
									width: '100%',
									textAlign: 'center',
									marginRight: '-2rem',
								}}>
								Scenario Cost Graph
							</Typography>
							<CancelIcon
								titleAccess='Close'
								sx={{ cursor: 'pointer', margin: '0.5rem' }}
								onClick={handleScenarioGraphClose}
							/>
						</Box>
						<Divider
							sx={{
								borderColor: 'primary.light',
								margin: '0 1rem',
							}}
						/>
						<Box
							sx={{
								padding: '1rem',
								display: 'grid',
								gridTemplateColumns: '1fr 1fr',
								height: '58vh',
							}}
							adl-scrollbar='true'
							adl-scrollbar-width='0.3'>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
								}}>
								<Box
									sx={{
										margin: '0rem',
										width: '45vw',
										height: '55vh',
										padding: '1rem 1rem',
									}}>
									<ResponsiveContainer>
										<BarChart
											width={500}
											height={300}
											data={openScenarioGraphModelData}
											margin={{
												top: 20,
												right: 10,
												left: 25,
												bottom: 55,
											}}>
											<CartesianGrid strokeDasharray='3 3' />
											<XAxis
												dataKey='name'
												interval={0}
												angle={0}
												textAnchor={'middle'}
											/>
											<YAxis
												domain={[
													0,
													openScenarioGraphModelData?.reduce(
														(prev: number, res: any) => {
															return (prev = Math.max(prev, res?.cost));
														},
														0
													) + 50,
												]}>
												<Label
													style={{
														textAnchor: 'middle',
														fontSize: '100%',
														fontWeight: '600',
													}}
													angle={270}
													dx={-28}
													value={`Cost (INR)`}
												/>
											</YAxis>
											<Bar
												dataKey='cost'
												fill='#8884d8'
												shape={<TriangleBar />}
												label={{ position: 'top' }}>
												{openScenarioGraphModelData?.map(
													(entry: any, index: any) => {
														return (
															<Cell
																key={`cell-${index}`}
																fill={colorsGet[index]}
															/>
														);
													}
												)}
											</Bar>
										</BarChart>
									</ResponsiveContainer>
								</Box>
							</div>
						</Box>
					</DialogContent>
				</Dialog>
			)}

			{openInfoScenarioModel && (
				<Dialog
					open={openInfoScenarioModel}
					TransitionComponent={Transition}
					keepMounted
					aria-describedby='alert-dialog-slide-description'
					sx={{
						'.MuiPaper-root.MuiDialog-paper': {
							maxWidth: '35vw',
							borderRadius: '10px',
							maxHeight: 'fit-content',
						},
					}}>
					<DialogContent
						sx={{
							padding: '0rem',
							width: '30vw',
							maxHeight: 'fit-content',
							overflow: 'hidden',
						}}>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								height: '2.5rem',
								position: 'sticky',
								top: 0,
								zIndex: 2,
								backgroundColor: 'white',
							}}>
							<Typography
								sx={{
									fontSize: '1rem',
									fontWeight: '600',
									width: '100%',
									textAlign: 'center',
									marginRight: '-2rem',
								}}>
								Scenario Information
							</Typography>
							<CancelIcon
								titleAccess='Close'
								sx={{ cursor: 'pointer', margin: '0.5rem' }}
								onClick={handleInfoScenarioClose}
							/>
						</Box>
						<Divider
							sx={{
								borderColor: 'primary.light',
								margin: '0 1rem',
							}}
						/>
						<Box>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
								}}>
								{openInfoScenarioModelData &&
									openInfoScenarioModelData?.length > 0 && (
										<Box
											sx={{
												margin: '0rem',
												width: '28vw',
												height: 'fit-content',
												padding: '1rem 1rem',
											}}>
											<Typography
												sx={{ display: 'flex', alignItems: 'center' }}>
												<Typography sx={{ width: '12rem', fontSize: '1rem' }}>
													Scenario Name
												</Typography>
												<Typography sx={{ fontSize: '1rem' }}>
													:{' '}
													{openInfoScenarioModelData &&
														openInfoScenarioModelData[0]?.name}
												</Typography>
											</Typography>
											<Typography
												sx={{ display: 'flex', alignItems: 'center' }}>
												<Typography sx={{ width: '12rem', fontSize: '1rem' }}>
													Scenario Cost
												</Typography>
												<Typography sx={{ fontSize: '1rem' }}>
													:{' '}
													{openInfoScenarioModelData &&
														openInfoScenarioModelData[0]?.cost}
												</Typography>
											</Typography>
											<Typography
												sx={{ display: 'flex', alignItems: 'center' }}>
												<Typography sx={{ width: '12rem', fontSize: '1rem' }}>
													Scenario Type
												</Typography>
												<Typography sx={{ fontSize: '1rem' }}>
													:{' '}
													{openInfoScenarioModelData &&
														openInfoScenarioModelData[0]?.scenario_creation}
												</Typography>
											</Typography>
											<Typography
												sx={{ display: 'flex', alignItems: 'center' }}>
												<Typography sx={{ width: '12rem', fontSize: '1rem' }}>
													Assumption Name
												</Typography>
												<Typography sx={{ fontSize: '1rem' }}>
													:{' '}
													{openInfoScenarioModelData &&
														openInfoScenarioModelData[0]?.assumptio_name}
												</Typography>
											</Typography>
											{openInfoScenarioModelData &&
												openInfoScenarioModelData[0]?.shared_user?.length >
													0 && (
													<Typography
														sx={{ display: 'flex', alignItems: 'center' }}>
														<Typography
															sx={{ width: '12rem', fontSize: '1rem' }}>
															Shared with
														</Typography>
														<Typography sx={{ fontSize: '1rem' }}>
															:{' '}
															{openInfoScenarioModelData &&
																openInfoScenarioModelData[0]?.shared_user?.toString()}
														</Typography>
													</Typography>
												)}
											<Typography
												sx={{ display: 'flex', alignItems: 'center' }}>
												<Typography sx={{ width: '12rem', fontSize: '1rem' }}>
													Created On
												</Typography>
												<Typography sx={{ fontSize: '1rem' }}>
													:{' '}
													{openInfoScenarioModelData &&
														openInfoScenarioModelData[0]?.created_at}
												</Typography>
											</Typography>
											<Typography
												sx={{ display: 'flex', alignItems: 'center' }}>
												<Typography sx={{ width: '12rem', fontSize: '1rem' }}>
													Created By
												</Typography>
												<Typography sx={{ fontSize: '1rem' }}>
													:{' '}
													{openInfoScenarioModelData &&
														openInfoScenarioModelData[0]?.created_by}
												</Typography>
											</Typography>
										</Box>
									)}
							</div>
						</Box>
					</DialogContent>
				</Dialog>
			)}

			<Box
				sx={{
					height: { sm: '90vh', md: '89vh', lg: '88vh', xl: '91vh' },
					width: '100%',
					padding: '0 1rem',
				}}>
				<DataGrid
					headerHeight={48}
					rowHeight={48}
					{...dataStructureDataGrid}
					loading={tableDashboardLoader}
					disableSelectionOnClick={true}
					components={{
						Toolbar: () => {
							return CustomToolbar();
						},
						noRowsOverlay: CustomNoRowsOverlay,
					}}
					sx={{
						'& .MuiDataGrid-virtualScroller-thumb': {
							backgroundImage: 'linear-gradient(#09C6F9, #045DE9)',
							borderRadius: '10px',
						},
						'& .MuiDataGrid-virtualScroller-thumb: hover ': {
							height: '3px',
							backgroundColor: '#045DE9 !important',
						},
						'.MuiDataGrid-virtualScroller-track': {
							backgroundColor: '#e1e1f3 !important',
						},
						'&.MuiDataGrid-root': {
							border: 'none',
						},
						'&.MuiDataGrid-root .MuiDataGrid-cell': {
							borderBottomColor: 'primary.light',
						},
					}}
					density={'compact'}
					pageSize={100}
					rowsPerPageOptions={[100]}
				/>
			</Box>
		</>
	);
};

export default CostingScenarioCategoryDashboard;
