import React from 'react';
import { Modal, Box, TextField, Button, Typography, Divider, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Field, Form, Formik, FieldProps } from 'formik';
import CancelIcon from '@mui/icons-material/Cancel';

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50rem",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

const AddDirectorModal: React.FC<any> = ({ isOpen, onCloseModal, onSave }) => {
  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: '-2rem'
            }}
          >
            Add Director/Signatory
          </Typography>
          <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
            marginBottom: "1rem",
          }}
        />
       <Formik
          initialValues={{
            din: '',
            firstName: '',
            middleName: '',
            lastName: '',
            designation:'',
            isCurrentSignatory:'',
            appointmentDate: '',
          }}
        
          onSubmit={(values) => {
            onSave(values);
            onCloseModal();
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <Field name="din">
             
              {({ field }: FieldProps) => (
                  <TextField
                    {...field}
                    sx={{marginBottom:"1rem"}}
                    label="DIN"
                    variant="outlined"
                    fullWidth
                    error={touched.din && Boolean(errors.din)}
                    helperText={touched.din && errors.din}
                  />
                )}
              </Field>
              <Field name="firstName">
              {({ field }: FieldProps) => (
                  <TextField
                    {...field}
                    sx={{marginBottom:"1rem"}}
                    label="First Name"
                    variant="outlined"
                    fullWidth
                    error={touched.firstName && Boolean(errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                  />
                )}
              </Field>
              <Field name="middleName">
              {({ field }: FieldProps) => (
                  <TextField
                    {...field}
                    sx={{marginBottom:"1rem"}}
                    label="Middle Name"
                    variant="outlined"
                    fullWidth
                  />
                )}
              </Field>
              <Field name="lastName">
              {({ field }: FieldProps) => (
                  <TextField
                    {...field}
                    sx={{marginBottom:"1rem"}}
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    error={touched.lastName && Boolean(errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                  />
                )}
              </Field>
              <Field name="appointmentDate">
              {({ field }: FieldProps) => (
                  <TextField
                    {...field}
                    sx={{marginBottom:"1rem"}}
                    label="Appointment Date"
                    type="date"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={touched.appointmentDate && Boolean(errors.appointmentDate)}
                    helperText={touched.appointmentDate && errors.appointmentDate}
                  />
                )}
              </Field>
              <Field name="designation">
              {({ field }: FieldProps) => (
                  <TextField
                    {...field}
                    sx={{marginBottom:"1rem"}}
                    label="Designation"
                    variant="outlined"
                    fullWidth
                    error={touched.designation && Boolean(errors.designation)}
                    helperText={touched.designation && errors.designation}
                  />
                )}
              </Field>
              <Field name="isCurrentSignatory">
                {({ field, form }: FieldProps) => (
                  <FormControl fullWidth variant="outlined" error={touched.isCurrentSignatory && Boolean(errors.isCurrentSignatory)}>
                    <InputLabel>Charge Status</InputLabel>
                    <Select
                      {...field}
                      label="Charge Status"
                      onChange={(event) => {
                        if (event.target.value === "true") {
                          form.setFieldValue("isCurrentSignatory", true)
                        } else {
                          form.setFieldValue("isCurrentSignatory", false)
                        }
                      }}
                      value={field.value}
                    >
                      <MenuItem value="true">Yes</MenuItem>
                      <MenuItem value="false">No</MenuItem>
                    </Select>
                    {touched.isCurrentSignatory && errors.isCurrentSignatory && (
                      <span style={{ color: 'red' }}>{errors.isCurrentSignatory}</span>
                    )}
                  </FormControl>
                )}
              </Field>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Add Director
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default AddDirectorModal;