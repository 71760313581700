import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import CancelIcon from '@mui/icons-material/Cancel';
import {
	Divider,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Skeleton,
	Input,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import { API } from '../../api-services';
import { ScenarioColors } from './ScenarioColors.constants';
import { useBaseParamsModal } from '../../CustomHook/useBaseParamsModal';
import PartCountDetailsModal from './PartCountDetailsModal';
import { AddAssumptionScenarioDropdown } from './AddAssumptionScenarioDropdown';
import ScenarioCloseAlertModal from './ScenarioCloseAlertModal';
import Swal from 'sweetalert2';
import successIcon from '../../Assets/images/success.png';
import { useHistory } from 'react-router-dom';
import { useBaseModal } from '../SearchPage/useBaseModal';
import RevisionListModal from './RevisionListModal';
import RevisionListNewCreateModal from './RevisionListNewCreateModal';
import { useState } from 'react';

export interface IAddScenarioModalProps {
	isOpen: any;
	onCloseModal: any;
	setCount: any;
	SelectedData?: any;
}
const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: { xs: '86vw', sm: '60vw', lg: '50vw', xl: '35vw' },
	height: 'fit-content',
	bgcolor: 'background.paper',
	borderRadius: '10px',
	p: 0,
};

export function AddScenarioTemp(props: IAddScenarioModalProps) {
	const { isOpen, onCloseModal, setCount, SelectedData } = props;
	const revisionListModal = useBaseModal();
	const [selectedScenario, setSelectedScenario] = React.useState<any>({});
	const [loader, setLoader] = React.useState<any>(false);
	const [flagCount, setFlagCount] = React.useState<any>(0);
	const [colorList, setColorList] = React.useState<any>([]);
	const [tableData, setTableData] = React.useState<any>([]);
	const [assumptionsList, setAssumptionsList] = React.useState<any>([]);
	const [selectedAssumption, setSelectedAssumption] = React.useState<any>({});
	const [selectedColor, setSelectedColor] = React.useState<any>('');
	const [age, setAge] = React.useState('');
	const [assumptionTableData, setAssumptionTableData] = React.useState<any>([]);
	const [errMsg, setErrMsg] = React.useState<any>(null);
	const [savedScenarioId, setSavedScenarioId] = React.useState<any>('');
	const [assumptionTableDataLoading, setAssumptionTableDataLoading] =
		React.useState<any>(false);

	const [isShowTable, setIsShowTable] = React.useState<boolean>(false);
	const [scenarioName, setScenarioName] = React.useState<any>('');
	const [assumptionsCardCount, setAssumptionCardCount] =
		React.useState<any>(null);
	const [loadingCardCount, setLoadingCardCount] = React.useState<boolean>(true);

	//   const handleChange = (event: SelectChangeEvent) => {
	//     setAge(event.target.value as string);
	//   };

	const [assumptionLoader, setAssumptionLoader] = React.useState<any>();
	const {
		projectId,
		projectName,
		topVault,
		vault,
		scenarioId,
		treeabbr,
		scenarioType,
		requestId,
	} = useRouteParams<any>();
	const history = useHistory();
	const partCountDetails = useBaseParamsModal();

	// color set
	const [colorSelect, setColorSelect] = useState<any>();
	const [error, setError] = useState<boolean>(false);
	const handleColorSelect: any = (e: any) => {
		setColorSelect(e.target.value);
		setError(false);
	};

	const totalAssumptionCardCounts = () => {
		API.get(
			'cost/assumption_card/',
			{
				vehicle_category: projectId,
				assumption_type: 2,
			},
			0
		)
			.then((res: any) => {
				setAssumptionCardCount(res?.data?.length);
				setLoadingCardCount(false);
			})
			.catch((err: any) => {
				setLoadingCardCount(false);
			});
		// https://api-stage2.xcpep.com/cost/assumption_card/?top_vault=49&assumption_type=2
	};

	React.useEffect(() => {
		if (Object.keys(SelectedData).length > 0) {
			setScenarioName(SelectedData?.scenario_name);
		}
	}, [SelectedData]);

	const handleChange = (event: SelectChangeEvent, itemMain?: any) => {
		const { name, value } = event.target;
		setErrMsg(null);
		if (name === 'scenario') {
			setSelectedScenario(value);
		} else if (name === 'assumption') {
			setSelectedAssumption(value);
			if (itemMain?.id) {
				API.put(
					`/cost/scenario_assumption/${itemMain?.id}/`,
					{
						target_assumption: value,
					},
					{},
					0
				)
					.then((res: any) => {
						getAssumptionDataTable(savedScenarioId);
					})
					.catch((err: any) => {});
			} else {
				API.post(
					'/cost/scenario_assumption/',
					{
						target_assumption: value,
						base_assumption: itemMain?.base_assumption,
						cost_scenario: savedScenarioId,
						request_id: requestId != 0 ? requestId : undefined,
					},
					{},
					0
				)
					.then((res: any) => {
						getAssumptionDataTable(savedScenarioId);
					})
					.catch((err: any) => {});
			}
		} else if (name === 'color') {
			if (value.length > 0){
			setColorSelect(value);
			} else {
				setErrMsg('Please select color');
			}
		} else if (name === 'scenarioname') {
			if (value.length > 0){
				setScenarioName(value);
			} else {
				setErrMsg('Please enter scenario name');
			}
			
		}
	};

	const ScenarioCloseAlert = useBaseParamsModal();
	const ScenarioCloseModalHandler = () => {
		if (isShowTable && savedScenarioId) {
			ScenarioCloseAlert.open(savedScenarioId);
		} else {
			onCloseModal();
		}
	};

	const getObj = () => {
		if (vault == 0) {
			return { vehicle_category: projectId };
		} else {
			return { vault: vault };
		}
	};

	const getDropDownOptions = () => {
		setLoader(true);
		API.get('/cost/scenario_table/', { ...getObj() })
			.then((res: any) => {
				setTableData(res?.data);
				setLoader(false);
			})
			.catch((err: any) => {
				console.log('Sever Error');
				setLoader(false);
			});
	};

	const getAssumptionsDropdownList = () => {
		setAssumptionLoader(true);
		API.get('/cost/assumption_card/', {
			vehicle_category: projectId,
			assumption_type: 2,
		})
			.then((res: any) => {
				setAssumptionsList(res?.data);
				setAssumptionLoader(false);
			})
			.catch((err: any) => {
				console.log('Sever Error');
				setAssumptionLoader(false);
			});
	};

	const getAssumptionDataTable = (iddd: any) => {
		setAssumptionTableDataLoading(true);
		API.get('/cost/scenario_assumption/', {
			...getObj(),
			selected_costing:
				selectedScenario?.scenario_creation === 'Main'
					? 0
					: selectedScenario?.id,
			scenario: iddd,
		})
			.then((res: any) => {
				setAssumptionTableData(res?.data);
				setAssumptionTableDataLoading(false);
			})
			.catch((err: any) => {
				console.log('Sever Error');
				setAssumptionTableDataLoading(false);
			});
	};

	//   React.useEffect(() => {
	//     if (Object.keys(selectedScenario).length > 0) {
	//       getAssumptionDataTable();
	//     }
	//   }, [selectedScenario]);

	React.useEffect(() => {
		getDropDownOptions();
		// getColor();
		totalAssumptionCardCounts();
	}, []);
	React.useEffect(() => {
		getAssumptionsDropdownList();
	}, [flagCount]);
	
	const getLoadingStatus = () => {
		let status = assumptionTableData.every((object: any) => {
			return (
				object.hasOwnProperty('target_assumption') &&
				object.target_assumption !== null
			);
		});
		return status;
	};

	const onSubmitScenario = () => {
		if (colorSelect == undefined || colorSelect == null) {
			setError(true);
			return;
		} else {
      setError(false);
			API.post(
				'cost/scenario_assumption/',
				{
					create_new_scenario: true,
					...getObj(),
					name: scenarioName,
					color: colorSelect,
					request_id:
						SelectedData?.request_from == 'client'
							? SelectedData?.ID
							: undefined,
					internal_request_id:
						SelectedData?.request_from != 'client'
							? SelectedData?.ID
							: undefined,
					scenario:
						selectedScenario?.scenario_creation === 'Main'
							? 0
							: selectedScenario?.id,
				},
				{},
				0
			)
				.then((res: any) => {
					setIsShowTable(true);
					setSavedScenarioId(res?.data?.id);
					getAssumptionsDropdownList();
					getAssumptionDataTable(res.data?.id);
				})
				.catch((err: any) => {
					setErrMsg(err?.response?.data[0]);
				});
		}
	};

	return (
		<div>
			<Modal
				open={isOpen}
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'>
				{assumptionsCardCount && assumptionsCardCount > 0 ? (
					<Box sx={style}>
						{revisionListModal.isOpen && (
							<RevisionListNewCreateModal
								isOpen={revisionListModal.isOpen}
								onCloseModal={revisionListModal.close}
								setFlagCount={setFlagCount}
							/>
						)}

						<Box
							sx={{ display: 'flex', alignItems: 'center', height: '3.5rem' }}>
							<Typography
								style={{
									fontSize: '1rem',
									fontWeight: '600',
									width: '100%',
									textAlign: 'center',
									marginRight: '-2rem',
								}}>
								Add New Scenario
							</Typography>
							<CancelIcon
								titleAccess='Close'
								sx={{ cursor: 'pointer', margin: '0.5rem' }}
								onClick={ScenarioCloseModalHandler}
							/>
						</Box>
						<Divider
							sx={{
								borderColor: 'primary.light',
								margin: '0 1rem',
								marginBottom: '1rem',
							}}
						/>

						<Box sx={{ padding: '0 1rem 1rem' }}>
							<Typography
								style={{
									fontSize: '1rem',
									fontWeight: '600',
									width: '100%',
									textAlign: 'center',
									marginRight: '-2rem',
								}}>
								Requested Details
							</Typography>
							<Box
								sx={{
									display: 'grid',
									gridTemplateColumns: '1fr 1fr',
									alignItems: 'center',
									columnGap: '1rem',
									rowGap: '0.5rem',
									flexDirection: 'column',
									marginBottom: '1rem',
								}}>
								<Box
									style={{
										fontSize: '1rem',
										width: '100%',
										display: 'flex',
									}}>
									<Typography style={{ width: '8rem' }}>
										{' '}
										MFG Location
									</Typography>
									: {SelectedData?.mfg_location}
								</Box>
								<Box
									style={{
										fontSize: '1rem',
										width: '100%',
										display: 'flex',
									}}>
									<Typography style={{ width: '8rem' }}>
										Requested By
									</Typography>
									: {SelectedData?.request_from}
								</Box>
								<Box
									style={{
										fontSize: '1rem',
										width: '100%',
										display: 'flex',
									}}>
									<Typography style={{ width: '8rem' }}>
										Requested Id
									</Typography>
									: {SelectedData?.request_id}
								</Box>
								<Box
									style={{
										fontSize: '1rem',
										width: '100%',
										display: 'flex',
									}}>
									<Typography style={{ width: '8rem' }}>Revised On</Typography>:{' '}
									{SelectedData?.revision_month} {SelectedData?.revision_year}
								</Box>
								<Box
									style={{
										fontSize: '1rem',
										width: '100%',
										display: 'flex',
									}}>
									<Typography style={{ width: '8rem' }}>
										Annual Volume
									</Typography>
									: {SelectedData?.annual_volume}
								</Box>
							</Box>
							<Box sx={{ marginBottom: '2rem' }}>
								<FormControl variant='standard' fullWidth>
									<InputLabel
										shrink
										sx={{ color: 'primary.main' }}
										id='demo-simple-select-label'>
										Selected Base Scenario <sup style={{ color: 'red' }}>*</sup>
									</InputLabel>
									<Select
										size='small'
										labelId='demo-simple-select-label'
										id='demo-simple-select'
										name='scenario'
                    					disabled={isShowTable}
										value={selectedScenario}
										onChange={(ed: any) => handleChange(ed, {})}
										sx={{
											width: '100%',
											'&:before': {
												borderBottomColor: 'primary.main',
											},
											'& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select':
												{
													fontSize: '1rem',
													paddingLeft: '0.5rem',
													paddingBottom: '0rem',
													paddingTop: '0.2rem',
												},
											'.MuiSvgIcon-root.MuiSelect-icon': {
												color: 'primary.main',
											},
										}}>
										{!loader && tableData ? (
											tableData?.map((item: any, index: any) => {
												return (
													<MenuItem
														sx={{ fontSize: '1rem' }}
														key={index}
														value={item}>
														{item?.name}
													</MenuItem>
												);
											})
										) : (
											<Skeleton animation='wave' />
										)}
									</Select>
								</FormControl>
								<Typography
									sx={{ fontSize: '1rem', width: '100%', textAlign: 'right' }}>
									{selectedScenario?.cost}
								</Typography>
							</Box>

							<Box
								sx={{
									display: 'flex',
									alignItems: 'flex-end',
									columnGap: '1rem',
								}}>
								<FormControl variant='standard' fullWidth>
									<InputLabel
										shrink
										sx={{ color: 'primary.main' }}
										id='demo-simple-select-label'>
										Enter Scenario Name <sup style={{ color: 'red' }}>*</sup>
									</InputLabel>
									<TextField
										//   label="Enter Scenario Name"
										// defaultValue="Enter Scenario Name"
										//   placeholder="Enter Scenario Name"
										required
										variant='standard'
										name='scenarioname'
										fullWidth
										size='small'
                    					disabled={isShowTable}
										value={scenarioName}
										onChange={(ed: any) => handleChange(ed, {})}
										sx={{
											marginTop: '2rem',
											'.MuiInputBase-root.MuiInput-root': {
												fontSize: '1rem !important',
											},
											'& .MuiInput-underline:before': {
												borderBottomColor: 'primary.main',
											},
											'& .MuiInput-underline:after': {
												borderBottomColor: 'primary.main',
											},
										}}
										error={errMsg}
										helperText={errMsg}
									/>
								</FormControl>

								<FormControl disabled={isShowTable} fullWidth sx={{ marginTop: '0.5rem' }}>
									<InputLabel
										shrink
										sx={{
											color: 'primary.main',
											marginLeft: '-1rem',
											marginBottom: '-1rem',
										}}
										id='demo-simple-select-label'>
										Color <sup style={{ color: 'red' }}>*</sup>
									</InputLabel>
									<Input
										type='color'
										placeholder='Select Color'
										onChange={handleColorSelect}
										value={colorSelect}
										sx={{
											'&.MuiInputBase-root.MuiInput-root:before': {
												borderBottomColor: 'primary.light',
												paddingTop: '0',
											},
											'label .MuiInputBase-root.MuiInput-root': {
												marginTop: '0',
											},
										}}
									/>

									{error && error == true ? (
										<p
											style={{
												fontSize: '0.75rem',
												color: '#d32f2f',
												marginTop: '0.4rem',
											}}>
											*Please select color
										</p>
									) : (
										<></>
									)}
								</FormControl>
							</Box>

							<Box sx={{ width: '100%', textAlign: 'right' }}>
								{/* <Button onClick={()=>FeatureCreateUploadModal.open()}>Open create</Button> */}
								<LoadingButton
									size='small'
									// loading={isLoadingsave}
									variant='contained'
									disabled={isShowTable}
									type='submit'
									sx={{ marginTop: '1rem' }}
									onClick={() => {
										onSubmitScenario();
									}}>
									Add Scenario
								</LoadingButton>
							</Box>

							{isShowTable && (
								<>
									<Box
										sx={{ maxHeight: '40vh', marginTop: '3rem' }}
										adl-scrollbar='true'
										adl-scrollbar-width='0.3'>
										<Table>
											<TableHead sx={{ backgroundColor: 'primary.light' }}>
												<TableRow>
													<TableCell
														sx={{
															padding: '0.2rem 0.5rem',
															borderBottomColor: 'primary.light',
														}}>
														Used Revision
													</TableCell>
													<TableCell
														sx={{
															padding: '0.2rem 0.5rem',
															borderBottomColor: 'primary.light',
														}}>
														Parts
													</TableCell>
													<TableCell
														sx={{
															padding: '0.2rem 0.5rem',
															borderBottomColor: 'primary.light',
														}}>
														New Revision
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{!assumptionTableDataLoading && assumptionTableData ? (
													assumptionTableData?.map(
														(itemMain: any, index: any) => {
															return (
																<TableRow>
																	<TableCell
																		sx={{
																			padding: '0.2rem 0.5rem',
																			borderBottomColor: 'primary.light',
																		}}>
																		{itemMain?.base_assumption_name}
																	</TableCell>
																	<TableCell
																		sx={{
																			padding: '0.2rem 0.5rem',
																			borderBottomColor: 'primary.light',
																			textDecoration: 'underline',
																			cursor: 'pointer',
																		}}
																		onClick={() => {
																			if (itemMain?.part_count > 0) {
																				partCountDetails.open({
																					id: itemMain?.base_assumption,
																					scenario: savedScenarioId,
																				});
																			}
																		}}>
																		{itemMain?.part_count}
																	</TableCell>
																	<TableCell
																		sx={{
																			padding: '0.2rem 0.5rem',
																			borderBottomColor: 'primary.light',
																		}}>
																		<AddAssumptionScenarioDropdown
																			assumptionLoader={assumptionLoader}
																			assumptionsList={assumptionsList}
																			handleChange={handleChange}
																			itemMain={itemMain}
																			selectedAssumption={selectedAssumption}
																			setSelectedAssumption={
																				setSelectedAssumption
																			}
																			key={index}
																		/>
																	</TableCell>
																</TableRow>
															);
														}
													)
												) : (
													<TableRow>
														<TableCell>
															<Skeleton animation='wave' />
														</TableCell>
														<TableCell>
															<Skeleton animation='wave' />
														</TableCell>
														<TableCell>
															<Skeleton animation='wave' />
														</TableCell>
													</TableRow>
												)}
											</TableBody>
										</Table>
									</Box>
									<Box sx={{ width: '100%', textAlign: 'right' }}>
										<Button
											size='small'
											variant='contained'
											type='submit'
											sx={{ marginTop: '1rem', marginRight: '1rem' }}
											onClick={() => revisionListModal.open()}>
											Go to Revisions
										</Button>
										<LoadingButton
											size='small'
											variant='contained'
											disabled={!getLoadingStatus() || false}
											type='submit'
											sx={{ marginTop: '1rem' }}
											onClick={() => {
												API.post(
													'cost/scenario_assumption/',
													{
														create_scenario: true,
														scenario_id: savedScenarioId,
														request_id:
															SelectedData?.request_from == 'client'
																? SelectedData?.ID
																: undefined,
														internal_request_id:
															SelectedData?.request_from != 'client'
																? SelectedData?.ID
																: undefined,
													},
													{},
													0
												)
													.then((res: any) => {
														setIsShowTable(true);
														setSavedScenarioId(res?.data?.id);
														getAssumptionsDropdownList();
														setCount((prev: any) => prev + 1);
														Swal.fire({
															html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
          
                              <br />
							  <br />
                        
                              <p style="color:#00778a;">Your scenario cost data will be made available as soon as it's created in the background.!</p>   
                        
                               </div>`,

															showConfirmButton: true,
														}).then((result) => {
															if (result.isConfirmed) {
																history.push(
																	`/costingscenario/${projectId}/${projectName}/${topVault}/${vault}/${scenarioId}/${treeabbr}/${scenarioType}/0/1`
																);
																// window.location.reload(); // Reload the page
															}
														});
														onCloseModal();
													})
													.catch((err: any) => {});
											}}>
											Proceed
										</LoadingButton>
									</Box>
								</>
							)}
						</Box>
					</Box>
				) : (
					<Box sx={style}>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Typography
								style={{
									fontSize: '1rem',
									fontWeight: '600',
									width: '100%',
									textAlign: 'center',
									marginRight: '-2rem',
								}}>
								<br />
							</Typography>
							<CancelIcon
								titleAccess='Close'
								sx={{ cursor: 'pointer', margin: '0.5rem' }}
								onClick={onCloseModal}
							/>
						</Box>
						<Divider
							sx={{
								borderColor: 'primary.light',
								margin: '0 1rem',
								marginBottom: '1rem',
							}}
						/>
						{!loadingCardCount && (
							<Typography
								style={{
									fontSize: '1rem',
									fontWeight: '600',
									color: 'red',
									width: '100%',
									textAlign: 'center',
									marginRight: '-2rem',
									marginBottom: '2rem',
								}}>
								Create Scenario Revision first to add new scenario
							</Typography>
						)}
					</Box>
				)}
			</Modal>
			{partCountDetails.isOpen && (
				<PartCountDetailsModal
					isOpen={partCountDetails.isOpen}
					onCloseModal={partCountDetails.close}
					assumption={partCountDetails.propsId}
				/>
			)}

			{ScenarioCloseAlert.isOpen && (
				<ScenarioCloseAlertModal
					isOpen={ScenarioCloseAlert.isOpen}
					onCloseModal={ScenarioCloseAlert.close}
					parentCloseModal={onCloseModal}
					scenarioIdd={ScenarioCloseAlert.propsId}
					setCount={setCount}
				/>
			)}
		</div>
	);
}
