import {
	Box,
	CircularProgress,
	Skeleton,
	TextField,
	debounce,
} from '@mui/material';
import * as React from 'react';
import { API } from '../../../api-services';
import { ADMIN } from '../../../Redux/Services/admin.service';
import Swal from 'sweetalert2';
import { useRouteParams } from '../../../ui-reusable-component/useRouteParams';
import { useQueryDispatch } from '../../../CustomHook/useQueryDispatch';
import {
	getCustomValidations,
	resetCustomValidations,
} from '../../../Redux/Actions/customValidations.actions';
import { useSelector } from 'react-redux';
import { customValidationSelectors } from '../../../Redux/Reducers/customValidation.reducer';
import { ID } from '../../../utlis/commonInterfaces';
import { parameterConfigSelectors } from '../../../Redux/Reducers/parameterConfig.reducer';
import {
	getBOMConfigurationsByCategory,
	resetParameterConfigs,
} from '../../../Redux/Actions/parameterConfig.actions';
import { RootState } from '../../../Redux/Store/storeConfigurations';
import { workstationSelectors } from '../../../Redux/Reducers/workstation.reducer';
import validateValues from '../validateValues';
import { customValidate } from '../../../utlis/CustomValidate';
import { pickBy } from 'lodash';
import FormError from '../../../Core/FromError';
import { userPermissionAction } from './BOMEntryLandingPage';
import { useBaseModal } from '../../SearchPage/useBaseModal';
import ErrorModalFilesMail from '../../ProjectMailModule/ErrorModalFilesMail';

export interface INumberInputBoxProps {
	item: any;
	details: any;
	vaultIdd: any;
	BOMValidationStatus: any;
	allValues: any;
	getAllValues: any;
	modalType?: any;
	vaultpropId?: any;
	setSyncColor?:any
}

export function NumberInputBox(props: INumberInputBoxProps) {
	const {
		item,
		details,
		vaultIdd,
		BOMValidationStatus,
		allValues,
		getAllValues,
		modalType,
		vaultpropId,
		setSyncColor
	} = props;
	const {
		projectId,
		projectName,
		vault,
		topVault,
		treeabbr,
		workstation,
		isLeaf,
		copyStatus,
	} = useRouteParams<any>();
	const userAction = React.useContext(userPermissionAction);
	let UserAction = userAction && userAction?.action;
	const [value, setValue] = React.useState<any>('');
	const [oldValue, setOldValue] = React.useState<any>('');
	const [errorMessage, setErrorMessage] = React.useState<any>('');
	const [loading, setLoading] = React.useState<any>(false);
	const [isError, setIsError] = React.useState<any>(false);
	const [isShow, setIsShow] = React.useState<any>(true);
	const [fieldError, setFieldError] = React.useState<any>(undefined);
	const customValidations = useSelector(customValidationSelectors.selectAll);
	const [ErrorModalFileMsg, setErrorModalFileMsg] = React.useState<any>();

	const ErrorModalFiles = useBaseModal();
	const parameterConfigs = useSelector(
		parameterConfigSelectors.selectNonImageNonTableChildernConfigs
	);

	const getObj = (vlt: any) => {
		if (vlt == 0) {
			return { top_vault: topVault };
		} else {
			return { vault: vlt };
		}
	};

	const workstationList = useSelector((state: RootState) => {
		return workstationSelectors.selectById(state, +workstation || 0);
	});

	const configToDbMap = parameterConfigs.reduce((result, config) => {
		result[config.id] = config.parameter_id;
		return result;
	}, {} as Record<string, ID>);

	const configToDataTypeMap = parameterConfigs.reduce((result, config) => {
		result[config.id] = config.data_type;
		return result;
	}, {} as Record<string, string>);
	const categoryIncludedInWorkstion = workstationList
		? workstationList.categories.includes(1)
		: false;

	const allInputData = parameterConfigs
		.filter((data) => data.data_type !== 'table')
		.filter((data) => data.data_type !== 'document');
	const allowedParameters = categoryIncludedInWorkstion
		? workstationList?.allowed_parameters
		: undefined;

	var errors = validateValues(
		allInputData,
		allValues,
		allowedParameters,
		'parameter_id'
	);

	React.useEffect(() => {
		setValue(item?.value);
		setOldValue(item?.value);
		setIsError(true);
		if (
			item?.data_type == 'nonzero-positive-int' &&
			+value < 0 &&
			!Number.isInteger(+value)
		) {
			setIsError(true);
			setErrorMessage('Please enter a non-zero positive integer');
		} else if (
			item?.data_type == 'nonzero-positive-float' &&
			+value < 0 &&
			!Number.isInteger(+value)
		) {
			setIsError(true);
			setErrorMessage('Please enter a non-zero positive float');
		} else if (
			item?.data_type == 'positive-float' &&
			+value < 0 &&
			Number.isInteger(+value)
		) {
			setIsError(true);
			setErrorMessage('Please enter a positive float');
		} else {
			setIsError(false);
			setErrorMessage('');
		}
	}, [item]);
	React.useEffect(() => {
		if (item && typeof value === 'number') {
			if (item?.data_type == 'nonzero-positive-int' && +value > 0) {
				setIsShow(true);
			} else if (item?.data_type == 'non-zero positive integer' && +value < 0) {
				setIsShow(true);
			} else if (item?.data_type == 'nonzero-positive-float' && +value > 0) {
				setIsShow(true);
			} else if (item?.data_type == 'positive-float' && +value >= 0) {
				setIsShow(true);
			} else {
				setIsShow(false);
			}
		} else {
			setIsShow(true);
		}
	}, [value, item]);

	const validateField = (parameter_id: ID, value?: any) => {
		const val = value ?? { [item?.key]: +value }[parameter_id];
		let error: string | undefined;
		const config: any = parameterConfigs.find(
			(config) => config.parameter_id === parameter_id
		);

		if (config)
			error = validateValues([config], { [parameter_id]: val })[parameter_id];

		// custom validations
		const relation = customValidations?.find(
			(relation) => relation.parameter_id === parameter_id
		);
		// { [item?.key]: +value },
		allValues[item?.key] = +value;

		if (relation)
			error = customValidate(
				relation.parameter_id,
				configToDataTypeMap,
				relation.validation,
				undefined,
				allValues,
				details,
				configToDbMap
			);
		setFieldError(error!);
	};

	const updateDataVaultParams = async (
		name: any,
		val: any,
		vlt: any,
		itm: any
	) => {
		if (!isError) {
			setLoading(true);
			API.put(
				`/bom/component_details/74699/`,
				{
					[name]: val,
				},
				{
					...getObj(vlt),
				},
				0
			)
				.then((res: any) => {
					setLoading(false);
					setOldValue(val);
					setSyncColor && setSyncColor("red")
					BOMValidationStatus();
					getAllValues();
					ADMIN.toast.info('Updated Successfully');
				})
				.catch((err: any) => {
					const { data } = err.response;
					setLoading(false);
					if (item && item?.paramter_name === 'Weight') {
						if (modalType == 'modalform') {
							ErrorModalFiles.open();
							setErrorModalFileMsg(data[0]);
						} else {
							Swal.fire({
								title: 'Error!',
								text: data[0],
								icon: 'error',
								confirmButtonText: 'Ok',
							});
						} 
					} else {
            Swal.fire({
              title: 'Error!',
              text: data[0],
              icon: 'error',
              confirmButtonText: 'Ok',
            });
          }
          Swal.fire({
            title: 'Error!',
            text: data[0],
            icon: 'error',
            confirmButtonText: 'Ok',
          });
				});
		} else {
		}
	};

	const ExactDebounceParameters = React.useRef(
		debounce(async (value: any, name: any, idd: any, itemm: any) => {
			await updateDataVaultParams(name, value, idd, itemm);
		}, 1000)
	).current;

	const handleChangeNumber = (event: any, itemm: any) => {
		const { name, value } = event.target;
		validateField(itemm?.key, +value);
		// ExactDebounceParameters(+value, name, vault, itemm);
		setValue(+value);
		setIsError(true);
		if (
			itemm?.data_type == 'nonzero-positive-int' &&
			+value <= 0 &&
			!Number.isInteger(+value)
		) {
			setIsError(true);
			setErrorMessage('Please enter a non-zero positive integer');
		} else if (
			itemm?.data_type == 'positive-int' &&
			!Number.isInteger(+value)
		) {
			setIsError(true);
			setErrorMessage('Please enter a  positive integer');
		} else {
			setIsError(false);
			setErrorMessage('');
		}
	};
	// case 'number':
	// case 'float':
	// case 'nonzero-positive-int':
	// case 'nonzero-positive-float':
	// case 'positive-float':
	// case 'nonzero-float':
	// case 'percentage':
	// case 'positive-int':
	// case 'integer':
	const getLogic = (valll: any, itemm: any) => {
		if (
			itemm?.data_type == 'nonzero-positive-int' &&
			valll > 0 &&
			Number.isInteger(valll)
		) {
			return true;
		} else if (itemm?.data_type == 'nonzero-positive-float' && valll > 0) {
			return true;
		} else if (itemm?.data_type == 'positive-float' && +valll >= 0) {
			return true;
		} else if (itemm?.data_type == 'number' && +valll >= 0) {
			return true;
		} else if (itemm?.data_type == 'float' && +valll >= 0) {
			return true;
		} else if (
			itemm?.data_type == 'integer' &&
			+valll >= 0 &&
			Number.isInteger(valll)
		) {
			return true;
		} else if (itemm?.data_type == 'nonzero-float' && +valll > 0) {
			return true;
		} else if (
			itemm?.data_type == 'nonzero-integer' &&
			+valll > 0 &&
			Number.isInteger(valll)
		) {
			return true;
		} else if (itemm?.data_type == 'percentage' && +valll > 0) {
			return true;
		} else if (
			itemm?.data_type == 'positive-int' &&
			+valll >= 0 &&
			Number.isInteger(valll)
		) {
			return true;
		} else {
			return false;
		}
	};

	
	return (
		<>
			<Box sx={{ display: 'flex' }}>
				<TextField
					variant='standard'
					name={item?.key}
					type='number'
					disabled={
						(copyStatus == false || copyStatus == 'false') &&
						(UserAction?.includes('U') || UserAction?.includes('C'))
							? false
							: true
					}
					onChange={(event: any) => handleChangeNumber(event, item)}
					value={value}
					placeholder='Enter Value'
					sx={{
						'.MuiInputBase-root.MuiInput-root': {
							fontSize: '12px',
							backgroundColor: 'rgba(255,255,255,0.3)',
							paddingLeft: '0.3rem',
						},
						'& .MuiInput-underline:before': {
							borderBottomColor: 'transparent',
						},
						'& .MuiInput-underline:after': {
							borderBottomColor: 'primary.light',
						},
					}}
					error={errorMessage ? true : false}
					inputProps={{ min: 0 }}
					helperText={errorMessage ? errorMessage : ''}
					onBlur={(event: any) => {
						if (fieldError) {
						} else {
							if (modalType == 'modalform') {
								if (getLogic(+event.target.value, item)) {
									if (+event.target.value != oldValue) {
										ExactDebounceParameters(
											+event.target.value,
											item?.key,
											vaultpropId,
											item
										);
									}
								}
							} else {
								if (getLogic(+event.target.value, item)) {
									if (+event.target.value != oldValue) {
										ExactDebounceParameters(
											+event.target.value,
											item?.key,
											vault,
											item
										);
									}
								}
							}
						}
					}}
				/>
				{loading && <CircularProgress size={20} sx={{ margin: '5px' }} />}
			</Box>
			<ErrorModalFilesMail
				isOpen={ErrorModalFiles.isOpen}
				onCloseModal={ErrorModalFiles.close}
				ErrorMsg={ErrorModalFileMsg}
			/>
			{fieldError && <FormError error={fieldError} align='center' />}
		</>
	);
}
