import { useCallback, useEffect, useState } from "react";
import { Typography, Box, Popover } from "@mui/material";

const InterLandingStatistics = ({data}: any) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [totalCount, setTotalCount] = useState<any>({
        asi_added_unpublished_bom: 0,
        asi_added_published_bom: 0,
        asi_added_unpublished_costing: 0,
        asi_added_published_costing: 0,
        new_zbc_drawing_unpublished:0,
        new_zbc_drawing_published: 0,
        new_zbc_physical_unpublished: 0,
        new_zbc_physical_published: 0,
        existing_sc_request: 0,
        existing_sc_published: 0,
        manufacturer_added_parts: 0,
    });
    const statsUIConfig = [
        {
            name: "Unpublished BOM",
            value: totalCount.asi_added_unpublished_bom
        },
        {
            name: "Published BOM",
            value: totalCount.asi_added_published_bom
        },
        {
            name: "Unpublished Costing",
            value: totalCount.asi_added_unpublished_costing
        },
        {
            name: "Published Costing",
            value: totalCount.asi_added_published_costing
        },
        {
            name: "ZBC Drawing Published Part",
            value: totalCount.new_zbc_drawing_published
        },
        {
            name:"ZBC Drawing Unpublished Part",
            value: totalCount.new_zbc_drawing_unpublished
        },
        {
            name: "ZBC Physical Unpublished Part",
            value: totalCount.new_zbc_physical_unpublished
        },
        {
            name: "ZBC Physical Published Part",
            value: totalCount.new_zbc_physical_published
        },
        {
            name: "Requested SC",
            value: totalCount.existing_sc_request
        },
        {
            name: "Published SC",
            value: totalCount.existing_sc_published
        },
        {
            name: "Manufacturer Added Parts",
            value: totalCount.manufacturer_added_parts
        }
    ]  

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const getTotalCount = useCallback(() => {
        if(data) {
            let count = {
                asi_added_unpublished_bom: 0,
                asi_added_published_bom: 0,
                asi_added_unpublished_costing: 0,
                asi_added_published_costing: 0,
                new_zbc_drawing_unpublished: 0,
                new_zbc_drawing_published: 0,
                new_zbc_physical_unpublished: 0,
                new_zbc_physical_published: 0,
                existing_sc_request: 0,
                existing_sc_published: 0,
                manufacturer_added_parts: 0,
            };
            data.forEach((item: any) => {
                count.asi_added_unpublished_bom += item.asi_added_unpublished_bom;
                count.asi_added_published_bom += item.asi_added_published_bom;
                count.asi_added_unpublished_costing += item.asi_added_unpublished_costing;
                count.asi_added_published_costing += item.asi_added_published_costing;
                count.new_zbc_drawing_unpublished += item.new_zbc_drawing_unpublished;
                count.new_zbc_drawing_published += item.new_zbc_drawing_published;
                count.new_zbc_physical_unpublished += item.new_zbc_physical_unpublished;
                count.new_zbc_physical_published += item.new_zbc_physical_published;
                count.existing_sc_request += item.existing_sc_request;
                count.existing_sc_published += item.existing_sc_published;
                count.manufacturer_added_parts += item.manufacturer_added_parts;
            });
            setTotalCount(count);
        }
    },[data]);

    useEffect(() => {
        getTotalCount()
    }, [ getTotalCount])

    
    return (
        <Box sx={{display:"flex", alignItems:"flex-end", justifyContent:"flex-end"}}>
            <Typography 
                sx={{color:"primary.main", textDecoration:"underline", cursor:"pointer"}}
                onClick={handleClick}
            >
                Statistics
            </Typography>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                elevation={1}

            >
                <Box sx={{ p: 2, width:"300px" }}>
                      <Box>
                        <Typography sx={{color: 'primary.main', textAlign:"center"}}>Total Count</Typography>
                      </Box>
                      <Box >
                        {statsUIConfig.map((item: any, index: number) => {
                          return (
                            <Box
                                key={index}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  padding: "0.5rem",
                                  border: index === 0 ? "1px solid" : "none", 
                                  borderLeft: "1px solid " ,
                                  borderRight: "1px solid ",
                                  borderBottom:  "1px solid",
                                  borderColor:"#bbf7c5"
                                 }}
                            >
                              <Box sx={{ paddingRight: '0.5rem', flex: 1}}>
                                <Typography>{item.name}</Typography>
                              </Box>
                              <Box>
                                <Typography sx={{flex: 1}}>{item.value}</Typography>
                              </Box>
                            </Box>
                          )
                        })}
                      </Box>
                    </Box>

            </Popover>
        </Box>
    )

}

export default InterLandingStatistics;