// react
import React, { Fragment, useRef, useState } from "react";

// vendors

import { useSelector } from "react-redux";
import { isNumber } from "lodash";
import clsx from "classnames";
import swal from "sweetalert";

// services

// styles
import styles from "./CalculatorInputRow.module.scss";

// interfaces and types

import RViewerJS from "viewerjs-react";

import { triangulationSelectors } from "../../../Redux/Reducers/triagulation.reducer";
import {
  CALCULATOR_INPUT_TYPE,
  calculatorInputBackgroundColorMap,
} from "../../../Configuration/Costing/calculator.constant";
import { Button } from "devextreme-react";
import { TableCell, TableRow, TextField } from "@mui/material";
import { useAppDispatch } from "../../../../AppRouter";
import { updateFlagValue } from "../../../Redux/Actions/calculatorInput.reducer";
import FlagFileUpload from "../../../Core/CustomHooks/FlagFileUpload";
import { toExponential } from "../../../utlis/toExponential";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import FlagIcon from "@mui/icons-material/Flag";
import UploadIcon from "@mui/icons-material/Upload";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { LoadingButton } from "@mui/lab";
// import FlagFileUpload from ../Components/Core/CustomHooks/FlagFileUpload.tsx

// lazy

// ----------------------------------------------------------------------------------

interface CalculatorInputRowProps {
  inputCalculationData: any;
  onValueChange: (value: any) => void;
  userInputValue: any;
  resultValue?: any;
}

const CalculatorInputRow: React.FC<CalculatorInputRowProps> = (props) => {
  const { inputCalculationData, onValueChange, userInputValue, resultValue } =
    props;

  const [flag, setflag] = useState();
  const [FlagLoader, setFlagLoader] = useState(false)

  const [file, setFile] = useState();

  const [loading, setLoading] = useState(false);

  const inputElRef = useRef<any>(null);

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (inputCalculationData) {
      setflag(inputCalculationData.flag);
      setFile(inputCalculationData.image_url);
    }
  }, [inputCalculationData.flag]);

  // ---------------------------------------------------------------------------------
  // selector

  // ----------------------------------------------------------------------------------
  //refs
  const imageViewerElRef = useRef<any>();
  //-----------------------------------------------------------------------------------
  // handlers

  const triangulationData: any = useSelector(triangulationSelectors.selectAll);

  const permisson = triangulationData[0]
    ? !triangulationData[0].allowed_action.includes("C" || "U")
    : false;

  const handleInputChange = (e: React.ChangeEvent) => {
    // @ts-ignore
    onValueChange(Number(e?.target?.value));
  };

  const browseFiles = () => inputElRef.current?.click?.();

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files;
    setLoading(true);
    FlagFileUpload(
      file,
      inputCalculationData.id,
      dispatch,
      "calculator_input",
      setFile,
      setLoading
    );
  };

  const handleInputClick = (e: React.MouseEvent) => {
    (e.target as HTMLInputElement).value = "";
  };

  const handleCalculatorResultModaOpen = () => {
    const category = inputCalculationData?.calculator_category;
    if (!isNumber(category)) return;

    // openCalculatorResult( category );
  };

  const handleInputFlag = async () => {
    if (!inputCalculationData) return;

    if (flag) {
      // unflag
      const confirm = await swal({
        buttons: ["Cancel", "Yes, Unflag"],
        title: "Unflag input as assumption",
        text: "Are you sure? This is delete the assumption file, if provided",
        icon: "warning",
      });

      if (confirm) {
        setFlagLoader(true)
        const action: any = await dispatch(
          updateFlagValue({ id: inputCalculationData.id, flag: !flag })
        );

        const flagValue = await action.payload.inputs.flag;
        const image_url = await action.payload.inputs.image_url;

        if (updateFlagValue.fulfilled.match(action)) {
          setflag(flagValue);
          setFlagLoader(false)
          // setFile( image_url );
          // ADMIN.toast.success( "Input unflag successfully" );
        } else {
          setFlagLoader(false)
          // ADMIN.toast.error( "Input unflag failed" );
        }
      }
      return;
    }

    //for creating flagged
    setFlagLoader(true)
    const action: any = await dispatch(
      updateFlagValue({ id: inputCalculationData.id, flag: !flag })
    );

    const flagValue = await action.payload.inputs.flag;
    if (updateFlagValue.fulfilled.match(action)) {
      setflag(flagValue);
      setFlagLoader(false)
      // ADMIN.toast.success( "Input flag successfully" );
    } else {
      setFlagLoader(false)
      // ADMIN.toast.error( "Input unflag failed" );
    }
  };

  // ----------------------------------------------------------------------------------
  // jsx abstractions

  const renderValue = () => {
    if (!inputCalculationData) return null;

    switch (inputCalculationData.type) {
      case CALCULATOR_INPUT_TYPE.USER_INPUT:
        return (
          <TextField
            type="number"
            variant="standard"
            name={inputCalculationData.id}
            value={userInputValue}
            onChange={handleInputChange}
            sx={{
              ".MuiInputBase-root.MuiInput-root": {
                fontSize: '1rem !important'
              },
              '& .MuiInput-underline:before': { borderBottomColor: 'white' },
              '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
            }}
          />
        );

      case CALCULATOR_INPUT_TYPE.FORMULA:
        return <p>{resultValue}</p>;
      default:
        return toExponential(inputCalculationData.value || 0);
    }
  };

  const renderIcon = () => {
    if (!inputCalculationData) return null;
    switch (inputCalculationData.type) {
      case CALCULATOR_INPUT_TYPE.CALCULATOR_RESULT:
        return (
          <button
            // name="window maximize"
            // link
            onClick={handleCalculatorResultModaOpen}
            className={clsx({ [styles.hide]: permisson })}
          />
        );
      case CALCULATOR_INPUT_TYPE.USER_INPUT:
        return (
          <Fragment>
            {flag ? (
              <LoadingButton style={{ cursor: "pointer", minWidth: '1rem', padding: '0.1rem 0.5rem' }} title="flag" onClick={handleInputFlag}>
                {/* <button
                name={flag ? "flag" : "flag outline"}
                title="flag"
                style={{ border: "none", cursor: "pointer" }}
              
                className={clsx({
                  [styles.disabled]: permisson,
                })}> */}
                <FlagIcon />

                {/* </button> */}
              </LoadingButton>
            ) : (
              <LoadingButton style={{ cursor: "pointer", minWidth: '1rem', padding: '0.1rem 0.5rem' }} title="flag" onClick={handleInputFlag}>
                {/* <button
                title="flag"
                name={flag ? "flag" : "flag outline"}
                // link
                style={{ border: "none", cursor: "pointer" }}
              
                className={clsx({
                  [styles.disabled]: permisson,
                })}> */}
                <FlagOutlinedIcon />
                {/* </button> */}
              </LoadingButton>
            )}
            {Boolean(flag) && (
              <>
                <LoadingButton loading={loading} sx={{ cursor: "pointer", minWidth: '1rem', padding: '0.1rem 0.5rem' }}>
                  <UploadIcon
                    titleAccess="Upload File"
                    sx={{ cursor: "pointer" }}
                    onClick={browseFiles}
                  />
                </LoadingButton>
                {/* <Button
                                    // name="upload"
                                    onClick={ browseFiles }
                                    // loading={ loading }
                                    // className={ clsx( {
                                    //     [styles.hide]: permisson,
                                    //     [styles.disabled]: permisson
                                    // } ) }
                                /> */}
                <input
                  hidden
                  type="file"
                  ref={inputElRef}
                  onChange={handleChange}
                  onClick={handleInputClick}
                  accept="application/pdf"
                />
              </>
            )}
            {Boolean(file) && (
              <>
                <a href={file} target="_blank" style={{ display: 'flex' }}>
                  <InsertDriveFileIcon titleAccess="Document" sx={{ color: 'primary.main', fontSize: '1.5rem' }} />
                </a>
              </>
            )}
          </Fragment>
        );

      default:
        return (
          <Fragment>
            {Boolean(inputCalculationData?.image_url) && (
              <Button
                // name="camera"
                disabled={inputCalculationData.image_url ? false : true}
                onClick={() => {
                  return imageViewerElRef.current?.click?.();
                }}
                style={
                  !inputCalculationData.image_url
                    ? { cursor: "not-allowed", minWidth: '1rem', padding: '0.1rem 0.5rem' }
                    : { cursor: "pointer", minWidth: '1rem', padding: '0.1rem 0.5rem' }
                }
                className={clsx({
                  [styles.hide]: permisson,
                  [styles.disabled]: permisson,
                })}>
                <CameraAltIcon />
              </Button>
            )}
          </Fragment>
        );
    }
  };

  // ----------------------------------------------------------------------------------
  // jsx

  if (!inputCalculationData) return null;
  return (
    <TableRow data-testid="calculator-row" className={styles.container}>
      <TableCell
        sx={{
          padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light',
          backgroundColor:
            calculatorInputBackgroundColorMap[inputCalculationData.type],
        }}>
        {inputCalculationData.name}
      </TableCell>
      <TableCell
        sx={{
          padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light',
        }}>
        <span style={{ display: "flex", alignItems: 'center' }} className={styles.popupValue}>
          <span>{renderValue()}</span>
          <span style={{ display: "flex", alignItems: 'center' }}>{renderIcon()}</span>
        </span>
      </TableCell>
      <TableCell sx={{
        padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', textAlign: 'center'
      }}>
        {inputCalculationData.user_input}
      </TableCell>
      {/* <div style={ { display: "none" } }>
                <RViewerJS>
                    <img src={ inputCalculationData?.image_url } alt="image" ref={ imageViewerElRef } />
                </RViewerJS>
            </div> */}
    </TableRow>
  );
};

// ----------------------------------------------------------------------------------

export default CalculatorInputRow;
