import * as React from "react";
import {
  Modal,
  Divider,
  Box,
  TableCell,
  Table,
  TableBody,
  TableRow,
  TableHead,
  Button,
  FormLabel,
  Skeleton,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CancelIcon from "@mui/icons-material/Cancel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import styles from "./AddCostModal.module.scss";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// import { CostingTreeComponent } from "./CostingTreeComponent";
// import { API } from "../../../api-services";
// import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
// import CalculatorListTree from "./CalculatorListTree";
// import { useDispatch, useSelector } from "react-redux";
// import { selectedCalculators } from "../../../Redux/Actions/compareopen.action";
// import { BorderRight, Height } from "@mui/icons-material";
// import CostingCostCalculator from "../../../Configuration/Costing/CostingCreateCalculator";
import { LoadingButton } from "@mui/lab";
import { useRouteParams } from "../../../../../../ui-reusable-component/useRouteParams";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../../../../../api-services";
import CalculatorListTree from "../../../CalculatorListTree";
import CostingCostCalculator from "../../../../../../Configuration/Costing/CostingCreateCalculator";
import { selectedCalculators } from "../../../../../../Redux/Actions/compareopen.action";
import { useHistory } from "react-router-dom";
import { useBaseParamsModal } from "../../../../../../CustomHook/useBaseParamsModal";
import { CostModelCancelAlertModal } from "../../../CostModelCancelAlertModal";

export interface IAssignCostModeltoAddedMaterialsModalProps {
  onCloseModal: any;
  isOpen: any;
  costDetailsData: any;
  parentClose?: any;
  SwalAlertHandlerImages: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "96vw",
  heigth: "80vh",
  bgcolor: "background.paper",
  // backgroundColor: "primary.light",
  borderRadius: "10px",
  p: 1,
};

export function AddCostModal(
  props: IAssignCostModeltoAddedMaterialsModalProps
) {
  const { onCloseModal, isOpen, costDetailsData,parentClose, SwalAlertHandlerImages } =
    props;

  const {
    vault,
    topVault,
    projectId,
    projectname,
    productId,
    abbrebation,
    cardtype,
    tableId,
    costEstimationId,
    tableCounter,
    condition,
    calctype,
    calcultorId,
    scenarioId
  } = useRouteParams<any>();
  const dispatch = useDispatch();
  const [proceedButtonStatus, setProccedButtonStatus] =
    React.useState<any>(false);
  const [age, setAge] = React.useState("");
  const [selectedMateialItem, setSelectedMaterialItem] =
    React.useState<any>("");
  const [materialItemsData, setMaterialItemsData] = React.useState<any>([]);
  const [allHierarchy, setAllHierarchy] = React.useState<any>([]);
  const [isTreeLoading, setIsTreeLoading] = React.useState<any>(false);
  const store: any = useSelector((state) => state);
  // store?.is_compare?.selcetedCalculators
  const [selectedTreeData, setSelectedTreeData] = React.useState<any>([]);
  const [singleSelectedTreeData, setSingleSelectedTreeData] =
    React.useState<any>([]);
  const [counterApiCall, setCounterApiCall] = React.useState<any>(0);
  const [quantity, setQuantity] = React.useState<any>(1);
  const [loading, setLoading] = React.useState<any>(false);
  const [calculatorsData, setCalculatorsData] = React.useState<any>([]);
  const history = useHistory<any>();
  const handleChangeQuantity = (event: any) => {
    const { value } = event.target;
    setQuantity(value);
  };

  const getMaterialItemData = () => {
    API.get(
      "/cost/material/",
      {
        cost_estimation: costDetailsData && costDetailsData?.cost_estimation,
        calculator: false,
      },
      0
    )
      .then((res: any) => {
        setMaterialItemsData(res.data);
        setSelectedMaterialItem(res.data[0].id);
      })
      .catch((err: any) => { });
  };

  React.useEffect(() => {
    getMaterialItemData();
  }, []);
  React.useEffect(() => {
    if (selectedMateialItem !== "") {
      dispatch(selectedCalculators([]));
    }
  }, [selectedMateialItem]);

  // const getCalcsDetailsData = () => {
  //   API.get(
  //     "/cost/calculator_input/",
  //     { calculator: singleSelectedTreeData[0]?.id },
  //     0
  //   )
  //     .then((res: any) => {
  //       console.log("kjsfukgsdkjsds", res.data);
  //       setCalculatorsData(res.data);
  //     })
  //     .catch((err: any) => {});
  // };
  // React.useEffect(() => {
  //   if (singleSelectedTreeData?.length > 0) {
  //     getCalcsDetailsData();
  //   }
  // }, [singleSelectedTreeData]);

  // React.useEffect(() => {
  //   if (store?.is_compare?.selcetedCalculators) {
  //     setSelectedTreeData(store?.is_compare?.selcetedCalculators);
  //   }
  // }, [store?.is_compare?.selcetedCalculators]);
  const getModule = () => {
    if (calctype == 1) {
      return { material: true };
    } else if (calctype == 2) {
      return { bop: true };
    } else if (calctype == 3) {
      return { process: true };
    } else if (calctype == 4) {
      return { process: true };
    } else if (calctype == 5) {
      return { process: true };
    } else if (calctype == 6) {
      return { process: true };
    } else {
      return { overhead: true };
    }
  };

  const getTreeData = () => {
    setIsTreeLoading(true);
    API.get(
      "/cost/costing_configuration/",
      {
        project: topVault,
        costing_config_hier: true,
        commodity:
          costDetailsData?.commodity?.length > 0
            ? costDetailsData?.commodity?.toString()
            : "commodity-not-available",
        cal_type: calctype,
      },
      0
    )
      .then((res: any) => {
        setAllHierarchy(res.data[0]?.data);
        setIsTreeLoading(false);
      })
      .catch((err: any) => {
        setIsTreeLoading(false);
      });
  };
  React.useEffect(() => {
    getTreeData();
  }, []);

  const onSelect = (
    nodeId: any,
    id: any,
    HierarchyAllData: any,
    itemId: any
  ) => {
    // history.push(`/costingconfiguration/${projectId}/${projectname}/${itemId}/${HierarchyAllData.type}/`);
  };

  const CancelCostModal = useBaseParamsModal();
  const cancelCostModelHndler = () => {
    CancelCostModal.open();
  }
  const closeHandlerParent = () => {
    API.post(
      "/cost/cost_calculator/",
      {
        material: true,
        cost_estimation: costDetailsData?.cost_estimation,
        scenario:scenarioId?scenarioId:undefined
      },
      {},
      0
    )
      .then((res: any) => { 
        onCloseModal();
        parentClose();
      })
      .catch((err: any) => { });
    
  }

  return (
    <div>
      {CancelCostModal.isOpen && (
        <CostModelCancelAlertModal
          isOpen={CancelCostModal.open}
          onCloseModal={CancelCostModal.close}
          cancelHandler={closeHandlerParent}
          parentClose={parentClose}
        />)}

      <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                // textAlign: "center",
                marginLeft: "1rem",
              }}
            >
              Add Cost Model
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={() => { cancelCostModelHndler(); }}
              // onClick={() => {
              //   API.post(
              //     "/cost/cost_calculator/",
              //     {
              //       material: true,
              //       cost_estimation: costDetailsData?.cost_estimation,
              //     },
              //     {},
              //     0
              //   )
              //     .then((res: any) => { })
              //     .catch((err: any) => { });
              //   onCloseModal();
              // }}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "0.5rem",
            }}
          />
          <Box sx={{ padding: "0 1rem" }}>
            <Box className={styles.selectMaterialCost}>
              <Box className={styles.selectedMaterialCalc}>
                <Box
                  sx={{
                    width: "25vw",
                    height: { lg: "59vh", xl: "63vh" },
                    borderRight: "1px solid",
                    borderColor: "primary.light",
                  }}
                  adl-scrollbar="true"
                  adl-scrollbar-width="0.3"
                >
                  {!isTreeLoading && allHierarchy ? (
                    allHierarchy?.map((item: any) => {
                      return (
                        <CalculatorListTree
                          HierarchyData={item}
                          onSelectCallback={onSelect}
                          setSelectedTreeData={setSelectedTreeData}
                          setCounterApiCall={setCounterApiCall}
                          selectedTreeData={selectedTreeData}
                          setSingleSelectedTreeData={setSingleSelectedTreeData}
                          singleSelectedTreeData={singleSelectedTreeData}
                        />
                      );
                    })
                  ) : (
                    <Box sx={{ width: "100%", padding: "0 0.5rem" }}>
                      <Skeleton sx={{ height: "2rem", width: "30%" }} />
                      <Skeleton sx={{ height: "2rem", width: "45%" }} />
                      <Skeleton sx={{ height: "2rem", width: "50%" }} />
                      <Skeleton sx={{ height: "2rem", width: "65%" }} />
                      <Skeleton sx={{ height: "2rem", width: "70%" }} />
                      <Skeleton sx={{ height: "2rem", width: "60%" }} />
                      <Skeleton sx={{ height: "2rem", width: "55%" }} />
                    </Box>
                  )}
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      height: {
                        sm: "60vh",
                        md: "69vh",
                        lg: "56vh",
                        xl: "56vh",
                      },
                      width: "100%",
                    }}
                  >
                    {singleSelectedTreeData?.length > 0 && (
                      <CostingCostCalculator
                        selectedTreeData={singleSelectedTreeData[0]}
                        setCounterApiCall={setCounterApiCall}
                        dataType={singleSelectedTreeData?.type}
                        vault={singleSelectedTreeData?.id}
                      />
                    )}
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      columnGap: "1rem",
                      justifyContent: "flex-end",
                      marginTop: "0.5rem",
                      paddingRight: "0.5rem",
                    }}
                  >
                    {selectedTreeData != '' && <><FormControl sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem', flexDirection: 'row' }}>
                      <label>Add Quantity<sup style={{ color: "red" }}>*</sup> :</label>
                      <TextField
                        type="number"
                        variant="standard"
                        placeholder="Quantity*"
                        value={quantity}
                        onChange={handleChangeQuantity}
                        inputProps={{
                          step: "any",
                          min: 0,
                          style: { fontSize: "1rem" },
                        }}
                        sx={{
                          width: "4rem",
                          borderBottomColor: "primary.light",
                          "& .MuiInput-underline:before": {
                            borderBottomColor: "primary.light",
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor: "primary.main",
                          },
                        }}
                      />
                    </FormControl>
                      <LoadingButton
                        size="small"
                        variant="contained"
                        sx={{
                          ".MuiCircularProgress-root": {
                            color: "primary.main",
                          },
                          "&:hover": {
                            transform: "Scale(1.05)",
                            transition: "transform 0.5s ease",
                          },
                        }}
                        loading={loading}
                        disabled={
                          quantity !== 0 &&
                            store?.is_compare?.selcetedCalculators?.length > 0
                            ? false
                            : true
                        }
                        onClick={() => {
                          setLoading(true);

                          API.post(
                            "/cost/calculation/",
                            {
                              object_id: tableId,
                              calculators:
                                store?.is_compare?.selcetedCalculators?.map(
                                  (item: any) => item.id
                                ),
                              qty: parseInt(quantity),
                              category: calctype ? calctype : 0,
                              multiple: true,
                            },
                            {},
                            0
                          )
                            .then((res: any) => {
                              getMaterialItemData();
                              setLoading(false);
                              setProccedButtonStatus(true);
                              dispatch(selectedCalculators([]))
                            })
                            .catch((err: any) => {
                              setLoading(false);
                            });
                        }}
                      >
                        Add Cost Model
                      </LoadingButton>
                      <Button
                        size="small"
                        variant="contained"
                        disabled={proceedButtonStatus ? false : true}
                        sx={{
                          "&:hover": {
                            transform: "Scale(1.05)",
                            transition: "transform 0.5s ease",
                          },
                        }}
                        //   disabled={proceedButtonStatus ? false : true}
                        // onClick={() => {
                        //   API.post(
                        //     "cost/cost_calculator/",
                        //     {
                        //       material: materialItemsData
                        //         ?.filter(
                        //           (item: any) => item?.calculator_mapped === true
                        //         )
                        //         .map((item: any) => item?.id),
                        //     },
                        //     {},
                        //     0
                        //   )
                        //     .then((res: any) => {
                        //       getRMData();
                        //       parentClose();
                        //       onCloseModal();
                        //     })
                        //     .catch((err: any) => {});
                        // }}
                        onClick={() => {
                          API.post(
                            "/cost/cost_calculator/",
                            {
                              ...getModule(),
                              cost_estimation: costDetailsData?.cost_estimation,
                              scenario:scenarioId?scenarioId:undefined
                            },
                            {},
                            0
                          )
                            .then((res: any) => {
                              dispatch(selectedCalculators([]));
                              SwalAlertHandlerImages(true);
                            })
                            .catch((err: any) => { });
                          // SwalAlertHandlerImages(false)
                          onCloseModal();
                        }}
                      >
                        Proceed
                      </Button>
                    </>}

                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
