import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import {
	Autocomplete,
	Badge,
	Box,
	IconButton,
	InputBase,
	Skeleton,
	Tab,
	TextField,
	styled,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
	useHistory,
	useLocation,
	useParams,
	useRouteMatch,
} from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import SearchIcon from '@mui/icons-material/Search';
import DataBaseDrawer from '../DataBaseDrawer';
import styles from './RevisionAndDataBase.module.scss';
import Chip from '@mui/material/Chip';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
	AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import AddRevisionModal from './AddRevisionModal';
import { useBaseParamsModal } from '../../CustomHook/useBaseParamsModal';
import { deleteSwal } from '../../utlis/deleteSwal';
import { useBaseModal } from '../../ComponentUI/SearchPage/useBaseModal';
import InfoRevisionModal from './InfoRevisionModal';
import { RawMaterialRevisionTable } from './RawMaterialRevisionTable';
import { MachineHourRateTable } from './MHRDataBase/MachineHourRateTable';
import { ToolRevisionTable } from './ToolRevisionTable';
import MachineDatabase from './MHRDataBase/MachineDatabase';
import { LabourHourRateTable } from './LHRDataBase/LabourHourRateTable';
import { ToolDatabase } from './ToolDatabase';
import { RawMaterialDataBase } from './RawMaterialDataBase';
import LabourDatabase from './LHRDataBase/LabourDatabase';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { OperationsDatabase } from './OperationsDatabase/OperationDatabase/OperationsDatabase';
import { SearchLHRrevisionPage } from './LHRDataBase/SearchLHRrevisionPage';
import SearchLHRLibraryPage from './LHRDataBase/SearchLHRLibraryPage';

import SearchMHRLibraryPage from './MHRDataBase/SearchMHRLibraryPage';
import { SearchMHRrevisionPage } from './MHRDataBase/SearchMHRrevisionPage';
import { OperationRevisionTable } from './OperationsDatabase/OperationRevision/OperationRevisionTable';

import OperationRevisionSearch from './OperationsDatabase/OperationRevision/OperationRevisionSearch';
import OperationsDatabaseSearch from './OperationsDatabase/OperationDatabase/OperationsDatabaseSearch';
import { ToolRevisionSearch } from './ToolRevisionSearch';
import { ToolDatabaseSearch } from './ToolDatabaseSearch';
import { BoughtOutPartsTableDatabase } from './BoughtOutParts/BoughtOutPartsTableDatabase';
import { BoughOutPartsRevisionTable } from './BoughtOutParts/BoughtOutPartsRevisionTable';
import { BOPRevisionSearch } from './BoughtOutParts/BOPRevisionSearch';
import { BOPDatabaseSearch } from './BoughtOutParts/BOPDatabaseSearch';
import { RMRevisionSearch } from './RMRevisionSearch';
import { RMDatabaseSearch } from './RMDatabaseSearch';
import deletefill from '../../Assets/images/deletefill.svg';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { API } from '../../api-services';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { userPermissionAction } from '../../ComponentUI/BOMCreate/BOMEntry/BOMEntryLandingPage';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { CurrencyDatabase } from './CurrencyDatabase';
import { CurrencyRevisionTable } from './CurrencyRevisionTable';

import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import FolderIcon from '@mui/icons-material/Folder';
import openFolder from '../../Assets/images/svgs/openfolderblue.svg';
import { debounce, set } from 'lodash';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AssumptionRouteModal from './AssumptionRouteModal';
import { useDocumentTitle } from '../../ui-reusable-component/useDocumentTitle';
import { AssumptionIdSelectedContext, OtherAssumptionIdContext } from '../../ComponentUI/CostingCreateModule/CostingEstimation/CreateCostingPart';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

const Accordion = styled((props: AccordionProps) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {
		borderBottom: 0,
	},
	'&:before': {
		display: 'none',
	},
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
	<MuiAccordionSummary
		// expandIcon={<ArrowRightIcon sx={{ fontSize: "2rem", color: "primary.main" }} />}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor:
		theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'white',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(0deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1),
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	// borderTop: "1px solid #ddefff",
}));

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export interface IRevisionAndProcessProps {}

export function RevisionAndDatabase() {
	const {
		tab,
		year,
		month,
		revisionId,
		typeId,
		databaseName,
		status,
		search,
		categoryName,
	} = useRouteParams<any>();
	useDocumentTitle('Revision and Database');
	const openRevisionModal = useBaseParamsModal();
	const [revisionData, setRevisionData] = React.useState<any>([]);
	const [isLoadingRevisionData, setIsLoadingRevisionData] =
		React.useState<any>(false);
	const [subRevisionData, setSubRevisionData] = React.useState<any>([]);
	const [isSubRevisiondataLoader, setIsSubRevisionDataLoader] =
		React.useState<any>(false);
	const [searchValue, setSearchValue] = useState<any>(
		search == '0' ? '' : search
	);
	const [RecyclebinCounter, setRecyclebinCounter] = useState<any>(0);
	const [isActive, setIsActive] = useState(false);
	const [useraction, setuseraction] = useState<any>(null);
	const [curdaction, setCurdaction] = useState<any>(null);
	const [counter, setCounter] = useState<any>(0);
	const infoModal = useBaseParamsModal();
	const [InfoIds, setInfoIds] = useState<any>();
	const { url } = useRouteMatch();
	const [revisionSelect, setRevisionSelect] = React.useState<any>([]);
	const [tabValue, setTabValue] = React.useState<any>(tab);
	const [value, setValue] = React.useState(tab);
	const [revisionValue, setRevisionValue] = useState<any>([]);
	const [expanded, setExpanded] = React.useState<any | false>();
	const [MonthList, setMonthList] = React.useState<any>([]);

	const [revisionList, setRevisionList] = useState<any>([]);
	const [revisionNameList, setRevisionType] = useState<any>([]);
	const [expandedLoader, setexpandedLoader] = useState<any>(false);
	// const [dbbuttonActive, setDBButtonActive] = useState<any>(true);

	const descRef = React.useRef<any>();
	const OuterRef = React.useRef<any>();
	const userAction = React.useContext(userPermissionAction);
	const [ActionData, setActionData] = useState<any>();
	const [expandedName, setexpandedName] = React.useState<any | false>();
	const [expandedRevisionIds, setexpandedRevisionIds] = React.useState<
		any | false
	>(revisionId);

	const [MonthLoader, setMonthLoader] = useState<any>(false);
	const [revisionLoader, setRevisionLoader] = useState<any>(false);


	const [expandedRevisionType, setexpandedRevisionType] = useState<any | false>(typeId);
	const [RevisionTypeLoader, setRevisionTypeLoader] = useState<any>(false);
	const [OnloadrevisionType, setOnloadRevisionType] = useState<any>(false);
	const [expandedRevisionName, setexpandedRevisionName] = React.useState<any | false>();
	const [materialLoader, setMaterialLoader] = useState<any>(false);
	// const [materialData, setMaterialData] = useState<any>([]);
	const [loader, setLoader] = useState<any>(false)
	const [selectedMaterial, setSelectedMaterial] = useState<any>(null);
	const AssumtionIds = useContext<any>(OtherAssumptionIdContext)
    const AssumptionData = useContext<any>(AssumptionIdSelectedContext)


	
	const [selectedValue, setSelectedValue] = React.useState<any>(null);
	const inputRef = React.useRef<any>(null);

	const [recyclebinCount, setRecyclebinCount] = useState<any>(0);

	const [expandedMonth, setexpandedMonth] = React.useState<any | false>(month);
	

	const [inputValue, setInputValue] = React.useState(null);
	const [SearchrevisionName, setSearchrevisionName] = useState<any>([]);

	const [DataBaseList, setDataBaseList] = useState<any>([])





	const [SearchStatus, setSearchStatus] = useState<any>(false);
	const [dropdown, setDropdown] = useState<any>(false);
	const [DBActive, setDBActive] = useState<any>(false);
	// const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	// const open = Boolean(anchorEl);
	// const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
	// 	setAnchorEl(event.currentTarget);
	// };
	// const handleClick = (revision: any) => {
	// 	setCurrentRevision(revision);

	// }
	// const [selectedMenuItem, setSelectedMenuItem] = useState('Database Library');
	const assumptionModal = useBaseModal();
	// const handleClose = () => {
	// 	setAnchorEl(null);
	// };

	useEffect(() => {
		search == '0' ? setSearchValue('') : setSearchValue(search);
	}, [search]);

	const toggletab = (type: any) => {
		if (type == 2) {
			return { scenario: true };
		} else if (type == 1) {
			return { revision_main: true };
		}
	};
	const ExactDebounce = React.useRef(
		debounce(async (criteria: any, Ids: any) => {
			await API.get('/db/location_revision/', {
				...toggletab(Ids),
				search_name: criteria,
			}).then((res: any) => {
				setSearchrevisionName(res.data);
			});
			//  setCharacters();
		}, 500)
	).current;
	const PartNameHandler = (item: any) => {
		// setSearchrevisionName([])
		setInputValue(item);
		if (item.length > 0) ExactDebounce(item, tab);
	};
	const SearchOption = () => {
		return SearchrevisionName?.map((item: any, index: any) => {
			return {
				name: item.item_name,
				id: item?.id,
				year: item?.abbreviation,
				month: item?.depth,
			};
		});
	};

	const handleChangeSearch = (newValue: any) => {
		setRevisionValue(newValue);

		setSearchrevisionName([]);
		setSearchStatus(false);

		if (newValue !== null) {
			setSearchStatus(true);
			setExpanded(newValue?.year);
			setexpandedMonth(newValue?.month);
			setexpandedRevisionIds(newValue?.id);
			setexpandedRevisionType(newValue?.id);
			history.push(
				`/data-base/revision-database/${tab}/${newValue?.year}/${
					newValue?.month
				}/${newValue?.id}/${newValue?.id}/0/0/true/0`
			);		
		}
		if (newValue == null) {
			setSearchStatus(true);
			history.push(
				`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${newValue?.id}/0/0/true/0`
			);
		
		}
	};

	// const expandBanner = (current: any) => {
	// 	// 👇️ toggle
	// 	setIsActive(current);
	// 	history.push(
	// 		`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/${databaseName}/${categoryName}/${current}/0`
	// 	);
	// 	// 👇️ or set to true
	// 	// setIsActive(true);
	// };

	useEffect(() => {
		setSearchValue(search == '0' ? '' : search);
	}, [search]);
	useEffect(() => {
		API.get(
			'/auth/user_permission_module/',
			{
				action: true,
				database: true,
				alldb: true,
			},
			0
		)
			.then((res: any) => {
				setActionData(res?.data);
			})
			.catch((err: any) => {
				console.log('Server error');
			});
	}, [search]);

	const getRevisionData = () => {
		setIsLoadingRevisionData(true);

		API.get(
			'/auth/user_permission_module/',
			{
				action: true,
				database: true,
				alldb: true,
			},
			0
		)
			.then((res1: any) => {
				setCurdaction(res1?.data);
				if (tab == 1) {
					API.get(
						'/db/location_revision/',
						{
							// scenario: res1?.data?.scenario,
							revision_main: res1?.data?.revision_main,

							year_wise: true,
						},
						0
					)
						.then((res: any) => {
							setRevisionData(res.data);
							setIsLoadingRevisionData(false);
						})
						.catch(() => {
							setIsLoadingRevisionData(false);
						});
				} else if (tab == 2) {
					API.get(
						'/db/location_revision/',
						{
							scenario: res1?.data?.scenario,

							year_wise: true,
						},
						0
					)
						.then((res: any) => {
							setRevisionData(res.data);
							setIsLoadingRevisionData(false);
						})
						.catch(() => {
							setIsLoadingRevisionData(false);
						});
				}

				// setuseraction(res?.data)
				// sessionStorage.setItem("DBPermission", res.data.action);
			})
			.catch((err: any) => {});
	};
	React.useEffect(() => {
		if (tab != 0) {
			getRevisionData();
		}
	}, [tab]);
	const getSubRevisionData = () => {
		setIsSubRevisionDataLoader(true);
		API.get(
			'/db/location_revision/',
			{
				revision: revisionId,
			},
			0
		)
			.then((res: any) => {
				setSubRevisionData(res.data);
				setIsSubRevisionDataLoader(false);
			})
			.catch(() => {
				setIsSubRevisionDataLoader(false);
			});
	};
	React.useEffect(() => {
		if (revisionId != 0) {
			getSubRevisionData();
		}
	}, [revisionId]);

	const history = useHistory();
	const [state, setState] = useState({
		top: false,
		left: false,
		bottom: false,
		right: false,
	});

	const toggleDrawer =
		(anchor: Anchor, open: boolean) =>
		(event: React.KeyboardEvent | React.MouseEvent) => {
			if (
				event.type === 'keydown' &&
				((event as React.KeyboardEvent).key === 'Tab' ||
					(event as React.KeyboardEvent).key === 'Shift')
			) {
				return;
			}

			setState({ ...state, [anchor]: open });
		};

	const list = (anchor: Anchor) => (
		<Box
			sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
			role='presentation'
			// onClick={toggleDrawer(anchor, false)}
			// onKeyDown={toggleDrawer(anchor, false)}
		>
			<DataBaseDrawer />
		</Box>
	);

	const DatabaseNamehandler = (e: any, item: any) => {
		e.stopPropagation();
		const typeId = expandedRevisionType;
		
		setSelectedMaterial(item);
		const databaseName = (type: string) => {
			switch (type) {
				case 'rm':
					return 'Raw Material';
					break;
				case 'bop':
					return 'Bought-Out Parts';
					break;
				case 'machine':
					return 'Machine Hour Rate';
					break;
				case 'tools':
					return 'Tools';
					break;
				case 'labor':
					return 'Labor Hour Rate';
					break;
				case 'operations':
					return 'Operations';
					break;
			}
		};
		// console.log(item,typeId,databaseName,expandedRevisionName, 'item');
		switch (expandedRevisionName || databaseName) {
			case 'Raw Material':
				history.push(
					`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/rm/${item?.category}/false/0`
				);
				break;
			case 'Bought-Out Parts':
				history.push(
					`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/bop/${item?.category}/false/0`
				);
				break;
			case 'Machine Hour Rate':
				history.push(
					`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/machine/${item?.id}/false/0`
				);
				break;
			case 'Labor Hour Rate':
				history.push(
					`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/labor/${item?.id}/false/0`
				);
				break;
			case 'Tools':
				history.push(
					`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/tools/${item?.id}/false/0`
				);
				break;
			case 'Operations':
				history.push(
					`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/operations/${item?.category}/false/0`
				);
				break;
			case 'Currency':
				history.push(
					`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/currency/${item?.name}/false/0`
				);
				break;
		}
	};

	// console.log(selectedMaterial, selectedMaterial?.category,'selectedMaterial');
	const Databasehandler = (revision: any) => {		
		window.open(`/#/data-base/revision-database/${tab}/${year}/${month}/${revision}/${typeId}/rmDB/0/true/0`);		
		// switch (item) {
		// 	case 'Raw Material DB':
		// 		history.push(
		// 			`/data-base/revision-database/${tab}/${year}/${month}/${0}/${typeId}/rmDB/0/true/0`
		// 		);
		// 		setAnchorEl(null);
		// 		break;
		// 	case 'Bought-out Parts DB':
		// 		history.push(
		// 			`/data-base/revision-database/${tab}/${year}/${month}/${0}/${typeId}/bopDB/0/true/0`
		// 		);
		// 		setAnchorEl(null);
		// 		break;
		// 	case 'machineDB':
		// 		history.push(
		// 			`/data-base/revision-database/${tab}/${year}/${month}/${0}/${typeId}/machineDB/0/true/0`
		// 		);
		// 		setAnchorEl(null);
		// 		break;
		// 	case 'laborDB':
		// 		history.push(
		// 			`/data-base/revision-database/${tab}/${year}/${month}/${0}/${typeId}/laborDB/0/true/0`
		// 		);
		// 		setAnchorEl(null);
		// 		break;
		// 	case 'Tools DB':
		// 		history.push(
		// 			`/data-base/revision-database/${tab}/${year}/${month}/${0}/${typeId}/toolsDB/0/true/0`
		// 		);
		// 		setAnchorEl(null);
		// 		break;
		// 	case 'Operations Cost DB':
		// 		history.push(
		// 			`/data-base/revision-database/${tab}/${year}/${month}/${0}/${typeId}/operationsDB/0/true/0`
		// 		);
		// 		setAnchorEl(null);
		// 		break;
		// 	case 'Currency':
		// 		history.push(
		// 			`/data-base/revision-database/${tab}/${year}/${month}/${0}/${typeId}/currencyDB/0/true/0`
		// 		);
		// 		setAnchorEl(null);
		// 		break;
		// }
	};

	const location = useLocation();
	React.useEffect(() => {
		const url = window.location.href;

		if (url.includes('rmDB')) {
			setDropdown('Raw Material DB');
			setDBActive(true);
		} else if (url.includes('bopDB')) {
			setDropdown('Bought-out Parts DB');
			setDBActive(true);
		} else if (url.includes('machineDB')) {
			setDropdown('Machine Hour Rate DB');
			setDBActive(true);
		} else if (url.includes('laborDB')) {
			setDropdown('Labor Hour Rate DB');
			setDBActive(true);
		} else if (url.includes('toolsDB')) {
			setDropdown('Tools DB');
			setDBActive(true);
		} else if (url.includes('operationsDB')) {
			setDropdown('Operation Cost DB');
			setDBActive(true);
		} else if (url.includes('currencyDB')) {
			setDropdown('Currency DB');
			setDBActive(true);
		} else {
			setDropdown('Database Library');
			setDBActive(false);
		}
	}, [location.pathname, setDropdown]);

	React.useEffect(() => {
		API.get(
			'/auth/user_permission_module/',
			{
				action: true,
				database: true,
				module: 'DB-Costing',
			},
			0
		)
			.then((res: any) => {
				setuseraction(res?.data?.action);
				// sessionStorage.setItem("DBPermission", res.data.action);
			})
			.catch((err: any) => {});
	}, []);

	const handleOnChange = (ed: any) => {
		const { value } = ed.target;
		if (value == '') {
			setSearchValue('');
			history.push(
				`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/${databaseName}/0/${status}/0`
			);
		} else {
			setSearchValue(value);
		}
	};

	const searchAPICall = (ed: any) => {
		if (ed.key == 'Enter') {
			if (searchValue == '') {
				history.push(
					`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/${databaseName}/0/${status}/0`
				);
			} else {
				history.push(
					`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/${databaseName}/0/${status}/${searchValue}`
				);
			}
		}
	};
	const getTableComponent = () => {
		if (search != '0') {
			switch (databaseName) {
				case 'labor':
					return (
						<SearchLHRrevisionPage
							setRecyclebinCounter={setRecyclebinCounter}
						/>
					);
				case 'laborDB':
					return (
						<SearchLHRLibraryPage setRecyclebinCounter={setRecyclebinCounter} />
					);
				case 'machine':
					return (
						<SearchMHRrevisionPage
							setRecyclebinCounter={setRecyclebinCounter}
						/>
					);
				case 'machineDB':
					return (
						<SearchMHRLibraryPage setRecyclebinCounter={setRecyclebinCounter} />
					);
				case 'operations':
					return (
						<OperationRevisionSearch
							setRecyclebinCounter={setRecyclebinCounter}
						/>
					);
				case 'currency':
					return (
						<CurrencyRevisionTable
							setRecyclebinCounter={setRecyclebinCounter}
						/>
					);
				case 'operationsDB':
					return (
						<OperationsDatabaseSearch
							setRecyclebinCounter={setRecyclebinCounter}
						/>
					);
				case 'tools':
					return (
						<ToolRevisionSearch setRecyclebinCounter={setRecyclebinCounter} />
					);
				case 'toolsDB':
					return (
						<ToolDatabaseSearch setRecyclebinCounter={setRecyclebinCounter} />
					);
				case 'bop':
					return <BOPRevisionSearch />;
				case 'bopDB':
					return <BOPDatabaseSearch />;
				case 'rm':
					return <RMRevisionSearch />;
				case 'rmDB':
					return <RMDatabaseSearch />;
				case 'currencyDB':
					return <CurrencyDatabase />;
				default:
					break;
			}
		} else {
			switch (databaseName) {
				case 'rm':
					return <RawMaterialRevisionTable />;
				
				case 'bop':
					return <BoughOutPartsRevisionTable />;				

				case 'machine':
					return (
						<MachineHourRateTable
							getSubRevisionData={getSubRevisionData}
							setRecyclebinCounter={setRecyclebinCounter}
						/>
					);

				case 'labor':
					return (
						<LabourHourRateTable
							getSubRevisionData={getSubRevisionData}
							setRecyclebinCounter={setRecyclebinCounter}
						/>
					);

				case 'tools':
					return (
						<ToolRevisionTable setRecyclebinCounter={setRecyclebinCounter} />
					);

				case 'operations':
					return (
						<OperationRevisionTable
							setRecyclebinCounter={setRecyclebinCounter}
						/>
					);

				case 'currency':
					return (
						<CurrencyRevisionTable
							setRecyclebinCounter={setRecyclebinCounter}
						/>
					);
				
				case 'rmDB':
				return <RawMaterialDataBase />;
				case 'bopDB':
						return <BoughtOutPartsTableDatabase />;
				case 'machineDB':
					return (
						<MachineDatabase setRecyclebinCounter={setRecyclebinCounter} />
					);

				case 'laborDB':
						return <LabourDatabase setRecyclebinCounter={setRecyclebinCounter} />;

				case 'toolsDB':
					return <ToolDatabase setRecyclebinCounter={setRecyclebinCounter} />;

				case 'operationsDB':
					return (
						<OperationsDatabase setRecyclebinCounter={setRecyclebinCounter} />
					);					
				case 'currencyDB':
					return <CurrencyDatabase />;

				default:
					break;
			}
		}
	};

	const handleSearchIcon = () => {
		if (searchValue == '') {
			history.push(
				`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/${databaseName}/0/${status}/0`
			);
		} else {
			history.push(
				`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/${databaseName}/0/${status}/${searchValue}`
			);
		}
	};

	const RouteAssumptionHandler = () => {
		assumptionModal.open();
	};

	const NameRevision = (type: any) => {
		switch (type) {
			case 'Raw Material':
				return 'rm';

			case 'Bought-Out Parts':
				return 'bop';

			case 'Machine Hour Rate':
				return 'machine';

			case 'Labor Hour Rate':
				return 'labor';

			case 'Tools':
				return 'tools';

			case 'Operations':
				return 'operations';

			case 'Currency':
				return 'currency';
		}
	};

	const goBackHandler = () => {
		switch (databaseName) {
			case 'rm':
				return history.push(`/data-base/data-base-landing-page`);
			case 'bop':
				return history.push(`/data-base/data-base-landing-page`);
			case 'machine':
				return history.push(`/data-base/data-base-landing-page`);
			case 'tools':
				return history.push(`/data-base/data-base-landing-page`);
			case 'labor':
				return history.push(`/data-base/data-base-landing-page`);
			case 'operations':
				return history.push(`/data-base/data-base-landing-page`);
			case 'laborDB':
				return history.push(
					`/data-base/revision-database/${tab}/${0}/${0}/${0}/${0}/0/0/false/0`
				);
			case 'machineDB':
				return history.push(
					`/data-base/revision-database/${tab}/${0}/${0}/${0}/${0}/0/0/false/0`
				);
			case 'toolsDB':
				return history.push(
					`/data-base/revision-database/${tab}/${0}/${0}/${0}/${0}/0/0/false/0`
				);
			case 'rmDB':
				return history.push(
					`/data-base/revision-database/${tab}/${0}/${0}/${0}/${0}/0/0/false/0`
				);
			case 'bopDB':
				return history.push(
					`/data-base/revision-database/${tab}/${0}/${0}/${0}/${0}/0/0/false/0`
				);
			case 'operationsDB':
				return history.push(
					`/data-base/revision-database/${tab}//${0}/${0}/${0}/${0}/0/0/false/0`
				);
			case 'currencyDB':
				return history.push(
					`/data-base/revision-database/${tab}//${0}/${0}/${0}/${0}/0/0/false/0`
				);
			default:
				break;
		}
	};
	const RecycleBinHanlder = () => {
		history.push('/data-base/revision-database/CostingDataBaseRecycleBin');
	};

	const handleChangeSession =
		(item: any) => (event: React.SyntheticEvent, newExpanded: boolean) => {
			setExpanded(newExpanded ? item?.year : false);
			history.push(
				`/data-base/revision-database/${tab}/${item?.year}/${0}/${0}/${0}/0/0/false/0`
			);
		};

	useEffect(() => {
		if (year != 0 || expanded != 0) {
			setMonthList([]);
			setexpandedLoader(true);
			API.get(
				'/db/location_revision/',
				{
					...toggletab(tab),
					year: year,
				},
				0
			)
				.then((res: any) => {
					setMonthList(res.data);
					setexpandedLoader(false);
				})
				.catch(() => {
					setexpandedLoader(false);
				});
		}
	}, [year, expanded]);

	const handleChangeMonth =
		(item: any) => (event: React.SyntheticEvent, newExpanded: boolean) => {
			setexpandedMonth(newExpanded ? item?.month : false);
			console.log(expandedMonth, 'expandedMonth');
			history.push(
				`/data-base/revision-database/${tab}/${year}/${
					item?.month
				}/${0}/${0}/0/0/false/0`
			);
		};

	useEffect(() => {
		setexpandedMonth(month);
		setexpandedRevisionIds(revisionId);
		setExpanded(year);
		setexpandedRevisionType(typeId);
	}, [year, month, revisionId, typeId]);

	useEffect(() => {
		if (month != 0) {
			if (tab == 1) {
				setMonthLoader(true);
				setRevisionList([]);

				API.get(
					'/db/location_revision/',
					{
						revision_main: true,
						month: month,

						year: year,
					},
					0
				)
					.then((res: any) => {
						setRevisionList(res.data);
						setMonthLoader(false);
					})
					.catch(() => {
						setMonthLoader(false);
					});
			} else if (tab == 2) {
				setMonthLoader(true);
				setRevisionList([]);
				API.get(
					'/db/location_revision/',
					{
						scenario: true,

						month: month,

						year: year,
					},
					0
				)
					.then((res: any) => {
						setRevisionList(res.data);
						setMonthLoader(false);
					})
					.catch(() => {
						setMonthLoader(false);
					});
			}
		}
	}, [month, year, counter]);
	const handleChangeRevisionName =
		(item: any) => (event: React.SyntheticEvent, newExpanded: boolean) => {
			setexpandedRevisionIds(newExpanded ? item?.id : false);
			history.push(
				`/data-base/revision-database/${tab}/${year}/${month}/${
					item?.id
				}/${0}/0/0/false/0`
			);
		};

	useEffect(() => {
		if (revisionId != 0) {
			setRevisionTypeLoader(true);

			API.get(
				'/db/location_revision/',
				{
					revision: revisionId,
				},
				0
			)
				.then((res: any) => {
					setRevisionType(res.data);
					setRevisionTypeLoader(false);
				})
				.catch(() => {
					setRevisionTypeLoader(false);
				});
		}
	}, [revisionId]);


	const handleChangeRevisionType = (revisionName:any) =>(event: React.SyntheticEvent, newExpanded: boolean) => {
		console.log(revisionName,'revisonName');
		if(revisionName?.name === 'Currency') {
		history.push( `/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${revisionName?.id}/currency/0/false/0`) 
		} else {
		setexpandedRevisionType(newExpanded ? revisionName?.id : false);
		setexpandedRevisionName(newExpanded ? revisionName?.name : false);	
		history.push( `/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${revisionName?.id}/${databaseName}/0/false/0`)	
		setMaterialLoader(true);
		// getRevisionMaterial();	
		}
	};

	const getRevisionMaterial =()=>{
	
		if(revisionId !== 0 && revisionId !== undefined && (expandedRevisionName === null || expandedRevisionName === undefined)) {
			API.get(
				'/db/location_revision/',
				{
					revision: revisionId,
				},
				0
			)
				.then((res: any) => {
					setOnloadRevisionType(res.data);					
					setRevisionTypeLoader(false);
					const materialType = res.data.filter((item: any) => item.id == typeId);
					let materialName = materialType[0].name;
					console.log(materialName,'res.data');
					setDataBaseList([]);
					if (materialName && materialName === "Raw Material") {
						// setexpandedRevisionName('Raw Material');
						API.get("/db/revision_database/", { revision_id: revisionId, category_type: "materialcost" }, 0).then((res: any) => {
							setDataBaseList(res.data)
							setLoader(false)
						}).catch((err: any) => {
							console.log("Server Error")
							setLoader(false)
						})				
					} else if (materialName && materialName === 'Machine Hour Rate') {
						if (AssumtionIds && AssumtionIds?.length == 0) {
							API.get("/db/revision_database/", { revision_id: revisionId, category_type: "machinedetail" }, 0).then((res: any) => {
								setDataBaseList(res.data)
								setLoader(false)
							}).catch((err: any) => {
								console.log("Server Error")
								setLoader(false)
							})
						}
					} else if(materialName && materialName === 'Labor Hour Rate') {
						if (AssumtionIds && AssumtionIds?.length == 0) {
							// setLoader(true)
							API.get("/db/revision_database/", { revision_id: revisionId, category_type: "labourdetail" }, 0).then((res: any) => {
								setDataBaseList(res.data)
								setLoader(false)
							}).catch((err: any) => {
								console.log("Server Error")
								setLoader(false)
							})
						}				
					} else if (materialName && materialName === 'Tools') {		
						if (AssumtionIds && AssumtionIds?.length == 0) {
							// setLoader(true)
							API.get("/db/revision_database/", { revision_id: revisionId, category_type: "toolcost" }, 0).then((res: any) => {
								setDataBaseList(res.data)
								setLoader(false)
							}).catch((err: any) => {
								console.log("Server Error")
								setLoader(false)
							})
						}                
					} else if (materialName && materialName === 'Bought-Out Parts') {
						// setLoader(true)
						API.get("/db/revision_database/", {
							category_type: "boughtoutpart", revision_id: revisionId
						}, 0).then((res: any) => {
							setDataBaseList(res.data)
							setLoader(false)
						}).catch((err: any) => {
							setLoader(false)
							console.log("Server Error")
						})
					} else if (materialName && materialName === 'Operations') {
						if (AssumtionIds && AssumtionIds?.length == 0) {
							// setLoader(true)
							API.get("/db/revision_database/", { revision_id: revisionId, category_type: "operationdatabase" }, 0).then((res: any) => {
								setDataBaseList(res.data)
								setLoader(false)
							}).catch((err: any) => {
								console.log("Server Error")
								setLoader(false)
							})
						}
					}
					console.log(DataBaseList, 'DataBaseList');
				})
				.catch(() => {
					setRevisionTypeLoader(false);
				});
		} else if (revisionId !== 0 && revisionId !== undefined && expandedRevisionName !== null || expandedRevisionName !== undefined) {	
			setLoader(true)
			setDataBaseList([])
			if (expandedRevisionName == "Raw Material") {
				// setexpandedRevisionName('Raw Material');
				API.get("/db/revision_database/", { revision_id: revisionId, category_type: "materialcost" }, 0).then((res: any) => {
					setDataBaseList(res.data)
					setLoader(false)
				}).catch((err: any) => {
					console.log("Server Error")
					setLoader(false)
				})				
			}
			if (expandedRevisionName == 'Machine Hour Rate') {
				if (AssumtionIds && AssumtionIds?.length == 0) {
					// setLoader(true)

					// console.log(AssumtionIds?.length, "vdbjvdbvbdvbhdj")
					API.get("/db/revision_database/", { revision_id: revisionId, category_type: "machinedetail" }, 0).then((res: any) => {
						setDataBaseList(res.data)
						setLoader(false)
					}).catch((err: any) => {
						console.log("Server Error")
						setLoader(false)
					})
				}
			}
			if (expandedRevisionName == 'Labor Hour Rate') {
				if (AssumtionIds && AssumtionIds?.length == 0) {
					// setLoader(true)
					API.get("/db/revision_database/", { revision_id: revisionId, category_type: "labourdetail" }, 0).then((res: any) => {
						setDataBaseList(res.data)
						setLoader(false)
					}).catch((err: any) => {
						console.log("Server Error")
						setLoader(false)
					})
				}				
			}
			if (expandedRevisionName == 'Tools') {

				if (AssumtionIds && AssumtionIds?.length == 0) {
					// setLoader(true)
					API.get("/db/revision_database/", { revision_id: revisionId, category_type: "toolcost" }, 0).then((res: any) => {
						setDataBaseList(res.data)
						setLoader(false)
					}).catch((err: any) => {
						console.log("Server Error")
						setLoader(false)
					})
				}                
			}

			if (expandedRevisionName == 'Bought-Out Parts') {
				// setLoader(true)
				API.get("/db/revision_database/", {
					category_type: "boughtoutpart", revision_id: revisionId
				}, 0).then((res: any) => {
					setDataBaseList(res.data)
					setLoader(false)
				}).catch((err: any) => {
					setLoader(false)
					console.log("Server Error")
				})
			}
			if (expandedRevisionName == 'Operations') {
				if (AssumtionIds && AssumtionIds?.length == 0) {
					// setLoader(true)
					API.get("/db/revision_database/", { revision_id: revisionId, category_type: "operationdatabase" }, 0).then((res: any) => {
						setDataBaseList(res.data)
						setLoader(false)
					}).catch((err: any) => {
						console.log("Server Error")
						setLoader(false)
					})
				}
			}
		}
		
	};

	useEffect(() => {
		getRevisionMaterial()
	}, [revisionId, typeId])

	// console.log(DataBaseList,expandedRevisionName, 'DataBaseList');



	useEffect(() => {
		API.get('/auth/mui_trash_view/', {
			bin_type: 'costing_database',
			count: true,
		}).then((res: any) => {
			setRecyclebinCount(res?.data);
		});
	}, [RecyclebinCounter]);
	
	const handleTabClicked = (value: any) => {
		setTabValue(value);
		setRevisionSelect([]);
		setexpandedRevisionIds(0);
		setExpanded(0);
		setexpandedMonth(0);
		setexpandedName(0);
		history.push(
			`/data-base/revision-database/${value}/${0}/${0}/${0}/${0}/0/0/false/0`
		);
	};

	const handleSelectImage = (event: any, newSystemValue: any) => {
		setRevisionSelect(newSystemValue);
	};

	const EditHandler = (e: any, item: any) => {
		e.stopPropagation();
		openRevisionModal.open(item);
	};


	// Tab to Accordian conver code
	

	return (
		<div>
			<Box sx={{ margin: '0 0.5rem' }}>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						// justifyContent: "space-between",
						width: '100%',
					}}>
					{assumptionModal.isOpen && (
						<AssumptionRouteModal
							onCloseModal={assumptionModal.close}
							isOpen={assumptionModal.isOpen}
							Item={curdaction}
						/>
					)}
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							columnGap: '1rem',
							width: { lg: '23vw', xl: '18vw' },
						}}>
						<IconButton
							sx={{ cursor: 'pointer' }}
							title='Go Back'
							onClick={() => goBackHandler()}>
							<ArrowBackIcon color='primary' titleAccess='Go Back' />
						</IconButton>
						<Typography
							sx={{
								fontSize: '1rem',
								whiteSpace: 'nowrap',
							}}>
							Database (Costing DB-Revisions)
						</Typography>
					</Box>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							columnGap: '1.5rem',
							justifyContent: 'space-between',
							// paddingRight: '1rem',
							width: { lg: '77vw', xl: '81vw' },
						}}>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								columnGap: '1.5rem',
							}}>
							{((useraction && useraction?.includes('C')) ||
								(useraction && useraction?.includes('U')) ||
								(useraction && useraction?.includes('R')) ||
								(useraction && useraction?.includes('D'))) && (
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										width: 'fit-content',
									}}>
									{' '}
									{!DBActive ? <Button size='small'
										variant='contained'
										 onClick={()=>Databasehandler(revisionId)}>
											{dropdown == '' ? 'Database Library' : dropdown}
										</Button>: <Typography sx={{fontWeight:'600'}}>
											{dropdown == '' ? 'Database Library' : dropdown}
										</Typography> }
									{/*<Button
										size='small'
										variant='contained'
										endIcon={<ArrowDropDownIcon />}
										id='basic-button'
										aria-controls={open ? 'basic-menu' : undefined}
										aria-haspopup='true'
										aria-expanded={open ? 'true' : undefined}
										onClick={handleClick}>
										{dropdown == '' ? 'Database Library' : dropdown}
									</Button>
									 <Menu
										id='basic-menu'
										anchorEl={anchorEl}
										open={open}
										onClose={handleClose}
										MenuListProps={{
											'aria-labelledby': 'basic-button',
										}}>
										<MenuItem
											sx={{ fontSize: '1rem' }}
											onClick={() => Databasehandler('Raw Material DB')}>
											Raw Material
										</MenuItem>
										<MenuItem
											sx={{ fontSize: '1rem' }}
											onClick={() => Databasehandler('Bought-out Parts DB')}>
											Bought-out Parts
										</MenuItem>
										<MenuItem
											sx={{ fontSize: '1rem' }}
											onClick={() => Databasehandler('machineDB')}>
											Machine Hour Rates
										</MenuItem>
										<MenuItem
											sx={{ fontSize: '1rem' }}
											onClick={() => Databasehandler('laborDB')}>
											Labor Hour Rates
										</MenuItem>
										<MenuItem
											sx={{ fontSize: '1rem' }}
											onClick={() => Databasehandler('Tools DB')}>
											Tools
										</MenuItem>
										<MenuItem
											sx={{ fontSize: '1rem' }}
											onClick={() => Databasehandler('Operations Cost DB')}>
											Operations Cost
										</MenuItem>
										<MenuItem
											sx={{ fontSize: '1rem' }}
											onClick={() => Databasehandler('Currency')}>
											Currency
										</MenuItem>
									</Menu>									 */}
								</Box>
							)}
							{revisionId != 0 && databaseName != 0 && (
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										borderBottom: '1px solid',
										borderBottomColor: 'primary.light',
									}}></Box>
							)}
						</Box>

						{(revisionId != 0 || databaseName != 0) && (
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									borderBottom: '1px solid',
									borderBottomColor: 'primary.light',
								}}>
								<InputBase
									sx={{ ml: 1, flex: 1, fontSize: '1rem' }}
									type='search'
									placeholder='Search..'
									onChange={handleOnChange}
									onKeyDown={searchAPICall}
									value={searchValue}
									inputProps={{ 'aria-label': 'Search..' }}
								/>
								<IconButton
									type='button'
									sx={{ p: '5px' }}
									onClick={handleSearchIcon}
									aria-label='search'>
									<SearchIcon sx={{ color: 'primary.main' }} />
								</IconButton>
								<Box
									sx={{
										width: '2rem',
										textAlign: 'center',
										alignItems: 'baseline',
										display: 'flex',
									}}>
									{recyclebinCount && recyclebinCount?.count > 0 ? (
										<Badge
											color='error'
											badgeContent={recyclebinCount && recyclebinCount?.count}>
											<img
												title='Recycle bin'
												className={styles.internalIcons}
												src={deletefill}
												style={{
													width: 'auto',
													height: '1.6rem',
													// margin: "0px -2px 0 0",
													// verticalAlign: "middle",
													cursor: 'pointer',
												}}
												onClick={() => RecycleBinHanlder()}
											/>
										</Badge>
									) : (
										<DeleteOutlineIcon
											sx={{ fontSize: '1.8rem', cursor: 'pointer' }}
										/>
									)}
								</Box>
							</Box>
						)}
					</Box>
				</Box>
			</Box>
			<Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
				<Box className={styles.expandIconBox}>
					<div className={styles.iconSec}>
						{(['left'] as const).map((anchor) => (
							<React.Fragment key={anchor}>
								<IconButton
									size='medium'
									sx={{
										backgroundColor: 'primary.light',
										color: 'primary.main',
									}}
									onClick={toggleDrawer(anchor, true)}>
									<AddIcon sx={{ fontSize: '1.7rem' }} />
								</IconButton>
								<Drawer
									anchor={anchor}
									open={state[anchor]}
									onClose={toggleDrawer(anchor, false)}
									className={styles.drawerContainer}
									PaperProps={{
										sx: {
											backgroundColor: 'aliceblue',
										},
									}}>
									<Box
										sx={{ padding: '1rem 0rem', paddingBottom: '0' }}
										adl-scrollbar='true'
										adl-scrollbar-width='0.3'>
										<Typography
											style={{
												width: '100%',
												textAlign: 'center',
												fontSize: '1rem',
											}}>
											Costing DB Revisions
										</Typography>
										<Divider sx={{ margin: '0.5rem' }} />
										{list(anchor)}
									</Box>
								</Drawer>
								<Typography
									style={{
										transform: 'rotate(270deg)',
										whiteSpace: 'nowrap',
										marginTop: '40vh',
										cursor: 'pointer',
										fontSize: '1rem',
									}}
									onClick={toggleDrawer(anchor, true)}>
									Costing DB Revisions
								</Typography>
							</React.Fragment>
						))}
					</div>
				</Box>

				{
					<Box
						sx={{
							width: '100%',
							display: 'flex',
							alignItems: 'flex-start',
							paddingRight: '1rem',
						}}>
						{!url.includes('DB') && (
							<Box
								sx={{
									width: { lg: '19vw', xl: '15vw' },
									borderRight: '1px solid',
									borderColor: 'primary.light',
								}}>
								<Box sx={{ padding: '0 0.5rem', width: '100%' }}>
									<Box
										sx={{
											display: 'flex',
											alignItems: 'center',
											width: '100%',
											borderBottom: '1px solid',
										}}>
										{ActionData && ActionData?.revision_main && (
											<Box
												sx={{
													color: 'primary.main',
													height: '2rem',
													minHeight: '2.1rem',
													maxWidth: '100% !important',
													padding: '0',
													fontSize: '1rem',
													width: '100%',
													backgroundColor:
														tabValue == 1 ? 'primary.light' : 'white',
													cursor: 'pointer',
												}}
												onClick={() => handleTabClicked(1)}>
												<Typography
													sx={{
														color: 'primary.main',
														height: '2rem',
														minHeight: '2.1rem',
														maxWidth: '100% !important',
														padding: '0',
														fontSize: '1rem',
														width: '100%',
														textAlign: 'center',
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
													}}>
													Main Costing
												</Typography>{' '}
											</Box>
										)}
										{ActionData && ActionData?.scenario && (
											<Box
												sx={{
													color: '#833406',
													height: '2rem',
													minHeight: '2.1rem',
													maxWidth: '100% !important',
													padding: '0',
													fontSize: '1rem',
													width: '100%',
													backgroundColor: tabValue == 2 ? '#faebd7' : 'white',
													cursor: 'pointer',
												}}
												onClick={() => handleTabClicked(2)}>
												<Typography
													sx={{
														color: '#833406',
														height: '2rem',
														minHeight: '2.1rem',
														maxWidth: '100% !important',
														padding: '0',
														fontSize: '1rem',
														width: '100%',
														textAlign: 'center',
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
													}}>
													Scenario Costing
												</Typography>
											</Box>
										)}
									</Box>
								</Box>
								<Box
									sx={{
										display: 'flex',
										borderBottom: '1px solid',
										borderBottomColor: 'primary.light',
										height: '2.5rem',
										padding: '0 0.5rem',
										overflow: 'hidden',
										alignItems: 'center',
										// width: '100%',
										width: { lg: '20rem', xl: '19rem' },
									}}>									
									<div className={styles.inputbox} ref={OuterRef}>
										{
											<input
												disabled
												type='search'
												placeholder='Search Revision Name'
												value={selectedValue}
												className={styles.searchInput}
												autoFocus={false}
												style={{ color: '#00887a' }}
												// @ts-expect-
												ref={descRef}
												onKeyPress={(event: any) => handleOnChange(event)}
												onChange={(event: any) => handleOnChange(event)}
											/>
										}

										<div
											style={{
												display: 'none',
												zIndex: 900,
												position: 'absolute',
											}}
											className={styles.AutoSearchBox}
											ref={inputRef}>
											<>
												<div className={styles.searchSubText}>
													<div>
														<div className={styles.innserSearchSubText}>
															<span>
																<>
																	<span>
																		Search Name
																		<span>
																			<span>(</span>
																			Search Part
																			<span>)</span>
																		</span>
																		| <span>Search Name</span>
																	</span>
																</>
															</span>
															<span
																style={{
																	paddingTop: '5px',
																}}>
																<SearchIcon />
															</span>
														</div>
													</div>
												</div>
											</>
										</div>
									</div>
									{(curdaction?.scenario_action ||
										curdaction?.main_action ||
										curdaction?.scenario_action?.includes('C') ||
										curdaction?.main_action?.includes('C')) && (
											<Box
												sx={{
													width: '100%',
													height: '100%',
													backgroundColor: tabValue == 1 ? 'primary.light' : '#faebd7',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}>
												<AddCircleOutlineOutlinedIcon
													sx={{ cursor: 'pointer' }}
													titleAccess='ADD Revision Main Costing'
													onClick={() => openRevisionModal.open('add')}
												/>

											</Box>
										)}
								</Box>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'start',
										flexDirection: 'column',
										rowGap: '1rem',
										padding: '0.5rem',
										// marginRight: '-0.5rem',
										// paddingLeft: '0rem',
										height: { lg: '76vh', xl: '83vh' },
										width: '100%',
									}}
									adl-scrollbar='true'
									adl-scrollbar-width='0.3'>
									{!isLoadingRevisionData && revisionData ? (
										<>
											<Box sx={{ width: '100%' }}>
												{revisionData &&
													revisionData?.length > 0 &&
													revisionData?.map((item: any, index: any) => {
														return (
															<>
																<Accordion
																	sx={{ border: 'none' }}
																	expanded={expanded == item?.year}
																	onChange={handleChangeSession(item)}>
																	<AccordionSummary
																		expandIcon={
																			expanded == item?.year ? (
																				<img
																					src={openFolder}
																					style={{ height: '1.2rem' }}
																				/>
																			) : (
																				<FolderIcon
																					sx={{
																						fontSize: '1.5rem',
																						color: 'primary.main',
																					}}
																				/>
																			)
																		}
																		sx={{
																			minHeight: '24px',
																			padding: '0',
																		}}
																		aria-controls='panel1d-content'
																		id='panel1d-header'>
																		<Typography
																			sx={{
																				color:
																					tabValue == 1
																						? 'primary.main'
																						: '#833406',
																				fontSize: '1rem',
																				marginLeft: '.3rem',
																			}}>
																			{item?.year}
																		</Typography>
																	</AccordionSummary>
																	<AccordionDetails>
																		{expandedLoader ? (
																			<>
																				<Skeleton />
																				<Skeleton />
																				<Skeleton />
																				<Skeleton />
																				<Skeleton />
																				<Skeleton />
																				<Skeleton />
																				<Skeleton />
																			</>
																		) : (
																			MonthList &&																			
																			MonthList.map(
																				(itemmonth: any, ind: any) => {
																					return (
																						<>
																							<Accordion
																								sx={{
																									border: 'none',
																									marginLeft: '1rem',
																								}}
																								expanded={
																									itemmonth?.month ==	expandedMonth
																								}
																								onChange={handleChangeMonth(
																									itemmonth
																								)}>
																								<AccordionSummary
																									expandIcon={itemmonth?.month ==	expandedMonth ?
																										<img
																											src={openFolder}
																											style={{ height: '1.2rem' }}
																										/> :
																										<FolderIcon
																											sx={{
																												fontSize: '1.5rem',
																												color: 'primary.main',
																											}}
																										/>
																									}
																									sx={{
																										minHeight: '24px',
																										padding: '0',
																									}}
																									aria-controls='panel1d-content'
																									id='panel1d-header'>
																									<Typography
																										sx={{
																											color:
																												tabValue === 2
																													? '#833406'
																													: 'primary.main',
																											fontSize: '1rem',
																											marginLeft: '0.3rem',
																										}}>
																										{itemmonth?.month}
																									</Typography>
																								</AccordionSummary>

																								<AccordionDetails>
																									{MonthLoader ? (
																										<>
																											<Skeleton />
																											<Skeleton />
																											<Skeleton />
																											<Skeleton />
																											<Skeleton />
																											<Skeleton />
																											<Skeleton />
																										</>
																									) : (
																										revisionList &&
																										revisionList?.map(
																											(
																												revisionItem: any,
																												ind: any
																											) => {
																												return (
																													<>
																														<Accordion
																															sx={{
																																border: 'none',
																																marginLeft:
																																	'0.5rem',
																															}}
																															expanded={
																																revisionItem?.id ==
																																expandedRevisionIds
																															}
																															onChange={handleChangeRevisionName(
																																revisionItem
																															)}>
																															<AccordionSummary
																																expandIcon={
																																	revisionItem?.id ==
																																	expandedRevisionIds ? (
																																		<img
																																			src={
																																				openFolder
																																			}
																																			style={{
																																				height:
																																					'1.2rem',
																																			}}
																																		/>
																																	) : (
																																		<FolderIcon
																																			sx={{
																																				fontSize:
																																					'1.5rem',
																																				color:
																																					'primary.main',
																																			}}
																																		/>
																																	)
																																}
																																sx={{
																																	minHeight:
																																		'24px',
																																	padding:
																																		'0.2rem 0.5rem',
																																}}
																																aria-controls='panel1d-content'
																																id='panel1d-header'>
																																<Typography
																																	sx={{
																																		marginLeft:
																																			'0.3rem',
																																		width:
																																			'100%',
																																		display:
																																			'flex',
																																		alignItems:
																																			'center',
																																		justifyContent:
																																			'space-between',
																																	}}>
																																	<Typography
																																		sx={{
																																			color:
																																				tabValue ==
																																				1
																																					? 'primary.main'
																																					: '#833406',
																																			fontSize:
																																				'1rem',
																																			lineHeight:
																																				'1.2',
																																		}}>
																																		{
																																			revisionItem?.item_name
																																		}
																																		<br />(
																																		{
																																			revisionItem?.city
																																		}
																																		,
																																		{
																																			revisionItem?.country
																																		}
																																		)
																																	</Typography>
																																	<Typography
																																		sx={{
																																			display:
																																				'flex',
																																			alignItems:
																																				'center',
																																			columnGap:
																																				'0.5rem',
																																		}}>
																																		{revisionItem?.action?.includes(
																																			'U'
																																		) && (
																																			<EditIcon
																																				titleAccess='Edit Revision'
																																				sx={{
																																					verticalAlign:
																																						'top',
																																				}}
																																				onClick={(
																																					e: any
																																				) => {
																																					EditHandler(
																																						e,
																																						revisionItem
																																					);
																																				}}
																																			/>
																																		)}
																																		{revisionItem?.mapped_status ? (
																																			<InfoIcon
																																				titleAccess='Revision Info'
																																				sx={{
																																					verticalAlign:
																																						'bottom',
																																					cursor:
																																						'pointer',
																																				}}
																																				onClick={(
																																					e
																																				) => {
																																					e.stopPropagation();
																																					infoModal.open(revisionItem?.id);
																																					setInfoIds(
																																						revisionItem?.id
																																					);
																																				}}
																																			/>
																																		) : (
																																			revisionItem?.action?.includes(
																																				'D'
																																			) && (
																																				<DeleteIcon
																																					titleAccess='Delete Revision'
																																					sx={{
																																						cursor:
																																							'pointer',
																																					}}
																																					onClick={async (
																																						e: any
																																					) => {
																																						e.stopPropagation();
																																						const confirm: any =
																																							await deleteSwal(
																																								'Delete Revision'
																																							);
																																						if (
																																							confirm
																																						) {
																																							API.delete(
																																								`/db/location_revision/${revisionItem.id}/`,
																																								{},
																																								0
																																							)
																																								.then(
																																									() => {
																																										getRevisionData();

																																										API.get(
																																											'db/location_revision/',
																																											{
																																												...toggletab(
																																													tab
																																												),
																																												year: year,
																																											},
																																											0
																																										)
																																											.then(
																																												(
																																													res: any
																																												) => {
																																													setMonthList(
																																														res.data
																																													);
																																													setexpandedLoader(
																																														false
																																													);
																																												}
																																											)
																																											.catch(
																																												() => {
																																													setexpandedLoader(
																																														false
																																													);
																																												}
																																											);

																																										setMonthLoader(
																																											true
																																										);
																																										API.get(
																																											'db/location_revision/',
																																											{
																																												...toggletab(
																																													tab
																																												),
																																												month:
																																													month,

																																												year: year,
																																											},
																																											0
																																										)
																																											.then(
																																												(
																																													res: any
																																												) => {
																																													setRevisionList(
																																														res.data
																																													);
																																													setMonthLoader(
																																														false
																																													);
																																												}
																																											)
																																											.catch(
																																												() => {
																																													setMonthLoader(
																																														false
																																													);
																																												}
																																											);
																																										history.push(
																																											`/data-base/revision-database/${tab}/${year}/${month}/${0}/${0}/${0}/${0}/${0}/0`
																																										);
																																									}
																																								)
																																								.catch(
																																									() => {}
																																								);
																																						}
																																					}}
																																				/>
																																			)
																																		)}
																																	</Typography>
																																</Typography>
																															</AccordionSummary>

																															<AccordionDetails>
																															{RevisionTypeLoader ? (
																																	<>
																																		<Skeleton />
																																		<Skeleton />
																																		<Skeleton />
																																		<Skeleton />
																																		<Skeleton />
																																		<Skeleton />
																																	</>
																																) : (revisionNameList &&
																																			revisionNameList?.map(
																																				(
																																					revisionNameList: any,
																																					indx: any
																																				) => {
																																					return (
																																						<>
																																<Accordion sx={{border: 'none',	marginLeft:'1rem',}}
																																expanded={
																																	revisionNameList?.id ===
																																	typeId
																																}
																																onChange={handleChangeRevisionType(revisionNameList)}>
																																		<AccordionSummary
																																		expandIcon={
																																			revisionNameList?.id ===
																																			typeId ? (
																																				<img
																																					src={
																																						openFolder
																																					}
																																					style={{
																																						height:
																																							'1.2rem',
																																					}}
																																				/>
																																			) : (
																																				<FolderIcon
																																					sx={{
																																						fontSize:
																																							'1.5rem',
																																						color:
																																							'primary.main',
																																					}}
																																				/>
																																			)
																																		}
																																		sx={{
																																			minHeight:
																																				'24px',
																																			padding:
																																				'0.2rem 0.5rem',
																																		}}>
																																			<Typography
																																			sx={{color:tabValue == 1 ? 'primary.main' : '#833406', marginLeft:'0.3rem', width:'100%',display:'flex',	alignItems:'center',justifyContent:'space-between',}}>
																																			{revisionNameList?.name}{' '}({revisionNameList?.count})
																																			</Typography>
																																		</AccordionSummary>
																																		<AccordionDetails>																																			
																																		{revisionNameList?.name !== 'Currency' && loader ? (
																																	<>
																																		<Skeleton />
																																		<Skeleton />
																																		<Skeleton />
																																		<Skeleton />
																																		<Skeleton />
																																		<Skeleton />
																																	</>
																																) : (
																																	revisionNameList?.name !== 'Currency' && <Box
																																		sx={{
																																			marginLeft:
																																				'1.4rem',
																																			borderLeft:
																																				'1px solid',
																																			borderColor:
																																				'primary.main',
																																		}}>
																																		{DataBaseList &&
																																			DataBaseList?.map(
																																				(
																																					item: any,
																																					indx: any
																																				) => {
																																					return (
																																						<>
																																							<Typography
																																								sx={{
																																									backgroundColor: (categoryName === item?.category || categoryName === item?.id) ? '#e6e6fa': '',
																																									display: 'flex',
																																									alignItems:'center',
																																									fontSize:'1rem',
																																									columnGap:'2px',
																																									cursor:'pointer',
																																									marginBottom:'3px',
																																									color:
																																										tabValue ==
																																										1
																																											? (categoryName === item?.category || categoryName === item?.id)
																																												? 'green'
																																												: 'text.primary'
																																											: item?.id ==
																																											  typeId
																																											? 'green'
																																											: '#833406',
																																								}}
																																								onClick={(e: any) => {DatabaseNamehandler(e,item);}}
																																								>
																																								{' '}
																																								<span style={{whiteSpace:'nowrap'}}>--</span>
																																								<span style={{lineHeight:'1.2',width:'100%'}}>{
																																									item?.category
																																								}</span>{' '}
																																								(
																																								{
																																									item?.count
																																								}

																																								)
																																							</Typography>
																																						</>
																																					);
																																				}
																																			)}
																																	</Box>
																																)}	
																																		</AccordionDetails>
																																</Accordion>
																																</>
																																					);
																																				}
																																			))}
																																
																															</AccordionDetails>
																														</Accordion>
																													</>
																												);
																											}
																										)
																									)}
																								</AccordionDetails>
																							</Accordion>
																						</>
																					);
																				}
																			)
																		)}
																	</AccordionDetails>
																</Accordion>
															</>
														);
													})}
											</Box>
										</>
									) : (
										<Box sx={{ padding: '0 1rem', width: '100%' }}>
											<Skeleton
												variant='rectangular'
												height='6rem'
												width='100%'
												sx={{ marginBottom: '1rem' }}
											/>

											<Skeleton
												variant='rectangular'
												height='6rem'
												width='100%'
												sx={{ marginBottom: '1rem' }}
											/>

											<Skeleton
												variant='rectangular'
												height='6rem'
												width='100%'
												sx={{ marginBottom: '1rem' }}
											/>

											<Skeleton
												variant='rectangular'
												height='6rem'
												width='100%'
												sx={{ marginBottom: '1rem' }}
											/>
											<Skeleton
												variant='rectangular'
												height='6rem'
												width='100%'
												sx={{ marginBottom: '1rem' }}
											/>
											<Skeleton
												variant='rectangular'
												height='6rem'
												width='100%'
												sx={{ marginBottom: '1rem' }}
											/>
											<Skeleton
												variant='rectangular'
												height='6rem'
												width='100%'
												sx={{ marginBottom: '1rem' }}
											/>
											<Skeleton
												variant='rectangular'
												height='6rem'
												width='100%'
												sx={{ marginBottom: '1rem' }}
											/>
										</Box>
									)}
								</Box>
							</Box>
						)}

						{/* Here all have to implement other modules as per switch case */}
						{/* This function returns component as per routeparams databasename using swichcase paste own component with case inside this function */}
						<Box
							sx={{ width: DBActive ? '96vw' : { lg: '77vw', xl: '81.5vw' } }}>
							{getTableComponent()}
						</Box>
					</Box>
				}
			</Box>
			{openRevisionModal.isOpen && (
				<AddRevisionModal
					Curdaction={curdaction}
					isOpen={openRevisionModal.isOpen}
					onCloseModal={openRevisionModal.close}
					actions={openRevisionModal.propsId}
					setCounter={setCounter}
					getRevisionData={getRevisionData}
				/>
			)}
			{infoModal.isOpen && (
				<InfoRevisionModal
					revisionData={infoModal.propsId}
					isOpen={infoModal.isOpen}
					onCloseModal={infoModal.close}
				/>
			)}
		</div>
	);
}
