import { omit } from "lodash";
import { API } from "./index.service";
import { ID } from "../../utlis/commonInterfaces";
// import { CalculatorInput } from "../Reducers/calculatorInput.reducer";
import { bomcalculatorInput } from "../Reducers/bomCalculatorInput.reducer";

const url = "config/bom_calculator/";
const IdeaUrl="/idea/idea_calculator_field/"
const trigulationurl = "cost/triangulation_calculator_field/";

export default {
    get ( calculatorId: ID ) {
        console.log(calculatorId)
        return API.get( url, { calculator_parameter: calculatorId } );
    },
    create ( input: Partial<bomcalculatorInput> ) {
        
        return API.post( url, input );
    },
    update ( input: Partial<bomcalculatorInput> ) {
        const id = input.id;
        return API.put( url + id + "/", omit( input, ["id"] ) );
    },
    delete ( inputId: ID ) {
        return API.delete( url + inputId + "/" );
    },

    getTriangulation ( calculatorId: any, ) {
        return API.get( trigulationurl, { calculator: calculatorId } );
    },
    getIdeaCost( calculatorId: any, ) {
        return API.get( IdeaUrl, { calculator: calculatorId } );
    }
    ,
    createCostModal( input: Partial<bomcalculatorInput> ) {
        return API.post( IdeaUrl, input );
    },
    createTiangulation ( input: Partial<bomcalculatorInput> ) {
        return API.post( trigulationurl, input );
    },

    deleteCostModal( inputId: ID ) {
        return API.delete( IdeaUrl + inputId + "/" );
    },

    deleteTriangulation ( inputId: ID ) {
        return API.delete( trigulationurl + inputId + "/" );
    },
    updateCostModal( input: Partial<bomcalculatorInput> ) {
        const id = input.id;
        return API.put( IdeaUrl + id + "/", omit( input, ["id"] ) );
    },
    updateTriangulation ( input: Partial<bomcalculatorInput> ) {
        const id = input.id;
        return API.put( trigulationurl + id + "/", omit( input, ["id"] ) );
    },

    updateflagTriangulation ( id: any, flag: boolean ) {
        return API.patch( trigulationurl + id + "/", { flag: flag } );
    },
    updateflagIdea ( id: any, flag: boolean ) {
        return API.patch( IdeaUrl + id + "/", { flag: flag } );
    },

    uploadFileTriangulation ( id: any, file: any ) {
        return API.patch( trigulationurl + id + "/", { image_url: file } );
    },
    uploadFileIdea ( id: any, file: any ) {
        return API.patch( IdeaUrl + id + "/", { image_url: file } );
    }
};