import * as React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import {
	Box,
	Button,
	Divider,
	FormControl,
	InputLabel,
	Modal,
	Typography,
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { API } from '../../../api-services';
import { useScroll } from '@react-spring/web';
import { LocalDining } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useHistory } from 'react-router-dom';
import { useRouteParams } from '../../../ui-reusable-component/useRouteParams';

export interface IBOMEntryAddSystemModalProps {
	onCloseModal: any;
	isOpen: any;
	getData: any;
	SystemData: any;
	setParentsCounter: any;
	SucessAndErrorMsg: any;
}

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: { lg: '50vw', xl: '50vw' },
	bgcolor: 'background.paper',
	borderRadius: '10px',
	p: 1,
};

export default function BOMEntryAddSystemModal(
	props: IBOMEntryAddSystemModalProps
) {
	const {
		onCloseModal,
		isOpen,
		getData,
		SystemData,
		setParentsCounter,
		SucessAndErrorMsg,
	} = props;
	const {
		projectId,
		vehCateId,
		projectName,
		vehCateName,
		topVault,
		vault,
		treeabbr,
		workstation,
		isLeaf,
		copyStatus,
		privateStatus
	} = useRouteParams<any>();
	const [SubsystemData, setSubsystemData] = React.useState([]);
	const [Selectedsystem, setSelectedsystem] = React.useState([]);
	const [quantity, setquantity] = React.useState<any>(1);
	const [subSystemName, setsubSystemName] = React.useState<any>('');
	const [subSystemAbbreviation, setSubSystemAbbreviation] =
		React.useState<any>('');
	const [SelectedCategory, setSelectedCategory] = React.useState<any>({});
	const [categoryList, setcategoryList] = React.useState<any>([]);
	const [CategoryError, SetCategoryError] = React.useState<any>(false);
	const [systemError, setsystemError] = React.useState<any>(false);
	const [loader, setloader] = React.useState<any>(false);
	const history = useHistory<any>();

	const handleSelectSystems = (event: any, newSystemValue: any) => {
		setsystemError(false);
		console.log(newSystemValue);
		setSelectedsystem(newSystemValue);
	};

	const handleChange = (e: any) => {
		setquantity(e?.target?.value);
	};
	const handleSelectChangecategory = (event: any, newSystemValue: any) => {
		setSelectedCategory(newSystemValue);
		SetCategoryError(false);
    
	};
  // const selectedCateId = () => {
  //   if (SelectedCategory && SelectedCategory.length > 0) {
  //     const CateIds = SelectedCategory?.map((item: any) => {
  //       console.log(item?.category,'item?.category');
  //       return item?.category;        
  //     });
  //     console.log(CateIds,'CateIds');
  //     return CateIds;      
  //   }
  //   console.log(selectedCateId, SelectedCategory,'selectedCateId');
  // };

	// if (
	//   SelectedCategory &&
	//   Object?.keys(SelectedCategory)?.length > 0 &&
	//   Selectedsystem?.length > 0
	// ) {
	//   setloader(true);
	//   let Ids = Selectedsystem?.map((item: any, index: any) => {
	//     return item?.id;
	//   });
	// }

	const CreateHandler = () => {		
		API.post(
			'/bom/vault_info/',
			{
				category: SelectedCategory.category,
				name: subSystemName,
				abbreviation: subSystemAbbreviation,
				vehicle_category: vehCateId,
				quantity: quantity,
			},
			0
		)
			.then((res: any) => {
				history.push(
					`/bomentry/${vehCateId}/${vehCateName}/0/${res?.data?.id}/${treeabbr}/0/${isLeaf}/${copyStatus}/${privateStatus}`
				);
				setParentsCounter((prev: any) => prev + 1);
				SucessAndErrorMsg(true);

				onCloseModal();
				sessionStorage.setItem(
					'BOMCreateNode',
					JSON.stringify(res?.data?.ancestors)
				);
				sessionStorage.setItem('newvault', res?.data?.id);
				setloader(false);
			})
			.catch((err: any) => {
				setloader(false);
				onCloseModal();
				SucessAndErrorMsg(false);
				console.log('Server Error');
			});

		// if (SelectedCategory && Object?.keys(SelectedCategory)?.length == 0) {
		// 	SetCategoryError(true);
		// }
		// if (Selectedsystem?.length == 0) {
		// 	setsystemError(true);
		// }
	};
	const checkboxhandler = (e: any) => {
		if (e?.target?.checked == true) {
			setSelectedsystem(SystemData);
			setsystemError(false);
		} else {
			setSelectedsystem([]);
		}
	};

	React.useEffect(() => {
		if (getData !== null) {
			API.get(
				'/bom/template_config/',
				{
					vehicle_category: vehCateId,
					// is_leaf: false,
					// top_vault:getData?.id, bom_create: true ,is_leaf:false
				},
				0
			)
				.then((res: any) => {
					setcategoryList(res?.data);
				})
				.catch((err: any) => {
					console.log('Server Error');
				});
		}
	}, [getData]);

	React.useEffect(() => {
		setSubsystemData(SystemData);
	}, [SystemData]);

	// console.log(categoryList, 'categoryList');
	return (
		<div>
			<Modal
				open={isOpen}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'>
				<Box sx={style}>
					<Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
						<Typography
							style={{
								fontSize: '1rem',
								fontWeight: '600',
								width: '100%',
								textAlign: 'center',
								marginRight: '-2rem',
							}}>
							Add Part/Assembly
						</Typography>
						<CancelIcon
							titleAccess='Close'
							sx={{ cursor: 'pointer', margin: '0.5rem' }}
							onClick={onCloseModal}
						/>
					</Box>
					<Divider
						sx={{
							borderColor: 'primary.light',
							margin: '0 1rem',
							marginBottom: '1rem',
						}}
					/>
					<form onSubmit={CreateHandler}>
						<Box sx={{ padding: '0 1rem 1rem' }}>
							<Box
								sx={{
									display: 'grid',
									gridTemplateColumns: '1fr 1fr',
									columnGap: '1rem',
									marginBottom: '1rem',
								}}>
								<FormControl fullWidth>
									<TextField
										variant='standard'
										label='Enter Part/Assembly Name'
										placeholder='Enter Part/Assembly Name'
										value={subSystemName}
										onChange={(e) => setsubSystemName(e.target.value)}
										sx={{
											width: '100%',
											'& .MuiInput-underline:before': {
												borderBottomColor: 'primary.main',
											},
											'& .MuiInput-underline:after': {
												borderBottomColor: 'primary.main',
											},
											'.MuiInputBase-root.MuiInput-root': {
												fontSize: '1rem !important',
											},
											'.MuiSvgIcon-root': { color: 'primary.main' },
										}}
									/>
								</FormControl>
								{/* <FormControl fullWidth>
									<TextField
										variant='standard'
										label='Enter Abbrevation'
										placeholder='Enter Abbrevation'
										value={subSystemAbbreviation}
										onChange={(e) => setSubSystemAbbreviation(e.target.value)}
										sx={{
											width: '100%',
											'& .MuiInput-underline:before': {
												borderBottomColor: 'primary.main',
											},
											'& .MuiInput-underline:after': {
												borderBottomColor: 'primary.main',
											},
											'.MuiInputBase-root.MuiInput-root': {
												fontSize: '1rem !important',
											},
											'.MuiSvgIcon-root': { color: 'primary.main' },
										}}
									/>
								</FormControl> */}
							</Box>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'flex-end',
									columnGap: '1rem',
									marginBottom: '1rem',
								}}>
								<FormControl fullWidth variant='standard'>
									<Autocomplete
										id='tags-standard'
										sx={{
											'.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator':
												{
													color: 'primary.main',
												},
											'.MuiButtonBase-root.MuiChip-root': {
												backgroundColor: 'primary.light',
												height: '26px',
											},
										}}
										options={categoryList}
										getOptionLabel={(option) =>
											// @ts-ignore
											option?.commodity_name == undefined
												? ''
												: option?.commodity_name
										}
										value={SelectedCategory}
										onChange={(event: any, newSystemValue: any) => {
											handleSelectChangecategory(event, newSystemValue);
										}}
										// getOptionDisabled={getOptionDisabled}
										renderOption={(props, option) => (
											<li {...props} style={{ fontSize: '1rem' }}>
												{option?.commodity_name}
											</li>
										)}
										renderInput={(params) => (
											<TextField
												{...params}
												variant='standard'
												placeholder='Select Category'
												sx={{
													'& .MuiInput-underline:before': {
														borderBottomColor: 'primary.main',
													},
													'& .MuiInput-underline:after': {
														borderBottomColor: 'primary.main',
													},
													'.MuiInputBase-root.MuiInput-root': {
														fontSize: '1rem !important',
													},
													'.MuiSvgIcon-root': { color: 'primary.main' },
												}}
											/>
										)}
									/>

									{CategoryError ? (
										<span style={{ color: 'red' }}>
											{' '}
											*Please Select Category
										</span>
									) : (
										''
									)}
								</FormControl>

								<FormControl fullWidth>
									<TextField
										value={quantity}
										onChange={(e: any) => handleChange(e)}
										variant='standard'
										type='number'
										label='Quantity'
										inputProps={{
											min: 1,
										}}
										InputLabelProps={{
											shrink: true,
											style: {
												color: '#00887a',
											},
										}}
										placeholder='Quantity'
										sx={{
											'.MuiInputBase-root.MuiInput-root': {
												fontSize: '1rem !important',
											},
											'& .MuiInput-underline:before': {
												borderBottomColor: 'primary.main',
											},
											'& .MuiInput-underline:after': {
												borderBottomColor: 'primary.main',
											},
										}}
									/>
								</FormControl>
							</Box>
							<Box sx={{ width: '100%', textAlign: 'right' }}>
								 <LoadingButton
									variant='contained'
									disabled={
										(subSystemName !== null && subSystemName !== ''  && SelectedCategory && Object.keys(SelectedCategory)?.length>0 && SelectedCategory != null && SelectedCategory != '' &&  quantity !== null && quantity !== '') ? false : true}
									size='small'
									loading={loader}
									type='submit'>
									Add Part/Assembly
								</LoadingButton>
							</Box>
						</Box>
					</form>
				</Box>
			</Modal>
		</div>
	);
}
