// react

import axios, { AxiosError, AxiosResponse } from "axios";
import { MODULES } from "../../Constants/modules.constants";
import { API } from "../../api-services";
import { s3Url } from "../../utlis/s3url.utils";
import { getVideoCover } from "../../CustomHook/CreateVideoThumbnail";
import { ADMIN } from "../../Redux/Services/admin.service";
import { createRepoImage, updateRepoImage } from "../../Redux/Actions/imageRepository.actions";
import Swal from "sweetalert2";
import CDN_URL from "../../utlis/CDN_URL";


const BOMImageSingleUpload = (
   fileList: any,
   component_details:any,
   parameter_id:any,
   vault:any,
   // top_vault:any,
   vehCateId: any,
   setApiCallVaultData:any,
   apiCallVaultData:any,
   nextValExist:any,
   setIsUpdating:any,
   callAPIOfNextImage?:any,
   type?:any,
   // setImageUrl?:any
) => {
   // const file_name = file?.name;
   var random: any = Math.floor( Math.random() * 1000000 + 1 );
   var editedImageName: any = `EditedImage${random}`;
   var obj: Array<any> = [];
   // const dispatch = useAppDispatch();
   // + "-" + uuidv4();
   API.get("cart/get_signed_policy/", {
      module: MODULES.CAD,
      // product: top_vault == 0 ? vault : top_vault,
      product : vault,
   } ).then( res => {

      const data = Array.from( fileList ).forEach( ( file: any, index: any ) => {
         const formData = new FormData();
         formData.append(
            "key", `BOMEntryImage/${vehCateId}/${vault ? vault : "Vehicle"}/${random}/${file.name || editedImageName}` );
         formData.append( "Content-Type", file?.type );
         formData.append( "x-amz-server-side-encryption", "AES256" );
         formData.append( "X-Amz-Credential", res.data.data.x_amz_credential );
         formData.append( "X-Amz-Algorithm", "AWS4-HMAC-SHA256" );
         formData.append( "X-Amz-Date", res.data.data.x_amz_date );
         formData.append(
            "X-Amz-Signature",
            res.data.data.aws_policy_signature
         );
         formData.append( "Policy", res.data.data.aws_policy );
         formData.append( "file", file, file?.name );

         axios
            .post( s3Url, formData, {
               onUploadProgress: data => {
                  //Set the progress value to show the progress bar
               },
            } )
            .then( async ( res: any ) => {
               if ( res.status === 204 ) {
                if(type=="video") {
                  const data = new FormData();
                  const dataThumnail = new FormData();
                  // get the frame at 1.5 seconds of the video file
                   const cover = await getVideoCover( file, 1.5 );
                  //  @ts-ignore
                   dataThumnail.append(parameter_id, cover);
                   data.append( parameter_id, `/BOMEntryImage/${vehCateId}/${vault ? vault : "Vehicle"}/${random}/${file.name || editedImageName}`); 
                   API.put(`/bom/component_details/${component_details}/`,data,{vault:vault == '0' ? null : vault ,vehCateId:vault == '0' ? vehCateId : null,video:true,},0).then((res:AxiosResponse)=>{

                        API.put(`/bom/component_details/${component_details}/`,dataThumnail,{video_thumb:true,vault:vault == '0' ? null : vault ,vehCateId:vault == '0' ? vehCateId : null,video:true,},0).then((res:AxiosResponse)=>{
                           setApiCallVaultData(!apiCallVaultData);
                           // setImageUrl(CDN_URL + `/BOMEntryImage/${vehCateId}/${vault ? vault : "Vehicle"}/${random}/${file.name || editedImageName}`);
                           setIsUpdating(false);
                           }).catch((err:AxiosError)=>{
                           })
                        }).catch((err:AxiosError)=>{
                           setIsUpdating(false);
                           //@ts-ignore
                           const {data} = err.response;
                           Swal.fire({
                              title: 'Error!',
                              text: data[0],
                              icon: 'error',
                              confirmButtonText: 'Ok'
                            })
                        })
               } 
               else{
                  const data = new FormData();
                  data.append( parameter_id, `/BOMEntryImage/${vehCateId}/${vault ? vault : "Vehicle"}/${random}/${file.name || editedImageName}` );
                  API.put(`/bom/component_details/${component_details}/`,data,{vault:vault == '0' ? null : vault ,vehCateId:vault == '0' ? vehCateId : null,image:true,rgb:false},0).then((res:AxiosResponse)=>{
                     // setImageUrl(CDN_URL + `/BOMEntryImage/${vehCateId}/${vault ? vault : "Vehicle"}/${random}/${file.name || editedImageName}`);
                     setIsUpdating(false);
                     // nextValExist ? callAPIOfNextImage('next') : setApiCallVaultData(!apiCallVaultData);
                     }).catch((err:AxiosError)=>{
                        setIsUpdating(false);
                        if(err.response){
                        //@ts-ignore
                        const {data} = err.response;
                        Swal.fire({
                           title: 'Error!',
                           text: data[0],
                           icon: 'error',
                           confirmButtonText: 'Ok'
                         })
                        }
                     })
               }
                  };
               }
            ).catch((err)=>{return ADMIN?.toast?.error(`CDN access denied`);});
      } );
   } );
};

// ----------------------------------------------------------------------------------

export default BOMImageSingleUpload;
