import * as React from 'react';
import styles from './MediaCreateLanding.module.scss';
import {
  Badge,
  Box,
  Dialog,
  Divider,
  Drawer,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import { useHistory } from "react-router-dom";
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import { MediaCreateBody } from './MediaCreateBody';
import { MediaCreateDrawer } from './MediaCreateDrawer';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import CancelIcon from "@mui/icons-material/Cancel"
import { createContext, useState } from 'react';
import { API } from '../../api-services';
import { AxiosError, AxiosResponse } from 'axios';
import MediaDownloadSystemTree from './MediaDownloadSystemTree';
import MediaGroupDownloadConsolidated from './MediaGroupDownloadConsolidated';
import { ID } from '../../utlis/commonInterfaces';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { updateGroupsByType } from '../../Redux/Actions/groupingMediaCreate.action';
import swal from "sweetalert";
import recyleBin from "../../Assets/images/deletefill.svg";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import excelIcon from "../../Assets/images/excel.png";
import ErrorModalFilesMail from '../ProjectMailModule/ErrorModalFilesMail';
import { useBaseModal } from '../SearchPage/useBaseModal';
import { byAbbreviationSelectors } from '../../Redux/Reducers/groupingMediaCreate.reducer';
import Swal from 'sweetalert2';
import successIcon from "../../Assets/images/success.png";
import { useDocumentTitle } from '../../ui-reusable-component/useDocumentTitle';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Params {
  projectId: any;
  projectName: any;
  mediaType: any;
  category: any;
  abbreviation: any;
  groupId: any;
  productList: any;
  pageNumber: any;
  apiCall: any;
}

export const MyContext = createContext<any>('');
export interface IMediaCreateLandingProps {
}

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export function MediaCreateLanding(props: IMediaCreateLandingProps) {
  const { projectId, projectName, productList, mediaType, category, abbreviation, groupId, pageNumber, apiCall } = useRouteParams<Params>();
  useDocumentTitle( "Media Create" );
  const [openMediaDownload, setOpenMediaDownload] = React.useState(false);
  const [openMediaConsolidatedDownload, setOpenMediaConsolidatedDownload] = React.useState(false);
  const [openMediaDeletePopUp, setOpenMediaDeletePopUp] = React.useState(false);
  const [groupCurrentInfo, setGroupCurrentInfo] = React.useState<any>([]);
  const [groupCurrentInfoLoader, setGroupCurrentInfoLoader] = React.useState<boolean>(false);
  const [downloadProductInfo, setDownloadProductInfo] = React.useState<any>();
  const [selectedTopVaultIds, setSelectedTopVaultIds] = useState<ID[]>([]);
  const [callGroupAPI, setCallGroupAPI] = React.useState<boolean>(false);
  const [callGroupNameAPI, setCallGroupNameAPI] = React.useState<boolean>(false);
  const [groupName, setGroupName] = useState<any>('');
  const [idLoader, setIdLoader] = useState<boolean>(false);
  const [idDelete, setIdDelete] = useState<any>();
  const [isNaShowData, setIsNaShowData] = useState<any>();
  const [isNaShowDataApiCall, setIsNaShowDataApiCall] = useState<boolean>(false);
  const [recyclebinCount, setRecyclebinCount] = useState<any>()
  const [recycleBinCounter, setRecycleBinCounter] = useState<number>(1);
  const [errorMessage, setErrorMessage] = useState<any>();
  const [permissionAccess, setPermissionAccess] = useState<any>();

  const history = useHistory();
  const dispatch = useDispatch();
  const errorValidation = useBaseModal();

  const GroupNames = useSelector(byAbbreviationSelectors.selectAll);


  const topVaultsParam: any =
    productList && typeof productList == "string"
      ? productList?.split(",")?.map(Number)
      : [productList];

  React.useEffect(() => {
    API.get("/auth/user_permission_module/", {
      action: true,
      media_create: true,
      project: projectId
    }).then((res: any) => {
      setPermissionAccess(res?.data);

    }).catch((err: any) => {
      console.log(err, "err Message")
    })
  }, [projectId])

  React.useEffect(() => {
    setSelectedTopVaultIds(topVaultsParam);
  }, [productList]);

  React.useEffect(() => {
    API.get("/image_repository/media_recycle_bin/", { bin_count: true, project: projectId }).then((res: any) => {
      setRecyclebinCount(res?.data)

    }).catch((err: any) => {
      console.log(err, "err Message")
    })
  }, [projectId, recycleBinCounter])

  React.useEffect(() => {
    if (groupId != 0) {
      API.get(
        "/image_repository/upload_na_image_group/",
        {
          project: projectId,
          top_vault_list: productList,
          media_type: mediaType,
          image_category: category,
          abbreviation: abbreviation,
          group: groupId
        },
        0
      )
        .then((res: AxiosResponse) => {
          setIsNaShowData(res?.data)
        })
        .catch((err: AxiosError) => {
          console.log(err, "error Message");
        });
    }
  }, [groupId, isNaShowDataApiCall, apiCall]);

  React.useEffect(() => {
    if (category != 0) {
      setGroupCurrentInfoLoader(true);
      API.get(
        "/image_repository/media_module/",
        {
          project: projectId,
          abbreviation: abbreviation,
          media_type: mediaType,
          image_category: category,
          get_groups: true,
          get_media_groups: true,
        },
        0
      )
        .then((res: AxiosResponse) => {
          setGroupCurrentInfo(res?.data);
          setGroupCurrentInfoLoader(false);
        })
        .catch((err: AxiosError) => {
          console.log(err, "error Message");
        });
    }
  }, [category, callGroupNameAPI, groupId]);

  React.useEffect(() => {
    setGroupName(groupCurrentInfo && groupCurrentInfo?.filter((item: any) => item?.id == groupId)[0]?.name)
  }, [groupId, groupCurrentInfo])

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }
        setState({ ...state, [anchor]: open });
      };

  const list = (anchor: Anchor) => (
    <MediaCreateDrawer anchorComing={anchor} toggleDrawer={toggleDrawer} />
  );


  const handleClick = () => {
    history.push(`/internallanding`);
  };

  const handleCloseMediaDownload = () => {
    setOpenMediaDownload(false);
  };

  const handleCloseMediaConsolidatedDownload = () => {
    setOpenMediaConsolidatedDownload(false);
  };

  const handleCloseDeletePopUp = () => {
    setOpenMediaDeletePopUp(false);
  };

  const handleDeletePopUp = (type: any) => {
    API.get(
      "/image_repository/media_module/",
      {
        project: projectId,
        media_type: mediaType,
        image_category: category,
        abbreviation: abbreviation,
        download_image_count: true,
        group_id: groupId,
      },
      0
    )
      .then((res: AxiosResponse) => {
        setDownloadProductInfo(res?.data);
        type == 'openDeleteModel' && setOpenMediaDeletePopUp(true);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error console");
      });
  }

  const MediaDownloadTree = (type: any) => {
    if (type == "consolidatedGroup") {
      API.get(
        "/image_repository/media_module/",
        {
          project: projectId,
          media_type: mediaType,
          image_category: category,
          abbreviation: abbreviation,
          download_image_count: true,
          group_id: groupId,
        },
        0
      )
        .then((res: AxiosResponse) => {
          setDownloadProductInfo(res?.data);
          setOpenMediaConsolidatedDownload(true);
        })
        .catch((err: AxiosError) => {
          console.log(err, "error console");
        });
    }
    else {
      API.get(
        "/image_repository/media_module/",
        {
          project: projectId,
          media_type: mediaType,
          image_category: category,
          abbreviation: abbreviation,
          download_image_count: true,
          group_id: groupId,
        },
        0
      )
        .then((res: AxiosResponse) => {
          setDownloadProductInfo(res?.data);
          setOpenMediaDownload(true);
        })
        .catch((err: AxiosError) => {
          console.log(err, "error console");
        });
    }
  };

  const handleChangeGroupName = (e: any) => {
    setGroupName(e.target.value);
  }

  const handleGroupNameAPICall = () => {
    if (groupName.trim() != groupCurrentInfo?.filter((item: any) => item?.id == groupId)?.[0]?.name) {
      dispatch(
        updateGroupsByType({
          id: groupCurrentInfo?.filter((item: any) => item?.id == groupId)?.[0]?.id,
          name: groupName.trim(),
          //@ts-ignore
        })).then((res: any) => {
          if (res.payload?.response?.status == 400) {
            // setGroupName(groupCurrentInfo && groupCurrentInfo?.filter((item: any) => item?.id == groupId)[0]?.name);
            setErrorMessage(res.payload?.response?.data[0]);
            errorValidation.open();
          }
          else {
            setCallGroupAPI(!callGroupAPI);
            history.push(
              `/mediacreateinternal/${projectId}/${projectName}/${mediaType}/${abbreviation}/${category}/${groupId}/${productList}/${pageNumber}/${apiCall == "true" ? "false" : "true"}`
            );
          }
        })
    }
  }

  const callDeleteAPI = async (e: React.MouseEvent, id: number) => {
    e.stopPropagation();
    setIdDelete(id);
    swal({
      title: `Delete Media `,
      text: `Are you sure?`,
      icon: "error",
      //@ts-ignore
      buttons: {
        cancel: "Cancel",
        permanent: {
          text: `Delete`,
          value: `deleteMedia`,
        },
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
      className: "delete-swal",
    }).then(async (type: string) => {
      switch (type) {
        case "deleteMedia":
          setIdLoader(true);
          API.delete("/image_repository/fetchs/", {
            top_vault: id,
            image_category: category,
            abbreviation: abbreviation,
            group: groupId,
            media_type: mediaType
          }, 0)
            .then((res: any) => {
              setIdLoader(false);
              setCallGroupAPI(!callGroupAPI);
              setRecycleBinCounter((prev: any) => prev + 1);
              setIsNaShowDataApiCall(!isNaShowDataApiCall);
              handleDeletePopUp('deleteAfterCall')
              history.push(
                `/mediacreateinternal/${projectId}/${projectName}/${mediaType}/${abbreviation}/${category}/${groupId}/${productList}/${pageNumber}/${apiCall == "true" ? "false" : "true"}`
              );
              setIdDelete(undefined);
            })
            .catch((err: any) => {
              console.log(err, "Unable to upload file. Server Error");
            });
          break;
        default:
          swal(`Data saved from deleting`, { icon: "error" });
      }
    });
  };

  const handleFillNAImages = () => {
    API.post(
      "/image_repository/upload_na_image_group/",
      {},
      {
        project: projectId,
        top_vault_list: productList,
        abbreviation: abbreviation,
        image_category: category,
        media_type: mediaType,
        group: groupId,
      },
      0
    )
      .then((res: AxiosResponse) => {
        Swal.fire({
          html:  `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
                    <br />
                    <p style="color:#007fff;">NA Images Filled Successfully in blank places.
                    </p>   
                  </div>`,
          });
        setCallGroupAPI(!callGroupAPI);
        setCallGroupNameAPI(!callGroupNameAPI);
        setIsNaShowDataApiCall(!isNaShowDataApiCall);
        history.push(
          `/mediacreateinternal/${projectId}/${projectName}/${mediaType}/${abbreviation}/${category}/${groupId}/${productList}/${pageNumber}/${apiCall == "true" ? "false" : "true"}`
        );
      })
      .catch((err: AxiosError) => {
        console.log(err, "error console");
      });
  };

  const RecycleBinHanlder = () => {
    history.push(`/mediacreateinternal/recycleBin/${projectId}`);
  };

  return (
    <div>
      <ErrorModalFilesMail
        onCloseModal={errorValidation.close}
        isOpen={errorValidation.isOpen}
        ErrorMsg={errorMessage}
      />
      {openMediaDownload && <Dialog
        open={openMediaDownload}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseMediaDownload}
        sx={{
          ".MuiPaper-root.MuiDialog-paper":
            { borderRadius: '10px' }
        }}
      >
        <DialogContent sx={{ width: "42rem", padding: "0rem" }}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              {groupCurrentInfo && groupCurrentInfo?.filter((item: any) => item?.id == groupId)?.[0]?.name} Group Download{" "}
              {mediaType == "image"
                ? " ( maximum 125 images at a time)"
                : " ( maximum 10 Videos at a time )"}
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={handleCloseMediaDownload}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ margin: "0.5rem", marginBottom: '1rem' }}>
            <MediaDownloadSystemTree downloadProductInfo={downloadProductInfo} groupContextAbbreviation={abbreviation} groupContextId={groupId} groupContextImageCategory={category} openMediaDownload={openMediaDownload} type={'groupLevel'} permissionAccess={permissionAccess}/>
          </Box>
        </DialogContent>
      </Dialog>}
      {openMediaConsolidatedDownload &&
        <Dialog
          open={openMediaConsolidatedDownload}
          TransitionComponent={Transition}
          keepMounted
          sx={{
            ".MuiPaper-root.MuiDialog-paper":
              { borderRadius: '10px' }
          }}
        >
          <DialogContent sx={{ width: "42rem", padding: "0rem" }}>
            <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "center",
                  marginRight: "-2rem",
                }}
              >
                Media Consolidated Download (Excel)
              </Typography>
              <CancelIcon
                titleAccess="Close"
                sx={{ cursor: "pointer", margin: "0.5rem" }}
                onClick={handleCloseMediaConsolidatedDownload}
              />
            </Box>
            <Box sx={{ margin: "0.5rem", color: "grey" }}>
              <MediaGroupDownloadConsolidated countImage={downloadProductInfo && downloadProductInfo?.reduce((totalItem: any, item: any) => totalItem + item?.count, 0)} groupContextId={groupId} groupContextTopVault={selectedTopVaultIds} groupContextImageCategory={category} groupContextAbbreviation={abbreviation} openMediaConsolidatedDownload={openMediaConsolidatedDownload} setOpenMediaConsolidatedDownload={setOpenMediaConsolidatedDownload} groupContextGroupName={groupCurrentInfo && groupCurrentInfo?.filter((item: any) => item?.id == groupId)?.[0]?.name} />
            </Box>
          </DialogContent>
        </Dialog>}
      {openMediaDeletePopUp &&
        <Dialog
          open={openMediaDeletePopUp}
          TransitionComponent={Transition}
          keepMounted
          sx={{
            ".MuiPaper-root.MuiDialog-paper":
              { borderRadius: '10px' }
          }}
        >
          <DialogContent sx={{ width: "42rem", padding: "0rem" }}>
            <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "center",
                  marginRight: "-2rem",
                }}
              >
                Media Delete
              </Typography>
              <CancelIcon
                titleAccess="Close"
                sx={{ cursor: "pointer", margin: "0.5rem" }}
                onClick={handleCloseDeletePopUp}
              />
            </Box>
            <Box sx={{ margin: "0.5rem", color: "grey" }}>
              <Table>
                <TableHead sx={{ backgroundColor: 'primary.light' }}>
                  <TableRow>
                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Product</TableCell>
                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Media Count</TableCell>
                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {downloadProductInfo && downloadProductInfo?.map((item: any) => {
                    return (
                    <>
                    {permissionAccess && permissionAccess?.filter((itemInner: any) => itemInner?.top_vault == item?.top_vault_id )?.[0]?.action?.includes('D') && 
                    <TableRow>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>{item?.name}</TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>{item?.count}</TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <LoadingButton
                          sx={{
                            padding: "0.2rem",
                            lineHeight: 1,
                            "&:hover": {
                              transform: 'Scale(1.05)',
                              transition: 'transform 0.5s ease',
                            },
                            '.MuiCircularProgress-root': {
                              color: 'primary.main',
                            },
                          }}
                          loading={idDelete == item?.top_vault_id ? idLoader : false} disabled={item?.count == 0} onClick={(e: any) => callDeleteAPI(e, item?.top_vault_id)}>
                          <DeleteIcon />
                        </LoadingButton>
                      </TableCell>
                    </TableRow>}
                    </>)
                  })}
                </TableBody>
              </Table>
            </Box>
          </DialogContent>
        </Dialog>}
      <Box sx={{ padding: "0 0.5rem", display: 'flex', alignItems: 'center' }}>
        <Box className={styles.titleSection}>
          <Box className={styles.titleSectionLeft} >
            <IconButton sx={{ cursor: "pointer", }} onClick={handleClick}>
              <ArrowBackIcon
                color="primary"
                titleAccess="Go Back"
              />
            </IconButton>
            <Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap', display: 'inline-block' }}>{projectName} [ Upload Media - <span style={{ textTransform: 'capitalize' }}>{mediaType}</span> ]</Typography>
          </Box>
          <Box className={styles.titleSectionRight}>
          {groupCurrentInfoLoader ? 
          <Skeleton variant="text" width='20rem' height={30} />
          :
            groupId != 0 && (permissionAccess && permissionAccess?.filter((item: any) => item?.top_vault == 'all')?.[0]?.action?.includes('U') ?
              <TextField
                variant='standard'
                sx={{
                  width: '20rem',
                  ".MuiInputBase-root.MuiInput-root": {
                    fontSize: '1rem !important'
                  },
                  '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                  '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                }}
                onChange={(e: any) => handleChangeGroupName(e)}
                value={groupName}
                onBlur={() => {
                  handleGroupNameAPICall();
                }}
              />
              : <span style={{ display: 'inline-block', width: '14rem', fontSize: '1rem !important' }}>groupName</span>)}
            {groupId != 0 && <div style={{ display: 'flex', alignItems: 'center', columnGap: '1rem', paddingRight: '0.5rem' }}>
              {isNaShowData && isNaShowData?.is_NA_show && permissionAccess && permissionAccess?.filter((item: any) => item?.top_vault == 'all')?.[0]?.action?.includes('C') && GroupNames?.length>0 && GroupNames?.filter((grp: any) => grp?.id == groupId)[0]?.image_count != 0 && <span style={{ cursor: 'pointer', whiteSpace: 'nowrap' }} onClick={handleFillNAImages}>Blank Fill with NA</span>}
              {mediaType == 'image' && GroupNames?.length>0 && GroupNames?.filter((grp: any) => grp?.id == groupId)[0]?.image_count != 0 && <img src={excelIcon} title='Excel Download' style={{ height: '1.5rem', cursor: 'pointer', }} onClick={() => MediaDownloadTree("consolidatedGroup")} />}
              {GroupNames?.length>0 && GroupNames?.filter((grp: any) => grp?.id == groupId)[0]?.image_count != 0 && <DownloadIcon titleAccess='ZIP Download' sx={{ cursor: 'pointer', }} onClick={() => MediaDownloadTree("zipFile")} />}
              {permissionAccess && permissionAccess?.filter((item: any) => item?.top_vault == 'all')?.[0]?.action?.includes('D') && GroupNames?.length>0 && GroupNames?.filter((grp: any) => grp?.id == groupId)[0]?.image_count != 0 && <DeleteIcon titleAccess='Delete vehicle wise' sx={{ cursor: 'pointer', }} onClick={() => handleDeletePopUp('openDeleteModel')} />}
              {recyclebinCount && recyclebinCount?.count > 0 ? (
                <Typography
                  title="Recycle bin"
                  style={{ cursor: "pointer", marginTop: '-0.2rem' }}
                  onClick={() => RecycleBinHanlder()}>
                  <Badge
                    badgeContent={`${recyclebinCount && recyclebinCount?.count}`}
                    color="error">
                    <img
                      src={recyleBin}
                      style={{ height: "1.5rem", cursor: 'pointer' }}
                    />
                  </Badge>
                </Typography>
              ) : (
                <DeleteOutlineIcon
                  sx={{ cursor: 'pointer', color: "primary.main", fontSize: "1.8rem" }}
                  onClick={() => RecycleBinHanlder()}
                  titleAccess="Recycle bin" />
              )}
            </div>}
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}
                >
                  <Box
                    sx={{ padding: "0.5rem 0", position: "sticky", top: "0" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                  >
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}
                    >
                      Select Project
                    </Typography>
                    <Divider sx={{ margin: "0.2rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  sx={{ fontSize: "1rem", color: "primary.main" }}
                  onClick={toggleDrawer(anchor, true)}
                  className={styles.sideTitle}
                >
                  Selection Options
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        <Box sx={{ width: { xs: '80vw', sm: '96%', md: '96%', lg: '96%', xl: '97%' }, height: { xs: '', lg: '86vh', xl: '90vh' }, paddingRight: '0.5rem', overflow: { xs: 'auto', lg: 'visible', xl: 'visible', } }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3">
          <MyContext.Provider value={{ callGroupAPI, setCallGroupAPI, callGroupNameAPI, setCallGroupNameAPI, setRecycleBinCounter, isNaShowDataApiCall, setIsNaShowDataApiCall, permissionAccess }}>
            <MediaCreateBody />
          </MyContext.Provider>
        </Box>
      </Box>
    </div>
  );
}