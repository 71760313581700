import React, { useCallback, useEffect, useState } from "react";
import errorImg from '../../Assets/images/internetIssue.svg'
interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement>   {
  placeholderImg?: string;

}
export default  ({ src, placeholderImg, ...props }: ImageProps) => {
  const [imgSrc, setSrc] = useState(placeholderImg || src);
  const onLoad = useCallback(() => {
    setSrc(src);
  }, [src]);

  // document.addEventListener('contextmenu', function (e) {
  //   // alert("This function has been disabled ");
  //   e.preventDefault();
  // }, false);

  const onError = useCallback(() => {
    setSrc(errorImg || placeholderImg);
  }, [errorImg, placeholderImg]);

  // useEffect(() => {
  //   const handleContextMenu = (event:any) => {
  //     event.preventDefault();
  //   };

  //   // Attach the event handler to the document object
  //   document.addEventListener('contextmenu', handleContextMenu);

  //   // Clean up the event handler when the component unmounts
  //   return () => {
  //     document.removeEventListener('contextmenu', handleContextMenu);
  //   };
  // }, []);
  useEffect(() => {
    const img = new Image();
    img.src = src as string;
    img.addEventListener("load", onLoad);
    img.addEventListener("error", onError);
    return () => {
      img.removeEventListener("load", onLoad);
      img.removeEventListener("error", onError);
    };
  }, [src, onLoad,onError]);
  return <img {...props}  src={imgSrc}  />;
};