import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs:"90vw", md: "50vw" },
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

function AddContactModal({isOpen, onCloseModal, setFieldValue, values, setClaimedData, handleAddContact, claimedData, contactInfo}:any) {
    const [contactErrors, setContactErrors] = useState<any>();
    const [contactDetails, setContactDetails] = useState<any>({
        spoc_name: "",
        spoc_email: "",
        spoc_contact_number: "",
        spoc_dial_code: "",
        spoc_designation: "",
        spoc_department: "",
      });


      useEffect(()=> {
       if (contactInfo?.spoc_name) {
        setContactDetails(contactInfo);
       }

      },[contactInfo])

      const handleEditContact = () => {
        if (contactDetails?.spoc_name === "" || contactDetails?.spoc_email === "" || contactDetails?.spoc_contact_number === "") {
          setContactErrors(true);
        } else {
          let updatedSpocInformation = [...values.spoc_information];
          let updatedClaimedData = [...claimedData.spoc_information]; 

          const index = values.spoc_information?.findIndex((item: any) => item?.spoc_email === contactInfo?.spoc_email);
          updatedSpocInformation[index] = contactDetails;
          updatedClaimedData[index] = contactDetails;

          setFieldValue(`spoc_information`, updatedSpocInformation);
          setClaimedData({ ...claimedData, spoc_information: updatedClaimedData });
          setContactErrors(false);
          onCloseModal();
          
        }
      }
  return (
    <>
     <Modal open={isOpen} onClose={onCloseModal}>
     <Box sx={style}>
    <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "baseline",
                      columnGap: "1rem",
                    }}
                  >
                    <TextField
                      size="small"
                      variant="standard"
                      placeholder="Name"
                      value={contactDetails.spoc_name}
                      label={
                        <span>
                          Name <span style={{ color: "#d32f2f" }}>*</span>
                        </span>
                      }
                      onChange={(e) => {
                        setContactDetails({
                          ...contactDetails,
                          spoc_name: e.target.value,
                        });
                      }}
                      sx={{
                        marginTop: { xs: "1rem", md: "0.5rem" },
                        width: { xs: "100%", md: "31%" },
                        "& .MuiFormLabel-root": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .Mui-focused": {
                          color: "primary.main",
                        },
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.light",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.light",
                        },
                      }}
                    />

                    <TextField
                      size="small"
                      variant="standard"
                      type="email"
                      placeholder="Email"
                      value={contactDetails.spoc_email}
                      onChange={(e) => {
                        setContactDetails({
                          ...contactDetails,
                          spoc_email: e.target.value,
                        });
                      }}
                      label={
                        <span>
                          Email <span style={{ color: "#d32f2f" }}>*</span>
                        </span>
                      }
                      sx={{
                        marginTop: { xs: "1rem", md: "0.5rem" },
                        width: { xs: "100%", md: "31%" },
                        "& .MuiFormLabel-root": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .Mui-focused": {
                          color: "primary.main",
                        },
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.light",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.light",
                        },
                      }}
                      // error={row.spoc_email === "" && errors["email"] !== undefined}
                      // //@ts-ignore
                      // helperText={email === "" && errors["email"]}
                    />

                    <TextField
                      size="small"
                      variant="standard"
                      type="number" // Keep input type as number
                      inputProps={{
                        maxLength: 10,
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                        style: {
                          MozAppearance: "textfield", // Removes spinner in Firefox
                        },
                      }}
                      label={
                        <span>
                          Phone Number{" "}
                          <span style={{ color: "#d32f2f" }}>*</span>
                        </span>
                      }
                      placeholder="Phone Number"
                      value={contactDetails.spoc_contact_number}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (inputValue.length <= 10) {
                          // Limit to 10 digits
                          setContactDetails({
                            ...contactDetails,
                            spoc_dial_code: "91",
                          });
                          setContactDetails({
                            ...contactDetails,
                            spoc_contact_number: e.target.value,
                          });
                        }
                      }}
                      sx={{
                        width: { xs: "100%", md: "32%" },
                        marginTop: { xs: "1rem", md: "0.5rem" },
                        "& .MuiFormLabel-root": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .Mui-focused": {
                          color: "primary.main",
                        },
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.light",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.light",
                        },
                        // Removes the spinner in Chrome, Safari, Edge, Opera
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                          {
                            WebkitAppearance: "none",
                            margin: 0,
                          },
                        // Removes the spinner in Firefox
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                      }}
                    />

                    <TextField
                      size="small"
                      type="string"
                      variant="standard"
                      placeholder="Designation"
                      value={contactDetails.spoc_designation}
                      onChange={(e) => {
                        setContactDetails({
                          ...contactDetails,
                          spoc_designation: e.target.value,
                        });
                      }}
                      sx={{
                        margin: "1.5rem 0",
                        width: { xs: "100%", md: "31%" },
                        marginTop: { xs: "1rem", md: "0.5rem" },

                        "& .MuiFormLabel-root": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .Mui-focused": {
                          color: "primary.main",
                        },
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.light",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.light",
                        },
                      }}
                      // error={designation === "" && errors["designation"] !== undefined}
                      // //@ts-ignore
                      // helperText={designation === "" && errors["designation"]}
                    />
                    <TextField
                      size="small"
                      variant="standard"
                      type="string"
                      placeholder="Department"
                      value={contactDetails.spoc_department}
                      onChange={(e) => {
                        setContactDetails({
                          ...contactDetails,
                          spoc_department: e.target.value,
                        });
                      }}
                      sx={{
                        width: { xs: "100%", md: "31%" },
                        marginTop: { xs: "1rem", md: "0.5rem" },
                        "& .MuiFormLabel-root": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .Mui-focused": {
                          color: "primary.main",
                        },
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.light",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.light",
                        },
                      }}
                      // error={department === "" && errors["department"] !== undefined}
                      // //@ts-ignore
                      // helperText={department === "" && errors["department"]}
                    />

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                        width: "100%",
                      }}
                      
                    >
                   {contactInfo ? <Button type="button"
                        sx={{
                          marginTop: { xs: "1rem", md: "-1rem" },
                          marginBottom: "1rem",
                          width: "100%",
                        }}   variant="contained" onClick={handleEditContact}>
Edit Contact
                   </Button> :  <Button
                        type="button"
                        sx={{
                          marginTop: { xs: "1rem", md: "-1rem" },
                          marginBottom: "1rem",
                          width: "100%",
                        }}
                        onClick={() => {
                          if (
                            contactDetails?.spoc_name === "" ||
                            contactDetails?.spoc_email === "" ||
                            contactDetails?.spoc_contact_number === ""
                          ) {
                            setContactErrors(true);
                          } else {
                            setFieldValue(`spoc_information`, [
                              ...values.spoc_information,
                              contactDetails,
                            ]);
                            setClaimedData({
                              ...claimedData,
                              spoc_information: [
                                ...values.spoc_information,
                                contactDetails,
                              ],
                            });
                            handleAddContact();
                            setContactErrors(false);
                            
                            onCloseModal();
                          }
                        }}
                        variant="contained"
                      >
                        Add Contact
                      </Button>}
                      <Button
                        type="button"
                        sx={{
                          marginTop: { xs: "1rem", md: "-1rem" },
                          marginBottom: "1rem",
                          width: "100%",
                        }}
                        onClick={onCloseModal}
                       
                        variant="contained"
                      >
                        Close
                      </Button>
                      {contactErrors === true && (
                        <Typography
                          sx={{
                            color: "red",
                            fontSize: "0.8rem",
                            marginTop: "-2rem",
                          }}
                        >
                          Please fill all the required fields in contact details
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  </Box>
                  </Modal>
    </>
  )
}

export default AddContactModal