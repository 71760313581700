import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import * as React from "react";
import { API } from "../../api-services";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { UserContextIdea } from "./IdeaConfigurationLanding";

export interface IEditEditableAtDropdownProps {
    val?:any;
    paramId?:any;
    setCount?:any;
    type?:any;
}
const stageList = [
    { key: 0, text: "Initiated", value: 0 },
    { key: 1, text: "In-Review", value: 1 },
    { key: 2, text: "Submitted", value: 2 },
  ]
export function EditEditableAtDropdown(props: IEditEditableAtDropdownProps) {
    const {val,paramId,setCount, type}=props;
    const {projectId}=useRouteParams<any>();
    const userPremissions=React.useContext(UserContextIdea)
    const [stage, setStage] = React.useState<any>(val);


    console.log(stage,"stagestagestagestagestagestage")
    const handleChange = (event: SelectChangeEvent) => {
      if(type==="Cost Model"){
       
        if(stage?.length!==0){
        
          API.put(`/config/idea_config_cost_model/${paramId}/`,{
            editable:event.target.value
        },{},0).then((res:any)=>{
            setStage(event.target.value);
            API.patch(
              `/xcpep/project/${projectId}/`,
              {
                idea_config: false,
              },
              {},
              0
            )
              .then((res: any) => {
                setCount((prev:any)=>prev+1)            })
              .catch((err: any) => {      
        
              });
        }).catch((err:any)=>{
            setStage((prev:any)=>prev);
        })
        }
       
      }
      else{
        if (event?.target?.value?.length>0){
          API.put(`/bom/parameter_mapping/${paramId}/`,{
            editable:event.target.value
        },{},0).then((res:any)=>{
            setStage(event.target.value);
            API.patch(
              `/xcpep/project/${projectId}/`,
              {
                idea_config: false,
              },
              {},
              0
            )
              .then((res: any) => {
                setCount((prev:any)=>prev+1)            })
              .catch((err: any) => {      
        
              });
        }).catch((err:any)=>{
            setStage((prev:any)=>prev);
        })
        }
        console.log(event.target.value,"cchghghhghgh")
       
      }
      };
    // React.useEffect(()=>{
    //     if(val){
    //         setStage(val)
    //     }
    // },[val])
  return (
    <FormControl fullWidth>
      {/* <InputLabel id="demo-simple-select-label">Editable at</InputLabel> */}
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={stage&&stage}
        onChange={handleChange}
        variant="standard"
        size="small"
        multiple
        disabled={userPremissions && userPremissions?.includes("U")?false:true}
        sx={{
          width: '100%',
          "& .MuiSelect-select": {             
              fontSize: "1rem",
          },
          "&:before": {
              borderBottomColor: "primary.light",
          },
          '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
      }}
      >
        {stageList?.map((item: any, index: any) => {
          return (
            <MenuItem sx={{fontSize:'1rem'}} key={index} value={item?.value}>
              {item.text}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
