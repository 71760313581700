import * as React from 'react';
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Box, IconButton, Skeleton, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useHistory } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { useShareWithDb } from '../../useShareWithDb';
import { useRouteParams } from '../../../../ui-reusable-component/useRouteParams';
import useWindowDimensions from '../../../../ui-reusable-component/useWindowDimensions';
import { API } from '../../../../api-services';
import { useToolAddEditModel } from '../../useToolAddEditModel';
import AddOperationDatabaseModel from './AddOperationDatabaseModel';
import UploadIcon from "@mui/icons-material/Upload";
import DownloadIcon from "@mui/icons-material/Download";
import { LoadingButton } from '@mui/lab';
import OperationMovePopUp from '../OperationRevision/OperationMovePopUp';
import { useBaseModal } from '../../../../ComponentUI/SearchPage/useBaseModal';
import SuccessModalFilesMail from '../../../Suppliers/SuccessModalFilesMail';
import ErrorModalFilesMail from '../../../../ComponentUI/ProjectMailModule/ErrorModalFilesMail';
import move from "../../../../Assets/images/right move.svg";
import { useBomCategoryUse } from '../../../BOM Categories/useBomCategoryUse';
import ToolUseList from '../../ToolUseList';
import DeleteIcon from '@mui/icons-material/Delete';
import swal from "sweetalert";
import AddIcon from '@mui/icons-material/Add';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../../../Redux/Reducers/user.reducer';
import openFolder from '../../../../Assets/images/svgs/openfolderblue.svg';
import FolderIcon from '@mui/icons-material/Folder';

type Anchor = "top" | "left" | "bottom" | "right";

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );
}

const csvOptions: GridCsvExportOptions = {
  fileName: "Database Operation Revision (Costing-DB) In CSV", delimiter: ",", utf8WithBom: true
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer sx={{
    width: { lg: '100%', xl: '100%' },
    height: '2.3rem',
    justifyContent: 'flex-end',
    paddingRight: '1rem',
    marginTop: '-2.4rem',}}>
    <span>
      <GridToolbarColumnsButton />
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    square
    {...props}
  />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", color: 'primary.main' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "white",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(00deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "0px solid rgba(0, 0, 0, .125)",
}));


export interface IOperationsDatabaseProps {
  setRecyclebinCounter: any;
}


export function OperationsDatabase(props: IOperationsDatabaseProps) {
  const { setRecyclebinCounter } = props;
  const userProfile = useSelector(userSelectors.selectAll);
	const { tab, year,month, revisionId, typeId, databaseName, status, search, categoryName } =
		useRouteParams<any>();
  const [expanded, setExpanded] = React.useState<string | false>(categoryName);
  const [accordianData, setAccordianData] = useState<any>();
  const [accordianInsideData, setAccordianInsideData] = useState<any>();
  const [CommodityLoader, setCommodityLoader] = useState<any>(false);
  const [counter,setCounter]=useState<number>(0)
  const [accordianInsideDataLoader, setAccordianInsideDataLoader] = useState<boolean>(true);
  const [apiCallAccordianInsideData, setApiCallAccordianInsideData] = useState<boolean>(true);
  const [apiCallAccordianData, setApiCallAccordianData] = useState<boolean>(true);
  const [reverseUploadLoader, setReverseUploadLoader] = useState<boolean>(false);
  const [SuccessModalFileMsg, setSuccessModalFileMsg] = useState<any>();
  const [ErrorModalFileMsg, setErrorModalFileMsg] = useState<any>();

  const listOperationsRevisionUse = useBomCategoryUse();
  const operationShare = useShareWithDb();
  const ErrorModalFiles = useBaseModal();
  const operationAddFromDb = useToolAddEditModel();
  const { width } = useWindowDimensions();
  const SuccessModalFiles = useBaseModal();
  const [useraction,setuseraction]=useState<any>(null)
  const history = useHistory();
  // helpers
  const inputRef = useRef<any>();
  const [selectedTab, setSelectedTab] = useState<any>();

  useEffect(() => {
    setCommodityLoader(true);
    API.get('/db/cost_databases/', { database_type: "operationdb" }, 0).then((res: any) => {
      setAccordianData(res?.data);
      setCommodityLoader(false);
      if(categoryName==0){						
        setSelectedTab(res?.data[0]?.category);
        history.push(`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/${databaseName}/${res?.data[0]?.category}/${status}/${search}`);
      }	
    })
  }, [typeId, apiCallAccordianData]);

  useEffect(() => {
    if (categoryName != 0) {
      setAccordianInsideDataLoader(true)
      API.get('/db/stroke_rate/', { library_category: categoryName }, 0).then((res: any) => {
        setAccordianInsideData(res?.data);
        setAccordianInsideDataLoader(false);
      });
    }
  }, [categoryName, apiCallAccordianInsideData,counter]);

  // const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
  //   setExpanded(newExpanded ? panel : false);
  //   if (newExpanded) {
  //     history.push(`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/${databaseName}/${panel}/${status}/${search}`)
  //   }
  // };

  const handleChange = (categoryName: any) => {
    setSelectedTab(categoryName);
    history.push(`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/${databaseName}/${categoryName}/${status}/${search}`)
	};

  const handleDBChange = (item: any) =>  (event: React.SyntheticEvent, newExpanded: boolean) => {
    // console.log(item,'panel');
      setExpanded(newExpanded ? item : false);  
      // console.log(item,expanded, 'panel');    
    }
  
    React.useEffect(() => {
      if (databaseName == 'rmDB') {
        setExpanded('rmDB');
      } else if (databaseName == 'bopDB') {
        setExpanded('bopDB');
      } else if(databaseName == 'machineDB') {
        setExpanded('machineDB');
      } else if(databaseName == 'laborDB') {
        setExpanded('laborDB');
      } else if(databaseName == 'toolDB') {
        setExpanded('toolDB');
      } else if(databaseName == 'operationsDB') {
        setExpanded('operationsDB');
      } else if(databaseName == 'currencyDB') {
        setExpanded('currencyDB');
      } 
    }, [databaseName]);
  
    const redirectDBHandler = (item: any) => {
      history.push(`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/${item}/${0}/${status}/${search}`);
    }


  React.useEffect(() => {
    API.get(
      "/auth/user_permission_module/",
      {
        action: true,
        database: true,
        module: "DB-Costing"
      },
      0
    )
      .then((res: any) => {
        setuseraction(res?.data?.action)
        // sessionStorage.setItem("DBPermission", res.data.action);
      })
      .catch((err: any) => { });
  }, []);

  // ------------------------------------------------------------------------------------
  //handlers:
  const browseFiles = (ed: any) => {
    return inputRef.current?.click?.();
  };

  const handleReverseUpload = async (e: any) => {
    setReverseUploadLoader(true);
    setExpanded(false);
    const formData = new FormData();
    formData.append("file", e?.target?.files[0]);
    API.post("/db/import_stroke_rate/", formData, { type: 2 }, 0)
      .then((res: any) => {
        setReverseUploadLoader(false);
        setSuccessModalFileMsg(res.data);
        setApiCallAccordianData(!apiCallAccordianData);
        if (categoryName != "0") {
          setApiCallAccordianInsideData(!apiCallAccordianInsideData)
        }
        SuccessModalFiles.open();
      })
      .catch((err) => {
        setReverseUploadLoader(false);
        setErrorModalFileMsg(err.response.data);
        ErrorModalFiles.open();
      });
  };

  const LockHandler = (item: any, type: any) => {
    swal({
      title: type == true ? "Lock" : "Unlock",
      text:
        type == true
          ? "Are you sure you want to lock this parameter?"
          : "Are you sure you want to Unlock this parameter?",
      icon: "warning",
      //@ts-ignore
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((confirm) => {
      if (confirm) {
        if (type == false) {
          if (
            (userProfile && userProfile[0]?.is_superuser) ||
            userProfile[0]?.is_project_admin ||
            userProfile[0]?.id == item?.locked_user_id
          ) {
            API.put(`/db/stroke_rate/${item?.id}/`, {
              lock_status: type
            })
              .then((res: any) => {
               setCounter((prev:any)=>prev+1)
              })
              .catch((err: any) => {
                console.log("Server error");
              });
          } else {
            // SetLockData(item)
            // lockmodal.open()
          }
        }
        if (type == true) {
          API.put(`db/stroke_rate/${item?.id}/`, {
            lock_status: type,
          })
            .then((res: any) => {
             setCounter((prev:any)=>prev+1)
            })
            .catch((err: any) => {
              console.log("Server error");
            });
        }
      }
    });
  };



  const handleShareWith: any = (idGet: any, shareItemCodeGet: any, listCommodityGet: any, shareItemNameGet: any) => {
    operationShare.open(idGet, shareItemCodeGet, listCommodityGet, shareItemNameGet)
  }

  const addDataFromDb: any = (e: any) => {
    e.stopPropagation();
    setExpanded(false);
    // id is dummy here
    operationAddFromDb.open(-1, "add");
  }


  const handleListToolLibraryUse = (id: any) => {
    listOperationsRevisionUse.open(id)
  }

  const handleDelete = async (e: React.MouseEvent, id: number) => {
    e.stopPropagation();
    swal({
      title: `Delete Data`,
      text: `Are you sure?`,
      icon: "error",
      //@ts-ignore
      buttons: {
        cancel: "Cancel",
        permanent: {
          text: `Delete Operations Code`,
          value: `deleteOperationCode`,
        },
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
      className: "delete-swal",
    }).then(async (type: string) => {
      switch (type) {
        case "deleteOperationCode":
          API.delete("/db/stroke_rate/" + id + "/")
            .then((res: any) => {
              setRecyclebinCounter((prev: any) => prev + 1);
              setApiCallAccordianInsideData(!apiCallAccordianInsideData);
              setApiCallAccordianData(!apiCallAccordianData);
            })
            .catch((err: any) => {
              console.log(err, "Unable to upload file. Server Error");
            });
          break;
        default:
          swal(`Data saved from deleting`, { icon: "error" });
      }
    });
  };

  // columns is a variable where column data is stored. This columns is then used as a prop in dataGrid.

  let columns: any = [
    {
      field: "actions",
      headerName: "Actions",
      width: width > 1370 ? 135 : 110,
      renderCell: (cellValues: any) => {
        return (<Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem', }}>
          {cellValues?.row?.lock_status ? (
              <IconButton
                title="lock"
                sx={{ padding: "0", cursor: "pointer", color: "green" }}
                onClick={() => LockHandler(cellValues?.row, false)}
              >
                <LockIcon />
              </IconButton>
            ) : (
              <IconButton
                title="lock"
                sx={{ padding: "0", cursor: "pointer", color: "primary.main" }}
                onClick={() => LockHandler(cellValues?.row, true)}
              >
                <LockOpenIcon sx={{color:'red'}}/>
              </IconButton>
            )}
          {cellValues?.row?.mapped_status ? <IconButton title='Operation Info' sx={{ color: 'primary.main', padding: '0rem' }} onClick={() => handleListToolLibraryUse(cellValues?.row?.id)}> <InfoIcon /> </IconButton> : useraction && useraction?.includes("D") && <IconButton title='Delete' sx={{ color: 'primary.main', padding: '0rem',opacity:cellValues?.row?.lock_status?0.5:1 }} onClick={(e: any) => {
            if(cellValues?.row?.lock_status!=true){
            handleDelete(e, cellValues?.row?.id)
            }
            }}> <DeleteIcon /> </IconButton>}
          {useraction && useraction?.includes("U") && <IconButton title='Move' sx={{ color: 'primary.main', padding: '0rem',opacity:cellValues?.row?.lock_status?0.5:1 }} onClick={() => {
            if(cellValues?.row?.lock_status!=true){
            handleShareWith(cellValues?.row?.id, cellValues?.row?.operationCode, 'dummy', cellValues?.row?.category)}
          }
            }> <img src={move} style={{ height: '1.3rem' }} /> </IconButton>}
          {useraction && useraction?.includes("U") && <IconButton title='Edit' sx={{ color: 'primary.main', padding: '0rem',opacity:cellValues?.row?.lock_status?0.5:1 }} onClick={() => { if(cellValues?.row?.lock_status != true){operationAddFromDb.open(cellValues?.row?.id, "edit", cellValues?.row)} }}><EditIcon /></IconButton>}
        </Box>)
      }
    },
    { field: "sno", headerName: "SNo.", width: width > 1370 ? 50 : width < 900 ? 50 : 50, },
    { field: "operationCode", headerName: "Operation Code", width: width > 1370 ? 340 : 220, },
    {
      field: "description",
      headerName: "Description",
      headerAlign: "left",
      //   align: 'right',
      minWidth: width > 1370 ? 270 : 250,
      flex: 1,
    },
    {
      field: "remark",
      headerName: "Remarks",
      width: width > 1370 ? 270 : 250,

      flex: 1,
    },
    { field: "rate", headerName: "Rate/Unit (INR)", width: width > 1370 ? 140 : 120, headerAlign: "right", align: 'right', cellClassName: "super-app-theme--cell" },
    {
      field: "unit",
      headerName: "Unit",
      minWidth: width > 1370 ? 130 : 90,
    },
  ];

  // In rows which is a array, we are pushing data which is structured in frontEnd so, that we can pass it to dataGrid

  let rows: any = accordianInsideData && accordianInsideData?.map((ideaItem: any, index: any) => {
    let objShared: any = {
      id: ideaItem?.id,
      sno: index + 1,
      operationCode: ideaItem?.code,
      description: ideaItem?.description,
      remark: ideaItem?.remark,
      unit: ideaItem?.unit,
      rate: ideaItem?.rate_per_unit,
      category: ideaItem?.category,
      mapped_status: ideaItem?.mapped_status,
      lock_status: ideaItem?.lock_status,

    };
    return objShared
  });

  // dataStructureDataGrid is the total data format which is ready to pass to the dataGrid Props

  let dataStructureDataGrid: any = {
    columns: columns,
    rows: rows,
  };


  const handleInputClick = (e: React.MouseEvent) => {
    // clear the prev value on click so that same file can be uploaded again
    (e.target as HTMLInputElement).value = "";
  };


  const handleDownloadTemplateDatabase = () => {
    API.get("/db/database/download/stroke/", {}, 0).then((res: any) => {
      window.open(res?.data);
    });
  };

  return (
    <Box sx={{ width: "100%", }}>
    {/* height: '90vh'
      adl-scrollbar="true"
      adl-scrollbar-width="0.4"> */}

      <input
        style={{ display: "none" }}
        type="file"
        accept=".xls,.xlsx"
        id="1"
        ref={inputRef}
        onChange={(e: any) => handleReverseUpload(e)}
        onClick={handleInputClick}
      />

      {SuccessModalFiles.isOpen && (
        <SuccessModalFilesMail
          isOpen={SuccessModalFiles.isOpen}
          onCloseModal={SuccessModalFiles.close}
          successMsg={SuccessModalFileMsg}
        />
      )}

      {ErrorModalFiles.isOpen && (
        <ErrorModalFilesMail
          isOpen={ErrorModalFiles.isOpen}
          onCloseModal={ErrorModalFiles.close}
          ErrorMsg={ErrorModalFileMsg}
        />
      )}

      {operationShare.isOpen &&
        <OperationMovePopUp
          isOpen={operationShare.open}
          onCloseModal={operationShare.close}
          idIncoming={operationShare.id}
          shareItemCode={operationShare.shareItemCode}
          shareItemCategory={operationShare.shareItemCategory}
          apiCallAccordianInsideData={apiCallAccordianInsideData}
          setApiCallAccordianInsideData={setApiCallAccordianInsideData}
          apiCallAccordianData={apiCallAccordianData}
          setApiCallAccordianData={setApiCallAccordianData}
        />
      }
      {
        operationAddFromDb.isOpen &&
        <AddOperationDatabaseModel
          isOpen={operationAddFromDb.open}
          onCloseModal={operationAddFromDb.close}
          id={operationAddFromDb.id}
          actionSend={operationAddFromDb.actionSend}
          apiCallAccordianInsideData={apiCallAccordianInsideData}
          setApiCallAccordianInsideData={setApiCallAccordianInsideData}
          valueEdit={operationAddFromDb?.valueEdit}
          apiCallAccordianData={apiCallAccordianData}
          setApiCallAccordianData={setApiCallAccordianData}
        />
      }

      {listOperationsRevisionUse.isOpen &&
        <ToolUseList
          isOpen={listOperationsRevisionUse.isOpen}
          onCloseModal={listOperationsRevisionUse.close}
          id={listOperationsRevisionUse.id}
        />}
     {/* <Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					columnGap: '0.5rem',
					width: '100%',
					borderBottom: '1px solid',
				}}
				adl-scrollbar='true'
				adl-scrollbar-width='0.2'>
      {accordianData && accordianData?.map((item: any, index: number) => {
        return <>
        <Box
									key={index}
									sx={{
										color: '#007fff',
										height: '2rem',
										minHeight: '2.1rem',
										maxWidth: '100% !important',
										padding: '0',
										fontSize: '1rem',
										width: '100%',
										backgroundColor:
											item?.category == selectedTab
												? 'primary.main'
												: 'primary.light',
										cursor: 'pointer',
										display: 'flex',
										alignItems: 'center',
									}}>
									<Typography
										sx={{
											color: item?.category == selectedTab ? 'white' : '#007fff',
											height: '2rem',
											minHeight: '2.1rem',
											maxWidth: '100% !important',
											padding: '0 0.5rem',
											fontSize: '1rem',
											width: '100%',
											textAlign: 'center',
											display: 'flex',
											alignItems: 'center',
											whiteSpace: 'nowrap',
											justifyContent: 'center',
											textTransform: 'capitalize',
										}}
										onClick={() => handleChange(item?.category)}>
										{item?.category.toLowerCase()} ({item?.count})
									</Typography>
								</Box>         
        </>
      })}
      </Box>
      <Box sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-end',
					marginLeft: '0.5rem',
					columnGap: '0.5rem',
				}}>
      {(
                <>
                  <span
                    style={{
                      marginLeft: "0.5rem",
                      marginRight: "0.5rem",
                      fontWeight: 500,
                      display: "flex",
                      alignItems: "center",
                      columnGap: '0.5rem',
                    }}
                  >
                    {(useraction && useraction?.includes("U") && useraction && useraction?.includes("C")) && <LoadingButton
                      sx={{
                        zIndex: 1,								
                        minWidth: "1rem",
                        padding: "0.2rem 0.5rem",
                        cursor: "pointer",
                        "&:hover": {
                          transform: "Scale(1.05)",
                          transition: "transform 0.5s ease",
                        },
                        '.MuiCircularProgress-root': {
                          color: 'primary.main',
                        },
                      }}
                      title="Upload"
                      startIcon={<UploadIcon sx={{ marginRight: '-1rem' }} />}
                      loading={reverseUploadLoader}
                      onClick={(ed: any) => browseFiles(ed)}
                    />}
                    <Button
                      sx={{
                        minWidth: "1rem",
                        padding: "0.2rem 0.5rem",
                        zIndex: 1,							
                      }}
                      startIcon={<DownloadIcon sx={{ marginRight: '-1rem' }} />}
                      title={"Download"}
                      onClick={handleDownloadTemplateDatabase}
                    />
                    {useraction && useraction?.includes("C") && <Button
                      size='small'
                      sx={{ cursor: "pointer", width: '10rem',zIndex: 1,
                      }}
                      onClick={(e: any) => addDataFromDb(e)}
                      startIcon={<AddIcon sx={{ marginRight: '-0.5rem' }} />}
                    >
                      Add Operation
                    </Button>}
                  </span>
                </>
              )}
      </Box>
      <Box sx={{ height: { lg: '78vh', xl: '83vh' }, width: "100%" }}>
              {!accordianInsideDataLoader ? <DataGrid
                headerHeight={48}
                rowHeight={48}
                {...dataStructureDataGrid}
                disableSelectionOnClick={true}
                components={{
                  Toolbar: () => {
                    return CustomToolbar();
                  },
                  noRowsOverlay: CustomNoRowsOverlay,
                }}
                sx={{
                  "& ::-webkit-scrollbar": {
                    width: "0.3rem",
                    height: "0.3rem",
                  },
                  "& ::-webkit-scrollbar-thumb": {
                    // background: $thumb-color;
                    backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                    borderRadius: "10px",
                  },
                  "& ::- webkit-scrollbar-thumb: hover ": {
                    // width: "4px",
                    backgroundColor: "#045DE9 !important",
                  },
                  "::-webkit-scrollbar-track": {
                    backgroundColor: "#e1e1f3 !important",
                  },
                  "&.MuiDataGrid-root": {
                    border: 'none',
                  },
                  "&.MuiDataGrid-root .MuiDataGrid-cell": {
                    borderBottomColor: "primary.light",
                  },
                  "& .super-app-theme--cell": {
                    backgroundColor: "#ddefff !important",
                  },
                }}
                density={"compact"}
                pageSize={100}
                rowsPerPageOptions={[100]}
              /> : <>
                <Box sx={{ padding: "0 1rem", width: "100%" }}>
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />

                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />

                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />

                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                </Box>
              </>}
            </Box> */}
            <Box sx={{display:'grid',gridTemplateColumns:'20rem 1fr',width:'100%'}}>
            <Box sx={{width:'100%', height:{lg:'87vh',xl:'89vh'}}}
        adl-scrollbar="true"
        adl-scrollbar-width="0.3">
        <Accordion sx={{ border: 'none',marginLeft:'0.5rem' }} expanded={expanded === 'rmDB'} onClick={()=>redirectDBHandler('rmDB') }>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header"
        expandIcon={
          expanded === 'rmDB' ? (
            <img
              src={openFolder}
              style={{ height: '1.2rem' }}
            />
          ) : (
            <FolderIcon
              sx={{
                fontSize: '1.5rem',
                color: 'primary.main',
              }}
            />
          )
        } sx={{minHeight: '24px',padding: '0',}}>
          <Typography sx={{marginLeft:'0.5rem'}}>Raw Material</Typography>
        </AccordionSummary>
        <AccordionDetails>
        
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ border: 'none',marginLeft:'0.5rem' }} expanded={expanded === 'bopDB'} onClick={()=>redirectDBHandler('bopDB') } >
        <AccordionSummary aria-controls="bopDB-content" id="bopDB-header" expandIcon={
          expanded === 'bopDB' ? (
            <img
              src={openFolder}
              style={{ height: '1.2rem' }}
            />
          ) : (
            <FolderIcon
              sx={{
                fontSize: '1.5rem',
                color: 'primary.main',
              }}
            />
          )
        } sx={{minHeight: '24px',padding: '0',}}>
          <Typography sx={{marginLeft:'0.5rem'}}>Bought-out Parts</Typography>
        </AccordionSummary>
        <AccordionDetails>
          
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ border: 'none',marginLeft:'0.5rem' }} expanded={expanded === 'machineDB'} onClick={()=>redirectDBHandler('machineDB') }>
        <AccordionSummary aria-controls="machineDB-content" id="machineDB-header" expandIcon={
          expanded === 'machineDB' ? (
            <img
              src={openFolder}
              style={{ height: '1.2rem' }}
            />
          ) : (
            <FolderIcon
              sx={{
                fontSize: '1.5rem',
                color: 'primary.main',
              }}
            />
          )
        } sx={{minHeight: '24px',padding: '0',}}>
          <Typography sx={{marginLeft:'0.5rem'}}>Machine Hour Rates</Typography>
        </AccordionSummary>
        <AccordionDetails>
       
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ border: 'none',marginLeft:'0.5rem' }} expanded={expanded === 'laborDB'} onClick={()=>redirectDBHandler('laborDB') }  >
        <AccordionSummary aria-controls="laborDB-content" id="laborDB-header" expandIcon={
          expanded === 'laborDB' ? (
            <img
              src={openFolder}
              style={{ height: '1.2rem' }}
            />
          ) : (
            <FolderIcon
              sx={{
                fontSize: '1.5rem',
                color: 'primary.main',
              }}
            />
          )
        } sx={{minHeight: '24px',padding: '0',}}>
          <Typography sx={{marginLeft:'0.5rem'}}>Labour Hour Rates</Typography>
        </AccordionSummary>
        <AccordionDetails>       
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ border: 'none',marginLeft:'0.5rem' }} expanded={expanded === 'toolsDB'} onClick={()=>redirectDBHandler('toolsDB') }>
        <AccordionSummary aria-controls="toolsDB-content" id="toolsDB-header" expandIcon={
          expanded === 'toolsDB' ? (
            <img
              src={openFolder}
              style={{ height: '1.2rem' }}
            />
          ) : (
            <FolderIcon
              sx={{
                fontSize: '1.5rem',
                color: 'primary.main',
              }}
            />
          )
        } sx={{minHeight: '24px',padding: '0',}}>
          <Typography sx={{marginLeft:'0.5rem'}}>Tools</Typography>
        </AccordionSummary>
        <AccordionDetails>        
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ border: 'none',marginLeft:'0.5rem' }} expanded={expanded === 'operationsDB'}  onChange={handleDBChange('operationsDB')}   >
        <AccordionSummary aria-controls="operationsDB-content" id="operationsDB-header" expandIcon={
          expanded === 'operationsDB' ? (
            <img
              src={openFolder}
              style={{ height: '1.2rem' }}
            />
          ) : (
            <FolderIcon
              sx={{
                fontSize: '1.5rem',
                color: 'primary.main',
              }}
            />
          )
        } sx={{minHeight: '24px',padding: '0',}}>
          <Typography sx={{marginLeft:'0.5rem'}}>Operation Cost</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              columnGap: "0.5rem",
              width: "94%",
              borderLeft: "1px solid",
              marginLeft:'1rem',
            }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.2"
          >
            {CommodityLoader ? (
            <Box sx={{ padding: "0 1rem", width: "100%" }}>
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
    
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
    
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />            
            </Box>
          ) : (
            <>
            {accordianData &&
              accordianData?.map((item: any, index: any) => {
                return (
                  <>
                    <Box
                      key={index}
                      sx={{
                        color: "primary.main",
                        // height: "2rem",
                        // minHeight: "2.1rem",
                        maxWidth: "100% !important",
                        padding: "0.1rem 0",
                        fontSize: "1rem",
                        width: "100%",
                        backgroundColor:
                          item?.category == selectedTab
                            ? "primary.main"
                            : "",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}
                    ><Typography sx={{whiteSpace: "nowrap",color:
                    item?.category == selectedTab
                      ? "white"
                      : "primary.main",}}>--</Typography>
                      <Typography
                        sx={{
                          color:
                            item?.category == selectedTab
                              ? "white"
                              : "primary.main",
                          // height: "2rem",
                          // minHeight: "2.1rem",
                          maxWidth: "100% !important",
                          padding: "0.1rem 0rem",
                          fontSize: "1rem",
                          width: "100%",
                          textAlign: "center",
                          display: "flex",
                          whiteSpace: "nowrap",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          textTransform: "capitalize",
                        }}
                        onClick={() => handleChange(item?.category)}
                      >
                        {item?.category.toLowerCase()} ({item?.count})
                      </Typography>
                    </Box>
                  </>
                );
              })}
              </>)}
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ border: 'none',marginLeft:'0.5rem' }} expanded={expanded === 'currencyDB'} onClick={()=>redirectDBHandler('currencyDB') }>
        <AccordionSummary aria-controls="currencyDB-content" id="currencyDB-header" expandIcon={
          expanded === 'currencyDB' ? (
            <img
              src={openFolder}
              style={{ height: '1.2rem' }}
            />
          ) : (
            <FolderIcon
              sx={{
                fontSize: '1.5rem',
                color: 'primary.main',
              }}
            />
          )
        } sx={{minHeight: '24px',padding: '0',}}>
          <Typography sx={{marginLeft:'0.5rem'}}>Currency</Typography>
        </AccordionSummary>
        <AccordionDetails>
          
        </AccordionDetails>
      </Accordion>       
        </Box>
        <Box>
        <Box sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-start',
					marginLeft: '0.5rem',
					columnGap: '0.5rem',
				}}>
      {(
                <>
                  <span
                    style={{
                      marginLeft: "0.5rem",
                      marginRight: "0.5rem",
                      fontWeight: 500,
                      display: "flex",
                      alignItems: "center",
                      columnGap: '0.5rem',
                    }}
                  >
                    {(useraction && useraction?.includes("U") && useraction && useraction?.includes("C")) && <LoadingButton
                      sx={{
                        zIndex: 1,								
                        minWidth: "1rem",
                        padding: "0.2rem 0.5rem",
                        cursor: "pointer",
                        "&:hover": {
                          transform: "Scale(1.05)",
                          transition: "transform 0.5s ease",
                        },
                        '.MuiCircularProgress-root': {
                          color: 'primary.main',
                        },
                      }}
                      title="Upload"
                      startIcon={<UploadIcon sx={{ marginRight: '-1rem' }} />}
                      loading={reverseUploadLoader}
                      onClick={(ed: any) => browseFiles(ed)}
                    />}
                    <Button
                      sx={{
                        minWidth: "1rem",
                        padding: "0.2rem 0.5rem",
                        zIndex: 1,							
                      }}
                      startIcon={<DownloadIcon sx={{ marginRight: '-1rem' }} />}
                      title={"Download"}
                      onClick={handleDownloadTemplateDatabase}
                    />
                    {useraction && useraction?.includes("C") && <Button
                      size='small'
                      sx={{ cursor: "pointer", width: '10rem',zIndex: 1,
                      }}
                      onClick={(e: any) => addDataFromDb(e)}
                      startIcon={<AddIcon sx={{ marginRight: '-0.5rem' }} />}
                    >
                      Add Operation
                    </Button>}
                  </span>
                </>
              )}
      </Box>
      <Box sx={{ height: { lg: '84vh', xl: '87vh' }, width: "100%" }}>
              {!accordianInsideDataLoader ? <DataGrid
                headerHeight={48}
                rowHeight={48}
                {...dataStructureDataGrid}
                disableSelectionOnClick={true}
                components={{
                  Toolbar: () => {
                    return CustomToolbar();
                  },
                  noRowsOverlay: CustomNoRowsOverlay,
                }}
                sx={{
                  "& ::-webkit-scrollbar": {
                    width: "0.3rem",
                    height: "0.3rem",
                  },
                  "& ::-webkit-scrollbar-thumb": {
                    // background: $thumb-color;
                    backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                    borderRadius: "10px",
                  },
                  "& ::- webkit-scrollbar-thumb: hover ": {
                    // width: "4px",
                    backgroundColor: "#045DE9 !important",
                  },
                  "::-webkit-scrollbar-track": {
                    backgroundColor: "#e1e1f3 !important",
                  },
                  "&.MuiDataGrid-root": {
                    border: 'none',
                  },
                  "&.MuiDataGrid-root .MuiDataGrid-cell": {
                    borderBottomColor: "primary.light",
                  },
                  "& .super-app-theme--cell": {
                    backgroundColor: "#ddefff !important",
                  },
                }}
                density={"compact"}
                pageSize={100}
                rowsPerPageOptions={[100]}
              /> : <>
                <Box sx={{ padding: "0 1rem", width: "100%" }}>
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />

                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />

                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />

                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                </Box>
              </>}
            </Box> 
        </Box>
        </Box>
    </Box>
  );
}