import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';

import { useFormik } from 'formik';
import * as yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { Divider, FormControl, InputLabel, TextField } from '@mui/material';

import Swal from 'sweetalert2';
import { API } from '../../../api-services';
import { ADMIN } from '../../../Redux/Services/admin.service';
interface InfoModalProps {
	onCloseModal: any;
	isOpen: any;
	Item: any;
	scenarioAmount?: any;
	tat?: any;
	getPublishStatus?: any;
}

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	minWidth: '10vw',
	maxWidth: '20vw',
	height: 'fit-content',
	bgcolor: 'background.paper',
	borderRadius: '10px',
	p: 1,
};
const validationSchema = yup.object({
	scenario_amount: yup
		.number()
		.required('Please Enter ZBC Amount (in INR)')
		.max(410000, "Quote amount cannot exceed ₹4.1 lakhs."),
	tat: yup
		.string()
		.required('Please Enter ZBC Turn Around Time (in Days)'),
});
const CostingDataModal = ({ onCloseModal, isOpen, Item, getPublishStatus, scenarioAmount, tat, ErrorModalFiles, setCalulatorList, setValidationLoader,costData,
	setTreeCount, setCostingStatus
}:any) => {
	// const { onCloseModal, isOpen, Item, getPublishStatus, scenarioAmount, tat } = props;

	const [loader, setloader] = useState<any>(false);

	

	const formik = useFormik({
		initialValues: {
			tat: '',
			scenario_amount: '',
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			
			setloader(true);
			
			// ------------------------------


			API.get(`cost/unmapped_calculator/`, { vault: Item })
				.then((res: any) => {
				
					
					
					if (res?.data?.status == true) {
						setCalulatorList(res?.data?.error);
						ErrorModalFiles.open();
						getPublishStatus();
						saveCostData();
						
						// API.put(
						//   `/cost/update_estimation/${costData?.cost_estimation}/`,
						//   { [event.target.value]: true },
						//   {},
						//   0
						// )
						//   .then((res: any) => {
						//     setCostingStatus(event.target.value);
						//     setTreeCount((prev: any) => prev + 1);
						//     // setCount((prev: any) => prev + 1);
						//   })
						//   .catch((err: any) => {
						//     const { data }: any = err?.response;
						//     Swal.fire({
						//       icon: "error",
						//       html: `<div>
						//                 <br />
						//                 <p style="color:"red">${data[0]}</p>
						//               </div>`,
						//     });
						//   });
					} else {
						setValidationLoader(true);
						API.put(
							`/cost/update_estimation/${costData?.cost_estimation}/`,
							{ ["validated"]: true },
							{},
							0
						)
							.then((res: any) => {
								// Swal.fire({
								// 	icon: "success",
								// 	title: `Costing Data Updated Successfully`,
								//   }).then((result) => {
									
								//   });

								  setCostingStatus("validated");
								setTreeCount((prev: any) => prev + 1);
								setValidationLoader(false);
								getPublishStatus();
								saveCostData();
								onCloseModal();


								
								// setCount((prev: any) => prev + 1);
							})
							.catch((err: any) => {
								const { data }: any = err?.response;
								setValidationLoader(false);
								Swal.fire({
									icon: 'warning',
									html: `<div>
                          <br />
                          <p style="color:"red">${data[0]}</p>   
                        </div>`,
								});
							});
					}
					// costModal.open();
				})
				.catch((err: any) => {
					console.log('Server Error');
				});

				
			
				setloader(false);
		},
	});


	const saveCostData = () => {
		API.put(
			`/bom/get_publish_interface_data/${Item}/`,
			{
				tat: formik.values.tat,
				scenario_amount: formik.values.scenario_amount,
				lock: undefined,
				publish:  undefined,
			},
			{},
			0
		)
			.then((res: any) => {
				
				
			})
			.catch((err: any) => {
				setloader(false);
				if (err?.response?.data) {
					Swal.fire({
						icon: 'error',
						html: `<div> 
								<p style="color:"red">${err?.response?.data[0]}</p>   
								</div>`,
						customClass: {
							container: 'swal2Container',
						},
					});
				}
			});	
	}	


	// const lockandPublish = () => {
	// 	Swal.fire({
	// 		title: 'Are you sure?',
	// 		text: 'You want to lock and publish this part to xcPROC, which will make it visible to the client?',
	// 		icon: 'warning',
	// 		showCancelButton: true,
	// 		confirmButtonColor: 'primary.main',
	// 		customClass: {
	// 			container: 'swal2Container',
	// 		},

	// 	}).then((result) => {
	// 		if (result.isConfirmed) {
	// 		API.put(`/bom/vault_info/${Item}/`, { cost_publish: true }, {}, 0)
	// 			.then((res: any) => {
	// 				getPublishStatus();
	// 				Swal.fire({
	// 					icon: 'success',
	// 					html: `<div>
	// 			<br />
	// 			<p style="color:"red">Locked and Published to xcPROC</p>   
	// 			</div>`,
	// 				});
	// 			})
	// 			.catch((err: any) => {});
	// 		} else if (result.isDenied) {
	// 			Swal.fire("Changes are not saved", "", "info");
	// 		  }
	// 		});
	// };

	// console.log(Item,scenarioAmount,tat, 'ItemscenarioAmount');
	React.useEffect(() => {
		formik.setFieldValue('tat', tat);
		formik.setFieldValue('scenario_amount', scenarioAmount);
	}, [tat, scenarioAmount]);



	return (
		<div>
			<Modal open={isOpen} onClose={onCloseModal}>
				<Box sx={style}>
						<Box
							sx={{
							display: "flex",
							alignItems: "center",
							columnGap: "1rem",
							marginBottom: "0.5rem",             
							}}
						>
							<Typography
							style={{
								fontSize: "1rem",
								fontWeight: "600",
								width: "100%",
								textAlign: "center",
								marginRight: "-2rem",
							}}
							>
							Other ZBC Information
						</Typography>
						<CancelIcon
							titleAccess='Close'
							sx={{ cursor: 'pointer', margin: '0.5rem' }}
							onClick={() => {
								formik.resetForm();
								onCloseModal();
							}}
						/>
					</Box>
					<Divider
						sx={{
							borderColor:'primary.light',
							margin: '0 1rem',
							marginBottom: '1rem',
						}}
					/>
					<Box sx={{ height: 'fit-content', width: '100%' }}>
						<form onSubmit={formik.handleSubmit}>
							<FormControl fullWidth variant='standard'>
								<InputLabel
									sx={{ color: 'primary.main', marginLeft: '1rem' }}
									shrink
									id='demo-simple-select-label'>
									Other ZBC Amount (in INR) <sup style={{ color: 'red' }}>*</sup>
								</InputLabel>
								<TextField
									variant='standard'
									// autoFocus={autoFocusStatus}
									
									InputProps={{
										style: { color: '#007fff' },
									}}
									InputLabelProps={{
										shrink: true,
									}}
									sx={{
										margin: '1rem 1rem',
										'& .Mui-error': {
											color: '#d32f2f',
											borderBottomColor: '#d32f2f',
										},
										'& .MuiInput-underline:before': {
											borderBottomColor: 'primary.light',
										},
										'& .MuiInput-underline:after': {
											borderBottomColor: 'primary.main',
										},
										'& .MuiFormLabel-root.Mui-focused': {
											color: 'primary.main',
										},
										'& .MuiFormLabel-root.MuiInputLabel-root ': {
											color: 'primary.main',
										},
										'.MuiInputBase-root.MuiInput-root': {
											fontSize: '1rem !important',
										},
									}}
									id='scenario_amount'
									type='number'
									name='scenario_amount'
									onChange={formik.handleChange}
									value={formik.values.scenario_amount}
									error={
										formik.touched.scenario_amount &&
										Boolean(formik.errors.scenario_amount)
									}
									helperText={
										formik.touched.scenario_amount &&
										formik.errors.scenario_amount
									}
								/>
							</FormControl>
							<FormControl fullWidth variant='standard'>
								<InputLabel
									sx={{ color: 'primary.main', marginLeft: '1rem' }}
									shrink
									id='demo-simple-select-label'>
									Other ZBC Information Turn Around Time (in Days){' '}
									<sup style={{ color: 'red' }}>*</sup>
								</InputLabel>
								<TextField
									variant='standard'
									// autoFocus={autoFocusStatus}
									InputProps={{
										style: { color: '#007fff' },
									}}
									InputLabelProps={{
										shrink: true,
									}}
									sx={{
										margin: '1rem 1rem',
										'& .Mui-error': {
											color: '#d32f2f',
											borderBottomColor: '#d32f2f',
										},
										'& .MuiInput-underline:before': {
											borderBottomColor: 'primary.light',
										},
										'& .MuiInput-underline:after': {
											borderBottomColor: 'primary.main',
										},
										'& .MuiFormLabel-root.Mui-focused': {
											color: 'primary.main',
										},
										'& .MuiFormLabel-root.MuiInputLabel-root ': {
											color: 'primary.main',
										},
										'.MuiInputBase-root.MuiInput-root': {
											fontSize: '1rem !important',
										},
									}}
									id='tat'
									name='tat'
									type='number'
									onChange={formik.handleChange}
									value={formik.values.tat}
									error={formik.touched.tat && Boolean(formik.errors.tat)}
									helperText={formik.touched.tat && formik.errors.tat}
								/>
							</FormControl>

							<Box sx={{ textAlign: 'right', marginRight: '1rem' }}>
								<LoadingButton
								disabled={formik.values.tat === "" || formik.values.scenario_amount === ""}
									size='small'
									variant='contained'
									type='submit'
									loading={loader}>
									{/* {Item.lock ? 'Lock & Publish' : 'Update'} */}
									Update
								</LoadingButton>
							</Box>
						</form>
					</Box>
				</Box>
			</Modal>
		</div>
	);
};

export default CostingDataModal;
