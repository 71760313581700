import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Formik, Field, Form } from "formik"; // Use Formik's Form component
import CancelIcon from "@mui/icons-material/Cancel";
import React from "react";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50rem",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

function ManualLocationModal({ isOpen, onCloseModal, location, onSave }: any) {
  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: "-2rem",
            }}
          >
            Edit Location Information
          </Typography>
          <CancelIcon
            titleAccess="Close"
            sx={{ cursor: "pointer", margin: "0.5rem" }}
            onClick={onCloseModal}
          />
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
            marginBottom: "1rem",
          }}
        />

        <Formik
          initialValues={location}
          onSubmit={(values) => {
            onSave(values); // Save updated values
            onCloseModal(); // Close modal after saving
          }}
        >
          {({ touched, errors, dirty }) => (
            <Form>
              {" "}
              {/* Replace with Formik's <form> */}
              <DialogContent>
                <Field name="gstin">
                  {({ field }: any) => (
                    <TextField
                      {...field}
                      label="GSTIN"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      error={!!(touched.gstin && errors.gstin)}
                      helperText={touched.gstin && errors.gstin}
                    />
                  )}
                </Field>

                <Field name="active">
                  {({ field, form }: any) => (
                    <FormControl fullWidth variant="outlined" margin="normal">
                      <InputLabel id="active-label">Active</InputLabel>
                      <Select
                        labelId="active-label"
                        label="Active"
                        value={field.value ? "true" : "false"} // Convert boolean to string for Select
                        onChange={(event) => {
                          const value = event.target.value === "true"; // Convert string back to boolean
                          form.setFieldValue(field.name, value); // Update Formik state
                        }}
                      >
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                      </Select>
                      {form.touched.active && form.errors.active && (
                        <Typography color="error">
                          {form.errors.active}
                        </Typography>
                      )}
                    </FormControl>
                  )}
                </Field>

                <Field name="legalName">
                  {({ field }: any) => (
                    <TextField
                      {...field}
                      label="Legal Name"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      error={!!(touched.legalName && errors.legalName)}
                      helperText={touched.legalName && errors.legalName}
                    />
                  )}
                </Field>
                <Field name="pan">
                  {({ field }: any) => (
                    <TextField
                      {...field}
                      label="PAN"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      error={!!(touched.pan && errors.pan)}
                      helperText={touched.pan && errors.pan}
                    />
                  )}
                </Field>
                <Field name="primaryAddress.district">
                  {({ field }: any) => (
                    <TextField
                      {...field}
                      label="District"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      // @ts-ignore
                      // error={
                      //   !!(
                      //     touched.primaryAddress?.district &&
                      //     errors.primaryAddress?.district
                      //   )
                      // }
                      // @ts-ignore
                      // helperText={
                      //   touched.primaryAddress?.district &&
                      //   errors.primaryAddress?.district
                      // }
                    />
                  )}
                </Field>
                <Field name="primaryAddress.street">
                  {({ field }: any) => (
                    <TextField
                      {...field}
                      label="Street"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      // @ts-ignore
                      // error={
                      //   !!(
                      //     touched.primaryAddress?.street &&
                      //     errors.primaryAddress?.street
                      //   )
                      // }
                      // // @ts-ignore
                      // helperText={
                      //   touched.primaryAddress?.street &&
                      //   errors.primaryAddress?.street
                      // }
                    />
                  )}
                </Field>
              </DialogContent>
              <DialogActions>
                <Button onClick={onCloseModal}>Cancel</Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!dirty}
                >
                  Save Changes
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}

export default ManualLocationModal;
